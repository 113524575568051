import React, { useMemo } from "react";
import { NewAccountsTable } from "./NewAccountsTable";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly withCreatorsAndApprovals?: boolean;
  readonly filter: AccountsFilter;
}

export function NewAccountTableWrapper({ withCreatorsAndApprovals, filter }: Props) {
  const { AccountApi } = useAccountContext();
  const dispatch = useDispatch();
  const getAccountForApprovalList = useResource(
    () => AccountApi.getAccountOpeningList(filter.getInquiryListFilter()),
    [filter],
  );
  const data = useMemo(() => getAccountForApprovalList.data || [], [
    getAccountForApprovalList.data,
  ]);
  useResourceHandler(getAccountForApprovalList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  return <NewAccountsTable data={data} withCreatorsAndApprovals={withCreatorsAndApprovals} />;
}
