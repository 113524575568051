import React from "react";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly type: string;
}

export function PaymentClaimTypePicker({ type }: Props) {
  const { translate } = useI18n();
  return (
    <span className="text-align-center py-2">
      {type === "01"
        ? `${translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_TITLE")}`
        : type === "03"
        ? `${translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_REFUND_TITLE")}`
        : "NaN"}
    </span>
  );
}
