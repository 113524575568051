import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function ContactUsIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 17.524 19.443"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-12 -9.81)">
        <g transform="translate(12 9.81)">
          <path
            fill={color}
            transform="translate(-12 -23.774)"
            d="M23.814,34.662a4.484,4.484,0,0,1-6.1,0A8.682,8.682,0,0,0,12,42.779a.438.438,0,0,0,.438.438H29.086a.438.438,0,0,0,.438-.438,8.682,8.682,0,0,0-5.71-8.117Z"
          />
          <path
            fill={color}
            transform="translate(-18.114 -12.506)"
            d="M30.871,18.929a4.276,4.276,0,0,0-4-4.321,4.276,4.276,0,0,0-4,4.321h.011a2.828,2.828,0,0,0,.595.059,3.517,3.517,0,0,0,3.01-1.878.455.455,0,0,1,.759,0,3.517,3.517,0,0,0,3.01,1.878,2.828,2.828,0,0,0,.595-.059h.011Z"
          />
          <path
            fill={color}
            transform="translate(-18.16 -17.008)"
            d="M26.923,22.62a4.28,4.28,0,0,1-3.389,1.747,3.812,3.812,0,0,1-.57-.046,4.215,4.215,0,0,0,3.959,3.9,4.215,4.215,0,0,0,3.959-3.9,3.812,3.812,0,0,1-.57.046A4.28,4.28,0,0,1,26.923,22.62Z"
          />
          <path
            fill={color}
            transform="translate(-14.427 -9.81)"
            d="M18.839,19.155h.114a5.833,5.833,0,0,1-.644-2.69,6.064,6.064,0,0,1,.206-1.56l0,0a5.136,5.136,0,0,1,.25-.732,4.861,4.861,0,0,1,4.42-3.483,4.861,4.861,0,0,1,4.42,3.483,5.136,5.136,0,0,1,.25.732l0,0a6.064,6.064,0,0,1,.206,1.56,5.833,5.833,0,0,1-.644,2.69h.114a2.517,2.517,0,0,0,2.484-2.09.828.828,0,0,1,.464.223c.048.053.188.21.048.6a3.377,3.377,0,0,1-2.659,1.923.439.439,0,0,0,.074.872.482.482,0,0,0,.079-.009,4.2,4.2,0,0,0,3.334-2.5,1.409,1.409,0,0,0-.228-1.476,1.754,1.754,0,0,0-1.126-.521,2.535,2.535,0,0,0-.688-1.3,2.574,2.574,0,0,0-.723-.491A5.83,5.83,0,0,0,23.189,9.81,5.83,5.83,0,0,0,17.779,14.4a2.54,2.54,0,0,0-.732.5,2.5,2.5,0,0,0,1.792,4.263Z"
          />
        </g>
      </g>
    </svg>
  );
}
