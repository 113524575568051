import { UnicodeToWin1251 } from "./AnsiEncodeUtils";
import { addZeros, floatFormat, floatFormatComma } from "./FloatUtils";
import { formatDateToBody } from "./DateUtils";
import iconv from "iconv-lite";

const spacePutter = (value, length) => {
  let space = "";
  for (let i = 0; i < length - value; i++) {
    space += " ";
  }
  return space;
};

function txtVer2(data) {
  const totalDebit = data.list.filter((x) => x.debitAmount !== "0");
  const totalCredit = data.list.filter((x) => x.creditAmount !== "0");
  return `${formatDateToBody(new Date())}. \r
Счет клиента: ${data.accountNumber} / ${data.accountName}
Клиент: ${data.accountName}   / ИНН: 201199324
 
==================================================================================================================
|Период выписки с ${data.fromDate} по ${
    data.toDate
  }.                                                                      |
|Входящий остаток на ${data.fromDate}.  :${spacePutter(
    addZeros(data.beginBalance).length,
    78,
  )}${addZeros(data.beginBalance).replace(".", ",")}|
------------------------------------------------------------------------------------------------------------------
|   Дата   |   Номер  |Оп| Корреспондент: Банк/Счет/ИНН Наименование         |      Дебет      |      Кредит     |
| проводки | документа|  | Назначение платежа                                |                 |                 |
${data.list.map((x) => {
  const date = `${x.transactionDate.substr(6)}${x.transactionDate.substr(
    3,
    2,
  )}${x.transactionDate.substr(0, 2)}`;
  return `-----------------------------------------------------------------------------------------------------------------
|${date}  |${spacePutter(x.documentNumber.length, 10)}${x.documentNumber}|01|МФО:${
    x.receiverMfo ? x.receiverMfo : x.receiverMFO
  }  Счет:${x.receiverAccount}  ИНН:${x.receiverTaxNumber}|${spacePutter(
    x.debitAmount !== "0" ? floatFormatComma(addZeros(x.debitAmount)).length : "0.00".length,
    17,
  )}${x.debitAmount !== "0" ? floatFormatComma(addZeros(x.debitAmount)) : "0.00"}|${spacePutter(
    x.creditAmount !== "0" ? floatFormatComma(addZeros(x.creditAmount)).length : "0.00".length,
    17,
  )}${x.creditAmount !== "0" ? floatFormatComma(addZeros(x.creditAmount)) : "0.00"}|
|          |          |  |${spacePutter(x.receiverName.length, 51)}${
    x.receiverName
  }|                 |                 |
|          |          |  |${x.paymentDetail.substr(1, 51)}${spacePutter(
    x.paymentDetail.substr(1, 51).length,
    51,
  )}|                 |                 |
|          |          |  |${x.paymentDetail.substr(52, 51)}${spacePutter(
    x.paymentDetail.substr(52, 51).length,
    51,
  )}|                 |                 |
|          |          |  |${x.paymentDetail.substr(102, 51)}${spacePutter(
    x.paymentDetail.substr(102, 51).length,
    51,
  )}|                 |                 |
|          |          |  |${x.paymentDetail.substr(153, 51)}${spacePutter(
    x.paymentDetail.substr(153, 51).length,
    51,
  )}|                 |                 |
`;
})}
------------------------------------------------------------------------------------------------------------------
|Сумма оборотов:                                                             |${spacePutter(
    data.debitAmount !== "0" ? addZeros(data.debitAmount).length : "0".length,
    17,
  )}${data.debitAmount !== "0" ? addZeros(data.debitAmount) : "0"}|${spacePutter(
    data.creditAmount !== "0" ? addZeros(data.creditAmount).length : "0".length,
    17,
  )}${data.creditAmount !== "0" ? addZeros(data.creditAmount) : "0"}|
|Количество оборотов:                                                        |${spacePutter(
    floatFormat(totalDebit.length.toString()).length,
    17,
  )}${floatFormat(totalDebit.length.toString())}|${spacePutter(
    floatFormat(totalCredit.length.toString()).length,
    17,
  )}${floatFormat(totalCredit.length.toString())}|
|Исходящий остаток на ${data.toDate}  :${spacePutter(
    data.endBalance === "0" ? "0".length : floatFormatComma(addZeros(data.endBalance)).length,
    78,
  )}${data.endBalance === "0" ? "0" : floatFormatComma(addZeros(data.endBalance))}|
==================================================================================================================
 
 МФО:${data.list[0].senderMFO}, ${data.list[0].senderBankName}`;
}

// 01.01.2020 => 01.01.20
const removeSomeSymbols = (str: string) =>
  str
    .split("")
    .map((item, index) => (index !== 6 && index !== 7 ? item : ""))
    .join("");
function txtVer3(data) {
  data.list.map(
    (x, index) =>
      (x.forTxt = `${index === 0 ? "" : `\r\n`}${x.documentNumber}~${removeSomeSymbols(
        x.transactionDate,
      )}~${removeSomeSymbols(x.transactionDate)}~${
        x.status === "CREDIT"
          ? x.receiverMfo
            ? x.receiverMfo
            : x.receiverMFO
          : x.senderMfo
          ? x.senderMfo
          : x.senderMFO
      }~${x.status === "CREDIT" ? x.receiverAccount : x.senderAccount}~${
        x.status === "CREDIT" ? x.receiverName : x.clientName
      }~${x.status === "CREDIT" ? x.receiverTaxNumber : x.senderTaxNumber}~${
        x.status === "CREDIT"
          ? x.senderMfo
            ? x.senderMfo
            : x.senderMFO
          : x.receiverMfo
          ? x.receiverMfo
          : x.receiverMFO
      }~${x.status === "CREDIT" ? x.senderAccount : x.receiverAccount}~${
        x.status === "CREDIT" ? x.clientName : x.receiverName
      }~${x.status === "CREDIT" ? x.senderTaxNumber : x.receiverTaxNumber}~${
        x.debitAmount === "0" ? x.creditAmount : x.debitAmount
      }~${x.paymentDetail}`),
  );
  const txtDone = data.list.map((x) => x.forTxt);
  return `Номер документа~Дата документа~Дата проводки~Банк плательщика~Счет плательщика~Наименование плательщика~ИНН плательщика~Банк получателя~Счет получателя~Наименование получателя~ИНН получателя~Сумма~Детали платежа \r\n${txtDone.join(
    "",
  )}\r\n`;
}

export function downloadTxt(content, filename, charset) {
  // you don't use this, it's old version
  if (charset == "windows-12511") content = UnicodeToWin1251(content);

  if (iconv) {
    const encodedDataURL = iconv.encode(content, "win1251");
    const encoded_as_ANSI = new Blob([encodedDataURL], {
      type: "text/plain;charset=windows-1251",
      endings: "native",
    });

    const url = window.URL.createObjectURL(encoded_as_ANSI);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  }

  // if (charset == "windows-1251") content = UnicodeToWin1251(content);
  // const blob = new Blob([content], { type: "text/plain", endings: "native" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.href = url;
  // a.download = filename;
  // a.click();
}

export function downloadVer2(data, filename) {
  downloadTxt(txtVer2(data), filename, "utf-8");
}

export function downloadVer3(data, filename) {
  downloadTxt(txtVer3(data), filename, "utf-8");
}

export function downloadAnsi(data, filename) {
  downloadTxt(txtVer2(data), filename, "utf-8");
}

export function currencyCodeCheck() {
  return /^(RUB|UZS|USD|EUR|GBP|KZT|CNY|KRW|JPY|AUD|AZN|ALL|DZD|ARP|AMD|BYN|BGN|BOB|BRL|HUF|VND|GTM|HKD|GEL|DKK|AED|DOP|EGP|INR|IND|JOD|IRR|ISK|CAD|KES|KGS|COP|CUP|KWD|MGA|MYR|MAD|MXP|MDL|MNT|MMK|NPR|NGN|NZD|ILS|TWD|NOK|OMR|PKR|PAB|PYG|PEN|PLN|RON|SAR|RSD|SGD|THB|TZS|TND|TRY|UAH|UYU|PHP|HRK|CLP|SEK|CHF|ETB|ZAR|JMD)*$/g;
}
