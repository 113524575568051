import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function PendingApprovalsIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20.013 19.226"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-6.366 -8.006)">
        <path
          fill={color}
          d="M32.807,28a4.807,4.807,0,1,0,4.807,4.807A4.807,4.807,0,0,0,32.807,28Zm2.264,3.67L32.7,34.553a.481.481,0,0,1-.348.175h-.023a.479.479,0,0,1-.34-.141l-1.442-1.442a.481.481,0,1,1,.68-.68l1.067,1.067,2.037-2.475a.481.481,0,0,1,.743.611Z"
          transform="translate(-16.434 -15.187)"
        />
        <path
          fill={color}
          d="M25.27,15.861l-.478-.228a.983.983,0,0,1-.441-1.358l.253-.465a1.946,1.946,0,0,0-2.065-2.843l-.52.1a.985.985,0,0,1-1.157-.839L20.793,9.7a1.946,1.946,0,0,0-3.341-1.086L17.087,9h0a1.007,1.007,0,0,1-1.429,0l-.364-.384A1.946,1.946,0,0,0,11.953,9.7l-.069.525a.978.978,0,0,1-.4.668.961.961,0,0,1-.759.171l-.521-.1A1.946,1.946,0,0,0,8.142,13.81l.253.465a.983.983,0,0,1-.441,1.358l-.478.228a1.946,1.946,0,0,0,0,3.514l.478.228a.983.983,0,0,1,.441,1.358l-.253.465a1.946,1.946,0,0,0,2.065,2.843l.52-.1a.985.985,0,0,1,1.157.839l.069.526a1.929,1.929,0,0,0,1.328,1.6,1.97,1.97,0,0,0,.609.1,1.931,1.931,0,0,0,1.4-.609l.364-.385a1.007,1.007,0,0,1,1.429,0l.364.384a1.946,1.946,0,0,0,3.341-1.086l.069-.525a.985.985,0,0,1,1.156-.84l.521.1A1.946,1.946,0,0,0,24.6,21.427l-.253-.465a.983.983,0,0,1,.441-1.358l.478-.228a1.946,1.946,0,0,0,0-3.514Zm-8.9,7.525a5.768,5.768,0,1,1,5.768-5.768A5.768,5.768,0,0,1,16.373,23.387Z"
        />
      </g>
    </svg>
  );
}
