import React from "react";

import { BankLogo } from "../app/BankLogo";
import { StatementTransactionItem } from "./StatementTransactionItem";
import { useI18n } from "../../i18n/I18nContext";
import "./assets/statement-table-items.scss";
import { formatDateToBody } from "../../utils/DateUtils";
import { floatFormat } from "../../utils/FloatUtils";
import { Loader } from "../ui/Loader";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

interface Props {
  readonly account: any;
  readonly data: any;
  readonly loading: boolean;
}

function CorporateStatementDataLoc({ account, data, loading }: Props) {
  const { translate } = useI18n();
  const isMobile = CheckMobileUtils();

  return (
    <div className="d-flex flex-column p-7 bg-white border border-gray-light br-1">
      <div className="mb-7">
        <div className="d-flex">
          <span>
            <span className="font-weight-bold fs-4">
              {translate("ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NAME_COLUMN_TITLE")}
            </span>
            {`: ${account.accountNumber.accountName}`}
          </span>
          {!isMobile && <BankLogo className="mb-5 img-for-statement" />}
        </div>
        <span>
          <span className="font-weight-bold fs-4">
            {translate("ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE")}
          </span>
          {`: ${account.accountNumber.label}`}
        </span>
        <hr className="line-in-body" />
      </div>
      <table>
        <tr>
          <th className="text-center font-weight-semi-bold column-header table-headers header-width-1" />

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-3" />

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {`${formatDateToBody(account.fromDate)}`}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {translate("ACCOUNT_STATEMENT_FORM_OPENING_BALANCE_FIELD_LABEL_TITLE")}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {data?.beginBalance ? floatFormat(data.beginBalance) : "0.00"}
          </th>
        </tr>
      </table>
      <hr className="line-in-body" />
      <table>
        <tr>
          <th className="text-center font-weight-semi-bold column-header table-headers header-width-1">
            {translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_POST_VALUE_COLUMN_TITLE")}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-3">
            {translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_REFERENCE_COLUMN_TITLE")}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE")}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE")}
          </th>

          <th className="text-center font-weight-semi-bold column-header table-headers header-width-2">
            {translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_BALANCE_COLUMN_TITLE")}
          </th>
        </tr>
      </table>
      {!loading && data?.list?.length > 0 ? (
        data.list.map((x) => <StatementTransactionItem key={x.transactionId} data={x} />)
      ) : !loading ? (
        <span className="text-center fs-4 font-weight-bold my-5">
          {translate("STATEMENT_NO_TRANSACTIONS")}
        </span>
      ) : (
        <div className="text-center fs-4 font-weight-bold my-5">
          <Loader />
        </div>
      )}
      <table className="table table-bordered mb-0">
        <tr>
          <td className="column-header table-headers font-weight-bold total-row-column">
            {`${translate("ACCOUNT_STATEMENT_FORM_ITEM_STATEMENT_FIELD_LABEL_TITLE")} ${
              data?.list?.length > 0 ? data.list[0].totalRow : "0"
            }`}
          </td>
          <td className="column-header table-headers font-weight-bold">Total:</td>
          <td className="text-center column-header table-headers font-weight-bold">
            {data?.debitAmount ? floatFormat(data.debitAmount) : "0.00"}
          </td>
          <td className="text-center column-header table-headers font-weight-bold">
            {data?.creditAmount ? floatFormat(data.creditAmount) : "0.00"}
          </td>
          <td />
        </tr>
        <tr>
          <td className="column-header table-headers font-weight-bold">End of statement</td>
          <td />
          <td className="text-center column-header table-headers font-weight-bold">
            {`${formatDateToBody(account.toDate)}`}
          </td>
          <td className="text-center column-header table-headers font-weight-bold">
            {translate("ACCOUNT_STATEMENT_FORM_CLOSING_BALANCE_FIELD_LABEL_TITLE")}
          </td>
          <td className="text-center column-header table-headers font-weight-bold">
            {data?.endBalance ? floatFormat(data.endBalance) : "0.00"}
          </td>
        </tr>
      </table>
    </div>
  );
}

export const CorporateStatementData = React.memo(CorporateStatementDataLoc);
