import React, { useCallback, useState } from "react";

import { TabPage } from "../tabs/TabPage";
import { CorporateCardOpeningUzCardForm } from "./CorporateCardOpeningUzCardForm";
import { AccountNameValues, ApplicationAccountOpeningForm } from "./ApplicationAccountOpeningForm";
import { CorporateCardOpeningVisaForm } from "./CorporateCardOpeningVisaForm";
import { ApplicationAccountOpeningFormIndividual } from "./ApplicationAccountOpeningFormIndividual";

interface Props {
  readonly onSubmit: (value) => void;
  readonly loading: boolean;
  readonly individual?: boolean;
}

export function NewAccountOpeningForm({ onSubmit, loading, individual = false }: Props) {
  const [accountType, setAccountType] = useState("");

  const getAccountName = useCallback((value) => {
    setAccountType(value);
  }, []);

  return (
    <TabPage className="px-lg-7 pt-lg-7" contentClassName="bg-lg-transparent border-0">
      {individual ? (
        <ApplicationAccountOpeningFormIndividual onSubmit={onSubmit} loading={loading} />
      ) : (
        <ApplicationAccountOpeningForm
          getAccountName={getAccountName}
          accountType={accountType}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}
      {accountType === AccountNameValues.UzCard ? (
        <CorporateCardOpeningUzCardForm />
      ) : accountType === AccountNameValues.Visa ? (
        <CorporateCardOpeningVisaForm />
      ) : null}
    </TabPage>
  );
}
