import { BaseApi } from "../BaseApi";
import {
  CardHoldersProps,
  CardHoldersQuery,
  CorporateCardsProps,
  CitizenShipProps,
  AccountOfCorporateProps,
} from "./CorporateCardsDTO";
import { TransferType } from "../transfer/TransferDTO";

interface GetSalaryListQuery {
  readonly transferType: TransferType;
  readonly pageNumber: number;
  readonly pageSize: number;
}

export class CorporateCardsApi extends BaseApi {
  public getCorporateCardsList(query: GetSalaryListQuery): Promise<CorporateCardsProps[]> {
    return this.get("salary/payrollforapproval/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getSalaryCardHolders(query: CardHoldersQuery): Promise<CardHoldersProps[]> {
    return this.get("corporateCard/card/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public citizenshipList(): Promise<CitizenShipProps[]> {
    return this.get("corporateCard/citizenship", { query: { employeeId: this.employeeId } });
  }

  public accountOfCorporateCardList(): Promise<AccountOfCorporateProps[]> {
    return this.get("corporateCard/card/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query: { cardType: "UZCARD" },
    });
  }
}
