import "./assets/history-table.scss";

import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { TransferHistoryProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { Dict } from "../../api/dto/AppDTO";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly data: TransferHistoryProps[];
  readonly loading: boolean;
  readonly onSelect?: (ids: Dict<boolean>) => void;
}

export function HistoryTable({ data, loading, onSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<TransferHistoryProps>[] = [
      {
        width: 95,
        accessor: "transactionDate",
        Header: translate(
          "CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 180,
        accessor: "senderAccount",
        Header: translate("TRANSFERS_HISTORY_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE"),
      },
      {
        width: 200,
        accessor: "receiverName",
        Header: translate("TRANSFERS_HISTORY_TABLE_CORRESPONDENT_NAME"),
      },
      {
        width: 180,
        accessor: "receiverAccount",
        Header: translate("TRANSFERS_HISTORY_TABLE_CORRESPONDENT_ACCOUNT"),
      },
      {
        width: 100,
        accessor: "currency",
        Header: translate("TRANSFERS_HISTORY_FILTER_FORM_CURRENCY_FIELD_LABEL_TITLE"),
      },
      {
        width: 110,
        accessor: "receiverMfo",
        Header: translate("TRANSFERS_HISTORY_TABLE_CORRESPONDENT_BANK_CODE_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "debitAmount",
        Header: translate("TRANSFERS_HISTORY_TABLE_DEBIT_AMOUNT"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{floatFormat(addZeros(row.value))}</div>,
      },
      {
        width: 180,
        accessor: "creditAmount",
        Header: translate("TRANSFERS_HISTORY_TABLE_CREDIT_AMOUNT"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{floatFormat(addZeros(row.value))}</div>,
      },
      {
        width: 300,
        accessor: "paymentDetails",
        Header: translate("TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE"),
      },
    ];

    return list;
  }, [translate]);

  return (
    <div className="d-flex br-1 border border-gray-light flex-column flex-1 bg-white overflow-y-hidden position-relative overflow-x-auto">
      <Table
        data={data}
        columns={columns}
        loading={loading}
        showEmptyRow={true}
        withCheckbox={true}
        onSelect={onSelect}
      />
    </div>
  );
}
