

import React, { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";

import { PageTitle } from "../app/PageTitle";
import { InputField } from "../form/InputField";
import { TransferType } from "../../api/transfer/TransferDTO";
import { update } from "immupdate";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";
import { SelectPickerField } from "../form/SelectPickerField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { DebitAccountNumberFieldWrapper } from "../form/DebitAccountNumberFieldWrapper";
import { useResource } from "../../hooks/useResource";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { Button, ButtonColor } from "../ui/Button";

import { TransitAccountNumberFieldWrapper } from "../form/TransitAccountNumberFieldWrapper";
import { SelectPicker } from "../ui/SelectPicker";
import { TransferSalaryDetailsType } from "../../constants/TransfersConstants";
import { UploadPayrollExcelButtonField } from "../form/UploadPayrollExcelButtonField";
import { noop } from "lodash";
interface Props {
  readonly transferType: TransferType;
  readonly setInitialValues: (value) => void;
  readonly onChangeAccountType: (value) => void;
  readonly submit: (value) => void;
  readonly initialValues: any;
  readonly accountTypes: any[];
  readonly readFromExcel: (type, value) => void;
  readonly getChooseExistingRegister: (type, value) => void;
  readonly onCreateClick: (type, value) => void;
}

export function SalaryChooseDebitAccountForm({
  transferType,
  setInitialValues,
  onChangeAccountType,
  initialValues,
  accountTypes,
  submit,
  onCreateClick,
  readFromExcel,
  getChooseExistingRegister,
}: Props) {
  const isMobile = CheckMobileUtils();
 
  const onChangeAgrNumber = useCallback(
    (value) => {
      if (value.length <= 21) {
        setInitialValues((prev) =>
          update(prev, {
            number: value,
          }),
        );
      }
    },
    [setInitialValues],
  );
  const onChangeMonth = useCallback(
    (value) => {
      setInitialValues((prev) => update(prev, { month: value }));
    },
    [setInitialValues],
  );


  return (
    <div
      className={`border border-gray-light br-1 mt-5 bg-white p-7 ${isMobile ? "w-100" : "w-50"}`}
    >
      <Formik initialValues={initialValues}  onSubmit={noop}>
        {() => (
          <Form>
            <div className="w-100 d-flex align-items-center justify-content-between pb-5">
              <PageTitle title="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_TITLE" />
              <SelectPickerField
                width={240}
                name="accountType"
                size={SizeType.Small}
                labelPosition={PositionType.Right}
                options={accountTypes}
                onChanges={onChangeAccountType}
              />
            </div>
            <div className="row pb-2">
              <DebitAccountNumberFieldWrapper
                defaultValue={initialValues.debitAccount}
                className="col-lg-5"
                name="debitAccount"
                transferType={transferType}
                label="TRANSFERS_SALARY_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                onChange={(value) => {
                  setInitialValues((prev) =>
                    update(prev, {
                      debitAccount: value,
                      debitAccountBalance: value.balance,
                    }),
                  );
                }}
              />
              <InputField
                disabled={true}
                className="col-lg-5"
                name="accountBalance"
                label="TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE"
                value={initialValues?.debitAccountBalance}
              />
              <InputField
                name="code"
                className="col-lg-2"
                label="TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE"
                value={initialValues?.code}
                disabled={true}
              />
            </div>
            <div className="row pb-2 d-flex">
              <TransitAccountNumberFieldWrapper
                className="col-lg-5"
                name="transitAccount"
                transferType={transferType}
                label="TRANSFERS_SALARY_TRANSIT_ACCOUNT_FIELD_LABEL_TITLE"
                onChange={(value) => {
                  setInitialValues((prev) =>
                    update(prev, {
                      transitAccount: value,
                      transitAccountBalance: value.balance,
                      type: value.label.substr(17, 1) === "0" ? "UZCARD" : "HUMO",
                    }),
                  );
                }}
                defaultValue={initialValues.transitAccount}
              />    
              <InputField
                disabled={true}
                className="col-lg-5"
                name="accountBalance"
                label="TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE"
                value={initialValues?.transitAccountBalance}
              />
            </div>
            <div className="row pb-2 d-flex">
                <div className="col-lg-4 pl-4">
                  <InputField
                    name="number"
                    label="TRANSFERS_SALARY_NUMBER_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeAgrNumber(value.target.value)}
                    value={initialValues?.number}
                    autoComplete="on"
                  />
                </div>
                <div className="col-lg-4">
                  <SelectPicker
                    options={TransferSalaryDetailsType()}
                    defaultValue={initialValues.detailsType}
                    name="detailsType"
                    label="TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE"
                    onChange={(value) =>
                      setInitialValues((prev) =>
                        update(prev, {
                          detailsType: value,
                        }),
                      )
                    }
                  />
                </div>
                <div className="col-lg-4 pl-lg-0">
                  <InputField
                    name="month"
                    label="TRANSFERS_SALARY_MONTH_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeMonth(value.target.value)}
                    value={initialValues?.month}
                  />
                </div>
              </div>

              <PageTitle title="TRANSFERS_SALARY_CHOOSE_REGISTER_TITLE" className="py-5" />
              <div className="d-flex flex-lg-row flex-column">
                <UploadPayrollExcelButtonField
                  disabled={
                    !initialValues.transitAccount ||
                    !initialValues.number ||
                    !initialValues.code ||
                    !initialValues.detailsType ||
                    !initialValues.month ||
                    !initialValues.debitAccount
                  }
                  type="button"
                  name="uploadData"
                  size={SizeType.Medium}
                  className="flex-1 mr-lg-2"
                  color={ButtonColor.Orange}
                  readFromExcel={(value) => readFromExcel("excel", value)}
                />
                <Button
                  disabled={
                    !initialValues.transitAccount ||
                    !initialValues.number ||
                    !initialValues.code ||
                    !initialValues.detailsType ||
                    !initialValues.month  ||
                    !initialValues.debitAccount
                  }
                  onClick={() => getChooseExistingRegister("existing", {})}
                  type="submit"
                  size={SizeType.Medium}
                  className="flex-1 mx-lg-2 my-lg-0 my-3"
                  color={ButtonColor.Orange}
                  labelCode="TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE"
                />
                <Button
                  disabled={
                    !initialValues.transitAccount ||
                    !initialValues.number ||
                    !initialValues.code ||
                    !initialValues.detailsType ||
                    !initialValues.month  ||
                    !initialValues.debitAccount
                  }
                  type="button"
                  size={SizeType.Medium}
                  className="flex-1 ml-lg-2"
                  onClick={() => onCreateClick("new", {})}
                  color={ButtonColor.Orange}
                  labelCode="TRANSFERS_SALARY_CREATE_NEW_REGISTER_BUTTON_TITLE"
                />
              </div>

          </Form>
        )}
      </Formik>
    </div>
  );
}
