import React, { MutableRefObject } from "react";
import "./assets/lcy-create-forms.scss";
import { InputField } from "../form/InputField";
import { Input } from "../ui/Input";
import { Form, Formik } from "formik";
import { LcyTransferBudgetFormProps } from "../../api/transfer/TransferDTO";
import { noop } from "lodash";
import { DatePickerField } from "../form/DatePickerField";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly ref?: MutableRefObject<any>;
  readonly initialValue: LcyTransferBudgetFormProps;
}
export function TransferLcyBudgetPrint({ ref, initialValue }: Props) {
  const { translate } = useI18n();
  return (
    <Formik initialValues={initialValue as LcyTransferBudgetFormProps} onSubmit={noop}>
      {({ handleSubmit }) => (
        <Form
          className=" budget-form form mt-0"
          id="printJS-budget-form"
          ref={ref}
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="row">
              <div className="col">
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3" id="spanBudget">
                    {translate("TRANSFERS_LCY_PRINT_DATA_LABEL")}
                  </label>
                  <div>
                    <DatePickerField name="date" className="date-field" width={580} />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3" id="spanBudget">
                    {translate("TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL")}
                  </label>
                  <div>
                    <InputField
                      name="senderNamePrintt"
                      width={580}
                      height={30}
                      value={initialValue.correspondentName}
                    />
                  </div>
                </div>
                <div className="ml-3 mb-0 mt-0">
                  <h1>{translate("TRANSFERS_LCY_PRINT_DEBIT_LABEL")}</h1>
                </div>
                <div className="d-flex ml-3 mb-0">
                  <div className="form-group row">
                    <label className="form-group row ml-4 mb-0" id="spanStandard">
                      {translate("TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL")}
                    </label>
                    <div className="form-group row ml-9 mb-0">
                      <InputField
                        width={220}
                        height={30}
                        name="currentBalancePrint"
                        value={initialValue.currentBalance}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <label className="form-group row ml-11 pt-3 mb-0" id="spanStandard">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL")}
                    </label>
                    <div className="form-group row ml-8 mb-0">
                      <InputField
                        width={220}
                        height={30}
                        name="senderTaxIdPrint"
                        value={initialValue.senderTaxId}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-0">
                  <div className="form-group row ">
                    <label className="form-group ml-7" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL")}
                    </label>
                    <div className="form-group ml-8">
                      <InputField
                        height={80}
                        width={220}
                        name="senderBankNamePrint"
                        value={initialValue.senderBankName}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <label className="form-group ml-10" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL")}
                    </label>
                    <div className="form-group ml-7">
                      <InputField
                        width={220}
                        height={80}
                        name="senderBankCodePrint"
                        value={initialValue.senderBankCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3" id="spanBudget">
                    {translate("TRANSFERS_LCY_PRINT_AMOUNT_LABEL")}
                  </label>
                  <div className="">
                    <InputField
                      name="amountPrint"
                      width={580}
                      height={30}
                      value={initialValue.amount}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3" id="spanBudget">
                    {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL")}
                  </label>
                  <div className="">
                    <InputField
                      width={580}
                      height={30}
                      name="correspondentNamePrint"
                      value={initialValue.correspondentName}
                    />
                  </div>
                </div>
                <div className="ml-3 mb-0">
                  <h1>{translate("TRANSFERS_LCY_PRINT_CREDIT_LABEL")}</h1>
                </div>
                <div className="d-flex flex-grow-1 mb-0">
                  <div className="form-group row ml-4 mb-0">
                    <label className="form-group" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL")}
                    </label>
                    <div className="form-group ml-9">
                      <InputField
                        width={220}
                        height={30}
                        name="correspondentAccountPrint"
                        value={initialValue.correspondentAccount}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <label className="form-group ml-10 pt-3" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL")}
                    </label>
                    <div className="ml-7">
                      <InputField
                        width={220}
                        height={30}
                        name="correspondentTaxNumberPrint"
                        value={initialValue.correspondentTaxNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-0">
                  <div className="form-group row ">
                    <label className="form-group ml-7" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL")}
                    </label>
                    <div className="form-group ml-9">
                      <InputField
                        width={220}
                        height={80}
                        name="correspondentBankNamePrint"
                        value={initialValue.correspondentBankName}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-0 ">
                    <label className="form-group ml-10 pt-2" id="spanBudget">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL")}
                    </label>
                    <div className="form-group ml-7">
                      <InputField
                        width={220}
                        height={80}
                        name="correspondentBankPrint"
                        value={initialValue.correspondentBank}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-4" id="spanBudget">
                    {translate("TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL")}
                  </label>
                  <div className="ml-1">
                    <InputField
                      name="sum"
                      width={580}
                      height={30}
                      value={initialValue.accountState}
                    />
                  </div>
                </div>
                <div className="d-flex mb-0 mt-1">
                  <div className="form-group row ">
                    <label className="form-group ml-7 pt-3" id="spanBudget">
                      {translate("TRANSFERS_LCY_BUDGET_PRINT_BUDGET_TAX_NUMBER_LABEL")}
                    </label>
                    <div className="ml-9">
                      <InputField
                        name="taxId"
                        width={220}
                        height={80}
                        value={initialValue.budgetTaxNumber}
                      />
                    </div>
                  </div>
                  <div className="form-group row ">
                    <label className="form-group ml-10" id="spanBudget">
                      {translate("TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_LABEL")}
                    </label>
                    <div className="ml-8">
                      <InputField
                        name="budgetAccount"
                        value={initialValue.budgetAccount}
                        width={220}
                        height={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanBudgetLong">
                    {translate("TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_NAME_LABEL")}
                  </label>
                  <div className="ml-1 pt-5">
                    <InputField
                      name="budgetName"
                      width={580}
                      height={30}
                      value={initialValue.budgetAccountName}
                    />
                  </div>
                </div>
                {initialValue.transactionDate && (
                  <div className="form-group row mb-4">
                    <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanBudgetLong">
                      {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
                    </label>
                    <div className="ml-1">
                      <InputField
                        name="name"
                        width={580}
                        height={30}
                        value={`Проведен (${initialValue.transactionDate})`}
                      />
                    </div>
                  </div>
                )}

                {/* start */}
                {/* <div className="form-group row mb-4">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanBudgetLong">
                    AuthorizerOtpKey
                  </label>
                  <div className="input-label-large from-group">
                    <Input
                      width={580}
                      height={30}
                      value={
                        initialValue.firstAuthorizerOtpKey
                          ? initialValue.firstAuthorizerOtpKey + " "
                          : " " + initialValue.secondAuthorizerOtpKey
                          ? initialValue.secondAuthorizerOtpKey + " "
                          : " " + initialValue.thirdAuthorizerOtpKey
                          ? initialValue.thirdAuthorizerOtpKey + ""
                          : ""
                      }
                    />
                  </div>
                </div> */}
                {/* end */}

                <div className="form-group row text-center mt-3 ml-11">
                  <label className="ml-11" id="spanGeneralAcc">
                    {translate("TRANSFERS_LCY_PRINT_MANAGER_LABEL")}
                  </label>
                  <label className="ml-11" id="spanInitialsTop">
                    {translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}
                  </label>
                  <input className="border-right-0 border-left-0 border-top-0 border-light ml-11" />
                </div>
                <div className="form-group row text-center ml-11">
                  <label className="ml-11" id="spanGeneralAcc">
                    {translate("TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL")}
                  </label>
                  <label className="ml-7" id="spanInitialsBot">
                    {translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}
                  </label>
                  <input className="border-right-0 border-left-0 border-top-0 border-light ml-11" />
                </div>
                <div className="row w-75 ml-11 mt-7 ml-10">
                  <div className="form-group row">
                    <div className=" ml-11 pl-11 row text-center ">
                      <Input
                        placeholder="Проверено"
                        className="w-25 mh-auto mr-8 pb-5 text-center"
                      />
                      <Input placeholder="Одобрено" className="w-25 mh-100 mr-8 pb-5 text-center" />
                      <Input placeholder="Проведено" className="w-25 mh-100 pb-5 text-center" />
                    </div>
                    <div className="row">
                      <label
                        className="col-sm-2 col-form-label position-absolute top-3 w-25"
                        id="span1"
                      >
                        {translate("TRANSFERS_LCY_PRINT_BANK_LABEL")}
                      </label>
                      <div className=" ml-11 pl-11 row text-center ">
                        <input
                          className="w-25 mh-auto mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                        <input
                          className="w-25 mh-100 mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                        <input
                          className="w-25 mh-100 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
