import React from "react";

import { TabPage } from "../tabs/TabPage";
import { PageTitle } from "../app/PageTitle";
import { CorrespondentsTable } from "./CorrespondentsTable";
import { CorrespondentProps } from "../../api/transfer/TransferDTO";

interface Props {
  readonly loading: boolean;
  readonly list: CorrespondentProps[];
}

export function CorrespondentsTab({ list, loading }: Props) {
  return (
    <TabPage
      className="px-4"
      headerComponent={
        <div className="d-flex flex-grow-1 flex-shrink-1 align-items-center">
          <PageTitle title="TRANSFERS_CORRESPONDENTS_TITLE" />
        </div>
      }
    >
      <CorrespondentsTable loading={loading} data={list} />
    </TabPage>
  );
}
