function checkNumber(str: string) {
  const check = str?.charAt(str?.length - 1);
  if (check?.match(/([0-9])/) == null && check != "." && check != "," && check != " ") {
    return str?.substring(0, str?.length - 1);
  }
  return str;
}

export function removeGaps(str: string) {
  str = checkNumber(str);

  let result = "";
  for (let i = 0; i < str?.length; i++) {
    if (str?.charAt(i) != " ") {
      result += str?.charAt(i);
    }
  }
  const dot = result.indexOf(".") != -1 ? result.indexOf(".") : result.indexOf(",");
  for (let i = 0; i < result?.length; i++) {
    if (i == dot) {
      i++;
    }
    if (result?.charAt(i) == "." || result?.charAt(i) == ",") {
      result = result?.substring(0, i) + result?.substring(i + 1);
    }
  }
  return dot == -1 ? result : result?.substring(0, dot + 3);
}

export function floatFormat(number: string) {
  number = removeGaps(number);
  let result = "";
  const dot = number.indexOf(".") != -1 ? number.indexOf(".") : number.indexOf(",");

  for (let i = 0; i < number?.length; i++) {
    if (i != 0 && i != number?.length - 1) {
      if (dot == -1) {
        if ((number?.length - i) % 3 == 0) {
          result += " ";
        }
      } else if ((dot - i) % 3 == 0 && i != dot) {
        result += " ";
      }
    }
    result += number?.charAt(i);
  }

  return result;
}

export function floatFormatComma(number: string) {
  number = removeGaps(number);
  let result = "";
  const dot = number.indexOf(".") != -1 ? number.indexOf(".") : number.indexOf(",");

  for (let i = 0; i < number?.length; i++) {
    if (i != 0 && i != number?.length - 1) {
      if (dot == -1) {
        if ((number?.length - i) % 3 == 0) {
          result += ",";
        }
      } else if ((dot - i) % 3 == 0 && i != dot) {
        result += ",";
      }
    }
    result += number?.charAt(i);
  }

  return result;
}

export function addZeros(value) {
  const dot = value.indexOf(".") != -1 ? value.indexOf(".") : value.indexOf(",");
  switch (dot) {
    case -1:
      value += ".00";
      break;
    case value?.length - 1:
      value += "00";
      break;
    case value?.length - 2:
      value += "0";
  }
  return value;
}
