import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function NotificationIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 18.326 19.992"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0.001)">
        <path
          fill={color}
          transform="translate(-129.696 -430.507)"
          d="M138.025,450.5a3.129,3.129,0,0,0,3.06-2.5h-6.121a3.129,3.129,0,0,0,3.06,2.5Zm0,0"
        />
        <path
          fill={color}
          transform="translate(0 -41.002)"
          d="M14.17,51h-.01a5.838,5.838,0,0,1-5.831-5.831A5.779,5.779,0,0,1,8.887,42.7c-.183-.017-.369-.028-.557-.028A5.831,5.831,0,0,0,2.5,48.5v2.322A5.585,5.585,0,0,1,.509,55.1a1.457,1.457,0,0,0-.458,1.5,1.528,1.528,0,0,0,1.5,1.069H15.106a1.527,1.527,0,0,0,1.523-1.155,1.46,1.46,0,0,0-.5-1.426A5.543,5.543,0,0,1,14.17,51Zm0,0"
        />
        <path
          fill={color}
          transform="translate(-246.017)"
          d="M264.342,4.165A4.165,4.165,0,1,1,260.177,0,4.165,4.165,0,0,1,264.342,4.165Zm0,0"
        />
      </g>
    </svg>
  );
}
