import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function TransferIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.627 17.991"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 -1)">
        <path
          fill={color}
          transform="translate(-0.547)"
          d="M19.56,12.449a.614.614,0,0,1-.613-.613,4.3,4.3,0,0,0-4.293-4.293H8.724V10.2a.614.614,0,0,1-1.037.443L3.19,6.35a.613.613,0,0,1,0-.886l4.5-4.293a.614.614,0,0,1,1.037.443V4.271h5.111a6.345,6.345,0,0,1,6.338,6.338v1.227A.614.614,0,0,1,19.56,12.449Z"
        />
        <path
          fill={color}
          transform="translate(0 -1.458)"
          d="M11.449,19.836V17.178H6.338A6.345,6.345,0,0,1,0,10.84V9.613a.613.613,0,0,1,1.227,0A4.3,4.3,0,0,0,5.52,13.907h5.929V11.249a.614.614,0,0,1,1.037-.443l4.5,4.293a.613.613,0,0,1,0,.886l-4.5,4.293A.614.614,0,0,1,11.449,19.836Z"
        />
      </g>
    </svg>
  );
}
