import React from "react";
import { Form, Formik } from "formik";

import { Button, ButtonColor } from "../ui/Button";
import { CreateNewDocumentRoles, Direction, SizeType } from "../../api/dto/AppDTO";
import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { DatePickerField } from "../form/DatePickerField";
import { RadioGroupField } from "../form/RadioGroupField";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { flagToHugeTurnover } from "../../reducers/authReducer";

interface Props {
  readonly initialValues: any;
  readonly onSubmit: (values) => void;
  readonly accounts: [{ label: ""; value: "" }];
  readonly onChangeFromDate: (value) => void;
  readonly onChangeAccountNumber: (value) => void;
}

function CorporateStatementFilterFormLoc({
  onSubmit,
  initialValues,
  accounts,
  onChangeFromDate,
  onChangeAccountNumber,
}: Props) {
  const flagToHugeType = useShallowEqualSelector(flagToHugeTurnover);
  const futureDate = () => {
    const maxDate = flagToHugeType === CreateNewDocumentRoles.Yes ? 1 : 12;
    const futureDate = new Date(initialValues.fromDate);
    futureDate.setMonth(futureDate.getMonth() + maxDate);
    return futureDate;
  };
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize={true}>
      {() => (
        <Form className="d-flex flex-column p-7 mb-3 bg-white border border-gray-light br-1">
          <div className="row d-flex">
            <div className="col-lg-4">
              <SelectPickerField
                name="accountNumber"
                options={accounts}
                label={"ACCOUNTS_STATEMENT_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE"}
                onChanges={(value) => onChangeAccountNumber(value)}
              />
            </div>
            <div className="col-lg-4 mt-lg-0 mt-3">
              <InputField
                name="searchAmount"
                label="ACCOUNTS_STATEMENT_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE"
              />
            </div>
            <div className="col-lg-2 mt-lg-0 mt-4">
              <RadioGroupField
                name="status"
                direction={Direction.Vertical}
                items={[
                  {
                    value: "A",
                    title: "ACCOUNTS_STATEMENT_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE",
                  },
                  {
                    value: "D",
                    title: "ACCOUNTS_STATEMENT_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE",
                  },
                  {
                    value: "C",
                    title: "ACCOUNTS_STATEMENT_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE",
                  },
                ]}
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-lg-2">
              <DatePickerField
                name="fromDate"
                label={"ACCOUNTS_STATEMENT_FILTER_FORM_FROM_FIELD_LABEL_TITLE"}
                onChange={(value) => onChangeFromDate(value)}
              />
            </div>
            <div className="col-lg-2 my-lg-0 my-3">
              <DatePickerField
                name="toDate"
                label={"ACCOUNTS_STATEMENT_FILTER_FORM_TO_FIELD_LABEL_TITLE"}
                minDate={initialValues.fromDate}
                maxDate={futureDate()}
              />
            </div>
            <div className="col-lg-4">
              <InputField
                name="corrAcc"
                label={"ACCOUNTS_STATEMENT_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE"}
              />
            </div>
            <div className="col-4">
              <Button
                type="submit"
                className="mt-5"
                size={SizeType.Medium}
                labelCode="ACCOUNTS_STATEMENT_FILTER_FORM_GENERATE_BUTTON_TITLE"
                color={ButtonColor.Orange}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export const CorporateStatementFilterForm = React.memo(CorporateStatementFilterFormLoc);
