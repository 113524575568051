import React from "react";

interface Props {
  readonly status: string;
}

export function ConversionTypePicker({ status }: Props) {
  return (
    <span>
      {status === "FTB"
        ? "Selling FCY to bank"
        : status === "FTU"
        ? "Selling FCY to UzRCE"
        : status === "FTF"
        ? "FCY to FCY"
        : status === "LTF"
        ? "LCY to FCY"
        : "NaN"}
    </span>
  );
}
