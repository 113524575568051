import { update } from "immupdate";
import { useDispatch } from "react-redux";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";

import { PayrollTable } from "./PayrollTable";
import { useResource } from "../../hooks/useResource";
import { PayrollFilterForm } from "./PayrollFilterForm";
import { TransferType } from "../../api/transfer/TransferDTO";
import { formatPayrollQuery } from "../../helpers/SalaryHelpers";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { payrollListSelector, setPayrollList } from "../../reducers/salaryReducer";
import { SalaryInstructionItem } from "../salary/SalaryInstructionItem";
import { Paginator } from "../ui/Paginator";
import { TabPage } from "../tabs/TabPage";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { SalaryChooseTransitAccountForm } from "../salary/SalaryChooseTransitAccountForm";
import { Modal } from "../ui/Modal";
import { useI18n } from "../../i18n/I18nContext";
import { Button, ButtonColor } from "../ui/Button";
import { CheckOtpModal } from "../../components/salary/CheckOtpModal";
import { RegisterTableType } from "../../api/salary/SalaryDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { userLoginSelector } from "../../reducers/userReducer";
import { DeleteIcon } from "../icons/DeleteIcon";
import { ColorPalette } from "../../theme/ColorPalette";
import { showError } from "../../utils/NotificationUtils";
import { payrollListIdsSelector } from "../../reducers/payrollSelectedIds";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { CorporateChooseDebitAccountForm } from "../transfers/CorporateChooseDebitAccountForm";
import { CorporateChooseTransitAccountForm } from "../../containers/corporate/CorporateChooseTransitAccountForm";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";

import { SalaryChooseDebitAccountForm } from "../salary/SalaryChooseDebitAccountForm";

interface Props {
  readonly transferType: TransferType;
  readonly transferFilter: TransfersFilter;
  readonly onCreateClick: () => void;
  readonly onRegistrationClick: () => void;
  readonly onGetListOfCardHoldersClick: () => void;
  readonly transitAccount: unknown;
  readonly debitAccount: unknown;
  readonly setTransitAccount: (value) => void;
  readonly setDebitAccount: (value) => void;
  readonly readFromExcel: (value) => void;
  readonly chooseExistingRegister?: (value, type) => void;
  readonly payrollTable: boolean;
  readonly setPayrollTable: (value) => void;
  readonly setRegisterInfo?: (value) => void;
}

// export const PayrollTableWrapper = memo(function ({
export function PayrollTableWrapper({
  transferType,
  transferFilter,
  onCreateClick,
  onGetListOfCardHoldersClick,
  onRegistrationClick,
  transitAccount,
  debitAccount,
  setTransitAccount,
  setDebitAccount,
  readFromExcel,
  chooseExistingRegister,
  payrollTable,
  setPayrollTable,
  setRegisterInfo,
}: Props) {
  const dispatch = useDispatch();
  const { AccountApi } = useAccountContext();
  const { translate } = useI18n();

  const locationHelpers = useLocationHelpers();

  const [showCreatorsAndApprovals, setShowCreatorsAndApprovals] = useState(false);
  const [filter, setFilter] = useState({
    toDate: new Date(),
    fromDate: new Date(),
    transferType,
    pageSize: transferFilter.perPage,
    pageNumber: transferFilter.page,
  });

  const list = useShallowEqualSelector(payrollListSelector(filter));
  // const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  const [documentsId, setDocumentsId] = useState<any>([]);
  const [checkOtp, setCheckOtp] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [appTypes, setAppTypes] = useState("");
  const chooseAccountType = useMemo(() => transferFilter.getChooseAccountType(), [transferFilter]);
  const chooseAccountTypeSalary = useMemo(() => transferFilter.getChooseAccountTypeSalary(), [transferFilter]);

  const [accountTypes] = useState([
    {
      label: translate("TRANSFERS_CORPORATE_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE"),
      value: "transit",
    },
    {
      label: translate("TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE"),
      value: "debit",
    },
  ]);

  const [accountTypesSalary] = useState([
    {
      label: translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE"),
      value: "salaryTransit",
    },
    {
      label: translate("TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE"),
      value: "salaryDebit",
    },
  ]);

  const { SalaryApi } = useSalaryContext();

  const listResource = useResource(() => SalaryApi.getPayrollList(formatPayrollQuery(filter)), [
    filter,
    transferType,
  ]);

  const onRegisterNewClick = useCallback(() => {
    SalaryApi.getTransitAccountNumbers({ transferType }).then((r) => {
      if (r.length > 0) {
        onRegistrationClick();
      } else {
        NotificationManager.error(
          translate("SALARY_NO_TRANSIT_ACCOUNT"),
          translate("SALARY_NO_TRANSIT_ACCOUNT_TITLE"),
          5000,
        );
      }
    });
  }, [SalaryApi, onRegistrationClick, transferType, translate]);

  useResourceHandler(listResource, {
    onSuccess: (data = []) => {
      dispatch(setPayrollList({ list: data, filter }));
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  // const data = useMemo(() => listResource.data || [], [listResource.data]);

  // new one start
  const payrollListIds = useShallowEqualSelector(payrollListIdsSelector);
  const login = useShallowEqualSelector(userLoginSelector);
  const [checkIfUserCreater, setCheckIfUserCreater] = useState<boolean[]>([]);
  const [checkPayrollRender, setCheckPayrollRender] = useState("{}");

  const setTableDatas = useCallback(() => {
    const tempDocumentsId: any = [];
    list.map((n, index) => {
      if (payrollListIds && payrollListIds[index] && n) {
        tempDocumentsId.push(n);
      }
    });
    setAppTypes(transferType);
    setDocumentsId(tempDocumentsId);
    setCheckPayrollRender(JSON.stringify(payrollListIds));
    const tempChecks = tempDocumentsId.map(
      (r) => r.createUser.includes(login) && r.payrollStatus === "101",
    );
    setCheckIfUserCreater(tempDocumentsId.length > 0 && tempChecks?.includes(false));
    // if (tempChecks?.includes(false) && tempChecks.length > 0) {
    //   NotificationManager.error(translate("NOTIFICATION_ON_STATUS_FOR_CREATER"));
    // }
  }, [list, payrollListIds, login, transferType]);

  useEffect(() => {
    if (checkPayrollRender !== JSON.stringify(payrollListIds)) {
      setTableDatas();
    }
  }, [payrollListIds, setTableDatas, checkPayrollRender]);

  const onDoneJob = useCallback(
    (value) => {
      setLoadingDoc(true);
      const data = {
        approvalType: appTypes,
        // state: job,
        state: "106",
        otpKey: value.token,
        rowsCount: documentsId.length.toString(),
      };
      AccountApi.approveOrRejectDoc({
        documentIds: documentsId.map((x) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          x.id ? x.id : x.documentId ? x.documentId : x.payrollId ? x.payrollId : x.accountOpenId,
        ),
        ...data,
      })
        .then((r) => {
          if (r.otpCheckFlag === "02") {
            NotificationManager.error(translate("WRONG_OTP_KEY"), "OTP", 5000);
          } else if (r.status.filter((x) => x === "EXPIRED").length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_EXPIRED_ERROR"), "", 5000);
            setCheckOtp(false);
          } else if (r.status.filter((x) => x === null).length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_NULL_ERROR"), "", 5000);
            setCheckOtp(false);
          } else {
            location.reload();
          }
          setLoadingDoc(false);
        })
        .catch((err) => {
          showError(err);
          setLoadingDoc(false);
        });
    },
    [AccountApi, appTypes, documentsId, translate],
  );

  // new one end

  // old loader
  // const loading = useMemo(() => (!listResource || listResource.loading) && list.length === 0, [
  //   list,
  //   listResource,
  // ]);

  const loading = useMemo(() => {
    return !listResource || listResource.loading || (list.length === 0 && !listResource.data);
  }, [listResource, list]);


  const [excel, setExcel] = useState([]);
  const [agreeFirst, setAgreeFirst] = useState(false);
  const [agreeType, setAgreeType] = useState("");

  const onCheckConfirmation = useCallback((type, value) => {
    setAgreeType(type);
    setAgreeFirst(true);
    if (type === "excel") {
      setExcel(value);
    }
  }, []);

  const onConfirmExcel = useCallback(
    (value) => {
      setAgreeFirst(false);
      readFromExcel(value);
    },
    [readFromExcel],
  );

  const onConfirmCreate = useCallback(() => {
    setAgreeFirst(false);
    onCreateClick();
  }, [onCreateClick]);

  const onConfirmExisting = useCallback(() => {
    setAgreeFirst(false);
    setPayrollTable(true);
  }, [setPayrollTable]);

  const onChangeChooseRegister = useCallback(
    (value: any, type: string) => {
      const register = list?.filter((r) => r.payrollId === value)[0];
      chooseExistingRegister && chooseExistingRegister(value, type);
      setRegisterInfo && setRegisterInfo(register);
    },
    [chooseExistingRegister, list, setRegisterInfo],
  );

  const submitFromDebitAccount = useCallback(
    (value) => {
      SalaryApi.getPayrollIdForDebitAccount()
        .then((payrollId) => {
          const json = {
            accounts : [
              {
                accountName: value.accountName,
                accountNumber: value.accountOfCard.label,
                amount: value.amount.replace(/\s/g, ""),
              }
            ],
            corporateCard: value.debitAccount.label,
            payrollDetail: value.detailsType,
            payrollId: payrollId,
            rowsCount: "1",
            transitAccountNumber: ""
          };
          AccountApi.createDocumentWithDebitAccount({ json })
            .then(() => {
              NotificationManager.info("Document Created!");
              window.location.reload();
            })
            .catch(showError);
        })
        .catch(showError);
    },
    [AccountApi, SalaryApi],
  );

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      pageSize: transferFilter.perPage,
      pageNumber: transferFilter.page,
    }));
  }, [transferFilter]);
  
  return (
    <TabPage
      className="px-7"
      contentClassName="bg-transparent border-0"
      footerComponent={
        <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-between">
          <div className="d-flex align-items-center mr-5">
            <Button
              // style={{ display: "none" }}
              color={documentsId.length > 0 ? ButtonColor.Danger : ButtonColor.Default}
              disabled={documentsId.length <= 0}
              onClick={() => {
                if (documentsId.length > 0) {
                  if (!checkIfUserCreater) {
                    setCheckOtp(true);
                  } else if (checkIfUserCreater) {
                    NotificationManager.error(translate("NOTIFICATION_ON_STATUS_FOR_CREATER"));
                  }
                } else {
                  NotificationManager.error(
                    translate("PENDING_APPROVAL_DOCUMENT_CHOOSE"),
                    translate("SIDE_MENU_PENDING_APPROVALS_TITLE"),
                  );
                }
              }}
            >
              <DeleteIcon color={documentsId.length > 0 ? ColorPalette.White : ColorPalette.Dark} />
              {translate("PENDING_APPROVAL_REJECT")}
            </Button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <Paginator filter={transferFilter} />
            </div>
          </div>
        </div>
      }
    >
      <div className="d-flex justify-content-between flex-lg-row flex-column">
        <SalaryInstructionItem
          filter={transferFilter}
          transferType={transferType}
          onCreateClick={onCreateClick}
          onRegistrationClick={onRegisterNewClick}
          onGetListOfCardHoldersClick={onGetListOfCardHoldersClick}
        />
          {transferType === "SL" && (
            <>
              {chooseAccountTypeSalary === "salaryTransit" && (
                <SalaryChooseTransitAccountForm
                  onChangeAccountType={(value) => {
                    setTransitAccount((prev) => update(prev, { accountType: value }));
                    setDebitAccount((prev) => update(prev, { accountType: value }));
                    locationHelpers.replaceQuery({ chooseAccountTypeSalary: value.value });
                  }}
                  accountTypes={accountTypesSalary}
                  onCreateClick={onCheckConfirmation}
                  readFromExcel={onCheckConfirmation}
                  initialValues={transitAccount}
                  setInitialValues={setTransitAccount}
                  transferType={transferType}
                  getChooseExistingRegister={onCheckConfirmation}
                />
              )}
              {chooseAccountTypeSalary === "salaryDebit" && (
                <SalaryChooseDebitAccountForm
                  onChangeAccountType={(value) => {
                    setDebitAccount((prev) => update(prev, { accountType: value }));
                    setTransitAccount((prev) => update(prev, { accountType: value }));
                    locationHelpers.replaceQuery({ chooseAccountTypeSalary: value.value });
                  }}
                  submit={submitFromDebitAccount}
                  accountTypes={accountTypesSalary}
                  setInitialValues={setTransitAccount}
                  initialValues={transitAccount}
                  transferType={transferType}
                  onCreateClick={onCheckConfirmation}
                  readFromExcel={onCheckConfirmation}
                  getChooseExistingRegister={onCheckConfirmation}
                />
              )}
            </>
          )}
          {transferType === "CC" && (
            <>
              {chooseAccountType === "debit" && (
                <CorporateChooseDebitAccountForm
                  onChangeAccountType={(value) => {
                    setDebitAccount((prev) => update(prev, { accountType: value }));
                    setTransitAccount((prev) => update(prev, { accountType: value }));
                    locationHelpers.replaceQuery({ chooseAccountType: value.value });
                  }}
                  submit={submitFromDebitAccount}
                  accountTypes={accountTypes}
                  setInitialValues={setDebitAccount}
                  initialValues={debitAccount}
                  transferType={transferType}
                />
              )}
              {chooseAccountType === "transit" && (
                <CorporateChooseTransitAccountForm
                  onChangeAccountType={(value) => {
                    setTransitAccount((prev) => update(prev, { accountType: value }));
                    setDebitAccount((prev) => update(prev, { accountType: value }));
                    locationHelpers.replaceQuery({ chooseAccountType: value.value });
                  }}
                  accountTypes={accountTypes}
                  setInitialValues={setTransitAccount}
                  initialValues={transitAccount}
                  onCreateClick={onCreateClick}
                  transferType={transferType}
                  readFromExcel={readFromExcel}
                  getChooseExistingRegister={() => setPayrollTable(true)}
                />
              )}
            </>
          )}
      </div>
      <PayrollFilterForm
        initialValues={filter}
        showCreatorsAndApprovals={showCreatorsAndApprovals}
        onChangeCreatorsAndApprovals={setShowCreatorsAndApprovals}
        onSubmit={(x) => setFilter((prev) => update(prev, { ...x, transferType }))}
      />
      <PayrollTable
        chooseExistingRegister={onChangeChooseRegister}
        withChoose={true}
        data={list}
        loading={loading}
        withCreatorsAndApprovals={showCreatorsAndApprovals}
        type={RegisterTableType.Table}
        // onSelect={setSelectedIds}
      />
      <Modal
        show={payrollTable}
        onClose={() => setPayrollTable(false)}
        title={"TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE"}
      >
        <PayrollFilterForm
          initialValues={filter}
          showCreatorsAndApprovals={showCreatorsAndApprovals}
          onChangeCreatorsAndApprovals={setShowCreatorsAndApprovals}
          onSubmit={(x) => setFilter((prev) => update(prev, { ...x, transferType }))}
        />
        <PayrollTable
          chooseExistingRegister={onChangeChooseRegister}
          withChoose={true}
          data={list}
          loading={loading}
          withCreatorsAndApprovals={showCreatorsAndApprovals}
          type={RegisterTableType.Existing}
          // onSelect={setSelectedIds}
        />
        <div className="mt-3">
          <Paginator filter={transferFilter} />
        </div>
      </Modal>

      <Modal
        onClose={() => setCheckOtp(false)}
        width={600}
        show={checkOtp}
        title={translate("REJECT_BUTTON")}
      >
        <TabPage>
          <CheckOtpModal onSubmit={onDoneJob} job={appTypes} loading={loadingDoc} />
        </TabPage>
      </Modal>

      <Modal
        show={agreeFirst}
        onClose={() => setAgreeFirst(false)}
        title="MODAL_CONFIRMATION_TITLE"
        width={500}
      >
        <div className="d-flex justify-content-center">
          <span className="my-4 mr-8">{translate("MODAL_INFO_SALARY")}</span>
        </div>
        <div className="d-flex justify-content-center">
          <Button className="bg-danger py-2 px-5 w-50 mr-8" onClick={() => setAgreeFirst(false)}>
            {translate("MODAL_CONFIRMATION_NO")}
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="bg-secondary py-2 px-5 w-50 mt-3 mr-8"
            onClick={() => {
              return agreeType === "new"
                ? onConfirmCreate()
                : agreeType === "excel"
                ? onConfirmExcel(excel)
                : onConfirmExisting();
            }}
          >
            {translate("MODAL_CONFIRMATION_YES")}
          </Button>
        </div>
      </Modal>
    </TabPage>
  );
}
