import React, { useCallback, useEffect, useMemo, useState, memo } from "react";

import { TabPage } from "../tabs/TabPage";
import { CreateNewDocumentRoles, Dict, SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";
import { TransferType } from "../../api/transfer/TransferDTO";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { PayrollTableWrapper } from "../payroll/PayrollTableWrapper";
import { SalaryListOfCardHoldersForm } from "../salary/SalaryListOfCardHoldersForm";
import { SalaryCreateNewRegisterTableForm } from "../salary/SalaryCreateNewRegisterTableForm";
import { CorporateCardListOfCardHoldersTableWrapper } from "../salary/CorporateCardListOfCardHoldersTableWrapper";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { useI18n } from "../../i18n/I18nContext";
// import { Paginator } from "../ui/Paginator";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { accessToCardReplenishment } from "../../reducers/authReducer";
import { useHistory } from "react-router";
import { Routes } from "../../constants/Routes";
import { AccountTabs } from "../../containers/corporate/CorporateAccountsContainer";
import { toFinite } from "lodash";
import { RegisterTableType, SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { Modal } from "../ui/Modal";
import { PayrollViewTable } from "../payroll/PayrollViewTable";
import { NotificationManager } from "react-notifications";
import { CorporateCardPrintTable } from "./CorporateCardPrintTable";
import { PrintPortal } from "../ui/PrintPortal";
import { printForm } from "../../utils/PintUtils";
import { createRegisterExcelFromTable } from "../../helpers/SalaryHelpers";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import { FileControls } from "../ui/FileControls";
import { createCardHoldersExcelFromTable } from "../../helpers/TransfersHelpers";
import { useResource } from "../../hooks/useResource";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";

let data = Array(2000).fill({
  id: "",
  accountNumber: "",
  systemNumber: "",
  fullName: "",
  amount: "",
  systemName: "",
  status: "",
});

interface Props {
  readonly filter: TransfersFilter;
  readonly onCreateClick: () => void;
  readonly onRegistrationClick: () => void;
  readonly onGetListOfCardHoldersClick: () => void;
}

export const CorporateCardTab = memo(function ({
  filter,
  onCreateClick,
  onRegistrationClick,
  onGetListOfCardHoldersClick,
}: Props) {
  const { translate } = useI18n();
  const { CorporateCardsApi } = useCorporateCardsContext();
  const locationHelpers = useLocationHelpers();
  const { SalaryApi } = useSalaryContext();
  const isCreate = useMemo(() => filter.getCreate(), [filter]);
  const isUploadFile = useMemo(() => filter.getUploadFile(), [filter]);
  const isRegisterSalary = useMemo(() => filter.getRegisterSalary(), [filter]);
  const isListOfCardHolders = useMemo(() => filter.getListOfCardHolders(), [filter]);
  const [count, setCount] = useState({ label: "10", value: "10" });
  const [payrollTable, setPayrollTable] = useState(false);
  const [viewTable, setViewTable] = useState(false);
  const [registerForPrint, setRegisterForPrint] = useState([{}]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  const [transitAccount, setTransitAccount] = useState({
    transitAccount: "",
    accountBalance: "",
    code: "00634",
    detailsType: `${translate("CORPORATE_CARD_REPLANISHMENT_TEXT")}`,
    number: "",
    date: "",
    type: "",
    accountType: {
      label: translate("TRANSFERS_CORPORATE_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE"),
      value: "transit",
    },
  });

  const [debitAccount, setDebitAccount] = useState({
    debitAccount: "",
    accountBalance: "",
    accountName: "",
    code: "00634",
    corporateCardNumber: "",
    amount: "",
    accountOfCorporateCard: "",
    detailsType: `${translate("CORPORATE_CARD_REPLANISHMENT_TEXT")}`,
    accountType: {
      label: translate("TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE"),
      value: "debit",
    },
  });
  const cardType = filter.getCardType().cardType;

  const onCreateRegister = useCallback(() => {
    data = Array(2000).fill({
      id: "",
      accountNumber: "",
      systemNumber: "",
      fullName: "",
      amount: "",
      systemName: "",
      status: "",
    });
    onCreateClick();
  }, [onCreateClick]);

  const readFromExcel = useCallback(
    (value) => {
      data = Array(2000).fill({
        id: "",
        accountNumber: "",
        systemNumber: "",
        fullName: "",
        amount: "",
        systemName: "",
        status: "",
      });
      value.shift();
      const list = value.map((x) => ({
        id: x?.A?.toString(),
        accountNumber: x?.B?.toString().substr(0, 20).trim(),
        systemNumber: "",
        fullName: x?.C?.toString().trim(),
        amount: x?.D?.toString(),
        systemName: "",
        status: "",
      }));
      data = [...list, ...data];
      locationHelpers.replaceQuery({ create: true });
      if (list.length < 101) {
        setCount({ label: "100", value: "100" });
      } else if (list.length < 501) {
        setCount({ label: "500", value: "500" });
      } else if (list.length < 1001) {
        setCount({ label: "1000", value: "1000" });
      } else if (list.length < 1501) {
        setCount({ label: "1500", value: "1500" });
      } else {
        setCount({ label: "2000", value: "2000" });
      }
    },
    [locationHelpers],
  );

  const chooseExistingRegister = useCallback(
    (value, type) => {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
      data = Array(2000).fill({
        id: "",
        accountNumber: "",
        systemNumber: "",
        fullName: "",
        amount: "",
        systemName: "",
        status: "",
        salaryStatus: "",
      });
      SalaryApi.getExistingRegister({
        transferType: TransferType.CorporateCard,
        pageNumber: 1,
        pageSize: 100,
        payrollId: value,
      }).then((res) => {
        let registerList = [{}];
        registerList.shift();
        // @ts-ignore
        for (let i = 0; i < res[0].totalPageCount; i++) {
          SalaryApi.getExistingRegister({
            transferType: TransferType.CorporateCard,
            pageNumber: i + 1,
            pageSize: 100,
            payrollId: value,
          })
            .then((r) => {
              const list = r.map((x) => ({
                id: (r.indexOf(x) + 1).toString(),
                fullName: x.cardName,
                accountNumber: x.cardAccount,
                amount: x.salaryAmount,
                systemNumber: "",
                systemName: "",
                status: "",
                salaryStatus: x.salaryStatus,
              }));
              registerList = [...registerList, ...list];
              if (
                registerList.length ===
                toFinite(
                  // @ts-ignore
                  res[0].totalRowCount,
                )
              ) {
                if (registerList.length < 101) {
                  setCount({ label: "100", value: "100" });
                } else if (registerList.length < 501) {
                  setCount({ label: "500", value: "500" });
                } else if (registerList.length < 1001) {
                  setCount({ label: "1000", value: "1000" });
                } else if (registerList.length < 1501) {
                  setCount({ label: "1500", value: "1500" });
                } else {
                  setCount({ label: "2000", value: "2000" });
                }
                data = [...registerList, ...data];
                if (type === RegisterTableType.Existing) {
                  locationHelpers.replaceQuery({ create: true });
                  setPayrollTable(false);
                } else {
                  setViewTable(true);
                }
              }
            })
            .catch(() => {
              SalaryApi.getExistingRegister({
                transferType: TransferType.CorporateCard,
                pageNumber: i + 1,
                pageSize: 100,
                payrollId: value,
              })
                .then((r) => {
                  const list = r.map((x) => ({
                    id: (r.indexOf(x) + 1).toString(),
                    fullName: x.cardName,
                    accountNumber: x.cardAccount,
                    amount: x.salaryAmount,
                    systemNumber: "",
                    systemName: "",
                    status: "",
                    salaryStatus: x.salaryStatus,
                  }));
                  registerList = [...registerList, ...list];
                  if (
                    registerList.length ===
                    toFinite(
                      // @ts-ignore
                      res[0].totalRowCount,
                    )
                  ) {
                    if (registerList.length < 101) {
                      setCount({ label: "100", value: "100" });
                    } else if (registerList.length < 501) {
                      setCount({ label: "500", value: "500" });
                    } else if (registerList.length < 1001) {
                      setCount({ label: "1000", value: "1000" });
                    } else if (registerList.length < 1501) {
                      setCount({ label: "1500", value: "1500" });
                    } else {
                      setCount({ label: "2000", value: "2000" });
                    }
                    data = [...registerList, ...data];
                    if (type === RegisterTableType.Existing) {
                      locationHelpers.replaceQuery({ create: true });
                      setPayrollTable(false);
                    } else {
                      setViewTable(true);
                    }
                  }
                })
                .catch(() =>
                  NotificationManager.error(
                    translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                    translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                  ),
                );
            });
        }
      });
    },
    [translate, SalaryApi, locationHelpers],
  );

  const printRegister = useCallback((value) => {
    setRegisterForPrint(value);
    setTimeout(
      () =>
        printForm({
          printable: "CorporateCardPrintTable",
          type: "html",
          targetStyles: ["*"],
        }),
      500,
    );
  }, []);

  const saveToExcel = useCallback((value) => {
    const workbook = createRegisterExcelFromTable(value, TransferType.CorporateCard);

    saveExcelToFile(workbook, "Corporate Register table").catch(showError);
  }, []);

  const accessToCard = useShallowEqualSelector(accessToCardReplenishment);
  const history = useHistory();

  useEffect(() => {
    if (accessToCard !== CreateNewDocumentRoles.Yes) {
      history.replace(`${Routes.CorporateAccountsRoute}/${AccountTabs.Accounts}`);
    }
  }, [accessToCard, history]);

  const chooseCardHolders = useCallback(() => {
    NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
    data = Array(2000).fill({
      id: "",
      accountNumber: "",
      systemNumber: "",
      fullName: "",
      amount: "",
      systemName: "",
      status: "",
    });

    const idx = [];
    loadIds(selectedIds, idx);
    function loadIds(obj, container) {
      for (const i in obj) {
        container.push(i);
        if (obj[i].constructor == Object) {
          loadIds(obj[i], container);
        }
      }
    }

    const chooseApi = TransferType.CorporateCard ? CorporateCardsApi : SalaryApi;

    chooseApi
      .getSalaryCardHolders({
        cardType: cardType,
        pageNumber: 1,
        pageSize: 100,
      })
      .then((r) => {
        if (r) {
          const result: SalaryCardHolders[] = [];
          for (let i = 0; i < idx.length; i++) {
            //@ts-ignore
            r.filter((x) => x.cardId == Number(idx[i]) && result.push(x));
          }

          const list = result.map((x) => ({
            accountNumber: x.accountNumber,
            id: x.cardId,
            systemNumber: "",
            fullName: x.employerName,
            amount: "",
            status: "",
            systemName: "",
          }));
          data = [...list, ...data];
          locationHelpers.replaceQuery({ create: true });
          if (list.length < 101) {
            setCount({ label: "100", value: "100" });
          } else if (list.length < 501) {
            setCount({ label: "500", value: "500" });
          } else if (list.length < 1001) {
            setCount({ label: "1000", value: "1000" });
          } else if (list.length < 1501) {
            setCount({ label: "1500", value: "1500" });
          } else {
            setCount({ label: "2000", value: "2000" });
          }
          setOpenModal(false);
        }
      })
      .catch(() => {
        NotificationManager.error(
          translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
          translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
        );
      });
  }, [translate, SalaryApi, locationHelpers, cardType, selectedIds, CorporateCardsApi]);

  const corporateListOfCardHolders = useResource(
    () =>
      CorporateCardsApi.getSalaryCardHolders({
        cardType: cardType,
        pageNumber: 1,
        pageSize: 4000,
      }),
    [filter],
  );
  const cardHoldersData = useMemo(() => corporateListOfCardHolders.data || [], [
    corporateListOfCardHolders.data,
  ]);

  return (
    <>
      {!(!isCreate && !isRegisterSalary && !isListOfCardHolders && !isUploadFile) && (
        <TabPage
          className="px-4"
          contentClassName="bg-transparent border-0"
          headerComponent={
            isListOfCardHolders ? (
              <SalaryListOfCardHoldersForm
                onChangeCardType={(value) =>
                  locationHelpers.replaceQuery({ cardType: value.value })
                }
                cardType={cardType}
                backButton={() => locationHelpers.replaceQuery({ listOfCardHolders: "" })}
              />
            ) : (
              isUploadFile && (
                <div className="d-flex justify-content-end pt-5">
                  <Button
                    labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
                    size={SizeType.Medium}
                    color={ButtonColor.Orange}
                  />
                </div>
              )
            )
          }
          footerComponent={
            isListOfCardHolders && (
              <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
                <div className="px-3">
                  <FileControls
                    isMinusTransferLcyType={true}
                    onSaveToExcelClick={() => {
                      // @ts-ignore
                      if (cardHoldersData.length > 0) {
                        NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
                        const workbook = createCardHoldersExcelFromTable(cardHoldersData, {
                          translate,
                        });
                        saveExcelToFile(workbook, "Statement table").catch(showError);
                      } else {
                        NotificationManager.error(translate("TABLE_EMPTY_STATE_TITLE"));
                      }
                    }}
                  />
                </div>
                {/* <Paginator filter={filter} /> */}
              </div>
            )
          }
        >
          {isCreate && (
            <SalaryCreateNewRegisterTableForm
              noTransitAccount={() => locationHelpers.replaceQuery({ create: "" })}
              transitAccount={transitAccount}
              initialValues={{ data }}
              transferType={TransferType.CorporateCard}
              count={count}
              setCount={setCount}
              filter={filter}
              cardType={cardType}
              openModal={openModal}
              setOpenModal={setOpenModal}
              chooseCardHolders={chooseCardHolders}
              setSelectIds={setSelectedIds}
            />
          )}
          {isListOfCardHolders && <CorporateCardListOfCardHoldersTableWrapper filter={filter} />}
        </TabPage>
      )}
      {Boolean(!isCreate && !isRegisterSalary && !isListOfCardHolders && !isUploadFile) && (
        <PayrollTableWrapper
          transferType={TransferType.CorporateCard}
          transitAccount={transitAccount}
          debitAccount={debitAccount}
          onCreateClick={onCreateRegister}
          setDebitAccount={setDebitAccount}
          setTransitAccount={setTransitAccount}
          onGetListOfCardHoldersClick={onGetListOfCardHoldersClick}
          onRegistrationClick={onRegistrationClick}
          transferFilter={filter}
          readFromExcel={readFromExcel}
          payrollTable={payrollTable}
          setPayrollTable={setPayrollTable}
          chooseExistingRegister={chooseExistingRegister}
        />
      )}
      <Modal
        show={viewTable}
        onClose={() => setViewTable(false)}
        title="TRANSFERS_CORPORATE_CARD_TAB_TITLE"
        width={1000}
      >
        <PayrollViewTable
          data={viewTable ? data.filter((x) => x.amount) : []}
          transferType={TransferType.CorporateCard}
          onPrint={printRegister}
          onSaveToExcel={saveToExcel}
        />
      </Modal>
      <PrintPortal>
        <CorporateCardPrintTable data={registerForPrint} />
      </PrintPortal>
    </>
  );
});

CorporateCardTab.displayName = "CorporateCardTab";
