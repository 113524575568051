import React from "react";
import "./assets/lcy-print-table-forms.scss";
import { Input } from "../ui/Input";
import { TextArea } from "../ui/TextArea";
import { useI18n } from "../../i18n/I18nContext";
import { PrintTableFormContainer } from "../print/PrintTableFormContainer";

export function TransferLcyStandardTablePrint() {
  const { translate } = useI18n();
  return (
    <PrintTableFormContainer id="TransferLcyStandardTablePrint">
      <div className="page-break">
        <div className="row">
          <div className="col">
            <div className="row" id="header-standard">
              <label className="pt-2" id="header-label-standard">
                {translate("TRANSFERS_LCY_PRINT_HEADER_TITTLE")}
              </label>
              <Input width={200} inputClassName="border-0 p-0" height={15} />
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_DATA_LABEL")}
              </label>
              <div className="input-label-large-standard">
                <Input width={800} height={2} inputClassName="border-0" />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-form-label ml-3" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL")}
              </label>
              <div className="input-label-large-standard">
                <Input width={800} height={2} inputClassName="border-0 " />
              </div>
            </div>
            <div className="d-flex mb-0">
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label " id="span-right-standard">
                  {translate("TRANSFERS_LCY_PRINT_DEBIT_LABEL")}
                </label>
                <div id="input-middle-standard">
                  <Input
                    name="currentBalancePrint"
                    width={300}
                    height={2}
                    inputClassName="border-0"
                  />
                </div>
              </div>
              <div className="form-group row mr-4 mb-0">
                <label className="col-sm-2 col-form-label ml-5" id="span-left-standard">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL")}
                </label>
                <div id="input-middle-right-standard">
                  <Input name="senderTaxIdPrint" width={300} height={2} inputClassName="border-0" />
                </div>
              </div>
            </div>
            <div className="d-flex mb-0 ">
              <div className="form-group row mb-2">
                <label className="col-sm-2 col-form-label" id="span-right-standard">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_NAME_LABEL")}
                </label>
                <div id="input-middle-standard">
                  <Input
                    className="input-height"
                    width={550}
                    height={2}
                    inputClassName="border-0"
                  />
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label" id="span-left-bank-code">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_CODE_LABEL")}
                </label>
                <div id="input-middle-right-bank-code">
                  <Input width={300} height={2} inputClassName="border-0" />
                </div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label mt-1" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_LABEL")}
              </label>
              <div className="input-label-large-standard">
                <Input width={800} height={2} inputClassName="border-0" />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-form-label ml-3" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL")}
              </label>
              <div className="input-label-large-standard">
                <Input width={800} height={2} inputClassName="border-0" />
              </div>
            </div>
            <div className="d-flex mb-0">
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label " id="span-right-standard">
                  {translate("TRANSFERS_LCY_PRINT_CREDIT_LABEL")}
                </label>
                <div id="input-middle-standard">
                  <Input
                    name="currentBalancePrint"
                    width={300}
                    height={2}
                    inputClassName="border-0"
                  />
                </div>
              </div>
              <div className="form-group row mr-4 mb-0">
                <label className="col-sm-2 col-form-label ml-5" id="span-left-standard">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL")}
                </label>
                <div id="input-middle-right-standard">
                  <Input name="senderTaxIdPrint" width={300} height={2} inputClassName="border-0" />
                </div>
              </div>
            </div>
            <div className="d-flex mb-0 ">
              <div className="form-group row mb-3">
                <label className="col-sm-2 col-form-label" id="span-right-standard">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_NAME_LABEL")}
                </label>
                <div id="input-middle-standard">
                  <Input
                    className="input-height"
                    width={550}
                    height={2}
                    inputClassName="border-0"
                  />
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label" id="span-left-bank-code">
                  {translate("TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_LABEL")}
                </label>
                <div id="input-middle-right-bank-code">
                  <Input width={300} height={2} inputClassName="border-0" />
                </div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL")}
              </label>
              <div className="input-label-large-standard pt-2">
                <Input width={800} height={2} inputClassName="border-0" />
              </div>
            </div>
            {/* <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
              </label>
              <div className="input-label-large-standard from-group row flex-xl-nowrap">
                <Input width={100} height={2} inputClassName="border-0" />
                // <Input width={900} height={4} inputClassName="border-0" />
                <TextArea width={900} inputClassName="border-0" />
              </div>
            </div> */}

            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label ml-3 pr-8 label-left-pad" id="span">
                <span>
                  {translate("TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE")}
                </span>
              </label>
              <div className="input-label-large1 col">
                {/* <Input width={300} height={40} /> */}
                <Input width={100} inputClassName="border-0" />
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-sm-2 col-form-label ml-3 pr-8 label-left-pad" id="span">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
              </label>
              <div className="input-label-large1 col">
                <TextArea width={900} inputClassName="paddingTop border-0" />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" id="span-standard">
                {translate("TRANSFERS_LCY_PRINT_STATUS_LABEL")}
              </label>
              <div id="input-middle-standard">
                <Input
                  name="currentBalancePrint"
                  width={500}
                  height={2}
                  inputClassName="border-0"
                />
              </div>
            </div>

            {/* start */}
            {/* <div className="form-group row">
              <label className="col-sm-2 col-form-label" id="span-standard">
                AuthorizerOtpKey
              </label>
              <div id="input-middle-standard">
                <Input name="authorizerOtpKey" width={500} height={2} inputClassName="border-0" />
              </div>
            </div> */}
            {/* end */}

            <div className="form-group row ml-3 mb-0">
              <div className="form-group row mb-0">
                <label id="span-manager-standard">
                  {translate("TRANSFERS_LCY_PRINT_MANAGER_LABEL")}
                </label>
                <Input
                  className="input-manager pt-1"
                  inputClassName="border-right-0 border-left-0 border-top-0 "
                  height={2}
                />
              </div>
              <div className="form-group row ml-7 mb-0">
                <label id="span-general-standard">
                  {translate("TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL")}
                </label>
                <Input
                  className="input-manager pt-1"
                  inputClassName="border-right-0 border-left-0 border-top-0"
                  height={2}
                />
              </div>
            </div>
            <div className="form-group row flex-xl-nowrap ml-1 mb-2">
              <Input
                inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                width={400}
                height={2}
                label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
              />
              <Input
                inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                width={800}
                height={2}
                label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
              />
            </div>
            <div className="form-group row flex-xl-nowrap ml-5 mb-1">
              <Input
                inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                label={translate("TRANSFERS_LCY_BANK_MP_LABEL")}
                width={150}
                height={2}
              />
              <Input
                className="ml-8"
                inputClassName="border-right-0 border-left-0 border-top-0 p-0 pl-3"
                label={translate("TRANSFERS_LCY_CHECKED_LABEL")}
                width={600}
                height={2}
              />
              <div className="ml-5">
                <label>{translate("TRANSFERS_LCY_APPROVED_LABEL")}</label>
              </div>
            </div>
            <div className="form-group row flex-xl-nowrap ml-1 mb-0">
              <Input
                inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                width={400}
                height={2}
                label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
              />
              <Input
                inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                width={800}
                height={2}
                label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
              />
            </div>
          </div>
        </div>
      </div>
    </PrintTableFormContainer>
  );
}
