import React, { Children, ReactElement } from "react";
import "./assets/mobile-menu.scss";

import { MobileMenuItemProps } from "./MobileMenuItem";
import MenuItemIcon from "./MenuItemIcon";

interface AppMobileMenuProps {
  readonly activeTab: string | undefined | null;
  readonly children: ReactElement<MobileMenuItemProps>[] | ReactElement<MobileMenuItemProps>;
  readonly onChangeTab: (tab: string, isResContent: boolean) => void;
  readonly className?: string;
}
export default function AppMobileMenu({
  children,
  onChangeTab,
  activeTab,
  className,
}: AppMobileMenuProps) {
  return (
    <>
      <div className={`menu-item-list-container ${className}`}>
        {Children.map(children, (child) => {
          if (child.props.show === false) {
            return null;
          }
          return (
            <div className="item-container">
              <div
                className={`item-header py-2 px-3 mt-1 ${
                  activeTab == child.key ? "active-item" : ""
                }`}
                onClick={() =>
                  onChangeTab(child.key as string, child.props.responsiveContent ? true : false)
                }
              >
                <div className="item-title">
                  {child.props.icon && <span className="pr-2">{child.props.icon}</span>}
                  <span>{child.props.children}</span>
                </div>

                {child.props.responsiveContent && (
                  <span>
                    <MenuItemIcon isActive={activeTab == child.key} type="for-item" size={8} />
                  </span>
                )}
              </div>
              {child.props.responsiveContent && activeTab == child.key && (
                <div className="responsive-content-box pl-5">
                  <div className="responsive-content">{child.props.responsiveContent}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
