import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function LogoutIcon({ size = 20, color = ColorPalette.BlueExtraLight, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20.089 20.091"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-0.014)">
        <g transform="translate(0.014)">
          <g transform="translate(0)">
            <path
              fill={color}
              transform="translate(-286.961 -143.479)"
              d="M306.987,152.367a.846.846,0,0,0-.182-.273l-2.51-2.511a.837.837,0,1,0-1.184,1.184l1.082,1.082h-4.676a.837.837,0,1,0,0,1.674h4.676l-1.082,1.082a.837.837,0,1,0,1.184,1.184l2.51-2.511a.828.828,0,0,0,.182-.273A.838.838,0,0,0,306.987,152.367Z"
            />
            <path
              fill={color}
              transform="translate(-0.014)"
              d="M14.245,11.719a.837.837,0,0,0-.837.837v4.186H10.059V3.348a.838.838,0,0,0-.6-.8L6.555,1.674h6.853V5.86a.837.837,0,0,0,1.674,0V.837A.837.837,0,0,0,14.245,0H.851A.746.746,0,0,0,.765.016.827.827,0,0,0,.41.14C.392.152.369.152.351.166S.342.18.335.185A.829.829,0,0,0,.113.45.684.684,0,0,0,.089.52a.794.794,0,0,0-.066.2.521.521,0,0,0,0,.073C.025.805.014.82.014.837V17.579a.836.836,0,0,0,.673.82l8.371,1.674a.779.779,0,0,0,.164.017.838.838,0,0,0,.837-.837v-.837h4.186a.837.837,0,0,0,.837-.837V12.557A.837.837,0,0,0,14.245,11.719Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
