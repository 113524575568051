import { I18nSchema } from "./I18nSchema";

export const I18nEnglish: I18nSchema = {
  ENGLISH_LANGUAGE: "English",
  RUSSIAN_LANGUAGE: "Russian",
  UZBEK_LANGUAGE: "Uzbek",
  SHORT_EN_LANGUAGE: "EN",
  SHORT_RU_LANGUAGE: "RU",
  SHORT_UZ_LANGUAGE: "UZ",

  //Months
  MONTH_MAY_TITLE: "May",
  MONTH_JUN_TITLE: "June",
  MONTH_JULY_TITLE: "July",
  MONTH_MARCH_TITLE: "March",
  MONTH_APRIL_TITLE: "April",
  MONTH_AUGUST_TITLE: "August",
  MONTH_JANUARY_TITLE: "January",
  MONTH_OCTOBER_TITLE: "October",
  MONTH_FEBRUARY_TITLE: "February",
  MONTH_NOVEMBER_TITLE: "November",
  MONTH_DECEMBER_TITLE: "December",
  MONTH_SEPTEMBER_TITLE: "September",

  // Forms
  FORMS_REQUIRED_FIELD_ERROR_TITLE: "Required",
  FORMS_PASSWORDS_NOT_MUCH_FIELD_ERROR_TITLE: "Passwords don't match",
  WRONG_CURRENCY_TYPE: "Wrong currency type",

  // Auth Route
  AUTH_TAB_CORPORATE_TITLE: "Corporate Client",
  AUTH_TAB_INDIVIDUAL_TITLE: "Individual Client",
  AUTH_TAB_FINANCIAL_TITLE: "Financial Institution",

  AUTH_USER_ID_TITLE: "Username",
  AUTH_LOGIN_TITLE: "Login",
  AUTH_BACK_BUTTON_TITLE: "Back",
  AUTH_PASSWORD_TITLE: "Password",
  AUTH_LOGIN_BUTTON_TITLE: "Login",
  AUTH_LOGO_TITLE: "KDB Bank Uzbekistan",
  AUTH_LOGO_SUB_TITLE: "Internet Banking iDBA",
  AUTH_FORM_LINK_TITLE: "Internet Banking Public Offer",
  AUTH_SECURITY_TITLE:
    "Do not respond to emails that ask for your account or password information and don’t follow the links they suggest.",
  AUTH_TOKEN_FIELD_LABEL_TITLE: "E-Token",
  AUTH_CHANGE_PASSWORD_TITLE: "Please, change your password for security purposes",
  AUTH_RESET_PASSWORD_BUTTON_TITLE: "Reset Password",
  AUTH_FORGOT_PASSWORD_LINK_TITLE: "Forgot Password",
  AUTH_CHANGE_PASSWORD_BUTTON_TITLE: "Change Password",
  AUTH_RESET_FORM_ENTER_LOGIN_FIELD_LABEL_TITLE: "Registered Email Address",
  AUTH_INTERNET_BANKING_LINK_TITLE: "Internet Banking Public Offer",
  AUTH_VIEW_ONLY_CHECKBOX_TITLE: "For view only (without OTP key)",
  AUTH_VIEW_ONLY_WITH_CHECKBOX_TITLE: "To transfer funds in foreign currency using the OTP key",
  AUTH_CHANGE_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "New Password",
  AUTH_CHANGE_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Current Password",
  AUTH_RESET_PASSWORD_TITLE: "Fill in following spaces to receive temporary password",
  AUTH_CHANGE_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Confirm New Password",
  AUTH_RESET_FORM_CHECK_OTP_KEY_FIELD_LABEL_TITLE: "Check OTP key",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_1:
    "Your Username and Password are strictly personal and you should not disclose them to anyone.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_2:
    "Do not respond to emails that ask for your account or password information and don’t follow the links they suggest.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_3:
    "Keep checking your accounts regularly, if you see any discrepancies in the amount inform the Bank immediately.",

  OTP_EXPIRED_SESSION_SCREEN_TITLE: "Session Expired",

  WRONG_OTP_KEY: "OTP Authorize failed (try again after 1 minute)",

  // Side Menu
  SIDE_MENU_MAIN_TITLE: "Main",
  SIDE_MENU_LOAN_TITLE: "Loan",
  SIDE_MENU_YEISVO_TITLE: "YEISVO",
  SIDE_MENU_ACCOUNTS_TITLE: "Account Administration",
  SIDE_MENU_TRANSFERS_TITLE: "Transfers",
  SIDE_MENU_ANALYTICS_TITLE: "Analytics",
  SIDE_MENU_CONVERSION_TITLE: "Conversion",
  SIDE_MENU_CONTACT_US_TITLE: "Contact Us",
  SIDE_MENU_AGREEMENTS_TITLE: "Agreements & Forms",
  SIDE_MENU_REFERENCE_TITLE: "Reference",
  SIDE_MENU_HELP_AND_FAQ_TITLE: "Help & FAQ",
  SIDE_MENU_NOTIFICATIONS_TITLE: "Notifications",
  SIDE_MENU_CLIENT_FEEDBACK_TITLE: "Client Feedback",
  //Yangi title
  SIDE_MENU_TRADE_FINANCE_TITLE: "Trade Finance",

  SIDE_MENU_WITHDRAWAL_TITLE: "Withdrawal of Loan Funds",
  SIDE_MENU_PENDING_APPROVALS_TITLE: "Pending Approvals",
  SIDE_MENU_USER_INFO_AND_SETTINGS_TITLE: "User Info. & Settings",
  SIDE_MENU_PRE_APPROVAL_TITLE: "Pre-Approval of New Loan & L/C Application",
  SIDE_MENU_REQUESTS_TITLE:
    "Requests for Registration of Individual Loan & L/C Application in NIKI System",
  SIDE_MENU_PAYMENT_CLAIM_TITLE: "Payment Claim/",
  SIDE_MENU_COLLECTION_ORDER_TITLE: "Collection Order",

  SIDE_MENU_EXPAND_BUTTON_TITLE: "Expand",
  SIDE_MENU_COLLAPSE_BUTTON_TITLE: "Collapse",

  // Pending Approvals Page
  PENDING_APPROVALS_SALARY_TAB_TITLE: "Salary",
  PENDING_APPROVALS_ACCOUNTS_TAB_TITLE: "Accounts",
  PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE: "LCY Transfers",
  PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE: "FCY Transfers",
  PENDING_APPROVALS_CONVERSION_TAB_TITLE: "Conversion",
  PENDING_APPROVALS_CORPORATE_CARDS_TAB_TITLE: "Corporate Cards",
  PENDING_APPROVALS_SENT_PAYMENT_CLAIMS_TAB_TITLE: "Sent Payment Claims",
  PENDING_APPROVALS_TRADE_FINANCE_TAB_TITLE: "Trade Finance",

  PENDING_APPROVALS_SELECT_REQUEST_TITLE: "Select Request",
  PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE: "Show Creator and Authorizer",

  PENDING_APPROVALS_ACCOUNT_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBJECT_COLUMN_TITLE: "Subject",
  PENDING_APPROVALS_ACCOUNT_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  PENDING_APPROVALS_ACCOUNT_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation",
  PENDING_APPROVALS_ACCOUNT_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  PENDING_APPROVALS_ACCOUNT_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Completed Date",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBMISSION_DATE_COLUMN_TITLE: "Submission Date",

  PENDING_APPROVALS_ACCOUNT_NONE_STANDART_INQUIRIES: "Non-Standard Inquiries",
  PENDING_APPROVALS_ACCOUNT_STANDART_INQUIRIES: "Standard Inquiries",
  PENDING_APPROVALS_ACCOUNT_NEW_ACCOUNT_OPENING: "New Account Opening",

  PENDING_APPROVALS_APPLICATION_OPENING: "Application",
  PENDING_APPROVALS_AMENDMENT_OPENING: "Amendment",
  PENDING_APPROVALS_DISCREPANCY_OPENING: "Discrepancy",

  PENDING_APPROVALS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  PENDING_APPROVALS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Maximum Rate",
  PENDING_APPROVALS_TRANSFERS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  PENDING_APPROVALS_TRANSFERS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Debit Account",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Buying Amount",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Exchange Rate",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_CDU_RATE_COLUMN_TITLE: "UZS",
  PENDING_APPROVALS_TRANSFERS_TABLE_CREDIT_AMOUNT_COLUMN_TITLE: "Credit Account",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Selling Amount",
  PENDING_APPROVALS_TRANSFERS_TABLE_MINIMUM_RATE_CODE_COLUMN_TITLE: "Minimum Rate",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_CODE_COLUMN_TITLE: "Maximum Rate",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Buying Currency",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Selling Currency",
  PENDING_APPROVALS_TRANSFERS_TABLE_COMMISSION_AMOUNT_COLUMN_TITLE: "Commission Amount",

  PENDING_APPROVALS_SALARY_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  PENDING_APPROVALS_SALARY_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_SALARY_TABLE_DETAILS_COLUMN_TITLE: "Details",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_COLUMN_TITLE: "Register",
  PENDING_APPROVALS_SALARY_TABLE_CARD_TYPE_COLUMN_TITLE: "Card Type",
  PENDING_APPROVALS_SALARY_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  PENDING_APPROVALS_SALARY_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  PENDING_APPROVALS_SALARY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Payment Code",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  PENDING_APPROVALS_SALARY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Transit Account",
  PENDING_APPROVALS_SALARY_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Transaction Date",
  PENDING_APPROVALS_SALARY_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: " Created Date",

  TRANSFERS_SALARY_PAYROLL_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_SALARY_PAYROLL_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_SALARY_PAYROLL_TABLE_DETAILS_COLUMN_TITLE: "Details",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_COLUMN_TITLE: "Register",
  TRANSFERS_SALARY_PAYROLL_TABLE_CARD_TYPE_COLUMN_TITLE: "Card Type",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  TRANSFERS_SALARY_PAYROLL_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  TRANSFERS_SALARY_PAYROLL_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Payroll ID",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Transit Account",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Transaction Date",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Created Date",

  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_DETAILS_COLUMN_TITLE: "Details",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_COLUMN_TITLE: "Register",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CARD_TYPE_COLUMN_TITLE: "Card Type",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Payment Code",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Transit Account",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Transaction Date",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Created Date",

  PENDING_APPROVALS_CONVERSION_TABLE_TYPE_COLUMN_TITLE: "Type",
  PENDING_APPROVALS_CONVERSION_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_CONVERSION_TABLE_DETAILS_COLUMN_TITLE: "Details",
  PENDING_APPROVALS_CONVERSION_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATOR_COLUMN_TITLE: "Created by",
  PENDING_APPROVALS_CONVERSION_TABLE_CURRENCY_COLUMN_TITLE: "Currency",
  PENDING_APPROVALS_CONVERSION_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  PENDING_APPROVALS_CONVERSION_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation",
  PENDING_APPROVALS_CONVERSION_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Credit Account",
  PENDING_APPROVALS_CONVERSION_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Beneficiary Bank Code",
  PENDING_APPROVALS_CONVERSION_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Document Number",
  PENDING_APPROVALS_CONVERSION_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Transaction Date",
  PENDING_APPROVALS_CONVERSION_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE: "Sender Account Number",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATED_DATE_AND_TIME_COLUMN_TITLE: "Created Date",
  PENDING_APPROVALS_CONVERSION_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Beneficiary Account Number",
  PENDING_APPROVALS_CONVERSION_TYPE_COLUMN_TITLE: "Conversion Type",

  // Pending Approvals Page

  ACCOUNTS_INQUIRY_TAB_TITLE: "Inquiry",
  ACCOUNTS_ACCOUNTS_TAB_TITLE: "Accounts",
  ACCOUNTS_STATEMENT_TAB_TITLE: "Statement",
  ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE: "New Account Opening",
  ACCOUNTS_OUTGOING_TAB_TITLE: "Outgoing Swift",
  ACCOUNTS_INCOMING_TAB_TITLE: "Incoming Swift",
  ACCOUNTS_INCOMING_COVER_TAB_TITLE: "Covered Incoming Swift messages",
  ACCOUNTS_REQUEST_NEW_ACCOUNT_OPENING_TAB_TITLE: "Request for New Account Opening",

  ACCOUNTS_ACCOUNTS_BALANCE_AS_OF_TITLE: "Balance as of",
  ACCOUNTS_ACCOUNTS_EXCLUDE_ZERO_BALANCE_CHECKBOX_TITLE: "Exclude zero balance",
  ACCOUNTS_ACCOUNTS_INCLUDE_CLOSED_ACCOUNTS_CHECKBOX_TITLE: "Include closed accounts",

  ACCOUNTS_ACCOUNTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  ACCOUNTS_ACCOUNTS_TABLE_INQUIRY_COLUMN_TITLE: "Inquiry",
  ACCOUNTS_ACCOUNTS_TABLE_BALANCE_COLUMN_TITLE: "Account Balance",
  ACCOUNTS_ACCOUNTS_TABLE_CURRENCY_COLUMN_TITLE: "Currency",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NAME_COLUMN_TITLE: "Account Name",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Account Number",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_OPENING_DATE_COLUMN_TITLE: "Account Opening Date",
  ACCOUNTS_ACCOUNTS_TABLE_LAST_TRANSACTION_DATE_COLUMN_TITLE: "Last Transaction Date",

  ACCOUNTS_STATEMENT_FILTER_TITLE: "Filter",
  ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE: "Filter",
  ACCOUNTS_STATEMENT_FILTER_FORM_TO_FIELD_LABEL_TITLE: "To",
  ACCOUNTS_STATEMENT_FILTER_FORM_YEAR_FIELD_LABEL_TITLE: "Year",
  ACCOUNTS_STATEMENT_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "From",
  ACCOUNTS_STATEMENT_FILTER_FORM_MONTH_FIELD_LABEL_TITLE: "Month",
  ACCOUNTS_STATEMENT_FILTER_FORM_GENERATE_BUTTON_TITLE: "Generate",
  ACCOUNTS_STATEMENT_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "debit only",
  ACCOUNTS_STATEMENT_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "credit only",
  ACCOUNTS_STATEMENT_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "all",
  ACCOUNTS_STATEMENT_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Search Amount",
  ACCOUNTS_STATEMENT_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Account Number",
  ACCOUNTS_STATEMENT_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Corr. Account Number",
  ACCOUNTS_STATEMENT_FILTER_ERROR_TITLE: "Request timed out",

  ACCOUNT_STATEMENT_FORM_TO_FIELD_LABEL_TITLE: "To",
  ACCOUNT_STATEMENT_FORM_DETAILS_BUTTON_TITLE: "Details",
  ACCOUNT_STATEMENT_FORM_FROM_FIELD_LABEL_TITLE: "From",
  ACCOUNT_STATEMENT_FORM_DEBIT_FIELD_LABEL_TITLE: "Debit",
  ACCOUNT_STATEMENT_FORM_CREDIT_FIELD_LABEL_TITLE: "Credit",
  ACCOUNT_STATEMENT_FORM_STATUS_FIELD_LABEL_TITLE: "Status",
  ACCOUNT_STATEMENT_FORM_BALANCE_FIELD_LABEL_TITLE: "Balance",
  ACCOUNT_STATEMENT_FORM_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  ACCOUNT_STATEMENT_FORM_DOC_NUMBER_FIELD_LABEL_TITLE: "Doc. Number",
  ACCOUNT_STATEMENT_FORM_BANK_CODE_FIELD_PLACEHOLDER_TITLE: "Bank Code",
  ACCOUNT_STATEMENT_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Company Name",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Account Type",
  ACCOUNT_STATEMENT_FORM_COMPANY_PHONE_FIELD_LABEL_TITLE: "Company Phone",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Account Number",
  ACCOUNT_STATEMENT_FORM_OPENING_BALANCE_FIELD_LABEL_TITLE: "Opening Balance",
  ACCOUNT_STATEMENT_FORM_CLOSING_BALANCE_FIELD_LABEL_TITLE: "Closing Balance",
  ACCOUNT_STATEMENT_FORM_END_OF_STATEMENT_FIELD_LABEL_TITLE: "End of Statement",
  ACCOUNT_STATEMENT_FORM_ITEM_STATEMENT_FIELD_LABEL_TITLE: "Item Enclosed:",
  ACCOUNT_STATEMENT_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Company Address",
  ACCOUNT_STATEMENT_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  ACCOUNT_STATEMENT_FORM_CORR_ACCOUNT_NUMBER_FIELD_PLACEHOLDER_TITLE:
    "Correspondent Account Number",

  ACCOUNT_INQUIRY_FILTER_TO_FIELD_LABEL_TITLE: "To",
  ACCOUNT_INQUIRY_FILTER_FROM_FIELD_LABEL_TITLE: "From",
  ACCOUNT_INQUIRY_FILTER_DATE_FROM_FIELD_LABEL_TITLE: "From",
  ACCOUNT_INQUIRY_NON_STANDART_INQUIRIES_BUTTON_TITLE: "Non-Standard Inquiries",
  ACCOUNT_INQUIRY_STANDART_INQUIRIES_BUTTON_TITLE: "Standard Inquiries",
  ACCOUNT_INQUIRY_SUBMIT_STANDARD_INQUIRY_BUTTON_TITLE: "Create Standard Inquiry",
  ACCOUNT_INQUIRY_SUBMIT_NON_STANDARD_INQUIRY_BUTTON_TITLE: "Create Non-Standard Inquiry",

  ACCOUNT_SWIFT_VALUE_DATE_COLUMN_TITLE: "Value Date",
  ACCOUNT_SWIFT_MSG_KEY_COLUMN_TITLE: "Swift message Key",
  ACCOUNT_SWIFT_REF_NUM_COLUMN_TITLE: "Reference Number",
  ACCOUNT_SWIFT_CURRENCY_COLUMN_TITLE: "Currency",
  ACCOUNT_SWIFT_AMOUNT_COLUMN_TITLE: "Amount",
  ACCOUNT_SWIFT_ORD_BIC_COLUMN_TITLE: "Ordering institution SWIFT/BIC",
  ACCOUNT_SWIFT_ORD_CUST_COLUMN_TITLE: "Ordering Customer",
  ACCOUNT_SWIFT_DETAILS_COLUMN_TITLE: "Details of Payment",
  ACCOUNT_SWIFT_ACC_BIC_COLUMN_TITLE: "Account with institution SWIFT/BIC",
  ACCOUNT_SWIFT_BEN_CUS_COLUMN_TITLE: "Beneficiary Customer",

  ACCOUNT_SWIFT_COVER_WAITING_TITLE: "Please Waiting!",
  ACCOUNT_SWIFT_COVER_CHOCKBOX_FILTER_TITLE: "Filter By Date",

  ACCOUNT_SWIFT_COVER_DATE_TITLE: "Date",
  ACCOUNT_SWIFT_COVER_SUM_TITLE: "Amount",
  ACCOUNT_SWIFT_COVER_CURRENCY_TITLE: "Currency",
  ACCOUNT_SWIFT_COVER_SENDER_TITLE: "Sender",
  ACCOUNT_SWIFT_COVER_BEBEFICIARY_TITLE: "Beneficiary",
  ACCOUNT_SWIFT_COVER_PAYMENT_DETAILS_TITLE: "Payment details",
  ACCOUNT_SWIFT_COVER_SUM_ADDITIONAL_PAYMENT_DETAILS_TITLE: "Additional payment details",
  ACCOUNT_SWIFT_COVER_ACCOUNT_FOR_RECEIPT_TITLE: "Account for receipt",

  ACCOUNT_SWIFT_COVER_CHECK_BUTTON_TITLE: "Check",
  ACCOUNT_SWIFT_COVER_CHECK_NO_MESSAGES_RECEIVED_TITLE: "No messages received",
  ACCOUNT_SWIFT_COVER_CHECK_RECEIVED_SWIFT_MESSAGES_TITLE: "Incoming SWIFT messages",

  ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE: "Status",
  ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE: "Subject",
  ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation",
  ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE: "Excluded Date",
  ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE: "Submitted Date",

  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_TITLE: "Submit Standard Inquiry",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_POWER_CHECKBOX_TITLE: "General Power of Attorney",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_DETAILS_SLEEP_CHECKBOX_TITLE:
    "Detalization slip for incoming UZS funds",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CASH_CHECKBOX_TITLE:
    "Power of attorney for cash withdrawal",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_PAYMENT_CHECKBOX_TITLE:
    "Power of attorney for (Payment instruction, letters, statements)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_CHECKBOX_TITLE: "General inquires",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_PAYMENT_CHECKBOX_TITLE:
    "Письмо на разрешение оплаты п/требования в безакцептном порядке",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_QUERY_ABOUT_BINDING_CHECKBOX_TITLE:
    "Запрос о привязке к транзитному счету, карточный счет сотрудника",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_EMAIL_CHECKBOX_TITLE:
    "Письмо об авторизованной электронной почте компании",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_SALARY_CHECKBOX_TITLE:
    "Письмо на зарплатный проект",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_STATEMENT_OF_ACCOUNT_CHECKBOX_TITLE:
    "Statement of account (additional)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_RENEWAL_OF_SIGNATURE_CHECKBOX_TITLE:
    "Renewal of signature card (including temporary card)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_CARD_CHECKBOX_TITLE:
    "Power of attorney for salary card of staff",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_REPLY_CHECKBOX_TITLE:
    "General inquires (reply within 24 hours)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CORPORATE_CARD_CHECKBOX_TITLE:
    "Power of attorney for corporate card",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_UP_12_MONTHS_CHECKBOX_TITLE:
    "Preparation of the information (paper/electronic) on account by Clients' request for the period up to 12 months",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OF_THE_LATTER_CHECKBOX_TITLE:
    "Preparation of the letter based on Auditor request on behalf of Client",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OVER_12_MONTHS_CHECKBOX_TITLE:
    "Preparation of the information (paper/electronic) on account by Clients' request for the period over 12 months",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_AND_CORPORATE_CARD_CHECKBOX_TITLE:
    "Power of attorney for salary and corporate cards",

  ACCOUNT_INQUIRY_NON_STANDARD_SUBJECT_FIELD_LABEL_TITLE: "Subject",
  ACCOUNT_INQUIRY_NON_STANDARD_TITLE: "Submit Non-Standard Inquiry",
  ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE: "Submit Request",
  ACCOUNT_INQUIRY_NON_STANDARD_MESSAGE_TEXT_FIELD_LABEL_TITLE: "Message text",
  ACCOUNT_INQUIRY_NON_STANDARD_DATE_OF_REQUEST_FIELD_LABEL_TITLE: "Date of Request",
  ACCOUNT_INQUIRY_NON_STANDARD_PREPARING_BRANCH_FIELD_LABEL_TITLE: "Name of the bank",
  ACCOUNT_INQUIRY_NON_STANDARD_REQUESTING_COMPANY_FIELD_LABEL_TITLE: "Requesting Company",
  ACCOUNT_INQUIRY_NON_STANDARD_CLIENT_REGISTRATION_NUMBER_FIELD_LABEL_TITLE:
    "Client Registration Number",

  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_DATE_FIELD_LABEL_TITLE: "Date",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Phone",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "E-mail",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ACCOUNT_NAME_FIELD_LABEL_TITLE:
    "Account Type",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_BANK_FIELD_LABEL_TITLE:
    "Name of the bank",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Name of the client",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ADDRESS_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Address of the client",
  ACCOUNT_INQUIRY_APPLICATION_FOR_ACCOUNT_OPENING_FORM_TITLE: "Application for account opening",

  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_1: "*If client choose",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "Email",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Phone",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_2: "FCY Corporate Card opening",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_ISSUED_BY_FIELD_LABEL_TITLE: "Issued by",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THRU_FIELD_LABEL_TITLE: "Valid thru",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_3: ", need to fill additional options",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CARD_NUMBER_FIELD_LABEL_TITLE: "Card number",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Citizenship",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_HOME_ADDRESS_FIELD_LABEL_TITLE: "Home Address",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE: "Date of issue",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PAGE_TITLE: "Cardholder details",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_FIRSTNAME_FIELD_TITLE: "First Name",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_LASTNAME_FIELD_TITLE: "Last Name",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSPORT_SERIES_FIELD_TITLE: "Passport series &",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE: "Number",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_MIDDLENAME_FIELD_TITLE: "Middle Name",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_ISSUE_FIELD_TITLE: "Place of issue Of The Passport",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_ISSUE_FIELD_TITLE: "Date of issue Of The Passport",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_WORK_PHONE_FIELD_TITLE: "Work Phone",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VERIFY_FIELD_TITLE:
    "Password to verify the identity of the Owner used in telephone conversations with the Back",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PHONE_FIELD_TITLE: "Phone",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_POSITION_HELD_TITLE: "Position held",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_BIRTH_TITLE: "Date Of Birth",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_ADDRESS_TITLE: "Address",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_RECIDENCE_TITLE: "Place of residence",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_REGISTERED_ADDRESS_TITLE: "Registered address",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VALIDITY_TITLE: "Password validity",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THROUGH_FIELD_LABEL_TITLE: "Valid through",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_CARDHOLDER_FIELD_LABEL_TITLE:
    "First & Family Name Of Cardholder",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Please print name as it is to appear on the card",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_COMPANY_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Please print company name as it is to appear on the card",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Passport No",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_BIRTH_FIELD_LABEL_TITLE: "Date & Place of Birth",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_MOTHER_NAME_FIELD_LABEL_TITLE: "Mother's maiden name",

  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE: "Submit",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_PUBLIC_OFFER_LABEL_TITLE: "Public Offer",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_READ_AND_AGREE_LINK_TITLE: "Read and Agree",
  ACCOUNT_INQUIRY_NEW_REQUEST_K2: "Request for Register K2",
  ACCOUNT_INQUIRY_SENT: "Request created",
  ACCOUNT_INQUIRY_TEXT_K2: " kindly asks Bank to provide copy of Register K2 as of",
  ACCOUNT_INQUIRY_TEXT_SINCERELY: "Sincerely,",
  ACCOUNT_REQUEST_NUMBER: "Request Number",
  ACCOUNT_REQUEST_DATE: "Request Date",
  ACCOUNT_BRANCH_NAME: "Bank Name",
  ACCOUNT_INQUIRY_TEXT_AUDIT_INQUIRY: "Request for the information on Audit Inquiry",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER: "Request for the Account Information",
  ACCOUNT_END_OF_DAY: "End of day",
  ACCOUNT_BEGINNING_OF_THE_DAY: "Beginning of the day",
  ACCOUNT_INQUIRY_TEXT_AUDIT: "In connection with the audit of the financial activities",
  ACCOUNT_INQUIRY_TEXT_2_AUDIT: " kindly asks Bank to provide account information as of ",
  ACCOUNT_INQUIRY_TEXT_BY_AUDIT: "by the Audit Company",
  ACCOUNT_FROM_DATE: "From",
  ACCOUNT_TO_DATE: "To",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    " kindly asks Bank to provide the following information on the company account(s);",
  ACCOUNT_BALANCE_OF_DATE: "Balance as of the date",
  ACCOUNT_TURNOVERS: "Turnovers for the period from",
  ACCOUNT_ALL_ACCOUNTS: "All",
  ACCOUNT_REQUEST_SENT: "Request created",
  ACCOUNT_INFORMATION: "Information on loans as of the date",
  ACCOUNT_CARD_FILE: "Card file number 2 as of the date",
  ACCOUNT_ADDITIONAL_STATEMENT_HEADER: "Request for Additional Statement",
  ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " kindly asks Bank to provide additional account statement(s) for the following account(s);",
  ACCOUNT_ADDITIONAL_FOR_THE_PERIOD: "From",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL:
    "Request for information on incoming Funds to Replenish Charter Capital",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT:
    " kindly asks Bank to provide information on incoming funds to the company's account(s)",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT_2:
    "for replenishment of charter capital for the period from",

  // Transfers Page
  TRANSFER_LCY_EXPORT_EXCEL_TITLE: "ПЛАТЁЖНОЕ ПОРУЧЕНИЕ №",
  TRANSFER_LCY_EXPORT_EXCEL_DATE: "ДАТА",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME: "Наименование плательщика",
  TRANSFER_LCY_EXPORT_EXCEL_DEBIT: "ДЕБЕТ",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT: "Счет плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER: "ИНН плательщика",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME:
    "Наименование банка, обслуживающего плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO: "Код банка, обслуживающего плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT: "СУММА",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME: "Наименование получатиля",
  TRANSFER_LCY_EXPORT_EXCEL_CREDIT: "КРЕДИТ",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT: "Счет получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER: "ИНН получателя",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME:
    "Наименование банка, обслуживающего получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO: "Код банка, обслуживающего получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS: "Сумма прописью",
  TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS: "Детали платежа",
  TRANSFER_LCY_BUDGET_INN: "ИНН бюджетополучателя",
  TRANSFER_LCY_BUDGET_REVENUE: "Казначейский лицевой счёт дохода",
  TRANSFER_BUDGET_ACCOUNT_NUMBER: "Казначейский лицевой счёт бюджетополучателя",
  TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME: "Руководитель",
  TRANSFER_LCY_EXPORT_EXCEL_FIO: "Ф.И.О.",
  TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT: "Главный бухгалтер",
  TRANSFER_LCY_EXPORT_EXCEL_OTPKEY_APPROVE: "Подтвержден OTP key",
  TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE: "М.П.",
  TRANSFER_LCY_EXPORT_EXCEL_BANK: "Банк",
  TRANSFER_LCY_EXPORT_EXCEL_CHECKED: "Проверено",
  TRANSFER_LCY_EXPORT_EXCEL_APPROVED: "Одобрено",
  TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED: "Проведено",
  TRANSFER_BUDGET_NAME: "Наименование бюджетополучателя",

  TRANSFERS_SALARY_TAB_TITLE: "Salary Card",
  TRANSFERS_HISTORY_TAB_TITLE: "History",
  TRANSFERS_SAMPLES_TAB_TITLE: "Forms",
  TRANSFERS_SAMPLES_TAB_IMPORT_FROM_EXCEL: "Import from Excel",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_TITLE: "File size",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_KB_TITLE: "kb",
  TRANSFERS_FORMS_DIRECTIONS_MODIFIED_DATE_TITLE: "Last modified date",
  TRANSFERS_TRANSFERS_TAB_LCY_TITLE: "LCY Transfers",
  TRANSFERS_TRANSFERS_TAB_FCY_TITLE: "FCY Transfers",
  TRANSFERS_FAVORITES_TAB_TITLE: "Favorites",
  TRANSFERS_PAYMENT_CLAIM_TAB_TITLE: "Payment Claim",
  TRANSFERS_CORRESPONDENTS_TAB_TITLE: "Counterparties",
  TRANSFERS_CORPORATE_CARD_TAB_TITLE: "Corporate Card",

  TRANSFERS_SALARY_INSTRUCTION_TITLE: "Instruction",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_TITLE: "Submit payroll to the bank",
  TRANSFERS_CORPORATE_INSTRUCTION_TITLE: "Multiple card replenishment instruction",
  TRANSFERS_SALARY_REPLENISH_SUBTITLE: "1. Replenish Transit Account",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REPLENISH_SUBTITLE: "1. Please, replenish the respective transit account",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SUBTITLE:
    "2. Select the transit account (23106, 23108, 23110) from the list.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REGISTER_SUBTITLE:
    "3. Select the payroll upload method (Upload Excel file / Choose existing Payroll / Create new Payroll).",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_CHECK_SUBTITLE:
    "4. Click on the Check button. If the status of the data record in the list appears as \"Not Match\" or \"Account is not found\", please make corrections as needed or delete those records.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_STATUS_SUBTITLE:
    "5. When all the data records have \"Match\" status, click on the Submit button.If the payroll data records are succesfully created, the Authorizer should go to \"Pending Approvals\" / \"Salary\" section and change the status of the selected documents to \"Approve\" (or \"Reject\"), then click on the Submit button.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_INFO_SUBTITLE:
    "Payroll submission cut-off time - 5.30 p.m.* Payrolls sumitted after 5.30 p.m. are automatically deleted. (* 4.30 p.m. on preholiday shortened business days).",
  
  TRANSFERS_SALARY_CHOOSE_REGISTER_SUBTITLE:
    "3. Please choose register option (Upload Excel File as per below templates, Choose Existing Register, Create New Register)",
  
  TRANSFERS_SALARY_DEBIT_ACCOUNT_TITLE: "Automatic payroll crediting to staff bank cards",
  TRANSFERS_SALARY_DEBIT_ACCOUNT_METHOD_INFO_SUBTITLE: "With this method funds can be credited to staff bank cards without sending a payroll to the bank.",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SUBTITLE: "1. Select the demand deposit account from the list.",

  TRANSFERS_CORPORATE_CARD_INFO: "2. Choose transit account -",
  UPLOAD_PAYROLL_EXCEL_BUTTON_TITLE: "Upload Excel File",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT:
    "(i) 23102 -  Transit account for replenishment of Corporate Card",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT_2:
    "(ii) 23104 -  Transit account for replenishment of Corporate Card of Individual Entrepreneur",

  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_TITLE: "Choose Transit Account",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_FIELD_LABEL_TITLE: "Transit Account Number",
  TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE: "Balance",
  TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE: "Register Type",
  TRANSFERS_SALARY_MONTH_FIELD_LABEL_TITLE: "For Month",
  TRANSFERS_SALARY_NUMBER_FIELD_LABEL_TITLE: "Register Number",
  TRANSFERS_SALARY_AGREEMENT_FIELD_LABEL_TITLE: "Agreement Date",
  TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE: "Code",
  TRANSFERS_SALARY_CHOOSE_REGISTER_TITLE: "Choose Register",
  TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE: "Choose Existing Register",
  TRANSFERS_SALARY_CREATE_NEW_REGISTER_BUTTON_TITLE: "Create New Register",
  NOTIFICATION_ON_STATUS_FOR_CREATER:
    'Please choose document(s) only created by you and with the status "Created"',
  
  TRANSFERS_SALARY_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Demand deposit account number",

  TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE: "From",
  TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE: "To",
  TRANSFERS_SALARY_CLAIM_FILTER_BY_STATUS_FIELD_LABEL_TITLE: "Filter by status",
  TRANSFERS_SALARY_SEARCH_BUTTON_TITLE: "Search",
  TRANSFERS_SALARY_SHOW_CREATORS_CHECKBOX_TITLE: "Show Creator and Authorizer",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_EMPLOYEE_REGISTRATION_FORM_TITLE:
    "Employee Registration Form",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_FIELD_LABEL_TITLE:
    "Passport Series & Number",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Passport Number",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Place of Issue Of The Passport",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Date Of Issue Of Passport",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_VALIDITY_FIELD_LABEL_TITLE:
    "Passport Validity",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_ADDRESS_FIELD_LABEL_TITLE:
    "Registration Address",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_CITY_FIELD_LABEL_TITLE: "City",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_PINFL_FIELD_LABEL_TITLE: "PINFL",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SAVE_BUTTON_TITLE: "Submit",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_INN_FIELD_LABEL_TITLE: "INN",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_AREA_FIELD_LABEL_TITLE: "Area",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_LAST_FIELD_LABEL_TITLE: "Last Name",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FATHER_FIELD_LABEL_TITLE: "Middle Name",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MALE_RADIO_BUTTON_LABEL_TITLE: "Male",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MOBILE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Of Mobile",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SERVICE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Of Service",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HOME_PHONE_RADIO_BUTTON_LABEL_TITLE: "Of Home",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_UZCARD_RADIO_BUTTON_LABEL_TITLE: "UzCard",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_YES_RADIO_BUTTON_LABEL_TITLE: "Yes",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_NO_RADIO_BUTTON_LABEL_TITLE: "No",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HUMO_RADIO_BUTTON_LABEL_TITLE: "HUMO",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PINF_FIELD_LABEL_TITLE: "PINF Number",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FIRST_FIELD_LABEL_TITLE: "First Name",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MIDDLE_FIELD_LABEL_TITLE: "Middle Name",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_GENDER_RADIO_BUTTON_LABEL_TITLE: "Gender",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_LABEL_TITLE: "Card",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_RESIDENT_LABEL_TITLE: "Resident",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FEMALE_RADIO_BUTTON_LABEL_TITLE: "Female",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SEND_REQUEST_BUTTON_TITLE: "Send Request",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Citizenship",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_BIRTH_FIELD_LABEL_TITLE: "Date Of Birth",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_BIRTH_FIELD_LABEL_TITLE: "Place Of Birth",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_STREET_FIELD_LABEL_TITLE:
    "Street, Quarter, House, Number, Apartment Number",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CONTACT_BUTTON_TITLE: "PRINT CONTACT",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CLEAR_FORM_BUTTON_TITLE: "CLEAR FORM",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_BUTTON_TITLE: "Search",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_CARD_TYPE: "Card Type",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_FIELD_LABEL_TITLE: "Search",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EXPORT_LIST_BUTTON_TITLE: "EXPORT LIST",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK: "Check",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_ERROR: "There should be no commas in the sum",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_AMOUNT_ERROR: "Amount must be filled or be greater than zero",
  TRANSFERS_SALARY_LIST_OF_CARD_BACK: "Back",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE: "Full Name",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EMPLOYEE_LIST_BUTTON_TITLE: "Employee List",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE: "Card ID",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Account Number",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE: "Open Date",

  TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE: "Choose File",

  TRANSFERS_CORPORATE_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Multiple card replenishment with Payroll",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE:
    "Single card replenishment without Payroll",

  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Submit payroll to the bank",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE:
    "Automatic payroll crediting to staff bank cards",

  TRANSFERS_CORPORATE_CHOOSE_DEBIT_SUBMIT_BUTTON_TITLE: "Submit",

  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_TITLE: "Choose Debit Account",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_NUMBER_FIELD_TITLE: "Debit Account Number",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_CORPORATE_CARD_NUMBER_FIELD_TITLE:
    "Corporate Card Number",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_AMOUNT_FIELD_TITLE: "Amount",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_OF_CORPORATE_FIELD_TITLE: "Account Of Corporate Card",

  TRANSFERS_CORPORATE_SINGLE_CARD_REPLENISHMENT_INSTURCTION_TITLE:
    "Single card replenishment instruction",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_DEBIT_ACCOUNT_TITLE: "1. Choose debit account",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_ACCOUNT_OF_COR_CARD_TITLE:
    "2. Choose account of corporate card",

  TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE: "Submit",
  TRANSFERS_TRANSFERS_CHECK_BUTTON_TITLE: "CHECK",
  TRANSFERS_TRANSFERS_CURRENT_BUSINESS_DAY_TITLE: "Current business Day",
  TRANSFERS_TRANSFERS_CREATE_NEW_LCY_BUTTON_TITLE: "Create New LCY Transfer",
  TRANSFERS_TRANSFERS_CREATE_NEW_FCY_BUTTON_TITLE: "Create New FCY Transfer",
  TRANSFERS_TRANSFERS_ALL_OUTGOING_TRANSFERS_TITLE: "LCY Outgoing Transfers",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_TITLE: "FCY Outgoing Transfers",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_IN_FORM_TITLE: "FCY Transfer",
  TRANSFERS_TRANSFERS_FIND_DOCUMENT_WITH_STATUS_LABEL_TITLE: "Find document with status",
  TRANSFERS_TRANSFERS_SHOW_CREATORS_AND_APPROVER_LABEL_TITLE: "Show Creator and Authorizer",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_FCY_BUTTON_TITLE: "Import from Excel",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_LCY_BUTTON_TITLE: "Import from Excel",

  TRANSFERS_TRANSFERS_FCY_ON_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on business days: 9 a.m. - 4.30 p.m. (Tashkent time)",  
  TRANSFERS_TRANSFERS_FCY_ON_SHORTENED_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on pre-holiday/shortened business days: 9 a.m. - 3.30 p.m. (Tashkent time)",  

  TRANSFERS_TRANSFERS_LCY_FAVORITES_BUTTON_TITLE: "Favorites",
  TRANSFERS_TRANSFERS_LCY_ANOR_TRANSFER_BUTTON_TITLE: "ANOR Standard Transfer",
  TRANSFERS_TRANSFERS_LCY_MINUS_TRANSFER_BUTTON_TITLE: "MUNIS Transfer",
  TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE: "LCY Budget Transfer",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_TRANSFER_BUTTON_TITLE: "ANOR Budget Transfer",
  TRANSFERS_TRANSFERS_LCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: "Upload from Excel",
  TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE: "LCY Standard Transfer",
  TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE: "LCY Budget Revenue Transfer",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE: "ANOR Budget Revenue Transfer",

  TRANSFERS_TRANSFERS_FCY_TITLE: "FCY Transfer",
  TRANSFERS_TRANSFERS_FCY_FAVORITES_BUTTON_TITLE: "Upload from Favorite",
  TRANSFERS_TRANSFERS_FCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: "Upload from Excel",

  TRANSFERS_TRANSFERS_TABLE_TYPE_COLUMN_TITLE: "Type",
  TRANSFERS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_TRANSFERS_TABLE_DETAILS_COLUMN_TITLE: "Details",
  TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE: "Created by",
  TRANSFERS_TRANSFERS_TABLE_CURRENCY_COLUMN_TITLE: "Currency",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation from bank",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_CB_COLUMN_TITLE: "Explanation from CB",
  TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  TRANSFERS_TRANSFERS_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Beneficiary Bank Code",
  TRANSFERS_TRANSFERS_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_TYPE_COLUMN_TITLE: "Document Type",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Document Number",
  TRANSFERS_TRANSFERS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Transaction Date",
  TRANSFERS_TRANSFERS_TABLE_CREATE_DATE_AND_TIME_COLUMN_TITLE: "Created Date",
  TRANSFERS_TRANSFERS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE: "Sender Account Number",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Created by",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Authorized by",
  TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE: "Beneficiary Account Number",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE:
    "Cancel payment claim",
  TRANSFERS_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_FOR_STATUS_COLUMN_TITLE:
    "Request for status",

  //Collection Orders

  COLLECTION_ORDERS_TAB_PANE_TITLE: "Collection Order From Tax",
  COLLECTION_ORDERS_TAB_TITLE: "Collection Order From Tax",

  COLLECTION_ORDERS_FROM_MIB_TAB_PANE_TITLE: "Collection Order From MIB",
  COLLECTION_ORDERS_FROM_MIB_TAB_TITLE: "Collection Order From MIB",

  COLLECTION_ORDERS_TABLE_TAX_ID_COLUMN_TITLE:
    "Unique system code of the collection order in the Information System of the Tax Committee",
  COLLECTION_ORDERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Document number",
  COLLECTION_ORDERS_TABLE_DOCUMENT_DATE_COLUMN_TITLE: "Document date",
  COLLECTION_ORDERS_TABLE_BRANCH_A_COLUMN_TITLE: "Payee Bank’s branch code",
  COLLECTION_ORDERS_TABLE_BRANCH_B_COLUMN_TITLE: "Beneficiary Bank’s branch code",
  COLLECTION_ORDERS_TABLE_ACCOUNT_A_COLUMN_TITLE: "Payee Bank’s bank account",
  COLLECTION_ORDERS_TABLE_ACCOUNT_B_COLUMN_TITLE: "Beneficiary Bank’s bank account",
  COLLECTION_ORDERS_TABLE_INN_A_COLUMN_TITLE: "Payee’s Tax ID",
  COLLECTION_ORDERS_TABLE_INN_B_COLUMN_TITLE: "Beneficiary’s Tax ID",
  COLLECTION_ORDERS_TABLE_PINFL_A_COLUMN_TITLE: "Payee’s PINFL number",
  COLLECTION_ORDERS_TABLE_SUMMA_COLUMN_TITLE: "Amount",
  COLLECTION_ORDERS_TABLE_PAYMENT_TYPE_COLUMN_TITLE: "Type of debt",
  COLLECTION_ORDERS_TABLE_UNPAID_COLUMN_TITLE:
    "Payment code of a payment document not paid in time",
  COLLECTION_ORDERS_TABLE_NONPAYMENT_COLUMN_TITLE: "Non-payment code",
  COLLECTION_ORDERS_TABLE_BOARD_COLUMN_TITLE: "Beneficiary’s governing body",
  COLLECTION_ORDERS_TABLE_OKED_COLUMN_TITLE: "Beneficiary’s type of economic activity code",
  COLLECTION_ORDERS_TABLE_NAME_A_COLUMN_TITLE: "Payee’s Name",
  COLLECTION_ORDERS_TABLE_NAME_B_COLUMN_TITLE: "Beneficiary’s Name",
  COLLECTION_ORDERS_TABLE_PURPOSE_CODE_COLUMN_TITLE: "Payment code",
  COLLECTION_ORDERS_TABLE_BUDGET_INCOME_COLUMN_TITLE: "Treasury’s budget income account",
  COLLECTION_ORDERS_TABLE_BUDGET_ACCOUNT_COLUMN_TITLE: "Budget beneficiary’s account",
  COLLECTION_ORDERS_TABLE_BUDGET_INN_COLUMN_TITLE: "Budget beneficiary’s Tax ID",
  COLLECTION_ORDERS_TABLE_PURPOSE_COLUMN_TITLE: "Purpose of payment",

  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_LOAD_BUTTON_TITLE: "Load",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE: "Sender",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE: "Amount",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE: "Details",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTIONS_BUTTON_TITLE: "Load",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Generate",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE: "Beneficiary",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CORRESPONDENT_BUTTON_TITLE: "Counterparty",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE: "Sender Name",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "Payment Type",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE: "Payment Code",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE: "Sender Tax ID",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_FIELD_LABEL_TITLE: "Account  (27 digit)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTION_TO_CHOOSE_BUTTON_TITLE: "option to choose",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Debit Account",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE: "Credit Account",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Invoice Number",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE: "Current Balance",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE: "Payment Details",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE: "Amount in Words",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "Transfer Number",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE: "Sender Bank Name",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE: "Sender Bank Code",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE: "Amount in Digits",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_AND_DETAILS_TITLE: "Payment code & details",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE: "Beneficiary Name",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE: "Beneficiary Tax ID",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE:
    "Budget Organisation Tax ID",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE:
    "Beneficiary Bank Name",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE:
    "Beneficiary Bank Code",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE: "Account (27 digits)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_25_DIGITS_FIELD_LABEL_TITLE: "Account (25 digits)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE:
    "Budget Organisation Name",

  TRANSFERS_LCY_PRINT_HEADER_TITTLE: "Payment order №",
  TRANSFERS_LCY_PRINT_DATA_LABEL: "Date",
  TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL: "Sender Name",
  TRANSFER_PRINT_TABLE_HELD: "Success",
  TRANSFERS_LCY_PRINT_STATE_LABEL: "State",
  TRANSFERS_LCY_PRINT_DEBIT_LABEL: "DEBIT",
  TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL: "Payer's account",
  TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL: "Payer's Inn",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL: "Inn",
  TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL: "Name of the bank serving the payer of funds",
  TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL: "Bank code serving the payer of funds",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_NAME_LABEL: "Payer's bank name",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_CODE_LABEL: "Payer's bank code",
  TRANSFERS_LCY_PRINT_AMOUNT_LABEL: "Amount:",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL: "Beneficiary name",
  TRANSFERS_LCY_PRINT_CREDIT_LABEL: "CREDIT",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL: "Beneficiary account",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL: "Beneficiary Inn",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL:
    "Name of the bank serving the recipient of funds",
  TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_NAME_LABEL: "Beneficiary's bank name",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL: "Bank code serving the beneficiary",
  TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_LABEL: "Beneficiary's bank code",
  TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL: "Amount with words:",
  TRANSFERS_LCY_PRINT_STATUS_LABEL: "State:",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_TAX_NUMBER_LABEL: "TIN of the budget recipient",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_LABEL:
    "Designated personal account of a budget recipient",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_NAME_LABEL: "Name of the budget recipient",
  TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL: "Payment details",
  TRANSFERS_LCY_PRINT_MANAGER_LABEL: "Manager",
  TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL: "Accountant General",
  TRANSFERS_LCY_SIGNATURE_LABEL: "signature",
  TRANSFERS_LCY_PRINT_INITIALS_LABEL: "Initials",
  TRANSFERS_LCY_PRINT_BANK_LABEL: "Bank",
  TRANSFERS_LCY_BANK_MP_LABEL: "М.П  БАНК",
  TRANSFERS_LCY_CHECKED_LABEL: "Checked",
  TRANSFERS_LCY_APPROVED_LABEL: "Approved",
  TRANSFERS_LCY_REVENUE_PRINT_CLIENT_ACCOUNT_LABEL: "Designated personal income account",
  TRANSFERS_LCY_UPLOAD_FROM_EXCEL_NUMBER_ERROR: "Cannot save reference with this Id number",

  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Generate",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYEE_GROUP_TITLE: "Payee Group",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SUPPLIER_CODE_TITLE: "Supplier Code",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SETTLEMENT_CODE_TITLE: "Settlement Code",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Invoice Number",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_GROUP_TITLE_TITLE: "Sender",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_GROUP_TITLE_TITLE: "Beneficiary",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNTS_GROUP_TITLE_TITLE: "Amounts",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_DETAILS_GROUP_TITLE_TITLE: "Details",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_NAME_TITLE: "Sender Name",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_DEBIT_ACCOUNT_TITLE: "Debit Account",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_CURRENT_BALANCE_TITLE: "Current Balance",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_NAME_TITLE: "Sender Bank Name",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_CODE_TITLE: "Sender Bank Code",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_TAX_ID_TITLE: "Sender Tax ID",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_NAME_TITLE: "Beneficiary Name",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_TITLE: "Credit Account",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_TAX_ID_TITLE: "Beneficiary Tax ID",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_AMOUNT_FOR_PAYMENT: "Amount for Payment",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_NAME_TITLE: "Beneficiary Bank Name",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_CODE_TITLE: "Beneficiary Bank Code",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ACCOUNT_TITLE: "Account",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_NAME_TITLE:
    "Beneficiary Budget Organisation Name",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_TAX_ID_TITLE:
    "Beneficiary Budget Organisation Tax ID",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_DIGITS_TITLE: "Amount in Digits",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_WORDS_TITLE: "Amount in Words",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE: "Payment Code",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_DETAILS_TITLE: "Payment Details",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TITLE: "Applicant (50)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_AMOUNT_TITLE: "Amount (32А)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_COMMISSION_TITLE: "Commission",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_OTHERS_FIELD_LABEL_TITLE: "Others (72)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_CODE_LABEL_TITLE: "Operator code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE:
    "Invalid mobile network code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_KPP_FIELD_LABEL_TITLE: "KPP",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE: "Beneficiary (59)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE_N2: "Beneficiary's Bank (57)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TITLE: "Ordering Bank (52)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_TITLE:
    "Herewith by this application I confirm that I have read the terms of Public Offer for rendering of service on conversion of fоreign currency of one type into foreign currency of another type, agree with and join them, I will not have any claims against the Bank in the future and give my consent to the direct write-off of the necessary amounts for the services rendered in accordance with the Bank’s Tariffs.",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARYS_BANK_TITLE: "BENEFICIARY'S BANK",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_1_TITLE:
    "Annex # 2 to the Public offer for rendering service on conversion of foreign currency of one type into foreign currency of another type",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_TITLE: "Charges for Transfer (71)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_2_TITLE:
    "Clause on joining to the agreement - Public Offer",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE: "Name",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "Transfer Number",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_FIELD_LABEL_TITLE: "Details (70)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_OR_SERVICES_LABEL_TITLE: "Goods/Services",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SERVICES_LABEL_TITLE: "Services",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_LABEL_TITLE: "Goods",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_IS_IDN_LABEL_TITLE: "For commercial payment",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_PAYMENT_FIELD_LABEL_TITLE: "Details of Payment",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DEBIT_COMMISSION_FIELD_LABEL_TITLE:
    "Please debit commission from our account#",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE: "Name",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE: "Bank Name",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_NAME_FIELD_LABEL_TITLE: "Name",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE: "Tax ID",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_CODE_TITLE: "Country Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_NUMBER_FIELD_LABEL_TITLE: "Phone Number",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PASSWORD_FIELD_LABEL_TITLE: "Passport",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE: "Tax ID",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE: "Address",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE: "Name",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_ADDRESS_FIELD_LABEL_TITLE: "Address",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TAX_ID_FIELD_LABEL_TITLE: "Tax ID",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_PLACE_FIELD_LABEL_TITLE: "Place",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_ADDRESS_FIELD_LABEL_TITLE: "Address",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_REMARKS_FIELD_LABEL_TITLE: "Remarks",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_ADDRESS_FIELD_LABEL_TITLE: "Address",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CORRESPONDENT_ACCOUNT_FIELD_LABEL_TITLE: "Corr. Acc.",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_VO_CODE_LABEL_TITLE: "VO Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE:
    "SWIFT/BIC code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Debit Account",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Current Balance",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Credit Account",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Current balance",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_CODE_FIELD_LABEL_TITLE: "SWIFT Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_DIGIT_FIELD_LABEL_TITLE:
    "Amount in Digit",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE:
    "Amount in Words",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_BIC_CODE_FIELD_LABEL_TITLE:
    "SWIFT / Bic Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Current Balance",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE:
    "Correspondent Bank of Beneficiary's Bank (53)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_REMITTER_CHECKBOX_LABEL_TITLE:
    "All charges are from remitter /OUR/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_CHARGES_OF_JSC_CHECKBOX_LABEL_TITLE:
    'Charges of JSC "KDB Bank Uzbekistan" are from remitter /SHA/',
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FULL_NO_DEDUCT_CHECKBOX_LABEL_TITLE:
    "Full no deduct /FND/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_BENEFICIARY_CHECKBOX_LABEL_TITLE:
    "All charges are from beneficiary /BEN/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE: "SWIFT/BIC code",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_MODAL_NACCODE_TITLE: "Nace Codes",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACCODE_TITLE: "Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACNAME_TITLE: "Description",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_LABEL_TITLE: "Nace Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_HS_CODE_LABEL_TITLE: "HS Code",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_BUTTON_TITLE: "Load",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INV_NUMBER_LABEL_TITLE: "INVN Number",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INVN_DATE_LABEL_TITLE: "INV Date",

  TRANSFERS_CORRESPONDENTS_TITLE: "List of Counterparties",

  TRANSFERS_CORRESPONDENTS_TABLE_TAX_ID_COLUMN_TITLE: "Tax ID",
  TRANSFERS_CORRESPONDENTS_TABLE_CODE_COLUMN_TITLE: "Code",
  TRANSFERS_CORRESPONDENTS_TABLE_DESCRIPTION_COLUMN_TITLE: "Description",
  TRANSFERS_CORRESPONDENTS_TABLE_ACCOUNT_COLUMN_TITLE: "Account",
  TRANSFERS_CORRESPONDENTS_TABLE_BANK_CODE_COLUMN_TITLE: "Bank Code",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_PARTNER_COLUMN_TITLE: "Name of the Bank",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_THE_BANK_COLUMN_TITLE: "Name of Partner",

  TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Subject",
  TRANSFERS_FAVORITES_TABLE_STATUS_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  TRANSFERS_FAVORITES_TABLE_CREATED_BY_BY_COLUMN_TITLE: "Created by",
  TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_DATE_COLUMN_TITLE: "Completed Date",
  TRANSFERS_FAVORITES_TABLE_EXPLANATION_EXPLANATION_COLUMN_TITLE: "Explanation",
  TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_DATE_COLUMN_TITLE: "Date of Submission",

  TRANSFERS_FAVOURITES_MODAL_TAB_TITLE: "Favorites List",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NUMBER_COLUMN_TITLE: "Doc Number",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NAME_COLUMN_TITLE: "Doc Name",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_TYPE_COLUMN_TITLE: "Doc Type",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Debit account",
  TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Beneficiary Name",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Credit account",
  TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CURRENCY_COLUMN_TITLE: "Currency",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE: "Details",
  TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE: "Inquiry",

  TRANSFERS_HISTORY_FILTER_BUTTON_TITLE: "Filter",
  TRANSFERS_HISTORY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "To",
  TRANSFERS_HISTORY_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "From",
  TRANSFERS_HISTORY_FILTER_FORM_STATUS_FIELD_LABEL_TITLE: "Status",
  TRANSFERS_HISTORY_FILTER_FORM_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  TRANSFERS_HISTORY_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "debit only",
  TRANSFERS_HISTORY_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "credit only",
  TRANSFERS_HISTORY_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Search Amount",
  TRANSFERS_HISTORY_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "without details",
  TRANSFERS_HISTORY_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Account Number",
  TRANSFERS_HISTORY_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Account Number",

  TRANSFERS_HISTORY_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Date of Submission",
  TRANSFERS_HISTORY_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_HISTORY_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation",
  TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Details",
  TRANSFERS_HISTORY_TABLE_CREATED_COLUMN_TITLE: "Created by",
  TRANSFERS_HISTORY_TABLE_APPROVER_COLUMN_TITLE: "Authorized by",
  TRANSFERS_HISTORY_TABLE_STATUS__STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_HISTORY_TABLE_SUBJECT_COLUMN_TITLE: "Subject",
  TRANSFERS_HISTORY_TABLE_APPROVED_BY_BY_COLUMN_TITLE: "Authorized by",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_BANK_CODE_COLUMN_TITLE: "Correspondent Bank Code",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_NAME: "Correspondent Name",
  TRANSFERS_HISTORY_TABLE_CREATED_CREATED_COLUMN_TITLE: "Created",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_ACCOUNT: "Correspondent Account Number",
  TRANSFERS_HISTORY_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Beneficiary Account Number",
  TRANSFERS_HISTORY_TABLE_DEBIT_AMOUNT: "Debit Amount",
  TRANSFERS_HISTORY_TABLE_CREDIT_AMOUNT: "Credit Amount",
  TRANSFERS_HISTORY_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Completed Date",

  //Corporate role  Transfers page Payment Claim Tab Received Payment Claims

  TRANSFERS_PAYMENT_CLAIM_SENT_SUBTAB_TITLE: "Sent Payment Claims",
  TRANSFERS_PAYMENT_CLAIM_CREATE_SUBTAB_TITLE: "Create New Payment Claim",
  TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE: "Received Payment Claims",

  TRANSFERS_PAYMENT_CLAIM_TO_FIELD_LABEL_TITLE: "To",
  TRANSFERS_PAYMENT_CLAIM_SEARCH_BUTTON_TITLE: "Search",
  TRANSFERS_PAYMENT_CLAIM_FROM_FIELD_LABEL_TITLE: "From",
  TRANSFERS_PAYMENT_CLAIM_FILTER_FIELD_LABEL_TITLE: "Filter by Status",

  TRANSFERS_PAYMENT_CLAIM_TABLE_TYPE_COLUMN_TITLE: "Type",
  TRANSFERS_PAYMENT_CLAIM_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_PAYMENT_CLAIM_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_PAYMENT_CLAIM_TABLE_DETAILS_COLUMN_TITLE: "Details",
  TRANSFERS_PAYMENT_CLAIM_TABLE_ACTIONS_COLUMN_TITLE: "Actions",
  TRANSFERS_PAYMENT_CLAIM_TABLE_PAYMENT_COLUMN_TITLE: "Payment Claim Number",
  TRANSFERS_PAYMENT_CLAIM_TABLE_SENDER_COLUMN_TITLE: "Sender Account Number",
  TRANSFERS_PAYMENT_CLAIM_TABLE_RECEIVED_COLUMN_TITLE: "Received Date and Time",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Beneficiary Name",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_BANK_COLUMN_TITLE: "Beneficiary Bank Code",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Beneficiary Account Number",
  TRANSFERS_PAYMENT_CLAIM_ACCEPT_BUTTON_TITLE: "",
  TRANSFERS_PAYMENT_CLAIM_REJECT_BUTTON_TITLE: "",

  //Trade finance

  TRADE_FINANCE_HEADER_TITLE: "List of Letter of Credits",
  TRADE_FINANCE_CREDITS_HEADER_TITLE: "Letter of Credits",
  TRADE_FINANCE_CONTRACTS_TABLE_APP_NO_COLUMN_TITLE: "Application №",
  TRADE_FINANCE_CONTRACTS_TABLE_LC_NO_COLUMN_TITLE: "L/C NO.",
  TRADE_FINANCE_CONTRACTS_TABLE_APPLICANT_COLUMN_TITLE: "Applicant",
  TRADE_FINANCE_CONTRACTS_TABLE_BENEFICIARY_COLUMN_TITLE: "Beneficiary",
  TRADE_FINANCE_CONTRACTS_TABLE_CURRENCY_COLUMN_TITLE: "Currency",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRADE_FINANCE_CONTRACTS_TABLE_DATE_COLUMN_TITLE: "Date",
  TRADE_FINANCE_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_APP_BUTTON_TITLE: "New Application",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_AMENDMENT_BUTTON_TITLE: "New Amendment",
  TRADE_FINANCE_CONTRACTS_TABLE_BACK_BUTTON_TITLE: "Back",
  TRADE_FINANCE_SUBMIT_BUTTON_TITLE: "Send",
  TRADE_FINANCE_CONTRACTS_TABLE_ADVISING_BANK_TITLE: "Advising bank",
  TRADE_FINANCE_CONTRACTS_TABLE_ACTIONS_COLUMN_TITLE: "Actions",
  TRADE_FINANCE_CONTRACTS_TABLE_DESCREPENCY_COLUMN_TITLE: "Descrepency",
  TRADE_FINANCE_CONTRACTS_TABLE_MODIFY_COLUMN_TITLE: "Modify",
  TRADE_FINANCE_CONTRACTS_TABLE_SUBMITED_COLUMN_TITLE: "Submited",
  TRADE_FINANCE_DISCREPANCY_LETTER: "Discrepency Letter",
  ACCEPT_DISCREPANCIES_YES_NO: "Accept discrepencies",
  TRADE_FINANCE_ACCEPTED_AMOUNT: "Accepted amount",
  TRADE_FINANCE_DISCREPANCY_MENU_MODAL_LETTER: "Discrepency Menu",
  TRADE_FINANCE_CONTRACTS_TABLE_AMENDMENT_COLUMN_TITLE: "Amendment",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_IN_WORDS: "Amount in word",
  TRADE_FINANCE_CONTRACTS_FORM_NEW_AMOUNT: "New Amount",
  TRADE_FINANCE_CONTRACTS_FORM_NUMBER: "Number",
  TRADE_FINANCE_CONTRACTS_FORM_DATA: "Date",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_NAME: "Applicant Name",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_ADDRESS: "Applicant Address",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_POST_CODE: "Applicant Post Code",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_PHONE: "Applicant Phone",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_NAME: "Beneficiary Name",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_ADDRESS: "Beneficiary Address",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_POST_CODE: "Beneficiary Post Code",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_PHONE: "Beneficiary Phone",
  TRADE_FINANCE_CONTRACTS_FORM_TO: "To Bank",
  TRADE_FINANCE_CONTRACTS_FORM_DATA_OF_EXPIRY: "Date of expiry",
  TRADE_FINANCE_CONTRACTS_FORM_PLACE_OF_EXPIRY: "Place of expiry",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT: "Latest date of shipment ",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT_OR__SHIPMENT_PERIOD:
    " Latest date of shipment or Shipment period",
  TRADE_FINANCE_CONTRACTS_FORM_SHIPMENT_PERIOD: "Shipment period",
  TRADE_FINANCE_CONTRACTS_FORM_PLEASE_OPEN_LETTER_OF_CREDIT: "Please open letter of credit",
  TRADE_FINANCE_CONTRACTS_FORM_IRREVOCABLE: "Irrevocable",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSFERABLE: "Transferable",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTARY: "Documentary",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMED: "Confirmed",
  TRADE_FINANCE_CONTRACTS_FORM_ADVICE_BY: "Advice by",
  TRADE_FINANCE_CONTRACTS_FORM_CABLE_YES_OR_NO: "Cable ",
  TRADE_FINANCE_CONTRACTS_FORM_AIR_MAIL: "Air mail",
  TRADE_FINANCE_CONTRACTS_FORM_CURRENCY: "Currency",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT: "Amout",
  TRADE_FINANCE_CONTRACTS_FORM_ADVISING_BANK: "Advising bank",
  TRADE_FINANCE_CONTRACTS_FORM_TERMS_OF_PAYMET: "Terms of payment",
  TRADE_FINANCE_CONTRACTS_FORM_AGAINST_PRESINTATION_DOCUMENTS:
    "Against presentation of documents (AT SIGHT)",
  TRADE_FINANCE_CONTRACTS_FORM_DEFEREND_PAYMENT: "Deferred payment",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMING_BANK: "Confirming bank",
  TRADE_FINANCE_CONTRACTS_FORM_REIMBURSING_BANK: "Reimbursing bank",
  TRADE_FINANCE_CONTRACTS_FORM_CREDIT_AVAILABLE_WITH_BY: "Credit available with by",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSSHIPMENT: "Transshipment",
  TRADE_FINANCE_CONTRACTS_FORM_PARTIAL_SHIPMENT: "Partial shipment",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSPORATION_FROM: "Transportation from",
  TRADE_FINANCE_CONTRACTS_FORM_TO_PLACE_OF_DELIVERY: "To place of delivery ",
  TRADE_FINANCE_CONTRACTS_FORM_DESCRIPTION_OF_COMMODITY_AND_OR_SERVICES:
    "Description of commodity/services",
  TRADE_FINANCE_CONTRACTS_FORM_DELIVERY_TERMS: "Delivery terms",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTS_REQUIRED: "Documents required",
  TRADE_FINANCE_CONTRACTS_FORM_SPECIAL_INSTRUCTIONS: "Special instructions",
  TRADE_FINANCE_CONTRACTS_FORM_PERIOD_FOR_PRESENTATION_OF_DOCUMENTS:
    "Period for presentation of documents",
  TRADE_FINANCE_CONTRACTS_FORM_LC_ACCOUNT_COVERED_FROM: "LC account covered from",
  TRADE_FINANCE_CONTRACTS_FORM_ACCOUNT_FOR_COMMISSIONS: "Account for commissions",
  TRADE_FINANCE_CONTRACTS_FORM_COMMENTS: "Comments",
  TRADE_FINANCE_FORM_SENDER_LABEL_TITLE: "Sender",
  TRADE_FINANCE_FORM_BENEFICIARY_LABEL_TITLE: "Beneficiary",
  TRADE_FINANCE_FORM_AMOUNTS_LABEL_TITLE: "Amount",
  TRADE_FINANCE_APPLICATION_INFO_SEND: "Application created ",
  TRADE_FINANCE_AMENDMENT_INFO_SEND: "Amendment created",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_BANK_COLUMN_TITLE: "Amount from bank",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_CLIENT_COLUMN_TITLE: "Amount from client",
  TRADE_FINANCE_ACCEPTED_AMOUNT_INFO_FOR_DISCREPANCY: "Your amount big our amount",
  TRADE_FINANCE_CONTRACTS_FORM_CHANGING_CURRENCY: "Change",
  TRADE_FINANCE_CONTRACTS_FORM_YES: "Yes",
  TRADE_FINANCE_CONTRACTS_FORM_NO: "No",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_INCREASE: "Increase",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_DECREASE: "Decrease",

  // payment claim

  PAYMENT_CLAIM_DEPLOY_BUTTON_TITLE: "Upload your file",
  PAYMENT_CLAIM_RECALL: "Revoke",
  PAYMENT_CLAIM_CREATED_SEND: "Payment claim send",
  PAYMENT_CLAIM_CREATED: "Created Payment claim",
  PAYMENT_CLAIM_ERROR_BRANCH: "Messages between branches are not possible",

  // Conversion Page

  CONVERSION_SUBMIT_NOTIFICATION: "Conversion sent",
  CONVERSION_CLIENT_SAVED: "Client`s information saved",
  CONVERSION_CLIENT_NO_CHANGES: "There is no changes in client's information",
  CONVERSION_FAVORITES_TAB_TITLE: "Favorites",
  CONVERSION_EXCHANGE_RATES_TAB_TITLE: "Exchange Rates",
  CONVERSION_FCY_TO_UZS_CONVERSION_TAB_TITLE: "FCY to UZS conversion",
  CONVERSION_UZS_TO_FCY_CONVERSION_TAB_TITLE: "UZS to FCY conversion",
  CONVERSION_FCY_TO_FCY_CONVERSION_TAB_TITLE: "FCY to FCY conversion",
  CONVERSION_FCY_TO_UZS_SELL_TAB_TITLE: "Sell FCY to Bank",
  CONVERSION_UZS_TO_FCY_SELL_TAB_TITLE: "Sell FCY at UzRCE",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_TITLE: "BUY",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_IN_TITLE: "in UZS",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PERCENT_TITLE: "%",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_TITLE: "SELL",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DETAIL_TITLE: "Detail",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_TITLE:
    "Annex #1 to MASTER AGREEMENT on selling foreign currency at Uzbekistan republican currency exchange (UzRCE)",
  CONVERSION_FCY_TO_UZS_FCY_COMMISSION_TITLE: "Commission",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_AMOUNT_LABEL_TITLE: "Buy amount",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_MINIMAL_RATE_LABEL_TITLE: "Minimal Selling Exchange Rate",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_CURRENCY_LABEL_TITLE: "Buy Currency",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_EXCHANGE_RATE_LABEL_TITLE: "Exchange Rate",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DEBIT_ACCOUNT_LABEL_TITLE: "Debit Account",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Sell Amount",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_RATE_LABEL_TITLE: "Commission Rate",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Sell Currency",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_AMOUNT_LABEL_TITLE: "Commission Amount",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_ACCOUNT_LABEL_TITLE: "Commission Account",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PURPOSE_OF_CONVERSION_LABEL_TITLE: "Purpose of Conversion",
  CONVERSION_FCY_TO_UZS_FCY_TO_NUMBER_OF_GENERAL_AGR_LABEL_TITLE: "Number Of General Agreement",
  CONVERSION_FCY_TO_UZS_FCY_TO_NAME_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Name Of The Employee in Charge Of The Deal",
  CONVERSION_FCY_TO_UZS_FCY_TO_DATE_OF_GENERAL_AGR_LABEL_TITLE: "Date Of The General Agreement",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSITION_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Position Of The Employee in Charge Of The Deal",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSTAL_ADDRESS_LABEL_TITLE: "Postal Address",
  CONVERSION_FCY_TO_UZS_FCY_TO_DIRECTOR_OF_THE_ORG_LABEL_TITLE: "Director Of The Organization",
  CONVERSION_FCY_TO_UZS_FCY_TO_TELEPHONE_NUMBER_LABEL_TITLE: "Telephone Number",
  CONVERSION_FCY_TO_UZS_FCY_TO_CHIEF_ACCOUNTANT_OF_THE_ORG_TITLE:
    "Chief Accountant Of The Organization",
  CONVERSION_FCY_TO_UZS_FCY_TO_FAX_NUMBER_TITLE: "Fax Number",
  CONVERSION_FCY_TO_UZS_FCY_TO_SAVE_BUTTON: "Save",
  CONVERSION_FCY_TO_UZS_FCY_TO_CANCEL_BUTTON: "Cancel",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_INFO_OF_CONVERSION_LABEL_TITLE:
    "Conversion amount has been reserved on the following bank transit account",

  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_TITLE: "BUY",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_TITLE: "SELL",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SUBMIT_BUTTON_TITLE: "Submit",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_TITLE:
    "Annex #1 to Public offer for rendering conversion services",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_CURRENCY_FIELD_LABEL_TITLE: "Buy Currency",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Sell Amount",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Sell Currency",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_EXCHANGE_RATE_FIELD_LABEL_TITLE: "Exchange Rate",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Debit Account",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_MINIMAL_SELLING_EXCHANGE_FIELD_LABEL_TITLE:
    "Minimal selling exchange rate",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE: "Credit Account",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_RATE_FIELD_LABEL_TITLE: "Commission Rate",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_AMOUNT_FIELD_LABEL_TITLE: "Commission Amount",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Transaction Date",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_ACCOUNT_FIELD_LABEL_TITLE:
    "Commission Account",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PURPOSE_OF_CONVERSION_FIELD_LABEL_TITLE:
    "Purpose of Conversion",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_AMOUNT_FIELD_LABEL_TITLE: "Buy Amount",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CONVERSION_AMOUNT_ACCOUNT_FIELD_LABEL_TITLE:
    "Conversion amount has been reserved on the following bank transit account",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PERCENT_TITLE: "%",

  CONVERSION_FCY_TO_UZS_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_TO_FIELD_LABEL_TITLE: "to",
  CONVERSION_FCY_TO_UZS_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  CONVERSION_FCY_TO_UZS_TABLE_MINIMUM_RATE_COLUMN_TITLE: "Minimum Rate",
  CONVERSION_FCY_TO_UZS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Exchange Rate",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "From",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_CREATED_BY_FIELD_LABEL_TITLE: "Created by",
  CONVERSION_FCY_TO_UZS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Buying Amount in UZS",
  CONVERSION_FCY_TO_UZS_TABLE_COMMISSION_RATE_COLUMN_TITLE: "Commission Rate",
  CONVERSION_FCY_TO_UZS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Debit Account in FCY",
  CONVERSION_FCY_TO_UZS_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Credit Account in UZS",
  CONVERSION_FCY_TO_UZS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Selling Amount in USD",
  CONVERSION_FCY_TO_UZS_TO_BANK_BUTTON_GROUP_TITLE: "Create Request for Selling FCY",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_FIND_DOCUMENTS_FIELD_LABEL_TITLE: "Find documents with status",
  CONVERSION_FCY_TO_UZS_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bank Comments",

  CONVERSION_FCY_TO_UZS_ON_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on business days: 9 a.m. - 4.30 p.m. (Tashkent time)",  
  CONVERSION_FCY_TO_UZS_ON_SHORTENED_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on pre-holiday/shortened business days: 9 a.m. - 3.30 p.m. (Tashkent time)",  

  CONVERSION_UZS_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_UZS_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bank Comments",
  CONVERSION_UZS_TO_FCY_TABLE_TITLE: "Client Information",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_COLUMN_TITLE: "Number",
  CONVERSION_UZS_TO_FCY_CREATE_REQUEST_AT_UZ_RCE_BUTTON_TITLE:
    "Create Request For Buying  FCY at UzRce",

  CONVERSION_UZS_TO_FCY_ON_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on business days: 9 a.m. - 4.30 p.m. (Tashkent time)",  
  CONVERSION_UZS_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on pre-holiday/shortened business days: 9 a.m. - 3.30 p.m. (Tashkent time)",  
  
  CONVERSION_UZS_TO_FCY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "To",
  CONVERSION_UZS_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  CONVERSION_UZS_TO_FCY_TABLE_BUY_AMOUNT_COLUMN_TITLE: "Buy Amount",
  CONVERSION_UZS_TO_FCY_TABLE_COMMISSION_COLUMN_TITLE: "COMMISSION",
  CONVERSION_UZS_TO_FCY_TABLE_DETAILS_COLUMN_TITLE: "DETAILS",
  CONVERSION_UZS_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Maximum rate",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_UZS_COLUMN_TITLE: "Account in UZS",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_COLUMN_TITLE: "Account in FCY",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_AMOUNT_COLUMN_TITLE: "Maximum Amount in UZS",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "№ and date of the contract",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_COLUMN_TITLE: "Reserve Account in UZS",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVED_AMOUNT_IN_UZS_COLUMN_TITLE: "Reserved Amount in UZS",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_COLUMN_TITLE: "Conversion account in FCY",
  CONVERSION_UZS_TO_FCY_TABLE_DEBIT_ACCOUNT_IN_UZS_TITLE: "Debit Account In UZS",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_TITLE: "Reserve Account In UZS",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_TITLE: "Conversion Account In FCY",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_TITLE: "Account In FCY",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_EQUIPMENT: "1) for import of equipment;",
  CONVERSION_UZS_TO_FCY_PURPOSE: "Purpose",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_COMPONENTRY: "2) for import of componentry;",
  CONVERSION_UZS_TO_FCY_RAW_MATERIALS: "3) for import of raw materials;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_SERVICE: "4) for import of services;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_CONSUMER_GOODS: "5) for import of consumer goods;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_SERVE_ATTACHED: "6) to serve attracted foreign credits;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_EFFECT_LEASING_PAYMENTS:
    "7) to effect leasing payments under leasing agreements;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION:
    "8) for repatriation of profit, dividends and other foreign investors' gains;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION_OF_EXCESS:
    "9) for repatriation of excess revenue over the expenses;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_PAY_TRAVELLING: "10) to pay travelling expenses;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_TRANSFERRING_FUNDS:
    "11) for transferring funds received from the sale of goods at the open electronic trades on the commodity exchange;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_OTHER: "12) for other purposes;",

  CONVERSION_UZS_TO_FCY_TITLE:
    "We are instructing to conclude on our behalf and for our account a deal for buying foreign currency on the following terms",
  CONVERSION_UZS_TO_FCY_ATTACHE_TITLE: "Supporting Documents",
  CONVERSION_UZS_TO_FCY_FORM_BUY_AMOUNT: "Buy Amount",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_BUY_RATE: "Maximum Buy Rate in UZS",
  CONVERSION_UZS_TO_FCY_FORM_RESERVED_AMOUNT: "Total Amount of Purchase in UZS at the Buy Rate",
  CONVERSION_UZS_TO_FCY_FORM_NUMBER_AND_DATE: "№ And Date Of The Contract",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_AMOUNT_UZS:
    "Amount (in national currency - UZS) at the rate mentioned in the application",
  CONVERSION_UZS_TO_FCY_FORM_BANKS_COMMISSION: "Bank's Commission For Conversion",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_TITLE: "Attached Documents",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_1: "Name of Document №1",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_2: "Name of Document №2",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_3: "Name of Document №3",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_4: "Name of Document №4",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_5: "Name of Document №5",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_6: "Name of Document №6",
  CONVERSION_ATTECH_TITLE:
    "Please send supporting documents for conversion to the following email address of Currency Control Department",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_BUTTON: "Attache",
  CONVERSION_UZS_TO_FCY_SUBMIT_ERROR: "The exchange rate for this currency is not set",
  CONVERSION_UZS_TO_FCY_FORM_SUBMIT_BUTTON: "Submit",
  CONVERSION_UZS_TO_FCY_CLIENT_INFO: "Client`s Information",
  CONVERSION_UZS_TO_FCY_FAVORITES: "Favorites FCY to UZS",
  CONVERSION_UZS_TO_FCY_PRINT: "Print",
  CONVERSION_UZS_TO_FCY_SAVE_FAVORITES: "Save as Favorite",
  CONVERSION_UZS_TO_FCY_UPLOAD_FROM_EXCEL: "Upload from Excel",
  CONVERSION_UZS_TO_FCY_SAVE_PDF: "Save to PDF",
  CONVERSION_MAIN_HEADER_TITTLE_UZS_TO_FCY_PRINT_TABLE:
    "APPLICATION FOR PURCHASE OF FOREIGN CURRENCY",
  CONVERSION_NAME_OF_APPLICANT_UZS_TO_FCY_PRINT_TABLE: "Name of the applicant:",
  CONVERSION_TAX_ID_UZS_TO_FCY_PRINT_TABLE: "Tax ID:",
  CONVERSION_PHONE_UZS_TO_FCY_PRINT_TABLE: "Contacts: Phone #",
  CONVERSION_PURPOSE_UZS_TO_FCY_PRINT_TABLE:
    "Purpose of use of purchased foreign currency (underline and indicate # and date of document):",
  CONVERSION_A_UZS_TO_FCY_PRINT_TABLE: "a) import of equipment (# and date of the contract);",
  CONVERSION_B_UZS_TO_FCY_PRINT_TABLE: "b) import of equipment components;",
  CONVERSION_C_UZS_TO_FCY_PRINT_TABLE: "c) import of raw materials and supplies;",
  CONVERSION_D_UZS_TO_FCY_PRINT_TABLE: "d) import of services;",
  CONVERSION_E_UZS_TO_FCY_PRINT_TABLE: "e) import of consumer goods;",
  CONVERSION_F_UZS_TO_FCY_PRINT_TABLE: "f) servicing of foreign loans;",
  CONVERSION_G_UZS_TO_FCY_PRINT_TABLE: "g) leasing payments under leasing agreements;",
  CONVERSION_H_UZS_TO_FCY_PRINT_TABLE:
    "h) repatriation of profits, dividends and other income of a foreign investor;",
  CONVERSION_I_UZS_TO_FCY_PRINT_TABLE:
    "i) repatriation of excess income over expenses of designated are carriers;",
  CONVERSION_J_UZS_TO_FCY_PRINT_TABLE: "j) business trip expenses;",
  CONVERSION_K_UZS_TO_FCY_PRINT_TABLE:
    "k) transfer of funds received from the sale of products at open electronic trading of the commodity exchanges;",
  CONVERSION_L_UZS_TO_FCY_PRINT_TABLE:
    "l) others (indicate):  _________________________________________________",
  CONVERSION_NAME_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Name and amount of Foreign Currency",
  CONVERSION_MAX_PURCHASE__UZS_TO_FCY_PRINT_TABLE: "Maximum purchase rate in UZS",
  CONVERSION_TOTAL_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Total amount of purchase at the rate in UZS",
  CONVERSION_COVER_ON_UZS_TO_FCY_PRINT_TABLE: "(*) Cover on 22613",
  CONVERSION_CONFIRM_UZS_TO_FCY_PRINT_TABLE: "Yes/No",
  CONVERSION_INSTRUCT_TO_DEBIT_UZS_TO_FCY_PRINT_TABLE:
    "In case of bargain, we instruct to debit our UZS account 22613000___________________________________ the equivalent of the purchased foreign currency in UZS at the purchase rate;",
  CONVERSION_ATTACHED_DOC_UZS_TO_FCY_PRINT_TABLE: "Attached documents:",
  CONVERSION_PUBLIC_OFFER_UZS_TO_FCY_PRINT_TABLE:
    "Signing this Application means the unconditional acceptance by Client of all conditions of the Public offer for rendering conversion services published on Bank's website (www.kdb.uz) as of the date of signing this Application, as well as Client's consent to the direct debit of the required amounts for services rendered in accordance with Bank tariffs and  with this Application.",
  CONVERSION_DIRECTOR_UZS_TO_FCY_PRINT_TABLE: "Director",
  CONVERSION_FULL_NAME_UZS_TO_FCY_PRINT_TABLE: "Full name",
  CONVERSION_CHIEF_ACCOUNTANT_UZS_TO_FCY_PRINT_TABLE: "Chief accountant",
  CONVERSION_RESPONSIBLE_UZS_TO_FCY_PRINT_TABLE: "Responsible CCD employee of the Brach",
  CONVERSION_HEAD_UZS_TO_FCY_PRINT_TABLE: "Head of CCD of the Branch",
  CONVERSION_HEAD_OF_BRANCH_UZS_TO_FCY_PRINT_TABLE: "Head of Branch",
  CONVERSION_HEAD_OF_THE_HO_UZS_TO_FCY_PRINT_TABLE: "Head of CCD of the HO",
  CONVERSION_DEPUTY_UZS_TO_FCY_PRINT_TABLE: "Deputy Chairman",


  CONVERSION_FCY_TO_FCY_ON_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on business days: 9 a.m. - 4.30 p.m. (Tashkent time)",  
  CONVERSION_FCY_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Cut-off time for FCY Transfer Applications on pre-holiday/shortened business days: 9 a.m. - 3.30 p.m. (Tashkent time)",

  CONVERSION_FCY_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_CREDIT_DEBIT_ARE_SAME: "Credit and Debit accounts are same",
  CONVERSION_FCY_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  CONVERSION_FCY_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  CONVERSION_FCY_TO_FCY_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Exchange Rate",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Buying Amount",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Selling Amount",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Buying Currency",
  CONVERSION_FCY_TO_FCY_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Document Number",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Selling Currency",
  CONVERSION_FCY_TO_FCY_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Credit Account in FCY ",
  CONVERSION_FCY_TO_FCY_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Debit Account in FCY",
  CONVERSION_FCY_TO_FCY_CREATE_APPLICATION_BUTTON_GROUP_TITLE:
    "Create new application for FCY to FCY conversion",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_TITLE: "Clause on joining to the agreement - Public Offer",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_SUBTITLE:
    "Herewith by this application I confirm that I have read the terms of Public Offer for rendering of service on conversion of fоreign currency of one type into foreign currency of another type, agree with and join them, I will not have any claims against the Bank in the future and give my consent to the direct write-off of the necessary amounts for the services rendered in accordance with the Bank’s Tariffs.",
  CONVERSION_FCY_TO_FCY_TITLE:
    "Annex 1 to the Public offer for rendering service on conversion of foreign currency of one type into foreign currency of another type",
  CONVERSION_FCY_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bank Comments",

  CONVERSION_FAVORITES_TABLE_DETAILS_COLUMN_TITLE: "Details",
  CONVERSION_FAVORITES_TABLE_NAME_COLUMN_TITLE: "Document Name",
  CONVERSION_FAVORITES_TABLE_NUMBER_COLUMN_TITLE: "Number",
  CONVERSION_FAVORITES_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Document Name",
  CONVERSION_FAVORITES_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Debit Account",
  CONVERSION_FAVORITES_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Buying Amount",
  CONVERSION_FAVORITES_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Selling Amount",
  CONVERSION_FAVORITES_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Credit Account",
  CONVERSION_FAVORITES_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Buying Currency",
  CONVERSION_FAVORITES_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Selling Currency",

  CONVERSION_MINIMUM_RATE_NULL_TITLE: "The exchange rate for this currency is not set",
  CONVERSION_IDN_NUMBER_TITLE: "Idn Number",
  CONVERSION_IDN_NUMBER_TIN_OF_BANK_FIELD_TITLE: "TIN of Bank",
  CONVERSION_IDN_NUMBER_TIN_OF_CUSTOMER_FIELD_TITLE: "TIN of Customer",
  CONVERSION_IDN_NUMBER_CONTRACT_NUMBER_TITLE: "The remaining 9 digits of the IDN",

  // YEISVO PAGE
  YEISVO_HEADER_TITLE: "List of Contracts",
  YEISVO_HEADER_SEARCH_BUTTON_TITLE: "Search",

  YEISVO_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  YEISVO_CONTRACTS_TABLE_BANK_CODE_COLUMN_TITLE: "Bank Code",
  YEISVO_CONTRACTS_TABLE_IDN_CONTRACT_COLUMN_TITLE: "IDN Contract",
  YEISVO_CONTRACTS_TABLE_CONTRACT_NUMBER_COLUMN_TITLE: "Contract Number",
  YEISVO_CONTRACTS_TABLE_DATE_OF_CONTRACT_COLUMN_TITLE: "Date of Contract",
  YEISVO_CONTRACTS_TABLE_BANK_INN_COLUMN_TITLE: "Bank INN",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_FOR_NAME_COLUMN_TITLE: "Foreign partner",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_UZ_NAME_COLUMN_TITLE: "Contract owner",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_COUNTRY_COLUMN_TITLE: "Foreign Partner Country",

  YEISVO_CLIENTS_INFORMATION_TITLE: "Contract's Information",
  YEISVO_CLIENTS_INFORMATION_BANK_FUNDS_TITLE: "Receipt of funds",
  YEISVO_CLIENTS_INFORMATION_BANK_EMPLOYEE_FULL_NAME: "FULL NAME bank employee",
  YEISVO_CLIENTS_INFORMATION_NUMBER_IN_ABS: "ABS payment number",
  YEISVO_CLIENTS_INFORMATION_CONDITIONS: "Admission conditions",
  YEISVO_CLIENTS_INFORMATION_OPENING_DATE: "Receipt date",
  YEISVO_CLIENTS_INFORMATION_INVOICE_VALUE: "Invoice value",
  YEISVO_CLIENTS_INFORMATION_AMOUNT: "Quantity",
  YEISVO_CLIENTS_INFORMATION_MEASURE: "Measurement",
  YEISVO_CLIENTS_INFORMATION_WEIGHT: "Weight",
  YEISVO_CLIENTS_INFORMATION_TN_VED: "TN VED",
  YEISVO_CLIENTS_INFORMATION_SERIAL_NUMBER: "Serial number",
  YEISVO_CLIENTS_INFORMATION_NAME: "Name of product",
  YEISVO_CLIENTS_INFORMATION_DECLARATION_TITLE: "Information on the cargo customs declaration",
  YEISVO_CLIENTS_INFORMATION_CONTRACT_CURRENCY_CODE: "Contract currency code",
  YEISVO_CLIENTS_INFORMATION_TOTAL_INVOICE_VALUE: "Total invoice value",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_REGIME_CODE: "Customs regime code",
  YEISVO_CLIENTS_INFORMATION_REGISTERATION_DATE: "Date of registration of the declaration",
  YEISVO_CLIENTS_INFORMATION_REGISTERED_NUMBER: "Declaration registration number",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_OFFICE_CODE: "Customs post code",
  YEISVO_CLIENTS_INFORMATION_FORM_TITLE: "Client's Information",
  YEISVO_CLIENTS_INFORMATION_FORM_OKPO_FIELD_LABEL_TITLE: "OKPO",
  YEISVO_CLIENTS_INFORMATION_FORM_OKONH_FIELD_LABEL_TITLE: "OKONH",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_FIELD_LABEL_TITLE: "Contract #",
  YEISVO_CLIENTS_INFORMATION_FORM_IDN_CONTRACT_FIELD_LABEL_TITLE: "IDN Contract",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_TIN_FIELD_LABEL_TITLE: "Contractor TIN",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_AREA_FIELD_LABEL_TITLE: "Contractor Address",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_PHONE_FIELD_LABEL_TITLE: "Contractor Phone",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_OF_CONTRACT_FIELD_LABEL_TITLE: "Date of contract",
  YEISVO_CLIENTS_INFORMATION_FORM_TYPE_OF_CONTRACT_FIELD_LABEL_TITLE: "Type of Contract",
  YEISVO_CLIENTS_INFORMATION_FORM_SUBJECT_OF_CONTRACT_FIELD_LABEL_TITLE: "Subject of contract",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE: "Contract Currency 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Name of the bank of the foreign partner",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_AND_TIME_YEISVO_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CLIENTS_INFORMATION_FORM_TERM_OF_THE_CONTRACT_FIELD_LABEL_TITLE: "Term of the contract",
  YEISVO_CLIENTS_INFORMATION_FORM_GENERAL_NAME_OF_GOODS_FIELD_LABEL_TITLE: "General name of goods",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_1_FIELD_LABEL_TITLE: "Settlement Currency 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_CONTRACT_HOLDER_FIELD_LABEL_TITLE:
    "Name of contract holder",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_FOREIGN_PARTNER_FIELD_LABEL_TITLE:
    "Name of foreign partner",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Foreign partner country",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_PARTNER_FIELD_LABEL_TITLE:
    "Legal address of a foreign partner",
  YEISVO_CLIENTS_INFORMATION_FORM_SIGN_OF_CONTRACT_ACTIVITY_FIELD_LABEL_TITLE:
    "Sign of contract activity",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Contract amount in currency 1",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Contract amount in currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_PARENT_ORGANIZATION_FIELD_LABEL_TITLE:
    "Name of parent organization of the contract holder",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_1_FIELD_LABEL_TITLE:
    "Amount payable in contract currency 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_2_FIELD_LABEL_TITLE:
    "Amount payable in contract currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_BANK_CODE_OF_AN_AUTHORIZED_BANK_FIELD_LABEL_TITLE:
    "Bank code of an authorized bank",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_1_FIELD_LABEL_TITLE:
    "Amount of receivables in the currency of the contract 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Amount of receivables in contract currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Amount of overdue payables in the currency of the contract 1",
  YEISVO_CLIENTS_INFORMATION_FORM_TOTAL_AMOUNT_OF_CCD_FIELD_LABEL_TITLE: "Total amount of CCD in",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Amount of overdue payables in contract currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Amount of overdue receivables in contract currency 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Amount of overdue receivables in contract currency 2",

  YEISVO_CONTRACT_SPECIFICATIONS_TITLE: "Contract specification",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Serial number",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_TAG_FIELD_LABEL_TITLE: "Specification Tag",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_DATE_FIELD_LABEL_TITLE: "Specification Date",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_NUMBER_FIELD_LABEL_TITLE:
    "Specification Number",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Date and time of entry",

  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DOC_DATE_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_AMOUNT_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_AGRM_NO_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_CURR_CODE_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_CST_DATE_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DOC_TYPE_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DOC_NO_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SERIAL_NO_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_IM_EX_FLAG_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_INS_TIME_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_OLD_NO_TITLE: "",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_STATUS_TITLE: "",

  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_TN_CODE_COLUMN_TITLE:
    "Product code according to the HS",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_ITEMS_NAME_COLUMN_TITLE: "Product name",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_SERIAL_NO_COLUMN_TITLE: "Serial number",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_UNIT_CODE_COLUMN_TITLE: "Unit code",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_QUANTITY_AGRMNO_COLUMN_TITLE: "Product Volume",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_CURR_CODE_COLUMN_TITLE: "Item price currency",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_COST_COLUMN_TITLE: "Unit Price",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_AMOUNT_COLUMN_TITLE: "Total item cost",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_INS_TIME_COLUMN_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_STATUS_COLUMN_TITLE: "Document Status",

  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_COLUMN_TITLE: "Customs post code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_COLUMN_TITLE: "Declaration registration number",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_COLUMN_TITLE: "Declaration registration date",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_COLUMN_TITLE: "Sender's country code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_COLUMN_TITLE: "Transaction nature code",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_COLUMN_TITLE: "Ship Date",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_COLUMN_TITLE: "Date of crossing the customs border",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_COLUMN_TITLE: "Exporter Name",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_COLUMN_TITLE: "Contract currency rate",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_COLUMN_TITLE: "Importer name",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_COLUMN_TITLE: "Name of the contract holder",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_COLUMN_TITLE: "Customs regime",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_COLUMN_TITLE: "Contract currency code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_COLUMN_TITLE: "Total invoice value",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_COLUMN_TITLE: "Departure country code",

  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G32_COLUMN_TITLE: "Serial number",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G33_COLUMN_TITLE:
    "Product code according to the Commodity Nomenclature of Foreign Economic Activity",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G42_COLUMN_TITLE: "Invoice value",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37C_COLUMN_TITLE: "Procedure Code",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31NAME_COLUMN_TITLE: "Product Name",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31AMOUNT_COLUMN_TITLE: "quantity of goods received",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G41_COLUMN_TITLE: "Additional unit of measure code",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G38_COLUMN_TITLE: "Item weight (net)",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37B_COLUMN_TITLE: "Departure country code",

  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TITLE:
    "Basis for the delivery of goods according to Incoterms",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DIGITAL_CODE_TITLE: "Digital delivery code",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DESTINATION_TITLE: "Destination",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE:
    "Additional agreement number",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SERIAL_NUMBER_TITLE: "Serial number",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Date and time of entry",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_CONTRACT_ACT_FORM_TITLE: "Act for the provision of services/works",
  YEISVO_CONTRACT_CALC_FORM_TITLE: "Form of calculations",
  YEISVO_CONTRACT_CALC_FORM_TABLE_PREPAYMENT_TERMS_TITLE: "Terms of payment",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DUE_DATE_TITLE: "Due date",
  YEISVO_CONTRACT_CALC_FORM_TABLE_LAST_PAYMENT_DATE_TITLE: "Last payment date",
  YEISVO_CONTRACT_CALC_FORM_TABLE_TIME_OF_IMPORT_AFTER_PAYMENT_TITLE:
    "Time of import after payment",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_OF_IMPORT_AFTER_PAYMENT_TITLE:
    "Date of import after payment",
  YEISVO_CONTRACT_CALC_FORM_TABLE_IMPORT_PERIOD_AFTER_LETTER_OF_CREDIT_OPENING_TITLE:
    "Import period after letter of credit opening",
  YEISVO_CONTRACT_CALC_FORM_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE:
    "Supplementary agreement number",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Date and time of entry",
  YEISVO_CONTRACT_CALC_FORM_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_PAYMENT_BY_CONTRACT_TITLE: "Payment by contract",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_FIELD_LABEL_TITLE: "Date of payment",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_FUNDS_SOURCE_FILED_LABEL_TITLE: "Source of funds",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_NUMBER_IN_ABC_FIELD_LABEL_TITLE: "Payment number in ABC",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Serial number",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_BANK_EMPLOYEE_FULLNAME_FIELD_LABEL_TITLE:
    "Full name of the bank employee",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Date and time of entry",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_TABLE_ID_FIELD_LABEL_TITLE: "#",
  YEISVO_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",
  YEISVO_TABLE_TN_VED_FIELD_LABEL_TITLE: "TN VED",
  YEISVO_TABLE_VOLUME_FIELD_LABEL_TITLE: "Volume",
  YEISVO_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  YEISVO_TABLE_UNIT_PRICE_FIELD_LABEL_TITLE: "Price",
  YEISVO_TABLE_TOTAL_COST_FIELD_LABEL_TITLE: "Total cost",
  YEISVO_TABLE_MEASUREMENT_FIELD_LABEL_TITLE: "Measurement",
  YEISVO_TABLE_NAME_OF_PRODUCT_FIELD_LABEL_TITLE: "Name of Product",
  YEISVO_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE: "Date and time of entry",
  YEISVO_REGION_FIELD_LABEL_TITLE: "Region",

  /// New Yeisvo

  YEISVO_CONTRACT_AGREEMENTS_TITLE: "Additional agreement",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Additional Agreement Date",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_MVESDATE_FIELD_LABEL_TITLE:
    "Date of registration of the additional agreement in MFERIT (not used)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AGRMSUBJECT_FIELD_LABEL_TITLE:
    "Subject of the additional agreement",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Contract currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Contract currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE1_FIELD_LABEL_TITLE: "Settlement currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE2_FIELD_LABEL_TITLE: "Settlement currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORNAME_FIELD_LABEL_TITLE: "Name of foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of foreign partner (dicCountry directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Name of the foreign partner's bank",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of the foreign partner's bank (dicCountry directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the foreign partner's bank",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE:
    "Bank details of a foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORUZNAME_FIELD_LABEL_TITLE:
    "Name of the contract holder",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Contract amount in contract currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Contract amount in contract currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CNTRSUBJECT_FIELD_LABEL_TITLE:
    "Contract subject (dicContractSubject directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_PRODUCTSNAME_FIELD_LABEL_TITLE: "Common name of goods",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECTYPE_FIELD_LABEL_TITLE:
    "Contract performance period (dicTerm directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECDATE_FIELD_LABEL_TITLE: "Contract Execution Date",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CREDITRATE_FIELD_LABEL_TITLE: "Loan percentage (per annum)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CSTDATE_FIELD_LABEL_TITLE:
    "Date of registration of the additional agreement with the customs authorities",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Additional agreement serial number",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Number according to the directory of industrial zones",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_BARTERTERM_TITLE: "Form of mutual settlements",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Payment type",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Last export date",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Export term",
  YEISVO_CONTRACT_BARTERTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE: "Import term",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_BARTERTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_BARTERTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_BARTERTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_COMMISSIONS_TITLE: "Foreign bank commission for funds transfer",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Commission amount in receipt currency",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Payment number from which the commission is deducted",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Fund receipt number from which the commission is deducted",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_COMPENSATIONS_TITLE: "Receipt of insurance compensation under the contract",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of receipt of insurance compensation",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_POLICYNO_FIELD_LABEL_TITLE:
    "Insurance policy number for which compensation was received",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of insurance compensation",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Insurance compensation currency",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_CONFIRMATION_TITLE: "Confirmations",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the user who confirmed/did not confirm the document",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_CONFIRM_FIELD_LABEL_TITLE: "confirmation sign",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ORGTYPE_FIELD_LABEL_TITLE: "organization type",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_REASON_FIELD_LABEL_TITLE:
    "grounds for not confirming the document",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_DATE_FIELD_LABEL_TITLE: "confirmation date and time",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ACTIONTYPE_FIELD_LABEL_TITLE: "Editing sign",

  YEISVO_CONTRACT_CONSIGNEE_TITLE: "Consignee",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_NAME_FIELD_LABEL_TITLE: "Name of the consignee",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Consignee Country",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_CONSIGNOR_TITLE: "Shipper",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_NAME_FIELD_LABEL_TITLE: "Item Sender Name",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Item Sender Country",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_DECLARATION_TITLE: "Shipping Details",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_FIELD_LABEL_TITLE: "Customs post code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_FIELD_LABEL_TITLE: "Declaration registration number",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_FIELD_LABEL_TITLE: "Declaration registration date",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_FIELD_LABEL_TITLE: "Sender Country Code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_FIELD_LABEL_TITLE: "Transaction nature code",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_FIELD_LABEL_TITLE: "Ship Date",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_FIELD_LABEL_TITLE:
    "Date of crossing the customs border",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_FIELD_LABEL_TITLE: "Name of exporter (sender)",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_FIELD_LABEL_TITLE: "Contract currency rate",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_FIELD_LABEL_TITLE: "Importer (recipient) name",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_FIELD_LABEL_TITLE: "Name of contract holder",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_FIELD_LABEL_TITLE: "Customs regime",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_FIELD_LABEL_TITLE: "Contract currency code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_FIELD_LABEL_TITLE: "Total invoice value",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_FIELD_LABEL_TITLE: "Departure country code",

  YEISVO_CONTRACT_DECLARATIONGOOD_TITLE: "Goods according to declaration",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G32_FIELD_LABEL_TITLE: "Item serial number",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G33_FIELD_LABEL_TITLE:
    "Product code according to the Commodity Nomenclature of Foreign Economic Activity",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G42_FIELD_LABEL_TITLE: "Invoice value",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37C_FIELD_LABEL_TITLE: "Procedure Code",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31NAME_FIELD_LABEL_TITLE: "Product Name",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31AMOUNT_FIELD_LABEL_TITLE: "quantity of goods received",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G41_FIELD_LABEL_TITLE: "Additional unit code",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G38_FIELD_LABEL_TITLE: "Item weight (net)",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37B_FIELD_LABEL_TITLE: "Preceding mode code",

  YEISVO_CONTRACT_DELEGATE_TITLE:
    "Transfer of rights and obligations under the contract to another resident",
  YEISVO_CONTRACT_DELEGATE_TABLE_REASONTYPE_FIELD_LABEL_TITLE: "Basis for transition",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTNAME_FIELD_LABEL_TITLE:
    "Name of the court that made the decision",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTDSCNDATE_FIELD_LABEL_TITLE: "Court decision date",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNNO_FIELD_LABEL_TITLE:
    "Decision number of the Government of the Republic of Uzbekistan",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNDATE_FIELD_LABEL_TITLE:
    "Date of decision of the Government of the Republic of Uzbekistan",
  YEISVO_CONTRACT_DELEGATE_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINN_FIELD_LABEL_TITLE: "TIN/PINFL of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKID_FIELD_LABEL_TITLE: "MFI bank of the successor",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKPO_FIELD_LABEL_TITLE:
    "OKPO code of the legal successor (not used)",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKONX_FIELD_LABEL_TITLE: "OKED code of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTNAME_FIELD_LABEL_TITLE: "Name of assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTRGNCODE_FIELD_LABEL_TITLE: "Designee Area",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Belonging of the legal successor to the industrial zone",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTADDRESS_FIELD_LABEL_TITLE: "Address of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTPHONE_FIELD_LABEL_TITLE: "Telephone of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_DELEGATE_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in YEISVO",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "A sign of confirmation of the transfer of the contract by the transferring bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "A sign of confirmation of the transfer of the contract by the receiving bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the transferring bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the receiving bank user",
  YEISVO_CONTRACT_DELEGATE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_DELEGATE_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "IDN of the contract before the transfer of rights and obligations",
  YEISVO_CONTRACT_DELEGATE_TABLE_PARENTCOMPANYCODE_FIELD_LABEL_TITLE:
    "Code of the ministry/department that includes the economic entity,",
  YEISVO_CONTRACT_DELEGATE_TABLE_NEWIDN_FIELD_LABEL_TITLE:
    "IDN of the new contract (after transfer)",
  YEISVO_CONTRACT_DELEGATE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKINN_FIELD_LABEL_TITLE: "TIN of the successor bank",

  YEISVO_CONTRACT_DELIVERYTERM_TITLE: "Basis for delivery of goods according to Incoterms",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_DESTINATION_FIELD_LABEL_TITLE: "Destination",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INCOTERMSCODE_FIELD_LABEL_TITLE: "Digital delivery code",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_ENDCONTRACT_TITLE: "End contract operations",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_DOCDATE_FIELD_LABEL_TITLE: "End Date",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_REMARK_FIELD_LABEL_TITLE: "Notes",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date entered into the system",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_EXPORTTERM_TITLE: "Export Terms",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTYPE_FIELD_LABEL_TITLE: "Export Type",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Last export date",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Export term",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGDEADLINE_FIELD_LABEL_TITLE: "Lease term",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGRATE_FIELD_LABEL_TITLE: "Leasing percentage",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_DEADLINETYPE_FIELD_LABEL_TITLE: "Revenue due date",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_FARE_TITLE: "Freight to specification",
  YEISVO_CONTRACT_FARE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Freight Charge Currency",
  YEISVO_CONTRACT_FARE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of transport costs",
  YEISVO_CONTRACT_FARE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_FARE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_FINANCEINFO_TITLE: "Fund Sender",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORNAME_FIELD_LABEL_TITLE: "Sender Name",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORCOUNTRYCODE_FIELD_LABEL_TITLE: "Sender Country",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORADDRESS_FIELD_LABEL_TITLE: "Sender's legal address",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORNAME_FIELD_LABEL_TITLE: "Name of the sender's bank",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE: "Sender's bank country",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the sender's bank",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE: "Sender's bank details",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_GOODS_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_TNCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_ITEMSNAME_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_UNITCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_QUANTITY_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_COST_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_INSTIME_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_STATUS_FIELD_LABEL_TITLE: "",

  YEISVO_CONTRACT_GUARANTEE_TITLE: "Foreign bank guarantee",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Guarantee number",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE: "Guarantee opening date",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Guarantee currency",
  YEISVO_CONTRACT_GUARANTEE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the guarantee currency to the contract currency",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Warranty expiration date (date)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Warranty expiration period (number of days)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_GUARANTEE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_GUARANTEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TITLE: "Change guarantee amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Valid Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "New Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the guarantee amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TITLE: "Changing the foreign bank guarantee period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Current warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "New warranty expiration date (date)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New warranty expiration date (number of days)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_IMPORTTERM_TITLE: "Settlement Form",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Import Type",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE: "Payment term",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE: "Last payment date",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE:
    "Import period after payment (number of days)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTDEADLINE_FIELD_LABEL_TITLE:
    "Import date after payment (date)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERMAFTERACCR_FIELD_LABEL_TITLE:
    "Import period after opening of letter of credit (number of days)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_LEASEPAYMENTS_TITLE: "Receipt of interest under the leasing agreement",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of receipt (payment)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Interest amount (leasing amount)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Receipt (payment) currency",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TITLE: "Change letter of credit amount",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Valid amount of letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "New letter of credit amount in letter of credit currency",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the amount of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TITLE: "Changing the term of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current validity period of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New validity period of the letter of credit (number of days)",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the validity period of a letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_LETTERSOF_CREDIT_TITLE: "Letter of Credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCNO_FIELD_LABEL_TITLE: "Letter of Credit Number",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE:
    "Date of opening of letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Letter of Credit Currency",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Amount of the letter of credit in the currency of the letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Validity period of letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_MANUFACTURER_TITLE: "Manufacturer",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_NAME_FIELD_LABEL_TITLE: "Name",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Country",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_MOVESFROM_EX_TITLE: "Transfer of funds from another contract (mutual settlement)",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Move date",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Settlement currency",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Move Amount",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "IDN of the export contract from which the funds were transferred",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for work (service) in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for work (service) in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "The ratio of the settlement currency to the contract currency",

  YEISVO_CONTRACT_MOVESFROM_IM_TITLE: "Move funds from another contract",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Move date",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Move Amount",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Settlement currency",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "IDN of the import contract from which funds were transferred",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for services (work) in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for services (work) in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "Ratio of settlement currency to contract currency",

  YEISVO_CONTRACT_MOVESTO_EX_TITLE: "Move funds to another contract",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on assets to be transferred",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of transfer",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE:
    "Movement amount from contract 1",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "IDN of the export contract to which the funds were transferred",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_MOVESTO_IM_TITLE: "Move funds to another contract",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Data on assets to be transferred",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE:
    "Movement amount from contract 1",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of transfer",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "IDN of the import contract to which funds are transferred",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYABLE_DROPS_TITLE: "Write-off of accounts payable under the contract",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Write-off currency",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "The amount of accounts payable to be written off in the currency of accounts payable",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for writing off accounts payable",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Write-off date",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_PAYMENT_REFS_TITLE: "Calculation certificate",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Reference number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Reference date",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Information about the receipt of funds",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_GUARANTEENO_FIELD_LABEL_TITLE: "Bank guarantee number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_POLICYNO_FIELD_LABEL_TITLE: "Insurance Policy Number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for issuing a certificate",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_LETTEROFCREDITNO_FIELD_LABEL_TITLE: "Letter of Credit Number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Reference amount",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_UNUSEDAMOUNT_FIELD_LABEL_TITLE:
    "The balance of the unused certificate amount",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Reference currency",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Reference validity period (date)",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Information about transfers",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TITLE: "Reducing the amount of the settlement certificate",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "New certificate amount",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_REASON_FIELD_LABEL_TITLE:
    "Grounds for reducing the amount of the certificate",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_MAINBRANCHUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the head bank user",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYMENTS_TITLE: "Payment under contract",
  YEISVO_CONTRACT_PAYMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Name of the foreign bank (foreign creditor, lender) from which the payment was made",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Payment Date",
  YEISVO_CONTRACT_PAYMENTS_TABLE_FUNDSOURCETYPE_FIELD_LABEL_TITLE: "Fund Source",
  YEISVO_CONTRACT_PAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Payment Amount",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Payment currency",
  YEISVO_CONTRACT_PAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_PAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for work (services) in contract currency 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for work (services) in contract currency 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "The ratio of the settlement currency to the contract currency",

  YEISVO_CONTRACT_PENALTIES_TITLE: "Ratio of settlement currency to contract currency",
  YEISVO_CONTRACT_PENALTIES_TABLE_PENALTYTYPE_FIELD_LABEL_TITLE: "Type of sanction",
  YEISVO_CONTRACT_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in YEISVO",
  YEISVO_CONTRACT_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of payment (receipt) of the fine",
  YEISVO_CONTRACT_PENALTIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Penalty amount",
  YEISVO_CONTRACT_PENALTIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Penalty currency",
  YEISVO_CONTRACT_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_PERFORMED_SERVICE_TITLE: "Act (confirming document) of services provided",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Act/invoice date",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Act/invoice number",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Contract currency 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Act/invoice amount in contract currency 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Act/invoice amount in contract currency 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Contract Currency 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_IMEXFLAG_FIELD_LABEL_TITLE: "Act attribute",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICIES_TITLE: "Insurance Policy",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCNO_FIELD_LABEL_TITLE: "Insurance Policy Number",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Name of insurance company",
  YEISVO_CONTRACT_POLICIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Policy currency",
  YEISVO_CONTRACT_POLICIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Policy Amount",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Policy expiration date (date)",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Policy expiration date (number of days)",
  YEISVO_CONTRACT_POLICIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_POLICIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICYSUM_CHANGES_TITLE: "Change insurance policy amount",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Effective Policy Sum",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "New policy amount in policy currency",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the policy amount",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICYTIME_CHANGES_TITLE: "Changing the term of the insurance policy",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Current policy expiration date (date)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current policy expiration date",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "New policy expiration date (date)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New policy expiration date (number of days)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the expiration date of the policy",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_RECEIVABLE_DROPS_TITLE: "Write-off of receivables under the contract",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Write-off currency",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "The amount of receivables to be written off in the currency of the receivables",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for writing off accounts receivable",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Write-off date",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_RECEIVABLE_INFOS_TITLE: "Additional information on accounts receivable",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPCURRCODE_FIELD_LABEL_TITLE:
    "Currency of goods under the customs regime of temporary import",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPAMOUNT_FIELD_LABEL_TITLE:
    "The amount of goods under the customs regime of temporary import",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_CUSTOMSMODE_FIELD_LABEL_TITLE: "Customs mode",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_REFUNDS_EXP_TITLE: "Return of previously received funds",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on funds subject to return",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Data on funds subject to return",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Refund Amount",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Refund Date",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_REFUNDS_IMP_TITLE: "Refund of previously paid funds",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Refund Date",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Refund amount in refund currency",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Return currency",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_SALES_TITLE: "Compulsory Sale Information",
  YEISVO_CONTRACT_SALES_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on receipts subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_BENEFITSINFOCODE_FIELD_LABEL_TITLE: "Benefits for mandatory sales",
  YEISVO_CONTRACT_SALES_TABLE_SALETYPE_FIELD_LABEL_TITLE: "Type of compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of compulsory sale or entry of information on compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Data on the transfer of money to a contract subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_SALES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_COMPENSATIONSERIALNO_FIELD_LABEL_TITLE:
    "Data on receipt of insurance compensation subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_SALES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_SALES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_SERVICE_TITLE: "Service in the act",
  YEISVO_CONTRACT_SERVICE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORNAME_FIELD_LABEL_TITLE:
    "Name of the service provider (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of service (work) provider",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTIONCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of provision of services (works)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICENAME_FIELD_LABEL_TITLE: "Name of service (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICETYPE_FIELD_LABEL_TITLE: "Service (work) code",
  YEISVO_CONTRACT_SERVICE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Service (work) price currency",
  YEISVO_CONTRACT_SERVICE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Cost of the service (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_SELFMADEFLAG_FIELD_LABEL_TITLE: "Own services",
  YEISVO_CONTRACT_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TITLE:
    "Fine from the tax authorities for overdue receivables under the contract",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Penalty payment date",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_AMOUNTSOUM_FIELD_LABEL_TITLE:
    "Amount of fine paid in soums",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TAXES_TITLE: "Tax on income paid by a resident abroad",
  YEISVO_CONTRACT_TAXES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_TAXES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Tax Payment Date",
  YEISVO_CONTRACT_TAXES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Tax amount",
  YEISVO_CONTRACT_TAXES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Tax Paid Currency",
  YEISVO_CONTRACT_TAXES_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Tax payment country",
  YEISVO_CONTRACT_TAXES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_TAXES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_TAXES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TOLLING_TERM_TITLE: "Mutual settlement of goods (by tolling)",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSTITLE_FIELD_LABEL_TITLE:
    "Names of goods used for mutual settlement",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Currency of settlement",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE1_FIELD_LABEL_TITLE:
    "Conversion rate of mutual settlement currency to contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE2_FIELD_LABEL_TITLE:
    "Conversion rate of mutual settlement currency to contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of service (mutual settlement) in contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of service (mutual settlement) in contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Settlement date",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE1_FIELD_LABEL_TITLE:
    "Ratio of mutual settlement currency to contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE2_FIELD_LABEL_TITLE:
    "Ratio of settlement currency to contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSAMOUNT_FIELD_LABEL_TITLE: "Amount of settlement item",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TRANSFER_TITLE:
    "Transfer of a contract to another bank (to a commodity exchange)",
  YEISVO_CONTRACT_TRANSFER_TABLE_TRANSFERTYPE_FIELD_LABEL_TITLE: "Contract is being transferred",
  YEISVO_CONTRACT_TRANSFER_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_TRANSFER_TABLE_NEWIDN_FIELD_LABEL_TITLE:
    "IDN of the new contract (after transfer)",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKID_FIELD_LABEL_TITLE:
    "MFO bank/number of the service center where the service contract is transferred",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHINN_FIELD_LABEL_TITLE:
    "TIN of the commodity exchange (fair), where the service contract is transferred,",
  YEISVO_CONTRACT_TRANSFER_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_TRANSFER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the transferring bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the receiving bank/exchange",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Sign of confirmation by the transferring bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Sign of confirmation by the receiving bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHNAME_FIELD_LABEL_TITLE:
    "Name of the commodity exchange (fair) where the service contract is transferred,",
  YEISVO_CONTRACT_TRANSFER_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_TRANSFER_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "IDN of the contract before transfer of the contract",
  YEISVO_CONTRACT_TRANSFER_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKINN_FIELD_LABEL_TITLE:
    "TIN of the bank where the service contract is transferred",
  // Analytics Page
  ANALYTICS_BALANCE_OF_ALL_ACCOUNTS_TITLE: "Balance of All accounts",

  ANALYTICS_BANK_COMMISSIONS_TITLE: "Bank Commissions",

  ANALYTICS_COSTS_TITLE: "Costs",

  // User Info & Settings
  USER_INFO_AND_SETTINGS_TITLE: "Information",
  USER_INFO_AND_SETTINGS_SUBMIT_BUTTON_TITLE: "Submit",
  USER_INFO_AND_SETTINGS_PASSWORD_SETTINGS_TITLE: "Password Setting",
  USER_INFO_AND_SETTINGS_LANGUAGE_SETTINGS_TITLE: "Language Setting",
  USER_INFO_AND_SETTINGS_NOTIFICATIONS_SETTING_TITLE: "Notification Setting",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_TITLE: "Theme Setting",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_YES_BUTTON_TITLE: "Yes",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_NO_BUTTON_TITLE: "No",

  USER_INFO_AND_SETTINGS_PASSWORD_CHANGED: "Password changed",
  USER_INFO_AND_SETTINGS_WRONG_PASSWORD: "Wrong Password",
  USER_INFO_AND_SETTINGS_SAME_PASSWORD: "Current password and new password are the same",
  USER_INFO_AND_SETTINGS_DUPLICATED_PASSWORD: "Password is duplicated from 5 last passwords",
  USER_INFO_AND_SETTINGS_TRY_AGAIN: "Please try again later",
  USER_INFO_AND_SETTINGS_PASSWORD_NOT_MATCHING: "Password Not Matching",
  USER_INFO_AND_SETTINGS_ALERT_LANGUAGE: "Are you sure you want to change the language?",
  USER_INFO_AND_SETTINGS_ALERT_THEME: "Are you sure you want to change the theme?",
  USER_INFO_AND_SETTINGS_PASSWORD_VALIDATE:
    "The password must be between 8 and 16 characters, including at least one uppercase Latin letter, one lowercase Latin letter and one number!",

  USER_INFO_THEME_LIGHTBLUE: "Light Blue",
  USER_INFO_THEME_DARKBLUE: "Dark Blue",
  USER_INFO_THEME_DARKGREY: "Dark Grey",
  USER_INFO_THEME_LIGHTGREY: "Light Grey",

  USER_INFO_RECEIVER_FCY_TITLE: "Receiving notification for FCY incomings",

  USER_INFO_AND_SETTINGS_COMPANY_FORM_EDIT_BUTTON_TITLE: "Edit",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_SAVE_BUTTON_TITLE: "Save",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Company Name",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Name",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Address",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_PHONE_NUMBER_FIELD_LABEL_TITLE: "Phone Number",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_MOBILE_NUMBER_FIELD_LABEL_TITLE: "Mobile Number",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_CALLBACK_NUMBER_FIELD_LABEL_TITLE: "Callback Number",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_EMAIL_ADDRESS_FIELD_LABEL_TITLE: "E-mail Address",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Company Address",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_ACCOUNT_OPEN_DATE_FIELD_LABEL_TITLE: "Account Open Date",
  USER_INFO_AND_SETTINGS_SIGNATURE_NAME_TITLE: "Name",
  USER_INFO_AND_SETTINGS_SIGNATURE_CATEGORIES_TITLE: "Categories",

  USER_INFO_AND_SETTINGS_ACCOUNT_SIGNATORIES_LABEL_TITLE: "Signatories",

  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TITLE: "User's Role",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_CREATOR_COLUMN_TITLE: "Creator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_ADMIN_COLUMN_TITLE: "Administrator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_VIEW_ONLY_COLUMN_TITLE: "View Only",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_1_COLUMN_TITLE: "First Authorizer",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_2_COLUMN_TITLE: "Second Authorizer",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_3_COLUMN_TITLE: "Third Authorizer",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_1_COLUMN_TITLE: "User ID",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_2_COLUMN_TITLE: "User Right",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_LIMIT_COLUMN_TITLE: "Approver Limit",

  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CHANGE_BUTTON_TITLE: "Change",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "New Password",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Current Password",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE:
    "Confirm New Password",

  //Agreement

  AGREEMENTS_TARIFF_SUBTITLE: "Tariff",
  AGREEMENTS_AGREEMENTS_TITLE: "Agreements & Forms",
  AGREEMENTS_CORPORATE_SIGNET_BUTTON: "Signed",
  AGREEMENTS_BANK_SUBTITLE: "Bank Account Agreement ",
  AGREEMENTS_CORPORATE_VISA_SUBTITLE: "Corporate Visa Card",
  AGREEMENTS_CORPORATE_MAIL_SUBTITLE: "Mail Box Agreement",
  AGREEMENTS_INTERNET_SUBTITLE: "Internet Banking Agreement",
  AGREEMENTS_CORPORATE_UZCARD_SUBTITLE: "Corporate UzCard Agreement",
  AGREEMENTS_CORPORATE_HUMO_SUBTITLE: "Corporate Humo Card Agreemen",

  AGREEMENTS_PUBLIC_OFFER_TAB_TITLE: "Public Offer",
  AGREEMENTS_TEMPLATES_AND_FORMS_TAB_TITLE: "Templates and forms",
  AGREEMENTS_INSTRUCTIONS_TITLE: "Instructions",

  AGREEMENTS_TEMPLATES_AND_FORMS_DOCUMENT_FOR_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES:
    "Documents for issuing funds for travel expenses",
  AGREEMENTS_TEMPLATES_AND_FORMS_RETURN_DOCUMENTS: "Return documents",

  AGREEMENTS_TEMPLATES_AND_FORMS_ON_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES_IN_FOREIGN_CURRENCY:
    "On issuing funds for travel expenses in foreign currency",

  //Reference
  REFERENCE_HEAD_TITLE: "Reference",
  REFERENCE_TAB1: "Transit accounts details",
  REFERENCE_TAB2: "Correspondent Relations",

  //Help & FAQ

  HELP_AND_FAQ_FAQ_TAB_TITLE: "FAQ",
  HELP_AND_FAQ_FAQ_QUESTIONS_TITLE: "Questions",
  HELP_AND_FAQ_FAQ_NO_QUESTIONS_TITLE: "No Questions",
  HELP_AND_FAQ_SEND_BUTTON_TITLE: "Send",
  HELP_AND_FAQ_CONTENT_SUBTITLE: "Content",
  HELP_AND_FAQ_SALARY_ITEM_TITLE: "8. Salary",
  HELP_AND_FAQ_CLIENT_ITEM_TITLE: "9. Client partner",
  HELP_AND_FAQ_CONVERSION_ITEM_TITLE: "7. Conversion",
  HELP_AND_FAQ_PAYMENT_ITEM_TITLE: "4. Payment orders",
  HELP_AND_FAQ_LABEL_QUESTION_TEXTAREA_TITLE: "Question:",
  HELP_AND_FAQ_HELP_TITLE: "Help / Frequently Asked Questions",
  HELP_AND_FAQ_STATUSES_ITEM_TITLE: "5. Statuses of payment orders",
  HELP_AND_FAQ_FEATURES_ITEM_TITLE: "1. Features of Internet Banking",
  HELP_AND_FAQ_FEATURES_SIX_ITEM_TITLE: "6. Features of Internet Banking",
  HELP_AND_FAQ_SOFTWARE_ITEM_TITLE: "2. Software and hardware requirements",
  HELP_AND_FAQ_GETTING_ITEM_TITLE: "3. Getting started: access, login, passwords",

  //Loan
  LOAN_LIST_OF_CONTACTS_TITLE: "List of Contracts",
  LOAN_FILTER_FORM_TO_FIELD_LABEL_TITLE: "To",
  LOAN_TABLE_INTEREST_COLUMN_TITLE: "Interest Rate",
  LOAN_TABLE_LOAN_CURRENCY_COLUMN_TITLE: "Loan Currency",
  LOAN_TABLE_CONTACT_COLUMN_TITLE: "Contact Expiration Date",
  LOAN_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "From",
  LOAN_TABLE_CREDIT_AGREEMENT_COLUMN_TITLE: "Credit Agreement Amount",
  LOAN_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "Number and Date of Loan Agreement",

  LOAN_ITEM_PENNY_FIELD_LABEL_TITLE: "Penalty",
  LOAN_ITEM_BALANCE_FIELD_LABEL_TITLE: "Balance",
  LOAN_ITEM_CURRENCY_FIELD_LABEL_TITLE: "Currency",
  LOAN_ITEM_CREDIT_TYPE_FIELD_LABEL_TITLE: "Credit Type",
  LOAN_ITEM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "Payment Type",
  LOAN_ITEM_PAYMENT_DATE_FIELD_LABEL_TITLE: "Payment Date",
  LOAN_ITEM_LOAN_REPAYMENT_TITLE: "Loan repayment schedule",
  LOAN_ITEM_INTEREST_RATE_FIELD_LABEL_TITLE: "Interest Rate",
  LOAN_ITEM_PAYMENT_AMOUNT_FIELD_LABEL_TITLE: "Payment Amount (Principal)",
  LOAN_ITEM_CONTACT_AMOUNT_FIELD_LABEL_TITLE: "Contract Amount",
  LOAN_ITEM_OVERDUE_INTEREST_FIELD_LABEL_TITLE: "Overdue Interest",
  LOAN_ITEM_INTEREST_PERIOD_FOR_FIELD_LABEL_TITLE: "Interest period for",
  LOAN_ITEM_SCHEDULER_DATE_FIELD_LABEL_TITLE: "Scheduled repayment date",
  LOAN_ITEM_BALANCE_OF_PRINCIPAL_FIELD_LABEL_TITLE: "Balance of principal",
  LOAN_ITEM_INTEREST_PERIOD_FROM_FIELD_LABEL_TITLE: "Interest period from",
  LOAN_ITEM_LOAN_AGREEMENT_TITLE: "Loan Agreement #",
  LOAN_ITEM_SCHEDULER_AMOUNT_FIELD_LABEL_TITLE: "Scheduled repayment amount",
  LOAN_ITEM_OVERDUE_PRINCIPAL_DEBT_FIELD_LABEL_TITLE: "Overdue principal debt",
  LOAN_ITEM_AMOUNT_PAID_TITLE: "Amount paid under the loan agreement #",
  LOAN_ITEM_BALANCES_TITLE: "Loan Balances",
  LOAN_ITEM_AGREEMENT_NUMBER_FIELD_LABEL_TITLE: "Loan Agreement Number",
  LOAN_ITEM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Loan Account Number",
  LOAN_ITEM_BANK_CODE_FIELD_LABEL_TITLE: "Bank Code",
  LOAN_ITEM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Loan Account Type",

  //Notifications
  NOTIFICATIONS_NEWS_TAB_TITLE: "News",
  NOTIFICATIONS_NO_NOTIFICATIONS_TITLE: "No Notifications",
  NOTIFICATIONS_SEARCH_TITLE: "Search",
  NOTIFICATIONS_EVENT_TAB_TITLE: "Event",
  NOTIFICATIONS_BUTTON_MENU_NEWS_TITLE: "News",
  NOTIFICATIONS_BUTTON_MENU_EMPTY_TITLE: "Empty",
  NOTIFICATIONS_BUTTON_MENU_DIVED_TITLE: "Dived",
  NOTIFICATIONS_BUTTON_READ_MORE_TITLE: "Read more",
  NOTIFICATIONS_BUTTON_MENU_GALLERY_TITLE: "Gallery",
  NOTIFICATIONS_ANNOUNCEMENTS_TAB_TITLE: "Announcements",
  NOTIFICATIONS_BUTTON_MENU_PRESS_CENTRE_TITLE: "Press centre",
  NOTIFICATIONS_CALNEDAR_BUTTON_TITLE: "Calendar",
  NOTIFICATIONS_LOAN_AGREEMENT_TITLE: "Loan Agreement #  2000/11-1 dd15.05.18",
  NOTIFICATIONS_LOAN_AGREEMENT_SUBTITLE:
    "Herewith by this application I confirm that I have read the terms of Public Offer for rendering of service on conversion of fоreign currency of one type into foreign currency of another type, agree with and join them, I will not have any claims against the Bank in the future and give my consent to the direct write-off of the necessary amounts for the services rendered in accordance with the Bank’s Tariffs.",

  // Table
  TABLE_EMPTY_STATE_TITLE: "Empty list",

  // Paginator
  PAGINATION_OF_TITLE: "of",

  // File Controls
  FILE_CONTROLS_PRINT_BUTTON_TITLE: "Print",
  FILE_CONTROLS_GENERATE_BUTTON_TITLE: "Generate",
  FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE: "Save to PDF",
  FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE: "Save to PDF with QR code",
  FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE: "Save to TXT",
  FILE_CONTROLS_SAVE_TO_TXT_2_BUTTON_TITLE: "Save to TXT_2",
  FILE_CONTROLS_SAVE_TO_TXT_3_BUTTON_TITLE: "Save to TXT_3",
  FILE_CONTROLS_SAVE_TO_MT940_BUTTON_TITLE: "Save to MT940",
  FILE_CONTROLS_SAVE_EXCEL_1C_BUTTON_TITLE: "Save to Excel 1C",
  FILE_CONTROLS_SAVE_TEXT_1C_BUTTON_TITLE: "Save to TXT 1C",
  FILE_CONTROLS_SAVE_AGROMIR_CREDIT_BUTTON_TITLE: "Agromir (credit)",
  FILE_CONTROLS_SAVE_AGROMIR_DEBIT_BUTTON_TITLE: "Agromir (debit)",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE_SV: "Save to Excel (SV)",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE: "Save to Excel",
  FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY_BUTTON_TITLE: "Save to Excel 2",
  FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY2_BUTTON_TITLE: "Save to Excel Anglesey2",
  FILE_CONTROLS_SAVE_TO_EXCEL_FUTTAIM_BUTTON_TITLE: "Save to Excel FUTTAIM",
  FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_CREDIT_BUTTON_TITLE: "Save to Excel Agromir(Credit)",
  FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_DEBIT_BUTTON_TITLE: "Save to Excel Agromir(Debit)",
  FILE_CONTROLS_SAVE_EXCEL_3KORZINKA_BUTTON_TITLE: "Save to Excel 3",
  FILE_CONTROLS_SAVE_TO_TXT_ANSI_BUTTON_TITLE: "Save to ANSI",
  FILE_CONTROLS_SAVE_TO_FAVORITE_BUTTON_TITLE: "Save to Favorite",
  FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE: "List of Card Holders",
  FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE: "Download Excel Template",
  FILE_CONTROLS_REGISTER_NEW_SALARY_CARD_BUTTON_TITLE: "Registration New Salary Card",
  FILE_CONTROLS_REGISTER_NEW_CORPORATE_CARD_BUTTON_TITLE: "Registration New Corporate Card",
  FAVORITES_DELETE_BUTTON: "Are you sure you want to delete this?",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_TITLE: "FCY transfer",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_TITLE: "LCY transfer",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_TITLE: "Corporate Card Payroll",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_TITLE: "Salary Card Payroll",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_TITLE: "Payment Claim",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_FILE_NAME_TITLE: "FCY transfer",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_FILE_NAME_TITLE: "LCY transfer",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_FILE_NAME_TITLE: "Corporate Card Payroll",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_FILE_NAME_TITLE: "Salary Card Payroll",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_FILE_NAME_TITLE: "Payment Claim",

  TRANSFERS_MODAL_PAYMENT_CODE_TITLE: "Payment Codes",
  TRANSFERS_MODAL_BUDGET_ACCOUNTS: "Budget Accounts",
  FILE_CONTROLS_SAVE_TO_UPLOAD_FORM_EXCEL_BUTTON_TITLE: "Upload from Excel",
  FILE_CONTROLS_SAVE_TO_FAVORITE_FCY_TO_UZS_BUTTON_TITLE: "Favorites FCY to UZS",
  QR_CODE_ERROR3015: "A PDF file with a QR code for this document in english already exists.",
  QR_CODE_ERROR3016: "This document has not yet been processed.",
  QR_CODE_SELECT_LIMIT_ERROR: "Only one document should be selected",

  // Select Picker
  SELECT_PICKER_PLACEHOLDER_TITLE: "All",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "No options",
  SELECT_PICKER_PLACEHOLDER_YES_OR_NO_TITLE: "Yes/No",
  SELECT_PICKER_PLACEHOLDER_CURRENCY_TITLE: "Your currency",
  SELECT_PICKER_PLACEHOLDER_CHOOSE_ONE_ONLY_TITLE: "Choose one only",

  //Contact Us
  CONTACT_US_EMAIL_TITLE: "E-mail",
  CONTACT_US_FULL_NAME_TITLE: "Full name",
  CONTACT_US_PHONE_NUMBER_TITLE: "Phone Number",
  CONTACT_US_CLIENT_SERVICES_TITLE: "Client Services",
  CONTACT_US_CLIENT_RELATIONSHIP_TITLE: "Client Relationship Managers",

  //Main
  MAIN_ON_LENDING_AGREEMENT_UNIT_TITLE: "Unit: USD",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_SUBTITLE: "Address",
  MAIN_ON_LENDING_AGREEMENT_BANK_CODE_SUBTITLE: "Bank Code",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_ONE_SUBTITLE: "Up to 1 year",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_TWO_SUBTITLE: "Up to 2 years",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_THREE_SUBTITLE: "Up to 3 years",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FOUR_SUBTITLE: "Up to 4 years",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FIVE_SUBTITLE: "Up to 5 years",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_SUBTITLE: "Next approval date",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_TITLE: "15.06.2021",
  MAIN_ON_LENDING_AGREEMENT_AUTHORIZED_SUBTITLE: "Authorized manager",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_SUBTITLE: "Intermediary bank",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_TITLE: "ABC Bank",
  MAIN_ON_LENDING_AGREEMENT_GENERAL_SUBTITLE: "General on-lending limit",
  MAIN_ON_LENDING_AGREEMENT_LATEST_DATE_SUBTITLE: "Latest approval date",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_NUMBER_SUBTITLE: "Agreement Number",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_DATE_SUBTITLE: "Agreement signing date",
  MAIN_ON_LENDING_AGREEMENT_INTEREST_SUBTITLE: "Interest rate of individual deal:",
  MAIN_ON_LENDING_AGREEMENT_MAXIMUM_SUBTITLE: "Maximum amount of individual deal",
  MAIN_ON_LENDING_AGREEMENT_MINIMUM_SUBTITLE: "Minimum amount of individual deal",
  MAIN_ON_LENDING_AGREEMENT_TAX_IDENTIFICATION_SUBTITLE: "Tax identification number",
  MAIN_ON_LENDING_AGREEMENT_INFORMATION_TITLE: "Information on General On-Lending Agreement",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_TITLE: "#3, Google Street, Mirabad District, Tashkent City",

  MAIN_PENDING_APPLICATION_UNIT_TITLE: "Unit: USD",
  MAIN_PENDING_APPLICATION_TABLE_AMOUNT_COLUMN_TITLE: "Sub-loan Amount",
  MAIN_PENDING_APPLICATION_TABLE_STATUS_COLUMN_TITLE: "Status",
  MAIN_PENDING_APPLICATION_TABLE_DATE_COLUMN_TITLE: "Application date",
  MAIN_PENDING_APPLICATION_TABLE_NAME_COLUMN_TITLE: "Sub-Borrower name",
  MAIN_PENDING_APPLICATION_TABLE_NUMBER_COLUMN_TITLE: "Application number",
  MAIN_PENDING_APPLICATION_PENDING_APPLICATION_TITLE: "Pending applications",
  MAIN_PENDING_APPLICATION_TABLE_UPDATING_COLUMN_TITLE: "Update",
  MAIN_PENDING_APPLICATION_PRE_APPROVAL_TITLE: "Pre-approval of new application",
  MAIN_PENDING_APPLICATION_TABLE_REGISTRATION_COLUMN_TITLE: "Request for registration",
  MAIN_PENDING_APPLICATION_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Request for updating",
  MAIN_PENDING_APPLICATION_REQUEST_BUTTON_TITLE:
    "Request for pre-approval of new loan or L/C application",

  FINANCIAL_REFUSED_DEALS_UNIT_TITLE: "Unit: USD",
  FINANCIAL_REFUSED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  FINANCIAL_REFUSED_DEALS_APPROVED_DEALS_TITLE: "Approved deals",
  FINANCIAL_REFUSED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Status",
  FINANCIAL_REFUSED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Borrower name",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: "Request date",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Request number",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Request for updating",
  FINANCIAL_REFUSED_DEALS_TABLE_REFUSED_OR_CANCELLED_COLUMN_TITLE: "Refused Or Cancelled Deals",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE: "Request for registration",

  MAIN_LOAN_REPAYMENT_DETAILS_UNIT_TITLE: "Unit: USD",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_AMOUNT_COLUMN_TITLE: "Amount",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Request number",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_BORROWER_NUMBER_COLUMN_TITLE: "Borrower name",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_DATE_TOTAL_COLUMN_TITLE: "Request date",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_DATE_COLUMN_TITLE: "Request date",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_BORROWER_NAME_COLUMN_TITLE: "Borrower name",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_NUMBER_COLUMN_TITLE: "Request number",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_UPCOMING_LOAN_UPCOMING_COLUMN_TITLE: "Upcoming repayment date",
  MAIN_LOAN_REPAYMENT_DETAILS_DETAILED_INFORMATION_BY_INDIVIDUAL_DEALS_TITLE:
    "Detailed information by individual deals",
  MAIN_LOAN_REPAYMENT_DETAILS_UPCOMING_LOAN_TITLE:
    "Upcoming loan repayment, overdue payments and commissions",

  MAIN_DOCUMENT_SUBMISSION_TABLE_DATE_COLUMN_TITLE: "Date",
  MAIN_DOCUMENT_SUBMISSION_REFUSED_TAB_TITLE: "Refused Deals",
  MAIN_DOCUMENT_SUBMISSION_APPROVED_TAB_TITLE: "Approved Deals",
  MAIN_DOCUMENT_SUBMISSION_SUBMITTED_TITLE: "Submitted documents",
  MAIN_DOCUMENT_SUBMISSION_LOAN_TAB_TITLE: "Loan Repayment Details",
  MAIN_DOCUMENT_SUBMISSION_LENDING_TAB_TITLE: "On-Lending Agreement",
  MAIN_DOCUMENT_SUBMISSION_DOCUMENT_TAB_TITLE: "Document Submission",
  MAIN_DOCUMENT_SUBMISSION_PENDING_TAB_TITLE: "Pending Applications",
  MAIN_DOCUMENT_SUBMISSION_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Document name",
  MAIN_DOCUMENT_SUBMISSION_SUBMISSION_BUTTON_TITLE: "Submission of document",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_TITLE:
    "Submission of documents to KDB Bank Uzbekistan",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_BUTTON_TITLE:
    "Submission of documents to KDB Bank Uzbekistan",

  // Withdrawal of Loan Funds page  " Individual deal TAB"

  WITHDRAWAL_TABLE_DATE_COLUMN_TITLE: "Date",
  WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_ADDRESS_FIELD_LABEL_TITLE: "Address",
  WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Details",
  WITHDRAWAL_BORROWER_FIELD_LABEL_TITLE: "Borrower",
  WITHDRAWAL_PRODUCTS_FIELD_LABEL_TITLE: "Products",
  WITHDRAWAL_TABLE_AMOUNT_COLUMN_TITLE: "Amount (USD)",
  WITHDRAWAL_TABLE_PAYMENT_COLUMN_TITLE: "Payment date",
  WITHDRAWAL_SIZE_FIELD_LABEL_TITLE: "Size of business",
  WITHDRAWAL_DEAL_NUMBER_FIELD_LABEL_TITLE: "Deal number",
  WITHDRAWAL_INTEREST_FIELD_LABEL_TITLE: "Interest rate",
  WITHDRAWAL_INDIVIDUAL_DEAL_TAB_TITLE: "Individual Deal",
  WITHDRAWAL_APPROVED_FIELD_LABEL_TITLE: "Approved amount",
  WITHDRAWAL_EMPLOYEE_FIELD_LABEL_TITLE: "Employee number",
  WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Date of establishment",
  WITHDRAWAL_BUSINESS_FIELD_LABEL_TITLE: "Business activity",
  WITHDRAWAL_WITHDRAWN_FIELD_LABEL_TITLE: "Withdrawn amount",
  WITHDRAWAL_TAX_FIELD_LABEL_TITLE: "Tax identification number",
  WITHDRAWAL_OUTSTANDING_FIELD_LABEL_TITLE: "Outstanding amount",
  WITHDRAWAL_TABLE_OVERDUE_COMMISSION_COLUMN_TITLE: "Commission",
  WITHDRAWAL_ON_LENDING_AGREEMENT_TAB_TITLE: "On-Lending Agreement",
  WITHDRAWAL_MANAGEMENT_FIELD_LABEL_TITLE: "Management of Borrower",
  WITHDRAWAL_DEAL_REQUEST_NUMBER_FIELD_LABEL_TITLE: "Request Number",
  WITHDRAWAL_TABLE_OVERDUE_PAYMENT_AMOUNT_COLUMN_TITLE: "Payment amount",
  WITHDRAWAL_LARGEST_FIELD_LABEL_TITLE: "Largest shareholders (above 10%)",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_TITLE: "Information on individual deal",
  WITHDRAWAL_TABLE_NUMBER_OF_PAYMENT_COLUMN_TITLE: "Number of payment request",
  WITHDRAWAL_LOAN_TITLE: "Loan repayment, overdue payment & commission payment history",
  WITHDRAWAL_INFORMATION_ON_CORPORATE_BORROWER_TITLE: "Information on corporate borrower",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_DEAL_TITLE: "Screen #4. information on individual deal",

  // Individual transfers page
  TRANSFERS_TRANSFERS_HISTORY_TAB_TITLE: "History",
  TRANSFERS_TRANSFERS_FAVORITES_TAB_TITLE: "Favorites",
  TRANSFERS_TRANSFERS_TO_ANOTHER_CLIENT_TAB_TITLE: "Transfer To Other Bank's Client",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_TAB_TITLE: "Transfers Between My Accounts",
  TRANSFERS_TRANSFERS_TO_OTHER_KDB_CLIENTS_TAB_TITLE: "Transfer To Other KDB Clients",

  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CARD_TITLE: "To card",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_SEND_BUTTON_TITLE: "Send",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_FIELD_LABEL_TITLE: "Receiver",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_BUDGET_TITLE: "To budget",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_FROM_FIELD_LABEL_TITLE: "Sender",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CURRENT_TITLE: "To current",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_AMOUNT_FIELD_LABEL_TITLE: "Amount",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_BANK_CODE_FIELD_LABEL_TITLE: "Bank code",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_RECEIVER_NAME_FIELD_LABEL_TITLE: "Receiver name",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE: "Payment details",

  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TITLE: "Confirmation",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_CANCEL_BUTTON_TITLE: "Cancel",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TRANSFER_BUTTON_TITLE: "Transfer",

  TRANSFERS_TRANSFERS_SUCCESS_MODAL_TITLE: "Success",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_EXIT_BUTTON_TITLE: "Exit",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_PDF_BUTTON_TITLE: "Save to PDF",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_FAVORITE_BUTTON_TITLE: "Save to favorite",

  TRANSFERS_TRANSFERS_MODAL_AMOUNT_TITLE: "Amount",
  TRANSFERS_TRANSFERS_MODAL_SENDER_NAME_TITLE: "Sender",
  TRANSFERS_TRANSFERS_MODAL_COMMISSION_TITLE: "Commission",
  TRANSFERS_TRANSFERS_MODAL_PAYMENT_DATE_TITLE: "Payment date",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_NAME_TITLE: "Receiver name",
  TRANSFERS_TRANSFERS_MODAL_SENDER_ACCOUNT_TITLE: "Sender account",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_ACCOUNT_TITLE: "Receiver account",

  // Withdrawal of Loan Funds page  "Withdrawn Loan Funds"

  WITHDRAWAL_WITHDRAWN_50_HEADER_ITEM_TITLE: "50",
  WITHDRAWAL_WITHDRAWN_52_HEADER_ITEM_TITLE: "52",
  WITHDRAWAL_WITHDRAWN_56_HEADER_ITEM_TITLE: "56",
  WITHDRAWAL_WITHDRAWN_57_HEADER_ITEM_TITLE: "57",
  WITHDRAWAL_WITHDRAWN_59_HEADER_ITEM_TITLE: "59",
  WITHDRAWAL_WITHDRAWN_70_HEADER_ITEM_TITLE: "70",
  WITHDRAWAL_WITHDRAWN_71_HEADER_ITEM_TITLE: "71",
  WITHDRAWAL_WITHDRAWN_72_HEADER_ITEM_TITLE: "72",
  WITHDRAWAL_WITHDRAWN_32A_HEADER_ITEM_TITLE: "32a",
  WITHDRAWAL_WITHDRAWN_DATE_FIELD_LABEL_TITLE: "Date",
  WITHDRAWAL_WITHDRAWN_TAX_ID_ITEM_TITLE: "Tax ID / INN",
  WITHDRAWAL_WITHDRAWN_NEW_BUTTON_TITLE: "New withdrawal",
  WITHDRAWAL_WITHDRAWN_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_WITHDRAWN_ADDRESS_FIELD_LABEL_TITLE: "Address",
  WITHDRAWAL_WITHDRAWN_TABLE_DETAILS_COLUMN_TITLE: "Details",
  WITHDRAWAL_WITHDRAWN_BORROWER_FIELD_LABEL_TITLE: "Borrower",
  WITHDRAWAL_WITHDRAWN_PRODUCTS_FIELD_LABEL_TITLE: "Products",
  WITHDRAWAL_WITHDRAWN_CURRENCY_ITEM_TITLE: "Currency / Валюта",
  WITHDRAWAL_WITHDRAWN_ADDRESS_UP_ITEM_TITLE: "Address / Адрес",
  WITHDRAWAL_WITHDRAWN_ADDRESS_DOWN_ITEM_TITLE: "Address / Адрес",
  WITHDRAWAL_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Interest rate",
  WITHDRAWAL_WITHDRAWN_REFUSED_TITLE: "Details of withdrawn loan funds",
  WITHDRAWAL_WITHDRAWN_DEAL_NUMBER_FIELD_LABEL_TITLE: "Deal number",
  WITHDRAWAL_WITHDRAWN_APPROVED_FIELD_LABEL_TITLE: "Approved amount",
  WITHDRAWAL_WITHDRAWN_EMPLOYEE_FIELD_LABEL_TITLE: "Employee number",
  WITHDRAWAL_WITHDRAWN_DATE_TITLE: "Date /  Дата         XX/XX/XXXX ",
  WITHDRAWAL_WITHDRAWN_BUSINESS_FIELD_LABEL_TITLE: "Business activity",
  WITHDRAWAL_WITHDRAWN_WITHDRAWN_FIELD_LABEL_TITLE: "Withdrawn amount",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_DATE_COLUMN_TITLE: "Payment date",
  WITHDRAWAL_WITHDRAWN_REQUEST_NUMBER_FIELD_LABEL_TITLE: "Request number",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_ITEM_TITLE: "Beneficiary / Получатель",
  WITHDRAWAL_WITHDRAWN_OUTSTANDING_FIELD_LABEL_TITLE: "Outstanding amount",
  WITHDRAWAL_PAGE_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Interest Rate",
  WITHDRAWAL_WITHDRAWN_DEBIT_ITEM_TITLE: "Debit account / Дебетовать счет",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "Payment amount",
  WITHDRAWAL_WITHDRAWN_VALUE_ITEM_TITLE: "Value date / Дата валютирования",
  WITHDRAWAL_WITHDRAWN_MANAGEMENT_FIELD_LABEL_TITLE: "Management of Borrower",
  WITHDRAWAL_WITHDRAWN_SIZE_OF_BUSINESS_FIELD_LABEL_TITLE: "Size of business",
  WITHDRAWAL_WITHDRAWN_AMOUNT_DIGIT_ITEM_TITLE: "Amount  in digit / Сумма цифрами",
  WITHDRAWAL_WITHDRAWN_ORDERING_BANK_ITEM_TITLE: "Ordering Bank / Банк плательщика",
  WITHDRAWAL_WITHDRAWN_LARGEST_FIELD_LABEL_TITLE: "Largest shareholders (above 10%)",
  WITHDRAWAL_WITHDRAWN_DATE_ESTABLISHMENT_FIELD_LABEL_TITLE: "Date of establishment",
  WITHDRAWAL_WITHDRAWN_INFORMATION_INDIVIDUAL_TITLE: "Information on individual deal",
  WITHDRAWAL_WITHDRAWN_ORDERING_CUSTOMER_ITEM_TITLE: "Ordering customer / Плательщик",
  WITHDRAWAL_WITHDRAWN_TABLE_NUMBER_REQUEST_COLUMN_TITLE: "Number of payment request",
  WITHDRAWAL_WITHDRAWN_CREDIT_ACCOUNT_ITEM_TITLE: "Credit account / Кредитовать счет",
  WITHDRAWAL_WITHDRAWN_PAYMENT_TITLE: "Payment instruction/ Платежное поручение № XXX",
  WITHDRAWAL_WITHDRAWN_INFORMATION_CORPORATE_TITLE: "Information on corporate borrower",
  WITHDRAWAL_WITHDRAWN_TAX_IDENTIFICATION_FIELD_LABEL_TITLE: "Tax identification number",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_BANK_ITEM_TITLE: "Beneficiary Bank / Банк получателя ",
  WITHDRAWAL_WITHDRAWN_INTERMEDIARY_BANK_ITEM_TITLE: "Intermediary Bank / Банк посредник",
  WITHDRAWAL_WITHDRAWN_IBAN_ITEM_TITLE: "(IBAN for payments to EU/IBAN для платежей в страны ЕС)",
  WITHDRAWAL_WITHDRAWN_AMOUNT_CURRENCY_ITEM_TITLE:
    "Amount and currency in words / Сумма и валюта прописью",
  WITHDRAWAL_WITHDRAWN_DETAILS_PAYMENT_ITEM_TITLE:
    "Details of Payment / Детали платежа (цель и назначение)",
  WITHDRAWAL_WITHDRAWN_DETAILS_CHARGES_ITEM_TITLE:
    "Details of Charges: / Оплата услуг банков корреспондентов:",
  WITHDRAWAL_WITHDRAWN_ADDITIONAL_INFORMATION_ITEM_TITLE:
    "Additional Information / Дополнительная информация",
  WITHDRAWAL_WITHDRAWN_CREATE_BUTTON_TITLE: "Create",
  WITHDRAWAL_WITHDRAWN_WITHDRAWAL_LOAN_TITLE:
    "Screen #5. withdrawal of loan funds/ information on withdrawn loan funds",

  WITHDRAWN_REQUEST_DRAWDOWN_FORM_TITLE: "REQUEST FOR ON-LENDING LOAN DRAWDOWN",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_DATE_TITLE: "Date",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_OF_WITHDRAWN_TITLE: "Form of drawdown of the On-lending loan",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUBMIT_BUTTON_TITLE: "SUBMIT",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_AMOUNT_TITLE: "Amount",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_NUMBER_TITLE: "Number",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_ADDRESS_TITLE: "Address",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_PAYMENT_DETAILS_TITLE: "Payment Details",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_VALUES_DATE_FIELD_TITLE: "Value date",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_NAME_TITLE: "Name",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_DATE_FIELD_TITLE: "Request date:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_GROUP_TITLE: "Sub-Borrower",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_TAX_ID_TITLE: "Tax ID",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AMOUNT_TITLE: "Sub-loan amount",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_NUMBER_FIELD_TITLE: "Request number:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_CONTACT_GROUP_TITLE: "Import contract",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_FIELD_TITLE: "Drawdown amount",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_REQUEST_GROUP_TITLE: "Drawdown request",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_NAME_FIELD_TITLE: "Name",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_BANK_GROUP_TITLE: "Beneficiary Bank",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_BENEFICIARY_NAME_TITLE: "Beneficiary Name",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_CURRENCY_FIELD_TITLE: "Drawdown currency",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_BANK_GROUP_TITLE:
    "Correspondent Bank of the Intermediary Bank",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_NAME_FIELD_TITLE: "Name",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_ADDRESS_FIELD_TITLE: "Address",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_ADDRESS_FIELD_TITLE: "Address",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_SWIFT_CODE_FIELD_TITLE: "SWIFT code",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AGREEMENT_NUMBER_TITLE: "Sub-loan Agreement Number",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_IN_WORDS_FIELD_TITLE: "Drawdown amount in words",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_SWIFT_CODE_FIELD_TITLE: "SWIFT code",

  //Withdrawal of Loan Funds page "New Withdrawal Tab"

  WITHDRAWAL_NEW_WITHDRAWAL_50_ITEM_TITLE: "50",
  WITHDRAWAL_NEW_WITHDRAWAL_52_ITEM_TITLE: "52",
  WITHDRAWAL_NEW_WITHDRAWAL_56_ITEM_TITLE: "56",
  WITHDRAWAL_NEW_WITHDRAWAL_57_ITEM_TITLE: "57",
  WITHDRAWAL_NEW_WITHDRAWAL_59_ITEM_TITLE: "59",
  WITHDRAWAL_NEW_WITHDRAWAL_70_ITEM_TITLE: "70",
  WITHDRAWAL_NEW_WITHDRAWAL_71_ITEM_TITLE: "71",
  WITHDRAWAL_NEW_WITHDRAWAL_72_ITEM_TITLE: "72",
  WITHDRAWAL_NEW_WITHDRAWAL_32A_ITEM_TITLE: "32а",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_AND_CURRENCY_ITEM_TITLE:
    "Amount and currency in words / Сумма и валюта прописью",
  WITHDRAWAL_NEW_WITHDRAWAL_TAX_ID_ITEM_TITLE: "Tax ID / INN",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_NEW_WITHDRAWAL_NEW_BUTTON_TITLE: "New withdrawal",
  WITHDRAWAL_NEW_WITHDRAWAL_IBAN_ITEM_TITLE:
    "(IBAN for payments to EU/IBAN для платежей в страны ЕС) ",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_PAYMENT_ITEM_TITLE:
    "Details of Payment / Детали платежа (цель и назначение)",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_CHANGES_ITEM_TITLE:
    "Details of Charges: / Оплата услуг банков корреспондентов: ",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDITIONAL_INFORMATION_ITEM_TITLE:
    "Additional Information / Дополнительная информация",
  WITHDRAWAL_NEW_WITHDRAWAL_CURRENCY_ITEM_TITLE: "Currency / Валюта ",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Details",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_UP_ITEM_TITLE: "Address / Адрес",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_DOWN_ITEM_TITLE: "Address / Адрес",
  WITHDRAWAL_NEW_WITHDRAWAL_REFUSED_TITLE: "Refused or cancelled deals",
  WITHDRAWAL_NEW_WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Date",
  WITHDRAWAL_NEW_WITHDRAWAL_DATA_TITLE: "Date /  Дата         XX/XX/XXXX ",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_DATE_COLUMN_TITLE: "Payment date",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "Payment amount",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_ITEM_TITLE: "Beneficiary / Получатель ",
  WITHDRAWAL_NEW_WITHDRAWAL_VALUE_ITEM_TITLE: "Value date / Дата валютирования",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_NUMBER_COLUMN_TITLE: "Number of payment request",
  WITHDRAWAL_NEW_WITHDRAWAL_DEBIT_ACCOUNT_ITEM_TITLE: "Debit account / Дебетовать счет",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_BANK_ITEM_TITLE: "Ordering Bank / Банк плательщика",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_CUSTOMER_ITEM_TITLE: "Ordering customer / Плательщик",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_IN_DIGIT_ITEM_TITLE: "Amount  in digit / Сумма цифрами",
  WITHDRAWAL_NEW_WITHDRAWAL_PAYMENT_TITLE: "Payment instruction/ Платежное поручение № XXX",
  WITHDRAWAL_NEW_WITHDRAWAL_INTERMEDIARY_BANK_ITEM_TITLE: "Intermediary Bank / Банк посредник",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_BANK_ITEM_TITLE: "Beneficiary Bank / Банк получателя ",
  WITHDRAWAL_NEW_WITHDRAWAL_CREDIT_ACCOUNT_ITEM_TITLE: "Credit account / Кредитовать счет\n",

  //Withdrawal of Loan Funds page "Request for reg.loan in NIKI tab"

  WITHDRAWAL_REQUEST_FOR_TABLE_DATE_COLUMN_TITLE: "Date",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_BUTTON_TITLE: "Attached",
  WITHDRAWAL_REQUEST_FOR_GRACE_FIELD_LABEL_TITLE: "Grace period",
  WITHDRAWAL_REQUEST_FOR_TABLE_AMOUNT_COLUMN_TITLE: "Amount (USD)",
  WITHDRAWAL_REQUEST_FOR_INTEREST_FIELD_LABEL_TITLE: "Interest rate",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DATE_FIELD_LABEL_TITLE: "Date",
  WITHDRAWAL_REQUEST_FOR_REQUEST_TERM_FIELD_LABEL_TITLE: "Requested term",
  WITHDRAWAL_REQUEST_FOR_PURPOSE_FIELD_LABEL_TITLE: "Purpose of financing",
  WITHDRAWAL_REQUEST_FOR_REQUEST_NUMBER_FIELD_LABEL_TITLE: "Request number",
  WITHDRAWAL_REQUEST_FOR_REQUEST_AMOUNT_FIELD_LABEL_TITLE: "Requested amount",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_DOCUMENTS_LABEL_TITLE: "Attached documents",
  WITHDRAWAL_REQUEST_FOR_MANAGER_FIELD_LABEL_TITLE: "Manager of intermediary bank",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DETAILS_TITLE: "Details of individual application",
  WITHDRAWAL_REQUEST_FOR_REQUEST_BUTTON_TITLE: "Request for registration of application",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_PARA_3_TITLE:
    "Para #3. request for registration of individual loan or l/c application in the niki system of the cbu",
  WITHDRAWAL_REQUEST_FOR_KDB_BANK_SUBTITLE_TITLE:
    "To Chairman of KDB Bank Uzbekistan," +
    "Hereby, you are kindly asked to allow  (the intermediary bank) to utilize the on-lending loan funds under  (the General On-Lending Agreement dd. 10.05.2020) for financing of the following individual application.",

  // Individual role Loan "Loan Tab"

  LOAN_TO_FIELD_LABEL_TITLE: "to",
  LOAN_DATE_FIELD_LABEL_TITLE: "Date",
  LOAN_TABLE_LOAN_COLUMN_TITLE: "Loan Currency",
  LOAN_TABLE_INTEREST_RATE_COLUMN_TITLE: "Interest rate",
  LOAN_TABLE_CREDIT_COLUMN_TITLE: "Credit Agreement Amount",
  LOAN_TABLE_NUMBER_COLUMN_TITLE: "Number and Date of Loan Agreement",
  LOAN_TABLE_CONTACT_EXPIRATION_COLUMN_TITLE: "Contact expiration date",

  // Individual role Conversion "History Tab"

  CONVERSION_HISTORY_TAB_TITLE: "History",
  CONVERSION_CONVERSION_TAB_TITLE: "Conversion",
  CONVERSION_EXCHANGE_RATE_TAB_TITLE: "Exchange Rate",

  CONVERSION_HISTORY_TO_FIELD_LABEL_TITLE: "to",
  CONVERSION_HISTORY_DATE_FROM_FIELD_LABEL_TITLE: "From",

  CONVERSION_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  CONVERSION_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Register",
  CONVERSION_HISTORY_TABLE_STATUS_CODE_COLUMN_TITLE: "Status",
  CONVERSION_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Card Type",
  CONVERSION_HISTORY_TABLE_DETAILS_CODE_COLUMN_TITLE: "Details",
  CONVERSION_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Created By",
  CONVERSION_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Approved By",
  CONVERSION_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Payment code",
  CONVERSION_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  CONVERSION_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Date and Time",
  CONVERSION_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Transit account",

  // Individual role Conversion "Conversion Tab"

  CONVERSION_CONVERSION_TO_FIELD_LABEL_TITLE: "To",
  CONVERSION_CONVERSION_SUBMIT_BUTTON_TITLE: "Submit",
  CONVERSION_CONVERSION_FROM_FIELD_LABEL_TITLE: "From",
  CONVERSION_CONVERSION_AMOUNT_LEFT_FIELD_LABEL_TITLE: "Amount",
  CONVERSION_CONVERSION_AMOUNT_RIGHT_FIELD_LABEL_TITLE: "Amount",
  CONVERSION_CONVERSION_EXCHANGE_FIELD_LABEL_TITLE: "Exchange Rate",

  // Individual role Conversion "Exchange rate"

  CONVERSION_EXCHANGE_CBU_TITLE: "CBU Rates",
  CONVERSION_EXCHANGE_TABLE_BUY_COLUMN_TITLE: "BUY",
  CONVERSION_EXCHANGE_TABLE_SELL_COLUMN_TITLE: "SELL",
  CONVERSION_EXCHANGE_KDB_TITLE: "KDB Bank Uzbekistan Exchange Office Rate",
  CONVERSION_EXCHANGE_UZRCE_RATES_TITLE: "UzRCE Rates",
  CONVERSION_EXCHANGE_BANK_RATE_TITLE: "Bank Rate",

  // Individual role  Pre-Approval of New Loan & L/C Application

  PRE_APPROVAL_DEFAULT_TAB_TITLE: "Default",
  PRE_APPROVAL_CORPORATE_TAB_TITLE: "Corporate Borrower",
  PRE_APPROVAL_INDIVIDUAL_TAB_TITLE: "Individual Application",
  PRE_APPROVAL_COMMENT_TAB_TITLE: "Comment of KDB Bank Uzbekistan",

  PRE_APPROVAL_DATE_FIELD_LABEL_TITLE: "Date of Establishment",
  PRE_APPROVAL_REQUEST_FIELD_LABEL_TITLE: "Request number",
  PRE_APPROVAL_MANAGER_FIELD_LABEL_TITLE: "Bank MFO",
  PRE_APPROVAL_SCREEN_TITLE: "Screen #2. pre-approval of new loan or l/c application",
  PRE_APPROVAL_KDB_SUBTITLE:
    "To Chairman of KDB Bank Uzbekistan," +
    "Hereby, you are kindly asked to allow  (the intermediary bank) to utilize the on-lending loan funds under  (the General On-Lending Agreement dd. 10.05.2020) for financing of the following individual application.",

  PRE_APPROVAL_ADDRESS_FIELD_LABEL_TITLE: "Address",
  PRE_APPROVAL_PRODUCTS_FIELD_LABEL_TITLE: "Products",
  PRE_APPROVAL_BORROWER_FIELD_LABEL_TITLE: "Company name",
  PRE_APPROVAL_SIZE_FIELD_LABEL_TITLE: "Size of business",
  PRE_APPROVAL_EMPLOYEE_FIELD_LABEL_TITLE: "Employee number",
  PRE_APPROVAL_BUSINESS_FIELD_LABEL_TITLE: "Company management",
  PRE_APPROVAL_TAX_FIELD_LABEL_TITLE: "Tax identification number",
  PRE_APPROVAL_DATE_OF_FIELD_LABEL_TITLE: "Date of establishment",
  PRE_APPROVAL_MANAGEMENT_FIELD_LABEL_TITLE: "Management of Borrower",
  PRE_APPROVAL_INFO_CORPORATION_TITLE: "Information on corporate borrower",
  PRE_APPROVAL_LARGEST_FIELD_LABEL_TITLE: "Largest shareholders (above 10%)",

  PRE_APPROVAL_NET_PRODUCTS_FIELD_LABEL_TITLE: "Products",
  PRE_APPROVAL_TOTAL_BORROWER_FIELD_LABEL_TITLE: "Borrower",
  PRE_APPROVAL_TOTAL_ADDRESS_FIELD_LABEL_TITLE: "Address",
  PRE_APPROVAL_PAGE_SIZE_FIELD_LABEL_TITLE: "Size Of Business",
  PRE_APPROVAL_BANK_TAX_FIELD_LABEL_TITLE: "Tax Identification Number",
  PRE_APPROVAL_NET_DATE_OF_OF_FIELD_LABEL_TITLE: "Date Of Establishment",
  PRE_APPROVAL_EXPORT_EMPLOYEE_NUMBER_FIELD_LABEL_TITLE: "Employee Number",
  PRE_APPROVAL_TOTAL_MANAGEMENT_FIELD_LABEL_TITLE: "Management Of Borrower",
  PRE_APPROVAL_NET_BUSINESS_ACTIVITY_FIELD_LABEL_TITLE: "Business Activity",
  PRE_APPROVAL_FINANCIALS_TITLE: "Financials in (",
  PRE_APPROVAL_FINANCIALS_TITLE_SECOND: "); Unit: UZS thousand",
  PRE_APPROVAL_EXPORT_LARGEST_FIELD_LABEL_TITLE: "Largest Shareholders (Above 10%)",

  PRE_APPROVAL_FINANCIAL_NET_SALES_FIELD_LABEL_TITLE: "Net sales",
  PRE_APPROVAL_FINANCIAL_BANK_LOANS_FIELD_LABEL_TITLE: "(incl. bank loans & leases)",
  PRE_APPROVAL_FINANCIAL_NET_INCOME_FIELD_LABEL_TITLE: "Net income",
  PRE_APPROVAL_FINANCIAL_TOTAL_ASSETS_FIELD_LABEL_TITLE: "Total assets",
  PRE_APPROVAL_FINANCIAL_TOTAL_CAPITAL_FIELD_LABEL_TITLE: "Total capital",
  PRE_APPROVAL_FINANCIAL_EXPORT_REVENUE_FIELD_LABEL_TITLE: "Export revenue",
  PRE_APPROVAL_FINANCIAL_TOTAL_LIABILITIES_FIELD_LABEL_TITLE: "Total liabilities",
  PRE_APPROVAL_FINANCIAL_NEW_OPERATING_INCOME_FIELD_LABEL_TITLE: "New operating income",

  PRE_APPROVAL_SECURITY_FIELD_LABEL_TITLE: "Security",
  PRE_APPROVAL_SUBMIT_BUTTON_TITLE: "Submit application",
  PRE_APPROVAL_INTEREST_FIELD_LABEL_TITLE: "Interest rate",
  PRE_APPROVAL_REQUEST_GRACE_FIELD_LABEL_TITLE: "Grace period",
  PRE_APPROVAL_REQUEST_TERM_FIELD_LABEL_TITLE: "Requested term",
  PRE_APPROVAL_PURPOSE_FIELD_LABEL_TITLE: "Purpose of financing",
  PRE_APPROVAL_APPLICATION_FIELD_LABEL_TITLE: "Application type",
  PRE_APPROVAL_REQUEST_AMOUNT_FIELD_LABEL_TITLE: "Requested amount",
  PRE_APPROVAL_PAGE_REQUEST_TERM_FIELD_LABEL_TITLE: "Requested term",
  PRE_APPROVAL_INFO_INDIVIDUAL_TITLE: "Information on individual application",
  PRE_APPROVAL_DESCRIPTION_FIELD_LABEL_TITLE: "Description of goods being purchased",

  PRE_APPROVAL_CANCEL_BUTTON_TITLE: "Cancel application",
  PRE_APPROVAL_COMMENT_TITLE: "Comment of KDB Bank Uzbekistan",
  PRE_APPROVAL_UPDATE_BUTTON_TITLE: "Update & resubmit application",

  // Individual role  Transfers "Transfers To Another Client Tab"

  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TO_FIELD_LABEL_TITLE: "To",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_SUBMIT_BUTTON_TITLE: "Submit",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_FROM_FIELD_LABEL_TITLE: "From",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_AMOUNT_FIELD_LABEL_TITLE: "Amount",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TRANSFERS_DETAILS_FIELD_LABEL_TITLE: "Transfer Details",

  //Individual role Transfers "History Tab"

  INDIVIDUAL_TRANSFERS_HISTORY_TO_FIELD_LABEL_TITLE: "To",
  INDIVIDUAL_TRANSFERS_HISTORY_DATE_FIELD_LABEL_TITLE: "Date",

  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_ACCOUNT_COLUMN_TITLE: "Status",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Details",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Register",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Card Type",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Payment code",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Date and Time",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register №",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Transit account",

  //Individual role Transfers "Favorites Tab"

  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_STATUS_COLUMN_TITLE: "Status",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Subject",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_CREATED_BY_COLUMN_TITLE: "Created by",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Authorized by",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_EXPLANATION_COLUMN_TITLE: "Explanation",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Completed Date",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Date of Submission",
  TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL: "Document Name",

  // Corporate role "Client Feedback Tab"

  CLIENT_FEEDBACK_RATE_VERY_TITLE: "Very dissatisfied",
  CLIENT_FEEDBACK_RATE_VERY_SATISFIED_TITLE: "Very satisfied",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_SATISFIED_TITLE: "Slightly satisfied",
  CLIENT_FEEDBACK_RATE_NEITHER_TITLE: "Neither satisfied nor dissatisfied",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_DISSATISFIED_TITLE: "Slightly dissatisfied",
  CLIENT_FEEDBACK_RATE_PLEASE_TITLE:
    "Please rate your overall experience with KDB Internet Banking iDBA",
  CLIENT_FEEDBACK_SUGGESTIONS_TITLE: "Comments & Suggestions",

  CLIENT_FEEDBACK_NOT_LIKELY_SUBTITLE: "Very Good",
  CLIENT_FEEDBACK_EXTREMELY_LIKELY_SUBTITLE: "Very Bad",
  CLIENT_FEEDBACK_HOW_LIKELY_TITLE:
    "How likely are you to recommend KDB Internet Banking to any of your friends?",

  CLIENT_FEEDBACK_LOAN_CHECKBOX_LABEL_TITLE: "Loan",
  CLIENT_FEEDBACK_YEISVO_CHECKBOX_LABEL_TITLE: "YEISVO",
  CLIENT_FEEDBACK_NEWS_CHECKBOX_LABEL_TITLE: "News",
  CLIENT_FEEDBACK_SUBMIT_BUTTON_LABEL_TITLE: "Submit",
  CLIENT_FEEDBACK_HELP_CHECKBOX_LABEL_TITLE: "Help/FAQ",
  CLIENT_FEEDBACK_ACCOUNTS_CHECKBOX_LABEL_TITLE: "Accounts",
  CLIENT_FEEDBACK_ON_LENDING_CHECKBOX_LABEL_TITLE: "On Lending",
  CLIENT_FEEDBACK_ANALYTICS_CHECKBOX_LABEL_TITLE: "Analytics",
  CLIENT_FEEDBACK_CONVERSION_CHECKBOX_LABEL_TITLE: "Conversion",
  CLIENT_FEEDBACK_PAYMENTS_CHECKBOX_LABEL_TITLE: "Transfers",
  CLIENT_FEEDBACK_IN_WHICH_LIKELY_TITLE:
    "Which section of Internet Banking you would like to see improvement?",
  CLIENT_FEEDBACK_WHICH_LIKELY_TITLE:
    "Which section of Internet Banking is your primary or regular focus?",
  CLIENT_FEEDBACK_SENT: "Your feedback has been sent",
  CLIENT_FEEDBACK_CHOOSE_RATE: "Please rate from 1 to 10",

  //Financial role Main "Approved Deals Tab"

  FINANCIAL_APPROVED_DEALS_UNIT_SUBTITLE: "Unit: USD",
  FINANCIAL_APPROVED_DEALS_TABLE_TOTAL_COLUMN_TITLE: "Total",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Details",
  FINANCIAL_APPROVED_DEALS_APPROVED_DEALS_TITLE: "Approved deals",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: "Application date",
  FINANCIAL_APPROVED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Sub-Borrower name",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Application number",
  FINANCIAL_APPROVED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Approved Sub-loan amount",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE: "New Withdrawal",
  FINANCIAL_APPROVED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Outstanding Sub-loan amount",

  // Corporate role Accounts "Statement tab"
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE: "Debit",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE: "Credit",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_BALANCE_COLUMN_TITLE: "Balance",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_REFERENCE_COLUMN_TITLE: "Reference / Description",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_POST_VALUE_COLUMN_TITLE: "Post Value",
  STATEMENT_EXPORT_EXCEL_DATA_LABEL: "date",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_LABEL: "Sender Account",
  STATEMENT_EXPORT_EXCEL_SENDER_TAX_NUMBER_LABEL: "Sender TaxId",
  STATEMENT_EXPORT_EXCEL_SENDER_NAME_LABEL: "Sender Name",
  STATEMENT_EXPORT_EXCEL_SENDER_MFO_LABEL: "Sender MFO",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_LABEL: "Receiver Account",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_INDEX_LABEL: "Receiver Account(index)",
  STATEMENT_EXPORT_EXCEL_RECEIVER_MFO_LABEL: "Receiver MFO",
  STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL: "Receiver Name",
  STATEMENT_EXPORT_EXCEL_RECEIVER_TAX_NUMBER_LABEL: "Receiver TaxId",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_NUMBER_LABEL: "Document Number",
  STATEMENT_EXPORT_EXCEL_EXPENDITURE_LABEL: "Expenditure",
  STATEMENT_EXPORT_EXCEL_COMING_LABEL: "Coming",
  STATEMENT_EXPORT_EXCEL_PAYMENT_CODE_LABEL: "Payment Code",
  STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL: "Purpose of Payment",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_TYPE_LABEL: "Document Type",
  STATEMENT_EXPORT_EXCEL_TRANSACTION_NUMBER_LABEL: "Transaction Number",
  STATEMENT_EXPORT_EXCEL_CARD_TYPE_LABEL: "Card type",
  STATEMENT_EXPORT_EXCEL_TRANSITION_DATE_LABEL: "Transition date",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_NUMBER_LABEL: "Card number",
  STATEMENT_EXPORT_EXCEL_TRANSITION_TIME_LABEL: "Transition time",
  STATEMENT_EXPORT_EXCEL_TRANSITION_PLACE_LABEL: "Transaction place",
  STATEMENT_EXPORT_EXCEL_AMOUNT_LABEL: "Amount",
  STATEMENT_EXPORT_EXCEL_COMMISSION_AMOUNT_LABEL: "Commission amount",
  STATEMENT_EXPORT_EXCEL_AMOUNT_AFTER_COMMISSION_LABEL: "Amount after commission",
  STATEMENT_EXPORT_EXCEL_SELLER_ID_LABEL: "Seller ID",
  STATEMENT_EXPORT_EXCEL_CLIENT_NAME_LABEL: "Client Name",
  STATEMENT_EXPORT_EXCEL_ACCOUNT_NUMBER_LABEL: "Account Number",
  STATEMENT_EXPORT_EXCEL_DR_CR_INDICATOR_LABEL: "DRCRIndicator",
  STATEMENT_EXPORT_EXCEL_EFFECTIVE_DATE_LABEL: "Effective Date",
  STATEMENT_EXPORT_EXCEL_ISO_TERMINAL_ID_LABEL: "ISO Terminal ID",
  STATEMENT_EXPORT_EXCEL_ISO_MERCHANT_ID_LABEL: "ISO Merchant ID",
  STATEMENT_EXPORT_EXCEL_CARD_NUMBER_LABEL: "Card number",
  STATEMENT_EXPORT_EXCEL_DT_BEG_LABEL: "Beginning date",
  STATEMENT_EXPORT_EXCEL_DT_END_LABEL: "End date",
  STATEMENT_EXPORT_EXCEL_H_LOCACC_LABEL: "H_LOCCAC",
  STATEMENT_EXPORT_EXCEL_DOC_NUMBER_LABEL: "Doc №",
  STATEMENT_EXPORT_EXCEL_BO_LABEL: "BO",
  STATEMENT_EXPORT_EXCEL_PAYERS_NAME_LABEL: "Payer's name",
  STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL: "Tax number",
  STATEMENT_EXPORT_EXCEL_MFO_LABEL: "MFO",
  STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL: "Checking account",
  STATEMENT_EXPORT_EXCEL_PAYMENT_DATE_LABEL: "Payment date",
  STATEMENT_EXPORT_EXCEL_OPERATION_DATE_LABEL: "Operation date",
  STATEMENT_EXPORT_EXCEL_RECEIVER_BANK_LABEL: "Receiver bank",
  STATEMENT_EXPORT_EXCEL_TOTAL_PERIOD_LABEL: "Total for the period",
  STATEMENT_EXPORT_EXCEL_BALANCE_END_PERIOD_LABEL: "Balance at the end of the period",
  STATEMENT_EXPORT_EXCEL_BALANCE_BEGINNING_PERIOD_LABEL: "Balance at the beginning of the period",
  STATEMENT_EXPORT_EXCEL_TO_LABEL: "to",
  STATEMENT_EXPORT_EXCEL_FROM_LABEL: "for the period from",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_LABEL: "Statement of debit turnovers on the account",
  STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL: "PASSIVE",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_CREDIT_LABEL:
    "Statement of credit turnovers on the account",
  STATEMENT_NO_TRANSACTIONS: "No Transactions",

  // Corporate role Transfers "Salary tab"
  SALARY_CREATE_NEW_REGISTER_SUBMIT_BUTTON_TITLE: "Submit",
  SALARY_CREATE_NEW_REGISTER_TABLE_ID_COLUMN_TITLE: "#",
  SALARY_CREATE_NEW_REGISTER_TABLE_STATUS_COLUMN_TITLE: "Status",
  SALARY_CREATE_NEW_REGISTER_TABLE_CARD_STATUS_COLUMN_TITLE: "Card Status",
  SALARY_CREATE_NEW_REGISTER_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE: "Full Name",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE: "Company Name",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Account Number",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_FROM_SYSTEM_COLUMN_TITLE: "Full Name From System",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_FROM_SYSTEM_COLUMN_TITLE:
    "Company Name From System",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_FROM_SYSTEM_COLUMN_TITLE:
    "Account number from system",
  CORPORATE_CARD_REPLANISHMENT_TEXT: "Corporate Card Replenishment according to Register",
  TRANSFERS_TRANSFERS_OPEN_MODAL_BUTTON_TITLE: "Upload from card holders",
  TRANSFERS_SALARY_LIST_OF_CARD_CHOOSE: "Upload",

  // Payroll statuses
  PAYROLL_STATUS_CREATED: "Created",
  PAYROLL_STATUS_ENTERED: "Entered",
  PAYROLL_STATUS_APPROVED: "Received by Bank",
  PAYROLL_STATUS_PROCESSING: "Processing",
  PAYROLL_STATUS_SENT_TO_BANK: "Sent to bank",
  PAYROLL_STATUS_REJECTED: "Rejected by Client",
  PAYROLL_STATUS_ANNULLED: "Annulled",
  PAYROLL_STATUS_COMPLETED: "Successfully Completed",
  PAYROLL_STATUS_SENT_TO_CB: "Sent to CB",
  PAYROLL_STATUS_REJECTED_BY_CB: "Rejected by Bank",
  PAYROLL_STATUS_SENT_TO_CARD_SYSTEM: "Sent to Card System",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1: "Approved by First Authorizer",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2: "Approved by Second Authorizer",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3: "Approved by Third Authorizer",
  STATUS_REJECTED: "Rejected",
  STATUS_ACCEPTED: "Accepted",
  REJECT_BUTTON: "Are you sure you want to reject this?",

  TRANSFER_HISTORY_STATUS_ALL: "All",
  TRANSFER_HISTORY_STATUS_OUTGOING: "Outgoing",
  TRANSFER_HISTORY_STATUS_INCOMING: "Incoming",

  TRANSFER_SALARY_DETAIL_TYPE_SALARY: "Salary",
  TRANSFER_SALARY_DETAIL_TYPE_PREPAYMENT: "Prepayment",
  TRANSFER_SALARY_DETAIL_TYPE_TRAVEL: "Travel expenses",
  TRANSFER_SALARY_DETAIL_TYPE_COMPENSATION: "Compensation",
  TRANSFER_SALARY_DETAIL_TYPE_BONUS: "Bonus",
  TRANSFER_SALARY_DETAIL_TYPE_PENSION: "Pension",
  TRANSFER_SALARY_DETAIL_TYPE_OTHERS: "Others",
  TRANSFER_SALARY_DETAIL_TYPE_VOCATION: "Vocation pay",
  TRANSFER_SALARY_DETAIL_TYPE_SETTLEMENT: "Final settlement",

  HELP_AND_FAQ_QUESTION: "Question: ",
  HELP_AND_FAQ_ANSWER: "Answer: ",

  ATTENTION_TITLE: "Attention!",
  ATTENTION_SUBTITLE: "Another user has logged in!",

  TRANSFER_ERRORS_BENEFICIARY_BANK: "Bank is not found",
  TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE: "Beneficiary bank",
  TRANSFER_ERRORS_AMOUNT: "Amount can't be 0",
  TRANSFER_ERRORS_AMOUNT_TITLE: "Amount",
  TRANSFER_ERRORS_DEBIT_ACCOUNT: "Choose debit account",
  TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE: "Debit",
  TRANSFER_ERRORS_BUDGET_ACCOUNT: "Budget account not found",
  TRANSFER_ERRORS_BUDGET_ACCOUNT_TITLE: "Budget account",
  TRANSFER_INFO_CREATED: "Transfer created",
  TRANSFER_MODIFIED: "Transfer modified",
  TRANSFER_INFO_TITLE: "Transfer",
  TRANSFER_CHARGES_ERROR: "Please choose charge for transfer",
  TRANSFER_SENDER_PHONE_NUMBER_ERROR: "Phone Number is Required!",
  TRANSFER__CHOOSE_CHARSET: "Please choose charset",
  TRANSFER_CHARGES_ERROR_TITLE: "Charge",
  TRANSFER_FILL_INFO: "Please use the following transliteration table from Russian to English.",

  TRANSFER_NACE_CODE_ERROR_TITLE: "Nace Code",
  TRANSFER_NACE_CODE_ERROR: "Please choose Nace Code",

  INQUIRY_STANDART_ERRORS_MAX: "Max 20 accounts can be entered or choose all",
  INQUIRY_STANDART_ERRORS_MAX_TITLE: "Account's",

  CONVERSION_INFO_SEND: "Conversion created",
  CONVERSION_INFO_TITLE: "Conversion",
  CONVERSION_FAVORITES_ERROR: "Please enter number and date of contract",
  CONVERSION_FAVORITES_ERROR_TITLE: "Favorite",
  CONVERSION_FAVORITES_INFO_SAVED: "Saved to favorite",
  CONVERSION_DOCUMENT_ID: "Document ID",

  CONVERSION_VISA_CLOSE_DAY_ERROR_TITLE:
    "VISA ochilish balansini solishtirish tufayli, iltimos, 30 daqiqadan so‘ng qayta urinib ko‘ring",

  SALARY_CREATED_INFO: "Salary register created",
  SALARY_CREATED_INFO_TITLE: "Salary register",
  SALARY_CARD_CREATED_INFO: "Request for opening new salary card has been created",
  SALARY_CARD_CREATED_INFO_TITLE: "Salary card",
  SALARY_CHOOSE_CARD_TYPE: "Please choose card type",
  SALARY_CHOOSE_CARD_TYPE_TITLE: "Card type",
  SALARY_CHOOSE_PHONE_TYPE: "Please Input Phone",
  SALARY_CHOOSE_PHONE_TYPE_TITLE: "Phone Type",
  SALARY_NO_TRANSIT_ACCOUNT: "There is no any transit account",
  SALARY_NO_TRANSIT_ACCOUNT_TITLE: "Transit account",
  SALARY_FULLNAME_LIMIT_ERROR_TITLE:
    "Name and Surname (Name + Surname) should not exceed 24 characters",

  QUESTION_CREATED_INFO: "Question received by bank",
  QUESTION_CREATED_INFO_TITLE: "Question",

  YEISVO_FIND_ERROR: "document not found in YESIVO system",
  YEISVO_FIND_ERROR_TITLE: "YESIVO",

  PASSWORD_ERROR_TITLE: "Password",
  SERVER_ERROR_TITLE: "Technical work is underway",

  FEEDBACK_INFO_TITLE: "Feedback",

  INFORMATION_TITLE_1:
    "Please check status of the request in Standard inquiries screen. When status will be\n" +
    "          changed to completed, approach the Bank to receive original letter. Note: Bank will charge\n" +
    "          commission for this request as per bank tariffs",

  INFORMATION_TITLE_2:
    "BEN (beneficiary) means you do not pay any charge. We receive your payment minus all\n" +
    "          transfer charges.",
  INFORMATION_TITLE_3:
    "    The OUR instruction means Sender pays all transfer charges. Beneficiary receives all\n" +
    "          senders payment.",
  INFORMATION_TITLE_4:
    "         SHA (shared) mean's you only pay your bank's outgoing transfer charge. We receive your\n" +
    "          payment minus the correspondent (intermediary) bank charges.",
  INFORMATION_TITLE_5:
    "    The PPRO/END instruction means Sender pays all transfer charges. Beneficiary receives all\n" +
    "          senders payment. For USD transfer via JP Morgan Chase Bank only.",
  INFORMATION_TITLE_6: "Please submit maximum 2,000 employees in one register",
  INFORMATION_TITLE_7: "Please, ensure there are sufficient funds in the relevant transit account to process payroll.",
  INFORMATION_TITLE_8: "Please, ensure there are sufficient funds in the relevant demand deposit account to process payroll.",

  MODAL_CONFIRMATION_TITLE: "Confirmation",
  MODAL_CONFIRMATION_YES: "Confirm",
  MODAL_CONFIRMATION_NO: "Cancel",
  MODAL_YES: "Yes",
  MODAL_NO: "No",
  MODAL_PAYMENT_ORDER_TO_RESERVE_FUNDS:
    "Would you like to create a payment order to reserve funds to a special account 22613?",
  MODAL_INFO_TRANSFER_FIRST:
    "Please confirm that you have applied for the conversion for transferring amount from conversion account",
  MODAL_INFO_TRANSFER_SECOND:
    "Please confirm that you want to transfer from your own funds (not from conversion account)",
  MODAL_INFO_SALARY: "Please confirm that salary tax payments have been paid",
  MODAL_INFO_CONVERSION:
    "\n" +
    "Public offer\n" +
    "for rendering conversion services\n" +
    "This document is an official offer (public offer) of “KDB Bank Uzbekistan” JSC (hereinafter referred to as Bank) and defines the standard conditions and procedure for banking operations with Bank’s Clients (hereinafter referred as Client):\n" +
    "    • on conversion of foreign currency into the national currency of the Republic of Uzbekistan (hereinafter referred to as national currency), \n" +
    "    • on conversion of national currency into foreign currency (excl. conversion for business trip expenses),\n" +
    "    • on conversion of foreign currency of one type into foreign currency of another type.\n" +
    "\n" +
    "In accordance with paragraph 2 of Article 369 of the Civil Code of the Republic of Uzbekistan, if the conditions set forth below are accepted and adhered to the agreement when submitting a conversion application or a transfer application in a foreign currency, Client accepting this public offer becomes a Party to the Agreement (in accordance with part 4 of Article 370 of the Civil Code of the Republic of Uzbekistan, acceptance of an offer is tantamount to concluding an agreement on the terms set out in the offer), and Bank and Client jointly - Parties to the agreement.\n" +
    "1. TERMINOLOGY\n" +
    "\n" +
    '1.1 Offer – the actual document "Public offer for rendering conversion services".\n' +
    "1.2. Public offer for rendering conversion services – an agreement between Bank and Client for rendering conversion services, which is concluded through the acceptance of the offer.\n" +
    "\n" +
    "1.3. Client - person who has accepted the public offer and is thus the customer of Bank’s services in line with the public offer.\n" +
    "\n" +
    "1.4. Bank - Head office of “KDB Bank Uzbekistan” JSC and its branches.\n" +
    "1.5. Acceptance of the public offer - full and unconditional acceptance of the offer by Client taking the actions specified in clause 1.7 of this public offer.\n" +
    "1.6. Remote service applications (hereinafter referred to as RSA) are software packages of Bank that allow Client to generate and transmit to Bank instructions for conversion and payment. \n" +
    "\n" +
    "\n" +
    "1.7. Clause on joining to public offer - a written clause in the conversion application (Appendix No. 1, Appendix No. 2) or in the transfer application in a foreign currency (Appendix No. 31) providing for Client’s unambiguous and automatic consent to join the public offer and acceptance of its conditions. Client having signed the conversion application or the transfer application in a foreign currency or authorizing these documents through RSA, automatically accepts the terms of the public offer.\n" +
    "\n" +
    "\n" +
    "1.8. Service - rendering conversion service by Bank.\n" +
    "1.9. Conversion - an operation on:\n" +
    "    • the sale of foreign currency and the purchase of national currency by Client, \n" +
    "    • the sale of national currency and the purchase of foreign currency by Client,\n" +
    "    • the purchase and the sale of foreign currency of one type for foreign currency of another type by Client.\n" +
    "1.10. Transfer application – a document providing Client's instruction to Bank to transfer foreign currency funds by debiting Client's funds in a foreign currency in which Client has an account with the Bank and further payment in the same currency or in converted currency specified by Client in the transfer application.\n" +
    "\n" +
    "1.11. Conversion application - a document providing for Client's instruction to Bank for:\n" +
    "\n" +
    "    • sale of foreign currency and further crediting to Client’s account in national currency,\n" +
    "\n" +
    "    • sale of national currency and purchase of foreign currency and further crediting to a special account of Client in foreign currency,\n" +
    "\n" +
    "    • sale of foreign currency of one type and further crediting to Client's account in foreign currency of another type specified by Client in the conversion application.\n" +
    "\n" +
    "\n" +
    "2. GENERAL TERMS FOR RENDERING SERVICES BY BANK \n" +
    "\n" +
    "2.1. This public offer determines the procedure and conditions for interaction between Bank and Client when performing conversion.\n" +
    "2.2. The service is provided to Bank’s Clients who have accepted this public offer by signing and submitting a conversion application and/or a transfer application to Bank. By signing a conversion application and/or a transfer application or authorizing these applications through RSA, Client has the right to use Bank’s service as necessary.\n" +
    "\n" +
    "2.3. Service provided to Client is limited solely to the execution of a conversion request and/or a transfer request. Bank does not provide recommendations or advice in essence or expediency of buying / selling or other operations with foreign currency within the framework of this public offer.\n" +
    "\n" +
    "2.4. The amount and type of currency for conversion is determined by Client based on a separate conversion application or a transfer application, which are an integral part of this public offer and have the force of an Agreement with all the ensuing rights and obligations inherent in the Agreement.\n" +
    "2.5. Conversion under this public offer can be carried out repeatedly during the validity period of the public offer.\n" +
    "2.6. Conversion under this public offer is carried out on the basis of this public offer, conversion application, transfer application and other necessary documents provided for by applicable law.\n" +
    "3. PROCEDURE ON CONVERSION OF FOREIGN CURRENCY INTO NATIONAL CURRENCY\n" +
    "\n" +
    "\n" +
    "3.1. Client (other than individuals2) sells foreign currency at the exchange rate set by Bank at the time of the transaction.\n" +
    "\n" +
    "3.2. Client transfers and reserves on its account with the Bank foreign currency funds in the amount sufficient for the sale, before submitting the application.\n" +
    "3.3. Сlient is obliged to have a demand deposit account with the Bank in the national currency for crediting the purchased funds.\n" +
    "3.4. Client submits to Bank an application in the form according to Appendix No. 1 to this public offer.\n" +
    "3.5. Client indicates in the application the minimum exchange rate at which Client agrees to sell foreign currency.\n" +
    "At the same time, the application will be satisfied if Bank’s exchange rate at the time of execution of the application is equal to / higher than the minimum exchange rate specified in the application. In this case, the sale of foreign currency is carried out at Bank’s exchange rate.\n" +
    "\n" +
    "The application will remain unsatisfied until Bank’s exchange rate is equal / higher than the minimum exchange rate specified in the application.\n" +
    "3.6. Bank executes the application and carries out all settlements with Client no later than 2 (two) banking business days after the date of the application.\n" +
    "3.7. Client notifies Bank on the revocation of the application submitted to Bank before Bank starts the process of execution of the application.\n" +
    "\n" +
    "4. PROCEDURE ON CONVERSION OF NATIONAL CURRENCY INTO FOREIGN CURRENCY\n" +
    "\n" +
    "4.1. Client (other than individuals3) buys foreign currency at the exchange rate set by Bank at the time of the transaction.\n" +
    "\n" +
    "4.2. Currency, amount and maximum exchange rate, the purpose of using the purchased currency as well as the documents justifying the currency operation, are indicated in the application (Appendix No. 2).\n" +
    "4.3. Transactions on the purchase of foreign currency are carried out for their purposeful use by Client on the basis of Client’s applications submitted in paper form or via RSA.\n" +
    "\n" +
    "4.4. When purchasing foreign currency Bank has the right to require additional documents confirming the validity of these transactions.\n" +
    "\n" +
    "4.5. Funds credited to special foreign currency accounts are used strictly for the purposes specified in the conversion applications.\n" +
    "4.6. When buying foreign currency in accordance with section 4 of this public offer:\n" +
    "\n" +
    "4.6.1 Client is obliged to open a special foreign currency account (balance account - 22614) to which the purchased foreign currency is credited.\n" +
    "\n" +
    "4.6.2. Client is obliged to open a special national currency account (balance account - 22613) and through this account, no later than (2) two banking business days, to carry out all settlements with Bank on applications, on which Bank has adopted positive conclusions.\n" +
    "\n" +
    "If Client does not complete settlements within (2) two banking business days, the transaction is considered canceled. At the same time, the sell back of funds purchased by Client is carried out at the exchange rate set by Bank at the time of the sell back transaction.\n" +
    "\n" +
    "4.6.3. When purchasing foreign currency, Client is obliged to submit to Bank an application, if necessary, copies of documents confirming the basis for buying foreign currency (purposeful use). In this case, in the event that the submitted documents do not comply with the requirements of the current legislation and this public offer, Bank has the right to refuse to execute Client's application.\n" +
    "\n" +
    "4.6.4. Client is obliged to use the purchased foreign currency funds within 7 (seven) banking business days from the moment the funds are credited to the foreign currency account.\n" +
    "4.6.5. Foreign currency, previously debited from a special foreign currency account and unused, as well as newly received due to other reasons, is subject to sell back to Bank within (3) three banking business days, or reused strictly for the purposes specified in the application.\n" +
    "\n" +
    "4.6.6. By joining this public offer, Client gives his unconditional and irrevocable consent to Bank to write off, without acceptance, from any of its accounts the amount equivalent to the purchased foreign currency specified in the application.\n" +
    "\n" +
    "4.6.7. If Client does not fulfill the conditions of the first paragraph of clause 4.6.2. of the public offer Client hereby gives its unconditional and irrevocable consent to Bank to write off possible Bank losses from any of its accounts without acceptance (including but not limited to negative difference between buying exchange rate and selling exchange rate).\n" +
    "\n" +
    "4.6.8. Client is obliged to cancel the application if any of the following conditions occur:\n" +
    "\n" +
    "    • changes in the purpose of the purchase in the application;\n" +
    "    • when blocking the account according to the decisions of the authorized bodies.\n" +
    "In case of untimely provision of funds for mutual settlements with Bank, Bank has the right to independently cancel the application. In the event of the above cases and Client's failure to inform Bank, Bank has the right to independently cancel the application without acceptance, and sell back foreign currency funds.\n" +
    "\n" +
    "\n" +
    "\n" +
    "4.7. When buying foreign currency in accordance with section 4 of this public offer:\n" +
    "\n" +
    "4.7.1. Bank is obliged to consider Client’s submitted application no later than the next banking business day after the day of submission of the conversion application.\n" +
    "\n" +
    "4.7.2. If it is impossible to execute the application due to non-compliance with the established requirements of the current legislation, Bank has to inform Client about it in writing.\n" +
    "\n" +
    "4.7.3. Bank is obliged to execute the applications and no later than (2) two banking business days to carry out all settlements with Client on applications, on which Bank has adopted positive conclusion.\n" +
    "4.7.4. Bank carries out without acceptance the reverse sale of foreign currency purchased by Client previously debited from special foreign currency accounts of Client and newly received into these accounts, due to non-use or return within 3 (three) banking business days.\n" +
    "\n" +
    "4.7.5. Bank sells back foreign currency purchased by Client without acceptance in case of non-use of the purchased foreign currency by Client within 7 (seven) banking business days from the moment it is credited to the special foreign currency account of Client. The sale must be carried out no later than the next banking business day after the end of this period.\n" +
    "\n" +
    "\n" +
    "4.7.6. In case of cancellation of the conversion application based on Client's written request, Bank takes measures in accordance with clause 4.6.7..\n" +
    "4.7.7. If Client’s funds are insufficient to pay for the purchased foreign currency, Bank reserves the right to debit, without acceptance, from any Client’s accounts the amount equivalent to the purchased foreign currency specified in the application.\n" +
    "\n" +
    "\n" +
    "5. PROCEDURE ON CONVERSION OF FOREIGN CURRENCY OF ONE TYPE INTO FOREIGN CURRENCY OF ANOTHER TYPE\n" +
    "\n" +
    "\n" +
    "5.1. For conversion Client submits to Bank a respective conversion application (Appendix No. 1) or a transfer application in a foreign currency (Appendix No. 3).\n" +
    "\n" +
    "5.2. Conversion is carried out in freely convertible foreign currencies, for which Bank has capability to conduct conversion.\n" +
    "\n" +
    "5.3. The conversion application or the transfer application is executed by Bank within 2 (two) banking business days after the date of acceptance by the Bank, taking into account that the day of execution of the application is not a public holiday in the countries of currencies that participate in the transaction.\n" +
    "5.4. The conversion is carried out according to the conversion application or the transfer application of Client at Bank’s exchange rate which includes Bank’s margin.\n" +
    "5.5. When carrying out the conversion and up to executing the conversion application or the transfer application by Bank, Client must ensure on its accounts with Bank the availability of foreign currency for the conversion.\n" +
    "\n" +
    "5.6. Bank’s margin for the service is set by Bank’s tariffs.\n" +
    "5.7. Payment for Bank services is carried out via debiting by Bank the required amount of the counter currency (sold currency) from Client's account (s) without acceptance.\n" +
    "5.8. By signing a conversion application or a transfer application or authorizing these applications in RSA, Client provides Bank with the right, without any additional Client’s consent, to debit Client's accounts opened with the Bank for the amount required for the conversion in accordance with the approved Bank’s tariffs.\n" +
    "6. RIGHTS AND OBLIGATIONS OF PARTIES\n" +
    "6.1. Bank reserves the right to refuse to execute the conversion in case of incorrect filling of the application by Client.\n" +
    "\n" +
    "6.2. Bank hereby is not responsible for errors and misprints arising through the fault of Client in the context of the application. The responsibility for the accuracy of the information specified in the application rests with Client.\n" +
    "6.3. Bank is not responsible for the untimely and / or improper action of other participants in the transaction as well as in case of failure to conclude the transaction in accordance with the legislation of the Republic of Uzbekistan.\n" +
    "6.4. Bank undertakes to timely and to fully fulfill the obligations stipulated in this public offer.\n" +
    "\n" +
    "\n" +
    "6.5. Bank has the right to receive the necessary explanations, documents and information on issues related to the operations carried out by Client.\n" +
    "6.6. Bank has the right to refuse to execute any application in full or in part.\n" +
    "6.7. Bank has the right to unilaterally suspend the provision of services in the following cases:\n" +
    "\n" +
    "A) incorrect filling in of the conversion application or the transfer application by Client;\n" +
    "\n" +
    "B) improper performance of obligations by Client provided for in this public offer;\n" +
    "\n" +
    "C) detection of transactions by Bank that do not meet the terms of the public offer or the current legislation of the Republic of Uzbekistan;\n" +
    "\n" +
    "D) non-acceptance of additional conditions and changes to this public offer by Client;\n" +
    "\n" +
    "E) presence of suspicion in ongoing transactions that may relate to money laundering and financing terrorism.\n" +
    "\n" +
    "6.8. Bank has the right to change tariffs, to make amendments and additions to the terms of this offer. At the same time, Bank notifies Client about changes in the tariffs no later than 10 (ten) banking business days before the changes come into force by posting information about the changes and additions being made at the stands in Bank’s divisions and on Bank’s website www.kdb.uz.\n" +
    "\n" +
    "6.9. If objections regarding Bank’s amendments / additions to the tariffs and the terms of this public offer are not received from Client within 10 days, these amendments / additions are considered accepted by Client. If Client does not agree with amendments or additions made to the tariffs and the terms of this offer, prior to the entry into force of such amendments / additions Client has the right to terminate the agreement by providing a written notice.\n" +
    "\n" +
    "\n" +
    "6.10. Client is obliged to provide, at Bank’s request, the necessary clarifications, documents and information on issues related to the operations carried out by the Client.\n" +
    "6.11. Client is obliged to pay for Bank’s services in accordance with the current Bank’s tariffs in the manner prescribed by this public offer, for which purpose, to maintain a sufficient balance of funds (in counter currency/sold currency/national currency) on the account(s) to pay for the purchased currency.\n" +
    "\n" +
    "6.12. Client has the right to use Bank’s services submitting to Bank, as necessary, a conversion application or a transfer application.\n" +
    "7. FORCE MAJEURE\n" +
    "\n" +
    "7.1. Parties are not responsible for non-fulfillment or improper fulfillment of their obligations arising from this public offer, if their proper fulfillment is impossible due to occurrence of force majeure circumstances (natural disasters, strikes, civil unrest, wars or any other circumstances beyond the control of Parties, including due to changes in the legislation of the Republic of Uzbekistan or the publication / adoption of acts / instructions by the Central Bank of the Republic of Uzbekistan).\n" +
    "\n" +
    "\n" +
    "\n" +
    "7.2. Bank is not liable in the following cases:\n" +
    "\n" +
    "7.2.1. In case of possible failures in the interbank electronic payment system;\n" +
    "7.2.2. Arrest and / or suspension and / or freezing of transactions on the accounts of Bank or Client in the manner prescribed by law;\n" +
    "\n" +
    "7.2.3. If Bank is unable to fulfill its obligations under the public offer due to reasons beyond Bank’s control and upon the occurrence of force majeure;\n" +
    "7.3. Bank is not responsible for the failure to satisfy the applications due to circumstances beyond Bank’s control.\n" +
    "\n" +
    "\n" +
    "8. CONFIDENTIALITY\n" +
    "8.1. Parties undertake, in accordance with the public offer, to keep secret and not disclose in any way to third Parties confidential information that has become known to them as a result of the conclusion and execution of this public offer.\n" +
    "\n" +
    "\n" +
    "8.2. Confidential information that was received by the Party prior to the acceptance of this public offer, information obtained by lawful methods from other sources, as well as information that cannot be classified as confidential in accordance with the current legislation of Parties is not recognized as confidential.\n" +
    "\n" +
    "8.3. If it becomes necessary to provide or disclose confidential information at the request of an authorized state body, to which the right to demand the provision of confidential information is provided by law, the Party is obliged to notify in writing or verbally the other Party that provided this information.\n" +
    "\n" +
    "\n" +
    "\n" +
    "8.4. Parties undertake to:\n" +
    "8.4.1. ensure the storage of confidential information, excluding unauthorized access to it by third Parties;\n" +
    "\n" +
    "8.4.2. not to transfer confidential information to third Parties, both in full and in part;\n" +
    "\n" +
    "8.4.3. not to publish confidential information in the media and not to disclose the content of confidential information to an unlimited number of persons in any other way;\n" +
    "\n" +
    "\n" +
    "8.4.4. provide access to confidential information exclusively to duly authorized persons of Parties;\n" +
    "\n" +
    "8.4.5. maintain a record of persons who have gained access to the confidential information of the other Party obtained under this public offer;\n" +
    "\n" +
    "8.4.6. perform other obligations to protect confidential information provided for by the legislation of Parties.\n" +
    "9. OTHER CONDITIONS\n" +
    "\n" +
    "9.1. The public offer comes into force from the moment of signing and submitting the conversion application or the transfer application in a foreign currency to Bank or from the moment of authorization of these applications in RSA.\n" +
    "9.2. The validity period of the public offer is unlimited.\n" +
    "9.3. Each time when submitting a conversion application or a transfer application to Bank or when authorizing applications in RSA, Client joins this public offer.\n" +
    "\n" +
    "9.4. This public offer is subject to termination in the following cases:\n" +
    "    • in case of the Client's refusal from the amendments and additions made by Bank to the tariffs and the terms of the offer;\n" +
    "    • in the manner prescribed by the legislation of the Republic of Uzbekistan.\n" +
    "9.5. From the date of entry into force of this public offer, all previous agreements and contracts between Parties for rendering conversion services are considered terminated.\n" +
    "\n" +
    "9.6. Parties take all measures to resolve the differences between them through negotiations. If an agreement is not reached, all disputes arising from this public offer are resolved in the Interdistrict Economic Court of Tashkent.\n" +
    "\n" +
    "\n" +
    "9.7. Neither Party has the right to transfer or assign to third Parties its rights and obligations under this public offer without the prior written consent of the other Party.\n" +
    "\n" +
    "9.8. In everything else that is not directly regulated in this public offer, Parties are guided by the current legislation of the Republic of Uzbekistan.\n" +
    "\n" +
    "9.9. Public offer has been drawn up in Russian and English. In the event of any disagreement or dispute, the Russian version of the public offer shall prevail.\n",
  MODAL_CONVERSION_TITLE: "Public offer",
  MODAL_LOADING_TITLE: "Loading...",
  MODAL_LOADING_EXCEL_TITLE: "In progress . . .",
  
  PENDING_APPROVAL_APPROVE: "Approve",
  PENDING_APPROVAL_REJECT: "Reject",

  APP_HEADER_SEARCH: "Search...",

  WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE:
    "When selecting a future date please make sure it is a working day, otherwise the payment instruction may not be processed.",

  TRANSFER_NOTIFICATION_TITLE: "Transfer",
  TRANSFER_NOTIFICATION_FIRST: "Transfer N-",
  TRANSFER_CREATED: "created",
  TRANSFER_NOT_CREATED: "not created",
  TRANSFER_NO_DEBIT_IMPORT: "No debit like this",
  TRANSFER_NO_PHONE_IMPORT: "Phone is empty",
  TRANSFER_DATE_INCORRECT_IMPORT: "Incorrect transaction date",
  TRANSFER_CORRESPANDENT_TAX_ID_INCORRECT_IMPORT: "Beneficiary Tax ID must be 9 digits long",

  FILE_SELECTOR_IMPORT_FROM_CSV: "Import from CSV",
  FILE_SELECTOR_IMPORT_FROM_TXT: "Import from TXT",

  SALARY_REGISTER_STATUS_MATCH: "Match",
  SALARY_REGISTER_STATUS_NOT_MATCH: "Not match",
  SALARY_REGISTER_STATUS_NOT_FOUND: "Account is not found",
  SALARY_REGISTER_CARD_STATUS_ACTIVE: "Active",
  SALARY_REGISTER_CARD_STATUS_CLOSED: "Closed",
  SALARY_REGISTER_CARD_STATUS_SUSPENDED: "Suspended",

  TRANSFER_IMPORT_DEFECTED: "Defected",
  TRANSFER_IMPORT_ERROR: "Error",
  TRANSFER_SUCCESS: "Success",
  TRANSFER_LOADING: "Loading",

  TRANSFER_UPAY_AMOUNT_ERROR_TITLE: "Minimum transfer amount 1000 sum",

  TRANSFER_ANOTHER_BANK_CLIENT_KDB_CARD_ERROR_TITLE:
    'The recipient is a client of KDB Bank, please use the function "Transfers to other KDB clients"',

  TRANSFER_IMPORT_TABLE_HEADER: "Import",
  TRANSFER_IMPORT_TABLE_COMMENT: "Comments",
  TRANSFER_SWIFT_NOT_FOUND: "Beneficiary swift bank code not found",
  TRANSFER_SWIFT_FIRST_NOT_FOUND: "Сorrespondent bank of beneficiary swift bank code not found",

  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE: "Contract Currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_BANK_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Country of the foreign partner bank",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Legal address of a foreign partner bank",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_2_FIELD_LABEL_TITLE: "Settlement Currency 2",
  YEISVO_CLIENTS_INFORMATION_FORM_DETAILS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Bank details of a foreign partner",

  ACCOUNT_OPENING_POSITION_HELD: "Position held",
  DELETE_BUTTON: "Delete",

  IMPORT_FOR_ANOR: "Transfer through ANOR",
  IMPORT_FOR_CBU: "Transfer through CBU Clearing",

  CONVERSION_MODIFIED: "Conversion modified",

  LOAN_INTEREST_AMOUNT: "Payment Amount (Interest)",

  SALARY_CHOOSE_GENDER: "Please choose gender",
  SALARY_CHOOSE_BIRTHDAY_DATE: "Please choose Birth Of Date",
  SALARY_CHOOSE_PASSPORT_REGISTRATION_DATE: "Please choose Date Of Issue Of Passport",
  SALARY_CHOOSE_PASSPORT_EXPIRY_DATE: "Please choose Passport Validity",

  SALARY_TOTAL_AMOUNT: "Total amount",
  SALARY_DETAILS_FOR: "for",
  SALARY_DETAILS_ACCORDING: "Registry No",
  SALARY_PINFL_IS_REQUIRED: "PINFL required",

  CONVERSION_COMMISSION_RATE_AND_AMOUNT: "Please check commission amount and rate",

  CONVERSION_PURPOSE_ERROR: "Please choose purpose",

  PENDING_APPROVAL_DOCUMENT_CHOOSE: "Please choose document",
  PENDING_APPROVAL_JOB_CHOOSE: "Please choose 'Approve' or 'Reject'",

  CHANGE_PASSWORD_SENT_TO_EMAIL: "Sent code to email",
  CHANGE_PASSWORD_CODE_FROM_EMAIL: "Code from email",
  OTP_KEY: "OTP key",
  TRANSFER_TIME_OUTGOING_TO_SUBMIT: "Outgoing transfers servicing time (Tashkent):",
  TRANSFER_TIME_INTERNAL_TO_SUBMIT: "Internal transfers servicing time (Tashkent):",
  TRANSFER_SERVICING_TIME: "Transfers servicing time (Tashkent):",

  SALARY_CREATE_AND_SAVE_TO_PDF: "Submit and save to PDF",

  SAVE_TO_WORD: "Save to word",

  DISABLED: "Do not use the following symbols",

  APRROVE_INFO:
    "If FCY transfer amount exceeds USD 10,000 or equivalent, please expect call from Bank specialist for Call Back authorization",

  CONFIRM_SESSION: "Continue",
  CONFIRM_TEXT: "Do you want to continue session?",
  EXIT_SESSION: "Exit",

  TRANSFER_BENEFICIARY_ERROR: "Budget account not found",
  TRANSFER_STATUS_SENT_BY_BANK: "Sent by bank",

  SYSTEM_ERROR: "System error (try again later)",

  TRANSFER_TEXT_REQUIRED: "Please use only followings:",

  LOGIN_WRONG_CAPTCHA: "Wrong captcha",
  LOGIN_REQUIRED_CAPTCHA: "Recaptcha is required",
  LOGIN_NOT_FOUND: "Login not found",
  LOGIN_USER_NOT_FOUND: "User ID is not registered in Internet Bank iDBA. Please contact the Bank",

  TRANSFERS_USE_THIS_SYMBOLS: "Used prohibited letter or symbol",

  CONVERSION_DOCUMENTS_TEXT: "Documents are enclosed",
  CONVERSION_DOCUMENTS: "Documents",
  CONVERSION_OFFER_TEXT: "Clause on joining to Public Offer:",
  CONVERSION_OFFER:
    "Signing this Application means the unconditional acceptance by Client of all conditions of the Public offer for rendering conversion services published on Bank's website (www.kdb.uz) as of the date of signing this Application, as well as Client's consent to the direct debit of the required amounts for services rendered in accordance with Bank tariffs and  with this application",
  CONVERSION_TEXT_AUTHORIZE:
    "We hereby authorize Bank to debit in advance our abovementioned foreign currency account for selling foreign currency",

  LOGIN_ACCOUNT_DISABLED: "Account disabled",
  RESET_PASSWORD_CODE_SEND: "Confirmation code sent to following email:",

  STATEMENT_NO_DATA: "No documents",

  AMOUNT_COPY_PASTE: "Copy - Paste not allowed",

  FILE_CONTROLS_SAVE_TO_EXCEL1_BUTTON_TITLE: "Incoming transfer",
  FILE_CONTROLS_SAVE_TO_EXCEL2_BUTTON_TITLE: "Outgoing transfer",
  FILE_CONTROLS_SAVE_TO_EXCEL3_BUTTON_TITLE: "Incoming & outgoing transfer",
  FILE_CONTROLS_SAVE_TO_EXCEL4_BUTTON_TITLE: "Incoming & outgoing transfer with balance",
  FILE_CONTROLS_SAVE_TO_EXCEL5_BUTTON_TITLE: "Save to Excel 1",
  FILE_CONTROLS_SAVE_TO_EXCEL_NEW_BUTTON_TITLE: "Save to Excel (New)",

  ANALYTICS_ERROR_NO_INFO: "No information about account",
  ANALYTICS_PERIOD_HEADER: "Period",
  ANALYTICS_ERROR_CHOOSE: "Please choose account and period",

  ACCOUNT_CARD_BOX_TAB: "Card Box",
  ACCOUNT_SWIFT_TAB: "SWIFT",

  CARD_BOX_TITLE_DOCUMENT_NUMBER: "Document Number",
  CARD_BOX_DATA: "Document Date",
  CARD_BOX_DATA_DISPLACEMENT: "Date of transfer to K-2",
  CARD_BOX_AMOUNT: "Amount Remaining",
  CARD_BOX_AMOUNT_ANNOUNCED: "Amount Declared",
  CARD_BOX_MFO_RECEIVER: "Receiver bank MFO",
  CARD_BOX_ACCOUNT_NUMBER_RECEIVER: "Receiver account number",
  CARD_BOX_RECEIVER_NAME: "Receiver name",
  CARD_BOX_DETAILS: "Payment details",

  USER_INFO_AND_SETTINGS_ALERT_NOTIFICATION:
    "Are you sure you want to change the notification setting?",
  PENDING_APPROVAL_EXPIRED_ERROR: "Transaction time expired",
  PENDING_APPROVAL_NULL_ERROR: "Insufficient funds in the transit account to process the payroll",

  //
  //
  // New Translates
  //
  //
  SALARY_PHONE_TYPE: "Phone",
  SALARY_PHONE_NUMBER_TITLE: "Phone number for SMS",
  SALARY_PASSWORD_PHONE_TITLE: "Password for phone",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_LENGTH:
    "For selected country, maximum 2 characters per passport series",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_LENGTH:
    "For selected country, maximum 7 characters per passport number",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_ERROR:
    "The passport serial number was entered incorrectly",

  CONVERSION_MAX_SYMBOLS: "Maximum 80 symbols in this field",
  CONVERSION_MIN_SYMBOLS: "Minimum 9 symbols in this field",

  TRANSFER_AMOUNT_ERROR: "Wrong amount format",
  TRANSFER_PAYMENT_CODE_ERROR: "Wrong payment code format",

  PRE_APPROVAL_COUNTRY_FIELD_LABEL_TITLE: "Origin of goods (country)",

  FINANCIAL_VALIDATION_MAX: "Maximum digits in this field: ",
  FINANCIAL_PRE_APPROVAL_TITLE: "Pre-Approval",
  FINANCIAL_PRE_APPROVAL_CREATED: "Document crated",

  FINANCIAL_BUSINESS_SIZE_SMALL: "small",
  FINANCIAL_BUSINESS_SIZE_MEDIUM: "medium",
  FINANCIAL_BUSINESS_SIZE_LARGE: "large",

  FINANCIAL_FORM_TITLE_1: "Financial in",
  FINANCIAL_FORM_TITLE_2: "year month",
  FINANCIAL_FORM_TITLE_3: "; Unit: UZS million",

  FINANCIAL_ALL_PASSIVE: "All passives",
  FINANCIAL_LONG_TERM: "Long term assets",

  FINANCIAL_ANNUAL_SALARY_INTEREST_RATE: "Interest rate on sub-credit",
  FINANCIAL_PRE_ORDER_NUMBER: "Transfer pre order number",

  INDIVIDUAL_TRANSFERS_SIDE_MENU: "Transfers",

  INDIVIDUAL_CARD_TRANSFER: "To Card account",
  INDIVIDUAL_SCORE_TRANSFER: "To Demand Deposit account",
  INDIVIDUAL_REQUISITES_TRANSFER: "By Requisites",
  INDIVIDUAL_BUDGET_TRANSFER: "To Budget",
  INDIVIDUAL_REVENUE_TRANSFER: "To Budget Revenue",
  INDIVIDUAL_MUNIS_TRANSFER: "MUNIS",
  INDIVIDUAL_TRANSFER_CODE: "Transfer code",
  INDIVIDUAL_RECEIVER_ACCOUNT: "Receiver account",
  INDIVIDUAL_TRANSFER_AMOUNT: "Transfer amount",
  INDIVIDUAL_MUNIS_GROUP: "Group name",
  INDIVIDUAL_MUNIS_ORDERS: "Service provider name",
  INDIVIDUAL_MUNIS_PAYMENT_TYPE: "Payment type",
  INDIVIDUAL_MUNIS_ORDER_NUMBER: "Order number",
  INDIVIDUAL_MUNIS_FACTURA_NUMBER: "Factura number",

  FINANCIAL_DOCUMENT_UPDATED: "Updated",

  INDIVIDUAL_CONVERSION_OTHERS_AMOUNT: "Foreign Currency Amount",
  INDIVIDUAL_CONVERSION_AMOUNT: "National Currency Amount",

  INDIVIDUAL_INQUIRY_REGISTER_AM: "I am",
  INDIVIDUAL_ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    " kindly asks Bank to provide the following information on my account(s);",
  INDIVIDUAL_ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " kindly asks Bank to provide additional account statement(s) for the following account(s);",

  INDIVIDUAL_VISA_CARD_NUMBER_TITLE: "Visa Card",
  INDIVIDUAL_VISA_CARD_NUMBER_INPUT: "Card Number",

  INDIVIDUAL_CONVERSION_SUCCESS: "Successfully completed",
  INDIVIDUAL_ENTER_RECEIVER_ACCOUNT: "Please enter receiver account or receiver card number",
  NOT_AVAILABLE_TRANSACTION_TITLE: "This transaction is not available.",
  NOT_AVAILABLE_TRANSACTION: "Enter the recipient's HUMO card number.",
  INDIVIDUAL_CONVERSION_HISTORY_RECEIVER: "Receiver account number",
  INDIVIDUAL_CONVERSION_BUY_AMOUNT: "Foreign currency amount",
  INDIVIDUAL_CONVERSION_SELL_AMOUNT: "Amount in national currency",

  INDIVIDUAL_FAVORITE_ID_TITLE: "ID",
  INDIVIDUAL_SMS_CODE_TITLE: "SMS Code",
  INDIVIDUAL_SMS_CODE_AUTH_TITLE: "SMS Authorization",
  INDIVIDUAL_CHOOSE_ACCOUNT: "Choose account",

  INDIVIDUAL_CARD_BALANCE_TITLE: "Card Balance",
  HUMO_EXPIRY_DATE: "Expiry date",

  DEMAND_TO_DEMAND_MESSAGE:
    "The transfer submitted, please check the status within 3 minutes in the History section.",

  CLEARING_OPEN: "Dear Сustomer, the operational day is opened!",
  CLEARING_NOT_OPEN:
    "Dear Сustomer, as operational day (clearing system) has not been opened yet in our Bank, we kindly ask you to make transactions via ANOR system untill opening the operational day.",
  NOTIFICATION_MESSAGE: `
  Dear Clients,
  Due to increasing number of fraud cases (unsanctioned withdrawal of funds from bank cards), JSC “KDB Bank Uzbekistan” kindly requests you to be more vigilant and not to disclose anyone the SMS codes sent to your mobile phone number, nor to disclose information of your card (expiry date, CVV) and not to post the photo of bank card on social media.
  We also kindly ask you to share with this information with your family and colleagues.
  `,
  NOTIFICATION_MESSAGE_CACHE_LIMIT: `
  Changes in the procedure for servicing individuals – residents and non-residents
  
Due to the recent changes in the requirements of the Compliance, the Management Board of the Bank has adopted the following decisions effective from August 15, 2022.
-------------------------------------------------------------------------------------------------------------------------------------------
For individuals – residents:
The exchange of national currency into foreign currency at an exchange office in an amount exceeding the equivalent of 10 thousand US dollars in a calendar month is executed after providing to the Bank of documents confirming the receipt of funds from legal sources. The maximum exchange amount per month is 100 thousand US dollars or the equivalent of another foreign currency.
Depositing of cash to an account in foreign and national currencies in an amount exceeding the equivalent of 50 thousand US dollars in a calendar month is executed after providing to the Bank of documents confirming the receipt of funds from legal sources.
Crediting of non-cash funds to an account in national currency (with the exception of P2P transfers, salary and salary-equated payments, insurance payments, budgetary payments) in an amount exceeding 500 million UZS in a calendar month is executed after providing to the Bank of documents confirming receipt of funds from legal sources.
Withdrawal of cash from bank and card accounts in foreign currency is executed within the amount not exceeding the equivalent of 50 thousand US dollars in a calendar month.
Withdrawal of cash in foreign currency from international cards issued by other banks (Visa and MasterCard) is executed in an amount not exceeding the equivalent of 10 thousand US dollars in a calendar month.
-------------------------------------------------------------------------------------------------------------------------------------------
For individuals – non-residents:
The exchange of national currency into foreign currency at an exchange office in an amount exceeding the equivalent of 100 US dollars in a calendar month is executed after providing to the Bank of documents confirming the receipt of funds from legal sources. The maximum exchange amount per month is 50 thousand US dollars or the equivalent of another foreign currency.
Depositing of cash to an account in foreign and national currencies in an amount exceeding the equivalent of 70 million UZS in a calendar month is executed after providing to the Bank of documents confirming the receipt of funds from legal sources.
Crediting of non-cash of funds to an account in national currency (with the exception of P2P transfers, salary and salary-equated payments, insurance payments, budgetary payments) in an amount exceeding 100 million UZS in a calendar month is executed after providing to the Bank of documents confirming the receipt of funds from legal sources.
Withdrawal of cash from bank and card accounts in foreign currency is executed in an amount not exceeding the equivalent of 10 thousand US dollars in a calendar month.
Withdrawal of cash in foreign currency from international cards issued by other banks (Visa and MasterCard) is executed in an amount not exceeding the equivalent of 10 thousand US dollars in a calendar month.
Onboarding of new clients for servicing (opening a bank account, issuing a bank card) is executed after providing to the Bank of documents confirming the registration at the place of temporary residence in the Republic of Uzbekistan with a period of stay of at least 30 days, with the exception of the purposes of stay as employment, study, medical treatment.
-------------------------------------------------------------------------------------------------------------------------------------------
Note: The Bank has the right to refuse to provide services, as well as to return the received funds back to the sender, in case of failure to provide the supporting documents to the Bank.
  `,
  NOTIFICATION_MESSAGE_10022023: `
  Announcement: Closure of Yunusobod branch of JSC “KDB Bank Uzbekistan” 

  With the purpose to optimize the resources, on 31 January 2023 the Supervisory Board of JSC "KDB Bank Uzbekistan" (hereafter “the Bank”) adopted a decision to close the Yunusobod branch of the Bank [Bank code 00831] (hereafter “the Branch”).
  
  In accordance with the applicable legislation, the Bank shall bear the responsibility for all obligations of the Branch, including the obligations in front of clients.
  
  In connection with the closure of the Branch, we kindly request all clients of the Branch to open accounts at the Head office of the Bank [Bank code 00842].
  
  For additional information or in case of questions, we request the clients of the Branch to contact the Client Service Department at the following telephone numbers: (+998) 781208743; 781208744; 781208709; 781206141; 781208780.
  
  Sincerely,
  
  JSC "KDB Bank Uzbekistan"  
  `,

  NOTIFICATION_MESSAGE_21122023: `Dear Clients, 
  Please kindly update the powers of attorney issued to employees authorized to work with the bank documents in 2024 (if POAs submitted to the bank expired in 2023).
  `,
  //Corporate role  Payment Claim page Tab Sent Payment Claims

  PAYMENT_CLAIM_FOMRS_TITLE: "Forms",

  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SENT_SUBTAB_TITLE: "",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SHOW_CHECKBOX_LABEL_TITLE: "Show Creators and Approvers",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE: "Cancel Payment Claim",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TO_FIELD_LABEL_TITLE: "To",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SEARCH_BUTTON_TITLE: "Search",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_TITLE: "PLEASE!",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_ONLY_ONE_PAYMENT_CLAIM_TITLE: "Choose Only One",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_PAYMENT_CLAIM_TITLE: "Choose Payment Claim",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DEPLOY_FILE_SUCCESSFULLY: "Excel File Loaded",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_COLUMN_TITLE: "Type",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_TITLE: "Payment Claim",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_REFUND_TITLE: "Revoke",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_COLUMN_TITLE: "Payment Claim Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_DATE: "Created Date",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_NAME_OF_THE_PAYER_TITLE: "Name of the Payer",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE_TITLE: "Transaction Date",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE: "Date",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_ACCOUNT_TITLE: "Payer's Account",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_TAX_ID_TITLE: "Payer's Tax ID",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_BANK_NAME_TITLE: "Name of the Payer’s Bank",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_MFO_TITLE: "MFO",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PURPOSE_CODE_TITLE: "Purpose Code",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_PURPOSE_TITLE: "Payment Purpose",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_CLAIM_COLUMN_TITLE: "Payment Claim Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_STATUS_COLUMN_TITLE: "Status",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Beneficiary Name",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_NAME_COLUMN_TITLE: "Sender Bank Name",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_CODE_COLUMN_TITLE: "Sender Bank Code",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DATE_FIELD_LABEL_TITLE: "From",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Created by",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NUMBER_COLUMN_TITLE:
    "Beneficiary Account Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE:
    "Beneficiary Bank Code",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Sender Account Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Authorized by",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_FILTER_FIELD_LABEL_TITLE: "Filter by Status",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_NAME_COLUMN_TITLE: "Sender Name",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_COLUMN_TITLE: "Request for status",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_COLUMN_TITLE: "Created Date",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREDITOR_ID_TITLE: "Creditor ID",

  PAYMENT_CLAIM_NOTIFICATIONS_TAB: "Notifiacations",

  //Corporate role Payment Claim page For Form

  PAYMENT_CLAIM_FORM_PAYER_TITLE: "Payer",
  PAYMENT_CLAIM_FORM_DOC_NUMBER: "Document Number",
  PAYMENT_CLAIM_FORM_DOC_DATE: "Date",
  PAYMENT_CLAIM_FORM_RECEIVER_ID: "Receiver ID",
  PAYMENT_CLAIM_FORM_PAYER_ACCOUNT: "Payer Account",
  PAYMENT_CLAIM_FORM_PAYER_NAME: "Payer Name",
  PAYMENT_CLAIM_FORM_PAYER_BANK_CODE: "Payer Bank Code",
  PAYMENT_CLAIM_FORM_PAYER_TAX_ID: "Payer Tax ID",
  PAYMENT_CLAIM_FORM_PAYER_BANK_NAME: "Payer Bank Name",
  PAYMENT_CLAIM_FORM_PURPOSE_CODE: "Purpose Code",
  PAYMENT_CLAIM_FORM_PAYMENT_PURPOSE: "Payment Purpose",
  PAYMENT_CLAIM_FORM_PAYMENT_TYPE: "Payment Type",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_NAME: "Payee Bank Name",
  PAYMENT_CLAIM_FORM_PAYEE_ACCOUNT: "Payee Account",
  PAYMENT_CLAIM_FORM_PAYEE_NAME: "Payee Name",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_CODE: "Payee Bank Code",
  PAYMENT_CLAIM_FORM_PAYEE_TAX_ID: "Payee Tax ID",
  PAYMENT_CLAIM_FORM_FIRST_CARD_ID: "K-1 ID",
  PAYMENT_CLAIM_FORM_FIRST_CARD_DATE: "K-1 Date",
  PAYMENT_CLAIM_FORM_FIRST_CARD_AMOUNT: "K-1 Amount",
  PAYMENT_CLAIM_FORM_FIRST_CARD_REMAINING_AMOUNT: "K-1 Balance",
  PAYMENT_CLAIM_FORM_SECOND_CARD_ID: "K-2 ID",
  PAYMENT_CLAIM_FORM_SECOND_CARD_DATE: "K-2 Date",
  PAYMENT_CLAIM_FORM_SECOND_CARD_AMOUNT: "K-2 Amount",
  PAYMENT_CLAIM_FORM_SECOND_CARD_REMAINING_AMOUNT: "K-2 Balance",
  PAYMENT_CLAIM_FORM_SECOND_CARD_TITLE: "Information About K-2",
  PAYMENT_CLAIM_FORM_FIRST_CARD_TITLE: "Information About K-1",
  PAYMENT_CLAIM_FORM_PAY_AMOUNT: "Payment Amount",
  PAYMENT_CLAIM_FORM_PAY_DATE: "Payment Date",
  PAYMENT_CLAIM_FORM_PAY_ID: "Payment ID",
  PAYMENT_CLAIM_FORM_IGNORING_MASSAGE: "Rejection Purpose",
  PAYMENT_CLAIM_FORM_IGNORING_AMOUNT: "Rejection Amount",
  PAYMENT_CLAIM_FORM_IGNORING_TITLE: "Refund",
  PAYMENT_CLAIM_FORM_PAY_TITLE: "Information About Payment",
  PAYMENT_CLAIM_FORM_MASSAGE: "Result of Receiving Document",
  PAYMENT_CLAIM_FORM_AMOUNT: "Amount",
  PAYMENT_CLAIM_FORM_PAYMENT_CONFIRM: "Confirm",
  PAYMENT_CLAIM_FORM_PAYMENT_IS_TRANSFER: "Status",
  PAYMENT_CLAIM_FORM_PAYMENT_BANK_MAIL_ID: "Bank Mail Index",
  PAYMENT_CLAIM_FORM_SENDER_ID: "Sender ID",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_SENT: "Sent",
  PAYMENT_CLAIM_FORM_PAYMENT_ENTER_REASON_API_TITLE: "Enter Reason",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_WAS_NOT_SENT: "Was Not Sent",
  PAYMENT_CLAIM_FORM_PAYMENT_REFUNDED_TITLE: "Refunded",
  PAYMENT_CLAIM_FORM_AMOUNT_TITLE: "Amount",
  PAYMENT_CLAIM_FORM_AMOUNT_IN_WORD_TITLE: "Amount In Words",
  PAYMENT_CLAIM_FORM_REASON: "Reason",
  PAYMENT_CLAIM_FORM_PAYMENT_CODE: "Payment Code",
  PAYMENT_CLAIM_FORM_PAYEE_TITLE: "Payee",
  PAYMENT_CLAIM_FORM_CURRENT_BALANCE: "Current Balance",

  // Transfers Import Excel files

  TRANSFERS_EXCEL_FILE_TABLE_DATE_COLUMN_TITLE: "Date",
  TRANSFERS_EXCEL_FILE_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Document Number",
  TRANSFERS_EXCEL_FILE_TABLE_SENDER_ACCOUNT_COLUMN_TITLE: "Sender Account",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Beneficiary Name",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Beneficiary Account",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_TIN_COLUMN_TITLE: "Beneficiary's TIN",
  TRANSFERS_EXCEL_FILE_TABLE_DETAILS_OF_PAYMENT_COLUMN_TITLE: "Details",
  TRANSFERS_EXCEL_FILE_TABLE_AMOUNT_COLUMN_TITLE: "Amount",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE: "Beneficiary Bank Code",
  TRANSFERS_EXCEL_FILE_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_EXCEL_FILE_TABLE_IMPORTED_COLUMN_TITLE: "Imported",
  TRANSFERS_EXCEL_FILE_TABLE_SUCCESS_COLUMN_TITLE: "Success",

};
