import { BaseApi } from "../BaseApi";
import {
  PaymentCodeProps,
  ReferenceBookBudgetAccountProps,
  ReferenceBookBudgetAccountQueryProps,
  ReferenceBookBudgetIncomeProps,
  ReferenceBookBudgetIncomeQueryProps,
  ReferenceBookMfoProps,
  ReferenceBookMfoQueryProps,
  ReferenceBookProps,
  ReferenceBookStaticDataProps,
} from "./ReferenceBookDTO";

export class ReferenceBookApi extends BaseApi {
  public getAllowedAccounts(): Promise<ReferenceBookProps[]> {
    return this.get("reference-book/account/:employeeId", {
      params: { employeeId: this.employeeId },
    });
  }

  public getMfoInformation(
    mfoNumber,
    query: ReferenceBookMfoQueryProps,
  ): Promise<ReferenceBookMfoProps[]> {
    return this.get("reference-book/mfo/:mfoNumber", { query, params: { mfoNumber } });
  }

  public getBudgetAccountsInformation(
    query: ReferenceBookBudgetAccountQueryProps,
  ): Promise<ReferenceBookBudgetAccountProps[]> {
    return this.get("reference-book/budget/account", { query });
  }

  public getBudgetIncomeAccountsInformation(
    query: ReferenceBookBudgetIncomeQueryProps,
  ): Promise<ReferenceBookBudgetIncomeProps[]> {
    return this.get("reference-book/budget/income", { query });
  }

  public getStaticDataForBeneficiary(): Promise<ReferenceBookStaticDataProps[]> {
    return this.get("reference-book/static/data");
  }

  public checkPaymentCode({ budgetFlag, paymentCode }): Promise<PaymentCodeProps[]> {
    return this.get("reference-book/payment/:budgetFlag", {
      params: { budgetFlag },
      query: { paymentCode },
    });
  }

  public checkPurposeCode({ budgetFlag, purpose }): Promise<PaymentCodeProps[]> {
    return this.get("reference-book/purpose/:budgetFlag", {
      params: { budgetFlag },
      query: { purpose },
    });
  }

  public checkPaymentType({ budgetFlag, paymentCode }): Promise<PaymentCodeProps[]> {
    return this.get("reference-book/payment-claim/:budgetFlag", {
      params: { budgetFlag },
      query: { paymentCode },
    });
  }
}
