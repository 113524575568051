import React from "react";
import { SwiftCoverTable } from "./SwiftCoverTable";
import { SwiftCoverTableDataProps } from "../../api/account/AccountDTO";

interface Props {
  readonly data: SwiftCoverTableDataProps[];
  readonly loading: boolean;
}

export function SwiftCoverTableWrapper({ data, loading }: Props) {
  return <SwiftCoverTable data={data} loading={loading} />;
}
