import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { AllLCYDocProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { Dict } from "../../api/dto/AppDTO";
import { TransferStatusPicker } from "./TransferStatusPicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly showCreators: boolean;
  readonly data: AllLCYDocProps[];
  readonly onSelect: (ids: Dict<boolean>) => void;
  readonly loading: boolean;
  readonly onGetDocument: (value, date) => void;
}

export function TransfersTable({ data, showCreators, onSelect, loading, onGetDocument }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<AllLCYDocProps>[] = [
      {
        width: 95,
        accessor: "operationDate",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATE_DATE_AND_TIME_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "documentDate",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_TRANSACTION_DATE_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "documentNumber",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a
            className="cursor-pointer"
            onClick={() => onGetDocument(row.row.original.id, row.data)}
          >
            {row.value}
          </a>
        ),
      },
      {
        width: 140,
        accessor: "amount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span>{row.value ? floatFormat(addZeros(row.value)) : ""}</span>,
      },
      {
        width: 180,
        accessor: "clientAccount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "correspondentAccount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "correspondentName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "receiverMfo",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_CORR_BANK_CODE_COLUMN_TITLE"),
      },
      {
        width: 300,
        accessor: "paymentDetail",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "state",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 100,
        accessor: "budgetFlag",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_DOCUMENT_TYPE_COLUMN_TITLE"),
        Cell: (row) => (
          <div>
            {row.value === "0"
              ? translate("TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE")
              : row.value === "1"
              ? translate("TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE")
              : row.value === "2"
              ? translate("TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE")
              : translate("TRANSFERS_TRANSFERS_LCY_MINUS_TRANSFER_BUTTON_TITLE")}
          </div>
        ),
      },
      {
        width: 249,
        accessor: "reasonRejectFromBank",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_EXPLANATION_COLUMN_TITLE"),
      },
      {
        width: 249,
        accessor: "reasonRejectFromCbu",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_EXPLANATION_CB_COLUMN_TITLE"),
      },
    ];

    if (showCreators) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [onGetDocument, showCreators, translate]);

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={onSelect}
      withCheckbox={true}
      loading={loading}
      showEmptyRow={true}
    />
  );
}
