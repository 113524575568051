import React, { useCallback, useEffect, useMemo, useState, memo } from "react";

import { TabPage } from "../tabs/TabPage";
import { CreateNewDocumentRoles, Dict, SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";
import { TransferType } from "../../api/transfer/TransferDTO";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { PayrollTableWrapper } from "../payroll/PayrollTableWrapper";
import { SalaryListOfCardHoldersForm } from "./SalaryListOfCardHoldersForm";
import { SalaryEmployeeRegistrationForm } from "./SalaryEmployeeRegistrationForm";
import { SalaryCreateNewRegisterTableForm } from "./SalaryCreateNewRegisterTableForm";
import { SalaryListOfCardHoldersTableWrapper } from "./SalaryListOfCardHoldersTableWrapper";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { formatDateToBody, formatDateToFilter } from "../../utils/DateUtils";
import { NotificationManager } from "react-notifications";
import { useResource } from "../../hooks/useResource";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";
import { update } from "immupdate";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useI18n } from "../../i18n/I18nContext";
import { Paginator } from "../ui/Paginator";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { accessToCardReplenishment } from "../../reducers/authReducer";
import { useHistory } from "react-router";
import { Routes } from "../../constants/Routes";
import { AccountTabs } from "../../containers/corporate/CorporateAccountsContainer";
import { toFinite } from "lodash";
import { RegisterTableType, SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { Modal } from "../ui/Modal";
import { PayrollViewTable } from "../payroll/PayrollViewTable";
import { PrintPortal } from "../ui/PrintPortal";
import { SalaryPrintTable } from "../transfers/SalaryPrintTable";
import { printForm } from "../../utils/PintUtils";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import { createRegisterExcelFromTable } from "../../helpers/SalaryHelpers";
import { FileControls } from "../ui/FileControls";
import { createCardHoldersExcelFromTable } from "../../helpers/TransfersHelpers";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { switchPageCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";
import { getCorrectAmount } from "../../utils/GetCorrectAmount";

let data = Array(2000).fill({
  id: "",
  accountNumber: "",
  systemNumber: "",
  fullName: "",
  amount: "",
  systemName: "",
  status: "",
});

interface Props {
  readonly filter: TransfersFilter;
  readonly onUploadFile: () => void;
  readonly onCreateClick: () => void;
  readonly onRegistrationClick: () => void;
  readonly onGetListOfCardHoldersClick: () => void;
}

export const SalaryTab = memo(function ({
  filter,
  onCreateClick,
  onRegistrationClick,
  onGetListOfCardHoldersClick,
}: Props) {
  const { translate } = useI18n();
  const { CorporateCardsApi } = useCorporateCardsContext();
  const [citizenship, setCitizenship] = useState({});
  const [viewTable, setViewTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [registerForPrint, setRegisterForPrint] = useState([{}]);
  const [forPrintInfo, setForPrinInfo] = useState({});
  const [count, setCount] = useState({ label: "10", value: "10" });
  const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  const accessToCard = useShallowEqualSelector(accessToCardReplenishment);
  const history = useHistory();
  const locationHelpers = useLocationHelpers();

  const { SalaryApi } = useSalaryContext();

  useEffect(() => {
    if (accessToCard !== CreateNewDocumentRoles.Yes) {
      history.replace(`${Routes.CorporateAccountsRoute}/${AccountTabs.Accounts}`);
    }
  }, [accessToCard, history]);

  const onCreateRegister = useCallback(() => {
    data = Array(2000).fill({
      id: "",
      accountNumber: "",
      systemNumber: "",
      fullName: "",
      amount: "",
      systemName: "",
      status: "",
    });
    onCreateClick();
  }, [onCreateClick]);

  useResource(
    () =>
      CorporateCardsApi.citizenshipList().then((r) => {
        setCitizenship([
          r.map((res) => ({
            label: res.countryName,
            value:
              res.countryCode === null && res.countryName == "Лицо без гражданства"
                ? " "
                : res.countryCode,
          })),
        ]);
      }),
    [],
  );

  const cardType = filter.getCardType().cardType;
  const chooseCardHolders = useCallback(() => {
    NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
    data = Array(2000).fill({
      id: "",
      accountNumber: "",
      systemNumber: "",
      fullName: "",
      amount: "",
      systemName: "",
      status: "",
    });

    const idx = [];
    loadIds(selectedIds, idx);
    function loadIds(obj, container) {
      for (const i in obj) {
        container.push(i);
        if (obj[i].constructor == Object) {
          loadIds(obj[i], container);
        }
      }
    }

    SalaryApi.getSalaryCardHolders({
      cardType: cardType,
      // pageNumber: 1,
      // pageSize: 100,
      pageNumber: filter.getPage(),
      pageSize: filter.getPerPage(),
      param: "",
    })
      .then((r) => {
        if (r) {
          const result: SalaryCardHolders[] = [];
          for (let i = 0; i < idx.length; i++) {
            //@ts-ignore
            r.filter((x) => x.cardId == Number(idx[i]) && result.push(x));
          }

          const list = result.map((x) => ({
            accountNumber: x.accountNumber,
            id: x.cardId,
            systemNumber: "",
            fullName: x.employerName,
            status: "",
            systemName: "",
          }));
          data = [...list, ...data];
          locationHelpers.replaceQuery({ create: true });
          if (list.length < 101) {
            setCount({ label: "100", value: "100" });
          } else if (list.length < 501) {
            setCount({ label: "500", value: "500" });
          } else if (list.length < 1001) {
            setCount({ label: "1000", value: "1000" });
          } else if (list.length < 1501) {
            setCount({ label: "1500", value: "1500" });
          } else {
            setCount({ label: "2000", value: "2000" });
          }
          setOpenModal(false);
        }
      })
      .catch(() => {
        NotificationManager.error(
          translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
          translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
        );
      });
  }, [translate, SalaryApi, locationHelpers, cardType, selectedIds, filter]);

  const [clearing, setClearing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    address: "",
    birthDay: "",
    birthdayPlace: "",
    city: "",
    country: { label: "Узбекистан", value: "UZ " },
    district: "",
    firstName: "",
    gender: "",
    passportExpiryDate: "",
    phoneNumber: "",
    password: "",
    passportIssuedBy: "",
    passportNumber: "",
    passportRegistrationDate: "",
    passportSeries: "",
    patronymic: "",
    residentFlag: "Y",
    surname: "",
    humo: false,
    uzCard: false,
    mobilePhoneNumber: "",
    servicePhone: "",
    homePhone: "",
    phoneInput: "01",
    pinfl: "",
  });

  const clearInitialValues = useCallback(() => {
    setInitialValues((prev) =>
      update(prev, {
        address: "",
        birthDay: "",
        birthdayPlace: "",
        city: "",
        country: { label: "Узбекистан", value: "UZ " },
        district: "",
        firstName: "",
        gender: "",
        passportExpiryDate: "",
        passportIssuedBy: "",
        passportNumber: "",
        passportRegistrationDate: "",
        passportSeries: "",
        patronymic: "",
        residentFlag: "Y",
        surname: "",
        uzCard: false,
        humo: false,
        mobilePhoneNumber: "",
        homePhone: "",
        servicePhone: "",
        phoneInput: "01",
        pinfl: "",
      }),
    );
    setClearing(false);
  }, []);

  const startClearing = useCallback(() => {
    setClearing(true);
    setTimeout(clearInitialValues, 500);
  }, [clearInitialValues]);

  const isCreate = useMemo(() => filter.getCreate(), [filter]);
  const isUploadFile = useMemo(() => filter.getUploadFile(), [filter]);
  const isRegisterSalary = useMemo(() => filter.getRegisterSalary(), [filter]);
  const isOpenSalary = useMemo(() => filter.getOpenSalary(), [filter]);
  const isListOfCardHolders = useMemo(() => filter.getListOfCardHolders(), [filter]);
  const [payrollTable, setPayrollTable] = useState(false);
  const dispatch = useDispatch();
  const [transitAccount, setTransitAccount] = useState({
    transitAccount: "",
    transitAccountBalance: "",
    code: "00633",
    detailsType: "",
    month: "",
    number: "",
    accountType: {
      label: translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE"),
      value: "salaryTransit",
    },
  });
  const [debitAccount, setDebitAccount] = useState({
    debitAccount: "",
    debitAccountBalance: "",
    accountName: "",
    code: "00633",
    accountType: {
      label: translate("TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE"),
      value: "salaryDebit",
    },
  });


  // const cardTypeSalary =  transitAccount?.transitAccount?.value?.substr(17, 1) === "0" ? filter.getCardTypeUzcard().cardTypeUzcard : filter.getCardTypeHumo().cardTypeHumo;
  
  const isMain = useMemo(
    () =>
      Boolean(
        !isCreate && !isRegisterSalary && !isListOfCardHolders && !isUploadFile && !isOpenSalary,
      ),
    [isCreate, isRegisterSalary, isListOfCardHolders, isUploadFile, isOpenSalary],
  );

  const { PdfReportApi } = usePdfReportContext();

  const checkPassportNumberAndSeries = useCallback(
    (value: { passportNumber: string; passportSeries: string }): boolean => {
      const isDuring = value.passportNumber && value.passportSeries;
      let done = false;
      if (isDuring && value.passportSeries.length === 2 && value.passportNumber.length === 7) {
        done = true;
        return done;
      } else if (
        isDuring &&
        value.passportSeries.length === 2 &&
        value.passportNumber.length === 6
      ) {
        done = true;
        return done;
      } else if (
        isDuring &&
        value.passportSeries.length === 1 &&
        value.passportNumber.length === 8
      ) {
        done = true;
        return done;
      } else if (
        isDuring &&
        value.passportSeries.length === 1 &&
        value.passportNumber.length === 9
      ) {
        done = true;
        return done;
      } else {
        NotificationManager.error(
          translate("TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_ERROR"),
          translate(
            "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_FIELD_LABEL_TITLE",
          ),
          7000,
        );
        done = false;
        return done;
      }
    },
    [translate],
  );

  const onCreateNewSalary = useCallback(
    (value) => {
      if (value.passportRegistrationDate === "") {
        NotificationManager.error(
          translate("SALARY_CHOOSE_PASSPORT_REGISTRATION_DATE"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if (value.residentFlag === "N" && value.pinfl === "") {
        NotificationManager.error(
          translate("SALARY_PINFL_IS_REQUIRED"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if (value.passportExpiryDate === "") {
        NotificationManager.error(
          translate("SALARY_CHOOSE_PASSPORT_EXPIRY_DATE"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if ((value.firstName + value.surname).length > 24) {
        NotificationManager.error(
          translate("SALARY_FULLNAME_LIMIT_ERROR_TITLE"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if (value.birthDay === "") {
        NotificationManager.error(
          translate("SALARY_CHOOSE_BIRTHDAY_DATE"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if (value.gender === "") {
        NotificationManager.error(
          translate("SALARY_CHOOSE_GENDER"),
          translate("PENDING_APPROVALS_SALARY_TAB_TITLE"),
          7000,
        );
      } else if (!value.uzCard && !value.humo) {
        NotificationManager.error(
          translate("SALARY_CHOOSE_CARD_TYPE"),
          translate("SALARY_CHOOSE_CARD_TYPE_TITLE"),
          7000,
        );
      } else if (
        value.mobilePhoneNumber === "" &&
        value.servicePhone === "" &&
        value.homePhone === ""
      ) {
        NotificationManager.error(
          translate("SALARY_CHOOSE_PHONE_TYPE"),
          translate("SALARY_CHOOSE_PHONE_TYPE_TITLE"),
          7000,
        );
      } else {
        if (checkPassportNumberAndSeries(value)) {
          let passportSerias = "";
          let passportNumber = "";
          if (value.passportSeries.length === 2 && value.passportNumber.length === 6) {
            passportSerias = value.passportSeries;
            passportNumber = value.passportNumber + " ";
          } else if (value.passportSeries.length === 1 && value.passportNumber.length === 8) {
            passportSerias = value.passportSeries + value.passportNumber.substring(0, 1);
            passportNumber = value.passportNumber.substring(1);
          } else if (value.passportSeries.length === 1 && value.passportNumber.length === 9) {
            passportSerias = value.passportSeries + value.passportNumber.substring(0, 1);
            passportNumber = value.passportNumber.substring(1, 8);
          } else {
            passportSerias = value.passportSeries;
            passportNumber = value.passportNumber;
          }
          const data = {
            ...value,
            birthDay: formatDateToFilter(value.birthDay),
            passportExpiryDate: formatDateToFilter(value.passportExpiryDate),
            passportRegistrationDate: formatDateToFilter(value.passportRegistrationDate),
            address: value.address.toUpperCase(),
            birthdayPlace: value.birthdayPlace.toUpperCase(),
            city: value.city.toUpperCase(),
            district: value.district.toUpperCase(),
            firstName: value.firstName.toUpperCase(),
            passportIssuedBy: value.passportIssuedBy.toUpperCase(),
            // passportSeries: isTurkey ? turkeyPassportSeries : value.passportSeries.toUpperCase(),
            // passportNumber: isTurkey ? turkeyPassportNumber : value.passportNumber,
            passportSeries: passportSerias,
            passportNumber: passportNumber,
            patronymic: value.patronymic.toUpperCase(),
            surname: value.surname.toUpperCase(),
            country: value.country.value,
          };
          let pdfDone = false;
          let uzcardFlag = false;
          let humoFlag = false;

         

          if(value.uzCard && value.humo){
            value.uzCard &&
              SalaryApi.createSalaryUzCardCard({ ...data, cardType: "uzCard" })
                .then(() => {
                  NotificationManager.info(
                    translate("SALARY_CARD_CREATED_INFO"),
                    translate("SALARY_CARD_CREATED_INFO_TITLE"),
                    7000,
                  );
                  locationHelpers.replaceQuery({ registerSalary: "" });
                  uzcardFlag = true;
                
                  if(uzcardFlag && humoFlag){
                   
                    PdfReportApi.createSalaryReport(
                      `Salary_card_${data.firstName
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.surname
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.patronymic
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}`,
                      {
                        ...data,
                        card: "humo",
                        fullName: `${data.firstName} ${data.surname} ${data.patronymic}`,
                        country: value.country.label,
                        birthDay: formatDateToBody(value.birthDay),
                        passportExpiryDate: formatDateToBody(value.passportExpiryDate),
                        passportRegistrationDate: formatDateToBody(value.passportRegistrationDate),
                        serialNumber: `${data.passportSeries}${data.passportNumber}`,
                        residence: `${data.city} ${data.district} ${data.address}`,
                      },
                    );
    
                  }
                })
                // eslint-disable-next-line no-console
                .catch(showError);

            value.humo &&
              SalaryApi.createSalaryHumoCard({ ...data, cardType: "humo" })
                .then(() => {
                  NotificationManager.info(
                    translate("SALARY_CARD_CREATED_INFO"),
                    translate("SALARY_CARD_CREATED_INFO_TITLE"),
                    7000,
                  );
                  locationHelpers.replaceQuery({ registerSalary: "" });
                  humoFlag = true;

                  if(uzcardFlag && humoFlag){
                    PdfReportApi.createSalaryReport(
                      `Salary_card_${data.firstName
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.surname
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.patronymic
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}`,
                      {
                        ...data,
                        card: "humo",
                        fullName: `${data.firstName} ${data.surname} ${data.patronymic}`,
                        country: value.country.label,
                        birthDay: formatDateToBody(value.birthDay),
                        passportExpiryDate: formatDateToBody(value.passportExpiryDate),
                        passportRegistrationDate: formatDateToBody(value.passportRegistrationDate),
                        serialNumber: `${data.passportSeries}${data.passportNumber}`,
                        residence: `${data.city} ${data.district} ${data.address}`,
                      },
                    );
    
                  }
                  
                })
                // eslint-disable-next-line no-console
                .catch(showError);

          }else if (value.humo){
            value.humo &&
              SalaryApi.createSalaryHumoCard({ ...data, cardType: "humo" })
                .then(() => {
                  NotificationManager.info(
                    translate("SALARY_CARD_CREATED_INFO"),
                    translate("SALARY_CARD_CREATED_INFO_TITLE"),
                    7000,
                  );
                  locationHelpers.replaceQuery({ registerSalary: "" });
                })
                .then(() => {
                  if (!pdfDone) {
                    pdfDone = true;
                    PdfReportApi.createSalaryReport(
                      `Salary_card_${data.firstName
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.surname
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.patronymic
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}`,
                      {
                        ...data,
                        card: "humo",
                        fullName: `${data.firstName} ${data.surname} ${data.patronymic}`,
                        country: value.country.label,
                        birthDay: formatDateToBody(value.birthDay),
                        passportExpiryDate: formatDateToBody(value.passportExpiryDate),
                        passportRegistrationDate: formatDateToBody(value.passportRegistrationDate),
                        serialNumber: `${data.passportSeries}${data.passportNumber}`,
                        residence: `${data.city} ${data.district} ${data.address}`,
                      },
                    );
                  }
                })
                // eslint-disable-next-line no-console
                .catch(showError);

          }else {
            value.uzCard &&
              SalaryApi.createSalaryUzCardCard({ ...data, cardType: "uzCard" })
                .then(() => {
                  NotificationManager.info(
                    translate("SALARY_CARD_CREATED_INFO"),
                    translate("SALARY_CARD_CREATED_INFO_TITLE"),
                    7000,
                  );
                  locationHelpers.replaceQuery({ registerSalary: "" });
                })
                .then(() => {
                  if (!pdfDone) {
                    pdfDone = true;
                    PdfReportApi.createSalaryReport(
                      `Salary_card_${data.firstName
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.surname
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}_${data.patronymic
                        .replace(/[А-Яа-я`"$%№=]/g, "")
                        .replace(" ", "_")}`,
                      {
                        ...data,
                        card: "humo",
                        fullName: `${data.firstName} ${data.surname} ${data.patronymic}`,
                        country: value.country.label,
                        birthDay: formatDateToBody(value.birthDay),
                        passportExpiryDate: formatDateToBody(value.passportExpiryDate),
                        passportRegistrationDate: formatDateToBody(value.passportRegistrationDate),
                        serialNumber: `${data.passportSeries}${data.passportNumber}`,
                        residence: `${data.city} ${data.district} ${data.address}`,
                      },
                    );
                  }
                })
                // eslint-disable-next-line no-console
                .catch(showError);

          }

        }
      }
    },
    [PdfReportApi, SalaryApi, locationHelpers, translate, checkPassportNumberAndSeries],
  );

  const onChangeCardInfo = useCallback(
    (value) =>
      SalaryApi.getParticularEmployeeInfo({
        cardType: cardType,
        cardAccount: value,
      }).then((r) => {
        const data = {
          ...r,
          gender: r.gender === "MAN" ? "01" : "02",
          card: cardType === "UZCARD" ? "uzCard" : "humo",
          residentFlag: r.residentFlag === "RESIDENT" ? "Y" : "N",
        };
        const selected = r && citizenship[0].filter((cit) => cit.value === r.country);
        setInitialValues((prev) =>
          update(prev, {
            address: data.address,
            birthDay: data.birthDay,
            birthdayPlace: data.birthdayPlace,
            city: data.city,
            country: selected[0],
            district: data.district,
            firstName: data.firstName,
            gender: data.gender,
            passportExpiryDate: data.passportExpiryDate,
            passportIssuedBy: data.passportIssuedBy,
            passportNumber: data.passportNumber,
            passportSeries: data.passportSeries,
            passportRegistrationDate: data.passportRegistrationDate,
            patronymic: data.patronymic,
            surname: data.surname,
            residentFlag: data.residentFlag,
            // @ts-ignore
            // huma: data.card === "humo",
            humo: data.card === "humo",
            uzCard: data.card === "uzCard",
          }),
        );
        locationHelpers.replaceQuery({ openSalary: true, listOfCardHolders: "" });
      }),
    [SalaryApi, cardType, citizenship, locationHelpers],
  );

  const readFromExcel = useCallback(
    (value) => {
      data = Array(2000).fill({
        id: "",
        accountNumber: "",
        systemNumber: "",
        fullName: "",
        amount: "",
        systemName: "",
        status: "",
      });
      value.shift();
      const list = value.map((x) => ({
        id: x?.A?.toString(),
        accountNumber: x?.B?.toString().substr(0, 20).trim(),
        systemNumber: "",
        fullName: x?.C?.toString().trim(),
        amount: x?.D ? getCorrectAmount(x.D) : "",
        systemName: "",
        status: "",
      }));
      data = [...list, ...data];
      locationHelpers.replaceQuery({ create: true });
      if (list.length < 101) {
        setCount({ label: "100", value: "100" });
      } else if (list.length < 501) {
        setCount({ label: "500", value: "500" });
      } else if (list.length < 1001) {
        setCount({ label: "1000", value: "1000" });
      } else if (list.length < 1501) {
        setCount({ label: "1500", value: "1500" });
      } else {
        setCount({ label: "2000", value: "2000" });
      }
    },
    [locationHelpers],
  );

  const chooseExistingRegister = useCallback(
    (value, type) => {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
      data = Array(2000).fill({
        id: "",
        accountNumber: "",
        systemNumber: "",
        fullName: "",
        amount: "",
        systemName: "",
        status: "",
      });
      SalaryApi.getExistingRegister({
        transferType: TransferType.Salary,
        pageNumber: 1,
        pageSize: 100,
        payrollId: value,
      }).then((res) => {
        let registerList = [{}];
        registerList.shift();
        // @ts-ignore
        for (let i = 0; i < res[0]?.totalPageCount; i++) {
          SalaryApi.getExistingRegister({
            transferType: TransferType.Salary,
            pageNumber: i + 1,
            pageSize: 100,
            payrollId: value,
          })
            .then((r) => {
              const list = r.map((x) => ({
                id: (r.indexOf(x) + 1).toString(),
                fullName: x.cardName,
                accountNumber: x.cardAccount,
                amount: x.salaryAmount,
                systemNumber: "",
                systemName: "",
                status: "",
                salaryStatus: x.salaryStatus,
              }));
              registerList = [...registerList, ...list];
              if (
                registerList.length ===
                toFinite(
                  // @ts-ignore
                  res[0].totalRowCount,
                )
              ) {
                if (registerList.length < 101) {
                  setCount({ label: "100", value: "100" });
                } else if (registerList.length < 501) {
                  setCount({ label: "500", value: "500" });
                } else if (registerList.length < 1001) {
                  setCount({ label: "1000", value: "1000" });
                } else if (registerList.length < 1501) {
                  setCount({ label: "1500", value: "1500" });
                } else {
                  setCount({ label: "2000", value: "2000" });
                }
                data = [...registerList, ...data];
                if (type === RegisterTableType.Existing) {
                  locationHelpers.replaceQuery({ create: true });
                  setPayrollTable(false);
                } else {
                  setViewTable(true);
                }
              }
            })
            .catch(() => {
              SalaryApi.getExistingRegister({
                transferType: TransferType.Salary,
                pageNumber: i + 1,
                pageSize: 100,
                payrollId: value,
              })
                .then((r) => {
                  const list = r.map((x) => ({
                    id: (r.indexOf(x) + 1).toString(),
                    fullName: x.cardName,
                    accountNumber: x.cardAccount,
                    amount: x.salaryAmount,
                    systemNumber: "",
                    systemName: "",
                    status: "",
                    salaryStatus: x.salaryStatus,
                  }));
                  registerList = [...registerList, ...list];
                  if (
                    registerList.length ===
                    toFinite(
                      // @ts-ignore
                      res[0].totalRowCount,
                    )
                  ) {
                    if (registerList.length < 101) {
                      setCount({ label: "100", value: "100" });
                    } else if (registerList.length < 501) {
                      setCount({ label: "500", value: "500" });
                    } else if (registerList.length < 1001) {
                      setCount({ label: "1000", value: "1000" });
                    } else if (registerList.length < 1501) {
                      setCount({ label: "1500", value: "1500" });
                    } else {
                      setCount({ label: "2000", value: "2000" });
                    }
                    data = [...registerList, ...data];
                    if (type === RegisterTableType.Existing) {
                      locationHelpers.replaceQuery({ create: true });
                      setPayrollTable(false);
                    } else {
                      setViewTable(true);
                    }
                  }
                })
                .catch(() =>
                  NotificationManager.error(
                    translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                    translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                  ),
                );
            });
        }
      });
    },
    [translate, SalaryApi, locationHelpers],
  );

  const printRegister = useCallback((value) => {
    setRegisterForPrint(value);
    setTimeout(
      () =>
        printForm({
          printable: "SalaryPrintTable",
          type: "html",
          targetStyles: ["*"],
        }),
      500,
    );
  }, []);

  const saveToExcel = useCallback((value) => {
    const workbook = createRegisterExcelFromTable(value, TransferType.Salary);

    saveExcelToFile(workbook, "Salary Register table").catch(showError);
  }, []);

  const salaryListOfCardHolders = useResource(
    () =>
      !isMain
        ? SalaryApi.getSalaryCardHolders({
            cardType: cardType,
            pageNumber: filter.getPage(),
            pageSize: filter.getPerPage(),
            param: "0",
          })
        : null,
    // [cardType],
    [cardType, filter.getPage(), filter.getPerPage(), isMain],
  );

  useResourceHandler(salaryListOfCardHolders, {
    onSuccess: (data) => {
      !isMain && data && dispatch(switchPageCount({ pageCount: data[0]?.totalPages?.toString() }));
    },
  });

  const cardHoldersData = useMemo(() => salaryListOfCardHolders.data || [], [
    salaryListOfCardHolders.data,
  ]);

  return (
    <>
      {!isMain && (
        <TabPage
          className="px-4"
          contentClassName="bg-transparent border-0"
          headerComponent={
            isListOfCardHolders ? (
              <SalaryListOfCardHoldersForm
                onChangeCardType={(value) =>
                  locationHelpers.replaceQuery({ cardType: value.value })
                }
                cardType={cardType}
                backButton={() => locationHelpers.replaceQuery({ listOfCardHolders: "" })}
              />
            ) : (
              isUploadFile && (
                <div className="d-flex justify-content-end pt-5">
                  <Button
                    labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
                    color={ButtonColor.Orange}
                    size={SizeType.Medium}
                  />
                </div>
              )
            )
          }
          footerComponent={
            isListOfCardHolders && (
              <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
                <div className="px-3">
                  <FileControls
                    isMinusTransferLcyType={true}
                    onSaveToExcelClick={() => {
                      if (cardHoldersData.length > 0) {
                        NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
                        SalaryApi.getSalaryCardHolders({
                          cardType: cardType,
                          pageNumber: filter.getPage(),
                          pageSize: filter.getPerPage(),
                        })
                          .then((res) => {
                            let cardHoldersList = [{}];
                            cardHoldersList.shift();
                            for (let i = 0; i < Number(res[0]?.totalPages); i++) {
                              SalaryApi.getSalaryCardHolders({
                                cardType: cardType,
                                pageNumber: i + 1,
                                pageSize: filter.getPerPage(),
                              }).then((r) => {
                                cardHoldersList = [...cardHoldersList, ...r];
                                if (cardHoldersList.length === toFinite(res[0]?.totalRows)) {
                                  const workbook = createCardHoldersExcelFromTable(
                                    cardHoldersList,
                                    {
                                      translate,
                                    },
                                  );
                                  saveExcelToFile(workbook, "Statement table").catch(showError);
                                }
                              });
                            }
                          })
                          .catch(() =>
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            ),
                          );
                      } else {
                        NotificationManager.error(translate("TABLE_EMPTY_STATE_TITLE"));
                      }
                    }}
                  />
                </div>
                <Paginator filter={filter} />
              </div>
            )
          }
        >
          {isRegisterSalary && (
            <SalaryEmployeeRegistrationForm
              citizenshipList={citizenship[0]}
              onSubmit={onCreateNewSalary}
              initialValues={initialValues}
              clearInitialValues={startClearing}
              clearing={clearing}
              setInitialValues={setInitialValues}
              disabled={false}
            />
          )}
          {isOpenSalary && (
            <SalaryEmployeeRegistrationForm
              citizenshipList={citizenship[0]}
              onSubmit={onCreateNewSalary}
              initialValues={initialValues}
              clearInitialValues={startClearing}
              clearing={clearing}
              setInitialValues={setInitialValues}
              disabled={true}
            />
          )}
          {isCreate && (
            <SalaryCreateNewRegisterTableForm
              noTransitAccount={() => locationHelpers.replaceQuery({ create: "" })}
              backTransitLocation={() => history.push(Routes.SalaryTransit)}
              backDebitLocation={() => history.push(Routes.SalaryDebit)}
              transitAccount={transitAccount}
              onChangeCardType={(value) => locationHelpers.replaceQuery({ cardType: value.value })}
              initialValues={{ data }}
              transferType={TransferType.Salary}
              count={count}
              setCount={setCount}
              filter={filter}
              cardType={cardType}
              openModal={openModal}
              setOpenModal={setOpenModal}
              chooseCardHolders={chooseCardHolders}
              setSelectIds={setSelectedIds}
            />
          )}
          {isListOfCardHolders && (
            <SalaryListOfCardHoldersTableWrapper
              data={cardHoldersData}
              loading={salaryListOfCardHolders.loading}
              cardType={cardType}
              onChangeCardInfo={onChangeCardInfo}
              filter={filter}
            />
          )}
        </TabPage>
      )}
      {isMain && (
        <PayrollTableWrapper
          transferType={TransferType.Salary}
          setRegisterInfo={setForPrinInfo}
          transitAccount={transitAccount}
          debitAccount={debitAccount}
          onCreateClick={onCreateRegister}
          onGetListOfCardHoldersClick={onGetListOfCardHoldersClick}
          onRegistrationClick={() => {
            clearInitialValues();
            onRegistrationClick();
          }}
          setDebitAccount={setDebitAccount}
          setTransitAccount={setTransitAccount}
          transferFilter={filter}
          readFromExcel={readFromExcel}
          chooseExistingRegister={chooseExistingRegister}
          payrollTable={payrollTable}
          setPayrollTable={setPayrollTable}
        />
      )}
      <Modal
        show={viewTable}
        onClose={() => setViewTable(false)}
        title="TRANSFERS_SALARY_TAB_TITLE"
        width={1000}
      >
        <PayrollViewTable
          data={viewTable ? data.filter((x) => x.amount || x.cardType === cardType) : []}
          transferType={TransferType.Salary}
          onPrint={printRegister}
          onSaveToExcel={saveToExcel}
        />
      </Modal>
      <PrintPortal>
        <SalaryPrintTable data={registerForPrint} registerInfo={forPrintInfo} />
      </PrintPortal>
    </>
  );
});
SalaryTab.displayName = "SalaryTab";
