import React from "react";
import { ExcelIcon } from "../icons/ExcelIcon";
import { useI18n } from "../../i18n/I18nContext";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props {
  readonly fileSize: string;
  readonly title: string;
  readonly href?: string;
  readonly iconName?: string;
  readonly className?: string;
  readonly onClick?: () => void;
}

export function TransferFormDirectionsTabItems({
  fileSize,
  title,
  href,
  iconName,
  className,
  onClick,
}: Props) {
  const { translate } = useI18n();
  return (
    <div className={`mt-5 mb_5 d-flex ${className}`}>
      {iconName === "doc" ? (
        <ExcelIcon size={60} color={ColorPalette.Blue} className="mr-3" />
      ) : (
        <ExcelIcon size={60} color={ColorPalette.Green} className="mr-3" />
      )}
      <div className="flex-fill  pr_3 w-75">
        <a href={href} className="fs-4" onClick={onClick}>
          {title}
        </a>
        <div className="w-100 d-flex flex-column mt-2">
          <span className="fs-3 text-dark">
            {translate("TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_TITLE")} {fileSize}
            {translate("TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_KB_TITLE")}
          </span>
        </div>
      </div>
    </div>
  );
}
