import React, { useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { AppLayout } from "./AppLayout";
import { PageLayoutProps } from "./PageLayout";
import { CorporateSideMenuWrapper } from "./CorporateSideMenuWrapper";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { SessionExpiredModal } from "../auth/SessionExpiredModal";
import {
  authDateExpiredSelector,
  setAuthDate,
  setAuthDateExpired,
  tokenSelector,
} from "../../reducers/authReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  changeNotificationMessageState,
  // notificationMessageSelector,
} from "../../reducers/appReducer";
import { useI18n } from "../../i18n/I18nContext";

export function CorporateAppLayout({ children, ...props }: Omit<PageLayoutProps, "menuComponent">) {
  const [sessionUpdate, setSessionUpdate] = useState(true);
  const authDateExpired = useShallowEqualSelector(authDateExpiredSelector);
  const token = useShallowEqualSelector(tokenSelector);
  const dispatch = useDispatch();
  const { translate } = useI18n();

  const showOtp = useMemo(() => token && authDateExpired, [authDateExpired, token]);

  // announcements  in IDBA
  const notification = true;
  // const notification = useShallowEqualSelector(notificationMessageSelector);

  const onCloseHandler = useCallback(() => {
    dispatch(changeNotificationMessageState({ notificationMessage: "Hide notification message" }));
  }, [dispatch]);

  return (
    <>
      <AppLayout {...props} menuComponent={<CorporateSideMenuWrapper />}>
        {children}
      </AppLayout>

      {sessionUpdate && (
        <Modal width={550} show={showOtp ? showOtp : false} title={""}>
          <TabPage>
            <SessionExpiredModal
              onSubmit={() => {
                setSessionUpdate(false);
                dispatch(setAuthDate({ authDate: Date.now() }));
                dispatch(setAuthDateExpired({ state: false }));
                setTimeout(() => setSessionUpdate(true), 1000);
              }}
            />
          </TabPage>
        </Modal>
      )}
      {!notification && (
        <Modal
          ariaHideApp={false}
          width={850}
          show={!notification ? true : false}
          title={""}
          onClose={() => onCloseHandler()}
          className={"modal_bodyNotification"}
        >
          <div>{translate("NOTIFICATION_MESSAGE_21122023")}</div>
        </Modal>
      )}
    </>
  );
}
