import React, { useCallback, useMemo, useState } from "react";
import {
  BudgetAccountSearchType,
  PaymentCodeBudgetFlag,
} from "../../api/reference-book/ReferenceBookDTO";
import { update } from "immupdate";
import { useResource } from "../../hooks/useResource";
import { useReferenceBookContext } from "../../api/reference-book/ReferenceBookContext";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { LcyTransferBudgetRevenueFormProps } from "../../api/transfer/TransferDTO";
import { FormikProps } from "formik";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { PaymentCodeTable } from "./PaymentCodeTable";
import { numberToWords } from "../../utils/FormatUtils";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { formatDateToBody } from "../../utils/DateUtils";
import { LcyTransferBudgetRevenueForm } from "./LcyTransferBudgetRevenueForm";
import { BudgetRevenueAccountTable } from "./BudgetRevenueAccountTable";
import { NotificationManager } from "react-notifications";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector } from "../../reducers/appReducer";
import { FormatPaymentCode } from "../../utils/FormatImportPaymentCode";

interface Props {
  readonly onSubmit: (values: LcyTransferBudgetRevenueFormProps) => void;

  readonly formikRef?: (instance: FormikProps<LcyTransferBudgetRevenueFormProps>) => void;

  readonly initialValue?: LcyTransferBudgetRevenueFormProps;

  readonly setinitialValues: (value) => void;
}

export function LCYBudgetRevenueTransferFormWrapper({
  onSubmit,
  formikRef,
  initialValue,
  setinitialValues,
}: Props) {
  const { translate } = useI18n();
  const { ReferenceBookApi } = useReferenceBookContext();
  const { TransferApi } = useTransferContext();

  const onChangeTransferNumber = useCallback(
    (value) => {
      if (value.length <= 10) {
        setinitialValues((prev) =>
          update(prev, {
            documentNumber: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangeDocumentDate = useCallback(
    (value) => {
      NotificationManager.info(
        translate("WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE"),
      );
      setinitialValues((prev) =>
        update(prev, {
          documentDate: value,
        }),
      );
    },
    [setinitialValues, translate],
  );

  const language = useShallowEqualSelector(appLanguageSelector);
  const onChangeAmount = useCallback(
    // (value) => {
    //   const languageApp =
    //     language === "ru"
    //       ? Language.Russian
    //       : language === "uz"
    //       ? Language.Uzbek
    //       : Language.English;
    //   const size = value.includes(" ") ? 19 : value.split(".")[1] ? 16 : 13;
    //   const checked = /[0-9 ]/.test(value);
    //   if (value.length < size && checked) {
    //     if (value.split(".")[1]) {
    //       if (value.split(".")[1]?.length <= 2) {
    //         const amountDetails = numberToWords(removeGaps(value), {
    //           language: languageApp,
    //           currencyCode: CurrencyCode.UzbekistanSum,
    //         });
    //         setinitialValues((prev) =>
    //           update(prev, {
    //             amount: value.replace(/[^0-9 .]/gi, ""),
    //             amountDetails:
    //               amountDetails !== ""
    //                 ? amountDetails && amountDetails[0].toUpperCase() + amountDetails.slice(1)
    //                 : "",
    //           }),
    //         );
    //       }
    //     } else {
    //       const amountDetails = numberToWords(removeGaps(value), {
    //         language: languageApp,
    //         currencyCode: CurrencyCode.UzbekistanSum,
    //       });
    //       setinitialValues((prev) =>
    //         update(prev, {
    //           amount: value.replace(/[^0-9 .]/gi, ""),
    //           amountDetails:
    //             amountDetails !== ""
    //               ? amountDetails && amountDetails[0].toUpperCase() + amountDetails.slice(1)
    //               : "",
    //         }),
    //       );
    //     }
    //   }
    // },
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      if (value.length <= 18) {
        const amountDetails = numberToWords(removeGaps(value), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        });
        setinitialValues((prev) =>
          update(prev, {
            amount: floatFormat(value),
            amountDetails:
              amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
          }),
        );
      }
    },
    [language, setinitialValues],
  );

  const onChangePaymentCode = useCallback(
    (value) => {
      if (value.length <= 5) {
        setinitialValues((prev) =>
          update(prev, {
            paymentCode: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangePaymentDetails = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          detailsOfPayment: value.replace(/\s+/g, " "),
        }),
      );
    },
    [setinitialValues],
  );

  const onChangeAccount = useCallback(
    (value) => {
      if (value.length <= 25) {
        setinitialValues((prev) =>
          update(prev, {
            budgetAccount: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const [debitAccount, setDebitAccount] = useState({});

  useResource(
    () =>
      ReferenceBookApi.getAllowedAccounts().then((r) => {
        setDebitAccount([
          r.map((res) => ({
            label: res.accountCode,
            value: res.accountCode,
            clientCode: res.accountCode,
            balance: res.balance,
            senderBankCode: res?.accountBranch,
            senderName: res?.accountName,
            accountState: res?.accountState,
            senderBankName: res?.bankName,
            senderTaxId: res?.taxNumber,
          })),
        ]);
      }),
    [TransferApi],
  );

  const onChangeDebitsAccount = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          senderName: value.senderName,
          currentBalance: floatFormat(addZeros(value.balance)),
          senderBankName: value.senderBankName,
          senderBankCode: value.senderBankCode,
          senderTaxId: value.senderTaxId,
          clientAccount: value,
          accountState: value.accountState,
        }),
      );
    },
    [setinitialValues],
  );

  const [paymentCode, setPaymentCode] = useState(false);

  const [corrAccount, setCorrAccount] = useState(false);

  const [searchTaxId, setSearchTaxId] = useState("");

  const [searchPaymentCode, setSearchPaymentCode] = useState("");

  const paymentCodeListSearch = useCallback((data, term) => {
    if (term !== "") {
      return data && data.filter((item) => `${item.code} ${item.name}`.indexOf(term) > -1);
    } else return data;
  }, []);

  const beneficiaryListSearch = useCallback((data, term) => {
    if (term !== "") {
      return data && data.filter((item) => item.budgetIncomeAccount.indexOf(term) > -1);
    } else return data;
  }, []);

  const paymentCodes = useResource(
    () =>
      ReferenceBookApi.checkPaymentCode({
        paymentCode: searchPaymentCode ? searchPaymentCode : "",
        budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
      }),
    [ReferenceBookApi, searchPaymentCode],
  );

  const paymentCodesList = useMemo(() => paymentCodes.data || [], [paymentCodes.data]);

  const corrAccounts = useResource(
    () =>
      ReferenceBookApi.getBudgetIncomeAccountsInformation({
        searchType: BudgetAccountSearchType.LastRows,
      }),
    [ReferenceBookApi],
  );

  const corrAccountsList = useMemo(() => corrAccounts.data || [], [corrAccounts.data]);

  useResource(() => {
    ReferenceBookApi.getStaticDataForBeneficiary().then((r) => {
      setinitialValues((prev) =>
        update(prev, {
          correspondentName: r[0].beneficiaryName,
          correspondentBankName: r[0].beneficiaryBankName,
          correspondentBank: r[0].beneficiaryBankCode,
          correspondentTaxNumber: r[0].beneficiaryTaxID,
          correspondentAccount: r[0].creditAccount,
        }),
      );
    });
  }, [ReferenceBookApi, TransferApi, setinitialValues]);

  const submitHandler = useCallback(
    (values) => {
      if (values.budgetAccountName === "Not Found") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_BUDGET_ACCOUNT"),
          translate("TRANSFER_ERRORS_BUDGET_ACCOUNT_TITLE"),
          5000,
        );
      }
      // else if (typeof values.amount !== "number") {
      //   NotificationManager.error(
      //     translate("TRANSFER_AMOUNT_ERROR"),
      //     translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
      //     7000,
      //   );
      // }
      else if (values.amount === "0.00") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_AMOUNT"),
          translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
          7000,
        );
      } else if (values.clientAccount?.label === undefined) {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT"),
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE"),
          7000,
        );
      } else if (!FormatPaymentCode(values.paymentCode, "08")) {
        NotificationManager.error(
          translate("TRANSFER_PAYMENT_CODE_ERROR"),
          translate("TRANSFER_IMPORT_ERROR"),
          7000,
        );
      } else {
        const data = {
          ...values,
          documentDate: formatDateToBody(values.documentDate),
          budgetTaxNumber: values.senderTaxId,
          amount: removeGaps(values.amount),
          correspondentBankName: values.correspondentBankName.substr(0, 70),
          budgetAccountName: values.budgetAccountName.substr(0, 70),
          clientAccount: values.clientAccount.label,
          currentBalance: removeGaps(values.currentBalance),
        };
        onSubmit(data);
      }
    },
    [onSubmit, translate],
  );

  const onCorrespondentAccountSelect = useCallback(
    (value) => {
      setCorrAccount(false);
      const selected = corrAccountsList.filter((corr) => corr.budgetIncomeAccount === value);
      setinitialValues((prev) =>
        update(prev, {
          budgetAccountName: selected[0]?.budgetAccountName,
          budgetAccount: selected[0]?.budgetIncomeAccount,
        }),
      );
    },
    [corrAccountsList, setinitialValues],
  );

  const onPaymentCodeSelect = useCallback(
    (value) => {
      setPaymentCode(false);
      const selected = paymentCodesList.filter((pCs) => pCs.code === value);
      setinitialValues((prev) =>
        update(prev, {
          paymentCode: selected[0].code,
        }),
      );
    },
    [paymentCodesList, setinitialValues],
  );

  const onFindBudgetName = useCallback(
    (value) => {
      if (value.length === 25) {
        ReferenceBookApi.getBudgetIncomeAccountsInformation({
          searchType: BudgetAccountSearchType.OneRow,
          budgetIncomeAccount: value,
        }).then((r) => {
          setinitialValues((prev) =>
            update(prev, {
              budgetAccountName: r.length === 0 ? "Not Found" : r[0]?.budgetAccountName,
            }),
          );
        });
      }
    },
    [ReferenceBookApi, setinitialValues],
  );

  const beneficiaryListAfterSearch = useMemo(
    () => beneficiaryListSearch(corrAccountsList, searchTaxId),
    [beneficiaryListSearch, corrAccountsList, searchTaxId],
  );
  const paymentCodeListAfterSearch = useMemo(
    () => paymentCodeListSearch(paymentCodesList, searchPaymentCode),
    [paymentCodeListSearch, paymentCodesList, searchPaymentCode],
  );
  return (
    <>
      <LcyTransferBudgetRevenueForm
        onChangeAccount={onChangeAccount}
        onFindBudgetName={onFindBudgetName}
        debits={debitAccount[0]}
        onSubmit={submitHandler}
        formikRef={formikRef}
        initialValues={initialValue}
        getPaymentCode={() => setPaymentCode(true)}
        getCorrespondentAccount={() => setCorrAccount(true)}
        onChangeDebitsAccount={onChangeDebitsAccount}
        onChangeAmount={onChangeAmount}
        onChangePaymentCode={onChangePaymentCode}
        onChangeTransferNumber={onChangeTransferNumber}
        onChangePaymentDetails={onChangePaymentDetails}
        onChangeDocumentDate={onChangeDocumentDate}
      />
      <Modal
        searchInput={true}
        setSearch={(value) => setSearchPaymentCode(value)}
        onClose={() => setPaymentCode(false)}
        width={700}
        show={paymentCode}
        title={"TRANSFERS_MODAL_PAYMENT_CODE_TITLE"}
      >
        <TabPage>
          <PaymentCodeTable
            onPaymentCodeSelect={onPaymentCodeSelect}
            data={paymentCodeListAfterSearch}
            loading={paymentCodes.loading}
          />
        </TabPage>
      </Modal>
      <Modal
        searchInput={true}
        setSearch={(value) => setSearchTaxId(value)}
        onClose={() => setCorrAccount(false)}
        width={900}
        show={corrAccount}
        title={"TRANSFERS_MODAL_BUDGET_ACCOUNTS"}
      >
        <TabPage>
          <BudgetRevenueAccountTable
            onCorrespondentAccountSelect={onCorrespondentAccountSelect}
            data={beneficiaryListAfterSearch}
            loading={corrAccounts.loading}
          />
        </TabPage>
      </Modal>
    </>
  );
}
