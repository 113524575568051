import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { AnyAction } from "../utils/ReducerUtils";
import { salaryReducer, SalaryReducerState } from "../reducers/salaryReducer";
import { payrollListIdsReducer, PayrollSelectedIds } from "../reducers/payrollSelectedIds";
import { accountsReducer, AccountsReducerState } from "../reducers/accountsReducer";
import { appReducer, appReducerPersistConfig, AppReducerState } from "../reducers/appReducer";
import { userReducer, userReducerPersistConfig, UserReducerState } from "../reducers/userReducer";
import { authReducer, authReducerPersistConfig, AuthReducerState } from "../reducers/authReducer";
import {
  paginationReducer,
  paginationReducerPersistConfig,
  PaginationReducerState,
} from "../reducers/paginationReducer";
import {
  totalReducer,
  totalReducerPersistConfig,
  TotalReducerState,
} from "../reducers/totalReducer";

export interface AppStoreState {
  readonly app: AppReducerState;
  readonly user: UserReducerState;
  readonly auth: AuthReducerState;
  readonly salary: SalaryReducerState;
  readonly accounts: AccountsReducerState;
  readonly payrollListIds: PayrollSelectedIds;
  readonly pagination: PaginationReducerState;
  readonly total: TotalReducerState;
}

export const rootReducer = combineReducers<any>({
  payrollListIds: payrollListIdsReducer,
  salary: salaryReducer,
  accounts: accountsReducer,
  pagination: persistReducer<PaginationReducerState, AnyAction>(
    {
      ...paginationReducerPersistConfig,
      key: "pagination",
      storage,
    },
    paginationReducer,
  ),
  app: persistReducer<AppReducerState, AnyAction>(
    {
      ...appReducerPersistConfig,
      key: "app",
      storage,
    },
    appReducer,
  ),
  auth: persistReducer<AuthReducerState, AnyAction>(
    {
      ...authReducerPersistConfig,
      key: "auth",
      storage,
    },
    authReducer,
  ),
  user: persistReducer<UserReducerState, AnyAction>(
    {
      ...userReducerPersistConfig,
      key: "user",
      storage,
    },
    userReducer,
  ),
  total: persistReducer<TotalReducerState, AnyAction>(
    {
      ...totalReducerPersistConfig,
      key: "total",
      storage,
    },
    totalReducer,
  ),
});
