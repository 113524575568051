import React, { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";

import { PageTitle } from "../app/PageTitle";
import { InputField } from "../form/InputField";
import { TransferType } from "../../api/transfer/TransferDTO";
import { update } from "immupdate";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";
import { SelectPickerField } from "../form/SelectPickerField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { DebitAccountNumberFieldWrapper } from "../form/DebitAccountNumberFieldWrapper";
import { useResource } from "../../hooks/useResource";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { Button, ButtonColor } from "../ui/Button";

interface Props {
  readonly transferType: TransferType;
  readonly setInitialValues: (value) => void;
  readonly onChangeAccountType: (value) => void;
  readonly submit: (value) => void;
  readonly initialValues: any;
  readonly accountTypes: any[];
}

export function CorporateChooseDebitAccountForm({
  transferType,
  setInitialValues,
  onChangeAccountType,
  initialValues,
  accountTypes,
  submit,
}: Props) {
  const isMobile = CheckMobileUtils();

  const { CorporateCardsApi } = useCorporateCardsContext();

  const accountOfCorporateCardData = useResource(
    () => CorporateCardsApi.accountOfCorporateCardList(),
    [],
  );

  const accountOfCorporateCardList = useMemo(() => accountOfCorporateCardData.data || [], [
    accountOfCorporateCardData.data,
  ]);

  const formattedAccount = useMemo(
    () =>
      accountOfCorporateCardList.map((x) => ({
        value: x.cardId,
        label: x.accountNumber,
      })),
    [accountOfCorporateCardList],
  );

  const onChangeAccountOfCard = useCallback(
    (value) => {
      const account = accountOfCorporateCardList.filter((acc) => acc.cardId === value.value)[0];
      setInitialValues((prev) =>
        update(prev, {
          accountOfCard: {
            label: value.label,
            value: value.value,
          },
          corporateCardNumber: account.cardNumber,
          accountName: account.employerName,
        }),
      );
    },
    [setInitialValues, accountOfCorporateCardList],
  );

  const onChangeAmount = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          amount: value,
        }),
      );
    },
    [setInitialValues],
  );
  return (
    <div
      className={`border border-gray-light br-1 mt-5 bg-white p-7 ${isMobile ? "w-100" : "w-50"}`}
    >
      <Formik initialValues={initialValues} onSubmit={() => submit(initialValues)}>
        {() => (
          <Form>
            <div className="w-100 d-flex align-items-center justify-content-between pb-5">
              <PageTitle title="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_TITLE" />
              <SelectPickerField
                width={240}
                name="accountType"
                size={SizeType.Small}
                labelPosition={PositionType.Right}
                options={accountTypes}
                onChanges={onChangeAccountType}
              />
            </div>
            <div className="row pb-2">
              <DebitAccountNumberFieldWrapper
                defaultValue={initialValues.debitAccount}
                className="col-lg-5"
                name="debitAccount"
                transferType={transferType}
                label="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_NUMBER_FIELD_TITLE"
                onChange={(value) => {
                  setInitialValues((prev) =>
                    update(prev, {
                      debitAccount: value,
                      accountBalance: value.balance,
                    }),
                  );
                }}
              />
              <InputField
                disabled={true}
                className="col-lg-5"
                name="accountBalance"
                label="TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE"
                value={initialValues?.accountBalance}
              />
              <InputField
                name="code"
                className="col-lg-2"
                label="TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE"
                value={initialValues?.code}
                disabled={true}
              />
            </div>
            <div className="row pb-2 d-flex">
              <div className="col-lg-6">
                <SelectPickerField
                  name="accountOfCard"
                  label="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_OF_CORPORATE_FIELD_TITLE"
                  onChanges={(value) => onChangeAccountOfCard(value)}
                  options={formattedAccount}
                  autoComplete="on"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  name="amount"
                  label="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_AMOUNT_FIELD_TITLE"
                  value={initialValues?.amount}
                  onFocus={(value) => onChangeAmount(removeGaps(value.target.value))}
                  onChange={(value) => onChangeAmount(value.target.value)}
                  onBlur={(value) =>
                    value.target.value && onChangeAmount(floatFormat(addZeros(value.target.value)))
                  }
                  inputClassName="amount"
                />
              </div>
            </div>
            <div className="row pb-2 d-flex">
              <div className="col-lg-6">
                <InputField
                  name="corporateCardNumber"
                  label="TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_CORPORATE_CARD_NUMBER_FIELD_TITLE"
                  value={initialValues?.corporateCardNumber}
                  disabled={true}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  name="detailsType"
                  label="TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE"
                  disabled={true}
                />
              </div>
              <div className="col-12 d-flex justify-content-end mt-5">
                <Button
                  disabled={
                    initialValues.debitAccount === "" ||
                    initialValues.accountOfCard === "" ||
                    initialValues.amount === ""
                  }
                  size={SizeType.Medium}
                  color={ButtonColor.Orange}
                  type="submit"
                  labelCode="TRANSFERS_CORPORATE_CHOOSE_DEBIT_SUBMIT_BUTTON_TITLE"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
