import React from "react";

import { IconButton } from "../ui/IconButton";
import { LogoutIcon } from "../icons/LogoutIcon";
import { useAuthContext } from "../../api/auth/AuthContext";

export function LogoutButtonWrapper() {
  const { logout } = useAuthContext();

  return (
    <IconButton onClick={() => logout()} className="mr-2">
      <LogoutIcon />
    </IconButton>
  );
}
