import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { GetTransferListForApprovalProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { Dict } from "../../api/dto/AppDTO";
import { TabPage } from "../tabs/TabPage";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly data: GetTransferListForApprovalProps[];
  readonly setDocumentId: (ids: Dict<boolean>) => void;
  readonly withCreatorsAndApprovals?: boolean;
  readonly chooseTransferForView: (value) => void;
  readonly loading?: boolean;
}

export function TransfersTable({
  data = [],
  setDocumentId,
  withCreatorsAndApprovals,
  chooseTransferForView,
  loading,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<GetTransferListForApprovalProps>[] = [
      {
        width: 95,
        accessor: "operationDate",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_CREATED_DATE_AND_TIME_COLUMN_TITLE"),
      },
      {
        width: 95,
        accessor: "documentDate",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_TRANSACTION_DATE_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "documentNumber",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a
            className="cursor-pointer"
            onClick={() => chooseTransferForView(row.cell.row.original)}
          >
            {row.value}
          </a>
        ),
      },
      {
        width: 170,
        accessor: "amount",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : "0.00"}
          </div>
        ),
      },
      {
        width: 180,
        accessor: "clientAccount",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "correspondentAccount",
        Header: translate(
          "PENDING_APPROVALS_CONVERSION_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE",
        ),
      },
      {
        width: 180,
        accessor: "correspondentName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "receiverMfo",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_CORR_BANK_CODE_COLUMN_TITLE"),
      },
      {
        width: 400,
        accessor: "paymentDetail",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "state",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [withCreatorsAndApprovals, translate]);

  return (
    <TabPage className="px-4">
      <Table
        data={data}
        columns={columns}
        onSelect={setDocumentId}
        withCheckbox={true}
        loading={loading}
      />
    </TabPage>
  );
}
