import { I18nSchema } from "./I18nSchema";
import { I18nRussian } from "./I18nRussian";

export const I18nUzbek: I18nSchema = {
  ...I18nRussian,
  MODAL_INFO_CONVERSION:
    "Konvertatsiya xizmatlarini ko‘rsatish bo‘yicha Ommaviy oferta\n" +
    "\n" +
    'Ushbu hujjat "KDB Bank Uzbekistan" AJ (keyingi o‘rinlarda Bank deb yuritiladi) ning rasmiy taklifi (ommaviy oferta) bo‘lib, Bank Mijozlari (keyingi o‘rinlarda Mijoz) bilan:\n' +
    "\n" +
    "\n" +
    "    • chet el valyutasini O‘zbekiston Respublikasining milliy valyutasiga (keyingi o‘rinlarda milliy valyuta deb yuritiladi) konvertatsiya qilish, \n" +
    "    • milliy valyutani chet el valyutasiga konvertatsiya qilish (xizmat safari xarajatlari uchun konvertatsiya bundan mustasno),\n" +
    "    • bir turdagi chet el valyutasini boshqa turdagi chet el valyutasiga konvertatsiya qilish\n" +
    "bo‘yicha bank operatsiyalarining standart shartlari va tartibini belgilaydi.\n" +
    "O‘zbekiston Respublikasi Fuqarolik Kodeksining 369-moddasi 2-bandiga muvofiq, konvertatsiya uchun buyurtma yoki chet el valyutasida o‘tkazma uchun ariza berilganda quyida keltirilgan shartlar qabul qilinsa va shartnoma qabul qilinsa, ushbu ommaviy ofertani qabul qilgan Mijoz Shartnomaning Tomoniga aylanadi (O‘zbekiston Respublikasi Fuqarolik Kodeksining 370-moddasi 4-qismiga binoan, ofertani qabul qilish ofertada ko‘rsatilgan shartlar bo‘yicha bitim tuzishga tengdir), va Bank va Mijoz birgalikda - bitimning Tomonlari hisoblanadi.\n" +
    "\n" +
    "\n" +
    "1. АТАМАЛАР\n" +
    '1.1. Oferta – ushbu hujjat "Konvertatsiya xizmatlarini ko‘rsatish bo‘yicha ommaviy oferta".\n' +
    "1.2. Konvertatsiya xizmatlarini ko‘rsatish bo‘yicha ommaviy oferta – bu ofertani qabul qilish yo‘li bilan tuziladigan Bank va Mijoz o‘rtasida konvertatsiya xizmatlarni ko‘rsatish to‘g‘risidagi bitim.\n" +
    "1.3. Mijoz – ommaviy ofertani qabul qilgan shaxs bo‘lib, ommaviy oferta doirasida Bank xizmatlarining buyurtmachisi hisoblanadi.\n" +
    '1.4. Bank – "KDB Bank Uzbekistan" AJ bosh ofisi va uning filiallari.\n' +
    "1.5. Ommaviy ofertani qabul qilish - Mijoz tomonidan ommaviy ofertaning 1.7-bandida ko‘rsatilgan harakatlarni amalga oshirish orqali ofertani to‘liq va shartsiz qabul qilish.\n" +
    "1.6. Masofaviy xizmat dasturlari (keyingi o‘rinlarda – MXD) – Mijozga konvertatsiya va to‘lovni amalga oshirish bo‘yicha topshiriqlarni yaratish va uzatish imkoniyatini beruvchi Bank dasturlari.\n" +
    "1.7. Ommaviy ofertaga qo‘shilish - konvertatsiya uchun buyurtma (Ilova №1, Ilova №2) yoki chet el valyutasida o‘tkazma uchun arizada (Ilova №31) mijozning ommaviy ofertaga qo‘shilishga aniq va avtomatik ravishda roziligi va uning shartlarini qabul qilishi ko‘zda tutilgan yozma band. Konvertatsiya uchun buyurtma yoki chet el valyutasida o‘tkazma uchun arizani imzolagan yoki ushbu hujjatlarni MXD orqali avtorizatsiya qilish orqali Mijoz ommaviy oferta shartlarini avtomatik ravishda qabul qiladi.\n" +
    "1.8. Xizmat - Bank tomonidan konvertatsiya xizmatlarni taqdim etish.\n" +
    "1.9. Konvertatsiya quyidagi operatsiyalarni o‘z ichiga oladi: \n" +
    "    • Mijoz tomonidan chet el valyutasini sotish va milliy valyutani sotib olish,\n" +
    "    • Mijoz tomonidan milliy valyutani sotish va chet el valyutasini sotib olish,\n" +
    "    • Mijoz tomonidan bir turdagi chet el valyutasini boshqa turdagi chet el valyutasiga sotish va sotib olish.\n" +
    "1.10. O‘tkazma uchun ariza - Mijozning bankdagi hisobvarag‘ida bo‘lgan chet el valyutasidagi mablag‘larini chiqim qilish va ushbu valyutada yoki Mijoz tomonidan o‘tkazma uchun arizada ko‘rsatilgan konvertatsiya qilingan valyutada to‘lovni amalga oshirish orqali chet el valyutasidagi mablag‘larni o‘tkazish to‘g‘risida Bankka ko‘rsatma beradigan hujjat.\n" +
    "1.11. Konvertatsiya uchun buyurtma - Mijozning Bankka quyidagi ko‘rsatmalarini taqdim etuvchi hujjat:\n" +
    "    • chet el valyutasini sotish va so‘ngra Mijozning milliy valyutadagi hisobvarag‘iga kirim qilish,\n" +
    "    • milliy valyutani sotish va chet el valyutasini sotib olish va so‘ngra Mijozning chet el valyutasidagi maxsus hisobvarag‘iga kirim qilish,\n" +
    "    • bir turdagi chet el valyutasini sotish va so‘ngra konvertatsiya uchun buyurtmada ko‘rsatilgan boshqa turdagi chet el valyutasida Mijozning hisobvarag‘iga kirim qilish.\n" +
    "\n" +
    "\n" +
    "2. BANK TOMONIDAN KO‘RSATILADIGAN XIZMATLARNING UMUMIY SHARTLARI\n" +
    "\n" +
    "2.1. Ushbu ommaviy oferta konvertatsiya amaliyotlarida Bank va Mijoz o‘rtasidagi o‘zaro munosabatlarning tartib va shartlarini belgilaydi. \n" +
    "2.2. Xizmat konvertatsiya uchun buyurtma va/yoki   o‘tkazma uchun arizani imzolash va taqdim etish orqali ushbu ommaviy ofertani qabul qilgan Bank Mijozlariga ko‘rsatiladi. Konvertatsiya uchun buyurtma va/yoki o‘tkazma uchun arizani imzolash yoki MXD orqali ushbu buyurtma/arizalarni tasdiqlash orqali Mijoz zaruriyat bo‘lganda Bank xizmatlaridan foydalanish huquqiga ega.\n" +
    "2.3. Mijozga ko‘rsatiladigan xizmat faqat konvertatsiya va/yoki o‘tkazma bo‘yicha murojaatni bajarish bilan cheklanadi. Bank ushbu ommaviy oferta doirasida chet el valyutasini sotib olish / sotish yoki boshqa operatsiyalarning mohiyati yoki maqsadga muvofiqligi to‘g‘risida tavsiyalar bermaydi.\n" +
    "2.4. Konvertatsiya qilish uchun valyuta miqdori va turi Mijoz tomonidan ushbu ommaviy ofertaning ajralmas qismi bo‘lgan va Shartnomaga xos bo‘lgan barcha huquq va majburiyatlari bilan Shartnoma kuchiga ega bo‘lgan alohida konvertatsiya uchun buyurtma yoki o‘tkazma uchun ariza asosida belgilanadi.\n" +
    "2.5. Ushbu ommaviy oferta doirasida konvertatsiya ommaviy ofertaning amal qilish muddati davomida takroriy ravishda amalga oshirilishi mumkin.\n" +
    "2.6. Ushbu ommaviy oferta doirasida konvertatsiya mazkur ommaviy oferta, konvertatsiya uchun buyurtma, o‘tkazma uchun ariza va amaldagi qonun hujjatlarida nazarda tutilgan boshqa zarur hujjatlar asosida amalga oshiriladi.\n" +
    "\n" +
    "3. CHET EL VALYUTASINI MILLIY VALYUTAGA KONVERTATSIYA QILISH TARTIBI\n" +
    "\n" +
    "3.1. Mijoz (jismoniy shaxslar mustasno2) chet el valyutasini bitim paytida Bank tomonidan belgilangan valyuta kursi bo‘yicha sotadi.\n" +
    "3.2. Mijoz, buyurtma taqdim etishidan oldin, sotish uchun etarli miqdordagi chet el valyutasini Bankdagi o‘z hisobvarag‘iga o‘tkazadi va zaxiralaydi.\n" +
    "3.3. Mijoz sotib olingan mablag‘larni kirim qilish uchun Bankda milliy valyutada talab qilib olinguncha depozit hisobvarag‘iga ega bo‘lishi lozim.\n" +
    "3.4. Mijoz ushbu ommaviy ofertaning 1-ilovasiga muvofiq Bankka buyurtma taqdim etadi.\n" +
    "\n" +
    "3.5. Mijoz buyurtmada chet el valyutasini sotishga rozi bo‘lgan minimal valyuta kursini ko‘rsatadi.\n" +
    "\n" +
    "Shu bilan birga, agar sotish kunida Bank belgilagan valyuta kursi buyurtmada ko‘rsatilgan minimal valyuta kursiga teng / yuqori bo‘lsa buyurtma qondiriladi. Bunda chet el valyutasini sotish Bank tomonidan belgilangan valyuta kursi bo‘yicha amalga oshiriladi.\n" +
    "Bankning valyuta kursi buyurtmada ko‘rsatilgan minimal valyuta kursiga teng / yuqori bo‘lmaguncha buyurtma qondirilmaydi.\n" +
    "\n" +
    "3.6. Bank buyurtma berilgan kundan keyingi 2 (ikki) bank ish kuni ichida buyurtmani ijro etadi va barcha hisob-kitoblarni amalga oshiradi.\n" +
    "\n" +
    "3.7. Mijoz Bankni Bankka taqdim etilgan buyurtmani bekor qilish to‘g‘risida Bank buyurtmani ijro etishni boshlashidan oldin xabardor etadi.\n" +
    "\n" +
    "\n" +
    "4. MILLIY VALYUTANI CHET EL VALYUTASIGA KONVERTATSIYA QILISH TARTIBI\n" +
    "\n" +
    "4.1. Mijoz (jismoniy shaxslar mustasno3) chet el valyutasini bitim paytida Bank tomonidan belgilangan valyuta kursi bo‘yicha sotib oladi.\n" +
    "4.2. Valyuta turi, miqdori va maksimal valyuta kursi, sotib olingan valyutadan foydalanish maqsadi hamda valyuta operatsiyasini asoslovchi hujjatlar buyurtmada (2-ilova) ko‘rsatiladi.\n" +
    "\n" +
    "4.3. Chet el valyutasini sotib olish bo‘yicha operatsiyalar Mijoz tomonidan qog‘oz shaklida yoki MXD orqali yuborilgan buyurtmalari asosida Mijoz tomonidan maqsadli foydalanilishi uchun amalga oshiriladi.\n" +
    "4.4. Chet el valyutasini sotib olishda Bank ushbu operatsiyalarning qonuniyligini tasdiqlovchi qo‘shimcha hujjatlarni talab qilishga haqlidir.\n" +
    "4.5. Chet el valyutasidagi maxsus hisobvaraqlarga kiritilgan mablag‘lar qat’iyan buyurtmalarda ko‘rsatilgan maqsadlar uchun foydalaniladi.\n" +
    "4.6. Ushbu ommaviy ofertaning 4-bo‘limiga muvofiq chet el valyutasini sotib olishda:\n" +
    "4.6.1 Mijoz sotib olingan chet el valyutasidagi mablag‘larni kirim qilish uchun chet el valyutasida maxsus hisobvaraq (balans hisobraqami - 22614) ochishi lozim.\n" +
    "4.6.2. Mijoz milliy valyutada maxsus hisobvaraq (balans hisobraqami - 22613) ochishi va ushbu hisobvaraq orqali (2) ikki bank ish kunidan kechiktirmagan holda, Bank tomonidan ijobiy xulosalar qabul qilingan buyurtmalar bo‘yicha barcha hisob-kitoblarni amalga oshirishi lozim.\n" +
    "Agar Mijoz hisob-kitoblarni (2) ikki bank ish kuni ichida amalga oshirmasa, transaksiya bekor qilingan hisoblanadi. Shu bilan birga, Mijoz tomonidan sotib olingan mablag‘lar Bankka qayta sotish bitimi paytida Bank tomonidan belgilangan valyuta kursi bo‘yicha qayta sotiladi.\n" +
    "4.6.3. Mijoz chet el valyutasini sotib olayotganda Bankka buyurtmani taqdim etishi, zarurat bo‘lganda, valyuta sotib olish asosini (maqsadli foydalanish) tasdiqlovchi hujjatlar nusxalarini topshirishi lozim. Taqdim etilgan hujjatlar amaldagi qonunchilik va ushbu ommaviy oferta talablariga to‘g‘ri kelmagan taqdirda, Bank Mijoz buyurtmasini ijro etishni rad etishga haqli.\n" +
    "\n" +
    "4.6.4. Mijoz sotib olingan valyuta mablag‘larini pul mablag‘lari valyuta hisobvarag‘iga kirim qilingan paytdan boshlab 7 (etti) bank ish kuni ichida ishlatishi lozim.\n" +
    "4.6.5. Chet el valyutasidagi maxsus hisobvaraqdan avval chiqim qilingan va ishlatilmagan, shuningdek boshqa sabablarga ko‘ra qaytib kelgan chet el valyutasi (3) uch bank ish kuni ichida Bankka qayta sotilishi yoki buyurtmada ko‘rsatilgan maqsadlar uchun qayta ishlatilishi lozim.\n" +
    "4.6.6. Ushbu ommaviy ofertaga qo‘shilish orqali Mijoz Bankka o‘zining har qanday hisobvarag‘idan, buyurtmada ko‘rsatilgan chet el valyutasiga ekvivalent miqdordagi mablag‘ni akseptsiz undirilishiga so‘zsiz va qaytarib olinmaydigan roziligini bildiradi.\n" +
    "4.6.7. Agar Mijoz 4.6.2-bandning birinchi xatboshisi shartlarini bajarmasa, Mijoz Bankka o‘zining har qanday hisobvarag‘idan Bank ko‘rishi mumkin bo‘lgan zararlarning akseptsiz undirilishiga so‘zsiz va qaytarib olinmaydigan roziligini bildiradi (shu jumladan, lekin sotib olish va sotish valyuta kurslari o‘rtasidagi salbiy farq bilan cheklanmagan holda).\n" +
    "4.6.8. Mijoz quyidagi shartlardan biri sodir bo‘lgan taqdirda, buyurtmani bekor qilishi lozim:\n" +
    "    • buyurtmada sotib olish maqsadlari o‘zgarganda;\n" +
    "    • vakolatli organlarning qarorlariga binoan hisobvaraq xatlanganda.\n" +
    "Bank bilan o‘zaro hisob-kitoblarni amalga oshirish uchun mablag‘lar o‘z vaqtida taqdim etilmagan taqdirda, Bank buyurtmani mustaqil ravishda bekor qilishga haqli. Yuqorida keltirilgan holatlar yuz berganda va Mijoz Bankka xabar bermagan taqdirda, Bank buyurtmani akseptsiz mustaqil ravishda bekor qilishga va valyuta mablag‘larini qaytarib sotishga haqli.\n" +
    "4.7. Ushbu ommaviy ofertaning 4-bo‘limiga muvofiq chet el valyutasini sotib olishda:\n" +
    "4.7.1. Bank Mijozning taqdim etgan buyurtmasini hujjatlar qabul qilingan kundan keyingi bank ish kunidan kechiktirmagan holda ko‘rib chiqishi lozim.\n" +
    "4.7.2. Amaldagi qonunchilikning o‘rnatilgan talablariga mos kelmasligi sababli buyurtmani ijro etish imkonsiz bo‘lsa, Bank bu haqda Mijozga yozma ravishda xabar berishi lozim.\n" +
    "4.7.3. Bank chet el valyutasini sotib olish bo‘yicha ijobiy qaror qabul qilingan buyurtmalarni 2 (ikki) bank ish kunidan kechiktirmagan holda ijro etishi va barcha hisob-kitoblarni amalga oshirishi lozim.\n" +
    "4.7.4. Bank avval Mijoz maxsus valyuta hisobvaraqlaridan chiqim qilingan va ushbu hisobvaraqlarga ishlatilmaganligi yoki qaytib kelganligi sababli kirim qilingan Mijoz tomonidan sotib olingan chet el valyutasidagi mablag‘larni 3 (uch) bank ish kunida akseptsiz qaytarib sotish amaliyotini amalga oshiradi.\n" +
    "4.7.5. Mijoz tomonidan sotib olingan chet el valyutasi Mijozning maxsus valyuta hisobvarag‘iga kirim qilingan kundan boshlab 7 (etti) bank ish kuni mobaynida Mijoz tomonidan ishlatilmagan taqdirda, Bank chet el valyutasidagi mablag‘larni akseptsiz sotish amaliyotini amalga oshiradi. Sotuv ushbu muddat tugagandan keyingi bank ish kunidan kechiktirmagan holda amalga oshirilishi lozim.\n" +
    "4.7.6. Mijozning yozma murojaatiga binoan konvertatsiya buyurtmasi bekor qilingan taqdirda, Bank 4.6.7-bandga muvoviq choralar ko‘radi.\n" +
    "\n" +
    "4.7.7. Mijozning mablag‘lari sotib olingan chet el valyutasi uchun to‘lovni amalga oshirishda yetarli bo‘lmagan taqdirda, Bank Mijozning har qanday hisobvarag‘idan sotib olingan chet el valyutasiga ekvivalent bo‘lgan miqdorni akseptsiz undirish huquqini o‘zida saqlab qoladi.\n" +
    "\n" +
    "5. BIR TURDAGI CHET EL VALYUTASINI BOSHQA TURDAGI CHET EL  VALYUTASIGA KONVERTATSIYA QILISH TARTIBI\n" +
    "\n" +
    "5.1. Konvertatsiyani amalga oshirish uchun Mijoz Bankka konvertatsiya uchun buyurtma (1-ilova) yoki chet el valyutasida o‘tkazma uchun ariza (3-ilova) taqdim etadi.\n" +
    "5.2. Konvertatsiya Bank konvertatsiya qilish imkoniyatiga ega bo‘lgan erkin konvertatsiya qilinadigan chet el valyutalarida amalga oshiriladi.\n" +
    "5.3. Konvertatsiya uchun buyurtma yoki o‘tkazma uchun ariza Bank tomonidan qabul qilingan kundan keying kundan boshlab, agar buyurtma/ariza ijro etiladigan kun bitimda qatnashadigan valyutalar mamlakatlarida rasmiy ta’til kuni bo‘lmasa, 2 (ikki) bank ish kuni ichida Bank tomonidan ijro etiladi.\n" +
    "5.4. Konvertatsiya Mijozning konvertatsiya uchun buyurtmasi yoki o‘tkazma uchun arizasi asosida Bank marjasini o‘z ichiga olgan Bank valyuta kursi bo‘yicha amalga oshiriladi.\n" +
    "5.5. Konvertatsiya amalga oshirilayotganda va konvertatsiya uchun buyurtma yoki o‘tkazma uchun ariza ijro etilgunga qadar, Mijoz konvertatsiya qilish uchun o‘zining Bankdagi hisobvarag‘ini yetarlicha chet el valyutasidagi mablag‘lar bilan ta’minlashi lozim.\n" +
    "5.6. Bankning xizmat uchun marjasi Bank tariflari bilan belgilanadi.\n" +
    "5.7. Bank xizmatlari uchun to‘lov Mijoz hisobvarag‘i yoki hisobvaraqlaridan kontrvalyutaning (sotilgan valyutaning) kerakli miqdorini Bank tomonidan akseptsiz chiqim qilish yo‘li bilan amalga oshiriladi.\n" +
    "5.8. Konvertatsiya uchun buyurtma yoki o‘tkazma uchun arizani imzolash yoki ushbu buyurtma/arizalarni MXD da avtorizatsiya qilish yo‘li bilan, Mijoz Bankka Bankning tasdiqlangan tariflariga muvofiq, Mijozning qo‘shimcha roziligisiz, Mijozning Bankda ochilgan hisobvaraqlaridan, konvertatsiyani amalga oshirish uchun kerakli mablag‘ miqdorini yechib olish huquqini beradi.\n" +
    "\n" +
    "6. TOMONLARNING HUQUQ VA MAJBURIYATLARI\n" +
    "\n" +
    "6.1. Mijoz tomonidan buyurtma/ariza noto‘g‘ri to‘ldirilgan taqdirda, Bank konvertatsiyani amalga oshirishni rad etish huquqini o‘zida saqlab qoladi.\n" +
    "6.2. Buyurtma/ariza matnidagi Mijoz aybiga ko‘ra xatolar va noto‘g‘ri nashrlar uchun Bank javobgar emas. Mijoz buyurtma/arizada ko‘rsatilgan ma’lumotlarning to‘g‘riligi uchun javobgar hisoblanadi.\n" +
    "6.3. Bank transaksiya boshqa ishtirokchilarining bevaqt va / yoki nomuvofiq xatti-harakatlari uchun, shuningdek, transaksiya O‘zbekiston Respublikasi qonunchiligiga muvofiq amalga oshirilmaganligi uchun javobgar emas.\n" +
    "6.4. Bank ushbu ommaviy ofertada ko‘zda tutilgan majburiyatlarni o‘z vaqtida va to‘liq bajarilishini o‘z zimmasiga oladi.\n" +
    "6.5. Bank Mijoz tomonidan amalga oshiriladigan operatsiyalar bilan bog‘liq masalalar bo‘yicha kerakli tushuntirishlar, hujjatlar va ma’lumotlarni olish huquqiga ega.\n" +
    "6.6. Bank har qanday buyurtma/arizani to‘liq yoki qisman bajarilishini rad etish huquqiga ega.\n" +
    "6.8. Bank quyidagi hollarda xizmat ko‘rsatishni bir tomonlama ravishda to‘xtatib qo‘yishga haqlidir:\n" +
    "A) Mijoz tomonidan konvertatsiya uchun buyurtma yoki o‘tkazma uchun arizani noto‘g‘ri to‘ldirilganligi;\n" +
    "B) Mijoz tomonidan ushbu ommaviy ofertada ko‘zda tutilgan majburiyatlarning nomuvofiq bajarilishi;\n" +
    "C) Bank tomonidan ommaviy oferta shartlariga yoki O‘zbekiston Respublikasining amaldagi qonunchiligiga mos kelmaydigan transaksiyalarning aniqlanishi;\n" +
    "D) ushbu ommaviy ofertaga kiritilgan qo‘shimcha shartlar va o‘zgartirishlarning Mijoz tomonidan qabul qilinmasligi;\n" +
    "E) amalga oshirilayotgan transaksiyalarda jinoiy faoliyatdan olingan daromadlarni legallashtirish va terrorizmni moliyalashtirish bilan bog‘liq bo‘lishi mumkin bo‘lgan shubhaning mavjudligi.\n" +
    "6.8. Bank tariflarni o‘zgartirish, ushbu oferta shartlariga o‘zgartirish va qo‘shimchalar kiritish huquqiga ega. Shu bilan birga, Bank tarifdagi o‘zgartirishlar to‘g‘risida Mijozni, o‘zgartirishlar kuchga kirgunga qadar 10 (o‘n) bank ish kunidan kechiktirmay, Bank bo‘limlari stendlari va Bankning www.kdb.uz vebsaytida kiritilayotgan o‘zgartirish va qo‘shimchalar to‘g‘risidagi ma'lumotlarni joylashtirish orqali xabardor etadi.\n" +
    "6.9. Agar Mijoz tomonidan 10 kun ichida Bankning tariflarga va ushbu ommaviy oferta shartlariga o‘zgartirishlar / qo‘shimchalar kiritishiga qarshi e’tirozlar bildirilmasa, ushbu o‘zgartirishlar / qo‘shimchalar Mijoz tomonidan qabul qilingan hisoblanadi. Agar Mijoz tariflarga va ushbu ommaviy oferta shartlariga kiritilgan o‘zgartirishlar / qo‘shimchalarga rozi bo‘lmasa, bunday o‘zgartirishlar / qo‘shimchalar kuchga kirgunga qadar Mijoz yozma ravishda xabar berish orqali shartnomani bekor qilishga haqli.\n" +
    "6.10. Mijoz Bank talabiga binoan Mijoz tomonidan amalga oshirilgan operatsiyalar bilan bog‘liq masalalar bo‘yicha kerakli tushuntirishlar, hujjatlar va ma’lumotlarni taqdim etishi lozim.\n" +
    "6.12. Mijoz ushbu ommaviy ofertada belgilangan tartibda amaldagi Bank tariflariga muvofiq xizmatlar uchun to‘lashi lozim, buning uchun sotib olingan valyutani to‘lash uchun hisobvaraqda yoki hisobvaraqlarda etarlicha mablag‘ (kontrvalyutada / sotilgan valyutada / milliy valyutada) saqlashi lozim.\n" +
    "6.12. Mijoz, zaruriyat bo‘lganda, konvertatsiya uchun buyurtma yoki o‘tkazma uchun arizani Bankka taqdim etgan holda, Bank xizmatlaridan foydalanish huquqiga ega.\n" +
    "\n" +
    "\n" +
    "7. FORS MAJOR XOLATLAR\n" +
    "\n" +
    "7.1. Tomonlar ushbu ommaviy oferta bo‘yicha majburiyatlarni fors major holatlar (tabiiy ofatlar, ish tashlashlar, fuqarolik tartibsizliklari, urushlar yoki Tomonlarning nazorati ostida bo‘lmagan boshqa holatlar, jumladan, O‘zbekiston Respublikasi qonunchiligidagi o‘zgarishlar yoki O‘zbekiston Respublikasi Markaziy banki tomonidan me’yoriy hujjatlar / yo‘riqnomalar nashr etilishi / qabul qilinishi) yuzaga kelishi sababli lozim darajada barajish imkoniyati bo‘lmaganligi uchun bajarmaganliklari yoki lozim darajada bajarmaganliklari uchun javobgar hisoblanmaydi.\n" +
    "7.2. Bank quyidagi hollarda javobgar bo‘lmaydi:\n" +
    "7.2.1. Banklararo elektron to‘lov tizimida nosozliklar yuz berganda;\n" +
    "7.2.2. Qonunda belgilangan tartibda Bank yoki Mijozning hisobvaraqlaridagi transaksiyalar hibsga olinganda va / yoki to‘xtatib turilganda va / yoki muzlatilganda;\n" +
    "7.2.3. Agar Bank o‘zining nazorati ostida bo‘lmagan sabablarga ko‘ra va fors major holatlar yuzaga kelganda, ommaviy oferta bo‘yicha o‘z majburiyatlarini bajara olmasa;\n" +
    "7.3. Bank tomonidan nazorat qilinmaydigan holatlar sababli buyurtma/arizalar qondirilmaganligi uchun Bank javobgar hisoblanmaydi.\n" +
    "\n" +
    "\n" +
    "8. MAXFIYLIK\n" +
    "\n" +
    "8.1. Tomonlar, ommaviy ofertaga binoan, ushbu ommaviy ofertani tuzish va uni ijro etish natijasida o‘zlariga ma’lum bo‘lgan maxfiy ma’lumotlarni sir tutish va hech qanday tarzda uchinchi tomonlarga oshkor qilmaslik majburiyatini oladi.\n" +
    "8.2. Tomonning ushbu ommaviy ofertaga qo‘shilishidan oldin olgan maxfiy ma’lumotlari, boshqa manbalardan qonuniy yo‘llar bilan olingan ma’lumotlar, shuningdek Tomonlarning amaldagi qonunchiligiga muvofiq maxfiy deb tasniflanmagan ma’lumotlar maxfiy ma’lumotlar sifatida tan olinmaydi.\n" +
    "8.3. Agar maxfiy ma’lumotlarni taqdim etishni talab qilish huquqi qonunda nazarda tutilgan vakolatli davlat organining talabiga binoan maxfiy ma’lumotlarni taqdim etish yoki oshkor qilish zaruriyati tug‘ilsa, Tomon ushbu ma’lumotlarni taqdim etgan Tomonni yozma yoki og‘zaki ravishda xabardor etishi lozim.\n" +
    "8.4. Tomonlar:\n" +
    "8.4.1. maxfiy ma’lumotlarning saqlanishini ularni uchinchi tomonlarning ruxsatsiz olishini cheklagan holda ta’minlashi lozim;\n" +
    "8.4.2. maxfiy ma’lumotlarni to‘liq yoki qisman uchinchi tomonlarga berishni cheklashi lozim;\n" +
    "8.4.3. ommaviy axborot vositalarida maxfiy ma’lumotlarni nashr qilmaslik va boshqa biror usul bilan cheklanmagan miqdordagi shaxslarga maxfiy ma’lumotlarning mazmunini oshkor qilmasligi lozim;\n" +
    "8.4.4. maxfiy ma’lumotlarga Tomonlarning faqatgina tegishli vakolatlarga ega shaxslariga ruxsat berishi lozim;\n" +
    "8.4.5. ushbu ommaviy oferta doirasida olingan boshqa Tomonning maxfiy ma’lumotlariga ega bo‘lgan shaxslarning hisobini olib borish;\n" +
    "8.4.6. maxfiy ma’lumotlarni himoya qilish bo‘yicha Tomonlarning qonunchiligida ko‘zda tutilgan boshqa majburiyatlarni bajarish.\n" +
    "\n" +
    "9. BOSHQA SHARTLAR\n" +
    "\n" +
    "9.1. Ommaviy oferta konvertatsiya uchun buyurtma yoki o‘tkazma uchun ariza imzolangan va Bankka taqdim etilgan paytdan yoki ushbu buyurtma/arizalar MXD da avtorizatsiya qilingan paytdan boshlab kuchga kiradi.\n" +
    "9.2. Ommaviy ofertaning amal qilish muddati cheklanmagan.\n" +
    "9.3. Har safar konvertatsiya uchun buyurtma yoki o‘tkazma uchun ariza Bankka taqdim etilganda yoki buyurtma/arizalar MXD da avtorizatsiya qilinganda, Mijoz ushbu ommaviy ofertaga qo‘shiladi.\n" +
    "9.4. Ushbu ommaviy oferta quyidagi hollarda bekor qilinishi mumkin:\n" +
    "    • agar Mijoz Bank tariflari va  ommaviy oferta shartlariga kiritilgan  o‘zgartirish va qoshimchalardan bosh tortsa;\n" +
    "    • O‘zbekiston Respublikasi qonunchiligida belgilangan tartibda.\n" +
    "9.5. Ushbu ommaviy oferta kuchga kirgan sanadan boshlab, Tomonlar o‘rtasida konvertatsiya xizmatlarini ko‘rsatish bo‘yicha avvalgi barcha kelishuvlar va shartnomalar bekor qilingan hisoblanadi.\n" +
    "9.6. Tomonlar o‘zaro kelishmovchiliklarni muzokaralar yo‘li bilan hal qilish uchun barcha choralarni ko‘rishadi. Agar kelishuvga erishilmasa, ushbu ommaviy oferta bo‘yicha barcha nizolar Toshkent shahar tumanlararo Iqtisodiy sudida hal qilinadi.\n" +
    "9.7. Biror bir Tomon ushbu ommaviy oferta bo‘yicha o‘z huquqlari va majburiyatlarini boshqa Tomonning oldindan yozma roziligisiz uchinchi tomonlarga o‘tkazish yoki berish huquqiga ega emas.\n" +
    "9.8. Ushbu ommaviy oferta bilan to‘g‘ridan to‘g‘ri tartibga solinmagan boshqa barcha holatlarda Tomonlar O‘zbekiston Respublikasining amaldagi qonunchiligiga asoslanadi.\n" +
    "9.9. Ommaviy oferta o‘zbek va ingliz tillarida tuzilgan. Biror kelishmovchilik yoki nizo yuzaga kelganda, ommaviy ofertaning o‘zbek tilidagi matni ustunlik qiladi.\n" +
    "\n" +
    "\n" +
    "\n",

  // Conversion
  CONVERSION_MAIN_HEADER_TITTLE_UZS_TO_FCY_PRINT_TABLE: "KONVERTATSIYA UCHUN BUYURTMA",
  CONVERSION_NAME_OF_APPLICANT_UZS_TO_FCY_PRINT_TABLE: "Buyurtma beruvchining nomi:",
  CONVERSION_TAX_ID_UZS_TO_FCY_PRINT_TABLE: "STIRi:",
  CONVERSION_PHONE_UZS_TO_FCY_PRINT_TABLE: "Ma’lumotlari: Telefon raqami",
  CONVERSION_PURPOSE_UZS_TO_FCY_PRINT_TABLE:
    "Sotib olinayotgan valyutaning foydalanish maqsadi (tagiga chizib, shartnomaning raqami va sanasi ko’rsatilsin):",
  CONVERSION_A_UZS_TO_FCY_PRINT_TABLE:
    "a) uskunalar importi uchun (shartnomaning raqami va sanasi);",
  CONVERSION_B_UZS_TO_FCY_PRINT_TABLE: "b) ehtiyot qismlar importi;",
  CONVERSION_C_UZS_TO_FCY_PRINT_TABLE: "c) xomashyo va materiallar importi;",
  CONVERSION_D_UZS_TO_FCY_PRINT_TABLE: "d) xizmatlar importi;",
  CONVERSION_E_UZS_TO_FCY_PRINT_TABLE: "e) iste’mol tovaralarining importi;",
  CONVERSION_F_UZS_TO_FCY_PRINT_TABLE: "f) chet el valyutasidagi kreditlarga xizmat ko’rsatish;",
  CONVERSION_G_UZS_TO_FCY_PRINT_TABLE:
    "g) lizing shartnomalari bo’yicha lizing to’lovlarni amalga oshirish;",
  CONVERSION_H_UZS_TO_FCY_PRINT_TABLE:
    "h) investorning foydasi, dividendlari va boshqa daromadlarini repatriatsiya qilish;",
  CONVERSION_I_UZS_TO_FCY_PRINT_TABLE:
    "i) belgilangan aviakompaniyalar xarajatlaridan ortiqcha daromadlarni repatriatsiya qilish;",
  CONVERSION_J_UZS_TO_FCY_PRINT_TABLE: "j) xizmat safar xarajatlarini to’lash;",
  CONVERSION_K_UZS_TO_FCY_PRINT_TABLE:
    "k) tovar birjalarining ochiq elektron savdolarida mahsulot sotishdan olingan mablag’larni o’tkazish;",
  CONVERSION_L_UZS_TO_FCY_PRINT_TABLE:
    "l) boshqa maqsadlar (qaysiligi belgilansin) _________________________________________________",
  CONVERSION_NAME_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Sotib olinayotgan valyutaning miqdori va nomi",
  CONVERSION_MAX_PURCHASE__UZS_TO_FCY_PRINT_TABLE: "Sotib olishning maksimal kursi so‘mda",
  CONVERSION_TOTAL_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Valyuta kursi bo’yicha so’mda umumiy summa",
  CONVERSION_COVER_ON_UZS_TO_FCY_PRINT_TABLE: "(*) 22613 h/rda qoplama",
  CONVERSION_CONFIRM_UZS_TO_FCY_PRINT_TABLE: "Mavjud / Mavjud emas",
  CONVERSION_INSTRUCT_TO_DEBIT_UZS_TO_FCY_PRINT_TABLE:
    "Bitim tuzilgan taqdirda sotib olingan chet el valyutasining sotib olish valyuta kursi bo‘yicha milliy valyutadagi ekvivalentini 22613_______________ hisobraqamimizdan yechib olishingizni so‘raymiz.",
  CONVERSION_ATTACHED_DOC_UZS_TO_FCY_PRINT_TABLE: "Quyidagi hujjatlar ilova qilinmoqda:",
  CONVERSION_PUBLIC_OFFER_UZS_TO_FCY_PRINT_TABLE:
    "⃝ Ushbu buyurtmaning imzolanishi Mijoz tomonidan ushbu buyurtma imzolangan sanada Bank vebsaytida (www.kdb.uz) nashr qilingan konvertatsiya xizmatlarini ko‘rsatish bo‘yicha ommaviy ofertaning barcha shartlari so‘zsiz qabul qilinganligini, shuningdek, Mijozning ko‘rsatilgan xizmatlar uchun Bank tariflariga va ushbu Arizaga muvofiq talab qilingan mablag‘larni to‘g‘ridan-to‘g‘ri chiqim qilishga rozilik bildirishini anglatadi.",
  CONVERSION_DIRECTOR_UZS_TO_FCY_PRINT_TABLE: "Korxona raxbari: ",
  CONVERSION_FULL_NAME_UZS_TO_FCY_PRINT_TABLE: "Ismi-sha’rifi",
  CONVERSION_CHIEF_ACCOUNTANT_UZS_TO_FCY_PRINT_TABLE: "Bosh hisobchi:",
  CONVERSION_RESPONSIBLE_UZS_TO_FCY_PRINT_TABLE: "Filial VNBning ma’sul xodimi",
  CONVERSION_HEAD_UZS_TO_FCY_PRINT_TABLE: "Filial VNBning Boshlig‘i",
  CONVERSION_HEAD_OF_BRANCH_UZS_TO_FCY_PRINT_TABLE: "Filial Boshqaruvchisi",
  CONVERSION_HEAD_OF_THE_HO_UZS_TO_FCY_PRINT_TABLE: "Bosh Ofis VNBning Boshlig‘i",
  CONVERSION_DEPUTY_UZS_TO_FCY_PRINT_TABLE: "Rais o‘rinbosari",

  ENGLISH_LANGUAGE: "English",
  RUSSIAN_LANGUAGE: "Russian",
  UZBEK_LANGUAGE: "Uzbek",
  SHORT_EN_LANGUAGE: "EN",
  SHORT_RU_LANGUAGE: "RU",
  SHORT_UZ_LANGUAGE: "UZ",

  //Months
  MONTH_MAY_TITLE: "May",
  MONTH_JUN_TITLE: "Iyun",
  MONTH_JULY_TITLE: "Iyul",
  MONTH_MARCH_TITLE: "Mart",
  MONTH_APRIL_TITLE: "Aprel",
  MONTH_AUGUST_TITLE: "Avgust",
  MONTH_JANUARY_TITLE: "Yanvar",
  MONTH_OCTOBER_TITLE: "Oktabr",
  MONTH_FEBRUARY_TITLE: "Fevral",
  MONTH_NOVEMBER_TITLE: "Noyabr",
  MONTH_DECEMBER_TITLE: "Dekabr",
  MONTH_SEPTEMBER_TITLE: "Sentabr",

  // Forms
  FORMS_REQUIRED_FIELD_ERROR_TITLE: "Talab qilingan",
  FORMS_PASSWORDS_NOT_MUCH_FIELD_ERROR_TITLE: "Parollar mos kelmadi",
  WRONG_CURRENCY_TYPE: "Valyuta turi yaroqsiz",

  // Auth Route
  AUTH_TAB_CORPORATE_TITLE: "Korporativ Mijoz",
  AUTH_TAB_INDIVIDUAL_TITLE: "Jismoniy Shaxs",
  AUTH_TAB_FINANCIAL_TITLE: "Moliya Instituti",

  AUTH_USER_ID_TITLE: "Foydalanuvchi Nomi",
  AUTH_LOGIN_TITLE: "Login",
  AUTH_BACK_BUTTON_TITLE: "Orqaga",
  AUTH_PASSWORD_TITLE: "Parol",
  AUTH_LOGIN_BUTTON_TITLE: "Kirish",
  AUTH_LOGO_TITLE: "KDB Bank O’zbekistan",
  AUTH_LOGO_SUB_TITLE: "Internet Banking iDBA",
  AUTH_FORM_LINK_TITLE: "Internet Banking Ommaviy Oferta",
  AUTH_SECURITY_TITLE:
    "Sizning akkauntingiz yoki parolingiz haqidagi ma’lumotlarni so’ragan Epochta xabarlariga javob bermang va ular taklif qilgan havolalarga ham kirmang.",
  AUTH_TOKEN_FIELD_LABEL_TITLE: "E-Token",
  AUTH_CHANGE_PASSWORD_TITLE: "Iltimos, xavfsizlik maqsadida parolingizni o'zgartiring",
  AUTH_RESET_PASSWORD_BUTTON_TITLE: "Parolni tiklash",
  AUTH_FORGOT_PASSWORD_LINK_TITLE: "Parolni unutdingizmi",
  AUTH_CHANGE_PASSWORD_BUTTON_TITLE: "Parolni o’zgartirish",
  AUTH_RESET_FORM_ENTER_LOGIN_FIELD_LABEL_TITLE: "Ro’yxatdan o’tgan Epochta manzili",
  AUTH_INTERNET_BANKING_LINK_TITLE: "Internet Banking Ommaviy Oferta",
  AUTH_VIEW_ONLY_CHECKBOX_TITLE: "Faqat ko’rish uchun (OTP kalitisiz)",
  AUTH_VIEW_ONLY_WITH_CHECKBOX_TITLE: "OTP kaliti yordamida chet el valyutasida pul o'tkazish",
  AUTH_CHANGE_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Yangi Parol",
  AUTH_CHANGE_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Joriy Parol",
  AUTH_RESET_PASSWORD_TITLE: "Vaqtinchalik parol olish uchun quyidagilarni to’ldiring",
  AUTH_CHANGE_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Yangi Parolni tasdiqlash",
  AUTH_RESET_FORM_CHECK_OTP_KEY_FIELD_LABEL_TITLE: "OTP kalitni tekshirish",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_1:
    "Foydalanuvchi nomi va parolingiz maxfiy hisoblanadi va ularni hech kimga oshkor qilmaslik kerak.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_2:
    " Sizning akkauntingiz yoki parolingiz haqidagi ma’lumotlarni so’ragan Epochta xabarlariga javob bermang va ular taklif qilgan havolalarga ham kirmang.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_3:
    "Hisobingizni muntazam ravishda tekshirib turing, agar hisob bo’yicha biror farqni ko’rsangiz, darhol Bankka xabar bering.",

  OTP_EXPIRED_SESSION_SCREEN_TITLE: "Sessiya muddati tugadi",

  WRONG_OTP_KEY: "OTP avtorizatsiyasi amalga oshmadi (1 daqiqadan so’ng qayta urinib ko’ring)",

  // Side Menu
  SIDE_MENU_MAIN_TITLE: "Bosh sahifa",
  SIDE_MENU_LOAN_TITLE: "Kredit",
  SIDE_MENU_YEISVO_TITLE: "YEISVO",
  SIDE_MENU_ACCOUNTS_TITLE: "Hisobvaraqni Yuritish",
  SIDE_MENU_TRANSFERS_TITLE: "O’tkazmalar",
  SIDE_MENU_ANALYTICS_TITLE: "Analitika",
  SIDE_MENU_CONVERSION_TITLE: "Ayirboshlash",
  SIDE_MENU_CONTACT_US_TITLE: "Bizga murojaat qilish",
  SIDE_MENU_AGREEMENTS_TITLE: "Shartnomalar va Formalar",
  SIDE_MENU_REFERENCE_TITLE: "Ma’lumotlar",
  SIDE_MENU_HELP_AND_FAQ_TITLE: "Savol va javoblar",
  SIDE_MENU_NOTIFICATIONS_TITLE: "Bildirishnoma",
  SIDE_MENU_CLIENT_FEEDBACK_TITLE: "Mijoz Fikri",
  SIDE_MENU_WITHDRAWAL_TITLE: "Kredit mablag’larini yechib olish",
  SIDE_MENU_PENDING_APPROVALS_TITLE: "Tasdiqlash Kutilmoqda",
  SIDE_MENU_USER_INFO_AND_SETTINGS_TITLE: "Foydalanuvchi Ma’lumotlari va Sozlamalar",
  SIDE_MENU_PRE_APPROVAL_TITLE: "Yangi kredit va akkreditivga Arizani oldindan tasdiqlash",
  SIDE_MENU_REQUESTS_TITLE:
    "NIKI Tizimida jismoniy shaxsning krediti va akkreditiv Arizasini ro’yxatdan o’tkazish uchun so’rovlar.",
  SIDE_MENU_PAYMENT_CLAIM_TITLE: "To'lov So'rovnomasi/",
  SIDE_MENU_COLLECTION_ORDER_TITLE: "Inkasso buyurmasi",

  SIDE_MENU_EXPAND_BUTTON_TITLE: "Kengaytirish",
  SIDE_MENU_COLLAPSE_BUTTON_TITLE: "Kichiklatish",

  // trade finance

  TRADE_FINANCE_HEADER_TITLE: "Akkreditivlar ro'yxati",
  TRADE_FINANCE_CREDITS_HEADER_TITLE: "Akkreditivlar",
  SIDE_MENU_TRADE_FINANCE_TITLE: "Savdo Moliyasi",
  TRADE_FINANCE_CONTRACTS_TABLE_APP_NO_COLUMN_TITLE: "Ilova №",
  TRADE_FINANCE_CONTRACTS_TABLE_LC_NO_COLUMN_TITLE: "Akkreditiv raqami",
  TRADE_FINANCE_CONTRACTS_TABLE_APPLICANT_COLUMN_TITLE: "Ariza beruvchi",
  TRADE_FINANCE_CONTRACTS_TABLE_BENEFICIARY_COLUMN_TITLE: "Benefisiar",
  TRADE_FINANCE_CONTRACTS_TABLE_CURRENCY_COLUMN_TITLE: "Valyuta",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdori",
  TRADE_FINANCE_CONTRACTS_TABLE_DATE_COLUMN_TITLE: "Sana",
  TRADE_FINANCE_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_APP_BUTTON_TITLE: "Yangi ilova",
  TRADE_FINANCE_CONTRACTS_TABLE_BACK_BUTTON_TITLE: "Orqaga",
  TRADE_FINANCE_SUBMIT_BUTTON_TITLE: "Yuborish",
  TRADE_FINANCE_CONTRACTS_TABLE_ADVISING_BANK_TITLE: "Maslahat berish banki",
  TRADE_FINANCE_CONTRACTS_TABLE_ACTIONS_COLUMN_TITLE: "Actions",
  TRADE_FINANCE_CONTRACTS_TABLE_DESCREPENCY_COLUMN_TITLE: "Nomuvofiqlik",
  TRADE_FINANCE_CONTRACTS_TABLE_MODIFY_COLUMN_TITLE: "Modify",
  TRADE_FINANCE_CONTRACTS_TABLE_SUBMITED_COLUMN_TITLE: "Yuborilgan",
  TRADE_FINANCE_DISCREPANCY_LETTER: "Nomuvofiqlik xati",
  ACCEPT_DISCREPANCIES_YES_NO: "Nomuvofiqliklarni qabul qilish",
  TRADE_FINANCE_ACCEPTED_AMOUNT: "Qabul qilingan qiymat",
  TRADE_FINANCE_DISCREPANCY_MENU_MODAL_LETTER: "Discrepency Menu",
  TRADE_FINANCE_CONTRACTS_TABLE_AMENDMENT_COLUMN_TITLE: "Shartlarga o'zgartirishlar",
  TRADE_FINANCE_CONTRACTS_FORM_NUMBER: "Raqam",
  TRADE_FINANCE_CONTRACTS_FORM_DATA: "Data",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_NAME: "Aplikant nomi",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_ADDRESS: "Aplikant manzili",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_POST_CODE: "Aplikant pochta indeksi",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_PHONE: "Aplikant telefon raqami",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_NAME: "Benefitsiar nomi",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_ADDRESS: "Benefitsiar manzili",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_POST_CODE: "Benefitsiar pochta indeksi",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_PHONE: "Benefitsiar telefon raqami",
  TRADE_FINANCE_CONTRACTS_FORM_TO: "Bank",
  TRADE_FINANCE_CONTRACTS_FORM_DATA_OF_EXPIRY: "Amal qilish muddati",
  TRADE_FINANCE_CONTRACTS_FORM_PLACE_OF_EXPIRY: "Amal qilish hududi",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT: "Yuk junatish so'ngi sanasi",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT_OR__SHIPMENT_PERIOD:
    "Lates data of shipment or Shipment period",
  TRADE_FINANCE_CONTRACTS_FORM_SHIPMENT_PERIOD: "Yuk junatish muddati",
  TRADE_FINANCE_CONTRACTS_FORM_PLEASE_OPEN_LETTER_OF_CREDIT:
    "Iltimos, akkreditivni ishlab chiqing:",
  TRADE_FINANCE_CONTRACTS_FORM_IRREVOCABLE: "Qaytarib olinmaydigan",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSFERABLE: "O'tkaziladigon akkredetiv",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTARY: "Hujjatli",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMED: "Tasdiqlangan",
  TRADE_FINANCE_CONTRACTS_FORM_ADVICE_BY: "Ma'lum qilish",
  TRADE_FINANCE_CONTRACTS_FORM_CABLE_YES_OR_NO: "Simli aloqa",
  TRADE_FINANCE_CONTRACTS_FORM_AIR_MAIL: "Havo pochtasi",
  TRADE_FINANCE_CONTRACTS_FORM_CURRENCY: "Pul birligi",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT: "Qiymat",
  TRADE_FINANCE_CONTRACTS_FORM_ADVISING_BANK: "Ma'lum qiluvchi bank",
  TRADE_FINANCE_CONTRACTS_FORM_TERMS_OF_PAYMET: "To'lov sharti",
  TRADE_FINANCE_CONTRACTS_FORM_AGAINST_PRESINTATION_DOCUMENTS: "Hujjatlarni taqdim etishga qarshi",
  TRADE_FINANCE_CONTRACTS_FORM_DEFEREND_PAYMENT: "Kechiktrilgan to'lov",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMING_BANK: "Tasdiqlovchi bank",
  TRADE_FINANCE_CONTRACTS_FORM_REIMBURSING_BANK: "Qoplab beruvchi bank",
  TRADE_FINANCE_CONTRACTS_FORM_CREDIT_AVAILABLE_WITH_BY: "Akkredetiv ijrosi bilan/orqali",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSSHIPMENT: "Yuk tashish",
  TRADE_FINANCE_CONTRACTS_FORM_PARTIAL_SHIPMENT: "Qisman yuk junatish",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSPORATION_FROM: "..dan yuk tashish",
  TRADE_FINANCE_CONTRACTS_FORM_TO_PLACE_OF_DELIVERY: "..ga/yuk yetib borish hududi",
  TRADE_FINANCE_CONTRACTS_FORM_DESCRIPTION_OF_COMMODITY_AND_OR_SERVICES:
    "Mol mulk va/yoki xizmatlar tavsifi",
  TRADE_FINANCE_CONTRACTS_FORM_DELIVERY_TERMS: "Yetkazib berish shartlari",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTS_REQUIRED: "Talab qilinadigan hujjatlar",
  TRADE_FINANCE_CONTRACTS_FORM_SPECIAL_INSTRUCTIONS: "Maxsus ko'rsatmalar",
  TRADE_FINANCE_CONTRACTS_FORM_PERIOD_FOR_PRESENTATION_OF_DOCUMENTS: "Hujjatlar taqdimoti muddati",
  TRADE_FINANCE_CONTRACTS_FORM_LC_ACCOUNT_COVERED_FROM: "Akkredetiv qoplanadigan hisob",
  TRADE_FINANCE_CONTRACTS_FORM_ACCOUNT_FOR_COMMISSIONS: "Xizmat haqi uchun hisob",
  TRADE_FINANCE_CONTRACTS_FORM_COMMENTS: "Izohlar",
  TRADE_FINANCE_FORM_SENDER_LABEL_TITLE: "Yuboruvchi",
  TRADE_FINANCE_FORM_BENEFICIARY_LABEL_TITLE: "Benefitsiar",
  TRADE_FINANCE_FORM_AMOUNTS_LABEL_TITLE: "Amount",
  TRADE_FINANCE_CONTRACTS_FORM_YES: "Ha",
  TRADE_FINANCE_CONTRACTS_FORM_NO: "Yo'q",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_IN_WORDS: "Miqdor so'zlarda",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_AMENDMENT_BUTTON_TITLE: "Yangi tuzatishlar arizasi",
  TRADE_FINANCE_CONTRACTS_FORM_NEW_AMOUNT: "Yangi miqdori",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_BANK_COLUMN_TITLE: "Bank tomonidan berilgan qiymat",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_CLIENT_COLUMN_TITLE: "Mijoz tomonidan berilgan qiymat",
  TRADE_FINANCE_CONTRACTS_FORM_CHANGING_CURRENCY: "O'zgartirish",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_INCREASE: "Ko'paytirish",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_DECREASE: "Kamaytirish",
  // Pending Approvals Page

  PENDING_APPROVALS_SALARY_TAB_TITLE: "Ish haqi",
  PENDING_APPROVALS_ACCOUNTS_TAB_TITLE: "Hisobvaraqlar",
  PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE: "So’mdagi O’tkazmalar",
  PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE: "Chet el valyutasida o’tkazmalar",
  PENDING_APPROVALS_CONVERSION_TAB_TITLE: "Ayirboshlash",
  PENDING_APPROVALS_CORPORATE_CARDS_TAB_TITLE: "Korporativ Kartalar",
  PENDING_APPROVALS_SENT_PAYMENT_CLAIMS_TAB_TITLE: "To’lov Talabnomasi",
  PENDING_APPROVALS_TRADE_FINANCE_TAB_TITLE: "Savdo Moliyasi",

  PENDING_APPROVALS_SELECT_REQUEST_TITLE: "So’rovni tanlash",
  PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE: "Bajaruvchi va Avtorizatorni ko’rsatish",

  PENDING_APPROVALS_ACCOUNT_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBJECT_COLUMN_TITLE: "Mavzu",
  PENDING_APPROVALS_ACCOUNT_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  PENDING_APPROVALS_ACCOUNT_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  PENDING_APPROVALS_ACCOUNT_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  PENDING_APPROVALS_ACCOUNT_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Yakunlangan Sana",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBMISSION_DATE_COLUMN_TITLE: "Topshirilgan Sana",

  PENDING_APPROVALS_ACCOUNT_NONE_STANDART_INQUIRIES: "Nostandart So’rovlar",
  PENDING_APPROVALS_ACCOUNT_STANDART_INQUIRIES: "Standart so’rovlar",
  PENDING_APPROVALS_ACCOUNT_NEW_ACCOUNT_OPENING: "Yangi hisobvaraq ochish",

  PENDING_APPROVALS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Jami",
  PENDING_APPROVALS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Maksimal Kurs",
  PENDING_APPROVALS_TRANSFERS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Ro’yxatdan o’tgan #",
  PENDING_APPROVALS_TRANSFERS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni Debitlash",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Sotib olish miqdorii",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Valyuta kurslari",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_CDU_RATE_COLUMN_TITLE: "UZS",
  PENDING_APPROVALS_TRANSFERS_TABLE_CREDIT_AMOUNT_COLUMN_TITLE: "Hisobvaraqni kreditlash",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Sotish miqdori",
  PENDING_APPROVALS_TRANSFERS_TABLE_MINIMUM_RATE_CODE_COLUMN_TITLE: "Minimal Kurs",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_CODE_COLUMN_TITLE: "Maximal Kurs",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Sotib olish valyutasi",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Sotish valyutasi",
  PENDING_APPROVALS_TRANSFERS_TABLE_COMMISSION_AMOUNT_COLUMN_TITLE: "Komissiya miqdori",

  PENDING_APPROVALS_SALARY_TABLE_AMOUNT_COLUMN_TITLE: "Miqdori",
  PENDING_APPROVALS_SALARY_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_SALARY_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_COLUMN_TITLE: "Reyestr",
  PENDING_APPROVALS_SALARY_TABLE_CARD_TYPE_COLUMN_TITLE: "Karta Turi",
  PENDING_APPROVALS_SALARY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  PENDING_APPROVALS_SALARY_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  PENDING_APPROVALS_SALARY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "To’lov Kodi",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Reyestr #",
  PENDING_APPROVALS_SALARY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Tranzit Hisobvarag’i",
  PENDING_APPROVALS_SALARY_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Tranzaksiya Sanasi",
  PENDING_APPROVALS_SALARY_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Yaratilgan Sana",

  PENDING_APPROVALS_APPLICATION_OPENING: "Ilovalar",
  PENDING_APPROVALS_AMENDMENT_OPENING: "Shartlarga o'zgartirishlar",
  PENDING_APPROVALS_DISCREPANCY_OPENING: "Nomuvofiqlik",

  TRANSFERS_SALARY_PAYROLL_TABLE_AMOUNT_COLUMN_TITLE: "Miqdori",
  TRANSFERS_SALARY_PAYROLL_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_SALARY_PAYROLL_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_COLUMN_TITLE: "Reyestr",
  TRANSFERS_SALARY_PAYROLL_TABLE_CARD_TYPE_COLUMN_TITLE: "Karta Turi",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  TRANSFERS_SALARY_PAYROLL_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_SALARY_PAYROLL_TABLE_PAYMENT_CODE_COLUMN_TITLE: "To’lov ID",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Reyestr #",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Tranzit Hiosbvarag’i",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Tranzaksiya Sanasi",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Yaratilgan Sana",

  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdori",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_COLUMN_TITLE: "Reyestr",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CARD_TYPE_COLUMN_TITLE: "Karta Turi",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_PAYMENT_CODE_COLUMN_TITLE: "To’lov Kodi",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Reyestr #",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Tranzit Hisobvarag’i",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Tranzaksiya Sanasi",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Yaratilgan Sana",

  PENDING_APPROVALS_CONVERSION_TABLE_TYPE_COLUMN_TITLE: "Hujjat Turi",
  PENDING_APPROVALS_CONVERSION_TABLE_STATUS_COLUMN_TITLE: "Status",
  PENDING_APPROVALS_CONVERSION_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  PENDING_APPROVALS_CONVERSION_TABLE_AMOUNT_COLUMN_TITLE: "Miqdori",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATOR_COLUMN_TITLE: "Bajaruvchi",
  PENDING_APPROVALS_CONVERSION_TABLE_CURRENCY_COLUMN_TITLE: "Valyuta",
  PENDING_APPROVALS_CONVERSION_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  PENDING_APPROVALS_CONVERSION_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  PENDING_APPROVALS_CONVERSION_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni Kreditlash",
  PENDING_APPROVALS_CONVERSION_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Benefitsiar Bank Kodi",
  PENDING_APPROVALS_CONVERSION_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Hujjat Raqami",
  PENDING_APPROVALS_CONVERSION_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Tranzaksiya Sanasi",
  PENDING_APPROVALS_CONVERSION_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Yuboruvchining Hisobvarag’i",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATED_DATE_AND_TIME_COLUMN_TITLE: "Yaratilgan Sanasi",
  PENDING_APPROVALS_CONVERSION_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Benefitsiar Hisobraqami",
  PENDING_APPROVALS_CONVERSION_TYPE_COLUMN_TITLE: "Konversiya Turi",

  // Pending Approvals Page

  ACCOUNTS_INQUIRY_TAB_TITLE: "So’rov",
  ACCOUNTS_ACCOUNTS_TAB_TITLE: "Hisobvaraqlar",
  ACCOUNTS_STATEMENT_TAB_TITLE: "Ko’chirma",
  ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE: "Yangi Hisobvaraq Ochish",
  ACCOUNTS_OUTGOING_TAB_TITLE: "Сhiquvchi Swift",
  ACCOUNTS_INCOMING_TAB_TITLE: "Kiruvchi Swift",
  ACCOUNTS_INCOMING_COVER_TAB_TITLE: "Qoplangan kiruvchi Swift xabarlari",
  ACCOUNTS_REQUEST_NEW_ACCOUNT_OPENING_TAB_TITLE: "Yangi hisobvarq ochish uchun so’rov",

  ACCOUNTS_ACCOUNTS_BALANCE_AS_OF_TITLE: "Qoldiq",
  ACCOUNTS_ACCOUNTS_EXCLUDE_ZERO_BALANCE_CHECKBOX_TITLE: "Nol qoldiqni chiqarib tashlash",
  ACCOUNTS_ACCOUNTS_INCLUDE_CLOSED_ACCOUNTS_CHECKBOX_TITLE:
    "Yopilgan hisobvaraqlarni inobatga olish",

  ACCOUNTS_ACCOUNTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  ACCOUNTS_ACCOUNTS_TABLE_INQUIRY_COLUMN_TITLE: "So’rov",
  ACCOUNTS_ACCOUNTS_TABLE_BALANCE_COLUMN_TITLE: "Hisob raqam balansi",
  ACCOUNTS_ACCOUNTS_TABLE_CURRENCY_COLUMN_TITLE: "Valyuta",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NAME_COLUMN_TITLE: "Hisobvaraq Nomi",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Hisobraqam",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_OPENING_DATE_COLUMN_TITLE: "Hisobvaraq Ochilgan Sana",
  ACCOUNTS_ACCOUNTS_TABLE_LAST_TRANSACTION_DATE_COLUMN_TITLE: "Oxirgi Tranzaksiya qilingan Sana",

  ACCOUNTS_STATEMENT_FILTER_TITLE: "Filtr",
  ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE: "Filtr",
  ACCOUNTS_STATEMENT_FILTER_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  ACCOUNTS_STATEMENT_FILTER_FORM_YEAR_FIELD_LABEL_TITLE: "Yil",
  ACCOUNTS_STATEMENT_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "dan",
  ACCOUNTS_STATEMENT_FILTER_FORM_MONTH_FIELD_LABEL_TITLE: "Oy",
  ACCOUNTS_STATEMENT_FILTER_FORM_GENERATE_BUTTON_TITLE: "Generatsiya qilish",
  ACCOUNTS_STATEMENT_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "faqat debit",
  ACCOUNTS_STATEMENT_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "faqat kredit",
  ACCOUNTS_STATEMENT_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "hammasi",
  ACCOUNTS_STATEMENT_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Miqdorni Qidirish",
  ACCOUNTS_STATEMENT_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Hisobraqam",
  ACCOUNTS_STATEMENT_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Korrespondent Hisobraqami",
  ACCOUNTS_STATEMENT_FILTER_ERROR_TITLE: "So‘rov muddati tugadi",

  ACCOUNT_STATEMENT_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  ACCOUNT_STATEMENT_FORM_DETAILS_BUTTON_TITLE: "Batafsil ma’lumot",
  ACCOUNT_STATEMENT_FORM_FROM_FIELD_LABEL_TITLE: "dan",
  ACCOUNT_STATEMENT_FORM_DEBIT_FIELD_LABEL_TITLE: "Debit",
  ACCOUNT_STATEMENT_FORM_CREDIT_FIELD_LABEL_TITLE: "Kredit",
  ACCOUNT_STATEMENT_FORM_STATUS_FIELD_LABEL_TITLE: "Status",
  ACCOUNT_STATEMENT_FORM_BALANCE_FIELD_LABEL_TITLE: "Balans",
  ACCOUNT_STATEMENT_FORM_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  ACCOUNT_STATEMENT_FORM_DOC_NUMBER_FIELD_LABEL_TITLE: "Hujjat Raqami",
  ACCOUNT_STATEMENT_FORM_BANK_CODE_FIELD_PLACEHOLDER_TITLE: "Bank Kodi",
  ACCOUNT_STATEMENT_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Kompaniya Nomi",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Hisobvaraq Turi ",
  ACCOUNT_STATEMENT_FORM_COMPANY_PHONE_FIELD_LABEL_TITLE: "Kompaniya Telefoni",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Hisobraqami",
  ACCOUNT_STATEMENT_FORM_OPENING_BALANCE_FIELD_LABEL_TITLE: "Dastlabki Qoldiq",
  ACCOUNT_STATEMENT_FORM_CLOSING_BALANCE_FIELD_LABEL_TITLE: "Yakuniy Qoldiq",
  ACCOUNT_STATEMENT_FORM_END_OF_STATEMENT_FIELD_LABEL_TITLE: "Ko’chirmaning Oxiri",
  ACCOUNT_STATEMENT_FORM_ITEM_STATEMENT_FIELD_LABEL_TITLE: "Jami Qatorlar:",
  ACCOUNT_STATEMENT_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Kompaniya Manzili",
  ACCOUNT_STATEMENT_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya Sanasi",
  ACCOUNT_STATEMENT_FORM_CORR_ACCOUNT_NUMBER_FIELD_PLACEHOLDER_TITLE: "Korrespondent Hisobraqami",

  ACCOUNT_INQUIRY_FILTER_TO_FIELD_LABEL_TITLE: "gacha",
  ACCOUNT_INQUIRY_FILTER_FROM_FIELD_LABEL_TITLE: "dan",
  ACCOUNT_INQUIRY_FILTER_DATE_FROM_FIELD_LABEL_TITLE: "dan",
  ACCOUNT_INQUIRY_NON_STANDART_INQUIRIES_BUTTON_TITLE: "Nostandart So’rovlar",
  ACCOUNT_INQUIRY_STANDART_INQUIRIES_BUTTON_TITLE: "Standart So’rovlar",
  ACCOUNT_INQUIRY_SUBMIT_STANDARD_INQUIRY_BUTTON_TITLE: "Standart So’rovlarni Yaratish",
  ACCOUNT_INQUIRY_SUBMIT_NON_STANDARD_INQUIRY_BUTTON_TITLE: "Nostandart So’rovlarni Yaratish",

  ACCOUNT_SWIFT_VALUE_DATE_COLUMN_TITLE: "Qiymat sanasi",
  ACCOUNT_SWIFT_MSG_KEY_COLUMN_TITLE: "Swift xabar kaliti",
  ACCOUNT_SWIFT_REF_NUM_COLUMN_TITLE: "Malumot raqami",
  ACCOUNT_SWIFT_CURRENCY_COLUMN_TITLE: "Valyuta",
  ACCOUNT_SWIFT_AMOUNT_COLUMN_TITLE: "Miqdori",
  ACCOUNT_SWIFT_ORD_BIC_COLUMN_TITLE: "Buyurtma beruvchi muassasa SWIFT/BIC",
  ACCOUNT_SWIFT_ORD_CUST_COLUMN_TITLE: "Buyurtmachi mijoz",
  ACCOUNT_SWIFT_DETAILS_COLUMN_TITLE: "To'lov tafsilotlari",
  ACCOUNT_SWIFT_ACC_BIC_COLUMN_TITLE: "Tashkilot bilan hisob SWIFT/BIC",
  ACCOUNT_SWIFT_BEN_CUS_COLUMN_TITLE: "Benitsiar mijoz",

  ACCOUNT_SWIFT_COVER_WAITING_TITLE: "Iltimos kuting!",
  ACCOUNT_SWIFT_COVER_CHOCKBOX_FILTER_TITLE: "Sana bo'yicha saralash",

  ACCOUNT_SWIFT_COVER_DATE_TITLE: "Vaqti",
  ACCOUNT_SWIFT_COVER_SUM_TITLE: "So'm",
  ACCOUNT_SWIFT_COVER_CURRENCY_TITLE: "Valyuta",
  ACCOUNT_SWIFT_COVER_SENDER_TITLE: "Yuboruvchi",
  ACCOUNT_SWIFT_COVER_BEBEFICIARY_TITLE: "Benefisiar",
  ACCOUNT_SWIFT_COVER_PAYMENT_DETAILS_TITLE: "To'lov tafsilotlari",
  ACCOUNT_SWIFT_COVER_SUM_ADDITIONAL_PAYMENT_DETAILS_TITLE: "Qo'shimcha to'lov tafsilotlari",
  ACCOUNT_SWIFT_COVER_ACCOUNT_FOR_RECEIPT_TITLE: "Qabul qilish uchun hisob",

  ACCOUNT_SWIFT_COVER_CHECK_BUTTON_TITLE: "Tekshirish",
  ACCOUNT_SWIFT_COVER_CHECK_NO_MESSAGES_RECEIVED_TITLE: "Hech qanday xabar olinmadi",
  ACCOUNT_SWIFT_COVER_CHECK_RECEIVED_SWIFT_MESSAGES_TITLE: "Qabul qilingan SWIFT xabarlari",

  ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE: "Status",
  ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE: "Mavzu",
  ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE: "Tugatish Sanasi",
  ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE: "Yuborish Sanasi",

  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_TITLE: "Standart So’rov Yuborish",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_POWER_CHECKBOX_TITLE: "Bosh Ishonchnoma",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_DETAILS_SLEEP_CHECKBOX_TITLE:
    "Kiruvchi so’m mablag’lari uchun detalizatsiya",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CASH_CHECKBOX_TITLE:
    "Naqd pul olish uchun ishonchnoma",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_PAYMENT_CHECKBOX_TITLE:
    "Ishonchnoma (To’lov ko’rsatmasiga, xatlarga, ko’chirmalarga)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_CHECKBOX_TITLE: "Umumiy so’rov",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_PAYMENT_CHECKBOX_TITLE:
    "To’lov talabnomasini akseptsiz to’lashni tasdiqlash to’g’risidagi xat",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_QUERY_ABOUT_BINDING_CHECKBOX_TITLE:
    "Tranzit hisobvarag’iga, xodimlarning karta hisobvarag’ini ulash uchun so’rov",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_EMAIL_CHECKBOX_TITLE:
    "Kompaniyaning vakolatli electron pochta xati",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_SALARY_CHECKBOX_TITLE:
    "Ish haqi loyihasi uchun xat",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_STATEMENT_OF_ACCOUNT_CHECKBOX_TITLE:
    "Hiosbvaraq bo’yicha ko’chirma (qo’shimcha)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_RENEWAL_OF_SIGNATURE_CHECKBOX_TITLE:
    "Imzo na’munalari kartasini almashtirish (vaqtinchalik imzo na’munalari kartasini o’z ichiga olgan holda)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_CARD_CHECKBOX_TITLE:
    "Xodimlarning ish haqi kartasi uchun ishonchnoma",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_REPLY_CHECKBOX_TITLE:
    "Umumiy so’rov (24 soat ichida javob qaytarish)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CORPORATE_CARD_CHECKBOX_TITLE:
    "Korporativ karta uchun ishonchnoma",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_UP_12_MONTHS_CHECKBOX_TITLE:
    "Ma’lumot tayyorlash (qog’ozda / electron formatda 12 oygacha muddatda buyurtmachining aylanmasi hisobvarag’i bo’yicha)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OF_THE_LATTER_CHECKBOX_TITLE:
    "Audit firmasi iltimosiga binoan so’rovnoma tayyorlash",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OVER_12_MONTHS_CHECKBOX_TITLE:
    " Ma’lumot tayyorlash (qog’ozda / electron formatda 12 oydan oshgan muddatda buyurtmachining aylanmasi hisobvarag’i bo’yicha)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_AND_CORPORATE_CARD_CHECKBOX_TITLE:
    "Ish haqi va korporativ kartalar uchun ishonchnoma",

  ACCOUNT_INQUIRY_NON_STANDARD_SUBJECT_FIELD_LABEL_TITLE: "Mavzu",
  ACCOUNT_INQUIRY_NON_STANDARD_TITLE: "No Standart So’rovni taqdim etish",
  ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE: "So’rovni taqdim etish",
  ACCOUNT_INQUIRY_NON_STANDARD_MESSAGE_TEXT_FIELD_LABEL_TITLE: "Xatning Matni",
  ACCOUNT_INQUIRY_NON_STANDARD_DATE_OF_REQUEST_FIELD_LABEL_TITLE: "So’rov Sanasi",
  ACCOUNT_INQUIRY_NON_STANDARD_PREPARING_BRANCH_FIELD_LABEL_TITLE: "Bank Nomi",
  ACCOUNT_INQUIRY_NON_STANDARD_REQUESTING_COMPANY_FIELD_LABEL_TITLE: "So’rov Bergan Kompaniya",
  ACCOUNT_INQUIRY_NON_STANDARD_CLIENT_REGISTRATION_NUMBER_FIELD_LABEL_TITLE:
    "Mijozning ro’yxatdan o’tish raqami",

  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_DATE_FIELD_LABEL_TITLE: "Sana",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Telefon",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "E-pochta",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ACCOUNT_NAME_FIELD_LABEL_TITLE:
    "Hiosbvaraq Turi",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_BANK_FIELD_LABEL_TITLE:
    "Bank Nomi",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Mijoz Nomi",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ADDRESS_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Mijoz Manzili",
  ACCOUNT_INQUIRY_APPLICATION_FOR_ACCOUNT_OPENING_FORM_TITLE: "Hisobvaraq ochish uchun Ariza",

  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_1: "*Agar Mijoz tanlasa",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "E-pochta",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Telefon",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_2:
    "Chet el valyutasidagi korporativ karta ochish",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_ISSUED_BY_FIELD_LABEL_TITLE: "Kim tomonidan berilgan",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THRU_FIELD_LABEL_TITLE: "Amal qilish muddati",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_3: ", qo’shimcha variantni to’ldirish kerak",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CARD_NUMBER_FIELD_LABEL_TITLE: "Karta Raqami",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Fuqaroligi",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_HOME_ADDRESS_FIELD_LABEL_TITLE: "Uy Manzili",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE: "Berilgan Sanasi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PAGE_TITLE: "karta Egasining Ma’lumotlari",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_FIRSTNAME_FIELD_TITLE: "Ismi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_LASTNAME_FIELD_TITLE: "Familiyasi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSPORT_SERIES_FIELD_TITLE: "Pasport Seriyasi va",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE: "Raqami",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_MIDDLENAME_FIELD_TITLE: "Otasining Ismi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_ISSUE_FIELD_TITLE: "Passport Kim tomonidan berilgan",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_ISSUE_FIELD_TITLE: "Pasport berilgan Sanasi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_WORK_PHONE_FIELD_TITLE: "Ish Telefoni",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VERIFY_FIELD_TITLE:
    "Password to verify the identity of the Owner used in telephone conversations with the Back",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PHONE_FIELD_TITLE: "Telefon",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_POSITION_HELD_TITLE: "Lavozimi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_BIRTH_TITLE: "Tug’ilgan Sana",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_ADDRESS_TITLE: "Manzil",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_RECIDENCE_TITLE: "Yashash Joyi",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_REGISTERED_ADDRESS_TITLE: "Ro’yxatdan o’tgan manzili",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VALIDITY_TITLE: "Parol amal qilish muddati",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THROUGH_FIELD_LABEL_TITLE:
    "Amal qilish muddati",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_CARDHOLDER_FIELD_LABEL_TITLE:
    "Karta Egasining Ismi va Familiyasi",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Iltimos, ismingizni kartada ko’rinadigan tarzda chop eting",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_COMPANY_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Iltimos, kompaniya nomini kartada ko’rinadigan tarzda chop eting",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Pasport raqami",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_BIRTH_FIELD_LABEL_TITLE:
    "Tug’ilgan Sana va  Joy",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_MOTHER_NAME_FIELD_LABEL_TITLE:
    "Onangizning qizlik familiyasi",

  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_PUBLIC_OFFER_LABEL_TITLE: "Ommaviy Oferta",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_READ_AND_AGREE_LINK_TITLE: "O’qing va rozilik bildiring",
  ACCOUNT_INQUIRY_NEW_REQUEST_K2: "K2 ni ro’yxatga olish uchun so’rov",
  ACCOUNT_INQUIRY_SENT: "So’rov yaratildi",
  ACCOUNT_INQUIRY_TEXT_K2: "Bankdan K2 reyestrining nusxasini taqdim etilishi so’raladi",
  ACCOUNT_INQUIRY_TEXT_SINCERELY: "Hurmat bilan,",
  ACCOUNT_REQUEST_NUMBER: "So’rov Raqami",
  ACCOUNT_REQUEST_DATE: "So’rov Sanasi",
  ACCOUNT_BRANCH_NAME: "Bank Nomi",
  ACCOUNT_INQUIRY_TEXT_AUDIT_INQUIRY: "Auditorlik tekshiruv to’g’risidagi ma’lumot so’rovi",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER: "Hisobvaraq haqida ma’lumot so’rovi",
  ACCOUNT_END_OF_DAY: "Kun yakuni",
  ACCOUNT_BEGINNING_OF_THE_DAY: "Kun boshlanishi",
  ACCOUNT_INQUIRY_TEXT_AUDIT: "Moliyaviy faoliyatni tekshirish bilan bog’liq",
  ACCOUNT_INQUIRY_TEXT_2_AUDIT: " Bankdan hisobvaraq haqida ma’lumotni taqdim etilishi so’raladi",
  ACCOUNT_INQUIRY_TEXT_BY_AUDIT: "Auditorlik Kompaniyasi tomonidan",
  ACCOUNT_FROM_DATE: "dan",
  ACCOUNT_TO_DATE: "gacha",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    "Bankdan Kompaniya hisobvaraq(lar)i haqida quyidagi ma’lumotlarni taqdim etilishi so’raladi;",
  ACCOUNT_BALANCE_OF_DATE: "Ushbu sanaga qoldiq",
  ACCOUNT_TURNOVERS: "Ushbu muddatga aylanma",
  ACCOUNT_ALL_ACCOUNTS: "Barcha",
  ACCOUNT_REQUEST_SENT: "So’rov yaratildi",
  ACCOUNT_INFORMATION: "Ushbu sanaga kredit ma’lumotlari",
  ACCOUNT_CARD_FILE: "Ushbu sanaga kartoteka 2",
  ACCOUNT_ADDITIONAL_STATEMENT_HEADER: "Qo’shimcha ko’chirma uchun so’rov",
  ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " Bankdan quyidagi hisobvaraq(lar) uchun hisobvaraq(lar) bo’yicha qo’shimcha ko’chirmani taqdim etilishi so’raladi;",
  ACCOUNT_ADDITIONAL_FOR_THE_PERIOD: "dan",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL:
    "Ustav kapitalini to’ldirish uchun tushadigan mablag’lar to’g’risida ma’lumot so’rash",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT:
    " Bankdan kompaniyaning hisobvaraq(lar)iga tushadigan mablag’lar to’g’risida ma’lumot berilishi so’raladi",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT_2: "ustav kapitalini ushbu davr uchun to’ldirish",

  // Transfers Page
  TRANSFER_LCY_EXPORT_EXCEL_TITLE: "TO’LOV TOPSHIRIQNOMASI #",
  TRANSFER_LCY_EXPORT_EXCEL_DATE: "SANA",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME: "To’lovchining nomi",
  TRANSFER_LCY_EXPORT_EXCEL_DEBIT: "DEBET",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT: "To’lovchining hisobvarag’i",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER: "To’lovchining STIR",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME: "To’lovchiga xizmat ko’rsatuvchi bank nomi",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO: "To’lovchiga xizmat ko’rsatuvchi bank kodi",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT: "MIQDORI",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME: "Qabul qiluvchining nomi",
  TRANSFER_LCY_EXPORT_EXCEL_CREDIT: "KREDIT",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT: "Qabul qiluvchining hisobvarag’i",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER: "Qabul qiluvchining STIR",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME:
    "Qabul qiluvchiga xizmat ko’rsatuvchi bank nomi",
  TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO: "Qabul qiluvchiga xizmat ko’rsatuvchi bank kodi",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS: "Miqdor so’zlarda",
  TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS: "To’lov maqsadi",
  TRANSFER_LCY_BUDGET_INN: "Byudjet qabul qiluvchining STIR",
  TRANSFER_LCY_BUDGET_REVENUE: "Daromad uchun g’aznachilik shaxsiy hisob",
  TRANSFER_BUDGET_ACCOUNT_NUMBER: "Byudjet qabul qiluvchining g’aznachilik shaxsiy hisobi",
  TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME: "Rahbar",
  TRANSFER_LCY_EXPORT_EXCEL_FIO: "To’liq ismi-sha’rifi",
  TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT: "Bosh buxgalter",
  TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE: "М.O’.",
  TRANSFER_LCY_EXPORT_EXCEL_BANK: "Bank",
  TRANSFER_LCY_EXPORT_EXCEL_CHECKED: "Tekshirildi",
  TRANSFER_LCY_EXPORT_EXCEL_APPROVED: "Tasdiqlandi",
  TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED: "O’tkazildi",
  TRANSFER_BUDGET_NAME: "Byudjet qabul qiluvchining nomi",

  TRANSFERS_SALARY_TAB_TITLE: "Ish haqi",
  TRANSFERS_HISTORY_TAB_TITLE: "Tarix",
  TRANSFERS_SAMPLES_TAB_TITLE: "Formalar",
  TRANSFERS_SAMPLES_TAB_IMPORT_FROM_EXCEL: "Excel orqali import qilish",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_TITLE: "Hujjat hajmi",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_KB_TITLE: "kb",
  TRANSFERS_FORMS_DIRECTIONS_MODIFIED_DATE_TITLE: "Oxirgi o’zgartirilgan sana",
  TRANSFERS_TRANSFERS_TAB_LCY_TITLE: "So’mdagi O’tkazmalar",
  TRANSFERS_TRANSFERS_TAB_FCY_TITLE: "Chet el valyutasidagi O’tkazmalar",
  TRANSFERS_FAVORITES_TAB_TITLE: "Tanlanganlar",
  TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL: "Hujjat nomi",
  TRANSFERS_PAYMENT_CLAIM_TAB_TITLE: "To’lov so’rovnomasi",
  TRANSFERS_CORRESPONDENTS_TAB_TITLE: "Kontragentlar",
  TRANSFERS_CORPORATE_CARD_TAB_TITLE: "Korporativ Karta",

  TRANSFERS_SALARY_INSTRUCTION_TITLE: "Qo’llanma",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_TITLE: "Reyestrni bankka topshirish",
  TRANSFERS_CORPORATE_INSTRUCTION_TITLE: "Bir nechta kartani to'ldirish bo'yicha ko'rsatma",
  TRANSFERS_SALARY_REPLENISH_SUBTITLE: "1. Tranzit hisobvaraqni to’ldirish",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REPLENISH_SUBTITLE: "1. Tranzit hisobvaraqni to’ldirish",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SUBTITLE:
    "2. Ro'yxatdan tranzit hisobvaraqni (23106, 23108, 23110) tanlang.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REGISTER_SUBTITLE:
    "3. Ish haqini reyestr yuklash usulini tanlang (Excel fayilni yuklash / Mavjud reyestrni tanlash / Yangi reyestrni suzish).",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_CHECK_SUBTITLE:
    "4. \"Tekshirish\" tugmasini bosing. Agar \"Mos emas\" yoki \"Hisobvaraq topilmadi\" holatiga ega kataklar paydo bo'lsa, tuzatish kiriting yoki ushbu qator(lar)ni o'chiring.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_STATUS_SUBTITLE:
    "5. Barcha kataklar \"Mos keldi\" bo'lsa, \"Taqdim etish\" tugmasini bosing. Agar reyestr muvaffaqiyatli yaratilgan bo'lsa, Avtorizatorlar \"Tasdiqlash kutilmoqda\" / \"Ish haqi\" bo'limiga o'tib va hujjatlar holatini \"Tasdiqlash\" yoki “Rad etish” ga o‘zgartiring, so‘ng \"Taqdim etish\" tugmasini bosing.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_INFO_SUBTITLE:
    "Reyestrni soat 17:30* qadar qabul qilinadi. 5.30 dan keyin taqdim etilgan reyestlari avtomatik ravishda o'chiriladi. (* soat 16:30 qisqartirilgan bayramdan oldingi ish kunlarida).",

  TRANSFERS_SALARY_CHOOSE_REGISTER_SUBTITLE:
    "3. Iltimos, ro’yxatdan o’tish turini tanlang (quyidagi shablonlarga muvofiq Excel faylni yuklang, Mavjud reyestrni tanlang, Yangi reyestrni tuzish)",

  TRANSFERS_SALARY_DEBIT_ACCOUNT_TITLE: "Xodimlarning bank kartalariga avtomatik ravishda pul kiritish",  
  TRANSFERS_SALARY_DEBIT_ACCOUNT_METHOD_INFO_SUBTITLE: "Ushbu usuldan foydalangan holda mablag'lar xodimlarning bank kartalariga bankka ish haqi reyestrni yubosmasdan kiritilishi mumkin.",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SUBTITLE: "1. Ro'yxatdan talab qilib olinguncha depozit hisobvaraqni tanlang.",

  TRANSFERS_CORPORATE_CARD_INFO: "2. Tranzit hisobvaraqni tanlash -",
  UPLOAD_PAYROLL_EXCEL_BUTTON_TITLE: "Excel Faylni yuklash",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT:
    "(i) 23102 -  Korporativ kartani to’ldirish uchun transit hisobvaraq",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT_2:
    "(ii) 23104 -  Yakka tartibdagi tadbirkor korporativ kartasini to’ldirish uchun tranzit hisobvaraq",

  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_TITLE: " Tranzit hisobvaraqni tanlash ",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_FIELD_LABEL_TITLE: "Tranzit hisobraqam",
  TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE: "Balans",
  TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE: "Reyestr turi",
  TRANSFERS_SALARY_MONTH_FIELD_LABEL_TITLE: "Oylik",
  TRANSFERS_SALARY_NUMBER_FIELD_LABEL_TITLE: "Reyestr raqami",
  TRANSFERS_SALARY_AGREEMENT_FIELD_LABEL_TITLE: "Shartnoma Sanasi",
  TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE: "Kod",
  TRANSFERS_SALARY_CHOOSE_REGISTER_TITLE: "Reyestrni tanlash",
  TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE: "Mavjud reyestrni tanlash",
  TRANSFERS_SALARY_CREATE_NEW_REGISTER_BUTTON_TITLE: "Yangi reyestrni tuzish",

  TRANSFERS_SALARY_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Talab qilib olinguncha depozit hisobraqam",

  TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE: "dan",
  TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE: "gacha",
  TRANSFERS_SALARY_CLAIM_FILTER_BY_STATUS_FIELD_LABEL_TITLE: "Status bo’yicha filtrlash",
  TRANSFERS_SALARY_SEARCH_BUTTON_TITLE: "Qidiruv",
  TRANSFERS_SALARY_SHOW_CREATORS_CHECKBOX_TITLE: "Bajaruvchi va Avtorizatorni ko’rsatish",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_EMPLOYEE_REGISTRATION_FORM_TITLE:
    "Xodimlarni ro’yxatdan o’tkazish shakli",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_FIELD_LABEL_TITLE:
    "Pasport seriyasi va raqami",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Pasport raqami",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Pasport kim tomonidan berilgan",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Pasport berilgan sana",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_VALIDITY_FIELD_LABEL_TITLE:
    "Amal qilish muddati",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_ADDRESS_FIELD_LABEL_TITLE:
    "Ro’yxatdan o’tgan manzili",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_CITY_FIELD_LABEL_TITLE: "Shahar",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_PINFL_FIELD_LABEL_TITLE: "PINFL",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SAVE_BUTTON_TITLE: "Taqdim etish",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_INN_FIELD_LABEL_TITLE: "STIR",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_AREA_FIELD_LABEL_TITLE: "Hudud",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_LAST_FIELD_LABEL_TITLE: "Familiya",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FATHER_FIELD_LABEL_TITLE: "Otasining ismi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MALE_RADIO_BUTTON_LABEL_TITLE: "Erkak",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MOBILE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Mobil",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SERVICE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Xizmat",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HOME_PHONE_RADIO_BUTTON_LABEL_TITLE: "Uy",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_UZCARD_RADIO_BUTTON_LABEL_TITLE: "UzCard",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_YES_RADIO_BUTTON_LABEL_TITLE: "Xa",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_NO_RADIO_BUTTON_LABEL_TITLE: "Yo’q",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HUMO_RADIO_BUTTON_LABEL_TITLE: "HUMO",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PINF_FIELD_LABEL_TITLE: "PINF Raqami",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FIRST_FIELD_LABEL_TITLE: "Ismi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MIDDLE_FIELD_LABEL_TITLE: "Otasining ismi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_GENDER_RADIO_BUTTON_LABEL_TITLE: "Jinsi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_LABEL_TITLE: "Karta",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_RESIDENT_LABEL_TITLE: "Rezident",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FEMALE_RADIO_BUTTON_LABEL_TITLE: "Ayol",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SEND_REQUEST_BUTTON_TITLE: "So’rov yuborish",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Fuqaroligi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_BIRTH_FIELD_LABEL_TITLE: "Tug’ilgan sanasi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_BIRTH_FIELD_LABEL_TITLE: "Tug’ilgan joyi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_STREET_FIELD_LABEL_TITLE:
    "Ko’cha, Kvartal, Uy, Raqam, Kvartira Raqami",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CONTACT_BUTTON_TITLE: "KONTAKTLARNI CHOP ETISH",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CLEAR_FORM_BUTTON_TITLE: "SHAKLNI TOZALASH",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_BUTTON_TITLE: "Qidiruv",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_CARD_TYPE: "Karta turi",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_FIELD_LABEL_TITLE: "Qidiruv",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EXPORT_LIST_BUTTON_TITLE: "Ro’yxatni eksport qilish",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK: "Tekshirish",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_ERROR: "Sumada vergul bo'lmasligi kerak",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_AMOUNT_ERROR:
    "Miqdor to'ldirilishi yoki noldan katta bo'lishi kerak",
  TRANSFERS_SALARY_LIST_OF_CARD_BACK: "Orqaga",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE: "To’liq ismi sha’rifi",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EMPLOYEE_LIST_BUTTON_TITLE: "Xodimlar ro’yxati",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE: "Karta ID",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Hisobraqam",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE: "Ochish sanasi",

  TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE: "Hujjatni tanlash",

  TRANSFERS_CORPORATE_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Bir nechta kartalarni reyestr bilan to'ldirish",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE: "Yagona kartani reyestrsiz to'ldirish",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Reyestrni bankka topshirish",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE:
    "Xodimlarning bank kartalariga avtomatik ravishda pul kiritish ",

  TRANSFERS_CORPORATE_CHOOSE_DEBIT_SUBMIT_BUTTON_TITLE: "Yuborish",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_TITLE: "Debet hisobini tanlang",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_NUMBER_FIELD_TITLE: "Debet hisob raqami",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_CORPORATE_CARD_NUMBER_FIELD_TITLE:
    "Korporativ karta raqami",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_AMOUNT_FIELD_TITLE: "Miqdor",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_OF_CORPORATE_FIELD_TITLE:
    "Korporativ kartaning hisob raqami",

  TRANSFERS_CORPORATE_SINGLE_CARD_REPLENISHMENT_INSTURCTION_TITLE:
    "Yagona kartani to'ldirish bo'yicha ko'rsatma",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_DEBIT_ACCOUNT_TITLE: "1. Debet hisobini tanlang",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_ACCOUNT_OF_COR_CARD_TITLE:
    "2. Korporativ karta hisobini tanlang",

  TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  TRANSFERS_TRANSFERS_CHECK_BUTTON_TITLE: "TEKSHIRISH	",
  TRANSFERS_TRANSFERS_CURRENT_BUSINESS_DAY_TITLE: "Joriy ish kuni",
  TRANSFERS_TRANSFERS_CREATE_NEW_LCY_BUTTON_TITLE: "So’mda yangi o’tkazmani yaratish",
  TRANSFERS_TRANSFERS_CREATE_NEW_FCY_BUTTON_TITLE: "Chet el valyutasida yangi o’tkazmani yaratish",
  TRANSFERS_TRANSFERS_ALL_OUTGOING_TRANSFERS_TITLE: "So’mdagi o’tkazmalar",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_TITLE: "Chet el valyutasidagi o’tkazmalar",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_IN_FORM_TITLE: "Chet el valyutasidagi o’tkazmalar",
  TRANSFERS_TRANSFERS_FIND_DOCUMENT_WITH_STATUS_LABEL_TITLE: "… holatidagi hujjatlarni topish",
  TRANSFERS_TRANSFERS_SHOW_CREATORS_AND_APPROVER_LABEL_TITLE:
    "Bajaruvchi va Avtorizatorni ko’rsatish",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_FCY_BUTTON_TITLE: "Exceldan yuklash",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_LCY_BUTTON_TITLE: " Exceldan yuklash ",

  TRANSFERS_TRANSFERS_FCY_ON_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari ish kunlari soat 09:00 dan 16:30 gacha qabul qilinadi (Toshkent vaqti)",  
  TRANSFERS_TRANSFERS_FCY_ON_SHORTENED_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari bayram oldi qisqartirilgan ish kunlari soat 09:00 dan 15:30 gacha qabul qilinadi (Toshkent vaqti)",

  TRANSFERS_TRANSFERS_LCY_FAVORITES_BUTTON_TITLE: "Tanlanganlar",
  TRANSFERS_TRANSFERS_LCY_ANOR_TRANSFER_BUTTON_TITLE: "ANOR standart o’tkazmalar",
  TRANSFERS_TRANSFERS_LCY_MINUS_TRANSFER_BUTTON_TITLE: "MUNIS o’tkazmalar",
  TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE: "So’mdagi byudjet o’tkazmalari",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_TRANSFER_BUTTON_TITLE: "ANOR byudjet o’tkazmalari",
  TRANSFERS_TRANSFERS_LCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: "Exceldan yuklash",
  TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE: "So’mdagi standart o’tkazmalar",
  TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE:
    "So’mdagi Byudjet kirimi o’tkazmalari",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE:
    "ANOR Byudjet kirimi o’tkazmalari",

  TRANSFERS_TRANSFERS_FCY_TITLE: "Chet el valyutasidagi o’tkazmalar",
  TRANSFERS_TRANSFERS_FCY_FAVORITES_BUTTON_TITLE: "Tanlangan ichidan yuklash",
  TRANSFERS_TRANSFERS_FCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: " Exceldan yuklash",

  TRANSFERS_TRANSFERS_TABLE_TYPE_COLUMN_TITLE: "Turi",
  TRANSFERS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_TRANSFERS_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE: "Bajaruvchi",
  TRANSFERS_TRANSFERS_TABLE_CURRENCY_COLUMN_TITLE: "Valyuta",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish bankdan",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_CB_COLUMN_TITLE: "Tushuntirish CB-dan",
  TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_TRANSFERS_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Qabul qiluvchining bank kodi",
  TRANSFERS_TRANSFERS_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_TYPE_COLUMN_TITLE: "Hujjat turi",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Hujjat raqami",
  TRANSFERS_TRANSFERS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Tranzaksiya sanasi",
  TRANSFERS_TRANSFERS_TABLE_CREATE_DATE_AND_TIME_COLUMN_TITLE: "Yaratilgan sanasi",
  TRANSFERS_TRANSFERS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE: "Yuboruvchining hisobraqami",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Bajaruvchi",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE: "Benefitsiarning hisobraqami",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE:
    "To’lov so’rovnomasini bekor qilish",
  TRANSFERS_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_FOR_STATUS_COLUMN_TITLE:
    "Status so’rovi",

  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_LOAD_BUTTON_TITLE: "Yuklash",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE: "Yuboruvchi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE: "Miqdori",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE: "Batafsil ma’lumot",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTIONS_BUTTON_TITLE: "Yuklash",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Generatsiya qilish",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE: "Benefitsiar",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CORRESPONDENT_BUTTON_TITLE: "Kontragent",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE: "Yuboruvchining nomi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "To’lov turi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE: "To’lov kodi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE: "Yuboruvchining STIR",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_FIELD_LABEL_TITLE: "Hisobvaraq  (27 raqam)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTION_TO_CHOOSE_BUTTON_TITLE: "tanlashga imkoniyat",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Hisobvaraqni debitlash",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE: "Hisobvaraqni kreditlash",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Invoice Number",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE: "Joriy balans",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE: "To’lov ma’lumotlari",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE: "Miqdor so’zlarda",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "To’lov raqami",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE:
    "Yuboruvchining bank nomi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE:
    "Yuboruvchining bank kodi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE: "Miqdor raqamlarda",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_AND_DETAILS_TITLE:
    "To’lov kodi va batafsil ma’lumoti",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya sanasi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE: "Benefitsiarning nomi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE:
    "Benefitsiarning STIR",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE:
    "Byudjet tashkilotining STIR",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE:
    "Benefitsiarning bank nomi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE:
    "Benefitsiarning bank kodi",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE:
    "Hisobvaraqa (27 raqam)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_25_DIGITS_FIELD_LABEL_TITLE:
    "Hisobvaraq (25 raqam)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE:
    "Byudjet tashkiloti nomi",

  TRANSFERS_LCY_PRINT_HEADER_TITTLE: "To’lov topshiriqnomasi #",
  TRANSFERS_LCY_PRINT_DATA_LABEL: "Sana",
  TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL: "Yuboruvchi nomi",
  TRANSFER_PRINT_TABLE_HELD: "O'tkazildi",
  TRANSFERS_LCY_PRINT_DEBIT_LABEL: "DEBIT",
  TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL: "To’lovchining hisobvarag’i",
  TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL: "To’lovchining STIR",
  TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL: "To’lovchiga xizmat ko’rsatadigan bank nomi",
  TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL: " To’lovchiga xizmat ko’rsatadigan bank kodi ",
  TRANSFERS_LCY_PRINT_AMOUNT_LABEL: "Miqdor",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL: "Qabul qiluvchining nomi",
  TRANSFERS_LCY_PRINT_CREDIT_LABEL: "KREDIT",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL: "Qabul qiluvchining hisobvarag’i",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL: "Qabul qiluvchining STIR",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL:
    "Mablag’ qabul qiluvchiga xizmat ko’rsatuvchi bankning nomi",
  TRANSFERS_LCY_PRINT_STATE_LABEL: "Holati",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL: "Benefitsiarga xizmat ko’rsatuvchi bank kodi",
  TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL: "Summa so’zlarda",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_TAX_NUMBER_LABEL: "Byudjet qabul qiluvchining STIR",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_LABEL:
    "Byudjet mablag’I qabul qiluvchining g’aznachilik shaxsiy kabineti",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_NAME_LABEL: "Byudjet qabul qiluvchining nomi",
  TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL: "To’lov ma’lumotlari",
  TRANSFERS_LCY_PRINT_MANAGER_LABEL: "Boshqaruvchi",
  TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL: "Bosh Buxgalter",
  TRANSFERS_LCY_PRINT_INITIALS_LABEL: "Initsiallar",
  TRANSFERS_LCY_PRINT_BANK_LABEL: "Bank",
  TRANSFERS_LCY_REVENUE_PRINT_CLIENT_ACCOUNT_LABEL: "Belgilangan shaxsiy daromad hisobi",
  TRANSFERS_LCY_UPLOAD_FROM_EXCEL_NUMBER_ERROR: "Bu Id nomer bilan malumotni saqlab bo'lmaydi",

  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Generatsiya qilish",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYEE_GROUP_TITLE: "Guruh nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SUPPLIER_CODE_TITLE: "Yetkazib beruvchining Kodi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SETTLEMENT_CODE_TITLE: "To’lov turi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Hisob-faktura raqami",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya sanasi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_GROUP_TITLE_TITLE: "Yuboruvchi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_GROUP_TITLE_TITLE: "Benefitsiar",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNTS_GROUP_TITLE_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_DETAILS_GROUP_TITLE_TITLE: "Batafsil ma’lumot",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_NAME_TITLE: "Yuboruvchi nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_DEBIT_ACCOUNT_TITLE: "Hisobvaraqni debitlash",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_CURRENT_BALANCE_TITLE: "Joriy balans",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_NAME_TITLE: "Yuboruvchining bank Nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_CODE_TITLE: "Yuboruvchining bank kodi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_TAX_ID_TITLE: "Yuboruvchining STIR",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_NAME_TITLE: "Benefitsiar nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_TITLE:
    "Hisobvaraqni kreditlash",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_TAX_ID_TITLE: "Benefitsiar STIR",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_AMOUNT_FOR_PAYMENT: "To’lov summasi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_NAME_TITLE: "Benefitsiar bank nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_CODE_TITLE: "Benefitsiar bank kodi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ACCOUNT_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_NAME_TITLE:
    "Benefitsiar byudjet tashkilotining nomi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_TAX_ID_TITLE:
    "Benefitsiar byudjet tashkilotining STIR",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_DIGITS_TITLE: "Miqdor raqamlarda",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_WORDS_TITLE: "Miqdor so’zlarda",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE: "To’lov kodi",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_DETAILS_TITLE: "To’lov ma’lumoti",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TITLE: "Ariza beruvchi (50)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_AMOUNT_TITLE: "Miqdor (32А)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_COMMISSION_TITLE: "Komissiya",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_OTHERS_FIELD_LABEL_TITLE: "Boshqalar (72)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_CODE_LABEL_TITLE: "Operator kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE:
    "Operator kodi yaroqsiz",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_KPP_FIELD_LABEL_TITLE: "KPP",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE: "Benefitsiar (59)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE_N2: "Benefitsiar Banki (57)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TITLE: "Ariza beruvchining Banki (52)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_TITLE:
    "Shu bilan ushbu ilova orqali bir turdagi chet el valyutasini boshqa turdagi chet el valyutasiga ayirboshlash bo’yicha xizmat ko’rsatish bo’yicha ommaviy oferta shartlarini o’qiganimni tasdiqlayman, ular bilan roziman va qo’shilaman, Bankka nisbatan mening davolarim bo’lmaydi. Bank tariflariga muvofiq ko’rsatiladigan xizmatlar uchun zarur miqdordagi mablag’ni bevosita hisobvaraqdan yechilishiga rozilik bildiraman.",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARYS_BANK_TITLE: "BENEFITSIAR BANKI",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_1_TITLE:
    "Bir turdagi chet el valyutasini boshqa turdagi chet el valyutasiga ayirboshlash bo’yicha Ommaviy ofertaga 2 – Ilova ",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_TITLE:
    "O’tkazma uchun xizmat haqi (71)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_2_TITLE:
    "Ommaviy oferta - Shartnomasiga qo’shilish to’g’risidagi bandi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE: "Nomi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "O’tkazma raqami",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_FIELD_LABEL_TITLE: "Batafsil ma’lumot (70)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_OR_SERVICES_LABEL_TITLE: "Xizmatlar/Tovarlar",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SERVICES_LABEL_TITLE: "Xizmatlar",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_LABEL_TITLE: "Tovarlar",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_IS_IDN_LABEL_TITLE: "Tijoriy to'lov uchun",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_PAYMENT_FIELD_LABEL_TITLE:
    "To’lov bo’yich ma’lumot",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DEBIT_COMMISSION_FIELD_LABEL_TITLE:
    "Iltimos kommissiyani hisobvaraqdan debitlang",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE: "Nomi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE: "Bank Nomi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_NAME_FIELD_LABEL_TITLE: "Nomi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE: "STIR",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_CODE_TITLE: "Davlat kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_NUMBER_FIELD_LABEL_TITLE: "Telefon raqam",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PASSWORD_FIELD_LABEL_TITLE: "Pasport",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE: "STIR",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE: "Nomi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TAX_ID_FIELD_LABEL_TITLE: "STIR",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_PLACE_FIELD_LABEL_TITLE: "Joy",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_REMARKS_FIELD_LABEL_TITLE: "Izohlar",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CORRESPONDENT_ACCOUNT_FIELD_LABEL_TITLE: "Korr. h/v",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_VO_CODE_LABEL_TITLE: "VO Kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya sanasi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE:
    "SWIFT/BIC kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_DEBIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Hisobvaraqni debitlash",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE: "Joriy balans",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Hisobvaraqni kreditlash",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Joriy balans",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_CODE_FIELD_LABEL_TITLE: "Swift kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_DIGIT_FIELD_LABEL_TITLE:
    "Miqdor raqamlarda",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE:
    "Miqdor so’zlarda",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_BIC_CODE_FIELD_LABEL_TITLE:
    "Swift / Bic kodi",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Joriy balans",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE:
    "Benefitsiar bankining korrespondent banki (53)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_REMITTER_CHECKBOX_LABEL_TITLE:
    "Barcha komissiyalar yuboruvchi hisobidan /OUR/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_CHARGES_OF_JSC_CHECKBOX_LABEL_TITLE:
    '"KDB Bank Uzbekistan" AJ ning komissiyalari yuboruvchi hisobidan /SHA/',
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FULL_NO_DEDUCT_CHECKBOX_LABEL_TITLE:
    "Barcha komissiyalar yuboruvchi hisobidan /FND/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_BENEFICIARY_CHECKBOX_LABEL_TITLE:
    "Barcha komissiyalar benefitsiar hisobidan /BEN/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE: "SWIFT/BIC kudi",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_MODAL_NACCODE_TITLE: "Nace Kodlari",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACCODE_TITLE: "Kod",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACNAME_TITLE: "Tavsifi",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_LABEL_TITLE: "Nace Kod",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_HS_CODE_LABEL_TITLE: "Hs Kod",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_BUTTON_TITLE: "Yuklash",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INV_NUMBER_LABEL_TITLE: "INVN Nomeri",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INVN_DATE_LABEL_TITLE: "INV vaqti",

  TRANSFERS_CORRESPONDENTS_TITLE: "Kontragentlar ro’yxati",

  TRANSFERS_CORRESPONDENTS_TABLE_TAX_ID_COLUMN_TITLE: "STIR",
  TRANSFERS_CORRESPONDENTS_TABLE_CODE_COLUMN_TITLE: "Kod",
  TRANSFERS_CORRESPONDENTS_TABLE_DESCRIPTION_COLUMN_TITLE: "Tavsifi",
  TRANSFERS_CORRESPONDENTS_TABLE_ACCOUNT_COLUMN_TITLE: "Hisobvaraq",
  TRANSFERS_CORRESPONDENTS_TABLE_BANK_CODE_COLUMN_TITLE: "Bank Kodi",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_PARTNER_COLUMN_TITLE: "Bank nomi",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_THE_BANK_COLUMN_TITLE: "Sherikning nomi",

  TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Mavzu",
  TRANSFERS_FAVORITES_TABLE_STATUS_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_FAVORITES_TABLE_CREATED_BY_BY_COLUMN_TITLE: "Bajaruvchi",
  TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_DATE_COLUMN_TITLE: "Tugagan sana",
  TRANSFERS_FAVORITES_TABLE_EXPLANATION_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_DATE_COLUMN_TITLE: "Taqdim etilgan sana",

  TRANSFERS_FAVOURITES_MODAL_TAB_TITLE: "Tanlangan ro’yxati",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NUMBER_COLUMN_TITLE: "Hujjat raqami",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NAME_COLUMN_TITLE: "Hujjat nomi",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_TYPE_COLUMN_TITLE: "Hujjat turi",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni debitlash",
  TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Benefitsiar nomi",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni kreditlash",
  TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CURRENCY_COLUMN_TITLE: "Valyuta",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE: "So’rov",

  TRANSFERS_HISTORY_FILTER_BUTTON_TITLE: "Filtr",
  TRANSFERS_HISTORY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  TRANSFERS_HISTORY_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "dan",
  TRANSFERS_HISTORY_FILTER_FORM_STATUS_FIELD_LABEL_TITLE: "Status",
  TRANSFERS_HISTORY_FILTER_FORM_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  TRANSFERS_HISTORY_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "faqat debit",
  TRANSFERS_HISTORY_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "faqat kredit",
  TRANSFERS_HISTORY_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Miqdorni qidirish",
  TRANSFERS_HISTORY_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "Batafsil ma’lumotlarsiz",
  TRANSFERS_HISTORY_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Hisobraqam",
  TRANSFERS_HISTORY_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Hisobraqam",

  TRANSFERS_HISTORY_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Taqdim etish sanasi",
  TRANSFERS_HISTORY_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_HISTORY_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  TRANSFERS_HISTORY_TABLE_CREATED_COLUMN_TITLE: "Bajaruvchi",
  TRANSFERS_HISTORY_TABLE_APPROVER_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_HISTORY_TABLE_STATUS__STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_HISTORY_TABLE_SUBJECT_COLUMN_TITLE: "Mavzu",
  TRANSFERS_HISTORY_TABLE_APPROVED_BY_BY_COLUMN_TITLE: "Avtorizator",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_BANK_CODE_COLUMN_TITLE: "Korrespondent bank kodi",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_NAME: "Korrespondent nomi",
  TRANSFERS_HISTORY_TABLE_CREATED_CREATED_COLUMN_TITLE: "Yaratildi",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_ACCOUNT: "Korrespondent hisobraqami",
  TRANSFERS_HISTORY_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Benefitsiar hisobraqami",
  TRANSFERS_HISTORY_TABLE_DEBIT_AMOUNT: "Miqdorni debitlash",
  TRANSFERS_HISTORY_TABLE_CREDIT_AMOUNT: "Miqdorni kreditlash",
  TRANSFERS_HISTORY_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Tugallangan sana",

  //Corporate role  Transfers page Payment Claim Tab Received Payment Claims

  TRANSFERS_PAYMENT_CLAIM_SENT_SUBTAB_TITLE: "To’lov so’rovnomasini yuborish",
  TRANSFERS_PAYMENT_CLAIM_CREATE_SUBTAB_TITLE: "Yangi to’lov so’rovnomasini yaratish",
  TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE: "Qabul qilingan to’lov so’rovnomasi",

  TRANSFERS_PAYMENT_CLAIM_TO_FIELD_LABEL_TITLE: "gacha",
  TRANSFERS_PAYMENT_CLAIM_SEARCH_BUTTON_TITLE: "Qidirish",
  TRANSFERS_PAYMENT_CLAIM_FROM_FIELD_LABEL_TITLE: "dan",
  TRANSFERS_PAYMENT_CLAIM_FILTER_FIELD_LABEL_TITLE: "Status bo’yicha filtrlash",

  TRANSFERS_PAYMENT_CLAIM_TABLE_TYPE_COLUMN_TITLE: "Turi",
  TRANSFERS_PAYMENT_CLAIM_TABLE_STATUS_COLUMN_TITLE: "Status",
  TRANSFERS_PAYMENT_CLAIM_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  TRANSFERS_PAYMENT_CLAIM_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  TRANSFERS_PAYMENT_CLAIM_TABLE_ACTIONS_COLUMN_TITLE: "Harakatlar",
  TRANSFERS_PAYMENT_CLAIM_TABLE_PAYMENT_COLUMN_TITLE: "To’lov so’rovnomasi raqami",
  TRANSFERS_PAYMENT_CLAIM_TABLE_SENDER_COLUMN_TITLE: "Yuboruvchi hisobraqami",
  TRANSFERS_PAYMENT_CLAIM_TABLE_RECEIVED_COLUMN_TITLE: "Qabul qilingan sana va vaqti",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Benefitsiar nomi",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_BANK_COLUMN_TITLE: "Benefitsiar bank kodi",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Benefitsiar hisobraqami",

  TRANSFERS_PAYMENT_CLAIM_ACCEPT_BUTTON_TITLE: "",
  TRANSFERS_PAYMENT_CLAIM_REJECT_BUTTON_TITLE: "",

  // payment claim

  PAYMENT_CLAIM_DEPLOY_BUTTON_TITLE: "Faylingizni yuklang",
  PAYMENT_CLAIM_RECALL: "Bekor qilish",
  PAYMENT_CLAIM_CREATED_SEND: "To'lov da'vosi yuboriladi",
  PAYMENT_CLAIM_CREATED: "Toʻlov daʼvosi yaratildi",
  PAYMENT_CLAIM_ERROR_BRANCH: "Filiallar o'rtasida aloqa qilish mumkin emas",

  // Conversion Page
  CONVERSION_SUBMIT_NOTIFICATION: "Konversiya jo’natildi",
  CONVERSION_CLIENT_SAVED: "Mijozning ma’lumoti saqlandi",
  CONVERSION_CLIENT_NO_CHANGES: "Mijozning ma’lumotida o’zgarish yo’q",
  CONVERSION_FAVORITES_TAB_TITLE: "Tanlangan",
  CONVERSION_EXCHANGE_RATES_TAB_TITLE: "Valyuta kursi",
  CONVERSION_FCY_TO_UZS_CONVERSION_TAB_TITLE: "Chet el valyutasini so’mga konvertatsiya qilish",
  CONVERSION_UZS_TO_FCY_CONVERSION_TAB_TITLE: "So’mni chet el valyutasiga konvertatsiya qilish",
  CONVERSION_FCY_TO_FCY_CONVERSION_TAB_TITLE:
    "Chet el valyutasini boshqa chet el valyutasiga konvertatsiya qilish",
  CONVERSION_FCY_TO_UZS_SELL_TAB_TITLE: "Chet el valyutasini bankka sotish",
  CONVERSION_UZS_TO_FCY_SELL_TAB_TITLE: " Chet el valyutasini O’zRVB da sotish",

  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_TITLE: "SOTIB OLISH",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_IN_TITLE: "So’mda",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PERCENT_TITLE: "%",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_TITLE: "SOTISH",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DETAIL_TITLE: "Batafsil ma’lumot",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_TITLE:
    "O’zbekiston Respublikasi valyuta birjasida (O’zRVB) chet el valyutasini sotish bo’yicha BOSH SHARTNOMAGA 1 – Ilova",
  CONVERSION_FCY_TO_UZS_FCY_COMMISSION_TITLE: "Komissiya",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_AMOUNT_LABEL_TITLE: "Sotib olish miqdori",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_MINIMAL_RATE_LABEL_TITLE: "Minimal sotish kursi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_CURRENCY_LABEL_TITLE: "Sotib olish valyutasi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_EXCHANGE_RATE_LABEL_TITLE: "Valyuta kursi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DEBIT_ACCOUNT_LABEL_TITLE: "Hisobvaraqni debitlash",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Sotish miqdori",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_RATE_LABEL_TITLE: "Komissiya stavkasi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Sotish valyutasi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_AMOUNT_LABEL_TITLE: "Komissiya miqdori",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_ACCOUNT_LABEL_TITLE: "Komissiya hisobvarag’i",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya sanasi",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PURPOSE_OF_CONVERSION_LABEL_TITLE:
    "Konversiya qilishdan maqsad",
  CONVERSION_FCY_TO_UZS_FCY_TO_NUMBER_OF_GENERAL_AGR_LABEL_TITLE: "Umumiy shartnomalar soni",
  CONVERSION_FCY_TO_UZS_FCY_TO_NAME_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Kelishuvga mas’ul bo’lgan xodimning nomi",
  CONVERSION_FCY_TO_UZS_FCY_TO_DATE_OF_GENERAL_AGR_LABEL_TITLE: "Umumiy shartnoma sanasi",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSITION_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Kelishuvga mas’ul bo’lgan xodimning lavozimi",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSTAL_ADDRESS_LABEL_TITLE: "Pochta manzili",
  CONVERSION_FCY_TO_UZS_FCY_TO_DIRECTOR_OF_THE_ORG_LABEL_TITLE: "Tashkilot direktori",
  CONVERSION_FCY_TO_UZS_FCY_TO_TELEPHONE_NUMBER_LABEL_TITLE: "Telefon raqami",
  CONVERSION_FCY_TO_UZS_FCY_TO_CHIEF_ACCOUNTANT_OF_THE_ORG_TITLE: "Tashkilot bosh buxgalteri",
  CONVERSION_FCY_TO_UZS_FCY_TO_FAX_NUMBER_TITLE: "Faks raqami",
  CONVERSION_FCY_TO_UZS_FCY_TO_SAVE_BUTTON: "Saqlash",
  CONVERSION_FCY_TO_UZS_FCY_TO_CANCEL_BUTTON: "Bekor qilish",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_INFO_OF_CONVERSION_LABEL_TITLE:
    "Konversiya summasi quyidagi bank transit hisobvarag’ida saqlanadi",

  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_TITLE: "SOTIB OLISH",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_TITLE: "SOTISH",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_TITLE:
    "Chet el valyutasini sotib olish va sotish(konversiya qilish) bo’yicha BOSH SHARTNOMAGA 1 - Ilova",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_CURRENCY_FIELD_LABEL_TITLE: "Sotib olish valyutasi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Sotish miqdori",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Sotish valyutasi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_EXCHANGE_RATE_FIELD_LABEL_TITLE: "Valyuta kursi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Hisobvaraqni debitlash",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_MINIMAL_SELLING_EXCHANGE_FIELD_LABEL_TITLE:
    "Minimal sotish kursi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Hisobvaraqni kreditlash",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_RATE_FIELD_LABEL_TITLE: "Komissiya stavkasi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_AMOUNT_FIELD_LABEL_TITLE: "Komissiya miqdori",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Tranzaksiya sanasi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_ACCOUNT_FIELD_LABEL_TITLE:
    "Komissiya hisobvarag’i",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PURPOSE_OF_CONVERSION_FIELD_LABEL_TITLE:
    "Konversiya qilish maqsadi",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_AMOUNT_FIELD_LABEL_TITLE: "Sotib olish miqdori",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CONVERSION_AMOUNT_ACCOUNT_FIELD_LABEL_TITLE:
    " Konversiya summasi quyidagi bank transit hisobvarag’ida saqlanadi ",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PERCENT_TITLE: "%",

  CONVERSION_FCY_TO_UZS_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  CONVERSION_FCY_TO_UZS_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  CONVERSION_FCY_TO_UZS_TABLE_MINIMUM_RATE_COLUMN_TITLE: "Minimal stavka",
  CONVERSION_FCY_TO_UZS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Valyuta kursi",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "dan",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_CREATED_BY_FIELD_LABEL_TITLE: "Bajaruvchi",
  CONVERSION_FCY_TO_UZS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Sotib olish miqdori so’mda",
  CONVERSION_FCY_TO_UZS_TABLE_COMMISSION_RATE_COLUMN_TITLE: "Komissiya stavkasi",
  CONVERSION_FCY_TO_UZS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE:
    "Chet el valyutasidagi hisobvaraqni debitlash",
  CONVERSION_FCY_TO_UZS_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "So’mdagi hisobvaraqni kreditlash",
  CONVERSION_FCY_TO_UZS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Chet el valyutasida sotish miqdori",
  CONVERSION_FCY_TO_UZS_TO_BANK_BUTTON_GROUP_TITLE:
    "Chet el valyutasini sotish uchun so’rov yaratish",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_FIND_DOCUMENTS_FIELD_LABEL_TITLE: "Statusli hujjatlarni topish",
  CONVERSION_FCY_TO_UZS_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bankdan sharhlar",

  CONVERSION_FCY_TO_UZS_ON_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari ish kunlari soat 09:00 dan 16:30 gacha qabul qilinadi (Toshkent vaqti)",  
  CONVERSION_FCY_TO_UZS_ON_SHORTENED_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari bayram oldi qisqartirilgan ish kunlari soat 09:00 dan 15:30 gacha qabul qilinadi (Toshkent vaqti)",

  CONVERSION_UZS_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_UZS_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bankdan sharhlar",
  CONVERSION_UZS_TO_FCY_TABLE_TITLE: "Mijoz ma’lumoti",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_COLUMN_TITLE: "Raqam",
  CONVERSION_UZS_TO_FCY_CREATE_REQUEST_AT_UZ_RCE_BUTTON_TITLE:
    "O’zRVB da chet el valyutasini sotib olish uchun so’rov yaratish",

  CONVERSION_UZS_TO_FCY_ON_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari ish kunlari soat 09:00 dan 16:30 gacha qabul qilinadi (Toshkent vaqti)",  
  CONVERSION_UZS_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari bayram oldi qisqartirilgan ish kunlari soat 09:00 dan 15:30 gacha qabul qilinadi (Toshkent vaqti)",
  

  CONVERSION_UZS_TO_FCY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  CONVERSION_UZS_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  CONVERSION_UZS_TO_FCY_TABLE_BUY_AMOUNT_COLUMN_TITLE: "Sotib olish miqdori",
  CONVERSION_UZS_TO_FCY_TABLE_COMMISSION_COLUMN_TITLE: "KOMISSIYA",
  CONVERSION_UZS_TO_FCY_TABLE_DETAILS_COLUMN_TITLE: "BATAFSIL MA’LUMOT",
  CONVERSION_UZS_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "avtorizator",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Maksimal stavka",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_UZS_COLUMN_TITLE: "So’mdagi hisobvaraq",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_COLUMN_TITLE: "Chet el valyutasidagi hisobvaraq",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_AMOUNT_COLUMN_TITLE: "So’mdagi maksimal miqdor",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "Shartnoma # va sanasi",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_COLUMN_TITLE: "So’mdagi  zaxira hisobvarag’i",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVED_AMOUNT_IN_UZS_COLUMN_TITLE: "So’mdagi zaxira miqdori",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_COLUMN_TITLE:
    "Chet el valyutasidagi konversiya hisobvarag’i",
  CONVERSION_UZS_TO_FCY_TABLE_DEBIT_ACCOUNT_IN_UZS_TITLE: "So’mdagi hisobvaraqni debitlash",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_TITLE: "So’mdagi zaxira hisobvarag’i",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_TITLE:
    " Chet el valyutasidagi konversiya hisobvarag’i ",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_TITLE: "Chet el valyutadagi hisobvaraq",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_EQUIPMENT: "1) uskunalar importi uchun;",
  CONVERSION_UZS_TO_FCY_PURPOSE: "Maqsad",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_COMPONENTRY: "2) tarkibiy qismlar importi uchun;",
  CONVERSION_UZS_TO_FCY_RAW_MATERIALS: "3) xom ashyo importi uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_SERVICE: "4) xizmatlar importi uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_CONSUMER_GOODS: "5) iste’mol mollari importi uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_SERVE_ATTACHED:
    "6) jalb qilingan xorijiy kreditlarga xizmat ko’rsatish;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_EFFECT_LEASING_PAYMENTS:
    "7) lizing shartnomasi bo’yicha lizing to’lovlarini amalga oshirish;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION:
    "8) foyda, dividendlar va boshqa xorijiy investorlarning daromadlarini repatriatsiyasi;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION_OF_EXCESS:
    "9) xarajatlardan ortgan daromadlarni repatriatsiya qilish uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_PAY_TRAVELLING: "10) xizmat safari xarajatlari uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_TRANSFERRING_FUNDS:
    "11) ochiq elektron birja savdolarida tovarlar sotishdan tushgan mablag’larni o’tkazish uchun;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_OTHER: "12) boshqa maqsadlar uchun;",
  CONVERSION_UZS_TO_FCY_TITLE:
    "Biz quyidagi shartlar asosida chet el valyutasini bizning nomimizdan va bizning hisobimizdan sotib olinishiga topshiriq beramiz",
  CONVERSION_UZS_TO_FCY_ATTACHE_TITLE: "Biriktirilgan hujjatlar",
  CONVERSION_UZS_TO_FCY_FORM_BUY_AMOUNT: "Xarid miqdori",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_BUY_RATE: "So’mdagi maksimal xarid kursi",
  CONVERSION_UZS_TO_FCY_FORM_RESERVED_AMOUNT:
    "Sotib olish kursi bo’yicha so’mda sotib olishning umumiy miqdori",
  CONVERSION_UZS_TO_FCY_FORM_NUMBER_AND_DATE: "Shartnoma # va sanasi",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_AMOUNT_UZS:
    "Arizada ko’rsatilgan stavka bo’yicha miqdor (milliy valyuta – so’mda)",
  CONVERSION_UZS_TO_FCY_FORM_BANKS_COMMISSION: "Konversiya uchun bank komissiyasi",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_TITLE: "Biriktirilgan hujjat",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_1: "#1 Hujjat nomi",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_2: '"#2 Hujjat nomi"',
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_3: '"#3 Hujjat nomi"',
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_4: '"#4 Hujjat nomi"',
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_5: '"#5 Hujjat nomi"',
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_6: '"#6 Hujjat nomi"',
  CONVERSION_ATTECH_TITLE:
    "Konversiya qilish uchun hujjatni valyuta nazorati boshqarmasiga quyidagi e-pochta manziliga yuboring",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_BUTTON: "Biriktirish",
  CONVERSION_UZS_TO_FCY_SUBMIT_ERROR: "Ushbu valyutaning kursi belgilanmagan",
  CONVERSION_UZS_TO_FCY_FORM_SUBMIT_BUTTON: "Taqdim etish",
  CONVERSION_UZS_TO_FCY_CLIENT_INFO: "Mijoz ma’lumoti",
  CONVERSION_UZS_TO_FCY_FAVORITES: "Tanlanganlar chet el valyutasini so’mga",
  CONVERSION_UZS_TO_FCY_PRINT: "Chop etish",
  CONVERSION_UZS_TO_FCY_SAVE_FAVORITES: "Tanlanganda saqlash",
  CONVERSION_UZS_TO_FCY_UPLOAD_FROM_EXCEL: " Excel dan yuklash",
  CONVERSION_UZS_TO_FCY_SAVE_PDF: "PDF da saqlash",

  CONVERSION_FCY_TO_FCY_ON_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari ish kunlari soat 09:00 dan 16:30 gacha qabul qilinadi (Toshkent vaqti)",  
  CONVERSION_FCY_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Xorijiy valyutadagi to’lov topshiriqnomalari bayram oldi qisqartirilgan ish kunlari soat 09:00 dan 15:30 gacha qabul qilinadi (Toshkent vaqti)",
  
  CONVERSION_FCY_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Status",
  CONVERSION_CREDIT_DEBIT_ARE_SAME: "Kredit va Debit hisobvaraqlar bir xil",
  CONVERSION_FCY_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  CONVERSION_FCY_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  CONVERSION_FCY_TO_FCY_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Valyuta kursi",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Sotib olish miqdori",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Sotish miqdori",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Sotib olish valyutasi",
  CONVERSION_FCY_TO_FCY_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Hujjat raqami",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Sotish valyutasi",
  CONVERSION_FCY_TO_FCY_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE:
    "Chet el valyutasidagi hisobvaraqni kreditlash",
  CONVERSION_FCY_TO_FCY_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE:
    " Chet el valyutasidagi hisobvaraqni debitlash",
  CONVERSION_FCY_TO_FCY_CREATE_APPLICATION_BUTTON_GROUP_TITLE:
    "Chet el valyutasidan boshqa chet el valyutasiga konversiya qilish uchun yangi ariza yaratish",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_TITLE:
    "Ommaviy oferta – shartnomaga rozilik bildirish to’g’risidagi bank",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_SUBTITLE:
    "Ushbu ariza orqali men bir valyutani boshqa valyutaga konversiya qilish yuzasidan xizmat ko’rsatish bo’yicha Ommaviy oferta shartlari bilan tanishib chiqganimni, hamda uning shartlariga roziligimni, kelgusida Bankka hech qanday e’tirozlarim bo’lmasligini, shuningdek ko’rsatilgan xizmatlar uchun keraklik miqdordagi mablag’larni to’g’ridan – to’g’ri yechib olinishiga o’z roziligimni bildiraman.",
  CONVERSION_FCY_TO_FCY_TITLE:
    "Bir turdagi chet el valyutasini boshqa turdagi chet el valyutasiga konversiya qilish bo’yicha xizmat ko’rsatish uchun Ommaviy ofertaga 1 – Ilova",
  CONVERSION_FCY_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Bankdan sharhlar",

  CONVERSION_FAVORITES_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  CONVERSION_FAVORITES_TABLE_NAME_COLUMN_TITLE: "Hujjat nomi",
  CONVERSION_FAVORITES_TABLE_NUMBER_COLUMN_TITLE: "Raqam",
  CONVERSION_FAVORITES_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Hujjat nomi",
  CONVERSION_FAVORITES_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni debitlash",
  CONVERSION_FAVORITES_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Sotib olish miqdori",
  CONVERSION_FAVORITES_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Sotish miqdori",
  CONVERSION_FAVORITES_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Hisobvaraqni kreditlash",
  CONVERSION_FAVORITES_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Sotib olish valyutasi",
  CONVERSION_FAVORITES_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Sotish valyutasi",

  CONVERSION_MINIMUM_RATE_NULL_TITLE: "Ushbu valyutaning kursi belgilanmagan",
  CONVERSION_IDN_NUMBER_TITLE: "Idn Raqami",
  CONVERSION_IDN_NUMBER_TIN_OF_BANK_FIELD_TITLE: "Bankning TIN",
  CONVERSION_IDN_NUMBER_TIN_OF_CUSTOMER_FIELD_TITLE: "Buyurtmachining TIN",
  CONVERSION_IDN_NUMBER_CONTRACT_NUMBER_TITLE: "IDN ning qolgan 9 ta raqami",

  // YEISVO PAGE
  YEISVO_HEADER_TITLE: "Shartnomalar ro’yxati",
  YEISVO_HEADER_SEARCH_BUTTON_TITLE: "Qidirish",

  YEISVO_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Status",
  YEISVO_CONTRACTS_TABLE_BANK_INN_COLUMN_TITLE: "Bank INN",
  YEISVO_CONTRACTS_TABLE_BANK_CODE_COLUMN_TITLE: "Bank kodi",
  YEISVO_CONTRACTS_TABLE_IDN_CONTRACT_COLUMN_TITLE: "Shartnoma ID raqami",
  YEISVO_CONTRACTS_TABLE_CONTRACT_NUMBER_COLUMN_TITLE: "Shartnoma raqami",
  YEISVO_CONTRACTS_TABLE_DATE_OF_CONTRACT_COLUMN_TITLE: "Shartnoma sanasi",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_FOR_NAME_COLUMN_TITLE: "Chet ellik hamkor",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_UZ_NAME_COLUMN_TITLE: "Shartnoma egasi",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_COUNTRY_COLUMN_TITLE: "Xorijiy hamkor mamlakati",

  YEISVO_CLIENTS_INFORMATION_TITLE: "Shartnoma ma'lumotlari",
  YEISVO_CLIENTS_INFORMATION_BANK_FUNDS_TITLE: "Mablag'larni qabul qilish",
  YEISVO_CLIENTS_INFORMATION_BANK_EMPLOYEE_FULL_NAME: "Bank xodimi t'oliq ismi sharifi",
  YEISVO_CLIENTS_INFORMATION_NUMBER_IN_ABS: "ABS to'lov raqami",
  YEISVO_CLIENTS_INFORMATION_CONDITIONS: "Qabul qilish shartlari",
  YEISVO_CLIENTS_INFORMATION_OPENING_DATE: "Qabul qilish sanasi",
  YEISVO_CLIENTS_INFORMATION_INVOICE_VALUE: "Faktura qiymati",
  YEISVO_CLIENTS_INFORMATION_AMOUNT: "Miqdori",
  YEISVO_CLIENTS_INFORMATION_MEASURE: "O'lchov",
  YEISVO_CLIENTS_INFORMATION_WEIGHT: "Og'irligi",
  YEISVO_CLIENTS_INFORMATION_TN_VED: "TN VED",
  YEISVO_CLIENTS_INFORMATION_SERIAL_NUMBER: "Tartib raqami",
  YEISVO_CLIENTS_INFORMATION_NAME: "Mahsulot nomi",
  YEISVO_CLIENTS_INFORMATION_DECLARATION_TITLE:
    "Yuk bojxona deklaratsiyasi to'g'risidagi ma'lumotlar",
  YEISVO_CLIENTS_INFORMATION_CONTRACT_CURRENCY_CODE: "Shartnoma valyuta kodi",
  YEISVO_CLIENTS_INFORMATION_TOTAL_INVOICE_VALUE: "Hisob-fakturaning umumiy qiymati",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_REGIME_CODE: "Bojxona rejimi kodi",
  YEISVO_CLIENTS_INFORMATION_REGISTERATION_DATE: "Deklaratsiyani ro'yxatdan o'tkazish sanasi",
  YEISVO_CLIENTS_INFORMATION_REGISTERED_NUMBER: "Deklaratsiyaning ro'yxatga olish raqami",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_OFFICE_CODE: "Bojxona pochta indeksi",
  YEISVO_CLIENTS_INFORMATION_FORM_TITLE: "Mijoz ma’lumoti",
  YEISVO_CLIENTS_INFORMATION_FORM_OKPO_FIELD_LABEL_TITLE: "OKPO",
  YEISVO_CLIENTS_INFORMATION_FORM_OKONH_FIELD_LABEL_TITLE: "OKONH",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_FIELD_LABEL_TITLE: "Shartnoma #",
  YEISVO_CLIENTS_INFORMATION_FORM_IDN_CONTRACT_FIELD_LABEL_TITLE: "Shartnoma IDN",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_TIN_FIELD_LABEL_TITLE: "Pudratchi STIR",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_AREA_FIELD_LABEL_TITLE: "Pudratchi manzili",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_PHONE_FIELD_LABEL_TITLE: "Pudratchi telefoni",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_OF_CONTRACT_FIELD_LABEL_TITLE: "Shartnoma sanasi",
  YEISVO_CLIENTS_INFORMATION_FORM_TYPE_OF_CONTRACT_FIELD_LABEL_TITLE: "Shartnoma turi",
  YEISVO_CLIENTS_INFORMATION_FORM_SUBJECT_OF_CONTRACT_FIELD_LABEL_TITLE: "Shartnoma mavzusi",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE: "Shartnoma valyutasi 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Xorijiy hamkor bankning nomi",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_AND_TIME_YEISVO_FIELD_LABEL_TITLE:
    "TSOYEAT ga ma’lumotlar kiritilgan sana va vaqti",
  YEISVO_CLIENTS_INFORMATION_FORM_TERM_OF_THE_CONTRACT_FIELD_LABEL_TITLE:
    "Shartnoma amal qilish muddati",
  YEISVO_CLIENTS_INFORMATION_FORM_GENERAL_NAME_OF_GOODS_FIELD_LABEL_TITLE:
    "Tovarlarning umumiy nomi",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_1_FIELD_LABEL_TITLE: "To’lov valyutasi 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_CONTRACT_HOLDER_FIELD_LABEL_TITLE:
    "Shartnoma egasining nomi",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_FOREIGN_PARTNER_FIELD_LABEL_TITLE: "Xorijiy hamkor nomi",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Xorijiy hamkor mamlakati",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_PARTNER_FIELD_LABEL_TITLE:
    "Xorijiy hamkorning yuridik manzili",
  YEISVO_CLIENTS_INFORMATION_FORM_SIGN_OF_CONTRACT_ACTIVITY_FIELD_LABEL_TITLE:
    "Shartnoma faoliyatining belgisi",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Shartnoma miqdori valyuta 1 da",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Shartnoma miqdori valyuta 2 da",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_PARENT_ORGANIZATION_FIELD_LABEL_TITLE:
    "Shartnoma egasining yuqori turuvchi tashkiloti nomi",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_1_FIELD_LABEL_TITLE:
    "To’lanishi kerak bo’lgan mablag’ Shartnoma valyutasi 1 da",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_2_FIELD_LABEL_TITLE:
    "To’lanish kerak bo’lgan mablag’ Shartnoma valyutasi 2 da",
  YEISVO_CLIENTS_INFORMATION_FORM_BANK_CODE_OF_AN_AUTHORIZED_BANK_FIELD_LABEL_TITLE:
    "Vakolatli bankning bank kodi",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_1_FIELD_LABEL_TITLE:
    "Debitor qarzdorlik miqdori Shartnoma valyutasi 1 da",
  YEISVO_CLIENTS_INFORMATION_FORM_TOTAL_AMOUNT_OF_CCD_FIELD_LABEL_TITLE: "Umumiy qiymati YBD",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Debitor qarzdorlik miqdori Shartnoma valyutasi 2 da",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Muddati o’tgan debitor qarzdorlik miqdori Shartnoma valyutasi 1 da",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Umumiy summa YBD",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Muddati o’tgan debitor qarzdorlik miqdori Shartnoma valyutasi 1 da",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Muddati o’tgan debitor qarzdorlik miqdori Shartnoma valyutasi 2 da",

  YEISVO_CONTRACT_SPECIFICATIONS_TITLE: "Shartnoma spesifikatsiyasi",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Miqdor",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Seriya raqami",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_TAG_FIELD_LABEL_TITLE: "Spesifikatsiya Tegi",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_DATE_FIELD_LABEL_TITLE:
    "Spesifikatsiya sanasi",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_NUMBER_FIELD_LABEL_TITLE:
    "Spesifikatsiya raqami",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Kiritish sanasi va vaqti",

  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_TN_CODE_COLUMN_TITLE: "SMSga muvofiq mahsulot kodi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_ITEMS_NAME_COLUMN_TITLE: "Element nomi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_SERIAL_NO_COLUMN_TITLE: "Tovar raqami",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_UNIT_CODE_COLUMN_TITLE: "Birlik kodi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_QUANTITY_AGRMNO_COLUMN_TITLE: "Mahsulot hajmi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_CURR_CODE_COLUMN_TITLE: "Tovar narxi valyutasi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_COST_COLUMN_TITLE: "Birlik narxi",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_AMOUNT_COLUMN_TITLE: "Tovarning umumiy qiymati",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_INS_TIME_COLUMN_TITLE:
    "EEISVO ga ma'lumotlarni kiritish sanasi va vaqti",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_STATUS_COLUMN_TITLE: "Hujjat holati",

  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_COLUMN_TITLE: "Bojxona pochta indeksi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_COLUMN_TITLE: "Deklaratsiyaning ro'yxatga olish raqami",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_COLUMN_TITLE: "Deklaratsiyani ro'yxatdan o'tkazish sanasi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_COLUMN_TITLE: "Yuborgan mamlakat kodi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_COLUMN_TITLE: "Tranzaksiya tabiati kodi",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_COLUMN_TITLE: "Etkazib berish sanasi",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_COLUMN_TITLE: "Bojxona chegarasini kesib o'tish sanasi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_COLUMN_TITLE: "Eksportchi nomi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_COLUMN_TITLE: "Shartnoma valyuta kursi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_COLUMN_TITLE: "Importer nomi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_COLUMN_TITLE: "Shartnoma egasining ismi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_COLUMN_TITLE: "Bojxona rejimi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_COLUMN_TITLE: "Shartnoma valyuta kodi",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_COLUMN_TITLE: "Jami hisob-faktura qiymati",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_COLUMN_TITLE: "Ketish mamlakat kodi",

  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G32_COLUMN_TITLE: "Element seriya raqami",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G33_COLUMN_TITLE:
    "Tashqi iqtisodiy faoliyatning tovar nomenklaturasi bo'yicha mahsulot kodi",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G42_COLUMN_TITLE: "Invoys qiymati",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37C_COLUMN_TITLE: "Protsedura kodeksi",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31NAME_COLUMN_TITLE: "Mahsulot nomi",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31AMOUNT_COLUMN_TITLE: "qabul qilingan tovarlar miqdori",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G41_COLUMN_TITLE: "Qo'shimcha o'lchov birligi kodi",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G38_COLUMN_TITLE: "Tovar og'irligi (sof)",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37B_COLUMN_TITLE: "Ketish mamlakat kodi",

  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TITLE:
    "Inkotermlarga muvofiq tovarlarni yetkazib berish uchun asos",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DIGITAL_CODE_TITLE: "Raqamli yetkazib berish kodi",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DESTINATION_TITLE: "Boradigan joy",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE:
    "Qo’shimcha shartnoma raqami",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SERIAL_NUMBER_TITLE: "Seriya raqami",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Kiritish sanasi va vaqti",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_CONTRACT_ACT_FORM_TITLE: "Xizmatlar/ishlar ko'rsatish to'g'risidagi qonun",
  YEISVO_CONTRACT_CALC_FORM_TITLE: "Hisoblash shakli",
  YEISVO_CONTRACT_CALC_FORM_TABLE_PREPAYMENT_TERMS_TITLE: "To’lov shartlari",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DUE_DATE_TITLE: "Muddati",
  YEISVO_CONTRACT_CALC_FORM_TABLE_LAST_PAYMENT_DATE_TITLE: "Oxirgi to’lov sanasi",
  YEISVO_CONTRACT_CALC_FORM_TABLE_TIME_OF_IMPORT_AFTER_PAYMENT_TITLE:
    "To’lovdan keyin import vaqti",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_OF_IMPORT_AFTER_PAYMENT_TITLE:
    "To’lovdan keyin import sanasi",
  YEISVO_CONTRACT_CALC_FORM_TABLE_IMPORT_PERIOD_AFTER_LETTER_OF_CREDIT_OPENING_TITLE:
    "Akkreditiv ochilgandan keyingi import davri",
  YEISVO_CONTRACT_CALC_FORM_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE:
    "Qo’shimcha kelishuv raqami",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Kiritish sanasi va vaqti",
  YEISVO_CONTRACT_CALC_FORM_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_PAYMENT_BY_CONTRACT_TITLE: "Shartnoma bo’yicha to’lov",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_FIELD_LABEL_TITLE: "To’lov sanasi",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Miqdori",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_FUNDS_SOURCE_FILED_LABEL_TITLE: "Mablag’ manbai",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_NUMBER_IN_ABC_FIELD_LABEL_TITLE: "ABC da to’lov raqami",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Seriya raqami",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_BANK_EMPLOYEE_FULLNAME_FIELD_LABEL_TITLE:
    "Bank xodimining to’liq nomi",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Kiritish sanasi va vaqti",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",

  YEISVO_TABLE_ID_FIELD_LABEL_TITLE: "#",
  YEISVO_TABLE_STATUS_FIELD_LABEL_TITLE: "Status",
  YEISVO_TABLE_TN_VED_FIELD_LABEL_TITLE: "TN VED",
  YEISVO_TABLE_VOLUME_FIELD_LABEL_TITLE: "Hajmi",
  YEISVO_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  YEISVO_TABLE_UNIT_PRICE_FIELD_LABEL_TITLE: "Narxi",
  YEISVO_TABLE_TOTAL_COST_FIELD_LABEL_TITLE: "Umumiy narxi",
  YEISVO_TABLE_MEASUREMENT_FIELD_LABEL_TITLE: "O’lchov",
  YEISVO_TABLE_NAME_OF_PRODUCT_FIELD_LABEL_TITLE: "Mahsulot nomi",
  YEISVO_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE: "Kiritish sanasi va vaqti",
  YEISVO_REGION_FIELD_LABEL_TITLE: "Hudud",

  /// New Yeisvo

  YEISVO_CONTRACT_AGREEMENTS_TITLE: "Additional agreement",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Additional Agreement Date",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_MVESDATE_FIELD_LABEL_TITLE:
    "Date of registration of the additional agreement in MFERIT (not used)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AGRMSUBJECT_FIELD_LABEL_TITLE:
    "Subject of the additional agreement",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Contract currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Contract currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE1_FIELD_LABEL_TITLE: "Settlement currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE2_FIELD_LABEL_TITLE: "Settlement currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORNAME_FIELD_LABEL_TITLE: "Name of foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of foreign partner (dicCountry directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Name of the foreign partner's bank",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of the foreign partner's bank (dicCountry directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the foreign partner's bank",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE:
    "Bank details of a foreign partner",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORUZNAME_FIELD_LABEL_TITLE:
    "Name of the contract holder",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Contract amount in contract currency 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Contract amount in contract currency 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CNTRSUBJECT_FIELD_LABEL_TITLE:
    "Contract subject (dicContractSubject directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_PRODUCTSNAME_FIELD_LABEL_TITLE: "Common name of goods",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECTYPE_FIELD_LABEL_TITLE:
    "Contract performance period (dicTerm directory)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECDATE_FIELD_LABEL_TITLE: "Contract Execution Date",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CREDITRATE_FIELD_LABEL_TITLE: "Loan percentage (per annum)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CSTDATE_FIELD_LABEL_TITLE:
    "Date of registration of the additional agreement with the customs authorities",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Additional agreement serial number",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Number according to the directory of industrial zones",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_BARTERTERM_TITLE: "Form of mutual settlements",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Payment type",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Last export date",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Export term",
  YEISVO_CONTRACT_BARTERTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE: "Import term",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_BARTERTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_BARTERTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_BARTERTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_COMMISSIONS_TITLE: "Foreign bank commission for funds transfer",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Commission amount in receipt currency",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Payment number from which the commission is deducted",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Fund receipt number from which the commission is deducted",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_COMPENSATIONS_TITLE: "Receipt of insurance compensation under the contract",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of receipt of insurance compensation",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_POLICYNO_FIELD_LABEL_TITLE:
    "Insurance policy number for which compensation was received",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of insurance compensation",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Insurance compensation currency",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_CONFIRMATION_TITLE: "Confirmations",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the user who confirmed/did not confirm the document",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_CONFIRM_FIELD_LABEL_TITLE: "confirmation sign",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ORGTYPE_FIELD_LABEL_TITLE: "organization type",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_REASON_FIELD_LABEL_TITLE:
    "grounds for not confirming the document",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_DATE_FIELD_LABEL_TITLE: "confirmation date and time",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ACTIONTYPE_FIELD_LABEL_TITLE: "Editing sign",

  YEISVO_CONTRACT_CONSIGNEE_TITLE: "Consignee",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_NAME_FIELD_LABEL_TITLE: "Name of the consignee",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Consignee Country",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_CONSIGNOR_TITLE: "Shipper",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_NAME_FIELD_LABEL_TITLE: "Item Sender Name",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Item Sender Country",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_DECLARATION_TITLE: "Shipping Details",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_FIELD_LABEL_TITLE: "Customs post code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_FIELD_LABEL_TITLE: "Declaration registration number",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_FIELD_LABEL_TITLE: "Declaration registration date",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_FIELD_LABEL_TITLE: "Sender Country Code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_FIELD_LABEL_TITLE: "Transaction nature code",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_FIELD_LABEL_TITLE: "Ship Date",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_FIELD_LABEL_TITLE:
    "Date of crossing the customs border",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_FIELD_LABEL_TITLE: "Name of exporter (sender)",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_FIELD_LABEL_TITLE: "Contract currency rate",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_FIELD_LABEL_TITLE: "Importer (recipient) name",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_FIELD_LABEL_TITLE: "Name of contract holder",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_FIELD_LABEL_TITLE: "Customs regime",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_FIELD_LABEL_TITLE: "Contract currency code",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_FIELD_LABEL_TITLE: "Total invoice value",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_FIELD_LABEL_TITLE: "Departure country code",

  YEISVO_CONTRACT_DECLARATIONGOOD_TITLE: "Goods according to declaration",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G32_FIELD_LABEL_TITLE: "Item serial number",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G33_FIELD_LABEL_TITLE:
    "Product code according to the Commodity Nomenclature of Foreign Economic Activity",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G42_FIELD_LABEL_TITLE: "Invoice value",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37C_FIELD_LABEL_TITLE: "Procedure Code",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31NAME_FIELD_LABEL_TITLE: "Product Name",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31AMOUNT_FIELD_LABEL_TITLE: "quantity of goods received",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G41_FIELD_LABEL_TITLE: "Additional unit code",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G38_FIELD_LABEL_TITLE: "Item weight (net)",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37B_FIELD_LABEL_TITLE: "Preceding mode code",

  YEISVO_CONTRACT_DELEGATE_TITLE:
    "Transfer of rights and obligations under the contract to another resident",
  YEISVO_CONTRACT_DELEGATE_TABLE_REASONTYPE_FIELD_LABEL_TITLE: "Basis for transition",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTNAME_FIELD_LABEL_TITLE:
    "Name of the court that made the decision",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTDSCNDATE_FIELD_LABEL_TITLE: "Court decision date",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNNO_FIELD_LABEL_TITLE:
    "Decision number of the Government of the Republic of Uzbekistan",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNDATE_FIELD_LABEL_TITLE:
    "Date of decision of the Government of the Republic of Uzbekistan",
  YEISVO_CONTRACT_DELEGATE_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINN_FIELD_LABEL_TITLE: "TIN/PINFL of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKID_FIELD_LABEL_TITLE: "MFI bank of the successor",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKPO_FIELD_LABEL_TITLE:
    "OKPO code of the legal successor (not used)",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKONX_FIELD_LABEL_TITLE: "OKED code of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTNAME_FIELD_LABEL_TITLE: "Name of assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTRGNCODE_FIELD_LABEL_TITLE: "Designee Area",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Belonging of the legal successor to the industrial zone",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTADDRESS_FIELD_LABEL_TITLE: "Address of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTPHONE_FIELD_LABEL_TITLE: "Telephone of the assignee",
  YEISVO_CONTRACT_DELEGATE_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_DELEGATE_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in YEISVO",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "A sign of confirmation of the transfer of the contract by the transferring bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "A sign of confirmation of the transfer of the contract by the receiving bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the transferring bank",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the receiving bank user",
  YEISVO_CONTRACT_DELEGATE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_DELEGATE_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "IDN of the contract before the transfer of rights and obligations",
  YEISVO_CONTRACT_DELEGATE_TABLE_PARENTCOMPANYCODE_FIELD_LABEL_TITLE:
    "Code of the ministry/department that includes the economic entity,",
  YEISVO_CONTRACT_DELEGATE_TABLE_NEWIDN_FIELD_LABEL_TITLE:
    "IDN of the new contract (after transfer)",
  YEISVO_CONTRACT_DELEGATE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKINN_FIELD_LABEL_TITLE: "TIN of the successor bank",

  YEISVO_CONTRACT_DELIVERYTERM_TITLE: "Basis for delivery of goods according to Incoterms",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_DESTINATION_FIELD_LABEL_TITLE: "Destination",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INCOTERMSCODE_FIELD_LABEL_TITLE: "Digital delivery code",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_ENDCONTRACT_TITLE: "End contract operations",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_DOCDATE_FIELD_LABEL_TITLE: "End Date",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_REMARK_FIELD_LABEL_TITLE: "Notes",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date entered into the system",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_EXPORTTERM_TITLE: "Export Terms",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTYPE_FIELD_LABEL_TITLE: "Export Type",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Last export date",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Export term",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE:
    "Deadline for receipt of revenue under the terms of the contract",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGDEADLINE_FIELD_LABEL_TITLE: "Lease term",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGRATE_FIELD_LABEL_TITLE: "Leasing percentage",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_DEADLINETYPE_FIELD_LABEL_TITLE: "Revenue due date",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_FARE_TITLE: "Freight to specification",
  YEISVO_CONTRACT_FARE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Freight Charge Currency",
  YEISVO_CONTRACT_FARE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of transport costs",
  YEISVO_CONTRACT_FARE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_FARE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_FINANCEINFO_TITLE: "Fund Sender",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORNAME_FIELD_LABEL_TITLE: "Sender Name",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORCOUNTRYCODE_FIELD_LABEL_TITLE: "Sender Country",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORADDRESS_FIELD_LABEL_TITLE: "Sender's legal address",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORNAME_FIELD_LABEL_TITLE: "Name of the sender's bank",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE: "Sender's bank country",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Legal address of the sender's bank",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE: "Sender's bank details",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_GOODS_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_TNCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_ITEMSNAME_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_UNITCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_QUANTITY_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_COST_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_INSTIME_FIELD_LABEL_TITLE: "",
  YEISVO_CONTRACT_GOODS_TABLE_STATUS_FIELD_LABEL_TITLE: "",

  YEISVO_CONTRACT_GUARANTEE_TITLE: "Foreign bank guarantee",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Guarantee number",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE: "Guarantee opening date",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Guarantee currency",
  YEISVO_CONTRACT_GUARANTEE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the guarantee currency to the contract currency",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Warranty expiration date (date)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Warranty expiration period (number of days)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_GUARANTEE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_GUARANTEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TITLE: "Change guarantee amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Valid Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "New Guarantee Amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the guarantee amount",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TITLE: "Changing the foreign bank guarantee period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Current warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "New warranty expiration date (date)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New warranty expiration date (number of days)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the warranty expiration period",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_IMPORTTERM_TITLE: "Settlement Form",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Import Type",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE: "Payment term",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE: "Last payment date",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE:
    "Import period after payment (number of days)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTDEADLINE_FIELD_LABEL_TITLE:
    "Import date after payment (date)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERMAFTERACCR_FIELD_LABEL_TITLE:
    "Import period after opening of letter of credit (number of days)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_LEASEPAYMENTS_TITLE: "Receipt of interest under the leasing agreement",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of receipt (payment)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Interest amount (leasing amount)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Receipt (payment) currency",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TITLE: "Change letter of credit amount",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Valid amount of letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "New letter of credit amount in letter of credit currency",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the amount of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TITLE: "Changing the term of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current validity period of the letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New validity period of the letter of credit (number of days)",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the validity period of a letter of credit",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_LETTERSOF_CREDIT_TITLE: "Letter of Credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCNO_FIELD_LABEL_TITLE: "Letter of Credit Number",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE:
    "Date of opening of letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Letter of Credit Currency",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Amount of the letter of credit in the currency of the letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Validity period of letter of credit",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_MANUFACTURER_TITLE: "Manufacturer",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_NAME_FIELD_LABEL_TITLE: "Name",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Country",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INN_FIELD_LABEL_TITLE: "INN/PINFL",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_MOVESFROM_EX_TITLE: "Transfer of funds from another contract (mutual settlement)",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Move date",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Settlement currency",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Move Amount",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "IDN of the export contract from which the funds were transferred",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for work (service) in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for work (service) in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "The ratio of the settlement currency to the contract currency",

  YEISVO_CONTRACT_MOVESFROM_IM_TITLE: "Move funds from another contract",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Move date",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Move Amount",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Settlement currency",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "IDN of the import contract from which funds were transferred",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for services (work) in contract currency 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for services (work) in contract currency 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "Ratio of settlement currency to contract currency",

  YEISVO_CONTRACT_MOVESTO_EX_TITLE: "Move funds to another contract",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on assets to be transferred",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of transfer",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE:
    "Movement amount from contract 1",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "IDN of the export contract to which the funds were transferred",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_MOVESTO_IM_TITLE: "Move funds to another contract",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Data on assets to be transferred",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE:
    "Movement amount from contract 1",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Date of transfer",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "IDN of the import contract to which funds are transferred",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYABLE_DROPS_TITLE: "Write-off of accounts payable under the contract",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Write-off currency",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "The amount of accounts payable to be written off in the currency of accounts payable",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for writing off accounts payable",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Write-off date",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_PAYMENT_REFS_TITLE: "Calculation certificate",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Reference number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Reference date",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Information about the receipt of funds",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_GUARANTEENO_FIELD_LABEL_TITLE: "Bank guarantee number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_POLICYNO_FIELD_LABEL_TITLE: "Insurance Policy Number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for issuing a certificate",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_LETTEROFCREDITNO_FIELD_LABEL_TITLE: "Letter of Credit Number",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Reference amount",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_UNUSEDAMOUNT_FIELD_LABEL_TITLE:
    "The balance of the unused certificate amount",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Reference currency",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Reference validity period (date)",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Information about transfers",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TITLE: "Reducing the amount of the settlement certificate",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "New certificate amount",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_REASON_FIELD_LABEL_TITLE:
    "Grounds for reducing the amount of the certificate",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_MAINBRANCHUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the head bank user",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_PAYMENTS_TITLE: "Payment under contract",
  YEISVO_CONTRACT_PAYMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Name of the foreign bank (foreign creditor, lender) from which the payment was made",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Payment Date",
  YEISVO_CONTRACT_PAYMENTS_TABLE_FUNDSOURCETYPE_FIELD_LABEL_TITLE: "Fund Source",
  YEISVO_CONTRACT_PAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Payment Amount",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Payment currency",
  YEISVO_CONTRACT_PAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_PAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Conversion rate of the settlement currency to the contract currency",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Payment amount for goods in contract currency 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of payment for work (services) in contract currency 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of payment for work (services) in contract currency 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "The ratio of the settlement currency to the contract currency",

  YEISVO_CONTRACT_PENALTIES_TITLE: "Ratio of settlement currency to contract currency",
  YEISVO_CONTRACT_PENALTIES_TABLE_PENALTYTYPE_FIELD_LABEL_TITLE: "Type of sanction",
  YEISVO_CONTRACT_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in YEISVO",
  YEISVO_CONTRACT_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of payment (receipt) of the fine",
  YEISVO_CONTRACT_PENALTIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Penalty amount",
  YEISVO_CONTRACT_PENALTIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Penalty currency",
  YEISVO_CONTRACT_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_PERFORMED_SERVICE_TITLE: "Act (confirming document) of services provided",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Act/invoice date",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Act/invoice number",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Contract currency 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Act/invoice amount in contract currency 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Act/invoice amount in contract currency 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Contract Currency 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_IMEXFLAG_FIELD_LABEL_TITLE: "Act attribute",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICIES_TITLE: "Insurance Policy",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCNO_FIELD_LABEL_TITLE: "Insurance Policy Number",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Name of insurance company",
  YEISVO_CONTRACT_POLICIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Policy currency",
  YEISVO_CONTRACT_POLICIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Policy Amount",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Policy expiration date (date)",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Policy expiration date (number of days)",
  YEISVO_CONTRACT_POLICIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_POLICIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICYSUM_CHANGES_TITLE: "Change insurance policy amount",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Effective Policy Sum",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "New policy amount in policy currency",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the policy amount",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_POLICYTIME_CHANGES_TITLE: "Changing the term of the insurance policy",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Current policy expiration date (date)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Current policy expiration date",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "New policy expiration date (date)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "New policy expiration date (number of days)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for changing the expiration date of the policy",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_RECEIVABLE_DROPS_TITLE: "Write-off of receivables under the contract",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Write-off currency",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "The amount of receivables to be written off in the currency of the receivables",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Grounds for writing off accounts receivable",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Write-off date",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_RECEIVABLE_INFOS_TITLE: "Additional information on accounts receivable",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPCURRCODE_FIELD_LABEL_TITLE:
    "Currency of goods under the customs regime of temporary import",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPAMOUNT_FIELD_LABEL_TITLE:
    "The amount of goods under the customs regime of temporary import",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_CUSTOMSMODE_FIELD_LABEL_TITLE: "Customs mode",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_REFUNDS_EXP_TITLE: "Return of previously received funds",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on funds subject to return",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Data on funds subject to return",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Refund Amount",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Refund Date",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_REFUNDS_IMP_TITLE: "Refund of previously paid funds",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCNO_FIELD_LABEL_TITLE: "ABS payment number",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Refund Date",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Refund amount in refund currency",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Return currency",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_SALES_TITLE: "Compulsory Sale Information",
  YEISVO_CONTRACT_SALES_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Data on receipts subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_BENEFITSINFOCODE_FIELD_LABEL_TITLE: "Benefits for mandatory sales",
  YEISVO_CONTRACT_SALES_TABLE_SALETYPE_FIELD_LABEL_TITLE: "Type of compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Date of compulsory sale or entry of information on compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Data on the transfer of money to a contract subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_SALES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Amount of compulsory sale",
  YEISVO_CONTRACT_SALES_TABLE_COMPENSATIONSERIALNO_FIELD_LABEL_TITLE:
    "Data on receipt of insurance compensation subject to mandatory sale",
  YEISVO_CONTRACT_SALES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_SALES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_SALES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",

  YEISVO_CONTRACT_SERVICE_TITLE: "Service in the act",
  YEISVO_CONTRACT_SERVICE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Sequence number",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORNAME_FIELD_LABEL_TITLE:
    "Name of the service provider (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of service (work) provider",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTIONCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Country of provision of services (works)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICENAME_FIELD_LABEL_TITLE: "Name of service (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICETYPE_FIELD_LABEL_TITLE: "Service (work) code",
  YEISVO_CONTRACT_SERVICE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Service (work) price currency",
  YEISVO_CONTRACT_SERVICE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Cost of the service (work)",
  YEISVO_CONTRACT_SERVICE_TABLE_SELFMADEFLAG_FIELD_LABEL_TITLE: "Own services",
  YEISVO_CONTRACT_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TITLE:
    "Fine from the tax authorities for overdue receivables under the contract",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Sequence number of the document in EEISVO",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Penalty payment date",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_AMOUNTSOUM_FIELD_LABEL_TITLE:
    "Amount of fine paid in soums",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TAXES_TITLE: "Tax on income paid by a resident abroad",
  YEISVO_CONTRACT_TAXES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_TAXES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Tax Payment Date",
  YEISVO_CONTRACT_TAXES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Tax amount",
  YEISVO_CONTRACT_TAXES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Tax Paid Currency",
  YEISVO_CONTRACT_TAXES_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Tax payment country",
  YEISVO_CONTRACT_TAXES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "Full name of the bank user who entered (edited) the document",
  YEISVO_CONTRACT_TAXES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_TAXES_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TOLLING_TERM_TITLE: "Mutual settlement of goods (by tolling)",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSTITLE_FIELD_LABEL_TITLE:
    "Names of goods used for mutual settlement",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Currency of settlement",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE1_FIELD_LABEL_TITLE:
    "Conversion rate of mutual settlement currency to contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE2_FIELD_LABEL_TITLE:
    "Conversion rate of mutual settlement currency to contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Amount of service (mutual settlement) in contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Amount of service (mutual settlement) in contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Settlement date",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE1_FIELD_LABEL_TITLE:
    "Ratio of mutual settlement currency to contract currency 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE2_FIELD_LABEL_TITLE:
    "Ratio of settlement currency to contract currency 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSAMOUNT_FIELD_LABEL_TITLE: "Amount of settlement item",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into EEISVO",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Document Status",

  YEISVO_CONTRACT_TRANSFER_TITLE:
    "Transfer of a contract to another bank (to a commodity exchange)",
  YEISVO_CONTRACT_TRANSFER_TABLE_TRANSFERTYPE_FIELD_LABEL_TITLE: "Contract is being transferred",
  YEISVO_CONTRACT_TRANSFER_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Additional agreement number",
  YEISVO_CONTRACT_TRANSFER_TABLE_NEWIDN_FIELD_LABEL_TITLE:
    "IDN of the new contract (after transfer)",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKID_FIELD_LABEL_TITLE:
    "MFO bank/number of the service center where the service contract is transferred",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHINN_FIELD_LABEL_TITLE:
    "TIN of the commodity exchange (fair), where the service contract is transferred,",
  YEISVO_CONTRACT_TRANSFER_TABLE_REMARK_FIELD_LABEL_TITLE: "Note",
  YEISVO_CONTRACT_TRANSFER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Document serial number in YEISVO",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the transferring bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "Full name of the user of the receiving bank/exchange",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Sign of confirmation by the transferring bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Sign of confirmation by the receiving bank",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHNAME_FIELD_LABEL_TITLE:
    "Name of the commodity exchange (fair) where the service contract is transferred,",
  YEISVO_CONTRACT_TRANSFER_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Date and time of data entry into YEISVO",
  YEISVO_CONTRACT_TRANSFER_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "IDN of the contract before transfer of the contract",
  YEISVO_CONTRACT_TRANSFER_TABLE_STATUS_FIELD_LABEL_TITLE: "Document status",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKINN_FIELD_LABEL_TITLE:
    "TIN of the bank where the service contract is transferred",

  // Analytics Page
  ANALYTICS_BALANCE_OF_ALL_ACCOUNTS_TITLE: "Barcha hisobvaraqlar qoldig’i",

  ANALYTICS_BANK_COMMISSIONS_TITLE: "Bank komissiyasi",

  ANALYTICS_COSTS_TITLE: "Xarajatlar",

  // User Info & Settings
  USER_INFO_AND_SETTINGS_TITLE: "Ma’lumot",
  USER_INFO_AND_SETTINGS_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  USER_INFO_AND_SETTINGS_PASSWORD_SETTINGS_TITLE: "Parolni kiritish",
  USER_INFO_AND_SETTINGS_LANGUAGE_SETTINGS_TITLE: "Tilni tanlash",
  USER_INFO_AND_SETTINGS_NOTIFICATIONS_SETTING_TITLE: "Bildirishnomani sozlash",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_TITLE: "Temani tanlash",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_YES_BUTTON_TITLE: "Xa",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_NO_BUTTON_TITLE: "Yo’q",

  USER_INFO_AND_SETTINGS_PASSWORD_CHANGED: "Parol o’zgartirildi",
  USER_INFO_AND_SETTINGS_WRONG_PASSWORD: "Noto’g’ri parol",
  USER_INFO_AND_SETTINGS_SAME_PASSWORD: "Joriy parol va yangi parol bir xil",
  USER_INFO_AND_SETTINGS_DUPLICATED_PASSWORD: "Parol oxirgi 5 ta paroldan takrorlanadi",
  USER_INFO_AND_SETTINGS_TRY_AGAIN: "Iltimos keyinroq qayta urininb ko’ring",
  USER_INFO_AND_SETTINGS_PASSWORD_NOT_MATCHING: "Parol mos emas",
  USER_INFO_AND_SETTINGS_ALERT_LANGUAGE: "Tilni o’zgartirmohchimisiz??",
  USER_INFO_AND_SETTINGS_ALERT_THEME: "Temani o’zgartirmohchimisiz?",
  USER_INFO_AND_SETTINGS_PASSWORD_VALIDATE:
    "Parol 8 dan 16 gacha belgilardan iborat bo’lishi kerak, jumladan kamida bitta katta lotin harfi, bitta kichik lotin harfi va bitta raqam!",

  USER_INFO_THEME_LIGHTBLUE: "Och ko’k",
  USER_INFO_THEME_DARKBLUE: "To’q ko’k",
  USER_INFO_THEME_DARKGREY: "To’q kulrang",
  USER_INFO_THEME_LIGHTGREY: "Och kulrang",

  USER_INFO_RECEIVER_FCY_TITLE: "Chet el valyutasidagi kirimlar bo’yicha xabarnoma olish",

  USER_INFO_AND_SETTINGS_COMPANY_FORM_EDIT_BUTTON_TITLE: "Qo’shish",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_SAVE_BUTTON_TITLE: "Saqlash",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Tashkilot nomi",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_PHONE_NUMBER_FIELD_LABEL_TITLE: "Telefon raqami",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Ism",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_MOBILE_NUMBER_FIELD_LABEL_TITLE: "Mobil raqami",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_CALLBACK_NUMBER_FIELD_LABEL_TITLE: "Call back raqami",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_EMAIL_ADDRESS_FIELD_LABEL_TITLE: "E-pochta manzili",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Tashkilot manzili",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_ACCOUNT_OPEN_DATE_FIELD_LABEL_TITLE:
    "Hisobvaraq ochilgan sanasi",
  USER_INFO_AND_SETTINGS_SIGNATURE_NAME_TITLE: "Nomi",
  USER_INFO_AND_SETTINGS_SIGNATURE_CATEGORIES_TITLE: "Toifalar",

  USER_INFO_AND_SETTINGS_ACCOUNT_SIGNATORIES_LABEL_TITLE: "Imzolaganlar",

  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TITLE: "User's Role",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_STATUS_COLUMN_TITLE: "Status",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_CREATOR_COLUMN_TITLE: "Bajaruvchi",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_ADMIN_COLUMN_TITLE: "Administrator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_VIEW_ONLY_COLUMN_TITLE: "Faqat ko’rish",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_1_COLUMN_TITLE:
    "Birinchi avtorizator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_2_COLUMN_TITLE:
    "Ikkinchi avtorizator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_3_COLUMN_TITLE:
    "Uchinchi avtorizator",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_1_COLUMN_TITLE:
    "Foydalanuvchi ID si",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_2_COLUMN_TITLE:
    "Foydalanuvchi huquqlari",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_LIMIT_COLUMN_TITLE:
    "Tasdiqlovchi limiti",

  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CHANGE_BUTTON_TITLE: "O’zgartirish",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Yangi parol",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Joriy parol",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE:
    "Yangi parolni tasdiqlash",

  //Agreement

  AGREEMENTS_TARIFF_SUBTITLE: "Tarif",
  AGREEMENTS_AGREEMENTS_TITLE: "Shartnomalar va shakllar",
  AGREEMENTS_CORPORATE_SIGNET_BUTTON: "Imzolandi",
  AGREEMENTS_BANK_SUBTITLE: "Bank Hisobvaraq Shartnomasi",
  AGREEMENTS_CORPORATE_VISA_SUBTITLE: "Korporativ Visa Card",
  AGREEMENTS_CORPORATE_MAIL_SUBTITLE: "Abonent qutichasi Shartnomasi",
  AGREEMENTS_INTERNET_SUBTITLE: "Internet Banking Shartnomasi",
  AGREEMENTS_CORPORATE_UZCARD_SUBTITLE: "Korporativ UzCard Shartnomasi",
  AGREEMENTS_CORPORATE_HUMO_SUBTITLE: "Korporativ Humo Card Shartnomasi",

  AGREEMENTS_PUBLIC_OFFER_TAB_TITLE: "Ommaviy Oferta",
  AGREEMENTS_TEMPLATES_AND_FORMS_TAB_TITLE: "Namuna va Formalar",
  AGREEMENTS_INSTRUCTIONS_TITLE: "Yo’riqnomalar",

  AGREEMENTS_TEMPLATES_AND_FORMS_DOCUMENT_FOR_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES:
    "Sayohat xarajatlari uchun mablag'larni berish uchun hujjatlar",
  AGREEMENTS_TEMPLATES_AND_FORMS_RETURN_DOCUMENTS: "Hujjatlarni qaytarish",

  AGREEMENTS_TEMPLATES_AND_FORMS_ON_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES_IN_FOREIGN_CURRENCY:
    "Chet el valyutasida sayohat xarajatlari uchun mablag'larni berish to'g'risida",

  //Reference
  REFERENCE_HEAD_TITLE: "Ma’lumotlar",
  REFERENCE_TAB1: "Tranzit hisobraqamlar ma’lumotlari",
  REFERENCE_TAB2: "Korrespondent banklar ma’lumotlari",

  //Help & FAQ

  HELP_AND_FAQ_FAQ_TAB_TITLE: "Tez-tez so’raladigan savollar",
  HELP_AND_FAQ_FAQ_QUESTIONS_TITLE: "Savollar",
  HELP_AND_FAQ_FAQ_NO_QUESTIONS_TITLE: "Savllar yo’q",
  HELP_AND_FAQ_SEND_BUTTON_TITLE: "Yuborish",
  HELP_AND_FAQ_CONTENT_SUBTITLE: "Tarkibi",
  HELP_AND_FAQ_SALARY_ITEM_TITLE: "8. Ish haqi",
  HELP_AND_FAQ_CLIENT_ITEM_TITLE: "9. Mijoz hamkori",
  HELP_AND_FAQ_CONVERSION_ITEM_TITLE: "7. Konversiya",
  HELP_AND_FAQ_PAYMENT_ITEM_TITLE: "4. To’lov talabnomasi",
  HELP_AND_FAQ_LABEL_QUESTION_TEXTAREA_TITLE: "Savol:",
  HELP_AND_FAQ_HELP_TITLE: "Yordam / Tez-tez so’raladigan savollar",
  HELP_AND_FAQ_STATUSES_ITEM_TITLE: "5. To’lov talabnomalarining holati",
  HELP_AND_FAQ_FEATURES_ITEM_TITLE: "1. Internet Banking xususiyatlari",
  HELP_AND_FAQ_FEATURES_SIX_ITEM_TITLE: "6. Internet Banking xususiyatlari",
  HELP_AND_FAQ_SOFTWARE_ITEM_TITLE: "2. Dasturiy ta’minot va apparat talablari",
  HELP_AND_FAQ_GETTING_ITEM_TITLE: "3. Ishlashni boshlash: kirish, login, parollar",

  //Loan
  LOAN_LIST_OF_CONTACTS_TITLE: "Shartnomalar ro’yxati",
  LOAN_FILTER_FORM_TO_FIELD_LABEL_TITLE: "gacha",
  LOAN_TABLE_INTEREST_COLUMN_TITLE: "Stavka foizi",
  LOAN_TABLE_LOAN_CURRENCY_COLUMN_TITLE: "Kredit valyutasi",
  LOAN_TABLE_CONTACT_COLUMN_TITLE: "Shartnoma amal qilish muddati",
  LOAN_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "dan",
  LOAN_TABLE_CREDIT_AGREEMENT_COLUMN_TITLE: "Kredit shartnomasi miqdori",
  LOAN_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "Kredit shartnomasi raqami va sanasi",

  LOAN_ITEM_PENNY_FIELD_LABEL_TITLE: "Jarima",
  LOAN_ITEM_BALANCE_FIELD_LABEL_TITLE: "Balans",
  LOAN_ITEM_CURRENCY_FIELD_LABEL_TITLE: "Valyuta",
  LOAN_ITEM_CREDIT_TYPE_FIELD_LABEL_TITLE: "Kredit turi",
  LOAN_ITEM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "To’lov turi",
  LOAN_ITEM_PAYMENT_DATE_FIELD_LABEL_TITLE: "To’lov sanasi",
  LOAN_ITEM_LOAN_REPAYMENT_TITLE: "Kredit to’lash jadvali",
  LOAN_ITEM_INTEREST_RATE_FIELD_LABEL_TITLE: "Foiz stavkasi",
  LOAN_ITEM_PAYMENT_AMOUNT_FIELD_LABEL_TITLE: "To’lov miqdori (Asosiy kredit))",
  LOAN_ITEM_CONTACT_AMOUNT_FIELD_LABEL_TITLE: "Shartnoma miqdori",
  LOAN_ITEM_OVERDUE_INTEREST_FIELD_LABEL_TITLE: "Muddati o’tgan foiz",
  LOAN_ITEM_INTEREST_PERIOD_FOR_FIELD_LABEL_TITLE: "Interest period for",
  LOAN_ITEM_SCHEDULER_DATE_FIELD_LABEL_TITLE: "Scheduled repayment date",
  LOAN_ITEM_BALANCE_OF_PRINCIPAL_FIELD_LABEL_TITLE: "Balance of principal",
  LOAN_ITEM_INTEREST_PERIOD_FROM_FIELD_LABEL_TITLE: "… uchun foiz davri",
  LOAN_ITEM_LOAN_AGREEMENT_TITLE: "Kredit Shartnomasi #",
  LOAN_ITEM_SCHEDULER_AMOUNT_FIELD_LABEL_TITLE: "Rejalashtirilgan to’lov miqdori",
  LOAN_ITEM_OVERDUE_PRINCIPAL_DEBT_FIELD_LABEL_TITLE: "Muddati o’tgan asosiy qarz",
  LOAN_ITEM_AMOUNT_PAID_TITLE: "Kredit shartnomasi #  bo’yicha to’langan mablag’",
  LOAN_ITEM_BALANCES_TITLE: "Kredit balansi",
  LOAN_ITEM_AGREEMENT_NUMBER_FIELD_LABEL_TITLE: "Kredit shartnomasi raqami",
  LOAN_ITEM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Kredit hisobraqami",
  LOAN_ITEM_BANK_CODE_FIELD_LABEL_TITLE: "Bank Kod",
  LOAN_ITEM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Kredit hisobvaraq turi",

  //Notifications
  NOTIFICATIONS_NEWS_TAB_TITLE: "Yangiliklar",
  NOTIFICATIONS_NO_NOTIFICATIONS_TITLE: "Bildirishnomasiz",
  NOTIFICATIONS_SEARCH_TITLE: "Qidirish",
  NOTIFICATIONS_EVENT_TAB_TITLE: "Tadbir",
  NOTIFICATIONS_BUTTON_MENU_NEWS_TITLE: "Yangiliklar",
  NOTIFICATIONS_BUTTON_MENU_EMPTY_TITLE: "Bo’sh",
  NOTIFICATIONS_BUTTON_MENU_DIVED_TITLE: "Sho’ng’idi",
  NOTIFICATIONS_BUTTON_READ_MORE_TITLE: "Batafsil",
  NOTIFICATIONS_BUTTON_MENU_GALLERY_TITLE: "Gallereya",
  NOTIFICATIONS_ANNOUNCEMENTS_TAB_TITLE: "E’lon",
  NOTIFICATIONS_BUTTON_MENU_PRESS_CENTRE_TITLE: "Matbuot markazi",
  NOTIFICATIONS_CALNEDAR_BUTTON_TITLE: "Kalendar",
  NOTIFICATIONS_LOAN_AGREEMENT_TITLE: "Kredit Shartnomasi #  2000/11-1 dd15.05.18",
  NOTIFICATIONS_LOAN_AGREEMENT_SUBTITLE:
    "Ushbu ariza orqali men bir chet el valyutasini boshqa chet el valyutasiga konversiya qilish yuzasidan xizmat ko’rsatish bo’yicha Ommaviy oferta shartlari bilan tanishib chiqganimni, hamda uning shartlariga roziligimni, kelgusida Bankka hech qanday e’tirozlarim bo’lmasligini, shuningdek ko’rsatilgan xizmatlar uchun keraklik miqdordagi mablag’larni yechib olinishiga o’z roziligimni bildiraman.",

  // Table
  TABLE_EMPTY_STATE_TITLE: "Bo’sh ro’yxat",

  // Paginator
  PAGINATION_OF_TITLE: "dan",

  // File Controls
  FILE_CONTROLS_PRINT_BUTTON_TITLE: "Chop etish",
  FILE_CONTROLS_GENERATE_BUTTON_TITLE: "Generate",
  FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE: "PDF da saqlash",
  FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE: "PDF da saqlash QR kod bilan",
  FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE: "TXT da saqlash",
  FILE_CONTROLS_SAVE_TO_TXT_2_BUTTON_TITLE: "TXT_2 da saqlash",
  FILE_CONTROLS_SAVE_TO_TXT_3_BUTTON_TITLE: "TXT_3 da saqlash",
  FILE_CONTROLS_SAVE_TO_MT940_BUTTON_TITLE: "MT940 da saqlash",
  FILE_CONTROLS_SAVE_EXCEL_1C_BUTTON_TITLE: "Excel da saqlash 1C",
  FILE_CONTROLS_SAVE_TEXT_1C_BUTTON_TITLE: "TXT da saqlash 1C",
  FILE_CONTROLS_SAVE_AGROMIR_CREDIT_BUTTON_TITLE: "Agromir (credit)",
  FILE_CONTROLS_SAVE_AGROMIR_DEBIT_BUTTON_TITLE: "Agromir (debit)",
  FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY_BUTTON_TITLE: "Excel da saqlash 2",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE_SV: "Excel da saqlash (SV)",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE: "Excel da saqlash",
  FILE_CONTROLS_SAVE_EXCEL_3KORZINKA_BUTTON_TITLE: "Excel da saqlash 3",
  FILE_CONTROLS_SAVE_TO_TXT_ANSI_BUTTON_TITLE: "ANSI da saqlash",
  FILE_CONTROLS_SAVE_TO_FAVORITE_BUTTON_TITLE: "Tanlanganga saqlash",
  FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE: "Karta egalarining ro’yxati",
  FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE: "Excel shablonini yuklab olish",
  FILE_CONTROLS_REGISTER_NEW_SALARY_CARD_BUTTON_TITLE:
    "Yangi ish haqi uchun kartani ro’yxatdan o’tkazish",
  FILE_CONTROLS_REGISTER_NEW_CORPORATE_CARD_BUTTON_TITLE: "Korporativ kastani ro’yxatdan o’tkazish",
  FAVORITES_DELETE_BUTTON: "Buni o’chirmohchimisiz?",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_TITLE: "Chet el valyutasida to'lov o'tkazmasini yaratish",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_TITLE: "To'lov o'tkazmasini o'zbek so'mida yaratish",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_TITLE: "Korporativ kartani to'ldirish",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_TITLE: "Ish haqi kartasini to'ldirish",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_TITLE: "To'lov so'rovnomasini yaratish",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_FILE_NAME_TITLE: "Chel el valyutasidagi o’tkazmalar",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_FILE_NAME_TITLE: "So’mdagi o’tkazmalar",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_FILE_NAME_TITLE:
    "Korporativ karta ish haqi jadvali",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_FILE_NAME_TITLE: "Ish haqi kartasi",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_FILE_NAME_TITLE: "To'lov da'vosi",

  TRANSFERS_MODAL_PAYMENT_CODE_TITLE: "To’lov kodlari",
  TRANSFERS_MODAL_BUDGET_ACCOUNTS: "Byudjet hisobvarag’i",
  FILE_CONTROLS_SAVE_TO_UPLOAD_FORM_EXCEL_BUTTON_TITLE: "Excel dan yuklab olish",
  FILE_CONTROLS_SAVE_TO_FAVORITE_FCY_TO_UZS_BUTTON_TITLE: "Tanlangan chet el valyutasini so’mga",
  QR_CODE_ERROR3015: "Ushbu hujjatning o’zbek tilidagi QR kodli PDF fayli allaqachon mavjud.",
  QR_CODE_ERROR3016: "Ushbu hujjat hali amalga oshirilmagan.",
  QR_CODE_SELECT_LIMIT_ERROR: "Faqat bitta hujjat tanlanishi kerak",

  // Select Picker
  SELECT_PICKER_PLACEHOLDER_TITLE: "Barchasi",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "Imkoniyat yo’q",
  SELECT_PICKER_PLACEHOLDER_YES_OR_NO_TITLE: "Ha/Yo'q",
  SELECT_PICKER_PLACEHOLDER_CHOOSE_ONE_ONLY_TITLE: "Faqat bittasini tanlang",

  //Contact Us
  CONTACT_US_EMAIL_TITLE: "E-pochta",
  CONTACT_US_FULL_NAME_TITLE: "To’liq ismi-sha’rifi",
  CONTACT_US_PHONE_NUMBER_TITLE: "Telefon raqami",
  CONTACT_US_CLIENT_SERVICES_TITLE: "Mijozga xizmat ko’rsatish",
  CONTACT_US_CLIENT_RELATIONSHIP_TITLE: "Mijoz bilan ishlash bo’yicha menedjer",

  //Main
  MAIN_ON_LENDING_AGREEMENT_UNIT_TITLE: "Birlik: AQSH Dollari",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_SUBTITLE: "Manzil",
  MAIN_ON_LENDING_AGREEMENT_BANK_CODE_SUBTITLE: "Bank kodi",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_ONE_SUBTITLE: "1 yilgacha",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_TWO_SUBTITLE: "2 yilgacha",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_THREE_SUBTITLE: "3 yilgacha",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FOUR_SUBTITLE: "4 yilgacha",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FIVE_SUBTITLE: "5 yilgacha",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_SUBTITLE: "Keyingi tasdiqlash sanasi",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_TITLE: "15.06.2021",
  MAIN_ON_LENDING_AGREEMENT_AUTHORIZED_SUBTITLE: "Avtorizatsiya qilgan menedjer",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_SUBTITLE: "Vositachi bank",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_TITLE: "ABC Bank",
  MAIN_ON_LENDING_AGREEMENT_GENERAL_SUBTITLE: "Umumiy on-lending limiti",
  MAIN_ON_LENDING_AGREEMENT_LATEST_DATE_SUBTITLE: "Oxirgi tasdiqlangan sana",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_NUMBER_SUBTITLE: "Shartnoma raqami",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_DATE_SUBTITLE: "Shartnoma imzolangan sana",
  MAIN_ON_LENDING_AGREEMENT_INTEREST_SUBTITLE: "Alohida bitimning foiz stavkasi:",
  MAIN_ON_LENDING_AGREEMENT_MAXIMUM_SUBTITLE: "Alohida bitimning maksimal miqdori",
  MAIN_ON_LENDING_AGREEMENT_MINIMUM_SUBTITLE: "Alohida bitimning minimal miqdori",
  MAIN_ON_LENDING_AGREEMENT_TAX_IDENTIFICATION_SUBTITLE:
    "Soliq to’lovchining identifikatsiya raqami",
  MAIN_ON_LENDING_AGREEMENT_INFORMATION_TITLE: "Umumiy on-lending shartnoma bo’yicha ma’lumot",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_TITLE: "#3, Google ko’chasi, Mirabad tumani, Tashkent shahri",

  MAIN_PENDING_APPLICATION_UNIT_TITLE: "Birlik: AQSH Dollari",
  MAIN_PENDING_APPLICATION_TABLE_AMOUNT_COLUMN_TITLE: "Sub-kredit miqdori",
  MAIN_PENDING_APPLICATION_TABLE_STATUS_COLUMN_TITLE: "Status",
  MAIN_PENDING_APPLICATION_TABLE_DATE_COLUMN_TITLE: "Ariza sanasi",
  MAIN_PENDING_APPLICATION_TABLE_NAME_COLUMN_TITLE: "Sub-qarz oluvchining nomi",
  MAIN_PENDING_APPLICATION_TABLE_NUMBER_COLUMN_TITLE: "Ariza raqami",
  MAIN_PENDING_APPLICATION_PENDING_APPLICATION_TITLE: "Navbatdagi ariza",
  MAIN_PENDING_APPLICATION_TABLE_UPDATING_COLUMN_TITLE: "Yangilash",
  MAIN_PENDING_APPLICATION_PRE_APPROVAL_TITLE: "Yangi arizani oldindan tasdiqlash",
  MAIN_PENDING_APPLICATION_TABLE_REGISTRATION_COLUMN_TITLE: "Ro’yxatdan o’tish uchun so’rov",
  MAIN_PENDING_APPLICATION_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Yangilash uchun so’rov",
  MAIN_PENDING_APPLICATION_REQUEST_BUTTON_TITLE:
    "Yangi kredit yoki akkreditivga oid dasturni oldindan tasdiqlash uchun so’rov",

  FINANCIAL_REFUSED_DEALS_UNIT_TITLE: "Birlik: AQSH Dollari",
  FINANCIAL_REFUSED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  FINANCIAL_REFUSED_DEALS_APPROVED_DEALS_TITLE: "Tasdiqlangan bitimlar",
  FINANCIAL_REFUSED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Status",
  FINANCIAL_REFUSED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Qarz oluvchi nomi",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: "So’rov sanasi",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "So’rov raqami",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Yangilash uchun so’rov",
  FINANCIAL_REFUSED_DEALS_TABLE_REFUSED_OR_CANCELLED_COLUMN_TITLE:
    "Rad etilgan yoki bekor qilingan bitimlar",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE:
    "Ro’yxatdan o’tish uchun so’rov",

  MAIN_LOAN_REPAYMENT_DETAILS_UNIT_TITLE: "Birlik: AQSH Dollari",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_AMOUNT_COLUMN_TITLE: "Miqdor",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "So’rov raqami",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_BORROWER_NUMBER_COLUMN_TITLE: "Qarz oluvchi nomi",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_DATE_TOTAL_COLUMN_TITLE: "So’rov sanasi",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_DATE_COLUMN_TITLE: "So’rov vaqti",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_BORROWER_NAME_COLUMN_TITLE: "Qarz oluvchi nomi",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_NUMBER_COLUMN_TITLE: "So’rov raqami",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_UPCOMING_LOAN_UPCOMING_COLUMN_TITLE:
    "Keyingi oldindan to’lash sanasi",
  MAIN_LOAN_REPAYMENT_DETAILS_DETAILED_INFORMATION_BY_INDIVIDUAL_DEALS_TITLE:
    "Alohida bitimlar bo’yicha batafsil ma’lumot",
  MAIN_LOAN_REPAYMENT_DETAILS_UPCOMING_LOAN_TITLE:
    "Upcoming loan repayment, overdue payments and commissions",

  MAIN_DOCUMENT_SUBMISSION_TABLE_DATE_COLUMN_TITLE: "Sana",
  MAIN_DOCUMENT_SUBMISSION_REFUSED_TAB_TITLE: "Rad etilgan bitimlar",
  MAIN_DOCUMENT_SUBMISSION_APPROVED_TAB_TITLE: "Tasdiqlangan bitimlar",
  MAIN_DOCUMENT_SUBMISSION_SUBMITTED_TITLE: "Topshirilgan hujjatlar",
  MAIN_DOCUMENT_SUBMISSION_LOAN_TAB_TITLE: "Kredit to’lash tafsilotlari",
  MAIN_DOCUMENT_SUBMISSION_LENDING_TAB_TITLE: "On lending Shartnomasi",
  MAIN_DOCUMENT_SUBMISSION_DOCUMENT_TAB_TITLE: "Hujjat taqdim etish",
  MAIN_DOCUMENT_SUBMISSION_PENDING_TAB_TITLE: "Navbatdagi arizalar",
  MAIN_DOCUMENT_SUBMISSION_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Hujjat nomi",
  MAIN_DOCUMENT_SUBMISSION_SUBMISSION_BUTTON_TITLE: "Hujjatni taqdim etish",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_TITLE:
    "KDB Bank O’zbekistan ga hujjatni taqdim etish",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_BUTTON_TITLE:
    " KDB Bank O’zbekistan ga hujjatni taqdim etish",

  // Withdrawal of Loan Funds page  " Individual deal TAB"

  WITHDRAWAL_TABLE_DATE_COLUMN_TITLE: "Sana",
  WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  WITHDRAWAL_BORROWER_FIELD_LABEL_TITLE: "Qarz oluvchi",
  WITHDRAWAL_PRODUCTS_FIELD_LABEL_TITLE: "Maxsulotlar",
  WITHDRAWAL_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor (AQSH Dollari)",
  WITHDRAWAL_TABLE_PAYMENT_COLUMN_TITLE: "To’lov sanasi",
  WITHDRAWAL_SIZE_FIELD_LABEL_TITLE: "Biznes hajmi ",
  WITHDRAWAL_DEAL_NUMBER_FIELD_LABEL_TITLE: "Bitim raqami",
  WITHDRAWAL_INTEREST_FIELD_LABEL_TITLE: "Foiz stavkasi",
  WITHDRAWAL_INDIVIDUAL_DEAL_TAB_TITLE: "Yakka bitim",
  WITHDRAWAL_APPROVED_FIELD_LABEL_TITLE: "Tasdiqlangan miqdor",
  WITHDRAWAL_EMPLOYEE_FIELD_LABEL_TITLE: "Xodimlar soni",
  WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Tashkil etilgan sana",
  WITHDRAWAL_BUSINESS_FIELD_LABEL_TITLE: "Ish faolligi",
  WITHDRAWAL_WITHDRAWN_FIELD_LABEL_TITLE: "Yechib olingan miqdor",
  WITHDRAWAL_TAX_FIELD_LABEL_TITLE: "Soliq to’lovchining identifikatsiya raqami",
  WITHDRAWAL_OUTSTANDING_FIELD_LABEL_TITLE: "Undirilmagan miqdor",
  WITHDRAWAL_TABLE_OVERDUE_COMMISSION_COLUMN_TITLE: "Komissiya",
  WITHDRAWAL_ON_LENDING_AGREEMENT_TAB_TITLE: "On-Lending Shartnoma",
  WITHDRAWAL_MANAGEMENT_FIELD_LABEL_TITLE: "Qarz oluvchini boshqarish",
  WITHDRAWAL_DEAL_REQUEST_NUMBER_FIELD_LABEL_TITLE: "So’rov raqami",
  WITHDRAWAL_TABLE_OVERDUE_PAYMENT_AMOUNT_COLUMN_TITLE: "To’lov miqdori",
  WITHDRAWAL_LARGEST_FIELD_LABEL_TITLE: "Yirik aksiyadorlar (10% dan yuqori)",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_TITLE: "Yakka bitim haqida ma’lumot",
  WITHDRAWAL_TABLE_NUMBER_OF_PAYMENT_COLUMN_TITLE: "To’lov so’rovnomasi raqami",
  WITHDRAWAL_LOAN_TITLE: "Kredit to’lovi, muddati utgan to’lov va komissiya to’lash tarixi",
  WITHDRAWAL_INFORMATION_ON_CORPORATE_BORROWER_TITLE: "Korporativ qarz oluvchi haqida ma’lumot",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_DEAL_TITLE: "Oyna #4. Yakka bitim haqida ma’lumot",

  // Individual transfers page
  TRANSFERS_TRANSFERS_HISTORY_TAB_TITLE: "Tarix",
  TRANSFERS_TRANSFERS_FAVORITES_TAB_TITLE: "Tanlangan",
  TRANSFERS_TRANSFERS_TO_ANOTHER_CLIENT_TAB_TITLE: "Boshqa bank mijoziga o’tkazma",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_TAB_TITLE: "Hisobvaraqlarim orasida o’tkazma",
  TRANSFERS_TRANSFERS_TO_OTHER_KDB_CLIENTS_TAB_TITLE: "Boshqa KDB mijozlariga o’tkazma",

  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CARD_TITLE: "Kartaga",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_SEND_BUTTON_TITLE: "Yuborish",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_FIELD_LABEL_TITLE: "Oluvchi",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_BUDGET_TITLE: "Byudjetga",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_FROM_FIELD_LABEL_TITLE: "Yuboruvchi",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CURRENT_TITLE: "Hisobvaraqga",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_AMOUNT_FIELD_LABEL_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_BANK_CODE_FIELD_LABEL_TITLE: "Bank kodi",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_RECEIVER_NAME_FIELD_LABEL_TITLE:
    "Qabul qiluvchi nomi",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE:
    "To’lov ma’lumotlari",

  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TITLE: "Tasdiqlash",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_CANCEL_BUTTON_TITLE: "Bekor qilish",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TRANSFER_BUTTON_TITLE: "O’tkazma",

  TRANSFERS_TRANSFERS_SUCCESS_MODAL_TITLE: "Muvaffaqiyatli",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_EXIT_BUTTON_TITLE: "Chiqish",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_PDF_BUTTON_TITLE: "PDF da saqlash",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_FAVORITE_BUTTON_TITLE: "Tanlanganda saqlash",

  TRANSFERS_TRANSFERS_MODAL_AMOUNT_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_MODAL_SENDER_NAME_TITLE: "Yuboruvchi",
  TRANSFERS_TRANSFERS_MODAL_COMMISSION_TITLE: "Komissiya",
  TRANSFERS_TRANSFERS_MODAL_PAYMENT_DATE_TITLE: "To’lov sanasi",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_NAME_TITLE: "Qabul qiluvchi nomi",
  TRANSFERS_TRANSFERS_MODAL_SENDER_ACCOUNT_TITLE: "Yuboruvchi hisobvarag’i",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_ACCOUNT_TITLE: "Qabul qiluvchi hisobvarag’i",

  // Withdrawal of Loan Funds page  "Withdrawn Loan Funds"

  WITHDRAWAL_WITHDRAWN_50_HEADER_ITEM_TITLE: "50",
  WITHDRAWAL_WITHDRAWN_52_HEADER_ITEM_TITLE: "52",
  WITHDRAWAL_WITHDRAWN_56_HEADER_ITEM_TITLE: "56",
  WITHDRAWAL_WITHDRAWN_57_HEADER_ITEM_TITLE: "57",
  WITHDRAWAL_WITHDRAWN_59_HEADER_ITEM_TITLE: "59",
  WITHDRAWAL_WITHDRAWN_70_HEADER_ITEM_TITLE: "70",
  WITHDRAWAL_WITHDRAWN_71_HEADER_ITEM_TITLE: "71",
  WITHDRAWAL_WITHDRAWN_72_HEADER_ITEM_TITLE: "72",
  WITHDRAWAL_WITHDRAWN_32A_HEADER_ITEM_TITLE: "32a",
  WITHDRAWAL_WITHDRAWN_DATE_FIELD_LABEL_TITLE: "Sana",
  WITHDRAWAL_WITHDRAWN_TAX_ID_ITEM_TITLE: "STIR",
  WITHDRAWAL_WITHDRAWN_NEW_BUTTON_TITLE: "New withdrawal",
  WITHDRAWAL_WITHDRAWN_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_WITHDRAWN_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  WITHDRAWAL_WITHDRAWN_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumot",
  WITHDRAWAL_WITHDRAWN_BORROWER_FIELD_LABEL_TITLE: "Qarz oluvchi",
  WITHDRAWAL_WITHDRAWN_PRODUCTS_FIELD_LABEL_TITLE: "Maxsulotlar",
  WITHDRAWAL_WITHDRAWN_CURRENCY_ITEM_TITLE: "Valyuta",
  WITHDRAWAL_WITHDRAWN_ADDRESS_UP_ITEM_TITLE: "Manzil",
  WITHDRAWAL_WITHDRAWN_ADDRESS_DOWN_ITEM_TITLE: "Manzil",
  WITHDRAWAL_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Foiz stavkasi",
  WITHDRAWAL_WITHDRAWN_REFUSED_TITLE: "Yechib olingan kredit mablag’lari to’g’risida ma’lumot",
  WITHDRAWAL_WITHDRAWN_DEAL_NUMBER_FIELD_LABEL_TITLE: "Bitim raqami",
  WITHDRAWAL_WITHDRAWN_APPROVED_FIELD_LABEL_TITLE: "Tasdiqlangan mablag’",
  WITHDRAWAL_WITHDRAWN_EMPLOYEE_FIELD_LABEL_TITLE: "Xodimlar soni",
  WITHDRAWAL_WITHDRAWN_DATE_TITLE: "Sana         XX/XX/XXXX ",
  WITHDRAWAL_WITHDRAWN_BUSINESS_FIELD_LABEL_TITLE: "Biznes faollik",
  WITHDRAWAL_WITHDRAWN_WITHDRAWN_FIELD_LABEL_TITLE: "Yechib olingan miqdor",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_DATE_COLUMN_TITLE: "To’lov sanasi",
  WITHDRAWAL_WITHDRAWN_REQUEST_NUMBER_FIELD_LABEL_TITLE: "So’rov raqami",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_ITEM_TITLE: "Benefitsiar",
  WITHDRAWAL_WITHDRAWN_OUTSTANDING_FIELD_LABEL_TITLE: "Undirilmagan miqdor",
  WITHDRAWAL_PAGE_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Foiz stavkasi",
  WITHDRAWAL_WITHDRAWN_DEBIT_ITEM_TITLE: "Hisobvaraqni debitlash",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "To’lov miqdori",
  WITHDRAWAL_WITHDRAWN_VALUE_ITEM_TITLE: "Valyutalash sanasi",
  WITHDRAWAL_WITHDRAWN_MANAGEMENT_FIELD_LABEL_TITLE: "Qarz oluvchini boshqarish",
  WITHDRAWAL_WITHDRAWN_SIZE_OF_BUSINESS_FIELD_LABEL_TITLE: "Biznes hajmi",
  WITHDRAWAL_WITHDRAWN_AMOUNT_DIGIT_ITEM_TITLE: "Miqdor raqamlarda",
  WITHDRAWAL_WITHDRAWN_ORDERING_BANK_ITEM_TITLE: "To’lovchining banki",
  WITHDRAWAL_WITHDRAWN_LARGEST_FIELD_LABEL_TITLE: "Yirik aksiyadorlar (10% dan yuqori)",
  WITHDRAWAL_WITHDRAWN_DATE_ESTABLISHMENT_FIELD_LABEL_TITLE: "Tashkil etilgan sana",
  WITHDRAWAL_WITHDRAWN_INFORMATION_INDIVIDUAL_TITLE: "Yakka bitim haqida ma’lumot",
  WITHDRAWAL_WITHDRAWN_ORDERING_CUSTOMER_ITEM_TITLE: "To’lovchi",
  WITHDRAWAL_WITHDRAWN_TABLE_NUMBER_REQUEST_COLUMN_TITLE: "To’lov so’rovnomashi raqami",
  WITHDRAWAL_WITHDRAWN_CREDIT_ACCOUNT_ITEM_TITLE: "Hisobvaraqni kreditlash",
  WITHDRAWAL_WITHDRAWN_PAYMENT_TITLE: "To’lov talabnomasi # XXX",
  WITHDRAWAL_WITHDRAWN_INFORMATION_CORPORATE_TITLE: "Korporativ qarz oluvchi haqida ma’lumot",
  WITHDRAWAL_WITHDRAWN_TAX_IDENTIFICATION_FIELD_LABEL_TITLE:
    "Soliq to’lovchining identifikatsiya raqami",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_BANK_ITEM_TITLE: "Benefitsiar banki ",
  WITHDRAWAL_WITHDRAWN_INTERMEDIARY_BANK_ITEM_TITLE: "Vositachi bank",
  WITHDRAWAL_WITHDRAWN_IBAN_ITEM_TITLE: "(Yevropa Ittifoqiga to’lovlar uchun IBAN)",
  WITHDRAWAL_WITHDRAWN_AMOUNT_CURRENCY_ITEM_TITLE: "Miqdor va valyuta so’zlarda",
  WITHDRAWAL_WITHDRAWN_DETAILS_PAYMENT_ITEM_TITLE: "To’lov ma’lumotlari",
  WITHDRAWAL_WITHDRAWN_DETAILS_CHARGES_ITEM_TITLE:
    "Korrespondent banklarning xizmatlari uchun to’lov:",
  WITHDRAWAL_WITHDRAWN_ADDITIONAL_INFORMATION_ITEM_TITLE: "Qo’shimcha ma’lumot",
  WITHDRAWAL_WITHDRAWN_CREATE_BUTTON_TITLE: "Yaratish",
  WITHDRAWAL_WITHDRAWN_WITHDRAWAL_LOAN_TITLE: "Oyna #5. Kredit mablag’larini yechib olish",

  WITHDRAWN_REQUEST_DRAWDOWN_FORM_TITLE: "ON-LENDING KREDIT BERISH MUDDATI BO’YICHA SO’ROV",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_DATE_TITLE: "Sana",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_OF_WITHDRAWN_TITLE: " On-lending kredit berish shakli",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUBMIT_BUTTON_TITLE: "TAQDIM ETISH",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_AMOUNT_TITLE: "Miqdor",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_NUMBER_TITLE: "Raqam",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_ADDRESS_TITLE: "Manzil",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_PAYMENT_DETAILS_TITLE: "To’lov ma’lumoti",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_VALUES_DATE_FIELD_TITLE: "Valyutalash sanasi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_NAME_TITLE: "Nomi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_DATE_FIELD_TITLE: "So’rov sanasi:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_GROUP_TITLE: "Sub qarz oluvchi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_TAX_ID_TITLE: "STIR",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AMOUNT_TITLE: "Sub-kredit miqdori",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_NUMBER_FIELD_TITLE: "So’rov raqami:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_CONTACT_GROUP_TITLE: "Import shartnomasi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_FIELD_TITLE: "Drawdown miqdori",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_REQUEST_GROUP_TITLE: "Drawdown so’rovi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_NAME_FIELD_TITLE: "Nomi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_BANK_GROUP_TITLE: "Benefitsiar bank",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_BENEFICIARY_NAME_TITLE: "Benefitsiar nomi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_CURRENCY_FIELD_TITLE: "Drawdown currency",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_BANK_GROUP_TITLE:
    "Vositachi bankning korrespondent banki",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_NAME_FIELD_TITLE: "Nomi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_ADDRESS_FIELD_TITLE: "Manzil",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_ADDRESS_FIELD_TITLE: "Manzil",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_SWIFT_CODE_FIELD_TITLE: "SWIFT kodi",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AGREEMENT_NUMBER_TITLE:
    "Sub-kredit shartnoma raqami",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_IN_WORDS_FIELD_TITLE:
    "Drawdown miqdori so’zlarda",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_SWIFT_CODE_FIELD_TITLE: "SWIFT kodi",

  //Withdrawal of Loan Funds page "New Withdrawal Tab"

  WITHDRAWAL_NEW_WITHDRAWAL_50_ITEM_TITLE: "50",
  WITHDRAWAL_NEW_WITHDRAWAL_52_ITEM_TITLE: "52",
  WITHDRAWAL_NEW_WITHDRAWAL_56_ITEM_TITLE: "56",
  WITHDRAWAL_NEW_WITHDRAWAL_57_ITEM_TITLE: "57",
  WITHDRAWAL_NEW_WITHDRAWAL_59_ITEM_TITLE: "59",
  WITHDRAWAL_NEW_WITHDRAWAL_70_ITEM_TITLE: "70",
  WITHDRAWAL_NEW_WITHDRAWAL_71_ITEM_TITLE: "71",
  WITHDRAWAL_NEW_WITHDRAWAL_72_ITEM_TITLE: "72",
  WITHDRAWAL_NEW_WITHDRAWAL_32A_ITEM_TITLE: "32а",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_AND_CURRENCY_ITEM_TITLE: "Miqdor va valyuta so’zlarda",
  WITHDRAWAL_NEW_WITHDRAWAL_TAX_ID_ITEM_TITLE: "STIR",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Status",
  WITHDRAWAL_NEW_WITHDRAWAL_NEW_BUTTON_TITLE: "New withdrawal",
  WITHDRAWAL_NEW_WITHDRAWAL_IBAN_ITEM_TITLE: "(Yevropa Ittifoqiga to’lovlar uchun IBAN) ",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_PAYMENT_ITEM_TITLE: "To’lov ma’lumotlari",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_CHANGES_ITEM_TITLE:
    "Korrespondent banklarning xizmatlari uchun to’lov: ",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDITIONAL_INFORMATION_ITEM_TITLE: "Qo’shimcha ma’lumotlar",
  WITHDRAWAL_NEW_WITHDRAWAL_CURRENCY_ITEM_TITLE: "Valyuta",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumotlar",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_UP_ITEM_TITLE: "Manzil",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_DOWN_ITEM_TITLE: "Manzil",
  WITHDRAWAL_NEW_WITHDRAWAL_REFUSED_TITLE: "Rad etilgan yoki bekor qilingan bitimlar",
  WITHDRAWAL_NEW_WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Sana",
  WITHDRAWAL_NEW_WITHDRAWAL_DATA_TITLE: "Sana         XX/XX/XXXX ",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_DATE_COLUMN_TITLE: "To’lov sanasi",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "To’lov miqdori",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_ITEM_TITLE: "Benefitsiar",
  WITHDRAWAL_NEW_WITHDRAWAL_VALUE_ITEM_TITLE: "Valyutalash sanasi",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_NUMBER_COLUMN_TITLE: "To’lov so’rovnomasi raqami",
  WITHDRAWAL_NEW_WITHDRAWAL_DEBIT_ACCOUNT_ITEM_TITLE: "Hisobvaraqni debitlash",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_BANK_ITEM_TITLE: "To’lovchining banki",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_CUSTOMER_ITEM_TITLE: "To’lovchi",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_IN_DIGIT_ITEM_TITLE: "Miqdor raqamlarda",
  WITHDRAWAL_NEW_WITHDRAWAL_PAYMENT_TITLE: "To’lov talabnomasi #XXX",
  WITHDRAWAL_NEW_WITHDRAWAL_INTERMEDIARY_BANK_ITEM_TITLE: "Vositachi bank",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_BANK_ITEM_TITLE: "Benefitsiar bank ",
  WITHDRAWAL_NEW_WITHDRAWAL_CREDIT_ACCOUNT_ITEM_TITLE: "Hisobvaraqni kreditlash",

  //Withdrawal of Loan Funds page "Request for reg.loan in NIKI tab"

  WITHDRAWAL_REQUEST_FOR_TABLE_DATE_COLUMN_TITLE: "Sana",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_BUTTON_TITLE: "Biriktirilgan",
  WITHDRAWAL_REQUEST_FOR_GRACE_FIELD_LABEL_TITLE: "Imtiyozli davr",
  WITHDRAWAL_REQUEST_FOR_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor (AQSH Dollari)",
  WITHDRAWAL_REQUEST_FOR_INTEREST_FIELD_LABEL_TITLE: "Foiz stavkasi",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DATE_FIELD_LABEL_TITLE: "Sana",
  WITHDRAWAL_REQUEST_FOR_REQUEST_TERM_FIELD_LABEL_TITLE: "So’ralgan muddat",
  WITHDRAWAL_REQUEST_FOR_PURPOSE_FIELD_LABEL_TITLE: "Moliyalashtirishning maqsadi",
  WITHDRAWAL_REQUEST_FOR_REQUEST_NUMBER_FIELD_LABEL_TITLE: "So’rov raqami",
  WITHDRAWAL_REQUEST_FOR_REQUEST_AMOUNT_FIELD_LABEL_TITLE: "So’ralgan miqdor",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_DOCUMENTS_LABEL_TITLE: "Biriktirilgan hujjatlar",
  WITHDRAWAL_REQUEST_FOR_MANAGER_FIELD_LABEL_TITLE: "Vositachi bank boshqaruvchisi",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DETAILS_TITLE: "Shaxsiy arizaning ma’lumotlari",
  WITHDRAWAL_REQUEST_FOR_REQUEST_BUTTON_TITLE: "Arizani ro’yxatdan o’tkazish uchun so’rov",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_PARA_3_TITLE:
    "Para #3. Markaziy bankning niki tizimida shaxsiy kreditni yoki akkreditivni rasmiylashtirish to’g’risidagi ariza",
  WITHDRAWAL_REQUEST_FOR_KDB_BANK_SUBTITLE_TITLE:
    "KDB Bank O’zbekistan Boshqaruv Raisiga," +
    "Shu bilan sizdan (vositachi bank) quyidagi shaxsiy arizani moliyalashtirish uchun (umumiy on lending shartnoma sana 10.05.2020) on lending kredit mablag’laridan foydalanishga ruxsat berishingizni so’raymiz.",

  // Individual role Loan "Loan Tab"

  LOAN_TO_FIELD_LABEL_TITLE: "gacha",
  LOAN_DATE_FIELD_LABEL_TITLE: "Sana",
  LOAN_TABLE_LOAN_COLUMN_TITLE: "Kredit valyutasi",
  LOAN_TABLE_INTEREST_RATE_COLUMN_TITLE: "Foiz stavkasi",
  LOAN_TABLE_CREDIT_COLUMN_TITLE: "Kredit shartnoma miqdori",
  LOAN_TABLE_NUMBER_COLUMN_TITLE: "Kredit shartnoma raqami va sanasi",
  LOAN_TABLE_CONTACT_EXPIRATION_COLUMN_TITLE: "Kontaktning amal qilish muddati",

  // Individual role Conversion "History Tab"

  CONVERSION_HISTORY_TAB_TITLE: "Tarix",
  CONVERSION_CONVERSION_TAB_TITLE: "Konversiya",
  CONVERSION_EXCHANGE_RATE_TAB_TITLE: "Valyuta kursi",

  CONVERSION_HISTORY_TO_FIELD_LABEL_TITLE: "gacha",
  CONVERSION_HISTORY_DATE_FROM_FIELD_LABEL_TITLE: "dan",

  CONVERSION_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  CONVERSION_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Register",
  CONVERSION_HISTORY_TABLE_STATUS_CODE_COLUMN_TITLE: "Status",
  CONVERSION_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Karta turi",
  CONVERSION_HISTORY_TABLE_DETAILS_CODE_COLUMN_TITLE: "Batafsil ma’lumot",
  CONVERSION_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  CONVERSION_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  CONVERSION_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "To’lov kodi",
  CONVERSION_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register #",
  CONVERSION_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Sana va vaqti",
  CONVERSION_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Tranzit hisobvaraq",

  // Individual role Conversion "Conversion Tab"

  CONVERSION_CONVERSION_TO_FIELD_LABEL_TITLE: "gacha",
  CONVERSION_CONVERSION_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  CONVERSION_CONVERSION_FROM_FIELD_LABEL_TITLE: "dan",
  CONVERSION_CONVERSION_AMOUNT_LEFT_FIELD_LABEL_TITLE: "Miqdor",
  CONVERSION_CONVERSION_AMOUNT_RIGHT_FIELD_LABEL_TITLE: "Miqdor",
  CONVERSION_CONVERSION_EXCHANGE_FIELD_LABEL_TITLE: "Valyuta kursi",

  // Individual role Conversion "Exchange rate"

  CONVERSION_EXCHANGE_CBU_TITLE: "Markaziy bank kursi",
  CONVERSION_EXCHANGE_TABLE_BUY_COLUMN_TITLE: "SOTIB OLISH",
  CONVERSION_EXCHANGE_TABLE_SELL_COLUMN_TITLE: "SOTISH",
  CONVERSION_EXCHANGE_KDB_TITLE: "KDB Bank O’zbekistan ayirboshlash shoxobchasi kursi",
  CONVERSION_EXCHANGE_UZRCE_RATES_TITLE: "O’zRVB kursi",
  CONVERSION_EXCHANGE_BANK_RATE_TITLE: "Bank kursi",

  // Individual role  Pre-Approval of New Loan & L/C Application

  PRE_APPROVAL_DEFAULT_TAB_TITLE: "Default",
  PRE_APPROVAL_CORPORATE_TAB_TITLE: "Korporativ qarz oluvchi",
  PRE_APPROVAL_INDIVIDUAL_TAB_TITLE: "Shaxsiy ariza",
  PRE_APPROVAL_COMMENT_TAB_TITLE: "KDB Bank O’zbekistan sharhlari",

  PRE_APPROVAL_DATE_FIELD_LABEL_TITLE: "Tashkil etilgan sana",
  PRE_APPROVAL_REQUEST_FIELD_LABEL_TITLE: "So’rov raqami",
  PRE_APPROVAL_MANAGER_FIELD_LABEL_TITLE: "Bank MFO",
  PRE_APPROVAL_SCREEN_TITLE: "Oyna #2. Yangi kreditni yoki akkreditivni oldindan tasdiqlash",
  PRE_APPROVAL_KDB_SUBTITLE:
    "KDB BankUzbekistan," +
    " Shu bilan sizdan (vositachi bank) quyidagi shaxsiy arizani moliyalashtirish uchun (umumiy on lending shartnoma sana 10.05.2020) on lending kredit mablag’laridan foydalanishga ruxsat berishingizni so’raymiz.",

  PRE_APPROVAL_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  PRE_APPROVAL_PRODUCTS_FIELD_LABEL_TITLE: "Maxsulotlar",
  PRE_APPROVAL_BORROWER_FIELD_LABEL_TITLE: "Qarz oluvchi",
  PRE_APPROVAL_SIZE_FIELD_LABEL_TITLE: "Biznes hajmi",
  PRE_APPROVAL_EMPLOYEE_FIELD_LABEL_TITLE: "Xodimlar soni",
  PRE_APPROVAL_BUSINESS_FIELD_LABEL_TITLE: "Biznes faollik",
  PRE_APPROVAL_TAX_FIELD_LABEL_TITLE: "Soliq to’lovchining identifikatsiya raqami",
  PRE_APPROVAL_DATE_OF_FIELD_LABEL_TITLE: "Tashkil etilgan sana",
  PRE_APPROVAL_MANAGEMENT_FIELD_LABEL_TITLE: "Qarz oluvchi boshqaruvi",
  PRE_APPROVAL_INFO_CORPORATION_TITLE: "Korporativ qarz oluvchi ma’lumoti",
  PRE_APPROVAL_LARGEST_FIELD_LABEL_TITLE: "Yirik aksiyadorlar (10% dan yuqori)",

  PRE_APPROVAL_NET_PRODUCTS_FIELD_LABEL_TITLE: "Maxsulotlar",
  PRE_APPROVAL_TOTAL_BORROWER_FIELD_LABEL_TITLE: "Qarz oluvchi",
  PRE_APPROVAL_TOTAL_ADDRESS_FIELD_LABEL_TITLE: "Manzil",
  PRE_APPROVAL_PAGE_SIZE_FIELD_LABEL_TITLE: "Biznes hajmi",
  PRE_APPROVAL_BANK_TAX_FIELD_LABEL_TITLE: " Soliq to’lovchining identifikatsiya raqami ",
  PRE_APPROVAL_NET_DATE_OF_OF_FIELD_LABEL_TITLE: "Tashkil etilgan sana",
  PRE_APPROVAL_EXPORT_EMPLOYEE_NUMBER_FIELD_LABEL_TITLE: "Xodimlar soni",
  PRE_APPROVAL_TOTAL_MANAGEMENT_FIELD_LABEL_TITLE: "Qarz oluvchi boshqaruvi",
  PRE_APPROVAL_NET_BUSINESS_ACTIVITY_FIELD_LABEL_TITLE: "Biznes faollik",
  PRE_APPROVAL_FINANCIALS_TITLE: "Moliyaviy ko’rsatkish (",
  PRE_APPROVAL_FINANCIALS_TITLE_SECOND: "); Birlik: UZS minglikda",
  PRE_APPROVAL_EXPORT_LARGEST_FIELD_LABEL_TITLE: "Yirik aksiyadorlar (10% dan yuqori)",

  PRE_APPROVAL_FINANCIAL_NET_SALES_FIELD_LABEL_TITLE: "Sof savdo",
  PRE_APPROVAL_FINANCIAL_BANK_LOANS_FIELD_LABEL_TITLE:
    "(bank krediti va lizingini ichiga olgan holda)",
  PRE_APPROVAL_FINANCIAL_NET_INCOME_FIELD_LABEL_TITLE: "Sof foyda",
  PRE_APPROVAL_FINANCIAL_TOTAL_ASSETS_FIELD_LABEL_TITLE: "Jami aktivlar",
  PRE_APPROVAL_FINANCIAL_TOTAL_CAPITAL_FIELD_LABEL_TITLE: "Jami kapital",
  PRE_APPROVAL_FINANCIAL_EXPORT_REVENUE_FIELD_LABEL_TITLE: "Eksportdan foyda",
  PRE_APPROVAL_FINANCIAL_TOTAL_LIABILITIES_FIELD_LABEL_TITLE: "Jami majburiyatlar",
  PRE_APPROVAL_FINANCIAL_NEW_OPERATING_INCOME_FIELD_LABEL_TITLE: "Yangi operatsion daromad",

  PRE_APPROVAL_SECURITY_FIELD_LABEL_TITLE: "Xavfsizlik",
  PRE_APPROVAL_SUBMIT_BUTTON_TITLE: "Arizani taqdim etish",
  PRE_APPROVAL_INTEREST_FIELD_LABEL_TITLE: "Foiz stavkasi",
  PRE_APPROVAL_REQUEST_GRACE_FIELD_LABEL_TITLE: "Imtiyozli davr",
  PRE_APPROVAL_REQUEST_TERM_FIELD_LABEL_TITLE: "So’ralgan muddat",
  PRE_APPROVAL_PURPOSE_FIELD_LABEL_TITLE: "Moliyalashtirish maqsadi",
  PRE_APPROVAL_APPLICATION_FIELD_LABEL_TITLE: "Ariza turi",
  PRE_APPROVAL_REQUEST_AMOUNT_FIELD_LABEL_TITLE: "So’ralgan miqdor",
  PRE_APPROVAL_PAGE_REQUEST_TERM_FIELD_LABEL_TITLE: "So’ralgan muddat",
  PRE_APPROVAL_INFO_INDIVIDUAL_TITLE: "Shaxsiy ariza haqida ma’lumot",
  PRE_APPROVAL_DESCRIPTION_FIELD_LABEL_TITLE: "Sotib olinayotgan tovarlarning tavsifi",

  PRE_APPROVAL_CANCEL_BUTTON_TITLE: "Arizani bekor qilish",
  PRE_APPROVAL_COMMENT_TITLE: "KDB Bank O’zbekiston sharhi",
  PRE_APPROVAL_UPDATE_BUTTON_TITLE: "Arizani yangilash va qayta taqdim etish",

  // Individual role  Transfers "Transfers To Another Client Tab"

  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TO_FIELD_LABEL_TITLE: "gacha",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_FROM_FIELD_LABEL_TITLE: "dan",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_AMOUNT_FIELD_LABEL_TITLE: "Miqdor",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TRANSFERS_DETAILS_FIELD_LABEL_TITLE:
    "O’tkazma haqida ma’lumotlar",

  //Individual role Transfers "History Tab"

  INDIVIDUAL_TRANSFERS_HISTORY_TO_FIELD_LABEL_TITLE: "gacha",
  INDIVIDUAL_TRANSFERS_HISTORY_DATE_FIELD_LABEL_TITLE: "Sana",

  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_ACCOUNT_COLUMN_TITLE: "Status",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Batafsil ma’lumotlar",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Register",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Karta turi",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "To’lov kodi",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Sana va vaqti",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Register #",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Tranzit hisobvaraq",

  //Individual role Transfers "Favorites Tab"

  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_STATUS_COLUMN_TITLE: "Status",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Mavzu",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_CREATED_BY_COLUMN_TITLE: "Bajaruvchi",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Avtorizator",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_EXPLANATION_COLUMN_TITLE: "Tushuntirish",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Tugatilgan sana",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Taqdim etilgan sana",

  // Corporate role "Client Feedback Tab"

  CLIENT_FEEDBACK_RATE_VERY_TITLE: "Juda norozi",
  CLIENT_FEEDBACK_RATE_VERY_SATISFIED_TITLE: "Juda rozi",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_SATISFIED_TITLE: "O’rtacha rozi",
  CLIENT_FEEDBACK_RATE_NEITHER_TITLE: "Rozi ham emas, norozi ham emas",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_DISSATISFIED_TITLE: "O’rtacha norozi",
  CLIENT_FEEDBACK_RATE_PLEASE_TITLE:
    "Iltimos, KDB Internet Banking iDBA bilan umumiy tajribangizni baholang",
  CLIENT_FEEDBACK_SUGGESTIONS_TITLE: "Sharhlar va takliflar",

  CLIENT_FEEDBACK_NOT_LIKELY_SUBTITLE: "Juda yaxshi",
  CLIENT_FEEDBACK_EXTREMELY_LIKELY_SUBTITLE: "Juda yomon",
  CLIENT_FEEDBACK_HOW_LIKELY_TITLE:
    "Do’stingizga KDB Internet Banking xizmatini tavsiya qilish ehtimoli qanchalik baland?",

  CLIENT_FEEDBACK_LOAN_CHECKBOX_LABEL_TITLE: "Kredit",
  CLIENT_FEEDBACK_YEISVO_CHECKBOX_LABEL_TITLE: "TSOYEAT",
  CLIENT_FEEDBACK_NEWS_CHECKBOX_LABEL_TITLE: "Yangiliklar",
  CLIENT_FEEDBACK_SUBMIT_BUTTON_LABEL_TITLE: "Taqdim etish",
  CLIENT_FEEDBACK_HELP_CHECKBOX_LABEL_TITLE: "Yordam/Tez tez so’raladigan savollar",
  CLIENT_FEEDBACK_ACCOUNTS_CHECKBOX_LABEL_TITLE: "Hisobvaraqlar",
  CLIENT_FEEDBACK_ON_LENDING_CHECKBOX_LABEL_TITLE: "On Lending",
  CLIENT_FEEDBACK_ANALYTICS_CHECKBOX_LABEL_TITLE: "Analitika",
  CLIENT_FEEDBACK_CONVERSION_CHECKBOX_LABEL_TITLE: "Konversiya",
  CLIENT_FEEDBACK_PAYMENTS_CHECKBOX_LABEL_TITLE: "O’tkazmalar",
  CLIENT_FEEDBACK_IN_WHICH_LIKELY_TITLE:
    "Internet Banking ning qaysi qismini yaxshilanishini xohlaysiz?",
  CLIENT_FEEDBACK_WHICH_LIKELY_TITLE:
    "Internet Banking ning qaysi qismi sizning asosiy yoki doimiy ishlatadiganingiz?",
  CLIENT_FEEDBACK_SENT: "Sizning fikringiz yuborildi",
  CLIENT_FEEDBACK_CHOOSE_RATE: "Iltimos 1 – 10 gacha baholang",

  //Financial role Main "Approved Deals Tab"

  FINANCIAL_APPROVED_DEALS_UNIT_SUBTITLE: "Birlik: AQSH Dollari",
  FINANCIAL_APPROVED_DEALS_TABLE_TOTAL_COLUMN_TITLE: "Jami",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Batafsil ma’lumot",
  FINANCIAL_APPROVED_DEALS_APPROVED_DEALS_TITLE: "Tasdiqlangan bitimlar",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: "Ariza sanasi",
  FINANCIAL_APPROVED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Sub-qarz oluvchi nomi",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Ariza raqami",
  FINANCIAL_APPROVED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Tasdiqlangan Sub-kredit miqdori",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE: "New withdrawal",
  FINANCIAL_APPROVED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Undirilmagan Sub-kredit summasi",

  // Corporate role Accounts "Statement tab"
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE: "Debit",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE: "Kredit",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_BALANCE_COLUMN_TITLE: "Balans",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_REFERENCE_COLUMN_TITLE: "Ma’lumotnoma / Tavsif",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_POST_VALUE_COLUMN_TITLE: "Qiymat",
  STATEMENT_EXPORT_EXCEL_DATA_LABEL: "Sana",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_LABEL: "Yuboruvchi hisobi",
  STATEMENT_EXPORT_EXCEL_SENDER_TAX_NUMBER_LABEL: "Yuboruvchining soliq identifikatori(TaxId)",
  STATEMENT_EXPORT_EXCEL_SENDER_NAME_LABEL: "Yuboruvchi nomi",
  STATEMENT_EXPORT_EXCEL_SENDER_MFO_LABEL: "Yuboruvchi MFO",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_LABEL: "Qabul qiluvchining hisobi",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_INDEX_LABEL: "Qabul qiluvchining hisobi (indeks)",
  STATEMENT_EXPORT_EXCEL_RECEIVER_MFO_LABEL: "Qabul qiluvchi MFO",
  STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL: "Qabul qiluvchining nomi",
  STATEMENT_EXPORT_EXCEL_RECEIVER_TAX_NUMBER_LABEL:
    "Qabul qiluvchining soliq identifikatori(TaxId)",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_NUMBER_LABEL: "Hujjat raqami",
  STATEMENT_EXPORT_EXCEL_EXPENDITURE_LABEL: "Xarajatlar",
  STATEMENT_EXPORT_EXCEL_COMING_LABEL: "Kelayotgan",
  STATEMENT_EXPORT_EXCEL_PAYMENT_CODE_LABEL: "To'lov kodi",
  STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL: "To'lov maqsadi",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_TYPE_LABEL: "Hujjat turi",
  STATEMENT_EXPORT_EXCEL_TRANSACTION_NUMBER_LABEL: "Tranzaksiya raqami",
  STATEMENT_EXPORT_EXCEL_CARD_TYPE_LABEL: "Karta turi",
  STATEMENT_EXPORT_EXCEL_TRANSITION_DATE_LABEL: "O'tkazish sanasi",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_NUMBER_LABEL: "Karta raqami",
  STATEMENT_EXPORT_EXCEL_TRANSITION_TIME_LABEL: "O'tish vaqti",
  STATEMENT_EXPORT_EXCEL_TRANSITION_PLACE_LABEL: "Tranzaktsiya joyi",
  STATEMENT_EXPORT_EXCEL_AMOUNT_LABEL: "Miqdori",
  STATEMENT_EXPORT_EXCEL_COMMISSION_AMOUNT_LABEL: "Komissiya miqdori",
  STATEMENT_EXPORT_EXCEL_AMOUNT_AFTER_COMMISSION_LABEL: "Komissiyadan keyingi miqdor",
  STATEMENT_EXPORT_EXCEL_SELLER_ID_LABEL: "Sotuvchi identifikatori(ID)",
  STATEMENT_EXPORT_EXCEL_CLIENT_NAME_LABEL: "Mijoz nomi",
  STATEMENT_EXPORT_EXCEL_ACCOUNT_NUMBER_LABEL: "Hisob raqami",
  STATEMENT_EXPORT_EXCEL_DR_CR_INDICATOR_LABEL: "DRCRIndikator",
  STATEMENT_EXPORT_EXCEL_EFFECTIVE_DATE_LABEL: "Ijroga Kirish muddati",
  STATEMENT_EXPORT_EXCEL_ISO_TERMINAL_ID_LABEL: "ISO Terminal ID",
  STATEMENT_EXPORT_EXCEL_ISO_MERCHANT_ID_LABEL: "ISO savdogar identifikatori(ID)",
  STATEMENT_EXPORT_EXCEL_CARD_NUMBER_LABEL: "Karta raqami",
  STATEMENT_EXPORT_EXCEL_DT_BEG_LABEL: "Boshlanish sanasi",
  STATEMENT_EXPORT_EXCEL_DT_END_LABEL: "Tugash sanasi",
  STATEMENT_EXPORT_EXCEL_H_LOCACC_LABEL: "H_LOCCAC",
  STATEMENT_EXPORT_EXCEL_DOC_NUMBER_LABEL: "Doc №",
  STATEMENT_EXPORT_EXCEL_BO_LABEL: "BO",
  STATEMENT_EXPORT_EXCEL_PAYERS_NAME_LABEL: "To'lovchining ismi",
  STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL: "Soliq raqami",
  STATEMENT_EXPORT_EXCEL_MFO_LABEL: "MFO",
  STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL: "Hisob tekshirilmoqda",
  STATEMENT_EXPORT_EXCEL_PAYMENT_DATE_LABEL: "To'lov sanasi",
  STATEMENT_EXPORT_EXCEL_OPERATION_DATE_LABEL: "Operatsiya sanasi",
  STATEMENT_EXPORT_EXCEL_RECEIVER_BANK_LABEL: "Qabul qiluvchi bank",
  STATEMENT_EXPORT_EXCEL_TOTAL_PERIOD_LABEL: "Davr uchun jami",
  STATEMENT_EXPORT_EXCEL_BALANCE_END_PERIOD_LABEL: "Davr oxiridagi qoldiq",
  STATEMENT_EXPORT_EXCEL_BALANCE_BEGINNING_PERIOD_LABEL: "Davr boshidagi qoldiq",
  STATEMENT_EXPORT_EXCEL_TO_LABEL: "to",
  STATEMENT_EXPORT_EXCEL_FROM_LABEL: "dan boshlab davr uchun",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_LABEL:
    "Hisobvaraq bo'yicha debet aylanmalari to'g'risidagi hisobot",
  STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL: "PASIV",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_CREDIT_LABEL:
    "Hisobvaraq bo'yicha kredit aylanmalari to'g'risidagi hisobot",
  STATEMENT_NO_TRANSACTIONS: "Tranzaksiya yo'q",

  // Corporate role Transfers "Salary tab"
  SALARY_CREATE_NEW_REGISTER_SUBMIT_BUTTON_TITLE: "Taqdim etish",
  SALARY_CREATE_NEW_REGISTER_TABLE_ID_COLUMN_TITLE: "#",
  SALARY_CREATE_NEW_REGISTER_TABLE_STATUS_COLUMN_TITLE: "Status",
  SALARY_CREATE_NEW_REGISTER_TABLE_CARD_STATUS_COLUMN_TITLE: "Karta holati",
  SALARY_CREATE_NEW_REGISTER_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE: "To’liq nomi",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE: "Tashkilot nomi",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Hisobraqam",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_FROM_SYSTEM_COLUMN_TITLE:
    "Tizimdan olingan to’liq nomi",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_FROM_SYSTEM_COLUMN_TITLE:
    "Tizimdan olingan tashkilot nomi",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_FROM_SYSTEM_COLUMN_TITLE:
    "Tizimdan olingan Hisobraqam",
  CORPORATE_CARD_REPLANISHMENT_TEXT: "Registrga muvofiq korporativ kartani to’ldirish",
  TRANSFERS_TRANSFERS_OPEN_MODAL_BUTTON_TITLE: "Karta egalaridan yuklab olish",
  TRANSFERS_SALARY_LIST_OF_CARD_CHOOSE: "Yuklab olish",

  // Payroll statuses
  PAYROLL_STATUS_CREATED: "Bajaruvchi",
  PAYROLL_STATUS_ENTERED: "Kiritildi",
  PAYROLL_STATUS_APPROVED: "Bank tomonidan qabul qilindi",
  PAYROLL_STATUS_PROCESSING: "Qayta ishlash",
  PAYROLL_STATUS_SENT_TO_BANK: "Bankka yuborish",
  PAYROLL_STATUS_REJECTED: "Mijoz tomonidan rad etilgan",
  PAYROLL_STATUS_ANNULLED: "Bekor qilindi",
  PAYROLL_STATUS_COMPLETED: "Muvaffaqiyatli tugatildi",
  PAYROLL_STATUS_SENT_TO_CB: "Markaziy bankka jo’natildi",
  PAYROLL_STATUS_REJECTED_BY_CB: "Bank tomonidan rad etildi",
  PAYROLL_STATUS_SENT_TO_CARD_SYSTEM: "Karta tizimiga yuborildi",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1: "Birinchi avtorizator tomonidan tasdiqlandi",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2: "Ikkinchi avtorizator tomonidan tasdiqlandi ",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3: "Uchinchi avtorizator tomonidan tasdiqlandi ",
  STATUS_REJECTED: "Rad edildi",
  STATUS_ACCEPTED: "Qabul qilindi",
  REJECT_BUTTON: "Buni rad qilmoqchimisiz?",
  NOTIFICATION_ON_STATUS_FOR_CREATER:
    "Iltimos, faqat siz yaratgan va “Yaratilgan” maqomiga ega hujjat(lar)ni tanlang",

  //
  //
  // New Added Translates
  //
  //

  TRANSFER_HISTORY_STATUS_ALL: "Barcha",
  TRANSFER_HISTORY_STATUS_OUTGOING: "Chiqish",
  TRANSFER_HISTORY_STATUS_INCOMING: "Kirish",

  TRANSFER_SALARY_DETAIL_TYPE_SALARY: "Ish haqi",
  TRANSFER_SALARY_DETAIL_TYPE_PREPAYMENT: "Oldindan to’lov",
  TRANSFER_SALARY_DETAIL_TYPE_TRAVEL: "Safar xarajatlari",
  TRANSFER_SALARY_DETAIL_TYPE_COMPENSATION: "Tovon puli",
  TRANSFER_SALARY_DETAIL_TYPE_BONUS: "Bonus",
  TRANSFER_SALARY_DETAIL_TYPE_PENSION: "Pensiya",
  TRANSFER_SALARY_DETAIL_TYPE_OTHERS: "Boshqalar",
  TRANSFER_SALARY_DETAIL_TYPE_VOCATION: "Ta'til uchun to'lov",
  TRANSFER_SALARY_DETAIL_TYPE_SETTLEMENT: "Yakuniy hisob -kitob",

  HELP_AND_FAQ_QUESTION: "Savol: ",
  HELP_AND_FAQ_ANSWER: "Javob: ",

  ATTENTION_TITLE: "Diqqat!",
  ATTENTION_SUBTITLE: "Boshqa foydalanuvchi tizimga kirdi!",

  TRANSFER_ERRORS_BENEFICIARY_BANK: "Bank topilmadi",
  TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE: "Benefitsiar bank",
  TRANSFER_ERRORS_AMOUNT: "Miqdori 0 bo’lishi mumkin emas",
  TRANSFER_ERRORS_AMOUNT_TITLE: "Miqdor",
  TRANSFER_ERRORS_DEBIT_ACCOUNT: "Debit hisobvaraqni tanlash",
  TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE: "Debit",
  TRANSFER_ERRORS_BUDGET_ACCOUNT: "Byudjet hisobvaraq topilmadi",
  TRANSFER_ERRORS_BUDGET_ACCOUNT_TITLE: "Byudjet hisobvaraq",
  TRANSFER_INFO_CREATED: "O’tkazma yaratildi",
  TRANSFER_MODIFIED: "O’tkazma o’zgartirildi",
  TRANSFER_INFO_TITLE: "O’tkazma",
  TRANSFER_CHARGES_ERROR: "Iltimos, pul o’tkazish uchun to’lovni tanlang",
  TRANSFER_SENDER_PHONE_NUMBER_ERROR: "Telefon nomerni kiriting",
  TRANSFER__CHOOSE_CHARSET: "Iltimos, charset -ni tanlang",
  TRANSFER_CHARGES_ERROR_TITLE: "Komissiya",
  TRANSFER_FILL_INFO:
    "Stralarni faqat ingliz alifbosining katta harflari bilan to’ldiring. Iltimos, rus tilidan ingliz tiliga quyidagi transliteratsiya jadvalidan foydalaning.",
  TRANSFER_NACE_CODE_ERROR_TITLE: "Nace Code",
  TRANSFER_NACE_CODE_ERROR: "Iltimos, pul o’tkazish uchun Nace Codeni tanlang",

  INQUIRY_STANDART_ERRORS_MAX: "Maksimal 20 hisobvaraq kiritilishi yoki barchasini tanlash mumkin.",
  INQUIRY_STANDART_ERRORS_MAX_TITLE: "Hisobvaraq",

  CONVERSION_INFO_SEND: "Konversiya yaratildi",
  CONVERSION_INFO_TITLE: "Konversiya",
  CONVERSION_FAVORITES_ERROR: "Iltimos, bitimning raqami va sanasini kiriting",
  CONVERSION_FAVORITES_ERROR_TITLE: "Tanlangan",
  CONVERSION_FAVORITES_INFO_SAVED: "Tanlanganga saqlash",
  CONVERSION_DOCUMENT_ID: "Hujjat ID si",

  CONVERSION_VISA_CLOSE_DAY_ERROR_TITLE:
    "VISA ochilish balansini solishtirish tufayli, iltimos, 30 daqiqadan so‘ng qayta urinib ko‘ring",

  SALARY_CREATED_INFO: "Ish haqi reyestri yaratildi",
  SALARY_CREATED_INFO_TITLE: "Ish haqi reyestri",
  SALARY_CARD_CREATED_INFO: "Yangi ish haqi kartasini ochish uchun so’rovnoma tuzildi",
  SALARY_CARD_CREATED_INFO_TITLE: "Ish haqi kartasi",
  SALARY_CHOOSE_CARD_TYPE: "Iltimos karta turini tanlang",
  SALARY_CHOOSE_CARD_TYPE_TITLE: "Karta turi",
  SALARY_NO_TRANSIT_ACCOUNT: "Tranzit hisobvarag’i yo’q",
  SALARY_NO_TRANSIT_ACCOUNT_TITLE: "Tranzit hisobvaraq",
  SALARY_FULLNAME_LIMIT_ERROR_TITLE:
    "Ism va Familiya (Ism + Familiya) 24 belgidan ko'p bo'lmasligi kerak",

  QUESTION_CREATED_INFO: "Bankka kelib tushgan savol",
  QUESTION_CREATED_INFO_TITLE: "Savol",

  YEISVO_FIND_ERROR: "Hujjat TSOYEAT tizimida topilmadi",
  YEISVO_FIND_ERROR_TITLE: " TSOYEAT ",

  PASSWORD_ERROR_TITLE: "Parol",
  SERVER_ERROR_TITLE: "Texnik ishlar olib borilmoqda",

  FEEDBACK_INFO_TITLE: "Feedback",

  INFORMATION_TITLE_1:
    "Iltimos, standart so’rovlar ekranida so’rovning holatini tekshiring. Holat \n" +
    "          tugatilgan deb o’zgartirildi, asl xatni olish uchun Bankka murojaat qiling. Izoh: Bank ushbu so’rov uchun\n" +
    "          Bank Tariflariga muvofiq komissiya oladi",

  INFORMATION_TITLE_2:
    "BEN (benefitsiar) hech qanday komissiya to’lanmasligini anglatadi. Komissiya to’lov miqdoridan \n" +
    "          ushlab qolinadi.",
  INFORMATION_TITLE_3:
    "    OUR ko’rsatmasi jo’natuvchini barcha o’tkazmalar uchun xarajatlarini to’lashini anglatadi. struction means Sender pays all transfer charges. Benefitsiar jo’natuvchining barcha\n" +
    "          to’lovini qabul qiladi.",
  INFORMATION_TITLE_4:
    "         SHA (shared) shuni anglatadiki, siz faqat bankning chiquvchi o’tkazma to’lovini to’laysiz. Qabul qiluvchi korrespondent (vositachi) bank \n" +
    "          to’lovi olingan to’lov miqdorini qabul qiladi..",
  INFORMATION_TITLE_5:
    "    PPRO/END ko’rsatmasida, jo’natuvchi barcha to’lov xarajatlarini to’laydi. Benefitsiar jo’natuvchining barcha to’lovini qabul qiladi. \n" +
    "          AQSH Dollaridagi o’tkazmalar uchun faqat JP Morgan Chase Banki orqali amalga oshiriladi.",
  INFORMATION_TITLE_6: "Iltimos, bir reyestrda maksimal 2,000 xodimni taqdim eting.",
  INFORMATION_TITLE_7: "Iltimos, ish haqini rasmiylashtirish uchun tegishli tranzit hisobvarag'ida etarli mablag' mavjudligiga ishoch hosil qiling",
  INFORMATION_TITLE_8: "Iltimos, ish haqini rasmiylashtirish uchun tegishli talab qilib olinguncha depozit hisobvarag'ida etarli mablag' mavjudligiga ishoch hosil qiling.",

  MODAL_CONFIRMATION_TITLE: "Tasdiqlash",
  MODAL_CONFIRMATION_YES: "Tasdiqlash",
  MODAL_CONFIRMATION_NO: "Bekor qilish",
  MODAL_YES: "Ha",
  MODAL_NO: "Yo`q",
  MODAL_PAYMENT_ORDER_TO_RESERVE_FUNDS:
    "22613 maxsus hisobvarag`iga pul mablag`larini zaxiralash uchun to`lov topshirig`ini yaratishni istaysizmi?",
  MODAL_INFO_TRANSFER_FIRST:
    "Iltimos, konversiya qilish uchun konversiya hisobvarag’idan pul o’tkazishni tasdiqlang.",
  MODAL_INFO_TRANSFER_SECOND:
    "Iltimos, o’zingizning mablag’ingizdan pul o’tkazishni xohlayotganingizni tasdiqland. (konversiya hisobvarag’idan emas)",
  MODAL_INFO_SALARY: "Iltimos, ish haqi bo’yicha soliq to’lovlari to’langanligini tasdiqlang",
  MODAL_CONVERSION_TITLE: "Ommaviy oferta",
  MODAL_LOADING_TITLE: "Yuklanmoqda...",
  MODAL_LOADING_EXCEL_TITLE: "Jarayonda . . .",
  
  PENDING_APPROVAL_APPROVE: "Tasdiqlash",
  PENDING_APPROVAL_REJECT: "Rad etish",

  APP_HEADER_SEARCH: "Qidiruv",

  WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE:
    "Kelgusi sanani tanlaganda, iltimos, bu ish kuni ekanligiga ishonch hosil qiling, aks holda to'lov yo'riqnomasi bajarilmasligi mumkin.",

  TRANSFER_NOTIFICATION_TITLE: "O’tkazma",
  TRANSFER_NOTIFICATION_FIRST: "O’tkazma N-",
  TRANSFER_CREATED: "yaratildi",
  TRANSFER_NOT_CREATED: "yaratilmadi",
  TRANSFER_NO_DEBIT_IMPORT: "Bu kabi debit yo’q",
  TRANSFER_NO_PHONE_IMPORT: "Telefon nomer kiritilmagan",
  TRANSFER_DATE_INCORRECT_IMPORT: "Operatsiya sanasi noto’g’ri",
  TRANSFER_CORRESPANDENT_TAX_ID_INCORRECT_IMPORT:
    "Benefisiar soliq identifikatori 9 xonali bo'lishi kerak",

  FILE_SELECTOR_IMPORT_FROM_CSV: "CSV formatdan yuklash",
  FILE_SELECTOR_IMPORT_FROM_TXT: "TXT formatdan yuklash",

  SALARY_REGISTER_STATUS_MATCH: "Mos keldi",
  SALARY_REGISTER_STATUS_NOT_MATCH: "Mos kelmadi",
  SALARY_REGISTER_STATUS_NOT_FOUND: "Hisob raqam topilmadi",
  SALARY_REGISTER_CARD_STATUS_ACTIVE: "Faol",
  SALARY_REGISTER_CARD_STATUS_CLOSED: "Yopilgan",
  SALARY_REGISTER_CARD_STATUS_SUSPENDED: "To'xtatilgan",

  TRANSFER_IMPORT_DEFECTED: "Noto’g’ri",
  TRANSFER_IMPORT_ERROR: "Xato",
  TRANSFER_SUCCESS: "Muvaffaqiyatli",
  TRANSFER_LOADING: "Yuklanmoqda",

  TRANSFER_UPAY_AMOUNT_ERROR_TITLE: "Minimal o'tkazma summasi 1000 so'm",

  TRANSFER_ANOTHER_BANK_CLIENT_KDB_CARD_ERROR_TITLE: `Qabul qiluvchi KDB Bank mijozi bo‘lib, "Boshqa KDB mijozlariga o'tkazma" funksiyasidan foydalaning`,

  TRANSFER_IMPORT_TABLE_HEADER: "Yuklash",
  TRANSFER_IMPORT_TABLE_COMMENT: "Izohlar",
  TRANSFER_SWIFT_NOT_FOUND: "Qabul qiluvchi Bankning SWIFT raqami topilmadi",
  TRANSFER_SWIFT_FIRST_NOT_FOUND:
    "Qabul qiluvchi Bankning korrespondent Banki SWIFT raqami topilmadi ",

  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE: "Shartnoma valyutasi 2",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_BANK_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Xorijiy sherik Bankning mamlakati",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    " Xorijiy sherik Bankning  yuridik manzili",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Hisob-kitob valyutasi 2",
  YEISVO_CLIENTS_INFORMATION_FORM_DETAILS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Chet ellik sherikning bank rekvizitlari",

  ACCOUNT_OPENING_POSITION_HELD: "Lavozimi",
  DELETE_BUTTON: "O’chirish",

  IMPORT_FOR_ANOR: " ANOR tizimi orqali o’tkazmalar",
  IMPORT_FOR_CBU: "MB kliring tizimi orqali o’tkazmalar",

  CONVERSION_MODIFIED: "Konvertatsiya o’zgartirildi",

  LOAN_INTEREST_AMOUNT: "To’lov summasi (foiz)",

  SALARY_CHOOSE_GENDER: "Iltimos, Jinsni tanlang",
  SALARY_CHOOSE_BIRTHDAY_DATE: "Iltimos, Tug'ilgan sanani tanlang",
  SALARY_CHOOSE_PASSPORT_REGISTRATION_DATE: "Iltimos, Passport berilgan sanani tanlang",
  SALARY_CHOOSE_PASSPORT_EXPIRY_DATE: "Iltimos, Passport amal qilish muddatini tanlang",

  SALARY_TOTAL_AMOUNT: "Umumiy summa",
  SALARY_DETAILS_FOR: " ",
  SALARY_DETAILS_ACCORDING: "Reyestr ko’ra",
  SALARY_PINFL_IS_REQUIRED: "PINFL talab qilinadi",

  CONVERSION_COMMISSION_RATE_AND_AMOUNT: "Komissiya summasi va stavkasini tekshiring",

  CONVERSION_PURPOSE_ERROR: "Konvertatsiya maqsadini tanlang",

  PENDING_APPROVAL_DOCUMENT_CHOOSE: "Hujjatni tanlang",
  PENDING_APPROVAL_JOB_CHOOSE: "Tasdiqlash yoki rad etishni tanlang",

  CHANGE_PASSWORD_SENT_TO_EMAIL: "Kodni E-pochtaga yuborish",
  CHANGE_PASSWORD_CODE_FROM_EMAIL: " E-pochtadan kod",
  OTP_KEY: "OTP Kalit",
  TRANSFER_TIME_OUTGOING_TO_SUBMIT: "Chiqish o’tkazmalariga xizmat ko’rsatish vaqti (Toshkent):",
  TRANSFER_TIME_INTERNAL_TO_SUBMIT: " Ichki o’tkazmalarga xizmat ko’rsatish vaqti (Toshkent):",
  TRANSFER_SERVICING_TIME: " O’tkazmalarga xizmat ko’rsatish vaqti (Toshkent):",

  SALARY_CREATE_AND_SAVE_TO_PDF: "Yuborish va PDF ga saqlash",

  SAVE_TO_WORD: "Word ga saqlash",

  DISABLED: "Quyidagi belgilarni ishlatmang",

  APRROVE_INFO:
    "Agar chet el valyutasida o’tkazmalar 10,000.00 AQSh dollaridan oshsa, iltimos, Bank mutaxassisi tomonidan to’lovni tasdiqlash uchun qo’ng’iroqni kuting",

  CONFIRM_SESSION: "Davom etish",
  CONFIRM_TEXT: "Sessiyani davom ettirishni istaysizmi?",
  EXIT_SESSION: "Chiqish",

  TRANSFER_BENEFICIARY_ERROR: "Byudjet hisob raqami topilmadi",
  TRANSFER_STATUS_SENT_BY_BANK: "Bank tomonidan yuborildi",

  SYSTEM_ERROR: " Tizimda xatolik (keyinroq qayta urinib ko’ring)",

  TRANSFER_TEXT_REQUIRED: "Faqat quyidagilardan foydalaning:",

  LOGIN_WRONG_CAPTCHA: "Noto’g’ri CAPTCHA",
  LOGIN_REQUIRED_CAPTCHA: "Recaptcha talab qilinadi",
  LOGIN_NOT_FOUND: "Foydalanuvchi Nomi topilmadi",
  LOGIN_USER_NOT_FOUND:
    "Foydalanuvchi Nomi Internet-bank iDBA-da ro’yxatdan o’tkazilmagan. Iltimos, Bank bilan bog’laning",

  TRANSFERS_USE_THIS_SYMBOLS: "Taqiqlangan harf yoki belgi ishlatilgan",

  CONVERSION_DOCUMENTS_TEXT: "Hujjatlar ilova qilingan",
  CONVERSION_DOCUMENTS: "Hujjatlar",
  CONVERSION_OFFER_TEXT: " Ommaviy offertaga qo’shilish bandi:",
  CONVERSION_OFFER:
    "Ushbu buyurtmaning imzolanishi Mijoz tomonidan ushbu buyurtma imzolangan sanada Bank vebsaytida (www.kdb.uz) nashr qilingan konvertatsiya xizmatlarini ko’rsatish bo’yicha ommaviy ofertaning barcha shartlari so’zsiz qabul qilinganligini, shuningdek, Mijozning ko’rsatilgan xizmatlar uchun Bank tariflariga va ushbu Arizaga muvofiq talab qilingan mablag’larni to’g’ridan-to’g’ri chiqim qilishga rozilik bildirishini anglatadi",
  CONVERSION_TEXT_AUTHORIZE:
    "Ushbu bilan chet el valyutasini sotish uchun yuqorida ko’rsatilgan valyuta hisob varrag’imizdan Bankka oldinda chiqim qilish huquqini beramiz",

  LOGIN_ACCOUNT_DISABLED: "Foydalanuvchi IDsi o’chirilgan",
  RESET_PASSWORD_CODE_SEND: "Tasdiqlash kodi quyidagi elektron pochtaga yuborildi:",

  STATEMENT_NO_DATA: "Hujjatlar yo’q",

  AMOUNT_COPY_PASTE: "Nusxa ko’chirish va joylashtirishga yo’l qo’yilmaydi",

  FILE_CONTROLS_SAVE_TO_EXCEL1_BUTTON_TITLE: "Kredit",
  FILE_CONTROLS_SAVE_TO_EXCEL2_BUTTON_TITLE: "Debit",
  FILE_CONTROLS_SAVE_TO_EXCEL3_BUTTON_TITLE: "Kredit & Debit",
  FILE_CONTROLS_SAVE_TO_EXCEL4_BUTTON_TITLE: " Kredit & Debit & Qoldiq",
  FILE_CONTROLS_SAVE_TO_EXCEL5_BUTTON_TITLE: "Save to Excel 1",
  FILE_CONTROLS_SAVE_TO_EXCEL_NEW_BUTTON_TITLE: "Save to Excel (New)",

  ANALYTICS_ERROR_NO_INFO: "Hisob raqam haqida ma’lumot yo’q",
  ANALYTICS_PERIOD_HEADER: " Davr ",
  ANALYTICS_ERROR_CHOOSE: " Iltimos, hisob raqam va muddatni tanlang ",

  ACCOUNT_CARD_BOX_TAB: "Kartoteka",
  ACCOUNT_SWIFT_TAB: "SWIFT",

  CARD_BOX_TITLE_DOCUMENT_NUMBER: "Hujjat raqami",
  CARD_BOX_DATA: "Hujjat sanasi",
  CARD_BOX_DATA_DISPLACEMENT: "K-2 ga o’tish sanasi",
  CARD_BOX_AMOUNT: "Qolgan summa",
  CARD_BOX_AMOUNT_ANNOUNCED: "Qo’yilgan summa",
  CARD_BOX_MFO_RECEIVER: "Oluvchi bank MFOsi",
  CARD_BOX_ACCOUNT_NUMBER_RECEIVER: "Qabul qiluvchining hisob raqami",
  CARD_BOX_RECEIVER_NAME: "Qabul qiluvchining nomi",
  CARD_BOX_DETAILS: "To’lov maqsadi",

  USER_INFO_AND_SETTINGS_ALERT_NOTIFICATION:
    "Bildirishnoma sozlamalarini o’zgartirmoqchi ekanligingizni tasdiqlang",
  PENDING_APPROVAL_EXPIRED_ERROR: "Operatsiyani amalga oshirish vaqti tugadi",
  PENDING_APPROVAL_NULL_ERROR:
    "Transit hisovarag’ingizda tranzaktsiyani amalga oshirish uchun mablag’ etarli emas",

  //
  //
  // New Translates
  //
  //

  SALARY_PHONE_TYPE: "Telefon",
  SALARY_PHONE_NUMBER_TITLE: "SMS xabarnoma uchun telefon raqami",
  SALARY_PASSWORD_PHONE_TITLE: "Telefon suhbatlari uchun parol",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_LENGTH:
    "Tanlangan mamlakat uchun har bir pasport seriyasi uchun maksimal 2 ta belgi",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_LENGTH:
    "Tanlangan mamlakat uchun har bir pasport raqami uchun maksimal 7 ta belgi",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_ERROR:
    "Pasport seriya raqami noto'g'ri kiritilgan",

  INDIVIDUAL_INQUIRY_REGISTER_AM: "Men",
  INDIVIDUAL_ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    " Bankdan hisobvaraq(lar)im haqida quyidagi ma’lumotlarni taqdim etilishi so’raladi;",
  INDIVIDUAL_ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " Bankdan quyidagi hisobvaraq(lar)im bo’yicha qo’shimcha ko’chirmani taqdim etilishini so’rayman;",
  INDIVIDUAL_ENTER_RECEIVER_ACCOUNT: "Iltimos oluvchinig hisob raqami yoki karta raqamini kiriting",
  NOT_AVAILABLE_TRANSACTION_TITLE: "Ushbu tranzaksiya mavjud emas.",
  NOT_AVAILABLE_TRANSACTION: "Qabul qiluvchining HUMO karta raqamini kiriting.",
  INDIVIDUAL_CONVERSION_HISTORY_RECEIVER: "Qabul qiluvchining hisob raqami",
  INDIVIDUAL_CONVERSION_BUY_AMOUNT: "Chet el valyutasi miqdori",
  INDIVIDUAL_CONVERSION_SELL_AMOUNT: "Milliy valyutadagi summa",

  INDIVIDUAL_FAVORITE_ID_TITLE: "ID",
  INDIVIDUAL_SMS_CODE_TITLE: "SMS Code",
  INDIVIDUAL_SMS_CODE_AUTH_TITLE: "SMS Authorization",

  INDIVIDUAL_TRANSFERS_SIDE_MENU: "O'tkazmalar",
  INDIVIDUAL_CHOOSE_ACCOUNT: "Hisob raqamni tanlang",
  INDIVIDUAL_CARD_BALANCE_TITLE: "Karta balansi",
  HUMO_EXPIRY_DATE: "Expiry date",

  DEMAND_TO_DEMAND_MESSAGE:
    "O'tkazma yuborildi, iltimos, 3 daqiqa ichida holatini Tarix bo'limida tekshiring.",

  CLEARING_OPEN: "Хурматли Мижоз, операцион кун (клиринг тизими) очилганлигини маълум киламиз!",
  CLEARING_NOT_OPEN:
    "Хурматли Мижоз, операцион кун (клиринг тизими) Банкимизда хали очилмаганлиги туфайли туловларни операцион кун очилгунига кадар АНОР тизими оркали юборишингизни сураймиз.",
  NOTIFICATION_MESSAGE: `
  Hurmatli Mijozlar,
  Oxirgi paytlarda moliyaviy firibgarlik xolatlari (bank kartalardan pul mablag’larini noqonuniy tarzda yechib olish) ko’payib borayotgani sababli, “KDB Bank O’zbekiston” AJ Sizdan xushyor bo’lishingizni va telefon raqamingizga yuborilgan SMS-kodlarni hamda bank kartangiz bo’yicha ma’lumotlarni (amal qilish muddati, CVV) hech kimga bermasligingizni, kartaning fotosuratini ijtimoiy tarmoqlarda joylashtirmaslikni so’raydi.
  Iltimos, ushbu ma’lumotni yaqinlaringiz va hamkasblaringizga ham yetkazing.
  `,
  NOTIFICATION_MESSAGE_CACHE_LIMIT: `
  Rezident va norezident jismoniy shaxslarga xizmat ko’rsatish tartibiga kiritilgan o’zgarishlar tasdiqlandi
  
Komplayens talablaridagi so’nggi o’zgarishlar munosabati bilan Bank Boshqaruv Kengashi 2022-yil 15-avgustdan kuchga kiruvchi quyidagi qarorlarni qabul qildi.
-------------------------------------------------------------------------------------------------------------------------------------------
Rezident jismoniy shaxslar uchun:
Kalendar oyi davomida valyuta ayirboshlash shoxobchasida 10 ming AQSH dollari ekvivalentidan ortiq miqdorda milliy valyutani xorijiy valyutaga ayirboshlash bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi. Oy davomidagi ayirboshlashning maksimal miqdori 100 ming AQSh dollarini yoki shu ekvivalentdagi boshqa xorijiy valyuta miqdorini tashkil qiladi.
Kalendar oyi davomida 50 ming AQSH dollari ekvivalentidan ortiq miqdorda xorijiy va milliy valyutadagi hisob raqamiga naqd pul mablag‘larini qo’yish (depozit qilish) bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi.
Kalendar oyi davomida 500 million so‘mdan ortiq miqdordagi pul mablag’larini milliy valyutadagi hisobraqamga naqdsiz ko’rinishda qabul qilish amaliyoti (P2P o‘tkazmalari, ish haqi va unga tenglashtirilgan to‘lovlar, sug‘urta to‘lovlari, byudjet to‘lovlari bundan mustasno) bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi.
Bank va karta hisobraqamlaridan xorijiy valyutada naqd pul mablag‘larini yechib olish kalendar oyi davomida 50 ming AQSh dollari ekvivalentidan yuqori bo‘lmagan miqdorda amalga oshiriladi.
Boshqa banklar tomonidan emissiya qilingan xalqaro Visa va MasterCard kartalaridan xorijiy valyutada naqd ko’rinishda pul mablag’larini yechib olish kalendar oyi davomida 10 ming AQSh dollari ekvivalentidan yuqori bo’lmagan miqdorda amalga oshiriladi.

-------------------------------------------------------------------------------------------------------------------------------------------
Norezident jismoniy shaxslar uchun:
Kalendar oyi davomida valyuta ayirboshlash shoxobchasida 100 AQSH dollari ekvivalentidan ortiq miqdorda milliy valyutani xorijiy valyutaga ayirboshlash bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi. Oy davomida ayirboshlashning maksimal miqdori 50 ming AQSh dollari yoki shu ekvivalentdagi boshqa xorijiy valyuta miqdorini tashkil qiladi.
Kalendar oyi davomida 70 million so‘m ekvivalentidan ortiq miqdorda xorijiy va milliy valyutadagi hisob raqamiga naqd pul mablag‘larini qo’yish (depozit qilish) bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi.
Kalendar oyi davomida 100 million so‘mdan ortiq miqdordagi pul mablag‘larini milliy valyutadagi hisobraqamga naqdsiz ko’rinishda qabul qilish (P2P o‘tkazmalari, ish haqi va unga tenglashtirilgan to‘lovlar, sug‘urta to‘lovlari, byudjet to‘lovlari bundan mustasno) bankka mablag’larning qonuniy manbalardan kelib tushganligini tasdiqlovchi hujjatlar taqdim etilganidan so’ng amalga oshiriladi.
Bank va karta hisobraqamlaridan xorijiy valyutada naqd pul mablag‘larini yechib olish kalendar oyi davomida 10 ming AQSh dollari ekvivalentidan yuqori bo’lmagan miqdorda amalga oshiriladi.
Boshqa banklar tomonidan emissiya qilingan xalqaro Visa va MasterCard kartalaridan xorijiy valyutada naqd ko’rinishda pul mablag’larini yechib olish kalendar oyi davomida 10 ming AQSh dollari ekvivalentidan yuqori bo’lmagan miqdorda amalga oshiriladi.

Xizmat ko’rsatish uchun yangi mijozlarni bankka qabul qilish (bank hisobraqami ochish, bank kartasini emissiya qilish) mijoz tomonidan O‘zbekiston Respublikasida vaqtinchalik yashash joyi bo‘yicha 30 kundan kam bo’lmagan muddatda ro‘yxatdan o‘tganligini tasdiqlovchi hujjatlar Bankka taqdim etilganidan so’ng amalga oshiriladi. Bunda agar kelishdan maqsad ishga joylashish, o'qish, davolanish kabilar bo’lsa istisno qilinadi.
-------------------------------------------------------------------------------------------------------------------------------------------

Eslatma: Tasdiqlovchi hujjatlar taqdim etilmagan taqdirda, Bank xizmat ko'rsatishni rad qilishga, shu jumladan bankka kelib tushgan pul mablag'larini jo'natuvchiga qaytarib yuborishga haqli.
  `,
  NOTIFICATION_MESSAGE_10022023: `
  E’lon: «KDB Bank O‘zbekiston» AJ Yunusobod filialining yopilishi to‘g‘risida

Resurslarni optimallashtirish maqsadida 2023 yil 31 yanvar kuni «KDB Bank O‘zbekiston» AJ (keyingi o‘rinlarda - Bank) Kuzatuv kengashi Bankning Yunusobod filialini [MFO 00831] (keying o‘rinlarda - Filial) tugatish to‘g‘risida qaror qabul qildi.

Amaldagi qonunchilikka ko‘ra, Bank Filialning barcha majburiyatlari, shu jumladan mijozlar oldidagi majburiyatlari yuzasidan ham javobgarlikni o‘z zimmasiga oladi.

Filialning yopilishi munosabati bilan, Filialning barcha mijozlaridan Bankning Bosh ofisida [MFO 00842] hisobraqamlar ochishlarini so‘raymiz. 

Qo‘shimcha ma’lumot yoki savollarga javob olish uchun, Filial mijozlaridan Mijozlarga xizmat ko‘rsatish bo‘limiga quyidagi telefon raqamlari orqali murojaat qilishlarini so‘raymiz: (+998) 781208743; 781208744; 781208709; 781206141; 781208780.

Hurmat bilan,

«KDB Bank O‘zbekiston» AJ
  `,

  NOTIFICATION_MESSAGE_21122023: `Hurmatli Mijozlar,
  Xodimlaringizni bank hujjatlari bilan ishlash uchun bo’lgan ishonchnomalarini 2024 yil uchun yangilab berishingizni so’raymiz (agar bankka topshirilgan ishonchnomalarning muddati 2023 yilda tugagan bo’lsa).
  `,
  //Corporate role  Payment Claim page Tab Sent Payment Claims

  PAYMENT_CLAIM_FOMRS_TITLE: "Formalar",

  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SENT_SUBTAB_TITLE: "",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SHOW_CHECKBOX_LABEL_TITLE: "show creators and approvals",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE: "Cancel payment claim",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TO_FIELD_LABEL_TITLE: "Gacha",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SEARCH_BUTTON_TITLE: "Qidiruv",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_TITLE: "ILTIMOS!",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_ONLY_ONE_PAYMENT_CLAIM_TITLE: "Choose Only One",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_PAYMENT_CLAIM_TITLE: "Choose Payment Claim",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DEPLOY_FILE_SUCCESSFULLY: "Excel File Yuklandi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_COLUMN_TITLE: "Turi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_TITLE: "To'lov So'rovnomasi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_REFUND_TITLE: "Qaytarilgan",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_COLUMN_TITLE: "To'lov So'rovnoma Raqami",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_DATE: "Yaratilgan Sana",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_NAME_OF_THE_PAYER_TITLE: "To'lovchining Nomi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE_TITLE: "Tranzaktsiya Sanasi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE: "Sana",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_ACCOUNT_TITLE: "To'lovchining Hisobi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_TAX_ID_TITLE: "To'lovchining Soliq Identifikatori",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_BANK_NAME_TITLE: "To'lovchining Bank Nomi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_MFO_TITLE: "MFO",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PURPOSE_CODE_TITLE: "Maqsad Kodi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_PURPOSE_TITLE: "To'lov Maqsadi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_CLAIM_COLUMN_TITLE: "To'lov So'rovnoma Raqami",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_STATUS_COLUMN_TITLE: "Holat",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Benefitsiar nomi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_NAME_COLUMN_TITLE: "Jo'natuvchi Bank Nomi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_CODE_COLUMN_TITLE: "Jo'natuvchi Bank Kodi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DATE_FIELD_LABEL_TITLE: "Dan",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Tomonidan Yaratilgan",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NUMBER_COLUMN_TITLE:
    "Beneficiary Account Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE:
    "Beneficiary Bank Code",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Sender Account Number",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Authorized by",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_FILTER_FIELD_LABEL_TITLE: "Filter by Status",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_NAME_COLUMN_TITLE: "Sender Name",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_COLUMN_TITLE: "Request for status",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_COLUMN_TITLE: "Yaratilgan sanasi",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREDITOR_ID_TITLE: "Kreditor IDsi",

  PAYMENT_CLAIM_NOTIFICATIONS_TAB: "Bildirishnoma",

  //Corporate role Payment Claim page For Form
  PAYMENT_CLAIM_FORM_PAYER_TITLE: "Mablag'larni To'lovchi",
  PAYMENT_CLAIM_FORM_DOC_NUMBER: "Inkasso Topshiriqnomasi Raqam",
  PAYMENT_CLAIM_FORM_DOC_DATE: "Xujjat Sanasi",
  PAYMENT_CLAIM_FORM_RECEIVER_ID: "Qabul Qiluvchi Identifikatori",
  PAYMENT_CLAIM_FORM_PAYER_NAME: "Mablag'larni To'lovchining Nomi",
  PAYMENT_CLAIM_FORM_PAYER_BANK_CODE: "Mablag'larni To'lovchining  Bank Kodi",
  PAYMENT_CLAIM_FORM_PAYER_ACCOUNT: "Mablag'larni To'lovchining Xisobvarag'i",
  PAYMENT_CLAIM_FORM_PAYER_TAX_ID: "Mablag'larni To'lovchining Identifikatsiya Raqami",
  PAYMENT_CLAIM_FORM_PAYER_BANK_NAME: "Mablag'larni To'lovchining Bank Nomi",
  PAYMENT_CLAIM_FORM_PURPOSE_CODE: "Maqsad Kodi",
  PAYMENT_CLAIM_FORM_PAYMENT_PURPOSE: "Xujjat Maqsadi",
  PAYMENT_CLAIM_FORM_PAYMENT_TYPE: "To'lov Turi",
  PAYMENT_CLAIM_FORM_PAYEE_ACCOUNT: "Mablag'larni Oluvchining Xisobvarag'i",
  PAYMENT_CLAIM_FORM_PAYEE_NAME: "Mablag'larni Oluvchining Nomi",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_NAME: "Mablag'larni Oluvchining Bank Nomi",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_CODE: "Mablag'larni Oluvchining Bank Kodi",
  PAYMENT_CLAIM_FORM_PAYEE_TAX_ID: "Mablag'larni Oluvchining Identifikatsiya Raqami",
  PAYMENT_CLAIM_FORM_FIRST_CARD_ID: "Kartoteka-1 IDsi",
  PAYMENT_CLAIM_FORM_FIRST_CARD_DATE: "Kartoteka-1 Sanasi",
  PAYMENT_CLAIM_FORM_FIRST_CARD_AMOUNT: "Kartoteka-1 Miqdori",
  PAYMENT_CLAIM_FORM_FIRST_CARD_REMAINING_AMOUNT: "Kartoteka-1 Balansi",
  PAYMENT_CLAIM_FORM_SECOND_CARD_ID: "Kartoteka-2 IDsi",
  PAYMENT_CLAIM_FORM_SECOND_CARD_DATE: "Kartoteka-2 Sanasi",
  PAYMENT_CLAIM_FORM_SECOND_CARD_AMOUNT: "Kartoteka-2 Miqdori",
  PAYMENT_CLAIM_FORM_SECOND_CARD_REMAINING_AMOUNT: "Kartoteka-2 Balansi",
  PAYMENT_CLAIM_FORM_SECOND_CARD_TITLE: "Kartoteka-2",
  PAYMENT_CLAIM_FORM_FIRST_CARD_TITLE: "Kartoteka-1",
  PAYMENT_CLAIM_FORM_PAY_AMOUNT: "To'lash Miqdori",
  PAYMENT_CLAIM_FORM_PAY_DATE: "To'lash Sanasi",
  PAYMENT_CLAIM_FORM_PAYMENT_CODE: "To'lov Kodi",
  PAYMENT_CLAIM_FORM_PAY_ID: "To'lash IDsi",
  PAYMENT_CLAIM_FORM_IGNORING_MASSAGE: "Rad Etilish Sababi",
  PAYMENT_CLAIM_FORM_IGNORING_AMOUNT: "Rad Etilgan Summa",
  PAYMENT_CLAIM_FORM_IGNORING_TITLE: "To'lovni Qaytarish",
  PAYMENT_CLAIM_FORM_PAY_TITLE: "To'langanlik To'g'risida Ma'lumot",
  PAYMENT_CLAIM_FORM_MASSAGE: "Xujjat Qabul Qilish Natijasi",
  PAYMENT_CLAIM_FORM_AMOUNT: "Talabnoma Summasi",
  PAYMENT_CLAIM_FORM_PAYMENT_CONFIRM: "Tasdiqlangan",
  PAYMENT_CLAIM_FORM_PAYMENT_IS_TRANSFER: "Xujjat Holati",
  PAYMENT_CLAIM_FORM_PAYMENT_BANK_MAIL_ID: "Bank Mail IDsi",
  PAYMENT_CLAIM_FORM_SENDER_ID: "Mablag' To'lovchining IDsi",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_SENT: "Yuborildi",
  PAYMENT_CLAIM_FORM_PAYMENT_ENTER_REASON_API_TITLE: "Enter Reason",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_WAS_NOT_SENT: "Yuborilmadi",
  PAYMENT_CLAIM_FORM_PAYMENT_REFUNDED_TITLE: "Qaytarilgan",
  PAYMENT_CLAIM_FORM_REASON: "Sabab",
  // PAYMENT_CLAIM_RECALL: "Qaytarilgan",
  PAYMENT_CLAIM_FORM_PAYEE_TITLE: "Mablag'larni Oluvchi",
  PAYMENT_CLAIM_FORM_AMOUNT_TITLE: "Summa",
  PAYMENT_CLAIM_FORM_CURRENT_BALANCE: "Joriy Balans",
  PAYMENT_CLAIM_FORM_AMOUNT_IN_WORD_TITLE: "Summa So'zlarda",

  // Collection Orders

  COLLECTION_ORDERS_TAB_PANE_TITLE: "SOLIQdan Inkasso buyurtmasi",
  COLLECTION_ORDERS_TAB_TITLE: "SOLIQdan Inkasso buyurtmasi",

  COLLECTION_ORDERS_FROM_MIB_TAB_PANE_TITLE: "MIBdan Inkasso buyurtmasi",
  COLLECTION_ORDERS_FROM_MIB_TAB_TITLE: "MIBdan Inkasso buyurtmasi",

  COLLECTION_ORDERS_TABLE_TAX_ID_COLUMN_TITLE:
    "AT SQda inkassa topshirig’ining yagona tizim raqami",
  COLLECTION_ORDERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Hujjat raqami",
  COLLECTION_ORDERS_TABLE_DOCUMENT_DATE_COLUMN_TITLE: "Hujjat sanasi",
  COLLECTION_ORDERS_TABLE_BRANCH_A_COLUMN_TITLE: "Qarzdor bank filialining kodi",
  COLLECTION_ORDERS_TABLE_BRANCH_B_COLUMN_TITLE: "Qabul qiluvchi bank filialining kodi",
  COLLECTION_ORDERS_TABLE_ACCOUNT_A_COLUMN_TITLE: "Qarzdorning bank hisobvarag’i",
  COLLECTION_ORDERS_TABLE_ACCOUNT_B_COLUMN_TITLE: "Qabul qiluvchining bank hisobvarag’i",
  COLLECTION_ORDERS_TABLE_INN_A_COLUMN_TITLE: "Qarzdorning INN raqami",
  COLLECTION_ORDERS_TABLE_INN_B_COLUMN_TITLE: "Qabul qiluvchining INN raqami",
  COLLECTION_ORDERS_TABLE_PINFL_A_COLUMN_TITLE: "Qarzdorning PINFL raqami",
  COLLECTION_ORDERS_TABLE_SUMMA_COLUMN_TITLE: "Hujjat miqdori",
  COLLECTION_ORDERS_TABLE_PAYMENT_TYPE_COLUMN_TITLE: "Qarzdorlik turi",
  COLLECTION_ORDERS_TABLE_UNPAID_COLUMN_TITLE:
    "O’z vaqtida to’lanmagan to’lov hujjati uchun to’lov maqsadi kodi",
  COLLECTION_ORDERS_TABLE_NONPAYMENT_COLUMN_TITLE: "To’lov kodi",
  COLLECTION_ORDERS_TABLE_BOARD_COLUMN_TITLE: "Qabul qiluvchining boshqaruv kodi",
  COLLECTION_ORDERS_TABLE_OKED_COLUMN_TITLE: "Qabul qiluvchining iqtisodiy faoliyat turi kodi",
  COLLECTION_ORDERS_TABLE_NAME_A_COLUMN_TITLE: "Yuboruvchining nomi",
  COLLECTION_ORDERS_TABLE_NAME_B_COLUMN_TITLE: "Qabul qiluvchining nomi",
  COLLECTION_ORDERS_TABLE_PURPOSE_CODE_COLUMN_TITLE: "To’lov maqsadi kodi",
  COLLECTION_ORDERS_TABLE_BUDGET_INCOME_COLUMN_TITLE: "Daromadlar uchun g’aznachilik hisobvarag’i",
  COLLECTION_ORDERS_TABLE_BUDGET_ACCOUNT_COLUMN_TITLE: "Byudjet oluvchining shaxsiy hisobvarag’i",
  COLLECTION_ORDERS_TABLE_BUDGET_INN_COLUMN_TITLE: "Byudjet oluvchining INN raqami",
  COLLECTION_ORDERS_TABLE_PURPOSE_COLUMN_TITLE: "To’lov maqsadi",


  // Transfers Import Excel files

  TRANSFERS_EXCEL_FILE_TABLE_DATE_COLUMN_TITLE: "Sana",
  TRANSFERS_EXCEL_FILE_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Hujjat raqami",
  TRANSFERS_EXCEL_FILE_TABLE_SENDER_ACCOUNT_COLUMN_TITLE: "To`lovchining hisobi",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Qabul qiluvchi",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Qabul qiluvchining hisob raqami",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_TIN_COLUMN_TITLE: "INN raqami",
  TRANSFERS_EXCEL_FILE_TABLE_DETAILS_OF_PAYMENT_COLUMN_TITLE: "To`lov uchun izoh",
  TRANSFERS_EXCEL_FILE_TABLE_AMOUNT_COLUMN_TITLE: "Miqdor",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE: "MFO",
  TRANSFERS_EXCEL_FILE_TABLE_STATUS_COLUMN_TITLE: "Holati",
  TRANSFERS_EXCEL_FILE_TABLE_IMPORTED_COLUMN_TITLE: "Import qilindi",
  TRANSFERS_EXCEL_FILE_TABLE_SUCCESS_COLUMN_TITLE: "Muvaffaqiyatli",
 
};
