import "./assets/table-header.scss";

import cx from "classnames";
import React, { ReactNode } from "react";
import { HeaderGroup } from "react-table";

interface Props<T extends object> {
  readonly className?: string;
  readonly groupClassName?: string;
  readonly headerClassName?: string;
  readonly headerComponent?: ReactNode;
  readonly headerGroups: HeaderGroup<T>[];
}

export function TableHeader<T extends object>({
  className,
  headerGroups = [],
  groupClassName,
  headerClassName,
  headerComponent,
}: Props<T>) {
  return (
    <thead className={cx(className, "table-header")}>
      {headerComponent}

      {Boolean(!headerComponent) &&
        headerGroups.map((headerGroup) => {
          const groupProps = headerGroup.getHeaderGroupProps();

          return (
            <tr
              {...groupProps}
              key={groupProps.key}
              className={groupClassName}
              style={{ ...groupProps.style, overflowY: "scroll" }}
            >
              {headerGroup.headers.map((column) => {
                const headerProps = column.getHeaderProps();
                // @ts-ignore
                const resizeProps = column.canResize ? column.getResizerProps() : {};

                return (
                  <th
                    {...headerProps}
                    key={headerProps.key}
                    className={cx(
                      "text-center font-weight-semi-bold column-header",
                      headerClassName,
                    )}
                  >
                    {column.render("Header")}
                    {/*
                    // @ts-ignore */}
                    {column.canResize && <div {...resizeProps} className="resizer" />}
                  </th>
                );
              })}
            </tr>
          );
        })}
    </thead>
  );
}
