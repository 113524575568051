import { formatDateToFilter } from "../utils/DateUtils";
import { GetPayrollListQuery } from "../api/salary/SalaryApi";
import { PayrollFilterFormProps, PayrollStatus } from "../api/salary/SalaryDTO";
import ExcelJS from "exceljs";
import { fillBorders } from "../utils/ExcelUtils";
import { addZeros, floatFormat } from "../utils/FloatUtils";
import { TransferType } from "../api/transfer/TransferDTO";

export function formatPayrollQuery(filter: PayrollFilterFormProps): GetPayrollListQuery {
  const status = filter.status?.value !== PayrollStatus.None ? filter.status?.value : undefined;

  return {
    status,
    transferType: filter.transferType,
    toDate: formatDateToFilter(filter.toDate),
    fromDate: formatDateToFilter(filter.fromDate),
    pageSize: filter?.pageSize,
    pageNumber: filter?.pageNumber,
  };
}

export function createRegisterExcelFromTable(data: any, transferType): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createRegisterExcelSheet(workbook, data, transferType);

  return workbook;
}

function createRegisterExcelSheet(workbook: ExcelJS.Workbook, data: any, transferType) {
  const name = `${data.id}. ${data.documentNumber} (${data.documentDate}_`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 40 },
    { key: "column3", width: 60 },
    { key: "column4", width: 40 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A1`);
  a1Cell.value = "№";
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { border: fillBorders(), font: { bold: true } };

  const b1Cell = sheet.getCell(`B1`);
  b1Cell.value =
    transferType === TransferType.Salary ? "Карточный счет работника" : "Карточный счет компании";
  b1Cell.alignment = { vertical: "middle", horizontal: "center" };
  b1Cell.style = { border: fillBorders(), font: { bold: true } };

  const c1Cell = sheet.getCell(`C1`);
  c1Cell.value =
    transferType === TransferType.Salary ? "Фамилия,Имя,Отчество" : "Наименование компании";
  c1Cell.alignment = { vertical: "middle", horizontal: "center" };
  c1Cell.style = { border: fillBorders(), font: { bold: true } };

  const d1Cell = sheet.getCell(`D1`);
  d1Cell.value = "Сумма";
  d1Cell.alignment = { vertical: "middle", horizontal: "center" };
  d1Cell.style = { border: fillBorders(), font: { bold: true } };

  let tableCells = 2;
  for (let i = 0; i < data.length; i++) {
    tableCells = 2 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = data[i].id;
    aCells_1.alignment = { vertical: "middle" };

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = data[i].accountNumber;
    bCells_1.alignment = { vertical: "middle" };

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value = data[i].fullName;
    cCells_1.alignment = { vertical: "middle" };

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value = data[i].amount && floatFormat(addZeros(data[i].amount));
    dCells_1.alignment = { vertical: "middle" };
  }
}
