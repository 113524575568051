import React from "react";
import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function ListIcon({ size = 15, color = ColorPalette.Blue, ...svgProps }: Props) {
  return (
    <svg
      width={size}
      height={size}
      {...svgProps}
      id="interface_11_"
      viewBox="0 0 18.06 18.06"
      data-name="interface (11)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group_4345" data-name="Group 4345">
        <path
          d="M16.125,0H1.935A1.935,1.935,0,0,0,0,1.935v14.19A1.935,1.935,0,0,0,1.935,18.06h14.19a1.935,1.935,0,0,0,1.935-1.935V1.935A1.935,1.935,0,0,0,16.125,0ZM5.8,14.19H3.87a.645.645,0,1,1,0-1.29H5.8a.645.645,0,1,1,0,1.29Zm0-4.515H3.87a.645.645,0,1,1,0-1.29H5.8a.645.645,0,0,1,0,1.29Zm0-4.515H3.87a.645.645,0,1,1,0-1.29H5.8a.645.645,0,1,1,0,1.29Zm8.385,9.03h-5.8a.645.645,0,1,1,0-1.29h5.8a.645.645,0,1,1,0,1.29Zm0-4.515h-5.8a.645.645,0,0,1,0-1.29h5.8a.645.645,0,0,1,0,1.29Zm0-4.515h-5.8a.645.645,0,0,1,0-1.29h5.8a.645.645,0,0,1,0,1.29Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
