import cx from "classnames";
import React, { useCallback, useMemo, useState } from "react";
// import { saveAs } from "file-saver";
import { update } from "immupdate";
import { toFinite } from "lodash";
import { NotificationManager } from "react-notifications";

import { TabPage } from "../tabs/TabPage";
import { FlatButton } from "../ui/FlatButton";
import { ShapeIcon } from "../icons/ShapeIcon";
import { FilterIcon } from "../icons/FilterIcon";
import { FileControls } from "../ui/FileControls";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { CorporateStatementData } from "./CorporateStatementData";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useAccountContext } from "../../api/account/AccountContext";
import { CorporateStatementFilterForm } from "./CorporateStatementFilterForm";
import { formatDateToBody, formatDateToFilter, formatDateToUrl } from "../../utils/DateUtils";
import { useResource } from "../../hooks/useResource";
import { PaginatorForStatement } from "../ui/PaginatorForStatement";
// import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { removeGaps } from "../../utils/FloatUtils";
import { downloadVer2, downloadVer3 } from "../../utils/TextUtils";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import {
  createStatement1ExcelFromTable,
  createStatement2ExcelFromTable,
  createStatement3ExcelFromTable,
  createStatement4ExcelFromTable,
  createStatement5ExcelFromTable,
  createStatementExcelAngleseyFromTable,
  createStatementExcelFromTable,
  sortDataFromDate,
} from "../../helpers/AccountHelpers";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  userFullNameSelector,
  userPhoneSelector,
  userCustomerSequenceSelector,
} from "../../reducers/userReducer";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { useI18n } from "../../i18n/I18nContext";
import { flagToHugeTurnover } from "../../reducers/authReducer";
import NotificationLoader from "../ui/NotificationLoader";

export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderDirection,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === OrderDirection.Desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface Props {
  readonly filter: AccountsFilter;
  readonly accountNumber: any;
}

function CorporateStatementTabLoc({ filter, accountNumber }: Props) {
  const { translate } = useI18n();

  const fullName = useShallowEqualSelector(userFullNameSelector);
  const flagToHugeType = useShallowEqualSelector(flagToHugeTurnover);
  const customerSequence = useShallowEqualSelector(userCustomerSequenceSelector);

  const [showFilter, setShowFilter] = useState(true);
  const [accounts, setAccounts] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [listAccounts, setListAccounts] = useState<any>([]);
  const [filterValues, setFilterValues] = useState({
    accountNumber: {
      label: accountNumber.accountNumber,
      value: accountNumber.accountNumber,
      accountName: accountNumber.accountName,
      lastTransactionDate: accountNumber.lastTransactionDate,
      currency: accountNumber.currency,
      accountType: accountNumber.accountType,
    },
    fromDate: filter.getInquiryFilterForm().fromDate,
    toDate: filter.getInquiryFilterForm().toDate,
    searchAmount: "",
    corrAcc: "",
    status: filter.getAccountStatementsFilter().status,
  });

  const { AccountApi } = useAccountContext();
  const { PdfReportApi } = usePdfReportContext();

  const locationHelpers = useLocationHelpers();

  useResource(
    () =>
      AccountApi.getAccountsListForHistory().then((r) => {
        setAccounts([
          r.map((x) => ({
            label: x.accounts,
            value: x.accounts,
            accountName: fullName,
          })),
        ]);
        if (accountNumber.accountNumber === "") {
          setFilterValues((prev) =>
            update(prev, {
              accountNumber: {
                label: r[0]?.accounts,
                value: r[0]?.accounts,
                accountName: fullName,
                lastTransactionDate: r[0]?.lastTransactionDate,
                currency: r[0]?.currency,
                accountType: r[0]?.accountType,
              },
            }),
          );
          setListAccounts(r);
        }
      }),
    [],
  );

  const listOfStatement = useResource(
    () =>
      AccountApi.getAccountsList(filter.getAccountListFilter()).then((r) => {
        return AccountApi.getAccountStatementCount({
          customerSequence,
          ...filter.getAccountStatementsFilter(),
          accountNumber:
            filterValues.accountNumber.label !== ""
              ? filterValues.accountNumber.label
              : r[0].accountNumber,
          flagToHugeTurnover: flagToHugeType,
        })
          .then((r) => {
            if (r?.list.length < 1) {
              NotificationManager.info(translate("STATEMENT_NO_TRANSACTIONS"), "", 5000);
            } else {
              locationHelpers.pushQuery({
                pageCount: r.list[0]?.totalPage,
                totalCount: r.list[0]?.totalRow,
              });
            }
            return r;
          })
          .catch((error) => {
            if (error.data[0].errorCode === 3018) {
              error.data[0].userMsg = translate("ACCOUNTS_STATEMENT_FILTER_ERROR_TITLE");
            }
            showError(error);
          });
      }),
    [
      filter.getAccountStatementsFilter().toDate,
      filter.getAccountStatementsFilter().fromDate,
      filter.getAccountStatementsFilter().status,
      filter.getAccountStatementsFilter().amount,
      filter.getAccountStatementsFilter().correspondentAccount,
      filter.getAccountStatementsFilter().accountNumber,
      filter.getAccountStatementsFilter().pageNumber,
      filter.getAccountStatementsFilter().pageSize,
    ],
  );

  const data = useMemo(() => listOfStatement.data || ([] as any), [listOfStatement.data]);
  const phone = useShallowEqualSelector(userPhoneSelector);

  const saveToTxt = useCallback(() => {
    // @ts-ignore
    if (data?.list.length > 0) {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
      AccountApi.getAccountStatementCount({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        flagToHugeTurnover: flagToHugeType,
      }).then((res) => {
        let statementList = [{}];
        statementList.shift();
        // @ts-ignore
        for (let i = 0; i < res.list[0].totalPage; i++) {
          AccountApi.getAccountStatementCount({
            customerSequence,
            ...filter.getAccountStatementsFilter(),
            accountNumber: filterValues.accountNumber.label,
            pageNumber: i + 1,
            pageSize: 100,
            flagToHugeTurnover: flagToHugeType,
          })
            .then((r) => {
              statementList = [...statementList, ...r.list];
              if (
                statementList.length ===
                toFinite(
                  // @ts-ignore
                  res.list[0].totalRow,
                )
              ) {
                downloadVer2(
                  {
                    ...res,
                    list: statementList.sort(sortDataFromDate),
                    clientInfo: fullName,
                    fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                    toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                    phoneNumber: phone,
                    lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                    accountNumber: filterValues.accountNumber.label,
                    currency: filterValues.accountNumber.currency,
                    accountName: filterValues.accountNumber.accountName,
                    accountType: filterValues.accountNumber.accountType,
                    // @ts-ignore
                    total: data.list[0].totalRow,
                  },
                  `${filterValues.accountNumber.label}_${formatDateToFilter(
                    filter.getInquiryFilterForm().fromDate,
                  )}_${formatDateToFilter(filter.getInquiryFilterForm().toDate)}`,
                );
              }
            })
            .catch(() => {
              AccountApi.getAccountStatementCount({
                customerSequence,
                ...filter.getAccountStatementsFilter(),
                accountNumber: filterValues.accountNumber.label,
                pageNumber: i + 1,
                pageSize: 100,
                flagToHugeTurnover: flagToHugeType,
              })
                .then((r) => {
                  statementList = [...statementList, ...r.list];
                  if (
                    statementList.length ===
                    toFinite(
                      // @ts-ignore
                      res.list[0].totalRow,
                    )
                  ) {
                    downloadVer2(
                      {
                        ...res,
                        list: statementList,
                        clientInfo: fullName,
                        fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                        toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                        phoneNumber: phone,
                        lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                        accountNumber: filterValues.accountNumber.label,
                        currency: filterValues.accountNumber.currency,
                        accountName: filterValues.accountNumber.accountName,
                        accountType: filterValues.accountNumber.accountType,
                        // @ts-ignore
                        total: data.list[0].totalRow,
                      },
                      `${filterValues.accountNumber.label}_${formatDateToFilter(
                        filter.getInquiryFilterForm().fromDate,
                      )}_${formatDateToFilter(filter.getInquiryFilterForm().toDate)}`,
                    );
                  }
                })
                .catch(() =>
                  NotificationManager.error(
                    translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                    translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                  ),
                );
            });
        }
      });
    } else {
      downloadVer2(
        {
          list: [
            {
              bankTellerId: "",
              documentNumber: "",
              transactionDate: formatDateToBody(new Date()),
              receiverAccount: "00000000000000000000",
              receiverTaxNumber: "000000000",
              receiverName: "",
              paymentDetail: "",
              receiverMFO: "00000",
              debitAmount: "0",
              creditAmount: "0",
              balance: "0",
            },
          ],
          endBalance: data.endBalance,
          beginBalance: data.beginBalance,
          creditAmount: "0",
          debitAmount: "0",
          clientInfo: fullName,
          fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
          toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
          phoneNumber: phone,
          lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
          accountNumber: filterValues.accountNumber.label,
          currency: filterValues.accountNumber.currency,
          accountName: filterValues.accountNumber.accountName,
          accountType: filterValues.accountNumber.accountType,
          total: 0,
        },
        `${filterValues.accountNumber.label}_${formatDateToFilter(
          filter.getInquiryFilterForm().fromDate,
        )}_${formatDateToFilter(filter.getInquiryFilterForm().toDate)}`,
      );
    }
  }, [
    AccountApi,
    translate,
    filterValues,
    phone,
    fullName,
    filter,
    data,
    customerSequence,
    flagToHugeType,
  ]);

  const saveToTxt2 = useCallback(() => {
    // @ts-ignore
    if (data.list.length > 0) {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
      AccountApi.getAccountStatementCount({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        flagToHugeTurnover: flagToHugeType,
      }).then((res) => {
        let statementList = [{}];
        statementList.shift();
        // @ts-ignore
        for (let i = 0; i < res.list[0].totalPage; i++) {
          AccountApi.getAccountStatementCount({
            customerSequence,
            ...filter.getAccountStatementsFilter(),
            accountNumber: filterValues.accountNumber.label,
            pageNumber: i + 1,
            pageSize: 100,
            flagToHugeTurnover: flagToHugeType,
          })
            .then((r) => {
              statementList = [...statementList, ...r.list];
              if (
                statementList.length ===
                toFinite(
                  // @ts-ignore
                  res.list[0].totalRow,
                )
              ) {
                downloadVer3(
                  {
                    ...res,
                    list: statementList.sort(sortDataFromDate),
                    clientInfo: fullName,
                    fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                    toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                    phoneNumber: phone,
                    lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                    accountNumber: filterValues.accountNumber.label,
                    currency: filterValues.accountNumber.currency,
                    accountName: filterValues.accountNumber.accountName,
                    accountType: filterValues.accountNumber.accountType,
                    // @ts-ignore
                    total: data.list[0].totalRow,
                  },
                  `${filterValues.accountNumber.label}_${formatDateToFilter(
                    filter.getInquiryFilterForm().fromDate,
                  )}_${formatDateToFilter(filter.getInquiryFilterForm().toDate)}`,
                );
              }
            })
            .catch(() => {
              AccountApi.getAccountStatementCount({
                customerSequence,
                ...filter.getAccountStatementsFilter(),
                accountNumber: filterValues.accountNumber.label,
                pageNumber: i + 1,
                pageSize: 100,
                flagToHugeTurnover: flagToHugeType,
              })
                .then((r) => {
                  statementList = [...statementList, ...r.list];
                  if (
                    statementList.length ===
                    toFinite(
                      // @ts-ignore
                      res.list[0].totalRow,
                    )
                  ) {
                    downloadVer3(
                      {
                        ...res,
                        list: statementList,
                        clientInfo: fullName,
                        fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                        toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                        phoneNumber: phone,
                        lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                        accountNumber: filterValues.accountNumber.label,
                        currency: filterValues.accountNumber.currency,
                        accountName: filterValues.accountNumber.accountName,
                        accountType: filterValues.accountNumber.accountType,
                        // @ts-ignore
                        total: data.list[0].totalRow,
                      },
                      `${filterValues.accountNumber.label}_${formatDateToFilter(
                        filter.getInquiryFilterForm().fromDate,
                      )}_${formatDateToFilter(filter.getInquiryFilterForm().toDate)}`,
                    );
                  }
                })
                .catch(() =>
                  NotificationManager.error(
                    translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                    translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                  ),
                );
            });
        }
      });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    translate,
    filterValues,
    phone,
    fullName,
    filter,
    data,
    customerSequence,
    flagToHugeType,
  ]);

  const saveToAnsi = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);
      AccountApi.getAccountTxt11({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        isAnsi: true,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const saveMT940 = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);

      AccountApi.getAccountMT940({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [AccountApi, data, filter, filterValues.accountNumber.label, translate, customerSequence]);

  const saveAgromir = useCallback(
    (typeStatus) => {
      // @ts-ignore
      if (data && data.list && data.list.length > 0) {
        setLoadingModal(true);

        AccountApi.getAccountAgromir({
          customerSequence,
          ...filter.getAccountStatementsFilter(),
          accountNumber: filterValues.accountNumber.label,
          pageNumber: 1,
          pageSize: 100,
          status: typeStatus,
          flagToHugeTurnover: flagToHugeType,
          showModalText: () =>
            NotificationManager.error(
              translate("STATEMENT_NO_DATA"),
              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
            ),
        })
          .then(() => setLoadingModal(false))
          .catch((errors) => {
            setLoadingModal(false);
            errors.data[0].userMsg === "Date range exceeds 1 month" &&
              NotificationManager.error(errors.data[0].userMsg);
          });
      } else {
        NotificationManager.error(
          translate("STATEMENT_NO_DATA"),
          translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
        );
      }
    },
    [
      AccountApi,
      data,
      filter,
      filterValues.accountNumber.label,
      translate,
      customerSequence,
      flagToHugeType,
    ],
  );

  const saveToExcelAngleseyOztransgaz = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);

      AccountApi.getAccountAngleseyOztransgaz({
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    translate,
  ]);

  const saveToExcel1C = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);
      // NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);

      AccountApi.getAccountExcel1C({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        // pageNumber: 1,
        pageNumber: Math.ceil(filter.getTotalCount() / 100),
        pageSize: 100,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
          setLoadingModal(false);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const onSaveToExcelNewClick = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);
      // NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);

      AccountApi.getAccountExcelNew({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        // pageNumber: 1,
        pageNumber: Math.ceil(filter.getTotalCount() / 100),
        pageSize: 100,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const onSaveToPdfClick = useCallback(() => {
    // @ts-ignore
    if (data && (data.beginBalance || data.endBalance)) {
      setLoadingModal(true);
      // NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);

      PdfReportApi.createStatementPdfReport(
        {
          customerSequence,
          ...filter.getAccountStatementsFilter(),
          accountNumber: filterValues.accountNumber.label,
          // pageNumber: 1,
          pageNumber: Math.ceil(filter.getTotalCount() / 100),
          pageSize: 100,
          status: filterValues.status !== "" ? filterValues.status : "A",
          amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
          correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
          flagToHugeTurnover: flagToHugeType,
          showModalText: () =>
            NotificationManager.error(
              translate("STATEMENT_NO_DATA"),
              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
            ),
        },
        "Statement Document",
      )
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    PdfReportApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const saveToText1C = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);
      AccountApi.getAccountTxt1C({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const saveToText11 = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(false);
      AccountApi.getAccountTxt11({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        isAnsi: false,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  const saveToText3 = useCallback(() => {
    // @ts-ignore
    if (data && data.list && data.list.length > 0) {
      setLoadingModal(true);

      AccountApi.getAccountTxt3({
        customerSequence,
        ...filter.getAccountStatementsFilter(),
        accountNumber: filterValues.accountNumber.label,
        pageNumber: 1,
        pageSize: 100,
        status: filterValues.status !== "" ? filterValues.status : "A",
        amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
        correspondentAccount: filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
        flagToHugeTurnover: flagToHugeType,
        showModalText: () =>
          NotificationManager.error(
            translate("STATEMENT_NO_DATA"),
            translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
          ),
      })
        .then(() => setLoadingModal(false))
        .catch((errors) => {
          setLoadingModal(false);
          errors.data[0].userMsg === "Date range exceeds 1 month" &&
            NotificationManager.error(errors.data[0].userMsg);
        });
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [
    AccountApi,
    data,
    filter,
    filterValues.accountNumber.label,
    translate,
    filterValues.status,
    filterValues.searchAmount,
    filterValues.corrAcc,
    customerSequence,
    flagToHugeType,
  ]);

  return (
    <TabPage
      className="px-4"
      contentClassName="bg-transparent border-0"
      headerMinHeight={!showFilter ? 80 : 20}
      footerComponent={
        <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
          <PaginatorForStatement filter={filter} />
        </div>
      }
      headerComponent={
        <>
          {loadingModal && <NotificationLoader />}
          {!showFilter && (
            <div
              className={cx("d-flex align-items-center flex-grow-1 flex-shrink-1", {
                "justify-content-end": showFilter,
                "justify-content-between": !showFilter,
              })}
            >
              <>
                <FlatButton
                  leftIcon={<FilterIcon />}
                  rightIcon={<ShapeIcon />}
                  onClick={() => setShowFilter((x) => !x)}
                  title="ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE"
                  className="border border-gray-light br-1 bg-white px-2 py-1"
                />
              </>
            </div>
          )}
        </>
      }
    >
      {showFilter && (
        <>
          <CorporateStatementFilterForm
            onSubmit={(value) => {
              locationHelpers.replaceQuery({
                fromDate: formatDateToUrl(value.fromDate),
                toDate: formatDateToUrl(value.toDate),
                amount: removeGaps(value.searchAmount),
                status: value.status,
                correspondentAccount: value.corrAcc,
                accountNumber: value.accountNumber.label,
              });
              const datas = listAccounts.filter(
                (item) => item.accounts === value.accountNumber.value,
              )[0];
              setFilterValues((prev) =>
                update(prev, {
                  accountNumber: { ...value.accountNumber, ...datas },
                  toDate: value.toDate,
                  fromDate: value.fromDate,
                  searchAmount: removeGaps(value.searchAmount),
                  status: value.status,
                  corrAcc: value.corrAcc,
                }),
              );
            }}
            onChangeAccountNumber={(value) =>
              setFilterValues((prev) =>
                update(prev, {
                  accountNumber: value,
                }),
              )
            }
            initialValues={filterValues}
            accounts={accounts[0]}
            onChangeFromDate={(value) => {
              setFilterValues((prev) =>
                update(prev, {
                  fromDate: value,
                  toDate: value,
                }),
              );
            }}
          />
          <div
            className={cx("d-flex align-items-center flex-grow-1 flex-shrink-1 mb-4", {
              "justify-content-end": !showFilter,
              "justify-content-between": showFilter,
            })}
          >
            <>
              <FileControls
                onSaveToMT940Click={saveMT940}
                onSaveAgromir={saveAgromir}
                onSaveToExcelAngleseyOztransgaz={saveToExcelAngleseyOztransgaz}
                onSaveToTxtClick={saveToTxt}
                onSaveToExcel1CClick={saveToExcel1C}
                onSaveToText1CClick={saveToText1C}
                onSaveToExcelNewClick={onSaveToExcelNewClick}
                onSaveToText3Click={saveToText3}
                onSaveToText11Click={saveToText11}
                onSaveToPdfClick={onSaveToPdfClick}
                // onSaveToPdfClick={() => {
                //   // @ts-ignore
                //   if (data.list.length > 0) {
                //     NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
                //     AccountApi.getAccountStatementCount({
                //       customerSequence,
                //       ...filter.getAccountStatementsFilter(),
                //       accountNumber: filterValues.accountNumber.label,
                //       pageNumber: 1,
                //       pageSize: 100,
                //       flagToHugeTurnover: flagToHugeType,
                //     }).then((res) => {
                //       // @ts-ignore
                //       res.closeCredit = floatFormat(addZeros(data.creditAmount));
                //       // @ts-ignore
                //       res.closeDebit = floatFormat(addZeros(data.debitAmount));
                //       // @ts-ignore
                //       res.closingBalance = floatFormat(addZeros(data.endBalance));
                //       // @ts-ignore
                //       res.openingBalance = floatFormat(addZeros(data.beginBalance));
                //       let statementList = [{}];
                //       statementList.shift();
                //       // @ts-ignore
                //       for (let i = 0; i < res.list[0].totalPage; i++) {
                //         AccountApi.getAccountStatementCount({
                //           customerSequence,
                //           ...filter.getAccountStatementsFilter(),
                //           accountNumber: filterValues.accountNumber.label,
                //           pageNumber: i + 1,
                //           pageSize: 100,
                //           flagToHugeTurnover: flagToHugeType,
                //         })
                //           .then((r) => {
                //             r.list.map((x) => {
                //               // @ts-ignore
                //               x.receivedDetails = `${x.receiverTaxNumber} ${x.receiverName} ${x.paymentDetail}`;
                //               // @ts-ignore
                //               x.validDate = x.transactionDate;
                //               // @ts-ignore
                //               x.debitAmount = floatFormat(addZeros(x.debitAmount));
                //               // @ts-ignore
                //               x.creditAmount = floatFormat(addZeros(x.creditAmount));
                //               // @ts-ignore
                //               x.balance = floatFormat(addZeros(x.balance));
                //             });
                //             statementList = [...statementList, ...r.list];
                //             if (
                //               statementList.length ===
                //               toFinite(
                //                 // @ts-ignore
                //                 res.list[0].totalRow,
                //               )
                //             ) {
                //               PdfReportApi.createStatementDocument("Statement Document", {
                //                 ...res,
                //                 list: statementList.sort(sortDataFromDate),
                //                 clientInfo: fullName,
                //                 fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                //                 toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                //                 openingDate: formatDateToBody(
                //                   filter.getInquiryFilterForm().fromDate,
                //                 ),
                //                 closingDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                //                 phoneNumber: phone,
                //                 lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                //                 accountNumber: filterValues.accountNumber.label,
                //                 currency: filterValues.accountNumber.currency,
                //                 accountName: filterValues.accountNumber.accountName,
                //                 accountType: filterValues.accountNumber.accountType,
                //                 // @ts-ignore
                //                 total: data.list[0].totalRow,
                //               });
                //             }
                //           })
                //           .catch(() => {
                //             AccountApi.getAccountStatementCount({
                //               customerSequence,
                //               ...filter.getAccountStatementsFilter(),
                //               accountNumber: filterValues.accountNumber.label,
                //               pageNumber: i + 1,
                //               pageSize: 100,
                //               flagToHugeTurnover: flagToHugeType,
                //             })
                //               .then((r) => {
                //                 statementList = [...statementList, ...r.list];
                //                 if (
                //                   statementList.length ===
                //                   toFinite(
                //                     // @ts-ignore
                //                     res.list[0].totalRow,
                //                   )
                //                 ) {
                //                   PdfReportApi.createStatementDocument("Statement Document", {
                //                     ...res,
                //                     list: statementList,
                //                     clientInfo: fullName,
                //                     fromDate: formatDateToBody(
                //                       filter.getInquiryFilterForm().fromDate,
                //                     ),
                //                     toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                //                     phoneNumber: phone,
                //                     lastTransactionDate:
                //                       filterValues.accountNumber.lastTransactionDate,
                //                     accountNumber: filterValues.accountNumber.label,
                //                     currency: filterValues.accountNumber.currency,
                //                     accountName: filterValues.accountNumber.accountName,
                //                     accountType: filterValues.accountNumber.accountType,
                //                     // @ts-ignore
                //                     total: data.list[0].totalRow,
                //                   });
                //                 }
                //               })
                //               .catch(() =>
                //                 NotificationManager.error(
                //                   translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                //                   translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                //                 ),
                //               );
                //           });
                //       }
                //     });
                //   } else {
                //     PdfReportApi.createStatementDocument("Statement Document", {
                //       list: [
                //         {
                //           bankTellerId: "",
                //           documentNumber: "",
                //           transactionDate: "",
                //           receiverAccount: "",
                //           receiverTaxNumber: "",
                //           receiverName: "",
                //           paymentDetail: "",
                //           receiverMFO: "",
                //           debitAmount: 0,
                //           creditAmount: 0,
                //           balance: 0,
                //         },
                //       ],
                //       closingBalance: data.endBalance,
                //       openingBalance: data.beginBalance,
                //       creditAmount: 0,
                //       debitAmount: 0,
                //       clientInfo: fullName,
                //       fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                //       toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                //       phoneNumber: phone,
                //       lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                //       accountNumber: filterValues.accountNumber.label,
                //       currency: filterValues.accountNumber.currency,
                //       accountName: filterValues.accountNumber.accountName,
                //       accountType: filterValues.accountNumber.accountType,
                //       total: 0,
                //     });
                //   }
                // }}
                onSaveToTxt2Click={saveToTxt2}
                onSaveToExcelClick={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              const workbook = createStatementExcelFromTable({
                                ...res,
                                list: statementList,
                                clientInfo: fullName,
                                fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                                toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                phoneNumber: phone,
                                lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                                accountNumber: filterValues.accountNumber.label,
                                currency: filterValues.accountNumber.currency,
                                accountName: filterValues.accountNumber.accountName,
                                accountType: filterValues.accountNumber.accountType,
                                // @ts-ignore
                                total: res.list[0].totalRow,
                              });

                              saveExcelToFile(workbook, "Statement table").catch(showError);
                              setLoadingModal(false);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                const workbook = createStatementExcelFromTable({
                                  ...res,
                                  list: statementList,
                                  clientInfo: fullName,
                                  fromDate: formatDateToBody(
                                    filter.getInquiryFilterForm().fromDate,
                                  ),
                                  toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                  phoneNumber: phone,
                                  lastTransactionDate:
                                    filterValues.accountNumber.lastTransactionDate,
                                  accountNumber: filterValues.accountNumber.label,
                                  currency: filterValues.accountNumber.currency,
                                  accountName: filterValues.accountNumber.accountName,
                                  accountType: filterValues.accountNumber.accountType,
                                  // @ts-ignore
                                  total: res.list[0].totalRow,
                                });

                                saveExcelToFile(workbook, "Statement table").catch(showError);
                                setLoadingModal(false);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    const workbook = createStatementExcelFromTable({
                      list: [
                        {
                          bankTellerId: "",
                          documentNumber: "",
                          transactionDate: "",
                          receiverAccount: "",
                          receiverTaxNumber: "",
                          receiverName: "",
                          paymentDetail: "",
                          receiverMFO: "",
                          debitAmount: 0,
                          creditAmount: 0,
                          balance: 0,
                        },
                      ],
                      endBalance: data.endBalance,
                      beginBalance: data.beginBalance,
                      creditAmount: 0,
                      debitAmount: 0,
                      clientInfo: fullName,
                      fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                      toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                      phoneNumber: phone,
                      lastTransactionDate: filterValues.accountNumber.lastTransactionDate,
                      accountNumber: filterValues.accountNumber.label,
                      currency: filterValues.accountNumber.currency,
                      accountName: filterValues.accountNumber.accountName,
                      accountType: filterValues.accountNumber.accountType,
                      total: 0,
                    });

                    saveExcelToFile(workbook, "Statement table").catch(showError);
                  }
                }}
                onSaveToTxtAnsiClick={saveToAnsi}
                isMinusTransferLcyType={true}
                onSaveToExcel3Click={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              const workbook = createStatement1ExcelFromTable({
                                ...res,
                                list: statementList,
                                clientInfo: fullName,
                              });

                              saveExcelToFile(workbook, "Incoming and Outgoing statement")
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                const workbook = createStatement1ExcelFromTable({
                                  ...res,
                                  list: statementList,
                                  clientInfo: fullName,
                                });

                                saveExcelToFile(workbook, "Incoming and Outgoing statement")
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcel2Click={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              // @ts-ignore
                              const credits = statementList.filter((x) => x.debitAmount !== "0");
                              const workbook = createStatement3ExcelFromTable({
                                ...res,
                                list: credits,
                                clientInfo: fullName,
                              });

                              saveExcelToFile(workbook, "Outgoing statement")
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                // @ts-ignore
                                const credits = statementList.filter((x) => x.debitAmount !== "0");
                                const workbook = createStatement3ExcelFromTable({
                                  ...res,
                                  list: credits,
                                  clientInfo: fullName,
                                });

                                saveExcelToFile(workbook, "Outgoing statement")
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcel3KorzinkaClick={() => {
                  // @ts-ignore
                  if (data && data.list && data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCountKorzinka({
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: Math.ceil(data.list[0].totalRow / 100),
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                      // pageNumber: filter.getPageCount(),
                      // pageSize: filter.getPerPageStatement(),
                      status: filterValues.status !== "" ? filterValues.status : "A",
                      amount: filterValues.searchAmount !== "" ? filterValues.searchAmount : "A",
                      correspondentAccount:
                        filterValues.corrAcc !== "" ? filterValues.corrAcc : "A",
                      // @ts-ignore
                      showModalText: () =>
                        NotificationManager.error(
                          translate("STATEMENT_NO_DATA"),
                          translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                        ),
                    })
                      .then(() => setLoadingModal(false))
                      // .then(() => (start = false))
                      .catch((errors) => {
                        errors.data[0].userMsg === "Date range exceeds 1 month" &&
                          NotificationManager.error(errors.data[0].userMsg);
                      });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcel1Click={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              // @ts-ignore
                              const debits = statementList.filter((x) => x.creditAmount !== "0");
                              const workbook = createStatement2ExcelFromTable({
                                ...res,
                                list: debits,
                              });

                              saveExcelToFile(workbook, "Incoming statement")
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                // @ts-ignore
                                const debits = statementList.filter((x) => x.creditAmount !== "0");
                                const workbook = createStatement2ExcelFromTable({
                                  ...res,
                                  list: debits,
                                });

                                saveExcelToFile(workbook, "Incoming statement")
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcel4Click={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              const workbook = createStatement4ExcelFromTable({
                                ...res,
                                list: stableSort(
                                  // @ts-ignore
                                  statementList,
                                  getComparator(OrderDirection.Asc, "transactionDate"),
                                ),
                                clientInfo: fullName,
                                fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                                toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                accountNumber: filterValues.accountNumber.label,
                              });

                              saveExcelToFile(
                                workbook,
                                "Incoming and Outgoing statement with balance",
                              )
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                const workbook = createStatement4ExcelFromTable({
                                  ...res,
                                  list: stableSort(
                                    // @ts-ignore
                                    statementList,
                                    getComparator(OrderDirection.Asc, "transactionDate"),
                                  ),
                                  clientInfo: fullName,
                                  fromDate: formatDateToBody(
                                    filter.getInquiryFilterForm().fromDate,
                                  ),
                                  toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                  accountNumber: filterValues.accountNumber.label,
                                });

                                saveExcelToFile(
                                  workbook,
                                  "Incoming and Outgoing statement with balance",
                                )
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcel5Click={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              const workbook = createStatement5ExcelFromTable({
                                ...res,
                                list: statementList,
                                clientInfo: fullName,
                                fromDate: formatDateToBody(filter.getInquiryFilterForm().fromDate),
                                toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                accountNumber: filterValues.accountNumber.label,
                              });

                              saveExcelToFile(workbook, "Excel-1")
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                const workbook = createStatement5ExcelFromTable({
                                  ...res,
                                  list: statementList,
                                  clientInfo: fullName,
                                  fromDate: formatDateToBody(
                                    filter.getInquiryFilterForm().fromDate,
                                  ),
                                  toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                  accountNumber: filterValues.accountNumber.label,
                                });

                                saveExcelToFile(workbook, "Excel-1")
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
                onSaveToExcelAngleseyClick={() => {
                  // @ts-ignore
                  if (data.list.length > 0) {
                    setLoadingModal(true);
                    AccountApi.getAccountStatementCount({
                      customerSequence,
                      ...filter.getAccountStatementsFilter(),
                      accountNumber: filterValues.accountNumber.label,
                      pageNumber: 1,
                      pageSize: 100,
                      flagToHugeTurnover: flagToHugeType,
                    }).then((res) => {
                      let statementList = [{}];
                      statementList.shift();
                      // @ts-ignore
                      for (let i = 0; i < res.list[0].totalPage; i++) {
                        AccountApi.getAccountStatementCount({
                          customerSequence,
                          ...filter.getAccountStatementsFilter(),
                          accountNumber: filterValues.accountNumber.label,
                          pageNumber: i + 1,
                          pageSize: 100,
                          flagToHugeTurnover: flagToHugeType,
                        })
                          .then((r) => {
                            statementList = [...statementList, ...r.list];
                            if (
                              statementList.length ===
                              toFinite(
                                // @ts-ignore
                                res.list[0].totalRow,
                              )
                            ) {
                              const workbook = createStatementExcelAngleseyFromTable(
                                {
                                  ...res,
                                  list: statementList,
                                  // @ts-ignore
                                  clientInfo: statementList[0].clientName,
                                  fromDate: formatDateToBody(
                                    filter.getInquiryFilterForm().fromDate,
                                  ),
                                  // @ts-ignore
                                  senderMFO: statementList[0].senderMFO,
                                  // @ts-ignore
                                  senderBankName: statementList[0].senderBankName,
                                  toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                  phoneNumber: phone,
                                  lastTransactionDate:
                                    filterValues.accountNumber.lastTransactionDate,
                                  accountNumber: filterValues.accountNumber.label,
                                  currency: filterValues.accountNumber.currency,
                                  accountName: filterValues.accountNumber.accountName,
                                  accountType: filterValues.accountNumber.accountType,
                                  // @ts-ignore
                                  total: res.list[0].totalRow,
                                },
                                {
                                  translate,
                                },
                              );

                              saveExcelToFile(
                                workbook,
                                `${filterValues.accountNumber.label}_${formatDateToBody(
                                  filter.getInquiryFilterForm().fromDate,
                                )}_${formatDateToBody(filter.getInquiryFilterForm().toDate)}.txt`,
                              )
                                .then(() => setLoadingModal(false))
                                .catch(showError);
                            }
                          })
                          .catch(() => {
                            AccountApi.getAccountStatementCount({
                              customerSequence,
                              ...filter.getAccountStatementsFilter(),
                              accountNumber: filterValues.accountNumber.label,
                              pageNumber: i + 1,
                              pageSize: 100,
                              flagToHugeTurnover: flagToHugeType,
                            }).then((r) => {
                              statementList = [...statementList, ...r.list];
                              if (
                                statementList.length ===
                                toFinite(
                                  // @ts-ignore
                                  res.list[0].totalRow,
                                )
                              ) {
                                const workbook = createStatementExcelAngleseyFromTable(
                                  {
                                    ...res,
                                    list: statementList,
                                    // @ts-ignore
                                    clientInfo: statementList[0].clientName,
                                    fromDate: formatDateToBody(
                                      filter.getInquiryFilterForm().fromDate,
                                    ),
                                    // @ts-ignore
                                    senderMFO: statementList[0].senderMFO,
                                    // @ts-ignore
                                    senderBankName: statementList[0].senderBankName,
                                    toDate: formatDateToBody(filter.getInquiryFilterForm().toDate),
                                    phoneNumber: phone,
                                    lastTransactionDate:
                                      filterValues.accountNumber.lastTransactionDate,
                                    accountNumber: filterValues.accountNumber.label,
                                    currency: filterValues.accountNumber.currency,
                                    accountName: filterValues.accountNumber.accountName,
                                    accountType: filterValues.accountNumber.accountType,
                                    // @ts-ignore
                                    total: res.list[0].totalRow,
                                  },
                                  { translate },
                                );

                                saveExcelToFile(
                                  workbook,
                                  `${filterValues.accountNumber.label}_${formatDateToBody(
                                    filter.getInquiryFilterForm().fromDate,
                                  )}_${formatDateToBody(filter.getInquiryFilterForm().toDate)}.txt`,
                                )
                                  .then(() => setLoadingModal(false))
                                  .catch(showError);
                              }
                            });
                          })
                          .catch(() => {
                            setLoadingModal(false);
                            NotificationManager.error(
                              translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                            );
                          });
                      }
                    });
                  } else {
                    NotificationManager.error(
                      translate("STATEMENT_NO_DATA"),
                      translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                    );
                  }
                }}
              />
              <FlatButton
                leftIcon={<FilterIcon />}
                rightIcon={<ShapeIcon />}
                onClick={() => setShowFilter((x) => !x)}
                title="ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE"
                className="border border-gray-light br-1 bg-white px-2 py-1"
              />
            </>
          </div>
        </>
      )}

      <CorporateStatementData
        account={filterValues}
        data={data}
        loading={listOfStatement.loading}
      />
    </TabPage>
  );
}

export const CorporateStatementTab = React.memo(CorporateStatementTabLoc);
