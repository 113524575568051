import React, { ReactNode } from "react";

export interface MobileMenuItemProps {
  readonly key?: string;
  readonly children?: ReactNode;
  readonly icon?: ReactNode | undefined;
  readonly responsiveContent?: ReactNode;
  readonly show?: boolean;
}

export default function MobileMenuItem({ children, show = true }: MobileMenuItemProps) {
  if (!show) {
    return null;
  }

  return <div>{children}</div>;
}
