import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import { AccountsFilter, ApprovalAccountsSubmitType } from "../../filters/AccountsFilter";
import { AccountTableWrapper } from "./AccountTableWrapper";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { InquiryTableWrapper } from "../accounts/InquiryTableWrapper";
import {
  ApprovalInquiryType,
  ApprovalTypes,
  TotalPendingApplicationProps,
} from "../../api/account/AccountDTO";
import { Badge } from "../ui/Badge";
import { toFinite } from "lodash";

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeTab: (tab: ApprovalAccountsSubmitType) => void;
  readonly approvalFilter: PendingApprovalsFilter;
  readonly withCreatorsAndApprovals?: boolean;
  readonly loading: boolean;
  readonly setDocumentsId: (value, appType) => void;
  readonly totalApl: TotalPendingApplicationProps | undefined;
}

export function AccountTab({
  filter,
  onChangeTab,
  approvalFilter,
  withCreatorsAndApprovals,
  loading,
  setDocumentsId,
  totalApl,
}: Props) {
  const accountType = useMemo(() => filter.getAccountType(), [filter]);

  return (
    <TabPage contentClassName="bg-transparent border-0" className="px-4">
      <SubTabs
        activeTab={accountType}
        onChangeTab={onChangeTab}
        defaultTab={ApprovalAccountsSubmitType.NoneStandInquiry}
      >
        <TabPane
          key={ApprovalAccountsSubmitType.NoneStandInquiry}
          tab="PENDING_APPROVALS_ACCOUNT_NONE_STANDART_INQUIRIES"
          badge={
            <Badge
              value={toFinite(totalApl?.totalRequestsForNonStandardInquiry)}
              className="bg-danger"
            />
          }
        >
          <InquiryTableWrapper
            filter={filter}
            inquiryType={ApprovalInquiryType.NonStandard}
            loading={loading}
            setDocumentsId={setDocumentsId}
            withCreatorsAndApprovals={withCreatorsAndApprovals}
            ApprovalType={ApprovalTypes.NonStandardInq}
          />
        </TabPane>
        <TabPane
          key={ApprovalAccountsSubmitType.StandInquiry}
          tab="PENDING_APPROVALS_ACCOUNT_STANDART_INQUIRIES"
          badge={
            <Badge
              value={toFinite(totalApl?.totalRequestsForStandardInquiry)}
              className="bg-danger"
            />
          }
        >
          <InquiryTableWrapper
            filter={filter}
            inquiryType={ApprovalInquiryType.Standard}
            loading={loading}
            setDocumentsId={setDocumentsId}
            withCreatorsAndApprovals={withCreatorsAndApprovals}
            ApprovalType={ApprovalTypes.StandardInq}
          />
        </TabPane>

        <TabPane
          key={ApprovalAccountsSubmitType.NewAccountOpening}
          tab="PENDING_APPROVALS_ACCOUNT_NEW_ACCOUNT_OPENING"
          badge={
            <Badge value={toFinite(totalApl?.totalRequestsForAccountOpen)} className="bg-danger" />
          }
        >
          <AccountTableWrapper
            filter={approvalFilter}
            withCreatorsAndApprovals={withCreatorsAndApprovals}
            setDocumentsId={setDocumentsId}
          />
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
