import React, { useCallback, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { toFinite } from "lodash";
import { update } from "immupdate";

import { useResource } from "../../hooks/useResource";
import { TransferType } from "../../api/transfer/TransferDTO";
import { SalaryTable } from "./SalaryTable";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useI18n } from "../../i18n/I18nContext";
import { Modal } from "../ui/Modal";
import { PayrollViewTable } from "../payroll/PayrollViewTable";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: PendingApprovalsFilter;
  readonly setDocumentsId: (value, appType) => void;
  readonly withCreatorsAndApprovals?: boolean;
}

export function SalaryTableWrapper({ filter, setDocumentsId, withCreatorsAndApprovals }: Props) {
  const { SalaryApi } = useSalaryContext();
  const dispatch = useDispatch();
  const { translate } = useI18n();
  const [salaryData, setSalaryData] = useState([]);
  const [viewTable, setViewTable] = useState(false);

  const getSalaryListResource = useResource(
    () =>
      SalaryApi.getSalaryList({
        transferType: TransferType.Salary,
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );

  const data = useMemo(() => getSalaryListResource.data || [], [getSalaryListResource.data]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.Salary);
    },
    [data, setDocumentsId],
  );

  useResourceHandler(getSalaryListResource, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  const list = useMemo(() => data.map((x) => update(x, { id: x.payrollId })), [data]);

  const chooseTransferForView = useCallback(
    (value) => {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
      SalaryApi.getExistingRegister({
        transferType: TransferType.Salary,
        pageNumber: 1,
        pageSize: 100,
        payrollId: value,
      }).then((res) => {
        let registerList = [{}];
        registerList.shift();
        // @ts-ignore
        for (let i = 0; i < res[0].totalPageCount; i++) {
          SalaryApi.getExistingRegister({
            transferType: TransferType.Salary,
            pageNumber: i + 1,
            pageSize: 100,
            payrollId: value,
          })
            .then((r) => {
              const list = r.map((x) => ({
                id: (r.indexOf(x) + 1).toString(),
                fullName: x.cardName,
                accountNumber: x.cardAccount,
                amount: x.salaryAmount,
                systemNumber: "",
                systemName: "",
                salaryStatus: x.salaryStatus,
              }));
              registerList = [...registerList, ...list];
              if (
                registerList.length ===
                toFinite(
                  // @ts-ignore
                  res[0].totalRowCount,
                )
              ) {
                // @ts-ignore
                setSalaryData([registerList]);
                setViewTable(true);
              }
            })
            .catch(() => {
              SalaryApi.getExistingRegister({
                transferType: TransferType.Salary,
                pageNumber: i + 1,
                pageSize: 100,
                payrollId: value,
              })
                .then((r) => {
                  const list = r.map((x) => ({
                    id: (r.indexOf(x) + 1).toString(),
                    fullName: x.cardName,
                    accountNumber: x.cardAccount,
                    amount: x.salaryAmount,
                    systemNumber: "",
                    systemName: "",
                    salaryStatus: x.salaryStatus,
                  }));
                  registerList = [...registerList, ...list];
                  if (
                    registerList.length ===
                    toFinite(
                      // @ts-ignore
                      res[0].totalRowCount,
                    )
                  ) {
                    // @ts-ignore
                    setSalaryData([registerList]);
                    setViewTable(true);
                  }
                })
                .catch(() =>
                  NotificationManager.error(
                    translate("USER_INFO_AND_SETTINGS_TRY_AGAIN"),
                    translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
                  ),
                );
            });
        }
      });
    },
    [SalaryApi, translate],
  );

  return (
    <>
      <SalaryTable
        data={list}
        setDocumentId={setDocumentId}
        withCreatorsAndApprovals={withCreatorsAndApprovals}
        chooseTransferForView={chooseTransferForView}
      />
      <Modal
        show={viewTable}
        onClose={() => setViewTable(false)}
        title="TRANSFERS_SALARY_TAB_TITLE"
        width={1000}
      >
        <PayrollViewTable data={salaryData[0]} transferType={TransferType.Salary} />
      </Modal>
    </>
  );
}
