import "./assets/button.scss";

import cx from "classnames";
import React, { useCallback, useMemo } from "react";

import { Loader, LoaderSize } from "./Loader";
import { useI18n } from "../../i18n/I18nContext";
import { I18nCode } from "../../i18n/I18nSchema";
import { useTheme } from "../../theme/ThemeContext";
import { ButtonProps as UIButtonProps, SizeType } from "../../api/dto/AppDTO";

export enum ButtonColor {
  Danger = "danger",
  Orange = "orange",
  Default = "default",
  Primary = "primary",
  Success = "success",
  Secondary = "secondary",
}

export interface ButtonProps extends UIButtonProps {
  readonly size?: SizeType;
  readonly active?: boolean;
  readonly loading?: boolean;
  readonly color?: ButtonColor;
  readonly loaderColor?: string;
  readonly labelCode?: I18nCode | string;
  readonly authButton?: boolean;
}

export function Button({
  active,
  size = SizeType.Small,
  color = ButtonColor.Secondary,
  loading,
  disabled,
  children,
  className,
  labelCode,
  authButton,
  loaderColor = "#ffffff",
  ...props
}: ButtonProps) {
  const { ColorPalette } = useTheme();
  const { translate } = useI18n();

  const getBackgroundColor = useCallback(
    (c) => {
      switch (c) {
        case ButtonColor.Danger:
          return ColorPalette.Danger;
        case ButtonColor.Orange:
          return ColorPalette.Orange;
        case ButtonColor.Primary:
          return ColorPalette.Primary;
        case ButtonColor.Secondary:
          return ColorPalette.Secondary;
        case ButtonColor.Success:
          return ColorPalette.Success;
      }
    },
    [ColorPalette],
  );

  const getTextColor = useCallback(
    (c) => {
      if (c === ButtonColor.Default && disabled) {
        return ColorPalette.Gray;
      }

      if (c === ButtonColor.Default && !disabled && !active) {
        return ColorPalette.Dark;
      }

      if (c === ButtonColor.Default && !disabled && active) {
        return ColorPalette.Primary;
      }

      return ColorPalette.White;
    },
    [
      active,
      disabled,
      ColorPalette.Dark,
      ColorPalette.Gray,
      ColorPalette.White,
      ColorPalette.Primary,
    ],
  );

  const style = useMemo(
    () => ({
      color: getTextColor(color),
      backgroundColor: getBackgroundColor(color),
      ...props.style,
    }),
    [color, getBackgroundColor, getTextColor, props.style],
  );

  return (
    <button
      {...props}
      style={style}
      disabled={loading || disabled}
      className={cx(
        "btn cursor-pointer buttons-font shadow-button",
        {
          "button-ui": !authButton,
          "btn-sm": size === SizeType.Small,
          "btn-lg": size === SizeType.Large,
          "bg-white border border-blue-gray-extra-light shadow-none font-weight-bold":
            color === ButtonColor.Default,
        },
        className,
      )}
    >
      {loading ? (
        <Loader size={LoaderSize.Small} color={loaderColor} />
      ) : labelCode ? (
        translate(labelCode)
      ) : (
        children
      )}
    </button>
  );
}
