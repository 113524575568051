import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { TransferApi } from "./TransferApi";

interface Props {
  readonly TransferApi: TransferApi;
}

export function useTransferContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new TransferApi(data), [data]);

  return {
    TransferApi: api,
  };
}
