import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// css minify conflicting order
import "./components/tabs/assets/tabs.scss";
import "./components/tabs/assets/tab-item.scss";
import "./components/ui/assets/button.scss";

import ReactDOM from "react-dom";
import React, { StrictMode } from "react";

import * as serviceWorker from "./serviceWorker";
import { AppContainer } from "./containers/AppContainer";

ReactDOM.render(
  <StrictMode>
    <AppContainer />
  </StrictMode>,
  document.getElementById("root"),
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

// код для обновления страницы
// document.addEventListener("visibilitychange", function () {
//   if (document.hidden) {
//     console.log("Browser tab is hidden");
//   } else {
//     console.log("Browser tab is visible");
//     location.reload();
//   }
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
