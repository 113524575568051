import { BaseApi } from "../BaseApi";
import {
  ChangePasswordFormProps,
  LoginCredentialsProps,
  LoginProps,
  UserThemeAndLang,
} from "./AuthDTO";

interface LoginCredentialsBodyProps extends LoginCredentialsProps {
  readonly token: string;
}

export class AuthApi extends BaseApi {
  public loginCredentials(body: LoginCredentialsBodyProps): Promise<LoginProps> {
    return this.post("auth/authorize", { json: body });
  }

  public getUserLangAndTheme({ userId }): Promise<UserThemeAndLang> {
    return this.get("settings/:userId", { params: { userId } });
  }

  public loginOtp(key: string): Promise<boolean> {
    return this.post("auth/otp", { json: { key, employeeId: this.employeeId } }).then(({ data }) =>
      Boolean(data),
    );
  }

  public changePassword(values: ChangePasswordFormProps): Promise<string> {
    return this.post("auth/change-password", { json: { employeeId: this.employeeId, ...values } });
  }

  public getReCaptcha({ uniqueId }): Promise<any> {
    return this.getCaptcha("settings/recaptcha", { query: { uniqueId } });
  }

  public forgotPasswordCodeSent({ login }): Promise<any> {
    return this.post("auth/forgot-password", {
      query: { username: login },
    });
  }

  public resetPassword({ json }): Promise<string> {
    return this.post("auth/reset-password", {
      json,
    });
  }

  public updateThemeAndLang({ json }): Promise<string> {
    return this.patch("settings/update", {
      json,
    });
  }

  public updateNotificationSetting({ json }): Promise<string> {
    return this.put("notification/settings", {
      json,
    });
  }

  public getNotificationSettings({ login }): Promise<any> {
    return this.get("notification/settings", {
      query: { login },
    });
  }
}
