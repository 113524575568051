import "./assets/create-new-register-table-row.scss";

import React from "react";
import { FastField } from "formik";
import { ListChildComponentProps } from "react-window";
import cx from "classnames";

import { Input } from "../ui/Input";
import { floatFormat } from "../../utils/FloatUtils";
import { useI18n } from "../../i18n/I18nContext";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";


interface Props extends ListChildComponentProps {
  onRemove?: (value) => void;
}

export function CreateNewRegisterTableRow({ index, style, onRemove }: Props) {
  const { translate } = useI18n();
  return (
    <div style={style} className="d-flex create-new-register-table-row">
      <div className="col-id col-1 row display-1">
        <FastField name={`data.${index}.id`}>
          {({ form }) => (
            <Input
              value={index + 1}
              onChange={({ target }) => form.setFieldValue(`data.${index}.id`, target.value)}
            />
          )}
        </FastField>
      </div>
      <div className="account-number-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.accountNumber`}>
          {({ field, form }) => (
            <Input
              value={field?.value}
              onChange={({ target }) => {
                form.setFieldValue(`data.${index}.id`, (index + 1).toString());
                form.setFieldValue(`data.${index}.accountNumber`, target.value);
              }}
            />
          )}
        </FastField>
      </div>
      <div className="full-name-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.fullName`}>
          {({ field, form }) => (
            <Input
              value={field?.value}
              onChange={({ target }) => form.setFieldValue(`data.${index}.fullName`, target.value)}
              pattern={"[A-Za-z]"}
            />
          )}
        </FastField>
      </div>
      <div className="amount-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.amount`}>
          {({ field, form }) => (
            <Input
              required={true}
              value={field.value && floatFormat(field?.value)}
              onChange={({ target }) => form.setFieldValue(`data.${index}.amount`, target.value)}
            />
          )}
        </FastField>
      </div>
      <div className="account-number-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.systemNumber`}>
          {({ field, form }) => (
            <Input
              value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
              editable={false}
              className="bg-white"
            />
          )}
        </FastField>
      </div>
      <div className="full-name-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.systemName`}>
          {({ field, form }) => (
            <Input
              value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
              editable={false}
              className="bg-white"
            />
          )}
        </FastField>
      </div>
      <div className="status-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.status`}>
          {({ field, form }) => (
            <>
              {form?.values?.data[index]?.accountNumber !== "" ? (
                field?.value !== "" ? (
                  <div
                    className={cx(
                      "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center px-3 br-1 status-table-cell my-1 status-field",
                      {
                        "red-color-for-badge": field?.value !== "03",
                        "green-color-for-badge": field?.value === "03",
                      },
                    )}
                  >
                    <span className="text-white">
                      {field?.value === "01"
                        ? translate("SALARY_REGISTER_STATUS_NOT_FOUND")
                        : field?.value === "02"
                        ? translate("SALARY_REGISTER_STATUS_NOT_MATCH")
                        : field?.value === "03"
                        ? translate("SALARY_REGISTER_STATUS_MATCH")
                        : ""}
                    </span>
                  </div>
                ) : (
                  <Input
                    value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
                    editable={false}
                    className="bg-white"
                  />
                )
              ) : (
                <Input
                  value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
                  editable={false}
                  className="bg-white"
                />
              )}
            </>
          )}
        </FastField>
      </div>
      <div className="card-status-column col-columns row ml-0 display-block">
        <FastField name={`data.${index}.cardStatus`}>
          {({ field, form }) => (
            <>
              {form?.values?.data[index]?.accountNumber !== "" ? (
                field?.value?.length > 1 ? (
                  <div
                    className={cx(
                      "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center px-3 br-1 status-table-cell my-1 status-field",
                      {
                        "red-color-for-badge": field?.value !== "Active",
                        "green-color-for-badge": field?.value === "Active",
                      },
                    )}
                  >
                    <span className="text-white">
                      {field?.value === "Closed"
                        ? translate("SALARY_REGISTER_CARD_STATUS_CLOSED")
                        : field?.value === "Suspended"
                        ? translate("SALARY_REGISTER_CARD_STATUS_SUSPENDED")
                        : field?.value === "Active"
                        ? translate("SALARY_REGISTER_CARD_STATUS_ACTIVE")
                        : ""}
                    </span>
                  </div>
                ) : (
                  <Input
                    value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
                    editable={false}
                    className="bg-white"
                  />
                )
              ) : (
                <Input
                  value={form?.values?.data[index]?.accountNumber !== "" ? field?.value : ""}
                  editable={false}
                  className="bg-white"
                />
              )}
            </>
          )}
        </FastField>
      </div>
    
      <div className="ml-3 display-block">
        <FastField name={`data.${index}.status`}>
          {() => (
            <Button
              active={true}
              size={SizeType.Medium}
              className="px-3 px-xl-5"
              onClick={() => onRemove && onRemove(index)}
              labelCode="DELETE_BUTTON"
              color={ButtonColor.Danger}
            />
          )}
        </FastField>
      </div>
    </div>
  );
}
