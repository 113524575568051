import React, { useCallback, useState } from "react";
import { NotificationManager } from "react-notifications";
import { DateTime } from "luxon";

import { TransfersFCYTab } from "./TransfersFCYTab";
import { TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { showError } from "../../utils/NotificationUtils";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly filter: TransfersFilter;
}

export function TransfersFCYTabWrapper({ filter }: Props) {
  const { translate } = useI18n();
  const { TransferApi } = useTransferContext();
  const locationHelpers = useLocationHelpers();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (value) => {
      const date = DateTime.fromFormat(value?.transactionDate, "dd.MM.yyyy").toJSDate();
      const dateFor = new Date();
      dateFor.setDate(dateFor.getDate() - 1);
      setLoading(true);
      if (date < dateFor) {
        NotificationManager.error(
          translate("TRANSFER_DATE_INCORRECT_IMPORT"),
          translate("TRANSFER_INFO_TITLE"),
          7000,
        );
        setLoading(false);
      } else if (value.state === "101") {
        TransferApi.updateFcyTransfer(value.id, value)
          .then(() => {
            setTimeout(() => setLoading(false), 500);
            setTimeout(
              () => locationHelpers.replaceQuery({ transferType: TransferType.Unselect }),
              500,
            );
            NotificationManager.info(
              translate("TRANSFER_MODIFIED"),
              translate("TRANSFER_INFO_TITLE"),
              7000,
            );
          })
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      } else {
        TransferApi.createFcyTransfer(value)
          .then(() => {
            NotificationManager.info(
              translate("TRANSFER_INFO_CREATED"),
              translate("TRANSFER_INFO_TITLE"),
              7000,
            );
            setTimeout(() => setLoading(false), 500);
            setTimeout(
              () => locationHelpers.replaceQuery({ transferType: TransferType.Unselect }),
              500,
            );
          })
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      }
    },
    [TransferApi, locationHelpers, translate],
  );

  return <TransfersFCYTab filter={filter} onFcySubmit={onSubmit} loading={loading} />;
}
