import React from "react";
import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function FileWithpenIcon({ size = 20, color = ColorPalette.Blue, ...svgProps }: Props) {
  return (
    <svg
      width={size}
      height={size}
      {...svgProps}
      id="file_4_"
      data-name="file (4)"
      viewBox="0 0 18.001 18.002"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(-1)"
        d="M10.188,1.5H9.363a2.067,2.067,0,0,0-3.975,0H4.563A.567.567,0,0,0,4,2.063V3.188A1.319,1.319,0,0,0,5.313,4.5H9.438a1.319,1.319,0,0,0,1.313-1.313V2.063A.567.567,0,0,0,10.188,1.5Z"
      />
      <path
        fill={color}
        transform="translate(0 -0.75)"
        d="M10.689,3H10.5v.938A2.062,2.062,0,0,1,8.438,6H4.313A2.062,2.062,0,0,1,2.25,3.938V3H2.063A2.062,2.062,0,0,0,0,5.063v9.376A2.062,2.062,0,0,0,2.063,16.5H7.6l.165-.923a2.089,2.089,0,0,1,.57-1.1l.6-.6H2.813a.563.563,0,1,1,0-1.125H9.939a.255.255,0,0,1,.105.015h.008l2.7-2.7v-5A2.062,2.062,0,0,0,10.689,3Zm-.75,8.438H2.813a.563.563,0,0,1,0-1.125H9.939a.563.563,0,1,1,0,1.125ZM9.939,9H2.813a.563.563,0,1,1,0-1.125H9.939A.563.563,0,1,1,9.939,9Z"
      />
      <path
        fill={color}
        transform="translate(-2.943 -2.918)"
        d="M12.34,20.92a.563.563,0,0,1-.554-.661l.4-2.254a.565.565,0,0,1,.156-.3l5.569-5.569A1.145,1.145,0,0,1,19.633,12l.928.928a1.313,1.313,0,0,1,0,1.856l-5.569,5.569a.554.554,0,0,1-.3.156l-2.254.4a.536.536,0,0,1-.1.009Zm2.254-.96H14.6Z"
      />
    </svg>
  );
}
