export enum ColorPalette {
  Transparent = "rgba(0, 0, 0, 0)",

  White = "#ffffff",
  Black = "#000000",

  Blue = "#006db9",
  BlueSecond = "#386fa4",
  BlueThird = "#015cab",
  BlueLight = "#016dbc",

  Teal = "#b7d698",

  Dark = "#52586c",

  BlueDark = "#1a173b",
  BlueExtraLight = "#a9d1ef",

  BlueWhite = "#eff9fd",

  Orange = "#ff9b21",

  BlueGray = "#919bb0",
  BlueGrayDark = "#777e8e",
  BlueGrayLight = "#94a3af",
  BlueGrayExtraLight = "#c6ccd8",
  BlueGrayLighten = "#d6e9f6",

  Gray = "#707070",
  GrayLight = "#c6ccd8",

  Info = "#cbebf9",
  Muted = "#efefef",
  Danger = "#ff0000",
  Warning = "#fdb44d",
  Primary = "#1c579b",
  Success = "#1c9b24",
  Secondary = "#0088d6",

  Green = "#2c6e49",
}
