import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { UserInfoApi } from "./UserInfoApi";

interface Props {
  readonly UserInfoApi: UserInfoApi;
}

export function useUserInfoContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new UserInfoApi(data), [data]);

  return {
    UserInfoApi: api,
  };
}
