import React from "react";
import { Form, Formik, FormikProps } from "formik";

import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { SizeType } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";
import { Button, ButtonColor } from "../ui/Button";
import { DatePickerField } from "../form/DatePickerField";
import { SelectPickerField } from "../form/SelectPickerField";
import { LcyTransferMunisFormProps } from "../../api/transfer/TransferDTO";
import { addZeros } from "../../utils/FloatUtils";
import { object, string } from "yup";

interface Props {
  readonly onSubmit: (values: LcyTransferMunisFormProps) => void;

  readonly getPaymentCode: () => void;

  readonly initialValues?: LcyTransferMunisFormProps;

  readonly onChangeAmount: (value) => void;
  readonly onChangePaymentCode: (value) => void;
  readonly onChangePaymentDetails: (value) => void;
  readonly onChangeDocumentDate: (value) => void;
  readonly getSupplierCode: (value) => void;
  readonly getSettlementCode: (value) => void;
  readonly setSettlementCode: (value) => void;
  readonly onChangeDebitsAccount: (value) => void;
  readonly onChangeInvoiceNumber: (value) => void;
  readonly GenerateCorrespondent: () => void;
  readonly onChangeTransferNumber: (value) => void;

  readonly formikRef?: (instance: FormikProps<LcyTransferMunisFormProps>) => void;

  readonly payeeGroupList?: [{ label: ""; value: "" }];

  readonly supplierCodes?: [{ label: ""; value: "" }];

  readonly settlementCodes?: [{ label: ""; value: "" }];

  readonly debitAccount?: [{ label: ""; value: "" }];
}

const validationSchema = object({
  payeeGroup: object().required("Required"),
  supplierCode: object().required("Required"),
  settlementCode: object().required("Required"),
  documentNumber: string().required("Required"),
  clientAccount: object().required("Required"),
  amount: string().required("Required"),
  paymentCode: string().required("Required"),
  detailsOfPayment: string().required("Required"),
});

export function MinusTransferForm({
  onSubmit,
  initialValues,
  formikRef,
  getPaymentCode,
  onChangePaymentCode,
  onChangePaymentDetails,
  onChangeAmount,
  onChangeDocumentDate,
  payeeGroupList,
  getSupplierCode,
  supplierCodes,
  getSettlementCode,
  settlementCodes,
  setSettlementCode,
  debitAccount,
  onChangeDebitsAccount,
  GenerateCorrespondent,
  onChangeInvoiceNumber,
  onChangeTransferNumber,
}: Props) {
  const { translate } = useI18n();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues as LcyTransferMunisFormProps}
      onSubmit={() => onSubmit(initialValues as LcyTransferMunisFormProps)}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="d-flex flex-column p-4">
          <GroupBox contentClassName="row" className="mb-7">
            <div className="col-12">
              <div className="row mb-2">
                <div className="col-lg-3">
                  <SelectPickerField
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYEE_GROUP_TITLE"
                    name="payeeGroup"
                    options={payeeGroupList}
                    onChanges={(value) => getSupplierCode(value)}
                  />
                </div>
                <div className="col-lg-3">
                  <SelectPickerField
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SUPPLIER_CODE_TITLE"
                    name="supplierCode"
                    options={supplierCodes}
                    onChanges={(value) => getSettlementCode(value)}
                  />
                </div>
                <div className="col-lg-3">
                  <SelectPickerField
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SETTLEMENT_CODE_TITLE"
                    name="settlementCode"
                    options={settlementCodes}
                    onChanges={(value) => setSettlementCode(value)}
                  />
                </div>
                <div className="col-lg-3">
                  <DatePickerField
                    name="documentDate"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeDocumentDate(value)}
                    autoComplete="off"
                    minDate={new Date()}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 mt-1">
                  <InputField
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                    name="documentNumber"
                    type="number"
                    inputClassName="amount"
                    autoComplete="off"
                    required={true}
                    onChange={(value) => onChangeTransferNumber(value.target.value)}
                    value={initialValues?.documentNumber}
                  />
                </div>
                <div className="col-lg-3 mt-1">
                  <SelectPickerField
                    name="clientAccount"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_DEBIT_ACCOUNT_TITLE"
                    options={debitAccount}
                    onChanges={onChangeDebitsAccount}
                  />
                </div>
                <div className="d-flex flex-column col-lg-3">
                  <label>
                    {translate(
                      "TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE",
                    )}
                  </label>
                  <div className="input-group">
                    <InputField
                      className="flex-1"
                      name="invoiceNumber"
                      value={initialValues?.invoiceNumber}
                      onChange={(value) => onChangeInvoiceNumber(value.target.value)}
                    />
                    <div className="input-group-append">
                      <Button
                        size={SizeType.Medium}
                        color={ButtonColor.Orange}
                        type="button"
                        onClick={GenerateCorrespondent}
                      >
                        {translate("TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_GENERATE_BUTTON_TITLE")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GroupBox>

          <div className="row mb-7">
            <div className="col-lg-6">
              <GroupBox
                className="h-100"
                contentClassName="row"
                title="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_GROUP_TITLE_TITLE"
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <InputField
                        className="mb-2"
                        name="currentBalance"
                        label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_CURRENT_BALANCE_TITLE"
                        value={initialValues?.currentBalance}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <InputField
                    className="mb-2"
                    name="senderName"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_NAME_TITLE"
                    value={initialValues?.senderName}
                    disabled={true}
                  />

                  <InputField
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_NAME_TITLE"
                    name="senderBankName"
                    className="mb-2"
                    value={initialValues?.senderBankName}
                    disabled={true}
                  />

                  <div className="row">
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="senderBankCode"
                        label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_CODE_TITLE"
                        value={initialValues?.senderBankCode}
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="senderTaxId"
                        label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_TAX_ID_TITLE"
                        value={initialValues?.senderTaxId}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                title="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_GROUP_TITLE_TITLE"
              >
                <div className="col-12">
                  <InputField
                    className="mb-2"
                    name="correspondentName"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_NAME_TITLE"
                    value={initialValues?.correspondentName}
                    disabled={true}
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="correspondentAccount"
                        label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_TITLE"
                        value={initialValues?.correspondentAccount}
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="correspondentTaxNumber"
                        label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_TAX_ID_TITLE"
                        value={initialValues?.correspondentTaxNumber}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <InputField
                    className="mb-2"
                    name="amountForPayment"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_AMOUNT_FOR_PAYMENT"
                    value={initialValues?.amountForPayment}
                    disabled={true}
                  />
                </div>
                <div className="col-12">
                  <InputField
                    className="mb-2"
                    name="correspondentBank"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_CODE_TITLE"
                    value={initialValues?.correspondentBank}
                    disabled={true}
                  />
                </div>
              </GroupBox>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                title="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNTS_GROUP_TITLE_TITLE"
              >
                <div className="col-lg-6">
                  <InputField
                    className="mb-2"
                    name="amount"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_DIGITS_TITLE"
                    value={initialValues?.amount}
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    //   NotificationManager.error(translate("AMOUNT_COPY_PASTE"));
                    //   return false;
                    // }}
                    onChange={(value) => onChangeAmount(value.target.value.replace(/[,]/g, "."))}
                    onFocus={(value) => value.target.select()}
                    onBlur={(value) =>
                      onChangeAmount(addZeros(value.target.value.replace(/[,]/g, ".")))
                    }
                    autoComplete="off"
                    inputClassName="amount"
                  />
                </div>
                <div className="col-12">
                  <InputField
                    name="amountDetails"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_WORDS_TITLE"
                    value={initialValues?.amountDetails}
                    disabled={true}
                  />
                </div>
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                title="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_DETAILS_GROUP_TITLE_TITLE"
              >
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label>
                      {translate(
                        "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE",
                      )}
                    </label>
                    <div className="input-group">
                      <InputField
                        className="flex-1"
                        value={initialValues?.paymentCode}
                        name="paymentCode"
                        onChange={(value) => onChangePaymentCode(value.target.value)}
                        autoComplete="off"
                        type="number"
                        inputClassName="amount"
                      />
                      <div>
                        <Button
                          onClick={getPaymentCode}
                          type="button"
                          size={SizeType.Medium}
                          color={ButtonColor.Orange}
                        >
                          {translate("TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTIONS_BUTTON_TITLE")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <InputField
                    name="detailsOfPayment"
                    label="TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_DETAILS_TITLE"
                    onChange={(value) => onChangePaymentDetails(value.target.value)}
                    value={initialValues?.detailsOfPayment}
                    maxLength={400}
                  />
                </div>
              </GroupBox>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
