import React, { useCallback, useMemo, useState } from "react";

import { useQuery } from "../../hooks/useQuery";
import { Tabs } from "../../components/tabs/Tabs";
import { TabPane } from "../../components/tabs/TabPane";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { CorporateAppLayout } from "../../components/app/CorporateAppLayout";
import { InquiryTabWrapper } from "../../components/accounts/InquiryTabWrapper";
import { AccountsTabWrapper } from "../../components/accounts/AccountsTabWrapper";
import { NewAccountOpeningTab } from "../../components/accounts/NewAccountsOpeningTab";
import { SwiftTab } from "../../components/accounts/SwiftTab";
import { CorporateStatementTab } from "../../components/accounts/CorporateStatementTab";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../constants/Routes";
import { formatDateToUrl } from "../../utils/DateUtils";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { accessToAccountManagement } from "../../reducers/authReducer";
import { CreateNewDocumentRoles, RoleType } from "../../api/dto/AppDTO";
import { CardBoxTableWrapper } from "../../components/accounts/CardBoxTableWrapper";
import { userRoleSelector } from "../../reducers/userReducer";

export enum AccountTabs {
  Inquiry = "inquiry",
  Accounts = "accounts",
  Statement = "statement",
  NewAccountOpening = "new-account-opening",
  CardBox = "card-box",
  SWIFT = "swift",
  PaymentClaim = "payment-claim",
}

interface Params {
  readonly tab?: AccountTabs;
}

function CorporateAccountsContainerLoc() {
  const query = useQuery();
  const locationHelpers = useLocationHelpers();
  const history = useHistory();
  const roleType = useShallowEqualSelector(userRoleSelector);

  const filter = useMemo(() => new AccountsFilter(query), [query]);
  const { tab = AccountTabs.Accounts } = useParams<Params>();
  const [accountNumber, setAccountNumber] = useState({
    accountNumber: "",
    accountName: "",
    lastTransactionDate: "",
    currency: "",
    accountType: "",
  });

  const onChangeStatementAccount = useCallback(
    ({ accountNumber, accountName, lastTransactionDate, currency, accountType }) => {
      history.replace(`${Routes.CorporateAccountsRoute}/${AccountTabs.Statement}`);
      setAccountNumber({
        accountNumber: accountNumber,
        accountName: accountName,
        lastTransactionDate: lastTransactionDate,
        currency: currency,
        accountType: accountType,
      });
    },
    [history],
  );
  const accessToAccount = useShallowEqualSelector(accessToAccountManagement);

  return (
    <CorporateAppLayout>
      <Tabs
        className="bg-white pt-2"
        activeTab={tab}
        defaultTab={AccountTabs.Accounts}
        onChangeTab={(tab: AccountTabs) =>
          history.replace(`${Routes.CorporateAccountsRoute}/${tab}`)
        }
      >
        <TabPane key={AccountTabs.Accounts} tab="ACCOUNTS_ACCOUNTS_TAB_TITLE">
          <AccountsTabWrapper filter={filter} onChangeStatementAccount={onChangeStatementAccount} />
        </TabPane>
        <TabPane
          scrollableContent={true}
          key={AccountTabs.Statement}
          tab="ACCOUNTS_STATEMENT_TAB_TITLE"
        >
          <CorporateStatementTab filter={filter} accountNumber={accountNumber} />
        </TabPane>
        {accessToAccount === CreateNewDocumentRoles.Yes ? (
          <TabPane key={AccountTabs.Inquiry} tab="ACCOUNTS_INQUIRY_TAB_TITLE">
            <InquiryTabWrapper filter={filter} />
          </TabPane>
        ) : (
          <div />
        )}
        {accessToAccount === CreateNewDocumentRoles.Yes ? (
          <TabPane key={AccountTabs.NewAccountOpening} tab="ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE">
            <NewAccountOpeningTab
              onChangeTab={(type) => locationHelpers.replaceQuery({ newAccType: type })}
              filter={filter}
              onChangeFilter={(value) =>
                locationHelpers.replaceQuery({
                  fromDate: formatDateToUrl(value.fromDate),
                  toDate: formatDateToUrl(value.toDate),
                })
              }
            />
          </TabPane>
        ) : (
          <div />
        )}
        <TabPane scrollableContent={true} key={AccountTabs.CardBox} tab="ACCOUNT_CARD_BOX_TAB">
          <CardBoxTableWrapper filter={filter} />
        </TabPane>
        {accessToAccount === CreateNewDocumentRoles.Yes ? (
          <TabPane key={AccountTabs.SWIFT} tab="ACCOUNT_SWIFT_TAB">
            {roleType === RoleType.Corporate && (
              <SwiftTab
                onChangeTab={(type) => locationHelpers.replaceQuery({ swiftInnerType: type })}
                filter={filter}
                onChangeFilter={(value) =>
                  locationHelpers.replaceQuery({
                    fromDate: formatDateToUrl(value.fromDate),
                    toDate: formatDateToUrl(value.toDate),
                  })
                }
              />
            )}
          </TabPane>
        ) : (
          <div />
        )}
      </Tabs>
    </CorporateAppLayout>
  );
}

export const CorporateAccountsContainer = React.memo(CorporateAccountsContainerLoc);
