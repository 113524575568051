import { useCallback, useEffect, useState } from "react";

let hidden: any = null;
let visibilityChange: any = null;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
  // @ts-ignore
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
  // @ts-ignore
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

export function useBrowserTabChange() {
  const [visibleBrowserTab, setVisibleBrowserTab] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    document[hidden] ? setVisibleBrowserTab(false) : setVisibleBrowserTab(true);
  }, []);

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    return () => document.addEventListener(visibilityChange, handleVisibilityChange);
  }, [handleVisibilityChange]);

  return { visibleBrowserTab };
}
