import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function UserInfoIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-0.082 -0.082)">
        <path
          fill={color}
          transform="translate(0.082 0.082)"
          d="M11.446,20H8.555a.5.5,0,0,1-.319-.111.388.388,0,0,1-.15-.279l-.365-2.4a8.165,8.165,0,0,1-1.172-.482L4.714,18.125a.472.472,0,0,1-.325.117.448.448,0,0,1-.325-.143,17.176,17.176,0,0,1-2.148-2.188.508.508,0,0,1,.013-.6c.126-.177.344-.46.665-.865s.552-.709.7-.918a6.423,6.423,0,0,1-.534-1.289L.378,11.888A.412.412,0,0,1,.1,11.725a.489.489,0,0,1-.1-.306V8.529a.5.5,0,0,1,.1-.3.393.393,0,0,1,.248-.17l2.422-.365a6.5,6.5,0,0,1,.507-1.2c-.344-.49-.813-1.1-1.393-1.8a.491.491,0,0,1-.13-.313.55.55,0,0,1,.117-.3,13.042,13.042,0,0,1,1.282-1.4c.627-.618,1.041-.931,1.231-.931a.554.554,0,0,1,.338.13l1.8,1.393a7.417,7.417,0,0,1,1.185-.495A21,21,0,0,1,8.086.365.439.439,0,0,1,8.554,0h2.891a.5.5,0,0,1,.319.11.388.388,0,0,1,.15.28l.365,2.4a8.085,8.085,0,0,1,1.172.482L15.3,1.875a.424.424,0,0,1,.313-.117.505.505,0,0,1,.325.13A17.864,17.864,0,0,1,18.086,4.1a.423.423,0,0,1,.091.286.5.5,0,0,1-.1.3c-.131.183-.354.474-.665.866-.329.417-.552.709-.7.918a8.006,8.006,0,0,1,.534,1.275l2.382.365a.412.412,0,0,1,.273.163.5.5,0,0,1,.1.306v2.891a.5.5,0,0,1-.1.3.4.4,0,0,1-.261.17l-2.409.364a8.321,8.321,0,0,1-.507,1.185c.3.429.768,1.034,1.393,1.8a.505.505,0,0,1,.13.325.432.432,0,0,1-.117.3,13.164,13.164,0,0,1-1.289,1.407c-.622.613-1.033.924-1.223.924a.642.642,0,0,1-.339-.117l-1.8-1.406a7.475,7.475,0,0,1-1.185.495,21.486,21.486,0,0,1-.378,2.422A.44.44,0,0,1,11.446,20ZM10,6.666A3.334,3.334,0,0,0,6.667,10,3.335,3.335,0,0,0,10,13.334,3.335,3.335,0,0,0,13.334,10,3.334,3.334,0,0,0,10,6.666Z"
        />
      </g>
    </svg>
  );
}
