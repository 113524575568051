import React from "react";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";

interface Props {
  readonly ImportForAnor: () => void;
  readonly ImportForCBU: () => void;
  readonly disabled?: boolean;
  readonly beginDay?: string;
}

export function ImportAnorOrCBU({ ImportForAnor, ImportForCBU, disabled = true, beginDay }: Props) {
  return (
    <div className="d-flex justify-content-between">
      {beginDay === "1" && (
        <Button
          onClick={ImportForCBU}
          size={SizeType.Medium}
          color={ButtonColor.Orange}
          labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
          className="m-5 mb-5 ml-auto mr-auto"
          disabled={!disabled}
        />
      )}
      {beginDay === "0" && (
        <Button
          onClick={ImportForAnor}
          size={SizeType.Medium}
          color={ButtonColor.Orange}
          labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
          className="mt-5 mb-5 ml-auto mr-auto"
          disabled={!disabled}
        />
      )}
      {/* {beginDay === "1" ? (
        <>
          <Button
            onClick={ImportForCBU}
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            labelCode="IMPORT_FOR_CBU"
            className="m-5"
            disabled={!disabled}
          />
          <Button
            onClick={ImportForAnor}
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            labelCode="IMPORT_FOR_ANOR"
            className="m-5"
            disabled={!disabled}
          />
        </>
      ) : (
        <>
          <Button
            onClick={ImportForAnor}
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
            className="mt-5 mb-5 ml-auto mr-auto"
            disabled={!disabled}
          />
        </>
      )} */}
    </div>
  );
}
