import React, { useCallback, useMemo } from "react";

import { SelectPicker } from "./SelectPicker";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "./Button";
import { useI18n } from "../../i18n/I18nContext";
import { AppFilter } from "../../filters/AppFilter";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";

const perPageCountList = ["25", "50", "100"].map((x) => ({
  label: x,
  value: x,
}));

interface Props<TData> {
  readonly filter: AppFilter<any>;
}

export function PaginatorForStatement<TData = any>({ filter }: Props<TData>) {
  const { translate } = useI18n();

  const locationHelpers = useLocationHelpers();

  const perPage = useMemo(() => filter.getPerPageStatement(), [filter]);
  const currentPage = useMemo(() => filter.getPage(), [filter]);
  const pageCount = useMemo(() => filter.getPageCount(), [filter]);

  const title = useMemo(() => {
    const of = translate("PAGINATION_OF_TITLE");

    return `${currentPage} ${of} ${pageCount}`;
  }, [currentPage, pageCount, translate]);

  const list = useMemo(() => Array(pageCount).fill(""), [pageCount]);

  const onChangePerPage = useCallback(
    (value) => {
      locationHelpers.replaceQuery({ perPageStatement: value.value });
    },
    [locationHelpers],
  );

  return (
    <div className="d-flex align-items-center">
      <SelectPicker
        minWidth={64}
        className="mr-2"
        size={SizeType.Small}
        options={perPageCountList}
        value={{ value: `${perPage}`, label: `${perPage}` }}
        onChange={(value) => onChangePerPage(value)}
      />
      <span className="mr-2 font-weight-bold text-nowrap">{title}</span>
      <div className="btn-group" role="group">
        <Button
          active={true}
          color={ButtonColor.Default}
          disabled={currentPage === 1}
          onClick={() => locationHelpers.replaceQuery({ page: 1 })}
        >
          {"<"}
        </Button>

        {list.map((_, idx, arr) => {
          const number = idx + 1;

          if (arr.length > 4 && (number === currentPage + 3 || number === currentPage - 2)) {
            return (
              <Button
                key={idx}
                disabled={true}
                color={ButtonColor.Default}
                onClick={() => locationHelpers.replaceQuery({ page: number })}
              >
                ...
              </Button>
            );
          }

          if (
            arr.length > 4 &&
            (number > currentPage + 3 || number < currentPage - 2) &&
            number < arr.length - 2
          ) {
            return null;
          }

          return (
            <Button
              key={idx}
              color={ButtonColor.Default}
              active={currentPage === number}
              onClick={() => locationHelpers.replaceQuery({ page: number })}
              className={currentPage === number ? "active-page" : ""}
            >
              {number}
            </Button>
          );
        })}
        <Button
          active={true}
          color={ButtonColor.Default}
          disabled={currentPage === pageCount}
          onClick={() => locationHelpers.replaceQuery({ page: pageCount })}
        >
          {">"}
        </Button>
      </div>
    </div>
  );
}
