import React, { useMemo, memo } from "react";

import { TabPage } from "../tabs/TabPage";
import { useI18n } from "../../i18n/I18nContext";
import { PayrollProps } from "../../api/salary/SalaryDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { Dict } from "../../api/dto/AppDTO";

interface Props {
  readonly loading: boolean;
  readonly data: PayrollProps[];

  readonly className?: string;

  readonly withCreatorsAndApprovals?: boolean;
  readonly chooseExistingRegister?: (value, type) => void;
  readonly withChoose?: boolean;
  readonly type: string;
  readonly onSelect?: (ids: Dict<boolean>) => void;
}

export const PayrollTable = memo(function ({
  // export function PayrollTable({
  data = [],
  loading,
  className,
  withCreatorsAndApprovals,
  chooseExistingRegister,
  withChoose,
  type,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<PayrollProps>[] = [
      {
        width: 90,
        accessor: "payrollId",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_PAYMENT_CODE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {withChoose ? (
              <a
                href="#"
                onClick={() => chooseExistingRegister && chooseExistingRegister(row.value, type)}
              >
                {row.value}
              </a>
            ) : (
              <div>{row.value}</div>
            )}
          </div>
        ),
      },
      {
        width: 75,
        accessor: "cardType",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_CARD_TYPE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{row.value}</div>,
      },
      {
        width: 70,
        accessor: "operationDate",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{row.value}</div>,
      },
      {
        width: 150,
        accessor: "payrollAmount",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">{row.value ? floatFormat(addZeros(row.value)) : "0"}</div>
        ),
      },
      {
        width: 180,
        accessor: "transitAccount",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{row.value}</div>,
      },
      {
        width: 400,
        accessor: "payrollDetail",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "payrollStatus",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_CREATED_BY_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [withCreatorsAndApprovals, translate, chooseExistingRegister, type, withChoose]);

  return (
    <TabPage className={className}>
      <Table
        data={data}
        // onSelect={onSelect}
        columns={columns}
        loading={loading}
        showEmptyRow={true}
        withCheckbox={true}
      />
    </TabPage>
  );
});

PayrollTable.displayName = "PayrollTable";
