import React, { useCallback } from "react";
import { Form, Formik } from "formik";

import { CheckboxField } from "../form/CheckboxField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { Button } from "../ui/Button";
import { DatePickerField } from "../form/DatePickerField";
import { update } from "immupdate";
import { useI18n } from "../../i18n/I18nContext";

export interface CoverHeaderFormProps {
  fromDate: Date;
  toDate: Date;
  chooseDate: boolean;
}

interface Props {
  readonly initialValues: CoverHeaderFormProps;
  readonly setInitialValues: (value: any) => void;
  readonly onSubmit: (value: CoverHeaderFormProps) => void;
}

export default function CoverHeader({ onSubmit, initialValues, setInitialValues }: Props) {
  const { translate } = useI18n();

  const onChangeFromDate = useCallback(
    (value: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          fromDate: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeToDate = useCallback(
    (value: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          toDate: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeChooseDate = useCallback(() => {
    setInitialValues((prev: any) =>
      update(prev, {
        chooseDate: !initialValues.chooseDate,
      }),
    );
  }, [setInitialValues, initialValues]);

  return (
    <div className="d-flex">
      <Formik onSubmit={() => onSubmit(initialValues)} initialValues={initialValues}>
        {() => (
          <Form className="w-100">
            <div className="d-flex justify-content-between flex-lg-row flex-column">
              <div className="d-flex flex-lg-row flex-column">
                <DatePickerField
                  width={200}
                  name="fromDate"
                  className="pr-5 my-lg-0 my-2"
                  size={SizeType.Small}
                  selectsStart={true}
                  labelPosition={PositionType.Left}
                  label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeFromDate(value)}
                  disabled={!initialValues.chooseDate}
                />
                <DatePickerField
                  width={200}
                  name="toDate"
                  className="pr-5 my-lg-0 my-2"
                  size={SizeType.Small}
                  selectsEnd={true}
                  labelPosition={PositionType.Left}
                  label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeToDate(value)}
                  disabled={!initialValues.chooseDate}
                />
                <CheckboxField
                  label={translate("ACCOUNT_SWIFT_COVER_CHOCKBOX_FILTER_TITLE")}
                  name="chooseDate"
                  className="d-flex align-items-center my-lg-0 my-2"
                  labelClassName="mt-1"
                  onChange={() => onChangeChooseDate()}
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <p className="p-0 m-0 mr-2">
                  {translate("ACCOUNT_SWIFT_COVER_CHECK_RECEIVED_SWIFT_MESSAGES_TITLE")}
                </p>
                <Button type="submit" size={SizeType.Small} className="mt-lg-0 mb-3">
                  {translate("ACCOUNT_SWIFT_COVER_CHECK_BUTTON_TITLE")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
