import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function AgreementIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20 19.222"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="hands-and-gestures" transform="translate(0 -18.111)">
        <g id="Group_106" data-name="Group 106" transform="translate(9.394 18.111)">
          <g id="Group_105" data-name="Group 105" transform="translate(0 0)">
            <path
              id="Path_29"
              data-name="Path 29"
              d="M241.606,18.111a.606.606,0,0,0-.606.606v1.818a.606.606,0,1,0,1.212,0V18.717A.606.606,0,0,0,241.606,18.111Z"
              transform="translate(-241 -18.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_108" data-name="Group 108" transform="translate(4.705 20.535)">
          <g id="Group_107" data-name="Group 107">
            <path
              id="Path_30"
              data-name="Path 30"
              d="M123.247,79.5l-1.212-1.212a.606.606,0,0,0-.857.857l1.212,1.212a.606.606,0,1,0,.857-.857Z"
              transform="translate(-121.001 -78.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_110" data-name="Group 110" transform="translate(12.871 20.535)">
          <g id="Group_109" data-name="Group 109">
            <path
              id="Path_31"
              data-name="Path 31"
              d="M333.247,78.288a.606.606,0,0,0-.857,0L331.178,79.5a.606.606,0,0,0,.857.857l1.212-1.212A.606.606,0,0,0,333.247,78.288Z"
              transform="translate(-331.001 -78.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_112" data-name="Group 112" transform="translate(17.536 26.595)">
          <g id="Group_111" data-name="Group 111" transform="translate(0 0)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M452.859,228.111H451V236.6h1.858a.606.606,0,0,0,.606-.606v-7.272A.606.606,0,0,0,452.859,228.111Z"
              transform="translate(-451 -228.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_114" data-name="Group 114" transform="translate(0 26.595)">
          <g id="Group_113" data-name="Group 113" transform="translate(0 0)">
            <path
              id="Path_33"
              data-name="Path 33"
              d="M.606,228.111a.606.606,0,0,0-.606.606v7.272a.606.606,0,0,0,.606.606H2.465v-8.484Z"
              transform="translate(0 -228.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_116" data-name="Group 116" transform="translate(6.503 27.201)">
          <g id="Group_115" data-name="Group 115">
            <path
              id="Path_34"
              data-name="Path 34"
              d="M174.979,243.111a1.206,1.206,0,0,0-1.084.67l-1.845,3.691a2.415,2.415,0,0,0,3.136-1.139l.4-.8h1.173a1.221,1.221,0,0,1,.857.355l4.493,4.493v-7.272Z"
              transform="translate(-172.05 -243.111)"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_118" data-name="Group 118" transform="translate(3.376 24.171)">
          <g id="Group_117" data-name="Group 117">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M98.983,174.776h-.424l-.035.07a3.692,3.692,0,0,1-2.965,2.057,4.389,4.389,0,0,1-2.361-.544.61.61,0,0,1-.3-.826l2.132-4.266a2.425,2.425,0,0,1,2.169-1.341h2.615a2.93,2.93,0,0,0,.272-1.212.606.606,0,0,0-.606-.606H94.779a1.818,1.818,0,0,0-1.627,1.005l-.542,1.084a.606.606,0,0,1-.542.335H91v8.484h2.779l1.717,1.717a1.8,1.8,0,0,0,1.833.448l5.176-1.627a1.753,1.753,0,0,0,.783-.475Z"
              transform="translate(-91 -168.11)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
