import React, { useCallback, useMemo } from "react";
import { InquiryTable } from "./InquiryTable";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: AccountsFilter;
  readonly inquiryType: string;
  readonly loading: boolean;
  readonly setDocumentsId: (value, appType) => void;
  readonly withCreatorsAndApprovals?: boolean;
  readonly ApprovalType: ApprovalTypes;
}

export function InquiryTableWrapper({
  filter,
  inquiryType,
  loading,
  setDocumentsId,
  withCreatorsAndApprovals,
  ApprovalType,
}: Props) {
  const { AccountApi } = useAccountContext();
  const dispatch = useDispatch();
  const getApprovalInquiryList = useResource(
    () =>
      AccountApi.getApprovalInquiryList({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
        inquiryType: inquiryType,
      }),
    [filter],
  );
  const data = useMemo(() => getApprovalInquiryList.data || [], [getApprovalInquiryList.data]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalType);
    },
    [ApprovalType, data, setDocumentsId],
  );

  useResourceHandler(getApprovalInquiryList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  return (
    <InquiryTable
      data={data}
      loading={loading}
      setDocumentId={setDocumentId}
      withCreatorsAndApprovals={withCreatorsAndApprovals}
    />
  );
}
