import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { AccountsFilter, ApprovalPaymentClaimSubmitType } from "../../filters/AccountsFilter";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { TotalPendingApplicationProps } from "../../api/account/AccountDTO";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import { Badge } from "../ui/Badge";
import { toFinite } from "lodash";
import { SentPaymentClaimTableWrapper } from "./SentPaymentClaimTableWrapper";
import { ReceivedPaymentClaimTableWrapper } from "./ReceivedPaymentClaimTableWrapper";

interface Props {
  readonly filter: AccountsFilter;
  readonly approvalFilter: PendingApprovalsFilter;
  readonly withCreatorsAndApprovals?: boolean;
  readonly loading: boolean;
  readonly setDocumentsId: (value, appType) => void;
  readonly totalApl: TotalPendingApplicationProps | undefined;
  readonly onChangeTab: (tab: ApprovalPaymentClaimSubmitType) => void;
}

export function PaymentClaimTab({
  filter,
  approvalFilter,
  onChangeTab,
  setDocumentsId,
  withCreatorsAndApprovals,
  totalApl,
}: Props) {
  const paymentCLaimType = useMemo(() => filter.getPaymentCLaimType(), [filter]);
  return (
    <TabPage contentClassName="bg-transparent border-0" className="px-4">
      <SubTabs
        activeTab={paymentCLaimType}
        onChangeTab={onChangeTab}
        defaultTab={ApprovalPaymentClaimSubmitType.ReceivedPaymentClaim}
      >
        <TabPane
          key={ApprovalPaymentClaimSubmitType.ReceivedPaymentClaim}
          tab="TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE"
          badge={
            <Badge
              value={toFinite(totalApl?.totalRequestsForPaymentClaimIncoming)}
              className="bg-danger"
            />
          }
        >
          <ReceivedPaymentClaimTableWrapper
            withCreatorsAndApprovals={withCreatorsAndApprovals}
            setDocumentsId={setDocumentsId}
            filter={approvalFilter}
          />
        </TabPane>
        <TabPane
          key={ApprovalPaymentClaimSubmitType.SentPaymentClaim}
          tab="TRANSFERS_PAYMENT_CLAIM_SENT_SUBTAB_TITLE"
          badge={
            <Badge
              value={toFinite(totalApl?.totalRequestsForPaymentClaimOutgoing)}
              className="bg-danger"
            />
          }
        >
          <SentPaymentClaimTableWrapper
            withCreatorsAndApprovals={withCreatorsAndApprovals}
            setDocumentsId={setDocumentsId}
            filter={approvalFilter}
          />
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
