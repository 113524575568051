import React, { useCallback, useMemo } from "react";
import { TradeFinanceDiscrepancyMenu } from "./TradeFinanceDiscrepencyMenu";
import { useResource } from "../../hooks/useResource";
import { useTradeFinanceContext } from "../../api/trade-finance/TradeFinanceContext";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { AccountsFilter } from "../../filters/AccountsFilter";

interface Props {
  filter: AccountsFilter;
  readonly setDocumentsId: (value, appType) => void;
}

export function TradeFinanceDiscrepencyWrapper({ filter, setDocumentsId }: Props) {
  const { TradeFinanceApi } = useTradeFinanceContext();

  const getApplicationDiscrepency = useResource(
    () =>
      TradeFinanceApi.getApprovalDiscrepancy({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );
  const data = useMemo(() => getApplicationDiscrepency.data || [], [
    getApplicationDiscrepency.data,
  ]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      // list.map((e) => (e.id = e.discrepancyId));
      setDocumentsId(list, ApprovalTypes.Discrepancy);
    },
    [data, setDocumentsId],
  );

  return (
    <TradeFinanceDiscrepancyMenu
      loading={getApplicationDiscrepency.loading}
      data={data}
      setDocumentsId={setDocumentId}
    />
  );
}
