import "./assets/tooltip-item.scss";

import React from "react";
import cx from "classnames";

interface Props {
  readonly id: string;
  readonly title: string;
  readonly show: boolean;
  readonly hidden?: boolean;
  readonly className?: string;
}

export function TooltipItem({ id, className, hidden, title, show }: Props) {
  return (
    <div
      id={id}
      role="tooltip"
      data-show={show || undefined}
      data-hidden={hidden || undefined}
      className={cx("tooltip-item p-2", className)}
    >
      <span className="tooltip-title">{title}</span>
      <div className="tooltip-arrow" data-popper-arrow={true} />
    </div>
  );
}
