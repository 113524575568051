/* eslint-disable no-console */
import React, { useCallback } from "react";
import { Form, Formik } from "formik";
import { PageTitle } from "../app/PageTitle";
import { InputField } from "../form/InputField";
import { SizeType, ViewMode } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";
import { Button, ButtonColor } from "../ui/Button";
import { DatePickerField } from "../form/DatePickerField";
import { RadioGroupField } from "../form/RadioGroupField";
import { SalaryCardProps } from "../../api/salary/SalaryDTO";
import { SelectPickerField } from "../form/SelectPickerField";
import { update } from "immupdate";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { viewModeSelector } from "../../reducers/appReducer";
import { object, string } from "yup";
import { CheckboxField } from "../form/CheckboxField";

interface Props {
  readonly onSubmit: (values: SalaryCardProps) => void;
  readonly citizenshipList: [{ label: ""; value: "" }];
  readonly initialValues: SalaryCardProps;
  readonly setInitialValues: (value) => void;
  readonly clearInitialValues: () => void;
  readonly clearing: boolean;
  readonly disabled: boolean;
}

export function SalaryEmployeeRegistrationForm({
  onSubmit,
  citizenshipList,
  initialValues,
  clearInitialValues,
  clearing,
  setInitialValues,
  disabled,
}: Props) {
  const { translate } = useI18n();

  const onChangePassportSeries = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 2 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            passportSeries: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangePassportNumber = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 9 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            passportNumber: value.toUpperCase(),
          }),
        );
      }
      // if (value.length <= 7) {
      //   setInitialValues((prev) =>
      //     update(prev, {
      //       passportNumber: value,
      //     }),
      //   );
      // }
    },
    [setInitialValues],
  );

  const onChangePassportIssue = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 60 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            passportIssuedBy: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  // const onChangePhoneNumber = useCallback(
  //   (value) => {
  //     const checked = /[А-яA-z"$%№=]/g.test(value);
  //     if (value.length <= 60 && !checked) {
  //       setInitialValues((prev) =>
  //         update(prev, {
  //           phoneNumber: value.toUpperCase(),
  //         }),
  //       );
  //     }
  //   },
  //   [setInitialValues],
  // );

  // const onChangePassword = useCallback(
  //   (value) => {
  //     const checked = /[А-Яа-я"$%№=]/g.test(value);
  //     if (value.length <= 60 && !checked) {
  //       setInitialValues((prev) =>
  //         update(prev, {
  //           password: value.toUpperCase(),
  //         }),
  //       );
  //     }
  //   },
  //   [setInitialValues],
  // );

  const onChangeFirstName = useCallback(
    (value) => {
      const checked = /[^A-Za-z ]/g.test(value);
      if (value.length <= 30 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            firstName: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeSurname = useCallback(
    (value) => {
      const checked = /[^A-Za-z ]/g.test(value);
      if (value.length <= 30 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            surname: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangePatronymic = useCallback(
    (value) => {
      const checked = /[^A-Za-z ]/g.test(value);
      if (value.length <= 30 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            patronymic: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeBirthdayPlace = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 100 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            birthdayPlace: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeCity = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 100 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            city: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeDistrict = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 30 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            district: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeAddress = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 30 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            address: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeCountry = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          country: value,
          residentFlag: value.value === "UZ " ? "Y" : "N",
        }),
      );
    },
    [setInitialValues],
  );

  const onChangePhoneInput = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          phoneInput: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeMobilePhone = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 60 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            mobilePhoneNumber: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeServicePhone = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 60 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            servicePhone: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeHomePhone = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 60 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            homePhone: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangePNFL = useCallback(
    (value) => {
      const checked = /[А-Яа-я"$%№=]/g.test(value);
      if (value.length <= 14 && !checked) {
        setInitialValues((prev) =>
          update(prev, {
            pinfl: value.toUpperCase(),
          }),
        );
      }
    },
    [setInitialValues],
  );

  const validationSchema = object({
    passportSeries: string().required("Required"),
    passportNumber: string().required("Required"),
    passportIssuedBy: string().required("Required"),
    passportRegistrationDate: string(),
    passportExpiryDate: string(),
    firstName: string().required("Required"),
    surname: string().required("Required"),
    patronymic: string().required("Required"),
    birthDay: string(),
    birthdayPlace: string().required("Required"),
    country: string().required("Required"),
    city: string().required("Required"),
    district: string().required("Required"),
    address: string().required("Required"),
    mobilePhoneNumber: string(),
    servicePhone: string(),
    homePhone: string(),
    phoneInput: string(),
    pinfl: string(),
  });
  const viewMode = useShallowEqualSelector(viewModeSelector);
  return (
    <>
      {!clearing ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize={!disabled}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form className="mt-5" onSubmit={handleSubmit}>
              <PageTitle
                className="mb-5"
                title="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_EMPLOYEE_REGISTRATION_FORM_TITLE"
              />
              <div className="bg-white border br-1 border-gray-light">
                <div className="p-7 border-gray-light border-bottom">
                  <div className="row pb-3">
                    <div className="col-lg-4">
                      <label>
                        {translate(
                          "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_FIELD_LABEL_TITLE",
                        )}
                      </label>
                      <div className="input-group-append">
                        <div className="col-3" style={{ paddingLeft: "0" }}>
                          <InputField
                            className="flex-1"
                            name="passportSeries"
                            type="text"
                            inputClassName="text-uppercase"
                            onChange={(value) => onChangePassportSeries(value?.target?.value)}
                            disabled={disabled}
                            value={initialValues?.passportSeries}
                          />
                        </div>
                        <InputField
                          className="flex-1"
                          name="passportNumber"
                          type="text"
                          inputClassName="amount"
                          onChange={(value) => onChangePassportNumber(value?.target?.value)}
                          disabled={disabled}
                          value={initialValues?.passportNumber}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mt-1">
                      <InputField
                        name="passportIssuedBy"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_ISSUE_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangePassportIssue(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.passportIssuedBy}
                        maxLength={50}
                      />
                    </div>
                    {/* <div className="col-lg-4 mt-1">
                      <InputField
                        name="phoneNumber"
                        label="SALARY_PHONE_NUMBER_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangePhoneNumber(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.phoneNumber}
                      />
                    </div> */}
                    <div className="col-lg-4">
                      <DatePickerField
                        name="passportRegistrationDate"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE"
                        autoComplete="off"
                        onChange={(value) =>
                          setInitialValues((prev) =>
                            update(prev, { passportRegistrationDate: value }),
                          )
                        }
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-4">
                      <DatePickerField
                        name="passportExpiryDate"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_VALIDITY_FIELD_LABEL_TITLE"
                        autoComplete="off"
                        onChange={(value) =>
                          setInitialValues((prev) => update(prev, { passportExpiryDate: value }))
                        }
                        disabled={disabled}
                      />
                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*  <InputField*/}
                    {/*    name="password"*/}
                    {/*    label="SALARY_PASSWORD_PHONE_TITLE"*/}
                    {/*    onChange={(value) => onChangePassword(value.target.value)}*/}
                    {/*    disabled={disabled}*/}
                    {/*    value={initialValues?.password}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="d-flex col-lg-4 col-lg-2 flex-column">
                      <div className="d-flex">
                        <div className="pr-5">{translate("SALARY_PHONE_TYPE")}</div>
                        <RadioGroupField
                          name="phoneInput"
                          items={[
                            {
                              value: "01",
                              title:
                                "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MOBILE_PHONE_RADIO_BUTTON_LABEL_TITLE",
                            },
                            {
                              value: "02",
                              title:
                                "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SERVICE_PHONE_RADIO_BUTTON_LABEL_TITLE",
                            },
                            {
                              value: "03",
                              title:
                                "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HOME_PHONE_RADIO_BUTTON_LABEL_TITLE",
                            },
                          ]}
                          disabled={disabled}
                          onChanges={(value) => onChangePhoneInput(value)}
                        />
                      </div>
                      <div>
                        {initialValues.phoneInput === "01" && (
                          <InputField
                            className="mt-1"
                            name="mobilePhoneNumber"
                            type="text"
                            inputClassName="text-uppercase"
                            disabled={disabled}
                            maxLength={50}
                            value={initialValues?.mobilePhoneNumber}
                            onChange={(value) => onChangeMobilePhone(value?.target?.value)}
                          />
                        )}
                        {initialValues.phoneInput === "02" && (
                          <InputField
                            className="mt-1"
                            name="servicePhone"
                            type="text"
                            inputClassName="text-uppercase"
                            disabled={disabled}
                            maxLength={50}
                            value={initialValues?.servicePhone}
                            onChange={(value) => onChangeServicePhone(value?.target?.value)}
                          />
                        )}
                        {initialValues.phoneInput === "03" && (
                          <InputField
                            className="mt-1"
                            name="homePhone"
                            type="text"
                            inputClassName="text-uppercase"
                            disabled={disabled}
                            maxLength={50}
                            value={initialValues?.homePhone}
                            onChange={(value) => onChangeHomePhone(value?.target?.value)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-7 border-gray-light border-bottom">
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <InputField
                        name="firstName"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FIRST_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeFirstName(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.firstName}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputField
                        name="surname"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_LAST_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeSurname(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.surname}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputField
                        name="patronymic"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FATHER_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangePatronymic(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.patronymic}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <DatePickerField
                        name="birthDay"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_BIRTH_FIELD_LABEL_TITLE"
                        autoComplete="No"
                        onChange={(value) =>
                          setInitialValues((prev) => update(prev, { birthDay: value }))
                        }
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputField
                        name="birthdayPlace"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_BIRTH_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeBirthdayPlace(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.birthdayPlace}
                      />
                    </div>
                    <div className="col-lg-4">
                      {/* COUNTRY */}
                      <SelectPickerField
                        name="country"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CITIZENSHIP_FIELD_LABEL_TITLE"
                        options={citizenshipList}
                        onChanges={(value) => onChangeCountry(value)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center pt-6 col-lg-4 col-lg-2">
                      <div className="pr-5">
                        {translate(
                          "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_GENDER_RADIO_BUTTON_LABEL_TITLE",
                        )}
                      </div>
                      <RadioGroupField
                        name="gender"
                        items={[
                          {
                            value: "01",
                            title:
                              "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MALE_RADIO_BUTTON_LABEL_TITLE",
                          },
                          {
                            value: "02",
                            title:
                              "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FEMALE_RADIO_BUTTON_LABEL_TITLE",
                          },
                        ]}
                        disabled={disabled}
                        onChanges={(value) =>
                          !disabled && setInitialValues((prev) => update(prev, { gender: value }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center pt-6 col-lg-4 col-lg-2">
                      <div className="pr-5">
                        {translate(
                          "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_LABEL_TITLE",
                        )}
                      </div>
                      <CheckboxField
                        name="uzCard"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_UZCARD_RADIO_BUTTON_LABEL_TITLE"
                        onChange={() =>
                          setInitialValues((prev) =>
                            update(prev, { uzCard: !initialValues.uzCard }),
                          )
                        }
                        disabled={disabled}
                      />
                      <CheckboxField
                        name="humo"
                        className="ml-2"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HUMO_RADIO_BUTTON_LABEL_TITLE"
                        onChange={() =>
                          setInitialValues((prev) => update(prev, { humo: !initialValues.humo }))
                        }
                        disabled={disabled}
                      />
                    </div>
                    <div className="d-flex align-items-center pt-6 col-lg-4 col-lg-2">
                      <div className="pr-5">
                        {translate(
                          "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_RESIDENT_LABEL_TITLE",
                        )}
                      </div>
                      <RadioGroupField
                        name="residentFlag"
                        items={[
                          {
                            value: "Y",
                            title:
                              "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_YES_RADIO_BUTTON_LABEL_TITLE",
                          },
                          {
                            value: "N",
                            title:
                              "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_NO_RADIO_BUTTON_LABEL_TITLE",
                          },
                        ]}
                        disabled={true}
                      />
                    </div>
                    {initialValues.residentFlag === "N" && (
                      <div className="col-lg-4 mt-3">
                        <InputField
                          name="pinfl"
                          label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_PINFL_FIELD_LABEL_TITLE"
                          type="text"
                          inputClassName="text-uppercase"
                          onChange={(value) => onChangePNFL(value.target.value)}
                          disabled={disabled}
                          value={initialValues?.pinfl}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-7 border-gray-light border-bottom">
                  <div className="row">
                    <div className="col-lg-4">
                      <InputField
                        name="city"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_CITY_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeCity(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.city}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputField
                        name="district"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_AREA_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeDistrict(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.district}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputField
                        name="address"
                        label="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_STREET_FIELD_LABEL_TITLE"
                        type="text"
                        inputClassName="text-uppercase"
                        onChange={(value) => onChangeAddress(value.target.value)}
                        disabled={disabled}
                        value={initialValues?.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex p-7 flex-lg-row flex-column">
                  <div className="pr-5">
                    {viewMode === ViewMode.Full ? (
                      <Button
                        type="submit"
                        className="px-7"
                        size={SizeType.Medium}
                        color={ButtonColor.Orange}
                        labelCode="SALARY_CREATE_AND_SAVE_TO_PDF"
                        disabled={disabled}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                  <div>
                    <Button
                      type="button"
                      onClick={clearInitialValues}
                      className="px-7 mt-lg-0 mt-2"
                      size={SizeType.Medium}
                      color={ButtonColor.Danger}
                      labelCode="TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CLEAR_FORM_BUTTON_TITLE"
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </>
  );
}
