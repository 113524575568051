import "./assets/mobile-menu.scss";
import React from "react";
import CorporateMobileMenu from "./CorporateMobileMenu";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { useRole } from "../helpers/RoleContext";
import { RoleType } from "../api/dto/AppDTO";
import MobileMenuLayout from "./MobileMenuLayout";
import IndividualMobileMenu from "./IndividualMobileMenu";

export default function MobileMenuWrapper() {
  const _role = useShallowEqualSelector(useRole);

  return (
    <MobileMenuLayout>
      {Boolean(_role.role === RoleType.Corporate && _role) && <CorporateMobileMenu />}
      {Boolean(_role.role === RoleType.Individual && _role) && <IndividualMobileMenu />}
    </MobileMenuLayout>
  );
}
