import { update } from "immupdate";
import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { SelectPicker, SelectPickerProps } from "../ui/SelectPicker";
import { PayrollStatus } from "../../api/salary/SalaryDTO";

const STATUSES = [
  { label: "SELECT_PICKER_PLACEHOLDER_TITLE", value: "" },
  { label: "PAYROLL_STATUS_CREATED", value: PayrollStatus.Created },
  {
    label: "PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1",
    value: PayrollStatus.AuthorizedByAuthorizer1,
  },
  {
    label: "PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2",
    value: PayrollStatus.AuthorizedByAuthorizer2,
  },
  {
    label: "PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3",
    value: PayrollStatus.AuthorizedByAuthorizer,
  },
  { label: "PAYROLL_STATUS_SENT_TO_CARD_SYSTEM", value: PayrollStatus.SentToCardSystem },
  { label: "PAYROLL_STATUS_REJECTED", value: PayrollStatus.Rejected },
  { label: "PAYROLL_STATUS_REJECTED_BY_CB", value: PayrollStatus.RejectedByCb },
  { label: "PAYROLL_STATUS_COMPLETED", value: PayrollStatus.Completed },
];

export type PayrollStatusPickerProps = Omit<SelectPickerProps, "options">;

export function PayrollStatusPicker(props: PayrollStatusPickerProps) {
  const { translate } = useI18n();

  const list = useMemo(
    () => STATUSES.map((status) => update(status, { label: translate(status.label) })),
    [translate],
  );
  return <SelectPicker options={list} {...props} />;
}
