export interface CorrespondentProps {
  readonly mfo: string;
  readonly name: string;
  readonly account: string;
  readonly taxNumber: string;
  readonly employeeId: string;
  readonly bankName: string;
}

export interface CorrespondentFCYProps {
  readonly beneficiaryAccount: string;
  readonly beneficiaryAddress: string;
  readonly beneficiaryBankAddress: string;
  readonly beneficiaryBankName: string;
  readonly beneficiaryName: string;
  readonly beneficiarySwiftCode: string;
  readonly beneficiaryTaxId: string;
  readonly corrAccount: string;
  readonly correspondentBankName1: string;
  readonly correspondentBankName2: string;
  readonly correspondentSwiftCode1: string;
  readonly correspondentSwiftCode2: string;
  readonly kpp: string;
}

export interface NacCodeFCYProps {
  readonly naceName: string;
  readonly naceCode: string;
}

export interface LcyTransferFormProps {
  readonly amount: string;
  readonly accountState: string;
  readonly amountDetails: string;
  readonly senderName: string;
  readonly operationDate: string;
  readonly currentBalance: string;
  readonly senderBankName: string;
  readonly senderBankCode: string;
  readonly senderTaxId: string;
  readonly correspondentBankName: string;
  readonly clientAccount: any;
  readonly clientName?: string;
  readonly correspondentAccount: string;
  readonly correspondentBank: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly detailsOfPayment: string;
  readonly documentDate: Date;
  readonly documentNumber: string;
  readonly paymentCode: string;
  readonly paymentType: string;
  readonly budgetFlag: string;
  readonly transactionDate?: string;
  readonly budgetAccount?: string;
  readonly budgetAccountName?: string;
  readonly budgetTaxNumber?: string;
  readonly state?: string;
  readonly firstAuthorizerOtpKey?: string;
  readonly secondAuthorizerOtpKey?: string;
  readonly thirdAuthorizerOtpKey?: string;
  readonly purposeCode?: string;
  readonly fromExcelDocument?: string;
}

export interface LcyTransferFormPropsExcel {
  readonly amount: string;
  readonly accountState: string;
  readonly amountDetails: string;
  readonly senderName: string;
  readonly currentBalance: string;
  readonly senderBankName: string;
  readonly senderBankCode: string;
  readonly senderTaxId: string;
  readonly correspondentBankName: string;
  readonly clientAccount: string;
  readonly correspondentAccount: string;
  readonly correspondentBank: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly detailsOfPayment: string;
  readonly documentDate: string;
  readonly documentNumber: string;
  readonly paymentCode: string;
  readonly paymentType: string;
  readonly budgetFlag: string;
}

export interface LcyTransferBudgetFormProps {
  readonly amount: string;
  readonly budgetAccount: string;
  readonly budgetAccountName: string;
  readonly budgetFlag: string;
  readonly budgetTaxNumber: string;
  readonly accountState: string;
  readonly amountDetails: string;
  readonly senderName: string;
  readonly operationDate: string;
  readonly currentBalance: string;
  readonly senderBankName: string;
  readonly senderBankCode: string;
  readonly senderTaxId: string;
  readonly correspondentBankName: string;
  readonly clientAccount: string;
  readonly correspondentAccount: string;
  readonly correspondentBank: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly detailsOfPayment: string;
  readonly documentDate: Date;
  readonly documentNumber: string;
  readonly paymentCode: string;
  readonly transactionDate: string;
  readonly paymentType: string;
  readonly firstAuthorizerOtpKey?: string;
  readonly secondAuthorizerOtpKey?: string;
  readonly thirdAuthorizerOtpKey?: string;
}

export interface LcyTransferBudgetRevenueFormProps {
  readonly amount: string;
  readonly budgetAccount: string;
  readonly budgetFlag: string;
  readonly budgetAccountName: string;
  readonly budgetTaxNumber: string;
  readonly accountState: string;
  readonly amountDetails: string;
  readonly senderName: string;
  readonly currentBalance: string;
  readonly senderBankName: string;
  readonly senderBankCode: string;
  readonly senderTaxId: string;
  readonly correspondentBankName: string;
  readonly operationDate: string;
  readonly clientAccount: string;
  readonly correspondentAccount: string;
  readonly correspondentBank: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly detailsOfPayment: string;
  readonly documentDate: Date;
  readonly documentNumber: string;
  readonly paymentCode: string;
  readonly paymentType: string;
  readonly transactionDate: string;
  readonly firstAuthorizerOtpKey?: string;
  readonly secondAuthorizerOtpKey?: string;
  readonly thirdAuthorizerOtpKey?: string;
}

export interface LcyTransferMunisFormProps {
  readonly amount: string;
  readonly budgetAccount: string;
  readonly budgetAccountName: string;
  readonly budgetFlag: string;
  readonly budgetTaxNumber: string;
  readonly accountState: string;
  readonly operationDate: string;
  readonly amountDetails: string;
  readonly senderName: string;
  readonly currentBalance: string;
  readonly senderBankName: string;
  readonly senderBankCode: string;
  readonly senderTaxId: string;
  readonly amountForPayment: string;
  readonly correspondentBankName: string;
  readonly clientAccount: string;
  readonly correspondentAccount: string;
  readonly correspondentBank: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly detailsOfPayment: string;
  readonly documentDate: Date;
  readonly documentNumber: string;
  readonly paymentCode: string;
  readonly paymentType: string;
  readonly settlementCode: string;
  readonly supplierCode: string;
  readonly invoiceNumber: string;
  readonly transactionDate: string;
}

export interface GetTransferListForApprovalProps {
  readonly amount: string;
  readonly approvalUser: string;
  readonly branch: string;
  readonly budgetAccount: string;
  readonly budgetFlag: string;
  readonly budgetName: string;
  readonly budgetTaxNumber: string;
  readonly clientAccount: string;
  readonly clientName: string;
  readonly correspondentAccount: string;
  readonly correspondentName: string;
  readonly correspondentTaxNumber: string;
  readonly createUser: string;
  readonly documentDate: string;
  readonly documentNumber: string;
  readonly documentType: string;
  readonly id: string;
  readonly operationDate: string;
  readonly paymentCode: string;
  readonly paymentDetail: string;
  readonly purpose: string;
  readonly reasonRejectFromBank: string;
  readonly reasonRejectFromCBU: string;
  readonly receiverMfo: string;
  readonly senderMfo: string;
  readonly state: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
}

export interface FcyTransferForPending {
  readonly amount: string;
  readonly amountInWord: string;
  readonly applicantAccount: string;
  readonly applicantName: string;
  readonly applicantAddress: string;
  readonly approvalUser: string;
  readonly bankName: string;
  readonly bankAddress: string;
  readonly bankSwift: string;
  readonly beneficiaryAccount: string;
  readonly beneficiaryAddress: string;
  readonly beneficiaryBankAddress: string;
  readonly beneficiaryBankName: string;
  readonly beneficiaryName: string;
  readonly beneficiarySwiftCode: string;
  readonly beneficiaryTaxId: string;
  readonly chargesForTransfer: string;
  readonly commissionAccount: string;
  readonly correspondentAccount: string;
  readonly correspondentBankName1: string;
  readonly correspondentBankName2: string;
  readonly correspondentSwiftCode1: string;
  readonly correspondentSwiftCode2: string;
  readonly createDate: string;
  readonly createUser: string;
  readonly currency: string;
  readonly corrAccount: string;
  readonly detailsOfPayment: string;
  readonly documentId: string;
  readonly kpp: string;
  readonly remarks: string;
  readonly state: string;
  readonly totalPage: string;
  readonly totalRow: string;
  readonly taxId: string;
  readonly transactionDate: string;
  readonly transferNumber: string;
  readonly id: string;
  readonly applicantTaxId: string;
  readonly details: string;
  readonly currencyCode?: string;
  readonly applicantPhone?: string;
  readonly rejectReason?: string;
}

export interface FcyFavouritesListProps {
  readonly amount: string;
  readonly amountInWord: string;
  readonly applicantAccount: string;
  readonly applicantName: string;
  readonly beneficiaryAccount: string;
  readonly beneficiaryAddress: string;
  readonly beneficiaryBankAddress: string;
  readonly beneficiaryBankName: string;
  readonly beneficiaryName: string;
  readonly beneficiarySwiftCode: string;
  readonly beneficiaryTaxId: string;
  readonly charges: string;
  readonly commissionAccount: string;
  readonly corrAccount: string;
  readonly correspondentBankName1: string;
  readonly correspondentBankName2: string;
  readonly correspondentSwiftCode1: string;
  readonly correspondentSwiftCode2: string;
  readonly currency: string;
  readonly details: string;
  readonly employerId?: string;
  readonly id: string;
  readonly kpp: string;
  readonly remarks: string;
  readonly transactionDate: string;
  readonly transferNumber: string;
}

export interface FcyToCreate {
  readonly phoneOperator?: string;
  readonly amount: string;
  readonly amountInWord: string;
  readonly applicantAccount: string | any;
  readonly applicantName: string;
  readonly applicantAddress: string;
  readonly bankName: string;
  readonly bankAddress: string;
  readonly bankSwift: string;
  readonly applicantTaxId: string;
  readonly applicantBalance: string;
  readonly beneficiaryAccount: string;
  readonly beneficiaryAddress: string;
  readonly beneficiaryBankAddress: string;
  readonly beneficiaryBankName: string;
  readonly beneficiaryName: string;
  readonly beneficiarySwiftCode: string;
  readonly beneficiaryTaxId: string;
  readonly charges: string;
  readonly commissionAccount: string;
  readonly commissionBalance: string;
  readonly corrAccount: string;
  readonly voCode: string;
  readonly correspondentBankName1: string;
  readonly correspondentBankName2: string;
  readonly correspondentSwiftCode1: string;
  readonly correspondentSwiftCode2: string;
  readonly currency: any;
  readonly details: string;
  readonly employerId?: string;
  readonly kpp: string;
  readonly id?: string;
  readonly remarks: string;
  readonly transactionDate: Date;
  readonly transferNumber: string;
  readonly applicantPhone?: string;
  readonly passportNumber?: string;
  readonly favoriteName?: string;
  readonly servicesType?: string;
  readonly senderPhoneNumber?: string | any;
  readonly hsCode?: string | any;
  readonly naceCode?: string;
  readonly invNumber?: string;
  readonly invnDate?: Date;
  readonly countryPhoneCode?: string | any;
  readonly tinOfBank: string;
  readonly tinOfCustomer: string;
  readonly contractNumber: string;
  readonly isIdn: boolean;
}

export interface SearchCorrespondentsQueryProps {
  readonly taxAccountNumber?: string;
  readonly parameter?: string;
}

export interface TransferListForApprovalProps {
  readonly pageNumber: number;
  readonly pageSize: number;
}
export interface TransferListLCYDocProps {
  readonly pageNumber?: number;
  readonly pageSize?: number;
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly state?: string;
}

export interface RegisterCheckQuery {
  readonly accountName: string;
  readonly accountNumber: string;
  readonly transferType: TransferType;
  readonly transitAccount: string;
}

export interface RegisterCheckProps {
  readonly accountName: string;
  readonly accountNumber: string;
  readonly checkFlag: string;
  readonly amount?: string;
  readonly cardStatus: string;
}

export interface SwiftCodeProps {
  readonly swiftAddress: string;
  readonly swiftCode: string;
  readonly swiftName: string;
}

export interface TransferHistoryQuery {
  readonly accountNumber?: string;
  readonly amount?: string;
  readonly currency?: string;
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly pageNumber?: number;
  readonly pageSize?: number;
  readonly receiverAccount?: string;
  readonly status: string;
}

export interface SenderAccountForFCYProps {
  readonly applicantAddress: string;
  readonly applicantName: string;
  readonly bankAddress: string;
  readonly bankName: string;
  readonly bankSwift: string;
  readonly currentBalance: string;
  readonly debitAccount: string;
  readonly taxId: string;
  readonly passportNumber: string;
  readonly label?: string;
  readonly value?: string;
}

export interface CommissionAccountProps {
  readonly account: string;
  readonly balance: string;
}

export interface TransferHistoryProps {
  readonly balance: string;
  clientName: string;
  readonly creditAmount: string;
  readonly currency: string;
  readonly debitAmount: string;
  readonly documentNumber: string;
  readonly documentType: string;
  readonly paymentCode: string;
  readonly paymentDetails: string;
  readonly receiverAccount: string;
  receiverMfo: string;
  readonly receiverName: string;
  readonly receiverTaxNumber: string;
  readonly senderAccount: string;
  senderMfo: string;
  readonly status: string;
  readonly totalPage: string;
  readonly totalRow: string;
  readonly transactionDate: string;
  readonly transactionDateTime: string;
  readonly transactionId: string;
  documentDate: string;
  documentId: string;
  totalPageCount: string;
  totalRowCount: string;
  clientAccount: any;
  clientTaxNumber?: string;
  clientBankName: string;
  amount: string;
  amountDetails: string;
  correspondentName: string;
  correspondentAccount: string;
  correspondentTaxNumber?: string;
  senderTaxNumber?: string;
  correspondentBankName: string;
  paymentDetail: string;
  id: string;
  senderBankName: string;
  receiverBankName: string;
  clName: string;
  rcName: string;
  clMfo: string;
  rcMfo: string;
  currencyCode: string;
}

export interface ReceiverInformationQueryProps {
  readonly debitAccount?: string;
  readonly payeeGroup?: string;
  readonly supplierCode?: string;
  readonly invoiceNumber?: string;
  readonly settlementCode?: string;
}

export interface ReceiverInformationProps {
  readonly name: string;
  readonly account: string;
  readonly branch: string;
  readonly taxNumber: string;
  readonly errorMessage: string;
  readonly amount: string;
}

export interface NextTransferNumberProps {
  readonly nextTransferNumber: string;
}

export interface SenderAccountProps {
  readonly accountName: string;
  readonly accountCode: string;
  readonly accountBranch: string;
  readonly accountState: string;
  readonly balance: string;
  readonly bankName: string;
}

export interface AllLCYDocProps {
  readonly amount: string;
  readonly approvalUser: string;
  readonly branch: string;
  readonly budgetAccount: string | null;
  readonly budgetFlag: string;
  readonly budgetName: string;
  readonly budgetTaxNumber: string;
  readonly clientTaxNumber: string;
  readonly clientBankName: string;
  readonly clientAccount: string;
  readonly clientName: string;
  readonly correspondentAccount: string;
  readonly correspondentName: string;
  readonly correspondentBankName: string;
  readonly correspondentTaxNumber: string;
  readonly createUser: string;
  readonly documentDate: string;
  readonly documentNumber: string;
  readonly documentType: string;
  readonly id: number;
  readonly operationDate: string;
  readonly paymentCode: string;
  readonly paymentDetail: string;
  readonly purpose: string;
  readonly reasonRejectFromBank: string;
  readonly reasonRejectFromCbu: string;
  readonly receiverMfo: string;
  readonly senderMfo: string;
  readonly state: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly senderAccount: string;
  readonly senderBankCode: string;
  readonly senderBankName: string;
  readonly senderName: string;
  readonly senderTaxId: string;
  readonly correspondentTaxId: string;
  readonly correspondentBank: string;
  readonly paymentDetails: string;
  readonly amountDetails: string;
  readonly senderMFO: string;
  readonly correspondentMFO: string;
  readonly budgetTaxId: string;
  readonly receiverMFO: string;
  readonly currencyCode: string;
  readonly detailsOfPayment: string;
  readonly transactionDate: string;
  readonly firstAuthorizerOtpKey?: string;
  readonly secondAuthorizerOtpKey?: string;
  readonly thirdAuthorizerOtpKey?: string;
}

export interface payeeGroupProps {
  readonly groupId: string;
  readonly groupName: string;
}

export interface supplierCodeProps {
  readonly supplierCode: string;
  readonly supplierName: string;
}

export interface settlementCodeProps {
  readonly settlementCode: string;
  readonly settlementName: string;
}

export enum TransferType {
  Salary = "SL",
  CorporateCard = "CC",
}

export enum TransferHistoryStatus {
  All = "A",
  Outgoing = "D",
  Incoming = "C",
}
