import React, { useCallback, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Form, Formik } from "formik";

import { TabPage } from "../tabs/TabPage";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import { AccountsFilter, NewAccountOpeningTabType } from "../../filters/AccountsFilter";
import { NewAccountTableWrapper } from "./NewAccountTableWrapper";
import { NewAccountOpeningForm } from "./NewAccountOpeningForm";
import { Checkbox } from "../ui/Checkbox";
import { Paginator } from "../ui/Paginator";
import { DatePickerField } from "../form/DatePickerField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { showError } from "../../utils/NotificationUtils";
import { useI18n } from "../../i18n/I18nContext";
import { useAccountContext } from "../../api/account/AccountContext";

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeTab: (tab: NewAccountOpeningTabType) => void;
  readonly onChangeFilter: (value) => void;
}

export function NewAccountOpeningTab({ filter, onChangeTab, onChangeFilter }: Props) {
  const { translate } = useI18n();
  const [loading, setLoading] = useState(false);
  const { AccountApi } = useAccountContext();
  const newAccType = useMemo(() => filter.getNewAccOpeningType(), [filter]);

  const [withCreatorsAndApprovals, setWithCreatorsAndApprovals] = useState(false);

  const checkWithCreatorsAndApprovals = useCallback(
    () => setWithCreatorsAndApprovals(!withCreatorsAndApprovals),
    [setWithCreatorsAndApprovals, withCreatorsAndApprovals],
  );

  const onSubmit = useCallback(
    (value) => {
      setLoading(true);
      const json = {
        accountCode: value.accountType.value,
        currencyCode: value.currency.value,
        branchCode: value.nameOfTheBank.value,
      };
      AccountApi.createNewAccount(json)
        .then(() => {
          NotificationManager.info(
            translate("ACCOUNT_REQUEST_SENT"),
            translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE"),
            5000,
          );
          setLoading(false);
          onChangeTab(NewAccountOpeningTabType.NewAccOpening);
        })
        .catch((r) => {
          showError(r);
          setLoading(false);
        });
    },
    [AccountApi, translate, onChangeTab],
  );

  return (
    <TabPage
      contentClassName="bg-transparent border-0"
      headerClassName="d-flex justify-content-center align-items-lg-end"
      headerComponent={
        newAccType === NewAccountOpeningTabType.NewAccOpening ? (
          <div className="d-flex flex-lg-row flex-column mt-lg-0 mt-3">
            <Formik onSubmit={onChangeFilter} initialValues={filter.getInquiryFilterForm()}>
              {({ handleSubmit, values }) => (
                <Form>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <DatePickerField
                        width={100}
                        name="fromDate"
                        className="pr-5"
                        size={SizeType.Small}
                        selectsStart={true}
                        labelPosition={PositionType.Left}
                        label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                        onChange={() => handleSubmit()}
                        maxDate={values.toDate}
                      />
                      <DatePickerField
                        width={100}
                        name="toDate"
                        className="pr-5"
                        size={SizeType.Small}
                        selectsEnd={true}
                        labelPosition={PositionType.Left}
                        label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                        onChange={() => handleSubmit()}
                        minDate={values.fromDate}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="d-flex flex-grow-1  align-items-center justify-content-lg-end my-lg-0 my-3 mr-7">
              <Checkbox
                value={withCreatorsAndApprovals}
                onClick={checkWithCreatorsAndApprovals}
                label="PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE"
              />
            </div>
          </div>
        ) : (
          <div />
        )
      }
      footerComponent={
        newAccType === NewAccountOpeningTabType.NewAccOpening ? (
          <div className="justify-content-end d-flex flex-shrink-1 flex-grow-1 align-items-center">
            <Paginator filter={filter} />
          </div>
        ) : (
          <div />
        )
      }
      className={"px-4"}
    >
      <SubTabs
        activeTab={newAccType}
        onChangeTab={onChangeTab}
        defaultTab={NewAccountOpeningTabType.NewAccOpening}
      >
        <TabPane
          key={NewAccountOpeningTabType.NewAccOpening}
          tab="ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE"
        >
          <NewAccountTableWrapper
            filter={filter}
            withCreatorsAndApprovals={withCreatorsAndApprovals}
          />
        </TabPane>

        <TabPane
          key={NewAccountOpeningTabType.RequestForOpening}
          tab="ACCOUNTS_REQUEST_NEW_ACCOUNT_OPENING_TAB_TITLE"
        >
          <NewAccountOpeningForm onSubmit={onSubmit} loading={loading} />
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
