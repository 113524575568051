import { BaseApi } from "../BaseApi";
import {
  CorrespondentProps,
  LcyTransferFormProps,
  ReceiverInformationProps,
  ReceiverInformationQueryProps,
  SearchCorrespondentsQueryProps,
  TransferListForApprovalProps,
  GetTransferListForApprovalProps,
  AllLCYDocProps,
  TransferHistoryQuery,
  TransferHistoryProps,
  NextTransferNumberProps,
  payeeGroupProps,
  supplierCodeProps,
  settlementCodeProps,
  FcyTransferForPending,
  SenderAccountForFCYProps,
  CommissionAccountProps,
  TransferListLCYDocProps,
  RegisterCheckProps,
  CorrespondentFCYProps,
  SwiftCodeProps,
  NacCodeFCYProps,
} from "./TransferDTO";

export class TransferApi extends BaseApi {
  public correspondentList(): Promise<CorrespondentProps[]> {
    return this.get("correspondent/:employeeId", { params: { employeeId: this.employeeId } });
  }

  public searchCorrespondent(query: SearchCorrespondentsQueryProps): Promise<CorrespondentProps[]> {
    return this.get("correspondent/:employeeID/filter", {
      query,
      params: { employeeID: this.employeeId },
    });
  }

  public searchCorrespondentOnlyText(
    query: SearchCorrespondentsQueryProps,
  ): Promise<CorrespondentProps[]> {
    return this.get("correspondent/:employeeID/filter", {
      query,
      params: { employeeID: this.employeeId },
    });
  }

  public getTransferListForApproval(
    query: TransferListForApprovalProps,
  ): Promise<GetTransferListForApprovalProps[]> {
    return this.get("payment/documents/approval", {
      query,
    });
  }

  public getFcyTransferListForApproval(
    query: TransferListForApprovalProps,
  ): Promise<FcyTransferForPending[]> {
    return this.get("fcy/approval", {
      query: { employeeId: this.employeeId, ...query },
    });
  }

  public getSenderAccountsForFCY(): Promise<SenderAccountForFCYProps[]> {
    return this.get("fcy/sender", { query: { employeeId: this.employeeId } });
  }

  public getCommissionsAccountsForFCY(): Promise<CommissionAccountProps[]> {
    return this.get("fcy/account", { query: { employeeId: this.employeeId } });
  }

  public getCorrespondentAccountsForFCY(): Promise<CorrespondentFCYProps[]> {
    return this.get("fcy/correspondent", { query: { employeeId: this.employeeId } });
  }

  public getNacCodesForFCY(naceCode: string): Promise<NacCodeFCYProps[]> {
    return this.get("fcy/naceCodes", {
      query: { employeeId: this.employeeId, naceCode: naceCode, naceLang: this.language },
    });
  }

  public createLcyTransfer(values: LcyTransferFormProps): Promise<boolean> {
    return this.post("lcy/create", { json: values });
  }

  public createLcyTransferExcelFile(value: FormData): Promise<any> {
    return this.post("excel-import", { body: value });
  }

  public createFcyTransfer(values: LcyTransferFormProps): Promise<boolean> {
    return this.post("fcy", { params: { employeeId: this.employeeId }, json: values });
  }

  public updateLcyTransfer(docID: string, values: LcyTransferFormProps): Promise<any> {
    return this.put("lcy/modify/:employeeId/:docID", {
      json: values,
      params: { employeeId: this.employeeId, docID },
    });
  }

  public updateFcyTransfer(docID: string, values: LcyTransferFormProps): Promise<any> {
    return this.put("fcy", {
      json: values,
      params: { employeeId: this.employeeId },
      query: { documentId: docID },
    });
  }

  public getReceiverInformation(
    query: ReceiverInformationQueryProps,
  ): Promise<ReceiverInformationProps[]> {
    return this.get("receiver/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getNextTransferNumber(): Promise<NextTransferNumberProps[]> {
    return this.get("transfer/:employeeId", {
      params: { employeeId: this.employeeId },
    });
  }

  public getAllLCYDocuments(query: TransferListLCYDocProps): Promise<AllLCYDocProps[]> {
    return this.get("payment/documents/list", {
      query,
    });
  }

  public getAllFCYDocuments(query: TransferListLCYDocProps): Promise<FcyTransferForPending[]> {
    return this.get("fcy/document", {
      query: { employeeId: this.employeeId, ...query },
    });
  }

  public getTransferHistory(query: TransferHistoryQuery): Promise<TransferHistoryProps[]> {
    return this.get("transferHistory", {
      query: { employeeId: this.employeeId, ...query },
    });
  }

  public getTransferIndividualHistory(query): Promise<TransferHistoryProps[]> {
    return this.get("pdf-report/transfer/get_history", {
      query: { employeeId: this.employeeId, ...query },
    });
  }

  public getPayeeGroup(): Promise<payeeGroupProps[]> {
    return this.get("payment-type/group");
  }

  public getSupplierCode({ groupId }): Promise<supplierCodeProps[]> {
    return this.get("payment-type/supplier/:groupId", {
      params: { groupId },
    });
  }

  public getSettlementCode({ supplierCode }): Promise<settlementCodeProps[]> {
    return this.get("payment-type/settlement/:supplierCode", {
      params: { supplierCode },
    });
  }

  public createLcyFavorites({ json, login }): Promise<boolean> {
    return this.post("favorites/lcy", {
      json: { ...json, userId: login },
    });
  }

  public createFcyFavorites({ json, login }): Promise<boolean> {
    return this.post("favorites/fcy", {
      json: { ...json, userId: login, employerId: this.employeeId },
    });
  }

  public getLcyFavorites({ queryType, login }): Promise<any> {
    return this.get("favorites/lcy", { query: { queryType, userId: login } });
  }

  public getLcyFavoritesDetails({ id }): Promise<any> {
    return this.get("favorites/lcy/:id", { params: { id } });
  }

  public getFcyFavorites({ userId }): Promise<any> {
    return this.get("favorites/fcy/list", { query: { userId } });
  }

  public getFcyFavoritesDetails({ id }): Promise<any> {
    return this.get("favorites/fcy/:id", { params: { id } });
  }

  public checkRegister({ json }): Promise<RegisterCheckProps[]> {
    return this.post("salary/excel/list", { json });
  }

  public getPayroll({ transitAccount }): Promise<string> {
    return this.post("salary/:employerId", {
      params: { employerId: this.employeeId },
      query: { transitAccount },
    });
  }

  public createRegister({ payrollId, json }): Promise<any> {
    return this.post("salary/:employerId/:payrollId", {
      params: { employerId: this.employeeId, payrollId },
      json,
    });
  }

  public getFavorites(): Promise<any> {
    return this.get("favorites/fcy/list");
  }

  public getAllFavorites({ login }): Promise<any> {
    return this.get("favorites/list", { query: { userId: login } });
  }

  public getBankNameBySwift({ swiftCode }): Promise<SwiftCodeProps> {
    return this.get("fcy/swift", { query: { swiftCode } });
  }

  public getSwiftCode({ swiftCode }): Promise<SwiftCodeProps> {
    return this.get("fcy/swift-search", { query: { swiftCode } });
  }


  public deleteFavoriteDocument({ id, type }): Promise<any> {
    return this.delete("favorites/:id", {
      params: { id },
      query: { type },
    });
  }

  public deleteFavoriteIndividual({ id }): Promise<any> {
    return this.delete("favorites/individual-transfer/:id", {
      params: { id },
    });
  }

  public createPDFStandard({ filename, json }): Promise<any> {
    return this.post("pdf-report/standard/:filename", {
      params: { filename },
      json,
    });
  }

  public getCommissionAmount(json): Promise<any> {
    return this.post("transfer/calc-commission-amount", {
      json,
    });
  }

  public transferFromUzcard(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/uzcard-to-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferFromDemand(json): Promise<any> {
    return this.post("transfer/fromdeposit", {
      json,
    });
  }

  public transferFromVisa(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/visa-to-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferFromHumo(json): Promise<any> {
    return this.post("transfer/humo-to-demand-deposit", {
      json,
    });
  }

  public getIndividualFavorites(userName): Promise<any> {
    return this.get("favorites/individual-transfer/list", {
      query: { userId: userName },
    });
  }

  public saveFavoriteIndividual(json): Promise<any> {
    return this.post("favorites/individual-transfer", {
      json,
    });
  }

  public transferHumoToUzcard(json): Promise<any> {
    return this.post("transfer/transfer-between-uz-humo-card", {
      json,
    });
  }

  public transferBetweenVisa(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/visa-to-visa-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferDemandHumo(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/between-humo-and-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferDemandHumoViaSMS(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/demand-deposit-to-other-humo-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public getCorrInd(value): Promise<boolean> {
    return this.get("account/individual/corr-info", { query: { correspondentAccount: value } });
  }

  public transferFromDemandViaSMS(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/demand-deposit-to-uzcard-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferFromUzcardViaSMS(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/uzcard-to-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferFromHumoViaSMS(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/between-humo-and-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public transferHumoToUzcardViaSMS(json, messageId, smsCode): Promise<any> {
    return this.post("transfer/between-card-via-sms-verification", {
      query: { messageId, smsCode },
      json,
    });
  }

  public createDemandToDemand(values, messageId, smsCode): Promise<any> {
    return this.post("lcy/create-via-sms", {
      query: { messageId, smsCode },
      json: values,
    });
  }

  public transferToAnotherBank(json, messageId, smsCode, beginDay): Promise<any> {
    return this.post("transfer/uzcard-humo-to-another-demand-deposit-via-sms-verification", {
      query: { messageId, smsCode, beginDay },
      json,
    });
  }

  public getCountyPhoneCodes(): Promise<any> {
    return this.get("fcy/countryCodes", {
      query: { lang: this.language },
    });
  }

  public getCountyPhoneOperators(): Promise<any> {
    return this.get("fcy/operatorCodes");
  }

  public getCorporateCardPayrollExcel(lang: string): Promise<any> {
    return this.get("transfer-form/excel/corporate-card-payroll", {
      query: { lang },
    });
  }

  public getSalaryCardPayrollExcel(lang: string): Promise<any> {
    return this.get("transfer-form/excel/salary-card-payroll", {
      query: { lang },
    });
  }

  public getLCYTransferExcel(lang: string): Promise<any> {
    return this.get("transfer-form/excel/lcy-transfer", {
      query: { lang },
    });
  }

  public getFCYTransferExcel(lang: string): Promise<any> {
    return this.get("transfer-form/excel/fcy-transfer", {
      query: { lang },
    });
  }

  public getPaymentClaimExcel(lang: string): Promise<any> {
    return this.get("transfer-form/excel/payment-claim", {
      query: { lang },
    });
  }

  public getTransferIdnInfo(senderAccount: string): Promise<any> {
    return this.get("conversion/get_idn/:employeeId", {
      params: { employeeId: this.employeeId },
      query: { senderAccount },
    });
  }
  public getHumoCardInfo(cardNumber): Promise<any> {
    return this.post("transfer/humo-cardholder-information", {
      query: { cardNumber },
    });
  }

  public getUzcardCardInfo(cardNumber): Promise<any> {
    return this.post("transfer/uzcard-cardholder-information", {
      query: { cardNumber },
    });
  }

  public createTransferRequestUpay(json): Promise<any> {
    return this.post("upay/transfer_request", {
      json,
    });
  }

  public createTransferVerifyUpay(json): Promise<any> {
    return this.post("upay/transfer_verify", {
      json,
    });
  }
}
