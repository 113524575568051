import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { Button } from "../ui/Button";

interface Props {
  readonly data: any[];
  readonly loading: boolean;
  readonly setId: (value, docType, alert) => void;
}

export function FavoritesTable({ loading, data, setId }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 100,
        accessor: "docType",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_TYPE_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "favoriteName",
        Header: translate("TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL"),
      },
      {
        width: 180,
        accessor: "clientAccount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "correspondentName",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "correspondentAccount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "amount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "currency",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 300,
        accessor: "detailsOfPayment",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 95,
        accessor: "id",
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <Button
              className="bg-danger"
              onClick={() => {
                setId(row.value, row.row.values.docType, true);
              }}
            >
              {translate("DELETE_BUTTON")}
            </Button>
          );
        },
      },
    ];

    return list;
  }, [setId, translate]);

  return (
    <div>
      <Table data={data} columns={columns} loading={loading} />
    </div>
  );
}
