import { BaseApi } from "../BaseApi";
import {
  AnswerDiscrepancy,
  ApplicationProps,
  ApplicationAndAmendment,
  TradeFinanceDocumentProps,
  TradeFinanceFilterDiscrepancy,
  TradeFinanceFilterPageProps,
  TradeFinanceFilterProps,
  TradeFinanceFormProps,
  TradeFinanceDiscrepancy,
} from "./TradeFinanceDTO";

export class TradeFinanceApi extends BaseApi {
  public getApplication(query: TradeFinanceFilterProps): Promise<TradeFinanceDocumentProps[]> {
    return this.get("trade-finance/get-documents/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public getApprovalApplicationAmendment(
    query: TradeFinanceFilterPageProps,
  ): Promise<ApplicationProps[]> {
    return this.get("trade-finance/get-applications-amendments-approval/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public getApprovalDiscrepancy(
    query: TradeFinanceFilterPageProps,
  ): Promise<TradeFinanceDiscrepancy[]> {
    return this.get("trade-finance/discrepancy/get-discrepancy-approval/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public getApplicationDiscrepancy(
    query: TradeFinanceFilterDiscrepancy,
  ): Promise<TradeFinanceDiscrepancy[]> {
    return this.get("trade-finance/discrepancy/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public createDiscrepancyAnswer(query: AnswerDiscrepancy): Promise<boolean> {
    return this.post("trade-finance/create/discrepancy/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public getOneApplication(query: ApplicationAndAmendment): Promise<ApplicationProps[]> {
    return this.get("trade-finance/get-applications-amendments/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public getOneApplicationGet(query: ApplicationAndAmendment): Promise<ApplicationProps[]> {
    return this.get("trade-finance/get-application/:employeeID", {
      params: { employeeID: this.employeeId },
      query,
    });
  }

  public createApplication(body: TradeFinanceFormProps): Promise<boolean> {
    return this.post("trade-finance/create/:employeeID", {
      params: { employeeID: this.employeeId },
      json: body,
    });
  }

  public modifyApplication(body: TradeFinanceFormProps): Promise<boolean> {
    return this.put("trade-finance/update/:employeeID", {
      params: { employeeID: this.employeeId },
      json: body,
    });
  }
}
