import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly data: any;
  readonly getFavorites?: (value) => void;
}

export function FavouritesModalTab({ data = [], getFavorites }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 100,
        accessor: "documentNumber",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              style={{ cursor: "pointer" }}
              onClick={() => getFavorites && getFavorites(row.data[row.row.index].id)}
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 180,
        accessor: "favoriteName",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NAME_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "clientAccount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 210,
        accessor: "correspondentName",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE"),
      },
      {
        width: 120,
        accessor: "correspondentTaxNumber",
        Header: translate("STATEMENT_EXPORT_EXCEL_RECEIVER_TAX_NUMBER_LABEL"),
      },
      {
        width: 180,
        accessor: "correspondentAccount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 160,
        accessor: "amount",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 400,
        accessor: "detailsOfPayment",
        Header: translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [getFavorites, translate]);

  return <Table data={data} columns={columns} showEmptyRow={true} />;
}
