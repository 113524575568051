import React from "react";

import { InquiryTab } from "./InquiryTab";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";

interface Props {
  readonly filter: AccountsFilter;
}

export function InquiryTabWrapper({ filter }: Props) {
  const locationHelpers = useLocationHelpers();

  return (
    <InquiryTab
      filter={filter}
      onChangeTab={(type) => locationHelpers.replaceQuery({ submitType: type })}
    />
  );
}
