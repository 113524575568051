import React from "react";
import { CellProps as RTCellProps } from "react-table";

export enum AppEnvironment {
  Staging = "staging",
  Production = "production",
  Development = "development",
}

export enum RoleType {
  Corporate = "corporate",
  Financial = "financial",
  Individual = "individual",
}

export enum ThemeType {
  Default = 1,
  DarkGray = 2,
  DarkBlue = 3,
  LightGray = 4,
}

export enum SizeType {
  Small = "small",
  Large = "large",
  Medium = "medium",
}

export interface Dict<T> {
  readonly [key: string]: T;
}

export interface OptionItem<T> {
  readonly value: T;
  readonly label: string;
}

export enum PositionType {
  Top = "top",
  Left = "left",
  Right = "right",
  Bottom = "bottom",
}

export interface ListFilterProps {
  readonly page?: number;
  readonly perPage?: number;
}

export enum Direction {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum AlignType {
  Left = "left",
  Right = "right",
  Center = "center",
}

export enum WrapType {
  Wrap = "wrap",
  NoWrap = "no-wrap",
}

export interface TableCellProps {
  readonly wrap?: WrapType;
  readonly align?: AlignType;
  readonly className?: string;
}

export enum Direction {
  Row = "row",
  Column = "column",
}

export type CellProps<T extends object = {}> = RTCellProps<T> & TableCellProps;

export interface ListMeta<T> {
  readonly list: T[];
  readonly page: number;
}

export enum ViewMode {
  Full = "full",
  ReadOnly = "readonly",
  Undetermined = "undetermined",
}

export enum CreateNewDocumentRoles {
  Yes = "1",
  No = "0",
}

export enum MenuState {
  Expanded = "expanded",
  Collapsed = "collapsed",
}

export enum MobileMenu {
  Opened = "opened",
  Closed = "closed",
}

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type SvgProps = React.SVGProps<SVGSVGElement>;

export interface ColorPaletteProps {
  readonly Transparent: any;

  readonly White: any;
  readonly Black: any;

  readonly Blue: any;
  readonly BlueSecond: any;
  readonly BlueThird: any;
  readonly BlueLight: any;

  readonly Teal: any;

  readonly Dark: any;

  readonly BlueDark: any;
  readonly BlueExtraLight: any;

  readonly BlueWhite: any;

  readonly Orange: any;

  readonly BlueGray: any;
  readonly BlueGrayDark: any;
  readonly BlueGrayLight: any;
  readonly BlueGrayExtraLight: any;
  readonly BlueGrayLighten: any;

  readonly Gray: any;
  readonly GrayLight: any;

  readonly Info: any;
  readonly Muted: any;
  readonly Danger: any;
  readonly Warning: any;
  readonly Primary: any;
  readonly Success: any;
  readonly Secondary: any;

  readonly Green: any;
}
