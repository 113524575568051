import React from "react";
import cx from "classnames";

import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";
import { TransferLcyType } from "../../filters/TransfersFilter";

export interface TransfersButtonsGroupProps {
  readonly className?: string;
  readonly type: TransferLcyType;
  readonly onChange: (type: TransferLcyType) => void;
  readonly disabled: boolean;
  readonly backButton: () => void;
  readonly beginDay: string;
}

export function TransfersButtonsGroup({
  type,
  onChange,
  className,
  disabled,
  backButton,
  beginDay,
}: TransfersButtonsGroupProps) {
  return (
    <div className={cx("btn-group w-100 flex-lg-row flex-column", className)} role="group">
      {(beginDay === "0" || !beginDay) && (
        <>
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.AnorTransfer)}
            // labelCode="TRANSFERS_TRANSFERS_LCY_ANOR_TRANSFER_BUTTON_TITLE"
            labelCode={`${
              beginDay !== "1"
                ? "TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE"
                : "TRANSFERS_TRANSFERS_LCY_ANOR_TRANSFER_BUTTON_TITLE"
            }`}
            color={
              type === TransferLcyType.AnorTransfer ? ButtonColor.Secondary : ButtonColor.Default
            }
            disabled={disabled}
          />
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.AnorBudgetTransfer)}
            labelCode={`${
              beginDay !== "1"
                ? "TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE"
                : "TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_TRANSFER_BUTTON_TITLE"
            }`}
            color={
              type === TransferLcyType.AnorBudgetTransfer
                ? ButtonColor.Secondary
                : ButtonColor.Default
            }
            disabled={disabled}
          />
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.AnorBudgetRevenueTransfer)}
            // labelCode="TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE"
            labelCode={`${
              beginDay !== "1"
                ? "TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE"
                : "TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE"
            }`}
            color={
              type === TransferLcyType.AnorBudgetRevenueTransfer
                ? ButtonColor.Secondary
                : ButtonColor.Default
            }
            disabled={disabled}
          />
        </>
      )}

      {beginDay === "1" && (
        <>
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.StandardTransfer)}
            labelCode="TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE"
            color={
              type === TransferLcyType.StandardTransfer
                ? ButtonColor.Secondary
                : ButtonColor.Default
            }
            disabled={disabled}
          />
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.BudgetTransfer)}
            labelCode="TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE"
            color={
              type === TransferLcyType.BudgetTransfer ? ButtonColor.Secondary : ButtonColor.Default
            }
            disabled={disabled}
          />
          <Button
            active={true}
            size={SizeType.Large}
            className="px-3 px-lg-5 my-lg-0 my-1"
            onClick={() => onChange(TransferLcyType.BudgetRevenueTransfer)}
            labelCode="TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE"
            color={
              type === TransferLcyType.BudgetRevenueTransfer
                ? ButtonColor.Secondary
                : ButtonColor.Default
            }
            disabled={disabled}
          />
        </>
      )}

      <Button
        active={true}
        size={SizeType.Large}
        className="px-3 px-lg-5 my-lg-0 my-1"
        onClick={() => onChange(TransferLcyType.MinusTransfer)}
        labelCode="TRANSFERS_TRANSFERS_LCY_MINUS_TRANSFER_BUTTON_TITLE"
        color={type === TransferLcyType.MinusTransfer ? ButtonColor.Secondary : ButtonColor.Default}
        disabled={disabled}
      />
      <Button
        active={true}
        size={SizeType.Medium}
        className="ml-lg-2 mt-1 px-3 mb-lg-0 mb-2"
        onClick={backButton}
        labelCode="TRANSFERS_SALARY_LIST_OF_CARD_BACK"
        color={ButtonColor.Orange}
      />
    </div>
  );
}
