import "./assets/app-header.scss";

import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import { LogoIcon } from "../icons/LogoIcon";
import { Routes } from "../../constants/Routes";
import { UserProps } from "../../api/dto/UserDTO";
import { useTheme } from "../../theme/ThemeContext";
import { AppTimerWrapper } from "./AppTimerWrapper";
import { LogoutButtonWrapper } from "./LogoutButtonWrapper";
import { useAuthContext } from "../../api/auth/AuthContext";

interface Props {
  readonly user: UserProps | undefined;
}

export function AppHeader({ user }: Props) {
  const { logout } = useAuthContext();
  const { ColorPalette } = useTheme();

  const appHeaderStyle = useMemo(
    () => ({
      background: `linear-gradient(to top, ${ColorPalette.Secondary} 0, ${ColorPalette.BlueThird} 100%)`,
    }),
    [ColorPalette.BlueThird, ColorPalette.Secondary],
  );

  return (
    <div className="d-flex text-white app-header" style={appHeaderStyle}>
      <NavLink
        to={Routes.CorporateAccountsRoute}
        className="d-flex align-items-center justify-content-center app-logo-container"
      >
        <LogoIcon
          height={32}
          width={170}
          firstColor={ColorPalette.White}
          thirdColor={ColorPalette.White}
          secondColor={ColorPalette.BlueExtraLight}
        />
      </NavLink>

      <span className="app-header-title align-self-center font-weight-bold pl-5">
        {user?.fullName}
      </span>

      <div className="d-flex flex-1 align-items-center justify-content-end px-6">
        <div className="d-flex mr-6 align-items-center">
          <span className="font-weight-bold fl-4">{user?.login}</span>
        </div>
        <div
          className="d-flex align-items-center justify-content-center   p-1 br-2   cursor-pointer"
          onClick={() => logout()}
          style={{
            background: ColorPalette.Secondary,
          }}
        >
          <LogoutButtonWrapper />
          <AppTimerWrapper />
        </div>
      </div>
    </div>
  );
}
