import cx from "classnames";
import React, { ReactNode } from "react";

interface Props {
  readonly headerMinHeight?: number;
  readonly footerMinHeight?: number;
  readonly children: ReactNode;
  readonly headerComponent?: ReactNode;
  readonly footerComponent?: ReactNode;
  readonly className?: string;
  readonly headerClassName?: string;
  readonly footerClassName?: string;
  readonly contentClassName?: string;
  readonly withHorizontalScroll?: boolean;
}

export function TabPage({
  children,
  className,
  headerMinHeight = 80,
  footerMinHeight = 90,
  footerComponent,
  headerComponent,
  headerClassName,
  footerClassName,
  contentClassName,
  withHorizontalScroll = true,
}: Props) {
  return (
    <div className={cx("d-flex flex-1 flex-column", className)}>
      {Boolean(headerComponent) && (
        <div
          style={{ minHeight: `${headerMinHeight}px` }}
          className={cx("d-flex flex-column", headerClassName)}
        >
          {headerComponent}
        </div>
      )}

      <div
        className={cx(
          "d-flex br-1 border border-gray-light flex-column flex-1 bg-white overflow-y-hidden position-relative",
          {
            "overflow-x-auto": withHorizontalScroll,
          },
          contentClassName,
        )}
      >
        {children}
      </div>

      {Boolean(footerComponent) && (
        <div
          style={{ minHeight: `${footerMinHeight}px` }}
          className={cx("d-flex flex-column", footerClassName)}
        >
          {footerComponent}
        </div>
      )}
    </div>
  );
}
