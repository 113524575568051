import React from "react";

import { ColorPalette } from "../../theme/ColorPalette";

export function ShapeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="4" viewBox="0 0 7 4">
      <path
        fillRule="evenodd"
        d="M580.5-13l3.5-4h-7Z"
        fill={ColorPalette.Gray}
        transform="translate(-577 17)"
      />
    </svg>
  );
}
