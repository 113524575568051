import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { DatePickerField } from "../form/DatePickerField";
import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userFullNameSelector } from "../../reducers/userReducer";
import { Button, ButtonColor } from "../ui/Button";
import { CreateNewDocumentRoles, SizeType, ViewMode } from "../../api/dto/AppDTO";
import { CheckboxField } from "../form/CheckboxField";
import { NotificationManager } from "react-notifications";
import { formatDateToBody } from "../../utils/DateUtils";
import { ApprovalInquiryType } from "../../api/account/AccountDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { object, string } from "yup";
import { showError } from "../../utils/NotificationUtils";
import { viewModeSelector } from "../../reducers/appReducer";
import { createNewDocumentRole } from "../../reducers/authReducer";

interface Props {
  readonly accountsNumber: [{ label: ""; value: "" }];
  readonly branchCode: [{ label: ""; value: "" }];
  readonly onClose: () => void;
}

const validationSchema = object({
  requestNumber: string().required("Required"),
  branchCode: string().required("Required"),
});

export function RegisterAccInfoInquiry({ accountsNumber, onClose, branchCode }: Props) {
  const { translate } = useI18n();
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const { AccountApi } = useAccountContext();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(1);
  const data = [{ id: "0" }];
  for (let i = 1; i < 20; i++) {
    // @ts-ignore
    data.push({ id: i.toString() });
  }
  const accounts = data.slice(0, id);

  const onSubmit = useCallback(
    (value) => {
      setLoading(true);
      const choosedAccount = [];
      for (let i = 0; i < id; i++) {
        // @ts-ignore
        choosedAccount.push({ label: `account_${i.toString()}` });
      }
      const values = {
        registrationNumber: value.requestNumber,
        requestDate: formatDateToBody(value.requestDate),
        subject: translate("ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER"),
        branchCode: value.branchCode.value,
        inquiryType: ApprovalInquiryType.Standard,
        message: `${fullName}${translate("ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO")} ${
          value.account_0 === ""
            ? translate("ACCOUNT_ALL_ACCOUNTS")
            : choosedAccount.map((x) => {
                // @ts-ignore
                return value[x.label]?.label !== undefined ? ` ${value[x.label]?.label}` : "";
              })
        }
        ${
          value["1"]
            ? `${translate("ACCOUNT_BALANCE_OF_DATE")} ${formatDateToBody(value.balanceAsOfDate)}`
            : ""
        }
        ${
          value["2"]
            ? `${translate("ACCOUNT_TURNOVERS")} ${formatDateToBody(
                value.turnoversFrom,
              )} ${translate("ACCOUNT_TO_DATE")} ${formatDateToBody(value.turnoversTo)}`
            : ""
        }
        ${
          value["3"]
            ? `${translate("ACCOUNT_INFORMATION")} ${formatDateToBody(value.informationDate)}`
            : ""
        }
        ${value["4"] ? `${translate("ACCOUNT_CARD_FILE")} ${formatDateToBody(value.cardDate)}` : ""}
        
        ${translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}
        
        ${fullName}`,
      };
      // @ts-ignore
      AccountApi.createNonStandardInquiry(values)
        .then(() => {
          NotificationManager.info(
            translate("ACCOUNT_INQUIRY_SENT"),
            translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE"),
            5000,
          );
          setLoading(false);
          onClose();
        })
        .catch((r) => {
          showError(r);
          setLoading(false);
        });
    },
    [AccountApi, fullName, id, onClose, translate],
  );
  const inqFormRef = useRef<any>(null);
  const inqFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        inqFormRef.current = instance;
      }
    },
    [inqFormRef],
  );
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);
  return (
    <>
      <Formik
        initialValues={{
          requestNumber: "",
          requestDate: new Date(),
          branchCode: "",
          account_0: "",
          balanceAsOfDate: new Date(),
          turnoversFrom: new Date(),
          turnoversTo: new Date(),
          informationDate: new Date(),
          cardDate: new Date(),
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={inqFormRefHandler}
      >
        {() => (
          <Form className="d-flex flex-column px-7 pt-7">
            <div className="d-flex row mb-4">
              <InputField
                name="requestNumber"
                className="col-lg-3"
                label={"ACCOUNT_REQUEST_NUMBER"}
              />
              <DatePickerField
                name="requestDate"
                className="col-lg-3"
                label={"ACCOUNT_REQUEST_DATE"}
              />
              <SelectPickerField
                name="branchCode"
                className="col-lg-6"
                label={"ACCOUNT_BRANCH_NAME"}
                options={branchCode}
              />
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-8">
                {fullName}
                {translate("ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO")}
              </span>
              <a
                className="ml-4 cursor-pointer"
                style={{ fontSize: "30px" }}
                onClick={() => {
                  if (id < 20) {
                    setId(id + 1);
                  } else {
                    NotificationManager.error(
                      translate("INQUIRY_STANDART_ERRORS_MAX"),
                      translate("INQUIRY_STANDART_ERRORS_MAX_TITLE"),
                      5000,
                    );
                  }
                }}
              >
                +
              </a>
              {id > 1 ? (
                <a
                  className="ml-4 cursor-pointer"
                  style={{ fontSize: "30px" }}
                  onClick={() => {
                    setId(id - 1);
                  }}
                >
                  -
                </a>
              ) : (
                <div />
              )}
            </div>
            <div className="d-flex row mb-4">
              {accounts.map((x) => (
                <SelectPickerField
                  className="col-lg-6 mb-2"
                  key={x.id}
                  name={`account_${x.id}`}
                  options={accountsNumber}
                />
              ))}
            </div>
            <div className="d-flex row mb-4">
              <div className="column">
                <CheckboxField name="1" className="ml-4 mt-1" label="ACCOUNT_BALANCE_OF_DATE" />
                <CheckboxField name="2" className="ml-4 mt-4" label="ACCOUNT_TURNOVERS" />
                <CheckboxField name="3" className="ml-4 mt-4" label="ACCOUNT_INFORMATION" />
                <CheckboxField name="4" className="ml-4 mt-4" label="ACCOUNT_CARD_FILE" />
              </div>
              <div className="d-flex row col-9">
                <DatePickerField
                  name="balanceAsOfDate"
                  className="col-lg-3"
                  size={SizeType.Small}
                />
                <div className="col-lg-9" />
                <DatePickerField name="turnoversFrom" className="col-lg-3" size={SizeType.Small} />
                <span className="pt-2">{translate("ACCOUNT_TO_DATE")}</span>
                <DatePickerField name="turnoversTo" className="col-lg-3" size={SizeType.Small} />
                <div className="col-lg-3" />
                <DatePickerField
                  name="informationDate"
                  className="col-lg-3"
                  size={SizeType.Small}
                />
                <div className="col-lg-9" />
                <DatePickerField name="cardDate" className="col-lg-3" size={SizeType.Small} />
              </div>
              <div className="d-flex row col-lg-6"></div>
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-9">{translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}</span>
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-9">{fullName}</span>
            </div>
          </Form>
        )}
      </Formik>
      <div>
        {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
          <Button
            className="ml-7 mb-3"
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            type="submit"
            disabled={loading}
            onClick={() => {
              if (inqFormRef.current?.handleSubmit) {
                inqFormRef.current?.handleSubmit();
              }
            }}
          >
            {translate("ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE")}
          </Button>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}
