import { update } from "immupdate";
import { Dict } from "../api/dto/AppDTO";
import { createReducer, createRootReducer } from "../utils/ReducerUtils";

enum ReducerActions {
  SetPayrollSelectedIds = "PayrollTable/SetPayrollSelectedIds",
}

export interface PayrollSelectedIds {
  payrollList?: Dict<boolean>;
}

function getState(): PayrollSelectedIds {
  return {};
}

export const payrollListIdsReducer = createRootReducer<PayrollSelectedIds>(
  getState(),

  createReducer([ReducerActions.SetPayrollSelectedIds], (state, { payrollList }) =>
    update(state, { payrollList }),
  ),
);

// ==================
// Selectors
// ==================

export const payrollListIdsSelector = ({ payrollListIds }): PayrollSelectedIds | undefined =>
  payrollListIds.payrollList;

// ==================
// Actions
// ==================

export function SetPayrollSelectedIds({ payrollList }) {
  return { type: ReducerActions.SetPayrollSelectedIds, payrollList };
}
