import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { update } from "immupdate";
import { FormikProps } from "formik";

import {
  BudgetAccountSearchType,
  PaymentCodeBudgetFlag,
  // PaymentCodeProps,
} from "../../api/reference-book/ReferenceBookDTO";
import { useResource } from "../../hooks/useResource";
import { useReferenceBookContext } from "../../api/reference-book/ReferenceBookContext";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { LcyTransferForm } from "./LcyTransferForm";
import { LcyTransferFormProps } from "../../api/transfer/TransferDTO";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { CorrespondentsTable } from "./CorrespondentsTable";
import { PaymentCodeTable } from "./PaymentCodeTable";
import { formatDateToBody } from "../../utils/DateUtils";
import { numberToWords } from "../../utils/FormatUtils";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector } from "../../reducers/appReducer";
import "./assets/lcy-create-forms.scss";

interface Props {
  readonly onSubmit: (values: LcyTransferFormProps) => void;

  readonly formikRef?: (instance: FormikProps<LcyTransferFormProps>) => void;

  readonly initialValue?: LcyTransferFormProps;

  readonly setinitialValues: (value) => void;
}

export function LCYStandardTransferFormWrapper({
  onSubmit,
  formikRef,
  initialValue,
  setinitialValues,
}: Props) {
  const location = useLocation();
  const { translate } = useI18n();
  const { ReferenceBookApi } = useReferenceBookContext();
  const { TransferApi } = useTransferContext();
  const fromConversionPage = useMemo(
    () =>
      new URLSearchParams(location.search).get("fromUzToFcyConversationPage") &&
      new URLSearchParams(location.search).get("reservedAmountInUZS"),
    [location.search],
  );

  const onChangeCorrespondentBankName = useCallback(
    (value) => {
      ReferenceBookApi.getMfoInformation(value, {
        searchType: BudgetAccountSearchType.OneRow,
      }).then((r) => {
        setinitialValues((prev) =>
          update(prev, {
            correspondentBankName: r[0] ? r[0].bankName : "Not Found",
          }),
        );
      });
    },
    [ReferenceBookApi, setinitialValues],
  );

  const language = useShallowEqualSelector(appLanguageSelector);
  const onChangeAmount = useCallback(
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      if (value.length <= 18) {
        const amountDetails = numberToWords(removeGaps(value), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        });
        setinitialValues((prev) =>
          update(prev, {
            amount: floatFormat(value),
            amountDetails:
              amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
          }),
        );
      }
    },
    [language, setinitialValues],
  );

  const [debitAccount, setDebitAccount] = useState({});

  useResource(
    () =>
      ReferenceBookApi.getAllowedAccounts().then((r) => {
        setDebitAccount([
          r.map((res) => ({
            label: res.accountCode,
            value: res.accountCode,
            clientCode: res.accountCode,
            balance: res.balance,
            senderBankCode: res?.accountBranch,
            senderName: res?.accountName,
            accountState: res?.accountState,
            senderBankName: res?.bankName,
            senderTaxId: res?.taxNumber,
          })),
        ]);
      }),
    [ReferenceBookApi],
  );

  const onChangeDebitsAccount = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          senderName: value.senderName,
          currentBalance: floatFormat(addZeros(value.balance)),
          senderBankName: value.senderBankName,
          senderBankCode: value.senderBankCode,
          senderTaxId: value.senderTaxId,
          clientAccount: value,
          accountState: value.accountState,
        }),
      );
    },
    [setinitialValues],
  );

  const [paymentCode, setPaymentCode] = useState(false);

  const [beneficiary, setBeneficiary] = useState(false);

  const [searchCorr, setSearchCorr] = useState([] as any);
  const [searching, setSearching] = useState(false);

  const [searchPaymentCode, setSearchPaymentCode] = useState("");

  const beneficiaryAccounts = useResource(() => TransferApi.correspondentList(), [TransferApi]);

  const beneficiaryList = useMemo(() => beneficiaryAccounts?.data || [], [
    beneficiaryAccounts?.data,
  ]);

  const paymentCodes = useResource(
    () =>
      ReferenceBookApi.checkPaymentCode({
        paymentCode: searchPaymentCode ? searchPaymentCode : "",
        budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
      }),
    [ReferenceBookApi, searchPaymentCode],
  );

  const paymentCodesList = useMemo(() => paymentCodes.data || [], [paymentCodes.data]);

  const paymentCodeListSearch = useCallback((data, term) => {
    if (term !== "") {
      return data && data.filter((item) => `${item.code} ${item.name}`.indexOf(term) > -1);
    } else return data;
  }, []);

  const paymentCodeListAfterSearch = useMemo(
    () => {
      let current = "";
      if (
        current !== searchPaymentCode &&
        searchPaymentCode !== "" &&
        searchPaymentCode.length > 2
      ) {
        current = searchPaymentCode;
        return paymentCodeListSearch(paymentCodesList, searchPaymentCode);
      } else {
        return paymentCodeListSearch(paymentCodesList, searchPaymentCode);
      }
    },
    // paymentCodeListSearch(paymentCodesList, searchPaymentCode),
    [paymentCodeListSearch, paymentCodesList, searchPaymentCode],
  );

  const submitHandler = useCallback(
    (values) => {
      if (values.correspondentBankName === "Not Found") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK"),
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE"),
          7000,
        );
      }
      // else if (typeof values.amount !== "number") {
      //   NotificationManager.error(
      //     translate("TRANSFER_AMOUNT_ERROR"),
      //     translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
      //     7000,
      //   );
      // }
      else if (values.amount === "0.00") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_AMOUNT"),
          translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
          7000,
        );
      } else if (values.clientAccount?.label === undefined) {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT"),
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE"),
          7000,
        );
      } else {
        const data = {
          ...values,
          documentDate: formatDateToBody(values.documentDate),
          amount: removeGaps(values.amount),
          clientAccount: values.clientAccount.label,
          correspondentBankName: values.correspondentBankName.substr(0, 70),
          currentBalance: removeGaps(values.currentBalance),
        };
        onSubmit(data);
      }
    },
    [onSubmit, translate],
  );

  const onPaymentCodeSelect = useCallback(
    (value) => {
      setPaymentCode(false);
      const selected = paymentCodesList.filter((pCs) => pCs.code === value);
      setinitialValues((prev) =>
        update(prev, {
          paymentCode: selected[0].code,
        }),
      );
    },
    [paymentCodesList, setinitialValues],
  );

  const onCorrespondentSelect = useCallback(
    (value) => {
      setBeneficiary(false);
      const selected = beneficiaryList.filter(
        (corr) => `${corr.name}${corr.mfo}${corr.taxNumber}${corr.account}` === value,
      );
      const searchSelected = searchCorr.filter(
        (x) => `${x.name}${x.mfo}${x.taxNumber}${x.account}` === value,
      );
      if (selected.length > 0) {
        setinitialValues((prev) =>
          update(prev, {
            correspondentName: selected[0]?.name,
            correspondentAccount: selected[0]?.account,
            correspondentBank: selected[0]?.mfo,
            correspondentTaxNumber: selected[0]?.taxNumber,
            correspondentBankName: selected[0]?.bankName,
          }),
        );
      } else {
        setinitialValues((prev) =>
          update(prev, {
            correspondentName: searchSelected[0]?.name,
            correspondentAccount: searchSelected[0]?.account,
            correspondentBank: searchSelected[0]?.mfo,
            correspondentTaxNumber: searchSelected[0]?.taxNumber,
            correspondentBankName: searchSelected[0]?.bankName,
          }),
        );
      }
    },
    [beneficiaryList, setinitialValues, searchCorr],
  );

  const onChangePaymentCode = useCallback(
    (value) => {
      if (value.length <= 5) {
        setinitialValues((prev) =>
          update(prev, {
            paymentCode: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangePaymentDetails = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          detailsOfPayment: value.replace(/\s+/g, " "),
        }),
      );
    },
    [setinitialValues],
  );

  const onChangeCorrespondentBank = useCallback(
    (value) => {
      if (value.length <= 5) {
        setinitialValues((prev) =>
          update(prev, {
            correspondentBank: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangeCorrespondentTaxNumber = useCallback(
    (value) => {
      if (value.length <= 9) {
        setinitialValues((prev) =>
          update(prev, {
            correspondentTaxNumber: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangeTransferNumber = useCallback(
    (value) => {
      if (value.length <= 10) {
        setinitialValues((prev) =>
          update(prev, {
            documentNumber: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const onChangeDocumentDate = useCallback(
    (value) => {
      NotificationManager.info(
        translate("WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE"),
      );
      setinitialValues((prev) =>
        update(prev, {
          documentDate: value,
        }),
      );
    },
    [setinitialValues, translate],
  );

  const onChangeCorrespondentName = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          correspondentName: value.replace(/[^A-zА-я0-9,. !@#$%^*()'"№;:?-]/gi, ""),
        }),
      );
    },
    [setinitialValues],
  );

  const onChangeCorrespondentAccount = useCallback(
    (value) => {
      if (value.length <= 20) {
        setinitialValues((prev) =>
          update(prev, {
            correspondentAccount: value,
          }),
        );
      }
    },
    [setinitialValues],
  );
  const correspondents = useMemo(() => (searching ? searchCorr : beneficiaryList), [
    searching,
    searchCorr,
    beneficiaryList,
  ]);
  // const searchCorrespondentOnlyTextFunc = useCallback(
  //   ({ parameter }: { parameter: string }) => {
  //     const temp =
  //       beneficiaryList &&
  //       beneficiaryList.filter(
  //         (item) => `${item.taxNumber} ${item.mfo} ${item.name}`.indexOf(parameter) > -1,
  //       );

  //     return new Promise((resolve) => resolve(temp)).catch(() => {
  //       NotificationManager.error(
  //         translate("TABLE_EMPTY_STATE_TITLE"),
  //         translate("TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE"),
  //         7000,
  //       );
  //     });
  //   },
  //   [beneficiaryList, translate],
  // );
  const searchCorrespondents = useCallback(
    (value) => {
      if (value.length > 2) {
        setSearching(true);
        // TransferApi.searchCorrespondent({ taxAccountNumber: value }).then((x) => setSearchCorr(x));

        // searchCorrespondentOnlyTextFunc({ parameter: value }).then((x) => setSearchCorr(x));
        TransferApi.searchCorrespondentOnlyText({ parameter: value }).then((x) => setSearchCorr(x));
      } else {
        setSearching(false);
      }
    },
    // TransferApi
    [TransferApi],
  );

  const [filteredDebitAccount, setFilteredDebitAccount] = useState([]);
  useEffect(() => {
    if (debitAccount[0] && fromConversionPage) {
      const temp = debitAccount[0].filter((item) => item.clientCode.slice(17) !== "888");
      setFilteredDebitAccount(temp);

      const reserveAccountInUZSFromConversion = new URLSearchParams(location.search).get(
        "reserveAccountInUZS",
      );
      // amount in words
      const amount = new URLSearchParams(location.search).get("reservedAmountInUZS");
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      if (amount && amount.length <= 18) {
        const amountDetails = numberToWords(removeGaps(amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        });
        // fill initial values
        setinitialValues((prev) =>
          update(prev, {
            senderName: temp[0].senderName,
            clientAccount: {
              ...temp[0],
            },
            currentBalance: temp[0].balance,
            senderBankName: temp[0].senderBankName,
            senderBankCode: temp[0].senderBankCode,
            senderTaxId: temp[0].senderTaxId,
            correspondentName: temp[0].senderName,
            correspondentAccount: reserveAccountInUZSFromConversion,
            correspondentBankName: temp[0].senderBankName,
            correspondentBank: temp[0].senderBankCode,
            correspondentTaxNumber: temp[0].senderTaxId,
            amount: floatFormat(amount),
            amountDetails:
              amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
            paymentCode: "00642",
            detailsOfPayment: "Покупка иностранной валюты для собственных нужд",
          }),
        );
      }
    }

    return () => {
      setFilteredDebitAccount([]);
    };
  }, [debitAccount, fromConversionPage, language, location.search, setinitialValues]);

  return (
    <>
      <LcyTransferForm
        debits={!fromConversionPage ? debitAccount[0] : filteredDebitAccount}
        onSubmit={submitHandler}
        formikRef={formikRef}
        initialValues={initialValue}
        getBeneficiaryName={() => setBeneficiary(true)}
        getPaymentCode={() => setPaymentCode(true)}
        onChangeDebitsAccount={onChangeDebitsAccount}
        onChangeAmount={onChangeAmount}
        onChangeCorrespondentBankName={onChangeCorrespondentBankName}
        onChangeCorrespondentAccount={onChangeCorrespondentAccount}
        onChangeCorrespondentBank={onChangeCorrespondentBank}
        onChangeCorrespondentName={onChangeCorrespondentName}
        onChangeCorrespondentTaxNumber={onChangeCorrespondentTaxNumber}
        onChangePaymentCode={onChangePaymentCode}
        onChangeTransferNumber={onChangeTransferNumber}
        onChangePaymentDetails={onChangePaymentDetails}
        onChangeDocumentDate={onChangeDocumentDate}
      />
      <Modal
        searchInput={true}
        onClose={() => setBeneficiary(false)}
        width={900}
        show={beneficiary}
        title={"TRANSFERS_CORRESPONDENTS_TITLE"}
        setSearch={(value) => searchCorrespondents(value)}
      >
        <TabPage>
          <CorrespondentsTable
            modalFor={true}
            data={correspondents}
            loading={beneficiaryAccounts.loading}
            onCorrespondentSelect={onCorrespondentSelect}
          />
        </TabPage>
      </Modal>
      <Modal
        searchInput={true}
        setSearch={(value) => setSearchPaymentCode(value)}
        onClose={() => setPaymentCode(false)}
        width={700}
        show={paymentCode}
        title={"TRANSFERS_MODAL_PAYMENT_CODE_TITLE"}
      >
        <TabPage>
          <PaymentCodeTable
            onPaymentCodeSelect={onPaymentCodeSelect}
            data={paymentCodeListAfterSearch}
            loading={paymentCodes.loading}
          />
        </TabPage>
      </Modal>
    </>
  );
}
