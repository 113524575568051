import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function ExcelIcon({ size = 18, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 18.133 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 -15.989)">
        <g transform="translate(0 15.989)">
          <g transform="translate(0 0)">
            <path
              fill={color}
              transform="translate(0 -15.989)"
              d="M17.566,18.257H10.2v-1.7a.565.565,0,0,0-.205-.436A.557.557,0,0,0,9.529,16L.462,17.7A.565.565,0,0,0,0,18.257V30.723a.566.566,0,0,0,.462.556l9.066,1.7a.533.533,0,0,0,.1.01.567.567,0,0,0,.567-.567v-1.7h7.366a.567.567,0,0,0,.567-.567V18.823A.567.567,0,0,0,17.566,18.257ZM7.792,26.384a.566.566,0,1,1-.852.746L5.292,25.247,3.847,27.105a.567.567,0,0,1-.9-.7l1.58-2.031L2.974,22.6a.567.567,0,0,1,.853-.746l1.414,1.616L6.92,21.309a.567.567,0,1,1,.894.7L6,24.336ZM17,29.59H10.2V28.456h1.7a.567.567,0,0,0,0-1.133H10.2V26.19h1.7a.567.567,0,0,0,0-1.133H10.2V23.923h1.7a.567.567,0,0,0,0-1.133H10.2V21.657h1.7a.567.567,0,1,0,0-1.133H10.2V19.39H17Z"
            />
          </g>
        </g>
        <g transform="translate(13.599 20.523)">
          <g>
            <path
              fill={color}
              transform="translate(-384 -144.011)"
              d="M385.7,144.011h-1.133a.567.567,0,1,0,0,1.133H385.7a.567.567,0,1,0,0-1.133Z"
            />
          </g>
        </g>
        <g transform="translate(13.599 22.79)">
          <g>
            <path
              fill={color}
              transform="translate(-384 -208.011)"
              d="M385.7,208.011h-1.133a.567.567,0,1,0,0,1.133H385.7a.567.567,0,1,0,0-1.133Z"
            />
          </g>
        </g>
        <g transform="translate(13.599 25.056)">
          <g transform="translate(0 0)">
            <path
              fill={color}
              transform="translate(-384 -272.011)"
              d="M385.7,272.011h-1.133a.567.567,0,0,0,0,1.133H385.7a.567.567,0,0,0,0-1.133Z"
            />
          </g>
        </g>
        <g transform="translate(13.599 27.323)">
          <g>
            <path
              fill={color}
              transform="translate(-384 -336.011)"
              d="M385.7,336.011h-1.133a.567.567,0,1,0,0,1.133H385.7a.567.567,0,1,0,0-1.133Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
