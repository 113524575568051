import { noop } from "lodash";
import React, { ReactElement, ReactNode, useContext, useMemo } from "react";

import { ColorPaletteProps } from "../api/dto/AppDTO";
import { DefaultTheme } from "../components/theme/DefaultTheme";
import { DarkBlueTheme } from "../components/theme/DarkBlueTheme";
import { DarkGrayTheme } from "../components/theme/DarkGrayTheme";
import { REACT_APP_DEFAULT_THEME } from "../constants/AppConstants";
import { LightGrayTheme } from "../components/theme/LightGrayTheme";
import { ColorPalette as DefaultColorPalette } from "./default/ColorPalette";
import { ColorPalette as DarkBlueColorPalette } from "./darkBlue/ColorPalette";
import { ColorPalette as DarkGrayColorPalette } from "./darkGray/ColorPalette";
import { ColorPalette as LightGrayColorPalette } from "./lightGray/ColorPalette";

export enum AppTheme {
  Default = "default",
  DarkGray = "dark-gray",
  DarkBlue = "dark-blue",
  LightGray = "light-gray",
}

export interface ThemeContextDataProps {
  readonly theme: AppTheme;
  readonly onChangeTheme: (theme: AppTheme) => void;
}

export interface ThemeContextProps {
  readonly theme: AppTheme;

  readonly ColorPalette: ColorPaletteProps;

  readonly isDefaultTheme: boolean;
  readonly isDarkGrayTheme: boolean;
  readonly isDarkBlueTheme: boolean;
  readonly isLightGrayTheme: boolean;

  readonly changeTheme: (theme: AppTheme) => void;
}

export interface ThemeProviderProps {
  readonly children: ReactNode;
  readonly data?: ThemeContextDataProps;
}

export function getDefaultTheme(): AppTheme {
  return REACT_APP_DEFAULT_THEME;
}

function getColorPalette(theme: AppTheme): ColorPaletteProps {
  switch (theme) {
    case AppTheme.DarkBlue:
      return DarkBlueColorPalette;
    case AppTheme.DarkGray:
      return DarkGrayColorPalette;
    case AppTheme.LightGray:
      return LightGrayColorPalette;

    default:
      return DefaultColorPalette;
  }
}

function createContentValue(themeData: ThemeContextDataProps): ThemeContextProps {
  const isDefaultTheme = themeData.theme === AppTheme.Default;
  const isDarkBlueTheme = themeData.theme === AppTheme.DarkBlue;
  const isDarkGrayTheme = themeData.theme === AppTheme.DarkGray;
  const isLightGrayTheme = themeData.theme === AppTheme.LightGray;

  const ColorPalette = getColorPalette(themeData.theme);

  return {
    ...themeData,

    theme: themeData.theme || AppTheme.Default,

    ColorPalette,

    isDefaultTheme,
    isDarkBlueTheme,
    isDarkGrayTheme,
    isLightGrayTheme,

    changeTheme: themeData.onChangeTheme,
  };
}

export const ThemeContext = React.createContext<ThemeContextProps>(
  createContentValue({ theme: AppTheme.Default, onChangeTheme: noop }),
);

export function ThemeProvider({
  data = {} as ThemeContextDataProps,
  ...props
}: ThemeProviderProps): ReactElement<object> {
  const value = useMemo(() => createContentValue(data), [data]);

  return (
    <>
      {value.isDarkBlueTheme && <DarkBlueTheme />}
      {value.isDarkGrayTheme && <DarkGrayTheme />}
      {value.isDefaultTheme && <DefaultTheme />}
      {value.isLightGrayTheme && <LightGrayTheme />}

      <ThemeContext.Provider {...props} value={value} />
    </>
  );
}

export function useTheme(): ThemeContextProps {
  return useContext(ThemeContext);
}
