import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import { object, string } from "yup";

import { DatePickerField } from "../form/DatePickerField";
import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userFullNameSelector } from "../../reducers/userReducer";
import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";
import { formatDateToBody } from "../../utils/DateUtils";
import { ApprovalInquiryType } from "../../api/account/AccountDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { showError } from "../../utils/NotificationUtils";
import { IndividualSMSModal } from "./IndividualSMSModal";

interface Props {
  readonly accountsNumber: [{ label: ""; value: "" }];
  readonly branchCode: [{ label: ""; value: "" }];
  readonly onClose: () => void;
}

const validationSchema = object({
  requestNumber: string().required("Required"),
  branchCode: string().required("Required"),
});

export function RegisterAdditionalStatement({ branchCode, accountsNumber, onClose }: Props) {
  const { translate } = useI18n();
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const { AccountApi } = useAccountContext();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");
  const [values, setValues] = useState({} as any);
  const [id, setId] = useState(1);
  const [loading, setLoading] = useState(false);
  const data = [{ id: "0" }];
  for (let i = 1; i < 20; i++) {
    // @ts-ignore
    data.push({ id: i.toString() });
  }
  const accounts = data.slice(0, id);

  const onSubmit = useCallback(
    (value) => {
      setLoading(true);
      const choosedAccount = [];
      for (let i = 0; i < id; i++) {
        choosedAccount.push({
          // @ts-ignore
          label: `account_${i.toString()}`,
          // @ts-ignore
          value: `date_${i.toString()}`,
          // @ts-ignore
          data: `data_${i.toString()}`,
        });
      }
      const values = {
        registrationNumber: value.requestNumber,
        requestDate: formatDateToBody(value.requestDate),
        subject: translate("ACCOUNT_ADDITIONAL_STATEMENT_HEADER"),
        branchCode: value?.branchCode?.value,
        inquiryType: ApprovalInquiryType.Standard,
        message: `${fullName}${translate("ACCOUNT_ADDITIONAL_STATEMENT_TEXT")} ${
          value.account_1 === ""
            ? translate("ACCOUNT_ALL_ACCOUNTS")
            : choosedAccount.map((x) => {
                // @ts-ignore
                return value[x.label]?.label !== undefined
                  ? // @ts-ignore
                    `${value[x.label]?.label} ${translate(
                      "ACCOUNT_ADDITIONAL_FOR_THE_PERIOD",
                      // @ts-ignore
                    )} ${formatDateToBody(value[x.value])} ${translate("ACCOUNT_TO_DATE")} ${
                      // @ts-ignore
                      formatDateToBody(value[x.data])
                    }`
                  : "";
              })
        }
        
        
        ${translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}
        
        ${fullName}`,
      };
      setValues(values);
      AccountApi.sentSMS()
        .then((r) => {
          setOpen(true);
          setCode(r);
          setLoading(false);
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
    [AccountApi, fullName, id, translate],
  );
  const inqFormRef = useRef<any>(null);
  const inqFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        inqFormRef.current = instance;
      }
    },
    [inqFormRef],
  );

  const onSubmitSMS = useCallback(
    (value) => {
      AccountApi.createInquiryIndividual({ messageId: code, smsCode: value.smsCode, json: values })
        .then(() => {
          NotificationManager.info(
            translate("ACCOUNT_INQUIRY_SENT"),
            translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE"),
            5000,
          );
          setLoading(false);
          onClose();
        })
        .catch((r) => {
          showError(r);
          setLoading(false);
        });
    },
    [AccountApi, values, code, onClose, translate],
  );
  return (
    <>
      <Formik
        initialValues={{
          date_0: new Date(),
          date_1: new Date(),
          date_2: new Date(),
          date_3: new Date(),
          date_4: new Date(),
          date_5: new Date(),
          date_6: new Date(),
          date_7: new Date(),
          date_8: new Date(),
          date_9: new Date(),
          date_10: new Date(),
          date_11: new Date(),
          date_12: new Date(),
          date_13: new Date(),
          date_14: new Date(),
          date_15: new Date(),
          date_16: new Date(),
          date_17: new Date(),
          date_18: new Date(),
          date_19: new Date(),
          data_0: new Date(),
          data_1: new Date(),
          data_2: new Date(),
          data_3: new Date(),
          data_4: new Date(),
          data_5: new Date(),
          data_6: new Date(),
          data_7: new Date(),
          data_8: new Date(),
          data_9: new Date(),
          data_10: new Date(),
          data_11: new Date(),
          data_12: new Date(),
          data_13: new Date(),
          data_14: new Date(),
          data_15: new Date(),
          data_16: new Date(),
          data_17: new Date(),
          data_18: new Date(),
          data_19: new Date(),
          branchCode: "",
          requestNumber: "",
          requestDate: new Date(),
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={inqFormRefHandler}
      >
        {() => (
          <Form className="d-flex flex-column p-7">
            <div className="d-flex row mb-4">
              <InputField
                name="requestNumber"
                className="col-lg-3"
                label={"ACCOUNT_REQUEST_NUMBER"}
              />
              <DatePickerField
                name="requestDate"
                className="col-lg-3"
                label={"ACCOUNT_REQUEST_DATE"}
              />
              <SelectPickerField
                name="branchCode"
                className="col-lg-6"
                label={"ACCOUNT_BRANCH_NAME"}
                options={branchCode}
              />
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-8">
                {translate("INDIVIDUAL_INQUIRY_REGISTER_AM")}
                {", "}
                {fullName}
                {translate("INDIVIDUAL_ACCOUNT_ADDITIONAL_STATEMENT_TEXT")}
              </span>
              <a
                className="ml-4 cursor-pointer"
                style={{ fontSize: "30px" }}
                onClick={() => {
                  if (id < 20) {
                    setId(id + 1);
                  } else {
                    NotificationManager.error(
                      translate("INQUIRY_STANDART_ERRORS_MAX"),
                      translate("INQUIRY_STANDART_ERRORS_MAX_TITLE"),
                      5000,
                    );
                  }
                }}
              >
                +
              </a>
              {id > 1 ? (
                <a
                  className="ml-4 cursor-pointer"
                  style={{ fontSize: "30px" }}
                  onClick={() => {
                    setId(id - 1);
                  }}
                >
                  -
                </a>
              ) : (
                <div />
              )}
            </div>
            <div className="d-flex row mb-10">
              {accounts.map((x) => (
                <>
                  <SelectPickerField
                    className="col-lg-5 mb-2"
                    key={x.id}
                    name={`account_${x.id}`}
                    options={accountsNumber}
                  />
                  <span className="pt-2">{translate("ACCOUNT_ADDITIONAL_FOR_THE_PERIOD")}</span>
                  <DatePickerField name={`date_${x.id}`} key={x.id} className="col-lg-3" />
                  <span className="pt-2">{translate("ACCOUNT_TO_DATE")}</span>
                  <DatePickerField name={`data_${x.id}`} key={x.id} className="col-lg-3" />
                </>
              ))}
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-9">{translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}</span>
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-lg-9">{fullName}</span>
            </div>
          </Form>
        )}
      </Formik>
      <div>
        <Button
          className="ml-7 mb-5"
          size={SizeType.Medium}
          color={ButtonColor.Orange}
          type="submit"
          disabled={loading}
          onClick={() => {
            if (inqFormRef.current?.handleSubmit) {
              inqFormRef.current?.handleSubmit();
            }
          }}
        >
          {translate("ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE")}
        </Button>
      </div>
      <IndividualSMSModal open={open} setOpen={setOpen} onSubmit={onSubmitSMS} loading={loading} />
    </>
  );
}
