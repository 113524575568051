import { Column } from "react-table";
import React, { useMemo } from "react";

import { Table } from "../table/Table";
import { useI18n } from "../../i18n/I18nContext";
import { FcyTransferForPending } from "../../api/transfer/TransferDTO";
import { Dict } from "../../api/dto/AppDTO";
import { TransferStatusPicker } from "./TransferStatusPicker";
import { floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly showCreators: boolean;
  readonly data: FcyTransferForPending[];
  readonly onSelect: (ids: Dict<boolean>) => void;
  readonly loading: boolean;
  readonly onSelectDocument: (value, data) => void;
}

export function TransfersFcyTable({
  data = [],
  showCreators,
  onSelect,
  loading,
  onSelectDocument,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<FcyTransferForPending>[] = [
      {
        width: 100,
        accessor: "documentId",
        Header: translate("ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a className="cursor-pointer" onClick={() => onSelectDocument(row.value, row.data)}>
            {row.value}
          </a>
        ),
      },
      {
        width: 95,
        accessor: "createDate",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATE_DATE_AND_TIME_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "transactionDate",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_TRANSACTION_DATE_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "transferNumber",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "currency",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "amount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span>{row.value ? floatFormat(row.value) : ""}</span>,
      },
      {
        width: 180,
        accessor: "applicantAccount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "beneficiaryAccount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "beneficiaryName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "beneficiarySwiftCode",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_SWIFT_BIC_COLUMN_TITLE"),
      },
      {
        width: 350,
        accessor: "detailsOfPayment",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "state",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} fcy={true} />,
      },
      {
        width: 249,
        // @ts-ignore
        accessor: "rejectReason",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_EXPLANATION_COLUMN_TITLE"),
      },
    ];

    if (showCreators) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [onSelectDocument, showCreators, translate]);

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={onSelect}
      withCheckbox={true}
      loading={loading}
      showEmptyRow={true}
    />
  );
}
