import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const paginationReducerPersistConfig: Partial<PersistConfig<PaginationReducerState>> = {
  whitelist: ["pageCount", "totalCount"],
};

interface SwitchPageCountMeta {
  readonly pageCount: string;
}

interface SwitchTotalCountMeta {
  readonly totalCount: string;
}

enum ReducerActions {
  SwitchPageCount = "Pagination/SwitchPageCount",
  SwitchTotalCount = "Pagination/SwitchTotalCount",
}

export interface PaginationReducerState {
  readonly pageCount: string;
  readonly totalCount: string;
}

function getState(): PaginationReducerState {
  return {
    pageCount: "",
    totalCount: "",
  };
}

export const paginationReducer = createRootReducer<PaginationReducerState>(
  getState(),

  createReducer([ReducerActions.SwitchPageCount], (state, { meta }) =>
    update(state, { pageCount: meta.pageCount }),
  ),

  createReducer([ReducerActions.SwitchTotalCount], (state, { meta }) =>
    update(state, { totalCount: meta.totalCount }),
  ),
);

// ==================
// Selectors
// ==================

export const appPageCountSelector = ({ pagination }: AppStoreState): string => pagination.pageCount;

export const appTotalCountSelector = ({ pagination }: AppStoreState): string =>
  pagination.totalCount;

// ==================
// Actions
// ==================

export function switchPageCount(meta: SwitchPageCountMeta): PerformAction<SwitchPageCountMeta> {
  return { type: ReducerActions.SwitchPageCount, meta };
}

export function switchTotalCount(meta: SwitchTotalCountMeta): PerformAction<SwitchTotalCountMeta> {
  return { type: ReducerActions.SwitchTotalCount, meta };
}
