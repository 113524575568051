import "./assets/create-new-register-table-header.scss";

import React from "react";

import { useI18n } from "../../i18n/I18nContext";
import { TransferType } from "../../api/transfer/TransferDTO";

interface Props {
  transferType: TransferType;
}

export function CreateNewRegisterTableHeader({ transferType }: Props) {
  const { translate } = useI18n();

  return (
    <div className="d-flex align-items-lg-end create-new-register-table-header">
      <div className="col-1 row">
        <label>{translate("SALARY_CREATE_NEW_REGISTER_TABLE_ID_COLUMN_TITLE")}</label>
      </div>
      <div className="account-number-column col-columns row ml-0">
        <label>{translate("SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE")}</label>
      </div>
      <div className="full-name-column col-columns row ml-0">
        <label>
          {transferType === TransferType.Salary
            ? translate("SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE")
            : translate("SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE")}
        </label>
      </div>
      <div className="amount-column col-columns row ml-0">
        <label>{translate("SALARY_CREATE_NEW_REGISTER_TABLE_AMOUNT_COLUMN_TITLE")}</label>
      </div>
      <div className="account-number-column col-columns row ml-0">
        <label>
          {translate("SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_FROM_SYSTEM_COLUMN_TITLE")}
        </label>
      </div>
      <div className="full-name-column col-columns row ml-0">
        <label>
          {transferType === TransferType.Salary
            ? translate("SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_FROM_SYSTEM_COLUMN_TITLE")
            : translate("SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_FROM_SYSTEM_COLUMN_TITLE")}
        </label>
      </div>
      <div className="status-column col-columns row ml-0">
        <label>{translate("SALARY_CREATE_NEW_REGISTER_TABLE_STATUS_COLUMN_TITLE")}</label>
      </div>
      <div className="card-status-column col-columns row ml-0">
        <label>{translate("SALARY_CREATE_NEW_REGISTER_TABLE_CARD_STATUS_COLUMN_TITLE")}</label>
      </div>
      
    </div>
  );
}
