import "./assets/icon-button.scss";

import cx from "classnames";
import React, { ReactNode } from "react";

interface Props {
  readonly badge?: ReactNode;
  readonly className?: string;
  readonly onClick: () => void;
  readonly children: ReactNode;
}

export function IconButton({ badge, onClick, children, className }: Props) {
  return (
    <div className={cx("position-relative icon-button", className)} role="button" onClick={onClick}>
      {children}

      {badge && <div className="position-absolute icon-badge-container">{badge}</div>}
    </div>
  );
}
