import React from "react";
import { GroupBox } from "../ui/GroupBox";
import "./assets/fcy-pint-table-form.scss";
import { Input } from "../ui/Input";
import KdbLogo from "./assets/kdb.png";
import { Checkbox } from "../ui/Checkbox";
import { PrintTableFormContainer } from "../print/PrintTableFormContainer";

export function TransferFcyTablePrint() {
  return (
    <PrintTableFormContainer className="form mt-0 p-0 m-0" id="TransferFcyTablePrint">
      <div className="row ml-5">
        <div className="row flex-xl-nowrap mb-1">
          <div className="row mb-0 logo-fcy-print">
            <img src={KdbLogo} alt="" className=" ml-1 mb-0 logo-fcy-print" />
            <div>
              <p className="tittle-header ml-4 mb-0">
                Annex # 2 to the Public offer for rendering service on conversion of foreign
                currency of one type into foreign currency of another type <br />
                Приложение 2 к публично й оферте на оказание услуг по ковертации иностранной валюты
                одного вида в иностранную валюту другого вида
              </p>
            </div>
          </div>
          <div className="form-group ml-2 mb-0" id="box-header-fcy">
            <GroupBox className="form-group col border-left-0 transfer-number-header mb-0">
              <h1 className="mb-0 pl-11">Transfer application Number №</h1>
              <div className="row transfer-number-block">
                <p className="w-5 pl-11">Заявление на перевод №</p>
                <Input width={100} height={20} />
              </div>
            </GroupBox>
            <span className="form-group row mb-0 ml-1">* should be typewritten in english</span>
            <div className="row mt-0 mb-0 mt-0 ml-1">
              <Input placeholder="Date" width={150} height={30} />
              <Input name="date" width={355} height={30} />
            </div>
          </div>
        </div>
        <div className="row flex-xl-nowrap mb-0">
          <div className="form-group row">
            <div className="form-group mb-0 ml-1">
              <Input width={540} height={20} placeholder="50: applicant / заявитель" />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className="box-left-350x60 mb-0">
                <p className="pt-2 pl-2">
                  name /<br /> наименование
                </p>
              </GroupBox>
              <Input width={400} height={60} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <Input
                width={140}
                height={40}
                placeholder=" tax id / инн"
                inputClassName="p-0 pl-1"
              />
              <Input width={400} height={40} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <Input
                width={140}
                height={40}
                placeholder="address / адрес"
                inputClassName="p-0 pl-1"
              />
              <Input width={400} height={40} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className=" box-left-350x30 ">
                <p> credit account/кредитовый счет</p>
              </GroupBox>
              <Input width={400} height={40} />
            </div>
          </div>
          <div className="form-group row right-block">
            <div className="form-group mb-0 ml-1">
              <Input width={515} height={20} placeholder="59: beneficary / получатель" />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className="box-left-350x60">
                <p className="pt-2 pl-2"> name /наименование</p>
              </GroupBox>
              <Input width={375} height={60} className="input-fcy" />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className="box-left-350x30">
                <p className="pl-2"> id /инн (if any / если имеется)</p>
              </GroupBox>
              <Input width={375} height={40} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <Input
                width={140}
                height={40}
                placeholder="address / адрес"
                inputClassName="p-0 pl-1"
              />
              <Input width={375} height={40} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className="box-left-350x30">
                <p>credit account /кредитовый счет</p>
              </GroupBox>
              <Input width={375} height={40} />
            </div>
          </div>
        </div>
        <div className="form-group row flex-xl-nowrap mb-0 mt-0 mr-2">
          <div className="ml-1">
            <div className="form-group row mb-0">
              <Input width={540} height={20} placeholder="52: ordering bank / банк отправителя" />
            </div>
            <div className="form-group row mb-0">
              <GroupBox className="box-left-350x60">
                <p className="pt-2 pl-2">name /наименование</p>
              </GroupBox>
              <Input width={400} height={60} />
            </div>
            <div className="form-group row mb-0">
              <Input
                width={140}
                height={40}
                placeholder="address / адрес"
                inputClassName="p-0 pl-1"
              />
              <Input width={400} height={40} />
            </div>
            <div className="form-group row mb-0">
              <Input width={140} height={30} placeholder="swift code" inputClassName="p-0 pl-1" />
              <Input width={400} height={30} />
            </div>
          </div>
          <div className="mb-0 right-block">
            <div className="row ml-1 mb-0 ">
              <Input
                width={515}
                height={20}
                placeholder="57: beneficiary's bank/ банк получателся"
              />
            </div>
            <div className="form-group row ml-1 mb-0">
              <GroupBox className="box-left-350x60">
                <p className="pt-2 pl-2">name /наименование</p>
              </GroupBox>
              <Input width={375} height={60} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <Input
                width={140}
                height={40}
                placeholder="address / адрес"
                inputClassName="p-0 pl-1"
              />
              <Input width={375} height={40} />
            </div>
            <div className="form-group row ml-1 mb-0">
              <Input width={140} height={30} placeholder="swift code" inputClassName="p-0 pl-1" />
              <Input width={375} height={30} />
            </div>
          </div>
        </div>
        <div className="row flex-xl-nowrap mb-0">
          <div className="ml-1">
            <div className="form-group row flex-nowrap mb-0">
              <div className="form-group mb-0">
                <Input width={270} height={30} placeholder="32: currency / валюта" />
                <Input width={270} height={30} />
              </div>
              <div className="form-group mb-0">
                <Input width={270} height={30} placeholder="amount in digit / сумма цифрами" />
                <Input width={270} height={30} />
              </div>
            </div>
            <div className="mb-0">
              <Input width={500} height={30} placeholder="amount in words / сумма с прописью" />
              <Input width={500} height={30} />
            </div>
            <div className="form-group mt-0 mb-0">
              <div className=" row mb-0">
                <Input
                  width={540}
                  height={30}
                  className="mb-0"
                  placeholder="71: charges for transfer / коммисия за перевод"
                />
              </div>
              <div className="form-group row w-100 flex-nowrap mb-0 mt-2">
                <p className="tittle-fcy-table mb-5">
                  all charges are from beneficiary / our /все комиссии за счет получателя
                </p>
                <Checkbox className="ml-4" />
              </div>
              <div className="form-group row w-100 flex-nowrap mb-0">
                <p className="tittle-fcy-table mb-2">
                  all charges are from remitter / our /все комиссии за счет отправителя
                </p>
                <Checkbox className="ml-4" />
              </div>
              <div className="form-group row w-100 flex-nowrap mb-0">
                <p className="tittle-fcy-table mb-5">
                  charges or JSC "kbd bank uzbekistan" are from remitter / sha / комиссии AO "кдб
                  банк узбекситан" за счет отправителя
                </p>
                <Checkbox className="ml-4" />
              </div>
              <div className="form-group row w-100 flex-nowrap mb-0">
                <p className="tittle-fcy-table mb-0">
                  full no deduct/fnd/все комисии за счет отправителя(jpmorgan chase bank)
                </p>
                <Checkbox className="ml-4" />
              </div>
            </div>
          </div>
          <div className="mt-0 mb-0 right-block-1">
            <div className="form-group row mb-0 input-color">
              <GroupBox className="box-left-500x40">
                <p>correspondent bank of beneficiary's bank / банк-корреспондент банка получаеля</p>
              </GroupBox>
              <Input width={515} height={90} />
            </div>
            <div className="form-group row mb-0 mt-7">
              <Input
                width={200}
                height={20}
                className="input-fcy"
                placeholder="swift code / swift код"
              />
              <Input width={315} height={20} className="input-fcy" />
            </div>
            <div className="row">
              <Input width={515} height={90} />
            </div>
            <div className="form-group row mb-0">
              <Input
                width={200}
                height={20}
                className="input-fcy"
                placeholder="swift code / swift код"
              />
              <Input width={315} height={20} className="input-fcy" />
            </div>
            <div className="form-group row mb-0 mt-1">
              <Input
                width={125}
                height={30}
                className="input-fcy"
                placeholder="place / место"
                inputClassName="p-0 pl-2"
              />
              <Input width={290} height={30} className="input-fcy" placeholder="Tashkent" />
            </div>
          </div>
        </div>
        <div className="form-group row mt-2" id="offer-content">
          <div className="form-group flex-xl-nowrap row  mb-0 ">
            <Input
              width={400}
              height={20}
              className="input-text"
              placeholder="70: details of payment / детали платежа"
            />
            <Input width={665} height={20} />
          </div>
          <div className="form-group flex-xl-nowrap row mb-0">
            <Input width={750} height={30} className="input-text" inputClassName="border-right-0" />
            <Input width={315} height={30} inputClassName="border-left-0" />
          </div>
        </div>
        <div className="form-group row">
          <div className="form-group flex-xl-nowrap row  mb-0">
            <Input width={400} height={20} placeholder="remarks / замечания" />
            <Input width={665} height={20} />
          </div>
          <div className="form-group flex-xl-nowrap row mb-0">
            <Input width={750} height={30} inputClassName="border-right-0" />
            <Input width={315} height={30} inputClassName="border-left-0" />
          </div>
        </div>
        <div className="form-group row mr-5 ">
          <GroupBox className="text-offer-fcy-box form-group row">
            <p className="text-offer-fcy fs-3">
              Clause on joining to the agreement - Public Offer / Оговорка о присоединении к
              договору - Публичной оферте: Настоящим заявлением подтверждаю, что ознакомлен(а) с
              условиями Публичной оферты на оказание услуг по конвертации иностранной валюты одного
              вида в иностранную валюту другого вида, соглашаюсь с ними и присоединяюсь к ним,
              претензий в дальнейшем к Банку иметь не буду и даю согласие на безакцептное списание
              необходимых сумм за оказанные услуги в соответствии с Тарифами Банка / Herewith by
              this application I confirm that I have read the terms of Public Offer for rendering of
              service on conversion of foreign currency of one type into foreign currency of another
              type, agree with and join them, I will not have any claims against the Bank in the
              future and give my consent to the direct write-off of the necessary amounts for the
              services rendered in accordance with the Bank’s Tariffs.
            </p>
          </GroupBox>
        </div>
        <div className="form-group row mb-0">
          <div className="form-group flex-xl-nowrap row mb-0">
            <Input
              width={850}
              height={20}
              placeholder="please debit our account# / просим списать с нашего расчетного счета №"
            />
            <Input width={265} height={20} />
          </div>
          <GroupBox className="text-fcy-charges form-group row">
            <p>
              for transfer charges as per applicable tariffs / комиссионное вознограждение за
              перевод соглосно действующим тарифам
            </p>
          </GroupBox>
        </div>
        <div className="form-group row mb-0">
          <div className="form-group flex-xl-nowrap row">
            <GroupBox className="box-left-300x40">
              <p>
                {" "}
                first signature / head <br /> первая подпись/ руководителя
              </p>
            </GroupBox>
            <GroupBox className="box-left-450x40">
              <p>
                {" "}
                second signature (if applicable) / chief accountant <br /> вторая подпись (если
                предусмотрена) / главный бухгалтер
              </p>
            </GroupBox>
            <GroupBox className="box-left-300x40">
              <p> stamp (if required) / штамп (по требованию)</p>
            </GroupBox>
          </div>
        </div>
        <div className="form-group flex-nowrap row mb-0" id="initials-fcy-table">
          <div className="form-group flex-xl-nowrap row ">
            <span>Ф.И.О/</span>
            <input className="border-right-0 border-top-0 border-left-0 border-light ml-2" />
          </div>
          <div className=" form-group flex-xl-nowrap row ml-7">
            <span>Ф.И.О/</span>
            <input className="border-right-0 border-top-0 border-left-0 border-light ml-2 " />
          </div>
        </div>
        <div className="form-group flex-nowrap row mb-0" id="initials-fcy-table">
          <div className="form-group flex-xl-nowrap row ">
            <span>Name/</span>
            <input className="border-right-0 border-top-0 border-left-0 border-light ml-2 " />
          </div>
          <div className=" form-group flex-xl-nowrap row ml-7 ">
            <span>Name/</span>
            <input className="border-right-0 border-top-0 border-left-0 border-light ml-2" />
          </div>
        </div>
        <div className="form-group mt-4 mb-0">
          <span className="bank-line-fcy justify-content-center">
            FILLED BY THE BANK / ЗАПОЛНЯЕТСЯ БАНКОМ
          </span>
          <div className="form-group flex-xl-nowrap row">
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p> csd(authentification and verification)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p>aad(signature/stamp verified)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p>ccd(checked)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p>payments(transition input)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p>management(approval)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
            <div className="form-group row ml-3 mb-0">
              <GroupBox className="box-left-175x30">
                <p>authorization and swift(swift sending)</p>
              </GroupBox>
              <Input width={170} height={30} />
            </div>
          </div>
        </div>
      </div>
    </PrintTableFormContainer>
  );
}
