import Package from "../../package.json";

import { SWRConfig } from "swr";
import { useDispatch } from "react-redux";
import React, { ReactNode, useCallback, useEffect } from "react";

import { ApiProvider } from "../api/ApiContext";
import { I18nProvider } from "../i18n/I18nContext";
import { RoleProvider } from "../helpers/RoleContext";
import { tokenSelector } from "../reducers/authReducer";
import { ThemeProvider } from "../theme/ThemeContext";
import { useAuthContext } from "../api/auth/AuthContext";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import {
  appLanguageSelector,
  changeVersion,
  switchTheme,
  themeSelector,
} from "../reducers/appReducer";
import {
  userEmployeeIdSelector,
  userRoleSelector,
  userTaxNoSelector,
} from "../reducers/userReducer";

interface Props {
  readonly children: ReactNode;
}

export function ProviderContainer({ children }: Props) {
  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const theme = useShallowEqualSelector(themeSelector);
  const role = useShallowEqualSelector(userRoleSelector);
  const taxNo = useShallowEqualSelector(userTaxNoSelector);
  const language = useShallowEqualSelector(appLanguageSelector);
  const employeeId = useShallowEqualSelector(userEmployeeIdSelector);

  const { logout } = useAuthContext();

  const changeThemeHandler = useCallback((x) => dispatch(switchTheme({ theme: x })), [dispatch]);

  useEffect(() => {
    dispatch(changeVersion({ version: Package.version }));
  }, [dispatch]);

  return (
    <I18nProvider data={{ language }}>
      <ApiProvider data={{ token, language, employeeId, logout, taxNo }}>
        <RoleProvider data={{ role }}>
          <ThemeProvider data={{ theme, onChangeTheme: changeThemeHandler }}>
            <SWRConfig value={{ revalidateOnFocus: false }}>{children}</SWRConfig>
          </ThemeProvider>
        </RoleProvider>
      </ApiProvider>
    </I18nProvider>
  );
}
