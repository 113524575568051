import { useMemo } from "react";

import { AccountApi } from "./AccountApi";
import { useApiBase } from "../ApiContext";

interface Props {
  readonly AccountApi: AccountApi;
}

export function useAccountContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new AccountApi(data), [data]);

  return {
    AccountApi: api,
  };
}
