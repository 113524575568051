import { AppFilter, AppFilterProps } from "./AppFilter";
import { ConversionLcyToFcyQueryProps, TransactionType } from "../api/conversion/ConversionDTO";
import { formatDateFromUrl, formatDateToFilter } from "../utils/DateUtils";
import { GetConversionDocumentsListQuery } from "../api/conversion/ConversionApi";

export enum ConversionFilterTabs {
  Favorites = "favorites",
  ExchangeRates = "exchange-rates",
  FcyToUzsConversion = "fcy-to-uzs-conversion",
  UzsToFcyConversion = "uzs-to-fcy-conversion",
  FcyToFcyConversion = "fcy-to-fcy-convexrsion",
}

export enum ConversionFcyToUzsType {
  SellFcy = "sell-fcy",
  SellUzs = "sell-uzs",
}

interface ConversionFilterProps extends AppFilterProps<ConversionFilterTabs> {
  readonly createRequestFcyTo?: boolean;
  readonly createRequestFcyToFcy?: boolean;
  readonly createRequestUzsTo?: boolean;
  readonly transferedUzsToFcy?: boolean;
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly fromDateFcyTo?: string;
  readonly toDateFcyTo?: string;
  readonly fromDateUzsTo?: string;
  readonly toDateUzsTo?: string;
  readonly status?: string;
  readonly reservedAmountInUZS?: string;
  readonly conversionFcyToUzsType?: ConversionFcyToUzsType;
}

export class ConversionFilter extends AppFilter<ConversionFilterTabs> {
  private readonly createRequestFcyTo: boolean;
  private readonly createRequestFcyToFcy: boolean;
  private readonly createRequestUzsTo: boolean;
  private readonly transferedUzsToFcy: boolean;
  private readonly fromDate?: Date;
  private readonly toDate?: Date;
  private readonly fromDateFcyTo?: Date;
  private readonly toDateFcyTo?: Date;
  private readonly fromDateUzsTo?: Date;
  private readonly toDateUzsTo?: Date;
  private readonly status?: string;
  private readonly reservedAmountInUZS?: string;
  private readonly conversionFcyToUzsType: ConversionFcyToUzsType;
  public constructor(
    {
      tab,
      createRequestFcyTo,
      createRequestFcyToFcy,
      createRequestUzsTo,
      transferedUzsToFcy,
      fromDate,
      toDate,
      fromDateFcyTo,
      toDateFcyTo,
      fromDateUzsTo,
      toDateUzsTo,
      status,
      reservedAmountInUZS,
      conversionFcyToUzsType,
      ...props
    } = {} as ConversionFilterProps,
  ) {
    super({ ...props, tab: tab || ConversionFilterTabs.FcyToUzsConversion });
    this.createRequestFcyTo = Boolean(createRequestFcyTo);
    this.createRequestFcyToFcy = Boolean(createRequestFcyToFcy);
    this.createRequestUzsTo = Boolean(createRequestUzsTo);
    this.transferedUzsToFcy = Boolean(transferedUzsToFcy);
    this.conversionFcyToUzsType = conversionFcyToUzsType || ConversionFcyToUzsType.SellFcy;
    this.fromDate = new Date();
    this.toDate = new Date();
    this.fromDateFcyTo = new Date();
    this.toDateFcyTo = new Date();
    this.fromDateUzsTo = new Date();
    this.toDateUzsTo = new Date();
    this.status = "A";
    this.reservedAmountInUZS = reservedAmountInUZS;

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }

    if (toDateFcyTo) {
      this.toDateFcyTo = formatDateFromUrl(toDateFcyTo);
    }

    if (fromDateFcyTo) {
      this.fromDateFcyTo = formatDateFromUrl(fromDateFcyTo);
    }
    if (toDateUzsTo) {
      this.toDateUzsTo = formatDateFromUrl(toDateUzsTo);
    }

    if (fromDateUzsTo) {
      this.fromDateUzsTo = formatDateFromUrl(fromDateUzsTo);
    }

    if (status) {
      this.status = status;
    }
  }
  public getCreateRequestFcyTo(): boolean {
    return this.createRequestFcyTo;
  }
  public getCreateRequestFcyToFcy(): boolean {
    return this.createRequestFcyToFcy;
  }
  public getConversionFcyToUzsType(): ConversionFcyToUzsType {
    return this.conversionFcyToUzsType;
  }
  public getCreateRequestUzsTo(): boolean {
    return this.createRequestUzsTo;
  }
  public getTransferedUzsToFcy(): boolean {
    return this.transferedUzsToFcy;
  }
  public getReservedAmountInUZS(): string | undefined {
    return this.reservedAmountInUZS;
  }
  public getLcyToFcyFilterProps(): ConversionLcyToFcyQueryProps {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      state: this.status,
    };
  }
  public getFCYtoFCYDocumentsListProps(): GetConversionDocumentsListQuery {
    return {
      transactionType: TransactionType.FCYtoFCYConversion,
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      state: this.status,
    };
  }
  public getFCYtoUZSDocumentsListProps(): GetConversionDocumentsListQuery {
    return {
      transactionType: TransactionType.FCYtoUZSConversion,
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      state: this.status,
    };
  }
  public getFilterForPage() {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
      status: this.status,
    };
  }
}
