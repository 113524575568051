import React from "react";
import { useField } from "formik";

import { readXlsx } from "../../utils/FileUtils";
import { Button, ButtonProps } from "../ui/Button";
import { FileAccept, useSelectFile } from "../../hooks/useSelectFile";

interface Props extends Omit<ButtonProps, "onClick"> {
  readonly name: string;
  readonly readFromExcel?: (value) => void;
}

export function UploadPayrollExcelButtonField({ name, readFromExcel, ...props }: Props) {
  const [, , helpers] = useField(name);

  const fileSelector = useSelectFile({
    accept: [FileAccept.Excel],
    onSelect: (x) =>
      readXlsx(x[0]).then((x) => {
        helpers.setValue(x);
        readFromExcel && readFromExcel(x);
      }),
  });

  return (
    <Button
      type="button"
      labelCode="UPLOAD_PAYROLL_EXCEL_BUTTON_TITLE"
      {...props}
      onClick={() => fileSelector.open()}
    />
  );
}
