import { Form, Formik } from "formik";
import React from "react";
import { noop } from "lodash";
import { Input } from "../ui/Input";
import "./assets/corporate-card-print-table.scss";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userFullNameSelector, userOtpKeySerialNumberSelector } from "../../reducers/userReducer";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import KDBLogo from "./assets/kdb.png";
import { GroupBox } from "../ui/GroupBox";

interface Props {
  readonly data: any[];
  readonly registerInfo: any;
}

export function SalaryPrintTable({ data, registerInfo }: Props) {
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const clientPostCode = useShallowEqualSelector(userOtpKeySerialNumberSelector);

  let totalAmount = 0;

  return (
    <Formik initialValues={data} onSubmit={noop}>
      {() => (
        <Form id="SalaryPrintTable">
          <div className="form-group col corporate-card-print-table">
            <div className="mb-2 text-center ml-4">
              <b>VEDEMOST LIST</b>
            </div>
            <div className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0">
              <Input width={80} height={60} value={"#"} className="fs-4" />
              <Input width={300} height={60} value={fullName} />
              <Input
                width={500}
                height={60}
                placeholder={`From account: ` + registerInfo.transitAccount}
              />
              <GroupBox>{registerInfo.operationDate}</GroupBox>
            </div>
            <div style={{ height: "20px" }} />
            <div className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0">
              <Input width={80} placeholder="№" />
              <Input width={300} placeholder="Account" />
              <Input width={500} placeholder="Full Name" />
              <Input width={150} placeholder="Amount" />
            </div>
            {data?.map(
              (x, index) => {
                totalAmount = totalAmount + Number(x.amount);
                return (
                  <div
                    className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0"
                    key={index}
                  >
                    <Input width={80} value={index + 1} />
                    <Input width={300} value={x.accountNumber} />
                    <Input width={500} value={x.fullName} />
                    <Input width={150} value={x.amount ? floatFormat(addZeros(x.amount)) : 0} />
                  </div>
                );
              },
              // eslint-disable-next-line react/jsx-key
            )}
            <div className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0">
              <Input width={80} placeholder="Total" />
              <Input width={300} />
              <Input width={500} />
              <Input
                width={150}
                value={
                  registerInfo.payrollAmount ? floatFormat(addZeros(registerInfo.payrollAmount)) : 0
                }
              />
            </div>
          </div>

          <div
            style={{
              marginTop: "140px",
            }}
          >
            <div className="form-group col corporate-card-print-table mt-4">
              <div className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0">
                <div
                  style={{
                    width: "1000px",
                  }}
                >
                  <b>Confirmed by the OTP key serial/</b>
                  <p>Подтвержден ОТП ключом серийный № {clientPostCode} </p>
                </div>
                <div
                  style={{
                    width: "600px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b>VIA INTERNET BANKING</b>
                  <img width={240} src={KDBLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
