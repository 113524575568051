import React, { useMemo } from "react";
import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { ApplicationProps } from "../../api/trade-finance/TradeFinanceDTO";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly data: ApplicationProps[];
  readonly loading: boolean;
  readonly setDocumentsId: (value) => void;
}

export function TradeFinanceTable({ data, setDocumentsId, loading }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<ApplicationProps>[] = [
      {
        width: 150,
        accessor: "documentNumber",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_APP_NO_COLUMN_TITLE"),
      },
      // {
      //   width: 100,
      //   accessor: "confirmingBank",
      //   Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_APPLICANT_COLUMN_TITLE"),
      // },
      {
        width: 150,
        accessor: "beneficiaryName",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_BENEFICIARY_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
      },
      {
        width: 150,
        accessor: "currency",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "amount",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "date",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_DATE_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "advisingBank",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_ADVISING_BANK_TITLE"),
      },
      {
        width: 100,
        accessor: "documentStatus",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    return list;
  }, [translate]);

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setDocumentsId}
      withCheckbox={true}
      loading={loading}
      tableClassName="bg-white"
    />
  );
}
