import React from "react";
import { noop } from "lodash";
import { Form, Formik } from "formik";

import { InputField } from "../form/InputField";
import { DatePickerField } from "../form/DatePickerField";
import { RadioGroupField } from "../form/RadioGroupField";
import { GroupBox } from "../ui/GroupBox";

export function CorporateCardOpeningVisaForm() {
  return (
    <Formik initialValues={{}} onSubmit={noop}>
      {() => (
        <Form className="d-flex flex-column br-1 p-4 bg-white">
          <GroupBox className="h-100" title="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PAGE_TITLE">
            <div className="row">
              <div className="w-100 d-flex flex-wrap mb-3">
                <InputField
                  name="firstName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_FIRSTNAME_FIELD_TITLE"
                />
                <InputField
                  name="lastName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_LASTNAME_FIELD_TITLE"
                />
                <InputField
                  name="middleName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_MIDDLENAME_FIELD_TITLE"
                />
              </div>
              <div className="w-100 d-flex flex-wrap mb-3">
                <div className="mb-3 w-100 d-flex">
                  <div className="col-4 d-flex pl-0 ">
                    <InputField
                      name="citizenship"
                      className="col-4"
                      label="ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CITIZENSHIP_FIELD_LABEL_TITLE"
                    />
                    <div className="flex-fill d-flex pt-lg-5">
                      <RadioGroupField
                        name="resedent"
                        items={[
                          {
                            value: "uzCard",
                            title: "Resedent",
                          },
                          {
                            value: "humo",
                            title: "No Resedent",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <InputField
                    name="address"
                    className="col-4"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_ADDRESS_TITLE"
                  />

                  <InputField
                    name="passwordVerify"
                    className="col-4"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VERIFY_FIELD_TITLE"
                  />
                </div>

                <div className="w-100 d-flex flex-wrap mb-3">
                  <DatePickerField name="dateOfBirth" className="col-3" label="Date Of Birth" />
                  <InputField
                    name="positionHeld"
                    className="col-3"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_POSITION_HELD_TITLE"
                  />
                  <InputField
                    name="phone"
                    className="col-3"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PHONE_FIELD_TITLE"
                    disabled={true}
                  />

                  <InputField
                    name="workPhone"
                    className="col-3"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_WORK_PHONE_FIELD_TITLE"
                  />
                </div>
                <div className="w-100 d-flex">
                  <div className="col-4 d-flex pl-0">
                    <InputField
                      name="workPhone"
                      className="col-4"
                      label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSPORT_SERIES_FIELD_TITLE"
                    />
                    <InputField
                      name="workPhone"
                      className="flex-fill"
                      label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE"
                    />
                  </div>

                  <InputField
                    name="workPhone"
                    className="col-5"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_ISSUE_FIELD_TITLE"
                  />

                  <DatePickerField
                    name="dateOfBirth"
                    className="col-3"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_ISSUE_FIELD_TITLE"
                  />
                </div>
              </div>
            </div>
          </GroupBox>
        </Form>
      )}
    </Formik>
  );
}
