import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import cx from "classnames";
import { PaymentClaimTypePicker } from "../payment-claim/PaymentClaimTypePicker";

interface Props {
  readonly data: any;
  readonly loading: boolean;
  readonly setDocumentId?: (value: any) => void;
  readonly withCreatorsAndApprovals?: boolean;
}

export function SentPaymentClaimTable({
  data = [],
  loading,
  setDocumentId,
  withCreatorsAndApprovals,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 80,
        accessor: "ndoc",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_COLUMN_TITLE"),
      },
      {
        width: 80,
        accessor: "ddoc",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_DATE"),
      },
      {
        width: 80,
        accessor: "transactionDate",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE_TITLE"),
      },
      {
        width: 100,
        accessor: "payerName",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_NAME_OF_THE_PAYER_TITLE"),
      },
      {
        width: 100,
        accessor: "payerAccount",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_ACCOUNT_TITLE"),
      },
      {
        width: 80,
        accessor: "payerTin",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_TAX_ID_TITLE"),
      },
      {
        width: 80,
        accessor: "payerBranch",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_MFO_TITLE"),
      },
      {
        width: 100,
        accessor: "amount",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_AMOUNT_COLUMN_TITLE"),
        Cell: (row) => <div>{row.value ? floatFormat(addZeros(row.value)) : ""}</div>,
      },
      {
        width: 100,
        accessor: "type",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_COLUMN_TITLE"),
        Cell: (row) => (
          <div
            className={cx(
              "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center br-1 text-light",
              {
                "gray-status": row.value === "03",
                "green-status": row.value === "01",
              },
            )}
            style={{ textAlign: "center" }}
          >
            <PaymentClaimTypePicker type={row.value} />
          </div>
        ),
      },
      {
        width: 90,
        accessor: "state",
        Header: translate("PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          width: 100,
          accessor: "createUser",
          Header: translate("ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE"),
        },
        {
          width: 100,
          accessor: "approvalUser",
          Header: translate("ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }
    return list;
  }, [translate, withCreatorsAndApprovals]);

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onSelect={setDocumentId}
      withCheckbox={true}
      tableClassName="bg-white"
    />
  );
}
