import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { ConversionApi } from "./ConversionApi";

interface Props {
  readonly ConversionApi: ConversionApi;
}

export function useConversionContext(): Props {
  const data = useApiBase();
  const api = useMemo(() => new ConversionApi(data), [data]);

  return {
    ConversionApi: api,
  };
}
