import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { CorporateCardsApi } from "./CorporateCardsApi";

interface Props {
  readonly CorporateCardsApi: CorporateCardsApi;
}

export function useCorporateCardsContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new CorporateCardsApi(data), [data]);

  return {
    CorporateCardsApi: api,
  };
}
