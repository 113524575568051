import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { AppClock } from "./AppClock";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  authDateExpiredSelector,
  authDateSelector,
  resetToken,
  setAuthDateExpired,
  tokenSelector,
} from "../../reducers/authReducer";
import { useAuthContext } from "../../api/auth/AuthContext";
import { ViewMode } from "../../api/dto/AppDTO";
import { changeViewMode } from "../../reducers/appReducer";
import { clearLendingGeneralInfo, clearRole, clearUser } from "../../reducers/userReducer";

export function AppTimerWrapper() {
  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const authDate = useShallowEqualSelector(authDateSelector);
  const { logout } = useAuthContext();
  const authDateExpired = useShallowEqualSelector(authDateExpiredSelector);

  const showOtp = useMemo(() => token && authDateExpired, [authDateExpired, token]);

  const initialTime = useMemo(() => {
    if (authDate) {
      if ((Date.now() - authDate) / 1000 > 1860) {
        logout();
      } else {
        const passTime = Date.now() - authDate;

        return 1800 - passTime / 1000;
      }
    }

    return 0;
  }, [authDate, logout]);

  return (
    <div className="d-flex  align-items-center">
      {!showOtp ? (
        <AppClock
          initialTime={initialTime}
          token={token}
          onComplete={() => {
            dispatch(clearRole());
            dispatch(clearUser());
            dispatch(setAuthDateExpired({ state: true }));
            dispatch(resetToken());
            dispatch(clearLendingGeneralInfo());
            dispatch(changeViewMode({ viewMode: ViewMode.Undetermined }));
          }}
        />
      ) : (
        <span className="font-weight-bold fs-4">00:00</span>
      )}
    </div>
  );
}
