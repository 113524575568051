import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { TradeFinanceApi } from "./TradeFinanceApi";

interface Props {
  readonly TradeFinanceApi: TradeFinanceApi;
}

export function useTradeFinanceContext(): Props {
  const data = useApiBase();
  const api = useMemo(() => new TradeFinanceApi(data), [data]);

  return {
    TradeFinanceApi: api,
  };
}
