import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { CorrespondentFCYProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly loading?: boolean;
  readonly data: CorrespondentFCYProps[];
  readonly onCorrespondentSelect?: (value) => void;
}

export function CorrespondentsFCYTable({ data = [], loading, onCorrespondentSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<CorrespondentFCYProps>[] = [
      {
        width: 150,
        accessor: "beneficiaryAccount",
        Header: translate("TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              style={{ cursor: "pointer" }}
              onClick={() => onCorrespondentSelect && onCorrespondentSelect(row.value)}
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 250,
        accessor: "beneficiaryName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 50,
        accessor: "beneficiarySwiftCode",
        Header: translate(
          "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE",
        ),
      },
    ];

    return list;
  }, [onCorrespondentSelect, translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
