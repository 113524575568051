import cx from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import { TabPage } from "../tabs/TabPage";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import { Paginator } from "../ui/Paginator";
import { useRole } from "../../helpers/RoleContext";
import { InquiryFilterForm } from "./InquiryFilterForm";
import { InquiryStandardForm } from "./InquiryStandardForm";
import { InquiryNonStandardForm } from "./InquiryNonStandardForm";
import { AccountsFilter, InquirySubmitType } from "../../filters/AccountsFilter";
import { ApprovalInquiryType, BranchInformationProps } from "../../api/account/AccountDTO";
import { AccountInquiryTable } from "./AccountInquiryTable";
import { formatDateToUrl } from "../../utils/DateUtils";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { IndividualInquiryStandardForm } from "./IndividualInquiryStandardForm";
import { FileControls } from "../ui/FileControls";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userSelector } from "../../reducers/userReducer";
import { useAccountContext } from "../../api/account/AccountContext";
import { showError } from "../../utils/NotificationUtils";

export enum tabTypes {
  Corporate = "corporate",
  Individual = "individual",
}

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeTab: (tab: InquirySubmitType) => void;
  readonly tabType?: tabTypes;
}

export function InquiryTab({ filter, onChangeTab, tabType = tabTypes.Corporate }: Props) {
  const { isCorporate } = useRole();
  const user = useShallowEqualSelector(userSelector);
  const [selectDocument, setSelectDocument] = useState({
    registrationNumber: "",
    beginDate: "",
    subject: "",
    message: "",
    accountName: "",
    branchCode: "",
  });

  const { PdfReportApi } = usePdfReportContext();
  const { AccountApi } = useAccountContext();

  const submitType = useMemo(() => filter.getSubmitType(), [filter]);

  const isUnselectSubmitType = useMemo(
    () => submitType === InquirySubmitType.NonStandart || InquirySubmitType.Standard,
    [submitType],
  );

  const isStandardType = useMemo(() => submitType === InquirySubmitType.Standard, [submitType]);
  const isNonStandardType = useMemo(() => submitType === InquirySubmitType.NonStandart, [
    submitType,
  ]);
  const filterInitialValues = useMemo(() => filter.getInquiryFilterForm(), [filter]);
  const locationHelpers = useLocationHelpers();

  const onSaveToPdfClick = useCallback(() => {
    AccountApi.getBranchesInformation()
      .then((response: BranchInformationProps[]) => {
  
        const branch = response
        .filter(
          (item: BranchInformationProps) => item.branchCode === selectDocument.branchCode
        )[0]
        .nameENG
        .replace(/.*?\bKDB\b/, 'KDB');
  
        const body = {
          accountName: user?.fullName,
          registrationNumber: selectDocument.registrationNumber,
          branchCode: branch,
          subject: selectDocument.subject,
          message: selectDocument.message,
          beginDate: selectDocument.beginDate,
        };
        PdfReportApi.createNonStandardDocument("Non-Standard-Inquiry", body);
      })
      .catch(showError);
  }, [selectDocument, PdfReportApi, user, AccountApi]);

  return (
    <TabPage
      contentClassName="bg-transparent border-0"
      className={cx("px-4", { "py-7": !isUnselectSubmitType })}
      headerClassName="d-flex justify-content-center align-items-lg-end"
      headerComponent={
        <>
          {isStandardType && (
            <InquiryFilterForm
              initialValues={filterInitialValues}
              onSubmit={(value) =>
                locationHelpers.replaceQuery({
                  toDate: formatDateToUrl(value.toDate),
                  fromDate: formatDateToUrl(value.fromDate),
                })
              }
            />
          )}
          {isNonStandardType && (
            <InquiryFilterForm
              initialValues={filterInitialValues}
              onSubmit={(value) =>
                locationHelpers.replaceQuery({
                  toDate: formatDateToUrl(value.toDate),
                  fromDate: formatDateToUrl(value.fromDate),
                })
              }
            />
          )}
        </>
      }
      footerComponent={
        <>
          {isNonStandardType && (
            <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
              <FileControls onSaveToPdfClick={onSaveToPdfClick} isMinusTransferLcyType={true} />
              <Paginator filter={filter} />
            </div>
          )}
          {isStandardType && (
            <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
              <FileControls onSaveToPdfClick={onSaveToPdfClick} isMinusTransferLcyType={true} />
              <Paginator filter={filter} />
            </div>
          )}
        </>
      }
    >
      <SubTabs
        activeTab={submitType}
        onChangeTab={onChangeTab}
        defaultTab={InquirySubmitType.NonStandart}
      >
        <TabPane
          key={InquirySubmitType.NonStandart}
          tab="ACCOUNT_INQUIRY_NON_STANDART_INQUIRIES_BUTTON_TITLE"
        >
          <AccountInquiryTable
            onSelect={setSelectDocument}
            filter={filter}
            inquiryType={ApprovalInquiryType.NonStandard}
          />
        </TabPane>

        <TabPane
          show={!isCorporate}
          key={InquirySubmitType.SubmitNonStandard}
          tab="ACCOUNT_INQUIRY_SUBMIT_NON_STANDARD_INQUIRY_BUTTON_TITLE"
        >
          <InquiryNonStandardForm
            onChangeTab={onChangeTab}
            individual={tabType === tabTypes.Individual}
          />
        </TabPane>

        <TabPane
          key={InquirySubmitType.Standard}
          tab="ACCOUNT_INQUIRY_STANDART_INQUIRIES_BUTTON_TITLE"
        >
          <AccountInquiryTable
            onSelect={setSelectDocument}
            filter={filter}
            inquiryType={ApprovalInquiryType.Standard}
          />
        </TabPane>

        <TabPane
          key={InquirySubmitType.SubmitStandard}
          tab="ACCOUNT_INQUIRY_SUBMIT_STANDARD_INQUIRY_BUTTON_TITLE"
        >
          {tabType === tabTypes.Corporate ? (
            <InquiryStandardForm filter={filter} />
          ) : (
            <IndividualInquiryStandardForm filter={filter} />
          )}
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
