import React from "react";
import { Form, Formik } from "formik";

import { Checkbox } from "../ui/Checkbox";
import { Button, ButtonColor } from "../ui/Button";
import { DatePickerField } from "../form/DatePickerField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { PayrollFilterFormProps } from "../../api/salary/SalaryDTO";
import { PayrollStatusPickerField } from "../form/PayrollStatusPickerField";

interface Props {
  readonly showCreatorsAndApprovals?: boolean;
  readonly initialValues: PayrollFilterFormProps;
  readonly onSubmit: (values: PayrollFilterFormProps) => void;
  readonly onChangeCreatorsAndApprovals?: (value: boolean) => void;
}

export function PayrollFilterForm({
  onSubmit,
  initialValues,
  showCreatorsAndApprovals,
  onChangeCreatorsAndApprovals,
}: Props) {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <Form className="my-5">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <DatePickerField
                width={100}
                name="fromDate"
                className="pr-5"
                size={SizeType.Small}
                selectsStart={true}
                maxDate={values.toDate}
                labelPosition={PositionType.Left}
                label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                onChange={() => handleSubmit()}
              />
              <DatePickerField
                width={100}
                name="toDate"
                className="pr-5"
                size={SizeType.Small}
                selectsEnd={true}
                minDate={values.fromDate}
                labelPosition={PositionType.Left}
                label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                onChange={() => handleSubmit()}
              />
              <div className="d-flex input-group">
                <PayrollStatusPickerField
                  name="status"
                  minWidth={250}
                  size={SizeType.Small}
                  labelPosition={PositionType.Left}
                  label="TRANSFERS_SALARY_CLAIM_FILTER_BY_STATUS_FIELD_LABEL_TITLE"
                  onChange={() => handleSubmit()}
                />
                <div className="input-group-append">
                  <Button
                    size={SizeType.Small}
                    color={ButtonColor.Orange}
                    labelCode="TRANSFERS_SALARY_SEARCH_BUTTON_TITLE"
                  />
                </div>
              </div>
            </div>
            {Boolean(onChangeCreatorsAndApprovals) && (
              <Checkbox
                value={Boolean(showCreatorsAndApprovals)}
                label="TRANSFERS_SALARY_SHOW_CREATORS_CHECKBOX_TITLE"
                onChange={({ target }) => onChangeCreatorsAndApprovals!(target.checked)}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
