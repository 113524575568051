import { Dict } from "../dto/AppDTO";
import { CardTypes } from "../../components/card/CardDropdownItem";

export interface AccountProps {
  readonly mfo: string;
  readonly branch: string;
  readonly currency: string;
  readonly accountCardType: CardTypes;
  readonly accounts: string;
  readonly openDate: string;
  readonly visaExpdt: string;
  readonly accountType: string;
  readonly accountName: string;
  readonly secondAccountName: string;
  readonly accountNumber: string;
  readonly accountStatus: string;
  readonly accountBalance: string;
  readonly localCardBalance: string;
  readonly visaCardNumber: string;
  readonly smartVistaExpdt: string;
  readonly lastTransactionDate: string;
  readonly smartVistaCardNumber: string;
  readonly forId: string;
  readonly inquiry: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
}

export interface HistoryAccountProps {
  readonly accounts: string;
  readonly currency: string;
  readonly lastTransactionDate: string;
  readonly accountType: string;
}

export interface AccountFilterFormProps {
  readonly balanceAsOfDate: Date;
  readonly excludeZeroBalance?: boolean;
  readonly includeClosedAccounts?: boolean;
}

export interface AccountListQuery {
  readonly pageNumber: number;
  readonly pageSize: number;
}

export interface AccountListQueryProps extends AccountListQuery {
  readonly balanceAsOfDate?: string;
  readonly excludeZeroBalance?: string;
  readonly includeClosedAccounts?: string;
}

export interface AccountStatementProps {
  readonly status: string;
  readonly balance: string;
  readonly currency: string;
  readonly pageCount: string;
  readonly senderMFO: string;
  readonly clientName: string;
  readonly debitAmount: string;
  readonly receiverMFO: string;
  readonly paymentCode: string;
  readonly receiverName: string;
  readonly createUserId: string;
  readonly creditAmount: string;
  readonly documentType: string;
  readonly transactionId: string;
  readonly senderAccount: string;
  readonly paymentDetail: string;
  readonly documentNumber: string;
  readonly receiverAccount: string;
  readonly transactionDate: string;
  readonly receiverTaxNumber: string;
  readonly transactionDateTime: string;
}

export interface AccountStatementListQueryProps {
  readonly page: number;
  readonly toDate?: string;
  readonly fromDate?: string;
  readonly accountNumber?: string;
}

export interface AccountStatementCountQueryProps {
  readonly page?: number;
  readonly toDate?: string;
  readonly fromDate?: string;
  readonly accountNumber?: string;
}

export interface getAccountStatementsQuery {
  readonly accountNumber: string;
  readonly amount?: string;
  readonly correspondentAccount?: string;
  readonly fromDate: string;
  readonly toDate: string;
  readonly pageSize: number;
  readonly pageNumber: number;
  readonly status?: string;
  readonly response?: any;
  readonly showModalText?: any;
  readonly messageKey?: string;
  readonly customerSequence?: string;
  readonly flagToHugeTurnover?: string;
}

export interface getAccountMT940Query {
  readonly accountNumber?: string;
  readonly amount?: string;
  readonly correspondentAccount?: string;
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly pageSize?: number;
  readonly pageNumber?: number;
  readonly status?: string;
  readonly response?: any;
  readonly showModalText?: any;
  readonly messageKey?: string;
  readonly isAnsi?: boolean;
  readonly customerSequence?: string;
  readonly flagToHugeTurnover?: string;
}

export interface getAccountStatementsPropsMT940 {
  readonly text: any;
  readonly blob: string | number | boolean;
  readonly json: any;
  arrayBuffer: any;
  size: any;
  slice: any;
  stream: any;
  textblob: any;
  type: any;
  blobMain: any;
}

export interface getAccountStatementsProps {
  readonly beginBalance: string;
  readonly creditAmount: string;
  readonly debitAmount: string;
  readonly endBalance: string;
  readonly clientInfo?: string;
  readonly phoneNumber?: string;
  readonly accountNumber?: string;
  readonly accountName?: string;
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly lastTransactionDate?: string;
  readonly currency?: string;
  readonly accountType?: string;
  readonly openingDate?: string;
  readonly openingBalance?: string;
  readonly list: [
    {
      readonly balance: string;
      readonly bankTellerId: string;
      readonly bankTellerSequence: string;
      readonly clientName: string;
      readonly createUserId: string;
      readonly creditAmount: string;
      readonly currency: string;
      readonly debitAmount: string;
      readonly documentNumber: string;
      readonly documentType: string;
      readonly pageCount: string;
      readonly paymentCode: string;
      readonly paymentDetail: string;
      readonly receiverAccount: string;
      readonly receiverBankName: string;
      readonly receiverMFO: string;
      readonly receiverName: string;
      readonly receiverTaxNumber: string;
      readonly senderAccount: string;
      readonly senderBankName: string;
      readonly senderMFO: string;
      readonly senderTaxNumber: string;
      readonly status: string;
      readonly totalPage: string;
      readonly totalRow: string;
      readonly transactionDate: string;
      readonly transactionDateTime: string;
      readonly transactionId: string;
      readonly validDate: string;
      readonly receivedDetails: string;
    },
  ];
  readonly closingDate?: string;
  readonly total?: string;
  readonly closeDebit?: string;
  readonly closeCredit?: string;
  readonly closingBalance?: string;
  readonly blob?: any;
}

export default interface IBlobFile {
  BinaryString: string;
  FileType: string;
  Name: string;
  Size: number;
}

export interface AccountStatementBranchQueryProps {
  readonly page?: number;
  readonly status?: string;
  readonly toDate?: string;
  readonly fromDate?: string;
  readonly accountNumber?: string;
}

export interface AccountInquiryFilterFormProps {
  readonly toDate: Date;
  readonly fromDate: Date;
}

export interface AccountCoverFilterFormProps {
  readonly toDate: Date;
  readonly fromDate: Date;
  readonly customerSequence: string;
}

export interface GetAccountForApproval {
  readonly pageNumber: number;
  readonly pageSize: number;
}

export interface GetAccountOpeningQuery {
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly fromDate: string;
  readonly toDate: string;
}

export interface AccountStatementFilterFormProps {
  readonly to: number;
  readonly from: number;
  readonly year: number;
  readonly month: number;
  readonly accountNumber?: Dict<string>;
}

export interface AccountInquiryProps {
  readonly type: string;
  readonly endDate: string;
  readonly subject: string;
  readonly beginDate: string;
  readonly createUser: string;
  readonly explanation: string;
  readonly approvalUser: string;
  readonly accountOpenID: string;
  readonly accountOpenState: string;
}

export interface OpenAccountFormProps {
  readonly branchCode: string;
  readonly accountCode: string;
  readonly currencyCode: string;
}

export interface NonStandardInquiryFormProps {
  readonly message: string;
  readonly subject: string;
  readonly branchCode: string;
}

export interface ApprovalAuthorizerFormProps {
  readonly state: string;
  readonly approvalType: string;
}

export interface AvailableAccountProps {
  readonly nameRU: string;
  readonly nameUZ: string;
  readonly nameENG: string;
  readonly accountCode: string;
}

export interface AccountsForTransactionsProps {
  readonly accountBalance: string;
  readonly accountType: string;
  readonly accounts: string;
  readonly currency: string;
  readonly humoCardNumber: string;
  readonly lastTransactionDate: string;
  readonly localCardBalance: string;
  readonly mfo: string;
  readonly uzcardExpireDate: string;
  readonly uzcardNumber: string;
  readonly visaCardNumber: string;
}

export interface BranchInformationProps {
  readonly nameRU: string;
  readonly nameUZ: string;
  readonly nameENG: string;
  readonly branchCode: string;
}

export interface TransitAccountNumberProps {
  readonly cardType: string;
  readonly payrollId: string;
  readonly createUser: string;
  readonly approvalUser: string;
  readonly operationDate: string;
  readonly payrollAmount: string;
  readonly payrollDetail: string;
  readonly payrollNumber: string;
  readonly payrollStatus: string;
  readonly accountBalance: string;
  readonly transitAccount: string;
}

export interface DebitAccountNumberProps {
  readonly corporateAccount: string;
  readonly accountBalance: string;
}

export interface AccountInquiryForApprovalProps {
  readonly type: string;
  readonly endDate: string;
  readonly subject: string;
  readonly beginDate: string;
  readonly createUser: string;
  readonly explanation: string;
  readonly approvalUser: string;
  readonly accountOpenID: string;
  readonly accountOpenState: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly subjectInEng: string;
  readonly subjectInUzb: string;
  readonly subjectInRu: string;
}

export interface SwiftListInnerDataProps {
  readonly swiftMessageKey: string;
  readonly msgTransactionRefNumber: string;
  readonly msgSenderBankIdCode: string;
  readonly msgReceiverBankIdCode: string;
  readonly msgStatusCode: string;
  readonly currency: string;
  readonly amount: string;
  readonly msgTag20: string;
  readonly msgTag23B: string;
  readonly msgTag32A: string;
  readonly msgTag33B: string;
  readonly msgTag50K: string;
  readonly msgTag57A: string;
  readonly msgTag59: string;
  readonly msgTag70: string;
  readonly msgTag71A: string;
  readonly msgTag72: string;
  readonly senderBankName: string;
  readonly senderBankCountryCode: string;
  readonly senderBankCity: string;
  readonly receiverBankName: string;
  readonly receiverBankCountryCode: string;
  readonly receiverBankCity: string;
  readonly msgTag52A: string;

  readonly createUser: string;
  readonly approvalUser: string;
}

export interface SwiftListTableDataProps {
  readonly msgTag32A: string;
  readonly swiftMessageKey: string;
  readonly msgTag20: string;
  readonly currency: string;
  readonly amount: string;
  readonly msgTag57A: string;
  readonly msgTag50K: string;
  readonly msgTag70: string;
  readonly msgTag52A: string;
  readonly msgTag59: string;

  readonly createUser: string;
  readonly approvalUser: string;
}

export interface SwiftCoverTableDataProps {
  readonly messageDetail32A: string;
  readonly messageDetail50K: string;
  readonly messageDetail59: string;
  readonly messageDetail70: string;
  readonly messageDetail72: string;
  readonly currency: string;
  readonly account: string;
  readonly date: string;
}

export interface SwiftListInnerProps {
  readonly totalPage: string;
  readonly totalRow: string;
  readonly data: SwiftListInnerDataProps[];
}

// export interface SwiftListProps {
//   readonly success: boolean;
//   readonly data: SwiftListInnerProps;
// }

export interface InquiryListProps {
  readonly accountOpenID: string;
  readonly accountOpenState: string;
  readonly approvalUser: string;
  readonly beginDate: string;
  readonly createUser: string;
  readonly endDate: string;
  readonly explanation: string;
  readonly message: string;
  readonly subject: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
}

export interface TotalPendingApplicationProps {
  readonly totalAllRequests: string;
  readonly totalConversions: string;
  readonly totalCorporateCardPayrolls: string;
  readonly totalFcYtransfers: string;
  readonly totalLcYtransfers: string;
  readonly totalRequestsForAccount: string;
  readonly totalSalaryPayrolls: string;
  readonly totalRequestsForAccountOpen: string;
  readonly totalRequestsForNonStandardInquiry: string;
  readonly totalRequestsForStandardInquiry: string;
  readonly totalRequestsForTradeFinance: string;
  readonly totalRequestsForDiscrepancy: string;
  readonly totalRequestsForAmendment: string;
  readonly operDay: string;
  readonly beginDay: string;
  readonly totalRequestsForPaymentDemandsOutgoing: string;
  readonly totalRequestsForPaymentClaimIncoming: string;
  readonly totalRequestsForPaymentClaimOutgoing: string;
  readonly totalReceivedPaymentClaim: string;
  readonly totalSentPaymentClaim: string;
  readonly totalRequestsForPaymentClaimCollectionOrder: string;
  readonly totalRequestsForPaymentClaimCollectionOrdersFromTax: string;
  readonly totalRequestsForPaymentClaimCollectionOrdersFromMib: string;
}

export interface GetApprovalInquiryList {
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly inquiryType: string;
}

export interface CreateNewNonStandardInq {
  readonly branchCode: string;
  readonly inquiryType: string;
  readonly message: string;
  readonly registrationNumber: string;
  readonly subject: string;
}

export interface GetInquiryForAccountsQuery {
  readonly fromDate: string;
  readonly inquiryType: ApprovalInquiryType;
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly toDate: string;
}

export interface AccountCardBoxProps {
  readonly announcedAmount: string;
  readonly displacementDate: string;
  readonly documentDate: string;
  readonly documentNumber: string;
  readonly paymentPurpose: string;
  readonly recipientAccountNumber: string;
  readonly recipientBankMfo: string;
  readonly recipientName: string;
  readonly restAmount: string;
  readonly forId: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
}

export interface AccountCardBoxQuery {
  readonly pageNumber: number;
  readonly pageSize: number;
}

export interface SaveToPdfProps {
  readonly amount: string;
  readonly currency: string;
  readonly msgReceiverBankIdCode: string;
  readonly msgSenderBankIdCode: string;
  readonly msgStatusCode: string;
  readonly msgTag20: string;
  readonly msgTag23B: string;
  readonly msgTag32A: string;
  readonly msgTag33B: string;
  readonly msgTag50K: string;
  readonly msgTag56A: string;
  readonly msgTag57A: string;
  readonly msgTag59: string;
  readonly msgTag70: string;
  readonly msgTag71A: string;
  readonly msgTag72: string;
  readonly msgTransactionRefNumber: string;
  readonly qrCodeBase64: string;
  readonly receiverBankCity: string;
  readonly receiverBankCountryCode: string;
  readonly receiverBankName: string;
  readonly senderBankCity: string;
  readonly senderBankCountryCode: string;
  readonly senderBankName: string;
  readonly swiftMessageKey: string;
}

export enum ApprovalTypes {
  Accounts = "AC",
  LCYTransfer = "TL",
  FCYTransfer = "TF",
  Salary = "SL",
  CorporateCard = "CC",
  Conversion = "CN",
  NonStandardInq = "NI",
  StandardInq = "SI",
  TradeFinance = "FX",
  Amendment = "FX",
  Discrepancy = "DS",
  SentPaymentClaim = "PO",
  ReceivedPaymentClaim = "PI",
}

export enum ApprovalInquiryType {
  NonStandard = "NI",
  Standard = "SI",
}
