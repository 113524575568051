import React, { ReactNode } from "react";

import { MenuStateButtonWrapper } from "./MenuStateButtonWrapper";

interface Props {
  readonly children: ReactNode;
}

export function AppSideMenu({ children }: Props) {
  return (
    <div className="d-flex flex-1 flex-column py-4">
      {children}

      <MenuStateButtonWrapper />
    </div>
  );
}
