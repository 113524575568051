import { OptionItem } from "../dto/AppDTO";
import { TransferType } from "../transfer/TransferDTO";

export interface SalaryProps {
  readonly accountBalance: string;
  readonly approvalUser: string;
  readonly cardType: string;
  readonly createUser: string;
  readonly operationDate: string;
  readonly payrollAmount: string;
  readonly payrollDetail: string;
  readonly payrollId: string;
  readonly payrollNumber: string;
  readonly payrollStatus: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly transitAccount: string;
  readonly id: string;
}

export enum SalaryExcelCheckFlag {
  Matches = "03",
  NameNotMatch = "02",
  AccountIsNotFound = "01",
}

export interface SalaryExcelProps {
  readonly accountName: string;
  readonly accountNumber: string;
  readonly checkFlag: SalaryExcelCheckFlag;
}

export enum RegisterTableType {
  Existing = "existing",
  Table = "table",
}

export interface PayrollProps {
  readonly cardType: string;
  readonly payrollId: string;
  readonly createUser: string;
  readonly approvalUser: string;
  readonly operationDate: string;
  readonly payrollAmount: string;
  readonly payrollDetail: string;
  readonly payrollNumber: string;
  readonly payrollStatus: string;
  readonly transitAccount: string;
  readonly accountBalance: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
}

export interface SalaryFilterFormProps {
  readonly toDate: Date;
  readonly fromDate: Date;
  readonly status?: OptionItem<PayrollStatus>;
}

export interface SalaryCardProps {
  readonly address: string;
  readonly birthDay: string;
  readonly birthdayPlace: string;
  readonly city: string;
  readonly country: any;
  readonly district: string;
  readonly firstName: string;
  readonly gender: string;
  readonly passportExpiryDate: string;
  readonly phoneNumber: string;
  readonly password: string;
  readonly passportIssuedBy: string;
  readonly passportNumber: string;
  readonly passportRegistrationDate: string;
  readonly passportSeries: string;
  readonly patronymic: string;
  readonly residentFlag: string;
  readonly surname: string;
  readonly uzCard: boolean;
  readonly humo: boolean;
  readonly mobilePhoneNumber?: string;
  readonly phoneInput?: string;
  readonly servicePhone?: string;
  readonly homePhone?: string;
  readonly pinfl?: string;
}

export interface ExistingRegister {
  readonly cardName: string;
  readonly cardAccount: string;
  readonly cardType: string;
  readonly createUser: string;
  readonly operationDate: string;
  readonly payrollDetail: string;
  readonly payrollNumber: string;
  readonly salaryAmount: string;
  readonly salaryId: string;
  readonly salaryStatus: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly transitAccount: string;
}

export interface PayrollFilterFormProps {
  readonly toDate: Date;
  readonly fromDate: Date;
  readonly transferType: TransferType;
  readonly status?: OptionItem<PayrollStatus>;
  readonly pageSize?: number;
  readonly pageNumber?: number;
}

export interface SalaryCardHolders {
  readonly accountNumber: string;
  readonly cardId: string;
  readonly employerName: string;
  readonly openDate: string;
  readonly status: string;
  readonly isSelected?: boolean;
  readonly totalPages: string;
  readonly totalRows: string;
}

export enum PayrollStatus {
  None = "",
  Entered = "1",
  SentToCb = "8",
  Approved = "2",
  Created = "101",
  Completed = "3",
  Rejected = "14",
  Annulled = "106",
  RejectedByCb = "7",
  SentToCardSystem = "9",
  AuthorizedByAuthorizer1 = "102",
  AuthorizedByAuthorizer2 = "103",
  AuthorizedByAuthorizer = "104",
}
