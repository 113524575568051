import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { useI18n } from "../../i18n/I18nContext";
import { FcyTransferForPending } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly data: FcyTransferForPending[];
  readonly setDocumentId: (value) => void;
  readonly withCreatorsAndApprovals?: boolean;
  readonly chooseTransferForView: (value) => void;
  readonly loading: boolean;
}

export function TransfersFcyTable({
  data = [],
  setDocumentId,
  withCreatorsAndApprovals,
  chooseTransferForView,
  loading,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<FcyTransferForPending>[] = [
      {
        width: 95,
        accessor: "createDate",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_CREATED_DATE_AND_TIME_COLUMN_TITLE"),
      },
      {
        width: 95,
        accessor: "transactionDate",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_TRANSACTION_DATE_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "transferNumber",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a
            className="cursor-pointer"
            onClick={() => chooseTransferForView(row.cell.row.original)}
          >
            {row.value}
          </a>
        ),
      },
      {
        width: 90,
        accessor: "currency",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "amount",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : "0.00"}
          </div>
        ),
      },
      {
        width: 180,
        accessor: "applicantAccount",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "beneficiaryAccount",
        Header: translate(
          "PENDING_APPROVALS_CONVERSION_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE",
        ),
      },
      {
        width: 180,
        accessor: "beneficiaryName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "beneficiarySwiftCode",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_SWIFT_BIC_COLUMN_TITLE"),
      },
      {
        width: 400,
        accessor: "detailsOfPayment",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "state",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [chooseTransferForView, withCreatorsAndApprovals, translate]);

  return (
    <TabPage className="px-4">
      <Table
        data={data}
        columns={columns}
        loading={loading}
        onSelect={setDocumentId}
        withCheckbox={true}
      />
    </TabPage>
  );
}
