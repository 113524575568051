import "./assets/table.scss";

import cx from "classnames";
import React, { ReactNode, useEffect, useMemo } from "react";

import { useDispatch } from "react-redux";
import {
  Row,
  TableOptions,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useTable,
} from "react-table";

import { Checkbox } from "../ui/Checkbox";
import { TableHeader } from "./TableHeader";
import { useI18n } from "../../i18n/I18nContext";
import { Loader, LoaderSize } from "../ui/Loader";
import { Dict } from "../../api/dto/AppDTO";
import { SetPayrollSelectedIds } from "../../reducers/payrollSelectedIds";

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, any>(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return <Checkbox ref={resolvedRef} {...rest} value={checked} />;
  },
);

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

interface Props<TData extends object = {}> extends TableOptions<TData> {
  readonly id?: string;

  readonly striped?: boolean;
  readonly hovered?: boolean;
  readonly loading?: boolean;
  readonly showEmptyRow?: boolean;

  readonly headerComponent?: ReactNode;

  readonly withCheckbox?: boolean;
  readonly onSelect?: (ids: Dict<boolean>) => void;

  readonly rowClassName?: string;
  readonly bodyClassName?: string;
  readonly headClassName?: string;
  readonly cellClassName?: string;
  readonly tableClassName?: string;
  readonly headerClassName?: string;
  readonly footerComponent?: ReactNode;
  readonly headerGroupClassName?: string;
}

export function SwiftTableRows<TData extends object = {}>({
  id,
  loading,
  striped = true,
  hovered = true,
  showEmptyRow = true,
  rowClassName,
  bodyClassName,
  headClassName,
  cellClassName,
  tableClassName,
  headerClassName,
  footerComponent,
  headerComponent,
  headerGroupClassName,
  onSelect,
  // withCheckbox,
  columns,
  data,
}: // ...options
Props<TData>) {
  const { translate } = useI18n();

  // const checkboxHooks = withCheckbox
  //   ? [
  //       useRowSelect,
  //       (hooks) => {
  //         hooks.visibleColumns.push((columns) => [
  //           {
  //             width: 50,
  //             id: "selection",
  //             disableResizing: true,
  //             // eslint-disable-next-line react/display-name
  //             Header: ({ getToggleAllRowsSelectedProps }: any) => (
  //               <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
  //             ),
  //             // eslint-disable-next-line react/display-name
  //             Cell: ({ row }: any) => (
  //               <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  //             ),
  //           },
  //           ...columns,
  //         ]);
  //       },
  //     ]
  //   : [];

  const {
    rows,
    prepareRow,
    allColumns = [],
    headerGroups,
    getTableProps,
    getTableBodyProps,
    // @ts-ignore
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      stateReducer: (newState: any, action) => {
        if (action.type === "toggleAllRowsSelected") {
          newState.selectedRowIds = {
            [action.id]: true,
          };
        }
        if (action.type === "toggleRowSelected") {
          if (newState.selectedRowIds[action.id]) {
            newState.selectedRowIds = {
              [action.id]: true,
            };
          } else {
            newState.selectedRowIds = {
              [action.id]: false,
            };
          }
        }

        return newState;
      },
    },
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          width: 10,
          id: "selection",
          // disableResizing: true,
          // eslint-disable-next-line react/display-name
          // Header: ({ getToggleAllRowsSelectedProps }: any) => (
          //   <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          // ),
          Header: () => <div />,
          // eslint-disable-next-line react/display-name
          Cell: ({ row }) => {
            if (rows.filter((row: any) => row.isSelected).length < 1 || row.isSelected) {
              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              );
            } else {
              return (
                <div>
                  <IndeterminateCheckbox
                    checked={false}
                    readOnly={true}
                    style={row.getToggleRowSelectedProps().style}
                  />
                </div>
              );
            }
          },
        },
        ...columns,
      ]);
    },
  );
  const dispatch = useDispatch();

  const columnsLength = useMemo(() => allColumns.length, [allColumns.length]);
  const emptyTable = useMemo(() => !loading && rows.length === 0, [loading, rows.length]);

  useEffect(() => {
    dispatch(SetPayrollSelectedIds({ payrollList: selectedRowIds }));
  }, [dispatch, selectedRowIds]);

  useEffect(() => {
    if (onSelect && selectedRowIds) {
      onSelect(selectedRowIds);
    }
  }, [onSelect, selectedRowIds]);

  return (
    <table
      id={id}
      {...getTableProps()}
      className={cx(
        "table table-bordered table-ui",
        { "table-hover": !loading && hovered, "table-striped": !loading && striped },
        tableClassName,
      )}
    >
      <TableHeader
        className={headClassName}
        headerGroups={headerGroups}
        headerClassName={headerClassName}
        headerComponent={headerComponent}
        groupClassName={headerGroupClassName}
      />

      <tbody {...getTableBodyProps()} className={bodyClassName}>
        {Boolean(emptyTable && showEmptyRow) && (
          <tr>
            <td align="center" className="border-transparent bg-white" colSpan={columnsLength}>
              <span className="text-blue-gray-dark">{translate("TABLE_EMPTY_STATE_TITLE")}</span>
            </td>
          </tr>
        )}
        <>
          {!loading ? (
            <div
              style={{
                maxHeight: "650px",
                overflowY: "scroll",
                // overflow: "overlay",
                // overflowY: "auto",
                // overflowX: "hidden",
                // marginRight: "-17px",
              }}
            >
              {rows.map((row: Row<any>) => {
                prepareRow(row);

                const rowProps = row.getRowProps();

                return (
                  <tr {...rowProps} key={rowProps.key} className={rowClassName}>
                    {row.cells.map((cell) => {
                      const cellProps = cell.getCellProps();
                      // @ts-ignore
                      const statusCells = cellProps.key?.indexOf("Status");

                      const year = cell.row.original.msgTag32A.split("").splice(0, 2).join("");
                      const month = cell.row.original.msgTag32A.split("").splice(2, 2).join("");
                      const day = cell.row.original.msgTag32A.split("").splice(4, 2).join("");
                      if (cellProps.key === `cell_${cell.row.index}_msgTag32A`) {
                        return (
                          <td
                            {...cellProps}
                            key={cellProps.key}
                            className={cellClassName}
                            style={statusCells === 14 ? { ...cellProps.style } : cellProps.style}
                          >
                            {/* {cell.render("Cell")} */}
                            {`${day}.${month}.${year}`}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            {...cellProps}
                            key={cellProps.key}
                            className={cellClassName}
                            style={statusCells === 14 ? { ...cellProps.style } : cellProps.style}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </div>
          ) : (
            <div className="text-center pt-2">
              <Loader size={LoaderSize.Small} />
            </div>
          )}
        </>
        {footerComponent}
      </tbody>
    </table>
  );
}
