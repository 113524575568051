import React, { useMemo } from "react";
import { useI18n } from "../../i18n/I18nContext";
import { SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TabPage } from "../tabs/TabPage";

interface Props {
  readonly data: SalaryCardHolders[];
  readonly loading: boolean;
  readonly onChangeCardInfo: (value) => void;
}

export const SalaryListOfCardHoldersTable = React.memo(function ({
  // export function SalaryListOfCardHoldersTable({
  loading,
  data,
  onChangeCardInfo,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SalaryCardHolders>[] = [
      {
        width: 95,
        accessor: "accountNumber",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE",
        ),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              onClick={() => onChangeCardInfo && onChangeCardInfo(row.value)}
              href="#"
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 300,
        accessor: "employerName",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "cardId",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE",
        ),
      },
      {
        width: 50,
        accessor: "openDate",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "status",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [onChangeCardInfo, translate]);

  return (
    <TabPage>
      <Table data={data} columns={columns} loading={loading} showEmptyRow={true} />
    </TabPage>
  );
});

SalaryListOfCardHoldersTable.displayName = "SalaryListOfCardHoldersTable";
