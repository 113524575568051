import React from "react";
import { useDispatch } from "react-redux";

import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { changeMobileMenu, mobileMenuSelector } from "../reducers/appReducer";
import { MobileMenuCloseButton } from "./MobileMenuCloseButton";
import { MobileMenu } from "../api/dto/AppDTO";
import { MobileMenuOpenButton } from "./MobileMenuOpenButton";

export function MobileMenuButtonWrapper() {
  const dispatch = useDispatch();

  const mobileMenu = useShallowEqualSelector(mobileMenuSelector);

  return (
    <>
      {mobileMenu === MobileMenu.Opened ? (
        <MobileMenuCloseButton
          type={mobileMenu}
          onClick={(type) => dispatch(changeMobileMenu({ type }))}
        />
      ) : (
        <MobileMenuOpenButton
          type={mobileMenu}
          onClick={(type) => dispatch(changeMobileMenu({ type }))}
        />
      )}
    </>
  );
}
