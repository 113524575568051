export enum Routes {
  // Auth
  Auth = "/auth",
  AuthLogin = "/auth/login",
  AuthAgreement = "/auth/agreement",
  AuthLoginToken = "/auth/login/token",
  AuthResetPassword = "/auth/reset-password",
  AuthChangePassword = "/auth/change-password",

  // Public

  // Corporate

  Corporate = "/corporate",
  CorporateLoan = "/corporate/loan",
  CorporateYeisvo = "/corporate/yeisvo",
  CorporateHelpRoute = "/corporate/help",
  CorporateHelp = "/corporate/help",
  CorporateHelpPath = "/corporate/help/:tab?",
  CorporateAccounts = "/corporate/accounts",
  CorporateUserInfo = "/corporate/user-info",
  CorporatePendingApprovalRoute = "/corporate/pending-approvals",
  CorporatePendingApprovalPath = "/corporate/pending-approvals/:tab?",
  CorporateAccountsRoute = "/corporate/accounts",
  CorporateAccountsPath = "/corporate/accounts/:tab?",
  CorporateTransfersRoute = "/corporate/transfers",
  CorporateTransfersPath = "/corporate/transfers/:tab?",
  CorporateTransfersCreateSalary = "/corporate/transfers/salary/create",
  CorporateConversion = "/corporate/conversion",
  CorporateConversionPath = "/corporate/conversion/:tab?",
  CorporateAnalytics = "/corporate/analytics",
  CorporateTradeFinance = "/corporate/trade-finance",
  CorporateAgreements = "/corporate/agreements",
  CorporateAgreementsPath = "/corporate/agreements/:tab?",
  CorporateReference = "/corporate/reference",
  CorporateNotifications = "/corporate/notifications",
  CorporateClientFeedback = "/corporate/client-feedback",
  CorporatePendingApprovals = "/corporate/pending-approvals",
  CorporatePaymentClaim = "/corporate/payment-claim",
  CorporatePaymentClaimRoute = "/corporate/payment-claim",
  CorporatePaymentClaimPath = "/corporate/payment-claim/:tab?",

  // Individual

  Individual = "/individual",
  IndividualPendingApproval = "/individual/pending-approval",
  IndividualLoan = "/individual/loan",
  IndividualHelp = "/individual/help",
  IndividualHelpPath = "/individual/help/:tab?",
  IndividualAccounts = "/individual/accounts",
  IndividualUserInfo = "/individual/user-info",
  IndividualTransfers = "/individual/transfers",
  IndividualContactUs = "/individual/contact-us",
  IndividualConversion = "/individual/conversion",
  IndividualAgreements = "/individual/agreements",
  IndividualNotifications = "/individual/notifications",
  IndividualAccountsRoute = "/individual/accounts",
  IndividualAccountsPath = "/individual/accounts/:tab?",

  // Financial
  Financial = "/financial",
  FinancialMain = "/financial/main",
  FinancialPending = "/financial/pending",
  FinancialApproved = "/financial/approved",
  FinancialRefused = "/financial/refused",
  FinancialLoan = "/financial/loan",
  FinancialAccounts = "/financial/accounts",
  FinancialAccountsPath = "/financial/accounts/:tab?",
  FinancialRequests = "/financial/requests",
  FinancialUserInfo = "/financial/user-info",
  FinancialWithdrawal = "/financial/withdrawal",
  FinancialPreApproval = "/financial/pre-approval",


  // Salary

  SalaryTransit = "salary?chooseAccountTypeSalary=salaryTransit",
  SalaryDebit = "salary?chooseAccountTypeSalary=salaryDebit"
}
