import React, { ReactNode, useCallback } from "react";
import "./assets/mobile-menu.scss";

import { CheckMobileUtils } from "../utils/CheckMobileUtils";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { changeMobileMenu, mobileMenuSelector } from "../reducers/appReducer";
import { MobileMenuButtonWrapper } from "./MobileMenuButtonWrapper";
import { userSelector } from "../reducers/userReducer";
import { MobileMenu } from "../api/dto/AppDTO";
import { ColorPalette } from "../theme/ColorPalette";
import { LogoutButtonWrapper } from "../components/app/LogoutButtonWrapper";
import { AppTimerWrapper } from "../components/app/AppTimerWrapper";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../api/auth/AuthContext";

interface MobileMenuLayoutProps {
  readonly children: ReactNode;
}
export default function MobileMenuLayout({ children }: MobileMenuLayoutProps) {
  const isMobile = CheckMobileUtils();
  const user = useShallowEqualSelector(userSelector);
  const dispatch = useDispatch();
  const isMobileMenu = useShallowEqualSelector(mobileMenuSelector);
  const { logout } = useAuthContext();
  const logoutFromMobileMenu = useCallback(
    (type: MobileMenu) => {
      dispatch(changeMobileMenu({ type }));
      logout();
    },
    [dispatch, logout],
  );

  return (
    <>
      {isMobile && isMobileMenu === MobileMenu.Opened && (
        <div className="mobile-menu-container">
          <div className="mobile-menu-header">
            <div className="mobile-menu-header-user-info">
              <span className="mobile-menu-header-user-name align-self-center font-weight-bold">
                {user?.fullName}
              </span>
              <span className="mobile-menu-header-user-login">{user?.login}</span>
            </div>
            <MobileMenuButtonWrapper />
          </div>
          <div>{children}</div>
          <div
            className="d-flex align-items-center justify-content-center   p-1 br-2   cursor-pointer mt-3"
            onClick={() => logoutFromMobileMenu(MobileMenu.Closed)}
            style={{
              background: ColorPalette.Secondary,
            }}
          >
            <LogoutButtonWrapper />
            <AppTimerWrapper />
          </div>
        </div>
      )}
    </>
  );
}
