import { deepUpdate } from "immupdate";
import { PersistConfig } from "redux-persist";

import { Dict, ListMeta } from "../api/dto/AppDTO";
import { AppStoreState } from "../store/RootReducer";
import { TransferType } from "../api/transfer/TransferDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import {
  AccountInquiryProps,
  AccountProps,
  TransitAccountNumberProps,
} from "../api/account/AccountDTO";

export const accountsReducerPersistConfig: Partial<PersistConfig<AccountsReducerState>> = {
  whitelist: [],
};

interface SetTransitAccountNumbersMeta {
  readonly transferType: TransferType;
  readonly list: TransitAccountNumberProps[];
}

enum ReducerActions {
  SetInquiryList = "Accounts/SetInquiryList",
  SetAccountsList = "Accounts/SetAccountsList",
  SetTransitAccountNumbers = "Account/SetTransitAccountNumbers",
}

export interface AccountsReducerState {
  readonly accountsList: Dict<AccountProps[]>;
  readonly inquiryList: Dict<AccountInquiryProps[]>;
  readonly transitAccountNumbers: Dict<TransitAccountNumberProps[]>;
}

function getState(): AccountsReducerState {
  return {
    inquiryList: {},
    accountsList: {},
    transitAccountNumbers: {},
  };
}

export const accountsReducer = createRootReducer<AccountsReducerState>(
  getState(),

  createReducer([ReducerActions.SetAccountsList], (state, { meta }) =>
    deepUpdate(state).at("accountsList").withDefault({}).at(meta.page).set(meta.list),
  ),

  createReducer([ReducerActions.SetInquiryList], (state, { meta }) =>
    deepUpdate(state).at("inquiryList").withDefault({}).at(meta.page).set(meta.list),
  ),

  createReducer([ReducerActions.SetTransitAccountNumbers], (state, { meta }) =>
    deepUpdate(state).at("transitAccountNumbers").at(meta.transferType).set(meta.list),
  ),
);

// ==================
// Selectors
// ==================

export const inquiryListSelector = (id: number) => ({
  accounts,
}: AppStoreState): AccountInquiryProps[] => accounts.inquiryList[id] || [];

export const accountsListSelector = (id: number) => ({ accounts }: AppStoreState): AccountProps[] =>
  accounts.accountsList[id] || [];

export const allAccountsListSelector = ({ accounts }: AppStoreState): AccountProps[] =>
  Object.values(accounts).flat();

export const transitAccountNumbersSelector = (transferType: TransferType) => ({
  accounts,
}: AppStoreState): TransitAccountNumberProps[] =>
  accounts.transitAccountNumbers[transferType] || [];

// ==================
// Actions
// ==================

export function setAccountsList(
  meta: ListMeta<AccountProps>,
): PerformAction<ListMeta<AccountProps>> {
  return { meta, type: ReducerActions.SetAccountsList };
}

export function setInquiryList(
  meta: ListMeta<AccountInquiryProps>,
): PerformAction<ListMeta<AccountInquiryProps>> {
  return { meta, type: ReducerActions.SetInquiryList };
}

export function setTransitAccountNumbers(
  meta: SetTransitAccountNumbersMeta,
): PerformAction<SetTransitAccountNumbersMeta> {
  return { meta, type: ReducerActions.SetTransitAccountNumbers };
}
