import { Form, Formik } from "formik";
import React from "react";
import { noop } from "lodash";
import { Input } from "../ui/Input";
import "./assets/corporate-card-print-table.scss";

interface Props {
  readonly data: any[];
}

export function CorporateCardPrintTable({ data }: Props) {
  return (
    <Formik initialValues={data} onSubmit={noop}>
      {() => (
        <Form id="CorporateCardPrintTable">
          <div className="form-group col corporate-card-print-table">
            <div className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0">
              <Input width={80} placeholder="№" />
              <Input width={300} placeholder="Карточный счет компании" />
              <Input width={520} placeholder="Наименование компании" />
              <Input width={130} placeholder="Сумма" />
            </div>
            {data.map((x, index) => (
              // eslint-disable-next-line react/jsx-key
              <div
                className="form-group row flex-xl-nowrap corporate-card-print ml-1 mb-0"
                key={index}
              >
                <Input width={80} value={x.id} />
                <Input width={300} value={x.accountNumber} />
                <Input width={520} value={x.fullName} />
                <Input width={130} value={x.amount} />
              </div>
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
}
