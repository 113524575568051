import React, { useMemo } from "react";
import { useI18n } from "../../i18n/I18nContext";
import { CardHoldersProps } from "../../api/corporate-card/CorporateCardsDTO";
import { Column } from "react-table";
import { SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { TabPage } from "../tabs/TabPage";
import { Table } from "../table/Table";

interface Props {
  readonly data: CardHoldersProps[];
  readonly loading: boolean;
}

export function CorporateCardListOfCardHoldersTable({ loading, data }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SalaryCardHolders>[] = [
      {
        width: 95,
        accessor: "accountNumber",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE",
        ),
      },
      {
        width: 300,
        accessor: "employerName",
        Header: translate("SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "cardId",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE",
        ),
      },
      {
        width: 50,
        accessor: "openDate",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "status",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [translate]);

  return (
    <TabPage>
      <Table data={data} columns={columns} loading={loading} showEmptyRow={true} />
    </TabPage>
  );
}
