import React, { useMemo } from "react";
import { Redirect, Route, RouteProps } from "react-router";

import { ViewMode } from "../../api/dto/AppDTO";
import { tokenSelector } from "../../reducers/authReducer";
import { viewModeSelector } from "../../reducers/appReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";

interface Props extends Omit<RouteProps, "component"> {
  readonly component: React.ComponentType<RouteProps>;
}

export function PrivateRoute({ component, ...props }: Props) {
  const token = useShallowEqualSelector(tokenSelector);
  const viewMode = useShallowEqualSelector(viewModeSelector);

  const isAuthorized = useMemo(() => Boolean(token && viewMode !== ViewMode.Undetermined), [
    token,
    viewMode,
  ]);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (isAuthorized) {
          return React.createElement(component, routeProps);
        }

        return <Redirect to="/" />;
      }}
    />
  );
}
