import { BaseApi } from "../BaseApi";
import { TransferType } from "../transfer/TransferDTO";
import { DebitAccountNumberProps, TransitAccountNumberProps } from "../account/AccountDTO";
import {
  PayrollProps,
  PayrollStatus,
  SalaryCardProps,
  SalaryExcelProps,
  SalaryProps,
  SalaryCardHolders,
  ExistingRegister,
} from "./SalaryDTO";

interface GetSalaryListQuery {
  readonly transferType: TransferType;
  readonly pageNumber: number;
  readonly pageSize: number;
}

interface GetSalaryExcelListQuery {
  readonly accountName: string;
  readonly accountNumber: string;
  readonly transitAccount: string;
  readonly transferType: TransferType;
}

export interface GetPayrollListQuery {
  readonly toDate?: string;
  readonly fromDate?: string;
  readonly status?: PayrollStatus;
  readonly transferType?: TransferType;
  readonly pageSize?: number;
  readonly pageNumber?: number;
}

interface GetTransitAccountNumbersQuery {
  readonly transferType?: TransferType;
}

interface GetSalaryCardHoldersQuery {
  readonly cardType: string;
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly param?: string;
}

interface GetParticularEmployeeInfoQuery {
  readonly cardAccount: string;
  readonly cardType: string;
}

interface GetExistingRegister {
  readonly transferType: TransferType;
  readonly payrollId: string;
  readonly pageSize: number;
  readonly pageNumber: number;
}

export class SalaryApi extends BaseApi {
  public getSalaryList(query: GetSalaryListQuery): Promise<SalaryProps[]> {
    return this.get("salary/payrollforapproval/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public createSalaryUzCardCard(body: SalaryCardProps): Promise<boolean> {
    return this.post("salary/uzCard/:employeeId", {
      params: { employeeId: this.employeeId },
      json: body,
    });
  }

  public createSalaryHumoCard(body: SalaryCardProps): Promise<boolean> {
    return this.post("salary/humo/:employeeId", {
      params: { employeeId: this.employeeId },
      json: body,
    });
  }

  public getSalaryExcelList(query: GetSalaryExcelListQuery): Promise<SalaryExcelProps[]> {
    return this.get("salary/excel/list/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getPayrollList(query: GetPayrollListQuery): Promise<PayrollProps[]> {
    return this.get("salary/payroll/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getTransitAccountNumbers(
    query: GetTransitAccountNumbersQuery,
  ): Promise<TransitAccountNumberProps[]> {
    return this.get("salary/details/list/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getDebitAccountNumbers(): Promise<DebitAccountNumberProps[]> {
    return this.get("salary/corporate/list/:employeeId", {
      params: { employeeId: this.employeeId },
    });
  }

  public getSalaryCardHolders(
    query: GetSalaryCardHoldersQuery,
  ): Promise<SalaryCardHolders[] | any> {
    return this.get("corporateCard/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getParticularEmployeeInfo(
    query: GetParticularEmployeeInfoQuery,
  ): Promise<SalaryCardProps> {
    return this.get("corporateCard/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getExistingRegister(query: GetExistingRegister): Promise<ExistingRegister[]> {
    return this.get("salary/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getPayrollIdForDebitAccount(): Promise<any> {
    return this.post("salary/corporate/:employeeId", {
      params: { employeeId: this.employeeId },
    });
  }
}
