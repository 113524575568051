import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";

import { AuthApi } from "./AuthApi";
import { MobileMenu, ViewMode } from "../dto/AppDTO";
import { useApiBase } from "../ApiContext";
import { resetToken, setAuthDateExpired } from "../../reducers/authReducer";
import {
  changeMobileMenu,
  changeNotificationMessageState,
  changeViewMode,
} from "../../reducers/appReducer";
import { clearLendingGeneralInfo, clearRole, clearUser } from "../../reducers/userReducer";

interface Props {
  readonly AuthApi: AuthApi;

  readonly logout: () => void;
}

export function useAuthContext(): Props {
  const dispatch = useDispatch();

  const data = useApiBase();

  const api = useMemo(() => new AuthApi(data), [data]);

  const logoutHandler = useCallback(() => {
    dispatch(clearRole());
    dispatch(clearUser());
    dispatch(setAuthDateExpired({ state: true }));
    dispatch(resetToken());
    dispatch(clearLendingGeneralInfo());
    dispatch(changeNotificationMessageState({ notificationMessage: "" }));
    dispatch(changeViewMode({ viewMode: ViewMode.Undetermined }));
    dispatch(changeMobileMenu({ type: MobileMenu.Closed }));
  }, [dispatch]);

  return {
    AuthApi: api,

    logout: logoutHandler,
  };
}
