import { formatDateFromUrl, formatDateToBody } from "../utils/DateUtils";

export enum HelpAndFaqTabs {
  Faq = "Faq",
  Questions = "questions",
}

interface HelpAndFaqFilterProps {
  readonly fromDate?: string;
  readonly toDate?: string;
}

export class HelpAndFaqFilter {
  private readonly fromDate?: Date;
  private readonly toDate?: Date;
  public constructor({ fromDate, toDate } = {} as HelpAndFaqFilterProps) {
    this.fromDate = new Date();
    this.toDate = new Date();

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }
  }

  public getFilterProps() {
    const D = new Date();
    D.setMonth(D.getMonth() - 1);
    return {
      fromDate: formatDateToBody(D),
      toDate: formatDateToBody(this.toDate),
    };
  }

  public getFilterPropsForForm() {
    const D = new Date();
    D.setMonth(D.getMonth() - 1);
    return {
      fromDate: D,
      toDate: this.toDate,
    };
  }
}
