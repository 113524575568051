import "./assets/menu-state-button.scss";

import cx from "classnames";
import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { MenuState } from "../../api/dto/AppDTO";
import { ArrowExpandIcon } from "../icons/ArrowExpandIcon";
import { ArrowCollapseIcon } from "../icons/ArrowCollapseIcon";
import { useTheme } from "../../theme/ThemeContext";

interface Props {
  readonly state: MenuState;
  readonly onClick: (state: MenuState) => void;
}

export function MenuStateButton({ state = MenuState.Expanded, onClick }: Props) {
  const { translate } = useI18n();
  const currentTheme = useTheme();

  const isExpanded = useMemo(() => state === MenuState.Expanded, [state]);
  const isCollapsed = useMemo(() => state === MenuState.Collapsed, [state]);

  const title = useMemo(() => {
    if (isExpanded) {
      return translate("SIDE_MENU_COLLAPSE_BUTTON_TITLE");
    }

    return translate("SIDE_MENU_EXPAND_BUTTON_TITLE");
  }, [translate, isExpanded]);

  return (
    <div
      role="button"
      title={title}
      className={cx("d-flex pl-6 align-items-center menu-state-button", {
        "menu-state-button-dark-gray": currentTheme.theme === "dark-gray",
        "menu-state-button-light-gray": currentTheme.theme === "light-gray",
      })}
      onClick={() => onClick(isExpanded ? MenuState.Collapsed : MenuState.Expanded)}
    >
      {isExpanded && <ArrowCollapseIcon />}
      {isCollapsed && <ArrowExpandIcon />}
    </div>
  );
}
