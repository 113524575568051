import React, { useState } from "react";
import { noop } from "lodash";
import { Form, Formik } from "formik";

import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { RegisterK2Inquiry } from "./RegisterK2Inquiry";
import { RegisterAuditInquiry } from "./RegisterAuditInquiry";
import { RegisterAccInfoInquiry } from "./RegisterAccInfoInquiry";
import { RegisterAdditionalStatement } from "./RegisterAdditionalStatement";
import { RegisterReplenishCharterCapital } from "./RegisterReplenishCharterCapital";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { AccountsFilter } from "../../filters/AccountsFilter";
import InfoTitle, { InformationBody } from "../info-for-user/InfoTitle";
import { OnShowUserInfo } from "../on-show-user-info/OnShowUserInfo";

interface Props {
  readonly filter: AccountsFilter;
}

export function InquiryStandardForm({ filter }: Props) {
  const { translate } = useI18n();
  const { AccountApi } = useAccountContext();
  const { language } = useI18n();
  const [registerK2, setRegisterK2] = useState(false);
  const [auditInq, setAuditInq] = useState(false);
  const [accInfo, setAccInfo] = useState(false);
  const [additionalStat, setAdditionalStat] = useState(false);
  const [charterCap, setCharterCap] = useState(false);
  const [branch, setBranch] = useState({});
  const [accountsNumber, setAccountsNumber] = useState({});

  useResource(
    () =>
      AccountApi.getAccountsList({ ...filter.getAccountListFilter(), pageSize: 200 }).then((r) => {
        setAccountsNumber([
          r.map((x) => ({
            label: x.accountNumber,
            value: x.accountNumber,
          })),
        ]);
      }),
    [],
  );

  useResource(
    () =>
      AccountApi.getBranchesInformation().then((r) => {
        setBranch([
          r.map((x) => ({
            label:
              language === AppLanguage.Russian
                ? x.nameRU
                : language === AppLanguage.English
                ? x.nameENG
                : x.nameUZ,
            value: x.branchCode,
          })),
        ]);
      }),
    [],
  );

  return (
    <>
      <Formik initialValues={{}} onSubmit={noop}>
        {() => (
          <Form className="d-flex flex-column p-7 bg-white">
            <h4 className="mb-6 font-weight-bold">
              {translate("ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_TITLE")}
            </h4>

            <div className="d-flex mb-3">
              <a href="#" className="font-weight-bold h5" onClick={() => setRegisterK2(true)}>
                {translate("ACCOUNT_INQUIRY_NEW_REQUEST_K2")}
              </a>
              <OnShowUserInfo
                title={
                  <InfoTitle table={false} infoTitle={InformationBody.InformationBodyTitle_1} />
                }
              />
            </div>
            <div className="d-flex mb-3">
              <a href="#" className="font-weight-bold h5" onClick={() => setAuditInq(true)}>
                {translate("ACCOUNT_INQUIRY_TEXT_AUDIT_INQUIRY")}
              </a>
              <OnShowUserInfo
                title={
                  <InfoTitle table={false} infoTitle={InformationBody.InformationBodyTitle_1} />
                }
              />
            </div>
            <div className="d-flex mb-3">
              <a href="#" className="font-weight-bold h5" onClick={() => setAccInfo(true)}>
                {translate("ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER")}
              </a>
              <OnShowUserInfo
                title={
                  <InfoTitle table={false} infoTitle={InformationBody.InformationBodyTitle_1} />
                }
              />
            </div>
            <div className="d-flex mb-3">
              <a href="#" className="font-weight-bold h5" onClick={() => setAdditionalStat(true)}>
                {translate("ACCOUNT_ADDITIONAL_STATEMENT_HEADER")}
              </a>
              <OnShowUserInfo
                title={
                  <InfoTitle table={false} infoTitle={InformationBody.InformationBodyTitle_1} />
                }
              />
            </div>
            <div className="d-flex mb-3">
              <a href="#" className="font-weight-bold h5" onClick={() => setCharterCap(true)}>
                {translate("ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL")}
              </a>
              <OnShowUserInfo
                title={
                  <InfoTitle table={false} infoTitle={InformationBody.InformationBodyTitle_1} />
                }
              />
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        onClose={() => setRegisterK2(false)}
        width={1100}
        height={600}
        show={registerK2}
        title={"ACCOUNT_INQUIRY_NEW_REQUEST_K2"}
      >
        <TabPage>
          <RegisterK2Inquiry branchCodes={branch[0]} onClose={() => setRegisterK2(false)} />
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setAuditInq(false)}
        width={1100}
        show={auditInq}
        title={"ACCOUNT_INQUIRY_TEXT_AUDIT_INQUIRY"}
      >
        <TabPage>
          <RegisterAuditInquiry branchCodes={branch[0]} onClose={() => setAuditInq(false)} />
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setAccInfo(false)}
        width={1100}
        height={800}
        show={accInfo}
        title={"ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER"}
      >
        <TabPage>
          <RegisterAccInfoInquiry
            accountsNumber={accountsNumber[0]}
            onClose={() => setAccInfo(false)}
            branchCode={branch[0]}
          />
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setAdditionalStat(false)}
        width={1100}
        height={600}
        show={additionalStat}
        title={"ACCOUNT_ADDITIONAL_STATEMENT_HEADER"}
      >
        <TabPage>
          <RegisterAdditionalStatement
            accountsNumber={accountsNumber[0]}
            onClose={() => setAdditionalStat(false)}
            branchCode={branch[0]}
          />
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setCharterCap(false)}
        width={1100}
        height={700}
        show={charterCap}
        title={"ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL"}
      >
        <TabPage>
          <RegisterReplenishCharterCapital
            accountsNumber={accountsNumber[0]}
            onClose={() => setCharterCap(false)}
            branchCode={branch[0]}
          />
        </TabPage>
      </Modal>
    </>
  );
}
