import React from "react";
import { useI18n } from "../../i18n/I18nContext";
import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";

interface Props {
  readonly backButton: () => void;
}

export function TransfersFcyHeader({ backButton }: Props) {
  const { translate } = useI18n();
  return (
    <div className="d-flex flex-shrink-1 flex-grow-1 justify-content-between align-items-center">
      <h2 className="m-0 font-weight-bold">
        {translate("TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_IN_FORM_TITLE")}
      </h2>
      <Button
        active={true}
        size={SizeType.Medium}
        className="ml-2 mt-1 px-3"
        onClick={backButton}
        labelCode="TRANSFERS_SALARY_LIST_OF_CARD_BACK"
        color={ButtonColor.Orange}
      />
    </div>
  );
}
