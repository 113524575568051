import React, { useMemo, useState, useCallback } from "react";
import { useSalaryContext } from "../../api/salary/SalaryContext";
// import { useResource } from "../../hooks/useResource";
import { SalaryListOfCardHoldersTable } from "./SalaryListOfCardHoldersTable";
import { TransfersFilter } from "../../filters/TransfersFilter";
// import { useResourceHandler } from "../../hooks/useResourceHandler";
// import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { Form, Formik } from "formik";
import { noop } from "lodash";
import { InputField } from "../form/InputField";
import { SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  cardType: string;
  onChangeCardInfo: (value) => void;
  readonly filter: TransfersFilter;
  data: SalaryCardHolders[];
  loading: any;
}

export const SalaryListOfCardHoldersTableWrapper = React.memo(function ({
  // export function SalaryListOfCardHoldersTableWrapper({
  cardType,
  onChangeCardInfo,
  filter,
  data,
  loading,
}: Props) {
  const { SalaryApi } = useSalaryContext();
  const { translate } = useI18n();
  // const locationHelpers = useLocationHelpers();

  // const salaryListOfCardHolders = useResource(
  //   () =>
  //     SalaryApi.getSalaryCardHolders({
  //       cardType: cardType,
  //       ...filter.getCardHoldersFilter(),
  //       param: "1",
  //     }),
  //   [cardType, filter],
  // );

  // useResourceHandler(salaryListOfCardHolders, {
  //   onSuccess: (data) => {
  //     locationHelpers.pushQuery({ pageCount: data[0]?.totalPages });
  //   },
  // });

  // const data = useMemo(() => salaryListOfCardHolders.data || [], [salaryListOfCardHolders.data]);
  const [searched, setSearched] = useState("");
  const [searchCorr, setSearchCorr] = useState([] as any);
  // const [searching, setSearching] = useState(false);

  // const searchList = useCallback(
  //   (value) => {
  //     setSearched(value);
  //     // if (value.length > 0) {
  //     //   // setSearching(true);
  //     // } else {
  //     //   // setSearching(false);
  //     //   // setSearched("");
  //     //   // setSearchCorr([]);
  //     // }
  //   },
  //   // TransferApi
  //   [],
  // );

  const handleSubmit = useCallback(() => {
    SalaryApi.getSalaryCardHolders({
      cardType: cardType,
      ...filter.getCardHoldersFilter(),
      param: searched?.toUpperCase(),
    }).then((x) => setSearchCorr(x));
  }, [SalaryApi, searched, cardType, filter]);

  const list = useMemo(() => (searchCorr.length > 0 || searched !== "" ? searchCorr : data), [
    searched,
    searchCorr,
    data,
  ]);

  // const cardHoldersListSearch = useCallback((data, term) => {
  //   if (term !== "") {
  //     return (
  //       data &&
  //       data.filter((item) => `${item.accountNumber} ${item.employerName}`.indexOf(term) > -1)
  //     );
  //   } else return data;
  // }, []);

  // const paymentCodeListAfterSearch = useMemo(() => cardHoldersListSearch(data, searched), [
  //   cardHoldersListSearch,
  //   data,
  //   searched,
  // ]);
  return (
    <Formik enableReinitialize={true} initialValues={{ search: "" }} onSubmit={noop}>
      {() => (
        <Form>
          <label className="mb-0 mr-3">{translate("TRANSFERS_SALARY_SEARCH_BUTTON_TITLE")}</label>
          <div className="input-group">
            <InputField
              name="search"
              className="col-3 row pb-2"
              // label="TRANSFERS_SALARY_SEARCH_BUTTON_TITLE"
              // placeholder="APP_HEADER_SEARCH"
              placeholder={`${translate(
                "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE",
              )} | ${translate(
                "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE",
              )}`}
              onChange={(value) => setSearched(value.target.value)}
              value={searched}
            />
            <div className="input-group-append">
              <Button
                onClick={() => handleSubmit()}
                size={SizeType.Medium}
                color={ButtonColor.Orange}
                labelCode="YEISVO_HEADER_SEARCH_BUTTON_TITLE"
              />
            </div>
          </div>
          <SalaryListOfCardHoldersTable
            data={list}
            loading={loading}
            onChangeCardInfo={onChangeCardInfo}
          />
        </Form>
      )}
    </Formik>
  );
});

SalaryListOfCardHoldersTableWrapper.displayName = "SalaryListOfCardHoldersTableWrapper";
