import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function FilterIcon({ size = 14, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 13.922 16.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(0.001 0)"
        d="M12.83,0H.576A.576.576,0,0,0,.063.312.584.584,0,0,0,.109.92L4.6,7.244l0,.006a1.282,1.282,0,0,1,.252.761v5.156a.573.573,0,0,0,.573.577.591.591,0,0,0,.226-.045l2.523-.962a.547.547,0,0,0,.376-.539V8.011A1.283,1.283,0,0,1,8.8,7.25l0-.006L13.3.92a.583.583,0,0,0,.045-.607A.575.575,0,0,0,12.83,0Zm0,0"
      />
    </svg>
  );
}
