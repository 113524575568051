import ExcelJS from "exceljs";
import { bottomBorders, fillBackground, fillBorders } from "../utils/ExcelUtils";
import { addZeros, floatFormat, floatFormatComma } from "../utils/FloatUtils";
import { TranslateFunction } from "../i18n/I18nContext";
import { toFinite } from "lodash";

export function formatAccountNumber(accountNumber = ""): string {
  if (accountNumber.length > 8) {
    const start = accountNumber.substr(0, 5);
    const end = accountNumber.substr(-3);

    return `${start}....................${end}`;
  }

  return accountNumber;
}

export function sortDataFromDate(a, b) {
  const partsA = a.transactionDate.split(".");
  const partsB = b.transactionDate.split(".");
  if (partsA[2] < partsB[2]) {
    return -1;
  } else if (partsA[2] > partsB[2]) {
    return 1;
  } else {
    if (partsA[1] < partsB[1]) {
      return -1;
    } else if (partsA[1] > partsB[1]) {
      return 1;
    } else {
      if (partsA[0] < partsB[0]) {
        return -1;
      } else if (partsA[0] > partsB[0]) {
        return 1;
      } else {
        return 0;
      }
    }
  }
}

export function createStatement4ExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatement4ExcelSheet(workbook, data);

  return workbook;
}
type CreateStatementExcel = { translate: TranslateFunction };

function createStatement4ExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 25 },
    { key: "column3", width: 25 },
    { key: "column4", width: 25 },
    { key: "column5", width: 25 },
    { key: "column6", width: 25 },
    { key: "column7", width: 25 },
    { key: "column8", width: 25 },
    { key: "column9", width: 25 },
    { key: "column10", width: 25 },
    { key: "column11", width: 25 },
    { key: "column12", width: 25 },
    { key: "column13", width: 30 },
    { key: "column14", width: 25 },
    { key: "column15", width: 25 },
    { key: "column16", width: 40 },
    { key: "column17", width: 25 },
    { key: "column18", width: 25 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A1`);
  a1Cell.value = `${data.list[0].senderMfo ? data.list[0].senderMfo : data.list[0].senderMFO} ${
    data.list[0].senderBankName
  }`;
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { font: { bold: true } };

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.value = `Справка о работе счёта за ${data.fromDate} - ${data.toDate}`;
  a2Cell.alignment = { vertical: "middle", horizontal: "center" };
  a2Cell.style = { font: { bold: true } };

  const a3Cell = sheet.getCell(`A3`);
  a3Cell.value = `Счёт: ${data.accountNumber} ${data.clientInfo}`;
  a3Cell.alignment = { vertical: "middle", horizontal: "center" };
  a3Cell.style = { font: { bold: true } };

  const a6Cell = sheet.getCell(`A6`);
  a6Cell.value = "№";
  a6Cell.alignment = { vertical: "middle", horizontal: "center" };
  a6Cell.style = { border: fillBorders(), font: { bold: true } };

  const b6Cell = sheet.getCell(`B6`);
  b6Cell.value = "дата";
  b6Cell.alignment = { vertical: "middle", horizontal: "center" };
  b6Cell.style = { border: fillBorders(), font: { bold: true } };

  const c6Cell = sheet.getCell(`C6`);
  c6Cell.value = "счет отправителя";
  c6Cell.alignment = { vertical: "middle", horizontal: "center" };
  c6Cell.style = { border: fillBorders(), font: { bold: true } };

  const d6Cell = sheet.getCell(`D6`);
  d6Cell.value = "ИНН отправителя";
  d6Cell.alignment = { vertical: "middle", horizontal: "center" };
  d6Cell.style = { border: fillBorders(), font: { bold: true } };

  const e6Cell = sheet.getCell(`E6`);
  e6Cell.value = "наименование отправителя";
  e6Cell.alignment = { vertical: "middle", horizontal: "center" };
  e6Cell.style = { border: fillBorders(), font: { bold: true } };

  const f6Cell = sheet.getCell(`F6`);
  f6Cell.value = "МФО отправителя";
  f6Cell.alignment = { vertical: "middle", horizontal: "center" };
  f6Cell.style = { border: fillBorders(), font: { bold: true } };

  const g1Cell = sheet.getCell(`G6`);
  g1Cell.value = "счет получателя";
  g1Cell.alignment = { vertical: "middle", horizontal: "center" };
  g1Cell.style = { border: fillBorders(), font: { bold: true } };

  const h6Cell = sheet.getCell(`H6`);
  h6Cell.value = "счет получателя (Index acct)";
  h6Cell.alignment = { vertical: "middle", horizontal: "center" };
  h6Cell.style = { border: fillBorders(), font: { bold: true } };

  const i6Cell = sheet.getCell(`I6`);
  i6Cell.value = "МФО получателя";
  i6Cell.alignment = { vertical: "middle", horizontal: "center" };
  i6Cell.style = { border: fillBorders(), font: { bold: true } };

  const j1Cell = sheet.getCell(`J6`);
  j1Cell.value = "наименование получателя";
  j1Cell.alignment = { vertical: "middle", horizontal: "center" };
  j1Cell.style = { border: fillBorders(), font: { bold: true } };

  const k1Cell = sheet.getCell(`K6`);
  k1Cell.value = "ИНН получателя";
  k1Cell.alignment = { vertical: "middle", horizontal: "center" };
  k1Cell.style = { border: fillBorders(), font: { bold: true } };

  const l6Cell = sheet.getCell(`L6`);
  l6Cell.value = "№ документа";
  l6Cell.alignment = { vertical: "middle", horizontal: "center" };
  l6Cell.style = { border: fillBorders(), font: { bold: true } };

  const m5Cell = sheet.getCell(`M5`);
  m5Cell.value = "Остаток на начало периода";
  m5Cell.alignment = { vertical: "middle", horizontal: "center" };
  m5Cell.style = { border: fillBorders(), font: { bold: true } };

  const m6Cell = sheet.getCell(`M6`);
  m6Cell.value = "Расход";
  m6Cell.alignment = { vertical: "middle", horizontal: "center" };
  m6Cell.style = { border: fillBorders(), font: { bold: true } };

  const n5Cell = sheet.getCell(`N5`);
  n5Cell.value = data.beginBalance;
  n5Cell.alignment = { vertical: "middle", horizontal: "center" };
  n5Cell.style = { border: fillBorders(), font: { bold: true } };

  const n6Cell = sheet.getCell(`N6`);
  n6Cell.value = "Приход";
  n6Cell.alignment = { vertical: "middle", horizontal: "center" };
  n6Cell.style = { border: fillBorders(), font: { bold: true } };

  const o6Cell = sheet.getCell(`O6`);
  o6Cell.value = "код платежа";
  o6Cell.alignment = { vertical: "middle", horizontal: "center" };
  o6Cell.style = { border: fillBorders(), font: { bold: true } };

  const p6Cell = sheet.getCell(`P6`);
  p6Cell.value = "назначение платежа";
  p6Cell.alignment = { vertical: "middle", horizontal: "center" };
  p6Cell.style = { border: fillBorders(), font: { bold: true } };

  const q6Cell = sheet.getCell(`Q6`);
  q6Cell.value = "Вид документа";
  q6Cell.alignment = { vertical: "middle", horizontal: "center" };
  q6Cell.style = { border: fillBorders(), font: { bold: true } };

  const r6Cell = sheet.getCell(`R6`);
  r6Cell.value = "Номер транзакции";
  r6Cell.alignment = { vertical: "middle", horizontal: "center" };
  r6Cell.style = { border: fillBorders(), font: { bold: true } };
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 7;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 7 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = i + 1;
    aCells_1.alignment = { vertical: "middle" };
    aCells_1.border = fillBorders();

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].transactionDate;
    bCells_1.alignment = { vertical: "middle" };
    bCells_1.border = fillBorders();

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverAccount : newData[i].senderAccount;
    cCells_1.alignment = { vertical: "middle" };
    cCells_1.border = fillBorders();

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverTaxNumber : newData[i].senderTaxNumber;
    dCells_1.alignment = { vertical: "middle" };
    dCells_1.border = fillBorders();

    //Table E column cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverName : newData[i].clientName;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.border = fillBorders();

    //Table F column cells
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value =
      newData[i].debitAmount === "0"
        ? newData[i].receiverMfo
          ? newData[i].receiverMfo
          : newData[i].receiverMFO
        : newData[i].senderMfo
        ? newData[i].senderMfo
        : newData[i].senderMFO;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.border = fillBorders();

    //Table G column cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].senderAccount : newData[i].receiverAccount;
    gCells_1.alignment = { vertical: "middle" };
    gCells_1.border = fillBorders();

    //Table H column cells
    const hCells_1 = sheet.getCell(`H${tableCells}`);
    hCells_1.value = "";
    hCells_1.alignment = { vertical: "middle" };
    hCells_1.border = fillBorders();

    //Table I column cells
    const iCells_1 = sheet.getCell(`I${tableCells}`);
    iCells_1.value =
      newData[i].debitAmount === "0"
        ? newData[i].senderMfo
          ? newData[i].senderMfo
          : newData[i].senderMFO
        : newData[i].receiverMfo
        ? newData[i].receiverMfo
        : newData[i].receiverMFO;
    iCells_1.alignment = { vertical: "middle" };
    iCells_1.border = fillBorders();

    //Table J column cells
    const jCells_1 = sheet.getCell(`J${tableCells}`);
    jCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].clientName : newData[i].receiverName;
    jCells_1.alignment = { vertical: "middle" };
    jCells_1.border = fillBorders();

    //Table K column cells
    const kCells_1 = sheet.getCell(`K${tableCells}`);
    kCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].senderTaxNumber : newData[i].receiverTaxNumber;
    kCells_1.alignment = { vertical: "middle" };
    kCells_1.border = fillBorders();

    //Table L column cells
    const lCells_1 = sheet.getCell(`L${tableCells}`);
    lCells_1.value = newData[i].documentNumber;
    lCells_1.alignment = { vertical: "middle" };
    lCells_1.border = fillBorders();

    //Table M column cells
    const mCells_1 = sheet.getCell(`M${tableCells}`);
    mCells_1.value = newData[i].debitAmount;
    mCells_1.alignment = { vertical: "middle" };
    mCells_1.border = fillBorders();

    //Table N column cells
    const nCells_1 = sheet.getCell(`N${tableCells}`);
    nCells_1.value = newData[i].creditAmount;
    nCells_1.alignment = { vertical: "middle" };
    nCells_1.border = fillBorders();

    //Table O column cells
    const oCells_1 = sheet.getCell(`O${tableCells}`);
    oCells_1.value = newData[i].paymentCode;
    oCells_1.alignment = { vertical: "middle" };
    oCells_1.border = fillBorders();

    //Table P column cells
    const pCells_1 = sheet.getCell(`P${tableCells}`);
    pCells_1.value = newData[i].paymentDetail;
    pCells_1.alignment = { vertical: "middle" };
    pCells_1.border = fillBorders();

    //Table Q column cells
    const qCells_1 = sheet.getCell(`Q${tableCells}`);
    qCells_1.value = newData[i].documentType;
    qCells_1.alignment = { vertical: "middle" };
    qCells_1.border = fillBorders();

    //Table R column cells
    const rCells_1 = sheet.getCell(`R${tableCells}`);
    rCells_1.value = newData[i].transactionId;
    rCells_1.alignment = { vertical: "middle" };
    rCells_1.border = fillBorders();
  }

  const footerMCell = sheet.getCell(`M${tableCells + 1}`);
  footerMCell.value = "Остаток на конец периода";
  footerMCell.alignment = { vertical: "middle", horizontal: "center" };
  footerMCell.style = { border: fillBorders(), font: { bold: true } };

  const footerNCell = sheet.getCell(`N${tableCells + 1}`);
  footerNCell.value = data.endBalance;
  footerNCell.alignment = { vertical: "middle", horizontal: "center" };
  footerNCell.style = { border: fillBorders(), font: { bold: true } };
}

export function createStatement3ExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatement3ExcelSheet(workbook, data);

  return workbook;
}

function createStatement3ExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 25 },
    { key: "column3", width: 25 },
    { key: "column4", width: 25 },
    { key: "column5", width: 25 },
    { key: "column6", width: 25 },
    { key: "column7", width: 25 },
    { key: "column8", width: 25 },
    { key: "column9", width: 25 },
    { key: "column10", width: 25 },
    { key: "column11", width: 25 },
    { key: "column12", width: 25 },
    { key: "column13", width: 25 },
    { key: "column14", width: 25 },
    { key: "column15", width: 25 },
    { key: "column16", width: 40 },
    { key: "column17", width: 25 },
    { key: "column18", width: 25 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A2`);
  a1Cell.value = "№";
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { border: fillBorders(), font: { bold: true } };

  const b1Cell = sheet.getCell(`B2`);
  b1Cell.value = "дата тран";
  b1Cell.alignment = { vertical: "middle", horizontal: "center" };
  b1Cell.style = { border: fillBorders(), font: { bold: true } };

  const c1Cell = sheet.getCell(`C2`);
  c1Cell.value = "счет отправителя (20-значный)";
  c1Cell.alignment = { vertical: "middle", horizontal: "center" };
  c1Cell.style = { border: fillBorders(), font: { bold: true } };

  const d1Cell = sheet.getCell(`D2`);
  d1Cell.value = "ИНН отправителя";
  d1Cell.alignment = { vertical: "middle", horizontal: "center" };
  d1Cell.style = { border: fillBorders(), font: { bold: true } };

  const e1Cell = sheet.getCell(`E2`);
  e1Cell.value = "наименование отправителя";
  e1Cell.alignment = { vertical: "middle", horizontal: "center" };
  e1Cell.style = { border: fillBorders(), font: { bold: true } };

  const f1Cell = sheet.getCell(`F2`);
  f1Cell.value = "МФО отправителя";
  f1Cell.alignment = { vertical: "middle", horizontal: "center" };
  f1Cell.style = { border: fillBorders(), font: { bold: true } };

  const g1Cell = sheet.getCell(`G2`);
  g1Cell.value = "счет получателя (20знач)";
  g1Cell.alignment = { vertical: "middle", horizontal: "center" };
  g1Cell.style = { border: fillBorders(), font: { bold: true } };

  const h1Cell = sheet.getCell(`H2`);
  h1Cell.value = "счет получателя (Index acct)";
  h1Cell.alignment = { vertical: "middle", horizontal: "center" };
  h1Cell.style = { border: fillBorders(), font: { bold: true } };

  const i1Cell = sheet.getCell(`I2`);
  i1Cell.value = "МФО банка получателя";
  i1Cell.alignment = { vertical: "middle", horizontal: "center" };
  i1Cell.style = { border: fillBorders(), font: { bold: true } };

  const j1Cell = sheet.getCell(`J2`);
  j1Cell.value = "наименование получателя";
  j1Cell.alignment = { vertical: "middle", horizontal: "center" };
  j1Cell.style = { border: fillBorders(), font: { bold: true } };

  const k1Cell = sheet.getCell(`K2`);
  k1Cell.value = "ИНН получателя";
  k1Cell.alignment = { vertical: "middle", horizontal: "center" };
  k1Cell.style = { border: fillBorders(), font: { bold: true } };

  const l1Cell = sheet.getCell(`L2`);
  l1Cell.value = "№ документа";
  l1Cell.alignment = { vertical: "middle", horizontal: "center" };
  l1Cell.style = { border: fillBorders(), font: { bold: true } };

  const m1Cell = sheet.getCell(`M2`);
  m1Cell.value = "Сумма";
  m1Cell.alignment = { vertical: "middle", horizontal: "center" };
  m1Cell.style = { border: fillBorders(), font: { bold: true } };

  const n1Cell = sheet.getCell(`N2`);
  n1Cell.value = "код платежа";
  n1Cell.alignment = { vertical: "middle", horizontal: "center" };
  n1Cell.style = { border: fillBorders(), font: { bold: true } };

  const o1Cell = sheet.getCell(`O2`);
  o1Cell.value = "назначение платежа";
  o1Cell.alignment = { vertical: "middle", horizontal: "center" };
  o1Cell.style = { border: fillBorders(), font: { bold: true } };
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 3;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 3 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = i + 1;
    aCells_1.alignment = { vertical: "middle" };
    aCells_1.border = fillBorders();

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].transactionDate;
    bCells_1.alignment = { vertical: "middle" };
    bCells_1.border = fillBorders();

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value = newData[i].senderAccount;
    cCells_1.alignment = { vertical: "middle" };
    cCells_1.border = fillBorders();

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value = newData[i].senderTaxNumber;
    dCells_1.alignment = { vertical: "middle" };
    dCells_1.border = fillBorders();

    //Table E column cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value = newData[i].clientName;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.border = fillBorders();

    //Table F column cells
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value = newData[i].senderMfo ? newData[i].senderMfo : newData[i].senderMFO;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.border = fillBorders();

    //Table G column cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value = newData[i].receiverAccount;
    gCells_1.alignment = { vertical: "middle" };
    gCells_1.border = fillBorders();

    //Table H column cells
    const hCells_1 = sheet.getCell(`H${tableCells}`);
    hCells_1.value = "";
    hCells_1.alignment = { vertical: "middle" };
    hCells_1.border = fillBorders();

    //Table I column cells
    const iCells_1 = sheet.getCell(`I${tableCells}`);
    iCells_1.value = newData[i].receiverMfo ? newData[i].receiverMfo : newData[i].receiverMFO;
    iCells_1.alignment = { vertical: "middle" };
    iCells_1.border = fillBorders();

    //Table J column cells
    const jCells_1 = sheet.getCell(`J${tableCells}`);
    jCells_1.value = newData[i].receiverName;
    jCells_1.alignment = { vertical: "middle" };
    jCells_1.border = fillBorders();

    //Table K column cells
    const kCells_1 = sheet.getCell(`K${tableCells}`);
    kCells_1.value = newData[i].receiverTaxNumber;
    kCells_1.alignment = { vertical: "middle" };
    kCells_1.border = fillBorders();

    //Table L column cells
    const lCells_1 = sheet.getCell(`L${tableCells}`);
    lCells_1.value = newData[i].documentNumber;
    lCells_1.alignment = { vertical: "middle" };
    lCells_1.border = fillBorders();

    //Table M column cells
    const mCells_1 = sheet.getCell(`M${tableCells}`);
    mCells_1.value = newData[i].debitAmount;
    mCells_1.alignment = { vertical: "middle" };
    mCells_1.border = fillBorders();

    //Table N column cells
    const nCells_1 = sheet.getCell(`N${tableCells}`);
    nCells_1.value = newData[i].paymentCode;
    nCells_1.alignment = { vertical: "middle" };
    nCells_1.border = fillBorders();

    //Table O column cells
    const oCells_1 = sheet.getCell(`O${tableCells}`);
    oCells_1.value = newData[i].paymentDetail;
    oCells_1.alignment = { vertical: "middle" };
    oCells_1.border = fillBorders();
  }
}

export function createStatement2ExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatement2ExcelSheet(workbook, data);

  return workbook;
}

function createStatement2ExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 25 },
    { key: "column3", width: 25 },
    { key: "column4", width: 25 },
    { key: "column5", width: 25 },
    { key: "column6", width: 25 },
    { key: "column7", width: 25 },
    { key: "column8", width: 25 },
    { key: "column9", width: 25 },
    { key: "column10", width: 25 },
    { key: "column11", width: 25 },
    { key: "column12", width: 25 },
    { key: "column13", width: 25 },
    { key: "column14", width: 25 },
    { key: "column15", width: 25 },
    { key: "column16", width: 40 },
    { key: "column17", width: 25 },
    { key: "column18", width: 25 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A2`);
  a1Cell.value = "№";
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { border: fillBorders(), font: { bold: true } };

  const b1Cell = sheet.getCell(`B2`);
  b1Cell.value = "дата тран";
  b1Cell.alignment = { vertical: "middle", horizontal: "center" };
  b1Cell.style = { border: fillBorders(), font: { bold: true } };

  const c1Cell = sheet.getCell(`C2`);
  c1Cell.value = "счет отправителя (20-значный)";
  c1Cell.alignment = { vertical: "middle", horizontal: "center" };
  c1Cell.style = { border: fillBorders(), font: { bold: true } };

  const d1Cell = sheet.getCell(`D2`);
  d1Cell.value = "ИНН отправителя";
  d1Cell.alignment = { vertical: "middle", horizontal: "center" };
  d1Cell.style = { border: fillBorders(), font: { bold: true } };

  const e1Cell = sheet.getCell(`E2`);
  e1Cell.value = "наименование отправителя";
  e1Cell.alignment = { vertical: "middle", horizontal: "center" };
  e1Cell.style = { border: fillBorders(), font: { bold: true } };

  const f1Cell = sheet.getCell(`F2`);
  f1Cell.value = "МФО отправителя";
  f1Cell.alignment = { vertical: "middle", horizontal: "center" };
  f1Cell.style = { border: fillBorders(), font: { bold: true } };

  const g1Cell = sheet.getCell(`G2`);
  g1Cell.value = "счет получателя (20знач)";
  g1Cell.alignment = { vertical: "middle", horizontal: "center" };
  g1Cell.style = { border: fillBorders(), font: { bold: true } };

  const h1Cell = sheet.getCell(`H2`);
  h1Cell.value = "счет получателя (Index acct)";
  h1Cell.alignment = { vertical: "middle", horizontal: "center" };
  h1Cell.style = { border: fillBorders(), font: { bold: true } };

  const i1Cell = sheet.getCell(`I2`);
  i1Cell.value = "МФО банка получателя";
  i1Cell.alignment = { vertical: "middle", horizontal: "center" };
  i1Cell.style = { border: fillBorders(), font: { bold: true } };

  const j1Cell = sheet.getCell(`J2`);
  j1Cell.value = "наименование получателя";
  j1Cell.alignment = { vertical: "middle", horizontal: "center" };
  j1Cell.style = { border: fillBorders(), font: { bold: true } };

  const k1Cell = sheet.getCell(`K2`);
  k1Cell.value = "ИНН получателя";
  k1Cell.alignment = { vertical: "middle", horizontal: "center" };
  k1Cell.style = { border: fillBorders(), font: { bold: true } };

  const l1Cell = sheet.getCell(`L2`);
  l1Cell.value = "№ документа";
  l1Cell.alignment = { vertical: "middle", horizontal: "center" };
  l1Cell.style = { border: fillBorders(), font: { bold: true } };

  const m1Cell = sheet.getCell(`M2`);
  m1Cell.value = "Сумма";
  m1Cell.alignment = { vertical: "middle", horizontal: "center" };
  m1Cell.style = { border: fillBorders(), font: { bold: true } };

  const n1Cell = sheet.getCell(`N2`);
  n1Cell.value = "код платежа";
  n1Cell.alignment = { vertical: "middle", horizontal: "center" };
  n1Cell.style = { border: fillBorders(), font: { bold: true } };

  const o1Cell = sheet.getCell(`O2`);
  o1Cell.value = "назначение платежа";
  o1Cell.alignment = { vertical: "middle", horizontal: "center" };
  o1Cell.style = { border: fillBorders(), font: { bold: true } };
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 3;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 3 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = i + 1;
    aCells_1.alignment = { vertical: "middle" };
    aCells_1.border = fillBorders();

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].transactionDate;
    bCells_1.alignment = { vertical: "middle" };
    bCells_1.border = fillBorders();

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value = newData[i].receiverAccount;
    cCells_1.alignment = { vertical: "middle" };
    cCells_1.border = fillBorders();

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value = newData[i].receiverTaxNumber;
    dCells_1.alignment = { vertical: "middle" };
    dCells_1.border = fillBorders();

    //Table E column cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value = newData[i].receiverName;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.border = fillBorders();

    //Table F column cells
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value = newData[i].receiverMfo ? newData[i].receiverMfo : newData[i].receiverMFO;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.border = fillBorders();

    //Table G column cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value = newData[i].senderAccount;
    gCells_1.alignment = { vertical: "middle" };
    gCells_1.border = fillBorders();

    //Table H column cells
    const hCells_1 = sheet.getCell(`H${tableCells}`);
    hCells_1.value = "";
    hCells_1.alignment = { vertical: "middle" };
    hCells_1.border = fillBorders();

    //Table I column cells
    const iCells_1 = sheet.getCell(`I${tableCells}`);
    iCells_1.value = newData[i].senderMfo ? newData[i].senderMfo : newData[i].senderMFO;
    iCells_1.alignment = { vertical: "middle" };
    iCells_1.border = fillBorders();

    //Table J column cells
    const jCells_1 = sheet.getCell(`J${tableCells}`);
    jCells_1.value = newData[i].clientName;
    jCells_1.alignment = { vertical: "middle" };
    jCells_1.border = fillBorders();

    //Table K column cells
    const kCells_1 = sheet.getCell(`K${tableCells}`);
    kCells_1.value = newData[i].senderTaxNumber;
    kCells_1.alignment = { vertical: "middle" };
    kCells_1.border = fillBorders();

    //Table L column cells
    const lCells_1 = sheet.getCell(`L${tableCells}`);
    lCells_1.value = newData[i].documentNumber;
    lCells_1.alignment = { vertical: "middle" };
    lCells_1.border = fillBorders();

    //Table M column cells
    const mCells_1 = sheet.getCell(`M${tableCells}`);
    mCells_1.value = newData[i].creditAmount;
    mCells_1.alignment = { vertical: "middle" };
    mCells_1.border = fillBorders();

    //Table N column cells
    const nCells_1 = sheet.getCell(`N${tableCells}`);
    nCells_1.value = newData[i].paymentCode;
    nCells_1.alignment = { vertical: "middle" };
    nCells_1.border = fillBorders();

    //Table O column cells
    const oCells_1 = sheet.getCell(`O${tableCells}`);
    oCells_1.value = newData[i].paymentDetail;
    oCells_1.alignment = { vertical: "middle" };
    oCells_1.border = fillBorders();
  }
}

export function createStatement1ExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatement1ExcelSheet(workbook, data);

  return workbook;
}

function createStatement1ExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 25 },
    { key: "column3", width: 25 },
    { key: "column4", width: 25 },
    { key: "column5", width: 25 },
    { key: "column6", width: 25 },
    { key: "column7", width: 25 },
    { key: "column8", width: 25 },
    { key: "column9", width: 25 },
    { key: "column10", width: 25 },
    { key: "column11", width: 25 },
    { key: "column12", width: 25 },
    { key: "column13", width: 25 },
    { key: "column14", width: 25 },
    { key: "column15", width: 25 },
    { key: "column16", width: 40 },
    { key: "column17", width: 25 },
    { key: "column18", width: 25 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A2`);
  a1Cell.value = "№";
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { border: fillBorders(), font: { bold: true } };

  const b1Cell = sheet.getCell(`B2`);
  b1Cell.value = "дата";
  b1Cell.alignment = { vertical: "middle", horizontal: "center" };
  b1Cell.style = { border: fillBorders(), font: { bold: true } };

  const c1Cell = sheet.getCell(`C2`);
  c1Cell.value = "счет отправителя";
  c1Cell.alignment = { vertical: "middle", horizontal: "center" };
  c1Cell.style = { border: fillBorders(), font: { bold: true } };

  const d1Cell = sheet.getCell(`D2`);
  d1Cell.value = "ИНН отправителя";
  d1Cell.alignment = { vertical: "middle", horizontal: "center" };
  d1Cell.style = { border: fillBorders(), font: { bold: true } };

  const e1Cell = sheet.getCell(`E2`);
  e1Cell.value = "наименование отправителя";
  e1Cell.alignment = { vertical: "middle", horizontal: "center" };
  e1Cell.style = { border: fillBorders(), font: { bold: true } };

  const f1Cell = sheet.getCell(`F2`);
  f1Cell.value = "МФО отправителя";
  f1Cell.alignment = { vertical: "middle", horizontal: "center" };
  f1Cell.style = { border: fillBorders(), font: { bold: true } };

  const g1Cell = sheet.getCell(`G2`);
  g1Cell.value = "счет получателя";
  g1Cell.alignment = { vertical: "middle", horizontal: "center" };
  g1Cell.style = { border: fillBorders(), font: { bold: true } };

  const h1Cell = sheet.getCell(`H2`);
  h1Cell.value = "счет получателя (Index acct)";
  h1Cell.alignment = { vertical: "middle", horizontal: "center" };
  h1Cell.style = { border: fillBorders(), font: { bold: true } };

  const i1Cell = sheet.getCell(`I2`);
  i1Cell.value = "МФО получателя";
  i1Cell.alignment = { vertical: "middle", horizontal: "center" };
  i1Cell.style = { border: fillBorders(), font: { bold: true } };

  const j1Cell = sheet.getCell(`J2`);
  j1Cell.value = "наименование получателя";
  j1Cell.alignment = { vertical: "middle", horizontal: "center" };
  j1Cell.style = { border: fillBorders(), font: { bold: true } };

  const k1Cell = sheet.getCell(`K2`);
  k1Cell.value = "ИНН получателя";
  k1Cell.alignment = { vertical: "middle", horizontal: "center" };
  k1Cell.style = { border: fillBorders(), font: { bold: true } };

  const l1Cell = sheet.getCell(`L2`);
  l1Cell.value = "№ документа";
  l1Cell.alignment = { vertical: "middle", horizontal: "center" };
  l1Cell.style = { border: fillBorders(), font: { bold: true } };

  const m1Cell = sheet.getCell(`M2`);
  m1Cell.value = "Расход";
  m1Cell.alignment = { vertical: "middle", horizontal: "center" };
  m1Cell.style = { border: fillBorders(), font: { bold: true } };

  const n1Cell = sheet.getCell(`N2`);
  n1Cell.value = "Приход";
  n1Cell.alignment = { vertical: "middle", horizontal: "center" };
  n1Cell.style = { border: fillBorders(), font: { bold: true } };

  const o1Cell = sheet.getCell(`O2`);
  o1Cell.value = "код платежа";
  o1Cell.alignment = { vertical: "middle", horizontal: "center" };
  o1Cell.style = { border: fillBorders(), font: { bold: true } };

  const p1Cell = sheet.getCell(`P2`);
  p1Cell.value = "назначение платежа";
  p1Cell.alignment = { vertical: "middle", horizontal: "center" };
  p1Cell.style = { border: fillBorders(), font: { bold: true } };

  const q1Cell = sheet.getCell(`Q2`);
  q1Cell.value = "Вид документа";
  q1Cell.alignment = { vertical: "middle", horizontal: "center" };
  q1Cell.style = { border: fillBorders(), font: { bold: true } };

  const r1Cell = sheet.getCell(`R2`);
  r1Cell.value = "Номер транзакции";
  r1Cell.alignment = { vertical: "middle", horizontal: "center" };
  r1Cell.style = { border: fillBorders(), font: { bold: true } };
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 3;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 3 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = i + 1;
    aCells_1.alignment = { vertical: "middle" };
    aCells_1.border = fillBorders();

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].transactionDate;
    bCells_1.alignment = { vertical: "middle" };
    bCells_1.border = fillBorders();

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverAccount : newData[i].senderAccount;
    cCells_1.alignment = { vertical: "middle" };
    cCells_1.border = fillBorders();

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverTaxNumber : newData[i].senderTaxNumber;
    dCells_1.alignment = { vertical: "middle" };
    dCells_1.border = fillBorders();

    //Table E column cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].receiverName : newData[i].clientName;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.border = fillBorders();

    //Table F column cells
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value =
      newData[i].debitAmount === "0"
        ? newData[i].receiverMfo
          ? newData[i].receiverMfo
          : newData[i].receiverMFO
        : newData[i].senderMfo
        ? newData[i].senderMfo
        : newData[i].senderMFO;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.border = fillBorders();

    //Table G column cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].senderAccount : newData[i].receiverAccount;
    gCells_1.alignment = { vertical: "middle" };
    gCells_1.border = fillBorders();

    //Table H column cells
    const hCells_1 = sheet.getCell(`H${tableCells}`);
    hCells_1.value = "";
    hCells_1.alignment = { vertical: "middle" };
    hCells_1.border = fillBorders();

    //Table I column cells
    const iCells_1 = sheet.getCell(`I${tableCells}`);
    iCells_1.value =
      newData[i].debitAmount === "0"
        ? newData[i].senderMfo
          ? newData[i].senderMfo
          : newData[i].senderMFO
        : newData[i].receiverMfo
        ? newData[i].receiverMfo
        : newData[i].receiverMFO;
    iCells_1.alignment = { vertical: "middle" };
    iCells_1.border = fillBorders();

    //Table J column cells
    const jCells_1 = sheet.getCell(`J${tableCells}`);
    jCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].clientName : newData[i].receiverName;
    jCells_1.alignment = { vertical: "middle" };
    jCells_1.border = fillBorders();

    //Table K column cells
    const kCells_1 = sheet.getCell(`K${tableCells}`);
    kCells_1.value =
      newData[i].debitAmount === "0" ? newData[i].senderTaxNumber : newData[i].receiverTaxNumber;
    kCells_1.alignment = { vertical: "middle" };
    kCells_1.border = fillBorders();

    //Table L column cells
    const lCells_1 = sheet.getCell(`L${tableCells}`);
    lCells_1.value = newData[i].documentNumber;
    lCells_1.alignment = { vertical: "middle" };
    lCells_1.border = fillBorders();

    //Table M column cells
    const mCells_1 = sheet.getCell(`M${tableCells}`);
    mCells_1.value = newData[i].debitAmount;
    mCells_1.alignment = { vertical: "middle" };
    mCells_1.border = fillBorders();

    //Table N column cells
    const nCells_1 = sheet.getCell(`N${tableCells}`);
    nCells_1.value = newData[i].creditAmount;
    nCells_1.alignment = { vertical: "middle" };
    nCells_1.border = fillBorders();

    //Table O column cells
    const oCells_1 = sheet.getCell(`O${tableCells}`);
    oCells_1.value = newData[i].paymentCode;
    oCells_1.alignment = { vertical: "middle" };
    oCells_1.border = fillBorders();

    //Table P column cells
    const pCells_1 = sheet.getCell(`P${tableCells}`);
    pCells_1.value = newData[i].paymentDetail;
    pCells_1.alignment = { vertical: "middle" };
    pCells_1.border = fillBorders();

    //Table Q column cells
    const qCells_1 = sheet.getCell(`Q${tableCells}`);
    qCells_1.value = newData[i].documentType;
    qCells_1.alignment = { vertical: "middle" };
    qCells_1.border = fillBorders();

    //Table R column cells
    const rCells_1 = sheet.getCell(`R${tableCells}`);
    rCells_1.value = newData[i].transactionId;
    rCells_1.alignment = { vertical: "middle" };
    rCells_1.border = fillBorders();
  }
}

export function createStatementExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelSheet(workbook, data);

  return workbook;
}

function createStatementExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 10 },
    { key: "column3", width: 25 },
    { key: "column4", width: 8 },
    { key: "column5", width: 20 },
    { key: "column6", width: 20 },
    { key: "column7", width: 20 },
  ] as any;

  // Объединение ячеек
  sheet.mergeCells("D4:G4");
  sheet.mergeCells("C7:F7");
  sheet.mergeCells("A8:E8");
  sheet.mergeCells("A9:E9");
  sheet.mergeCells("A15:C15");
  sheet.mergeCells("C11:D11");
  sheet.mergeCells("A10:B10");
  sheet.mergeCells("A11:B11");
  sheet.mergeCells("A12:B12");
  sheet.mergeCells("A13:B13");
  sheet.mergeCells("A14:B14");
  sheet.mergeCells("C1:D1");
  sheet.mergeCells("C12:G12");
  sheet.mergeCells("C13:G13");

  const d4Cell = sheet.getCell("D4");
  d4Cell.alignment = { wrapText: true, vertical: "middle" };
  d4Cell.value = "ул. Бухара, 3, Узбекистан, Ташкент 100047";
  d4Cell.font = { bold: true };

  const d5Cell = sheet.getCell("D5");
  d5Cell.alignment = { wrapText: true, vertical: "middle" };
  d5Cell.value = "Tel: ";
  d5Cell.font = { bold: true };

  const e5Cell = sheet.getCell("E5");
  e5Cell.alignment = { wrapText: true, vertical: "middle" };
  e5Cell.value = "(99878) 120-8000";
  e5Cell.font = { bold: true };

  const f5Cell = sheet.getCell("F5");
  f5Cell.alignment = { wrapText: true, vertical: "middle" };
  f5Cell.value = "Fax :";
  f5Cell.font = { bold: true };

  const g5Cell = sheet.getCell("G5");
  g5Cell.alignment = { wrapText: true, vertical: "middle" };
  g5Cell.value = "(99871) 120-6970";
  g5Cell.font = { bold: true };

  const d6Cell = sheet.getCell("D6");
  d6Cell.alignment = { wrapText: true, vertical: "middle" };
  d6Cell.value = "Telex: ";
  d6Cell.font = { bold: true };

  const e6Cell = sheet.getCell("E6");
  e6Cell.alignment = { wrapText: true, vertical: "middle" };
  e6Cell.value = "(99871) 120-6970";
  e6Cell.font = { bold: true };

  const f6Cell = sheet.getCell("F6");
  f6Cell.alignment = { wrapText: true, vertical: "middle" };
  f6Cell.value = "S.W.I.F.T. :";
  f6Cell.font = { bold: true };

  const g6Cell = sheet.getCell("G6");
  g6Cell.alignment = { wrapText: true, vertical: "middle" };
  g6Cell.value = "KODBUZ22";
  g6Cell.font = { bold: true };

  const a8Cell = sheet.getCell("A8");
  a8Cell.alignment = { wrapText: true, vertical: "middle" };
  a8Cell.value = data.clientInfo;
  a8Cell.font = { bold: true };

  const f9Cell = sheet.getCell("F9");
  f9Cell.alignment = { wrapText: true, vertical: "middle", horizontal: "right" };
  f9Cell.value = data.fromDate;
  f9Cell.font = { bold: true };

  const g9Cell = sheet.getCell("G9");
  g9Cell.alignment = { wrapText: true, vertical: "middle", horizontal: "right" };
  g9Cell.value = data.toDate;
  g9Cell.font = { bold: true };

  const a10Cell = sheet.getCell("A10");
  a10Cell.alignment = { wrapText: true, vertical: "middle" };
  a10Cell.value = "Phone: ";
  a10Cell.font = { bold: true };

  const c10Cell = sheet.getCell("C10");
  c10Cell.alignment = { wrapText: true, vertical: "middle" };
  c10Cell.value = data.phoneNumber;
  c10Cell.font = { bold: true };

  const d10Cell = sheet.getCell("D10");
  d10Cell.alignment = { wrapText: true, vertical: "middle" };
  d10Cell.value = "Fax:";
  d10Cell.font = { bold: true };

  const f10Cell = sheet.getCell("F10");
  f10Cell.alignment = { wrapText: true, vertical: "middle" };
  f10Cell.value = "Last tr.date";
  f10Cell.font = { bold: true };

  const g10Cell = sheet.getCell("G10");
  g10Cell.alignment = { wrapText: true, vertical: "middle", horizontal: "right" };
  g10Cell.value = data.lastTransactionDate;
  g10Cell.font = { bold: true };

  const a11Cell = sheet.getCell("A11");
  a11Cell.alignment = { wrapText: true, vertical: "middle" };
  a11Cell.value = "Account No :";
  a11Cell.font = { bold: true };

  const c11Cell = sheet.getCell("C11");
  c11Cell.alignment = { wrapText: true, vertical: "middle" };
  c11Cell.value = data.accountNumber;
  c11Cell.font = { bold: true };

  const e11Cell = sheet.getCell("E11");
  e11Cell.alignment = { wrapText: true, vertical: "middle" };
  e11Cell.value = " Currency :";
  e11Cell.font = { bold: true };

  const f11Cell = sheet.getCell("F11");
  f11Cell.alignment = { wrapText: true, vertical: "middle" };
  f11Cell.value = data.currency;
  f11Cell.font = { bold: true };

  const a12Cell = sheet.getCell("A12");
  a12Cell.alignment = { wrapText: true, vertical: "middle" };
  a12Cell.value = "Account Name: ";
  a12Cell.font = { bold: true };

  const c12Cell = sheet.getCell("C12");
  c12Cell.alignment = { wrapText: true, vertical: "middle" };
  c12Cell.value = data.accountName;
  c12Cell.font = { bold: true };

  const a13Cell = sheet.getCell("A13");
  a13Cell.alignment = { wrapText: true, vertical: "middle" };
  a13Cell.value = "Account type: ";
  a13Cell.font = { bold: true };

  const c13Cell = sheet.getCell("C13");
  c13Cell.alignment = { vertical: "middle" };
  c13Cell.value = data.accountType;
  c13Cell.font = { bold: true };

  const a14Cell = sheet.getCell("A14");
  a14Cell.alignment = { vertical: "middle" };
  a14Cell.value = "Post value";
  a14Cell.font = { bold: true };

  const c14Cell = sheet.getCell("C14");
  c14Cell.alignment = { vertical: "middle" };
  c14Cell.value = "Reference / Description";
  c14Cell.font = { bold: true };

  const e14Cell = sheet.getCell("E14");
  e14Cell.alignment = { vertical: "middle" };
  e14Cell.value = "Debit";
  e14Cell.font = { bold: true };

  const f14Cell = sheet.getCell("F14");
  f14Cell.alignment = { vertical: "middle" };
  f14Cell.value = "Credit";
  f14Cell.font = { bold: true };

  const g14Cell = sheet.getCell("G14");
  g14Cell.alignment = { vertical: "middle" };
  g14Cell.value = "Balance";
  g14Cell.font = { bold: true };

  const e15Cell = sheet.getCell("E15");
  e15Cell.alignment = { vertical: "middle" };
  e15Cell.value = data.fromDate;
  e15Cell.font = { bold: true };

  const f15Cell = sheet.getCell("F15");
  f15Cell.alignment = { vertical: "middle" };
  f15Cell.value = "Opening Balance";
  f15Cell.font = { bold: true };

  const g15Cell = sheet.getCell("G15");
  g15Cell.alignment = { vertical: "middle" };
  g15Cell.value = data.beginBalance !== 0 ? floatFormat(addZeros(data.beginBalance)) : 0;
  g15Cell.font = { bold: true };

  const newData = data.list.sort(sortDataFromDate);

  let tableCells = 16;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 16 + i * 4;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = newData[i].bankTellerSequence;
    aCells_1.alignment = { vertical: "middle" };

    const aCells_2 = sheet.getCell(`A${tableCells + 1}`);
    aCells_2.value = "Doc №";

    const aCells_3 = sheet.getCell(`A${tableCells + 2}`);
    aCells_3.value = "Val.date";

    const aCells_4 = sheet.getCell(`A${tableCells + 3}`);
    aCells_4.value = "Tr.date";
    aCells_4.border = bottomBorders();

    //  B Column cells

    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].bankTellerId;
    bCells_1.alignment = { vertical: "middle" };

    const bCells_2 = sheet.getCell(`B${tableCells + 1}`);
    bCells_2.value = newData[i].documentNumber;
    bCells_2.alignment = { vertical: "middle" };

    const bCells_3 = sheet.getCell(`B${tableCells + 2}`);
    bCells_3.value = newData[i].transactionDate;
    bCells_3.alignment = { vertical: "middle" };

    const bCells_4 = sheet.getCell(`B${tableCells + 3}`);
    bCells_4.value = newData[i].transactionDate;
    bCells_4.alignment = { vertical: "middle" };
    bCells_4.border = bottomBorders();

    //  C Column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value = newData[i].receiverAccount;
    cCells_1.alignment = { vertical: "middle" };

    const cCells_2 = sheet.getCell(`C${tableCells + 1}`);
    cCells_2.value = `${newData[i].receiverTaxNumber} ${newData[i].receiverName} ${newData[i].paymentDetail}`;
    cCells_2.alignment = { vertical: "middle", wrapText: true };
    cCells_2.border = bottomBorders();

    //  merge cells

    sheet.mergeCells(`C${tableCells + 1}:G${tableCells + 3}`);

    // D column Cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value = newData[i].receiverMFO;
    dCells_1.alignment = { vertical: "middle" };

    // E column Cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value =
      newData[i].debitAmount !== 0 ? floatFormat(addZeros(newData[i].debitAmount)) : 0;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.style = { fill: fillBackground({ argb: "dbdbdb" }) };

    // F column Cellsr
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value =
      newData[i].creditAmount !== 0 ? floatFormat(addZeros(newData[i].creditAmount)) : 0;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.style = { fill: fillBackground({ argb: "dbdbdb" }) };
    cCells_2.border = bottomBorders();

    // G column Cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value = newData[i].balance !== 0 ? floatFormat(addZeros(newData[i].balance)) : 0;
    gCells_1.alignment = { vertical: "middle" };
  }

  // Table Footer Cells

  const tFooterACells_1 = sheet.getCell(`A${tableCells + 4}`);
  tFooterACells_1.value = "Item Enclosed:";

  const tFooterACells_2 = sheet.getCell(`A${tableCells + 5}`);
  tFooterACells_2.value = data.toDate;

  const tFooteCCells_1 = sheet.getCell(`C${tableCells + 4}`);
  tFooteCCells_1.value = data.total;

  const tFooteCCells_3 = sheet.getCell(`C${tableCells + 6}`);
  tFooteCCells_3.value = " End Of Statement";

  const tFooterDCells_1 = sheet.getCell(`D${tableCells + 4}`);
  tFooterDCells_1.value = "Total:";

  const tFooterECells_1 = sheet.getCell(`E${tableCells + 4}`);
  tFooterECells_1.value = data.creditAmount !== 0 ? floatFormat(addZeros(data.creditAmount)) : 0;

  const tFooterFCells_1 = sheet.getCell(`F${tableCells + 4}`);
  tFooterFCells_1.value = data.debitAmount !== 0 ? floatFormat(addZeros(data.debitAmount)) : 0;

  const tFooterFCells_2 = sheet.getCell(`F${tableCells + 5}`);
  tFooterFCells_2.value = "Closing Balance:";

  const tFooterGCells_2 = sheet.getCell(`G${tableCells + 5}`);
  tFooterGCells_2.value = data.endBalance !== 0 ? floatFormat(addZeros(data.endBalance)) : 0;

  //  merge cells
  sheet.mergeCells(`A${tableCells + 4}:B${tableCells + 4}`);
  sheet.mergeCells(`A${tableCells + 5}:B${tableCells + 5}`);

  // Добаление  фона
  sheet.getCell("D4").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("E5").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("E6").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("G6").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("G5").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("A8").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("A9").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("C10").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("E10").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("C11").style = { fill: fillBackground({ argb: "dbdbdb" }) };
  sheet.getCell("G15").style = { fill: fillBackground({ argb: "dbdbdb" }) };
}
export function createStatementExcelAngleseyFromTable(
  data: any,
  options: CreateStatementExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelAngleseySheet(workbook, data, options);

  return workbook;
}

function createStatementExcelAngleseySheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate }: CreateStatementExcel,
) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 14 } });
  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 25 },
    { key: "column2", width: 10 },
    { key: "column3", width: 22 },
    { key: "column4", width: 15 },
    { key: "column5", width: 23 },
    { key: "column6", width: 15 },
    { key: "column7", width: 23 },
    { key: "column8", width: 23 },
    { key: "column9", width: 15 },
    { key: "column10", width: 23 },
    { key: "column11", width: 15 },
    { key: "column12", width: 12 },
    { key: "column13", width: 15 },
    { key: "column14", width: 15 },
    { key: "column15", width: 12 },
    { key: "column16", width: 40 },
    { key: "column17", width: 15 },
    { key: "column18", width: 20 },
  ] as any;

  const a1Cell = sheet.getCell(`A1`);
  a1Cell.alignment = { wrapText: true, vertical: "middle" };
  a1Cell.value = data.fromDate;

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.alignment = { vertical: "middle" };
  a2Cell.value = data.accountNumber;

  const a3Cell = sheet.getCell(`A3`);
  a3Cell.alignment = { wrapText: true, vertical: "middle" };
  a3Cell.value = data.clientInfo;

  const a4Cell = sheet.getCell(`A4`);
  a4Cell.alignment = { wrapText: true, vertical: "middle" };
  a4Cell.value = data.senderMFO;

  const a5Cell = sheet.getCell(`A5`);
  a5Cell.alignment = { wrapText: true, vertical: "middle" };
  a5Cell.value = data.senderBankName;

  const a6Cell = sheet.getCell(`A6`);
  a6Cell.alignment = { wrapText: true, vertical: "middle" };
  a6Cell.value = data.beginBalance;

  const a7Cell = sheet.getCell(`A7`);
  a7Cell.alignment = { wrapText: true, vertical: "middle" };
  a7Cell.value = data.debitAmount;
  a7Cell.font = { bold: true };

  const a8Cell = sheet.getCell(`A8`);
  a8Cell.alignment = { wrapText: true, vertical: "middle" };
  a8Cell.value = data.creditAmount;

  const a9Cell = sheet.getCell(`A9`);
  a9Cell.alignment = { wrapText: true, vertical: "middle" };
  a9Cell.value = data.endBalance;

  const a10Cell = sheet.getCell(`A10`);
  a10Cell.alignment = { vertical: "middle" };
  a10Cell.value = "№";
  a10Cell.border = fillBorders();

  const b10Cell = sheet.getCell(`B10`);
  b10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  b10Cell.value = translate("STATEMENT_EXPORT_EXCEL_DATA_LABEL");
  b10Cell.border = fillBorders();

  const c10Cell = sheet.getCell(`C10`);
  c10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  c10Cell.value = translate("STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_LABEL");
  c10Cell.border = fillBorders();

  const d10Cell = sheet.getCell(`D10`);
  d10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  d10Cell.value = translate("STATEMENT_EXPORT_EXCEL_SENDER_TAX_NUMBER_LABEL");
  d10Cell.border = fillBorders();

  const e10Cell = sheet.getCell(`E10`);
  e10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  e10Cell.value = translate("STATEMENT_EXPORT_EXCEL_SENDER_NAME_LABEL");
  e10Cell.border = fillBorders();

  const f10Cell = sheet.getCell(`F10`);
  f10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  f10Cell.value = translate("STATEMENT_EXPORT_EXCEL_SENDER_MFO_LABEL");
  f10Cell.border = fillBorders();

  const g10Cell = sheet.getCell(`G10`);
  g10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  g10Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_LABEL");
  g10Cell.border = fillBorders();

  const h10Cell = sheet.getCell(`H10`);
  h10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  h10Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_INDEX_LABEL");
  h10Cell.border = fillBorders();

  const i10Cell = sheet.getCell(`I10`);
  i10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  i10Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_MFO_LABEL");
  i10Cell.border = fillBorders();

  const j10Cell = sheet.getCell(`J10`);
  j10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  j10Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL");
  j10Cell.border = fillBorders();

  const k10Cell = sheet.getCell(`K10`);
  k10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  k10Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_TAX_NUMBER_LABEL");
  k10Cell.border = fillBorders();

  const l10Cell = sheet.getCell(`L10`);
  l10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  l10Cell.value = translate("STATEMENT_EXPORT_EXCEL_DOCUMENT_NUMBER_LABEL");
  l10Cell.border = fillBorders();

  const m10Cell = sheet.getCell(`M10`);
  m10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  m10Cell.value = translate("STATEMENT_EXPORT_EXCEL_EXPENDITURE_LABEL");
  m10Cell.border = fillBorders();

  const n10Cell = sheet.getCell(`N10`);
  n10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  n10Cell.value = translate("STATEMENT_EXPORT_EXCEL_COMING_LABEL");
  n10Cell.border = fillBorders();

  const o10Cell = sheet.getCell(`O10`);
  o10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  o10Cell.value = translate("STATEMENT_EXPORT_EXCEL_PAYMENT_CODE_LABEL");
  o10Cell.border = fillBorders();

  const p10Cell = sheet.getCell(`P10`);
  p10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  p10Cell.value = translate("STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL");
  p10Cell.border = fillBorders();

  const q10Cell = sheet.getCell(`Q10`);
  q10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  q10Cell.value = translate("STATEMENT_EXPORT_EXCEL_DOCUMENT_TYPE_LABEL");
  q10Cell.border = fillBorders();

  const r10Cell = sheet.getCell(`R10`);
  r10Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  r10Cell.value = translate("STATEMENT_EXPORT_EXCEL_TRANSACTION_NUMBER_LABEL");
  r10Cell.border = fillBorders();
  // const newData = data.list.sort(sortDataFromDate);
  const newData = data.list;
  let tableCells = 11;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 11 + i;

    const a11Cell = sheet.getCell(`A${tableCells}`);
    a11Cell.alignment = { vertical: "middle", horizontal: "center" };
    a11Cell.value = i + 1;
    a11Cell.font = { bold: true };
    a11Cell.border = fillBorders();

    const b11Cell = sheet.getCell(`B${tableCells}`);
    b11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    b11Cell.value = newData[i].transactionDate;
    b11Cell.border = fillBorders();

    const c11Cell = sheet.getCell(`C${tableCells}`);
    c11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    c11Cell.value = newData[i].senderAccount;
    c11Cell.border = fillBorders();

    const d11Cell = sheet.getCell(`D${tableCells}`);
    d11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    d11Cell.value = newData[i].senderTaxNumber;
    d11Cell.border = fillBorders();

    const e11Cell = sheet.getCell(`E${tableCells}`);
    e11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    e11Cell.value = newData[i].clientName;
    e11Cell.border = fillBorders();

    const f11Cell = sheet.getCell(`F${tableCells}`);
    f11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    f11Cell.value = newData[i].senderMFO;
    f11Cell.border = fillBorders();

    const g11Cell = sheet.getCell(`G${tableCells}`);
    g11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    g11Cell.value = newData[i].receiverAccount;
    g11Cell.border = fillBorders();

    const h11Cell = sheet.getCell(`H${tableCells}`);
    h11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    h11Cell.value = "";
    h11Cell.border = fillBorders();

    const i11Cell = sheet.getCell(`I${tableCells}`);
    i11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    i11Cell.value = newData[i].receiverMFO;
    i11Cell.border = fillBorders();

    const j11Cell = sheet.getCell(`J${tableCells}`);
    j11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    j11Cell.value = newData[i].receiverName;
    j11Cell.border = fillBorders();

    const k11Cell = sheet.getCell(`K${tableCells}`);
    k11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    k11Cell.value = newData[i].receiverTaxNumber;
    k11Cell.border = fillBorders();

    const l11Cell = sheet.getCell(`L${tableCells}`);
    l11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    l11Cell.value = newData[i].documentNumber;
    l11Cell.border = fillBorders();

    const m11Cell = sheet.getCell(`M${tableCells}`);
    m11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    m11Cell.value = newData[i].debitAmount;
    m11Cell.border = fillBorders();

    const n11Cell = sheet.getCell(`N${tableCells}`);
    n11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    n11Cell.value = newData[i].creditAmount;
    n11Cell.border = fillBorders();

    const o11Cell = sheet.getCell(`O${tableCells}`);
    o11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    o11Cell.value = newData[i].paymentCode;
    o11Cell.border = fillBorders();

    const p11Cell = sheet.getCell(`P${tableCells}`);
    p11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    p11Cell.value = newData[i].paymentDetail;
    p11Cell.border = fillBorders();

    const q11Cell = sheet.getCell(`Q${tableCells}`);
    q11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    q11Cell.value = newData[i].documentType;
    q11Cell.border = fillBorders();

    const r11Cell = sheet.getCell(`R${tableCells}`);
    r11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    r11Cell.value = newData[i].transactionId;
    r11Cell.border = fillBorders();
  }
}

export function createStatementExcelFuttaimFromTable(
  data: any,
  options: CreateStatementExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelFuttaimSheet(workbook, data, options);

  return workbook;
}

function createStatementExcelFuttaimSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate }: CreateStatementExcel,
) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });
  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 6 },
    { key: "column2", width: 10 },
    { key: "column3", width: 20 },
    { key: "column4", width: 20 },
    { key: "column5", width: 17 },
    { key: "column6", width: 17 },
    { key: "column7", width: 23 },
    { key: "column8", width: 15 },
    { key: "column9", width: 20 },
    { key: "column10", width: 22 },
    { key: "column11", width: 20 },
    { key: "column12", width: 25 },
  ] as any;

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.alignment = { vertical: "middle" };
  a2Cell.value = "№";
  a2Cell.border = fillBorders();

  const b2Cell = sheet.getCell(`B2`);
  b2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  b2Cell.value = translate("STATEMENT_EXPORT_EXCEL_CARD_TYPE_LABEL");
  b2Cell.border = fillBorders();

  const c2Cell = sheet.getCell(`C2`);
  c2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  c2Cell.value = translate("STATEMENT_EXPORT_EXCEL_TRANSITION_DATE_LABEL");
  c2Cell.border = fillBorders();

  const d2Cell = sheet.getCell(`D2`);
  d2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  d2Cell.value = translate("STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_NUMBER_LABEL");
  d2Cell.border = fillBorders();

  const e2Cell = sheet.getCell(`E2`);
  e2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  e2Cell.value = translate("STATEMENT_EXPORT_EXCEL_TRANSITION_TIME_LABEL");
  e2Cell.border = fillBorders();

  const f2Cell = sheet.getCell(`F2`);
  f2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  f2Cell.value = translate("STATEMENT_EXPORT_EXCEL_DOCUMENT_NUMBER_LABEL");
  f2Cell.border = fillBorders();

  const g2Cell = sheet.getCell(`G2`);
  g2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  g2Cell.value = translate("STATEMENT_EXPORT_EXCEL_TRANSITION_PLACE_LABEL");
  g2Cell.border = fillBorders();

  const h2Cell = sheet.getCell(`H2`);
  h2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  h2Cell.value = translate("STATEMENT_EXPORT_EXCEL_AMOUNT_LABEL");
  h2Cell.border = fillBorders();

  const i2Cell = sheet.getCell(`I2`);
  i2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  i2Cell.value = translate("STATEMENT_EXPORT_EXCEL_COMMISSION_AMOUNT_LABEL");
  i2Cell.border = fillBorders();

  const j2Cell = sheet.getCell(`J2`);
  j2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  j2Cell.value = translate("STATEMENT_EXPORT_EXCEL_AMOUNT_AFTER_COMMISSION_LABEL");
  j2Cell.border = fillBorders();

  const k2Cell = sheet.getCell(`K2`);
  k2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  k2Cell.value = translate("STATEMENT_EXPORT_EXCEL_SELLER_ID_LABEL");
  k2Cell.border = fillBorders();

  const l2Cell = sheet.getCell(`L2`);
  l2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  l2Cell.value = translate("STATEMENT_EXPORT_EXCEL_CLIENT_NAME_LABEL");
  l2Cell.border = fillBorders();
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 3;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 3 + i;

    const a11Cell = sheet.getCell(`A${tableCells}`);
    a11Cell.alignment = { vertical: "middle", horizontal: "center" };
    a11Cell.value = i + 1;
    a11Cell.font = { bold: true };
    a11Cell.border = fillBorders();

    const b11Cell = sheet.getCell(`B${tableCells}`);
    b11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    b11Cell.value = newData[i].cardType;
    b11Cell.border = fillBorders();

    const c11Cell = sheet.getCell(`C${tableCells}`);
    c11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    c11Cell.value = newData[i].transactionDate;
    c11Cell.border = fillBorders();

    const d11Cell = sheet.getCell(`D${tableCells}`);
    d11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    d11Cell.value = newData[i].senderAccount;
    d11Cell.border = fillBorders();

    const e11Cell = sheet.getCell(`E${tableCells}`);
    e11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    e11Cell.value = newData[i].transactionDateTime;
    e11Cell.border = fillBorders();

    const f11Cell = sheet.getCell(`F${tableCells}`);
    f11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    f11Cell.value = newData[i].documentNumber;
    f11Cell.border = fillBorders();

    const g11Cell = sheet.getCell(`G${tableCells}`);
    g11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    g11Cell.value = newData[i].transactionPlace;
    g11Cell.border = fillBorders();

    const h11Cell = sheet.getCell(`H${tableCells}`);
    h11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    h11Cell.value = newData[i].amount;
    h11Cell.border = fillBorders();

    const i11Cell = sheet.getCell(`I${tableCells}`);
    i11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    i11Cell.value = newData[i].commissionAmount;
    i11Cell.border = fillBorders();

    const j11Cell = sheet.getCell(`J${tableCells}`);
    j11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    j11Cell.value = newData[i].amountAfterCommission;
    j11Cell.border = fillBorders();

    const k11Cell = sheet.getCell(`K${tableCells}`);
    k11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    k11Cell.value = newData[i].sellerId;
    k11Cell.border = fillBorders();

    const l11Cell = sheet.getCell(`L${tableCells}`);
    l11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    l11Cell.value = newData[i].clientName;
    l11Cell.border = fillBorders();
  }
}

export function createStatementExcelAglesey2FromTable(
  data: any,
  options: CreateStatementExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelAnglesey2Sheet(workbook, data, options);

  return workbook;
}

function createStatementExcelAnglesey2Sheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate }: CreateStatementExcel,
) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });
  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 6 },
    { key: "column2", width: 25 },
    { key: "column3", width: 17 },
    { key: "column4", width: 15 },
    { key: "column5", width: 20 },
    { key: "column6", width: 25 },
    { key: "column7", width: 17 },
    { key: "column8", width: 17 },
    { key: "column9", width: 20 },
    { key: "column10", width: 15 },
    { key: "column11", width: 15 },
    { key: "column12", width: 20 },
  ] as any;

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.alignment = { vertical: "middle" };
  a2Cell.value = "№";
  a2Cell.border = fillBorders();

  const b2Cell = sheet.getCell(`B2`);
  b2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  b2Cell.value = translate("STATEMENT_EXPORT_EXCEL_ACCOUNT_NUMBER_LABEL");
  b2Cell.border = fillBorders();

  const c2Cell = sheet.getCell(`C2`);
  c2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  c2Cell.value = translate("STATEMENT_EXPORT_EXCEL_AMOUNT_LABEL");
  c2Cell.border = fillBorders();

  const d2Cell = sheet.getCell(`D2`);
  d2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  d2Cell.value = translate("STATEMENT_EXPORT_EXCEL_DR_CR_INDICATOR_LABEL");
  d2Cell.border = fillBorders();

  const e2Cell = sheet.getCell(`E2`);
  e2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  e2Cell.value = translate("STATEMENT_EXPORT_EXCEL_EFFECTIVE_DATE_LABEL");
  e2Cell.border = fillBorders();

  const f2Cell = sheet.getCell(`F2`);
  f2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  f2Cell.value = translate("STATEMENT_EXPORT_EXCEL_TRANSITION_PLACE_LABEL");
  f2Cell.border = fillBorders();

  const g2Cell = sheet.getCell(`G2`);
  g2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  g2Cell.value = translate("STATEMENT_EXPORT_EXCEL_ISO_TERMINAL_ID_LABEL");
  g2Cell.border = fillBorders();

  const h2Cell = sheet.getCell(`H2`);
  h2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  h2Cell.value = translate("STATEMENT_EXPORT_EXCEL_ISO_MERCHANT_ID_LABEL");
  h2Cell.border = fillBorders();

  const i2Cell = sheet.getCell(`I2`);
  i2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  i2Cell.value = translate("STATEMENT_EXPORT_EXCEL_CARD_NUMBER_LABEL");
  i2Cell.border = fillBorders();

  const j2Cell = sheet.getCell(`J2`);
  j2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  j2Cell.value = translate("STATEMENT_EXPORT_EXCEL_DT_BEG_LABEL");
  j2Cell.border = fillBorders();

  const k2Cell = sheet.getCell(`K2`);
  k2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  k2Cell.value = translate("STATEMENT_EXPORT_EXCEL_DT_END_LABEL");
  k2Cell.border = fillBorders();

  const l2Cell = sheet.getCell(`L2`);
  l2Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  l2Cell.value = translate("STATEMENT_EXPORT_EXCEL_H_LOCACC_LABEL");
  l2Cell.border = fillBorders();

  let tableCells = 3;
  for (let i = 0; i < data.list.length; i++) {
    tableCells = 3 + i;

    const a11Cell = sheet.getCell(`A${tableCells}`);
    a11Cell.alignment = { vertical: "middle", horizontal: "center" };
    a11Cell.value = i + 1;
    a11Cell.font = { bold: true };
    a11Cell.border = fillBorders();

    const b11Cell = sheet.getCell(`B${tableCells}`);
    b11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    b11Cell.value = data.list[i].accountNumber;
    b11Cell.border = fillBorders();

    const c11Cell = sheet.getCell(`C${tableCells}`);
    c11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    c11Cell.value = data.list[i].amount;
    c11Cell.border = fillBorders();

    const d11Cell = sheet.getCell(`D${tableCells}`);
    d11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    d11Cell.value = data.list[i].debitOrcredit;
    d11Cell.border = fillBorders();

    const e11Cell = sheet.getCell(`E${tableCells}`);
    e11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    e11Cell.value = data.list[i].effectiveDate;
    e11Cell.border = fillBorders();

    const f11Cell = sheet.getCell(`F${tableCells}`);
    f11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    f11Cell.value = data.list[i].transitionPlace;
    f11Cell.border = fillBorders();

    const g11Cell = sheet.getCell(`G${tableCells}`);
    g11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    g11Cell.value = data.list[i].isoTerminalId;
    g11Cell.border = fillBorders();

    const h11Cell = sheet.getCell(`H${tableCells}`);
    h11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    h11Cell.value = data.list[i].isoMerchantId;
    h11Cell.border = fillBorders();

    const i11Cell = sheet.getCell(`I${tableCells}`);
    i11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    i11Cell.value = data.list[i].cardNumber;
    i11Cell.border = fillBorders();

    const j11Cell = sheet.getCell(`J${tableCells}`);
    j11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    j11Cell.value = data.list[i].fromDate;
    j11Cell.border = fillBorders();

    const k11Cell = sheet.getCell(`K${tableCells}`);
    k11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    k11Cell.value = data.list[i].toDate;
    k11Cell.border = fillBorders();

    const l11Cell = sheet.getCell(`L${tableCells}`);
    l11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    l11Cell.value = data.list[i].clientName;
    l11Cell.border = fillBorders();
  }
}

export function createStatementExcelAgromirDebitFromTable(
  data: any,
  options: CreateStatementExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelAgromirDebitSheet(workbook, data, options);

  return workbook;
}

function createStatementExcelAgromirDebitSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate }: CreateStatementExcel,
) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });
  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 12 },
    { key: "column3", width: 10 },
    { key: "column4", width: 25 },
    { key: "column5", width: 12 },
    { key: "column6", width: 15 },
    { key: "column7", width: 20 },
    { key: "column8", width: 15 },
    { key: "column9", width: 15 },
    { key: "column10", width: 25 },
    { key: "column11", width: 10 },
    { key: "column12", width: 10 },
    { key: "column11", width: 25 },
    { key: "column12", width: 12 },
    { key: "column11", width: 10 },
    { key: "column12", width: 20 },
    { key: "column12", width: 20 },
    { key: "column12", width: 20 },
  ] as any;

  // Объединение ячеек
  sheet.mergeCells("A2:D2");
  sheet.mergeCells("E3:L3");
  sheet.mergeCells("A4:C4");

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.alignment = { vertical: "middle" };
  a2Cell.value = `${data.accountNumber} - ${data.accountName}`;

  const e3Cell = sheet.getCell(`E3`);
  e3Cell.style = { font: { size: 13, bold: true }, alignment: { vertical: "middle" } };
  e3Cell.value = `${translate("STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_LABEL")} ${
    data.accountNumber
  } ${translate("STATEMENT_EXPORT_EXCEL_FROM_LABEL")} ${data.fromDate} ${translate(
    "STATEMENT_EXPORT_EXCEL_TO_LABEL",
  )} ${data.toDate}`;

  const c2Cell = sheet.getCell(`A4`);
  c2Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c2Cell.value = translate("STATEMENT_EXPORT_EXCEL_BALANCE_BEGINNING_PERIOD_LABEL");

  const d4Cell = sheet.getCell(`D4`);
  d4Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  d4Cell.value = data.openingBalance;

  const e4Cell = sheet.getCell(`E4`);
  e4Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e4Cell.value = translate("STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL");

  const f4Cell = sheet.getCell(`F4`);
  f4Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  f4Cell.value = data.endBalance;

  //Создание таблицы
  const a5Cell = sheet.getCell(`A5`);
  a5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a5Cell.value = "№";
  a5Cell.border = fillBorders();

  const b5Cell = sheet.getCell(`B5`);
  b5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  b5Cell.value = translate("STATEMENT_EXPORT_EXCEL_DOC_NUMBER_LABEL");
  b5Cell.border = fillBorders();

  const c5Cell = sheet.getCell(`C5`);
  c5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c5Cell.value = translate("STATEMENT_EXPORT_EXCEL_BO_LABEL");
  c5Cell.border = fillBorders();

  const d5Cell = sheet.getCell(`D5`);
  d5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  d5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PAYERS_NAME_LABEL");
  d5Cell.border = fillBorders();

  const e5Cell = sheet.getCell(`E5`);
  e5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e5Cell.value = translate("STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL");
  e5Cell.border = fillBorders();

  const f5Cell = sheet.getCell(`F5`);
  f5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  f5Cell.value = translate("STATEMENT_EXPORT_EXCEL_MFO_LABEL");
  f5Cell.border = fillBorders();

  const g5Cell = sheet.getCell(`G5`);
  g5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  g5Cell.value = translate("STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL");
  g5Cell.border = fillBorders();

  const h5Cell = sheet.getCell(`H5`);
  h5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  h5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PAYMENT_DATE_LABEL");
  h5Cell.border = fillBorders();

  const i5Cell = sheet.getCell(`I5`);
  i5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  i5Cell.value = translate("STATEMENT_EXPORT_EXCEL_OPERATION_DATE_LABEL");
  i5Cell.border = fillBorders();

  const j5Cell = sheet.getCell(`J5`);
  j5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  j5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL");
  j5Cell.border = fillBorders();

  const k5Cell = sheet.getCell(`K5`);
  k5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  k5Cell.value = translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE");
  k5Cell.border = fillBorders();

  const l5Cell = sheet.getCell(`L5`);
  l5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  l5Cell.value = translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE");
  l5Cell.border = fillBorders();

  const m5Cell = sheet.getCell(`M5`);
  m5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  m5Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL");
  m5Cell.border = fillBorders();

  const n5Cell = sheet.getCell(`N5`);
  n5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  n5Cell.value = translate("STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL");
  n5Cell.border = fillBorders();

  const o5Cell = sheet.getCell(`O5`);
  o5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  o5Cell.value = translate("STATEMENT_EXPORT_EXCEL_MFO_LABEL");
  o5Cell.border = fillBorders();

  const p5Cell = sheet.getCell(`P5`);
  p5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  p5Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_BANK_LABEL");
  p5Cell.border = fillBorders();

  const q5Cell = sheet.getCell(`Q5`);
  q5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  q5Cell.value = translate("STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL");
  q5Cell.border = fillBorders();

  let tableCells = 6;
  for (let i = 0; i < data.list.length; i++) {
    tableCells = 6 + i;

    const a6Cell = sheet.getCell(`A${tableCells}`);
    a6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", horizontal: "center" } };
    a6Cell.value = i + 1;
    a6Cell.border = fillBorders();

    const b6Cell = sheet.getCell(`B${tableCells}`);
    b6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    b6Cell.value = data.list[i].accountNumber;
    b6Cell.border = fillBorders();

    const c6Cell = sheet.getCell(`C${tableCells}`);
    c6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    c6Cell.value = data.list[i].amount;
    c6Cell.border = fillBorders();

    const d6Cell = sheet.getCell(`D${tableCells}`);
    d6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    d6Cell.value = data.list[i].senderName;
    d6Cell.border = fillBorders();

    const e6Cell = sheet.getCell(`E${tableCells}`);
    e6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    e6Cell.value = data.list[i].senderTaxNumber;
    e6Cell.border = fillBorders();

    const f6Cell = sheet.getCell(`F${tableCells}`);
    f6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    f6Cell.value = data.list[i].senderMFO;
    f6Cell.border = fillBorders();

    const g11Cell = sheet.getCell(`G${tableCells}`);
    g11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    g11Cell.value = data.list[i].senderAccount;
    g11Cell.border = fillBorders();

    const h6Cell = sheet.getCell(`H${tableCells}`);
    h6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    h6Cell.value = data.list[i].transactionDate;
    h6Cell.border = fillBorders();

    const i6Cell = sheet.getCell(`I${tableCells}`);
    i6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    i6Cell.value = data.list[i].cardNumber;
    i6Cell.border = fillBorders();

    const j6Cell = sheet.getCell(`J${tableCells}`);
    j6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    j6Cell.value = data.list[i].fromDate;
    j6Cell.border = fillBorders();

    const k6Cell = sheet.getCell(`K${tableCells}`);
    k6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    k6Cell.value = data.list[i].toDate;
    k6Cell.border = fillBorders();

    const l6Cell = sheet.getCell(`L${tableCells}`);
    l6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    l6Cell.value = data.list[i].clientName;
    l6Cell.border = fillBorders();

    const m6Cell = sheet.getCell(`M${tableCells}`);
    m6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    m6Cell.value = data.list[i].receiverName;
    m6Cell.border = fillBorders();

    const n6Cell = sheet.getCell(`N${tableCells}`);
    n6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    n6Cell.value = data.list[i].receiverTaxNumber;
    n6Cell.border = fillBorders();

    const o6Cell = sheet.getCell(`O${tableCells}`);
    o6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    o6Cell.value = data.list[i].receiverMFO;
    o6Cell.border = fillBorders();

    const p6Cell = sheet.getCell(`P${tableCells}`);
    p6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    p6Cell.value = data.list[i].receiverBankName;
    p6Cell.border = fillBorders();

    const q6Cell = sheet.getCell(`Q${tableCells}`);
    q6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    q6Cell.value = data.list[i].receiverAccount;
    q6Cell.border = fillBorders();
  }
  sheet.mergeCells(`A${tableCells + 1}:D${tableCells + 1}`);
  sheet.mergeCells(`A${tableCells + 2}:C${tableCells + 2}`);
  const a7Cell = sheet.getCell(`A${tableCells + 1}`);
  a7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a7Cell.value = translate("STATEMENT_EXPORT_EXCEL_TOTAL_PERIOD_LABEL");
  a7Cell.border = fillBorders();

  const b7Cell = sheet.getCell(`B${tableCells + 1}`);
  b7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  b7Cell.border = fillBorders();

  const c7Cell = sheet.getCell(`C${tableCells + 1}`);
  c7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c7Cell.border = fillBorders();

  const d7Cell = sheet.getCell(`D${tableCells + 1}`);
  d7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  d7Cell.border = fillBorders();

  const e7Cell = sheet.getCell(`E${tableCells + 1}`);
  e7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e7Cell.border = fillBorders();

  const f7Cell = sheet.getCell(`F${tableCells + 1}`);
  f7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  f7Cell.border = fillBorders();

  const g7Cell = sheet.getCell(`G${tableCells + 1}`);
  g7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  g7Cell.border = fillBorders();

  const h7Cell = sheet.getCell(`H${tableCells + 1}`);
  h7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  h7Cell.border = fillBorders();

  const i7Cell = sheet.getCell(`I${tableCells + 1}`);
  i7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  i7Cell.border = fillBorders();

  const j7Cell = sheet.getCell(`J${tableCells + 1}`);
  j7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  j7Cell.border = fillBorders();

  const k7Cell = sheet.getCell(`K${tableCells + 1}`);
  k7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  k7Cell.value = data.total;
  k7Cell.border = fillBorders();

  const l7Cell = sheet.getCell(`L${tableCells + 1}`);
  l7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  l7Cell.border = fillBorders();

  const m7Cell = sheet.getCell(`M${tableCells + 1}`);
  m7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  m7Cell.border = fillBorders();

  const n7Cell = sheet.getCell(`N${tableCells + 1}`);
  n7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  n7Cell.border = fillBorders();

  const o7Cell = sheet.getCell(`O${tableCells + 1}`);
  o7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  o7Cell.border = fillBorders();

  const p7Cell = sheet.getCell(`P${tableCells + 1}`);
  p7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  p7Cell.border = fillBorders();

  const q7Cell = sheet.getCell(`Q${tableCells + 1}`);
  q7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  q7Cell.border = fillBorders();

  const a8Cell = sheet.getCell(`A${tableCells + 2}`);
  a8Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a8Cell.value = translate("STATEMENT_EXPORT_EXCEL_BALANCE_END_PERIOD_LABEL");

  const d8Cell = sheet.getCell(`D${tableCells + 2}`);
  d8Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  d8Cell.value = data.endBalance;

  const e8Cell = sheet.getCell(`E${tableCells + 2}`);
  e8Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e8Cell.value = translate("STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL");
}

export function createStatementExcelAgromirCreditFromTable(
  data: any,
  options: CreateStatementExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatementExcelAgromirCreditSheet(workbook, data, options);

  return workbook;
}

function createStatementExcelAgromirCreditSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate }: CreateStatementExcel,
) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });
  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 12 },
    { key: "column3", width: 10 },
    { key: "column4", width: 25 },
    { key: "column5", width: 12 },
    { key: "column6", width: 15 },
    { key: "column7", width: 20 },
    { key: "column8", width: 15 },
    { key: "column10", width: 25 },
    { key: "column11", width: 10 },
    { key: "column12", width: 10 },
    { key: "column11", width: 25 },
    { key: "column12", width: 12 },
    { key: "column11", width: 10 },
    { key: "column12", width: 20 },
    { key: "column12", width: 20 },
  ] as any;

  // Объединение ячеек
  sheet.mergeCells("A2:D2");
  sheet.mergeCells("E3:L3");
  sheet.mergeCells("A4:C4");

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.alignment = { vertical: "middle" };
  a2Cell.value = `${data.accountNumber} - ${data.accountName}`;

  const e3Cell = sheet.getCell(`E3`);
  e3Cell.style = { font: { size: 13, bold: true }, alignment: { vertical: "middle" } };
  e3Cell.value = `${translate("STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_CREDIT_LABEL")} ${
    data.accountNumber
  } ${translate("STATEMENT_EXPORT_EXCEL_FROM_LABEL")} ${data.fromDate} ${translate(
    "STATEMENT_EXPORT_EXCEL_TO_LABEL",
  )} ${data.toDate}`;

  const c2Cell = sheet.getCell(`A4`);
  c2Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c2Cell.value = translate("STATEMENT_EXPORT_EXCEL_BALANCE_BEGINNING_PERIOD_LABEL");

  const d4Cell = sheet.getCell(`D4`);
  d4Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  d4Cell.value = data.openingBalance;

  const e4Cell = sheet.getCell(`E4`);
  e4Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e4Cell.value = translate("STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL");

  const f4Cell = sheet.getCell(`F4`);
  f4Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  f4Cell.value = data.endBalance;

  //Создание таблицы
  const a5Cell = sheet.getCell(`A5`);
  a5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a5Cell.value = "№";
  a5Cell.border = fillBorders();

  const b5Cell = sheet.getCell(`B5`);
  b5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  b5Cell.value = translate("STATEMENT_EXPORT_EXCEL_DOC_NUMBER_LABEL");
  b5Cell.border = fillBorders();

  const c5Cell = sheet.getCell(`C5`);
  c5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c5Cell.value = translate("STATEMENT_EXPORT_EXCEL_BO_LABEL");
  c5Cell.border = fillBorders();

  const d5Cell = sheet.getCell(`D5`);
  d5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  d5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PAYERS_NAME_LABEL");
  d5Cell.border = fillBorders();

  const e5Cell = sheet.getCell(`E5`);
  e5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e5Cell.value = translate("STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL");
  e5Cell.border = fillBorders();

  const f5Cell = sheet.getCell(`F5`);
  f5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  f5Cell.value = translate("STATEMENT_EXPORT_EXCEL_MFO_LABEL");
  f5Cell.border = fillBorders();

  const g5Cell = sheet.getCell(`G5`);
  g5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  g5Cell.value = translate("STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL");
  g5Cell.border = fillBorders();

  const h5Cell = sheet.getCell(`H5`);
  h5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  h5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PAYMENT_DATE_LABEL");
  h5Cell.border = fillBorders();

  const i5Cell = sheet.getCell(`I5`);
  i5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  i5Cell.value = translate("STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL");
  i5Cell.border = fillBorders();

  const j5Cell = sheet.getCell(`J5`);
  j5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  j5Cell.value = translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE");
  j5Cell.border = fillBorders();

  const k5Cell = sheet.getCell(`K5`);
  k5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  k5Cell.value = translate("CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE");
  k5Cell.border = fillBorders();

  const l5Cell = sheet.getCell(`L5`);
  l5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  l5Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL");
  l5Cell.border = fillBorders();

  const m5Cell = sheet.getCell(`M5`);
  m5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  m5Cell.value = translate("STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL");
  m5Cell.border = fillBorders();

  const n5Cell = sheet.getCell(`N5`);
  n5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  n5Cell.value = translate("STATEMENT_EXPORT_EXCEL_MFO_LABEL");
  n5Cell.border = fillBorders();

  const o5Cell = sheet.getCell(`O5`);
  o5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  o5Cell.value = translate("STATEMENT_EXPORT_EXCEL_RECEIVER_BANK_LABEL");
  o5Cell.border = fillBorders();

  const p5Cell = sheet.getCell(`P5`);
  p5Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  p5Cell.value = translate("STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL");
  p5Cell.border = fillBorders();

  let tableCells = 6;
  for (let i = 0; i < data.list.length; i++) {
    tableCells = 6 + i;

    const a6Cell = sheet.getCell(`A${tableCells}`);
    a6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", horizontal: "center" } };
    a6Cell.value = i + 1;
    a6Cell.border = fillBorders();

    const b6Cell = sheet.getCell(`B${tableCells}`);
    b6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    b6Cell.value = data.list[i].accountNumber;
    b6Cell.border = fillBorders();

    const c6Cell = sheet.getCell(`C${tableCells}`);
    c6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    c6Cell.value = data.list[i].amount;
    c6Cell.border = fillBorders();

    const d6Cell = sheet.getCell(`D${tableCells}`);
    d6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    d6Cell.value = data.list[i].senderBankName;
    d6Cell.border = fillBorders();

    const e6Cell = sheet.getCell(`E${tableCells}`);
    e6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    e6Cell.value = data.list[i].senderTaxNumber;
    e6Cell.border = fillBorders();

    const f6Cell = sheet.getCell(`F${tableCells}`);
    f6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    f6Cell.value = data.list[i].senderMFO;
    f6Cell.border = fillBorders();

    const g11Cell = sheet.getCell(`G${tableCells}`);
    g11Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    g11Cell.value = data.list[i].senderAccount;
    g11Cell.border = fillBorders();

    const h6Cell = sheet.getCell(`H${tableCells}`);
    h6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    h6Cell.value = data.list[i].transactionDate;
    h6Cell.border = fillBorders();

    const i6Cell = sheet.getCell(`I${tableCells}`);
    i6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    i6Cell.value = data.list[i].fromDate;
    i6Cell.border = fillBorders();

    const j6Cell = sheet.getCell(`J${tableCells}`);
    j6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    j6Cell.value = data.list[i].toDate;
    j6Cell.border = fillBorders();

    const k6Cell = sheet.getCell(`K${tableCells}`);
    k6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    k6Cell.value = data.list[i].clientName;
    k6Cell.border = fillBorders();

    const l6Cell = sheet.getCell(`L${tableCells}`);
    l6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    l6Cell.value = data.list[i].receiverName;
    l6Cell.border = fillBorders();

    const m6Cell = sheet.getCell(`M${tableCells}`);
    m6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    m6Cell.value = data.list[i].receiverTaxNumber;
    m6Cell.border = fillBorders();

    const n6Cell = sheet.getCell(`N${tableCells}`);
    n6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    n6Cell.value = data.list[i].receiverMFO;
    n6Cell.border = fillBorders();

    const o6Cell = sheet.getCell(`O${tableCells}`);
    o6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle", wrapText: true } };
    o6Cell.value = data.list[i].receiverBankName;
    o6Cell.border = fillBorders();

    const p6Cell = sheet.getCell(`P${tableCells}`);
    p6Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    p6Cell.value = data.list[i].receiverAccount;
    p6Cell.border = fillBorders();
  }

  sheet.mergeCells(`A${tableCells + 1}:D${tableCells + 1}`);
  sheet.mergeCells(`A${tableCells + 2}:C${tableCells + 2}`);
  const a7Cell = sheet.getCell(`A${tableCells + 1}`);
  a7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a7Cell.value = translate("STATEMENT_EXPORT_EXCEL_TOTAL_PERIOD_LABEL");
  a7Cell.border = fillBorders();

  const b7Cell = sheet.getCell(`B${tableCells + 1}`);
  b7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  b7Cell.border = fillBorders();

  const c7Cell = sheet.getCell(`C${tableCells + 1}`);
  c7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  c7Cell.border = fillBorders();

  const d7Cell = sheet.getCell(`D${tableCells + 1}`);
  d7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  d7Cell.border = fillBorders();

  const e7Cell = sheet.getCell(`E${tableCells + 1}`);
  e7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e7Cell.border = fillBorders();

  const f7Cell = sheet.getCell(`F${tableCells + 1}`);
  f7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  f7Cell.border = fillBorders();

  const g7Cell = sheet.getCell(`G${tableCells + 1}`);
  g7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  g7Cell.border = fillBorders();

  const h7Cell = sheet.getCell(`H${tableCells + 1}`);
  h7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  h7Cell.border = fillBorders();

  const i7Cell = sheet.getCell(`I${tableCells + 1}`);
  i7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  i7Cell.border = fillBorders();

  const j7Cell = sheet.getCell(`J${tableCells + 1}`);
  j7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  j7Cell.border = fillBorders();

  const k7Cell = sheet.getCell(`K${tableCells + 1}`);
  k7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  k7Cell.value = data.total;
  k7Cell.border = fillBorders();

  const l7Cell = sheet.getCell(`L${tableCells + 1}`);
  l7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  l7Cell.border = fillBorders();

  const m7Cell = sheet.getCell(`M${tableCells + 1}`);
  m7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  m7Cell.border = fillBorders();

  const n7Cell = sheet.getCell(`N${tableCells + 1}`);
  n7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  n7Cell.border = fillBorders();

  const o7Cell = sheet.getCell(`O${tableCells + 1}`);
  o7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  o7Cell.border = fillBorders();

  const p7Cell = sheet.getCell(`P${tableCells + 1}`);
  p7Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  p7Cell.border = fillBorders();

  const a8Cell = sheet.getCell(`A${tableCells + 2}`);
  a8Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  a8Cell.value = translate("STATEMENT_EXPORT_EXCEL_BALANCE_END_PERIOD_LABEL");

  const d8Cell = sheet.getCell(`D${tableCells + 2}`);
  d8Cell.style = {
    font: { size: 9, bold: true },
    alignment: { vertical: "middle", horizontal: "right" },
  };
  d8Cell.value = data.endBalance;

  const e8Cell = sheet.getCell(`E${tableCells + 2}`);
  e8Cell.style = { font: { size: 9, bold: true }, alignment: { vertical: "middle" } };
  e8Cell.value = translate("STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL");
}

function createStatement5ExcelSheet(workbook: ExcelJS.Workbook, data: any) {
  const name = `${data.beginBalance}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 20 },
    { key: "column2", width: 25 },
    { key: "column3", width: 35 },
    { key: "column4", width: 15 },
    { key: "column5", width: 15 },
    { key: "column6", width: 15 },
    { key: "column7", width: 25 },
    { key: "column8", width: 25 },
    { key: "column9", width: 40 },
    { key: "column10", width: 10 },
    { key: "column11", width: 15 },
  ] as any;

  //Table column cells
  const a1Cell = sheet.getCell(`A1`);
  a1Cell.value = `${data.list[0].senderMfo ? data.list[0].senderMfo : data.list[0].senderMFO} ${
    data.list[0].senderBankName
  }`;
  a1Cell.alignment = { vertical: "middle", horizontal: "center" };
  a1Cell.style = { font: { bold: true } };

  const a2Cell = sheet.getCell(`A2`);
  a2Cell.value = `Справка о работе счёта за ${data.fromDate} - ${data.toDate}`;
  a2Cell.alignment = { vertical: "middle", horizontal: "center" };
  a2Cell.style = { font: { bold: true } };

  const a3Cell = sheet.getCell(`A3`);
  a3Cell.value = `Счёт: ${data.accountNumber} ${data.clientInfo}`;
  a3Cell.alignment = { vertical: "middle", horizontal: "center" };
  a3Cell.style = { font: { bold: true } };

  const a4Cell = sheet.getCell(`A4`);
  a4Cell.value = ` Остаток на начало периода         ${floatFormatComma(
    data.beginBalance,
  )}                                      Остаток на конец  периода         ${floatFormatComma(
    data.endBalance,
  )}`;
  a4Cell.alignment = { vertical: "middle", horizontal: "center" };
  a4Cell.style = { font: { bold: true } };

  const a6Cell = sheet.getCell(`A6`);
  a6Cell.value = "Дата документа";
  a6Cell.alignment = { vertical: "middle", horizontal: "center" };
  a6Cell.style = { border: fillBorders(), font: { bold: true } };

  const b6Cell = sheet.getCell(`B6`);
  b6Cell.value = "Счёт";
  b6Cell.alignment = { vertical: "middle", horizontal: "center" };
  b6Cell.style = { border: fillBorders(), font: { bold: true } };

  const c6Cell = sheet.getCell(`C6`);
  c6Cell.value = "Наименование";
  c6Cell.alignment = { vertical: "middle", horizontal: "center" };
  c6Cell.style = { border: fillBorders(), font: { bold: true } };

  const d6Cell = sheet.getCell(`D6`);
  d6Cell.value = "Номер документа";
  d6Cell.alignment = { vertical: "middle", horizontal: "center" };
  d6Cell.style = { border: fillBorders(), font: { bold: true } };

  const e6Cell = sheet.getCell(`E6`);
  e6Cell.value = "Тип документа";
  e6Cell.alignment = { vertical: "middle", horizontal: "center" };
  e6Cell.style = { border: fillBorders(), font: { bold: true } };

  const f6Cell = sheet.getCell(`F6`);
  f6Cell.value = "Филиал";
  f6Cell.alignment = { vertical: "middle", horizontal: "center" };
  f6Cell.style = { border: fillBorders(), font: { bold: true } };

  const g1Cell = sheet.getCell(`G6`);
  g1Cell.value = "Оборот Дебет";
  g1Cell.alignment = { vertical: "middle", horizontal: "center" };
  g1Cell.style = { border: fillBorders(), font: { bold: true } };

  const h6Cell = sheet.getCell(`H6`);
  h6Cell.value = "Оборот кредит";
  h6Cell.alignment = { vertical: "middle", horizontal: "center" };
  h6Cell.style = { border: fillBorders(), font: { bold: true } };

  const i6Cell = sheet.getCell(`I6`);
  i6Cell.value = "Назначение платежа";
  i6Cell.alignment = { vertical: "middle", horizontal: "center" };
  i6Cell.style = { border: fillBorders(), font: { bold: true } };

  const j1Cell = sheet.getCell(`J6`);
  j1Cell.value = "Кассовый символ";
  j1Cell.alignment = { vertical: "middle", horizontal: "center" };
  j1Cell.style = { border: fillBorders(), font: { bold: true } };

  const k1Cell = sheet.getCell(`K6`);
  k1Cell.value = "ИНН";
  k1Cell.alignment = { vertical: "middle", horizontal: "center" };
  k1Cell.style = { border: fillBorders(), font: { bold: true } };
  const newData = data.list.sort(sortDataFromDate);
  let tableCells = 7;
  for (let i = 0; i < newData.length; i++) {
    tableCells = 7 + i;

    //Table A column cells
    const aCells_1 = sheet.getCell(`A${tableCells}`);
    aCells_1.value = newData[i].transactionDate;
    aCells_1.alignment = { vertical: "middle" };
    aCells_1.border = fillBorders();

    //Table B column cells
    const bCells_1 = sheet.getCell(`B${tableCells}`);
    bCells_1.value = newData[i].receiverAccount;
    bCells_1.alignment = { vertical: "middle" };
    bCells_1.border = fillBorders();

    //Table C column cells
    const cCells_1 = sheet.getCell(`C${tableCells}`);
    cCells_1.value = newData[i].receiverName;
    cCells_1.alignment = { vertical: "middle" };
    cCells_1.border = fillBorders();

    //Table D column cells
    const dCells_1 = sheet.getCell(`D${tableCells}`);
    dCells_1.value = newData[i].documentNumber;
    dCells_1.alignment = { vertical: "middle" };
    dCells_1.border = fillBorders();

    //Table E column cells
    const eCells_1 = sheet.getCell(`E${tableCells}`);
    eCells_1.value = newData[i].documentTypeNumber;
    eCells_1.alignment = { vertical: "middle" };
    eCells_1.border = fillBorders();

    //Table F column cells
    const fCells_1 = sheet.getCell(`F${tableCells}`);
    fCells_1.value = newData[i].receiverMfo ? newData[i].receiverMfo : newData[i].receiverMFO;
    fCells_1.alignment = { vertical: "middle" };
    fCells_1.border = fillBorders();

    //Table G column cells
    const gCells_1 = sheet.getCell(`G${tableCells}`);
    gCells_1.value = toFinite(newData[i].debitAmount);
    gCells_1.alignment = { vertical: "middle" };
    gCells_1.border = fillBorders();

    //Table H column cells
    const hCells_1 = sheet.getCell(`H${tableCells}`);
    hCells_1.value = toFinite(newData[i].creditAmount);
    hCells_1.alignment = { vertical: "middle" };
    hCells_1.border = fillBorders();

    //Table I column cells
    const iCells_1 = sheet.getCell(`I${tableCells}`);
    iCells_1.value = newData[i].paymentDetail;
    iCells_1.alignment = { vertical: "middle" };
    iCells_1.border = fillBorders();

    //Table J column cells
    const jCells_1 = sheet.getCell(`J${tableCells}`);
    jCells_1.value = "";
    jCells_1.alignment = { vertical: "middle" };
    jCells_1.border = fillBorders();

    //Table K column cells
    const kCells_1 = sheet.getCell(`K${tableCells}`);
    kCells_1.value = newData[i].receiverTaxNumber;
    kCells_1.alignment = { vertical: "middle" };
    kCells_1.border = fillBorders();
  }

  const footerACell = sheet.getCell(`A${tableCells + 1}`);
  footerACell.value = "Итого оборот за период:";
  footerACell.alignment = { vertical: "middle", horizontal: "center" };
  footerACell.style = { border: fillBorders(), font: { bold: true } };

  const footerGCell = sheet.getCell(`G${tableCells + 1}`);
  footerGCell.value = data.debitAmount;
  footerGCell.alignment = { vertical: "middle", horizontal: "center" };
  footerGCell.style = { border: fillBorders(), font: { bold: true } };

  const footerHCell = sheet.getCell(`H${tableCells + 1}`);
  footerHCell.value = data.creditAmount;
  footerHCell.alignment = { vertical: "middle", horizontal: "center" };
  footerHCell.style = { border: fillBorders(), font: { bold: true } };
}

export function createStatement5ExcelFromTable(data: any): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createStatement5ExcelSheet(workbook, data);

  return workbook;
}
