export interface ReferenceBookProps {
  readonly accountCode: string;
  readonly accountName: string;
  readonly accountState: string;
  readonly accountBranch: string;
  readonly balance: string;
  readonly bankName: string;
  readonly accountTaxId: string;
  readonly taxNumber: string;
}

export interface ReferenceBookMfoQueryProps {
  readonly searchType: BudgetAccountSearchType;
}

export interface ReferenceBookMfoProps {
  readonly bankName: string;
  readonly mfoNumber: string;
}

export interface ReferenceBookBudgetAccountQueryProps {
  readonly searchType: string;
  readonly budgetIncomeAccount?: string;
}

export interface ReferenceBookBudgetAccountProps {
  readonly taxNumber: string;
  readonly budgetAccount: string;
  readonly budgetAccountName: string;
}

export interface ReferenceBookBudgetIncomeQueryProps {
  readonly searchType: string;
  readonly budgetIncomeAccount?: string;
}

export interface ReferenceBookBudgetIncomeProps {
  readonly budgetAccountName: string;
  readonly budgetIncomeAccount: string;
}

export interface ReferenceBookStaticDataProps {
  readonly creditAccount: string;
  readonly beneficiaryName: string;
  readonly beneficiaryTaxID: string;
  readonly beneficiaryBankCode: string;
  readonly beneficiaryBankName: string;
}

export interface PaymentCodeProps {
  readonly code: string;
  readonly name: string;
}

export interface ConversionFavoriteProps {
  readonly bankCommissionForConversion: string;
  readonly buyAmount: string;
  readonly conversionAccountInFcy: string;
  readonly id: string;
  readonly login: string;
  readonly maximumAmountInUzs: string;
  readonly maximumRate: string;
  readonly numberAndDateOfTheContract: string;
  readonly otherPurpose: string;
  readonly purpose: string;
  readonly reserveAccountInUzs: string;
  readonly reservedAmountInUzs: string;
  readonly idn?: string;
  readonly conversionDocumentId: string;
}

export enum PaymentCodeBudgetFlag {
  StandartDoc = "0",
  BudgetDoc = "1",
  BudgetRevenue = "2",
  Munis = "3",
}
export interface PaymentCodeQuery {
  readonly budgetFlag: PaymentCodeBudgetFlag;
}

export enum PaymentType {
  PaymentOrder = "01",
  Munis = "20",
  Anor = "21",
}

export enum BudgetAccountSearchType {
  OneRow = "1",
  LastRows = "2",
}
