import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TradeFinanceDiscrepancy } from "../../api/trade-finance/TradeFinanceDTO";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly loading: boolean;
  readonly data: TradeFinanceDiscrepancy[];
  readonly setDocumentsId?: (value) => void;
}

export function TradeFinanceDiscrepancyMenu({ data, loading, setDocumentsId }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<TradeFinanceDiscrepancy>[] = [
      {
        width: 200,
        accessor: "lcNumber",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_LC_NO_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "currencyFromBank",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "amountFromBank",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_BANK_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "amountFromClient",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_CLIENT_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "documentDate",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_APPLICANT_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "documentStatus",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 200,
        accessor: "transactionDate",
        Header: translate("TRADE_FINANCE_CONTRACTS_TABLE_DATE_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [translate]);

  return (
    <Table
      onSelect={setDocumentsId}
      withCheckbox={true}
      data={data}
      columns={columns}
      loading={loading}
    />
  );
}
