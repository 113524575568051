import React from "react";
import { TabPage } from "../tabs/TabPage";
import { Modal } from "../ui/Modal";
import { Form, Formik } from "formik";
import { InputField } from "../form/InputField";
import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly open: boolean;
  readonly setOpen: (value) => void;
  readonly onSubmit: (value) => void;
  readonly loading?: boolean;
}

export function IndividualSMSModal({ open, setOpen, onSubmit, loading }: Props) {
  const { translate } = useI18n();
  return (
    <Modal
      onClose={() => setOpen(false)}
      width={600}
      show={open}
      title={"INDIVIDUAL_SMS_CODE_AUTH_TITLE"}
    >
      <TabPage>
        <Formik initialValues={{ smsCode: "" }} onSubmit={onSubmit}>
          {({ handleSubmit, values }) => (
            <Form className="row w-100 p-7" onSubmit={handleSubmit}>
              <div className="col-12">
                <InputField name="smsCode" label={"INDIVIDUAL_SMS_CODE_TITLE"} />
              </div>
              <div>
                <Button
                  className="mt-4 ml-4"
                  size={SizeType.Medium}
                  color={ButtonColor.Orange}
                  type="submit"
                  disabled={values.smsCode.length === 0 && !loading}
                  loading={loading}
                >
                  {translate("ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </TabPage>
    </Modal>
  );
}
