import {
  CurrencyCode,
  DictionaryProps,
  Language,
} from "../components/number-to-words/NumberToWords";
import { AppLanguage } from "../i18n/I18nContext";
import { Dict } from "../api/dto/AppDTO";
import { AppTheme } from "../theme/ThemeContext";

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const IS_DEV = process.env.NODE_ENV !== "production";

export const API_HOST = process.env.REACT_APP_API_URL as string;

export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE as AppLanguage;

export const REACT_APP_DEFAULT_THEME = process.env.REACT_APP_DEFAULT_THEME as AppTheme;

export const NUMBER_TO_WORDS_DICTIONARY: Dict<Dict<DictionaryProps>> = {
  [CurrencyCode.RussianRuble]: {
    [Language.Russian]: {
      cent: ["копейка", "копейки", "копеек"],
      currency: ["рубль", "рубля", "рублей"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "kopeck",
      currency: "ruble",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "kopeyka",
      currency: "rubl",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },
  [CurrencyCode.UzbekistanSum]: {
    [Language.Russian]: {
      cent: ["тийин", "тийина", "тийинов"],
      currency: ["сум", "сума", "сумов"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "tiyin",
      currency: "soum",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "tiyin",
      currency: "so’m",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },
  [CurrencyCode.UnitedStatesDollar]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["доллар США", "доллара США", "долларов США"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "US dollar",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "AQSH dollar",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yu’qqiz yuz ",
        ],
      ],
    },
  },
  [CurrencyCode.EURO]: {
    [Language.Russian]: {
      cent: ["евроцент", "евроцента", "евроцентов"],
      currency: ["евро", "евро", "евро"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "eurocent",
      currency: "Euro",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "yevrosent",
      currency: "yevro",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.GreatBritainPound]: {
    [Language.Russian]: {
      cent: ["пенс", "пенса", "пенсов"],
      currency: ["фунт", "фунта", "фунтов"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "pence",
      currency: "pound sterling",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "pens",
      currency: "funt sterling",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.KazakhTenge]: {
    [Language.Russian]: {
      cent: ["тиын", "тиына", "тиынов"],
      currency: ["тенге", "тенге", "тенге"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "tiyn",
      currency: "tenge",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "tiyin",
      currency: "tenge",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ChineseYuan]: {
    [Language.Russian]: {
      cent: ["цзяо", "цзяо", "цзяо"],
      currency: ["юань", "тюаня", "юаней"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "jiao",
      currency: "yuan",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "jiao",
      currency: "yuan",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.KoreanWon]: {
    [Language.Russian]: {
      cent: ["чонам", "чонама", "чонамов"],
      currency: ["вон", "вона", "вонов"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "chonam",
      currency: "Korean won",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "chonam",
      currency: "won",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.JapaneseYen]: {
    [Language.Russian]: {
      cent: ["сена", "сены", "сен"],
      currency: ["Японская йена", " Японская йена", " Японская йена"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "sena",
      currency: "Japanese yen",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sena",
      currency: "Yaponiya yeni",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.AUD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["AUD", "AUD", "AUD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "AUD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "AUD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.AZN]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["AZN", "AZN", "AZN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "AZN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "AZN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ALL]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ALL", "ALL", "ALL"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ALL",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ALL",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.DZD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["DZD", "DZD", "DZD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "DZD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "DZD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ARP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ARP", "ARP", "ARP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ARP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ARP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.AMD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["AMD", "AMD", "AMD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "AMD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "AMD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.BYN]: {
    [Language.Russian]: {
      cent: ["копейка", "копейки", "копеек"],
      currency: ["BYN", "BYN", "BYN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "kopeck",
      currency: "BYN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "kopeyka",
      currency: "BYN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.BGN]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["BGN", "BGN", "BGN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "BGN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "BGN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.BOB]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["BOB", "BOB", "BOB"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "BOB",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "BOB",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.BRL]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["BRL", "BRL", "BRL"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "BRL",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "BRL",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.HUF]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["HUF", "HUF", "HUF"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "HUF",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "HUF",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.VND]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["VND", "VND", "VND"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "HUF",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "HUF",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.GTM]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["GTM", "GTM", "GTM"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "GTM",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "GTM",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.HKD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["HKD", "HKD", "HKD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "HKD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "HKD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.GEL]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["GEL", "GEL", "GEL"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "GEL",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "GEL",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.DKK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["DKK", "DKK", "DKK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "DKK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "DKK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.AED]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["AED", "AED", "AED"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "AED",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "AED",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.DOP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["DOP", "DOP", "DOP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "DOP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "DOP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.EGP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["EGP", "EGP", "EGP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "EGP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "EGP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.INR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["INR", "INR", "INR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "INR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "INR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.IND]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["IND", "IND", "IND"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "IND",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "IND",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.JOD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["JOD", "JOD", "JOD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "JOD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "JOD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.IRR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["IRR", "IRR", "IRR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "IRR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "IRR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ISK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ISK", "ISK", "ISK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ISK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ISK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.CAD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["CAD", "CAD", "CAD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "CAD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "CAD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.KES]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["KES", "KES", "KES"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "KES",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "KES",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.KGS]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["KGS", "KGS", "KGS"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "KGS",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "KGS",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.COP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["COP", "COP", "COP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "COP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "COP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.CUP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["CUP", "CUP", "CUP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "CUP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "CUP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.KWD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["KWD", "KWD", "KWD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "KWD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "KWD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MGA]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MGA", "MGA", "MGA"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MGA",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MGA",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MYR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MYR", "MYR", "MYR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MYR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MYR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MAD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MAD", "MAD", "MAD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MAD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MAD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MXP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MXP", "MXP", "MXP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MXP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MXP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MDL]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MDL", "MDL", "MDL"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MDL",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MDL",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MNT]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MNT", "MNT", "MNT"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MNT",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MNT",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.MMK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["MMK", "MMK", "MMK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "MMK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "MMK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.NPR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["NPR", "NPR", "NPR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "NPR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "NPR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.NGN]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["NGN", "NGN", "NGN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "NGN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "NGN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.NZD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["NZD", "NZD", "NZD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "NZD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "NZD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ILS]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ILS", "ILS", "ILS"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ILS",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ILS",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.TWD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["TWD", "TWD", "TWD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "TWD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "TWD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.NOK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["NOK", "NOK", "NOK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "NOK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "NOK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.OMR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["OMR", "OMR", "OMR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "OMR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "OMR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PKR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PKR", "PKR", "PKR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PKR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PKR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PAB]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PAB", "PAB", "PAB"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PAB",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PAB",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PYG]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PYG", "PYG", "PYG"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PYG",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PYG",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PEN]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PEN", "PEN", "PEN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PEN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PEN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PLN]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PLN", "PLN", "PLN"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PLN",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PLN",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.RON]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["RON", "RON", "RON"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "RON",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "RON",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.SAR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["SAR", "SAR", "SAR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "SAR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "SAR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.RSD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["RSD", " RSD", " RSD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: " RSD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "RSD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.SGD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["SGD", " SGD", " SGD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "SGD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "SGD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.THB]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["THB", " THB", " THB"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "THB",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "THB",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.TZS]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["TZS", " TZS", " TZS"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "TZS",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "TZS",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.TND]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["TND", " TND", " TND"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "TND",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "TND",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.TRY]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["TRY", " TRY", " TRY"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "TRY",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "TRY",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.UAH]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["UAH", " UAH", " UAH"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "UAH",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "UAH",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.UYU]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["UYU", " UYU", " UYU"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "UYU",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "UYU",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.PHP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["PHP", " PHP", " PHP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "PHP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "PHP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.HRK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["HRK", " HRK", " HRK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "HRK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "HRK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.CLP]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["CLP", " CLP", " CLP"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "CLP",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "CLP",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.SEK]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["SEK", " SEK", " SEK"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "SEK",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "SEK",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.CHF]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["CHF", " CHF", " CHF"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "CHF",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "CHF",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ETB]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ETB", " ETB", " ETB"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ETB",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ETB",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.ZAR]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["ZAR", " ZAR", " ZAR"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "ZAR",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "ZAR",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.JMD]: {
    [Language.Russian]: {
      cent: ["цент", "цента", "центов"],
      currency: ["JMD", " JMD", " JMD"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "cent",
      currency: "JMD",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "sent",
      currency: "JMD",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },

  [CurrencyCode.NAN]: {
    [Language.Russian]: {
      cent: ["-", "-", "-"],
      currency: ["-", "-", "-"],
      thousand: ["тысяча", "тысячи", "тысяч"],
      million: ["миллион", "миллиона", "миллионов"],
      billion: ["миллиард", "миллиарда", "миллиардов"],
      trillion: ["триллион", "триллиона", "триллионов"],
      declinationOfThousands: (x) => x.replace("один ", "одна ").replace("два ", "две "),
      words: [
        [
          "один",
          "два",
          "три",
          "четыре",
          "пять",
          "шесть",
          "семь",
          "восемь",
          "девять",
          "десять",
          "одиннадцать",
          "двенадцать",
          "тринадцать",
          "четырнадцать",
          "пятнадцать",
          "шестнадцать",
          "семнадцать",
          "восемнадцать",
          "девятнадцать",
        ],
        [
          "двадцать",
          "тридцать",
          "сорок",
          "пятьдесят",
          "шестьдесят",
          "семьдесят",
          "восемьдесят",
          "девяносто",
        ],
        [
          "сто",
          "двести",
          "триста",
          "четыреста",
          "пятьсот",
          "шестьсот",
          "семьсот",
          "восемьсот",
          "девятьсот",
        ],
      ],
    },
    [Language.English]: {
      cent: "-",
      currency: "-",
      thousand: "thousand",
      million: "million",
      billion: "billion",
      trillion: "trillion",

      words: [
        [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ],
        ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"],
        [
          "one hundred",
          "two hundred",
          "three hundred",
          "four hundred",
          "five hundred",
          "six hundred",
          "seven hundred",
          "eight hundred",
          "nine hundred",
        ],
      ],
    },
    [Language.Uzbek]: {
      cent: "-",
      currency: "-",
      thousand: "ming",
      million: "million",
      billion: "milliard",
      trillion: "trillion",

      words: [
        [
          "bir",
          "ikki",
          "uch",
          "to’rt",
          "besh",
          "olti",
          "yetti",
          "sakkiz",
          "to’qqiz",
          "o’n",
          "o’n bir",
          "o’n ikki",
          "o’n uch",
          "o’n to’rt",
          "o’n besh",
          "o’n olti",
          "o’n yetti",
          "o’n sakkiz",
          "o’n to’qqiz",
        ],
        ["yigirma", "o’ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to’qson"],
        [
          "bir yuz",
          "ikki yuz",
          "uch yuz",
          "to’rt yuz",
          "besh yuz",
          "olti yuz",
          "yetti yuz",
          "sakkiz yuz ",
          "to’qqiz yuz ",
        ],
      ],
    },
  },
};
