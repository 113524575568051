import "./assets/mobile-menu.scss";
import "./assets/mobile-menu-item.scss";

import { useLocation } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import AppMobileMenu from "./AppMobileMenu";
import MobileMenuItem from "./MobileMenuItem";
import { Routes } from "../constants/Routes";
import { PendingApprovalsIcon } from "../components/icons/PendingApprovalsIcon";
import { useHistory } from "react-router";
import { AccountIcon } from "../components/icons/AccountIcon";
import { TransferIcon } from "../components/icons/TransferIcon";
import { useI18n } from "../i18n/I18nContext";
import { PendingTabs } from "../containers/corporate/CorporatePendingApprovalsContainer";
import {
  AccountsTabs,
  ApprovalAccountsSubmitType,
  ApprovalPaymentClaimSubmitType,
  ApprovalTradeFinanceSubmitType,
  InquirySubmitType,
  NewAccountOpeningTabType,
  SwiftInnerTabType,
} from "../filters/AccountsFilter";
import { AccountTabs } from "../containers/corporate/CorporateAccountsContainer";
import { useLocationHelpers } from "../hooks/useLocationHelpers";
import { ConversionIcon } from "../components/icons/ConversionIcon";
import { OfficeIcon } from "../components/icons/OfficeIcon";
import { PaymentClaimIcon } from "../components/icons/PaymentClaimIcon";
import { DocIcon } from "../components/icons/DocIcon";
import { LoanIcon } from "../components/icons/LoanIcon";
import { AnalyticsIcon } from "../components/icons/AnalyticsIcon";
import { ColorPalette } from "../theme/ColorPalette";
import { NotificationIcon } from "../components/icons/NotificationIcon";
import { AgreementIcon } from "../components/icons/AgreementIcon";
import { ReferenceIcon } from "../components/icons/ReferenceIcon";
import { HelpIcon } from "../components/icons/HelpIcon";
import { ClientFeedbackIcon } from "../components/icons/ClientFeedbackIcon";
import { UserInfoIcon } from "../components/icons/UserInfoIcon";
import { ContactUsIcon } from "../components/icons/ContactUsIcon";
import { useDispatch } from "react-redux";
import { changeMobileMenu, viewModeSelector } from "../reducers/appReducer";
import { CreateNewDocumentRoles, MobileMenu, ViewMode } from "../api/dto/AppDTO";
import { TransfersTabs } from "../containers/corporate/CorporateTransfersContainer";
import { ConversionFilterTabs } from "../filters/ConversionFilter";
import { HelpAndFaqTabs } from "../filters/HelpAndFaqFilter";
import { PaymentClaimFilterTabs } from "../filters/PaymentClaimFilter";
import { SaveActiveMobileMenuTab } from "../utils/SaveActiveMobileMenuTab";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import {
  accessToAccountManagement,
  accessToCardReplenishment,
  accessToConversion,
  accessToFcyTransfer,
  accessToLcyTransfer,
} from "../reducers/authReducer";
import { userSelector } from "../reducers/userReducer";
import { AgreementsTabs, TmeplatesAndFormsTabs } from "../filters/AgreementsFilter";

export default function CorporateMobileMenu() {
  const { translate } = useI18n();
  const history = useHistory();
  const locationHelpers = useLocationHelpers();
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToAccount = useShallowEqualSelector(accessToAccountManagement);
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const accessToConversionTab = useShallowEqualSelector(accessToConversion);
  const user = useShallowEqualSelector(userSelector);
  const accessToLcyTransferTab = useShallowEqualSelector(accessToLcyTransfer);
  const accessToFcyTransferTab = useShallowEqualSelector(accessToFcyTransfer);
  const accessToCard = useShallowEqualSelector(accessToCardReplenishment);

  const isCorporateClient = useMemo(() => (user?.clientTypeId === "2" ? true : false), [
    user?.clientTypeId,
  ]);

  const activeAppTab = useMemo(() => SaveActiveMobileMenuTab("active_app", location.pathname), [
    location.pathname,
  ]);

  const activeChildTab = useMemo(
    () => SaveActiveMobileMenuTab("active_app_child", location.pathname),
    [location.pathname],
  );

  const onChangeAppTab = useCallback(
    (value, isResContent = true) => {
      history.push(`${value}`);
      if (!isResContent) {
        dispatch(changeMobileMenu({ type: MobileMenu.Closed }));
      }
    },
    [history, dispatch],
  );

  const onChangeAppChildTab = useCallback(
    (value, isResContent = true) => {
      history.push(`${activeAppTab}/${value}`);
      if (!isResContent) {
        dispatch(changeMobileMenu({ type: MobileMenu.Closed }));
      }
    },
    [activeAppTab, history, dispatch],
  );

  const onChangeChildChildTab = useCallback(
    (value, type = "") => {
      locationHelpers.replaceQuery({ [type]: value });
      dispatch(changeMobileMenu({ type: MobileMenu.Closed }));
    },
    [locationHelpers, dispatch],
  );

  return (
    <>
      <AppMobileMenu
        activeTab={activeAppTab}
        onChangeTab={(tab, isResContent) => onChangeAppTab(tab, isResContent)}
      >
        <MobileMenuItem
          key={Routes.CorporatePendingApprovals}
          show={viewMode === ViewMode.Full}
          icon={<PendingApprovalsIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={PendingTabs.TransfersLCY}>
                {translate("PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PendingTabs.TransfersFCY}>
                {translate("PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PendingTabs.Salary}>
                {translate("PENDING_APPROVALS_SALARY_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PendingTabs.CorporateCards}>
                {translate("PENDING_APPROVALS_CORPORATE_CARDS_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PendingTabs.Conversion}>
                {translate("PENDING_APPROVALS_CONVERSION_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={PendingTabs.Accounts}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "accountType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "accountType")}
                  >
                    <MobileMenuItem key={ApprovalAccountsSubmitType.NoneStandInquiry}>
                      {translate("PENDING_APPROVALS_ACCOUNT_NONE_STANDART_INQUIRIES")}
                    </MobileMenuItem>
                    <MobileMenuItem key={ApprovalAccountsSubmitType.StandInquiry}>
                      {translate("PENDING_APPROVALS_ACCOUNT_STANDART_INQUIRIES")}
                    </MobileMenuItem>
                    <MobileMenuItem key={ApprovalAccountsSubmitType.NewAccountOpening}>
                      {translate("PENDING_APPROVALS_ACCOUNT_NEW_ACCOUNT_OPENING")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("PENDING_APPROVALS_ACCOUNTS_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={PendingTabs.TradeFinance}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "tradeFinanceType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "tradeFinanceType")}
                  >
                    <MobileMenuItem key={ApprovalTradeFinanceSubmitType.Application}>
                      {translate("PENDING_APPROVALS_APPLICATION_OPENING")}
                    </MobileMenuItem>
                    <MobileMenuItem key={ApprovalTradeFinanceSubmitType.Amendment}>
                      {translate("PENDING_APPROVALS_AMENDMENT_OPENING")}
                    </MobileMenuItem>
                    <MobileMenuItem key={ApprovalTradeFinanceSubmitType.Discrepancy}>
                      {translate("PENDING_APPROVALS_DISCREPANCY_OPENING")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("PENDING_APPROVALS_TRADE_FINANCE_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={PendingTabs.PaymentClaim}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "paymentClaimType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "paymentClaimType")}
                  >
                    <MobileMenuItem key={ApprovalPaymentClaimSubmitType.ReceivedPaymentClaim}>
                      {translate("TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={ApprovalPaymentClaimSubmitType.SentPaymentClaim}>
                      {translate("PENDING_APPROVALS_SENT_PAYMENT_CLAIMS_TAB_TITLE")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("TRANSFERS_PAYMENT_CLAIM_TAB_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_PENDING_APPROVALS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateAccounts}
          icon={<AccountIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={AccountsTabs.Accounts}>
                {translate("ACCOUNTS_ACCOUNTS_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={AccountsTabs.Statement}>
                {translate("ACCOUNTS_STATEMENT_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={AccountsTabs.Inquiry}
                show={accessToAccount === CreateNewDocumentRoles.Yes}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "submitType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "submitType")}
                  >
                    <MobileMenuItem key={InquirySubmitType.NonStandart}>
                      {translate("ACCOUNT_INQUIRY_NON_STANDART_INQUIRIES_BUTTON_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={InquirySubmitType.SubmitNonStandard}>
                      {translate("ACCOUNT_INQUIRY_SUBMIT_NON_STANDARD_INQUIRY_BUTTON_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={InquirySubmitType.Standard}>
                      {translate("ACCOUNT_INQUIRY_STANDART_INQUIRIES_BUTTON_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={InquirySubmitType.SubmitStandard}>
                      {translate("ACCOUNT_INQUIRY_SUBMIT_STANDARD_INQUIRY_BUTTON_TITLE")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("ACCOUNTS_INQUIRY_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={AccountsTabs.NewAccountOpening}
                show={accessToAccount === CreateNewDocumentRoles.Yes}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "newAccType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "newAccType")}
                  >
                    <MobileMenuItem key={NewAccountOpeningTabType.NewAccOpening}>
                      {translate("ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={NewAccountOpeningTabType.RequestForOpening}>
                      {translate("ACCOUNTS_REQUEST_NEW_ACCOUNT_OPENING_TAB_TITLE")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={AccountTabs.CardBox}>
                {translate("ACCOUNT_CARD_BOX_TAB")}
              </MobileMenuItem>
              <MobileMenuItem
                key={AccountTabs.SWIFT}
                show={accessToAccount === CreateNewDocumentRoles.Yes}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "swiftInnerType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "swiftInnerType")}
                  >
                    <MobileMenuItem key={SwiftInnerTabType.Outgoing}>
                      {translate("ACCOUNTS_OUTGOING_TAB_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={SwiftInnerTabType.Incoming}>
                      {translate("ACCOUNTS_INCOMING_TAB_TITLE")}
                    </MobileMenuItem>
                    <MobileMenuItem key={SwiftInnerTabType.IncomingCover}>
                      {translate("ACCOUNTS_INCOMING_COVER_TAB_TITLE")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("ACCOUNT_SWIFT_TAB")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_ACCOUNTS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateTransfersRoute}
          icon={<TransferIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem
                key={TransfersTabs.TransfersLCY}
                show={accessToLcyTransferTab === CreateNewDocumentRoles.Yes}
              >
                {translate("TRANSFERS_TRANSFERS_TAB_LCY_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={TransfersTabs.TransfersFCY}
                show={accessToFcyTransferTab === CreateNewDocumentRoles.Yes}
              >
                {translate("TRANSFERS_TRANSFERS_TAB_FCY_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={TransfersTabs.Salary}
                show={accessToCard === CreateNewDocumentRoles.Yes}
              >
                {translate("TRANSFERS_SALARY_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={TransfersTabs.CorporateCard}
                show={accessToCard === CreateNewDocumentRoles.Yes}
              >
                {translate("TRANSFERS_CORPORATE_CARD_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={TransfersTabs.History}>
                {translate("TRANSFERS_HISTORY_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={TransfersTabs.Favorites}>
                {translate("TRANSFERS_FAVORITES_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={TransfersTabs.Correspondents}>
                {translate("TRANSFERS_CORRESPONDENTS_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={TransfersTabs.Samples}>
                {translate("TRANSFERS_SAMPLES_TAB_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_TRANSFERS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateConversion}
          icon={<ConversionIcon color={ColorPalette.White} />}
          show={accessToConversionTab === CreateNewDocumentRoles.Yes}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={ConversionFilterTabs.FcyToUzsConversion}>
                {translate("CONVERSION_FCY_TO_UZS_CONVERSION_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={ConversionFilterTabs.UzsToFcyConversion}>
                {translate("CONVERSION_UZS_TO_FCY_CONVERSION_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={ConversionFilterTabs.FcyToFcyConversion}>
                {translate("CONVERSION_FCY_TO_FCY_CONVERSION_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={ConversionFilterTabs.Favorites}>
                {translate("CONVERSION_FAVORITES_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={ConversionFilterTabs.ExchangeRates}>
                {translate("CONVERSION_EXCHANGE_RATES_TAB_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_CONVERSION_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateTradeFinance}
          icon={<OfficeIcon color={ColorPalette.White} />}
        >
          {translate("SIDE_MENU_TRADE_FINANCE_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporatePaymentClaimRoute}
          icon={<PaymentClaimIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={PaymentClaimFilterTabs.ReceivedPaymentClaims}>
                {translate("TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PaymentClaimFilterTabs.SentPaymentClaims}>
                {translate("TRANSFERS_PAYMENT_CLAIM_SENT_SUBTAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={PaymentClaimFilterTabs.CreateNewPaymentClaim}>
                {translate("TRANSFERS_PAYMENT_CLAIM_CREATE_SUBTAB_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("TRANSFERS_PAYMENT_CLAIM_TAB_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem key={Routes.CorporateYeisvo} icon={<DocIcon color={ColorPalette.White} />}>
          {translate("SIDE_MENU_YEISVO_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem key={Routes.CorporateLoan} icon={<LoanIcon color={ColorPalette.White} />}>
          {translate("SIDE_MENU_LOAN_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateAnalytics}
          icon={<AnalyticsIcon color={ColorPalette.White} />}
        >
          {translate("SIDE_MENU_ANALYTICS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateNotifications}
          icon={<NotificationIcon color={ColorPalette.White} />}
        >
          {translate("SIDE_MENU_NOTIFICATIONS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateAgreements}
          icon={<AgreementIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={AgreementsTabs.PublicOffer}>
                {translate("AGREEMENTS_PUBLIC_OFFER_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem
                key={AgreementsTabs.TmeplatesAndForms}
                responsiveContent={
                  <AppMobileMenu
                    activeTab={SaveActiveMobileMenuTab(
                      "active_child_child",
                      location.search,
                      "templatesAndFormsType=",
                    )}
                    onChangeTab={(tab) => onChangeChildChildTab(tab, "templatesAndFormsType")}
                  >
                    <MobileMenuItem key={TmeplatesAndFormsTabs.Issuing}>
                      {translate(
                        "AGREEMENTS_TEMPLATES_AND_FORMS_DOCUMENT_FOR_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES",
                      )}
                    </MobileMenuItem>
                    <MobileMenuItem key={TmeplatesAndFormsTabs.ReturnDocuments}>
                      {translate("AGREEMENTS_TEMPLATES_AND_FORMS_RETURN_DOCUMENTS")}
                    </MobileMenuItem>
                  </AppMobileMenu>
                }
              >
                {translate("AGREEMENTS_TEMPLATES_AND_FORMS_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={AgreementsTabs.Insturction}>
                {translate("AGREEMENTS_INSTRUCTIONS_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_AGREEMENTS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateReference}
          icon={<ReferenceIcon color={ColorPalette.White} />}
          show={isCorporateClient}
        >
          {translate("SIDE_MENU_REFERENCE_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateHelpRoute}
          icon={<HelpIcon color={ColorPalette.White} />}
          responsiveContent={
            <AppMobileMenu
              activeTab={activeChildTab}
              onChangeTab={(tab, isResContent) => onChangeAppChildTab(tab, isResContent)}
            >
              <MobileMenuItem key={HelpAndFaqTabs.Faq}>
                {translate("HELP_AND_FAQ_FAQ_TAB_TITLE")}
              </MobileMenuItem>
              <MobileMenuItem key={HelpAndFaqTabs.Questions}>
                {translate("HELP_AND_FAQ_FAQ_QUESTIONS_TITLE")}
              </MobileMenuItem>
            </AppMobileMenu>
          }
        >
          {translate("SIDE_MENU_HELP_AND_FAQ_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateClientFeedback}
          icon={<ClientFeedbackIcon color={ColorPalette.White} />}
        >
          {translate("SIDE_MENU_CLIENT_FEEDBACK_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.CorporateUserInfo}
          icon={<UserInfoIcon color={ColorPalette.White} />}
        >
          {translate("SIDE_MENU_USER_INFO_AND_SETTINGS_TITLE")}
        </MobileMenuItem>

        <MobileMenuItem
          key={Routes.IndividualContactUs}
          icon={<ContactUsIcon color={ColorPalette.White} />}
        >
          <a
            href=""
            className="text-white"
            onClick={() => window.open("https://kdb.uz/ru/about-us/contact-us", "_blank")}
          >
            {translate("SIDE_MENU_CONTACT_US_TITLE")}
          </a>
        </MobileMenuItem>
      </AppMobileMenu>
    </>
  );
}
