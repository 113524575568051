import { isArray } from "lodash";
import { NotificationManager } from "react-notifications";

import { AppError } from "../helpers/AppError";

export function showError(error: AppError): void {
  const errors = error.getErrors();

  if (isArray(errors)) {
    errors.forEach((error) => {
      NotificationManager.error(error);
    });
  }
}
