import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function ClientFeedbackIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.99 19.99"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-0.002)">
        <path
          fill={color}
          transform="translate(-87.446 0)"
          d="M97.443,0a6.44,6.44,0,0,0-4.019,11.474A4.1,4.1,0,0,1,97.443,8.2a2.928,2.928,0,1,1,2.928-2.928A2.932,2.932,0,0,1,97.443,8.2a4.106,4.106,0,0,1,4.016,3.277A6.44,6.44,0,0,0,97.443,0Z"
        />
        <path
          fill={color}
          transform="translate(-174.019 -230.63)"
          d="M184.016,240a2.922,2.922,0,0,0-2.925,2.811,6.434,6.434,0,0,0,5.849,0A2.926,2.926,0,0,0,184.016,240Z"
        />
        <path
          fill={color}
          transform="translate(-164.115 -335.883)"
          d="M176.164,355.873l-2.052-1.079-2.052,1.079.394-2.3-1.669-1.627,2.306-.335,1.021-2.085,1.021,2.085,2.306.335-1.669,1.627Z"
        />
        <path
          fill={color}
          transform="translate(0 -335.766)"
          d="M5.4,355.756l-2.063-1.084-2.063,1.084.394-2.3L0,351.833l2.306-.335,1.031-2.09,1.031,2.09,2.306.335-1.669,1.627Z"
        />
        <path
          fill={color}
          transform="translate(-327.721 -335.765)"
          d="M346.438,355.755l-2.063-1.084-2.063,1.084.394-2.3-1.669-1.627,2.306-.335,1.031-2.09,1.031,2.09,2.306.335-1.669,1.627Z"
        />
        <circle cx="2" cy="2" r="2" transform="translate(8.002 2.999)" fill={color} />
      </g>
    </svg>
  );
}
