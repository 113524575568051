import "./assets/menu-wrapper.scss";
import { useTheme } from "../../theme/ThemeContext";

import React, { ReactNode, useMemo } from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

const defaultStyles = {
  width: 250,
};

const transitionStyles = {
  exited: { width: 68 },
  exiting: { width: 68 },
  entered: { width: 250 },
  entering: { width: 250 },
};

interface Props {
  readonly children: ReactNode;
  readonly state: TransitionStatus;
}

export function MenuWrapper({ children, state }: Props) {
  const { ColorPalette } = useTheme();

  const isMobile = CheckMobileUtils();

  const menuWrapperStyle = useMemo(
    () => ({
      background: `linear-gradient(to left, ${ColorPalette.Secondary} 0, ${ColorPalette.BlueThird} 150%)`,
    }),
    [ColorPalette.BlueThird, ColorPalette.Secondary],
  );

  return (
    <>
      {!isMobile ? (
        <div
          className="d-flex flex-column menu-wrapper"
          style={{ ...defaultStyles, ...transitionStyles[state], ...menuWrapperStyle }}
        >
          {children}
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
