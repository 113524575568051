import { saveAs } from "file-saver";
import ExcelJS, { Border } from "exceljs";

export const fillBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({
  top: border,
  left: border,
  right: border,
  bottom: border,
});

export const leftBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ left: border });

export const topBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ top: border });

export const rightBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ right: border });

export const bottomBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ bottom: border });

export const verticalBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ ...topBorders(border), ...bottomBorders(border) });

export const horizontalBorders = (
  border: Border = { style: "thin", color: { argb: "00000000" } },
): Partial<ExcelJS.Borders> => ({ ...leftBorders(border), ...rightBorders(border) });

export const saveExcelToFile = (workbook: ExcelJS.Workbook, fileName: string): Promise<void> =>
  workbook.xlsx.writeBuffer().then((x) => saveAs(new Blob([x]), `${fileName}.xlsx`));

export const fillBackground = (
  color: Partial<ExcelJS.Color> = { argb: "d6e9f6" },
): ExcelJS.Fill => ({
  type: "pattern",
  fgColor: color,
  bgColor: color,
  pattern: "solid",
});
