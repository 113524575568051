import { formatDateFromUrl, formatDateToFilter } from "../utils/DateUtils";
import { AppFilter, AppFilterProps } from "./AppFilter";

export enum PaymentClaimFilterTabs {
  SentPaymentClaims = "sent-payment-claims",
  CreateNewPaymentClaim = "create-new-payment-claim",
  ReceivedPaymentClaims = "received-payment-claims",
}

interface PaymentClaimFilterProps extends AppFilterProps<PaymentClaimFilterTabs> {
  readonly fromDate?: string;
  readonly toDate?: string;
  readonly state?: string;
  readonly paymentClaimType?: PaymentClaimFilterTabs;
}

export class PaymentClaimFilter extends AppFilter<PaymentClaimFilterTabs> {
  private readonly fromDate?: Date;
  private readonly toDate?: Date;
  private readonly state?: string;
  private readonly paymentClaimType: PaymentClaimFilterTabs;
  public constructor(
    { tab, fromDate, toDate, state, paymentClaimType, ...props } = {} as PaymentClaimFilterProps,
  ) {
    super({ ...props, tab: tab || PaymentClaimFilterTabs.SentPaymentClaims });
    this.fromDate = new Date();
    this.toDate = new Date();
    this.state = "A";
    this.paymentClaimType = paymentClaimType || PaymentClaimFilterTabs.ReceivedPaymentClaims;

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }

    if (state) {
      this.state = state;
    }
  }

  public getPaymentClaimFormFilter() {
    return {
      toDate: this.toDate,
      fromDate: this.fromDate,
      state: this.state,
    };
  }

  public getPaymentClaimType() {
    return this.paymentClaimType;
  }

  public getPaymentClaimSentFilter() {
    return {
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
      state: this.state,
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }
}
