import { BaseApi } from "../BaseApi";
import {
  NotificationsQuery,
  UserInfoProps,
  UserInfoQuery,
  UserRolesProps,
  UserSignatoriesProps,
} from "./UserInfoDTO";

export class UserInfoApi extends BaseApi {
  public getUserInfo(query: UserInfoQuery): Promise<UserInfoProps[]> {
    return this.get("user-info/", {
      query,
    });
  }

  public getUserSignatories({ login }): Promise<UserSignatoriesProps[]> {
    return this.get("user-info/signatories", {
      query: { login },
    });
  }

  public getUserRoles({ login }): Promise<UserRolesProps[]> {
    return this.get("user-info/role", {
      query: { login },
    });
  }

  public getUserNotificationsList(query: NotificationsQuery): Promise<any[]> {
    return this.get("notification/list/", {
      query,
    });
  }

  public getUserNotifications(query: NotificationsQuery): Promise<any[]> {
    return this.get("notification/:employeeId", {
      params: { employeeId: query.employeeId },
      query,
    });
  }

  public sentNotification({ id, employeeId }): Promise<any[]> {
    return this.put("notification/:employeeId", {
      params: { employeeId },
      query: { notificationId: id },
    });
  }
}
