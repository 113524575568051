import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { SalaryApi } from "./SalaryApi";

interface Props {
  readonly SalaryApi: SalaryApi;
}

export function useSalaryContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new SalaryApi(data), [data]);

  return {
    SalaryApi: api,
  };
}
