import ReactModal from "react-modal";
import React, { ReactNode } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { I18nCode } from "../../i18n/I18nSchema";
import CloseIcon from "../icons/CloseIcon";
import { SearchInput } from "./SearchInput";
import "./assets/modal.scss";

export interface ModalProps extends Omit<ReactModal.Props, "isOpen" | "onRequestClose"> {
  readonly show: boolean;
  readonly width?: number;
  readonly height?: number;
  readonly actions?: ReactNode;
  readonly children: ReactNode;
  readonly onClose?: () => void;
  readonly title: string | I18nCode;
  readonly searchInput?: boolean;
  readonly setSearch?: (value) => void;
}

export function Modal({
  children,
  width,
  height,
  title,
  onClose,
  show,
  actions,
  searchInput,
  setSearch,
  ...props
}: ModalProps) {
  const { translate } = useI18n();

  return (
    <ReactModal
      {...props}
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      shouldReturnFocusAfterClose={true}
      overlayClassName="d-flex position-absolute-fill  position-fixed"
      className="d-flex flex-shrink-1 flex-grow-1 bg-transparent align-items-center justify-content-center "
    >
      <div className="position-absolute w-100 h-100 bg-black-50" onClick={onClose} />
      <div
        style={{
          width: width ? `${width}px` : "auto",
          height: height ? `${height}px` : "auto",
        }}
        className={`d-flex flex-column bg-white py-6 px-7 br-1 border border-blue-gray-extra-light overflow-auto modal_body ${props.className}`}
      >
        <div className="d-flex justify-content-between">
          <h3 className="mb-5 text-center w-100">{translate(title)}</h3>
          {searchInput && (
            <div className="mr-6">
              <SearchInput
                type="search"
                placeholder="Search"
                className="bs-solid"
                onChange={(value) => setSearch && setSearch(value.target.value)}
              />
            </div>
          )}
          {onClose && (
            <div onClick={onClose} className="pb-4 pl-2">
              <CloseIcon />
            </div>
          )}
        </div>

        {children}

        <div>{actions}</div>
      </div>
    </ReactModal>
  );
}
