import { BaseApi } from "../BaseApi";
import {
  ClientsInfoProps,
  ConversionAccountProps,
  ConversionAccountQuery,
  ConversionDocumentsList,
  ConversionGetCreditAccount,
  ConversionGetDebitAccount,
  ConversionIndHistoryQuery,
  ConversionLcyToFcy,
  ConversionLcyToFcyQueryProps,
  ConversionProps,
  ExchangeRateProps,
  TransactionType,
} from "./ConversionDTO";
import { ConversionFavoriteProps } from "../reference-book/ReferenceBookDTO";

export interface GetConversionDocumentsListQuery {
  transactionType?: TransactionType;
  fromDate?: string;
  toDate?: string;
  pageNumber?: number;
  pageSize?: number;
  state?: string;
}

interface GetConversionForApprovalQuery {
  readonly pageNumber: number;
  readonly pageSize: number;
}

export class ConversionApi extends BaseApi {
  public getConversions(query: GetConversionForApprovalQuery): Promise<ConversionProps[]> {
    return this.get("conversion/documents/approvals/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getCurrentExchangeRate(): Promise<ExchangeRateProps[]> {
    return this.get("exchange/:lang", {
      params: { lang: this.language.toUpperCase() },
      query: { employeeID: this.employeeId },
    });
  }

  public getFcyToLcyConversionRate(currency: string): Promise<any> {
    return this.get("conversion/fcytolcy_conversion_rate/:employeeId", {
      params: { employeeId: this.employeeId },
      query: { currency: currency },
    });
  }

  public getCurrency(): Promise<any[]> {
    return this.get("open-information/currency");
  }

  public getLcyToFcyConversion(query: ConversionLcyToFcyQueryProps): Promise<ConversionLcyToFcy[]> {
    return this.get("conversion/LCYtoFCY/:employerId", {
      params: { employerId: this.employeeId },
      query,
    });
  }

  public getDocumentsList(
    query: GetConversionDocumentsListQuery,
  ): Promise<ConversionDocumentsList[]> {
    return this.get("conversion/documents/:employerId", {
      params: { employerId: this.employeeId },
      query,
    });
  }

  public getCreditAccount(): Promise<ConversionGetCreditAccount[]> {
    return this.get("conversion/creditLcyAccount/:employerId", {
      params: { employerId: this.employeeId },
    });
  }

  public getDebitAccount(transferType): Promise<ConversionGetDebitAccount[]> {
    return this.get("conversion/debitFcyAccount/:employerId", {
      params: { employerId: this.employeeId },
      query: { transferType },
    });
  }

  public createConversionDocument({ json }): Promise<boolean> {
    return this.post("conversion/payment/document/:employerId", {
      params: { employerId: this.employeeId },
      json,
    });
  }

  public modifyConversionDocument({ json }): Promise<boolean> {
    return this.put("conversion/payment/document/:employerId", {
      params: { employerId: this.employeeId },
      json,
    });
  }

  public getClientsInfo(): Promise<ClientsInfoProps> {
    return this.get("conversion/clientInfo/:employerId", {
      params: { employerId: this.employeeId },
    });
  }

  public postClientInfo({ json }): Promise<string> {
    return this.post("conversion/clientInfo/:employerId", {
      params: { employerId: this.employeeId },
      json,
    });
  }

  public getAccounts(query: ConversionAccountQuery): Promise<ConversionAccountProps[]> {
    return this.get("conversion/accounts/:employerId", {
      params: { employerId: this.employeeId },
      query,
    });
  }

  public submitUzsToFcy({ json }): Promise<boolean> {
    return this.post("conversion/document", {
      json: { ...json, employeeId: this.employeeId },
    });
  }

  public modifyUzsToFcy({ json }): Promise<boolean> {
    return this.put("conversion/document", {
      json: { ...json, employeeId: this.employeeId },
    });
  }

  public getConversionFavorites({ login }): Promise<ConversionFavoriteProps[]> {
    return this.get("favorite/conversion/:login", {
      params: { login },
    });
  }

  public getConversionIndHistory(query: ConversionIndHistoryQuery): Promise<any[]> {
    return this.get("conversion/uzcardtovisa_conversion/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getConversionIdnInfo(senderAccount: string): Promise<any> {
    return this.get("conversion/get_idn/:employeeId", {
      params: { employeeId: this.employeeId },
      query: { senderAccount: senderAccount },
    });
  }

  public saveToFavorite({ json }): Promise<boolean> {
    return this.post("favorite/conversion", {
      json,
    });
  }

  public deleteFavorite({ id }): Promise<boolean> {
    return this.delete("favorite/conversion/:id", { params: { id: id } });
  }

  public createConversionLCYToFCY(query): Promise<any> {
    return this.post("conversion/transfers_conversion_via_sms", {
      query,
    });
  }

  public createConversionFCYtoLCY(json, messageId, smsCode): Promise<boolean> {
    return this.post("conversion/reverse_conversion_via_sms", {
      json,
      query: { messageId, smsCode },
    });
  }

  public createConversionVisaTo(json, messageId, smsCode): Promise<any> {
    return this.post("conversion/visa-to-uzcard-via-sms", {
      json,
      query: { messageId, smsCode },
    });
  }

  public createConversionUzcardTo(json, messageId, smsCode): Promise<boolean> {
    return this.post("conversion/uzcard-to-visa-via-sms", {
      json,
      query: { messageId, smsCode },
    });
  }

  public getCardAccountInfo(cardNumber): Promise<any> {
    return this.post("card_account/card_account_info", {
      query: { receiverCardNumber: cardNumber },
    });
  }

  public getUzcardAccountInfo(cardNumber): Promise<any> {
    return this.post("upay/card_number_details", {
      json: { cardNumber },
    });
  }

  public getCardInfo(cardNumber): Promise<any> {
    return this.post("cardbox/card-info/:cardNumber", {
      params: { cardNumber },
    });
  }

  public calcCommissionUzToFcy(json): Promise<any> {
    return this.post("conversion/calc/commission", {
      body: json,
    });
  }
}
