import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function DocIcon({ size = 20, color = ColorPalette.BlueExtraLight, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 16.894 20.187"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-41.762)">
        <g transform="translate(41.762)">
          <g transform="translate(0)">
            <path
              fill={color}
              transform="translate(-41.762)"
              d="M57.646,7.764h-5.1a1.01,1.01,0,0,1-1.01-1.01V1.01A1.01,1.01,0,0,0,50.524,0H43.189a1.427,1.427,0,0,0-1.427,1.427V18.76a1.427,1.427,0,0,0,1.427,1.427h14.04a1.427,1.427,0,0,0,1.427-1.427V8.775A1.01,1.01,0,0,0,57.646,7.764Zm-12-4.605h2.545a.82.82,0,0,1,.828.767.8.8,0,0,1-.8.84H45.669A.82.82,0,0,1,44.842,4,.8.8,0,0,1,45.644,3.159Zm9.1,13.333H45.669a.82.82,0,0,1-.828-.767.8.8,0,0,1,.8-.84h9.078a.82.82,0,0,1,.828.767A.8.8,0,0,1,54.747,16.492Zm0-2.731H45.669a.82.82,0,0,1-.828-.767.8.8,0,0,1,.8-.84h9.078a.82.82,0,0,1,.828.767A.8.8,0,0,1,54.747,13.762Zm0-2.624H45.669a.82.82,0,0,1-.828-.767.8.8,0,0,1,.8-.84h9.078a.82.82,0,0,1,.828.767A.8.8,0,0,1,54.747,11.138Z"
            />
          </g>
        </g>
        <g transform="translate(53.141)">
          <g>
            <path
              fill={color}
              transform="translate(-330.356)"
              d="M335.273,5.7,331.624.6a1.427,1.427,0,0,0-1.159-.6.109.109,0,0,0-.109.109V5.644a.514.514,0,0,0,.514.514h4.169A.287.287,0,0,0,335.273,5.7Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
