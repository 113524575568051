export function convertImgToBase64URL(url: string): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();

    img.crossOrigin = "Anonymous";
    img.onload = () => {
      let canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      if (ctx) {
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);

        resolve(canvas.toDataURL());

        canvas = null as any;
      }
    };

    img.src = url;
  });
}
