import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

import { SelectPicker } from "./SelectPicker";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "./Button";
import { useI18n } from "../../i18n/I18nContext";
import { AppFilter } from "../../filters/AppFilter";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userLoginSelector } from "../../reducers/userReducer";
import { appPageCountSelector, appTotalCountSelector } from "../../reducers/paginationReducer";

interface Props<TData> {
  readonly filter: AppFilter<any>;
  readonly toFiveHundered?: boolean;
}

export function Paginator<TData = any>({ filter, toFiveHundered }: Props<TData>) {
  const { translate } = useI18n();

  const locationHelpers = useLocationHelpers();

  const perPage = useMemo(() => filter.getPerPage(), [filter]);
  const currentPage = useMemo(() => filter.getPage(), [filter]);

  const pageCount = Number(useShallowEqualSelector(appPageCountSelector)) || 1;
  const totalCount = Number(useShallowEqualSelector(appTotalCountSelector)) || 0;

  const login = useShallowEqualSelector(userLoginSelector);

  const history = useHistory();
  const pageForCheckIfDocsLess100 = history.location.pathname === "/corporate/transfers";
  const perPageArrayList =
    (login === "uzkorgasview" || login === "uzkorgas") && pageForCheckIfDocsLess100
      ? ["15", "25", "50", "100", "500"]
      : toFiveHundered
      ? ["15", "25", "50", "100", "250"]
      : ["15", "25", "50", "100"];

  const perPageCountList = perPageArrayList.map((x) => ({
    label: x,
    value: x,
  }));

  const title = useMemo(() => {
    const of = translate("PAGINATION_OF_TITLE");

    return `${currentPage} ${of} ${pageCount}`;
  }, [currentPage, pageCount, translate]);

  const list = useMemo(() => Array(pageCount).fill(""), [pageCount]);

  const onChangePerPage = useCallback(
    (value) => {
      locationHelpers.replaceQuery({ perPage: value.value });
    },
    [locationHelpers],
  );

  return (
    <div className="d-flex align-items-center">
      <SelectPicker
        minWidth={64}
        className="mr-2"
        size={SizeType.Small}
        options={perPageCountList}
        value={{ value: `${perPage}`, label: `${perPage}` }}
        onChange={(value) => onChangePerPage(value)}
      />
      <span className="mr-2 font-weight-bold text-nowrap">{title}</span>
      <div className="btn-group" role="group">
        <Button
          active={true}
          color={ButtonColor.Default}
          disabled={currentPage === 1}
          onClick={() => locationHelpers.replaceQuery({ page: 1 })}
        >
          {"<"}
        </Button>

        {list.map((_, idx, arr) => {
          const number = idx + 1;

          if (arr.length > 4 && (number === currentPage + 3 || number === currentPage - 2)) {
            return (
              <Button
                key={idx}
                disabled={true}
                color={ButtonColor.Default}
                onClick={() => locationHelpers.replaceQuery({ page: number })}
              >
                ...
              </Button>
            );
          }

          if (
            arr.length > 4 &&
            (number > currentPage + 3 || number < currentPage - 2) &&
            number < arr.length - 2
          ) {
            return null;
          }

          return (
            <Button
              key={idx}
              color={ButtonColor.Default}
              active={currentPage === number}
              onClick={() => locationHelpers.replaceQuery({ page: number })}
            >
              {number}
            </Button>
          );
        })}
        <Button
          active={true}
          color={ButtonColor.Default}
          disabled={currentPage === pageCount}
          onClick={() => locationHelpers.replaceQuery({ page: pageCount })}
        >
          {">"}
        </Button>
      </div>
      <span className="ml-2">
        {translate("FINANCIAL_APPROVED_DEALS_TABLE_TOTAL_COLUMN_TITLE")}: {totalCount}
      </span>
    </div>
  );
}
