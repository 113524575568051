import {
  DateObject,
  DateTime,
  DateTimeFormatOptions,
  DateTimeJSOptions,
  DateTimeOptions,
} from "luxon";

export const FILTER_DATE_FORMAT = "yyyyMMdd";
export const SHORT_DATE_FORMAT = "dd.MM.yyyy";
export const SHORT_DATE_DAY = "ddMMyyyy";
export const SHORT_DATE_URL_FORMAT = "yyyy-MM-dd";
export const SHORTTEST_DATE_FORMAT = "ddMMyy";
export const FULL_DATE_FORMAT = "dd.MM.yyyy HH:mm:ss";

export const INCOME_API_DATE_FORMAT = SHORT_DATE_FORMAT;
export const INCOME_API_FULL_DATE_FORMAT = FULL_DATE_FORMAT;

export function isIncomeDateFormat(date = ""): boolean {
  return /^\d{2}\.\d{2}\.\d{4}$/.test(date);
}

export function isIncomeStringDateFormat(date = ""): boolean {
  return /^\d{8}$/.test(date);
}

export function isIncomeFullDateFormat(date = ""): boolean {
  return /^\d{2}\.\d{2}\.\d{4} (\d{2}:?){3}$/.test(date);
}

interface FormatDateFromFormatOptionsProps {
  readonly toFormat: string;
  readonly fromFormat: string;
  readonly fromOptions?: DateTimeOptions;
  readonly toOptions?: DateTimeFormatOptions;
}

export function formatDateFromFormat(date: string, options: FormatDateFromFormatOptionsProps) {
  return DateTime.fromFormat(date, options.fromFormat, options.fromOptions).toFormat(
    options.toFormat,
    options.toOptions,
  );
}

export function formatDateFromUrl(date: string, options?: DateTimeOptions): Date {
  if (!date) {
    return new Date();
  }

  return DateTime.fromFormat(date, SHORT_DATE_URL_FORMAT, options).toJSDate();
}

export function formatDate(date = new Date(), format: string, options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(format);
}

export function formatDateToUrl(date = new Date(), options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(SHORT_DATE_URL_FORMAT);
}

export function formatDateToFilter(date = new Date(), options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(FILTER_DATE_FORMAT);
}

export function formatDateToDay(date = new Date(), options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(SHORT_DATE_DAY);
}

export function formatDateToForm(date = new Date(), options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(SHORTTEST_DATE_FORMAT);
}

export function formatDateToBody(date = new Date(), options?: DateTimeOptions): string {
  return DateTime.fromJSDate(date, options).toFormat(SHORT_DATE_FORMAT);
}

export function getDateMonth(date = new Date(), options?: DateTimeJSOptions): number {
  return DateTime.fromJSDate(date, options).month;
}

export function getDateYear(date = new Date(), options?: DateTimeJSOptions): number {
  return DateTime.fromJSDate(date, options).year;
}

export function getDateDay(date = new Date(), options?: DateTimeJSOptions): number {
  return DateTime.fromJSDate(date, options).day;
}

export function formatDateFromObjectToDate(dateObject: DateObject): Date {
  return DateTime.fromObject(dateObject).toJSDate();
}

export function formatDateFromObjectToFormat(
  dateObject: DateObject,
  format: string,
  options?: DateTimeFormatOptions,
): string {
  return DateTime.fromObject(dateObject).toFormat(format, options);
}

export function toDateFormatUZT(str: string): string {
  const temp = str
    .split("")
    .reverse()
    .map((item, index) => {
      if (index >= 5 && index <= 9) {
        return "";
      } else {
        return item;
      }
    })
    .reverse()
    .join("");
  return str.includes("UZT") ? temp : str;
}

export function formatToYesOrNo(str: string) {
  if (str === "1") return "Yes";
  if (str === "0") return "No";
}
