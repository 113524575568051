import React, { MutableRefObject } from "react";
import "./assets/lcy-create-forms.scss";
import { Form, Formik } from "formik";
import { InputField } from "../form/InputField";
import { LcyTransferBudgetRevenueFormProps } from "../../api/transfer/TransferDTO";
import { DatePickerField } from "../form/DatePickerField";
import { noop } from "lodash";
import { Input } from "../ui/Input";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly ref?: MutableRefObject<any>;
  readonly initialValue: LcyTransferBudgetRevenueFormProps;
}

export function TransferLcyRevenuePrint({ ref, initialValue }: Props) {
  const { translate } = useI18n();
  return (
    <Formik initialValues={initialValue as LcyTransferBudgetRevenueFormProps} onSubmit={noop}>
      {({ handleSubmit }) => (
        <Form className="form mt-0" id="printJS-revenue-form" ref={ref} onSubmit={handleSubmit}>
          <div className="row">
            <div className="row">
              <div className="col">
                <div className="form-group row mb-1">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_DATA_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <DatePickerField name="datePrint" className="data-field" />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField
                      name="senderNamePrint"
                      height={30}
                      value={initialValue.correspondentName}
                    />
                  </div>
                </div>
                <div className="ml-3 mb-0">
                  <h1>{translate("TRANSFERS_LCY_PRINT_DEBIT_LABEL")}</h1>
                </div>
                <div className="d-flex  ml-3 mb-0">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label " id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-11">
                      <InputField
                        height={30}
                        name="currentBalancePrint"
                        value={initialValue.currentBalance}
                      />
                    </div>
                  </div>
                  <div className="form-group row mr-4 mb-0">
                    <label className="col-sm-2 col-form-label mr-4" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-10">
                      <InputField
                        name="senderTaxIdPrint"
                        height={30}
                        value={initialValue.senderTaxId}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-0">
                  <div className="form-group row ">
                    <label className="col-sm-2 col-form-label ml-3" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-11">
                      <InputField
                        name="senderBankNamePrint"
                        className="w-100"
                        height={80}
                        value={initialValue.senderBankName}
                      />
                    </div>
                  </div>
                  <div className="form-group row mr-2">
                    <label className="col-sm-2 col-form-label mr-11" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-2">
                      <InputField
                        className="w-100"
                        height={80}
                        name="senderBankCodePrint"
                        value={initialValue.senderBankCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-1">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_AMOUNT_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField name="amountPrint" height={30} value={initialValue.amount} />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField
                      height={30}
                      name="correspondentNamePrint"
                      value={initialValue.correspondentName}
                    />
                  </div>
                </div>
                <div className="ml-3 mb-0">
                  <h1>{translate("TRANSFERS_LCY_PRINT_CREDIT_LABEL")}</h1>
                </div>
                <div className="d-flex flex-grow-1 ml-3 mb-0">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-11">
                      <InputField
                        height={30}
                        name="correspondentAccountPrint"
                        value={initialValue.correspondentAccount}
                      />
                    </div>
                  </div>
                  <div className="form-group row mr-4 ">
                    <label className="col-sm-2 col-form-label mr-4" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-10">
                      <InputField
                        height={30}
                        name="correspondentTaxNumberPrint"
                        value={initialValue.correspondentTaxNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-0">
                  <div className="form-group row ">
                    <label className="col-sm-2 col-form-label ml-3" id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-11">
                      <InputField
                        name="correspondentBankNamePrintt"
                        className="w-100"
                        height={80}
                        value={initialValue.correspondentBankName}
                      />
                    </div>
                  </div>
                  <div className="form-group row mr-2">
                    <label className="col-sm-2 col-form-label mr-10 " id="spanRevenue">
                      {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL")}
                    </label>
                    <div className="col-sm-7 col-form-label ml-3">
                      <InputField
                        name="correspondentBankPrint"
                        className="w-100"
                        height={80}
                        value={initialValue.correspondentBank}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField name="sum" height={30} value={initialValue.accountState} />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label ml-3 pr-8 " id="spanRevenue">
                    {translate("TRANSFERS_LCY_REVENUE_PRINT_CLIENT_ACCOUNT_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField name="PrintBank" height={30} value={initialValue.clientAccount} />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanRevenue">
                    {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
                  </label>
                  <div className="col-sm-9 ml-1">
                    <InputField name="name" height={30} value={initialValue.amountDetails} />
                  </div>
                </div>

                {/* start */}
                {/* <div className="form-group row mb-4">
                  <label className="col-sm-2 col-form-label ml-3 pr-8" id="spanBudgetLong">
                    AuthorizerOtpKey
                  </label>
                  <div className="input-label-large from-group">
                    <Input
                      width={580}
                      height={30}
                      value={
                        initialValue.firstAuthorizerOtpKey
                          ? initialValue.firstAuthorizerOtpKey + " "
                          : " " + initialValue.secondAuthorizerOtpKey
                          ? initialValue.secondAuthorizerOtpKey + " "
                          : " " + initialValue.thirdAuthorizerOtpKey
                          ? initialValue.thirdAuthorizerOtpKey + ""
                          : ""
                      }
                    />
                  </div>
                </div> */}
                {/* end */}

                <div className="form-group row text-center mt-3 ml-11">
                  <label className="ml-11" id="spanGeneralAcc">
                    {translate("TRANSFERS_LCY_PRINT_MANAGER_LABEL")}
                  </label>
                  <label className="ml-11" id="spanInitialsTop">
                    {translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}
                  </label>
                  <input className="border-right-0 border-left-0 border-top-0 border-light ml-11" />
                </div>
                <div className="form-group row text-center ml-11">
                  <label className="ml-11" id="spanGeneralAcc">
                    {translate("TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL")}
                  </label>
                  <label className="ml-7" id="spanInitialsBot">
                    {translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}
                  </label>
                  <input className="border-right-0 border-left-0 border-top-0 border-light ml-11" />
                </div>
                <div className="row w-75 ml-11 mt-7 ml-10">
                  <div className="form-group row">
                    <div className=" ml-11 pl-11 row text-center ">
                      <Input
                        name="провернео"
                        placeholder="Проверено"
                        className="w-25 mh-auto mr-8 pb-5 text-center"
                      />
                      <Input
                        name="просоа"
                        placeholder="Одобрено"
                        className="w-25 mh-100 mr-8 pb-5 text-center"
                      />
                      <Input
                        name="sdigsio"
                        placeholder="Проведено"
                        className="w-25 mh-100 pb-5 text-center"
                      />
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label position-absolute top-3" id="span1">
                        {translate("TRANSFERS_LCY_PRINT_BANK_LABEL")}
                      </label>
                      <div className=" ml-11 pl-11 row text-center ">
                        <input
                          className="w-25 mh-auto mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                        <input
                          className="w-25 mh-100 mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                        <input
                          className="w-25 mh-100 pb-5 text-center border-right-0 border-left-0 border-top-0"
                          id="lines-end"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
