import React, { useMemo } from "react";
import cx from "classnames";
import { useHistory } from "react-router";

import { FlatButton } from "./FlatButton";
import { PdfIcon } from "../icons/PdfIcon";
import { TxtIcon } from "../icons/TxtIcon";
import { GearIcon } from "../icons/GearIcon";
import { ExcelIcon } from "../icons/ExcelIcon";
import { PrintIcon } from "../icons/PrintIcon";
import { FavoriteIcon } from "../icons/FavoriteIcon";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { viewModeSelector } from "../../reducers/appReducer";
import { CreateNewDocumentRoles, ViewMode } from "../../api/dto/AppDTO";
import { createNewDocumentRole } from "../../reducers/authReducer";
import { userLoginSelector } from "../../reducers/userReducer";
import "./assets/swift-btn-disable.css";

export interface FileControlsProps {
  readonly className?: string;

  readonly onPrintClick?: () => void;
  readonly onGenerateClick?: () => void;
  readonly onSaveToPdfClick?: () => void;
  readonly onSaveToPdf2Click?: () => void;
  readonly onSaveToTxtClick?: () => void;
  readonly onSaveToTxt2Click?: () => void;
  readonly onSaveToExcelClick?: () => void;
  readonly onSaveToExcel1Click?: () => void;
  readonly onSaveToExcel2Click?: () => void;
  readonly onSaveToExcel3Click?: () => void;
  readonly onSaveToExcel4Click?: () => void;
  readonly onSaveToExcel5Click?: () => void;
  readonly onSaveToExcelNewClick?: () => void;
  readonly onSaveToTxtAnsiClick?: () => void;
  readonly onSaveToFavoriteClick?: () => void;
  readonly onSaveToMT940Click?: () => void;
  readonly onSaveAgromir?: (typeStatus: string) => void;
  readonly onSaveToExcelAngleseyOztransgaz?: () => void;
  readonly onImportFromExcel?: () => void;
  readonly onImportFromCSV?: () => void;
  readonly onImportFromTxt?: () => void;
  readonly onUploadClick?: () => void;
  readonly onFavoritesClick?: () => void;
  readonly isUnselectType?: boolean;
  readonly isSalary?: boolean;
  readonly isMinusTransferLcyType?: boolean;
  readonly nothing?: boolean;
  readonly onSaveToWordClick?: () => void;
  readonly onSaveToExcelAngleseyClick?: () => void;
  readonly onSaveToExcelAnglesey2Click?: () => void;
  readonly onSaveToExcelFuttaimClick?: () => void;
  readonly onSaveToExcelAgromirDebitClick?: () => void;
  readonly onSaveToExcelAgromirCreditClick?: () => void;
  readonly onSaveTxtSwiftClick?: () => void;
  readonly disabledSwiftButton?: boolean;
  readonly onSaveToExcel1CClick?: () => void;
  readonly onSaveToText1CClick?: () => void;
  readonly onSaveToText3Click?: () => void;
  readonly onSaveToExcel3KorzinkaClick?: () => void;
  readonly onSaveToText11Click?: () => void;
  readonly checkIfDocsLess100?: boolean;
  readonly conversionFormButton?: boolean;
}

export function FileControls({
  className,
  onPrintClick,
  onGenerateClick,
  onSaveToPdfClick,
  onSaveToPdf2Click,
  onSaveToTxtClick,
  onSaveToTxt2Click,
  onSaveToExcelClick,
  onSaveToExcel1Click,
  onSaveToExcel2Click,
  onSaveToExcel3Click,
  onSaveToExcel4Click,
  onSaveToExcel5Click,
  onSaveToExcelNewClick,
  onSaveToTxtAnsiClick,
  onSaveToFavoriteClick,
  onSaveToMT940Click,
  onSaveTxtSwiftClick,
  disabledSwiftButton,
  onSaveAgromir,
  onSaveToExcelAngleseyOztransgaz,
  onSaveToExcel1CClick,
  onSaveToText1CClick,
  onSaveToText3Click,
  onSaveToText11Click,
  onFavoritesClick,
  onUploadClick,
  isUnselectType,
  isSalary,
  isMinusTransferLcyType,
  nothing,
  onImportFromExcel,
  onImportFromCSV,
  onImportFromTxt,
  onSaveToWordClick,
  onSaveToExcelAngleseyClick,
  onSaveToExcelAnglesey2Click,
  onSaveToExcelFuttaimClick,
  onSaveToExcelAgromirDebitClick,
  onSaveToExcelAgromirCreditClick,
  onSaveToExcel3KorzinkaClick,
  checkIfDocsLess100,
  conversionFormButton = false,
}: FileControlsProps) {
  const showPrint = useMemo(() => Boolean(onPrintClick), [onPrintClick]);
  const showTxt = useMemo(() => Boolean(onSaveToTxtClick), [onSaveToTxtClick]);
  const showPdf = useMemo(() => Boolean(onSaveToPdfClick), [onSaveToPdfClick]);
  const showPdf2 = useMemo(() => Boolean(onSaveToPdf2Click), [onSaveToPdf2Click]);
  const showTxt2 = useMemo(() => Boolean(onSaveToTxt2Click), [onSaveToTxt2Click]);
  const showGenerate = useMemo(() => Boolean(onGenerateClick), [onGenerateClick]);
  const showExcel = useMemo(() => Boolean(onSaveToExcelClick), [onSaveToExcelClick]);
  const showExcel1 = useMemo(() => Boolean(onSaveToExcel1Click), [onSaveToExcel1Click]);
  const showExcel2 = useMemo(() => Boolean(onSaveToExcel2Click), [onSaveToExcel2Click]);
  const showExcel3 = useMemo(() => Boolean(onSaveToExcel3Click), [onSaveToExcel3Click]);
  const showExcel4 = useMemo(() => Boolean(onSaveToExcel4Click), [onSaveToExcel4Click]);
  const showExcel5 = useMemo(() => Boolean(onSaveToExcel5Click), [onSaveToExcel5Click]);
  const showExcelNew = useMemo(() => Boolean(onSaveToExcelNewClick), [onSaveToExcelNewClick]);
  const showExcel3Korzinka = useMemo(() => Boolean(onSaveToExcel3KorzinkaClick), [
    onSaveToExcel3KorzinkaClick,
  ]);
  const showMT940 = useMemo(() => Boolean(onSaveToMT940Click), [onSaveToMT940Click]);
  const showAgromir = useMemo(() => Boolean(onSaveAgromir), [onSaveAgromir]);
  const showAngleseyOztransgaz = useMemo(() => Boolean(onSaveToExcelAngleseyOztransgaz), [
    onSaveToExcelAngleseyOztransgaz,
  ]);
  const showExcel1C = useMemo(() => Boolean(onSaveToExcel1CClick), [onSaveToExcel1CClick]);
  const showText1C = useMemo(() => Boolean(onSaveToText1CClick), [onSaveToText1CClick]);
  const showText3 = useMemo(() => Boolean(onSaveToText3Click), [onSaveToText3Click]);
  const showText11 = useMemo(() => Boolean(onSaveToText11Click), [onSaveToText11Click]);
  const showSwiftTxt = useMemo(() => Boolean(onSaveTxtSwiftClick), [onSaveTxtSwiftClick]);
  const showExcelAnglesey = useMemo(() => Boolean(onSaveToExcelAngleseyClick), [
    onSaveToExcelAngleseyClick,
  ]);
  const showExcelAnglesey2 = useMemo(() => Boolean(onSaveToExcelAnglesey2Click), [
    onSaveToExcelAnglesey2Click,
  ]);
  const showExcelFuttaim = useMemo(() => Boolean(onSaveToExcelFuttaimClick), [
    onSaveToExcelFuttaimClick,
  ]);
  const showAgromirDebet = useMemo(() => Boolean(onSaveToExcelAgromirDebitClick), [
    onSaveToExcelAgromirDebitClick,
  ]);
  const showAgromirCredit = useMemo(() => Boolean(onSaveToExcelAgromirCreditClick), [
    onSaveToExcelAgromirCreditClick,
  ]);
  const showWord = useMemo(() => Boolean(onSaveToWordClick), [onSaveToWordClick]);
  const showTxtAnsi = useMemo(() => Boolean(onSaveToTxtAnsiClick), [onSaveToTxtAnsiClick]);
  const showFavorite = useMemo(() => Boolean(onSaveToFavoriteClick), [onSaveToFavoriteClick]);
  const login = useShallowEqualSelector(userLoginSelector);
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);

  const history = useHistory();
  const pageForCheckIfDocsLess100 = history.location.pathname === "/corporate/transfers";

  return (
    <>
      {!nothing && (
        <div className={cx("d-flex flex-wrap", className)}>
          {isSalary !== undefined ||
            (!isMinusTransferLcyType && !showSwiftTxt && (
              <>
                <FlatButton
                  className={cx("mr-5", { "mt-2": !conversionFormButton })}
                  onClick={onFavoritesClick}
                  leftIcon={<FavoriteIcon />}
                  title="TRANSFERS_TRANSFERS_FCY_FAVORITES_BUTTON_TITLE"
                />

                {onUploadClick && (
                  <FlatButton
                    className="mr-5 mt-2"
                    onClick={onUploadClick}
                    leftIcon={<ExcelIcon />}
                    title="TRANSFERS_TRANSFERS_FCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE"
                  />
                )}
              </>
            ))}
          {showFavorite && !isMinusTransferLcyType && (
            <FlatButton
              leftIcon={<FavoriteIcon />}
              onClick={onSaveToFavoriteClick}
              title="FILE_CONTROLS_SAVE_TO_FAVORITE_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2":
                  showTxt ||
                  showPdf ||
                  showTxt2 ||
                  showExcel ||
                  showPrint ||
                  showTxtAnsi ||
                  showGenerate,
              })}
            />
          )}
          {isUnselectType && (
            <>
              {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
                <FlatButton
                  leftIcon={<ExcelIcon />}
                  title="TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_LCY_BUTTON_TITLE"
                  className="mr-5 mt-2"
                  onClick={onImportFromExcel}
                />
              ) : (
                <div />
              )}
            </>
          )}
          {onImportFromCSV && isUnselectType && (
            <>
              {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
                <FlatButton
                  leftIcon={<ExcelIcon />}
                  title="FILE_SELECTOR_IMPORT_FROM_CSV"
                  className="mr-5 mt-2"
                  onClick={onImportFromCSV}
                />
              ) : (
                <div />
              )}
            </>
          )}
          {onImportFromTxt && isUnselectType && (
            <>
              {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
                <FlatButton
                  leftIcon={<TxtIcon />}
                  title="FILE_SELECTOR_IMPORT_FROM_TXT"
                  className="mr-5 mt-2"
                  onClick={onImportFromTxt}
                />
              ) : (
                <div />
              )}
            </>
          )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            pageForCheckIfDocsLess100 &&
            showExcel && (
              <FlatButton
                leftIcon={<ExcelIcon />}
                onClick={!checkIfDocsLess100 ? () => false : onSaveToExcelClick}
                title="FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
                  disabled: !checkIfDocsLess100,
                })}
              />
            )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            !pageForCheckIfDocsLess100 &&
            showExcel && (
              <FlatButton
                leftIcon={<ExcelIcon />}
                onClick={onSaveToExcelClick}
                title="FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
                })}
              />
            )}
          {login !== "uzkorgasview" && login !== "uzkorgas" && showExcel && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcelNew && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelNewClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_NEW_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2":
                  showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint || showExcel,
              })}
            />
          )}

          {showExcel5 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel5Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL5_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2":
                  showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint || showExcel,
              })}
            />
          )}

          {showExcel1 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel1Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL1_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcel2 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel2Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL2_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcel3 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel3Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL3_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcel4 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel4Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL4_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcelAnglesey && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelAngleseyClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcelAnglesey2 && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelAnglesey2Click}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY2_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showExcelFuttaim && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelFuttaimClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_FUTTAIM_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showAgromirCredit && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelAgromirCreditClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_CREDIT_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showAgromirDebet && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcelAgromirDebitClick}
              title="FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_DEBIT_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {showWord && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToWordClick}
              title="SAVE_TO_WORD"
              className={cx({
                "mr-5": showTxt2 || showTxtAnsi || showTxt || showPdf || showPrint,
              })}
            />
          )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            pageForCheckIfDocsLess100 &&
            showPdf && (
              <FlatButton
                leftIcon={<PdfIcon />}
                onClick={!checkIfDocsLess100 ? () => false : onSaveToPdfClick}
                title="FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showPdf,
                  disabled: !checkIfDocsLess100 || disabledSwiftButton,
                })}
              />
            )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            !pageForCheckIfDocsLess100 &&
            showPdf && (
              <FlatButton
                leftIcon={<PdfIcon />}
                onClick={onSaveToPdfClick}
                title="FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showPdf,
                  disabled: disabledSwiftButton,
                })}
              />
            )}
          {login !== "uzkorgasview" && login !== "uzkorgas" && showPdf && (
            <FlatButton
              leftIcon={<PdfIcon />}
              onClick={onSaveToPdfClick}
              title="FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showPdf,
                disabled: disabledSwiftButton,
              })}
            />
          )}

          {(login === "uzkorgasview" || login === "uzkorgas") &&
            pageForCheckIfDocsLess100 &&
            showPdf2 && (
              <FlatButton
                leftIcon={<PdfIcon />}
                onClick={!checkIfDocsLess100 ? () => false : onSaveToPdf2Click}
                title="FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showPdf,
                  disabled: !checkIfDocsLess100 || disabledSwiftButton,
                })}
              />
            )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            !pageForCheckIfDocsLess100 &&
            showPdf2 && (
              <FlatButton
                leftIcon={<PdfIcon />}
                onClick={onSaveToPdf2Click}
                title="FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE"
                className={cx({
                  "mr-5 mt-2": showPdf,
                  disabled: disabledSwiftButton,
                })}
              />
            )}
          {login !== "uzkorgasview" && login !== "uzkorgas" && showPdf2 && (
            <FlatButton
              leftIcon={<PdfIcon />}
              onClick={onSaveToPdf2Click}
              title="FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2": showPdf,
                disabled: disabledSwiftButton,
              })}
            />
          )}

          {showGenerate && (
            <FlatButton
              leftIcon={<GearIcon />}
              onClick={onGenerateClick}
              title="FILE_CONTROLS_GENERATE_BUTTON_TITLE"
              className={cx({
                "mr-5 mt-2":
                  showTxt2 || showTxtAnsi || showTxt || showPdf || showExcel || showPrint,
              })}
            />
          )}
          {/* It's old version save to text1 */}
          {/* {showTxt && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToTxtClick}
              title="FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE"
              className={cx({ "mr-5 mt-2": showTxt2 || showTxtAnsi })}
            />
          )} */}
          {showText11 && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToText11Click}
              className={cx({ "mr-5 mt-2": showText11 })}
              title="FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE"
            />
          )}
          {showTxt2 && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToTxt2Click}
              className={cx({ "mr-5 mt-2": showTxtAnsi })}
              title="FILE_CONTROLS_SAVE_TO_TXT_2_BUTTON_TITLE"
            />
          )}
          {showText3 && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToText3Click}
              className={cx({ "mr-5 mt-2": showText3 })}
              title="FILE_CONTROLS_SAVE_TO_TXT_3_BUTTON_TITLE"
            />
          )}
          {showTxtAnsi && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToTxtAnsiClick}
              title="FILE_CONTROLS_SAVE_TO_TXT_ANSI_BUTTON_TITLE"
              className="mr-5 mt-2"
            />
          )}
          {showMT940 && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToMT940Click}
              className={cx({ "mr-5 mt-2": showMT940 })}
              title="FILE_CONTROLS_SAVE_TO_MT940_BUTTON_TITLE"
            />
          )}
          {showSwiftTxt && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveTxtSwiftClick}
              className={cx({
                "mr-5 mt-2": showSwiftTxt,
                "swift-btn disabled": disabledSwiftButton,
              })}
              title="FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE"
            />
          )}
          {showExcel1C && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel1CClick}
              className={cx({ "mr-5 mt-2": showExcel1C })}
              title="FILE_CONTROLS_SAVE_EXCEL_1C_BUTTON_TITLE"
            />
          )}
          {showExcel3Korzinka && (
            <FlatButton
              leftIcon={<ExcelIcon />}
              onClick={onSaveToExcel3KorzinkaClick}
              className={cx({ "mr-5 mt-2": showExcel3Korzinka })}
              title="FILE_CONTROLS_SAVE_EXCEL_3KORZINKA_BUTTON_TITLE"
            />
          )}
          {showText1C && (
            <FlatButton
              leftIcon={<TxtIcon />}
              onClick={onSaveToText1CClick}
              className={cx({ "mr-5 mt-2": showText1C })}
              title="FILE_CONTROLS_SAVE_TEXT_1C_BUTTON_TITLE"
            />
          )}
          {/* Uztransgaz: utgbux480
              Anglesey Food: Angelseyfood
          */}
          {(login === "utgbux480" ||
            login === "Angelseyfood" ||
            login === "AngleseyFood" ||
            login === "Angelseyfood111" ||
            login === "Angelseyfood767" ||
            login === "isakov7676" ||
            login === "ibankuser618") &&
            showAngleseyOztransgaz && (
              <FlatButton
                leftIcon={<PrintIcon />}
                onClick={onSaveToExcelAngleseyOztransgaz}
                className={cx({ "mr-5 mt-2": showAngleseyOztransgaz })}
                title="FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE_SV"
              />
            )}
          {(login === "AGROMIR" || login === "ibankuser539") && showAgromir && (
            <FlatButton
              leftIcon={<PrintIcon />}
              onClick={() => onSaveAgromir && onSaveAgromir("C")}
              className={cx({ "mr-5 mt-2": showAgromir })}
              title="FILE_CONTROLS_SAVE_AGROMIR_CREDIT_BUTTON_TITLE"
            />
          )}
          {(login === "AGROMIR" || login === "ibankuser539") && showAgromir && (
            <FlatButton
              leftIcon={<PrintIcon />}
              onClick={() => onSaveAgromir && onSaveAgromir("D")}
              className={cx({ "mr-5 mt-2": showAgromir })}
              title="FILE_CONTROLS_SAVE_AGROMIR_DEBIT_BUTTON_TITLE"
            />
          )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            pageForCheckIfDocsLess100 &&
            showPrint && (
              <FlatButton
                onClick={!checkIfDocsLess100 ? () => false : onPrintClick}
                leftIcon={<PrintIcon />}
                title="FILE_CONTROLS_PRINT_BUTTON_TITLE"
                className={cx({
                  "mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showExcel,
                  disabled: !checkIfDocsLess100,
                })}
              />
            )}
          {(login === "uzkorgasview" || login === "uzkorgas") &&
            !pageForCheckIfDocsLess100 &&
            showPrint && (
              <FlatButton
                onClick={onPrintClick}
                leftIcon={<PrintIcon />}
                title="FILE_CONTROLS_PRINT_BUTTON_TITLE"
                className={cx({
                  "mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showExcel,
                })}
              />
            )}
          {login !== "uzkorgasview" && login !== "uzkorgas" && showPrint && (
            <FlatButton
              onClick={onPrintClick}
              leftIcon={<PrintIcon />}
              title="FILE_CONTROLS_PRINT_BUTTON_TITLE"
              className={cx({
                "mt-2": showTxt2 || showTxtAnsi || showTxt || showPdf || showExcel,
              })}
            />
          )}
        </div>
      )}
    </>
  );
}
