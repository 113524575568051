import { Redirect, Route, Switch } from "react-router";
import React, { Suspense, useEffect, useMemo } from "react";

import { Routes } from "../constants/Routes";
import { ViewMode } from "../api/dto/AppDTO";
import { Loader } from "../components/ui/Loader";
import { useRole } from "../helpers/RoleContext";
import { useAuthContext } from "../api/auth/AuthContext";
import { viewModeSelector } from "../reducers/appReducer";
import { NotFoundContainer } from "./shared/NotFoundContainer";
import { PrivateRoute } from "../components/routing/PrivateRoute";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { tokenSelector } from "../reducers/authReducer";
import { userSelector } from "../reducers/userReducer";

const AuthContainer = React.lazy(() => import("./auth/AuthContainer"));
const FinancialContainer = React.lazy(() => import("./financial/FinancialContainer"));
const CorporateContainer = React.lazy(() => import("./corporate/CorporateContainer"));
const IndividualContainer = React.lazy(() => import("./individual/IndividualContainer"));

export function RootContainer() {
  const { isFinancial, isCorporate, isIndividual } = useRole();
  const user = useShallowEqualSelector(userSelector);

  const token = useShallowEqualSelector(tokenSelector);
  const viewMode = useShallowEqualSelector(viewModeSelector);

  const { logout } = useAuthContext();

  const isAuthorized = useMemo(() => Boolean(token && viewMode !== ViewMode.Undetermined && user), [
    token,
    viewMode,
    user,
  ]);

  const initialRoute: Routes = useMemo(() => {
    if (isIndividual) {
      return Routes.IndividualAccountsRoute;
    }

    if (isFinancial) {
      return Routes.FinancialMain;
    }

    return Routes.CorporateAccounts;
  }, [isFinancial, isIndividual]);

  useEffect(() => {
    if (!isCorporate && !isFinancial && !isIndividual && isAuthorized) {
      logout();
    }
  }, [isCorporate, isFinancial, isIndividual, isAuthorized, logout]); // было связано. добавил чтобы исправить эту ошибку url: /api/v1/Error:%20Missing%20path%20param:%20employeeId

  return (
    <Suspense
      fallback={
        <div className="d-flex flex-1 align-items-center justify-content-center">
          <Loader />
        </div>
      }
    >
      <Switch>
        {isAuthorized && <Redirect to={initialRoute} from="/auth" />}
        {isAuthorized && <Redirect exact={true} to={initialRoute} from="/" />}

        {!isAuthorized && <Route component={AuthContainer} path={Routes.Auth} />}

        {!isAuthorized && <Redirect to={Routes.AuthLogin} />}

        {isFinancial && <Redirect to={initialRoute} from="/(corporate|individual)" />}
        {isCorporate && <Redirect to={initialRoute} from="/(individual|financial)" />}
        {isIndividual && <Redirect to={initialRoute} from="/(corporate|financial)" />}

        {isFinancial && <PrivateRoute path={Routes.Financial} component={FinancialContainer} />}
        {isCorporate && <PrivateRoute path={Routes.Corporate} component={CorporateContainer} />}
        {isIndividual && <PrivateRoute path={Routes.Individual} component={IndividualContainer} />}

        <Route component={NotFoundContainer} />
      </Switch>
    </Suspense>
  );
}
