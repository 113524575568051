import React, { useMemo } from "react";
import { TabPage } from "../tabs/TabPage";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import {
  AccountsFilter,
  ApprovalAccountsSubmitType,
  ApprovalTradeFinanceSubmitType,
} from "../../filters/AccountsFilter";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { TotalPendingApplicationProps } from "../../api/account/AccountDTO";
import { Badge } from "../ui/Badge";
import { toFinite } from "lodash";
import { TradeFinanceTableWrapper } from "./TradeFinanceTableWrapper";
import { TradeFinanceAmandmentWrapper } from "./TradeFinanceAmandmentWrapper";
import { TradeFinanceDiscrepencyWrapper } from "./TradeFinanceDiscrepencyWrapper";

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeTab: (tab: ApprovalAccountsSubmitType) => void;
  readonly approvalFilter: PendingApprovalsFilter;
  readonly setDocumentsId: (value: any, appType: any) => void;
  readonly totalApl: TotalPendingApplicationProps | undefined;
}

export function TradeFinanceTab({
  filter,
  onChangeTab,
  approvalFilter,
  setDocumentsId,
  totalApl,
}: Props) {
  const accountType = useMemo(() => filter.getTradeFinanceType(), [filter]);

  return (
    <TabPage contentClassName="bg-transparent border-0" className="px-4">
      <SubTabs
        activeTab={accountType}
        onChangeTab={onChangeTab}
        defaultTab={ApprovalTradeFinanceSubmitType.Application}
      >
        <TabPane
          key={ApprovalTradeFinanceSubmitType.Application}
          tab="PENDING_APPROVALS_APPLICATION_OPENING"
          badge={
            <Badge value={toFinite(totalApl?.totalRequestsForTradeFinance)} className="bg-danger" />
          }
        >
          <TradeFinanceTableWrapper setDocumentsId={setDocumentsId} filter={approvalFilter} />
        </TabPane>
        <TabPane
          key={ApprovalTradeFinanceSubmitType.Amendment}
          tab="PENDING_APPROVALS_AMENDMENT_OPENING"
          badge={
            <Badge value={toFinite(totalApl?.totalRequestsForAmendment)} className="bg-danger" />
          }
        >
          <TradeFinanceAmandmentWrapper filter={filter} setDocumentsId={setDocumentsId} />
        </TabPane>
        <TabPane
          key={ApprovalTradeFinanceSubmitType.Discrepancy}
          tab="PENDING_APPROVALS_DISCREPANCY_OPENING"
          badge={
            <Badge value={toFinite(totalApl?.totalRequestsForDiscrepancy)} className="bg-danger" />
          }
        >
          <TradeFinanceDiscrepencyWrapper setDocumentsId={setDocumentsId} filter={filter} />
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
