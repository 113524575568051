import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { update } from "immupdate";
import { NotificationManager } from "react-notifications";
import cx from "classnames";
import { DateTime } from "luxon";

import { LCYStandardTransferFormWrapper } from "./LCYStandardTransferFormWrapper";
import { LCYBudgetTransferFormWrapper } from "./LCYBudgetTransferFormWrapper";
import { LCYBudgetRevenueTransferFormWrapper } from "./LCYBudgetRevenueTransferFormWrapper";
import { LCYMunisTransferFormWrapper } from "./LCYMunisTransferFormWrapper";
import { TransferLcyType, TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { PaymentCodeBudgetFlag, PaymentType } from "../../api/reference-book/ReferenceBookDTO";
import { LcyTransferFormProps } from "../../api/transfer/TransferDTO";
import { TransfersTabLcyHeader } from "./TransfersTabLcyHeader";
import { Button, ButtonColor } from "../ui/Button";
import { FileControls } from "../ui/FileControls";
import { formatDateToBody } from "../../utils/DateUtils";
import { showError } from "../../utils/NotificationUtils";
import { Paginator } from "../ui/Paginator";
import { TabPage } from "../tabs/TabPage";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { FavouritesModalTab } from "./FavouritesModalTab";
import { Modal } from "../ui/Modal";
import {
  createTransferLCYBudgetExcelFromForm,
  createTransferLCYExcelFromForm,
  createTransferLCYRevenueExcelFromForm,
  printTransferLCYTable,
} from "../../helpers/TransfersHelpers";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userLoginSelector } from "../../reducers/userReducer";
import { useReferenceBookContext } from "../../api/reference-book/ReferenceBookContext";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { numberToWords } from "../../utils/FormatUtils";
import { CreateNewDocumentRoles, SizeType, ViewMode } from "../../api/dto/AppDTO";
import { useResource } from "../../hooks/useResource";
import { printForm } from "../../utils/PintUtils";
import { TransferLcyBudgetPrint } from "./TransferLcyBudgetPrint";
import { TransferLcyRevenuePrint } from "./TransferLcyRevenuePrint";
import { TransferLcyStandartPrint } from "./TransferLcyStandartPrint";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { appLanguageSelector, viewModeSelector } from "../../reducers/appReducer";
import { createNewDocumentRole } from "../../reducers/authReducer";
import { PrintPortal } from "../ui/PrintPortal";
import { TransferLcyBudgetTablePrint } from "./TransferLcyBudgetTablePrint";
import { TransferLcyRevenueTablePrint } from "./TransferLcyRevenueTablePrint";
import { Input } from "../ui/Input";

interface Props {
  readonly filter: TransfersFilter;

  readonly onLcySubmit: (values: LcyTransferFormProps) => void;
  readonly values: any;
  readonly backButton: () => void;
  readonly loading: boolean;
  readonly beginDay: string;
}

export function TransferLCYFormsWrapper({
  filter,
  onLcySubmit,
  values,
  backButton,
  loading,
  beginDay,
}: Props) {
  const { ReferenceBookApi } = useReferenceBookContext();
  const { TransferApi } = useTransferContext();
  const { PdfReportApi } = usePdfReportContext();
  const { translate } = useI18n();
  const [searchFav, setSearchFav] = useState("");
  const transferType = useMemo(() => filter.getTransferType(), [filter]);
  const transferLcyType = useMemo(() => filter.getTransferLcyType(), [filter]);
  const lcyFormRef = useRef<any>(null);
  const isUnselectType = useMemo(() => transferType === TransferType.Unselect, [transferType]);
  const locationHelpers = useLocationHelpers();
  const isLcyTransferType = useMemo(() => transferType === TransferType.LCY, [transferType]);
  const [lcyFavourites, setLcyFavourites] = useState(false);
  const login = useShallowEqualSelector(userLoginSelector);
  const language = useShallowEqualSelector(appLanguageSelector);
  const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;

  const isStandardTransferLcyType = useMemo(
    () => transferLcyType === TransferLcyType.StandardTransfer,
    [transferLcyType],
  );
  const isBudgetTransferLcyType = useMemo(
    () => transferLcyType === TransferLcyType.BudgetTransfer,
    [transferLcyType],
  );
  const isAnorBudgetTransferLcyType = useMemo(
    () => transferLcyType === TransferLcyType.AnorBudgetTransfer,
    [transferLcyType],
  );
  const isAnorTransferLcyType = useMemo(() => transferLcyType === TransferLcyType.AnorTransfer, [
    transferLcyType,
  ]);
  const isBudgetRevenueTransferLcyType = useMemo(
    () => transferLcyType === TransferLcyType.BudgetRevenueTransfer,
    [transferLcyType],
  );
  const isAnorBudgetRevenueTransferLcyType = useMemo(
    () => transferLcyType === TransferLcyType.AnorBudgetRevenueTransfer,
    [transferLcyType],
  );
  const isMinusTransferLcyType = useMemo(() => transferLcyType === TransferLcyType.MinusTransfer, [
    transferLcyType,
  ]);
  const ref = useRef<any>(null);
  const lcyFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        lcyFormRef.current = instance;
      }
    },
    [lcyFormRef],
  );

  const [budgetInitialValues, setBudgetInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "" as any,
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    operationDate: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    detailsOfPayment: "",
    paymentType: PaymentType.PaymentOrder,
    budgetAccount: "",
    budgetAccountName: "",
    budgetFlag: PaymentCodeBudgetFlag.BudgetDoc,
    budgetTaxNumber: "",
    state: "",
    transactionDate: "",
  });

  const [standardInitialValues, setStandardInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "",
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    senderMfo: "",
    receiverMfo: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    amount: "",
    amountDetails: "",
    operationDate: "",
    paymentCode: "",
    detailsOfPayment: "",
    paymentType: PaymentType.PaymentOrder,
    budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
    budgetAccountName: "",
    budgetTaxNumber: "",
    state: "",
    transactionDate: "",
  });

  const [budgetRevenueInitialValues, setBudgetRevenueInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "" as any,
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    operationDate: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    detailsOfPayment: "",
    paymentType: PaymentType.PaymentOrder,
    budgetAccount: "",
    budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
    budgetAccountName: "",
    budgetTaxNumber: "",
    state: "",
    transactionDate: "",
  });

  const [munisInitialValues, setMunisInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "",
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    amountForPayment: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    operationDate: "",
    detailsOfPayment: "",
    paymentType: PaymentType.Munis,
    budgetAccount: "",
    budgetAccountName: "",
    budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
    budgetTaxNumber: "",
    settlementCode: "",
    supplierCode: "",
    invoiceNumber: "",
    state: "",
    transactionDate: "",
  });

  const [anorStandartInitialValues, setAnorStandartInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "",
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    operationDate: "",
    detailsOfPayment: "",
    paymentType: PaymentType.Anor,
    budgetAccount: "",
    budgetAccountName: "",
    budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
    budgetTaxNumber: "",
    settlementCode: "",
    supplierCode: "",
    invoiceNumber: "",
    state: "",
    transactionDate: "",
  });

  const [anorBudgetInitialValues, setAnorBudgetInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "",
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    operationDate: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    detailsOfPayment: "",
    paymentType: PaymentType.Anor,
    budgetAccount: "",
    budgetAccountName: "",
    budgetFlag: PaymentCodeBudgetFlag.BudgetDoc,
    budgetTaxNumber: "",
    settlementCode: "",
    supplierCode: "",
    invoiceNumber: "",
    state: "",
    transactionDate: "",
  });

  const [anorBudgetRevenueInitialValues, setAnorBudgetRevenueInitialValues] = useState({
    documentNumber: "",
    documentDate: new Date(),
    senderName: "",
    clientAccount: "",
    accountState: "",
    currentBalance: "",
    senderBankName: "",
    operationDate: "",
    senderBankCode: "",
    senderTaxId: "",
    correspondentName: "",
    correspondentAccount: "",
    correspondentBankName: "",
    correspondentBank: "",
    correspondentTaxNumber: "",
    amount: "",
    amountDetails: "",
    paymentCode: "",
    detailsOfPayment: "",
    favoriteName: "",
    paymentType: PaymentType.Anor,
    budgetAccount: "",
    budgetAccountName: "",
    budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
    budgetTaxNumber: "",
    settlementCode: "",
    supplierCode: "",
    invoiceNumber: "",
    state: "",
    transactionDate: "",
  });
  const initials = useMemo(
    () =>
      isStandardTransferLcyType
        ? standardInitialValues
        : isBudgetTransferLcyType
        ? budgetInitialValues
        : isBudgetRevenueTransferLcyType
        ? budgetRevenueInitialValues
        : isAnorTransferLcyType
        ? anorStandartInitialValues
        : isAnorBudgetTransferLcyType
        ? anorBudgetInitialValues
        : anorBudgetRevenueInitialValues,
    [
      anorBudgetInitialValues,
      anorBudgetRevenueInitialValues,
      anorStandartInitialValues,
      budgetInitialValues,
      budgetRevenueInitialValues,
      isAnorBudgetTransferLcyType,
      isAnorTransferLcyType,
      isBudgetRevenueTransferLcyType,
      isBudgetTransferLcyType,
      isStandardTransferLcyType,
      standardInitialValues,
    ],
  );
  const [favorites, setFavorites] = useState([
    {
      amount: "",
      clientAccount: "",
      budgetAccount: "",
      budgetAccountName: "",
      budgetTaxNumber: "",
      correspondentAccount: "",
      correspondentBank: "",
      correspondentBankName: "",
      correspondentName: "",
      correspondentTaxNumber: "",
      currency: "",
      detailsOfPayment: "",
      documentNumber: "",
      employerId: "",
      invoiceNumber: "",
      paymentCode: "",
      settlementCode: "",
      supplierCode: "",
      type: "",
      id: "",
    },
  ]);
  const openFavorites = useCallback(() => {
    if (isStandardTransferLcyType || isAnorTransferLcyType) {
      TransferApi.getLcyFavorites({ queryType: "standardTransfer", login }).then((r) => {
        r.map(
          (x) => (
            (x.docType = "Standard Transfer"),
            (x.currency = "UZS"),
            (x.detailsOfPayment = x.detailsOfPayment && x.detailsOfPayment.replace(/\s+/g, " "))
          ),
        );
        setFavorites(r);
      });
    } else if (isBudgetTransferLcyType || isAnorBudgetTransferLcyType) {
      TransferApi.getLcyFavorites({ queryType: "budgetTransfer", login }).then((r) => {
        r.map(
          (x) => (
            (x.docType = "Budget Transfer"),
            (x.currency = "UZS"),
            (x.detailsOfPayment = x.detailsOfPayment && x.detailsOfPayment.replace(/\s+/g, " "))
          ),
        );
        setFavorites(r);
      });
    } else {
      TransferApi.getLcyFavorites({ queryType: "revenueTransfer", login }).then((r) => {
        r.map(
          (x) => (
            (x.docType = "Revenue Transfer"),
            (x.currency = "UZS"),
            (x.detailsOfPayment = x.detailsOfPayment && x.detailsOfPayment.replace(/\s+/g, " "))
          ),
        );
        setFavorites(r);
      });
    }
    setLcyFavourites(true);
  }, [
    TransferApi,
    isAnorBudgetTransferLcyType,
    isAnorTransferLcyType,
    isBudgetTransferLcyType,
    isStandardTransferLcyType,
    login,
  ]);

  useResource(() => {
    if (!values[0]) {
      TransferApi.getNextTransferNumber().then((r) => {
        setStandardInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
        setBudgetInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
        setBudgetRevenueInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
        setAnorStandartInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
        setAnorBudgetInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
        setAnorBudgetRevenueInitialValues((prev) =>
          update(prev, {
            documentNumber: r[0].nextTransferNumber,
          }),
        );
      });
    }
  }, []);

  const [debitAccount, setDebitAccount] = useState({});

  useResource(
    () =>
      ReferenceBookApi.getAllowedAccounts().then((r) => {
        setDebitAccount(
          r.map((res) => ({
            label: res.accountCode,
            value: res.accountCode,
            clientCode: res.accountCode,
            balance: res.balance,
            senderBankCode: res?.accountBranch,
            senderName: res?.accountName,
            accountState: res?.accountState,
            senderBankName: res?.bankName,
            senderTaxId: res?.taxNumber,
          })),
        );
      }),
    [TransferApi],
  );

  const getFavorites = useCallback(
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      const selected = favorites.filter((x) => x.id === value);
      const amountDetails =
        selected[0].amount !== "0.00"
          ? numberToWords(removeGaps(selected[0].amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            })
          : "";
      // @ts-ignore
      const account = debitAccount.filter((x) => x.clientCode === selected[0].clientAccount);
      if (isStandardTransferLcyType || isAnorTransferLcyType) {
        if (isAnorTransferLcyType) {
          setAnorStandartInitialValues((prev) =>
            update(prev, {
              amount: selected[0]?.amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: { label: selected[0].clientAccount, value: selected[0].clientAccount },
              correspondentAccount: selected[0]?.correspondentAccount,
              correspondentBank: selected[0]?.correspondentBank,
              correspondentBankName: selected[0]?.correspondentBankName,
              correspondentName: selected[0]?.correspondentName,
              correspondentTaxNumber: selected[0]?.correspondentTaxNumber,
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
            }),
          );
        } else {
          setStandardInitialValues((prev) =>
            update(prev, {
              amount: selected[0].amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: { label: selected[0].clientAccount, value: selected[0].clientAccount },
              correspondentAccount: selected[0]?.correspondentAccount,
              correspondentBank: selected[0]?.correspondentBank,
              correspondentBankName: selected[0]?.correspondentBankName,
              correspondentName: selected[0]?.correspondentName,
              correspondentTaxNumber: selected[0]?.correspondentTaxNumber,
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
            }),
          );
        }
      } else if (isBudgetTransferLcyType || isAnorBudgetTransferLcyType) {
        if (isAnorBudgetTransferLcyType) {
          setAnorBudgetInitialValues((prev) =>
            update(prev, {
              amount: selected[0].amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: {
                label: selected[0].clientAccount,
                value: selected[0].clientAccount,
              },
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
              budgetAccount: selected[0]?.budgetAccount,
              budgetAccountName: selected[0]?.budgetAccountName,
              budgetTaxNumber: selected[0]?.budgetTaxNumber,
            }),
          );
        } else {
          setBudgetInitialValues((prev) =>
            update(prev, {
              amount: selected[0].amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: {
                label: selected[0].clientAccount,
                value: selected[0].clientAccount,
              },
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
              budgetAccount: selected[0]?.budgetAccount,
              budgetAccountName: selected[0]?.budgetAccountName,
              budgetTaxNumber: selected[0]?.budgetTaxNumber,
            }),
          );
        }
      } else {
        if (isAnorBudgetRevenueTransferLcyType) {
          setAnorBudgetRevenueInitialValues((prev) =>
            update(prev, {
              amount: selected[0].amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: {
                label: selected[0].clientAccount,
                value: selected[0].clientAccount,
              },
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
              budgetAccount: selected[0]?.budgetAccount,
              budgetAccountName: selected[0]?.budgetAccountName,
            }),
          );
        } else {
          setBudgetRevenueInitialValues((prev) =>
            update(prev, {
              amount: selected[0].amount,
              amountDetails:
                amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
              // @ts-ignore
              clientAccount: {
                label: selected[0].clientAccount,
                value: selected[0].clientAccount,
              },
              detailsOfPayment: selected[0]?.detailsOfPayment,
              paymentCode: selected[0]?.paymentCode,
              senderName: account[0]?.senderName,
              senderBankCode: account[0]?.senderBankCode,
              senderBankName: account[0]?.senderBankName,
              currentBalance: account[0]?.balance,
              senderTaxId: account[0]?.senderTaxId,
              budgetAccount: selected[0]?.budgetAccount,
              budgetAccountName: selected[0]?.budgetAccountName,
            }),
          );
        }
      }
      setLcyFavourites(false);
    },
    [
      debitAccount,
      favorites,
      isAnorBudgetRevenueTransferLcyType,
      isAnorBudgetTransferLcyType,
      isAnorTransferLcyType,
      isBudgetTransferLcyType,
      isStandardTransferLcyType,
      language,
    ],
  );

  useEffect(() => {
    if (values[0]?.budgetAccount === null) {
      if (values[0]?.documentType === "01") {
        locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.StandardTransfer });
      } else {
        locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.AnorTransfer });
      }
    } else if (values[0]?.budgetAccount?.length === 25) {
      if (values[0]?.documentType === "01") {
        locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.BudgetRevenueTransfer });
      } else {
        locationHelpers.replaceQuery({
          transferLcyType: TransferLcyType.AnorBudgetRevenueTransfer,
        });
      }
    } else if (values[0]?.budgetAccount?.length === 27) {
      if (values[0]?.documentType === "01") {
        locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.BudgetTransfer });
      } else {
        locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.AnorBudgetTransfer });
      }
    } else if (values[0]?.documentType === "20") {
      locationHelpers.replaceQuery({ transferLcyType: TransferLcyType.MinusTransfer });
    }
    const amountDetails = values[0]?.amount
      ? numberToWords(removeGaps(values[0]?.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        })
      : "";
    const dataForInitial = {
      ...values[0],
      documentDate:
        values[0]?.documentDate &&
        DateTime.fromFormat(values[0]?.documentDate, "dd.MM.yyyy").toJSDate(),
      senderName: values[0]?.clientName,
      clientAccount: { label: values[0]?.clientAccount, value: values[0]?.clientAccount },
      senderBankName: values[0]?.clientBankName,
      senderBankCode: values[0]?.senderMfo,
      senderTaxId: values[0]?.clientTaxNumber,
      correspondentBank: values[0]?.receiverMfo,
      amount: values[0]?.amount && floatFormat(addZeros(values[0]?.amount)),
      amountDetails:
        amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
      detailsOfPayment: values[0]?.paymentDetail,
      budgetAccountName: values[0]?.budgetName,
      currentBalance: values[0]?.clientAccountBalance,
    };
    if (transferLcyType === TransferLcyType.StandardTransfer) {
      setStandardInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.BudgetTransfer) {
      setBudgetInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.BudgetRevenueTransfer) {
      setBudgetRevenueInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.AnorTransfer) {
      setAnorStandartInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.AnorBudgetTransfer) {
      setAnorBudgetInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.AnorBudgetRevenueTransfer) {
      setAnorBudgetRevenueInitialValues((prev) => update(prev, { ...dataForInitial }));
    } else if (transferLcyType === TransferLcyType.MinusTransfer) {
      setMunisInitialValues((prev) => update(prev, { ...dataForInitial }));
    }
  }, [transferLcyType, values, languageApp]);
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);

  const favoritesListSearch = useCallback((data, term) => {
    if (term !== "") {
      return (
        data &&
        data.filter(
          (item) => `${item.documentNumber} ${item.correspondentTaxNumber}`.indexOf(term) > -1,
        )
      );
    } else return data;
  }, []);

  const beneficiaryListAfterSearch = useMemo(() => favoritesListSearch(favorites, searchFav), [
    favorites,
    searchFav,
    favoritesListSearch,
  ]);

  const [favouritesNameModal, setFavouritesNameModal] = useState(false);
  const [favouritesName, setFavouritesName] = useState<string>("");

  return (
    <>
      <TabPage
        className="px-7"
        headerComponent={
          <TransfersTabLcyHeader
            type={transferLcyType}
            onChange={(lcyType) => locationHelpers.replaceQuery({ transferLcyType: lcyType })}
            disabled={values.length !== 0}
            backButton={backButton}
            beginDay={beginDay}
          />
        }
        footerComponent={
          <div
            className={cx(
              "d-flex flex-shrink-1 flex-grow-1 align-items-lg-center flex-lg-row flex-column",
              {
                "justify-content-between": !isUnselectType,
              },
            )}
          >
            {!isUnselectType && (
              <>
                {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
                  transferLcyType === TransferLcyType.AnorBudgetRevenueTransfer ||
                  transferLcyType === TransferLcyType.AnorBudgetTransfer ||
                  transferLcyType === TransferLcyType.AnorTransfer ||
                  transferLcyType === TransferLcyType.MinusTransfer ? (
                    <Button
                      className="px-6 font-weight-bold my-lg-0 my-3"
                      color={ButtonColor.Orange}
                      labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                      onClick={() => {
                        if (lcyFormRef.current?.handleSubmit) {
                          lcyFormRef.current?.handleSubmit();
                        }
                      }}
                      size={SizeType.Medium}
                      disabled={loading}
                    />
                  ) : (
                    <Button
                      className="px-6 font-weight-bold my-lg-0 my-3"
                      color={ButtonColor.Orange}
                      labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                      onClick={() => {
                        if (lcyFormRef.current?.handleSubmit) {
                          lcyFormRef.current?.handleSubmit();
                        }
                      }}
                      size={SizeType.Medium}
                      disabled={loading}
                    />
                  )
                ) : (
                  <div />
                )}

                {/* {viewMode === ViewMode.Full &&
                createMode === CreateNewDocumentRoles.Yes &&
                (transferLcyType === "minus" || transferLcyType === "anor") ? (
                  <Button
                    className="px-6 font-weight-bold"
                    color={ButtonColor.Orange}
                    labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                    onClick={() => {
                      if (lcyFormRef.current?.handleSubmit) {
                        lcyFormRef.current?.handleSubmit();
                      }
                    }}
                    size={SizeType.Medium}
                    disabled={loading}
                  />
                ) : (
                  <div />
                )} */}
              </>
            )}
            <FileControls
              nothing={isMinusTransferLcyType}
              onFavoritesClick={openFavorites}
              isMinusTransferLcyType={isMinusTransferLcyType}
              onPrintClick={() => {
                if (isLcyTransferType && isStandardTransferLcyType) {
                  printForm({
                    printable: "printJS-standard-form",
                    type: "html",
                    targetStyles: ["*"],
                  });
                } else if (isLcyTransferType && isBudgetTransferLcyType) {
                  const budget = {
                    ...budgetInitialValues,
                    clientAccount: budgetInitialValues.clientAccount.value,
                    documentDate: formatDateToBody(budgetInitialValues.documentDate),
                  };
                  printTransferLCYTable({
                    // @ts-ignore
                    budgetList: [budget],
                    standardList: [],
                    revenueList: [],
                  });
                } else if (isLcyTransferType && isBudgetRevenueTransferLcyType) {
                  const revenue = {
                    ...budgetRevenueInitialValues,
                    clientAccount: budgetRevenueInitialValues.clientAccount.value,
                    documentDate: formatDateToBody(budgetRevenueInitialValues.documentDate),
                  };
                  printTransferLCYTable({
                    budgetList: [],
                    standardList: [],
                    // @ts-ignore
                    revenueList: [revenue],
                  });
                }
              }}
              onSaveToPdfClick={() => {
                const data = {
                  ...initials,
                  documentDate: formatDateToBody(initials.documentDate),
                  clientName: initials?.senderName,
                  clientBankName: initials?.senderBankName,
                  clientTaxNumber: initials?.senderTaxId,
                  senderMFO: initials?.senderBankCode,
                  correspondentMFO: initials?.correspondentBank,
                  correspondentTaxId: initials?.correspondentTaxNumber,
                  budgetTaxId: initials?.budgetTaxNumber,
                  budgetName: initials?.budgetAccountName,
                  paymentDetail: initials?.detailsOfPayment,
                  // @ts-ignore
                  senderAccount: initials?.clientAccount?.label,
                  paymentDetails: initials?.detailsOfPayment,
                  // @ts-ignore
                  clientAccount: initials?.clientAccount?.label,
                  detailsOfPayment: initials?.detailsOfPayment.includes("<")
                    ? initials?.detailsOfPayment.replace("<", "&#x276E;")
                    : initials?.detailsOfPayment,
                };
                const pdfReport = [data];
                if ((isLcyTransferType && isStandardTransferLcyType) || isAnorTransferLcyType) {
                  PdfReportApi.createStandardPdfReport("standard-report", pdfReport, login);
                } else if (
                  (isLcyTransferType && isBudgetTransferLcyType) ||
                  isAnorBudgetTransferLcyType
                ) {
                  PdfReportApi.createBudgetPdfReport("budget-report", pdfReport, login);
                } else if (
                  (isLcyTransferType && isBudgetRevenueTransferLcyType) ||
                  isAnorBudgetRevenueTransferLcyType
                ) {
                  PdfReportApi.createRevenuePdfReport("revenue-report", pdfReport, login);
                }
              }}
              onSaveToExcelClick={() => {
                const data = {
                  ...initials,
                  documentDate: formatDateToBody(initials.documentDate),
                  clientName: initials.senderName,
                  clientBankName: initials.senderBankName,
                  clientTaxNumber: initials.senderTaxId,
                  senderMfo: initials.senderBankCode,
                  receiverMfo: initials.correspondentBank,
                  paymentDetail: initials.detailsOfPayment,
                  // @ts-ignore
                  clientAccount: initials?.clientAccount?.label,
                  budgetName: initials?.budgetAccountName,
                  currencyCode: CurrencyCode.UzbekistanSum,
                };
                if (isLcyTransferType && isStandardTransferLcyType) {
                  const workbook = createTransferLCYExcelFromForm(data, {
                    translate,
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  });
                  saveExcelToFile(workbook, "LCY Standard Outgoing Transfers").catch(showError);
                } else if (isLcyTransferType && isBudgetTransferLcyType) {
                  const workbook = createTransferLCYBudgetExcelFromForm(data, {
                    translate,
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  });
                  saveExcelToFile(workbook, "LCY Budget Outgoing Transfers").catch(showError);
                } else if (isLcyTransferType && isBudgetRevenueTransferLcyType) {
                  const workbook = createTransferLCYRevenueExcelFromForm(data, {
                    translate,
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  });
                  saveExcelToFile(workbook, "LCY Revenue Outgoing Transfers").catch(showError);
                }
              }}
              className={cx({ "mr-5": isUnselectType })}
              onSaveToFavoriteClick={() => {
                setFavouritesNameModal(true);
              }}
            />
            {isUnselectType && <Paginator filter={filter} />}
          </div>
        }
      >
        <>
          <div style={{ display: "none" }}>
            <TransferLcyStandartPrint ref={ref} initialValue={standardInitialValues} />
          </div>
          {Boolean(isLcyTransferType && isStandardTransferLcyType) && (
            <LCYStandardTransferFormWrapper
              onSubmit={onLcySubmit}
              formikRef={lcyFormRefHandler}
              initialValue={standardInitialValues}
              setinitialValues={setStandardInitialValues}
            />
          )}
          <div style={{ display: "none" }}>
            <TransferLcyBudgetPrint ref={ref} initialValue={budgetInitialValues} />
          </div>
          {Boolean(isLcyTransferType && isBudgetTransferLcyType) && (
            <LCYBudgetTransferFormWrapper
              onSubmit={onLcySubmit}
              formikRef={lcyFormRefHandler}
              initialValue={budgetInitialValues}
              setInitialValues={setBudgetInitialValues}
            />
          )}
          <div style={{ display: "none" }}>
            <TransferLcyRevenuePrint ref={ref} initialValue={budgetRevenueInitialValues} />
          </div>
          {Boolean(isLcyTransferType && isBudgetRevenueTransferLcyType) && (
            <LCYBudgetRevenueTransferFormWrapper
              onSubmit={onLcySubmit}
              formikRef={lcyFormRefHandler}
              setinitialValues={setBudgetRevenueInitialValues}
              initialValue={budgetRevenueInitialValues}
            />
          )}
        </>
        {/* )} */}
        {Boolean(isLcyTransferType && isAnorBudgetTransferLcyType) && (
          <LCYBudgetTransferFormWrapper
            onSubmit={onLcySubmit}
            formikRef={lcyFormRefHandler}
            initialValue={anorBudgetInitialValues}
            setInitialValues={setAnorBudgetInitialValues}
          />
        )}
        {Boolean(isLcyTransferType && isAnorBudgetRevenueTransferLcyType) && (
          <LCYBudgetRevenueTransferFormWrapper
            onSubmit={onLcySubmit}
            formikRef={lcyFormRefHandler}
            setinitialValues={setAnorBudgetRevenueInitialValues}
            initialValue={anorBudgetRevenueInitialValues}
          />
        )}
        {Boolean(isLcyTransferType && isMinusTransferLcyType) && (
          <LCYMunisTransferFormWrapper
            setinitialValues={setMunisInitialValues}
            initialValue={munisInitialValues}
            onSubmit={onLcySubmit}
            formikRef={lcyFormRefHandler}
          />
        )}
        {Boolean(isLcyTransferType && isAnorTransferLcyType) && (
          <LCYStandardTransferFormWrapper
            formikRef={lcyFormRefHandler}
            onSubmit={onLcySubmit}
            setinitialValues={setAnorStandartInitialValues}
            initialValue={anorStandartInitialValues}
          />
        )}
      </TabPage>
      <Modal
        searchInput={true}
        onClose={() => setLcyFavourites(false)}
        width={1200}
        show={lcyFavourites}
        title={"TRANSFERS_FAVOURITES_MODAL_TAB_TITLE"}
        setSearch={(value) => setSearchFav(value)}
      >
        <TabPage>
          <FavouritesModalTab getFavorites={getFavorites} data={beneficiaryListAfterSearch} />
        </TabPage>
      </Modal>

      <Modal
        show={favouritesNameModal}
        onClose={() => setFavouritesNameModal(false)}
        title={"TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL"}
      >
        <div className="py-2">
          <Input
            type="text"
            onChange={(e) => {
              setFavouritesName(e.target.value);
            }}
          />
          <div className="mt-3 d-flex justify-content-center ">
            <Button onClick={() => setFavouritesNameModal(false)} className="bg-danger  m-1">
              {translate("CONVERSION_FCY_TO_UZS_FCY_TO_CANCEL_BUTTON")}
            </Button>
            <Button
              onClick={() => {
                if (favouritesName.trim()) {
                  const json = {
                    ...initials,
                    budgetAccountName:
                      initials.budgetAccountName !== "" &&
                      initials.budgetAccountName?.substr(0, 70),
                    documentDate: formatDateToBody(initials.documentDate),
                    // @ts-ignore
                    clientAccount: initials.clientAccount.label,
                    type:
                      isStandardTransferLcyType || isAnorTransferLcyType
                        ? "standardTransfer"
                        : isBudgetTransferLcyType || isAnorBudgetTransferLcyType
                        ? "budgetTransfer"
                        : isBudgetRevenueTransferLcyType || isAnorBudgetRevenueTransferLcyType
                        ? "revenueTransfer"
                        : "",
                    docType:
                      isStandardTransferLcyType || isAnorTransferLcyType
                        ? "Standard Transfer"
                        : isBudgetTransferLcyType || isAnorBudgetTransferLcyType
                        ? "Budget Transfer"
                        : isBudgetRevenueTransferLcyType || isAnorBudgetRevenueTransferLcyType
                        ? "Revenue Transfer"
                        : "",
                    uniqueId: `${initials.correspondentAccount} ${initials.detailsOfPayment}`,
                    currency: "UZS",
                    favoriteName: favouritesName,
                  };
                  TransferApi.createLcyFavorites({ json, login })
                    .catch(showError)
                    .then((r) => {
                      if (r !== undefined) {
                        NotificationManager.info(
                          translate("CONVERSION_FAVORITES_INFO_SAVED"),
                          translate("CONVERSION_FAVORITES_ERROR_TITLE"),
                          7000,
                        );
                      }
                    });
                } else {
                  NotificationManager.error(
                    `${translate("TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL")} ${translate(
                      "FORMS_REQUIRED_FIELD_ERROR_TITLE",
                    )}`,
                  );
                }
              }}
              className=" m-1"
            >
              {translate("CONVERSION_FCY_TO_UZS_FCY_TO_SAVE_BUTTON")}
            </Button>
          </div>
        </div>
      </Modal>
      <PrintPortal>
        <TransferLcyBudgetTablePrint />
        <TransferLcyRevenueTablePrint />
      </PrintPortal>
    </>
  );
}
