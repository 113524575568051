import { useEffect } from "react";
// @ts-ignore
import { createPopper } from "@popperjs/core";
// @ts-ignore
import { Modifier, OptionsGeneric } from "@popperjs/core/lib/types";

interface Props {
  readonly buttonId: string;
  readonly tooltipId: string;
  readonly options?: Partial<OptionsGeneric<Partial<Modifier<any, any>>>>;
}

export function Tooltip({ tooltipId, buttonId, options }: Props) {
  useEffect(() => {
    const button = document.querySelector<any>(`#${buttonId}`);
    const tooltip = document.querySelector<any>(`#${tooltipId}`);

    if (button && tooltip) {
      createPopper(button, tooltip, options);
    }
  }, [tooltipId, buttonId, options]);

  return null;
}
