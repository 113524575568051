import React from "react";
import { noop } from "lodash";
import { Form, Formik } from "formik";

import { InputField } from "../form/InputField";
import { DatePickerField } from "../form/DatePickerField";
import { GroupBox } from "../ui/GroupBox";

export function CorporateCardOpeningUzCardForm() {
  return (
    <Formik initialValues={{}} onSubmit={noop}>
      {() => (
        <Form className="d-flex flex-column br-1 p-4 bg-white">
          <GroupBox className="h-100" title="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PAGE_TITLE">
            <div className="row">
              <div className="w-100 d-flex flex-wrap mb-3">
                <InputField
                  name="firstName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_FIRSTNAME_FIELD_TITLE"
                />
                <InputField
                  name="lastName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_LASTNAME_FIELD_TITLE"
                />
                <InputField
                  name="middleName"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_MIDDLENAME_FIELD_TITLE"
                />
              </div>
              <div className="w-100 d-flex flex-wrap mb-3">
                <div className="col-4 d-flex pl-0">
                  <InputField
                    name="passportSeries"
                    className="col-4"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSPORT_SERIES_FIELD_TITLE"
                  />

                  <InputField
                    name="number"
                    className="flex-fill"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE"
                  />
                </div>
                <InputField
                  name="placeOfIssue"
                  className="col-4"
                  label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_ISSUE_FIELD_TITLE"
                />
                <div className="col-4 d-flex pl-0 mb-3">
                  <DatePickerField
                    name="dateOfIssue"
                    className="col-6"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_ISSUE_FIELD_TITLE"
                  />
                  <DatePickerField
                    name="passportValidity"
                    className="col-6"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VALIDITY_TITLE"
                  />
                </div>
                <div className="w-100 d-flex flex-wrap mb-3">
                  <InputField
                    name="registerAddress"
                    className="col-4"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_REGISTERED_ADDRESS_TITLE"
                    disabled={true}
                  />
                  <InputField
                    name="placeOfResidence"
                    className="col-4"
                    label="ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_RECIDENCE_TITLE"
                    disabled={true}
                  />
                  <InputField
                    name="positionHeld"
                    className="col-4"
                    label="ACCOUNT_OPENING_POSITION_HELD"
                  />
                </div>
              </div>
            </div>
          </GroupBox>
        </Form>
      )}
    </Formik>
  );
}
