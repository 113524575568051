import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";

import { Button, ButtonColor } from "../ui/Button";
import { InputField } from "../form/InputField";
import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { SelectPickerField } from "../form/SelectPickerField";
import { CreateNewDocumentRoles, SizeType, ViewMode } from "../../api/dto/AppDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { useResource } from "../../hooks/useResource";
import { DatePickerField } from "../form/DatePickerField";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userFullNameSelector } from "../../reducers/userReducer";
import { InquirySubmitType } from "../../filters/AccountsFilter";
import { ApprovalInquiryType } from "../../api/account/AccountDTO";
import { object, string } from "yup";
import "./assets/account-inquiry.scss";
import { showError } from "../../utils/NotificationUtils";
import { NotificationManager } from "react-notifications";
// import { TextAreaField } from "../form/TextAreaField";
import { viewModeSelector } from "../../reducers/appReducer";
import { createNewDocumentRole } from "../../reducers/authReducer";
import { IndividualSMSModal } from "./IndividualSMSModal";
import { InquiryTextAreaField } from "../form/InquiryTextAreaField";

interface Props {
  readonly onChangeTab: (tab: InquirySubmitType) => void;
  readonly individual?: boolean;
}

const validationSchema = object({
  registrationNumber: string()
    .required("Required")
    .max(100, "Registration Number should be less than 100 digits"),
  subject: string().required("Required"),
  // .max(5000, "Subject should be less than 5000 digits"),
  message: string()
    .required("Required")
    .max(5000, "Message should be less than 5000 digits")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([а-яА-ЯёЁa-zA-ZЎўӨөҲҳҚқҒғO’0-9\s!"“”#$%&'()*+,-.(\/):;<=>?@[\]^_`\\{|}~–«№»]*)$/g,
      "Used prohibited letter or symbol",
    )
    // .matches(/^[a-zA-Zа-яА-ЯёЁ]*$/g, "In the field you can use only Cyrillic or Latin alphabet")
    .min(1),
  branchCode: string().required("Required"),
});

export function InquiryNonStandardForm({ onChangeTab, individual = false }: Props) {
  const { translate } = useI18n();
  const { AccountApi } = useAccountContext();
  const { language } = useI18n();
  const [branch, setBranch] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");
  const [values, setValues] = useState({} as any);
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const lcyFormRef = useRef<any>(null);
  const lcyFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        lcyFormRef.current = instance;
      }
    },
    [lcyFormRef],
  );

  const onSubmit = useCallback(
    (value) => {
      setLoading(true);
      if (individual) {
        setValues({
          ...value,
          branchCode: value.branchCode.value,
          inquiryType: ApprovalInquiryType.NonStandard,
        });
        AccountApi.sentSMS()
          .then((r) => {
            setOpen(true);
            setCode(r);
            setLoading(false);
          })
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      } else {
        setLoading(true);
        const values = {
          ...value,
          branchCode: value.branchCode.value,
          inquiryType: ApprovalInquiryType.NonStandard,
        };
        AccountApi.createNonStandardInquiry(values)
          .then(() => {
            onChangeTab(InquirySubmitType.NonStandart);
            setLoading(false);
            NotificationManager.info(
              translate("ACCOUNT_REQUEST_SENT"),
              translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE"),
              5000,
            );
          })
          .catch((r) => {
            showError(r);
            setLoading(false);
          });
      }
    },
    [AccountApi, onChangeTab, translate, individual],
  );

  const onSubmitSMS = useCallback(
    (value) => {
      AccountApi.createInquiryIndividual({
        smsCode: value.smsCode,
        messageId: code,
        json: values,
      }).then(() => {
        setOpen(false);
        onChangeTab(InquirySubmitType.NonStandart);
      });
    },
    [values, AccountApi, code, onChangeTab],
  );

  useResource(
    () =>
      AccountApi.getBranchesInformation().then((r) => {
        setBranch(
          r.map((x) => ({
            label:
              language === AppLanguage.Russian
                ? x.nameRU
                : language === AppLanguage.English
                ? x.nameENG
                : x.nameUZ,
            value: x.branchCode,
          })),
        );
      }),
    [],
  );
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);

  return (
    <>
      <Formik
        initialValues={{
          registrationNumber: "",
          dateOfRequest: new Date(),
          requestingCompany: fullName,
          branchCode: "",
          subject: "",
          message: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={lcyFormRefHandler}
      >
        {({ handleSubmit }) => (
          <Form className="row pl-7 pt-7 w-100 bg-white" onSubmit={handleSubmit}>
            <div className="col-lg-5">
              <h4 className="mb-6 font-weight-bold">
                {translate("ACCOUNT_INQUIRY_NON_STANDARD_TITLE")}
              </h4>

              <InputField
                className="mb-2"
                name="registrationNumber"
                label="ACCOUNT_INQUIRY_NON_STANDARD_CLIENT_REGISTRATION_NUMBER_FIELD_LABEL_TITLE"
              />
              <DatePickerField
                className="mb-2"
                name="dateOfRequest"
                label="ACCOUNT_INQUIRY_NON_STANDARD_DATE_OF_REQUEST_FIELD_LABEL_TITLE"
                disabled={true}
              />
              <InputField
                className="mb-2"
                name="requestingCompany"
                label="ACCOUNT_INQUIRY_NON_STANDARD_REQUESTING_COMPANY_FIELD_LABEL_TITLE"
                disabled={true}
              />
              <SelectPickerField
                className="mb-2"
                name="branchCode"
                // @ts-ignore
                options={branch}
                label="ACCOUNT_INQUIRY_NON_STANDARD_PREPARING_BRANCH_FIELD_LABEL_TITLE"
              />
              <InputField
                className="mb-2"
                name="subject"
                label="ACCOUNT_INQUIRY_NON_STANDARD_SUBJECT_FIELD_LABEL_TITLE"
                maxLength={5000}
              />
              <InquiryTextAreaField
                data-testid="nostandart-message"
                className="mb-5"
                name="message"
                label="ACCOUNT_INQUIRY_NON_STANDARD_MESSAGE_TEXT_FIELD_LABEL_TITLE"
                height={200}
              />
            </div>
          </Form>
        )}
      </Formik>
      {individual ? (
        <div>
          <Button
            type="submit"
            className="ml-7 mb-4"
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            disabled={loading}
            onClick={() => {
              if (lcyFormRef.current?.handleSubmit) {
                lcyFormRef.current?.handleSubmit();
              }
            }}
          >
            {translate("ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE")}
          </Button>
        </div>
      ) : (
        <div>
          {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
            <Button
              type="submit"
              className="ml-7 mb-4"
              size={SizeType.Medium}
              color={ButtonColor.Orange}
              disabled={loading}
              onClick={() => {
                if (lcyFormRef.current?.handleSubmit) {
                  lcyFormRef.current?.handleSubmit();
                }
              }}
            >
              {translate("ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE")}
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
      <IndividualSMSModal open={open} setOpen={setOpen} onSubmit={onSubmitSMS} loading={loading} />
    </>
  );
}
