import "./assets/tabs.scss";

import cx from "classnames";
import { uid } from "react-uid";
import React, { Children, CSSProperties, ReactElement, ReactNode, useMemo, useState } from "react";

import { TabItem } from "./TabItem";
import { TabPaneProps } from "./TabPane";
import { useI18n } from "../../i18n/I18nContext";
import { useTheme } from "../../theme/ThemeContext";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

export interface TabsProps {
  readonly fluidTabs?: boolean;

  readonly tabBarHeight?: number;
  readonly headerHeight?: number;
  readonly footerHeight?: number;

  readonly headerActionsComponent?: ReactNode;
  readonly footerActionsComponent?: ReactNode;

  readonly tabsColor?: string;
  readonly activeTab?: string;
  readonly defaultTab: string;
  readonly stickTabs?: boolean;
  readonly authTabBg?: boolean;
  readonly whiteBorder?: boolean;
  readonly textColor?: boolean;
  readonly style?: CSSProperties;
  readonly onChangeTab?: (tab: any) => void;
  readonly children: ReactElement<TabPaneProps>[] | ReactElement<TabPaneProps>;

  readonly withTabHeader?: boolean;

  readonly className?: string;
  readonly tabClassName?: string;
  readonly tabBarClassName?: string;
  readonly contentClassName?: string;
  readonly containerClassName?: string;
  readonly subTabs?: boolean;
  readonly hover?: boolean;
}

export function Tabs({
  style,
  children,
  activeTab,
  authTabBg,
  whiteBorder,
  tabsColor,
  className,
  stickTabs,
  fluidTabs,
  textColor,
  defaultTab,
  onChangeTab,
  tabBarHeight = 40,
  headerHeight = 80,
  footerHeight = 90,
  tabClassName,
  withTabHeader,
  tabBarClassName,
  contentClassName,
  containerClassName,
  headerActionsComponent,
  footerActionsComponent,
  subTabs,
  hover,
}: TabsProps) {
  const { translate } = useI18n();

  const [active, setActive] = useState<string>(defaultTab);

  const isMobile = CheckMobileUtils();

  const isOutsideControl = useMemo(() => Boolean(activeTab && onChangeTab), [
    activeTab,
    onChangeTab,
  ]);

  const { ColorPalette } = useTheme();

  return (
    <div
      className={cx("d-flex  w-100 flex-column tabs-ui", `${!isMobile ? className : ""}`, {
        "flex-1": tabClassName !== "auth-tabs",
      })}
      style={style}
    >
      {(tabClassName == "auth-tabs" || !isMobile) && (
        <div
          style={{ height: `${tabBarHeight}px` }}
          className={cx(
            "d-flex border-bottom border-blue-gray-extra-light auth-small-tab",
            {
              "px-4": !stickTabs,
              "px-7": tabClassName == "auth-tabs" && isMobile != "480",
            },
            tabBarClassName,
          )}
        >
          {Children.map(children, (child) => {
            if (child.type === "div") {
              return null;
            }
            const currentTab = isOutsideControl ? activeTab === child.key : active === child.key;

            const { showTab = true } = child.props;

            if (!showTab) {
              return null;
            }

            return (
              <TabItem
                hover={hover}
                key={uid(child)}
                color={
                  authTabBg
                    ? (tabsColor = ColorPalette.Transparent)
                    : subTabs
                    ? (tabsColor = ColorPalette.White)
                    : (tabsColor = ColorPalette.BlueGrayLighten)
                }
                fluid={fluidTabs}
                background={authTabBg}
                textColor={textColor}
                active={currentTab}
                border={whiteBorder}
                className={tabClassName}
                badge={child.props.badge}
                withHeader={withTabHeader}
                title={translate(child.props.tab)}
                onClick={() => {
                  if (isOutsideControl) {
                    onChangeTab!(child.key as string);
                  } else {
                    setActive(child.key as string);
                  }
                }}
              />
            );
          })}
        </div>
      )}

      <div
        style={{
          backgroundColor:
            isMobile && tabClassName != "auth-tabs" ? ColorPalette.BlueGrayLighten : tabsColor,
        }}
        className={cx("d-flex flex-shrink-1 flex-grow-1 flex-column", containerClassName)}
      >
        {Boolean(headerActionsComponent) && (
          <div className="d-flex" style={{ height: `${headerHeight}px` }}>
            {headerActionsComponent}
          </div>
        )}

        <div className={cx("d-flex flex-column flex-shrink-1 flex-grow-1", contentClassName)}>
          {Children.map(children, (child) => {
            const currentTab = isOutsideControl ? activeTab === child.key : active === child.key;

            if (currentTab && child.props.scrollableContent) {
              return (
                <div
                  className={cx(
                    "d-flex flex-column flex-shrink-1 flex-grow-1",
                    {
                      "overflow-auto scrollable-content": child.props.scrollableContent,
                    },
                    contentClassName,
                  )}
                >
                  {child.props.children}
                </div>
              );
            }

            if (currentTab) {
              return child.props.children;
            }

            return null;
          })}
        </div>

        {Boolean(footerActionsComponent) && (
          <div className="d-flex" style={{ height: `${footerHeight}px` }}>
            {footerActionsComponent}
          </div>
        )}
      </div>
    </div>
  );
}
