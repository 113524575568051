import React from "react";

import { Checkbox } from "../ui/Checkbox";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { Form, Formik } from "formik";
import { useI18n } from "../../i18n/I18nContext";
import { TransfersFCYTabControls } from "./TransfersFCYTabControls";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { DatePickerField } from "../form/DatePickerField";
import { SelectPickerField } from "../form/SelectPickerField";
import "./assets/transfers-fcy-tab-header.scss";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

interface Props {
  readonly filter: TransfersFilter;

  readonly onCreateFcyClick: () => void;
  readonly onChangeFilter: (value) => void;
  readonly onSetShowCreators: () => void;
}

export function TransfersFCYTabHeader({
  onCreateFcyClick,
  filter,
  onChangeFilter,
  onSetShowCreators,
}: Props) {
  const { translate } = useI18n();
  const isMobile = CheckMobileUtils();
  const statuses = [
    { label: `${translate("ACCOUNT_ALL_ACCOUNTS")}`, value: "A" },
    {
      label: `${translate("PAYROLL_STATUS_CREATED")}`,
      value: "101",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1")}`,
      value: "102",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2")}`,
      value: "103",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3")}`,
      value: "104",
    },
    {
      label: `${translate("PAYROLL_STATUS_APPROVED")}`,
      value: "1",
    },
    {
      label: `${translate("PAYROLL_STATUS_PROCESSING")}`,
      value: "2",
    },
    {
      label: `${translate("PAYROLL_STATUS_SENT_TO_BANK")}`,
      value: "5",
    },
    {
      label: `${translate("PAYROLL_STATUS_REJECTED")}`,
      value: "106",
    },
    {
      label: `${translate("PAYROLL_STATUS_REJECTED_BY_CB")}`,
      value: "14",
    },
    {
      label: `${translate("TRANSFER_STATUS_SENT_BY_BANK")}`,
      value: "3",
    },
  ];

  return (
    <div className="d-flex flex-column">

      <div className="transfersFCYHeader flex-lg-row flex-column  align-items-start align-items-lg-center">

        <div className="order-lg-0 order-2">
          <TransfersFCYTabControls onCreateFcyClick={onCreateFcyClick}/>
        </div>
        
        <div className="transfersFCYHeader_time order-lg-0 order-1 mt-lg-0 mt-3">
          <span>{translate("TRANSFERS_TRANSFERS_FCY_ON_BUSINESS_DAYS")}</span>
          <span>{translate("TRANSFERS_TRANSFERS_FCY_ON_SHORTENED_BUSINESS_DAYS")}</span>
        </div>

      </div>
      
      <h5 className="text-center mb-6 font-weight-bold">
        {translate("TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_TITLE")}
      </h5>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <Formik onSubmit={onChangeFilter} initialValues={filter.getFcyFilterForPage()}>
          {({ handleSubmit, values }) => (
            <Form>
              <div className="d-flex justify-content-between flex-lg-row flex-column">
                <div className="d-flex">
                  <DatePickerField
                    width={100}
                    name="fromDate"
                    className="pr-5"
                    size={SizeType.Small}
                    selectsStart={true}
                    labelPosition={PositionType.Left}
                    label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                    onChange={() => handleSubmit()}
                    maxDate={values.toDate}
                  />
                  <DatePickerField
                    width={100}
                    name="toDate"
                    className="pr-5"
                    size={SizeType.Small}
                    selectsEnd={true}
                    labelPosition={PositionType.Left}
                    label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                    onChange={() => handleSubmit()}
                    minDate={values.fromDate}
                  />
                </div>
                <div
                  className={` ${
                    !isMobile ? "position-absolute" : ""
                  } d-flex status-container flex-lg-row flex-column`}
                >
                  <div className="d-flex align-items-center my-lg-0 my-3">
                    <span className="mr-2">
                      {translate("TRANSFERS_TRANSFERS_FIND_DOCUMENT_WITH_STATUS_LABEL_TITLE")}
                    </span>
                    <SelectPickerField
                      minWidth={250}
                      name="status"
                      onChanges={() => handleSubmit()}
                      options={statuses}
                      size={SizeType.Small}
                      defaultValue={statuses[0]}
                    />
                  </div>
                  <div className="pl-lg-7 pt-2">
                    <Checkbox
                      name="showCreators"
                      onChange={() => onSetShowCreators()}
                      label="TRANSFERS_TRANSFERS_SHOW_CREATORS_AND_APPROVER_LABEL_TITLE"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
