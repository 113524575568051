import React, { useCallback, useMemo } from "react";
import { AccountsTable } from "./AccountsTable";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: PendingApprovalsFilter;
  readonly withCreatorsAndApprovals?: boolean;
  readonly setDocumentsId?: (value, appType) => void;
}

export function AccountTableWrapper({ filter, withCreatorsAndApprovals, setDocumentsId }: Props) {
  const { AccountApi } = useAccountContext();
  const dispatch = useDispatch();
  const getAccountForApprovalList = useResource(
    () =>
      AccountApi.getAccountInquiryForApprovalList({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );
  const data = useMemo(() => getAccountForApprovalList.data || [], [
    getAccountForApprovalList.data,
  ]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId && setDocumentsId(list, ApprovalTypes.Accounts);
    },
    [data, setDocumentsId],
  );

  useResourceHandler(getAccountForApprovalList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  return (
    <AccountsTable
      data={data}
      withCreatorsAndApprovals={withCreatorsAndApprovals}
      setDocumentId={setDocumentId}
    />
  );
}
