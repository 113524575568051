import React, { useMemo } from "react";

import { useResource } from "../../hooks/useResource";
import { CorrespondentsTab } from "./CorrespondentsTab";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useTransferContext } from "../../api/transfer/TransferContext";

export function CorrespondentsTabWrapper() {
  const { TransferApi } = useTransferContext();

  const correspondentsListResource = useResource(() => TransferApi.correspondentList(), []);

  useResourceHandler(correspondentsListResource);

  const list = useMemo(() => correspondentsListResource.data || [], [
    correspondentsListResource.data,
  ]);

  return <CorrespondentsTab list={list} loading={correspondentsListResource.loading} />;
}
