import { Action } from "redux";
import { DELETE, update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import { CreateNewDocumentRoles } from "../api/dto/AppDTO";

export const authReducerPersistConfig: Partial<PersistConfig<AuthReducerState>> = {
  whitelist: [
    "token",
    "authDate",
    "createNewDocumentRole",
    "accessToAccountManagement",
    "accessToCardReplenishment",
    "accessToConversion",
    "accessToFcyTransfer",
    "accessToLcyTransfer",
    "flagToHuge",
  ],
};

interface SetTokenMeta {
  readonly token: string;
}

interface SetAuthDateMeta {
  readonly authDate: number;
}

interface SetAuthDateExpiredMeta {
  readonly state: boolean;
}

interface ChangeCreateNewDocumentRoleMeta {
  readonly createNewDocumentRole: CreateNewDocumentRoles;
}

interface ChangeAccessToAccountManagementMeta {
  readonly accessToAccountManagement: CreateNewDocumentRoles;
}

interface ChangeAccessToCardReplenishmentMeta {
  readonly accessToCardReplenishment: CreateNewDocumentRoles;
}

interface ChangeAccessToConversionMeta {
  readonly accessToConversion: CreateNewDocumentRoles;
}

interface ChangeAccessToFcyTransferMeta {
  readonly accessToFcyTransfer: CreateNewDocumentRoles;
}

interface ChangeAccessToLcyTransferMeta {
  readonly accessToLcyTransfer: CreateNewDocumentRoles;
}

interface ChangeFlagToHuge {
  readonly flagToHuge: CreateNewDocumentRoles;
}

enum ReducerActions {
  SetToken = "Auth/SetToken",
  ResetToken = "Auth/ResetToken",
  SetAuthDate = "Auth/SetAuthDate",
  SetAuthDateExpired = "Auth/SetAuthDateExpired",
  ChangeCreateNewDocumentRole = "App/ChangerCreateNewDocumentRole",
  ChangeAccessToAccountManagement = "App/ChangerAccessToAccountManagement",
  ChangeAccessToCardReplenishment = "App/ChangerAccessToCardReplenishment",
  ChangeAccessToConversion = "App/ChangerAccessToConversion",
  ChangeAccessToFcyTransfer = "App/ChangerAccessToFcyTransfer",
  ChangeAccessToLcyTransfer = "App/ChangerAccessToLcyTransfer",
  ChangeFlagToHuge = "App/ChangerFlagAccessToHuge",
}

export interface AuthReducerState {
  readonly token?: string;
  readonly authDate?: number;
  readonly authDateExpired: boolean;
  readonly createNewDocumentRole: CreateNewDocumentRoles;
  readonly accessToAccountManagement: CreateNewDocumentRoles;
  readonly accessToCardReplenishment: CreateNewDocumentRoles;
  readonly accessToConversion: CreateNewDocumentRoles;
  readonly accessToFcyTransfer: CreateNewDocumentRoles;
  readonly accessToLcyTransfer: CreateNewDocumentRoles;
  readonly flagToHuge: CreateNewDocumentRoles;
}

function getState(): AuthReducerState {
  return {
    authDateExpired: false,
    createNewDocumentRole: CreateNewDocumentRoles.No,
    accessToAccountManagement: CreateNewDocumentRoles.No,
    accessToCardReplenishment: CreateNewDocumentRoles.No,
    accessToConversion: CreateNewDocumentRoles.No,
    accessToFcyTransfer: CreateNewDocumentRoles.No,
    accessToLcyTransfer: CreateNewDocumentRoles.No,
    flagToHuge: CreateNewDocumentRoles.No,
  };
}

export const authReducer = createRootReducer<AuthReducerState>(
  getState(),

  createReducer([ReducerActions.SetToken], (state, { meta }) =>
    update(state, { token: meta.token, authDate: Date.now() }),
  ),

  createReducer([ReducerActions.ResetToken], (state) =>
    update(state, { token: DELETE, authDate: DELETE }),
  ),

  createReducer([ReducerActions.SetAuthDate], (state, { meta }) =>
    update(state, { authDate: meta.authDate }),
  ),

  createReducer([ReducerActions.SetAuthDateExpired], (state, { meta }) =>
    update(state, { authDateExpired: meta.state }),
  ),

  createReducer([ReducerActions.ChangeCreateNewDocumentRole], (state, { meta }) =>
    update(state, { createNewDocumentRole: meta.createNewDocumentRole }),
  ),

  createReducer([ReducerActions.ChangeAccessToAccountManagement], (state, { meta }) =>
    update(state, { accessToAccountManagement: meta.accessToAccountManagement }),
  ),

  createReducer([ReducerActions.ChangeAccessToCardReplenishment], (state, { meta }) =>
    update(state, { accessToCardReplenishment: meta.accessToCardReplenishment }),
  ),

  createReducer([ReducerActions.ChangeAccessToConversion], (state, { meta }) =>
    update(state, { accessToConversion: meta.accessToConversion }),
  ),

  createReducer([ReducerActions.ChangeAccessToFcyTransfer], (state, { meta }) =>
    update(state, { accessToFcyTransfer: meta.accessToFcyTransfer }),
  ),

  createReducer([ReducerActions.ChangeAccessToLcyTransfer], (state, { meta }) =>
    update(state, { accessToLcyTransfer: meta.accessToLcyTransfer }),
  ),

  createReducer([ReducerActions.ChangeFlagToHuge], (state, { meta }) =>
    update(state, { flagToHuge: meta.flagToHuge }),
  ),
);

// ==================
// Selectors
// ==================

export function tokenSelector(state: AppStoreState): string | undefined {
  return state.auth.token;
}

export function authDateSelector(state: AppStoreState): number | undefined {
  return state.auth.authDate;
}

export const authDateExpiredSelector = ({ auth }: AppStoreState): boolean => auth.authDateExpired;

export const createNewDocumentRole = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.createNewDocumentRole;

export const accessToAccountManagement = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.accessToAccountManagement;

export const accessToCardReplenishment = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.accessToCardReplenishment;

export const accessToConversion = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.accessToConversion;

export const accessToLcyTransfer = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.accessToLcyTransfer;

export const accessToFcyTransfer = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.accessToFcyTransfer;

export const flagToHugeTurnover = ({ auth }: AppStoreState): CreateNewDocumentRoles =>
  auth.flagToHuge;

// ==================
// Actions
// ==================

export function setToken(meta: SetTokenMeta): PerformAction<SetTokenMeta> {
  return { meta, type: ReducerActions.SetToken };
}

export function resetToken(): Action {
  return { type: ReducerActions.ResetToken };
}

export function setAuthDate(meta: SetAuthDateMeta): PerformAction<SetAuthDateMeta> {
  return { meta, type: ReducerActions.SetAuthDate };
}

export function setAuthDateExpired(
  meta: SetAuthDateExpiredMeta,
): PerformAction<SetAuthDateExpiredMeta> {
  return { meta, type: ReducerActions.SetAuthDateExpired };
}

export function changeCreateNewDocumentRole(
  meta: ChangeCreateNewDocumentRoleMeta,
): PerformAction<ChangeCreateNewDocumentRoleMeta> {
  return { meta, type: ReducerActions.ChangeCreateNewDocumentRole };
}

export function ChangeAccessToAccountManagement(
  meta: ChangeAccessToAccountManagementMeta,
): PerformAction<ChangeAccessToAccountManagementMeta> {
  return { meta, type: ReducerActions.ChangeAccessToAccountManagement };
}

export function ChangeAccessToCardReplenishment(
  meta: ChangeAccessToCardReplenishmentMeta,
): PerformAction<ChangeAccessToCardReplenishmentMeta> {
  return { meta, type: ReducerActions.ChangeAccessToCardReplenishment };
}

export function ChangeAccessToConversion(
  meta: ChangeAccessToConversionMeta,
): PerformAction<ChangeAccessToConversionMeta> {
  return { meta, type: ReducerActions.ChangeAccessToConversion };
}

export function ChangeAccessToLcyTransfer(
  meta: ChangeAccessToLcyTransferMeta,
): PerformAction<ChangeAccessToLcyTransferMeta> {
  return { meta, type: ReducerActions.ChangeAccessToLcyTransfer };
}

export function ChangeAccessToFcyTransfer(
  meta: ChangeAccessToFcyTransferMeta,
): PerformAction<ChangeAccessToFcyTransferMeta> {
  return { meta, type: ReducerActions.ChangeAccessToFcyTransfer };
}

export function ChangeFlagToHuge(meta: ChangeFlagToHuge): PerformAction<ChangeFlagToHuge> {
  return { meta, type: ReducerActions.ChangeFlagToHuge };
}
