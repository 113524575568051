import React, { useCallback, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Form, Formik } from "formik";

import { TabPage } from "../tabs/TabPage";
import { SubTabs } from "../tabs/SubTabs";
import { TabPane } from "../tabs/TabPane";
import { AccountsFilter, SwiftInnerTabType } from "../../filters/AccountsFilter";
import { SwiftTableWrapper } from "./SwiftTableWrapper";
import { FileControls } from "../ui/FileControls";
import { Paginator } from "../ui/Paginator";
import { DatePickerField } from "../form/DatePickerField";
import { PositionType, RoleType, SizeType } from "../../api/dto/AppDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { payrollListIdsSelector } from "../../reducers/payrollSelectedIds";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useI18n } from "../../i18n/I18nContext";
import { userLoginSelector, userRoleSelector } from "../../reducers/userReducer";
import { SwiftCoverTableWrapper } from "./SwiftCoverTableWrapper";
import CoverHeader, { CoverHeaderFormProps } from "./CoverHeader";
import { formatDateToFilter } from "../../utils/DateUtils";
import { showError } from "../../utils/NotificationUtils";
import { SwiftCoverTableDataProps } from "../../api/account/AccountDTO";
import { BeforeMonthDate } from "../../utils/BeforeMonthDate";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeTab: (tab: SwiftInnerTabType) => void;
  readonly onChangeFilter: (value) => void;
}

export function SwiftTab({ filter, onChangeTab, onChangeFilter }: Props) {
  const { AccountApi } = useAccountContext();
  const { PdfReportApi } = usePdfReportContext();
  const locationHelpers = useLocationHelpers();
  const dispatch = useDispatch();
  const roleType = useShallowEqualSelector(userRoleSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCover, setDataCover] = useState<SwiftCoverTableDataProps[]>([]);
  const login = useShallowEqualSelector(userLoginSelector);

  const swiftInnerType = useMemo(() => filter.getSwiftInnerType(), [filter]);

  const { translate } = useI18n();

  const [initialValues, setInitialValues] = useState<CoverHeaderFormProps>({
    fromDate: new Date(),
    toDate: new Date(),
    chooseDate: false,
  });

  // const [withCreatorsAndApprovals, setWithCreatorsAndApprovals] = useState(false);
  // const checkWithCreatorsAndApprovals = useCallback(
  //   () => setWithCreatorsAndApprovals(!withCreatorsAndApprovals),
  //   [setWithCreatorsAndApprovals, withCreatorsAndApprovals],
  // );

  const getAccountForApprovalList = useResource(
    () =>
      filter.swiftInnerType === "outgoing"
        ? AccountApi.getAccountSwiftOutgoing(filter.getSwiftOutgoingListFilter())
        : AccountApi.getAccountSwiftIncoming(filter.getSwiftIncomingListFilter()),
    [filter],
  );
  const data = useMemo(
    () => (getAccountForApprovalList.data && getAccountForApprovalList.data.data) || [],
    [getAccountForApprovalList.data],
  );

  useResourceHandler(getAccountForApprovalList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  const payrollListIds = useShallowEqualSelector(payrollListIdsSelector);

  const saveTxtSwift = useCallback(() => {
    const messageKey = data.filter((item, index) => payrollListIds && payrollListIds[index])[0];
    if (data.length > 0) {
      if (filter.swiftInnerType === "outgoing") {
        AccountApi.getAccountSwiftOutgoingTxt({
          ...filter.getSwiftOutgoingListFilter(),
          messageKey: messageKey && messageKey.swiftMessageKey,
          pageNumber: 1,
          pageSize: 100,
          showModalText: () =>
            NotificationManager.error(
              translate("STATEMENT_NO_DATA"),
              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
            ),
        });
      }

      if (filter.swiftInnerType === "incoming") {
        AccountApi.getAccountSwiftIncomingTxt({
          ...filter.getSwiftIncomingListFilter(),
          messageKey: messageKey && messageKey.swiftMessageKey,
          pageNumber: 1,
          pageSize: 100,
          showModalText: () =>
            NotificationManager.error(
              translate("STATEMENT_NO_DATA"),
              translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
            ),
        });
      }
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [AccountApi, data, payrollListIds, translate, filter]);

  const saveToPdfWithQrCode = useCallback(() => {
    const pdf = data.filter((item, index) => payrollListIds && payrollListIds[index])[0];
    if (data.length > 0) {
      if (pdf.msgStatusCode === "1") {
        PdfReportApi.saveSwiftToPdfWithQrCode(
          [pdf],
          "outgoing-swift-with-qr-code",
          login,
        ).catch(() => NotificationManager.error(translate("QR_CODE_ERROR3015")));
      } else {
        NotificationManager.error(translate("QR_CODE_ERROR3016"));
      }
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [data, login, PdfReportApi, payrollListIds, translate]);

  const saveToPdf = useCallback(() => {
    const pdf = data.filter((item, index) => payrollListIds && payrollListIds[index])[0];
    if (data.length > 0) {
      if (swiftInnerType === SwiftInnerTabType.Outgoing) {
        PdfReportApi.saveSwiftToPdf([pdf], "outgoing", login);
      } else if (swiftInnerType === SwiftInnerTabType.Incoming) {
        PdfReportApi.saveSwiftToPdf([pdf], "incoming", login);
      }
    } else {
      NotificationManager.error(
        translate("STATEMENT_NO_DATA"),
        translate("ACCOUNTS_STATEMENT_TAB_TITLE"),
      );
    }
  }, [data, login, PdfReportApi, payrollListIds, translate, swiftInnerType]);

  const checkIfNotSelected = data.filter((item, index) => payrollListIds && payrollListIds[index]);

  const onSubmit = useCallback(
    (value: CoverHeaderFormProps) => {
      NotificationManager.info(translate("TRANSFER_LOADING"), "", 800);
      setLoading(true);
      const queryWithDate = value.chooseDate
        ? {
            ...filter.getAccountCardBoxFilter(),
            toDate: formatDateToFilter(value.toDate),
            fromDate: formatDateToFilter(value.fromDate),
          }
        : {
            ...filter.getAccountCardBoxFilter(),
            toDate: formatDateToFilter(new Date()),
            fromDate: BeforeMonthDate(formatDateToFilter(new Date()), 1).toString(),
          };

      AccountApi.getAccountSwiftIncomingCover(queryWithDate)
        .then((response: any) => {
          setLoading(false);
          setDataCover(response);
          locationHelpers.pushQuery({
            pageCount: response[0]?.totalPageCount,
            totalCount: response[0]?.totalRowCount,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error?.data[0]?.errorCode === 3006) {
            NotificationManager.error(
              translate("ACCOUNT_SWIFT_COVER_CHECK_NO_MESSAGES_RECEIVED_TITLE"),
            );
          } else {
            showError(error);
          }
        });
    },
    [AccountApi, filter, setLoading, locationHelpers, translate],
  );

  return (
    <TabPage
      contentClassName={`${
        roleType === RoleType.Individual ? "border-gray-light" : "bg-transparent"
      }  border-0`}
      headerClassName={`d-flex justify-content-center ${
        swiftInnerType !== SwiftInnerTabType.IncomingCover ? "align-items-end" : ""
      }`}
      headerComponent={
        // === SwiftInnerTabType.Outgoing
        swiftInnerType === SwiftInnerTabType.IncomingCover ? (
          <CoverHeader
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            onSubmit={onSubmit}
          />
        ) : (
          <div className="d-flex">
            <Formik onSubmit={onChangeFilter} initialValues={filter.getInquiryFilterForm()}>
              {({ handleSubmit, values }) => (
                <Form>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <DatePickerField
                        width={100}
                        name="fromDate"
                        className="pr-5"
                        size={SizeType.Small}
                        selectsStart={true}
                        labelPosition={PositionType.Left}
                        label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                        onChange={() => handleSubmit()}
                        maxDate={values.toDate}
                      />
                      <DatePickerField
                        width={100}
                        name="toDate"
                        className="pr-5"
                        size={SizeType.Small}
                        selectsEnd={true}
                        labelPosition={PositionType.Left}
                        label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                        onChange={() => handleSubmit()}
                        minDate={values.fromDate}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )
      }
      footerComponent={
        <div className="justify-content-end d-flex flex-shrink-1 flex-grow-1 align-items-center">
          {swiftInnerType !== SwiftInnerTabType.IncomingCover && (
            <FileControls
              className="mr-5"
              disabledSwiftButton={checkIfNotSelected.length < 1}
              conversionFormButton={true}
              onSaveToPdf2Click={
                swiftInnerType !== SwiftInnerTabType.Incoming ? saveToPdfWithQrCode : null
              }
              onSaveToPdfClick={saveToPdf}
              onSaveTxtSwiftClick={
                checkIfNotSelected.length > 0
                  ? saveTxtSwift
                  : () => {
                      return;
                    }
              }
            />
          )}
          <Paginator filter={filter} />
        </div>
      }
      className={"px-4"}
    >
      <SubTabs
        activeTab={swiftInnerType}
        onChangeTab={onChangeTab}
        defaultTab={SwiftInnerTabType.Outgoing}
      >
        <TabPane key={SwiftInnerTabType.Outgoing} tab="ACCOUNTS_OUTGOING_TAB_TITLE">
          <SwiftTableWrapper filter={filter} />
        </TabPane>

        <TabPane key={SwiftInnerTabType.Incoming} tab="ACCOUNTS_INCOMING_TAB_TITLE">
          <SwiftTableWrapper filter={filter} />
        </TabPane>

        <TabPane key={SwiftInnerTabType.IncomingCover} tab="ACCOUNTS_INCOMING_COVER_TAB_TITLE">
          <SwiftCoverTableWrapper data={dataCover} loading={loading} />
        </TabPane>
      </SubTabs>
    </TabPage>
  );
}
