import { DependencyList, useCallback, useEffect, useMemo, useState } from "react";
import {
  PromiseReducerAction,
  PromiseState,
  reducePromiseState,
  usePromise,
  PromiseFactory,
  UsePromiseOptions,
} from "../utils/utility-hooks";

import { AppError } from "../helpers/AppError";
import { toCamelCase } from "../utils/CaseUtils";

export interface Resource<T> {
  data?: T;
  error?: AppError;
  loading: boolean;
  refetch: () => void;
  resetError: () => void;
}

export type UseResourceOptions<T> = Omit<UsePromiseOptions<T>, "reducer">;

function reducer<T>(state: PromiseState<T>, action: PromiseReducerAction<T>): PromiseState<T> {
  if (action.type === "pending") {
    return { status: "pending", value: state.value };
  }

  return reducePromiseState(state, action);
}

export function useResource<T>(
  factory: PromiseFactory<T>,
  deps: DependencyList,
  options?: UseResourceOptions<T>,
): Resource<T> {
  const [key, setKey] = useState(Math.random);
  const [error, setError] = useState<AppError | undefined>();

  const state = usePromise(factory, deps, {
    reducer,
    ...options,
    key: `${key}@${options?.key}`,
  });

  const refetch = useCallback(() => setKey(Math.random), []);
  const resetError = useCallback(() => setError(undefined), []);

  useEffect(() => {
    if (state.error) {
      setError(state.error as AppError);
    }
  }, [state.error]);

  // @ts-ignore
  return useMemo(
    () => ({
      error,
      refetch,
      resetError,
      data: toCamelCase(state.value),
      loading: state.status === "pending" && !options?.skip,
    }),
    [state.value, error, state.status, refetch, options?.skip, resetError],
  );
}
