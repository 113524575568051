import React, { useMemo } from "react";

import { AccountsFilter } from "../../filters/AccountsFilter";
import { CardBoxTable } from "./CardBoxTable";
import { useAccountContext } from "../../api/account/AccountContext";
import { useResource } from "../../hooks/useResource";
import { Paginator } from "../ui/Paginator";
import { TabPage } from "../tabs/TabPage";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { switchPageCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: AccountsFilter;
}

export function CardBoxTableWrapper({ filter }: Props) {
  const { AccountApi } = useAccountContext();
  const locationHelpers = useLocationHelpers();
  const dispatch = useDispatch();
  const getCardBox = useResource(
    () =>
      AccountApi.getCardBoxList(filter.getAccountCardBoxFilter()).then((r) => {
        locationHelpers.pushQuery({ totalCount: r[0]?.totalRowCount });
        return r;
      }),
    [filter],
  );
  const data = useMemo(() => getCardBox.data || [], [getCardBox.data]);
  useResourceHandler(getCardBox, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
    },
  });
  return (
    <TabPage
      className="px-4 mt-5"
      footerComponent={
        <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
          <Paginator filter={filter} />
        </div>
      }
    >
      <CardBoxTable loading={getCardBox.loading} data={data} />
    </TabPage>
  );
}
