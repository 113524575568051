import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { useI18n } from "../../i18n/I18nContext";
import { SalaryProps } from "../../api/salary/SalaryDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

export interface SalaryTableProps {
  readonly data: SalaryProps[];

  readonly withCreatorsAndApprovals?: boolean;
  readonly setDocumentId: (value) => void;
  readonly chooseTransferForView: (value) => void;
}

export function SalaryTable({
  data = [],
  withCreatorsAndApprovals,
  setDocumentId,
  chooseTransferForView,
}: SalaryTableProps) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SalaryProps>[] = [
      {
        width: 75,
        accessor: "cardType",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_CARD_TYPE_COLUMN_TITLE"),
      },
      {
        width: 75,
        accessor: "payrollNumber",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a className="cursor-pointer" onClick={() => chooseTransferForView(row.value)}>
            {row.value}
          </a>
        ),
      },
      {
        width: 70,
        accessor: "operationDate",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "payrollAmount",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : "0.00"}
          </div>
        ),
      },
      {
        width: 135,
        accessor: "transitAccount",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 400,
        accessor: "payrollDetail",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_DETAILS_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "payrollStatus",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          accessor: "createUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          accessor: "approvalUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [withCreatorsAndApprovals, translate, chooseTransferForView]);

  return (
    <TabPage className="px-4">
      <Table data={data} columns={columns} onSelect={setDocumentId} withCheckbox={true} />
    </TabPage>
  );
}
