import React from "react";
import "./assets/notification-loader.scss";
import { Loader } from "./Loader";
import { useI18n } from "../../i18n/I18nContext";

export default function NotificationLoader() {
  const { translate } = useI18n();

  return (
    <div className="notification-loader">
      <Loader color="white" />
      <span className="ml-3 fs-4 text-light fw-bold">{translate("MODAL_LOADING_TITLE")}</span>
    </div>
  );
}
