import React, { useMemo, useState, useCallback } from "react";

import { TabPage } from "../tabs/TabPage";
import { Paginator } from "../ui/Paginator";
import { FlatButton } from "../ui/FlatButton";
import { ShapeIcon } from "../icons/ShapeIcon";
import { FilterIcon } from "../icons/FilterIcon";
import { FileControls } from "../ui/FileControls";
import { HistoryFilterForm } from "./HistoryFilterForm";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { formatDateToUrl } from "../../utils/DateUtils";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { HistoryTable } from "./HistoryTable";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { Dict } from "../../api/dto/AppDTO";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { numberToWords } from "../../utils/FormatUtils";
import { removeGaps } from "../../utils/FloatUtils";
import { createTransferLCYExcel, printTransferHistoryTable } from "../../helpers/TransfersHelpers";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userTaxNoSelector } from "../../reducers/userReducer";
import { appLanguageSelector } from "../../reducers/appReducer";
import { TransferCurrencyPicker } from "./TransferCurrencyPicker";
import { PrintPortal } from "../ui/PrintPortal";
import { TransferLcyStandardTablePrint } from "./TransferLcyStandartTablePrint";
import { payrollListIdsSelector } from "../../reducers/payrollSelectedIds";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: TransfersFilter;
}

export function HistoryTab({ filter }: Props) {
  const locationHelpers = useLocationHelpers();
  const { translate } = useI18n();
  const [showFilter, setShowFilter] = useState(true);
  const language = useShallowEqualSelector(appLanguageSelector);
  const dispatch = useDispatch();
  const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;
  const [historyAccounts] = useState([
    {
      label: `${translate("TRANSFER_HISTORY_STATUS_ALL")}`,
      value: "A",
      currency: `A`,
    },
  ]);
  const { AccountApi } = useAccountContext();
  const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  useResource(
    () =>
      AccountApi.getAccountsListForHistory().then((r) => {
        r.map((res) => {
          historyAccounts.push({
            label: res.accounts,
            value: res.accounts,
            currency: res.currency,
          });
        });
      }),
    [filter],
  );

  const { TransferApi } = useTransferContext();

  const getTransferHistory = useResource(
    () => TransferApi.getTransferHistory(filter.getTransferHistoryFilter()),
    [
      filter.getTransferHistoryFilter().amount,
      filter.getTransferHistoryFilter().status,
      filter.getTransferHistoryFilter().fromDate,
      filter.getTransferHistoryFilter().toDate,
      filter.getTransferHistoryFilter().accountNumber,
      filter.getTransferHistoryFilter().currency,
      filter.getTransferHistoryFilter().receiverAccount,
      filter.getTransferHistoryFilter().pageNumber,
    ],
  );

  useResourceHandler(getTransferHistory, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPage?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRow?.toString() }));
    },
  });

  const data = useMemo(() => getTransferHistory.data || [], [getTransferHistory.data]);
  const taxNo = useShallowEqualSelector(userTaxNoSelector);
  const payrollListIds = useShallowEqualSelector(payrollListIdsSelector);

  return (
    <TabPage
      className="px-4"
      contentClassName="bg-transparent border-0"
      headerComponent={
        <div className="d-flex align-items-center flex-grow-1 flex-shrink-1 justify-content-end">
          <FlatButton
            leftIcon={<FilterIcon />}
            rightIcon={<ShapeIcon />}
            title="ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE"
            onClick={() => setShowFilter((x) => !x)}
            className="border border-gray-light br-1 bg-white px-2 py-1"
          />
        </div>
      }
      footerComponent={
        <div className="d-flex flex-grow-1 flex-shrink-1 align-items-center justify-content-end">
          <FileControls
            isMinusTransferLcyType={true}
            isUnselectType={false}
            className="mr-5"
            onPrintClick={useCallback(() => {
              const historyList = data.filter((_, idx) => payrollListIds && payrollListIds[idx]);

              historyList.map((x: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                x.amountDetails =
                  numberToWords(
                    removeGaps(
                      (x.amount = x.creditAmount === "0" ? x.debitAmount : x.creditAmount),
                    ),
                    {
                      language: languageApp,
                      currencyCode: x.currency ? x.currency : CurrencyCode.UzbekistanSum,
                    },
                  )[0].toUpperCase() +
                  numberToWords(
                    removeGaps(
                      (x.amount = x.creditAmount === "0" ? x.debitAmount : x.creditAmount),
                    ),
                    {
                      language: languageApp,
                      currencyCode: x.currency ? x.currency : CurrencyCode.UzbekistanSum,
                    },
                  ).slice(1);
              });
              printTransferHistoryTable({ historyList });
            }, [data, languageApp, payrollListIds])}
            onSaveToExcelClick={() => {
              const list = data.filter((_, idx) => selectedIds[idx]);
              list.map((x) => {
                x.clName = x.clientName;
                x.rcName = x.receiverName;
                x.clMfo = x.senderMfo;
                x.rcMfo = x.receiverMfo;
                x.documentDate = x.transactionDate;
                x.clientAccount = x.creditAmount === "0" ? x.senderAccount : x.receiverAccount;
                // @ts-ignore
                x.clientTaxNumber = x.creditAmount === "0" ? taxNo : x.receiverTaxNumber;
                x.clientName = x.creditAmount === "0" ? x.clientName : x.receiverName;
                x.amount = x.creditAmount === "0" ? x.debitAmount : x.creditAmount;
                x.correspondentName = x.creditAmount === "0" ? x.rcName : x.clName;
                x.correspondentAccount =
                  x.creditAmount === "0" ? x.receiverAccount : x.senderAccount;
                // @ts-ignore
                x.correspondentTaxNumber = x.creditAmount === "0" ? x.receiverTaxNumber : taxNo;
                x.paymentDetail = x.paymentDetails;
                x.id = x.transactionId;
                x.clientBankName = x.creditAmount === "0" ? x.senderBankName : x.receiverBankName;
                x.correspondentBankName =
                  x.creditAmount === "0" ? x.receiverBankName : x.senderBankName;
                x.receiverMfo = x.creditAmount === "0" ? x.receiverMfo : x.senderMfo;
                x.senderMfo = x.creditAmount === "0" ? x.clMfo : x.rcMfo;
                x.currencyCode = TransferCurrencyPicker({ currency: x.currency });
              });
              if (list.length > 0) {
                // @ts-ignore
                const workbook = createTransferLCYExcel(list, {
                  translate,
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                });

                saveExcelToFile(workbook, "LCY Standard Outgoing Transfers").catch(showError);
              }
            }}
          />
          <Paginator filter={filter} />
        </div>
      }
    >
      {showFilter && (
        <HistoryFilterForm
          filter={filter}
          onChangeFilter={(value) => {
            locationHelpers.replaceQuery({
              fromDate: formatDateToUrl(value.fromDate),
              toDate: formatDateToUrl(value.toDate),
              status: value?.status.value,
              accountNumber: value?.accountNumber.value,
              currency: value?.accountNumber.currency,
              amount: value?.amount,
              receiverAccount: value?.receiverAccount,
            });
          }}
          historyAccount={historyAccounts}
        />
      )}
      <PrintPortal>
        <TransferLcyStandardTablePrint />
      </PrintPortal>
      <HistoryTable data={data} loading={getTransferHistory.loading} onSelect={setSelectedIds} />
    </TabPage>
  );
}
