import { useI18n } from "../i18n/I18nContext";

export const TransfersStatuses = () => {
  const { translate } = useI18n();
  return [
    { label: `${translate("ACCOUNT_ALL_ACCOUNTS")}`, value: "A" },
    {
      label: `${translate("PAYROLL_STATUS_CREATED")}`,
      value: "101",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1")}`,
      value: "102",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2")}`,
      value: "103",
    },
    {
      label: `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3")}`,
      value: "104",
    },
    {
      label: `${translate("PAYROLL_STATUS_APPROVED")}`,
      value: "1",
    },
    {
      label: `${translate("PAYROLL_STATUS_PROCESSING")}`,
      value: "2",
    },
    {
      label: `${translate("PAYROLL_STATUS_SENT_TO_BANK")}`,
      value: "5",
    },
    {
      label: `${translate("PAYROLL_STATUS_REJECTED")}`,
      value: "106",
    },
    {
      label: `${translate("PAYROLL_STATUS_REJECTED_BY_CB")}`,
      value: "14",
    },
    {
      label: `${translate("PAYROLL_STATUS_COMPLETED")}`,
      value: "3",
    },
  ];
};

export const TransferHistoryStatuses = () => {
  const { translate } = useI18n();
  return [
    { label: `${translate("TRANSFER_HISTORY_STATUS_ALL")}`, value: "A" },
    { label: `${translate("TRANSFER_HISTORY_STATUS_OUTGOING")}`, value: "D" },
    { label: `${translate("TRANSFER_HISTORY_STATUS_INCOMING")}`, value: "C" },
  ];
};

export const TransferSalaryDetailsType = () => {
  const { translate } = useI18n();
  return [
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_SALARY")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_SALARY")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_PREPAYMENT")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_PREPAYMENT")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_TRAVEL")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_TRAVEL")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_COMPENSATION")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_COMPENSATION")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_BONUS")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_BONUS")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_PENSION")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_PENSION")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_OTHERS")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_OTHERS")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_VOCATION")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_VOCATION")}`,
    },
    {
      label: `${translate("TRANSFER_SALARY_DETAIL_TYPE_SETTLEMENT")}`,
      value: `${translate("TRANSFER_SALARY_DETAIL_TYPE_SETTLEMENT")}`,
    },
  ];
};

export const TransferForDetailsMonth = () => {
  const { translate } = useI18n();
  return [
    { label: `${translate("MONTH_JANUARY_TITLE")}`, value: `${translate("MONTH_JANUARY_TITLE")}` },
    {
      label: `${translate("MONTH_FEBRUARY_TITLE")}`,
      value: `${translate("MONTH_FEBRUARY_TITLE")}`,
    },
    { label: `${translate("MONTH_MARCH_TITLE")}`, value: `${translate("MONTH_MARCH_TITLE")}` },
    { label: `${translate("MONTH_APRIL_TITLE")}`, value: `${translate("MONTH_APRIL_TITLE")}` },
    { label: `${translate("MONTH_MAY_TITLE")}`, value: `${translate("MONTH_MAY_TITLE")}` },
    { label: `${translate("MONTH_JUN_TITLE")}`, value: `${translate("MONTH_JUN_TITLE")}` },
    { label: `${translate("MONTH_JULY_TITLE")}`, value: `${translate("MONTH_JULY_TITLE")}` },
    { label: `${translate("MONTH_AUGUST_TITLE")}`, value: `${translate("MONTH_AUGUST_TITLE")}` },
    {
      label: `${translate("MONTH_SEPTEMBER_TITLE")}`,
      value: `${translate("MONTH_SEPTEMBER_TITLE")}`,
    },
    { label: `${translate("MONTH_OCTOBER_TITLE")}`, value: `${translate("MONTH_OCTOBER_TITLE")}` },
    {
      label: `${translate("MONTH_NOVEMBER_TITLE")}`,
      value: `${translate("MONTH_NOVEMBER_TITLE")}`,
    },
    {
      label: `${translate("MONTH_DECEMBER_TITLE")}`,
      value: `${translate("MONTH_DECEMBER_TITLE")}`,
    },
  ];
};
