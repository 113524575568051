import { BaseApi } from "../BaseApi";
import { SwiftListInnerDataProps } from "../account/AccountDTO";

export class PdfReportApi extends BaseApi {
  public createStandardPdfWithQRCode(fileName: string, json, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-qr/standard/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public createBudgetPdfWithQRCode(fileName: string, json, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-qr/budget/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public createRenevuePdfWithQRCode(fileName: string, json, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-qr/revenue/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public checkPdfWithQRCode(documentId?: string) {
    return this.get("pdf-qr/get-qr-codes", {
      query: { documentId: documentId },
    });
  }

  public createStatementPdfReport(query, fileName) {
    return this.downloadPdf("pdf-report/statement/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { ...query, employeeId: this.employeeId },
    });
  }

  public createStandardPdfReport(fileName: string, json: any, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-report/standard/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public createBudgetPdfReport(fileName: string, json, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-report/budget/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public createRevenuePdfReport(fileName: string, json, userId?: string): Promise<void> {
    return this.downloadPdf("pdf-report/revenue/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { userId },
      json,
    });
  }

  public createFcyReport(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/fcy/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createConversionToBankReport(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/conversion-to-bank/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createConversionToFcyReport(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/fcy-to-fcy-conversion/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createIndividualTransfersHistoryPdf({ fileName, json }: any): Promise<void> {
    return this.downloadPdf("pdf-report/transfer/report_pdf_history/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createConversionToRceReport(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/conversion-to-UzRCE/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createSalaryReport(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/application-form/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createConversionWord(fileName: string, json, lang): Promise<void> {
    return this.downloadWord("word-report/currency/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { lang },
      json,
    });
  }

  public createStatementDocument(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/statement/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createIndividualStatementDocument(fileName: string, query): Promise<void> {
    return this.downloadPdf("pdf-report/statement/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { ...query, employeeId: this.employeeId },
    });
  }

  public createNonStandardDocument(fileName: string, json): Promise<void> {
    return this.downloadPdf("pdf-report/inquiry-report/:fileName", fileName, {
      method: "post",
      params: { fileName },
      json,
    });
  }

  public createDiscrepancyDocument(fileName: string, json, clientName): Promise<void> {
    return this.downloadPdf("trade-finance/discrepancy-pdf/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: { clientName },
      json,
    });
  }

  public createTradeFinanceDocument(
    fileName: string,
    json,
    pageNumber,
    pageSize,
    clientName,
    clientAddress,
    clientPostCode,
    clientPhone,
  ): Promise<void> {
    return this.downloadPdf("trade-finance/application-pdf/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: {
        employeeID: this.employeeId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        clientName: clientName,
        clientAddress: clientAddress,
        clientPostCode: clientPostCode,
        clientPhone: clientPhone,
      },
      json,
    });
  }
  public createTradeFinanceAmendment(
    fileName: string,
    json,
    clientName,
    clientAddress,
    clientPostCode,
    clientPhone,
  ): Promise<void> {
    return this.downloadPdf("trade-finance/amendment-pdf/:fileName", fileName, {
      method: "post",
      params: { fileName },
      query: {
        employeeID: this.employeeId,
        clientName: clientName,
        clientAddress: clientAddress,
        clientPostCode: clientPostCode,
        clientPhone: clientPhone,
      },
      json,
    });
  }

  public saveSwiftToPdfWithQrCode(
    json: SwiftListInnerDataProps[],
    filename: string,
    userId?: string,
  ): Promise<void> {
    return this.downloadPdf("pdf-qr/outgoing/:filename", filename, {
      method: "post",
      query: { userId: userId },
      params: { filename },
      json,
    });
  }

  public saveSwiftToPdf(
    json: SwiftListInnerDataProps[],
    path: string,
    userId?: string,
  ): Promise<void> {
    const filename = path + "-swift";
    return this.downloadPdf(`pdf-report/${path}/:filename`, filename, {
      method: "post",
      query: { userId: userId },
      params: { filename },
      json,
    });
  }
}
