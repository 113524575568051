import React from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("print-root");

export class PrintPortal extends React.Component {
  private readonly el: HTMLDivElement;

  public constructor(props) {
    super(props);

    this.el = document.createElement("div");
  }

  public componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  public render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
