import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { PdfReportApi } from "./PdfReportApi";

interface Props {
  readonly PdfReportApi: PdfReportApi;
}

export function usePdfReportContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new PdfReportApi(data), [data]);

  return {
    PdfReportApi: api,
  };
}
