import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import cx from "classnames";
import { toFinite } from "lodash";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";
import { Loader } from "../ui/Loader";

interface Props {
  readonly data: any;
  readonly onCloseModal: () => void;
  readonly importModalTable: boolean;
}

export function ImportLogsModalTab({ data = [], onCloseModal, importModalTable }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 20,
        accessor: "forId",
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{toFinite(row.row.id) + 1}</span>,
        Header: "#",
      },
      {
        width: 95,
        accessor: "transferNumber",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "comment",
        Header: translate("TRANSFER_IMPORT_TABLE_COMMENT"),
      },
      {
        width: 100,
        accessor: "status",
        Header: translate("PENDING_APPROVALS_ACCOUNT_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div
            className={cx(
              "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center br-1",
              {
                "gray-status": row.value === translate("TRANSFER_IMPORT_DEFECTED"),
                "green-status": row.value === translate("TRANSFER_SUCCESS"),
                "red-color-for-badge": row.value === translate("TRANSFER_IMPORT_ERROR"),
              },
            )}
            style={{ height: "30px" }}
          >
            <div className="text-white">{row.value}</div>
          </div>
        ),
      },
    ];

    return list;
  }, [translate]);
  if (!Object.values(data[0]).some((v) => v)) {
    data.shift();
  }
  return (
    <>
      {importModalTable ? (
        <>
          <Table data={data} columns={columns} />
          <Button
            type="button"
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            onClick={onCloseModal}
            className="m-4"
          >
            {translate("MODAL_CONFIRMATION_YES")}
          </Button>
        </>
      ) : (
        <div className="d-flex flex-1 align-items-center justify-content-center m-4">
          <Loader />
        </div>
      )}
    </>
  );
}
