import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { NacCodeFCYProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly loading?: boolean;
  readonly data: NacCodeFCYProps[];
  readonly onNacCodeSelect?: (value) => void;
}

export function FCYNacCodeTable({ data = [], loading, onNacCodeSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<NacCodeFCYProps>[] = [
      {
        width: 50,
        accessor: "naceCode",
        Header: translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACCODE_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              style={{ cursor: "pointer" }}
              onClick={() => onNacCodeSelect && onNacCodeSelect(row.value)}
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 150,
        accessor: "naceName",
        Header: translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACNAME_TITLE"),
      },
    ];

    return list;
  }, [onNacCodeSelect, translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
