import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function FavoriteIcon({ size = 20, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.329 18.523"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(0 -0.496)"
        d="M19.278,7.5a1.026,1.026,0,0,0-.883-.706l-5.58-.507L10.61,1.12a1.028,1.028,0,0,0-1.89,0L6.514,6.283.934,6.79a1.029,1.029,0,0,0-.584,1.8l4.218,3.7L3.324,17.763a1.027,1.027,0,0,0,1.529,1.111L9.664,16l4.811,2.877A1.028,1.028,0,0,0,16,17.763L14.76,12.285l4.218-3.7a1.029,1.029,0,0,0,.3-1.092ZM9.757,15.941"
      />
    </svg>
  );
}
