import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import "./assets/transfer-history-filter.scss";

import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { DatePickerField } from "../form/DatePickerField";
import { TransferHistoryStatuses } from "../../constants/TransfersConstants";
import { Button, ButtonColor } from "../ui/Button";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { CreateNewDocumentRoles, SizeType } from "../../api/dto/AppDTO";
import { update } from "immupdate";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { flagToHugeTurnover } from "../../reducers/authReducer";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

interface Props {
  readonly onChangeFilter: (value) => void;
  readonly filter: TransfersFilter;
  readonly historyAccount: any[];
}

export function HistoryFilterForm({ onChangeFilter, filter, historyAccount }: Props) {
  const { translate } = useI18n();
  const flagToHugeType = useShallowEqualSelector(flagToHugeTurnover);
  const isMobile = CheckMobileUtils();
  const [initialValues, setInitialValues] = useState({
    accountNumber: historyAccount[0],
    currency: filter.getCurrencyForFilter().currency,
    status: TransferHistoryStatuses()[0],
    fromDate: filter.getHistoryFilterForPage().fromDate,
    toDate: filter.getHistoryFilterForPage().toDate,
    amount: "",
    corrAccount: "",
  });
  const onChangeCurrency = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        currency: value.currency,
        accountNumber: value,
      }),
    );
  }, []);

  const onChangeFromDate = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        fromDate: value,
        toDate: value,
      }),
    );
  }, []);

  const onChangeToDate = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        toDate: value,
      }),
    );
  }, []);

  const futureDate = () => {
    const maxDate = flagToHugeType === CreateNewDocumentRoles.Yes ? 1 : 12;
    const futureDate = new Date(initialValues.fromDate);
    futureDate.setMonth(futureDate.getMonth() + maxDate);
    return futureDate;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onChangeFilter} enableReinitialize={true}>
      {({ values }) => (
        <Form className="d-flex flex-column p-7 mb-7 bg-white border border-gray-light br-1">
          <div className="row">
            <div className="d-flex flex-column col-lg-3 justify-content-between">
              <SelectPickerField
                options={historyAccount}
                name="accountNumber"
                className="mb-2"
                onChanges={(value) => onChangeCurrency(value)}
                label="TRANSFERS_HISTORY_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE"
              />
              <InputField
                disabled={true}
                value={
                  initialValues.currency === "A"
                    ? translate("TRANSFER_HISTORY_STATUS_ALL")
                    : initialValues.currency
                }
                name="currency"
                label="TRANSFERS_HISTORY_FILTER_FORM_CURRENCY_FIELD_LABEL_TITLE"
              />
            </div>
            <div className="col-lg-3">
              <SelectPickerField
                options={TransferHistoryStatuses()}
                name="status"
                className="mb-2"
                label="TRANSFERS_HISTORY_FILTER_FORM_STATUS_FIELD_LABEL_TITLE"
              />
              <div className="row">
                <div className="col-lg-6 pr-lg-1">
                  <DatePickerField
                    name="fromDate"
                    label="TRANSFERS_HISTORY_FILTER_FORM_FROM_FIELD_LABEL_TITLE"
                    selectsStart={true}
                    onChange={(value) => onChangeFromDate(value)}
                  />
                </div>
                <div className="col-lg-6 pl-lg-2">
                  <DatePickerField
                    name="toDate"
                    label="TRANSFERS_HISTORY_FILTER_FORM_TO_FIELD_LABEL_TITLE"
                    selectsEnd={true}
                    minDate={values.fromDate}
                    maxDate={futureDate()}
                    onChange={(value) => onChangeToDate(value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <InputField
                className="mb-2"
                name="amount"
                label="TRANSFERS_HISTORY_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE"
              />
              <InputField
                name="receiverAccount"
                label="TRANSFERS_HISTORY_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE"
              />
            </div>
            <div className="d-flex flex-column col-lg-3 justify-content-center">
              <div
                className={`col-lg-1 p-0 mt-lg-0 mt-3 ${
                  !isMobile ? "transfers-submit-button" : ""
                } `}
              >
                <Button
                  size={SizeType.Medium}
                  className="px-lg-6 font-weight-bold"
                  color={ButtonColor.Orange}
                  labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
