
import React from "react";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";

interface Props {
  readonly ImportForCBU: () => void;
  readonly disabled?: boolean;
  readonly beginDay?: string;
}

export function ImportExcelFile({ImportForCBU, disabled = true, beginDay }: Props) {
  return (
    <div className="d-flex justify-content-between">
      {beginDay === "1" && (
        <Button
          onClick={ImportForCBU}
          size={SizeType.Medium}
          color={ButtonColor.Orange}
          labelCode="TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE"
          className="m-5 mb-5 ml-auto mr-auto"
          disabled={!disabled}
        />
      )}
    </div>
  );
}
