import React from "react";
import { TabPage } from "../tabs/TabPage";
import { TransferFormDirectionsTab } from "../../components/transfers/TransfersFormDirectionsTab";

export function SampleTab() {
  return (
    <TabPage className="mt-lg-7 mt-4" contentClassName="bg-transparent border-0 pb-3">
      <TransferFormDirectionsTab />
    </TabPage>
  );
}
