import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { PaymentApi } from "./PaymentApi";

interface Props {
  readonly PaymentApi: PaymentApi;
}

export function usePaymentContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new PaymentApi(data), [data]);

  return {
    PaymentApi: api,
  };
}
