import React, { useMemo } from "react";

import { AccountsTab } from "./AccountsTab";
import { useResource } from "../../hooks/useResource";
import { formatDateToUrl } from "../../utils/DateUtils";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useAccountContext } from "../../api/account/AccountContext";
import { useDispatch } from "react-redux";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";

interface Props {
  readonly filter: AccountsFilter;
  readonly onChangeStatementAccount: ({
    accountNumber,
    accountName,
    lastTransactionDate,
    currency,
    accountType,
  }) => void;
  readonly individual?: boolean;
}

export function AccountsTabWrapper({ filter, onChangeStatementAccount, individual }: Props) {
  const locationHelpers = useLocationHelpers();
  const dispatch = useDispatch();

  const { AccountApi } = useAccountContext();

  const listResource = useResource(
    () => AccountApi.getAccountsList(filter.getAccountListFilter()),
    [filter],
  );

  const list = useMemo(() => listResource.data || [], [listResource.data]);

  useResourceHandler(listResource, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  return (
    <AccountsTab
      list={list}
      filter={filter}
      loading={listResource.loading}
      onChangeFilter={(values) =>
        locationHelpers.replaceQuery({
          balanceAsOfDate: formatDateToUrl(values.balanceAsOfDate),
          excludeZeroBalance: values.excludeZeroBalance ? "1" : undefined,
          includeClosedAccounts: values.includeClosedAccounts ? "1" : undefined,
        })
      }
      onChangeStatementAccount={onChangeStatementAccount}
      individual={individual}
    />
  );
}
