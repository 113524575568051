import React, { ReactElement, ReactNode, useContext, useMemo } from "react";

import { RoleType } from "../api/dto/AppDTO";

export interface RoleContextDataProps {
  readonly role?: RoleType;
}

export interface RoleContextProps {
  readonly role: RoleType;

  readonly isCorporate: boolean;
  readonly isFinancial: boolean;
  readonly isIndividual: boolean;
}

export interface RoleProviderProps {
  readonly children: ReactNode;
  readonly data?: RoleContextDataProps;
}

function createContentValue(roleData: RoleContextDataProps): RoleContextProps {
  return {
    ...roleData,

    role: roleData.role || RoleType.Corporate,

    isCorporate: roleData.role === RoleType.Corporate,
    isFinancial: roleData.role === RoleType.Financial,
    isIndividual: roleData.role === RoleType.Individual,
  };
}

export const RoleContext = React.createContext<RoleContextProps>(
  createContentValue({ role: RoleType.Corporate }),
);

export function RoleProvider({
  data = {} as RoleContextDataProps,
  ...props
}: RoleProviderProps): ReactElement<object> {
  const value = useMemo(() => createContentValue(data), [data]);

  return <RoleContext.Provider {...props} value={value} />;
}

export function useRole(): RoleContextProps {
  return useContext(RoleContext);
}
