import { AppFilter, AppFilterProps } from "./AppFilter";
import { formatDateFromUrl, formatDateToFilter } from "../utils/DateUtils";
import { TransferListLCYDocProps } from "../api/transfer/TransferDTO";

export enum TransfersTabs {
  Salary = "salary",
  History = "history",
  Samples = "samples",
  Transfers = "transfers",
  Favorites = "favorites",
  CorporateCard = "corporate-card",
  PaymentClaim = "payment-claim",
  Correspondents = "correspondents",
  TransfersToAnotherClient = "transfers-to-another-client",
  TransfersBetweenMyAccounts = "transfers-between-my-accounts",
  TransferToOtherKDBClients = "transfers-to-other-kdb-client",
  TransferFcy = "transfers-fcy",
}

export enum TransferType {
  LCY = "lcy",
  FCY = "fcy",
  Unselect = "unselect",
}

export enum ChooseAccountTypes {
  Debit = "debit",
  Transit = "transit",

}

export enum ChooseAccountTypesSalary {
  SalaryDebit = "salaryDebit",
  SalaryTransit = "salaryTransit"
}

export enum TransferLcyType {
  AnorTransfer = "anor",
  MinusTransfer = "minus",
  BudgetTransfer = "budget",
  AnorBudgetTransfer = "Anor-budget",
  StandardTransfer = "standard",
  BudgetRevenueTransfer = "budget-revenue",
  AnorBudgetRevenueTransfer = "Anor-budget-revenue",
}

export enum TransferPaymentClaimTabs {
  SentPaymentClaims = "sent-payment-claims",
  CreateNewPaymentClaim = "create-new-payment-claim",
  ReceivedPaymentClaims = "received-payment-claims",
}

interface TransfersFilterProps extends AppFilterProps<TransfersTabs> {
  readonly create?: boolean;
  readonly uploadFile?: boolean;
  readonly registerSalary?: boolean;
  readonly openSalary?: boolean;
  readonly listOfCardHolders?: boolean;
  readonly individualViewType: boolean;

  readonly transferType?: TransferType;
  readonly transferLcyType?: TransferLcyType;
  readonly paymentClaimTab?: TransferPaymentClaimTabs;

  readonly fromDate?: string;
  readonly toDate?: string;

  readonly fromDateFcy?: string;
  readonly toDateFcy?: string;

  readonly fromDateHistory?: string;
  readonly toDateHistory?: string;

  readonly status?: string;

  readonly cardType?: string;

  readonly cardTypeUzcard?: string;
  readonly cardTypeHumo?: string;


  readonly register?: number;

  readonly accountNumber?: string;
  readonly currency?: string;
  readonly amount?: string;
  readonly receiverAccount?: string;

  readonly chooseAccountType?: ChooseAccountTypes;
  readonly chooseAccountTypeSalary?:ChooseAccountTypesSalary;
}

export class TransfersFilter extends AppFilter<TransfersTabs> {
  private readonly create: boolean;
  private readonly uploadFile: boolean;
  private readonly registerSalary: boolean;
  private readonly openSalary: boolean;
  private readonly listOfCardHolders: boolean;
  private readonly individualViewType: boolean;

  private readonly transferType: TransferType;
  private readonly transferLcyType: TransferLcyType;
  private readonly paymentClaimTab: TransferPaymentClaimTabs;

  readonly fromDate: Date;
  readonly toDate: Date;
  private readonly fromDateFcy: Date;
  private readonly toDateFcy: Date;

  private readonly status: string;

  private readonly register: number;

  private readonly cardType: string;

  private readonly cardTypeUzcard: string;
  private readonly cardTypeHumo: string;

  private readonly accountNumber?: string;
  private readonly currency?: string;
  private readonly amount?: string;
  private readonly receiverAccount?: string;
  private readonly chooseAccountType?: ChooseAccountTypes;
  private readonly chooseAccountTypeSalary?:ChooseAccountTypesSalary;
  public constructor(
    {
      tab,
      accountNumber,
      amount,
      receiverAccount,
      currency,
      create,
      uploadFile,
      fromDate,
      toDate,
      fromDateFcy,
      toDateFcy,
      status,
      cardType,
      cardTypeUzcard,
      cardTypeHumo,
      register,
      registerSalary,
      openSalary,
      individualViewType,
      listOfCardHolders,
      chooseAccountType = ChooseAccountTypes.Transit,
      chooseAccountTypeSalary = ChooseAccountTypesSalary.SalaryTransit,
      transferType = TransferType.Unselect,
      paymentClaimTab = TransferPaymentClaimTabs.SentPaymentClaims,
      transferLcyType = TransferLcyType.StandardTransfer,
      ...props
    } = {} as TransfersFilterProps,
  ) {
    super({ ...props, tab: tab || TransfersTabs.Transfers });

    this.create = Boolean(create);
    this.uploadFile = Boolean(uploadFile);
    this.registerSalary = Boolean(registerSalary);
    this.openSalary = Boolean(openSalary);
    this.listOfCardHolders = Boolean(listOfCardHolders);

    this.transferType = transferType;
    this.transferLcyType = transferLcyType;
    this.paymentClaimTab = paymentClaimTab;
    this.individualViewType = individualViewType;

    this.chooseAccountType = chooseAccountType;
    this.chooseAccountTypeSalary = chooseAccountTypeSalary;

    this.fromDate = new Date();
    this.toDate = new Date();
    this.fromDateFcy = new Date();
    this.toDateFcy = new Date();
    this.status = "A";
    this.register = 10;
    this.cardType = "UZCARD";
    this.cardTypeUzcard = "UZCARD";
    this.cardTypeHumo = "HUMO";
    this.receiverAccount = "A";
    this.currency = "A";
    this.amount = "A";
    this.accountNumber = "A";

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }

    if (toDateFcy) {
      this.toDateFcy = formatDateFromUrl(toDateFcy);
    }

    if (fromDateFcy) {
      this.fromDateFcy = formatDateFromUrl(fromDateFcy);
    }

    if (status) {
      this.status = status;
    }

    if (cardType) {
      this.cardType = cardType;
    }

    if (cardTypeUzcard) {
      this.cardTypeUzcard = cardTypeUzcard;
    }

    if (cardTypeHumo) {
      this.cardTypeHumo = cardTypeHumo;
    }

    if (register) {
      this.register = register;
    }

    if (accountNumber === undefined || accountNumber === "All") {
      this.accountNumber = "A";
    } else {
      this.accountNumber = accountNumber;
    }

    if (amount === undefined || amount === "") {
      this.amount = "A";
    } else {
      this.amount = amount;
    }

    if (currency) {
      this.currency = currency;
    }

    if (receiverAccount === undefined || receiverAccount === "") {
      this.receiverAccount = "A";
    } else {
      this.receiverAccount = receiverAccount;
    }
  }

  public getPaymentClaimTab(): TransferPaymentClaimTabs {
    return this.paymentClaimTab;
  }

  public getTransferType(): TransferType {
    return this.transferType;
  }

  public getTransferLcyType(): TransferLcyType {
    return this.transferLcyType;
  }

  public getQuery(): TransfersFilterProps {
    const baseQuery = super.getQuery();

    return {
      ...baseQuery,
      transferType: this.transferType,
    };
  }

  public getIndividualViewType(): boolean {
    return this.individualViewType;
  }

  public getCreate(): boolean {
    return this.create;
  }
  public getRegisterSalary(): boolean {
    return this.registerSalary;
  }
  public getOpenSalary(): boolean {
    return this.openSalary;
  }
  public getListOfCardHolders(): boolean {
    return this.listOfCardHolders;
  }
  public getUploadFile(): boolean {
    return this.uploadFile;
  }

  public getChooseAccountType(): ChooseAccountTypes | undefined {
    return this.chooseAccountType;
  }

  public getChooseAccountTypeSalary(): ChooseAccountTypesSalary | undefined {
    return this.chooseAccountTypeSalary;
  }


  public getLcyFilterForTransferDoc(): TransferListLCYDocProps {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      state: this.status,
    };
  }
  public getLcyFilterForPage() {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
      status: this.status,
    };
  }

  public getTransferHistoryFilter() {
    return {
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      status: this.status,
      accountNumber: this.accountNumber,
      currency: this.currency,
      amount: this.amount,
      receiverAccount: this.receiverAccount,
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }

  public getTransferIndividualHistoryFilter() {
    return {
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }
  public getFcyFilterForTransferDoc(): TransferListLCYDocProps {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDateFcy),
      toDate: formatDateToFilter(this.toDateFcy),
      state: this.status,
    };
  }
  public getFcyFilterForPage() {
    return {
      fromDate: this.fromDateFcy,
      toDate: this.toDateFcy,
      status: this.status,
    };
  }
  public getRegisterCount() {
    return {
      register: this.register,
    };
  }
  public getCardType() {
    return {
      cardType: this.cardType,
    };
  }
  public getCardTypeUzcard() {
    return {
      cardTypeUzcard: this.cardTypeUzcard,
    };
  }
  public getCardTypeHumo() {
    return {
      cardTypeHumo: this.cardTypeHumo,
    };
  }
  public getCurrencyForFilter() {
    return {
      currency: this.currency,
    };
  }
  public getHistoryFilterForPage() {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
  }
  public getCardHoldersFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }
}
