import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function DateIcon({ size = 20, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.128 19.562"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(-112.562 -247.894)"
        d="M119.472,258.686a.444.444,0,0,0-.444-.444h-1.549a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.549a.444.444,0,0,0,.444-.444v-1.549Z"
      />
      <path
        fill={color}
        transform="translate(-205.325 -247.894)"
        d="M216.107,258.686a.444.444,0,0,0-.444-.444h-1.55a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.55a.444.444,0,0,0,.444-.444Z"
      />
      <path
        fill={color}
        transform="translate(-298.08 -247.894)"
        d="M312.735,258.686a.444.444,0,0,0-.444-.444h-1.549a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.549a.444.444,0,0,0,.444-.444Z"
      />
      <path
        fill={color}
        transform="translate(-112.562 -340.654)"
        d="M119.472,355.319a.444.444,0,0,0-.444-.444h-1.549a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.549a.444.444,0,0,0,.444-.444v-1.549Z"
      />
      <path
        fill={color}
        transform="translate(-205.325 -340.654)"
        d="M216.107,355.319a.444.444,0,0,0-.444-.444h-1.55a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.55a.444.444,0,0,0,.444-.444Z"
      />
      <path
        fill={color}
        transform="translate(-298.08 -340.654)"
        d="M312.735,355.319a.444.444,0,0,0-.444-.444h-1.55a.444.444,0,0,0-.444.444v1.549a.444.444,0,0,0,.444.444h1.55a.444.444,0,0,0,.444-.444v-1.549Z"
      />
      <path
        fill={color}
        transform="translate(-5.411 -51.972)"
        d="M22.836,54.15v2.366A1.933,1.933,0,0,1,20.9,58.448H19.677a1.943,1.943,0,0,1-1.949-1.931V54.142H12.221v2.375a1.942,1.942,0,0,1-1.948,1.931H9.051a1.933,1.933,0,0,1-1.937-1.931V54.15a1.761,1.761,0,0,0-1.7,1.752V69.77a1.762,1.762,0,0,0,1.753,1.764H22.785a1.764,1.764,0,0,0,1.753-1.764V55.9A1.761,1.761,0,0,0,22.836,54.15Zm-.568,14.756a.758.758,0,0,1-.758.758H8.406a.758.758,0,0,1-.758-.758V61.743a.758.758,0,0,1,.758-.758h13.1a.758.758,0,0,1,.758.758v7.163Z"
      />
      <path
        fill={color}
        transform="translate(-76.579 0)"
        d="M80.214,5.207h1.209a.664.664,0,0,0,.664-.664V.664A.664.664,0,0,0,81.423,0H80.214a.664.664,0,0,0-.664.664V4.543A.664.664,0,0,0,80.214,5.207Z"
      />
      <path
        fill={color}
        transform="translate(-330.872 0)"
        d="M345.122,5.207h1.209A.664.664,0,0,0,347,4.543V.664A.664.664,0,0,0,346.331,0h-1.209a.664.664,0,0,0-.664.664V4.543A.664.664,0,0,0,345.122,5.207Z"
      />
    </svg>
  );
}
