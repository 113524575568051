import React, { useCallback, useMemo, useState } from "react";
import { useResource } from "../../hooks/useResource";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { TransfersFcyTable } from "./TransfersFcyTable";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { update } from "immupdate";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector } from "../../reducers/appReducer";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { numberToWords } from "../../utils/FormatUtils";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { TabPage } from "../tabs/TabPage";
import { Form, Formik } from "formik";
import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { Modal } from "../ui/Modal";
import { noop } from "lodash";
import { RadioGroupField } from "../form/RadioGroupField";
import { Direction } from "../../api/dto/AppDTO";
import { InformationBody } from "../info-for-user/InfoTitle";
import { TextArea } from "../ui/TextArea";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: PendingApprovalsFilter;
  readonly setDocumentsId: (value, appType) => void;
  readonly withCreatorsAndApprovals?: boolean;
}

export function TransfersFCYTableWrapper({
  filter,
  setDocumentsId,
  withCreatorsAndApprovals,
}: Props) {
  const { TransferApi } = useTransferContext();
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const [transferView, setTransferView] = useState(false);
  const [transfer, setTransfer] = useState({});
  const language = useShallowEqualSelector(appLanguageSelector);
  const getTransferListForApproval = useResource(
    () =>
      TransferApi.getFcyTransferListForApproval({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );
  useResourceHandler(getTransferListForApproval, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPage?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRow?.toString() }));
    },
  });

  const data = useMemo(() => getTransferListForApproval.data || [], [
    getTransferListForApproval.data,
  ]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.FCYTransfer);
    },
    [data, setDocumentsId],
  );

  const list = useMemo(() => data.map((x) => update(x, { id: x.documentId })), [data]);

  const chooseTransferForView = useCallback(
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      const amountDetails = numberToWords(removeGaps(value.amount), {
        language: languageApp,
        currencyCode: CurrencyCode.UzbekistanSum,
      });
      setTransfer({
        ...value,
        amountDetails:
          amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
        amount: floatFormat(addZeros(value.amount)),
      });
      setTransferView(true);
    },
    [language],
  );

  return (
    <>
      <TransfersFcyTable
        setDocumentId={setDocumentId}
        data={list}
        withCreatorsAndApprovals={withCreatorsAndApprovals}
        chooseTransferForView={chooseTransferForView}
        loading={getTransferListForApproval.loading}
      />
      <Modal
        onClose={() => setTransferView(false)}
        width={1200}
        show={transferView}
        title={"PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE"}
      >
        <TabPage>
          <Formik initialValues={transfer} onSubmit={noop}>
            {() => (
              <Form className="p-lg-5" onSubmit={noop}>
                <GroupBox contentClassName="row" className="mb-7">
                  <div className="col-lg-6">
                    <div className="row  flex-lg-nowrap">
                      <div className="col-12">
                        <InputField
                          name="transferNumber"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12">
                        <InputField
                          name="transactionDate"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </GroupBox>
                <div className="row">
                  <div className="col-lg-6">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TITLE"}
                      className="mb-7"
                    >
                      <div className="row mb-2">
                        <div className="col-12">
                          <InputField
                            name="applicantAccount"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <InputField
                        className="mb-2"
                        name="applicantName"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                      <InputField
                        className="mb-2"
                        name="taxId"
                        label="TRANSFERS_CORRESPONDENTS_TABLE_TAX_ID_COLUMN_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                      <InputField
                        className="mb-2"
                        name="applicantAddress"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE"}
                      className="mb-7"
                    >
                      <div className="d-flex flex-column col-12">
                        <label className="text-ellipsis mb-1">
                          {translate(
                            "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE",
                          )}
                        </label>
                        <div className="input-group">
                          <InputField
                            className="flex-1"
                            name="beneficiaryName"
                            disabled={true}
                            inputClassName="text-uppercase"
                          />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-12"
                            name="beneficiaryTaxId"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE"
                            disabled={true}
                            inputClassName="text-uppercase"
                          />
                        </div>
                        <div className="row mb-2">
                          <InputField
                            className="col-12"
                            name="beneficiaryAddress"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                            disabled={true}
                            inputClassName="text-uppercase"
                          />
                        </div>
                        <div className="row mb-2">
                          <InputField
                            className="col-12"
                            name="beneficiaryAccount"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                            disabled={true}
                            inputClassName="text-uppercase"
                          />
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TITLE"}
                      className="mb-7"
                    >
                      <div className="row mb-2">
                        <InputField
                          name="bankName"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>
                      <div className="row mb-2">
                        <InputField
                          name="bankAddress"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>
                      <div className="row mb-2">
                        <InputField
                          name="bankSwift"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE_N2"}
                      className="mb-7"
                    >
                      <div className="row mb-2">
                        <InputField
                          name="beneficiaryBankName"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>
                      <div className="row mb-2">
                        <InputField
                          name="beneficiaryBankAddress"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>{" "}
                      <div className="row mb-2">
                        <InputField
                          name="beneficiarySwiftCode"
                          className="col-12"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE"
                          disabled={true}
                          inputClassName="text-uppercase"
                        />
                      </div>
                    </GroupBox>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-lg-6 ">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_AMOUNT_TITLE"}
                      className="h-100"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <InputField
                            name="currency"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE"
                            disabled={true}
                            inputClassName="text-uppercase"
                          />
                        </div>
                        <div className="col-lg-6">
                          <InputField
                            name="amount"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_DIGIT_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <span className="pl-1 text-danger">{""}</span>
                      <InputField
                        name="amountInWord"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      title={
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE"
                      }
                    >
                      <InputField
                        name="correspondentBankName1"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                      <span className="pl-1 text-danger">{""}</span>
                      <InputField
                        name="correspondentSwiftCode1"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                      <span className="pl-1 text-danger">{""}</span>
                    </GroupBox>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-lg-6">
                    <GroupBox
                      className="h-100"
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_TITLE"}
                    >
                      <RadioGroupField
                        name="chargesForTransfer"
                        direction={Direction.Vertical}
                        disabled={true}
                        items={[
                          {
                            value: "BEN",
                            title:
                              "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_BENEFICIARY_CHECKBOX_LABEL_TITLE",
                            infoTitle: InformationBody.InformationBodyTitle_2,
                          },
                          {
                            value: "OUR",
                            title:
                              "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_REMITTER_CHECKBOX_LABEL_TITLE",
                            infoTitle: InformationBody.InformationBodyTitle_3,
                          },
                          {
                            value: "SHA",
                            title:
                              "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_CHARGES_OF_JSC_CHECKBOX_LABEL_TITLE",
                            infoTitle: InformationBody.InformationBodyTitle_4,
                          },
                          {
                            value: "FND",
                            title:
                              "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FULL_NO_DEDUCT_CHECKBOX_LABEL_TITLE",
                            infoTitle: InformationBody.InformationBodyTitle_5,
                          },
                        ]}
                      />
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      title={
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE"
                      }
                    >
                      <InputField
                        name="correspondentBankName2"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                      <InputField
                        name="correspondentSwiftCode2"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                    </GroupBox>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-lg-6 ">
                    <GroupBox
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_FIELD_LABEL_TITLE"
                    >
                      <TextArea
                        name="detailsOfPayment"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_PAYMENT_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                        // @ts-ignore
                        value={transfer?.detailsOfPayment}
                      />
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_OTHERS_FIELD_LABEL_TITLE"}
                    >
                      <div className="row">
                        <div className="col-12 pb-2">
                          <InputField
                            name="commissionAccount"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DEBIT_COMMISSION_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                        <div className="col-12 pb-2">
                          <InputField
                            name="commissionAccountBalance"
                            label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <InputField
                        name="remarks"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_REMARKS_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                    </GroupBox>
                  </div>
                </div>

                <div className="col-12 d-flex flex-column mb-7">
                  <span className="font-weight-bold">
                    {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_1_TITLE")}
                  </span>
                  <span className="font-weight-bold">
                    {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_2_TITLE")}
                  </span>
                  <span>
                    {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_TITLE")}
                  </span>
                </div>
                {
                  //@ts-ignore
                  transfer?.currency === "RUB" && (
                    <GroupBox contentClassName="row">
                      <div className="col-4">
                        <InputField
                          name="kpp"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_KPP_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputField
                          name="correspondentAccount"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CORRESPONDENT_ACCOUNT_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputField
                          name="voCode"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_VO_CODE_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  )
                }
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
    </>
  );
}
