import React, { useCallback, useMemo, useState } from "react";
import { ConversionTable } from "./ConversionTable";
import { useResource } from "../../hooks/useResource";
import { useConversionContext } from "../../api/conversion/ConversionContext";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useI18n } from "../../i18n/I18nContext";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { Form, Formik } from "formik";
import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { noop } from "lodash";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly setDocumentsId: (value, appType) => void;
  readonly filter: PendingApprovalsFilter;
}
export function ConversionTableWrapper({ setDocumentsId, filter }: Props) {
  const { ConversionApi } = useConversionContext();
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const [conversionFCYBankView, setConversionFCYBankView] = useState(false);
  const [conversionLCYFCYView, setConversionLCYFCYView] = useState(false);
  const [conversionFCYFCYView, setConversionFCYFCYView] = useState(false);
  const [conversion, setConversion] = useState({});
  const getConversionResource = useResource(
    () =>
      ConversionApi.getConversions({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );

  useResourceHandler(getConversionResource, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  const data = useMemo(() => getConversionResource.data || [], [getConversionResource.data]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.Conversion);
    },
    [data, setDocumentsId],
  );

  const chooseTransferForView = useCallback(
    (value) => {
      setConversion({
        ...value,
        buyAmount: floatFormat(addZeros(value.buyAmount)),
        sellAmount: floatFormat(addZeros(value.sellAmount)),
        purposeOfConversion:
          value.purposeOfConversion === "1;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_OF_EQUIPMENT")
            : value.purposeOfConversion === "2;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_OF_COMPONENTRY")
            : value.purposeOfConversion === "3;"
            ? translate("CONVERSION_UZS_TO_FCY_RAW_MATERIALS")
            : value.purposeOfConversion === "4;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_OF_SERVICE")
            : value.purposeOfConversion === "5;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_OF_CONSUMER_GOODS")
            : value.purposeOfConversion === "6;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_TO_SERVE_ATTACHED")
            : value.purposeOfConversion === "7;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_TO_EFFECT_LEASING_PAYMENTS")
            : value.purposeOfConversion === "8;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION")
            : value.purposeOfConversion === "9;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION_OF_EXCESS")
            : value.purposeOfConversion === "10;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_TO_PAY_TRAVELLING")
            : value.purposeOfConversion === "11;"
            ? translate("CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_TRANSFERRING_FUNDS")
            : value.purposeOfConversion === "12;"
            ? `${translate("CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_OTHER")}`
            : value.purposeOfConversion,
      });
      if (value.transactionType === "FTB") {
        setConversionFCYBankView(true);
      } else if (value.transactionType === "LTF") {
        setConversionLCYFCYView(true);
      } else if (value.transactionType === "FTF") {
        setConversionFCYFCYView(true);
      }
    },
    [translate],
  );

  return (
    <>
      <ConversionTable
        loading={getConversionResource.loading}
        data={data}
        setDocumentId={setDocumentId}
        chooseTransferForView={chooseTransferForView}
      />
      <Modal
        onClose={() => setConversionFCYBankView(false)}
        width={1200}
        show={conversionFCYBankView}
        title={"PENDING_APPROVALS_CONVERSION_TAB_TITLE"}
      >
        <TabPage>
          <Formik initialValues={conversion} onSubmit={noop}>
            {() => (
              <Form className="d-flex flex-column p-7 bg-white">
                <h6>{translate("CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_TITLE")}</h6>
                <br />
                <div className="row mb-5">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6 d-flex ml-5">
                        <InputField
                          className="flex-grow-1 flex-shrink-1"
                          name="transactionDate"
                          label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <GroupBox
                      className="h-100"
                      title={"CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_TITLE"}
                    >
                      <InputField
                        className="mb-2"
                        name="debtAccount"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                      <InputField
                        className="mb-2"
                        name="sellAmount"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                      <InputField
                        className="mb-2"
                        name="sellCurrency"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                    </GroupBox>
                  </div>
                  <div className="col-6">
                    <GroupBox
                      className="h-100"
                      title={"CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_TITLE"}
                    >
                      <InputField
                        className="mb-2"
                        name="creditAccount"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                      <InputField
                        className="mb-2"
                        name="buyAmount"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_AMOUNT_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                      <InputField
                        className="mb-2"
                        name="buyCurrency"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_CURRENCY_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                    </GroupBox>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <GroupBox
                      className="h-100"
                      title={"CONVERSION_UZS_TO_FCY_TABLE_DETAILS_COLUMN_TITLE"}
                    >
                      <InputField
                        className="mb-2"
                        name="minimumRate"
                        label="PENDING_APPROVALS_TRANSFERS_TABLE_MINIMUM_RATE_CODE_COLUMN_TITLE"
                        disabled={true}
                      />
                      <InputField
                        className="mb-2"
                        name="purposeOfConversion"
                        label="CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PURPOSE_OF_CONVERSION_FIELD_LABEL_TITLE"
                        disabled={true}
                      />
                    </GroupBox>
                  </div>
                  <div className="col-6">
                    <GroupBox className="h-100" title={"CONVERSION_DOCUMENTS"}>
                      <InputField
                        className="mb-2"
                        name="documents"
                        label="CONVERSION_DOCUMENTS_TEXT"
                        disabled={true}
                      />
                      <span className="font-weight-bold">{translate("CONVERSION_OFFER_TEXT")}</span>
                      <br />
                      <span>{translate("CONVERSION_OFFER")}</span>
                    </GroupBox>
                  </div>
                  <span className="ml-4 mt-2">{translate("CONVERSION_TEXT_AUTHORIZE")}</span>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setConversionLCYFCYView(false)}
        width={1200}
        show={conversionLCYFCYView}
        title={"PENDING_APPROVALS_CONVERSION_TAB_TITLE"}
      >
        <TabPage>
          <Formik initialValues={conversion} onSubmit={noop}>
            {() => (
              <Form>
                <div className="px-7 py-7 mb-7 bg-white border br-1 border-gray-light">
                  <div className="d-flex mx-3">
                    <GroupBox
                      className="h-100 account-group w-50"
                      title={"PENDING_APPROVALS_ACCOUNTS_TAB_TITLE"}
                    >
                      <div className="row">
                        <div className="col-12">
                          <InputField
                            name="debtAccount"
                            label="CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_TITLE"
                            disabled={true}
                          />
                        </div>
                        <div className="col-12">
                          <InputField
                            className="mt-3"
                            name="creditAccount"
                            label="CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_TITLE"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </GroupBox>
                    <GroupBox
                      className="h-100 account-group ml-7 w-50"
                      title={"CONVERSION_UZS_TO_FCY_PURPOSE"}
                    >
                      <div className="row">
                        <div className="col-12">
                          <InputField name="purposeOfConversion" disabled={true} className="pt-2" />
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                  <div className="mx-3">
                    <GroupBox
                      className="row h-100 mt-5"
                      contentClassName="col-12"
                      title={"CONVERSION_UZS_TO_FCY_TITLE"}
                    >
                      <div className="row mt-7">
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="buyAmount"
                            label="CONVERSION_UZS_TO_FCY_FORM_BUY_AMOUNT"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="maximumRate"
                            label="CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_BUY_RATE"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="reservedAmountInUzs"
                            label="CONVERSION_UZS_TO_FCY_FORM_RESERVED_AMOUNT"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="numberAndDateContract"
                            label="CONVERSION_UZS_TO_FCY_FORM_NUMBER_AND_DATE"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="maximumAmountInUzs"
                            label="CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_AMOUNT_UZS"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                  <div className="mx-3">
                    <GroupBox
                      className="row h-100 mt-5"
                      contentClassName="col-12"
                      title={"CONVERSION_UZS_TO_FCY_ATTACHE_TITLE"}
                    >
                      <div className="row mt-2">
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument1"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_1"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument2"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_2"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument3"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_3"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument4"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_4"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument5"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_5"
                            disabled={true}
                          />
                        </div>
                        <div className="col-4">
                          <InputField
                            className="flex-grow-1 flex-shrink-1"
                            name="attachedDocument6"
                            label="CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_6"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="d-flex">
                          <div
                            className="d-flex justify-content-sm-center align-items-center infIcon cursor-pointer position-relative rounded-circle border border-blue-light info-icon"
                            style={{ margin: "0" }}
                          >
                            <svg
                              x="0px"
                              className="w-100 h-100"
                              y="0px"
                              viewBox="0 0 111.577 111.577"
                              xmlSpace="preserve"
                            >
                              <path d="M78.962 99.536l-1.559 6.373c-4.677 1.846-8.413 3.251-11.195 4.217-2.785.969-6.021 1.451-9.708 1.451-5.662 0-10.066-1.387-13.207-4.142-3.141-2.766-4.712-6.271-4.712-10.523 0-1.646.114-3.339.351-5.064.239-1.727.619-3.672 1.139-5.846l5.845-20.688c.52-1.981.962-3.858 1.316-5.633.359-1.764.532-3.387.532-4.848 0-2.642-.547-4.49-1.636-5.529-1.089-1.036-3.167-1.562-6.252-1.562-1.511 0-3.064.242-4.647.71-1.59.47-2.949.924-4.09 1.346l1.563-6.378c3.829-1.559 7.489-2.894 10.99-4.002 3.501-1.111 6.809-1.667 9.938-1.667 5.623 0 9.962 1.359 13.009 4.077 3.047 2.72 4.57 6.246 4.57 10.591 0 .899-.1 2.483-.315 4.747-.21 2.269-.601 4.348-1.171 6.239l-5.82 20.605c-.477 1.655-.906 3.547-1.279 5.676-.385 2.115-.569 3.731-.569 4.815 0 2.736.61 4.604 1.833 5.597 1.232.993 3.354 1.487 6.368 1.487 1.415 0 3.025-.251 4.814-.744 1.784-.493 3.085-.926 3.892-1.305zm1.476-86.506c0 3.59-1.353 6.656-4.072 9.177-2.712 2.53-5.98 3.796-9.803 3.796-3.835 0-7.111-1.266-9.854-3.796-2.738-2.522-4.11-5.587-4.11-9.177 0-3.583 1.372-6.654 4.11-9.207C59.447 1.274 62.729 0 66.563 0c3.822 0 7.091 1.277 9.803 3.823 2.721 2.553 4.072 5.625 4.072 9.207z" />
                            </svg>
                          </div>
                          <span className="ml-2 text-blue-light">
                            {translate("CONVERSION_ATTECH_TITLE")}:{" "}
                            <span className="font-weight-bold">ccd@kdb.uz</span>
                          </span>
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setConversionFCYFCYView(false)}
        width={1200}
        show={conversionFCYFCYView}
        title={"PENDING_APPROVALS_CONVERSION_TAB_TITLE"}
      >
        <TabPage>
          <Formik initialValues={conversion} onSubmit={noop}>
            {() => (
              <Form>
                <div className="px-7 py-7 bg-white border br-1 border-gray-light">
                  <div className="m-5">
                    <span>{translate("CONVERSION_FCY_TO_FCY_TITLE")}</span>
                  </div>
                  <div className="mb-7">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6 d-flex ml-5">
                            <InputField
                              className="flex-grow-1 flex-shrink-1"
                              name="transactionDate"
                              label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <div className="col-6">
                      <GroupBox
                        className="h-100"
                        title={"CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_TITLE"}
                      >
                        <InputField
                          className="mb-2"
                          name="debtAccount"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DEBIT_ACCOUNT_LABEL_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="exchangeRate"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_EXCHANGE_RATE_LABEL_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="sellAmount"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="sellCurrency"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </GroupBox>
                    </div>
                    <div className="col-6">
                      <GroupBox title="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_TITLE">
                        <InputField
                          className="mb-2"
                          name="creditAccount"
                          label="PENDING_APPROVALS_CONVERSION_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="purposeOfConversion"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PURPOSE_OF_CONVERSION_LABEL_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="buyAmount"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_AMOUNT_LABEL_TITLE"
                          disabled={true}
                        />
                        <InputField
                          className="mb-2"
                          name="buyCurrency"
                          label="CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_CURRENCY_LABEL_TITLE"
                          disabled={true}
                        />
                      </GroupBox>
                    </div>
                  </div>
                  <div className="d-flex flex-column pb-7 ">
                    <span className="ml-5 mb-5 font-weight-bold fs-5">
                      {translate("CONVERSION_OFFER_TEXT")}
                    </span>
                    <span className="ml-5">{translate("CONVERSION_OFFER")}</span>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
    </>
  );
}
