import React, { useMemo } from "react";
import { toFinite } from "lodash";

import { useI18n } from "../../i18n/I18nContext";
import { AccountCardBoxProps } from "../../api/account/AccountDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import "./assets/account-table.scss";

interface Props {
  readonly loading: boolean;
  readonly data: AccountCardBoxProps[];
}

export function CardBoxTable({ data = [], loading }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<AccountCardBoxProps>[] = [
      {
        width: 20,
        accessor: "forId",
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{toFinite(row.row.id) + 1}</span>,
        Header: "#",
      },
      {
        width: 90,
        accessor: "documentNumber",
        Header: translate("CARD_BOX_TITLE_DOCUMENT_NUMBER"),
      },
      {
        width: 100,
        accessor: "documentDate",
        Header: translate("CARD_BOX_DATA"),
      },
      {
        width: 100,
        accessor: "displacementDate",
        Header: translate("CARD_BOX_DATA_DISPLACEMENT"),
      },
      {
        width: 150,
        accessor: "restAmount",
        Header: translate("CARD_BOX_AMOUNT"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div>{row.value ? floatFormat(addZeros(row.value)) : ""}</div>,
      },
      {
        width: 150,
        accessor: "announcedAmount",
        Header: translate("CARD_BOX_AMOUNT_ANNOUNCED"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div>{row.value ? floatFormat(addZeros(row.value)) : ""}</div>,
      },
      {
        width: 100,
        accessor: "recipientBankMfo",
        Header: translate("CARD_BOX_MFO_RECEIVER"),
      },
      {
        width: 180,
        accessor: "recipientAccountNumber",
        Header: translate("CARD_BOX_ACCOUNT_NUMBER_RECEIVER"),
      },
      {
        width: 150,
        accessor: "recipientName",
        Header: translate("CARD_BOX_RECEIVER_NAME"),
      },
      {
        width: 200,
        accessor: "paymentPurpose",
        Header: translate("CARD_BOX_DETAILS"),
      },
    ];

    return list;
  }, [translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
