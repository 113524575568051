import cx from "classnames";
import React, { ReactNode, useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { I18nCode } from "../../i18n/I18nSchema";

import "./assets/flat-button.scss";

interface Props {
  readonly title: I18nCode | string;

  readonly leftIcon?: ReactNode;
  readonly rightIcon?: ReactNode;

  readonly onClick?: () => void;

  readonly className?: string;
  readonly titleClassName?: string;
  readonly titleContainerClassName?: string;
}

export function FlatButton({
  title,
  onClick,
  leftIcon,
  rightIcon,
  className,
  titleClassName,
  titleContainerClassName,
}: Props) {
  const { translate } = useI18n();

  const text = useMemo(() => translate(title), [title, translate]);

  return (
    <div
      role="button"
      onClick={onClick}
      className={cx("d-flex cursor-pointer user-select-none hover-btn", className)}
    >
      {Boolean(leftIcon) && (
        <div className="d-flex align-items-center justify-content-center mr-2">{leftIcon}</div>
      )}

      <div
        className={cx(
          "d-flex align-items-center flex-grow-1 flex-shrink-1",
          titleContainerClassName,
        )}
      >
        <span className={cx("font-weight-bold", titleClassName)}>{text}</span>
      </div>

      {Boolean(rightIcon) && (
        <div className="d-flex align-items-center justify-content-center ml-2">{rightIcon}</div>
      )}
    </div>
  );
}
