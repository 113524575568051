import { AppFilter, AppFilterProps } from "./AppFilter";

export enum PendingApprovalsTabs {
  Salary = "salary",
  Accounts = "accounts",
  TransfersLCY = "transfersLCY",
  TransfersFCY = "transfersFCY",
  Conversion = "conversion",
  CorporateCards = "corporate-cards",
  SentPaymentClaim = "sent-payment-claim",
}

export class PendingApprovalsFilter extends AppFilter<PendingApprovalsTabs> {
  public constructor({ tab, ...props } = {} as AppFilterProps<PendingApprovalsTabs>) {
    super({ ...props, tab: tab || PendingApprovalsTabs.TransfersLCY });
  }
}
