import React from "react";

import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";

interface Props {
  readonly onCreateLcyClick: () => void;
}

export function TransfersLCYTabControls({ onCreateLcyClick }: Props) {
  return (
    <div className="d-flex justify-content-between mt-4 mb-5 align-items-center">
      <div className="d-flex">
        <Button
          active={true}
          className="mr-3"
          size={SizeType.Large}
          onClick={onCreateLcyClick}
          color={ButtonColor.Default}
          labelCode="TRANSFERS_TRANSFERS_CREATE_NEW_LCY_BUTTON_TITLE"
        />
      </div>
    </div>
  );
}
