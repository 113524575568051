import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { ReferenceBookBudgetAccountProps } from "../../api/reference-book/ReferenceBookDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly loading: boolean;
  readonly data: ReferenceBookBudgetAccountProps[];
  readonly onCorrespondentAccountSelect: (value) => void;
}

export function BudgetAccountTable({ data = [], loading, onCorrespondentAccountSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<ReferenceBookBudgetAccountProps>[] = [
      {
        width: 60,
        accessor: "taxNumber",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE",
        ),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              style={{ cursor: "pointer" }}
              onClick={() =>
                onCorrespondentAccountSelect && onCorrespondentAccountSelect(row.value)
              }
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 150,
        accessor: "budgetAccount",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE",
        ),
      },
      {
        width: 230,
        accessor: "budgetAccountName",
        Header: translate(
          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE",
        ),
      },
    ];

    return list;
  }, [onCorrespondentAccountSelect, translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
