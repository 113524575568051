import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import { MenuState, MobileMenu, ViewMode } from "../api/dto/AppDTO";
import { AppLanguage, getDefaultLanguage } from "../i18n/I18nContext";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import { AppTheme, getDefaultTheme } from "../theme/ThemeContext";

export const appReducerPersistConfig: Partial<PersistConfig<AppReducerState>> = {
  whitelist: [
    "version",
    "language",
    "menuState",
    "viewMode",
    "theme",
    "mobileMenu",
    "notificationMessage",
  ],
};

interface ChangeVersionMeta {
  readonly version: string;
}

interface SwitchLanguageMeta {
  readonly language: AppLanguage;
}

interface ChangeViewModeMeta {
  readonly viewMode: ViewMode;
}

interface ChangeMenuStateMeta {
  readonly state: MenuState;
}

interface ChangeThemeStateMeta {
  readonly theme: AppTheme;
}

interface ChangeNotificationMeta {
  readonly notification: string;
}

interface ChangeNotificationMessageMeta {
  readonly notificationMessage: string;
}

interface ChangeNotificationMetaCount {
  readonly count?: string;
}

interface ChangeMobileMenuMeta {
  readonly type: MobileMenu;
}

enum ReducerActions {
  ChangeTheme = "App/ChangeTheme",
  ChangeVersion = "App/ChangeVersion",
  SwitchLanguage = "App/SwitchLanguage",
  ChangeViewMode = "App/ChangeViewMode",
  ChangeMenuState = "App/ChangeMenuState",
  ChangeNotification = "App/Notification",
  ChangeNotificationMessage = "App/NotificationMessage",
  ChangeNotificationCount = "App/Notification/count",
  ChangeMobileMenu = "App/ChangeMobileMenu",
}

export interface AppReducerState {
  readonly version: string;
  readonly viewMode: ViewMode;
  readonly menuState: MenuState;
  readonly language: AppLanguage;
  readonly theme: AppTheme;
  readonly notification: string;
  readonly notificationMessage: string;
  readonly count?: string;
  readonly mobileMenu: MobileMenu;
}

function getState(): AppReducerState {
  return {
    version: "0.0.1",
    menuState: MenuState.Expanded,
    language: getDefaultLanguage(),
    viewMode: ViewMode.Undetermined,
    theme: getDefaultTheme(),
    notification: "",
    notificationMessage: "",
    mobileMenu: MobileMenu.Closed,
  };
}

export const appReducer = createRootReducer<AppReducerState>(
  getState(),

  createReducer([ReducerActions.ChangeVersion], (state, { meta }) =>
    update(state, { version: meta.version }),
  ),

  createReducer([ReducerActions.SwitchLanguage], (state, { meta }) =>
    update(state, { language: meta.language }),
  ),

  createReducer([ReducerActions.ChangeViewMode], (state, { meta }) =>
    update(state, { viewMode: meta.viewMode }),
  ),

  createReducer([ReducerActions.ChangeMenuState], (state, { meta }) =>
    update(state, { menuState: meta.state }),
  ),

  createReducer([ReducerActions.ChangeTheme], (state, { meta }) =>
    update(state, { theme: meta.theme }),
  ),

  createReducer([ReducerActions.ChangeMobileMenu], (state, { meta }) =>
    update(state, { mobileMenu: meta.type }),
  ),

  createReducer([ReducerActions.ChangeNotification], (state, { meta }) =>
    update(state, { notification: meta.notification }),
  ),

  createReducer([ReducerActions.ChangeNotificationMessage], (state, { meta }) =>
    update(state, { notificationMessage: meta.notificationMessage }),
  ),

  createReducer([ReducerActions.ChangeNotificationCount], (state, { meta }) =>
    update(state, { count: meta.count }),
  ),
);

// ==================
// Selectors
// ==================

export const appLanguageSelector = ({ app }: AppStoreState): AppLanguage =>
  app.language || getDefaultLanguage();

export const viewModeSelector = ({ app }: AppStoreState): ViewMode => app.viewMode;

export const menuStateSelector = ({ app }: AppStoreState): MenuState => app.menuState;

export const mobileMenuSelector = ({ app }: AppStoreState) => app.mobileMenu;

export const notificationSelector = ({ app }: AppStoreState) => app.notification;

export const notificationMessageSelector = ({ app }: AppStoreState) => app.notificationMessage;

export const notificationCountSelector = ({ app }: AppStoreState) => app.count;

export const themeSelector = ({ app }: AppStoreState): AppTheme => app.theme;

// ==================
// Actions
// ==================

export function changeVersion(meta: ChangeVersionMeta): PerformAction<ChangeVersionMeta> {
  return {
    type: ReducerActions.ChangeVersion,
    meta,
  };
}

export function switchLanguage(meta: SwitchLanguageMeta): PerformAction<SwitchLanguageMeta> {
  return { type: ReducerActions.SwitchLanguage, meta };
}

export function changeNotificationState(
  meta: ChangeNotificationMeta,
): PerformAction<ChangeNotificationMeta> {
  return { meta, type: ReducerActions.ChangeNotification };
}

export function changeNotificationMessageState(
  meta: ChangeNotificationMessageMeta,
): PerformAction<ChangeNotificationMessageMeta> {
  return { meta, type: ReducerActions.ChangeNotificationMessage };
}

export function changeNotificationStateCount(
  meta: ChangeNotificationMetaCount,
): PerformAction<ChangeNotificationMetaCount> {
  return { meta, type: ReducerActions.ChangeNotificationCount };
}

export function changeViewMode(meta: ChangeViewModeMeta): PerformAction<ChangeViewModeMeta> {
  return { meta, type: ReducerActions.ChangeViewMode };
}

export function changeMenuState(meta: ChangeMenuStateMeta): PerformAction<ChangeMenuStateMeta> {
  return { meta, type: ReducerActions.ChangeMenuState };
}

export function switchTheme(meta: ChangeThemeStateMeta): PerformAction<ChangeThemeStateMeta> {
  return { meta, type: ReducerActions.ChangeTheme };
}

export function changeMobileMenu(meta: ChangeMobileMenuMeta): PerformAction<ChangeMobileMenuMeta> {
  return { meta, type: ReducerActions.ChangeMobileMenu };
}
