import { useField } from "formik";
import React, { useMemo } from "react";

import { useResource } from "../../hooks/useResource";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { SelectPicker, SelectPickerProps } from "../ui/SelectPicker";

interface Props extends Omit<SelectPickerProps, "value"> {
  readonly name: string;
  readonly defaultValue?: any;
}

// import uzcardIcon from "../icons/uzcard-icon.jpg";
// import humoIcon from "../icons/humo-icon.jpg";

export function DebitAccountNumberFieldWrapper({ name, onChange, defaultValue, ...props }: Props) {
  const [field, , helpers] = useField(name);

  const { SalaryApi } = useSalaryContext();

  const accountNumbersResource = useResource(() => SalaryApi.getDebitAccountNumbers(), []);

  const debitNumbersList = useMemo(() => accountNumbersResource.data || [], [
    accountNumbersResource.data,
  ]);

  const formattedList = useMemo(
    () =>
      debitNumbersList.map((x) => ({
        value: x.corporateAccount,
        label: x.corporateAccount,
        balance: x.accountBalance,
        // image: x.corporateAccount.substr(17, 1) !== "0" ? humoIcon : uzcardIcon,
      })),
    [debitNumbersList],
  );

  const loading = useMemo(() => formattedList.length === 0 && accountNumbersResource.loading, [
    formattedList,
    accountNumbersResource.loading,
  ]);

  return (
    <SelectPicker
      {...props}
      defaultValue={defaultValue}
      fluid={true}
      value={field.value}
      isLoading={loading}
      options={formattedList}
      onChange={(x) => {
        if (onChange) {
          onChange(x);
        }

        helpers.setValue(x);
      }}
    />
  );
}
