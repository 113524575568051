import { I18nSchema } from "./I18nSchema";
import { I18nEnglish } from "./I18nEnglish";

export const I18nRussian: I18nSchema = {
  ...I18nEnglish,

  ENGLISH_LANGUAGE: "Английский",
  RUSSIAN_LANGUAGE: "Русский",
  UZBEK_LANGUAGE: "Узбекский",
  SHORT_EN_LANGUAGE: "EN",
  SHORT_RU_LANGUAGE: "RU",
  SHORT_UZ_LANGUAGE: "UZ",

  //Months
  MONTH_MAY_TITLE: "Май",
  MONTH_JUN_TITLE: "Июнь",
  MONTH_JULY_TITLE: "Июль",
  MONTH_MARCH_TITLE: "Март",
  MONTH_APRIL_TITLE: "Апрель",
  MONTH_AUGUST_TITLE: "Август",
  MONTH_JANUARY_TITLE: "Январь",
  MONTH_OCTOBER_TITLE: "Октябрь",
  MONTH_FEBRUARY_TITLE: "Февраль",
  MONTH_NOVEMBER_TITLE: "Ноябрь",
  MONTH_DECEMBER_TITLE: "Декабрь",
  MONTH_SEPTEMBER_TITLE: "Сентябрь",

  // Forms
  FORMS_REQUIRED_FIELD_ERROR_TITLE: "Требуется",
  FORMS_PASSWORDS_NOT_MUCH_FIELD_ERROR_TITLE: "Пароли не совпадают",
  WRONG_CURRENCY_TYPE: "Неверный тип валюты",

  // Auth Route
  AUTH_TAB_CORPORATE_TITLE: "Корпоративный Клиент",
  AUTH_TAB_INDIVIDUAL_TITLE: "Индивидуальный Клиент",
  AUTH_TAB_FINANCIAL_TITLE: "Финансовое Учреждение",

  AUTH_USER_ID_TITLE: "Имя пользователя",
  AUTH_LOGIN_TITLE: "Логин",
  AUTH_BACK_BUTTON_TITLE: "Назад",
  AUTH_PASSWORD_TITLE: "Пароль",
  AUTH_LOGIN_BUTTON_TITLE: "Войти",
  AUTH_LOGO_TITLE: "КДБ Банк Узбекистан",
  AUTH_LOGO_SUB_TITLE: "Интернет Банкинг iDBA",
  AUTH_FORM_LINK_TITLE: "Публичная оферта интернет-банка",
  AUTH_SECURITY_TITLE:
    "Не отвечайте на электронные письма, в которых запрашивают информацию об учетной записи или пароле, и не переходите по ссылкам, которые они предлагают.",
  AUTH_TOKEN_FIELD_LABEL_TITLE: "Электронный Токен",
  AUTH_CHANGE_PASSWORD_TITLE: "Пожалуйста, измените пароль в целях безопасности",
  // AUTH_CHANGE_PASSWORD_TITLE: "Измени пароль",
  AUTH_RESET_PASSWORD_BUTTON_TITLE: "Сбросить пароль",
  AUTH_FORGOT_PASSWORD_LINK_TITLE: "Забыл пароль",
  AUTH_CHANGE_PASSWORD_BUTTON_TITLE: "Сменить пароль",
  AUTH_RESET_FORM_ENTER_LOGIN_FIELD_LABEL_TITLE: "Зарегестрированный адрес электронной почты",
  AUTH_INTERNET_BANKING_LINK_TITLE: "Публичная оферта интернет-банка",
  AUTH_VIEW_ONLY_CHECKBOX_TITLE: "Только для просмотра (без OTP-ключа)",
  AUTH_VIEW_ONLY_WITH_CHECKBOX_TITLE:
    "Для перевода денежных средств в иностранной валюта при помощи ОТП ключа",
  AUTH_CHANGE_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Новый пароль",
  AUTH_CHANGE_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Текущий пароль",
  AUTH_RESET_PASSWORD_TITLE: "Заполните следующие поля, чтобы получить временный пароль",
  AUTH_CHANGE_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE: " Подтвердите новый пароль",
  AUTH_RESET_FORM_CHECK_OTP_KEY_FIELD_LABEL_TITLE: "Проверить ключ OTP",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_1:
    "Ваше имя пользователя и пароль являются строго личными, и вы не должны никому их раскрывать.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_2:
    "Не отвечайте на электронные письма, в которых запрашивают информацию об учетной записи или пароле, и не переходите по ссылкам, которые они предлагают.",
  AUTH_SECURITY_TIPS_SLIDE_ITEM_3:
    "Регулярно проверяйте свои счета, если вы видите расхождения в сумме, немедленно сообщите в Банк.",

  OTP_EXPIRED_SESSION_SCREEN_TITLE: "Сессия истекла",

  WRONG_OTP_KEY: " Ошибка авторизации OTP (повторите попытку через 1 минуту)",

  // Side Menu
  SIDE_MENU_MAIN_TITLE: "Главная",
  SIDE_MENU_LOAN_TITLE: "Кредиты",
  SIDE_MENU_YEISVO_TITLE: "ЕЭИСВО",
  SIDE_MENU_ACCOUNTS_TITLE: "Ведение Счета",
  SIDE_MENU_TRANSFERS_TITLE: "Платежные поручения",
  SIDE_MENU_ANALYTICS_TITLE: "Аналитика",
  SIDE_MENU_CONVERSION_TITLE: "Конвертация",
  SIDE_MENU_CONTACT_US_TITLE: "Контакты",
  SIDE_MENU_AGREEMENTS_TITLE: "Договоры и формы",
  SIDE_MENU_REFERENCE_TITLE: "Справочник",
  SIDE_MENU_HELP_AND_FAQ_TITLE: "Часто задаваемые вопросы",
  SIDE_MENU_NOTIFICATIONS_TITLE: "Уведомления",
  SIDE_MENU_CLIENT_FEEDBACK_TITLE: "Отзыв клиента",
  SIDE_MENU_WITHDRAWAL_TITLE: "Вывод кредитных средств",
  SIDE_MENU_PENDING_APPROVALS_TITLE: "Документы к утверждению",
  SIDE_MENU_USER_INFO_AND_SETTINGS_TITLE: "Настройки",
  SIDE_MENU_PRE_APPROVAL_TITLE: "Предварительное одобрение новой заявки на ссуду и аккредитив",
  SIDE_MENU_REQUESTS_TITLE:
    "Запросы на Реестрацию заявки на получение индивидуального кредита и аккредитива в системе НИКИ",
  SIDE_MENU_PAYMENT_CLAIM_TITLE: "Платеж.Требования/",
  SIDE_MENU_COLLECTION_ORDER_TITLE: "Инкасс.Поручение",
  SIDE_MENU_EXPAND_BUTTON_TITLE: "Развернуть",
  SIDE_MENU_COLLAPSE_BUTTON_TITLE: "Свернуть",

  //Trade finance

  TRADE_FINANCE_HEADER_TITLE: "Список аккредитивов",
  TRADE_FINANCE_CREDITS_HEADER_TITLE: "Аккредитив",
  SIDE_MENU_TRADE_FINANCE_TITLE: "Торговое финансирование",
  TRADE_FINANCE_CONTRACTS_TABLE_APP_NO_COLUMN_TITLE: "№ Заявка",
  TRADE_FINANCE_CONTRACTS_TABLE_LC_NO_COLUMN_TITLE: "№ аккредитива",
  TRADE_FINANCE_CONTRACTS_TABLE_APPLICANT_COLUMN_TITLE: "Заявитель",
  TRADE_FINANCE_CONTRACTS_TABLE_BENEFICIARY_COLUMN_TITLE: "Бенефициар",
  TRADE_FINANCE_CONTRACTS_TABLE_CURRENCY_COLUMN_TITLE: "Валюта",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_COLUMN_TITLE: "Количество",
  TRADE_FINANCE_CONTRACTS_TABLE_DATE_COLUMN_TITLE: "Дата",
  TRADE_FINANCE_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_APP_BUTTON_TITLE: "Новое Заявление",
  TRADE_FINANCE_CONTRACTS_TABLE_BACK_BUTTON_TITLE: "Назад",
  TRADE_FINANCE_SUBMIT_BUTTON_TITLE: "Отправить",
  TRADE_FINANCE_CONTRACTS_TABLE_ADVISING_BANK_TITLE: "Авизующий банк",
  TRADE_FINANCE_CONTRACTS_TABLE_CREATE_NEW_AMENDMENT_BUTTON_TITLE: "Новая поправка",
  TRADE_FINANCE_CONTRACTS_TABLE_ACTIONS_COLUMN_TITLE: "Actions",
  TRADE_FINANCE_CONTRACTS_TABLE_DESCREPENCY_COLUMN_TITLE: "Несоответствии",
  TRADE_FINANCE_CONTRACTS_TABLE_MODIFY_COLUMN_TITLE: "Modify",
  TRADE_FINANCE_CONTRACTS_TABLE_SUBMITED_COLUMN_TITLE: "Submited",
  TRADE_FINANCE_DISCREPANCY_LETTER: "Письмо о несоответствии",
  ACCEPT_DISCREPANCIES_YES_NO: "Принять несоответствия",
  TRADE_FINANCE_ACCEPTED_AMOUNT: "принятая сумма",
  TRADE_FINANCE_DISCREPANCY_MENU_MODAL_LETTER: "Discrepency Menu",
  TRADE_FINANCE_CONTRACTS_TABLE_AMENDMENT_COLUMN_TITLE: "Изменения условий",
  TRADE_FINANCE_CONTRACTS_FORM_NUMBER: "Номер",
  TRADE_FINANCE_CONTRACTS_FORM_DATA: "Дата",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_NAME: "Имя заявителя",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_ADDRESS: "Адрес заявителя",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_POST_CODE: "Почтовый индекс заявителя",
  TRADE_FINANCE_CONTRACTS_FORM_APPLICANT_PHONE: "Телефон заявителя",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_NAME: "Имя Бенефициар",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_ADDRESS: "Адрес Бенефициар",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_POST_CODE: "Почтовый индекс Бенефициар",
  TRADE_FINANCE_CONTRACTS_FORM_BENEFICIARY_PHONE: "Телефон Бенефициар",
  TRADE_FINANCE_CONTRACTS_FORM_TO: "В Банк",
  TRADE_FINANCE_CONTRACTS_FORM_DATA_OF_EXPIRY: "Дата истечения срока",
  TRADE_FINANCE_CONTRACTS_FORM_PLACE_OF_EXPIRY: "Место  истечения срока",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT: "Последний день отправки",
  TRADE_FINANCE_CONTRACTS_FORM_LATETS_DATA_OF_SHIPMENT_OR__SHIPMENT_PERIOD:
    "Lates data of shipment or Shipment period",
  TRADE_FINANCE_CONTRACTS_FORM_SHIPMENT_PERIOD: "Период отгрузки",
  TRADE_FINANCE_CONTRACTS_FORM_PLEASE_OPEN_LETTER_OF_CREDIT: "Пожалуйста откройте аккредитив",
  TRADE_FINANCE_CONTRACTS_FORM_IRREVOCABLE: "Безотзывный аккредитив",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSFERABLE: "Передаваемый аккредитив ",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTARY: "Документальный ",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMED: "Подтвержденный ",
  TRADE_FINANCE_CONTRACTS_FORM_ADVICE_BY: "Авизования",
  TRADE_FINANCE_CONTRACTS_FORM_CABLE_YES_OR_NO: "Кабель ",
  TRADE_FINANCE_CONTRACTS_FORM_AIR_MAIL: "Воздушная почта ",
  TRADE_FINANCE_CONTRACTS_FORM_CURRENCY: "Валюта",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT: "Количество",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_IN_WORDS: "Сумма прописью",
  TRADE_FINANCE_CONTRACTS_FORM_ADVISING_BANK: "Авизующий банк",
  TRADE_FINANCE_CONTRACTS_FORM_TERMS_OF_PAYMET: "Условия платежа",
  TRADE_FINANCE_CONTRACTS_FORM_AGAINST_PRESINTATION_DOCUMENTS: "Против предъявления документов",
  TRADE_FINANCE_CONTRACTS_FORM_DEFEREND_PAYMENT: "Отсроченный платеж",
  TRADE_FINANCE_CONTRACTS_FORM_CONFIRMING_BANK: "Подтверждающий банк",
  TRADE_FINANCE_CONTRACTS_FORM_REIMBURSING_BANK: "Рамбурсирующий банк",
  TRADE_FINANCE_CONTRACTS_FORM_CREDIT_AVAILABLE_WITH_BY: "Кредит доступен с/от",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSSHIPMENT: "Перевалка ",
  TRADE_FINANCE_CONTRACTS_FORM_PARTIAL_SHIPMENT: "Частичная отгрузка",
  TRADE_FINANCE_CONTRACTS_FORM_TRANSPORATION_FROM: "Транспорт из",
  TRADE_FINANCE_CONTRACTS_FORM_TO_PLACE_OF_DELIVERY: "Куда/место доставки",
  TRADE_FINANCE_CONTRACTS_FORM_DESCRIPTION_OF_COMMODITY_AND_OR_SERVICES:
    "Описание товара и/или услуги",
  TRADE_FINANCE_CONTRACTS_FORM_DELIVERY_TERMS: "Условия доставки",
  TRADE_FINANCE_CONTRACTS_FORM_DOCUMENTS_REQUIRED: "Необходимые документы",
  TRADE_FINANCE_CONTRACTS_FORM_SPECIAL_INSTRUCTIONS: "Специальные инструкции",
  TRADE_FINANCE_CONTRACTS_FORM_PERIOD_FOR_PRESENTATION_OF_DOCUMENTS:
    "Срок предоставления документов",
  TRADE_FINANCE_CONTRACTS_FORM_LC_ACCOUNT_COVERED_FROM: "Аккредитив аккаунт снят с",
  TRADE_FINANCE_CONTRACTS_FORM_ACCOUNT_FOR_COMMISSIONS: "Учет комиссий",
  TRADE_FINANCE_CONTRACTS_FORM_COMMENTS: "Комментарии",
  TRADE_FINANCE_FORM_SENDER_LABEL_TITLE: "Отправитель",
  TRADE_FINANCE_FORM_BENEFICIARY_LABEL_TITLE: "Получатель",
  TRADE_FINANCE_FORM_AMOUNTS_LABEL_TITLE: "Amount",
  TRADE_FINANCE_CONTRACTS_FORM_YES: "Да",
  TRADE_FINANCE_CONTRACTS_FORM_NO: "Нет",
  TRADE_FINANCE_CONTRACTS_FORM_NEW_AMOUNT: "Новая количество",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_BANK_COLUMN_TITLE: "Сумма от банка",
  TRADE_FINANCE_CONTRACTS_TABLE_AMOUNT_FROM_CLIENT_COLUMN_TITLE: "Сумма от клиента",
  TRADE_FINANCE_CONTRACTS_FORM_CHANGING_CURRENCY: "Сдача",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_INCREASE: "Увеличивать",
  TRADE_FINANCE_CONTRACTS_FORM_AMOUNT_DECREASE: "Снижаться",

  // Pending Approvals Page

  PENDING_APPROVALS_SALARY_TAB_TITLE: "Зарплата",
  PENDING_APPROVALS_ACCOUNTS_TAB_TITLE: "Счета",
  PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE: " Платежи в Узб. сумах ",
  PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE: " Платежи в Ин. валюте ",
  PENDING_APPROVALS_CONVERSION_TAB_TITLE: "Конвертация",
  PENDING_APPROVALS_CORPORATE_CARDS_TAB_TITLE: "Корпоративные карты",
  PENDING_APPROVALS_SENT_PAYMENT_CLAIMS_TAB_TITLE: "Платежные требования",
  PENDING_APPROVALS_TRADE_FINANCE_TAB_TITLE: "Торговое финансирование",

  PENDING_APPROVALS_SELECT_REQUEST_TITLE: "Выберите запрос",
  PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE: "Показать исполнителя и авторизатора",

  PENDING_APPROVALS_ACCOUNT_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBJECT_COLUMN_TITLE: "Тема",
  PENDING_APPROVALS_ACCOUNT_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  PENDING_APPROVALS_ACCOUNT_TABLE_EXPLANATION_COLUMN_TITLE: "Комментарии",
  PENDING_APPROVALS_ACCOUNT_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  PENDING_APPROVALS_ACCOUNT_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Дата завершения",
  PENDING_APPROVALS_ACCOUNT_TABLE_SUBMISSION_DATE_COLUMN_TITLE: "Дата подачи",

  PENDING_APPROVALS_ACCOUNT_NONE_STANDART_INQUIRIES: "Нестандартные запросы",
  PENDING_APPROVALS_ACCOUNT_STANDART_INQUIRIES: "Стандартные запросы",
  PENDING_APPROVALS_ACCOUNT_NEW_ACCOUNT_OPENING: "Открытие нового счета",

  PENDING_APPROVALS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  PENDING_APPROVALS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Максимальный курс",
  PENDING_APPROVALS_TRANSFERS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Реестрационный №",
  PENDING_APPROVALS_TRANSFERS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Дебетовать счет",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Сумма покупки",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Обменный курс",
  PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_CDU_RATE_COLUMN_TITLE: "UZS",
  PENDING_APPROVALS_TRANSFERS_TABLE_CREDIT_AMOUNT_COLUMN_TITLE: "Кредитовать счет",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Сумма продажи",
  PENDING_APPROVALS_TRANSFERS_TABLE_MINIMUM_RATE_CODE_COLUMN_TITLE: "Минимальный курс",
  PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_CODE_COLUMN_TITLE: "Максимальный курс",
  PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Валюта покупки",
  PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Валюта продажи",
  PENDING_APPROVALS_TRANSFERS_TABLE_COMMISSION_AMOUNT_COLUMN_TITLE: "Сумма комиссии",

  PENDING_APPROVALS_SALARY_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  PENDING_APPROVALS_SALARY_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PENDING_APPROVALS_SALARY_TABLE_DETAILS_COLUMN_TITLE: " Детали",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_COLUMN_TITLE: "Реестр",
  PENDING_APPROVALS_SALARY_TABLE_CARD_TYPE_COLUMN_TITLE: "Тип карты",
  PENDING_APPROVALS_SALARY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  PENDING_APPROVALS_SALARY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  PENDING_APPROVALS_SALARY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Код платежа",
  PENDING_APPROVALS_SALARY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "№ Реестра",
  PENDING_APPROVALS_SALARY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Транзитный счет",
  PENDING_APPROVALS_SALARY_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Дата транзакции",
  PENDING_APPROVALS_SALARY_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Дата создания",

  TRANSFERS_SALARY_PAYROLL_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_SALARY_PAYROLL_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_SALARY_PAYROLL_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_COLUMN_TITLE: "Реестр",
  TRANSFERS_SALARY_PAYROLL_TABLE_CARD_TYPE_COLUMN_TITLE: "Тип карты",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  TRANSFERS_SALARY_PAYROLL_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_SALARY_PAYROLL_TABLE_PAYMENT_CODE_COLUMN_TITLE: "ID платежа",
  TRANSFERS_SALARY_PAYROLL_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "№ Реестра",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Транзитный счет",
  TRANSFERS_SALARY_PAYROLL_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Дата транзакции",
  TRANSFERS_SALARY_PAYROLL_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Дата создания",

  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_COLUMN_TITLE: "Реестр",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CARD_TYPE_COLUMN_TITLE: "Тип карты",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Код платежа",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "№ Реестра",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Транзитный счет",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Дата транзакции",
  PENDING_APPROVALS_CORPORATE_CARDS_TABLE_CREATION_DATE_AND_TIME_COLUMN_TITLE: "Дата создания",

  PENDING_APPROVALS_CONVERSION_TABLE_TYPE_COLUMN_TITLE: "Тип документа",
  PENDING_APPROVALS_CONVERSION_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PENDING_APPROVALS_CONVERSION_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  PENDING_APPROVALS_CONVERSION_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATOR_COLUMN_TITLE: "Исполнитель",
  PENDING_APPROVALS_CONVERSION_TABLE_CURRENCY_COLUMN_TITLE: "Валюта",
  PENDING_APPROVALS_CONVERSION_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  PENDING_APPROVALS_CONVERSION_TABLE_EXPLANATION_COLUMN_TITLE: "Комментарии",
  PENDING_APPROVALS_CONVERSION_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Кредитовать счет",
  PENDING_APPROVALS_CONVERSION_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Код банка получателя",
  PENDING_APPROVALS_CONVERSION_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Номер документа",
  PENDING_APPROVALS_CONVERSION_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Дата транзакции",
  PENDING_APPROVALS_CONVERSION_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета отправителя",
  PENDING_APPROVALS_CONVERSION_TABLE_CREATED_DATE_AND_TIME_COLUMN_TITLE: "Дата создания",
  PENDING_APPROVALS_CONVERSION_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Номер счета получателя",
  PENDING_APPROVALS_CONVERSION_TYPE_COLUMN_TITLE: "Тип конверсии",
  PENDING_APPROVALS_APPLICATION_OPENING: "Заявление",
  PENDING_APPROVALS_AMENDMENT_OPENING: "Изменения условий",
  PENDING_APPROVALS_DISCREPANCY_OPENING: "Несоответствии",

  // Pending Approvals Page

  ACCOUNTS_INQUIRY_TAB_TITLE: "Запрос",
  ACCOUNTS_ACCOUNTS_TAB_TITLE: "Счета",
  ACCOUNTS_STATEMENT_TAB_TITLE: "Выписка",
  ACCOUNTS_NEW_ACCOUNT_OPENING_TAB_TITLE: "Открытие нового счета",
  ACCOUNTS_OUTGOING_TAB_TITLE: "Исходящий Swift",
  ACCOUNTS_INCOMING_TAB_TITLE: "Входящий Swift",
  ACCOUNTS_INCOMING_COVER_TAB_TITLE: "Входящие Swift cообщения с покрытием",
  ACCOUNTS_REQUEST_NEW_ACCOUNT_OPENING_TAB_TITLE: "Запрос на открытие нового счета",

  ACCOUNTS_ACCOUNTS_BALANCE_AS_OF_TITLE: "Остаток на",
  ACCOUNTS_ACCOUNTS_EXCLUDE_ZERO_BALANCE_CHECKBOX_TITLE: "Исключить нулевой баланс",
  ACCOUNTS_ACCOUNTS_INCLUDE_CLOSED_ACCOUNTS_CHECKBOX_TITLE: "Показать закрытые счета",

  ACCOUNTS_ACCOUNTS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  ACCOUNTS_ACCOUNTS_TABLE_INQUIRY_COLUMN_TITLE: "Запрос",
  ACCOUNTS_ACCOUNTS_TABLE_BALANCE_COLUMN_TITLE: "Баланс счета",
  ACCOUNTS_ACCOUNTS_TABLE_CURRENCY_COLUMN_TITLE: "Валюта",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NAME_COLUMN_TITLE: "Наименование счета",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета",
  ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_OPENING_DATE_COLUMN_TITLE: "Дата открытия счета",
  ACCOUNTS_ACCOUNTS_TABLE_LAST_TRANSACTION_DATE_COLUMN_TITLE: "Дата последней транзакции",

  ACCOUNTS_STATEMENT_FILTER_TITLE: "Фильтр",
  ACCOUNTS_STATEMENT_FILTER_BUTTON_TITLE: "Фильтр",
  ACCOUNTS_STATEMENT_FILTER_FORM_TO_FIELD_LABEL_TITLE: "по",
  ACCOUNTS_STATEMENT_FILTER_FORM_YEAR_FIELD_LABEL_TITLE: "Год",
  ACCOUNTS_STATEMENT_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "с",
  ACCOUNTS_STATEMENT_FILTER_FORM_MONTH_FIELD_LABEL_TITLE: "Месяц",
  ACCOUNTS_STATEMENT_FILTER_FORM_GENERATE_BUTTON_TITLE: "Генерировать",
  ACCOUNTS_STATEMENT_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "только дебет",
  ACCOUNTS_STATEMENT_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "только кредит",
  ACCOUNTS_STATEMENT_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "все",
  ACCOUNTS_STATEMENT_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Поиск суммы",
  ACCOUNTS_STATEMENT_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Номер счета",
  ACCOUNTS_STATEMENT_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE:
    "Номер корреспондентского счета",
  ACCOUNTS_STATEMENT_FILTER_ERROR_TITLE: "Идёт закрытие дня, пожалуйста подождите!",

  ACCOUNT_STATEMENT_FORM_TO_FIELD_LABEL_TITLE: "по",
  ACCOUNT_STATEMENT_FORM_DETAILS_BUTTON_TITLE: "Детали",
  ACCOUNT_STATEMENT_FORM_FROM_FIELD_LABEL_TITLE: "с",
  ACCOUNT_STATEMENT_FORM_DEBIT_FIELD_LABEL_TITLE: "Дебит",
  ACCOUNT_STATEMENT_FORM_CREDIT_FIELD_LABEL_TITLE: "Кредит",
  ACCOUNT_STATEMENT_FORM_STATUS_FIELD_LABEL_TITLE: "Статус",
  ACCOUNT_STATEMENT_FORM_BALANCE_FIELD_LABEL_TITLE: "Баланс",
  ACCOUNT_STATEMENT_FORM_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  ACCOUNT_STATEMENT_FORM_DOC_NUMBER_FIELD_LABEL_TITLE: "Номер документа",
  ACCOUNT_STATEMENT_FORM_BANK_CODE_FIELD_PLACEHOLDER_TITLE: "Код банка",
  ACCOUNT_STATEMENT_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Наименование компании",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Тип счета",
  ACCOUNT_STATEMENT_FORM_COMPANY_PHONE_FIELD_LABEL_TITLE: "Телефон Компании",
  ACCOUNT_STATEMENT_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Номер ссчета",
  ACCOUNT_STATEMENT_FORM_OPENING_BALANCE_FIELD_LABEL_TITLE: "Начальное сальдо",
  ACCOUNT_STATEMENT_FORM_CLOSING_BALANCE_FIELD_LABEL_TITLE: "Конечное сальдо",
  ACCOUNT_STATEMENT_FORM_END_OF_STATEMENT_FIELD_LABEL_TITLE: "Конец выписки",
  ACCOUNT_STATEMENT_FORM_ITEM_STATEMENT_FIELD_LABEL_TITLE: "Итого строк:",
  ACCOUNT_STATEMENT_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Адрес компании",
  ACCOUNT_STATEMENT_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  ACCOUNT_STATEMENT_FORM_CORR_ACCOUNT_NUMBER_FIELD_PLACEHOLDER_TITLE:
    "Номер корреспондентского счета",

  ACCOUNT_INQUIRY_FILTER_TO_FIELD_LABEL_TITLE: "по",
  ACCOUNT_INQUIRY_FILTER_FROM_FIELD_LABEL_TITLE: "с",
  ACCOUNT_INQUIRY_FILTER_DATE_FROM_FIELD_LABEL_TITLE: "с",
  ACCOUNT_INQUIRY_NON_STANDART_INQUIRIES_BUTTON_TITLE: "Нестандартные запросы",
  ACCOUNT_INQUIRY_STANDART_INQUIRIES_BUTTON_TITLE: "Стандартные запросы",
  ACCOUNT_INQUIRY_SUBMIT_STANDARD_INQUIRY_BUTTON_TITLE: "Создать стандартный запрос",
  ACCOUNT_INQUIRY_SUBMIT_NON_STANDARD_INQUIRY_BUTTON_TITLE: "Создать нестандартный запрос",

  ACCOUNT_SWIFT_VALUE_DATE_COLUMN_TITLE: "Дата зачисления",
  ACCOUNT_SWIFT_MSG_KEY_COLUMN_TITLE: "Ключ Swift сообщения",
  ACCOUNT_SWIFT_REF_NUM_COLUMN_TITLE: "Номер ссылки",
  ACCOUNT_SWIFT_CURRENCY_COLUMN_TITLE: "Валюта",
  ACCOUNT_SWIFT_AMOUNT_COLUMN_TITLE: "Количество",
  ACCOUNT_SWIFT_ORD_BIC_COLUMN_TITLE: "Заказывающее учреждение SWIFT/BIC",
  ACCOUNT_SWIFT_ORD_CUST_COLUMN_TITLE: "Заказывающий клиент",
  ACCOUNT_SWIFT_DETAILS_COLUMN_TITLE: "Детали оплаты",
  ACCOUNT_SWIFT_ACC_BIC_COLUMN_TITLE: "Запись с учреждением SWIFT/BIC",
  ACCOUNT_SWIFT_BEN_CUS_COLUMN_TITLE: "Полезный клиент",

  ACCOUNT_SWIFT_COVER_WAITING_TITLE: "Пожалюста подождите!",
  ACCOUNT_SWIFT_COVER_CHOCKBOX_FILTER_TITLE: "Фильтр По Дате",

  ACCOUNT_SWIFT_COVER_DATE_TITLE: "Дата",
  ACCOUNT_SWIFT_COVER_SUM_TITLE: "Сумма",
  ACCOUNT_SWIFT_COVER_CURRENCY_TITLE: "Валюта",
  ACCOUNT_SWIFT_COVER_SENDER_TITLE: "Отправитель",
  ACCOUNT_SWIFT_COVER_BEBEFICIARY_TITLE: "Бенефицар",
  ACCOUNT_SWIFT_COVER_PAYMENT_DETAILS_TITLE: "Детали платежа",
  ACCOUNT_SWIFT_COVER_SUM_ADDITIONAL_PAYMENT_DETAILS_TITLE: "Дополнительные детали платежа ",
  ACCOUNT_SWIFT_COVER_ACCOUNT_FOR_RECEIPT_TITLE: "Счет для поступления",

  ACCOUNT_SWIFT_COVER_CHECK_BUTTON_TITLE: "Проверить",
  ACCOUNT_SWIFT_COVER_CHECK_NO_MESSAGES_RECEIVED_TITLE: "Поступивших сообщений нет",
  ACCOUNT_SWIFT_COVER_CHECK_RECEIVED_SWIFT_MESSAGES_TITLE: "Поступившие SWIFT сообщения",

  ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE: "Статус",
  ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE: "Тема",
  ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE: "Комментарии",
  ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE: "Дата завершения",
  ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE: "Дата отправки",

  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_TITLE: "Отправить стандартный запрос",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_POWER_CHECKBOX_TITLE: "Генеральная доверенность",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_DETAILS_SLEEP_CHECKBOX_TITLE:
    "Детализация квитанции о поступлении средств в сумах",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CASH_CHECKBOX_TITLE:
    "Доверенность на снятие наличных",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_PAYMENT_CHECKBOX_TITLE:
    "Доверенность на (Платежная инструкция, письма, выписки)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_CHECKBOX_TITLE: " Общие запросы",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_PAYMENT_CHECKBOX_TITLE:
    "Письмо на разрешение оплаты п/требования в безакцептном порядке",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_QUERY_ABOUT_BINDING_CHECKBOX_TITLE:
    "Запрос о привязке к транзитному счету, карточный счет сотрудника",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_EMAIL_CHECKBOX_TITLE:
    "Письмо об авторизованной электронной почте компании",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_LETTER_OF_THE_SALARY_CHECKBOX_TITLE:
    "Письмо на зарплатный проект",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_STATEMENT_OF_ACCOUNT_CHECKBOX_TITLE:
    "Выписка со счета (дополнительно)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_RENEWAL_OF_SIGNATURE_CHECKBOX_TITLE:
    "Продление карты подписи (включая временную карту)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_CARD_CHECKBOX_TITLE:
    "Доверенность на зарплатную карту персонала",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_GENERAL_INQUIRES_REPLY_CHECKBOX_TITLE:
    "Общие запросы (ответ в течение 24 часов)",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_CORPORATE_CARD_CHECKBOX_TITLE:
    "Доверенность на корпоративную карту",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_UP_12_MONTHS_CHECKBOX_TITLE:
    "Подготовка информации (бумажной / электронной) по счету по запросу Клиентов на срок до 12 месяцев.",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OF_THE_LATTER_CHECKBOX_TITLE:
    "Подготовка письма по запросу Аудитора от имени Клиента.",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_PREPARATION_OVER_12_MONTHS_CHECKBOX_TITLE:
    "Подготовка информации (бумажной / электронной) по счету по запросу Клиента на срок более 12 месяцев.",
  ACCOUNT_INQUIRY_STANDARD_SUBMIT_FORM_POWER_OF_SALARY_AND_CORPORATE_CARD_CHECKBOX_TITLE:
    "Доверенность на зарплатную и корпоративную карты",

  ACCOUNT_INQUIRY_NON_STANDARD_SUBJECT_FIELD_LABEL_TITLE: "Тема",
  ACCOUNT_INQUIRY_NON_STANDARD_TITLE: "Отправить нестандартный запрос",
  ACCOUNT_INQUIRY_NON_STANDARD_SUBMIT_BUTTON_TITLE: "Отправить запрос",
  ACCOUNT_INQUIRY_NON_STANDARD_MESSAGE_TEXT_FIELD_LABEL_TITLE: "Текст сообщения",
  ACCOUNT_INQUIRY_NON_STANDARD_DATE_OF_REQUEST_FIELD_LABEL_TITLE: "Дата запроса",
  ACCOUNT_INQUIRY_NON_STANDARD_PREPARING_BRANCH_FIELD_LABEL_TITLE: "Наименование банка исполнителя",
  ACCOUNT_INQUIRY_NON_STANDARD_REQUESTING_COMPANY_FIELD_LABEL_TITLE: "Наименование заявителя",
  ACCOUNT_INQUIRY_NON_STANDARD_CLIENT_REGISTRATION_NUMBER_FIELD_LABEL_TITLE:
    "Регистрационный номер",

  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_DATE_FIELD_LABEL_TITLE: "Дата",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Телефон",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "Электронная почта",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ACCOUNT_NAME_FIELD_LABEL_TITLE: "Тип счета",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_BANK_FIELD_LABEL_TITLE:
    "Наименование банка",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Имя клиента",
  ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ADDRESS_OF_THE_CLIENT_FIELD_LABEL_TITLE:
    "Адрес клиента",
  ACCOUNT_INQUIRY_APPLICATION_FOR_ACCOUNT_OPENING_FORM_TITLE: "Заявление на открытие счета",

  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_1: "*Если клиент выберет",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE: "Электронная почта",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PHONE_FIELD_LABEL_TITLE: "Телефон",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_2:
    "Открытие корпоративной карты в иностранной валюте",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_ISSUED_BY_FIELD_LABEL_TITLE: "Выдан",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THRU_FIELD_LABEL_TITLE: "Годен до",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_TITLE_3:
    ", необходимо заполнить дополнительные параметры",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CARD_NUMBER_FIELD_LABEL_TITLE: "Номер карты",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Гражданство",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_HOME_ADDRESS_FIELD_LABEL_TITLE: "Домашний адрес",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE: "Дата выдачи",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PAGE_TITLE: "Данные держателя карты",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_FIRSTNAME_FIELD_TITLE: "Имя",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_LASTNAME_FIELD_TITLE: "Фамилия",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSPORT_SERIES_FIELD_TITLE: "Серия паспорта",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_NUMBER_FIELD_TITLE: "ID документа",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_MIDDLENAME_FIELD_TITLE: "Отчество",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_ISSUE_FIELD_TITLE: "Место выдачи паспорта",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_ISSUE_FIELD_TITLE: "Дата выдачи паспорта",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_WORK_PHONE_FIELD_TITLE: "Рабочий телефон",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VERIFY_FIELD_TITLE:
    "Пароль для подтверждения личности Владельца, используемый в телефонных разговорах",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PHONE_FIELD_TITLE: "Телефон",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_POSITION_HELD_TITLE: "Занимаемая должность",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_DATE_OF_BIRTH_TITLE: "Дата рождения",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_ADDRESS_TITLE: "Адрес",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PLACE_OF_RECIDENCE_TITLE: "Место жительства",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_REGISTERED_ADDRESS_TITLE: "Зарегистрированный адрес",
  ACCOUNT_NEW_ACCOUNT_OPENING_REQUEST_PASSWORD_VALIDITY_TITLE: "Срок действия пароля",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_VALID_THROUGH_FIELD_LABEL_TITLE: "Действует до",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_CARDHOLDER_FIELD_LABEL_TITLE:
    "Имя и фамилия держателя карты",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_NAME_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Пожалуйста, напечатайте имя, как оно должно быть на карточке",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_COMPANY_ON_THE_CARD_FIELD_LABEL_TITLE:
    "Пожалуйста, напечатайте название компании, как оно должно быть на карточке",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Паспорт №",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_DATE_BIRTH_FIELD_LABEL_TITLE: "Дата Место рождения",
  ACCOUNT_INQUIRY_CORPORATE_CARD_OPENING_FORM_MOTHER_NAME_FIELD_LABEL_TITLE:
    "Девичья фамилия матери",

  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE: "Отправить",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_PUBLIC_OFFER_LABEL_TITLE: "Публичная оферта",
  ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_READ_AND_AGREE_LINK_TITLE: "Ознакомлен и согласен",
  ACCOUNT_INQUIRY_NEW_REQUEST_K2: "Запрос на регистрацию K2",
  ACCOUNT_INQUIRY_SENT: "Запрос создан",
  ACCOUNT_INQUIRY_TEXT_K2: " просит Банк предоставить копию реестра K2 по состоянию на",
  ACCOUNT_INQUIRY_TEXT_SINCERELY: "С уважением,",
  ACCOUNT_REQUEST_NUMBER: "Номер Запроса",
  ACCOUNT_REQUEST_DATE: "Дата заказа",
  ACCOUNT_BRANCH_NAME: "Название банка",
  ACCOUNT_INQUIRY_TEXT_AUDIT_INQUIRY: "Запрос информации по аудиторскому запросу",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO_HEADER: "Запрос информации о банковском счете",
  ACCOUNT_END_OF_DAY: "Конец дня",
  ACCOUNT_BEGINNING_OF_THE_DAY: "Начало дня",
  ACCOUNT_INQUIRY_TEXT_AUDIT: "В связи с проверкой финансовой деятельности",
  ACCOUNT_INQUIRY_TEXT_2_AUDIT: " просит Банк предоставить информацию о счете по состоянию на ",
  ACCOUNT_INQUIRY_TEXT_BY_AUDIT: "аудиторской компанией",
  ACCOUNT_FROM_DATE: "с",
  ACCOUNT_TO_DATE: "по",
  ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    " просит Банк предоставить следующую информацию о счете (ах) компании;",
  ACCOUNT_BALANCE_OF_DATE: " Остаток на дату",
  ACCOUNT_TURNOVERS: "Обороты за период с",
  ACCOUNT_ALL_ACCOUNTS: "Все",
  ACCOUNT_REQUEST_SENT: "Запрос создан",
  ACCOUNT_INFORMATION: "Информация по кредитам на дату",
  ACCOUNT_CARD_FILE: "Картотека номер 2 на дату",
  ACCOUNT_ADDITIONAL_STATEMENT_HEADER: "Запрос на дополнительные выписки",
  ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " просит Банка предоставить дополнительную выписку по следующему(ым) счету(ам);",
  ACCOUNT_ADDITIONAL_FOR_THE_PERIOD: "С",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL:
    "Запрос информации о поступлениях денежных средств на пополнение уставного капитала",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT:
    " просит Банка предоставить информацию о поступлениях денежных средств на следующий(ие) счет(а) компании",
  ACCOUNT_FUNDS_REPLENISH_CHARTER_CAPITAL_TEXT_2: "на пополнение уставного капитала на период с",

  // Transfers Page
  TRANSFER_LCY_EXPORT_EXCEL_TITLE: "ПЛАТЁЖНОЕ ПОРУЧЕНИЕ №",
  TRANSFER_LCY_EXPORT_EXCEL_DATE: "ДАТА",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME: "Наименование плательщика",
  TRANSFER_LCY_EXPORT_EXCEL_DEBIT: "ДЕБЕТ",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT: "Счет плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER: "ИНН плательщика",
  TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME:
    "Наименование банка, обслуживающего плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO: "Код банка, обслуживающего плательщика средств",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT: "СУММА",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME: "Наименование получателя",
  TRANSFER_LCY_EXPORT_EXCEL_CREDIT: "КРЕДИТ",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT: "Счет получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER: "ИНН получателя",
  TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME:
    "Наименование банка, обслуживающего получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO: "Код банка, обслуживающего получателя средств",
  TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS: "Сумма прописью",
  TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS: "Детали платежа",
  TRANSFER_LCY_BUDGET_INN: "ИНН бюджетополучателя",
  TRANSFER_LCY_BUDGET_REVENUE: "Казначейский лицевой счёт дохода",
  TRANSFER_BUDGET_ACCOUNT_NUMBER: "Казначейский лицевой счёт бюджетополучателя",
  TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME: "Руководитель",
  TRANSFER_LCY_EXPORT_EXCEL_FIO: "Ф.И.О.",
  TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT: "Главный бухгалтер",
  TRANSFER_LCY_EXPORT_EXCEL_OTPKEY_APPROVE: "Подтвержден OTP key",
  TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE: "М.П.",
  TRANSFER_LCY_EXPORT_EXCEL_BANK: "Банк",
  TRANSFER_LCY_EXPORT_EXCEL_CHECKED: "Проверено",
  TRANSFER_LCY_EXPORT_EXCEL_APPROVED: "Одобрено",
  TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED: "Проведено",
  TRANSFER_BUDGET_NAME: "Наименование бюджетополучателя",

  TRANSFERS_SALARY_TAB_TITLE: "Зарплатная карта",
  TRANSFERS_HISTORY_TAB_TITLE: "История",
  TRANSFERS_SAMPLES_TAB_TITLE: "Формы",
  TRANSFERS_SAMPLES_TAB_IMPORT_FROM_EXCEL: "Импорт из Excel",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_TITLE: "Размер файла",
  TRANSFERS_FORMS_DIRECTIONS_FILE_SIZE_KB_TITLE: "кб",
  TRANSFERS_FORMS_DIRECTIONS_MODIFIED_DATE_TITLE: "Дата последнего изменения",
  TRANSFERS_TRANSFERS_TAB_LCY_TITLE: "Платежи в Узб. сумах",
  TRANSFERS_TRANSFERS_TAB_FCY_TITLE: " Платежи в Ин. валюте ",
  TRANSFERS_FAVORITES_TAB_TITLE: "Избранные",
  TRANSFERS_PAYMENT_CLAIM_TAB_TITLE: "Платежное требование",
  TRANSFERS_CORRESPONDENTS_TAB_TITLE: "Контрагенты",
  TRANSFERS_CORPORATE_CARD_TAB_TITLE: "Корпоративная карта",

  TRANSFERS_SALARY_INSTRUCTION_TITLE: "Инструкция",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_TITLE: "Зачисление с отправкой ведомости через банк",
  TRANSFERS_CORPORATE_INSTRUCTION_TITLE: "Инструкция по пополнению нескольких карт",
  TRANSFERS_SALARY_REPLENISH_SUBTITLE: "1. Пополните транзитный счет",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REPLENISH_SUBTITLE: "1. Пополните транзитный счет",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SUBTITLE:
    "2. Выберите транзитный счет (23106, 23108, 23110) из списка.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REGISTER_SUBTITLE:
    "3. Выберите один из способов загрузки ведомости (Загрузить Excel файл / Выбрать существующий реестр / Создать новый реестр).",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_CHECK_SUBTITLE:
    "4. Нажмите кнопку \"Проверить\". Если появились строки со статусом \"Not Match/Не совпадает\" или \"Account is not found/Счет не найден\", внесите необходимые исправления или удалите эти строки.",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_STATUS_SUBTITLE:
    "5. После того, как статус всех строк - \"Match\", нажмите кнопку \"Отправить\". Если реестр создан успешно, Авторизатору необходимо перейти в раздел \"Документы к утверждению\" / \"Зарплата\" и изменить статус выбранных документов на \"Утвердить\" (или \"Отклонить\"), затем нажать кнопку \"Отправить\".",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_INFO_SUBTITLE:
    "Ограничение времени для отправки ведомости - до 17.30*. Ведомости, отправленные после 17.30 автоматически удаляются (* 16.30 в предпраздничные сокращенные операционные дни).",
  
  TRANSFERS_SALARY_CHOOSE_REGISTER_SUBTITLE:
    "3. Выберите один из вариантов загрузки реестра (Загрузить из Excel файла согласно шаблону ниже, Выбрать из существующего реестра, Создать новый реестр)",

  TRANSFERS_SALARY_DEBIT_ACCOUNT_TITLE: "Автоматическое зачисление на карты сотрудников",
  TRANSFERS_SALARY_DEBIT_ACCOUNT_METHOD_INFO_SUBTITLE: "Данный вид зачисления позволяет зачислить средства на карты сотрудников напрямую, без отправки ведомости в банк.",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SUBTITLE:
    "1. Выберите депозитный счет до востребования из списка.",

  TRANSFERS_CORPORATE_CARD_INFO: "2. Выберите транзитный счет -",
  UPLOAD_PAYROLL_EXCEL_BUTTON_TITLE: "Загрузить Excel файл",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT:
    "(I) 23102 - Транзитный счет для пополнения Корпоративной карты",
  TRANSFERS_CORPORATE_CARD_INFO_TEXT_2:
    "(II) 23104 - Транзитный счет для пополнения Корпоративной карты ИП",

  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_TITLE: "Выберите транзитный счет",
  TRANSFERS_SALARY_TRANSIT_ACCOUNT_FIELD_LABEL_TITLE: "Номер транзитного счета",
  TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE: "Баланс",
  TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE: " Тип реестра",
  TRANSFERS_SALARY_MONTH_FIELD_LABEL_TITLE: "за месяц",
  TRANSFERS_SALARY_NUMBER_FIELD_LABEL_TITLE: "Номер реестра",
  TRANSFERS_SALARY_AGREEMENT_FIELD_LABEL_TITLE: "Дата соглашения",
  TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE: "Код",
  TRANSFERS_SALARY_CHOOSE_REGISTER_TITLE: "Выберите способ создания реестра",
  TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE: "Выбрать существующий реестр",
  TRANSFERS_SALARY_CREATE_NEW_REGISTER_BUTTON_TITLE: "Создать новый реестр",

  TRANSFERS_SALARY_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Номер депозитного счета до востребования",

  TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE: "с",
  TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE: "по",
  TRANSFERS_SALARY_CLAIM_FILTER_BY_STATUS_FIELD_LABEL_TITLE: "Фильтр по статусу",
  TRANSFERS_SALARY_SEARCH_BUTTON_TITLE: "Поиск",
  TRANSFERS_SALARY_SHOW_CREATORS_CHECKBOX_TITLE: "Показать исполнителя и авторизатора",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_EMPLOYEE_REGISTRATION_FORM_TITLE:
    "Регистрационная форма сотрудника",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_FIELD_LABEL_TITLE:
    "Серия и номер паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_FIELD_LABEL_TITLE: "Номер паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Место выдачи паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_ISSUE_FIELD_LABEL_TITLE:
    "Дата выдачи паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_VALIDITY_FIELD_LABEL_TITLE:
    "Срок действия паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_ADDRESS_FIELD_LABEL_TITLE:
    "Адрес по прописке",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_CITY_FIELD_LABEL_TITLE: "Город",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_REGISTRATION_PINFL_FIELD_LABEL_TITLE: "ПИНФЛ",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SAVE_BUTTON_TITLE: "Отправить",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_INN_FIELD_LABEL_TITLE: "ИНН",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_AREA_FIELD_LABEL_TITLE: "Район",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_LAST_FIELD_LABEL_TITLE: "Фамилия",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FATHER_FIELD_LABEL_TITLE: "Отчество",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MALE_RADIO_BUTTON_LABEL_TITLE: "Мужской",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MOBILE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Мобильный",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SERVICE_PHONE_RADIO_BUTTON_LABEL_TITLE: "Служебный",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HOME_PHONE_RADIO_BUTTON_LABEL_TITLE: "Домашний",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_UZCARD_RADIO_BUTTON_LABEL_TITLE: "UzCard",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_YES_RADIO_BUTTON_LABEL_TITLE: "Да",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_NO_RADIO_BUTTON_LABEL_TITLE: "Нет",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_HUMO_RADIO_BUTTON_LABEL_TITLE: "HUMO",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PINF_FIELD_LABEL_TITLE: "ПНФЛ номер",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FIRST_FIELD_LABEL_TITLE: "Имя",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_MIDDLE_FIELD_LABEL_TITLE: "Отчество",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_GENDER_RADIO_BUTTON_LABEL_TITLE: "Пол",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_LABEL_TITLE: "Карта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CARD_RADIO_BUTTON_RESIDENT_LABEL_TITLE: "Резидент",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_FEMALE_RADIO_BUTTON_LABEL_TITLE: "Женский",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_SEND_REQUEST_BUTTON_TITLE: "Отправить запрос",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_CITIZENSHIP_FIELD_LABEL_TITLE: "Гражданство",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_DATE_OF_BIRTH_FIELD_LABEL_TITLE: "Дата рождения",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PLACE_OF_BIRTH_FIELD_LABEL_TITLE: "Место рождения",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_STREET_FIELD_LABEL_TITLE:
    "Квартал, Улица, Дом, Номер квартиры",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CONTACT_BUTTON_TITLE: "РАСПЕЧАТАТЬ КОНТАКТ",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PRINT_CLEAR_FORM_BUTTON_TITLE: "ОЧИСТИТЬ ФОРМУ",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_BUTTON_TITLE: "Поиск",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_CARD_TYPE: "Тип карты",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_SEARCH_FIELD_LABEL_TITLE: "Поиск",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EXPORT_LIST_BUTTON_TITLE: "ЭКСПОРТИРОВТЬ СПИСОК",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK: "Проверить",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_ERROR: "В сумме не должно быть запятых",
  TRANSFERS_SALARY_LIST_OF_CARD_CHECK_AMOUNT_ERROR:
    "Сумма должна быть заполнена или быть больше нуля",
  TRANSFERS_SALARY_LIST_OF_CARD_BACK: "Назад",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE: "Ф.И.О.",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_EMPLOYEE_LIST_BUTTON_TITLE: "Список сотрудников",

  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE: "ID карты",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета",
  TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE: "Дата открытия",

  TRANSFERS_SALARY_UPLOAD_EXCEL_FILE_CHOOSE_FILE_BUTTON_TITLE: "Выбрать файл",

  TRANSFERS_CORPORATE_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Пополнение нескольких карт с ведомостью",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE: "Пополнение одной карты без ведомости",
  TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SELECT_TITLE:
    "Зачисление с отправкой ведомости через банк",
  TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SELECT_TITLE:
    "Автоматическое зачисление на карты сотрудников",

  TRANSFERS_CORPORATE_CHOOSE_DEBIT_SUBMIT_BUTTON_TITLE: "Отправить",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_TITLE: "Выберите дебетовый счет",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_NUMBER_FIELD_TITLE: "Номер дебетового счета",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_CORPORATE_CARD_NUMBER_FIELD_TITLE:
    "Номер корпоративной карты",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_AMOUNT_FIELD_TITLE: "Сумма",
  TRANSFERS_CORPORATE_CHOOSE_DEBIT_ACCOUNT_OF_CORPORATE_FIELD_TITLE:
    "Номер счета корпоративной карты",

  TRANSFERS_CORPORATE_SINGLE_CARD_REPLENISHMENT_INSTURCTION_TITLE:
    "Инструкция по пополнению одной карты",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_DEBIT_ACCOUNT_TITLE: "1. Выберите дебетовый счет",
  TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_ACCOUNT_OF_COR_CARD_TITLE:
    "2. Выберите счет корпоративной карты",

  TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE: "Отправить",
  TRANSFERS_TRANSFERS_CHECK_BUTTON_TITLE: "ПРОВЕРИТЬ",
  TRANSFERS_TRANSFERS_CURRENT_BUSINESS_DAY_TITLE: "Текущий рабочий день",
  TRANSFERS_TRANSFERS_CREATE_NEW_LCY_BUTTON_TITLE: "Создать новое поручение в Узб. сумах",
  TRANSFERS_TRANSFERS_CREATE_NEW_FCY_BUTTON_TITLE: "Создать новое поручение в Ин. валюте",
  TRANSFERS_TRANSFERS_ALL_OUTGOING_TRANSFERS_TITLE: "Исходящие платежи в Узб. сумах",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_TITLE: "Исходящие платежи в Ин. валюте",
  TRANSFERS_TRANSFERS_ALL_FCY_OUTGOING_TRANSFERS_IN_FORM_TITLE: "Исходящий платёж в Ин. валюте",
  TRANSFERS_TRANSFERS_FIND_DOCUMENT_WITH_STATUS_LABEL_TITLE: "Найти документ со статусом",
  TRANSFERS_TRANSFERS_SHOW_CREATORS_AND_APPROVER_LABEL_TITLE: "Показать исполнителя и авторизатора",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_FCY_BUTTON_TITLE: "Импорт из Excel",
  TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_LCY_BUTTON_TITLE: "Импорт из Excel",

  TRANSFERS_TRANSFERS_FCY_ON_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в операционные дни: с 09:00 до 16:30 (Ташкентское время)",  
  TRANSFERS_TRANSFERS_FCY_ON_SHORTENED_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в предпраздничные/сокращенные операционные дни: с 09:00 до 15:30 (Ташкентское время)",

  TRANSFERS_TRANSFERS_LCY_FAVORITES_BUTTON_TITLE: "Избранные",
  TRANSFERS_TRANSFERS_LCY_ANOR_TRANSFER_BUTTON_TITLE: "ANOR Стандартный",
  TRANSFERS_TRANSFERS_LCY_MINUS_TRANSFER_BUTTON_TITLE: "MUNIS",
  TRANSFERS_TRANSFERS_LCY_BUDGET_TRANSFER_BUTTON_TITLE: "Бюджетный",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_TRANSFER_BUTTON_TITLE: "ANOR Бюджетный",
  TRANSFERS_TRANSFERS_LCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: "Загрузить из Excel",
  TRANSFERS_TRANSFERS_LCY_STANDARD_TRANSFER_BUTTON_TITLE: "Стандартный",
  TRANSFERS_TRANSFERS_LCY_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE: "Бюджетный доход",
  TRANSFERS_TRANSFERS_LCY_ANOR_BUDGET_REVENUE_TRANSFER_BUTTON_TITLE: "ANOR Бюджетный доход",

  TRANSFERS_TRANSFERS_FCY_TITLE: "Платеж в Ин. валюте",
  TRANSFERS_TRANSFERS_FCY_FAVORITES_BUTTON_TITLE: "Загрузить из избранного",
  TRANSFERS_TRANSFERS_FCY_UPLOAD_FROM_EXCEL_BUTTON_TITLE: "Загрузить из Excel",

  TRANSFERS_TRANSFERS_TABLE_TYPE_COLUMN_TITLE: "Тип",
  TRANSFERS_TRANSFERS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_TRANSFERS_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE: "Исполнитель",
  TRANSFERS_TRANSFERS_TABLE_CURRENCY_COLUMN_TITLE: "Валюта",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_COLUMN_TITLE: "Комментарии от банка",
  TRANSFERS_TRANSFERS_TABLE_EXPLANATION_CB_COLUMN_TITLE: "Комментарии от ЦБ",
  TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_TRANSFERS_TABLE_CORR_BANK_CODE_COLUMN_TITLE: "Код банка получателя",
  TRANSFERS_TRANSFERS_TABLE_SWIFT_BIC_COLUMN_TITLE: "SWIFT/BIC",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_TYPE_COLUMN_TITLE: "Тип документа",
  TRANSFERS_TRANSFERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Номер документа",
  TRANSFERS_TRANSFERS_TABLE_TRANSACTION_DATE_COLUMN_TITLE: "Дата транзакции",
  TRANSFERS_TRANSFERS_TABLE_CREATE_DATE_AND_TIME_COLUMN_TITLE: "Дата создания",
  TRANSFERS_TRANSFERS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета отправителя",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Исполнитель",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_TRANSFERS_TABLE_BENEFICIARY_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета получателя",
  TRANSFERS_PAGE_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE:
    "Отменить платежное требование",
  TRANSFERS_PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_FOR_STATUS_COLUMN_TITLE:
    "Запрос статуса",

  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_LOAD_BUTTON_TITLE: "Загрузить",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE: "Отправитель",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE: "Детали",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTIONS_BUTTON_TITLE: "Загрузить",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Генерировать",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE: "Получатель",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CORRESPONDENT_BUTTON_TITLE: "Контрагент",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE: "Наименование отправителя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "Тип платежа",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE: "Код платежа",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE: "ИНН отправителя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_FIELD_LABEL_TITLE: "Счет (27 цифр)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTION_TO_CHOOSE_BUTTON_TITLE: "варианты для выбора",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Дебетовать счет",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE: "Кредитовать счет",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Номер счета-фактуры",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE: "Текущий баланс",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE: "Детали платежа",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE: "Сумма прописью",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "Номер платежа",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE:
    "Наименование банка отправителя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE: "Код банка отправителя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE: "Сумма цифрами",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_AND_DETAILS_TITLE: "Код платежа и детали",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE:
    "Наименование получателя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE: "ИНН получателя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE:
    "ИНН бюджетной организации",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE:
    "Наименование банка получателя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE:
    "Код банка получателя",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE: "Счет (27 цифр)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_25_DIGITS_FIELD_LABEL_TITLE: "Счет (25 цифр)",
  TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE:
    "Наименование бюджетной организации",

  TRANSFERS_LCY_PRINT_HEADER_TITTLE: "Платежное поручение №",
  TRANSFERS_LCY_PRINT_DATA_LABEL: "Дата:",
  TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL: "Наименование плательщика:",
  TRANSFER_PRINT_TABLE_HELD: "Проведен",
  TRANSFERS_LCY_PRINT_DEBIT_LABEL: "ДЕБЕТ:",
  TRANSFERS_LCY_PRINT_STATE_LABEL: "Статус",
  TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL: "Счет плательщика средств",
  TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL: "ИНН плательщика",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL: "ИНН:",
  TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL:
    "Наименование банка, обслуживающего плательщика средств",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_NAME_LABEL: "Наименование банка плательщика:",
  TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL: "Код банка обслуживающего плательщика средств",
  TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_CODE_LABEL: "Код банка плательщика:",
  TRANSFERS_LCY_PRINT_AMOUNT_LABEL: "Сумма:",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL: "Наименование получателя:",
  TRANSFERS_LCY_PRINT_CREDIT_LABEL: "КРЕДИТ:",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL: "Счет получателя средств",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL: "ИНН получателя",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL:
    "Наименование банка, обслуживающего получателя средств",
  TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_NAME_LABEL: "Наименование банка получателя:",
  TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL: "Код банка, обслуживающего получателя средств",
  TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_LABEL: "Код банка получаетля:",
  TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL: "Сумма прописью:",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_TAX_NUMBER_LABEL: "ИНН бюджетополучателя",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_LABEL: "Каначейский лицевой счет бюджетополучателя",
  TRANSFERS_LCY_BUDGET_PRINT_BUDGET_ACCOUNT_NAME_LABEL: "Наименование бюджетополучателя",
  TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL: "Детали платежа:",
  TRANSFERS_LCY_PRINT_STATUS_LABEL: "Статус:",
  TRANSFERS_LCY_PRINT_MANAGER_LABEL: "Руководитель:",
  TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL: "Главный бухгалтер:",
  TRANSFERS_LCY_SIGNATURE_LABEL: "подп",
  TRANSFERS_LCY_BANK_MP_LABEL: "М.П.  БАНК",
  TRANSFERS_LCY_CHECKED_LABEL: "Проверен",
  TRANSFERS_LCY_APPROVED_LABEL: "Одобрен",
  TRANSFERS_LCY_PRINT_INITIALS_LABEL: "Инициалы",
  TRANSFERS_LCY_PRINT_BANK_LABEL: "Банк",
  TRANSFERS_LCY_REVENUE_PRINT_CLIENT_ACCOUNT_LABEL: "Казначейский лицевой счет дохода",
  TRANSFERS_LCY_UPLOAD_FROM_EXCEL_NUMBER_ERROR:
    "Невозможно сохранить ссылку с этим идентификационным номером.",

  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_GENERATE_BUTTON_TITLE: "Генерировать",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYEE_GROUP_TITLE: "Наименование группы",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SUPPLIER_CODE_TITLE: "Наименование поставщика услуг",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SETTLEMENT_CODE_TITLE: "Вид оплаты",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_INVOICE_NUMBER_FIELD_LABEL_TITLE: "Номер счета-фактуры",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_GROUP_TITLE_TITLE: "Отправитель",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_GROUP_TITLE_TITLE: "Получатель",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNTS_GROUP_TITLE_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_DETAILS_GROUP_TITLE_TITLE: "Детали",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_NAME_TITLE: "Наименование отправителя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_DEBIT_ACCOUNT_TITLE: "Дебетовать счет",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_CURRENT_BALANCE_TITLE: "Текущий баланс",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_NAME_TITLE: "Наименование банка отправителя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_BANK_CODE_TITLE: "Код банка отправителя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_SENDER_TAX_ID_TITLE: "ИНН отправителя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_NAME_TITLE: "Наименование получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_TITLE: "Кредитовать счет",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_TAX_ID_TITLE: "ИНН получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_AMOUNT_FOR_PAYMENT: "Сумма к оплате",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_NAME_TITLE:
    "Наименование банка получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_BANK_CODE_TITLE: "Код банка получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ACCOUNT_TITLE: "Счет",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_NAME_TITLE:
    "Наименование бюджетной организации-получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_BENEFICIARY_ORGANISATION_TAX_ID_TITLE:
    "ИНН бюджетной организации-получателя",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_DIGITS_TITLE: "Сумма цифрами",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_AMOUNT_IN_WORDS_TITLE: "Сумма прописью",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE: "Код платежа",
  TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_DETAILS_TITLE: "Детали платежа",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TITLE: " Заявитель (50)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_AMOUNT_TITLE: "Сумма (32А)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_COMMISSION_TITLE: "Комиссия",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_OTHERS_FIELD_LABEL_TITLE: "Другие (72)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_CODE_LABEL_TITLE: "Код оператора",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE:
    "Не верный код оператора",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_KPP_FIELD_LABEL_TITLE: "КПП",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE: "Получатель (59)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE_N2: "Банк получателя (57)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TITLE: "Банк заявителя (52)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_TITLE:
    "Настоящим заявлением подтверждаю, что ознакомлен(а) с условиями Публичной оферты на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида, соглашаюсь с ними и присоединяюсь к ним, претензий  в дальнейшем к Банку иметь не буду и даю согласие на безакцептное списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка.",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARYS_BANK_TITLE: "Банк получателя",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_1_TITLE:
    "Приложение №2 к Публичной оферте на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_TITLE: "Комиссии за перевод (71)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_2_TITLE:
    "Оговорка о присоединении к договору - Публичной оферте",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE: "Наименование",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE: "Номер платежа",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_FIELD_LABEL_TITLE: "Детали (70)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_OR_SERVICES_LABEL_TITLE: "Услуги/Товары",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SERVICES_LABEL_TITLE: "Услуги",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_LABEL_TITLE: "Товары",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_IS_IDN_LABEL_TITLE: "Для коммерческого платежа",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_PAYMENT_FIELD_LABEL_TITLE: "Детали платежа",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DEBIT_COMMISSION_FIELD_LABEL_TITLE:
    "Счёт для списании комиссии банка",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE: "Наименование",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE: "Наименование Банка",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_NAME_FIELD_LABEL_TITLE: "Наименование",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE: "ИНН",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_CODE_TITLE: "Код страны",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_NUMBER_FIELD_LABEL_TITLE: "Номер телефона",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PASSWORD_FIELD_LABEL_TITLE: "Паспорт",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE: "ИНН",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE: "Наименование",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TAX_ID_FIELD_LABEL_TITLE: "ИНН",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_PLACE_FIELD_LABEL_TITLE: "Место",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_REMARKS_FIELD_LABEL_TITLE: "Замечания",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CORRESPONDENT_ACCOUNT_FIELD_LABEL_TITLE: "Корр. счет.",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_VO_CODE_LABEL_TITLE: "VO код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE: "SWIFT/BIC код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_DEBIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Дебетовать счет",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Текущий баланс",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_FIELD_LABEL_TITLE:
    "Кредитовать счет",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Текущий баланс",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_CODE_FIELD_LABEL_TITLE: "SWIFT код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_DIGIT_FIELD_LABEL_TITLE:
    " Сумма цифрами ",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE:
    "Сумма прописью",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_SWIFT_BIC_CODE_FIELD_LABEL_TITLE:
    "SWIFT / BIC код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CURRENT_BALANCE_FIELD_LABEL_TITLE:
    "Текущий баланс",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE:
    "Банк-корреспондент Банка получателя (53)",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_REMITTER_CHECKBOX_LABEL_TITLE:
    "Все комиссии за счет отправителя /OUR/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_CHARGES_OF_JSC_CHECKBOX_LABEL_TITLE:
    "Комиссии АО КДБ Банк Узбекистан за счет отправителя /SHA/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FULL_NO_DEDUCT_CHECKBOX_LABEL_TITLE:
    "Все комиссии за счет отправителя /FND/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_BENEFICIARY_CHECKBOX_LABEL_TITLE:
    "Все комиссии за счет получателя /BEN/",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE: "SWIFT/BIC код",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_MODAL_NACCODE_TITLE: "Код Nace",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACCODE_TITLE: "Код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_TABLE_NACNAME_TITLE: "Описание",

  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_LABEL_TITLE: "Nace Код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_HS_CODE_LABEL_TITLE: "Hs Код",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_BUTTON_TITLE: "Загрузить",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INV_NUMBER_LABEL_TITLE: "INVN номер",
  TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INVN_DATE_LABEL_TITLE: "Дата INV",

  TRANSFERS_CORRESPONDENTS_TITLE: "Список контрагентов",

  TRANSFERS_CORRESPONDENTS_TABLE_TAX_ID_COLUMN_TITLE: "ИНН",
  TRANSFERS_CORRESPONDENTS_TABLE_CODE_COLUMN_TITLE: "Код",
  TRANSFERS_CORRESPONDENTS_TABLE_DESCRIPTION_COLUMN_TITLE: "Описание",
  TRANSFERS_CORRESPONDENTS_TABLE_ACCOUNT_COLUMN_TITLE: "Счет",
  TRANSFERS_CORRESPONDENTS_TABLE_BANK_CODE_COLUMN_TITLE: "Код банка",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_PARTNER_COLUMN_TITLE: "Наименование банка",
  TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_THE_BANK_COLUMN_TITLE: "Наименование партнера",

  TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Тема",
  TRANSFERS_FAVORITES_TABLE_STATUS_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_FAVORITES_TABLE_CREATED_BY_BY_COLUMN_TITLE: "Исполнитель",
  TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_DATE_COLUMN_TITLE: "Дата завершения",
  TRANSFERS_FAVORITES_TABLE_EXPLANATION_EXPLANATION_COLUMN_TITLE: "Комментарии",
  TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_DATE_COLUMN_TITLE: "Дата подачи",

  TRANSFERS_FAVOURITES_MODAL_TAB_TITLE: "Список избранных",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NUMBER_COLUMN_TITLE: "Номер документа",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_NAME_COLUMN_TITLE: "Название документа",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_TYPE_COLUMN_TITLE: "Тип документа",
  TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL: "Имя документа",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Дебетовать счет",
  TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Наименование Получателя",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Кредитовать счет",
  TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_FAVOURITES_MODAL_TABLE_CURRENCY_COLUMN_TITLE: "Валюта",
  TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE: "Запрос",

  TRANSFERS_HISTORY_FILTER_BUTTON_TITLE: "Фильтр",
  TRANSFERS_HISTORY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "по",
  TRANSFERS_HISTORY_FILTER_FORM_FROM_FIELD_LABEL_TITLE: "с",
  TRANSFERS_HISTORY_FILTER_FORM_STATUS_FIELD_LABEL_TITLE: "Статус",
  TRANSFERS_HISTORY_FILTER_FORM_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  TRANSFERS_HISTORY_FILTER_FORM_DEBIT_ONLY_CHECKBOX_TITLE: "только дебет",
  TRANSFERS_HISTORY_FILTER_FORM_CREDIT_ONLY_CHECKBOX_TITLE: "только кредит",
  TRANSFERS_HISTORY_FILTER_FORM_SEARCH_AMOUNT_FIELD_LABEL_TITLE: "Поиск суммы",
  TRANSFERS_HISTORY_FILTER_FORM_WITHOUT_DETAILS_CHECKBOX_TITLE: "Без деталей",
  TRANSFERS_HISTORY_FILTER_FORM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Номер счета",
  TRANSFERS_HISTORY_FILTER_FORM_CORR_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Номер счета",

  TRANSFERS_HISTORY_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Дата подачи",
  TRANSFERS_HISTORY_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_HISTORY_TABLE_EXPLANATION_COLUMN_TITLE: "Комментарии",
  TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  TRANSFERS_HISTORY_TABLE_CREATED_COLUMN_TITLE: "Исполнитель",
  TRANSFERS_HISTORY_TABLE_APPROVER_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_HISTORY_TABLE_STATUS__STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_HISTORY_TABLE_SUBJECT_COLUMN_TITLE: "Тема",
  TRANSFERS_HISTORY_TABLE_APPROVED_BY_BY_COLUMN_TITLE: "Авторизатор",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_BANK_CODE_COLUMN_TITLE: "Код банка-корреспондента",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_NAME: "Наименование корреспондента",
  TRANSFERS_HISTORY_TABLE_CREATED_CREATED_COLUMN_TITLE: "Создан",
  TRANSFERS_HISTORY_TABLE_CORRESPONDENT_ACCOUNT: "Номер корреспондентского счета",
  TRANSFERS_HISTORY_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Номер счета получателя",
  TRANSFERS_HISTORY_TABLE_DEBIT_AMOUNT: "Сумма списания",
  TRANSFERS_HISTORY_TABLE_CREDIT_AMOUNT: "Сумма поступления",
  TRANSFERS_HISTORY_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Дата завершения",

  //Corporate role  Transfers page Payment Claim Tab Received Payment Claims

  TRANSFERS_PAYMENT_CLAIM_SENT_SUBTAB_TITLE: "Отправленные платежные требования",
  TRANSFERS_PAYMENT_CLAIM_CREATE_SUBTAB_TITLE: "Создать новое платежное требование",
  TRANSFERS_PAYMENT_CLAIM_RECEIVED_SUBTAB_TITLE: "Полученные платежные требования",

  TRANSFERS_PAYMENT_CLAIM_TO_FIELD_LABEL_TITLE: "по",
  TRANSFERS_PAYMENT_CLAIM_SEARCH_BUTTON_TITLE: "Поиск",
  TRANSFERS_PAYMENT_CLAIM_FROM_FIELD_LABEL_TITLE: "с",
  TRANSFERS_PAYMENT_CLAIM_FILTER_FIELD_LABEL_TITLE: "Фильтр по статусу",

  TRANSFERS_PAYMENT_CLAIM_TABLE_TYPE_COLUMN_TITLE: "Тип",
  TRANSFERS_PAYMENT_CLAIM_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_PAYMENT_CLAIM_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_PAYMENT_CLAIM_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  TRANSFERS_PAYMENT_CLAIM_TABLE_ACTIONS_COLUMN_TITLE: "Действия",
  TRANSFERS_PAYMENT_CLAIM_TABLE_PAYMENT_COLUMN_TITLE: "Номер платежного требования",
  TRANSFERS_PAYMENT_CLAIM_TABLE_SENDER_COLUMN_TITLE: "Номер счета отправителя",
  TRANSFERS_PAYMENT_CLAIM_TABLE_RECEIVED_COLUMN_TITLE: "Дата и время получения",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Наименование получателя",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_BANK_COLUMN_TITLE: "Код банка получателя",
  TRANSFERS_PAYMENT_CLAIM_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Номер счета получателя ",

  TRANSFERS_PAYMENT_CLAIM_ACCEPT_BUTTON_TITLE: "",
  TRANSFERS_PAYMENT_CLAIM_REJECT_BUTTON_TITLE: "",

  // payment claim

  PAYMENT_CLAIM_DEPLOY_BUTTON_TITLE: "Загрузите свой файл",
  PAYMENT_CLAIM_RECALL: "Отозвать",
  PAYMENT_CLAIM_CREATED_SEND: "Отправлено платежное требование",
  PAYMENT_CLAIM_CREATED: "Создано платежное требование",
  PAYMENT_CLAIM_ERROR_BRANCH: "Сообщения между филиалами невозможны",

  // Conversion Page
  CONVERSION_SUBMIT_NOTIFICATION: "Конверсия отправлена",
  CONVERSION_CLIENT_SAVED: "Информация о клиенте сохранена",
  CONVERSION_CLIENT_NO_CHANGES: "В информации о клиенте изменений нет",
  CONVERSION_FAVORITES_TAB_TITLE: "Избранные",
  CONVERSION_EXCHANGE_RATES_TAB_TITLE: "Обменные курсы",
  CONVERSION_FCY_TO_UZS_CONVERSION_TAB_TITLE: "Продажа Ин. валюты",
  CONVERSION_UZS_TO_FCY_CONVERSION_TAB_TITLE: "Покупка Ин. валюты",
  CONVERSION_FCY_TO_FCY_CONVERSION_TAB_TITLE: "Конвертация между Ин. валютами",
  CONVERSION_FCY_TO_UZS_SELL_TAB_TITLE: "Продажа Ин. валюты Банку",
  CONVERSION_UZS_TO_FCY_SELL_TAB_TITLE: "Продажа Ин. валюты на УзРВБ",

  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_TITLE: "Покупка",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_IN_TITLE: " в сумах",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PERCENT_TITLE: "%",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_TITLE: "Продажа",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DETAIL_TITLE: "Детали",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_TITLE:
    "Приложение №1 к ГЕНЕРАЛЬНОМУ СОГЛАШЕНИЮ на продажу иностранной валюты на Узбекской республиканской валютной бирже (УзРВБ) ",
  CONVERSION_FCY_TO_UZS_FCY_COMMISSION_TITLE: "Комиссия",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_AMOUNT_LABEL_TITLE: "Сумма покупки",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_MINIMAL_RATE_LABEL_TITLE: "Минимальный курс продажи",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_BUY_CURRENCY_LABEL_TITLE: "Валюта покупки",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_EXCHANGE_RATE_LABEL_TITLE: "Обменный курс",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_DEBIT_ACCOUNT_LABEL_TITLE: "Дебетовать счет",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Сумма продажи",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_RATE_LABEL_TITLE: "Ставка комиссии",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Валюта продажи",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_AMOUNT_LABEL_TITLE: "Сумма комиссии",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_COMMISSION_ACCOUNT_LABEL_TITLE: "Комиссионный счет",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_PURPOSE_OF_CONVERSION_LABEL_TITLE: "Цель конвертации",
  CONVERSION_FCY_TO_UZS_FCY_TO_NUMBER_OF_GENERAL_AGR_LABEL_TITLE: "Номер генерального соглашения",
  CONVERSION_FCY_TO_UZS_FCY_TO_NAME_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Ф.И.О. сотрудника по решению вопросов по сделке",
  CONVERSION_FCY_TO_UZS_FCY_TO_DATE_OF_GENERAL_AGR_LABEL_TITLE: "Дата генерального соглашения",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSITION_OF_THE_EMPLOYEE_LABEL_TITLE:
    "Должность сотрудника по решению вопросов по сделке",
  CONVERSION_FCY_TO_UZS_FCY_TO_POSTAL_ADDRESS_LABEL_TITLE: "Почтовый адрес",
  CONVERSION_FCY_TO_UZS_FCY_TO_DIRECTOR_OF_THE_ORG_LABEL_TITLE:
    "Ф.И.О. Руководителя предприятия (организации)",
  CONVERSION_FCY_TO_UZS_FCY_TO_TELEPHONE_NUMBER_LABEL_TITLE: "Номер телефона",
  CONVERSION_FCY_TO_UZS_FCY_TO_CHIEF_ACCOUNTANT_OF_THE_ORG_TITLE: "Ф.И.О. Главного бухгалтера",
  CONVERSION_FCY_TO_UZS_FCY_TO_FAX_NUMBER_TITLE: "Номер факса",
  CONVERSION_FCY_TO_UZS_FCY_TO_SAVE_BUTTON: "Сохранить",
  CONVERSION_FCY_TO_UZS_FCY_TO_CANCEL_BUTTON: "Отменить",
  CONVERSION_FCY_TO_UZS_FCY_TO_BANK_FORM_INFO_OF_CONVERSION_LABEL_TITLE:
    "Сумма конвертации зарезервирована на следующем банковском транзитном счете",

  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_TITLE: "Покупка",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_TITLE: "Продажа",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SUBMIT_BUTTON_TITLE: "Отправить",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CAPTION_TITLE: '"ABC"LCC',
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_TITLE:
    "Приложение №1 к Публичной оферте на оказание услуг по конвертации",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_CURRENCY_FIELD_LABEL_TITLE: "Валюта покупки",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_AMOUNT_FIELD_LABEL_TITLE: "Сумма продажи",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_SELL_CURRENCY_FIELD_LABEL_TITLE: "Валюта продажи",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_EXCHANGE_RATE_FIELD_LABEL_TITLE: "Обменный курс",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE: "Дебетовать счет",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_MINIMAL_SELLING_EXCHANGE_FIELD_LABEL_TITLE:
    "Минимальный курс продажи",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE: "Кредитовать счет",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_RATE_FIELD_LABEL_TITLE: "Ставка комиссии",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_AMOUNT_FIELD_LABEL_TITLE: "Сумма комиссии",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE: "Дата транзакции",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_COMMISSION_ACCOUNT_FIELD_LABEL_TITLE:
    "Комиссионный счет",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PURPOSE_OF_CONVERSION_FIELD_LABEL_TITLE:
    "Цель конвертации",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_BY_AMOUNT_FIELD_LABEL_TITLE: "Сумма покупки",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_CONVERSION_AMOUNT_ACCOUNT_FIELD_LABEL_TITLE:
    "Сумма конвертации зарезервирована на следующем банковском транзитном счете",
  CONVERSION_FCY_TO_UZS_FCY_AT_UZ_RCE_FORM_PERCENT_TITLE: "%",

  CONVERSION_FCY_TO_UZS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_TO_FIELD_LABEL_TITLE: "по",
  CONVERSION_FCY_TO_UZS_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  CONVERSION_FCY_TO_UZS_TABLE_MINIMUM_RATE_COLUMN_TITLE: " Минимальный курс ",
  CONVERSION_FCY_TO_UZS_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Обменный курс",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "c",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_CREATED_BY_FIELD_LABEL_TITLE: "Исполнитель",
  CONVERSION_FCY_TO_UZS_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Сумма покупки в сумах ",
  CONVERSION_FCY_TO_UZS_TABLE_COMMISSION_RATE_COLUMN_TITLE: "Ставка комиссии",
  CONVERSION_FCY_TO_UZS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Дебетовать счет в Ин. валюте",
  CONVERSION_FCY_TO_UZS_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Кредитовать счет в сумах",
  CONVERSION_FCY_TO_UZS_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Сумма продажи в Ин. валюте",
  CONVERSION_FCY_TO_UZS_TO_BANK_BUTTON_GROUP_TITLE: "Создать заявку на продажу Ин. валюты",
  CONVERSION_FCY_TO_UZS_FILTER_FORM_FIND_DOCUMENTS_FIELD_LABEL_TITLE: "Найти документы со статусом",
  CONVERSION_FCY_TO_UZS_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Коментарии от Банка",

  CONVERSION_FCY_TO_UZS_ON_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в операционные дни: с 09:00 до 16:30 (Ташкентское время)",  
  CONVERSION_FCY_TO_UZS_ON_SHORTENED_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в предпраздничные/сокращенные операционные дни: с 09:00 до 15:30 (Ташкентское время)",  

  CONVERSION_UZS_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Статус",
  CONVERSION_UZS_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Коментарии от Банка",
  CONVERSION_UZS_TO_FCY_TABLE_TITLE: "Информация о клиенте",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_COLUMN_TITLE: "Номер",
  CONVERSION_UZS_TO_FCY_CREATE_REQUEST_AT_UZ_RCE_BUTTON_TITLE:
    "Создать заявку на покупку Ин.валюты на УзРВБ ",

  CONVERSION_UZS_TO_FCY_ON_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в операционные дни: с 09:00 до 16:30 (Ташкентское время)",  
  CONVERSION_UZS_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в предпраздничные/сокращенные операционные дни: с 09:00 до 15:30 (Ташкентское время)",  
  

  CONVERSION_UZS_TO_FCY_FILTER_FORM_TO_FIELD_LABEL_TITLE: "по",
  CONVERSION_UZS_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  CONVERSION_UZS_TO_FCY_TABLE_BUY_AMOUNT_COLUMN_TITLE: "Сумма покупки",
  CONVERSION_UZS_TO_FCY_TABLE_COMMISSION_COLUMN_TITLE: "Комиссия",
  CONVERSION_UZS_TO_FCY_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  CONVERSION_UZS_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_RATE_COLUMN_TITLE: "Максимальный курс",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_UZS_COLUMN_TITLE: "Счет в сумах",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_COLUMN_TITLE: "Счет в Ин. валюте",
  CONVERSION_UZS_TO_FCY_TABLE_MAXIMUM_AMOUNT_COLUMN_TITLE: "Общая сумма покупки в сумах по курсу",
  CONVERSION_UZS_TO_FCY_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "№ и дата контракта",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_COLUMN_TITLE:
    "Счет для резервирования в сумах",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVED_AMOUNT_IN_UZS_COLUMN_TITLE:
    "Сумма в сумах по курсу, указанному в заявке",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_COLUMN_TITLE:
    "Конвертационный счет в Ин. валюте",
  CONVERSION_UZS_TO_FCY_TABLE_DEBIT_ACCOUNT_IN_UZS_TITLE: "Дебетовать счет в сумах",
  CONVERSION_UZS_TO_FCY_TABLE_RESERVE_ACCOUNT_IN_UZS_TITLE: "Счет для резервирования в сумах",
  CONVERSION_UZS_TO_FCY_TABLE_CONVERSION_ACCOUNT_IN_FCY_TITLE: "Конвертационный счет в Ин. валюте",
  CONVERSION_UZS_TO_FCY_TABLE_ACCOUNT_IN_FCY_TITLE: "Счет в Ин. валюте",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_EQUIPMENT: "1) для импорта оборудования;",
  CONVERSION_UZS_TO_FCY_PURPOSE: "Цель использования покупаемой иностранной валюты:",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_COMPONENTRY: "2) для импорта комплектующих изделий;",
  CONVERSION_UZS_TO_FCY_RAW_MATERIALS: "3) для импорта сырья и материалов;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_SERVICE: "4) для импорта услуг;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_OF_CONSUMER_GOODS: "5) для импорта потребительских товаров;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_SERVE_ATTACHED: "6) для обслуживания иностранных кредитов;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_EFFECT_LEASING_PAYMENTS:
    "7) для осуществления лизинговых платежей по лизинговым договорам;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION:
    "8) для репатриации прибыли, дивидендов и других доходов иностранного инвестора;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_REPORTIATION_OF_EXCESS:
    "9) для репатриации превышения доходов над расходами назначенных авиаперевозчиков;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_TO_PAY_TRAVELLING: "10) для выплаты командировочных расходов;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_TRANSFERRING_FUNDS:
    "11) для перевода средств, полученных от продажи продукции на открытых электронных торгах товарно-сырьевых бирж;",
  CONVERSION_UZS_TO_FCY_CHECKBOX_FOR_OTHER: "12) другие цели;",
  CONVERSION_UZS_TO_FCY_TITLE:
    "Поручаем заключить от нашего имени и за наш счет сделку на покупку иностранной валюты на нижеследующих условиях:",
  CONVERSION_UZS_TO_FCY_ATTACHE_TITLE: "Документы",
  CONVERSION_UZS_TO_FCY_FORM_BUY_AMOUNT: "Сумма покупки",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_BUY_RATE: "Максимальный курс покупки в сумах",
  CONVERSION_UZS_TO_FCY_FORM_RESERVED_AMOUNT: "Общая сумма покупки в сумах по курсу",
  CONVERSION_UZS_TO_FCY_FORM_NUMBER_AND_DATE: "№ и дата контракта",
  CONVERSION_UZS_TO_FCY_FORM_MAXIMUM_AMOUNT_UZS:
    "Сумма (в национальной валюте - сумах) по указанному в заявке курсу",
  CONVERSION_UZS_TO_FCY_FORM_BANKS_COMMISSION: "Комиссия от суммы совершённой сделки",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_TITLE: "Документы",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_1: "Укажите название документа №1",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_2: "Укажите название документа №2",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_3: "Укажите название документа №3",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_4: "Укажите название документа №4",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_5: "Укажите название документа №5",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_NAME_TITLE_6: "Укажите название документа №6",
  CONVERSION_ATTECH_TITLE:
    "Пришлите подтверждающие документы для конвертации на следующий адрес электронной почты Департамента Валютного Контроля",
  CONVERSION_UZS_TO_FCY_FORM_ATTACHED_BUTTON: "Прикрепить",
  CONVERSION_UZS_TO_FCY_SUBMIT_ERROR: "Курс по данной валюте не установлена",
  CONVERSION_UZS_TO_FCY_FORM_SUBMIT_BUTTON: "Отправить",
  CONVERSION_UZS_TO_FCY_CLIENT_INFO: "Информация о клиенте",
  CONVERSION_UZS_TO_FCY_FAVORITES: "Избранное (конвертация)",
  CONVERSION_UZS_TO_FCY_PRINT: "Распечатать",
  CONVERSION_UZS_TO_FCY_SAVE_FAVORITES: "Сохранить в Избранном",
  CONVERSION_UZS_TO_FCY_UPLOAD_FROM_EXCEL: "Загрузить из Excel",
  CONVERSION_UZS_TO_FCY_SAVE_PDF: "Сохранить в PDF",
  CONVERSION_MAIN_HEADER_TITTLE_UZS_TO_FCY_PRINT_TABLE: "ЗАЯВКА НА ПОКУПКУ ИНОСТРАННОЙ ВАЛЮТЫ",
  CONVERSION_NAME_OF_APPLICANT_UZS_TO_FCY_PRINT_TABLE: "Наименование заявителя::",
  CONVERSION_TAX_ID_UZS_TO_FCY_PRINT_TABLE: "ИНН:",
  CONVERSION_PHONE_UZS_TO_FCY_PRINT_TABLE: "Контакты: тел:",
  CONVERSION_PURPOSE_UZS_TO_FCY_PRINT_TABLE:
    "Цель использования покупаемой инвалюты (подчеркнуть, указать № и дату документа):",
  CONVERSION_A_UZS_TO_FCY_PRINT_TABLE: "а) импорт оборудования (№ и дата контракта);",
  CONVERSION_B_UZS_TO_FCY_PRINT_TABLE: "б) импорт комплектующих изделий;",
  CONVERSION_C_UZS_TO_FCY_PRINT_TABLE: "в) импорт сырья и материалов;",
  CONVERSION_D_UZS_TO_FCY_PRINT_TABLE: "г) импорт услуг;",
  CONVERSION_E_UZS_TO_FCY_PRINT_TABLE: "д) импорт потребительских товаров;",
  CONVERSION_F_UZS_TO_FCY_PRINT_TABLE: "е) обслуживание иностранных кредитов;",
  CONVERSION_G_UZS_TO_FCY_PRINT_TABLE:
    "ж) осуществление лизинговых платежей по лизинговым договорам;",
  CONVERSION_H_UZS_TO_FCY_PRINT_TABLE:
    "з) репатриация прибыли, дивидендов и других доходов иностранного инвестора;",
  CONVERSION_I_UZS_TO_FCY_PRINT_TABLE:
    "и) репатриация превышения доходов над расходами назначенных авиаперевозчиков;",
  CONVERSION_J_UZS_TO_FCY_PRINT_TABLE: "к) выплата командировочных расходов;",
  CONVERSION_K_UZS_TO_FCY_PRINT_TABLE:
    "л) перевод средств, полученных от продажи продукции на открытых электронных торгах товарно-сырьевых бирж",
  CONVERSION_L_UZS_TO_FCY_PRINT_TABLE:
    "м) другие цели (указать какие):  _________________________________________________",
  CONVERSION_NAME_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Cумма и наименование приобретаемой валюты",
  CONVERSION_MAX_PURCHASE__UZS_TO_FCY_PRINT_TABLE: "Максимальный курс покупки в сумах",
  CONVERSION_TOTAL_AMOUNT_UZS_TO_FCY_PRINT_TABLE: "Общая сумма покупки в сумах по курсу",
  CONVERSION_COVER_ON_UZS_TO_FCY_PRINT_TABLE: "(*) Покрытие на 22613",
  CONVERSION_CONFIRM_UZS_TO_FCY_PRINT_TABLE: "Имеется / Не имеется",
  CONVERSION_INSTRUCT_TO_DEBIT_UZS_TO_FCY_PRINT_TABLE:
    "В случае заключения сделки поручаем списать с нашего счета №:22613000______________________________ в узбекских сумах сумму, эквивалентную сумме приобретённой иностранной валюты по курсу покупки.",
  CONVERSION_ATTACHED_DOC_UZS_TO_FCY_PRINT_TABLE: "Прилагаются следующие документы:",
  CONVERSION_PUBLIC_OFFER_UZS_TO_FCY_PRINT_TABLE:
    "⃝ Подписание настоящего Заявления означает безусловное принятие Клиентом всех условий Публичной оферты на оказание услуг по конвертации, опубликованной на сайте Банка (www.kdb.uz) на дату подписания настоящего Заявления, а также согласие Клиента на безакцептное списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка и настоящим Заявлением.",
  CONVERSION_DIRECTOR_UZS_TO_FCY_PRINT_TABLE: "Руководитель предприятия:   ",
  CONVERSION_FULL_NAME_UZS_TO_FCY_PRINT_TABLE: "И.Ф.О.",
  CONVERSION_CHIEF_ACCOUNTANT_UZS_TO_FCY_PRINT_TABLE: "Главный бухгалтер  предприятия:",
  CONVERSION_RESPONSIBLE_UZS_TO_FCY_PRINT_TABLE: "Ответственный сотрудник УВК филиала",
  CONVERSION_HEAD_UZS_TO_FCY_PRINT_TABLE: "Начальник УВК филиала",
  CONVERSION_HEAD_OF_BRANCH_UZS_TO_FCY_PRINT_TABLE: "Управляющий филиала",
  CONVERSION_HEAD_OF_THE_HO_UZS_TO_FCY_PRINT_TABLE: "Начальник УВК ГО",
  CONVERSION_DEPUTY_UZS_TO_FCY_PRINT_TABLE: "Заместитель председателя",

  CONVERSION_FCY_TO_FCY_ON_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в операционные дни: с 09:00 до 16:30 (Ташкентское время)",  
  CONVERSION_FCY_TO_FCY_ON_SHORTENED_BUSINESS_DAYS: "Время приема платежных инструкций в иностранной валюте в предпраздничные/сокращенные операционные дни: с 09:00 до 15:30 (Ташкентское время)",  
  
  CONVERSION_FCY_TO_FCY_TABLE_STATUS_COLUMN_TITLE: "Статус",
  CONVERSION_CREDIT_DEBIT_ARE_SAME: " Одинаковый кредитовый и Дебетовать счет",
  CONVERSION_FCY_TO_FCY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  CONVERSION_FCY_TO_FCY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  CONVERSION_FCY_TO_FCY_TABLE_EXCHANGE_RATE_COLUMN_TITLE: "Обменный курс",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_AMOUNT_COLUMN_TITLE: " Сумма покупки",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Сумма продажи",
  CONVERSION_FCY_TO_FCY_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Валюта покупки",
  CONVERSION_FCY_TO_FCY_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Номер документа",
  CONVERSION_FCY_TO_FCY_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Валюта продажи",
  CONVERSION_FCY_TO_FCY_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Кредитовать счет в Ин. валюте",
  CONVERSION_FCY_TO_FCY_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Дебетовать счет в Ин. валюте",
  CONVERSION_FCY_TO_FCY_CREATE_APPLICATION_BUTTON_GROUP_TITLE:
    "Создать новую конвертацию между Ин. валютами",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_TITLE:
    "Оговорка о присоединении к договору - Публичной оферте:",
  CONVERSION_FCY_TO_FCY_DESCRIPTION_SUBTITLE:
    "Настоящим заявлением подтверждаю, что ознакомлен(а) с условиями Публичной оферты на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида, соглашаюсь с ними и присоединяюсь к ним, претензий  в дальнейшем к Банку иметь не буду и даю согласие на безакцептное списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка.",
  CONVERSION_FCY_TO_FCY_TITLE:
    "Приложение №1 к Публичной оферте на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида",
  CONVERSION_FCY_TO_FCY_TABLE_BANK_COMMENTS_COLUMN_TITLE: "Коментарии от Банка",


  CONVERSION_FAVORITES_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  CONVERSION_FAVORITES_TABLE_NAME_COLUMN_TITLE: "Название документа",
  CONVERSION_FAVORITES_TABLE_NUMBER_COLUMN_TITLE: "Номер",
  CONVERSION_FAVORITES_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Наименование документа",
  CONVERSION_FAVORITES_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE: "Дебетовать счет",
  CONVERSION_FAVORITES_TABLE_BUYING_AMOUNT_COLUMN_TITLE: "Сумма покупки",
  CONVERSION_FAVORITES_TABLE_SELLING_AMOUNT_COLUMN_TITLE: "Сумма продажи",
  CONVERSION_FAVORITES_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE: "Кредитовать счет",
  CONVERSION_FAVORITES_TABLE_BUYING_CURRENCY_COLUMN_TITLE: "Валюта покупки",
  CONVERSION_FAVORITES_TABLE_SELLING_CURRENCY_COLUMN_TITLE: "Валюта продажи",

  CONVERSION_MINIMUM_RATE_NULL_TITLE: "Курс по данной валюте не выставлен",

  CONVERSION_IDN_NUMBER_TITLE: "ИДН",
  CONVERSION_IDN_NUMBER_TIN_OF_BANK_FIELD_TITLE: "ИНН банка",
  CONVERSION_IDN_NUMBER_TIN_OF_CUSTOMER_FIELD_TITLE: "ИНН клиента",
  CONVERSION_IDN_NUMBER_CONTRACT_NUMBER_TITLE: "Остальные 9 цифр ИДН",

  // YEISVO PAGE
  YEISVO_HEADER_TITLE: "Список контрактов",
  YEISVO_HEADER_SEARCH_BUTTON_TITLE: "Поиск",

  YEISVO_CONTRACTS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  YEISVO_CONTRACTS_TABLE_BANK_CODE_COLUMN_TITLE: "МФО банка",
  YEISVO_CONTRACTS_TABLE_IDN_CONTRACT_COLUMN_TITLE: "ИДН контракта",
  YEISVO_CONTRACTS_TABLE_CONTRACT_NUMBER_COLUMN_TITLE: "Номер контракта",
  YEISVO_CONTRACTS_TABLE_DATE_OF_CONTRACT_COLUMN_TITLE: "Дата контракта",
  YEISVO_CONTRACTS_TABLE_BANK_INN_COLUMN_TITLE: "ИНН Банка",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_FOR_NAME_COLUMN_TITLE: "Иностранный партнер",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_UZ_NAME_COLUMN_TITLE: "Контрактодержатель",
  YEISVO_CONTRACTS_TABLE_FOREIGN_PARTNER_COUNTRY_COLUMN_TITLE: "Страна инопартнера",

  YEISVO_CLIENTS_INFORMATION_TITLE: "Информация о контракте",
  YEISVO_CLIENTS_INFORMATION_BANK_FUNDS_TITLE: "Поступление средств",
  YEISVO_CLIENTS_INFORMATION_BANK_EMPLOYEE_FULL_NAME: "Ф.И.О. сотрудника банка",
  YEISVO_CLIENTS_INFORMATION_NUMBER_IN_ABS: "Номер платежа в АБС",
  YEISVO_CLIENTS_INFORMATION_CONDITIONS: "Условия поступления",
  YEISVO_CLIENTS_INFORMATION_OPENING_DATE: "Дата поступления",
  YEISVO_CLIENTS_INFORMATION_INVOICE_VALUE: "Фактурная стоимость",
  YEISVO_CLIENTS_INFORMATION_AMOUNT: "Количество",
  YEISVO_CLIENTS_INFORMATION_MEASURE: "Измерение",
  YEISVO_CLIENTS_INFORMATION_WEIGHT: "Вес",
  YEISVO_CLIENTS_INFORMATION_TN_VED: "ТН ВЭД",
  YEISVO_CLIENTS_INFORMATION_SERIAL_NUMBER: "Порядковый номер",
  YEISVO_CLIENTS_INFORMATION_NAME: "Наименование товара",
  YEISVO_CLIENTS_INFORMATION_DECLARATION_TITLE: "Сведения по грузовой таможенной декларации",
  YEISVO_CLIENTS_INFORMATION_CONTRACT_CURRENCY_CODE: "Код валюты контракта",
  YEISVO_CLIENTS_INFORMATION_TOTAL_INVOICE_VALUE: "Общая фактурная стоимость",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_REGIME_CODE: "Код таможенного режима",
  YEISVO_CLIENTS_INFORMATION_REGISTERATION_DATE: "Дата регистрации декларации",
  YEISVO_CLIENTS_INFORMATION_REGISTERED_NUMBER: "Регистрационный номер декларации",
  YEISVO_CLIENTS_INFORMATION_CUSTOM_OFFICE_CODE: "Код таможенного поста",
  YEISVO_CLIENTS_INFORMATION_FORM_TITLE: "Информация по контракту",
  YEISVO_CLIENTS_INFORMATION_FORM_OKPO_FIELD_LABEL_TITLE: "код ОКПО",
  YEISVO_CLIENTS_INFORMATION_FORM_OKONH_FIELD_LABEL_TITLE: "код ОКОНХ",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_FIELD_LABEL_TITLE: "№ контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_IDN_CONTRACT_FIELD_LABEL_TITLE: "ИДН",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_TIN_FIELD_LABEL_TITLE: "ИНН контрактодержателя",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_AREA_FIELD_LABEL_TITLE: "Адресс контрактодержателя",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACTOR_PHONE_FIELD_LABEL_TITLE: "Телефон контрактодержателя",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_OF_CONTRACT_FIELD_LABEL_TITLE: "Дата заключения контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_TYPE_OF_CONTRACT_FIELD_LABEL_TITLE: "Тип контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_SUBJECT_OF_CONTRACT_FIELD_LABEL_TITLE: "Предмет контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE: "Валюта контракта 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Наименование банка иностранного партнера",
  YEISVO_CLIENTS_INFORMATION_FORM_DATE_AND_TIME_YEISVO_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CLIENTS_INFORMATION_FORM_TERM_OF_THE_CONTRACT_FIELD_LABEL_TITLE:
    "Срок исполнения контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_GENERAL_NAME_OF_GOODS_FIELD_LABEL_TITLE:
    "Общее наименование товаров",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_1_FIELD_LABEL_TITLE: "Валюта расчета 1",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_CONTRACT_HOLDER_FIELD_LABEL_TITLE:
    "Наименование контрактодержателя",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_FOREIGN_PARTNER_FIELD_LABEL_TITLE:
    "Наименование иностранного партнера",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Страна иностранного партнера",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_PARTNER_FIELD_LABEL_TITLE:
    "Юридический адрес иностранного партнера",
  YEISVO_CLIENTS_INFORMATION_FORM_SIGN_OF_CONTRACT_ACTIVITY_FIELD_LABEL_TITLE:
    "Признак активности контракта",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Сумма контракта в валюте 1",
  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_AMOUNT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Сумма контракта в валюте 2",
  YEISVO_CLIENTS_INFORMATION_FORM_NAME_OF_PARENT_ORGANIZATION_FIELD_LABEL_TITLE:
    "Наименование вышестоящей организации контрактодержателя",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_1_FIELD_LABEL_TITLE:
    "Сумма кредиторской задолженности в валюте контракта 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_PAYABLE_CONTRACT_2_FIELD_LABEL_TITLE:
    " Сумма кредиторской задолженности в валюте контракта 2",
  YEISVO_CLIENTS_INFORMATION_FORM_BANK_CODE_OF_AN_AUTHORIZED_BANK_FIELD_LABEL_TITLE:
    "Код МФО уполномоченного банка",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_1_FIELD_LABEL_TITLE:
    "Сумма дебиторской задолженности в валюте контракта 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Сумма дебиторской задолженности в валюте контракта 2",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Сумма просроченной кредиторской задолженности в валюте контракта 1",
  YEISVO_CLIENTS_INFORMATION_FORM_TOTAL_AMOUNT_OF_CCD_FIELD_LABEL_TITLE: "Обшая сумма ГТД в",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_PAYABLE_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Сумма просроченной кредиторской задолженности в валюте контракта 2",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_1_FIELD_LABEL_TITLE:
    "Сумма просроченной дебиторской задолженности в валюте контракта 1",
  YEISVO_CLIENTS_INFORMATION_FORM_AMOUNT_OF_OVERDUE_RECEIVABLES_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE:
    "Сумма просроченной дебиторской задолженности в валюте контракта 2",

  YEISVO_CONTRACT_SPECIFICATIONS_TITLE: "Спецификации по контракту",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_TAG_FIELD_LABEL_TITLE: "Признак спецификации",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_DATE_FIELD_LABEL_TITLE: "Дата спецификации",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_SPECIFICATION_NUMBER_FIELD_LABEL_TITLE: "Номер спецификации",
  YEISVO_CONTRACT_SPECIFICATIONS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Дата и время ввода",

  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_TN_CODE_COLUMN_TITLE: "Код товара по ТН ВЭД",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_ITEMS_NAME_COLUMN_TITLE: "Наименование товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_SERIAL_NO_COLUMN_TITLE: "Номер товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_UNIT_CODE_COLUMN_TITLE: "Код единицы измерения",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_QUANTITY_AGRMNO_COLUMN_TITLE: "Объем товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_CURR_CODE_COLUMN_TITLE: "Валюта цены товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_COST_COLUMN_TITLE: "Цена за единицу товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_AMOUNT_COLUMN_TITLE: "Общая стоимость товара",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_INS_TIME_COLUMN_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_SPECIFICATIONS_GOODS_TABLE_STATUS_COLUMN_TITLE: "Статус документа",

  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_COLUMN_TITLE: "Код таможенного поста",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_COLUMN_TITLE: "Регистрационный номер декларации",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_COLUMN_TITLE: "Дата регистрации декларации",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_COLUMN_TITLE: "Код страны отправителя",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_COLUMN_TITLE: "Код характера сделки",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_COLUMN_TITLE: "Дата отгрузки",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_COLUMN_TITLE: "Дата пересечения таможенной границы",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_COLUMN_TITLE: "Наименование экспортера",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_COLUMN_TITLE: "Курс валюты контракта",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_COLUMN_TITLE: "Наименование импортера",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_COLUMN_TITLE: "Наименование контрактодержателя",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_COLUMN_TITLE: "Таможенный режим",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_COLUMN_TITLE: "Код валюты контракта",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_COLUMN_TITLE: "Общая фактурная стоимость",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_COLUMN_TITLE: "Код страны отправления",

  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G32_COLUMN_TITLE: "Порядковый номер товара",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G33_COLUMN_TITLE: "Код товара по ТН ВЭД",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G42_COLUMN_TITLE: "Фактурная стоимость",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37C_COLUMN_TITLE: "Код процедуры",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31NAME_COLUMN_TITLE: "Наименование товара",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G31AMOUNT_COLUMN_TITLE: "количество поступившего товара",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G41_COLUMN_TITLE: "Код дополнительной единицы измерения",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G38_COLUMN_TITLE: "Вес товара (нетто)",
  YEISVO_CONTRACT_DECLARATION_GOODS_TABLE_G37B_COLUMN_TITLE: "Код страны отправления",

  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TITLE: "Базис поставки товара по Инкотермс",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DIGITAL_CODE_TITLE: "Цифровой код поставки ",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DESTINATION_TITLE: "Пункт назначения",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE:
    "Номер дополнительного соглашения",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_SERIAL_NUMBER_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE:
    "Дата и время ввода",
  YEISVO_CONTRACT_DELIVERY_IN_INCOTERMS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус",

  YEISVO_CONTRACT_CALC_FORM_TITLE: "Форма расчетов",
  YEISVO_CONTRACT_ACT_FORM_TITLE: "Акт на оказание услуг/работ",
  YEISVO_CONTRACT_CALC_FORM_TABLE_PREPAYMENT_TERMS_TITLE: "Условие оплаты",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DUE_DATE_TITLE: "Срок оплаты (кол-во дней с даты импорта)",
  YEISVO_CONTRACT_CALC_FORM_TABLE_LAST_PAYMENT_DATE_TITLE: "Последняя дата оплаты",
  YEISVO_CONTRACT_CALC_FORM_TABLE_TIME_OF_IMPORT_AFTER_PAYMENT_TITLE:
    "Срок импорта после оплаты (кол-во дней)",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_OF_IMPORT_AFTER_PAYMENT_TITLE: "Дата импорта после оплаты",
  YEISVO_CONTRACT_CALC_FORM_TABLE_IMPORT_PERIOD_AFTER_LETTER_OF_CREDIT_OPENING_TITLE:
    "Срок импорта после открытия аккредитива (кол-во дней)",
  YEISVO_CONTRACT_CALC_FORM_TABLE_SUPPLEMENTARY_AGREEMENT_NUMBER_TITLE: "Номер доп. соглашения",
  YEISVO_CONTRACT_CALC_FORM_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE: "Дата и время ввода",
  YEISVO_CONTRACT_CALC_FORM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус",

  YEISVO_PAYMENT_BY_CONTRACT_TITLE: "Оплата по договору",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_FIELD_LABEL_TITLE: "Дата оплаты",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_FUNDS_SOURCE_FILED_LABEL_TITLE: "Источник средств",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_NUMBER_IN_ABC_FIELD_LABEL_TITLE: "Номер платежа в ABC",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_SERIAL_NUMBER_FIELD_LABEL_TITLE: "Серийный номер",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_BANK_EMPLOYEE_FULLNAME_FIELD_LABEL_TITLE:
    "Ф.И.О. сотрудника банка",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE: "Дата и время ввода",
  YEISVO_PAYMENT_BY_CONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус",

  YEISVO_TABLE_ID_FIELD_LABEL_TITLE: "Номер",
  YEISVO_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус",
  YEISVO_TABLE_TN_VED_FIELD_LABEL_TITLE: "ТН ВЭД",
  YEISVO_TABLE_VOLUME_FIELD_LABEL_TITLE: "Объем",
  YEISVO_TABLE_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  YEISVO_TABLE_UNIT_PRICE_FIELD_LABEL_TITLE: "Цена за единицу",
  YEISVO_TABLE_TOTAL_COST_FIELD_LABEL_TITLE: "Общая стоимость",
  YEISVO_TABLE_MEASUREMENT_FIELD_LABEL_TITLE: "Измерение",
  YEISVO_TABLE_NAME_OF_PRODUCT_FIELD_LABEL_TITLE: "Наименование товара",
  YEISVO_TABLE_DATE_AND_TIME_OF_ENTRY_FIELD_LABEL_TITLE: "Дата и время ввода",
  YEISVO_REGION_FIELD_LABEL_TITLE: "Регион",

  /// New Yeisvo

  YEISVO_CONTRACT_AGREEMENTS_TITLE: "Дополнительное соглашение",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата дополнительного соглашения",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_MVESDATE_FIELD_LABEL_TITLE:
    "Дата регистрации дополнительного соглашения в МВЭСИТ (не используется)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AGRMSUBJECT_FIELD_LABEL_TITLE:
    "Предмет дополнительного соглашения",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Валюта контракта 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Валюта контракта 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE1_FIELD_LABEL_TITLE: "Валюта расчета 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_ACCCURRCODE2_FIELD_LABEL_TITLE: "Валюта расчета 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORNAME_FIELD_LABEL_TITLE:
    "Наименование иностранного партнера",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Страна инопартнера (справочник dicCountry)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORFORADDRESS_FIELD_LABEL_TITLE:
    "Юридический адрес иностранного партнера",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Наименование банка иностранного партнера",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Страна банка инопартнера (справочник dicCountry)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Юридический адрес банка иностранного партнера",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE:
    "Банковские реквизиты иностранного партнера",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CONTRACTORUZNAME_FIELD_LABEL_TITLE:
    "Наименование контрактодержателя",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Сумма контракта в валюте контракта 1",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Сумма контракта в валюте контракта 2",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CNTRSUBJECT_FIELD_LABEL_TITLE:
    "Предмет контракта (справочник dicContractSubject)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_PRODUCTSNAME_FIELD_LABEL_TITLE: "Общее наименование товаров",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECTYPE_FIELD_LABEL_TITLE:
    "Срок исполнения контракта (справочник dicTerm)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_EXECDATE_FIELD_LABEL_TITLE: "Дата исполнения контракта",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CREDITRATE_FIELD_LABEL_TITLE: "Процент займа (годовых)",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_CSTDATE_FIELD_LABEL_TITLE:
    "Дата регистрации дополнительного соглашения в таможенных органах",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер доп.соглашения",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_INDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Номер по справочнику индустриальных зон",
  YEISVO_CONTRACT_AGREEMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_BARTERTERM_TITLE: "Форма взаиморасчётов",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Тип взаиморасчётов",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Последняя дата экспорта",
  YEISVO_CONTRACT_BARTERTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Срок экспорта",
  YEISVO_CONTRACT_BARTERTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE: "Срок импорта",
  YEISVO_CONTRACT_BARTERTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Срок поступления выручки по условиям контракта",
  YEISVO_CONTRACT_BARTERTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_BARTERTERM_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_BARTERTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_COMMISSIONS_TITLE: "Комиссия иностранного банка за перевод средств",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма комиссии в валюте поступления",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Номер платежа, с которого удержана комиссия",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Номер поступления средств, с которого удержана комиссия",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_COMMISSIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_COMPENSATIONS_TITLE: "Поступление страхового возмещения по контракту",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Дата поступления страхового возмещения",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_POLICYNO_FIELD_LABEL_TITLE:
    "Номер страхового полиса, по которому поступило возмещение",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма страхового возмещения",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта страхового возмещения",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_COMPENSATIONS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_CONFIRMATION_TITLE: "Подтверждения",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя, который подтвердил/не подтвердил документ",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_CONFIRM_FIELD_LABEL_TITLE: "признак подтверждения",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ORGTYPE_FIELD_LABEL_TITLE: "тип организации",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_REASON_FIELD_LABEL_TITLE:
    "основание для не подтверждения документа",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_DATE_FIELD_LABEL_TITLE: "дата и время подтверждения",
  YEISVO_CONTRACT_CONFIRMATION_TABLE_ACTIONTYPE_FIELD_LABEL_TITLE: "Признак редактирования",

  YEISVO_CONTRACT_CONSIGNEE_TITLE: "Грузополучатель",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_NAME_FIELD_LABEL_TITLE: "Наименование грузополучателя",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Страна грузополучателя",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INN_FIELD_LABEL_TITLE: "ИНН/ПИНФЛ",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_CONSIGNEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_CONSIGNOR_TITLE: "Грузоотправитель",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_NAME_FIELD_LABEL_TITLE: "Наименование отправителя товара",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Страна отправителя товара",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INN_FIELD_LABEL_TITLE: "ИНН/ПИНФЛ",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_CONSIGNOR_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_DECLARATION_TITLE: "Сведения по отгрузке",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7A_FIELD_LABEL_TITLE: "Код таможенного поста",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7C_FIELD_LABEL_TITLE: "Регистрационный номер декларации",
  YEISVO_CONTRACT_DECLARATION_TABLE_G7B_FIELD_LABEL_TITLE: "Дата регистрации декларации",
  YEISVO_CONTRACT_DECLARATION_TABLE_G17_FIELD_LABEL_TITLE: "Код страны отправителя",
  YEISVO_CONTRACT_DECLARATION_TABLE_G24A_FIELD_LABEL_TITLE: "Код характера сделки",
  YEISVO_CONTRACT_DECLARATION_TABLE_GDVIPDATE_FIELD_LABEL_TITLE: "Дата отгрузки",
  YEISVO_CONTRACT_DECLARATION_TABLE_GC3DATE_FIELD_LABEL_TITLE:
    "Дата пересечения таможенной границы",
  YEISVO_CONTRACT_DECLARATION_TABLE_G2NAME_FIELD_LABEL_TITLE:
    "Наименование экспортера (отправителя)",
  YEISVO_CONTRACT_DECLARATION_TABLE_G23_FIELD_LABEL_TITLE: "Курс валюты контракта",
  YEISVO_CONTRACT_DECLARATION_TABLE_G8NAME_FIELD_LABEL_TITLE: "Наименование импортера (получателя)",
  YEISVO_CONTRACT_DECLARATION_TABLE_G9NAME_FIELD_LABEL_TITLE: "Наименование контрактодержателя",
  YEISVO_CONTRACT_DECLARATION_TABLE_G1_FIELD_LABEL_TITLE: "Таможенный режим",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22A_FIELD_LABEL_TITLE: "Код валюты контракта",
  YEISVO_CONTRACT_DECLARATION_TABLE_G22B_FIELD_LABEL_TITLE: "Общая фактурная стоимость",
  YEISVO_CONTRACT_DECLARATION_TABLE_G15_FIELD_LABEL_TITLE: "Код страны отправления",

  YEISVO_CONTRACT_DECLARATIONGOOD_TITLE: "Товары по декларации",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G32_FIELD_LABEL_TITLE: "Порядковый номер товара",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G33_FIELD_LABEL_TITLE: "Код товара по ТН ВЭД",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G42_FIELD_LABEL_TITLE: "Фактурная стоимость",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37C_FIELD_LABEL_TITLE: "Код процедуры",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31NAME_FIELD_LABEL_TITLE: "Наименование товара",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G31AMOUNT_FIELD_LABEL_TITLE:
    "количество поступившего товара",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G41_FIELD_LABEL_TITLE:
    "Код дополнительной единицы измерения",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G38_FIELD_LABEL_TITLE: "Вес товара (нетто)",
  YEISVO_CONTRACT_DECLARATIONGOOD_TABLE_G37B_FIELD_LABEL_TITLE: "Код предшествующего режима",

  YEISVO_CONTRACT_DELEGATE_TITLE: "Переход прав и обязательств по контракту другому резиденту",
  YEISVO_CONTRACT_DELEGATE_TABLE_REASONTYPE_FIELD_LABEL_TITLE: "Основание для перехода",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTNAME_FIELD_LABEL_TITLE:
    "Наименование суда, принявшего решение",
  YEISVO_CONTRACT_DELEGATE_TABLE_COURTDSCNDATE_FIELD_LABEL_TITLE: "Дата решения суда",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNNO_FIELD_LABEL_TITLE:
    "Номер решения Правительства Республики Узбекистан",
  YEISVO_CONTRACT_DELEGATE_TABLE_GOVDSCNDATE_FIELD_LABEL_TITLE:
    "Дата решения Правительства Республики Узбекистан",
  YEISVO_CONTRACT_DELEGATE_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINN_FIELD_LABEL_TITLE: "ИНН/ПИНФЛ правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKID_FIELD_LABEL_TITLE: "МФО банка правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKPO_FIELD_LABEL_TITLE:
    "Код ОКПО правопреемника (не используется)",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTOKONX_FIELD_LABEL_TITLE: "Код ОКЭД правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTNAME_FIELD_LABEL_TITLE: "Наименование правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTRGNCODE_FIELD_LABEL_TITLE: "Область правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTINDUSTRIALZONECODE_FIELD_LABEL_TITLE:
    "Принадлежность правопреемника к индустриальной зоне",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTADDRESS_FIELD_LABEL_TITLE: "Адрес правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTPHONE_FIELD_LABEL_TITLE: "Телефон правопреемника",
  YEISVO_CONTRACT_DELEGATE_TABLE_REMARK_FIELD_LABEL_TITLE: "Примечание",
  YEISVO_CONTRACT_DELEGATE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Признак подтверждения передачи контракта передающим банком",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Признак подтверждения передачи контракта принимающим банком",
  YEISVO_CONTRACT_DELEGATE_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя передающего банка",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя принимающего банка",
  YEISVO_CONTRACT_DELEGATE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_DELEGATE_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "ИДН контракта перед переходом прав и обязательств",
  YEISVO_CONTRACT_DELEGATE_TABLE_PARENTCOMPANYCODE_FIELD_LABEL_TITLE:
    "Код министерства/ведомства, в которое входит хоз субъект",
  YEISVO_CONTRACT_DELEGATE_TABLE_NEWIDN_FIELD_LABEL_TITLE: "ИДН нового контракта (после передачи)",
  YEISVO_CONTRACT_DELEGATE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",
  YEISVO_CONTRACT_DELEGATE_TABLE_DSTBANKINN_FIELD_LABEL_TITLE: "ИНН банка правопреемника",

  YEISVO_CONTRACT_DELIVERYTERM_TITLE: "Базис поставки товара по Инкотермс",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_DESTINATION_FIELD_LABEL_TITLE: "Пункт назначения",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INCOTERMSCODE_FIELD_LABEL_TITLE: "Цифровой код поставки",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_DELIVERYTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_ENDCONTRACT_TITLE: "Завершение операций по контракту",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата завершения",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_REMARK_FIELD_LABEL_TITLE: "Примечания",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата ввода в систему",
  YEISVO_CONTRACT_ENDCONTRACT_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_EXPORTTERM_TITLE: "Условия экспорта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTYPE_FIELD_LABEL_TITLE: "Тип экспорта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTDEADLINE_FIELD_LABEL_TITLE: "Последняя дата экспорта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_EXPORTTERM_FIELD_LABEL_TITLE: "Срок экспорта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE:
    "Срок поступления выручки по условиям контракта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE:
    "Срок поступления выручки по условиям контракта",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGDEADLINE_FIELD_LABEL_TITLE: "Срок лизинга",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_LEASINGRATE_FIELD_LABEL_TITLE: "Процент лизинга",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_DEADLINETYPE_FIELD_LABEL_TITLE: "Срок поступления выручки",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_EXPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_FARE_TITLE: "Транспортные расходы к спецификации",
  YEISVO_CONTRACT_FARE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта транспортных расходов",
  YEISVO_CONTRACT_FARE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма транспортных расходов",
  YEISVO_CONTRACT_FARE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_FARE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_FINANCEINFO_TITLE: "Отправитель средств",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORNAME_FIELD_LABEL_TITLE: "Наименование отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORCOUNTRYCODE_FIELD_LABEL_TITLE: "Страна отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_COMPANYFORADDRESS_FIELD_LABEL_TITLE:
    "Юридический адрес отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORNAME_FIELD_LABEL_TITLE: "Наименование банка отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Страна банка отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORADDRESS_FIELD_LABEL_TITLE:
    "Юридический адрес банка отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_BANKFORATTRIBUTES_FIELD_LABEL_TITLE:
    "Банковские реквизиты отправителя",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_FINANCEINFO_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_GUARANTEE_TITLE: "Гарантия иностранного банка",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер гарантии",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE: "Дата открытия гарантии",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта гарантии",
  YEISVO_CONTRACT_GUARANTEE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма гарантии",
  YEISVO_CONTRACT_GUARANTEE_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Курс пересчета валюты гарантии к валюте контракта",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Срок истечения гарантии (дата)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Срок истечения гарантии (количество дней)",
  YEISVO_CONTRACT_GUARANTEE_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_GUARANTEE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_GUARANTEE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TITLE: "Изменение суммы гарантии",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Действующая сумма гарантии",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "Новая сумма гарантии",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения суммы гарантии",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_GUARANTEESUMCHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TITLE: "Изменение срока гарантии иностранного банка",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Действующий срок истечения гарантии",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Действующий срок истечения гарантии",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "Новый срок истечения гарантии (дата)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "Новый срок истечения гарантии (количество дней)",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения срока истечения гарантии",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_GUARANTEETIMECHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_IMPORTTERM_TITLE: "Форма расчетов",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTMETHOD_FIELD_LABEL_TITLE: "Тип импорта",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTTERM_FIELD_LABEL_TITLE: "Срок оплаты",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_PAYMENTDEADLINE_FIELD_LABEL_TITLE: "Последняя дата оплаты",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERM_FIELD_LABEL_TITLE:
    "Срок импорта после оплаты (количество дней)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTDEADLINE_FIELD_LABEL_TITLE:
    "Дата импорта после оплаты (дата)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_IMPORTTERMAFTERACCR_FIELD_LABEL_TITLE:
    "Срок импорта после открытия аккредитива (количество дней)",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_IMPORTTERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_LEASEPAYMENTS_TITLE: "Поступление процентов по лизинговому договору",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата поступления (оплаты)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма процентов (сумма лизинга)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта поступления (оплаты)",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_LEASEPAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TITLE: "Изменение суммы аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Действующая сумма аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "Новая сумма аккредитива в валюте аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения суммы аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_LETTEROF_CREDITSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TITLE: "Изменение срока аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Действующий срок действия аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "Новый срок действия аккредитива (количество дней)",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения срока действия аккредитива",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_LETTEROF_CREDITTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_LETTERSOF_CREDIT_TITLE: "Аккредитив",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер аккредитива",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCSTARTDATE_FIELD_LABEL_TITLE:
    "Дата открытия аккредитива",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта аккредитива",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Сумма аккредитива в валюте аккредитива",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE: "Срок действия аккредитива",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_LETTERSOF_CREDIT_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа ",

  YEISVO_CONTRACT_MANUFACTURER_TITLE: "Производитель",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_NAME_FIELD_LABEL_TITLE: "Наименование",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Страна",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INN_FIELD_LABEL_TITLE: "ИНН/ПИНФЛ",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_MANUFACTURER_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_MOVESFROM_EX_TITLE: "Переброска средств c другого контракта (взаиморасчет)",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата переброски",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта расчета",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма переброски",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "ИДН экспортного контракта, с которого были переброшены средства",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Курс пересчета валюты расчёта к валюте контракта",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за работу (услугу) в валюте контракта 1",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за работу (услугу) в валюте контракта 2",
  YEISVO_CONTRACT_MOVESFROM_EX_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "Отношение валюты расчёта к валюте контракта",

  YEISVO_CONTRACT_MOVESFROM_IM_TITLE: "Переброска средств с другого контракта",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата переброски",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма переброски",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта расчета",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SOURCEIDN_FIELD_LABEL_TITLE:
    "ИДН импортного контракта, с которого были переброшены средства",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Курс пересчета валюты расчета к валюте контракта",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за услуги (работу) в валюте контракта 1",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за услуги (работу) в валюте контракта 2",
  YEISVO_CONTRACT_MOVESFROM_IM_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "Отношение валюты расчета к валюте контракта",

  YEISVO_CONTRACT_MOVESTO_EX_TITLE: "Переброска средств на другой контракт",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Данные о средствах, подлежащих переброске",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата переброски",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE: "Сумма переброски с контракта 1",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "ИДН экспортного контракта, на который были переброшены средства",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESTO_EX_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_MOVESTO_IM_TITLE: "Переброска средств на другой контракт",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_PAYMENTSERIALNO_FIELD_LABEL_TITLE:
    "Данные о средствах, подлежащих переброске",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SOURCEAMOUNT_FIELD_LABEL_TITLE: "Сумма переброски с контракта 1",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата переброски",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_DESTINATIONIDN_FIELD_LABEL_TITLE:
    "ИДН импортного контракта, на который перебрасываются средства",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_MOVESTO_IM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_PAYABLE_DROPS_TITLE: "Cписание кредиторской задолженности по контракту",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта списания",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Сумма кредиторской задолженности, подлежащая списанию в валюте кредиторской задолженности",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для списания кредиторской задолженности",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата списания",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PAYABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_PAYMENT_REFS_TITLE: "Справка о расчётах",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Информация о поступлении средств",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_GUARANTEENO_FIELD_LABEL_TITLE: "Номер банковской гарантии",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_POLICYNO_FIELD_LABEL_TITLE: "Номер страхового полиса",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для оформления справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_LETTEROFCREDITNO_FIELD_LABEL_TITLE: "Номер аккредитива",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_UNUSEDAMOUNT_FIELD_LABEL_TITLE:
    "Остаток неиспользованной суммы справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта справки",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Срок действия справки (дата)",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Информация о перебросках",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PAYMENT_REFS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TITLE: "Уменьшение суммы справки о расчётах",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE: "Новая сумма справки",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_REASON_FIELD_LABEL_TITLE:
    "Основание для уменьшения суммы справки",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_MAINBRANCHUSERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя головного банка",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PAYMENTREFSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_PAYMENTS_TITLE: "Оплата по контракту",
  YEISVO_CONTRACT_PAYMENTS_TABLE_BANKFORNAME_FIELD_LABEL_TITLE:
    "Наименование зарубежного банка (иностранного кредитора, заимодателя), с которого был осуществлён платёж",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер платежа в АБС",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_PAYMENTS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата оплаты",
  YEISVO_CONTRACT_PAYMENTS_TABLE_FUNDSOURCETYPE_FIELD_LABEL_TITLE: "Источник средств",
  YEISVO_CONTRACT_PAYMENTS_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма оплаты",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта оплаты",
  YEISVO_CONTRACT_PAYMENTS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_PAYMENTS_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PAYMENTS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",
  YEISVO_CONTRACT_PAYMENTS_TABLE_CURRRATE_FIELD_LABEL_TITLE:
    "Курс пересчета валюты расчёта к валюте контракта",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_GOODSAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за товар в валюте контракта 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма оплаты за работы (услуги) в валюте контракта 1",
  YEISVO_CONTRACT_PAYMENTS_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма оплаты за работы (услуги) в валюте контракта 2",
  YEISVO_CONTRACT_PAYMENTS_TABLE_RATETYPE_FIELD_LABEL_TITLE:
    "Отношение валюты расчёта к валюте контракта",

  YEISVO_CONTRACT_PENALTIES_TITLE: "Отношение валюты расчёта к валюте контракта",
  YEISVO_CONTRACT_PENALTIES_TABLE_PENALTYTYPE_FIELD_LABEL_TITLE: "Вид санкции",
  YEISVO_CONTRACT_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата оплаты (поступления) штрафа",
  YEISVO_CONTRACT_PENALTIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма штрафа",
  YEISVO_CONTRACT_PENALTIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта штрафа",
  YEISVO_CONTRACT_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_PERFORMED_SERVICE_TITLE: "Акт (подтверждающий документ) оказанных услуг",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата акта/инвойса",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер акта/инвойса",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE1_FIELD_LABEL_TITLE: "Валюта контракта 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT1_FIELD_LABEL_TITLE:
    "Сумма акта/инвойса в валюте контракта 1",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_AMOUNT2_FIELD_LABEL_TITLE:
    "Сумма акта/инвойса в валюте контракта 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_CURRCODE2_FIELD_LABEL_TITLE: "Валюта контракта 2",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_IMEXFLAG_FIELD_LABEL_TITLE: "Признак акта",
  YEISVO_CONTRACT_PERFORMED_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_POLICIES_TITLE: "Страховой полис",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер страхового полиса",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Наименование страховой компании",
  YEISVO_CONTRACT_POLICIES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта полиса",
  YEISVO_CONTRACT_POLICIES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма полиса",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE: "Срок истечения полиса (дата)",
  YEISVO_CONTRACT_POLICIES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Срок истечения полиса (количество дней)",
  YEISVO_CONTRACT_POLICIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_POLICIES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_POLICIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_POLICYSUM_CHANGES_TITLE: "Изменение суммы страхового полиса",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Действующая сумма полиса",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_NEWAMOUNT_FIELD_LABEL_TITLE:
    "Новая сумма полиса в валюте полиса",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения суммы полиса",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_POLICYSUM_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_POLICYTIME_CHANGES_TITLE: "Изменение срока страхового полиса",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDATE_FIELD_LABEL_TITLE:
    "Действующий срок истечения полиса (дата)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_DOCVALDAYS_FIELD_LABEL_TITLE:
    "Действующий срок истечения полиса",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDATE_FIELD_LABEL_TITLE:
    "Новый срок истечения полиса (дата)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_NEWDOCVALDAYS_FIELD_LABEL_TITLE:
    "Новый срок истечения полиса (количество дней)",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для изменения срока истечения полиса",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_POLICYTIME_CHANGES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_RECEIVABLE_DROPS_TITLE: "Cписание дебиторской задолженности по контракту",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта списания",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_AMOUNT_FIELD_LABEL_TITLE:
    "Сумма дебиторской задолженности, подлежащая списанию в валюте дебиторской задолженности",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_REASONTYPE_FIELD_LABEL_TITLE:
    "Основание для списания дебиторской задолженности",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата списания",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_RECEIVABLE_DROPS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_RECEIVABLE_INFOS_TITLE: "Дополнительная информация по дебиторской задолженности",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPCURRCODE_FIELD_LABEL_TITLE:
    "Валюта товара, находящегося в таможенном режиме временного ввоза",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_TEMPIMPAMOUNT_FIELD_LABEL_TITLE:
    "Сумма товара находящегося в таможенном режиме временного ввоза",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_CUSTOMSMODE_FIELD_LABEL_TITLE: "Таможенный режим",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_REMARK_FIELD_LABEL_TITLE: "Примечание",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_RECEIVABLE_INFOS_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_REFUNDS_EXP_TITLE: "Возврат ранее поступивших средств",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Данные о средствах, подлежащих возврату",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Данные о средствах, подлежащих возврату",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма возврата",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер платежа в АБС",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата возврата средств",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_REFUNDS_EXP_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_REFUNDS_IMP_TITLE: "Возврат ранее оплаченных средств",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCNO_FIELD_LABEL_TITLE: "Номер платежа в АБС",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата возврата средств",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма возврата в валюте возврата",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта возврата",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_REFUNDS_IMP_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_SALES_TITLE: "Информация об обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_FUNDSERIALNO_FIELD_LABEL_TITLE:
    "Данные о поступлениях, подлежащих обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_BENEFITSINFOCODE_FIELD_LABEL_TITLE: "Льготы по обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_SALETYPE_FIELD_LABEL_TITLE: "Вид обязательной продажи",
  YEISVO_CONTRACT_SALES_TABLE_DOCDATE_FIELD_LABEL_TITLE:
    "Дата обязательной продажи или ввода информации по обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_MOVEFROMEXSERIALNO_FIELD_LABEL_TITLE:
    "Данные о переброске денег на контракт, подлежащих обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_SALES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма обязательной продажи",
  YEISVO_CONTRACT_SALES_TABLE_COMPENSATIONSERIALNO_FIELD_LABEL_TITLE:
    "Данные о поступлении страхового возмещения, подлежащего обязательной продаже",
  YEISVO_CONTRACT_SALES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_SALES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_SALES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_SERVICE_TITLE: "Услуга в акте",
  YEISVO_CONTRACT_SERVICE_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORNAME_FIELD_LABEL_TITLE:
    "Наименование исполнителя услуг (работ)",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTORCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Страна исполнителя услуг (работ)",
  YEISVO_CONTRACT_SERVICE_TABLE_EXECUTIONCOUNTRYCODE_FIELD_LABEL_TITLE:
    "Страна оказания услуг (работ)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICENAME_FIELD_LABEL_TITLE: "Наименование услуги (работы)",
  YEISVO_CONTRACT_SERVICE_TABLE_SERVICETYPE_FIELD_LABEL_TITLE: "Код услуги (работы)",
  YEISVO_CONTRACT_SERVICE_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта цены услуги (работы)",
  YEISVO_CONTRACT_SERVICE_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Стоимость услуги (работы)",
  YEISVO_CONTRACT_SERVICE_TABLE_SELFMADEFLAG_FIELD_LABEL_TITLE: "Собственные услуги",
  YEISVO_CONTRACT_SERVICE_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_SERVICE_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TITLE:
    "Штраф налоговых органов за просроченную дебиторскую задолженность по контракту",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_SERIALNO_FIELD_LABEL_TITLE:
    "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата уплаты штрафа",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_AMOUNTSOUM_FIELD_LABEL_TITLE:
    "Сумма уплаченного штрафа в сумах",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_TAXAGENCY_PENALTIES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_TAXES_TITLE: "Налог на доход, уплаченный резидентом за границей",
  YEISVO_CONTRACT_TAXES_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_TAXES_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата оплаты налога",
  YEISVO_CONTRACT_TAXES_TABLE_AMOUNT_FIELD_LABEL_TITLE: "Сумма налога",
  YEISVO_CONTRACT_TAXES_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта уплаченного налога",
  YEISVO_CONTRACT_TAXES_TABLE_COUNTRYCODE_FIELD_LABEL_TITLE: "Страна оплаты налога",
  YEISVO_CONTRACT_TAXES_TABLE_USERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя банка, который ввел (редактировал) документ",
  YEISVO_CONTRACT_TAXES_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_TAXES_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_TOLLING_TERM_TITLE: "Взаиморасчёт товаром (по толлингу)",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSTITLE_FIELD_LABEL_TITLE:
    "Наименования товара, которым осуществлен взаиморасчет",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRCODE_FIELD_LABEL_TITLE: "Валюта взаиморасчета",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE1_FIELD_LABEL_TITLE:
    "Курс пересчета валюты взаиморасчёта к валюте контракта 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_CURRRATE2_FIELD_LABEL_TITLE:
    "Курс пересчета валюты взаиморасчёта к валюте контракта 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT1_FIELD_LABEL_TITLE:
    "Сумма услуги (взаиморасчета) в валюте контракта 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERVICEAMOUNT2_FIELD_LABEL_TITLE:
    "Сумма услуги (взаиморасчета) в валюте контракта 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_DOCDATE_FIELD_LABEL_TITLE: "Дата взаиморасчета",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE1_FIELD_LABEL_TITLE:
    "Отношение валюты взаиморасчёта к валюте контракта 1",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_RATETYPE2_FIELD_LABEL_TITLE:
    "Отношение валюты взаиморасчёта к валюте контракта 2",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_GOODSAMOUNT_FIELD_LABEL_TITLE: "Сумма товара взаиморасчета",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_INSTIME_FIELD_LABEL_TITLE:
    "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_TOLLING_TERM_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",

  YEISVO_CONTRACT_TRANSFER_TITLE: "Перевод контракта в другой банк (на товарно-сырьевую биржу)",
  YEISVO_CONTRACT_TRANSFER_TABLE_TRANSFERTYPE_FIELD_LABEL_TITLE: "Контракт переводится",
  YEISVO_CONTRACT_TRANSFER_TABLE_AGRMNO_FIELD_LABEL_TITLE: "Номер дополнительного соглашения",
  YEISVO_CONTRACT_TRANSFER_TABLE_NEWIDN_FIELD_LABEL_TITLE: "ИДН нового контракта (после перевода)",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKID_FIELD_LABEL_TITLE:
    "МФО банка/номер центра оказания услуг, куда переходит контракт на обслуживание",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHINN_FIELD_LABEL_TITLE:
    "ИНН товарно-сырьевой биржи (ярмарки), куда переходит контракт на обслуживание",
  YEISVO_CONTRACT_TRANSFER_TABLE_REMARK_FIELD_LABEL_TITLE: "Примечание",
  YEISVO_CONTRACT_TRANSFER_TABLE_SERIALNO_FIELD_LABEL_TITLE: "Порядковый номер документа в ЕЭИСВО",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKUSERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя передающего банка",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKUSERNAME_FIELD_LABEL_TITLE:
    "ФИО пользователя принимающего банка/биржи",
  YEISVO_CONTRACT_TRANSFER_TABLE_SRCBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Признак подтверждения передающим банком",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKCONFIRMATIONFLAG_FIELD_LABEL_TITLE:
    "Признак подтверждения принимающим банком",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTEXCHNAME_FIELD_LABEL_TITLE:
    "Наименование товарно-сырьевой биржи (ярмарки), куда переходит контракт на обслуживание",
  YEISVO_CONTRACT_TRANSFER_TABLE_INSTIME_FIELD_LABEL_TITLE: "Дата и время ввода данных в ЕЭИСВО",
  YEISVO_CONTRACT_TRANSFER_TABLE_OLDIDN_FIELD_LABEL_TITLE:
    "ИДН контракта перед переводом контракта",
  YEISVO_CONTRACT_TRANSFER_TABLE_STATUS_FIELD_LABEL_TITLE: "Статус документа",
  YEISVO_CONTRACT_TRANSFER_TABLE_DSTBANKINN_FIELD_LABEL_TITLE:
    "ИНН банка, куда переходит контракт на обслуживание",

  // Analytics Page
  ANALYTICS_BALANCE_OF_ALL_ACCOUNTS_TITLE: "Баланс счёта",

  ANALYTICS_BANK_COMMISSIONS_TITLE: "Банковские комиссии",

  ANALYTICS_COSTS_TITLE: "Расходы",

  // User Info & Settings
  USER_INFO_AND_SETTINGS_TITLE: "Информация",
  USER_INFO_AND_SETTINGS_SUBMIT_BUTTON_TITLE: "Отправить",
  USER_INFO_AND_SETTINGS_PASSWORD_SETTINGS_TITLE: "Установка пароля",
  USER_INFO_AND_SETTINGS_LANGUAGE_SETTINGS_TITLE: "Настройка языка",
  USER_INFO_AND_SETTINGS_NOTIFICATIONS_SETTING_TITLE: "Настройка уведомлений",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_TITLE: "Настройка темы",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_YES_BUTTON_TITLE: "Да",
  USER_INFO_AND_SETTINGS_THEME_SETTINGS_NO_BUTTON_TITLE: "Нет",

  USER_INFO_AND_SETTINGS_PASSWORD_CHANGED: "Пароль изменен",
  USER_INFO_AND_SETTINGS_WRONG_PASSWORD: "Неправильный пароль",
  USER_INFO_AND_SETTINGS_SAME_PASSWORD: "Текущий пароль и новый пароль совпадают",
  USER_INFO_AND_SETTINGS_DUPLICATED_PASSWORD: "Пароль дублируется из 5 последних паролей",
  USER_INFO_AND_SETTINGS_TRY_AGAIN: "Пожалуйста, повторите попытку позже",
  USER_INFO_AND_SETTINGS_PASSWORD_NOT_MATCHING: "Пароль не соответствует",
  USER_INFO_AND_SETTINGS_ALERT_LANGUAGE: "Вы уверены, что хотите изменить язык?",
  USER_INFO_AND_SETTINGS_ALERT_THEME: "Вы уверены, что хотите изменить тему?",
  USER_INFO_AND_SETTINGS_PASSWORD_VALIDATE:
    "Пароль должен содержать от 8 до 16 символов, включая как минимум одну заглавную латинскую букву, одну строчную латинскую букву и одну цифру.!",

  USER_INFO_THEME_LIGHTBLUE: "Светло-синий",
  USER_INFO_THEME_DARKBLUE: "Темно-синий",
  USER_INFO_THEME_DARKGREY: "Темно-серый",
  USER_INFO_THEME_LIGHTGREY: "Светло-серый",

  USER_INFO_RECEIVER_FCY_TITLE: "Получение уведомления о поступлениях в Ин. Валюте",

  USER_INFO_AND_SETTINGS_COMPANY_FORM_EDIT_BUTTON_TITLE: "Редактировать",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_SAVE_BUTTON_TITLE: "Сохранить",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Наименование компании",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_NAME_FIELD_LABEL_TITLE: "Имя",
  USER_INFO_INDIVIDUAL_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_PHONE_NUMBER_FIELD_LABEL_TITLE: "Телефон номер",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_MOBILE_NUMBER_FIELD_LABEL_TITLE: "Мобильный номер",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_CALLBACK_NUMBER_FIELD_LABEL_TITLE: "Номер обратного звонка",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_EMAIL_ADDRESS_FIELD_LABEL_TITLE: "Адрес электронной почты",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_COMPANY_ADDRESS_FIELD_LABEL_TITLE: "Адрес компании",
  USER_INFO_AND_SETTINGS_COMPANY_FORM_ACCOUNT_OPEN_DATE_FIELD_LABEL_TITLE: "Дата открытия счета",
  USER_INFO_AND_SETTINGS_SIGNATURE_NAME_TITLE: "Ф.И.О.",
  USER_INFO_AND_SETTINGS_SIGNATURE_CATEGORIES_TITLE: "Категория подписи",

  USER_INFO_AND_SETTINGS_ACCOUNT_SIGNATORIES_LABEL_TITLE: "Подписанты",

  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TITLE: "Роль пользователя",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_CREATOR_COLUMN_TITLE: "Исполнитель",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_ADMIN_COLUMN_TITLE: "Администратор",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_VIEW_ONLY_COLUMN_TITLE: "Только просмотр",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_1_COLUMN_TITLE: "Первый авторизатор",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_2_COLUMN_TITLE: "Второй авторизатор",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_3_COLUMN_TITLE: "Третий авторизатор",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_1_COLUMN_TITLE: "ID пользователя",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_USER_NAME_2_COLUMN_TITLE:
    "Право пользователя",
  USER_INFO_AND_SETTINGS_INTERNET_BANKING_USERS_TABLE_APPROVER_LIMIT_COLUMN_TITLE:
    "Лимит утверждающего",

  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CHANGE_BUTTON_TITLE: "Изменить",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_NEW_PASSWORD_FIELD_LABEL_TITLE: "Новый пароль",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CURRENT_PASSWORD_FIELD_LABEL_TITLE: "Текущий пароль",
  USER_INFO_AND_SETTINGS_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_FIELD_LABEL_TITLE:
    "Подтвердите новый пароль",

  //Agreement

  AGREEMENTS_TARIFF_SUBTITLE: "Тариф",
  AGREEMENTS_AGREEMENTS_TITLE: "Соглашения и формы",
  AGREEMENTS_CORPORATE_SIGNET_BUTTON: "Подписано",
  AGREEMENTS_BANK_SUBTITLE: "Договор банковского счета",
  AGREEMENTS_CORPORATE_VISA_SUBTITLE: "Корпоративная карта Visa",
  AGREEMENTS_CORPORATE_MAIL_SUBTITLE: "Договор на аренду ячейки",
  AGREEMENTS_INTERNET_SUBTITLE: "Договор на обслуживания интернет-банкинг",
  AGREEMENTS_CORPORATE_UZCARD_SUBTITLE: " Договор корпоративной карты UzCard",
  AGREEMENTS_CORPORATE_HUMO_SUBTITLE: " Договор корпоративной карты HUMO",

  AGREEMENTS_PUBLIC_OFFER_TAB_TITLE: "Публичая оферта",
  AGREEMENTS_TEMPLATES_AND_FORMS_TAB_TITLE: "Образцы и формы",
  AGREEMENTS_INSTRUCTIONS_TITLE: "Руководства",

  AGREEMENTS_TEMPLATES_AND_FORMS_DOCUMENT_FOR_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES:
    "Документы для на выдачи средств на командировочные расходы",
  AGREEMENTS_TEMPLATES_AND_FORMS_RETURN_DOCUMENTS: "Документы для возврата средств",

  AGREEMENTS_TEMPLATES_AND_FORMS_ON_ISSUING_FUNDS_FOR_TRAVEL_EXPENSES_IN_FOREIGN_CURRENCY:
    "О выдаче средств на командировочные расходы в иностранной валюте",

  //Reference
  REFERENCE_HEAD_TITLE: "Справочник",
  REFERENCE_TAB1: "Реквизиты транзитных счетов",
  REFERENCE_TAB2: "Реквизиты банков-корреспондентов",

  //Help & FAQ

  HELP_AND_FAQ_FAQ_TAB_TITLE: "FAQ",
  HELP_AND_FAQ_FAQ_QUESTIONS_TITLE: "Вопросы",
  HELP_AND_FAQ_FAQ_NO_QUESTIONS_TITLE: "Нет вопросов",
  HELP_AND_FAQ_SEND_BUTTON_TITLE: "Отправить",
  HELP_AND_FAQ_CONTENT_SUBTITLE: "Содержание",
  HELP_AND_FAQ_SALARY_ITEM_TITLE: "8. Заработная плата",
  HELP_AND_FAQ_CLIENT_ITEM_TITLE: "9. Клиент-партнер",
  HELP_AND_FAQ_CONVERSION_ITEM_TITLE: "7. Conversion",
  HELP_AND_FAQ_PAYMENT_ITEM_TITLE: "4. Платежные поручения",
  HELP_AND_FAQ_LABEL_QUESTION_TEXTAREA_TITLE: "Вопрос:",
  HELP_AND_FAQ_HELP_TITLE: "Помощь / Часто задаваемые вопросы",
  HELP_AND_FAQ_STATUSES_ITEM_TITLE: "5. Статусы платежных поручений. ",
  HELP_AND_FAQ_FEATURES_ITEM_TITLE: "1. Особенности интернет-банка ",
  HELP_AND_FAQ_FEATURES_SIX_ITEM_TITLE: "6. Возможности интернет-банка ",
  HELP_AND_FAQ_SOFTWARE_ITEM_TITLE: "2. Требования к программному и аппаратному обеспечению ",
  HELP_AND_FAQ_GETTING_ITEM_TITLE: "3. Начало работы: доступ, логин, пароли. ",

  //Loan
  LOAN_LIST_OF_CONTACTS_TITLE: "Список контрактов",
  LOAN_FILTER_FORM_TO_FIELD_LABEL_TITLE: "по",
  LOAN_TABLE_INTEREST_COLUMN_TITLE: "Процентная ставка",
  LOAN_TABLE_LOAN_CURRENCY_COLUMN_TITLE: "Валюта кредита",
  LOAN_TABLE_CONTACT_COLUMN_TITLE: "Срок действия контакта",
  LOAN_FILTER_FORM_DATE_FROM_FIELD_LABEL_TITLE: "с",
  LOAN_TABLE_CREDIT_AGREEMENT_COLUMN_TITLE: "Сумма кредитного договора",
  LOAN_TABLE_NUMBER_AND_DATE_COLUMN_TITLE: "Номер и дата кредитного договора",

  LOAN_ITEM_PENNY_FIELD_LABEL_TITLE: "Пеня",
  LOAN_ITEM_BALANCE_FIELD_LABEL_TITLE: "Баланс",
  LOAN_ITEM_CURRENCY_FIELD_LABEL_TITLE: "Валюта",
  LOAN_ITEM_CREDIT_TYPE_FIELD_LABEL_TITLE: "Тип кредита",
  LOAN_ITEM_PAYMENT_TYPE_FIELD_LABEL_TITLE: "Тип платежа",
  LOAN_ITEM_PAYMENT_DATE_FIELD_LABEL_TITLE: "Дата платежа",
  LOAN_ITEM_LOAN_REPAYMENT_TITLE: "График погашения",
  LOAN_ITEM_INTEREST_RATE_FIELD_LABEL_TITLE: "Процентная ставка",
  LOAN_ITEM_PAYMENT_AMOUNT_FIELD_LABEL_TITLE: "Сумма платежа (Основной долг)",
  LOAN_ITEM_CONTACT_AMOUNT_FIELD_LABEL_TITLE: "Сумма контракта",
  LOAN_ITEM_OVERDUE_INTEREST_FIELD_LABEL_TITLE: "Просроченный процент",
  LOAN_ITEM_INTEREST_PERIOD_FOR_FIELD_LABEL_TITLE: "Период процента по",
  LOAN_ITEM_SCHEDULER_DATE_FIELD_LABEL_TITLE: "Дата погашения по графику",
  LOAN_ITEM_BALANCE_OF_PRINCIPAL_FIELD_LABEL_TITLE: "Остаток оснавного долга",
  LOAN_ITEM_INTEREST_PERIOD_FROM_FIELD_LABEL_TITLE: "Период процента с",
  LOAN_ITEM_LOAN_AGREEMENT_TITLE: "Кредитный договор №",
  LOAN_ITEM_SCHEDULER_AMOUNT_FIELD_LABEL_TITLE: "Сумма погашения по графику",
  LOAN_ITEM_OVERDUE_PRINCIPAL_DEBT_FIELD_LABEL_TITLE: "Просроченный основной долг",
  LOAN_ITEM_AMOUNT_PAID_TITLE: "Выплаченные суммы по кредитному договору №",
  LOAN_ITEM_BALANCES_TITLE: "Остатки ссудных счетов",
  LOAN_ITEM_AGREEMENT_NUMBER_FIELD_LABEL_TITLE: "Номер кредитного договора",
  LOAN_ITEM_ACCOUNT_NUMBER_FIELD_LABEL_TITLE: "Номер ссудного счета",
  LOAN_ITEM_BANK_CODE_FIELD_LABEL_TITLE: "МФО",
  LOAN_ITEM_ACCOUNT_TYPE_FIELD_LABEL_TITLE: "Тип ссудного счета",

  //Notifications
  NOTIFICATIONS_NEWS_TAB_TITLE: "Новости",
  NOTIFICATIONS_NO_NOTIFICATIONS_TITLE: "Уведомление нет",
  NOTIFICATIONS_SEARCH_TITLE: "Поиск",
  NOTIFICATIONS_EVENT_TAB_TITLE: "Событие",
  NOTIFICATIONS_BUTTON_MENU_NEWS_TITLE: "Новости",
  NOTIFICATIONS_BUTTON_MENU_EMPTY_TITLE: "Пустой",
  NOTIFICATIONS_BUTTON_MENU_DIVED_TITLE: "Нырнул",
  NOTIFICATIONS_BUTTON_READ_MORE_TITLE: "Читать больше",
  NOTIFICATIONS_BUTTON_MENU_GALLERY_TITLE: "Галерея",
  NOTIFICATIONS_ANNOUNCEMENTS_TAB_TITLE: "Анонсы",
  NOTIFICATIONS_BUTTON_MENU_PRESS_CENTRE_TITLE: "Пресс-центр",
  NOTIFICATIONS_CALNEDAR_BUTTON_TITLE: "Календарь",
  NOTIFICATIONS_LOAN_AGREEMENT_TITLE: "Договор займа №2000 / 11-1 от 15.05.18 г.",
  NOTIFICATIONS_LOAN_AGREEMENT_SUBTITLE:
    "Настоящим заявлением подтверждаю, что ознакомился с условиями Публичной оферты на оказание услуги по конвертации иностранной валюты одного вида в иностранную валюту другого типа, согласен с ними и присоединяюсь к ним, претензий к Банку не будет. будущее и даю согласие на прямое списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка.",

  // Table
  TABLE_EMPTY_STATE_TITLE: "Пустой список",

  // Paginator
  PAGINATION_OF_TITLE: "из",

  // File Controls
  FILE_CONTROLS_PRINT_BUTTON_TITLE: "Распечатать",
  FILE_CONTROLS_GENERATE_BUTTON_TITLE: "Генерировать",
  FILE_CONTROLS_SAVE_TO_PDF_BUTTON_TITLE: "Сохранить в PDF",
  FILE_CONTROLS_SAVE_TO_PDF2_BUTTON_TITLE: "Сохранить в PDF с QR кодом",
  FILE_CONTROLS_SAVE_TO_TXT_BUTTON_TITLE: "Сохранить в TXT",
  FILE_CONTROLS_SAVE_TO_TXT_2_BUTTON_TITLE: "Сохранить в TXT_2",
  FILE_CONTROLS_SAVE_TO_TXT_3_BUTTON_TITLE: "Сохранить в TXT_3",
  FILE_CONTROLS_SAVE_TO_MT940_BUTTON_TITLE: "Сохранить в MT940",
  FILE_CONTROLS_SAVE_EXCEL_1C_BUTTON_TITLE: "Сохранить в Excel 1C",
  FILE_CONTROLS_SAVE_TEXT_1C_BUTTON_TITLE: "Сохранить в TXT 1C",
  FILE_CONTROLS_SAVE_AGROMIR_CREDIT_BUTTON_TITLE: "Агромир (кредит)",
  FILE_CONTROLS_SAVE_AGROMIR_DEBIT_BUTTON_TITLE: "Агромир (дебит)",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE_SV: "Сохранить в Excel (SV)",
  FILE_CONTROLS_SAVE_TO_EXCEL_BUTTON_TITLE: "Сохранить в Excel",
  FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY_BUTTON_TITLE: "Сохранить в Excel 2",
  FILE_CONTROLS_SAVE_TO_EXCEL_ANGLESEY2_BUTTON_TITLE: "Сохранить в Excel ANGLESEY2",
  FILE_CONTROLS_SAVE_TO_EXCEL_FUTTAIM_BUTTON_TITLE: "Сохранить в Excel FUTTAIM",
  FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_CREDIT_BUTTON_TITLE: "Сохранить в Excel Aгромир(Кредит)",
  FILE_CONTROLS_SAVE_TO_EXCEL_AGROMIR_DEBIT_BUTTON_TITLE: "Сохранить в Excel Aгромир(Дебет)",
  FILE_CONTROLS_SAVE_EXCEL_3KORZINKA_BUTTON_TITLE: "Сохранить в Excel 3",
  FILE_CONTROLS_SAVE_TO_TXT_ANSI_BUTTON_TITLE: "Сохранить в ANSI",
  FILE_CONTROLS_SAVE_TO_FAVORITE_BUTTON_TITLE: "Сохранить в избранное",
  FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE: "Список держателей карт",
  FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE: "Скачать шаблон Excel",
  FILE_CONTROLS_REGISTER_NEW_SALARY_CARD_BUTTON_TITLE: "Зарегистрировать новую зарплатную карту",
  FILE_CONTROLS_REGISTER_NEW_CORPORATE_CARD_BUTTON_TITLE:
    "Зарегистрировать новую корпоративную карту",
  FAVORITES_DELETE_BUTTON: "Вы действительно хотите удалить?",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_TITLE:
    "Создание платёжного поручения в иностранной валюте",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_TITLE: "Создание платёжного поручения в Узб. сумах",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_TITLE: "Пополнение корпоративной карты",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_TITLE: "Пополнение зарплатной карты",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_TITLE: "Создание платёжного требования",

  TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_FILE_NAME_TITLE: "Платеж в Ин. валюте",
  TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_FILE_NAME_TITLE: "Платеж в сумах",
  TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_FILE_NAME_TITLE:
    "Зарплату по корпоративной карте",
  TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_FILE_NAME_TITLE: "Зарплатную карту",
  TRANSFERS_SAMPLES_DOWNLOAD_PAYMENT_CLAIM_FILE_NAME_TITLE: "Заявление на оплату",

  TRANSFERS_MODAL_PAYMENT_CODE_TITLE: "Код платежа",
  TRANSFERS_MODAL_BUDGET_ACCOUNTS: "Бюджетные счета",
  FILE_CONTROLS_SAVE_TO_UPLOAD_FORM_EXCEL_BUTTON_TITLE: "Загрузить из Excel",
  FILE_CONTROLS_SAVE_TO_FAVORITE_FCY_TO_UZS_BUTTON_TITLE: "Избранное Ин. Валюты на сумы",
  QR_CODE_ERROR3015: "PDF файл с QR кодом данного документа на русском языке уже существует.",
  QR_CODE_ERROR3016: "Данный документ еще не исполнен.",
  QR_CODE_SELECT_LIMIT_ERROR: "Должен быть выбран только один документ",

  // Select Picker
  SELECT_PICKER_PLACEHOLDER_TITLE: "Все",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "Нет опций",
  SELECT_PICKER_PLACEHOLDER_YES_OR_NO_TITLE: "Да/Нет",
  SELECT_PICKER_PLACEHOLDER_CHOOSE_ONE_ONLY_TITLE: "Выбрат только один",

  //Contact Us
  CONTACT_US_EMAIL_TITLE: " Эл. почта ",
  CONTACT_US_FULL_NAME_TITLE: " Полное имя ",
  CONTACT_US_PHONE_NUMBER_TITLE: " Телефонный номер ",
  CONTACT_US_CLIENT_SERVICES_TITLE: " Клиентские службы ",
  CONTACT_US_CLIENT_RELATIONSHIP_TITLE: " Менеджеры по работе с клиентами ",

  //Main
  MAIN_ON_LENDING_AGREEMENT_UNIT_TITLE: "Валюта: доллар США",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_SUBTITLE: "Адрес",
  MAIN_ON_LENDING_AGREEMENT_BANK_CODE_SUBTITLE: "МФО",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_ONE_SUBTITLE: "До 1 года",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_TWO_SUBTITLE: "До 2 лет",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_THREE_SUBTITLE: "До 3 лет",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FOUR_SUBTITLE: "До 4 лет",
  MAIN_ON_LENDING_AGREEMENT_UP_TO_FIVE_SUBTITLE: "До 5 лет",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_SUBTITLE:
    "Дата следующего утверждения общего кредитного лимита",
  MAIN_ON_LENDING_AGREEMENT_NEXT_DATE_TITLE: "15.06.2021",
  MAIN_ON_LENDING_AGREEMENT_AUTHORIZED_SUBTITLE: "Уполномоченный представитель Банка-Заемщика",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_SUBTITLE: "Банк-заемщик",
  MAIN_ON_LENDING_AGREEMENT_INTERMEDIARY_TITLE: "ABC Bank",
  MAIN_ON_LENDING_AGREEMENT_GENERAL_SUBTITLE: "Общий лимит On-Lending кредитования",
  MAIN_ON_LENDING_AGREEMENT_LATEST_DATE_SUBTITLE:
    "Дата последнего утверждения общего кредитного лимита",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_NUMBER_SUBTITLE: "Номер соглашения",
  MAIN_ON_LENDING_AGREEMENT_AGREEMENT_DATE_SUBTITLE: "Дата подписания соглашения",
  MAIN_ON_LENDING_AGREEMENT_INTEREST_SUBTITLE: "Процентная ставка по Суб-кредиту:",
  MAIN_ON_LENDING_AGREEMENT_MAXIMUM_SUBTITLE: "Максимальная сумма суб-кредита",
  MAIN_ON_LENDING_AGREEMENT_MINIMUM_SUBTITLE: "Максимальная сумма суб-кредита",
  MAIN_ON_LENDING_AGREEMENT_TAX_IDENTIFICATION_SUBTITLE:
    "Идентификационный номер налогоплательщика",
  MAIN_ON_LENDING_AGREEMENT_INFORMATION_TITLE:
    "Информация о Генеральном соглашении On-Lending кредитования",
  MAIN_ON_LENDING_AGREEMENT_ADDRESS_TITLE: "#3, Google Street, Mirabad District, Tashkent City",

  MAIN_PENDING_APPLICATION_UNIT_TITLE: "Валюта: доллар США",
  MAIN_PENDING_APPLICATION_TABLE_AMOUNT_COLUMN_TITLE: "Сумма суб-кредита",
  MAIN_PENDING_APPLICATION_TABLE_STATUS_COLUMN_TITLE: "Статус",
  MAIN_PENDING_APPLICATION_TABLE_DATE_COLUMN_TITLE: "Дата заявки",
  MAIN_PENDING_APPLICATION_TABLE_NAME_COLUMN_TITLE: "Наименование суб-заемщика",
  MAIN_PENDING_APPLICATION_TABLE_NUMBER_COLUMN_TITLE: "Номер заявки",
  MAIN_PENDING_APPLICATION_PENDING_APPLICATION_TITLE:
    "Заявки на получение суб-кредита в процессе рассмотрения",
  MAIN_PENDING_APPLICATION_TABLE_UPDATING_COLUMN_TITLE: "Обновить",
  MAIN_PENDING_APPLICATION_PRE_APPROVAL_TITLE: "Предварительное утверждение нового Суб-кредита",
  MAIN_PENDING_APPLICATION_TABLE_REGISTRATION_COLUMN_TITLE: "Запрос на регистрацию",
  MAIN_PENDING_APPLICATION_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Запрос на обновление",
  MAIN_PENDING_APPLICATION_REQUEST_BUTTON_TITLE:
    "Запрос на предварительное одобрение заявки на новый кредит или аккредитив",

  FINANCIAL_REFUSED_DEALS_UNIT_TITLE: "Валюта: доллар США",
  FINANCIAL_REFUSED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  FINANCIAL_REFUSED_DEALS_APPROVED_DEALS_TITLE: "Одобренные заявки на получение Суб-кредита",
  FINANCIAL_REFUSED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Статус",
  FINANCIAL_REFUSED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Имя суб-заемщика",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: " Дата заявки ",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: " Номер заявки ",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Запрос на обновление",
  FINANCIAL_REFUSED_DEALS_TABLE_REFUSED_OR_CANCELLED_COLUMN_TITLE:
    "Отклоненные или отмененные сделки",
  FINANCIAL_REFUSED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE: "Запрос на регистрацию",

  MAIN_LOAN_REPAYMENT_DETAILS_UNIT_TITLE: " Валюта: доллар США ",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_AMOUNT_COLUMN_TITLE: "Сумма",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Номер запроса",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_BORROWER_NUMBER_COLUMN_TITLE: " Имя заемщика",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_REQUEST_DATE_TOTAL_COLUMN_TITLE: "Дата запроса",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_DATE_COLUMN_TITLE: "Дата запроса",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_BORROWER_NAME_COLUMN_TITLE: "Имя заемщика",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_OVERDUE_REQUEST_NUMBER_COLUMN_TITLE: "Номер запроса",
  MAIN_LOAN_REPAYMENT_DETAILS_TABLE_UPCOMING_LOAN_UPCOMING_COLUMN_TITLE: "Ближайшая дата погашения",
  MAIN_LOAN_REPAYMENT_DETAILS_DETAILED_INFORMATION_BY_INDIVIDUAL_DEALS_TITLE:
    "Подробная информация по индивидуальным сделкам",
  MAIN_LOAN_REPAYMENT_DETAILS_UPCOMING_LOAN_TITLE:
    "Предстоящий возврат кредита, просроченные платежи и комиссии",

  MAIN_DOCUMENT_SUBMISSION_TABLE_DATE_COLUMN_TITLE: "Дата",
  MAIN_DOCUMENT_SUBMISSION_REFUSED_TAB_TITLE: "Отвергнутые заявки",
  MAIN_DOCUMENT_SUBMISSION_APPROVED_TAB_TITLE: "одобренные заявки на получение Суб-кредита",
  MAIN_DOCUMENT_SUBMISSION_SUBMITTED_TITLE: "Представленные документы",
  MAIN_DOCUMENT_SUBMISSION_LOAN_TAB_TITLE: "Детали погашения кредита",
  MAIN_DOCUMENT_SUBMISSION_LENDING_TAB_TITLE: "Договор On-Lending",
  MAIN_DOCUMENT_SUBMISSION_DOCUMENT_TAB_TITLE: " Представление документов",
  MAIN_DOCUMENT_SUBMISSION_PENDING_TAB_TITLE: "Ожидающие заявки",
  MAIN_DOCUMENT_SUBMISSION_TABLE_DOCUMENT_NAME_COLUMN_TITLE: "Название документа",
  MAIN_DOCUMENT_SUBMISSION_SUBMISSION_BUTTON_TITLE: "Представление документа",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_TITLE:
    "Представление документов в КДБ Банк Узбекистан",
  MAIN_DOCUMENT_SUBMISSION_OF_DOCUMENTS_TO_KDB_BANK_UZBEKISTAN_BUTTON_TITLE:
    "Представление документов в КДБ Банк Узбекистан",

  // Withdrawal of Loan Funds page  " Individual deal TAB"

  WITHDRAWAL_TABLE_DATE_COLUMN_TITLE: "Дата",
  WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Статус",
  WITHDRAWAL_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  WITHDRAWAL_BORROWER_FIELD_LABEL_TITLE: "Заемщик",
  WITHDRAWAL_PRODUCTS_FIELD_LABEL_TITLE: "Продукты",
  WITHDRAWAL_TABLE_AMOUNT_COLUMN_TITLE: "Сумма (доллар США)",
  WITHDRAWAL_TABLE_PAYMENT_COLUMN_TITLE: "Дата платежа",
  WITHDRAWAL_SIZE_FIELD_LABEL_TITLE: "Размер бизнеса",
  WITHDRAWAL_DEAL_NUMBER_FIELD_LABEL_TITLE: "Номер сделки",
  WITHDRAWAL_INTEREST_FIELD_LABEL_TITLE: "Процентная ставка",
  WITHDRAWAL_INDIVIDUAL_DEAL_TAB_TITLE: "Индивидуальная сделка",
  WITHDRAWAL_APPROVED_FIELD_LABEL_TITLE: "Утвержденная сумма",
  WITHDRAWAL_EMPLOYEE_FIELD_LABEL_TITLE: "Количество сотрудников",
  WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Дата создания",
  WITHDRAWAL_BUSINESS_FIELD_LABEL_TITLE: "Деловая активность",
  WITHDRAWAL_WITHDRAWN_FIELD_LABEL_TITLE: "Сумма снятия",
  WITHDRAWAL_TAX_FIELD_LABEL_TITLE: "Идентификационный номер налогоплательщика",
  WITHDRAWAL_OUTSTANDING_FIELD_LABEL_TITLE: "Непогашенная сумма",
  WITHDRAWAL_TABLE_OVERDUE_COMMISSION_COLUMN_TITLE: "Комиссия",
  WITHDRAWAL_ON_LENDING_AGREEMENT_TAB_TITLE: "Договор On-Lending",
  WITHDRAWAL_MANAGEMENT_FIELD_LABEL_TITLE: "Управление заемщика",
  WITHDRAWAL_DEAL_REQUEST_NUMBER_FIELD_LABEL_TITLE: "Номер Запроса",
  WITHDRAWAL_TABLE_OVERDUE_PAYMENT_AMOUNT_COLUMN_TITLE: "Сумма к оплате",
  WITHDRAWAL_LARGEST_FIELD_LABEL_TITLE: "Крупнейшие акционеры (более 10%)",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_TITLE: "Информация об индивидуальной сделке",
  WITHDRAWAL_TABLE_NUMBER_OF_PAYMENT_COLUMN_TITLE: "Номер платежного запроса",
  WITHDRAWAL_LOAN_TITLE: "История погашения кредита, просроченных платежей и комиссионных выплат",
  WITHDRAWAL_INFORMATION_ON_CORPORATE_BORROWER_TITLE: "Информация о корпоративном заемщике",
  WITHDRAWAL_INFORMATION_ON_INDIVIDUAL_DEAL_TITLE: "Информация об индивидуальной сделке",

  // Individual transfers page
  TRANSFERS_TRANSFERS_HISTORY_TAB_TITLE: "История",
  TRANSFERS_TRANSFERS_FAVORITES_TAB_TITLE: "Избранное",
  TRANSFERS_TRANSFERS_TO_ANOTHER_CLIENT_TAB_TITLE: "Переводы клиентам другого банка",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_TAB_TITLE: "Переводы между моими счетами в KDB UZB",
  TRANSFERS_TRANSFERS_TO_OTHER_KDB_CLIENTS_TAB_TITLE: "Переводы на счета других клиентов KDB UZB",

  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CARD_TITLE: "на карту",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_SEND_BUTTON_TITLE: "Отправить",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_FIELD_LABEL_TITLE: "Получатель",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_BUDGET_TITLE: "в бюджет",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_FROM_FIELD_LABEL_TITLE: "Отправитель",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_TO_CURRENT_TITLE: "на счет",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_AMOUNT_FIELD_LABEL_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_BANK_CODE_FIELD_LABEL_TITLE: "Код банка",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_RECEIVER_NAME_FIELD_LABEL_TITLE:
    "Наименование получателя",
  TRANSFERS_TRANSFERS_BETWEEN_MY_ACCOUNTS_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE: "Детали платежа",

  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TITLE: "Подтверждение",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_CANCEL_BUTTON_TITLE: "Отменить",
  TRANSFERS_TRANSFERS_CONFIRMATION_MODAL_TRANSFER_BUTTON_TITLE: "Отправить",

  TRANSFERS_TRANSFERS_SUCCESS_MODAL_TITLE: "Успешно",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_EXIT_BUTTON_TITLE: "Выход",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_PDF_BUTTON_TITLE: "Сохранить в PDF",
  TRANSFERS_TRANSFERS_SUCCESS_MODAL_SAVE_TO_FAVORITE_BUTTON_TITLE: "Сохранить в избранное",

  TRANSFERS_TRANSFERS_MODAL_AMOUNT_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_MODAL_SENDER_NAME_TITLE: "Отправитель",
  TRANSFERS_TRANSFERS_MODAL_COMMISSION_TITLE: "Комиссия",
  TRANSFERS_TRANSFERS_MODAL_PAYMENT_DATE_TITLE: "Дата платежа",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_NAME_TITLE: "Имя получателя",
  TRANSFERS_TRANSFERS_MODAL_SENDER_ACCOUNT_TITLE: "Счет отправителя",
  TRANSFERS_TRANSFERS_MODAL_RECEIVER_ACCOUNT_TITLE: "Счет получателя",

  // Withdrawal of Loan Funds page  "Withdrawn Loan Funds"

  WITHDRAWAL_WITHDRAWN_50_HEADER_ITEM_TITLE: "50",
  WITHDRAWAL_WITHDRAWN_52_HEADER_ITEM_TITLE: "52",
  WITHDRAWAL_WITHDRAWN_56_HEADER_ITEM_TITLE: "56",
  WITHDRAWAL_WITHDRAWN_57_HEADER_ITEM_TITLE: "57",
  WITHDRAWAL_WITHDRAWN_59_HEADER_ITEM_TITLE: "59",
  WITHDRAWAL_WITHDRAWN_70_HEADER_ITEM_TITLE: "70",
  WITHDRAWAL_WITHDRAWN_71_HEADER_ITEM_TITLE: "71",
  WITHDRAWAL_WITHDRAWN_72_HEADER_ITEM_TITLE: "72",
  WITHDRAWAL_WITHDRAWN_32A_HEADER_ITEM_TITLE: "32a",
  WITHDRAWAL_WITHDRAWN_DATE_FIELD_LABEL_TITLE: "Дата",
  WITHDRAWAL_WITHDRAWN_TAX_ID_ITEM_TITLE: "ИНН",
  WITHDRAWAL_WITHDRAWN_NEW_BUTTON_TITLE: "Новый вывод",
  WITHDRAWAL_WITHDRAWN_TABLE_STATUS_COLUMN_TITLE: "Статус",
  WITHDRAWAL_WITHDRAWN_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  WITHDRAWAL_WITHDRAWN_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  WITHDRAWAL_WITHDRAWN_BORROWER_FIELD_LABEL_TITLE: "Заемщик",
  WITHDRAWAL_WITHDRAWN_PRODUCTS_FIELD_LABEL_TITLE: "Продукты",
  WITHDRAWAL_WITHDRAWN_CURRENCY_ITEM_TITLE: "Валюта",
  WITHDRAWAL_WITHDRAWN_ADDRESS_UP_ITEM_TITLE: "Адрес",
  WITHDRAWAL_WITHDRAWN_ADDRESS_DOWN_ITEM_TITLE: "Адрес",
  WITHDRAWAL_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Процентная ставка",
  WITHDRAWAL_WITHDRAWN_REFUSED_TITLE: "Иноформация о выведенных заемных средств",
  WITHDRAWAL_WITHDRAWN_DEAL_NUMBER_FIELD_LABEL_TITLE: "Номер сделки",
  WITHDRAWAL_WITHDRAWN_APPROVED_FIELD_LABEL_TITLE: "Утвержденная сумма",
  WITHDRAWAL_WITHDRAWN_EMPLOYEE_FIELD_LABEL_TITLE: "Количество сотрудников",
  WITHDRAWAL_WITHDRAWN_DATE_TITLE: "Дата         XX/XX/XXXX ",
  WITHDRAWAL_WITHDRAWN_BUSINESS_FIELD_LABEL_TITLE: "Деловая активность",
  WITHDRAWAL_WITHDRAWN_WITHDRAWN_FIELD_LABEL_TITLE: "Сумма снятия",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_DATE_COLUMN_TITLE: "Дата платежа",
  WITHDRAWAL_WITHDRAWN_REQUEST_NUMBER_FIELD_LABEL_TITLE: "Номер запроса",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_ITEM_TITLE: "Получатель",
  WITHDRAWAL_WITHDRAWN_OUTSTANDING_FIELD_LABEL_TITLE: "Непогашенная сумма",
  WITHDRAWAL_PAGE_WITHDRAWN_INTEREST_FIELD_LABEL_TITLE: "Процентная ставка",
  WITHDRAWAL_WITHDRAWN_DEBIT_ITEM_TITLE: "Дебетовать счет",
  WITHDRAWAL_WITHDRAWN_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "Сумма платежа",
  WITHDRAWAL_WITHDRAWN_VALUE_ITEM_TITLE: "Дата валютирования",
  WITHDRAWAL_WITHDRAWN_MANAGEMENT_FIELD_LABEL_TITLE: "Управление заемщика",
  WITHDRAWAL_WITHDRAWN_SIZE_OF_BUSINESS_FIELD_LABEL_TITLE: "Размер бизнеса",
  WITHDRAWAL_WITHDRAWN_AMOUNT_DIGIT_ITEM_TITLE: "Сумма цифрами",
  WITHDRAWAL_WITHDRAWN_ORDERING_BANK_ITEM_TITLE: "Банк плательщика",
  WITHDRAWAL_WITHDRAWN_LARGEST_FIELD_LABEL_TITLE: "Крупнейшие акционеры (более 10%)",
  WITHDRAWAL_WITHDRAWN_DATE_ESTABLISHMENT_FIELD_LABEL_TITLE: "Дата основания",
  WITHDRAWAL_WITHDRAWN_INFORMATION_INDIVIDUAL_TITLE: "Информация об индивидуальной сделке",
  WITHDRAWAL_WITHDRAWN_ORDERING_CUSTOMER_ITEM_TITLE: "Плательщик",
  WITHDRAWAL_WITHDRAWN_TABLE_NUMBER_REQUEST_COLUMN_TITLE: "Номер платежного запроса",
  WITHDRAWAL_WITHDRAWN_CREDIT_ACCOUNT_ITEM_TITLE: "Кредитовать счет",
  WITHDRAWAL_WITHDRAWN_PAYMENT_TITLE: "Платежное поручение № XXX",
  WITHDRAWAL_WITHDRAWN_INFORMATION_CORPORATE_TITLE: "Информация о корпоративном заемщике",
  WITHDRAWAL_WITHDRAWN_TAX_IDENTIFICATION_FIELD_LABEL_TITLE:
    "Идентификационный номер налогоплательщика",
  WITHDRAWAL_WITHDRAWN_BENEFICIARY_BANK_ITEM_TITLE: "Банк получателя",
  WITHDRAWAL_WITHDRAWN_INTERMEDIARY_BANK_ITEM_TITLE: "Банк посредник",
  WITHDRAWAL_WITHDRAWN_IBAN_ITEM_TITLE: "(IBAN для платежей в страны ЕС)",
  WITHDRAWAL_WITHDRAWN_AMOUNT_CURRENCY_ITEM_TITLE: "Сумма и валюта прописью",
  WITHDRAWAL_WITHDRAWN_DETAILS_PAYMENT_ITEM_TITLE: "Детали платежа (цель и назначение)",
  WITHDRAWAL_WITHDRAWN_DETAILS_CHARGES_ITEM_TITLE: "Оплата услуг банков корреспондентов:",
  WITHDRAWAL_WITHDRAWN_ADDITIONAL_INFORMATION_ITEM_TITLE: "Дополнительная информация",
  WITHDRAWAL_WITHDRAWN_CREATE_BUTTON_TITLE: "Создать",
  WITHDRAWAL_WITHDRAWN_WITHDRAWAL_LOAN_TITLE:
    "Cнятие заемных средств / информация о снятых заемных средствах",

  WITHDRAWN_REQUEST_DRAWDOWN_FORM_TITLE: "ЗАЯВКА НА ВЫБОРКУ ПРЕДАТОЧНОГО КРЕДИТА",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_DATE_TITLE: "Дата",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_OF_WITHDRAWN_TITLE: "Форма выборки предаточного кредита",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUBMIT_BUTTON_TITLE: "Отправить",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_AMOUNT_TITLE: "Сумма",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_NUMBER_TITLE: "Номер",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_ADDRESS_TITLE: "Адрес",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_PAYMENT_DETAILS_TITLE: "Детали платежа",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_VALUES_DATE_FIELD_TITLE: "Дата валютирования",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_NAME_TITLE: "Наименование",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_DATE_FIELD_TITLE: "Дата запроса:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_GROUP_TITLE: "Субзаемщик",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_TAX_ID_TITLE: "ИНН",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AMOUNT_TITLE: "Сумма суб-кредита",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_REQUEST_NUMBER_FIELD_TITLE: "Номер запроса:",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_CONTACT_GROUP_TITLE: "Импортный контракт ",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_FIELD_TITLE: "Сумма выборки",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_REQUEST_GROUP_TITLE: "Запрос на выборку",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_NAME_FIELD_TITLE: "Наименование",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_BANK_GROUP_TITLE: "Банк-бенефициар",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_IMPORT_BENEFICIARY_NAME_TITLE: "Наименование бенефициара",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_CURRENCY_FIELD_TITLE: "Валюта выборки",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_BANK_GROUP_TITLE:
    "Банк корреспондент Банка посредника",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_NAME_FIELD_TITLE: "Наименование",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_ADDRESS_FIELD_TITLE: "Адрес",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_ADDRESS_FIELD_TITLE: "Адрес",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_BENEFICIARY_SWIFT_CODE_FIELD_TITLE: "SWIFT код",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_SUB_BORROWER_AGREEMENT_NUMBER_TITLE: "Номер договора Суб-кредита",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_DRAWDOWN_AMOUNT_IN_WORDS_FIELD_TITLE: "Сумма прописью",
  WITHDRAWN_REQUEST_DRAWDOWN_FORM_CORRESPONDENT_SWIFT_CODE_FIELD_TITLE: "SWIFT код",

  //Withdrawal of Loan Funds page "New Withdrawal Tab"

  WITHDRAWAL_NEW_WITHDRAWAL_50_ITEM_TITLE: "50",
  WITHDRAWAL_NEW_WITHDRAWAL_52_ITEM_TITLE: "52",
  WITHDRAWAL_NEW_WITHDRAWAL_56_ITEM_TITLE: "56",
  WITHDRAWAL_NEW_WITHDRAWAL_57_ITEM_TITLE: "57",
  WITHDRAWAL_NEW_WITHDRAWAL_59_ITEM_TITLE: "59",
  WITHDRAWAL_NEW_WITHDRAWAL_70_ITEM_TITLE: "70",
  WITHDRAWAL_NEW_WITHDRAWAL_71_ITEM_TITLE: "71",
  WITHDRAWAL_NEW_WITHDRAWAL_72_ITEM_TITLE: "72",
  WITHDRAWAL_NEW_WITHDRAWAL_32A_ITEM_TITLE: "32а",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_AND_CURRENCY_ITEM_TITLE: "Сумма и валюта прописью",
  WITHDRAWAL_NEW_WITHDRAWAL_TAX_ID_ITEM_TITLE: "ИНН",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_STATUS_COLUMN_TITLE: "Статус",
  WITHDRAWAL_NEW_WITHDRAWAL_NEW_BUTTON_TITLE: "Новый вывод",
  WITHDRAWAL_NEW_WITHDRAWAL_IBAN_ITEM_TITLE: "(IBAN для платежей в страны ЕС) ",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_PAYMENT_ITEM_TITLE: "Детали платежа (цель и назначение)",
  WITHDRAWAL_NEW_WITHDRAWAL_DETAILS_OF_CHANGES_ITEM_TITLE: "Оплата услуг банков корреспондентов: ",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDITIONAL_INFORMATION_ITEM_TITLE: "Дополнительная информация",
  WITHDRAWAL_NEW_WITHDRAWAL_CURRENCY_ITEM_TITLE: "Валюта ",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_UP_ITEM_TITLE: "Адрес",
  WITHDRAWAL_NEW_WITHDRAWAL_ADDRESS_DOWN_ITEM_TITLE: "Адрес",
  WITHDRAWAL_NEW_WITHDRAWAL_REFUSED_TITLE: "Отклоненные или отмененные сделки",
  WITHDRAWAL_NEW_WITHDRAWAL_DATE_FIELD_LABEL_TITLE: "Дата",
  WITHDRAWAL_NEW_WITHDRAWAL_DATA_TITLE: "Дата         XX/XX/XXXX ",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_DATE_COLUMN_TITLE: "Дата платежа",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_PAYMENT_AMOUNT_COLUMN_TITLE: "Сумма платежа",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_ITEM_TITLE: "Получатель ",
  WITHDRAWAL_NEW_WITHDRAWAL_VALUE_ITEM_TITLE: "Дата валютирования",
  WITHDRAWAL_NEW_WITHDRAWAL_TABLE_NUMBER_COLUMN_TITLE: "Номер платежного запроса",
  WITHDRAWAL_NEW_WITHDRAWAL_DEBIT_ACCOUNT_ITEM_TITLE: "Дебетовать счет",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_BANK_ITEM_TITLE: "Банк плательщика",
  WITHDRAWAL_NEW_WITHDRAWAL_ORDERING_CUSTOMER_ITEM_TITLE: "Плательщик",
  WITHDRAWAL_NEW_WITHDRAWAL_AMOUNT_IN_DIGIT_ITEM_TITLE: "Сумма цифрами",
  WITHDRAWAL_NEW_WITHDRAWAL_PAYMENT_TITLE: "Платежное поручение № XXX",
  WITHDRAWAL_NEW_WITHDRAWAL_INTERMEDIARY_BANK_ITEM_TITLE: "Банк посредник",
  WITHDRAWAL_NEW_WITHDRAWAL_BENEFICIARY_BANK_ITEM_TITLE: "Банк получателя ",
  WITHDRAWAL_NEW_WITHDRAWAL_CREDIT_ACCOUNT_ITEM_TITLE: " Кредитовать счет\n",

  //Withdrawal of Loan Funds page "Request for reg.loan in NIKI tab"

  WITHDRAWAL_REQUEST_FOR_TABLE_DATE_COLUMN_TITLE: "Свидание",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_BUTTON_TITLE: "Прилагается",
  WITHDRAWAL_REQUEST_FOR_GRACE_FIELD_LABEL_TITLE: "Льготный период (в месяцах)",
  WITHDRAWAL_REQUEST_FOR_TABLE_AMOUNT_COLUMN_TITLE: "Сумма (в долларах США)",
  WITHDRAWAL_REQUEST_FOR_INTEREST_FIELD_LABEL_TITLE: "Процентная ставка (ежегодная)",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DATE_FIELD_LABEL_TITLE: "Дата",
  WITHDRAWAL_REQUEST_FOR_REQUEST_TERM_FIELD_LABEL_TITLE: "Запрашиваемый период (в месяцах)",
  WITHDRAWAL_REQUEST_FOR_PURPOSE_FIELD_LABEL_TITLE: " Цель финансирования ",
  WITHDRAWAL_REQUEST_FOR_REQUEST_NUMBER_FIELD_LABEL_TITLE: " Номер запроса ",
  WITHDRAWAL_REQUEST_FOR_REQUEST_AMOUNT_FIELD_LABEL_TITLE: " Запрошенная сумма ",
  WITHDRAWAL_REQUEST_FOR_ATTACHED_DOCUMENTS_LABEL_TITLE: " Приложенные документы ",
  WITHDRAWAL_REQUEST_FOR_MANAGER_FIELD_LABEL_TITLE: " Менеджер банка-посредника ",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_DETAILS_TITLE: " Детали индивидуальной заявки ",
  WITHDRAWAL_REQUEST_FOR_REQUEST_BUTTON_TITLE: " Запрос на регистрацию заявки ",
  WITHDRAWAL_REQUEST_FOR_REGISTRATION_PARA_3_TITLE:
    "Запрос на регистрацию индивидуальной заявки на кредит или аккредитив в системе ники ЦБ ",
  WITHDRAWAL_REQUEST_FOR_KDB_BANK_SUBTITLE_TITLE:
    " Председателю КДБ Банк Узбекистан," +
    " Настоящим просим вас разрешить (банку-посреднику) использовать кредитные средства для последующего кредитования в соответствии с (Генеральным соглашением о кредитовании от 10.05.2020) для финансирования следующей индивидуальной заявки.",

  // Individual role Loan "Loan Tab"

  LOAN_TO_FIELD_LABEL_TITLE: "по",
  LOAN_DATE_FIELD_LABEL_TITLE: "Дата",
  LOAN_TABLE_LOAN_COLUMN_TITLE: "Валюта кредита",
  LOAN_TABLE_INTEREST_RATE_COLUMN_TITLE: "Процентная ставка",
  LOAN_TABLE_CREDIT_COLUMN_TITLE: "Сумма кредитного договора",
  LOAN_TABLE_NUMBER_COLUMN_TITLE: "Номер и дата кредитного договора",
  LOAN_TABLE_CONTACT_EXPIRATION_COLUMN_TITLE: "Дата истечения срока действия контакта",

  // Individual role Conversion "History Tab"

  CONVERSION_HISTORY_TAB_TITLE: "История",
  CONVERSION_CONVERSION_TAB_TITLE: "Конвертация",
  CONVERSION_EXCHANGE_RATE_TAB_TITLE: "Курс обмена",

  CONVERSION_HISTORY_TO_FIELD_LABEL_TITLE: "по",
  CONVERSION_HISTORY_DATE_FROM_FIELD_LABEL_TITLE: "с",

  CONVERSION_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  CONVERSION_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Регистр",
  CONVERSION_HISTORY_TABLE_STATUS_CODE_COLUMN_TITLE: "Статус",
  CONVERSION_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Тип карты",
  CONVERSION_HISTORY_TABLE_DETAILS_CODE_COLUMN_TITLE: "Детали",
  CONVERSION_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнтель",
  CONVERSION_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  CONVERSION_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Код платежа",
  CONVERSION_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Регистр №",
  CONVERSION_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Дата",
  CONVERSION_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Транзитный счет",

  // Individual role Conversion "Conversion Tab"

  CONVERSION_CONVERSION_TO_FIELD_LABEL_TITLE: "на",
  CONVERSION_CONVERSION_SUBMIT_BUTTON_TITLE: "Отправить",
  CONVERSION_CONVERSION_FROM_FIELD_LABEL_TITLE: "с",
  CONVERSION_CONVERSION_AMOUNT_LEFT_FIELD_LABEL_TITLE: "Сумма",
  CONVERSION_CONVERSION_AMOUNT_RIGHT_FIELD_LABEL_TITLE: "Сумма",
  CONVERSION_CONVERSION_EXCHANGE_FIELD_LABEL_TITLE: "Курс обмена",

  // Individual role Conversion "Exchange rate"

  CONVERSION_EXCHANGE_CBU_TITLE: "Курс ЦБ",
  CONVERSION_EXCHANGE_TABLE_BUY_COLUMN_TITLE: "Покупка",
  CONVERSION_EXCHANGE_TABLE_SELL_COLUMN_TITLE: "Продажа",
  CONVERSION_EXCHANGE_KDB_TITLE: "Курс обмена валют КДБ Банк Узбекистан",
  CONVERSION_EXCHANGE_UZRCE_RATES_TITLE: "Курс УзРВБ",
  CONVERSION_EXCHANGE_BANK_RATE_TITLE: "Конвертационный курс валют КДБ Банк Узбекистан",

  // Individual role  Pre-Approval of New Loan & L/C Application

  PRE_APPROVAL_DEFAULT_TAB_TITLE: "По умолчанию",
  PRE_APPROVAL_CORPORATE_TAB_TITLE: "Корпоративный заемщик",
  PRE_APPROVAL_INDIVIDUAL_TAB_TITLE: "Индивидуальное приложение",
  PRE_APPROVAL_COMMENT_TAB_TITLE: "Комментарий КДБ Банк Узбекистан",

  PRE_APPROVAL_DATE_FIELD_LABEL_TITLE: "Дата создания",
  PRE_APPROVAL_REQUEST_FIELD_LABEL_TITLE: "Номер запроса",
  PRE_APPROVAL_MANAGER_FIELD_LABEL_TITLE: "Код банка",
  PRE_APPROVAL_SCREEN_TITLE: "Предварительное одобрение заявки на новый кредит или аккредитив ",
  PRE_APPROVAL_KDB_SUBTITLE:
    "Председателю КДБ Банк Узбекистан," +
    "Настоящим просим вас разрешить (банку-посреднику) использовать кредитные средства, предоставленные в соответствии с (Генеральным соглашением о кредитовании от 10.05.2020), для финансирования следующей индивидуальной заявки.",

  PRE_APPROVAL_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  PRE_APPROVAL_PRODUCTS_FIELD_LABEL_TITLE: "Продукты",
  PRE_APPROVAL_BORROWER_FIELD_LABEL_TITLE: "Суб-Заемщик",
  PRE_APPROVAL_SIZE_FIELD_LABEL_TITLE: "Размер бизнеса",
  PRE_APPROVAL_EMPLOYEE_FIELD_LABEL_TITLE: "Количество сотрудников",
  PRE_APPROVAL_BUSINESS_FIELD_LABEL_TITLE: "Сфера деятельности",
  PRE_APPROVAL_TAX_FIELD_LABEL_TITLE: "Идентификационный номер налогоплательщика",
  PRE_APPROVAL_DATE_OF_FIELD_LABEL_TITLE: "Дата создания",
  PRE_APPROVAL_MANAGEMENT_FIELD_LABEL_TITLE: "Управление заемщика",
  PRE_APPROVAL_INFO_CORPORATION_TITLE: "Информация о корпоративном заемщике",
  PRE_APPROVAL_LARGEST_FIELD_LABEL_TITLE: "Крупнейшие акционеры (более 10%)",

  PRE_APPROVAL_NET_PRODUCTS_FIELD_LABEL_TITLE: "Продукты",
  PRE_APPROVAL_TOTAL_BORROWER_FIELD_LABEL_TITLE: "Заемщик",
  PRE_APPROVAL_TOTAL_ADDRESS_FIELD_LABEL_TITLE: "Адрес",
  PRE_APPROVAL_PAGE_SIZE_FIELD_LABEL_TITLE: "Размер бизнеса",
  PRE_APPROVAL_BANK_TAX_FIELD_LABEL_TITLE: "Идентификационный номер налогоплательщика",
  PRE_APPROVAL_NET_DATE_OF_OF_FIELD_LABEL_TITLE: "Дата создания",
  PRE_APPROVAL_EXPORT_EMPLOYEE_NUMBER_FIELD_LABEL_TITLE: "Количество сотрудников",
  PRE_APPROVAL_TOTAL_MANAGEMENT_FIELD_LABEL_TITLE: "Управление заемщика",
  PRE_APPROVAL_NET_BUSINESS_ACTIVITY_FIELD_LABEL_TITLE: "Деловая активность",
  PRE_APPROVAL_FINANCIALS_TITLE: "Финансовые показатели в ( ",
  PRE_APPROVAL_FINANCIALS_TITLE_SECOND: "г.); Единица: тыс. Сум",
  PRE_APPROVAL_EXPORT_LARGEST_FIELD_LABEL_TITLE: "Крупнейшие акционеры (более 10%)",

  PRE_APPROVAL_FINANCIAL_NET_SALES_FIELD_LABEL_TITLE: "Чистые продажи",
  PRE_APPROVAL_FINANCIAL_BANK_LOANS_FIELD_LABEL_TITLE: "(включая банковские ссуды и лизинг)",
  PRE_APPROVAL_FINANCIAL_NET_INCOME_FIELD_LABEL_TITLE: "Чистая прибыль",
  PRE_APPROVAL_FINANCIAL_TOTAL_ASSETS_FIELD_LABEL_TITLE: "Всего активов",
  PRE_APPROVAL_FINANCIAL_TOTAL_CAPITAL_FIELD_LABEL_TITLE: "Общий капитал",
  PRE_APPROVAL_FINANCIAL_EXPORT_REVENUE_FIELD_LABEL_TITLE: "Выручка от экспорта",
  PRE_APPROVAL_FINANCIAL_TOTAL_LIABILITIES_FIELD_LABEL_TITLE: "Всего обязательства",
  PRE_APPROVAL_FINANCIAL_NEW_OPERATING_INCOME_FIELD_LABEL_TITLE: "Новая операционная прибыль",

  PRE_APPROVAL_SECURITY_FIELD_LABEL_TITLE: "Обеспечение",
  PRE_APPROVAL_SUBMIT_BUTTON_TITLE: "Подать заявку",
  PRE_APPROVAL_INTEREST_FIELD_LABEL_TITLE: "Процентная ставка",
  PRE_APPROVAL_REQUEST_GRACE_FIELD_LABEL_TITLE: "Льготный период (в месяцах)",
  PRE_APPROVAL_REQUEST_TERM_FIELD_LABEL_TITLE: "Запрашиваемый период (в месяцах)",
  PRE_APPROVAL_PURPOSE_FIELD_LABEL_TITLE: "Цель финансирования",
  PRE_APPROVAL_APPLICATION_FIELD_LABEL_TITLE: "Тип заявки",
  PRE_APPROVAL_REQUEST_AMOUNT_FIELD_LABEL_TITLE: "Запрашиваемая сумма",
  PRE_APPROVAL_PAGE_REQUEST_TERM_FIELD_LABEL_TITLE: "Запрашиваемый период (в месяцах)",
  PRE_APPROVAL_INFO_INDIVIDUAL_TITLE: "Информация по индивидуальному заявлению",
  PRE_APPROVAL_DESCRIPTION_FIELD_LABEL_TITLE: "Описание покупаемого товара",

  PRE_APPROVAL_CANCEL_BUTTON_TITLE: "Отменить заявку",
  PRE_APPROVAL_COMMENT_TITLE: "Комментарий КДБ Банк Узбекистан",
  PRE_APPROVAL_UPDATE_BUTTON_TITLE: "Обновить и повторно отправить заявку",

  // Individual role  Transfers "Transfers To Another Client Tab"

  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TO_FIELD_LABEL_TITLE: "на",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_SUBMIT_BUTTON_TITLE: "Отправить",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_FROM_FIELD_LABEL_TITLE: "с",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_AMOUNT_FIELD_LABEL_TITLE: "Сумма",
  TRANSFERS_TRANSFERS_ANOTHER_CLIENT_TRANSFERS_DETAILS_FIELD_LABEL_TITLE: "Детали платежа",

  //Individual role Transfers "History Tab"

  INDIVIDUAL_TRANSFERS_HISTORY_TO_FIELD_LABEL_TITLE: "по",
  INDIVIDUAL_TRANSFERS_HISTORY_DATE_FIELD_LABEL_TITLE: "Дата",

  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_ACCOUNT_COLUMN_TITLE: "Статус",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DETAILS_COLUMN_TITLE: "Детали",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_COLUMN_TITLE: "Регистр",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CARD_TYPE_COLUMN_TITLE: "Тип карты",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_PAYMENT_CODE_COLUMN_TITLE: "Код платежа",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_DATE_AND_TIME_COLUMN_TITLE: "Дата",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_REGISTER_NUMBER_COLUMN_TITLE: "Регистр №",
  INDIVIDUAL_TRANSFERS_HISTORY_TABLE_TRANSIT_ACCOUNT_COLUMN_TITLE: "Транзитный счет",

  //Individual role Transfers "Favorites Tab"

  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_STATUS_COLUMN_TITLE: "Статус",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_SUBJECT_COLUMN_TITLE: "Тема",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_CREATED_BY_COLUMN_TITLE: "Исполнитель",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_APPROVED_BY_COLUMN_TITLE: "Авторизатор",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_EXPLANATION_COLUMN_TITLE: "Коментарии",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_COMPLETED_DATE_COLUMN_TITLE: "Дата завершения",
  INDIVIDUAL_TRANSFERS_FAVORITES_TABLE_DATE_OF_SUBMISSION_COLUMN_TITLE: "Дата подачи",

  // Corporate role "Client Feedback Tab"

  CLIENT_FEEDBACK_RATE_VERY_TITLE: "Очень недоволен",
  CLIENT_FEEDBACK_RATE_VERY_SATISFIED_TITLE: "Очень доволен",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_SATISFIED_TITLE: "Slightly satisfied",
  CLIENT_FEEDBACK_RATE_NEITHER_TITLE: "Neither satisfied nor dissatisfied",
  CLIENT_FEEDBACK_RATE_SLIGHTLY_DISSATISFIED_TITLE: "Slightly dissatisfied",
  CLIENT_FEEDBACK_RATE_PLEASE_TITLE:
    "Пожалуйста, оцените работу системы Интернет Банкинг KDB iDBA ",
  CLIENT_FEEDBACK_SUGGESTIONS_TITLE: "Комментарии и предложения",

  CLIENT_FEEDBACK_NOT_LIKELY_SUBTITLE: "Очень хорошо",
  CLIENT_FEEDBACK_EXTREMELY_LIKELY_SUBTITLE: "Очень плохо",
  CLIENT_FEEDBACK_HOW_LIKELY_TITLE:
    "Насколько вероятно, что вы порекомендуете KDB Internet Banking кому-либо из своих друзей?",

  CLIENT_FEEDBACK_LOAN_CHECKBOX_LABEL_TITLE: "Кредит",
  CLIENT_FEEDBACK_YEISVO_CHECKBOX_LABEL_TITLE: "ЕЭИСВО",
  CLIENT_FEEDBACK_NEWS_CHECKBOX_LABEL_TITLE: "Новости",
  CLIENT_FEEDBACK_SUBMIT_BUTTON_LABEL_TITLE: "Отправить",
  CLIENT_FEEDBACK_HELP_CHECKBOX_LABEL_TITLE: "Помоoь/FAQ",
  CLIENT_FEEDBACK_ACCOUNTS_CHECKBOX_LABEL_TITLE: "Счета",
  CLIENT_FEEDBACK_ON_LENDING_CHECKBOX_LABEL_TITLE: "On Lending",
  CLIENT_FEEDBACK_ANALYTICS_CHECKBOX_LABEL_TITLE: "Аналитика",
  CLIENT_FEEDBACK_CONVERSION_CHECKBOX_LABEL_TITLE: "Конвертация",
  CLIENT_FEEDBACK_PAYMENTS_CHECKBOX_LABEL_TITLE: "Платежные поручения",
  CLIENT_FEEDBACK_IN_WHICH_LIKELY_TITLE: "В каком разделе интернет-банка вы хотели бы улучшить?",
  CLIENT_FEEDBACK_WHICH_LIKELY_TITLE:
    "На каком разделе интернет-банка вы в первую очередь или постоянно занимаетесь?",
  CLIENT_FEEDBACK_SENT: "Ваш отзыв был отправлен",
  CLIENT_FEEDBACK_CHOOSE_RATE: "Оцените от 1 до 10",

  //Financial role Main "Approved Deals Tab"

  FINANCIAL_APPROVED_DEALS_UNIT_SUBTITLE: "Валюта: доллары США",
  FINANCIAL_APPROVED_DEALS_TABLE_TOTAL_COLUMN_TITLE: "Всего",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_UPDATING_COLUMN_TITLE: "Детали",
  FINANCIAL_APPROVED_DEALS_APPROVED_DEALS_TITLE: "Утвержденные сделки",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_DATE_COLUMN_TITLE: "Дата подачи документов",
  FINANCIAL_APPROVED_DEALS_TABLE_BORROWER_COLUMN_TITLE: "Наименование субзаемщика",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_NUMBER_COLUMN_TITLE: "Номер заявления",
  FINANCIAL_APPROVED_DEALS_TABLE_AMOUNT_COLUMN_TITLE: "Утвержденная сумма суб-кредита",
  FINANCIAL_APPROVED_DEALS_TABLE_REQUEST_FOR_REGISTRATION_COLUMN_TITLE: "Новый вывод",
  FINANCIAL_APPROVED_DEALS_TABLE_OUTSTANDING_COLUMN_TITLE: "Непогашенная сумма суб-кредита",

  // Corporate role Accounts "Statement tab"
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_DEBIT_COLUMN_TITLE: "Дебет",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_CREDIT_COLUMN_TITLE: "Кредит",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_BALANCE_COLUMN_TITLE: "Баланс",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_REFERENCE_COLUMN_TITLE: "Ссылка / Описание",
  CORPORATE_ACCOUNTS_STATEMENT_TABLE_POST_VALUE_COLUMN_TITLE: "Значение",
  STATEMENT_EXPORT_EXCEL_DATA_LABEL: "Дата",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_LABEL: "Счет отправителя",
  STATEMENT_EXPORT_EXCEL_SENDER_TAX_NUMBER_LABEL: "ИНН отправителя",
  STATEMENT_EXPORT_EXCEL_SENDER_NAME_LABEL: "Наименование отправителя",
  STATEMENT_EXPORT_EXCEL_SENDER_MFO_LABEL: "МФО отправителя",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_LABEL: "Счет получателя",
  STATEMENT_EXPORT_EXCEL_RECEIVER_ACCOUNT_INDEX_LABEL: "Счет получателя (Index acct)",
  STATEMENT_EXPORT_EXCEL_RECEIVER_MFO_LABEL: "МФО получателя",
  STATEMENT_EXPORT_EXCEL_RECEIVER_NAME_LABEL: "Наименование получателя",
  STATEMENT_EXPORT_EXCEL_RECEIVER_TAX_NUMBER_LABEL: "ИНН получателя",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_NUMBER_LABEL: "№ документа",
  STATEMENT_EXPORT_EXCEL_EXPENDITURE_LABEL: "Расход",
  STATEMENT_EXPORT_EXCEL_COMING_LABEL: "Приход",
  STATEMENT_EXPORT_EXCEL_PAYMENT_CODE_LABEL: "Код платежа",
  STATEMENT_EXPORT_EXCEL_PURPOSE_OF_PAYMENT_LABEL: "Назначение платежа",
  STATEMENT_EXPORT_EXCEL_DOCUMENT_TYPE_LABEL: "Вид документа",
  STATEMENT_EXPORT_EXCEL_TRANSACTION_NUMBER_LABEL: "Номер транзакции",
  STATEMENT_EXPORT_EXCEL_CARD_TYPE_LABEL: "Тип карты",
  STATEMENT_EXPORT_EXCEL_TRANSITION_DATE_LABEL: "Дата перевода на счет",
  STATEMENT_EXPORT_EXCEL_SENDER_ACCOUNT_NUMBER_LABEL: "Номер карты",
  STATEMENT_EXPORT_EXCEL_TRANSITION_TIME_LABEL: "Время транзакции",
  STATEMENT_EXPORT_EXCEL_TRANSITION_PLACE_LABEL: "Место транзакции",
  STATEMENT_EXPORT_EXCEL_AMOUNT_LABEL: "Сумма",
  STATEMENT_EXPORT_EXCEL_COMMISSION_AMOUNT_LABEL: "Сумма комиссии",
  STATEMENT_EXPORT_EXCEL_AMOUNT_AFTER_COMMISSION_LABEL: "Сумма после комиссии",
  STATEMENT_EXPORT_EXCEL_SELLER_ID_LABEL: "ID продавца",
  STATEMENT_EXPORT_EXCEL_CLIENT_NAME_LABEL: "Имя клиента",
  STATEMENT_EXPORT_EXCEL_ACCOUNT_NUMBER_LABEL: "Номер аккаунта",
  STATEMENT_EXPORT_EXCEL_DR_CR_INDICATOR_LABEL: "DRCR индикатор",
  STATEMENT_EXPORT_EXCEL_EFFECTIVE_DATE_LABEL: "Дата вступления в силу",
  STATEMENT_EXPORT_EXCEL_ISO_TERMINAL_ID_LABEL: "ID терминала ISO ",
  STATEMENT_EXPORT_EXCEL_ISO_MERCHANT_ID_LABEL: "ID продавца ISO",
  STATEMENT_EXPORT_EXCEL_CARD_NUMBER_LABEL: "Номер карты",
  STATEMENT_EXPORT_EXCEL_DT_BEG_LABEL: "Дата начала",
  STATEMENT_EXPORT_EXCEL_DT_END_LABEL: "Дата окончания",
  STATEMENT_EXPORT_EXCEL_H_LOCACC_LABEL: "H_LOCACC",
  STATEMENT_EXPORT_EXCEL_DOC_NUMBER_LABEL: "№ Док",
  STATEMENT_EXPORT_EXCEL_BO_LABEL: "BO",
  STATEMENT_EXPORT_EXCEL_PAYERS_NAME_LABEL: "Наименование плательщика",
  STATEMENT_EXPORT_EXCEL_TAX_NUMBER_LABEL: "Инн",
  STATEMENT_EXPORT_EXCEL_MFO_LABEL: "МФО",
  STATEMENT_EXPORT_EXCEL_CHECKING_ACCOUNT_LABEL: "Расчетный счет",
  STATEMENT_EXPORT_EXCEL_PAYMENT_DATE_LABEL: "Дата платежа",
  STATEMENT_EXPORT_EXCEL_OPERATION_DATE_LABEL: "Опер.день",
  STATEMENT_EXPORT_EXCEL_RECEIVER_BANK_LABEL: "Банк получателя",
  STATEMENT_EXPORT_EXCEL_TOTAL_PERIOD_LABEL: "Итого за период",
  STATEMENT_EXPORT_EXCEL_BALANCE_END_PERIOD_LABEL: "Остаток на конец периода",
  STATEMENT_EXPORT_EXCEL_BALANCE_BEGINNING_PERIOD_LABEL: "Остаток на начала периода",
  STATEMENT_EXPORT_EXCEL_TO_LABEL: "по",
  STATEMENT_EXPORT_EXCEL_FROM_LABEL: "за период с",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_LABEL: "Справка о дебетовых оборотах по счету",
  STATEMENT_EXPORT_EXCEL_PASSIVE_LABEL: "ПАССИВ",
  STATEMENT_EXPORT_EXCEL_DEBIT_TURNOVERS_CREDIT_LABEL: "Справка о кредитовых оборотах по счету",
  STATEMENT_NO_TRANSACTIONS: "Нет транзакций",

  // Corporate role Transfers "Salary tab"
  SALARY_CREATE_NEW_REGISTER_SUBMIT_BUTTON_TITLE: "Отправить",
  SALARY_CREATE_NEW_REGISTER_TABLE_ID_COLUMN_TITLE: "№",
  SALARY_CREATE_NEW_REGISTER_TABLE_STATUS_COLUMN_TITLE: "Статус",
  SALARY_CREATE_NEW_REGISTER_TABLE_CARD_STATUS_COLUMN_TITLE: "Cтатус карты",
  SALARY_CREATE_NEW_REGISTER_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE: "Ф.И.О",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE: "Наименование компании",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE: "Номер счета",
  SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_FROM_SYSTEM_COLUMN_TITLE: "Полное имя из системы",
  SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_FROM_SYSTEM_COLUMN_TITLE:
    "Наименование компании из системы",
  SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_FROM_SYSTEM_COLUMN_TITLE:
    "Номер счета из системы",
  CORPORATE_CARD_REPLANISHMENT_TEXT: "Пополнение корпоративной карты по реестру",
  TRANSFERS_TRANSFERS_OPEN_MODAL_BUTTON_TITLE: "Загрузить от держателей карт",
  TRANSFERS_SALARY_LIST_OF_CARD_CHOOSE: "Загрузить",

  // Payroll statuses
  PAYROLL_STATUS_CREATED: "Создан",
  PAYROLL_STATUS_ENTERED: "Введен",
  PAYROLL_STATUS_APPROVED: "Получен банком",
  PAYROLL_STATUS_SENT_TO_BANK: "Отправлен в банк",
  PAYROLL_STATUS_PROCESSING: "В процессе",
  PAYROLL_STATUS_REJECTED: "Отклонено клиентом",
  PAYROLL_STATUS_ANNULLED: "Аннулирован",
  PAYROLL_STATUS_COMPLETED: "Успешно завершено",
  PAYROLL_STATUS_SENT_TO_CB: "Отправлено в ЦБ",
  PAYROLL_STATUS_REJECTED_BY_CB: "Отклонено банком",
  PAYROLL_STATUS_SENT_TO_CARD_SYSTEM: "Отправлено в карточную систему",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1: "Утверждено первым авторизатором",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2: "Утверждено вторым авторизатором",
  PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3: "Утверждено третьим авторизатором",
  STATUS_REJECTED: "Отклонено",
  STATUS_ACCEPTED: "Принято",
  REJECT_BUTTON: "Вы уверены, что хотите отклонить это?",
  NOTIFICATION_ON_STATUS_FOR_CREATER:
    "Пожалуйста, выберите документ(ы), созданный(е) только Вами и имеющий(е) статус «Создан»",

  TRANSFER_HISTORY_STATUS_ALL: "Все",
  TRANSFER_HISTORY_STATUS_OUTGOING: "Исходящие",
  TRANSFER_HISTORY_STATUS_INCOMING: "Входящие",

  TRANSFER_SALARY_DETAIL_TYPE_SALARY: "Зарплата",
  TRANSFER_SALARY_DETAIL_TYPE_PREPAYMENT: "Аванс",
  TRANSFER_SALARY_DETAIL_TYPE_TRAVEL: "Командировачные расходы",
  TRANSFER_SALARY_DETAIL_TYPE_COMPENSATION: "Компенсация",
  TRANSFER_SALARY_DETAIL_TYPE_BONUS: "Бонус",
  TRANSFER_SALARY_DETAIL_TYPE_PENSION: "Пенсия",
  TRANSFER_SALARY_DETAIL_TYPE_OTHERS: "Другие",
  TRANSFER_SALARY_DETAIL_TYPE_VOCATION: "Отпускные",
  TRANSFER_SALARY_DETAIL_TYPE_SETTLEMENT: "Окончательный расчет",

  HELP_AND_FAQ_QUESTION: "Вопрос: ",
  HELP_AND_FAQ_ANSWER: "Ответ: ",

  ATTENTION_TITLE: "Внимание!",
  ATTENTION_SUBTITLE: "Другой пользователь вошел в систему!",

  TRANSFER_ERRORS_BENEFICIARY_BANK: "Банк не был найден",
  TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE: "Банк получателя",
  TRANSFER_ERRORS_AMOUNT: "Сумма не может равна 0",
  TRANSFER_ERRORS_AMOUNT_TITLE: "Сумма",
  TRANSFER_ERRORS_DEBIT_ACCOUNT: "Выберите дебит счёт",
  TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE: "Дебит",
  TRANSFER_ERRORS_BUDGET_ACCOUNT: "Бюджетный счёт не найден",
  TRANSFER_ERRORS_BUDGET_ACCOUNT_TITLE: "Бюджетный счёт",
  TRANSFER_INFO_CREATED: "Платёжное поручение создано",
  TRANSFER_MODIFIED: "Платёжное поручение исправлена",
  TRANSFER_INFO_TITLE: "Платёжное поручение",
  TRANSFER_CHARGES_ERROR: "Пожалуйста выберите тип комиссии за перевод",
  TRANSFER_SENDER_PHONE_NUMBER_ERROR: "Требуется номер телефона",
  TRANSFER__CHOOSE_CHARSET: "Пожалуйста, выберите кодировку",
  TRANSFER_CHARGES_ERROR_TITLE: "Комиссия",

  TRANSFER_NACE_CODE_ERROR_TITLE: "Nace Code",
  TRANSFER_NACE_CODE_ERROR: "Пожалуйста, выберите Nace Code",

  INQUIRY_STANDART_ERRORS_MAX: "Максимально можно ввести 20 аккаунтов или выберите все",
  INQUIRY_STANDART_ERRORS_MAX_TITLE: "Аккаунты",

  CONVERSION_INFO_SEND: "Конвертация создана",
  CONVERSION_INFO_TITLE: "Конвертация",
  CONVERSION_FAVORITES_ERROR: "Введите номер и дату контракта",
  CONVERSION_FAVORITES_ERROR_TITLE: "Избранные",
  CONVERSION_FAVORITES_INFO_SAVED: "Сохранено в избранные",
  CONVERSION_DOCUMENT_ID: "ID документа",

  CONVERSION_VISA_CLOSE_DAY_ERROR_TITLE:
    "В связи с открытием операционного дня для карт VISA, пожалуйста, попробуйте снова после 30 минут",

  TRANSFER_FILL_INFO:
    "Пожалуйтса используйте следующую таблицу транслитерации с русского на английский.",

  SALARY_CREATED_INFO: "Зарплатный реест создан",
  SALARY_CREATED_INFO_TITLE: "Зарплатный реестр",
  SALARY_CARD_CREATED_INFO: "Заявка на открытие новой зарплатной карты была создана",
  SALARY_CARD_CREATED_INFO_TITLE: "Зарплатная карта",
  SALARY_CHOOSE_CARD_TYPE: "Пожалуйста выберите тип карты",
  SALARY_CHOOSE_CARD_TYPE_TITLE: "Тип карты",
  SALARY_NO_TRANSIT_ACCOUNT: "Нету транзитного счёта",
  SALARY_NO_TRANSIT_ACCOUNT_TITLE: "Транзитный счёт",
  SALARY_FULLNAME_LIMIT_ERROR_TITLE:
    "Имя и Фамилия (Имя + Фамилия) не должны превышать 24 символов",

  QUESTION_CREATED_INFO: "Вопрос получен банком",
  QUESTION_CREATED_INFO_TITLE: "Вопрос",

  YEISVO_FIND_ERROR: "Документ не найден в системе ИЭИСВО",
  YEISVO_FIND_ERROR_TITLE: "ИЭИСВО",

  PASSWORD_ERROR_TITLE: "Пароль",
  SERVER_ERROR_TITLE: "Ведутся технические работы",

  FEEDBACK_INFO_TITLE: "Отзыв",

  INFORMATION_TITLE_1:
    "Пожалуйста проверьте статус запроса на экране стандартных запросов. Когда статус будет изменён на завершён,\n" +
    "подойдите в банк для получение оригинала ответа. Примечание: Банк спишет комиссию за запрос согласно тарифам банка",

  INFORMATION_TITLE_2:
    "BEN (beneficiary) Все комиссии за счёт получателя, сумма комиссий удерживается с суммы платежа",
  INFORMATION_TITLE_3:
    "OUR Все комиссии за счёт отправителя, получатель получить полную сумму платежа",
  INFORMATION_TITLE_4:
    "SHA (shared) Комиссии АО КДБ Банка Узбекистан за счёт отправителя, получатель получит сумму платежа за минусом комиссии банка-корреспондента",
  INFORMATION_TITLE_5:
    "PPRO/END Все коммисии за счёт отправителя, получатель получит полную сумму платежа (Для переводов в доллорав США через JP Morgan Chase Bank)",
  INFORMATION_TITLE_6: "Максимум 2,000 сотрудников в одном реестре",
  INFORMATION_TITLE_7: "Убедитесь, что на соответствующем транзитом счете достаточно средств для исполнения ведомости",
  INFORMATION_TITLE_8: "Убедитесь, что на соответствующем депозитном счете до востребования достаточно средств для исполнения ведомости.",

  MODAL_CONFIRMATION_TITLE: "Подтверждение",
  MODAL_CONFIRMATION_YES: "Подтвердить",
  MODAL_CONFIRMATION_NO: "Отменить",
  MODAL_YES: "Да",
  MODAL_NO: "Нет",
  MODAL_PAYMENT_ORDER_TO_RESERVE_FUNDS:
    "Хотите создать платежное поручение для резервирования средств на специальный счет 22613?",
  MODAL_INFO_TRANSFER_FIRST:
    "Пожалуйста, подтвердите, что вы подали заявку на конвертацию для перевода с конвертационного счёта",
  MODAL_INFO_TRANSFER_SECOND:
    "Пожалуйста, подтвердите, что вы хотите сделать перевод из собственных средств (не с конвертационного счёта)",
  MODAL_INFO_SALARY:
    "Пожалуйста, подтвердите, что налоговые платежи по заработной плате были выплачены",
  MODAL_INFO_CONVERSION:
    "\n" +
    "Публичная оферта на оказание услуг по конвертации\n" +
    "\n" +
    "Данный документ является официальным предложением (публичной офертой) АО «КДБ Банк Узбекистан» (далее по тексту – Банк) и определяет стандартные условия и порядок осуществления банковских операций c Клиентами Банка (далее – Клиент):\n" +
    "    • по конвертации иностранной валюты в национальную валюту Республики Узбекистан (далее по тексту – национальная валюта),\n" +
    "    • по конвертации национальной валюты в иностранную валюту (кроме конвертации для командировочных расходов),\n" +
    "    • по конвертации иностранной валюты одного вида в иностранную валюту другого вида.\n" +
    "В соответствии с пунктом 2 статьи 369 Гражданского Кодекса Республики Узбекистан в случае принятия изложенных ниже условий и присоединения к договору при подаче Заявления на конвертацию или Заявления на перевод валютных средств, Клиент, производящий акцепт настоящей публичной оферты, становится Стороной Договора (в соответствии с частью 4 статьи 370 ГК РУз акцепт оферты равносилен заключению договора на условиях, изложенных в оферте), а Банк и Клиент совместно – Сторонами договора.\n" +
    "\n" +
    "\n" +
    "1. ТЕРМИНОЛОГИЯ\n" +
    "\n" +
    "1.1 Оферта – настоящий документ «Публичная оферта на оказание услуг по конвертации».\n" +
    "1.2. Публичная оферта на оказание услуг по конвертации – договор между Банком и Клиентом на оказание услуг по конвертации, который заключается посредством акцепта оферты.\n" +
    "1.3. Клиент – лицо, осуществившее акцепт публичной оферты, и являющееся таким образом заказчиком услуг Банка в рамках публичной оферты.\n" +
    "1.4. Банк – Головной офис АО «КДБ Банк Узбекистан» и его филиалы.\n" +
    "1.5. Акцепт публичной оферты – полное и безоговорочное принятие оферты путем осуществления Клиентом действий, указанных в пункте 1.7 настоящей публичной оферты.\n" +
    "1.6. Приложения дистанционного обслуживания (далее по тексту – ПДО) –пакеты программного обеспечения Банка, которые позволяют Клиенту формировать и передавать Банку инструкции по конвертации и платежам.\n" +
    "1.7. Заявление о присоединении к публичной оферте – письменная оговорка в Заявлении на конвертацию (Приложение №1, Приложение №2) или в Заявлении на перевод валютных средств (Приложение №31), предусматривающая однозначное и автоматическое согласие Клиента на присоединение к публичной оферте и акцепт (принятие) его условий. Клиент, подписав Заявление на конвертацию или  Заявление на перевод валютных средств, либо авторизовав эти документы через ПДО, автоматически акцептует условия публичной оферты.\n" +
    "1.8. Услуга – предоставление Банком услуги по конвертации.\n" +
    "1.9. Конвертация – это операция по:\n" +
    "    • продаже иностранной валюты и покупке национальной валюты Клиентом, \n" +
    "    • продаже национальной валюты и покупке иностранной валюты Клиентом,\n" +
    "    • покупке и продаже иностранной валюты одного вида за иностранную валюту другого вида Клиентом.\n" +
    "1.10. Заявление на перевод – документ предусматривающий инструкцию Клиента Банку на перевод валютных средств путем дебетования денежных средств Клиента в иностранной валюте, в которой Клиент имеет счет в Банке и дальнейшей оплаты в этой же валюте или в конвертируемой валюте, указанной Клиентом в платежном поручении.  \n" +
    "1.11. Заявление на конвертацию – документ предусматривающий инструкцию Клиента Банку на:\n" +
    "    • продажу иностранной валюты и дальнейшего зачисления на счет Клиента в национальной валюте,\n" +
    "    • продажу национальной валюты и покупку иностранной валюты и дальнейшего зачисления на специальный счет  Клиента в иностранной валюте,\n" +
    "    • продажу иностранной валюты одного вида и дальнейшего зачисления на счет Клиента в иностранной валюте другого вида, указанной Клиентом в Заявлении на конвертацию.\n" +
    "\n" +
    "\n" +
    "2. ОБЩИЕ УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ БАНКОМ УСЛУГ\n" +
    "\n" +
    "2.1. Настоящая публичная оферта определяет порядок и условия взаимодействия Банка и Клиента при совершении конвертации.\n" +
    "2.2. Услуга предоставляется Клиентам Банка, осуществившим акцепт настоящей публичной оферты, путем подписания и подачи Заявления на конвертацию и/или Заявления на перевод в Банк. Подписав Заявление на конвертацию и/или Заявление на перевод или авторизовав эти Заявления через ПДО, Клиент вправе пользоваться услугой Банка по мере необходимости.\n" +
    "2.3. Услуга, предоставляемая Клиенту, ограничивается исключительно исполнением Заявления на конвертацию и/или Заявления на перевод. Банк не дает рекомендаций или консультаций по существу или целесообразности покупки/продажи или иных операций с иностранной валютой в рамках настоящей публичной оферты.\n" +
    "2.4. Cумма и вид валюты для конвертации определяется Клиентом по отдельному Заявлению на конвертацию или Заявлению на перевод, являющимися неотъемлемой частью настоящей публичной оферты и имеют силу Договора со всеми вытекающими правами и обязанностями, присущими Договору.\n" +
    "2.5. Конвертация по настоящей публичной оферте может осуществляться неоднократно в течение срока действия публичной оферты.\n" +
    "2.6. Конвертация по настоящей публичной оферте осуществляется на основании настоящей публичной оферты, Заявления на конвертацию, Заявления на перевод и других необходимых документов, предусмотренных действующим законодательством.\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "3. ПОРЯДОК ОСУЩЕСТВЛЕНИЯ КОНВЕРТАЦИИ ИНОСТРАННОЙ ВАЛЮТЫ В НАЦИОНАЛЬНУЮ ВАЛЮТУ\n" +
    "\n" +
    "3.1. Клиент (кроме физических лиц2) продает иностранную валюту по курсу, устанавливаемому Банком на момент совершения сделки.\n" +
    "3.2. Клиент переводит и резервирует на своем счете в Банке денежные средства в иностранной валюте в объеме, достаточном для продажи, до подачи Заявления.\n" +
    "3.3. Клиент обязан иметь депозитный счет до востребования в Банке в национальной валюте для зачисления купленных средств.\n" +
    "3.4. Клиент предоставляет в Банк Заявление по форме согласно Приложению №1 к настоящей публичной оферте.\n" +
    "3.5. Клиент указывает в Заявлении минимальный курс, по которому Клиент согласен продать иностранную валюту. \n" +
    "При этом, Заявление будет удовлетворено в случае если курс установленный Банком на момент исполнения заявления будет равен/выше минимального курса, указанного в Заявлении. В этом случае, продажа иностранной валюты будет осуществлена по курсу установленный Банком.\n" +
    "Заявление будет оставаться неудовлетворенной до тех пор, пока курс, установленный Банком, не будет равен/выше минимального курса, указанного в Заявлении. \n" +
    "3.6. Банк исполняет Заявление и осуществляет все расчеты с Клиентом в срок не позднее 2 (двух) банковских рабочих дней после даты Заявления.\n" +
    "3.7. Клиент извещает Банк об отзыве предоставленного в Банк Заявления до начала процесса исполнения Заявления Банком.\n" +
    "\n" +
    "\n" +
    "\n" +
    "4. ПОРЯДОК ОСУЩЕСТВЛЕНИЯ КОНВЕРТАЦИИ НАЦИОНАЛЬНОЙ ВАЛЮТЫ В ИНОСТРАННУЮ ВАЛЮТУ\n" +
    "\n" +
    "4.1. Клиент (кроме физических лиц3) покупает иностранную валюту по курсу, устанавливаемому Банком на момент совершения сделки.\n" +
    "4.2. Наименование валюты, сумма и максимальный курс, цель использования покупаемой валюты, а также документы, обосновывающие валютную операцию, указываются в Заявлении (Приложение №2).\n" +
    "4.3. Операции по покупке иностранной валюты осуществляются для их целевого использования Клиентом на основании Заявлений Клиента, представленных в бумажном виде или через ПДО.\n" +
    "4.4. При покупке иностранной валюты Банк вправе требовать дополнительные документы, подтверждающие законность данных операций.\n" +
    "4.5. Средства, зачисленные на специальные валютные счета, используются строго на цели, указанные в Заявлениях на конвертацию.\n" +
    "4.6. При покупке иностранной валюты согласно раздела 4 настоящей публичной оферты:\n" +
    "4.6.1 Клиент обязуется открыть специальный счет в иностранной валюте (балансовый счет – 22614), на который зачисляется приобретенная иностранная валюта. \n" +
    "4.6.2.\tКлиент обязуется открыть специальный счет в национальной валюте (балансовый счет – 22613) и через данный счет, в срок не позднее 2 (двух) банковских рабочих дней осуществить все расчеты с Банком по Заявлениям, по которым Банком приняты положительные заключения.\n" +
    "Если в течение (2) двух банковских рабочих дней Клиент не завершает расчеты, то сделка считается аннулированной. При этом, обратная продажа средств купленных Клиентом, осуществляется по курсу, устанавливаемому Банком на момент совершения обратной продажи. \n" +
    "4.6.3.\tПри покупке иностранной валюты Клиент обязуется предоставить в Банк Заявление, при необходимости копии документов, подтверждающих основание для покупки валюты (целевое использование). При этом в случае несоответствия предоставленных документов требованиям действующего законодательства и настоящей публичной оферты, Банк вправе отказать в исполнении Заявления Клиента.\n" +
    "4.6.4.\tКлиент обязуется использовать приобретенные валютные средства в течение 7 (семи) банковских рабочих дней с момента зачисления средств на валютный счет.\n" +
    "4.6.5. Ранее списанная со специального валютного счета и неиспользованная, а также вновь поступившая по другим причинам иностранная валюта подлежит обратной продаже Банку в течение (3) трех банковских рабочих дней, либо использована повторно строго на цели, указанные в Заявлении.\n" +
    "4.6.6.\tПрисоединяясь к настоящей публичной оферте, Клиент дает свое безоговорочное и безотзывное согласие Банку на списание в без акцептном порядке с любых своих счетов сумму, эквивалентной купленной иностранной валюте, указанной в Заявлении.\n" +
    "4.6.7. При не выполнении Клиентом условий первого абзаца пункта 4.6.2. публичной оферты настоящим Клиент дает свое безоговорочное и безотзывное согласие Банку на списание в безакцептном порядке с любых своих счетов возможные убытки Банка (включая но, не ограничиваясь отрицательной разницей в курсе между покупкой и продажей).\n" +
    "4.6.8. Клиент обязуется аннулировать Заявление в случае наступления любых из нижеследующих условий:\n" +
    "    • изменения цели покупки по Заявлению;\n" +
    "    • при блокировании счета согласно решениям уполномоченных органов.\n" +
    "\n" +
    "При несвоевременном обеспечении денежными средствами для осуществления взаиморасчетов с Банком, Банк имеет право в самостоятельном порядке аннулиривать Заявление. При возникновении вышеуказанных случаев и неинформировании Банка со стороны Клиента, Банк имеет право самостоятельно аннулировать Заявление в безакцептном порядке, и осуществить обратную продажу валютных средств. \n" +
    "4.7. При покупке иностранной валюты согласно раздела 4 настоящей публичной оферты:\n" +
    "4.7.1. Банк обязуется рассмотреть предоставленное Заявление Клиента  в срок не позднее  следующего банковского рабочего дня после дня приема Заявления на конвертацию.\n" +
    "4.7.2.\tВ случае невозможности исполнения Заявления по причине несоответствия установленным требованиям действующего законодательства, Банк обязан письменно сообщить Клиенту об этом.\n" +
    "4.7.3.\tБанк обязуется исполнить Заявления и в срок не позднее 2 (двух) банковских рабочих дней осуществить все расчеты  с Клиентом по Заявлениям, по которым Банком приняты положительные заключения.\n" +
    "4.7.4.\tБанк осуществляет в безакцептном порядке обратную продажу валютных средств приобретенных Клиентом ранее списанных со специальных валютных счетов Клиента и вновь поступивших на эти счета, в связи с неиспользованием или возвратом в течение 3 (трех) банковских рабочих дней.\n" +
    "4.7.5.\tБанк осуществляет обратную продажу валютных средств приобретенных Клиентом в безакцептном порядке в случае неиспользования Клиентом приобретенной иностранной валюты в течение 7 (семи) банковских рабочих дней с момента зачисления на специальный валютный счет Клиента. Продажа должна быть осуществлена не позднее следующего банковского рабочего дня после окончания этого срока.\n" +
    "4.7.6.\tВ случае аннулирования Заявления на конвертацию на основании письменного обращения Клиента, Банк предпринимает меры согласно пункту 4.6.7..\n" +
    "4.7.7.\tПри недостаточности денежных средств Клиента для оплаты купленных валютных средств, Банк оставляет за собой право на списание в без акцептном порядке с любых счетов Клиента сумму, эквивалентной купленной иностранной валюте, указанной в Заявлении.\n" +
    "\n" +
    "\n" +
    "5. ПОРЯДОК ОСУЩЕСТВЛЕНИЯ КОНВЕРТАЦИИ ИНОСТРАННОЙ ВАЛЮТЫ ОДНОГО ВИДА В ИНОСТРАННУЮ ВАЛЮТУ ДРУГОГО ВИДА\n" +
    "\n" +
    "5.1. Для осуществления конвертации Клиент предоставляет в Банк соответствующее Заявление на конвертацию (Приложение №1) или Заявление на перевод валютных средств (Приложение №3).\n" +
    "5.2. Конвертация осуществляется в свободно конвертируемых иностранных валютах, по которым Банк имеет возможность конвертации.\n" +
    "5.3. Заявление на конвертацию или Заявление на перевод исполняется Банком в течение 2 (двух) банковских рабочих дней после даты принятия Заявления Банком, с учетом того, что день исполнения Заявления не является праздничным днем в странах валют, которые участвуют в транзакции.\n" +
    "5.4. Конвертация осуществляется согласно Заявлению на конвертацию или Заявлению на перевод Клиента по курсу Банка, который включает в себя маржу Банка.\n" +
    "5.5. При осуществлении конвертации и вплоть до исполнения Банком Заявления на конвертацию или Заявления перевод, Клиент должен обеспечить на своем счете в Банке наличие денежных средств в иностранной валюте для осуществления конвертации.\n" +
    "5.6. Размер маржи Банка за услугу устанавливается тарифами Банка.\n" +
    "5.7. Оплата за услуги Банка осуществляется путем списания Банком в безакцептном порядке со счета(ов) Клиента необходимой суммы контрвалюты (продаваемой валюты).\n" +
    "5.8. Подписав Заявление на конвертацию или Заявление на перевод, либо авторизовав эти Заявления в ПДО, Клиент предоставляет Банку право, без какого-либо дополнительного согласия Клиента, списать со счетов Клиента, открытых в Банке, необходимую сумму для осуществления конвертации в соответствии с утвержденными тарифами Банка. \n" +
    "\n" +
    "\n" +
    "6. ПРАВА И ОБЯЗАННОСТИ СТОРОН\n" +
    "\n" +
    "6.1. Банк оставляет за собой право отказать в исполнении конвертации в случае некорректности заполнения Заявления Клиентом.\n" +
    "6.2. Банк настоящим не несет ответственность за ошибки и опечатки, возникшие по вине Клиента в контексте Заявления. Ответственность за достоверность сведений, указанных в Заявлении, возлагается Клиента.\n" +
    "6.3. Банк не несет ответственность за несвоевременное и/или ненадлежащее действие других участников сделки, а также в случае не заключения сделки в установленном законодательством Республики Узбекистан.\n" +
    "6.4. Банк обязуется своевременно и в полном объеме выполнять обязательства, предусмотренные в  настоящей публичной оферте.\n" +
    "6.5. Банк имеет право получать необходимые разъяснения, документы и сведения по вопросам, касающимся проводимых Клиентом операциям.\n" +
    "6.6. Банк имеет право отказать в полном или частичном исполнении любого Заявления.\n" +
    "6.7. Банк имеет право приостановить в одностороннем порядке предоставление услуги в случаях:\n" +
    "А) некорректного заполнения Клиентом Заявления на конвертацию или Заявления на перевод;\n" +
    "Б) ненадлежащего исполнения Клиентом своих обязательств, предусмотренных в настоящей публичной оферте;\n" +
    "В) обнаружения Банком операций, не соответствующих условиям публичной оферты или действующего законодательства Республики Узбекистан;\n" +
    "Г) непринятия Клиентом дополнительных условий и изменений настоящей публичной оферты;\n" +
    "Д) наличия подозрения в проводимых операциях, которые могут отнестись к легализации преступных доходов и финансирования терроризма.\n" +
    "6.8. Банк имеет право изменять тарифы, вносить изменения и дополнения в условия настоящей оферты. При этом Банк уведомляет Клиента об этих изменениях не позднее 10 (десять) банковских рабочих дней до вступления изменений в силу путем размещения информации о вносимых изменениях и дополнениях на стендах в подразделениях Банка и на Веб-сайте Банка www.kdb.uz.\n" +
    "6.9. В случае непоступления от Клиента в 10-дневный срок возражений относительно внесенных Банком изменений/дополнений в тарифы и условия настоящей публичной оферты, данные изменения/дополнения считаются принятыми Клиентом. В случае несогласия Клиента с изменениями или дополнениями, внесенными в тарифы и условия настоящей оферты, Клиент вправе до вступления в силу таких изменений/дополнений расторгнуть Договор путем направления письменного уведомления.\n" +
    "6.10. Клиент обязан предоставить по требованию Банка необходимые разъяснения, документы и сведения по вопросам, касающихся проводимых Клиентом операций.\n" +
    "6.11. Клиент обязан оплачивать услуги Банка в соответствии с действующими тарифами Банка в порядке, предусмотренном настоящей публичной офертой, для чего, поддерживать достаточный остаток денежных средств (в контрвалюте/продаваемой валюте/национальной валюте) на счете (ах), для оплаты покупаемой валюты.\n" +
    "6.12. Клиент имеет право пользоваться услугами Банка, представляя в Банк по мере необходимости Заявления на конвертацию или Заявление на перевод.\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "7. ФОРС-МАЖОР\n" +
    "\n" +
    "7.1. Стороны не несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств, вытекающих из настоящей публичной оферты, если надлежащее их исполнение невозможно вследствие наступления обстоятельств непреодолимой силы (стихийные бедствия, забастовки, гражданские волнения, войны или какие-либо другие, не зависящие от воли Сторон обстоятельства, в том числе и из-за изменения законодательства Республики Узбекистан либо издания/принятия Центральным банком Республики Узбекистан актов/указаний).\n" +
    "7.2. Банк не несет ответственность в следующих случаях:\n" +
    "7.2.1. При возможных сбоях в межбанковской системе электронных платежей;\n" +
    "7.2.2. Ареста и/или приостановления и/или замораживания операций по счетам Банка или Клиента в порядке, установленном законодательством;\n" +
    "7.2.3. При невозможности выполнения Банком своих обязательств по публичной оферте по независящим от Банка причинам и при наступлении форс-мажорных обстоятельств;\n" +
    "7.3. Банк не несет ответственность за неудовлетворение Заявлений по независящим от Банка обстоятельствам.\n" +
    "\n" +
    "\n" +
    "8. КОНФИДЕНЦИАЛЬНОСТЬ\n" +
    "8.1.\tСтороны обязуются в соответствие с публичной офертой, сохранять в тайне и не разглашать каким бы то ни было способом третьим лицам конфиденциальную информацию, ставшую им известной в результате заключения и исполнения настоящей публичной оферты.\n" +
    "8.2.\tНе признается конфиденциальной информация, которая была получена Стороной до присоединения к настоящей публичной оферте, информация, полученная законными методами из других источников, а также информация, которая не может относиться к конфиденциальной в соответствии с действующим законодательством Сторон.\n" +
    "8.3.\tВ случае возникновения необходимости предоставления или разглашения конфиденциальной информации по запросу уполномоченного государственного органа, которому право требовать предоставления конфиденциальной информации предоставлено законом, Сторона обязана письменно или устно уведомить об этом Сторону, предоставившую данную информацию.\n" +
    "8.4.\tСтороны обязуются:\n" +
    "8.4.1.\tОбеспечить хранение конфиденциальной информации, исключающее несанкционированный доступ к ней третьих лиц;\n" +
    "8.4.2. Не передавать конфиденциальную информацию третьим лицам, как в полном объеме, так и частично;\n" +
    "8.4.3. Не публиковать конфиденциальную информацию в средствах массовой информации и не разглашать содержание конфиденциальной информации неограниченному кругу лиц любым иным способом;\n" +
    "8.4.4. Обеспечить доступ к конфиденциальной информации исключительно должным образом      уполномоченных лиц Сторон;\n" +
    "8.4.5. Вести учет лиц, получивших доступ к конфиденциальной информации другой Стороны, полученной по настоящей публичной оферте;\n" +
    "8.4.6. Выполнять иные обязанности по защите конфиденциальной информации, предусмотренные законодательством Сторон.\n" +
    "\n" +
    "9. ПРОЧИЕ УСЛОВИЯ\n" +
    "\n" +
    "9.1. Публичная оферта вступает в силу с момента подписания и предоставления Заявления на конвертацию или Заявления  на перевод валютных средств в Банк или с момента  авторизации этих Заявлений в ПДО.\n" +
    "9.2. Срок действия публичной оферты неограничен.\n" +
    "9.3. Каждый раз при предоставлении Заявления на конвертацию или Заявления на перевод в Банк или при авторизации Заявлений в ПДО Клиент присоединяется к данной публичной оферте.\n" +
    "9.4. Настоящая публичная оферта подлежит расторжению в следующих случаях:\n" +
    "    • в случае отказа Клиента от внесенных Банком изменений и дополнений в тарифы и условия оферты;\n" +
    "    • в порядке установленном законодательством Республики Узбекистан.\n" +
    "9.5. C даты вступления в силу настоящей публичной оферты все предыдущие соглашения и договора между Сторонами на оказание услуг по конвертации считаются расторгнутыми.\n" +
    "9.6.\tСтороны принимают все меры к решению разногласий между ними путем переговоров. При недостижении соглашения, все споры, возникающие из настоящей публичной оферты, разрешаются в Межрайонном Экономическом суде г. Ташкента.\n" +
    "9.7.\tНи одна из Сторон не имеет права передавать или переуступать третьим лицам свои права и обязанности по настоящей публичной оферте без предварительного письменного согласия на то другой Стороны.\n" +
    "9.8.\tВо всем ином, что прямо не урегулировано в настоящей публичной оферте, Стороны руководствуются действующим законодательством Республики Узбекистан.\n" +
    "9.9.\tПубличная оферта составлена на русском и английском языках. В случае возникновения каких-либо разногласий и споров русская версия публичной оферты является превалирующей.\n" +
    "\n" +
    "\n",
  MODAL_CONVERSION_TITLE: "Публичная оферта",
  MODAL_LOADING_TITLE: "Загрузка...",
  MODAL_LOADING_EXCEL_TITLE: "В процессе . . .",

  PENDING_APPROVAL_APPROVE: "Утвердить",
  PENDING_APPROVAL_REJECT: "Отклонить",

  APP_HEADER_SEARCH: "Поиск...",

  WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE:
    "При выборе будущей даты пожалуйста убедитесь, что это рабочий день, иначе платежная инструкция может быть не исполнена.",

  TRANSFER_NOTIFICATION_TITLE: "Платёжное поручение",
  TRANSFER_NOTIFICATION_FIRST: "Платёжное поручение",
  TRANSFER_CREATED: "создан",
  TRANSFER_NOT_CREATED: "не создан",
  TRANSFER_NO_DEBIT_IMPORT: "Дебит счёт не найден",
  TRANSFER_NO_PHONE_IMPORT: "Номер телефона пустой",
  TRANSFER_DATE_INCORRECT_IMPORT: "Неверная дата транзакции",
  TRANSFER_CORRESPANDENT_TAX_ID_INCORRECT_IMPORT:
    "Налоговый идентификатор бенефициара должен состоять из 9 цифр.",

  FILE_SELECTOR_IMPORT_FROM_CSV: "Импорт из CSV",
  FILE_SELECTOR_IMPORT_FROM_TXT: "Импорт из TXT",

  SALARY_REGISTER_STATUS_MATCH: "Совпадает",
  SALARY_REGISTER_STATUS_NOT_MATCH: "Не совподает",
  SALARY_REGISTER_STATUS_NOT_FOUND: "Счёт не найден",
  SALARY_REGISTER_CARD_STATUS_ACTIVE: "Активный",
  SALARY_REGISTER_CARD_STATUS_CLOSED: "Закрытый",
  SALARY_REGISTER_CARD_STATUS_SUSPENDED: "Приостановленный",

  TRANSFER_IMPORT_DEFECTED: "Забракован",
  TRANSFER_IMPORT_ERROR: "Ошибка",
  TRANSFER_SUCCESS: "Успешно",
  TRANSFER_LOADING: "Идёт загрузка",

  TRANSFER_UPAY_AMOUNT_ERROR_TITLE: "Минимальная сумма перевода 1000 сум",

  TRANSFER_ANOTHER_BANK_CLIENT_KDB_CARD_ERROR_TITLE:
    'Получатель является клиентом банка KDB, пожалуйста воспользуйтесь функцией "Переводы на счета других клиентов KDB UZB"',

  TRANSFER_IMPORT_TABLE_HEADER: "Импорт",
  TRANSFER_IMPORT_TABLE_COMMENT: "Комментарий",
  TRANSFER_SWIFT_NOT_FOUND: "SWIFT код банка получателя не найден",
  TRANSFER_SWIFT_FIRST_NOT_FOUND: "SWIFT код банк-корреспондент банка получателя не найден",

  YEISVO_CLIENTS_INFORMATION_FORM_CONTRACT_CURRENCY_2_FIELD_LABEL_TITLE: "Валюта контракта 2",
  YEISVO_CLIENTS_INFORMATION_FORM_FOREIGN_BANK_PARTNER_COUNTRY_FIELD_LABEL_TITLE:
    "Страна банка зарубежного парнтёра",
  YEISVO_CLIENTS_INFORMATION_FORM_LEGAL_ADDRESS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Юридический адрес банка иностранного партнера",
  YEISVO_CLIENTS_INFORMATION_FORM_SETTLEMENT_CURRENCY_2_FIELD_LABEL_TITLE: "Валюта расчета 2",
  YEISVO_CLIENTS_INFORMATION_FORM_DETAILS_OF_BANK_PARTNER_FIELD_LABEL_TITLE:
    "Банковские реквизиты иностранного партнёра",

  ACCOUNT_OPENING_POSITION_HELD: "Занимаемая должность",
  DELETE_BUTTON: "Удалить",

  IMPORT_FOR_ANOR: "Провести через АНОР",
  IMPORT_FOR_CBU: "Провести через Клиринг ЦБ",

  CONVERSION_MODIFIED: "Конвертация исправлена",

  LOAN_INTEREST_AMOUNT: "Сумма платежа (Проценты)",

  SALARY_CHOOSE_GENDER: "Пожалуйста выберите пол",
  SALARY_CHOOSE_BIRTHDAY_DATE: "Пожалуйста выберите Дата рождения",
  SALARY_CHOOSE_PASSPORT_REGISTRATION_DATE: "Пожалуйста выберите Дата выдачи паспорта",
  SALARY_CHOOSE_PASSPORT_EXPIRY_DATE: "Пожалуйста выберите Срок действия паспорта",

  SALARY_TOTAL_AMOUNT: "Итоговая сумма",
  SALARY_DETAILS_FOR: "за",
  SALARY_DETAILS_ACCORDING: "Реестр №",
  SALARY_PINFL_IS_REQUIRED: "Требуется ПИНФЛ",

  CONVERSION_COMMISSION_RATE_AND_AMOUNT: "Пожалуйста, проверьте ставку и сумму комиссии",

  CONVERSION_PURPOSE_ERROR: "Пожалуйста выберите цель конвертация",

  PENDING_APPROVAL_DOCUMENT_CHOOSE: "Пожалуйста выберите документ",
  PENDING_APPROVAL_JOB_CHOOSE: "Пожалуйста выберите 'Утвердить' или 'Отклонить'",

  CHANGE_PASSWORD_SENT_TO_EMAIL: "Отправить код на почту",
  CHANGE_PASSWORD_CODE_FROM_EMAIL: "Код из почты",
  OTP_KEY: "Ключ OTP",
  TRANSFER_TIME_OUTGOING_TO_SUBMIT: "Время проведения исходящих операций (Ташкентское время):",
  TRANSFER_TIME_INTERNAL_TO_SUBMIT: "Время проведения внутренних операций (Ташкентское время):",
  TRANSFER_SERVICING_TIME: "Время проведения операции (Ташкентское время):",

  SALARY_CREATE_AND_SAVE_TO_PDF: "Отправить и сохранить в PDF",

  SAVE_TO_WORD: "Сохранить в Word",

  DISABLED: "Не использовать следующие символы",

  APRROVE_INFO:
    'Если сумма валютного перевода превышает 10,000 долларов США или эквивалент, ожидайте звонка специалиста Банка для "Call Back" авторизации.',

  CONFIRM_SESSION: "Продолжить",
  CONFIRM_TEXT: "Хотите ли вы продолжить сессию?",
  EXIT_SESSION: "Закончить",

  TRANSFER_BENEFICIARY_ERROR: "Бюджетный аккаунт не найден",
  TRANSFER_STATUS_SENT_BY_BANK: "Отправлен банком",

  SYSTEM_ERROR: "Системная ошибка (попробуйте позже)",

  TRANSFER_TEXT_REQUIRED: "Пожалуйста используйте только следующие:",

  LOGIN_WRONG_CAPTCHA: "Неправильная капча",
  LOGIN_REQUIRED_CAPTCHA: "Требуется рекапча",
  LOGIN_NOT_FOUND: "Логин не найден",
  LOGIN_USER_NOT_FOUND:
    "Логин не зарегистрирован в системе Internet Bank iDBA, пожалуйста свяжитесь с Банком",

  TRANSFERS_USE_THIS_SYMBOLS: "Используется запрещённая буква или символ",

  CONVERSION_DOCUMENTS_TEXT: "Документы прилагаются",
  CONVERSION_DOCUMENTS: "Документы",
  CONVERSION_OFFER_TEXT: "Заявление о присоединении к Публичной оферте:",
  CONVERSION_OFFER:
    "Подписание настоящего Заявления означает безусловное принятие Клиентом всех условий Публичной оферты на оказание услуг по конвертации, опубликованной на сайте Банка (www.kdb.uz) на дату подписания настоящего Заявления, а также согласие Клиента на безакцептное списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка и настоящим Заявлением.",
  CONVERSION_TEXT_AUTHORIZE:
    "Настоящим мы уполномочиваем Банк заранее дебетовать наш вышеупомянутый счёт в иностранной валюте для продажи иностранной валюты",

  LOGIN_ACCOUNT_DISABLED: "Аккаунт заблокирован",
  RESET_PASSWORD_CODE_SEND: "Код был отправлен на почту:",

  STATEMENT_NO_DATA: "Нет документов",

  AMOUNT_COPY_PASTE: "Копировка - Вставка запрещена",

  FILE_CONTROLS_SAVE_TO_EXCEL1_BUTTON_TITLE: "Входящие платежи",
  FILE_CONTROLS_SAVE_TO_EXCEL2_BUTTON_TITLE: "Исходящие платежи",
  FILE_CONTROLS_SAVE_TO_EXCEL3_BUTTON_TITLE: "Входящие и исходящие платежи",
  FILE_CONTROLS_SAVE_TO_EXCEL4_BUTTON_TITLE: "Входящие и исходяшие платежи с остатком",
  FILE_CONTROLS_SAVE_TO_EXCEL5_BUTTON_TITLE: "Сохранить в Excel 1",
  FILE_CONTROLS_SAVE_TO_EXCEL_NEW_BUTTON_TITLE: "Сохранить в Excel (Новый)",

  ANALYTICS_ERROR_NO_INFO: "Нет никакой информации об аккаунте",
  ANALYTICS_PERIOD_HEADER: "Период",
  ANALYTICS_ERROR_CHOOSE: "Пожалуйста выберите аккаунт и период",

  ACCOUNT_CARD_BOX_TAB: "Картотека 2",
  ACCOUNT_SWIFT_TAB: "SWIFT",

  CARD_BOX_TITLE_DOCUMENT_NUMBER: "№ документа",
  CARD_BOX_DATA: "Дата документа",
  CARD_BOX_DATA_DISPLACEMENT: "Дата перемещения в К-2",
  CARD_BOX_AMOUNT: "Сумма Оставшаяся",
  CARD_BOX_AMOUNT_ANNOUNCED: "Сумма Обьявленная",
  CARD_BOX_MFO_RECEIVER: "МФО банка получятеля",
  CARD_BOX_ACCOUNT_NUMBER_RECEIVER: "№ счёта получателя",
  CARD_BOX_RECEIVER_NAME: "Наименование получателя",
  CARD_BOX_DETAILS: "Назначение платежа",

  USER_INFO_AND_SETTINGS_ALERT_NOTIFICATION:
    "Вы уверены, что хотите изменить настройку уведомления?",
  PENDING_APPROVAL_EXPIRED_ERROR: "Время проведения операции истекло",
  PENDING_APPROVAL_NULL_ERROR:
    "На транзитном счете недостаточно средств для исполнения платежной ведомости",

  //
  //
  // New Translates
  //
  //

  SALARY_PHONE_TYPE: "Телефон",
  SALARY_PHONE_NUMBER_TITLE: "Номер телефона для СМС информирования",
  SALARY_PASSWORD_PHONE_TITLE: "Пароль для телефонных разговоров",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_SERIES_LENGTH:
    "Для выбранной страны, максимум 2 символа на серию паспорта",
  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_LENGTH:
    "Для выбранной страны, максимум 7 символов на номер паспорта",

  TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_PASSPORT_NUMBER_ERROR:
    "Серийный номер паспорта введен неправильно",

  CONVERSION_MAX_SYMBOLS: "Максимальное количество символов в этом поле",

  TRANSFER_AMOUNT_ERROR: "Неправильный формат суммы",
  TRANSFER_PAYMENT_CODE_ERROR: "Неправильный формат Код платежа",

  PRE_APPROVAL_COUNTRY_FIELD_LABEL_TITLE: "Страны поставщика",

  FINANCIAL_BUSINESS_SIZE_SMALL: "малый",
  FINANCIAL_BUSINESS_SIZE_MEDIUM: "средний",
  FINANCIAL_BUSINESS_SIZE_LARGE: "крупный",

  FINANCIAL_FORM_TITLE_1: "Финансовый показатель в",
  FINANCIAL_FORM_TITLE_2: "году мес.",
  FINANCIAL_FORM_TITLE_3: "; Единица измерения: млн. сум",

  FINANCIAL_ALL_PASSIVE: "Всего пассивов",
  FINANCIAL_LONG_TERM: "Долгосрочные активы",

  FINANCIAL_ANNUAL_SALARY_INTEREST_RATE: "Процентная ставка по суб-кредиту",
  FINANCIAL_PRE_ORDER_NUMBER: "Номер платёжного поручения",

  INDIVIDUAL_TRANSFERS_SIDE_MENU: "Переводы",

  INDIVIDUAL_CARD_TRANSFER: "На карту",
  INDIVIDUAL_SCORE_TRANSFER: "На счёт",
  INDIVIDUAL_REQUISITES_TRANSFER: "По реквизитам",
  INDIVIDUAL_BUDGET_TRANSFER: "В бюджет",
  INDIVIDUAL_REVENUE_TRANSFER: "В бюджетный доход",
  INDIVIDUAL_MUNIS_TRANSFER: "МУНИС",
  INDIVIDUAL_TRANSFER_CODE: "Код перевода",
  INDIVIDUAL_RECEIVER_ACCOUNT: "Номер карты получатела",
  INDIVIDUAL_TRANSFER_AMOUNT: "Сумма перевода",
  INDIVIDUAL_MUNIS_GROUP: "Наименование группы",
  INDIVIDUAL_MUNIS_ORDERS: "Наименование поставщика услуг",
  INDIVIDUAL_MUNIS_PAYMENT_TYPE: "Вид оплаты",
  INDIVIDUAL_MUNIS_ORDER_NUMBER: "Номер платежа",
  INDIVIDUAL_MUNIS_FACTURA_NUMBER: "Номер счета-фактуры",

  FINANCIAL_DOCUMENT_UPDATED: "Обнавлён",

  INDIVIDUAL_CONVERSION_OTHERS_AMOUNT: "Сумма иностранной валюты",
  INDIVIDUAL_CONVERSION_AMOUNT: "Сумма национальной валюты",

  INDIVIDUAL_INQUIRY_REGISTER_AM: "Я",
  INDIVIDUAL_ACCOUNT_INQUIRY_TEXT_ACCOUNT_INFO:
    " прошу Банк предоставить следующую информацию о моём(их) счете(ах);",
  INDIVIDUAL_ACCOUNT_ADDITIONAL_STATEMENT_TEXT:
    " прошу Банк предоставить дополнительную выписку по следующему(ым) счету(ам);",
  INDIVIDUAL_VISA_CARD_NUMBER_TITLE: "Visa Карта",
  INDIVIDUAL_VISA_CARD_NUMBER_INPUT: "Номер карты",

  INDIVIDUAL_CONVERSION_SUCCESS: "Успешно проведена",
  INDIVIDUAL_ENTER_RECEIVER_ACCOUNT:
    "Пожалуйста введите аккаунт получятеля или номер карты получятеля",
  NOT_AVAILABLE_TRANSACTION_TITLE: "Эта транзакция недоступна.",
  NOT_AVAILABLE_TRANSACTION: "Введите номер карты HUMO получателя.",
  INDIVIDUAL_CONVERSION_HISTORY_RECEIVER: "Номер счёта получятеля",
  INDIVIDUAL_CONVERSION_BUY_AMOUNT: "Сумма в Иностранной Валюте",
  INDIVIDUAL_CONVERSION_SELL_AMOUNT: "Сумма в Иациональной Валюте",

  INDIVIDUAL_FAVORITE_ID_TITLE: "ID",
  INDIVIDUAL_SMS_CODE_TITLE: "СМС Код",
  INDIVIDUAL_SMS_CODE_AUTH_TITLE: "СМС Авторизация",
  INDIVIDUAL_CHOOSE_ACCOUNT: "Выберите счёт",

  INDIVIDUAL_CARD_BALANCE_TITLE: "Баланс карты",
  HUMO_EXPIRY_DATE: "Срок годности",

  DEMAND_TO_DEMAND_MESSAGE:
    "Перевод отправлен, пожалуйста, проверьте статус в течении 3 минуты в разделе История.",

  CLEARING_OPEN:
    "Уважаемый Клиент, информируем, что операционный день (клиринговая система) открыт!",
  CLEARING_NOT_OPEN:
    "Уважаемый Клиент, в связи с тем, что операционный день (клиринговая система) пока еще не открыт в нашем Банке, просим Вас отправлять платежи через систему ANOR до открытия операционного дня.",
  NOTIFICATION_MESSAGE: `
  Уважаемые Клиенты,
  В связи участившимися случаями мошенничества (несанкционированного снятия средств с банковских карт), АО «КДБ Банк Узбекистан» просит Вас быть бдительными и не передавать никому коды SMS, которые приходят на Ваш мобильный номер, а также данные по Вашей карте (срок действия, CVV), не выкладывать фото банковских карт в социальные сети. 
  Также убедительно просим Вас распространить эту информацию среди Ваших близких и коллег.
  `,
  NOTIFICATION_MESSAGE_CACHE_LIMIT: `
  Утверждены изменения в порядок обслуживания физических лиц – резидентов и нерезидентов
  
В связи с изменениями требований Комплаенс, Правлением Банка приняты следующие решения, действующих с 15 августа 2022 года.
-------------------------------------------------------------------------------------------------------------------------------------------
Для физических лиц – резидентов:
Обмен национальной валюты в иностранную валюту в обменном пункте в сумме, превышающей эквивалент 10-ти тысяч долларов США в течение календарного месяца, осуществляется после представления в Банк документов, подтверждающих получение средств из законных источников. Максимальная сумма обмена в месяц 100 тысяч долларов США или в эквиваленте другой иностранной валюты. 
Депонирование (внесение) наличных денежных средств на счет в иностранной и национальной валютах в сумме, превышающей эквивалент 50-ти тысяч долларов США в течение календарного месяца, осуществляется после представления в Банк документов, подтверждающих получение средств из законных источников. 
Безналичное зачисление денежных средств на счет в национальной валюте в сумме, превышающей 500 миллионов сум в течение календарного месяца (за исключением П2П переводов, зачисления заработной платы и приравненных к ней платежей, страховых выплат, бюджетных платежей), осуществляется после представления в Банк документов, подтверждающих получение средств из законных источников. 
Снятие наличных денежных средств с банковских и карточных счетов в иностранной валюте осуществляется в пределах суммы, не превышающей эквивалент 50-ти тысяч долларов США в течение календарного месяца.
Снятие наличных денежных средств в иностранной валюте с международных карт Visa и MasterCard, выпущенных другими банками, осуществляется в пределах суммы, не превышающей эквивалент 10-ти тысяч долларов США в течение календарного месяца.
-------------------------------------------------------------------------------------------------------------------------------------------
Для физических лиц – нерезидентов:
Обмен национальной валюты в иностранную валюту в обменном пункте в сумме, превышающей эквивалент 100 долларов США в течение календарного месяца, осуществляется после представления в Банк документов, подтверждающих получение средств из законных источников. Максимальная сумма обмена в месяц 50 тысяч долларов США или в эквиваленте другой иностранной валюты.
Депонирование (внесение) наличных денежных средств на счет в иностранной и национальной валютах в сумме, превышающей эквивалент 70-ти миллионов сум в течение календарного месяца, осуществляется после представления в Банк документов, подтверждающих получение средств из законных источников. 
Безналичное зачисление денежных средств на счет в национальной валюте (за исключением П2П переводов, зачисления заработной платы и приравненных к ней платежей, страховых выплат, бюджетных платежей) в сумме, превышающей 100 миллионов сум в течение календарного месяца осуществляется после представления в Банк документов подтверждающих получение средств из законных источников. 
Снятие наличных средств с банковских и карточных счетов в иностранной валюте осуществляется в сумме, не превышающей эквивалент 10-ти тысяч долларов США в течение календарного месяца.
Снятие наличных средств в иностранной валюте с международных карт Visa и MasterCard, выпущенных другими банками осуществляется в сумме, не превышающей эквивалент 10-ти тысяч долларов США в течение календарного месяца.

Принятие новых клиентов на обслуживание (открытие банковского счета, выпуск банковской карты) осуществляется после представления в Банк документов, подтверждающих регистрацию по месту временного проживания в Республике Узбекистан со сроком пребывания не менее 30 дней, за исключением цели пребывания как трудоустройство, учеба, лечение. 
-------------------------------------------------------------------------------------------------------------------------------------------

Прим.: Банк имеет право отказать в оказании услуг, в том числе вернуть поступившие денежные средства обратно отправителю, в случае не представления подтверждающих документов. 
  `,
  NOTIFICATION_MESSAGE_10022023: `
    Объявление: Закрытие Юнусабадского филиала АО «КДБ Банк Узбекистан»

    В целях оптимизации ресурсов, 31 января 2023 года Наблюдательным советом АО «КДБ Банк Узбекистан» (далее - Банк) принято решение о закрытии Юнусабадского филиала Банка [МФО 00831] (далее - Филиал).

    В соответствии с действующим законодательством, Банк несет ответственность по всем обязательствам Филиала, включая и обязательства перед клиентами.

    В связи с закрытием Филиала, просим всех клиентов Филиала открыть счета в Головном офисе Банка [МФО 00842].

    Для получения дополнительной информации или в случае возникновения вопросов, просим клиентов Филиала обращаться в Отдел по обслуживанию клиентов по телефонам: (+998) 781208743; 781208744; 781208709; 781206141; 781208780.

    С уважением,

    АО «КДБ Банк Узбекистан»

  `,

  NOTIFICATION_MESSAGE_21122023: `Уважаемые Клиенты!
  Просим обновить доверенности, выданные сотрудникам, уполномоченным для работы с банковскими документами в 2024 году (если срок действия предоставленных в банк доверенностей истек в 2023 году).   
  `,
  //Corporate role  Payment Claim page Tab Sent Payment Claims

  PAYMENT_CLAIM_FOMRS_TITLE: "Формы",

  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SENT_SUBTAB_TITLE: "",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SHOW_CHECKBOX_LABEL_TITLE:
    "Показать Исполнителей и Авторизаторов ",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CANCEL_COLUMN_TITLE: "Отменить платежное требование",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TO_FIELD_LABEL_TITLE: "Получатель",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_SEARCH_BUTTON_TITLE: "Поиск",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_TITLE: "ПОЖАЛУЙСТА!",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_ONLY_ONE_PAYMENT_CLAIM_TITLE:
    "Выберите только одно",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_PLEASE_CHOOSE_PAYMENT_CLAIM_TITLE:
    "Выбрать платежное требование",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DEPLOY_FILE_SUCCESSFULLY: "Excel файл загружен",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_COLUMN_TITLE: "Вид",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_TITLE: "Платежное требование",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TYPE_DOCUMENT_REFUND_TITLE: "Возврат",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_COLUMN_TITLE: "Номер платежного требования",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_DATE: "Дата создания",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_NAME_OF_THE_PAYER_TITLE: "Наименование Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE_TITLE: "Дата транзакции",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_TRANSACTION_DATE: "Дата",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_ACCOUNT_TITLE: "Счет Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_TAX_ID_TITLE: "ИНН Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYERS_BANK_NAME_TITLE: "Наименование Банка Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_MFO_TITLE: "МФО Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PURPOSE_CODE_TITLE: "Код назначения",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_PURPOSE_TITLE: "Назначение платежа",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_PAYMENT_CLAIM_COLUMN_TITLE: "Номер платежного требования",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_STATUS_COLUMN_TITLE: "Статус",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Наименование получателя",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_NAME_COLUMN_TITLE:
    "Наименование банка Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_BANK_CODE_COLUMN_TITLE: "Код Банка Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_DATE_FIELD_LABEL_TITLE: "От",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATOR_COLUMN_TITLE: "Создано",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_NUMBER_COLUMN_TITLE: "Номер счета получателя",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE:
    "Код банка получателя",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_ACCOUNT_NUMBER_COLUMN_TITLE:
    "Номер счета Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_APPROVED_COLUMN_TITLE: "Авторизовано",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_FILTER_FIELD_LABEL_TITLE: "Фильтр по статусу",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_SENDER_NAME_COLUMN_TITLE: "Наименование Плательщика",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_REQUEST_COLUMN_TITLE: "Запрос статуса",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREATED_COLUMN_TITLE: "Дата создания",
  PAYMENT_CLAIM_SENT_PAYMENT_CLAIMS_TABLE_CREDITOR_ID_TITLE: "ID Кредитора",

  PAYMENT_CLAIM_NOTIFICATIONS_TAB: "Уведомления",

  //Corporate role Payment Claim page For Form

  PAYMENT_CLAIM_FORM_PAYER_TITLE: "Плательщик",
  PAYMENT_CLAIM_FORM_DOC_NUMBER: "Номер Документа",
  PAYMENT_CLAIM_FORM_DOC_DATE: "Дата",
  PAYMENT_CLAIM_FORM_RECEIVER_ID: "ID Плательщика",
  PAYMENT_CLAIM_FORM_PAYER_ACCOUNT: "Счет Плательщика",
  PAYMENT_CLAIM_FORM_PAYER_NAME: "Наименование Плательщика",
  PAYMENT_CLAIM_FORM_PAYER_BANK_CODE: "Код Банка Плательщика",
  PAYMENT_CLAIM_FORM_PAYER_TAX_ID: "ИНН Плательщика",
  PAYMENT_CLAIM_FORM_PAYER_BANK_NAME: "Наименование Банка Плательщика",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_NAME: "Наименование Банка Получателя",
  PAYMENT_CLAIM_FORM_PURPOSE_CODE: "Код назначения",
  PAYMENT_CLAIM_FORM_PAYMENT_PURPOSE: "Назначение Документа",
  PAYMENT_CLAIM_FORM_PAYMENT_TYPE: "Тип Документа",
  PAYMENT_CLAIM_FORM_PAYMENT_CODE: "Код Документа",
  PAYMENT_CLAIM_FORM_PAYEE_ACCOUNT: "Счет получателя",
  PAYMENT_CLAIM_FORM_PAYEE_NAME: "Наименование Получателя",
  PAYMENT_CLAIM_FORM_PAYEE_BANK_CODE: "Код Банка Получателя",
  PAYMENT_CLAIM_FORM_PAYEE_TAX_ID: "ИНН получателя",
  PAYMENT_CLAIM_FORM_FIRST_CARD_ID: "ID Картотеки-1",
  PAYMENT_CLAIM_FORM_FIRST_CARD_DATE: "Дата Картотеки-1",
  PAYMENT_CLAIM_FORM_FIRST_CARD_AMOUNT: "Сумма Картотеки-1",
  PAYMENT_CLAIM_FORM_FIRST_CARD_REMAINING_AMOUNT: "Баланс Картотеки-1",
  PAYMENT_CLAIM_FORM_SECOND_CARD_ID: "ID Картотеки-2",
  PAYMENT_CLAIM_FORM_SECOND_CARD_DATE: "Дата Картотеки-2",
  PAYMENT_CLAIM_FORM_SECOND_CARD_AMOUNT: "Сумма Картотеки-2",
  PAYMENT_CLAIM_FORM_SECOND_CARD_REMAINING_AMOUNT: "Баланс Картотеки-2",
  PAYMENT_CLAIM_FORM_SECOND_CARD_TITLE: "Информация Картотека-2",
  PAYMENT_CLAIM_FORM_FIRST_CARD_TITLE: "Информация Картотека-1",
  PAYMENT_CLAIM_FORM_PAY_AMOUNT: "Сумма оплаты",
  PAYMENT_CLAIM_FORM_PAY_DATE: "Дата оплаты",
  PAYMENT_CLAIM_FORM_PAY_ID: "ID оплаты",
  PAYMENT_CLAIM_FORM_IGNORING_MASSAGE: "Причина Отклонения",
  PAYMENT_CLAIM_FORM_IGNORING_AMOUNT: "Отклоненная Сумма",
  PAYMENT_CLAIM_FORM_IGNORING_TITLE: "Отозвать",
  PAYMENT_CLAIM_FORM_PAY_TITLE: "Информация об Оплате",
  PAYMENT_CLAIM_FORM_MASSAGE: "Результат Приема Документа",
  PAYMENT_CLAIM_FORM_AMOUNT: "Сумма Требования",
  PAYMENT_CLAIM_FORM_PAYMENT_CONFIRM: "Одобрить",
  PAYMENT_CLAIM_FORM_PAYMENT_IS_TRANSFER: "Статус Документа",
  PAYMENT_CLAIM_FORM_PAYMENT_BANK_MAIL_ID: "ID БанкМейл",
  PAYMENT_CLAIM_FORM_SENDER_ID: "ID Получателя",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_SENT: "Отправлено",
  PAYMENT_CLAIM_FORM_PAYMENT_ENTER_REASON_API_TITLE: "Назначение ввода",
  PAYMENT_CLAIM_FORM_IS_TRANSFER_WAS_NOT_SENT: "Не отправлено",
  PAYMENT_CLAIM_FORM_PAYMENT_REFUNDED_TITLE: "Отозвано",
  PAYMENT_CLAIM_FORM_REASON: "Причина",
  // PAYMENT_CLAIM_RECALL: "Отозвать",
  PAYMENT_CLAIM_FORM_AMOUNT_IN_WORD_TITLE: "Сумма прописью",
  PAYMENT_CLAIM_FORM_AMOUNT_TITLE: "Сумма",
  PAYMENT_CLAIM_FORM_PAYEE_TITLE: "Получатель",
  PAYMENT_CLAIM_FORM_CURRENT_BALANCE: "Текущий баланс",

  // Collection Orders

  COLLECTION_ORDERS_TAB_PANE_TITLE: "Инкассовое поручение из Налоговой",
  COLLECTION_ORDERS_TAB_TITLE: "Инкассовое поручение из Налоговой",

  COLLECTION_ORDERS_FROM_MIB_TAB_PANE_TITLE: "Инкассовое поручение из БПИ",
  COLLECTION_ORDERS_FROM_MIB_TAB_TITLE: "Инкассовое поручение из БПИ",

  COLLECTION_ORDERS_TABLE_TAX_ID_COLUMN_TITLE:
    "Уникальный системный номер инкассового поручения в ИС НК",
  COLLECTION_ORDERS_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Номер документа",
  COLLECTION_ORDERS_TABLE_DOCUMENT_DATE_COLUMN_TITLE: "Дата документа",
  COLLECTION_ORDERS_TABLE_BRANCH_A_COLUMN_TITLE: "Код филиала банка задолжника",
  COLLECTION_ORDERS_TABLE_BRANCH_B_COLUMN_TITLE: "Код филиала банка получателя",
  COLLECTION_ORDERS_TABLE_ACCOUNT_A_COLUMN_TITLE: "Банковский счет задолжника",
  COLLECTION_ORDERS_TABLE_ACCOUNT_B_COLUMN_TITLE: "Банковский счет получателя",
  COLLECTION_ORDERS_TABLE_INN_A_COLUMN_TITLE: "ИИН задолжника",
  COLLECTION_ORDERS_TABLE_INN_B_COLUMN_TITLE: "ИИН получателя",
  COLLECTION_ORDERS_TABLE_PINFL_A_COLUMN_TITLE: "ПИНФЛ задолжника",
  COLLECTION_ORDERS_TABLE_SUMMA_COLUMN_TITLE: "Сумма документа",
  COLLECTION_ORDERS_TABLE_PAYMENT_TYPE_COLUMN_TITLE: "Вид задолженности",
  COLLECTION_ORDERS_TABLE_UNPAID_COLUMN_TITLE:
    "Код назначения плотежа по неоплаченному в срок платежному документу",
  COLLECTION_ORDERS_TABLE_NONPAYMENT_COLUMN_TITLE: "Шифр неплатежа",
  COLLECTION_ORDERS_TABLE_BOARD_COLUMN_TITLE: "Код управления получателя",
  COLLECTION_ORDERS_TABLE_OKED_COLUMN_TITLE: "Код вида экономической деятельности получателя",
  COLLECTION_ORDERS_TABLE_NAME_A_COLUMN_TITLE: "Наименование Плательщика",
  COLLECTION_ORDERS_TABLE_NAME_B_COLUMN_TITLE: "Наименование Получателя",
  COLLECTION_ORDERS_TABLE_PURPOSE_CODE_COLUMN_TITLE: "Код назначения платежа",
  COLLECTION_ORDERS_TABLE_BUDGET_INCOME_COLUMN_TITLE: "Казначейский лицевой счет по доходам",
  COLLECTION_ORDERS_TABLE_BUDGET_ACCOUNT_COLUMN_TITLE: "Лицевой счет бюджетополучателя",
  COLLECTION_ORDERS_TABLE_BUDGET_INN_COLUMN_TITLE: "ИНН бюджетополучателя",
  COLLECTION_ORDERS_TABLE_PURPOSE_COLUMN_TITLE: "Назначение платежа",

  // Transfers Import Excel files

  TRANSFERS_EXCEL_FILE_TABLE_DATE_COLUMN_TITLE: "Дата",
  TRANSFERS_EXCEL_FILE_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE: "Номер документа",
  TRANSFERS_EXCEL_FILE_TABLE_SENDER_ACCOUNT_COLUMN_TITLE: "Счет плательщика",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_NAME_COLUMN_TITLE: "Получатель",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE: "Счет получателя",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_TIN_COLUMN_TITLE: "ИНН получателя",
  TRANSFERS_EXCEL_FILE_TABLE_DETAILS_OF_PAYMENT_COLUMN_TITLE: "Содержание",
  TRANSFERS_EXCEL_FILE_TABLE_AMOUNT_COLUMN_TITLE: "Сумма",
  TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE: "МФО",
  TRANSFERS_EXCEL_FILE_TABLE_STATUS_COLUMN_TITLE: "Статус",
  TRANSFERS_EXCEL_FILE_TABLE_IMPORTED_COLUMN_TITLE: "Импортировано",
  TRANSFERS_EXCEL_FILE_TABLE_SUCCESS_COLUMN_TITLE: "Успешно",
 
};
