import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { PaymentCodeProps } from "../../api/reference-book/ReferenceBookDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly loading: boolean;
  readonly data: PaymentCodeProps[];
  readonly onPaymentCodeSelect: (value) => void;
}

export function PaymentCodeTable({ data = [], loading, onPaymentCodeSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<PaymentCodeProps>[] = [
      {
        width: 50,
        accessor: "code",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_CODE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <a
              className="font-weight-bold"
              style={{ cursor: "pointer" }}
              onClick={() => onPaymentCodeSelect && onPaymentCodeSelect(row.value)}
            >
              {row.value}
            </a>
          );
        },
      },
      {
        width: 200,
        accessor: "name",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_DESCRIPTION_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [onPaymentCodeSelect, translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
