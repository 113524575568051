import cx from "classnames";
import React, { useMemo } from "react";

import UzCard from "./assets/uzcard.jpg";
import Visa from "./assets/visa.jpg";
import Humo from "./assets/humo.jpg";
import Demand from "./assets/demand.jpg";
import Wallet from "./assets/wallet.jpg";
import Master from "./assets/master.jpg";
import { formatCardNumber, formatCurrencyNumber } from "../../utils/FormatUtils";

export interface CardDropdownItemProps {
  readonly item?: any;
  readonly className?: string;
  readonly placeholder?: string;
}

export enum CardTypes {
  UzCard = "1",
  Humo = "2",
  Demand = "3",
  Visa = "4",
  Master = "6",
  Wallet = "7",
}

export function CardDropdownItem({ item, placeholder, className }: CardDropdownItemProps) {
  const cardText = useMemo(
    () =>
      item?.accountType === CardTypes.Visa
        ? `${item?.visaCardNumber.substr(0, 4)} ${item?.visaCardNumber.substr(
            4,
            4,
          )} ${item?.visaCardNumber.substr(8, 4)} ${item?.visaCardNumber.substr(12, 4)}`
        : formatCardNumber(
            item?.accountType === CardTypes.UzCard
              ? item?.uzcardNumber
              : item?.accountType === CardTypes.Humo
              ? item?.humoCardNumber
              : item?.accounts,
          ),
    [item],
  );

  const sumText = useMemo(() => {
    if (!item) {
      return;
    }

    const data = formatCurrencyNumber(
      item.accountType === CardTypes.Demand || item.accountType === CardTypes.Visa
        ? item.accountBalance
        : item.localCardBalance,
      2,
    );
    const currency = item.currency;

    return `(${data} ${currency})`;
  }, [item]);

  if (!item) {
    return (
      <div className="d-flex flex-1 align-items-center pl-3">
        <span className="text-blue-gray-dark fs-5">{placeholder}</span>
      </div>
    );
  }

  return (
    <div className={cx("d-flex flex-1 overflow-hidden", className)} style={{ height: "70px" }}>
      <img
        alt="card"
        width={90}
        height={60}
        src={
          item.accountType === CardTypes.UzCard
            ? UzCard
            : item.accountType === CardTypes.Humo
            ? Humo
            : item.accountType === CardTypes.Visa
            ? Visa
            : item.accountType === CardTypes.Demand
            ? Demand
            : item.accountType === CardTypes.Master
            ? Master
            : Wallet
        }
        className="m-1"
      />
      <div className="d-flex flex-column justify-content-center overflow-hidden">
        <span className="text-gray font-weight-bold text-ellipsis card-text">{cardText}</span>
        <span className="text-gray text-ellipsis card-text">{sumText}</span>
      </div>
    </div>
  );
}
