import React, { useCallback, useMemo } from "react";
import { useResource } from "../../hooks/useResource";
import { useTradeFinanceContext } from "../../api/trade-finance/TradeFinanceContext";
import { showError } from "../../utils/NotificationUtils";
import { TradeFinanceAmandmentTable } from "./TradeFinanceAmandmentTable";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { AccountsFilter } from "../../filters/AccountsFilter";

interface Props {
  readonly filter: AccountsFilter;
  readonly setDocumentsId: (value, appType) => void;
}

export function TradeFinanceAmandmentWrapper({ filter, setDocumentsId }: Props) {
  const { TradeFinanceApi } = useTradeFinanceContext();

  const getApprovalAmendment = useResource(
    () =>
      TradeFinanceApi.getApprovalApplicationAmendment({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
        type: "02",
      }).catch(showError),
    [filter],
  );
  const data = useMemo(() => getApprovalAmendment.data || [], [getApprovalAmendment.data]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.Amendment);
    },
    [data, setDocumentsId],
  );

  return (
    <TradeFinanceAmandmentTable
      onSelect={setDocumentId}
      data={data}
      loading={getApprovalAmendment.loading}
    />
  );
}
