import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { useI18n } from "../../i18n/I18nContext";
import { ConversionProps } from "../../api/conversion/ConversionDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { ConversionTypePicker } from "../conversion/ConversionTypePicker";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly loading: boolean;
  readonly data: ConversionProps[];
  readonly setDocumentId: (value) => void;
  readonly chooseTransferForView: (value) => void;
}

export function ConversionTable({
  data = [],
  loading,
  setDocumentId,
  chooseTransferForView,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<ConversionProps>[] = [
      {
        width: 95,
        accessor: "transactionDate",
        Header: translate("PENDING_APPROVALS_SALARY_TABLE_TRANSACTION_DATE_COLUMN_TITLE"),
      },
      {
        width: 95,
        accessor: "documentId",
        Header: translate("PENDING_APPROVALS_CONVERSION_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a
            className="cursor-pointer"
            onClick={() => chooseTransferForView(row.cell.row.original)}
          >
            {row.value}
          </a>
        ),
      },
      {
        width: 100,
        accessor: "transactionType",
        Header: translate("PENDING_APPROVALS_CONVERSION_TYPE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <ConversionTypePicker status={row.value} />,
      },
      {
        width: 180,
        accessor: "debtAccount",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 180,
        accessor: "creditAccount",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_CREDIT_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 140,
        accessor: "sellAmount",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : "0.00"}
          </div>
        ),
      },
      {
        width: 90,
        accessor: "sellCurrency",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_SELLING_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 140,
        accessor: "buyAmount",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : "0.00"}
          </div>
        ),
      },
      {
        width: 90,
        accessor: "buyCurrency",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_BUYING_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 140,
        accessor: "exchangeRate",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_EXCHANGE_RATE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <span>
            {row.value ? `${floatFormat(row.value.split(".")[0])}.${row.value.split(".")[1]}` : ""}
          </span>
        ),
      },
      {
        width: 140,
        accessor: "minimumRate",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_MINIMUM_RATE_CODE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : ""}
          </div>
        ),
      },
      {
        width: 140,
        accessor: "maximumRate",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_MAXIMUM_RATE_CODE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div className="text-center">
            {row.value !== null ? floatFormat(addZeros(row.value)) : ""}
          </div>
        ),
      },
      {
        width: 130,
        accessor: "state",
        Header: translate("PENDING_APPROVALS_TRANSFERS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];

    return list;
  }, [translate]);

  return (
    <TabPage className="px-4">
      <Table
        data={data}
        columns={columns}
        onSelect={setDocumentId}
        loading={loading}
        withCheckbox={true}
      />
    </TabPage>
  );
}
