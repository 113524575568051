import "./assets/salary-create-new-register-table-form.scss";

import { toFinite } from "lodash";
import { Form, Formik, FormikProps } from "formik";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { noop } from "lodash";

import { CreateNewDocumentRoles, Dict, SizeType, ViewMode } from "../../api/dto/AppDTO";
import { SelectPicker } from "../ui/SelectPicker";
import { Button, ButtonColor } from "../ui/Button";
import { CreateNewRegisterTableRow } from "./CreateNewRegisterTableRow";
import { CreateNewRegisterTableHeader } from "./CreateNewRegisterTableHeader";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { TransferType } from "../../api/transfer/TransferDTO";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userEmployeeIdSelector } from "../../reducers/userReducer";
import { useResource } from "../../hooks/useResource";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { useI18n } from "../../i18n/I18nContext";
import { InputField } from "../form/InputField";
import { viewModeSelector } from "../../reducers/appReducer";
import { createNewDocumentRole } from "../../reducers/authReducer";
import { showError } from "../../utils/NotificationUtils";
import { SalaryListModal } from "./SalaryCardListOfCardHoldersTableModal";
import { Modal } from "../ui/Modal";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { useAccountContext } from "../../api/account/AccountContext";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { SelectPickerField } from "../form/SelectPickerField";
import { Paginator } from "../ui/Paginator";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

import NotificationSalaryLoader from "../ui/NotificationSalaryLoader";

interface Props {
  readonly initialValues: any;
  readonly transitAccount: any;
  readonly noTransitAccount: () => void;
  readonly transferType: TransferType;
  readonly count: { label: string; value: string };
  readonly setCount: (value) => void;
  readonly cardType?: string;
  readonly filter: TransfersFilter;
  readonly setSelectIds?: (ids: Dict<boolean>) => void;
  readonly chooseCardHolders?: (value) => void;
  readonly openModal?: boolean;
  readonly setOpenModal?: (value) => void;
  readonly onChangeCardType?: (value) => void;
  readonly backDebitLocation: () => void;
  readonly backTransitLocation: () => void;
}

export function SalaryCreateNewRegisterTableForm({
  initialValues,
  transitAccount,
  noTransitAccount,
  transferType,
  count,
  cardType = "",
  setCount,
  filter,
  onChangeCardType,
  setSelectIds,
  chooseCardHolders,
  openModal = false,
  setOpenModal = () => [],
  backDebitLocation,
  backTransitLocation
}: Props) {
  const { translate } = useI18n();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [disableSalaryCheckBtn, setDisableSalaryCheckBtn] = useState<boolean>(false);
  const [disableSalarySubmitBtn, setDisableSalarySubmitBtn] = useState<boolean>(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const ref = useRef<FormikProps<any>>(null);
  const { TransferApi } = useTransferContext();
  if (transitAccount.transitAccount === "") {
    noTransitAccount();
  }
  const employedId = useShallowEqualSelector(userEmployeeIdSelector);
  const [state, setState] = useState(true);
  const [notMatched, setNotMatched] = useState(true);
  const [payrollId, setPayrollId] = useState("");
  const { SalaryApi } = useSalaryContext();
  const { AccountApi } = useAccountContext();
  const { CorporateCardsApi } = useCorporateCardsContext();
  const dispatch = useDispatch();

  useResource(
    () =>
      TransferApi.getPayroll({ transitAccount: transitAccount.transitAccount.label }).then((r) =>
        setPayrollId(r),
      ),
    [],
  );
  const onCheck = useCallback(
    async (value) => {
      
      setLoadingModal(true);
      setDisableSalaryCheckBtn(true);
      
      let completedCount = 0;
      let notMatchStatus = 0;
      try {
        const listOf = [];
        
        for (let i = 0; i < value.length; i++) {
          if (value[i].accountNumber !== "" || value[i].fullName !== "" || value[i].amount !== "") {
          // @ts-ignore
            listOf.push(value[i]);
          } else {
            setState(true);
          }
        }
     
        // @ts-ignore
        const checkAmount = listOf.filter((item) => item?.amount === "")
        
        // @ts-ignore
        const allHaveAmountKey = listOf.every(item => item.hasOwnProperty('amount'));
      
        // @ts-ignore
        const containsComma = listOf.some(item => item?.amount?.includes(","));
        if (containsComma) {
          NotificationManager.error(translate("TRANSFERS_SALARY_LIST_OF_CARD_CHECK_ERROR"));
          return;
        }
      
        // @ts-ignore
        const amounts = listOf.map(x => toFinite(removeGaps(x.amount)));
        const arraySize = 50;
        const sum = amounts.reduce((acc, curr) => acc + curr, 0);
        setTotalAmount(sum);
  
      
        const slicedArray = [];
        for (let i = 0; i < listOf.length; i += arraySize) {
          // @ts-ignore
          slicedArray.push(listOf.slice(i, i + arraySize));
        }
        
        for (const chunk of slicedArray) {
        // @ts-ignore
          const listBy = chunk.filter(item => item.id !== "");
          const accountsList = {
            accounts: listBy
              .map(item => item.id !== "" && {
                accountNumber: item.accountNumber,
                accountName: item.fullName,
              })
              .filter(item => item && item.accountName),
            employerId: employedId,
            type: transferType,
            rowsCount: listBy.length,
            transitAccount: transitAccount.transitAccount.label,
          };
          
       
          if (accountsList.accounts.length > 0) {
            try {
             
              const response = await TransferApi.checkRegister({ json: accountsList });
              
             
              response.forEach(r => {
                const selected = initialValues.data.find(t => r.accountNumber === t.accountNumber);
                if (selected) {
                  const indexOf = initialValues.data.indexOf(selected);
                  initialValues.data[indexOf] = {
                    ...initialValues.data[indexOf],
                    systemNumber: r.accountNumber,
                    systemName: r.accountName,
                    status: r.checkFlag,
                    cardStatus: r.cardStatus,
                  };
                }
              })
  
            
              setTimeout(() => setState(true), 1000);
  
             
              const notMatched = response.filter(x => ["01", "02"].includes(x.checkFlag));
              const notMatchedCardStatus = response.filter(x => ["Closed", "Suspended"].includes(x.cardStatus));
              
              setNotMatched(notMatched.length > 0 && notMatchedCardStatus.length > 0);
              
              if (notMatched.length > 0 || notMatchedCardStatus.length > 0){
                notMatchStatus++;
              }
              completedCount++;
              if (completedCount === slicedArray.length) {
                setLoadingModal(false);
                setDisableSalaryCheckBtn(false);
              }

              if(notMatchStatus === 0 && checkAmount.length === 0 && allHaveAmountKey === true){
                setDisableSalarySubmitBtn(false);
              }
              
            } catch (error) {
              
              setState(true);
              setDisableSalarySubmitBtn(true);
              setLoadingModal(false);
              setDisableSalaryCheckBtn(false);
            } 
          } else {
            setDisableSalaryCheckBtn(false);
            setState(true);
            setLoadingModal(false);
          }
        }
      } catch (error) {
        setDisableSalaryCheckBtn(false);
        setState(true);
        setLoadingModal(false);
      }
    },
    [
      TransferApi,
      employedId,
      initialValues,
      transferType,
      translate,
      transitAccount.transitAccount,
    ] 
  );

 
  
  // old version of checking the accounts
  // const onCheck = useCallback(
  //   (value) => {
  //     const listOf = [];
  //     for (let i = 0; i < value.length; i++) {
  //       if (value[i].accountNumber !== "" || value[i].fullName !== "" || value[i].amount !== "") {
  //         // @ts-ignore
  //         listOf.push(value[i]);
  //       } else {
  //         i = value.length;
  //         setState(true);
  //       }
  //     }

  //     let isTrue = 0;

  //     for (let i = 0; i < listOf.length; i++) {
  //       // @ts-ignore
  //       if (listOf[i]?.amount?.includes(",")) {
  //         isTrue++;
  //       }
  //     }

  //     if (isTrue === 0) {
  //       // @ts-ignore
  //       const amounts = [listOf.map((x) => toFinite(removeGaps(x.amount)))];
  //       const array_size = 100;
  //       let sum = 0;
  //       if (amounts[0].length > 0) {
  //         for (let i = 0; i < amounts[0].length; i++) {
  //           // @ts-ignore
  //           sum += amounts[0][i];
  //         }
  //       }
  //       setTotalAmount(sum);
  //       const sliced_array = [];

  //       for (let i = 0; i < listOf.length; i += array_size) {
  //         // @ts-ignore
  //         sliced_array.push(listOf.slice(i, i + array_size));
  //       }
  //       if (sliced_array.length > 0) {
  //         sliced_array.map((x) => {
  //           // @ts-ignore
  //           const listBy = x.filter((x) => x.id !== "");
  //           const accountsList = {
  //             accounts: [],
  //             employerId: employedId,
  //             type: transferType,
  //             rowsCount: listBy.length,
  //             transitAccount: transitAccount.transitAccount.label,
  //           };
  //           accountsList.accounts = listBy
  //             .map(
  //               (x) =>
  //                 x.id !== "" && {
  //                   accountNumber: x.accountNumber,
  //                   accountName: x.fullName,
  //                 },
  //             )
  //             .filter((item) => item.accountName);
              
  //           if (accountsList.accounts.length !== 0) {
  //             TransferApi.checkRegister({ json: accountsList })
  //               .then((r) => {
  //                 r.map((x) => {
  //                   const selected = initialValues.data.filter(
  //                     (t) => x.accountNumber === t.accountNumber,
  //                   );
  //                   if (selected.length > 0) {
  //                     const indexOf = initialValues.data.indexOf(selected[0]);
  //                     initialValues.data[indexOf].systemNumber = x.accountNumber;
  //                     initialValues.data[indexOf].systemName = x.accountName;
  //                     initialValues.data[indexOf].status = x.checkFlag;
  //                     initialValues.data[indexOf].cardStatus = x.cardStatus;
  //                   }

  //                 });
  //                 setTimeout(() => setState(true), 1000);
  //                 const notMatched = r.filter((x) => ["01", "02"].includes(x.checkFlag));
  //                 const notMatchedCardStatus = r.filter((x) => ["Closed", "Suspended"].includes(x.cardStatus));
                 
  //                 if (notMatched.length > 0 && notMatchedCardStatus.length > 0) {
  //                   setNotMatched(true);
  //                 } else {
  //                   setNotMatched(false);
  //                 }
  //               })
  //               .catch(() => {
  //                 setState(true);
  //               });
  //           } else {
  //             setState(true);
  //           }
  //         });
  //       } else {
  //         setState(true);
  //       }
  //     } else {
  //       NotificationManager.error(translate("TRANSFERS_SALARY_LIST_OF_CARD_CHECK_ERROR"));
  //     }
  //   },
  //   [
  //     TransferApi,
  //     employedId,
  //     initialValues,
  //     transferType,
  //     translate,
  //     transitAccount.transitAccount,
  //   ],
  // );
  
  const [searched, setSearched] = useState("");
  const cardHoldersListSearch = useCallback((data, term) => {
    if (term !== "") {
      return (
        data &&
        data.filter(
          (item) =>
            `${item.accountNumber.toUpperCase()} ${item.employerName.toUpperCase()}`.indexOf(
              term.toUpperCase(),
            ) > -1,
        )
      );
    } else return data;
  }, []);

  const salaryListOfCardHolders = useResource(
    () =>
      transferType === TransferType.Salary
        ? SalaryApi.getSalaryCardHolders({ cardType: cardType, ...filter.getCardHoldersFilter() })
        : transferType === TransferType.CorporateCard
        ? CorporateCardsApi.getSalaryCardHolders({
            cardType: cardType,
            ...filter.getCardHoldersFilter(),
          })
        : [],
    [cardType, filter],
  );

  useResourceHandler(salaryListOfCardHolders, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPages?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRows?.toString() }));
    },
  });
  const data = useMemo(() => salaryListOfCardHolders.data || [], [salaryListOfCardHolders.data]);
  const paymentCodeListAfterSearch = useMemo(() => cardHoldersListSearch(data, searched), [
    cardHoldersListSearch,
    data,
    searched,
  ]);
  
  const onSubmit = useCallback(async () => {
    
    if(transitAccount?.transitAccount?.label?.length > 2 && transitAccount?.debitAccount?.label?.length > 2){
    
      setLoadingModal(true);
      setDisableSalaryCheckBtn(true);
      setDisableSalarySubmitBtn(true);
      let completedCount = 0;

      try{
        const list = initialValues.data.filter(
          (x) => x.amount !== "" && x.accountNumber !== "" && x.fullName !== "" && x.status === "03" && x.cardStatus === "Active",
        );
        const array_size = 100;
        const sliced_array = [];

        for (let i = 0; i < list.length; i += array_size) {
          // @ts-ignore
          sliced_array.push(list.slice(i, i + array_size));
        }

        for (const chunk of sliced_array) {
          // @ts-ignore
            const listBy = chunk.filter(item => item.id !== "");
           
            const accountsList = {
              accounts: listBy
                .map(item => item.id !== "" && {
                  accountName: item.fullName,
                  accountNumber: item.accountNumber,
                  amount: removeGaps(item.amount),
                })
                .filter(item => item && item.accountName),
              corporateCard: transitAccount.debitAccount.label,
              payrollDetail:
                transferType === "SL"
                  ? `${transitAccount?.code} ${transitAccount?.detailsType?.label} ${translate(
                      "SALARY_DETAILS_FOR",
                    )} ${transitAccount?.month} ${translate("SALARY_DETAILS_ACCORDING")}${
                      transitAccount?.number
                    }`
                  : `${transitAccount?.code} ${transitAccount.detailsType.label} No${transitAccount?.number}`,
              payrollId: payrollId,
              rowsCount: listBy.length,
              transitAccountNumber: transitAccount.transitAccount.label
            };
            
            if (accountsList.accounts.length > 0) {
              try {
                await AccountApi.createDocumentWithDebitAccount({ json: accountsList });
              } catch (error) {
                // showError(error);
              } finally {
                completedCount++;
                if (completedCount === sliced_array.length) {
                  setLoadingModal(false);
                  setDisableSalaryCheckBtn(false);
                  NotificationManager.info(
                    translate("SALARY_CREATED_INFO"),
                    translate("SALARY_CREATED_INFO_TITLE"),
                    7000,
                  );
                  backDebitLocation();
                }
              }
            }else {
              backDebitLocation();
            }
          }

      }catch(err){
        // showError(err);
      }
          
    }else {
      
      setLoading(true);

      setLoadingModal(true);
      setDisableSalaryCheckBtn(true);
      setDisableSalarySubmitBtn(true);
      
      const list = initialValues.data.filter(
        (x) => x.amount !== "" && x.accountNumber !== "" && x.fullName !== "" && x.status === "03",
      );
      const array_size = 100;

      const sliced_array = [];

      for (let i = 0; i < list.length; i += array_size) {
        // @ts-ignore
        sliced_array.push(list.slice(i, i + array_size));
      }
      if (sliced_array.length > 0) {
        // @ts-ignore
        const listBy = sliced_array[0].filter((x) => x.id !== "");
        const accountsList = {
          payrollDetail:
            transferType === "SL"
              ? `${transitAccount?.code} ${transitAccount?.detailsType?.label} ${translate(
                  "SALARY_DETAILS_FOR",
                )} ${transitAccount?.month} ${translate("SALARY_DETAILS_ACCORDING")}${
                  transitAccount?.number
                }`
              : `${transitAccount?.code} ${transitAccount?.detailsType} No${transitAccount?.number}`,
          accounts: [],
          rowsCount: listBy.length,
          transitAccount: transitAccount.transitAccount.label,
        };
        let oneTimeCheck = true;
        accountsList.accounts = listBy.map((x) => {
          if (x.id !== "" && x.amount && parseFloat(x.amount) > 0) {
            return {
              accountNumber: x.accountNumber,
              accountName: x.fullName,
              amount: removeGaps(x.amount),
            };
          } else {
            if (oneTimeCheck) {
              setNotMatched(true);
              NotificationManager.info(
                translate("TRANSFERS_SALARY_LIST_OF_CARD_CHECK_AMOUNT_ERROR"),
                translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_AMOUNT_COLUMN_TITLE"),
                7000,
              );
              oneTimeCheck = false;
            }
            return null;
          }
        });

        if (accountsList.accounts.length !== 0) {
          TransferApi.createRegister({ payrollId, json: accountsList })
            .then(() => {
              sliced_array.shift();
              if (sliced_array.length > 0) {
                sliced_array.map((x, idx) => {
                  // @ts-ignore
                  const listBy = x.filter((x) => x.id !== "");
                  const accountsList = {
                    payrollDetail:
                      transferType === "SL"
                        ? `${transitAccount?.code} ${transitAccount?.detailsType?.label} ${translate(
                            "SALARY_DETAILS_FOR",
                          )} ${transitAccount?.month} ${translate("SALARY_DETAILS_ACCORDING")}${
                            transitAccount?.number
                          }`
                        : `${transitAccount?.code} ${transitAccount?.detailsType} No${transitAccount?.number}`,
                    accounts: [],
                    rowsCount: listBy.length,
                    transitAccount: transitAccount.transitAccount.label,
                  };
                  accountsList.accounts = listBy.map(
                    (x) =>
                      x.id !== "" && {
                        accountNumber: x.accountNumber,
                        accountName: x.fullName,
                        amount: removeGaps(x.amount),
                      },
                  );
                  if (accountsList.accounts.length !== 0) {
                    TransferApi.createRegister({ payrollId, json: accountsList })
                      .then(() => {
                        setLoadingModal(false);
                        NotificationManager.info(
                          translate("SALARY_CREATED_INFO"),
                          translate("SALARY_CREATED_INFO_TITLE"),
                          7000,
                        );
                        noTransitAccount();
                        // if (idx === sliced_array.length) {
                        //   // noTransitAccount();
                        //   setLoading(false);
                        //   setLoadingModal(false);
                        //   NotificationManager.info(
                        //     translate("SALARY_CREATED_INFO"),
                        //     translate("SALARY_CREATED_INFO_TITLE"),
                        //     7000,
                        //   );
                        // }
                      })
                      .catch((err) => {
                        showError(err);
                        if (idx === sliced_array.length) {
                          setLoading(false);
                          setLoadingModal(false);
                        }
                      });
                  } else {
                    setLoading(false);
                    NotificationManager.info(
                      translate("SALARY_CREATED_INFO"),
                      translate("SALARY_CREATED_INFO_TITLE"),
                      7000,
                    );
                    noTransitAccount();
                  }
                });
              } else {
                setLoading(false);
                setLoadingModal(false);
                NotificationManager.info(
                  translate("SALARY_CREATED_INFO"),
                  translate("SALARY_CREATED_INFO_TITLE"),
                  7000,
                );
                noTransitAccount();
              }
            })
            .catch((err) => {
              showError(err);
              setLoading(false);
              setLoadingModal(false);
            });
        } else setLoadingModal(false);
      } else setLoadingModal(false);
    }
    
  }, [
    TransferApi,
    initialValues.data,
    noTransitAccount,
    payrollId,
    transferType,
    transitAccount?.code,
    transitAccount?.detailsType,
    transitAccount?.month,
    transitAccount?.number,
    transitAccount.transitAccount.label,
    translate,
    backDebitLocation,
    backTransitLocation
  ]);

  const submitHandler = useCallback(
    ({ data }) => {
      setState(false);
      initialValues.data = data;
      onCheck(data.slice(0, toFinite(count.value)));
    },
    [count.value, initialValues, onCheck],
  );

  const onRemove = useCallback(
    (index) => {
      setState(false);
      initialValues.data.splice(index, 1);
      setTimeout(() => setState(true), 1000);
    },
    [initialValues],
  );
  const cardTypes = [
    { label: "UzCard", value: "UZCARD" },
    { label: "HUMO", value: "HUMO" },
  ];

  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);

  const selectRow = useCallback(
    (cardId) => {
  
      const cardIds = {};
  
      if (cardId !== "all") {
        const document = data.filter((d: any) => d.cardId === cardId); 
        if (document.length > 0) {
          const index = data.findIndex((d: any) => d.cardId === document[0].cardId);
          data[index].isSelected = !data[index].isSelected;
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          data[i].isSelected = !data[i].isSelected;
        }
      }
  
      data.forEach((d: any) => {
        if (d.isSelected) {
          cardIds[d.cardId] = true;
        }
      });
      
      setSelectIds && setSelectIds(cardIds); 
    },
    [data, setSelectIds],
  );
  

  return (
    <div className="d-flex flex-column flex-grow-1 flex-shrink-1 salary-create-new-register-table-form">
      { loadingModal && <NotificationSalaryLoader />}
      <div className="py-5" />
      <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
        <div className="d-flex justify-content-lg-start">
          <Button
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            style={{ width: "15%" }}
            labelCode="TRANSFERS_TRANSFERS_OPEN_MODAL_BUTTON_TITLE"
            onClick={() => setOpenModal(true)}
          />
        </div>
        
        <CreateNewRegisterTableHeader transferType={transferType} />
        <div className="d-flex flex-column flex-shrink-1 flex-grow-1">
          <AutoSizer>
            {({ width, height }) => (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                onSubmit={submitHandler}
                enableReinitialize={true}
              >
                {state && (
                  <List
                    width={width}
                    itemSize={48}
                    height={height}
                    className="form-list"
                    itemCount={toFinite(count.value)}
                  >
                    {(x) => <CreateNewRegisterTableRow {...x} onRemove={onRemove} />}
                  </List>
                )}
              </Formik>
            )}
          </AutoSizer>
        </div>
        <div className="d-flex">
          <div className="row">
            <div className="col-6 pt-2">
              <Button
                size={SizeType.Medium}
                color={ButtonColor.Orange}
                labelCode="TRANSFERS_SALARY_LIST_OF_CARD_CHECK"
                onClick={() => {
                  if (ref.current) {
                    ref.current.handleSubmit();
                  }
                }}
                disabled={disableSalaryCheckBtn}
              />
            </div>
            <div className="col-6 pt-2">
              {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
                <Button
                  // disabled={notMatched || loading}
                  disabled={disableSalarySubmitBtn}
                  size={SizeType.Medium}
                  color={ButtonColor.Orange}
                  labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                  onClick={onSubmit}
                  type="button"
                />
              ) : (
                <div />
              )}
            </div>
          </div>
          <Formik onSubmit={noop} initialValues={{}}>
            <Form className="w-100 pt-2 ml-11">
              <div className="d-flex align-items-lg-end create-new-register-table-header ml-11">
                <div className="col-1 row ml-11" />
                <div className="ml-11 text-right mb-3 mr-2">{translate("SALARY_TOTAL_AMOUNT")}</div>
                <div className="amount-column display-block pt-2 ml-2">
                  <InputField
                    name="totalAmount"
                    disabled={true}
                    value={floatFormat(addZeros(totalAmount.toFixed(2).toString()))}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="py-5 d-flex justify-content-end">
          <SelectPicker
            minWidth={64}
            defaultValue={count}
            size={SizeType.Small}
            onChange={(value) => setCount(value)}
            options={[
              { label: "10", value: "10" },
              { label: "100", value: "100" },
              { label: "500", value: "500" },
              { label: "1000", value: "1000" },
              { label: "1500", value: "1500" },
              { label: "2000", value: "2000" },
            ]}
            name="count"
          />
        </div>
      </div>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        title={
          transferType === "SL"
            ? "TRANSFERS_SALARY_TAB_TITLE"
            : "TRANSFERS_CORPORATE_CARD_TAB_TITLE"
        }
        width={1000}
        ariaHideApp={false}
      >
        <Formik enableReinitialize={true} initialValues={{ search: "" }} onSubmit={noop}>
          {() => (
            <Form>
              <div className="d-flex justify-content-between">
                <SelectPickerField
                  options={cardTypes}
                  name="cardType"
                  label="TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_CARD_TYPE"
                  onChanges={(value) => onChangeCardType && onChangeCardType(value)}
                  value={cardType === "UZCARD" ? cardTypes[0] : cardTypes[1]}
                />
                <InputField
                  name="search"
                  className="col-3 row pb-2"
                  label="TRANSFERS_SALARY_SEARCH_BUTTON_TITLE"
                  placeholder="APP_HEADER_SEARCH"
                  onChange={(value) => setSearched(value.target.value)}
                  value={searched}
                />
                <Button
                  size={SizeType.Medium}
                  color={ButtonColor.Orange}
                  labelCode="TRANSFERS_SALARY_LIST_OF_CARD_CHOOSE"
                  style={{ width: "25%" }}
                  onClick={chooseCardHolders}
                />
              </div>
              <div className="py-3">
                <Paginator filter={filter} toFiveHundered={true} />
              </div>
              <SalaryListModal
                data={paymentCodeListAfterSearch}
                loading={salaryListOfCardHolders.loading}
                onSelect={selectRow}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
