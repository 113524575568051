import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import { object, string } from "yup";

import { DatePickerField } from "../form/DatePickerField";
import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userFullNameSelector } from "../../reducers/userReducer";
import { Button, ButtonColor } from "../ui/Button";
import { CreateNewDocumentRoles, SizeType, ViewMode } from "../../api/dto/AppDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { formatDateToBody } from "../../utils/DateUtils";
import { ApprovalInquiryType } from "../../api/account/AccountDTO";
import { showError } from "../../utils/NotificationUtils";
import { viewModeSelector } from "../../reducers/appReducer";
import { createNewDocumentRole } from "../../reducers/authReducer";

interface Props {
  readonly branchCodes: [{ label: ""; value: "" }];
  readonly onClose: () => void;
}

const validationSchema = object({
  requestNumber: string().required("Required"),
  branchCode: string().required("Required"),
});

export function RegisterK2Inquiry({ branchCodes, onClose }: Props) {
  const { translate } = useI18n();
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const { AccountApi } = useAccountContext();
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    (value) => {
      setLoading(true);
      const values = {
        registrationNumber: value.requestNumber,
        requestDate: formatDateToBody(value.requestDate),
        subject: translate("ACCOUNT_INQUIRY_NEW_REQUEST_K2"),
        branchCode: value.branchCode.value,
        inquiryType: ApprovalInquiryType.Standard,
        message: `${fullName}${translate("ACCOUNT_INQUIRY_TEXT_K2")} ${formatDateToBody(
          value.provideDate,
        )}
        
        
        ${translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}
        
        ${fullName}`,
      };
      // @ts-ignore
      AccountApi.createNonStandardInquiry(values)
        .then(() => {
          NotificationManager.info(
            translate("ACCOUNT_INQUIRY_SENT"),
            translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE"),
            5000,
          );
          setLoading(false);
          onClose();
        })
        .catch((r) => {
          showError(r);
          setLoading(false);
        });
    },
    [AccountApi, fullName, onClose, translate],
  );
  const inqFormRef = useRef<any>(null);
  const inqFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        inqFormRef.current = instance;
      }
    },
    [inqFormRef],
  );
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole);
  return (
    <>
      <Formik
        initialValues={{
          requestDate: new Date(),
          provideDate: new Date(),
          requestNumber: "",
          branchCode: "",
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={inqFormRefHandler}
      >
        {({ handleSubmit }) => (
          <Form className="d-flex flex-column px-7 pt-7" onSubmit={handleSubmit}>
            <div className="d-flex row mb-4">
              <InputField name="requestNumber" className="col-3" label={"ACCOUNT_REQUEST_NUMBER"} />
              <DatePickerField
                name="requestDate"
                className="col-3"
                label={"ACCOUNT_REQUEST_DATE"}
              />
              <SelectPickerField
                name="branchCode"
                className="col-6"
                label={"ACCOUNT_BRANCH_NAME"}
                options={branchCodes}
              />
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-9">
                {fullName}
                {translate("ACCOUNT_INQUIRY_TEXT_K2")}
              </span>
            </div>
            <div className="d-flex row mb-4">
              <DatePickerField name="provideDate" className="col-3" />
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-9">{translate("ACCOUNT_INQUIRY_TEXT_SINCERELY")}</span>
            </div>
            <div className="d-flex row mb-4">
              <span className="pt-3 col-9">{fullName}</span>
            </div>
          </Form>
        )}
      </Formik>
      <div>
        {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
          <Button
            className="ml-7 mb-3"
            size={SizeType.Medium}
            color={ButtonColor.Orange}
            type="submit"
            disabled={loading}
            onClick={() => {
              if (inqFormRef.current?.handleSubmit) {
                inqFormRef.current?.handleSubmit();
              }
            }}
          >
            {translate("ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE")}
          </Button>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}
