import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";
// import iconv from "iconv-lite";

import { BaseApi } from "../BaseApi";
import {
  AccountInquiryForApprovalProps,
  AccountListQueryProps,
  AccountProps,
  AccountStatementBranchQueryProps,
  AccountStatementListQueryProps,
  AccountStatementProps,
  ApprovalAuthorizerFormProps,
  AvailableAccountProps,
  BranchInformationProps,
  NonStandardInquiryFormProps,
  OpenAccountFormProps,
  GetAccountForApproval,
  GetApprovalInquiryList,
  InquiryListProps,
  SwiftListInnerProps,
  CreateNewNonStandardInq,
  GetInquiryForAccountsQuery,
  GetAccountOpeningQuery,
  getAccountStatementsQuery,
  getAccountStatementsProps,
  getAccountStatementsPropsMT940,
  TotalPendingApplicationProps,
  HistoryAccountProps,
  AccountCardBoxProps,
  AccountCardBoxQuery,
  AccountsForTransactionsProps,
  getAccountMT940Query,
  SwiftCoverTableDataProps,
} from "./AccountDTO";

export class AccountApi extends BaseApi {
  public getAccountsList(query: AccountListQueryProps): Promise<AccountProps[]> {
    return this.get("account/:employeeId", { query, params: { employeeId: this.employeeId } });
  }
  public getAccountsListForHistory(): Promise<HistoryAccountProps[]> {
    return this.get("account/list/:employeeId", { params: { employeeId: this.employeeId } });
  }
  public openAccount(values: OpenAccountFormProps): Promise<any> {
    return this.post("account/:employeeId", {
      json: values,
      params: { employeeId: this.employeeId },
    });
  }

  public getAccountStatementList(
    query: AccountStatementListQueryProps,
  ): Promise<AccountStatementProps[]> {
    return this.get("statement-account/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getAccountStatementCount(
    query: getAccountStatementsQuery,
  ): Promise<getAccountStatementsProps> {
    return this.get("statement-account/inquiry/:employeeID", {
      query,
      params: { employeeID: this.employeeId },
    });
  }

  public getAccountStatementCountKorzinka(
    query: getAccountStatementsQuery,
  ): Promise<getAccountStatementsProps> {
    return this.get("statement-account/export/anglesey/excel2/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (response: any) => {
        function b64toBlob(b64Data, contentType, sliceSize = 512) {
          contentType = contentType || "";
          sliceSize = sliceSize || 512;

          const byteCharacters = atob(b64Data);
          const byteArrays: any[] = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }

        const parseResponse = JSON.parse(response);
        if (parseResponse && parseResponse.data && parseResponse.success === true) {
          const contentType = "application/vnd.ms-excel";
          const blob1 = b64toBlob(parseResponse.data, contentType);
          const blobUrl1 = URL.createObjectURL(blob1);

          saveAs(blobUrl1, `${query.accountNumber}_${query.fromDate}_${query.toDate}.xlsx`);
        }
      },
    });
  }

  public getAccountMT940(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/mt940/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        if (text) {
          const value = b64_to_utf8(text);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute(
              "download",
              `${query.accountNumber}_${query.fromDate}_${query.toDate}.txt`,
            );
            a.click();
          }
        } else {
          return query.showModalText && query.showModalText();
        }
      },
    });
  }

  public getAccountSwiftOutgoingTxt(
    query: getAccountMT940Query,
  ): Promise<getAccountStatementsPropsMT940> {
    return this.get("swift/outgoing/export/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        const parseResponse = JSON.parse(text);
        if (parseResponse.data) {
          const value = b64_to_utf8(parseResponse.data);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute("download", `${query.messageKey}_${query.fromDate}_${query.toDate}.txt`);
            a.click();
          }
        } else {
          return query.showModalText && NotificationManager.error(query.showModalText());
        }
      },
    });
  }

  public getAccountSwiftIncomingTxt(
    query: getAccountMT940Query,
  ): Promise<getAccountStatementsPropsMT940> {
    return this.get("swift/incoming/export/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text: any) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        const parseResponse = JSON.parse(text);
        if (parseResponse.data) {
          const value = b64_to_utf8(parseResponse.data);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute("download", `${query.messageKey}_${query.fromDate}_${query.toDate}.txt`);
            a.click();
          }
        } else {
          return query.showModalText && NotificationManager.error(query.showModalText());
        }
      },
    });
  }

  public getAccountAgromir(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/excel/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (response: any) => {
        function b64toBlob(b64Data, contentType, sliceSize = 512) {
          contentType = contentType || "";
          sliceSize = sliceSize || 512;

          const byteCharacters = atob(b64Data);
          const byteArrays: any[] = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }

        const parseResponse = JSON.parse(response);
        if (parseResponse && parseResponse.data && parseResponse.success === true) {
          const contentType = "application/vnd.ms-excel";
          const blob1 = b64toBlob(parseResponse.data, contentType);
          const blobUrl1 = URL.createObjectURL(blob1);

          saveAs(blobUrl1, `${query.accountNumber}_${query.fromDate}_${query.toDate}.xlsx`);
        }
      },
    });
  }

  public getAccountAngleseyOztransgaz(
    query: getAccountMT940Query,
  ): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/anglesey/excel/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (response: any) => {
        function b64toBlob(b64Data, contentType, sliceSize = 512) {
          contentType = contentType || "";
          sliceSize = sliceSize || 512;

          const byteCharacters = atob(b64Data);
          const byteArrays: any[] = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }

        const parseResponse = JSON.parse(response);
        if (parseResponse && parseResponse.data && parseResponse.success === true) {
          const contentType = "application/vnd.ms-excel";
          const blob1 = b64toBlob(parseResponse.data, contentType);
          const blobUrl1 = URL.createObjectURL(blob1);

          saveAs(blobUrl1, `${query.accountNumber}_${query.fromDate}_${query.toDate}.xlsx`);
        }
      },
    });
  }

  public getAccountExcel1C(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/1c/excel/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (response: any) => {
        function b64toBlob(b64Data, contentType, sliceSize = 512) {
          contentType = contentType || "";
          sliceSize = sliceSize || 512;

          const byteCharacters = atob(b64Data);
          const byteArrays: any[] = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }

        const parseResponse = JSON.parse(response);
        if (parseResponse && parseResponse.data && parseResponse.success === true) {
          const contentType = "application/vnd.ms-excel";
          const blob1 = b64toBlob(parseResponse.data, contentType);
          const blobUrl1 = URL.createObjectURL(blob1);

          saveAs(blobUrl1, `${query.accountNumber}_${query.fromDate}_${query.toDate}.xlsx`);
        }
      },
    });
  }

  public getAccountExcelNew(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/excel1/excel/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (response: any) => {
        function b64toBlob(b64Data, contentType, sliceSize = 512) {
          contentType = contentType || "";
          sliceSize = sliceSize || 512;

          const byteCharacters = atob(b64Data);
          const byteArrays: any[] = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }

        const parseResponse = JSON.parse(response);
        if (parseResponse && parseResponse.data && parseResponse.success === true) {
          const contentType = "application/vnd.ms-excel";
          const blob1 = b64toBlob(parseResponse.data, contentType);
          const blobUrl1 = URL.createObjectURL(blob1);

          saveAs(blobUrl1, `${query.accountNumber}_${query.fromDate}_${query.toDate}.xlsx`);
        }
      },
    });
  }

  public getAccountTxt1C(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/1c/text/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text: any) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        const parseResponse = JSON.parse(text);
        if (parseResponse.data) {
          const value = b64_to_utf8(parseResponse.data);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute(
              "download",
              `${query.accountNumber}_${query.fromDate}_${query.toDate}.txt`,
            );
            a.click();
          }
        } else {
          return query.showModalText && NotificationManager.error(query.showModalText());
        }
      },
    });
  }

  public getAccountTxt3(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/text3/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text: any) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        const parseResponse = JSON.parse(text);
        if (parseResponse.data) {
          const value = b64_to_utf8(parseResponse.data);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute(
              "download",
              `${query.accountNumber}_${query.fromDate}_${query.toDate}.txt`,
            );
            a.click();
          }
        } else {
          return query.showModalText && NotificationManager.error(query.showModalText());
        }
      },
    });
  }

  public getAccountTxt11(query: getAccountMT940Query): Promise<getAccountStatementsPropsMT940> {
    return this.get("statement-account/export/text1/:employeeID", {
      query,
      params: { employeeID: this.employeeId },

      parseJson: (text: any) => {
        function b64_to_utf8(str) {
          return escape(atob(str));
        }
        const parseResponse = JSON.parse(text);
        if (parseResponse.data) {
          const value = b64_to_utf8(parseResponse.data);
          if (value) {
            const a = document.createElement("a");
            a.setAttribute("href", `data:text/plain;charset=windows-1251,${value}`);
            a.setAttribute(
              "download",
              `${query.accountNumber}_${query.fromDate}_${query.toDate}.txt`,
            );
            a.click();
          }
        } else {
          return query.showModalText && NotificationManager.error(query.showModalText());
        }
      },
    });
  }

  public getStatementOfAccount(query: getAccountStatementsQuery): Promise<number> {
    return this.get("statement-account/:employeeId/page", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getAccountStatementBranchList(
    branch: string,
    query: AccountStatementBranchQueryProps,
  ): Promise<AccountStatementProps[]> {
    return this.get("statement-account/:employeeId/:branch", {
      query,
      params: { employeeId: this.employeeId, branch },
    });
  }

  public getAccountInquiryForApprovalList(
    query: GetAccountForApproval,
  ): Promise<AccountInquiryForApprovalProps[]> {
    return this.get("account/approval-inquiry/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public createNonStandardInquiry(values: NonStandardInquiryFormProps): Promise<any> {
    return this.post("account/inquiry/create", {
      json: { ...values },
      query: { employeeID: this.employeeId },
    });
  }

  public approvalOrRejectedAuthorizer(
    documentId: string,
    values: ApprovalAuthorizerFormProps,
  ): Promise<any> {
    return this.get("approval/:employeeId/:documentId", {
      json: values,
      params: { documentId, employeeId: this.employeeId },
    });
  }

  public getAvailableAccounts(): Promise<AvailableAccountProps[]> {
    return this.get("open-information/available-account/:employerId", {
      params: { employerId: this.employeeId },
    });
  }

  public getAccountsForTransactionsList(): Promise<AccountsForTransactionsProps[]> {
    return this.get("account/individual/:employerId", {
      params: { employerId: this.employeeId },
    });
  }

  public getBranchesInformation(): Promise<BranchInformationProps[]> {
    return this.get("open-information/branch");
  }

  public approveOrRejectDoc(json): Promise<any> {
    return this.put("approval", { json: { employeeId: this.employeeId, ...json } });
  }

  public approveFcyIndividual({ smsCode, messageId, json }): Promise<any> {
    return this.put("approval/approve-via-sms", {
      json: { employeeId: this.employeeId, ...json },
      query: { employerId: this.employeeId, smsCode, messageId },
    });
  }

  public getApprovalInquiryList(query: GetApprovalInquiryList): Promise<InquiryListProps[]> {
    return this.get("account/inquiry/approval", {
      query: { ...query, employeeID: this.employeeId },
    });
  }

  public getAccountInquiryList(query: GetInquiryForAccountsQuery): Promise<InquiryListProps[]> {
    return this.get("account/inquiry/list", {
      query: { ...query, employeeID: this.employeeId },
    });
  }

  public getAccountOpeningList(query: GetAccountOpeningQuery): Promise<InquiryListProps[]> {
    return this.get("account/inquiry/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getAccountSwiftOutgoing(query: GetAccountOpeningQuery): Promise<SwiftListInnerProps> {
    return this.get("swift/outgoing/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getAccountSwiftIncoming(query: GetAccountOpeningQuery): Promise<SwiftListInnerProps> {
    return this.get("swift/incoming/list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getAccountSwiftIncomingCover(
    query: GetAccountOpeningQuery,
  ): Promise<SwiftCoverTableDataProps> {
    return this.get("swift/incoming/cover/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public createNonStanInq(json: CreateNewNonStandardInq): Promise<boolean> {
    return this.post("account/:employeeId", {
      json,
      params: { employeeId: this.employeeId },
    });
  }

  public createNewAccount(json): Promise<boolean> {
    return this.post("account/:employeeId", {
      json,
      params: { employeeId: this.employeeId },
    });
  }

  public createNewAccountIndividual(query): Promise<boolean> {
    return this.post("account/deposit/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public getTotalPendingApplications(): Promise<TotalPendingApplicationProps> {
    return this.get("approval/total/:employerId", {
      params: { employerId: this.employeeId },
    });
  }

  public getCardBoxList(query: AccountCardBoxQuery): Promise<AccountCardBoxProps[]> {
    return this.get("cardbox/:employerId", {
      query: { ...query },
      params: { employerId: this.employeeId },
    });
  }

  public sentSMS(): Promise<any> {
    return this.post("settings/send-sms");
  }

  public createInquiryIndividual({ smsCode, messageId, json }): Promise<any> {
    return this.post("account/account/inquiry/create/viasms", {
      query: { employeeID: this.employeeId, smsCode, messageId },
      json,
    });
  }

  public createAccountIndividual(query): Promise<boolean> {
    return this.post("account/deposit/viasms/:employeeId", {
      query,
      params: { employeeId: this.employeeId },
    });
  }

  public createDocumentWithDebitAccount({ json }): Promise<any> {
    return this.post("salary/replenishment/:employeeId", {
      params: { employeeId: this.employeeId },
      json,
    });
  }
}
