import { Action } from "redux";
import { DELETE, update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { RoleType } from "../api/dto/AppDTO";
import { UserProps } from "../api/dto/UserDTO";
import { AppStoreState } from "../store/RootReducer";
import { OnLendingGeneralProps } from "../api/on-lending/OnLendingDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const userReducerPersistConfig: Partial<PersistConfig<UserReducerState>> = {
  whitelist: ["role", "user"],
};

interface SetUserMeta {
  readonly user: UserProps;
}

interface SetRoleMeta {
  readonly role: RoleType | undefined;
}

interface SetLendingGeneralInfoMeta {
  readonly info: OnLendingGeneralProps | undefined;
}

enum ReducerActions {
  SetRole = "User/SetRole",
  SetUser = "User/SetUser",

  ClearRole = "User/ClearRole",
  ClearUser = "User/ClearUser",

  SetLendingGeneralInfo = "User/SetLendingGeneralInfo",
  ClearLendingGeneralInfo = "User/ClearLendingGeneralInfo",
}

export interface UserReducerState {
  readonly role?: RoleType;
  readonly user?: UserProps;
  readonly lendingGeneralInfo?: OnLendingGeneralProps;
}

function getState(): UserReducerState {
  return {};
}

export const userReducer = createRootReducer<UserReducerState>(
  getState(),

  createReducer([ReducerActions.SetUser], (state, { meta }) => update(state, { user: meta.user })),

  createReducer([ReducerActions.SetRole], (state, { meta }) => update(state, { role: meta.role })),

  createReducer([ReducerActions.SetLendingGeneralInfo], (state, { meta }) =>
    update(state, { lendingGeneralInfo: meta.info }),
  ),

  createReducer([ReducerActions.ClearRole], (state) => update(state, { role: DELETE })),

  createReducer([ReducerActions.ClearUser], (state) => update(state, { user: DELETE })),

  createReducer([ReducerActions.ClearLendingGeneralInfo], (state) =>
    update(state, { lendingGeneralInfo: DELETE }),
  ),
);

// ==================
// Selectors
// ==================

export const userSelector = ({ user }: AppStoreState): UserProps | undefined => user.user;

export const userEmployeeIdSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.employeeId;

export const userLoginSelector = ({ user }: AppStoreState): string | undefined => user.user?.login;

export const userFullNameSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.fullName;

export const userTaxNoSelector = ({ user }: AppStoreState): string | undefined => user.user?.taxNo;

export const userAddressSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.address;

export const userEmailSelector = ({ user }: AppStoreState): string | undefined => user.user?.email;

export const userOtpKeySerialNumberSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.otpkeySerialNumber;

export const userPhoneForTradeFinanceSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.phone;

export const userPhoneSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.phoneNumberForSms;

export const userCustomerSequenceSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.customerSequence;

export const userRoleSelector = ({ user }: AppStoreState): RoleType | undefined => user.role;

export const lendingGeneralInfoSelector = ({
  user,
}: AppStoreState): OnLendingGeneralProps | undefined => user.lendingGeneralInfo;

// ==================
// Actions
// ==================

export function setUser(meta: SetUserMeta): PerformAction<SetUserMeta> {
  return { type: ReducerActions.SetUser, meta };
}

export function clearUser(): Action {
  return { type: ReducerActions.ClearUser };
}

export function setRole(meta: SetRoleMeta): PerformAction<SetRoleMeta> {
  return { type: ReducerActions.SetRole, meta };
}

export function clearRole(): Action {
  return { type: ReducerActions.ClearRole };
}

export function setLendingGeneralInfo(
  meta: SetLendingGeneralInfoMeta,
): PerformAction<SetLendingGeneralInfoMeta> {
  return { type: ReducerActions.SetLendingGeneralInfo, meta };
}

export function clearLendingGeneralInfo(): Action {
  return { type: ReducerActions.ClearLendingGeneralInfo };
}
