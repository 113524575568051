import React from "react";
import { useField } from "formik";

import { DateIcon } from "../icons/DateIcon";
import { SizeType } from "../../api/dto/AppDTO";
import { DatePicker, DatePickerProps } from "../ui/DatePicker";

interface Props extends Omit<DatePickerProps, "onChange" | "value"> {
  readonly name: string;

  readonly onChange?: (
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any> | undefined,
  ) => void;
}

export function DatePickerField({ name, onChange, ...inputProps }: Props) {
  const [field, , helpers] = useField(name);

  return (
    <DatePicker
      id={name}
      {...field}
      {...inputProps}
      selected={field.value || new Date()}
      rightIcon={
        inputProps.rightIcon || <DateIcon size={inputProps.size === SizeType.Small ? 14 : 20} />
      }
      onChange={(date, event) => {
        helpers.setValue(date);

        if (onChange) {
          onChange(date, event);
        }
      }}
    />
  );
}
