import qs from "qs";
import { toNumber, toString } from "lodash";

import { Dict } from "../api/dto/AppDTO";
import {
  CurrencyCode,
  formatNumberToWord,
  Language,
} from "../components/number-to-words/NumberToWords";
import { NUMBER_TO_WORDS_DICTIONARY } from "../constants/AppConstants";

export function formatCurrencyNumber(value: string | number = 0, fraction = 2): string {
  return toNumber(value)
    .toFixed(fraction)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatCardNumber(value: string | number = 0): string {
  return toString(value).replace(/(?=(\d{4})+(?!\d))/g, " ");
}

export function parseSearch<TData = any>(search = ""): TData & Dict<string> {
  return qs.parse(search.replace("?", "")) as TData & Dict<string>;
}

export interface FormatNumberToWordOptionProps {
  readonly language: Language;
  readonly withStartZero?: boolean;
  readonly showZeroCents?: boolean;
  readonly currencyCode: CurrencyCode;
}

export function numberToWords(
  number: string | number,
  options: FormatNumberToWordOptionProps = {} as FormatNumberToWordOptionProps,
): string {
  return formatNumberToWord(number, {
    dictionary: NUMBER_TO_WORDS_DICTIONARY,

    ...options,
  });
}

export function formatPhoneNumber(number?: string) {
  if (!number) return "";
  if (!number.includes("+")) {
    number = "+" + number;
  }
  if (number !== "+998" || number !== "+998 ") {
    const code = number?.replaceAll(" ", "").substring(0, 4);
    const operatorCode = number?.replaceAll(" ", "").substring(4, 6);
    const firstThree = number?.replaceAll(" ", "").substring(6, 9);
    const firstTwo = number?.replaceAll(" ", "").substring(9, 11);
    const secondTwo = number?.replaceAll(" ", "").substring(11, 13);

    const formattedNumber: string =
      code + " " + operatorCode + " " + firstThree + " " + firstTwo + " " + secondTwo;

    return formattedNumber;
  }
  return number;
}
