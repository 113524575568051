import React, { useMemo } from "react";
import { useField } from "formik";
import { TextAreaProps } from "../ui/TextArea";
import { InquiryTextArea } from "../ui/InquiryTextArea";

interface Props extends Omit<TextAreaProps, "value"> {
  readonly name: string;
}

export function InquiryTextAreaField({ name, ...inputProps }: Props) {
  const [field, meta] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta]);

  return (
    <InquiryTextArea
      id={name}
      hasError={showError}
      hintText={showError ? meta.error : undefined}
      {...field}
      {...inputProps}
    />
  );
}
