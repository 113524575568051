import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function PrintIcon({ size = 20, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.331 17.967"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 -18.065)">
        <g transform="translate(6.229 32.479)">
          <g transform="translate(0 0)">
            <path
              fill={color}
              transform="translate(-164.977 -399.834)"
              d="M171.206,399.834h-5.585a.644.644,0,0,0,0,1.289h5.584a.644.644,0,0,0,0-1.289Z"
            />
          </g>
        </g>
        <g transform="translate(6.229 30.447)">
          <g>
            <path
              fill={color}
              transform="translate(-164.977 -346.006)"
              d="M171.206,346.006h-5.585a.644.644,0,1,0,0,1.289h5.584a.644.644,0,0,0,0-1.289Z"
            />
          </g>
        </g>
        <g transform="translate(0 18.065)">
          <g transform="translate(0 0)">
            <path
              fill={color}
              transform="translate(0 -18.065)"
              d="M17.827,22.439h-2.05V18.709a.644.644,0,0,0-.644-.644H4.2a.644.644,0,0,0-.644.644v3.729H1.5a1.505,1.505,0,0,0-1.5,1.5v6.483a1.505,1.505,0,0,0,1.5,1.5h2.05v3.459a.644.644,0,0,0,.644.644H15.133a.644.644,0,0,0,.644-.644V31.929h2.05a1.505,1.505,0,0,0,1.5-1.5V23.942A1.505,1.505,0,0,0,17.827,22.439ZM4.842,19.354h9.646v3.085H4.842Zm9.646,15.389H4.843V29.471h9.646C14.488,29.631,14.488,34.622,14.488,34.743Zm.644-8.521h-1.64a.644.644,0,1,1,0-1.289h1.64a.644.644,0,1,1,0,1.289Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
