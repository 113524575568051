import React, { useCallback } from "react";
import { Form, Formik } from "formik";
import { PageTitle } from "../../components/app/PageTitle";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { InputField } from "../../components/form/InputField";
import { Button, ButtonColor } from "../../components/ui/Button";
import { TransferType } from "../../api/transfer/TransferDTO";
import { UploadPayrollExcelButtonField } from "../../components/form/UploadPayrollExcelButtonField";
import { TransitAccountNumberFieldWrapper } from "../../components/form/TransitAccountNumberFieldWrapper";
import { noop } from "lodash";
import { update } from "immupdate";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";
import { SelectPickerField } from "../../components/form/SelectPickerField";

interface Props {
  readonly onCreateClick: () => void;
  readonly transferType: TransferType;
  readonly setInitialValues: (value) => void;
  readonly onChangeAccountType: (value) => void;
  readonly initialValues: any;
  readonly accountTypes: any[];
  readonly getChooseExistingRegister: () => void;
  readonly readFromExcel: (value) => void;
}

export function CorporateChooseTransitAccountForm({
  transferType,
  onCreateClick,
  setInitialValues,
  onChangeAccountType,
  initialValues,
  accountTypes,
  getChooseExistingRegister,
  readFromExcel,
}: Props) {
  const isMobile = CheckMobileUtils();
  const onChangeAgrNumber = useCallback(
    (value) => {
      if (value.length <= 21) {
        setInitialValues((prev) =>
          update(prev, {
            number: value,
          }),
        );
      }
    },
    [setInitialValues],
  );
  return (
    <div
      className={`border border-gray-light br-1 mt-5 bg-white p-7 ${isMobile ? "w-100" : "w-50"}`}
    >
      <Formik initialValues={initialValues} onSubmit={noop}>
        {() => (
          <Form>
            <div className="w-100 d-flex align-items-center justify-content-between pb-5">
              <PageTitle title="TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_TITLE" />
              <SelectPickerField
                width={240}
                name="accountType"
                size={SizeType.Small}
                options={accountTypes}
                labelPosition={PositionType.Right}
                onChanges={onChangeAccountType}
              />
            </div>
            <div className="row pb-2">
              <TransitAccountNumberFieldWrapper
                defaultValue={initialValues.transitAccount}
                className="col-lg-5"
                name="transitAccount"
                transferType={transferType}
                label="TRANSFERS_SALARY_TRANSIT_ACCOUNT_FIELD_LABEL_TITLE"
                onChange={(value) => {
                  setInitialValues((prev) =>
                    update(prev, {
                      transitAccount: value,
                      accountBalance: value.balance,
                      type: value.label.substr(17, 1) === "0" ? "UZCARD" : "HUMO",
                    }),
                  );
                }}
              />
              <InputField
                disabled={true}
                className="col-lg-5"
                name="accountBalance"
                label="TRANSFERS_SALARY_BALANCE_FIELD_LABEL_TITLE"
                value={initialValues?.accountBalance}
              />
              <InputField
                name="code"
                className="col-lg-2"
                label="TRANSFERS_SALARY_CODE_FIELD_LABEL_TITLE"
                value={initialValues?.code}
                disabled={true}
              />
            </div>
            <div className="row pb-2 d-flex">
              <div className="col-lg-6">
                <InputField
                  name="number"
                  label="TRANSFERS_SALARY_NUMBER_FIELD_LABEL_TITLE"
                  value={initialValues?.number}
                  onChange={(value) => onChangeAgrNumber(value.target.value)}
                  autoComplete="on"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  name="detailsType"
                  label="TRANSFERS_SALARY_DETAILS_FIELD_LABEL_TITLE"
                  disabled={true}
                />
              </div>
            </div>
            <PageTitle title="TRANSFERS_SALARY_CHOOSE_REGISTER_TITLE" className="py-5" />
            <div className="d-flex flex-lg-row flex-column">
              <UploadPayrollExcelButtonField
                disabled={
                  !initialValues.transitAccount ||
                  !initialValues.number ||
                  !initialValues.code ||
                  !initialValues.detailsType
                }
                type="button"
                name="uploadData"
                size={SizeType.Medium}
                className="flex-1 mr-lg-2"
                color={ButtonColor.Orange}
                readFromExcel={readFromExcel}
              />
              <Button
                disabled={
                  !initialValues.transitAccount ||
                  !initialValues.number ||
                  !initialValues.code ||
                  !initialValues.detailsType
                }
                type="submit"
                size={SizeType.Medium}
                className="flex-1 mx-lg-2 my-lg-0 my-3"
                color={ButtonColor.Orange}
                labelCode="TRANSFERS_SALARY_CHOOSE_EXISTING_BUTTON_TITLE"
                onClick={getChooseExistingRegister}
              />
              <Button
                disabled={
                  !initialValues.transitAccount ||
                  !initialValues.number ||
                  !initialValues.code ||
                  !initialValues.detailsType
                }
                type="button"
                size={SizeType.Medium}
                className="flex-1 ml-lg-2"
                onClick={onCreateClick}
                color={ButtonColor.Orange}
                labelCode="TRANSFERS_SALARY_CREATE_NEW_REGISTER_BUTTON_TITLE"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
