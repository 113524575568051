import React, { useCallback, useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { ApprovalInquiryType, InquiryListProps } from "../../api/account/AccountDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { useResource } from "../../hooks/useResource";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { NotificationManager } from "react-notifications";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: AccountsFilter;
  readonly inquiryType: ApprovalInquiryType;
  readonly onSelect: (value: any) => void;
}

export function AccountInquiryTable({ filter, onSelect, inquiryType }: Props) {
  const { translate } = useI18n();
  const { AccountApi } = useAccountContext();
  const dispatch = useDispatch();

  const getAccountInq = useResource(
    () =>
      AccountApi.getAccountInquiryList({
        ...filter.getInquiryListFilter(),
        inquiryType,
      }),
    [filter],
  );
  useResourceHandler(getAccountInq, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  const data = useMemo(() => getAccountInq.data || [], [getAccountInq.data]);

  const selectDocument = useCallback(
    (value) => {
      if (Object.keys(value).length > 1) {
        NotificationManager.info("Please choose only one document");
        window.location.reload();
      } else {
        const array = data[Object.keys(value)[0]];
        onSelect(array);
      }
    },
    [data, onSelect],
  );

  const columns = useMemo(() => {
    const list: Column<InquiryListProps>[] = [
      {
        width: 50,
        accessor: "beginDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "subject",
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "accountOpenState",
        Header: translate("ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 100,
        accessor: "explanation",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE"),
      },
      {
        width: 40,
        accessor: "endDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "createUser",
        Header: translate("ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "approvalUser",
        Header: translate("ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [translate]);

  return (
    <Table
      columns={columns}
      onSelect={(value) => selectDocument(value)}
      data={data}
      loading={getAccountInq.loading}
      withCheckbox={true}
      tableClassName="bg-white"
    />
  );
}
