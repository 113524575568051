import React, { useMemo } from "react";
import { PageTitle } from "../app/PageTitle";
import { ListIcon } from "../icons/ListIcon";
import { FlatButton } from "../ui/FlatButton";
import { ExcelIcon } from "../icons/ExcelIcon";
import { useI18n } from "../../i18n/I18nContext";
import { FileWithpenIcon } from "../icons/FileWithpenIcon";
import { Link } from "react-router-dom";
import { TransferType } from "../../api/transfer/TransferDTO";
import InfoTitle, { InformationBody } from "../info-for-user/InfoTitle";
import { OnShowUserInfo } from "../on-show-user-info/OnShowUserInfo";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";
import { TransfersFilter } from "../../filters/TransfersFilter";

interface Props {
  readonly onCreateClick: () => void;
  readonly onRegistrationClick: () => void;
  readonly onGetListOfCardHoldersClick: () => void;
  readonly transferType: TransferType;
  readonly filter: TransfersFilter;
}

export function SalaryInstructionItem({
  onRegistrationClick,
  onGetListOfCardHoldersClick,
  transferType,
  filter,
}: Props) {
  const { translate } = useI18n();
  const isMobile = CheckMobileUtils();

  const chooseAccountType = useMemo(() => filter.getChooseAccountType(), [filter]);
  const chooseAccountTypeSalary = useMemo(() => filter.getChooseAccountTypeSalary(), [filter]);
  return (
    <>
      <div
        className={`border border-gray-light br-1 p-7 mt-5 bg-white mr-lg-5 ${
          isMobile ? "w-100" : "w-50"
        }`}
      >

        {chooseAccountTypeSalary === "salaryTransit" && transferType === "SL"  && (
          <>
            <PageTitle
              title="TRANSFERS_SALARY_TRANSIT_ACCOUNT_TITLE"
              className="pb-5"
            />
            <div className="pb-5">
              <div className="pb-2 d-flex">
                <Link to="/corporate/transfers/transfersLCY?transferType=lcy">
                  {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REPLENISH_SUBTITLE")}
                </Link>
                <OnShowUserInfo
                  title={<InfoTitle infoTitle={InformationBody.InformationBodyTitle_7} />}
                />
              </div>
              
              <div className="pb-2">{translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SUBTITLE")}</div>
              
              <div className="d-flex pb-2">
                <div>{translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REGISTER_SUBTITLE")}</div>
                <OnShowUserInfo
                  title={<InfoTitle infoTitle={InformationBody.InformationBodyTitle_6} />}
                />
              </div>
              <div className="pb-2">
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_CHECK_SUBTITLE")}
              </div>
              <div>
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_STATUS_SUBTITLE")}
              </div>
            </div>
            <div className="d-flex justify-content-between flex-lg-row flex-column pb-10">
             
              <a href="https://ibtest.kdb.uz/templates/Salary_Card_Payroll.xls">
                <FlatButton
                  leftIcon={<ExcelIcon />}
                  title="FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE"
                />
              </a>
              <a>
                <FlatButton
                  leftIcon={<ListIcon />}
                  onClick={onGetListOfCardHoldersClick}
                  title="FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE"
                />
              </a>
              <a>
                <FlatButton
                  leftIcon={<FileWithpenIcon />}
                  onClick={onRegistrationClick}
                  title="FILE_CONTROLS_REGISTER_NEW_SALARY_CARD_BUTTON_TITLE"
                />
              </a>
            </div>

            <div className="text-danger">
              {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_INFO_SUBTITLE")}
            </div>

          </>
        )}

        {chooseAccountTypeSalary === "salaryDebit" && transferType === "SL" && (
          <>
            <PageTitle
              title="TRANSFERS_SALARY_DEBIT_ACCOUNT_TITLE"
              className="pb-5"
            />
            <div className="pb-5">
              <div className="pb-2">
                {translate("TRANSFERS_SALARY_DEBIT_ACCOUNT_METHOD_INFO_SUBTITLE")}
              </div>
              <div className="pb-2 d-flex">
                {translate("TRANSFERS_SALARY_CHOOSE_DEBIT_ACCOUNT_SUBTITLE")}
                <OnShowUserInfo
                  title={<InfoTitle infoTitle={InformationBody.InformationBodyTitle_8} />}
                />
              </div>
              <div className="pb-2">
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_SUBTITLE")}
              </div>
              <div className="pb-2">
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_REGISTER_SUBTITLE")}
              </div>
              <div className="pb-2">
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_CHECK_SUBTITLE")}
              </div>
              <div>
                {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_STATUS_SUBTITLE")}
              </div>
            </div>

            <div className="d-flex justify-content-between flex-lg-row flex-column pb-10">
              <a href="https://ibtest.kdb.uz/templates/Salary_Card_Payroll.xls">
                <FlatButton
                  leftIcon={<ExcelIcon />}
                  title="FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE"
                />
              </a>
              <a>
                <FlatButton
                  leftIcon={<ListIcon />}
                  onClick={onGetListOfCardHoldersClick}
                  title="FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE"
                />
              </a>
              <a>
                <FlatButton
                  leftIcon={<FileWithpenIcon />}
                  onClick={onRegistrationClick}
                  title="FILE_CONTROLS_REGISTER_NEW_SALARY_CARD_BUTTON_TITLE"
                />
              </a>
            </div>

            <div className="text-danger">
              {translate("TRANSFERS_SALARY_CHOOSE_TRANSIT_ACCOUNT_INFO_SUBTITLE")}
            </div>
          </>
        )}

        {chooseAccountType === "transit" && transferType != "SL" && (
          <>
            <PageTitle
              title="TRANSFERS_CORPORATE_INSTRUCTION_TITLE"
              className="pb-5"
            />
            <div className="pb-5">
              <div className="pb-2">
                <Link to="/corporate/transfers/transfersLCY?transferType=lcy">
                  {translate("TRANSFERS_SALARY_REPLENISH_SUBTITLE")}
                </Link>
              </div>
              <div className="pb-2">
                <div>{translate("TRANSFERS_CORPORATE_CARD_INFO")}</div>
                <div>{translate("TRANSFERS_CORPORATE_CARD_INFO_TEXT")}</div>
                <div>{translate("TRANSFERS_CORPORATE_CARD_INFO_TEXT_2")}</div>
              </div>
              <div className="d-flex">
                <div>{translate("TRANSFERS_SALARY_CHOOSE_REGISTER_SUBTITLE")}</div>
                <OnShowUserInfo
                  title={<InfoTitle infoTitle={InformationBody.InformationBodyTitle_6} />}
                />
              </div>

            </div>
            <div className="d-flex flex-lg-row flex-column">
             
              <a href="https://ibtest.kdb.uz/templates/Corporate_Card_Payroll.xls">
                <FlatButton
                  leftIcon={<ExcelIcon />}
                  title="FILE_CONTROLS_DOWNLOAD_EXCEL_TEMPLATE_BUTTON_TITLE"
                />
              </a>
             
              <a className="ml-6">
                <FlatButton
                  leftIcon={<ListIcon />}
                  onClick={onGetListOfCardHoldersClick}
                  title="FILE_CONTROLS_LIST_OF_CARD_HOLDERS_BUTTON_TITLE"
                />
              </a>
              
            </div>
          </>
        )}

        {chooseAccountType === "debit" && transferType != "SL" && (
          <>
            <div className="mt-3">
              <PageTitle
                title="TRANSFERS_CORPORATE_SINGLE_CARD_REPLENISHMENT_INSTURCTION_TITLE"
                className="pb-5"
              />
              <div className="pb-2">
                <div>{translate("TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_DEBIT_ACCOUNT_TITLE")}</div>
                <div>
                  {translate("TRANSFERS_CORPORATE_CHOOSE_SINGLE_CARD_ACCOUNT_OF_COR_CARD_TITLE")}
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
}
