import React, { useMemo } from "react";

import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { AccountInquiryForApprovalProps } from "../../api/account/AccountDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly data: AccountInquiryForApprovalProps[];
  readonly withCreatorsAndApprovals?: boolean;
  readonly setDocumentId: (value) => void;
}

export function AccountsTable({ data = [], withCreatorsAndApprovals, setDocumentId }: Props) {
  const { translate } = useI18n();
  const { language } = useI18n();

  const columns = useMemo(() => {
    const list: Column<AccountInquiryForApprovalProps>[] = [
      {
        width: 50,
        accessor: "beginDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE"),
      },
      {
        width: 150,
        // @ts-ignore
        accessor: `${
          language === AppLanguage.Russian
            ? "subjectInRu"
            : language === AppLanguage.Uzbek
            ? "subjectInUzb"
            : "subjectInEng"
        }`,
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "accountOpenState",
        Header: translate("ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 100,
        accessor: "explanation",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE"),
      },
      {
        width: 40,
        accessor: "endDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE"),
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          width: 50,
          accessor: "createUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
        },
        {
          width: 50,
          accessor: "approvalUser",
          Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }

    return list;
  }, [withCreatorsAndApprovals, translate]);

  return <Table data={data} columns={columns} onSelect={setDocumentId} withCheckbox={true} />;
}
