import { AppFilter, AppFilterProps } from "./AppFilter";

export enum AgreementsTabs {
  PublicOffer = "public-offer",
  TmeplatesAndForms = "templates-and-forms",
  Insturction = "instruction",
}

export enum TmeplatesAndFormsTabs {
  Issuing = "issuing",
  ReturnDocuments = "return-documets",
}

interface AgreementsFilterProps extends AppFilterProps<AgreementsTabs> {
  readonly templatesAndFormsType?: string;
}

export class AgreementsFilter extends AppFilter<AgreementsTabs> {
  private readonly templatesAndFormsType?: string;
  public constructor({ tab, templatesAndFormsType, ...props } = {} as AgreementsFilterProps) {
    super({ ...props, tab: tab || AgreementsTabs.PublicOffer });
    this.templatesAndFormsType = TmeplatesAndFormsTabs.Issuing;

    if (templatesAndFormsType) {
      this.templatesAndFormsType = templatesAndFormsType;
    }
  }

  public getTemplatesAndFormsType(): any {
    return this.templatesAndFormsType;
  }
}
