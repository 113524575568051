import React from "react";
import { Formik } from "formik";

import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { CheckboxField } from "../form/CheckboxField";
import { DatePickerField } from "../form/DatePickerField";
import { AccountFilterFormProps } from "../../api/account/AccountDTO";

interface Props {
  readonly initialValues: AccountFilterFormProps;
  readonly onSubmit: (values: AccountFilterFormProps) => void;
}

export function AccountsFilterForm({ initialValues, onSubmit }: Props) {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <div className="d-flex flex-grow-1 flex-shrink-1 align-items-xl-center align-items-lg-center justify-content-end flex-xl-row flex-lg-row flex-column gap-4">
          <DatePickerField
            className="mr-4 mt-xl-0 mt-lg-0 mt-2"
            name="balanceAsOfDate"
            onChange={() => handleSubmit()}
            labelPosition={PositionType.Left}
            label="ACCOUNTS_ACCOUNTS_BALANCE_AS_OF_TITLE"
            size={SizeType.Small}
          />

          <CheckboxField
            className="mr-4 mt-xl-0 mt-lg-0 mt-2"
            name="includeClosedAccounts"
            onChange={() => handleSubmit()}
            label="ACCOUNTS_ACCOUNTS_INCLUDE_CLOSED_ACCOUNTS_CHECKBOX_TITLE"
          />
          <CheckboxField
            className="mt-xl-0 my-lg-0 my-2"
            name="excludeZeroBalance"
            onChange={() => handleSubmit()}
            label="ACCOUNTS_ACCOUNTS_EXCLUDE_ZERO_BALANCE_CHECKBOX_TITLE"
          />
        </div>
      )}
    </Formik>
  );
}
