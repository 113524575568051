import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function ConversionIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0)">
        <path d="M54.214,19.692Z" transform="translate(-36.697 -13.317)" fill={color} />
        <path
          fill={color}
          d="M17.517,6.375a.345.345,0,0,0-.485.055l-.675.846V3.253a.345.345,0,0,0-.345-.345H9.255a5.034,5.034,0,1,0,.236.69h6.178V7.186l-.682-.8a.345.345,0,1,0-.524.448l1.348,1.575a.347.347,0,0,0,.532-.009l1.23-1.543A.345.345,0,0,0,17.517,6.375ZM5.133,6.957v.675H4.554V6.988A1.948,1.948,0,0,1,3.6,6.7l-.054-.038.215-.59.091.06a1.653,1.653,0,0,0,.883.259c.4,0,.682-.22.682-.536,0-.289-.2-.484-.675-.675C4.162,4.952,3.624,4.654,3.624,4A1.119,1.119,0,0,1,4.6,2.9V2.248h.573v.62a1.8,1.8,0,0,1,.8.23l.064.037-.224.581-.087-.049a1.492,1.492,0,0,0-.771-.2c-.442,0-.6.247-.6.46,0,.264.167.408.747.644.739.3,1.054.668,1.054,1.242a1.189,1.189,0,0,1-1.02,1.151Z"
        />
        <path
          fill={color}
          transform="translate(-5.56 -21.724)"
          d="M20.732,32.069A4.824,4.824,0,0,0,16,37.843H10.122V34.293l.613.753a.345.345,0,0,0,.535-.435L9.988,33.035a.35.35,0,0,0-.543.009L8.277,34.587a.345.345,0,0,0,.55.416l.605-.8v3.983a.345.345,0,0,0,.345.345H16.2a4.825,4.825,0,1,0,4.536-6.464ZM22.2,39.3H18.9v-.516l.047-.024a1.418,1.418,0,0,0,.814-1.245,3.133,3.133,0,0,0-.027-.442h-.789v-.647h.688a4.625,4.625,0,0,1-.068-.73A1.48,1.48,0,0,1,21.1,34.139a1.647,1.647,0,0,1,.868.2l.056.035-.2.665-.093-.052a1.289,1.289,0,0,0-.631-.14c-.647,0-.744.537-.744.857a3.725,3.725,0,0,0,.076.722h1.1v.647H20.51a2.463,2.463,0,0,1-.039.78,1.623,1.623,0,0,1-.4.724H22.2V39.3Z"
        />
      </g>
    </svg>
  );
}
