import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { SwiftListTableDataProps } from "../../api/account/AccountDTO";
import { Column } from "react-table";
import { SwiftTableRows } from "../table/SwiftTableRows";
import { AccountsFilter } from "../../filters/AccountsFilter";

// import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly data: SwiftListTableDataProps[];
  readonly withCreatorsAndApprovals?: boolean;
  readonly filter: AccountsFilter;
  readonly loading: boolean;
}

export function SwiftTable({ data = [], filter, loading }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SwiftListTableDataProps>[] = [
      {
        width: 60,
        accessor: "msgTag32A",
        Header: translate("ACCOUNT_SWIFT_VALUE_DATE_COLUMN_TITLE"),
      },
      {
        width: 60,
        accessor: "swiftMessageKey",
        Header: translate("ACCOUNT_SWIFT_MSG_KEY_COLUMN_TITLE"),
      },
      {
        width: 60,
        accessor: "msgTag20",
        Header: translate("ACCOUNT_SWIFT_REF_NUM_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        // Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 20,
        accessor: "currency",
        Header: translate("ACCOUNT_SWIFT_CURRENCY_COLUMN_TITLE"),
      },
      {
        width: 40,
        accessor: "amount",
        Header: translate("ACCOUNT_SWIFT_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: filter.swiftInnerType === "outgoing" ? "msgTag57A" : "msgTag52A",
        Header: translate(
          filter.swiftInnerType === "outgoing"
            ? "ACCOUNT_SWIFT_ACC_BIC_COLUMN_TITLE"
            : "ACCOUNT_SWIFT_ORD_BIC_COLUMN_TITLE",
        ),
      },
      {
        width: 100,
        accessor: filter.swiftInnerType === "outgoing" ? "msgTag59" : "msgTag50K",
        Header: translate(
          filter.swiftInnerType === "outgoing"
            ? "ACCOUNT_SWIFT_BEN_CUS_COLUMN_TITLE"
            : "ACCOUNT_SWIFT_ORD_CUST_COLUMN_TITLE",
        ),
      },
      {
        width: 100,
        accessor: "msgTag70",
        Header: translate("ACCOUNT_SWIFT_DETAILS_COLUMN_TITLE"),
      },
    ];

    // if (withCreatorsAndApprovals) {
    //   return list.concat([
    //     {
    //       width: 50,
    //       accessor: "createUser",
    //       Header: translate("TRANSFERS_TRANSFERS_TABLE_CREATOR_COLUMN_TITLE"),
    //     },
    //     {
    //       width: 50,
    //       accessor: "approvalUser",
    //       Header: translate("TRANSFERS_TRANSFERS_TABLE_APPROVED_BY_COLUMN_TITLE"),
    //     },
    //   ]);
    // }

    return list;
  }, [translate, filter.swiftInnerType]);

  return <SwiftTableRows data={data} columns={columns} withCheckbox={true} loading={loading} />;
}
