import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function GearIcon({ size = 18, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 17.551 17.551"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M15.935,6.609h-.373a7.087,7.087,0,0,0-.457-1.1l.264-.264a1.616,1.616,0,0,0,0-2.285l-.779-.778a1.616,1.616,0,0,0-2.285,0l-.264.264a7.092,7.092,0,0,0-1.1-.457V1.616A1.618,1.618,0,0,0,9.326,0h-1.1A1.618,1.618,0,0,0,6.609,1.616v.373a7.085,7.085,0,0,0-1.1.457l-.264-.264a1.616,1.616,0,0,0-2.285,0l-.779.779a1.616,1.616,0,0,0,0,2.285l.264.264a7.086,7.086,0,0,0-.457,1.1H1.616A1.618,1.618,0,0,0,0,8.225v1.1a1.618,1.618,0,0,0,1.616,1.616h.373a7.088,7.088,0,0,0,.457,1.1l-.264.264a1.616,1.616,0,0,0,0,2.285l.779.778a1.616,1.616,0,0,0,2.285,0l.264-.264a7.092,7.092,0,0,0,1.1.457v.373a1.618,1.618,0,0,0,1.616,1.616h1.1a1.618,1.618,0,0,0,1.616-1.616v-.373a7.086,7.086,0,0,0,1.1-.457l.264.264a1.616,1.616,0,0,0,2.285,0l.779-.779a1.616,1.616,0,0,0,0-2.285l-.264-.264a7.086,7.086,0,0,0,.457-1.1h.373a1.618,1.618,0,0,0,1.616-1.616v-1.1a1.618,1.618,0,0,0-1.616-1.616Zm-7.16,5.985a3.819,3.819,0,1,1,3.819-3.819A3.823,3.823,0,0,1,8.776,12.594Z"
      />
    </svg>
  );
}
