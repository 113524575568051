import React from "react";
import { TransfersButtonsGroup, TransfersButtonsGroupProps } from "./TransfersButtonsGroup";

interface Props extends Omit<TransfersButtonsGroupProps, "className"> {}

export function TransfersTabLcyHeader({ beginDay, ...buttonsGroup }: Props) {
  return (
    <div className="d-flex flex-grow-1 flex-shrink-1 justify-content-between align-items-center">
      <TransfersButtonsGroup
        {...buttonsGroup}
        beginDay={beginDay}
        className="mr-lg-3 mt-lg-0 mt-2"
      />
    </div>
  );
}
