import React from "react";
import { noop } from "lodash";
import { Form, Formik } from "formik";
import { SelectPickerField } from "../form/SelectPickerField";
import { SizeType } from "../../api/dto/AppDTO";
import { Button, ButtonColor } from "../ui/Button";

interface Props {
  readonly onChangeCardType: (value) => void;
  readonly cardType: string;
  readonly backButton: () => void;
}

export function SalaryListOfCardHoldersForm({ onChangeCardType, cardType, backButton }: Props) {
  const cardTypes = [
    { label: "UzCard", value: "UZCARD" },
    { label: "HUMO", value: "HUMO" },
  ];
  return (
    <Formik initialValues={{}} onSubmit={noop}>
      {() => (
        <Form className="d-flex align-items-center  justify-content-between flex-shrink-1 flex-grow-1 ">
          <div className="d-flex align-items-center input-group w-100">
            <div>
              <SelectPickerField
                options={cardTypes}
                name="cardType"
                label="TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_CARD_TYPE"
                onChanges={(value) => onChangeCardType && onChangeCardType(value)}
                value={cardType === "UZCARD" ? cardTypes[0] : cardTypes[1]}
              />
            </div>
            <Button
              className="position-absolute mt-6"
              style={{ right: "1px" }}
              size={SizeType.Medium}
              labelCode="TRANSFERS_SALARY_LIST_OF_CARD_BACK"
              onClick={backButton}
              type="button"
              color={ButtonColor.Orange}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
