import React from "react";
import { useField } from "formik";

import { PayrollStatusPicker, PayrollStatusPickerProps } from "../payroll/PayrollStatusPicker";

interface Props extends Omit<PayrollStatusPickerProps, "value"> {
  readonly name: string;
}

export function PayrollStatusPickerField({ name, onChange, ...props }: Props) {
  const [field, , helpers] = useField(name);

  return (
    <PayrollStatusPicker
      {...props}
      value={field.value}
      onChange={(x) => {
        if (onChange) {
          onChange(x);
        }

        helpers.setValue(x);
      }}
    />
  );
}
