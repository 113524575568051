import cx from "classnames";
import React, { useMemo } from "react";
import "./assets/badge.scss";

export enum BadgeColor {
  Danger = "danger",
  Primary = "primary",
  Inverse = "inverse",
}

interface Props {
  readonly size?: number;
  readonly value: number;
  readonly color?: BadgeColor;
  readonly className?: string;
}

export function Badge({ size = 20, color = BadgeColor.Primary, value, className }: Props) {
  const title = useMemo(() => Math.min(value, 999), [value]);

  if (value <= 0) {
    return null;
  }

  return (
    <span
      style={{
        minWidth: size,
        height: size,
        borderRadius: 5,
      }}
      className={cx(
        "badge p-1 d-inline-flex align-items-center justify-content-center fs-3",
        {
          "red-color-for-badge": color === BadgeColor.Danger,
          "badge-primary": color === BadgeColor.Primary,
          "bg-white text-primary": color === BadgeColor.Inverse,
        },
        className,
      )}
    >
      {value > 999 ? `999+` : title}
    </span>
  );
}
