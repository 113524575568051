import React from "react";
import { Form, Formik } from "formik";

import { DatePickerField } from "../form/DatePickerField";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { AccountInquiryFilterFormProps } from "../../api/account/AccountDTO";

interface Props {
  readonly initialValues: AccountInquiryFilterFormProps;
  readonly onSubmit: (values: AccountInquiryFilterFormProps) => void;
}

export function InquiryFilterForm({ initialValues, onSubmit }: Props) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <Form className="d-flex flex-column flex-lg-row">
          <DatePickerField
            name="fromDate"
            width={168}
            className="mr-3 my-2 my-lg-0"
            size={SizeType.Small}
            onChange={() => handleSubmit()}
            labelPosition={PositionType.Left}
            selectsStart={true}
            maxDate={values.toDate}
            label="ACCOUNT_INQUIRY_FILTER_DATE_FROM_FIELD_LABEL_TITLE"
          />
          <DatePickerField
            name="toDate"
            width={168}
            className=" my-2 my-lg-0"
            size={SizeType.Small}
            onChange={() => handleSubmit()}
            labelPosition={PositionType.Left}
            selectsEnd={true}
            minDate={values.fromDate}
            label="ACCOUNT_INQUIRY_FILTER_TO_FIELD_LABEL_TITLE"
          />
        </Form>
      )}
    </Formik>
  );
}
