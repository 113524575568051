import "./assets/auth-form.scss";

import React from "react";
import { Formik } from "formik";

import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";
import { AppClock } from "../app/AppClock";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly className?: string;
  readonly onSubmit: () => void;
}

export function SessionExpiredModal({ onSubmit }: Props) {
  const { logout } = useAuthContext();
  const { translate } = useI18n();
  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {() => (
        <div className="w-100">
          <form className="d-flex flex-column align-items-center auth-form">
            <div className="mt-4">
              <AppClock initialTime={60} onComplete={logout} />
            </div>
            <div className="mt-1 fs-5">{translate("CONFIRM_TEXT")}</div>
            <div className="d-flex w-100">
              <Button
                labelCode="EXIT_SESSION"
                className="w-50 m-4 text-white rounded border-0"
                size={SizeType.Medium}
                onClick={logout}
                color={ButtonColor.Danger}
                type="button"
              />
              <Button
                labelCode="CONFIRM_SESSION"
                className="w-50 m-4 text-white rounded border-0"
                size={SizeType.Medium}
                onClick={onSubmit}
                color={ButtonColor.Orange}
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}
