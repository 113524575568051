import "./assets/mobile-menu-buttons.scss";

import React from "react";

import { MobileMenu } from "../api/dto/AppDTO";
import MenuItemIcon from "./MenuItemIcon";

interface Props {
  readonly type: MobileMenu;
  readonly onClick: (type: MobileMenu) => void;
}

export function MobileMenuOpenButton({ onClick }: Props) {
  return (
    <div
      role="button"
      className="mobile-menu-open-button"
      onClick={() => onClick(MobileMenu.Opened)}
    >
      <MenuItemIcon type="for-header" width={12} height={10} />
    </div>
  );
}
