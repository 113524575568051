export const getIdnInfo = (code) => {
  const tinOfBank = code.substring(0, 9);
  const tinOfCustomer = code.substring(9, 18);
  const contractNumber = code.substring(18);
  return {
    tinOfBank,
    tinOfCustomer,
    contractNumber,
  };
};
