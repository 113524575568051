import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { toFinite } from "lodash";
import { TransferType } from "../../api/transfer/TransferDTO";
import { FileControls } from "../ui/FileControls";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly data: any[];
  readonly transferType: TransferType;
  readonly onSaveToExcel?: (value) => void;
  readonly onPrint?: (value) => void;
}

export function PayrollViewTable({ data = [], transferType, onPrint, onSaveToExcel }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 50,
        accessor: "id",
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{toFinite(row.row.id) + 1}</span>,
        Header: "#",
      },
      {
        width: 100,
        accessor: "accountNumber",
        Header: translate(
          "SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_FROM_SYSTEM_COLUMN_TITLE",
        ),
      },
      {
        width: 180,
        accessor: "fullName",
        Header:
          transferType === TransferType.Salary
            ? translate("SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE")
            : translate("SALARY_CREATE_NEW_REGISTER_TABLE_COMPANY_NAME_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "amount",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_AMOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div className="text-center">{floatFormat(addZeros(row.value))}</div>,
      },
      {
        width: 100,
        accessor: "salaryStatus",
        Header: translate("TRANSFERS_SALARY_PAYROLL_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
    ];
    return list;
  }, [transferType, translate]);
  return (
    <>
      {onPrint && onSaveToExcel ? (
        <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center mb-4">
          <FileControls
            onPrintClick={() => onPrint(data)}
            onSaveToExcelClick={() => onSaveToExcel(data)}
            isMinusTransferLcyType={true}
          />
        </div>
      ) : (
        <div />
      )}
      <TabPage>
        <Table data={data} columns={columns} showEmptyRow={true} />
      </TabPage>
    </>
  );
}
