import React, { useCallback, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router";
import { toFinite } from "lodash";

import { SizeType, ViewMode } from "../../api/dto/AppDTO";
import { useQuery } from "../../hooks/useQuery";
import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { Tabs } from "../../components/tabs/Tabs";
import { Badge } from "../../components/ui/Badge";
import { TabPane } from "../../components/tabs/TabPane";
import { Checkbox } from "../../components/ui/Checkbox";
import { Paginator } from "../../components/ui/Paginator";
import { SelectPicker } from "../../components/ui/SelectPicker";
import { Button, ButtonColor } from "../../components/ui/Button";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { SalaryTableWrapper } from "../../components/salary/SalaryTableWrapper";
import { CorporateAppLayout } from "../../components/app/CorporateAppLayout";
import { TransfersLCYTableWrapper } from "../../components/pending-approvals/TransfersLCYTableWrapper";
import { TransfersFCYTableWrapper } from "../../components/pending-approvals/TransfersFCYTableWrapper";
import { CorporateCardsTableWrapper } from "../../components/pending-approvals/CorporateCardsTableWrapper";
import { ConversionTableWrapper } from "../../components/pending-approvals/ConversionTableWrapper";
import { PendingApprovalsFilter, PendingApprovalsTabs } from "../../filters/PendingApprovalsFilter";
import { AccountTab } from "../../components/pending-approvals/AccountTab";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { useAccountContext } from "../../api/account/AccountContext";
import { TabPage } from "../../components/tabs/TabPage";
import { Modal } from "../../components/ui/Modal";
import { CheckOtpModal } from "../../components/salary/CheckOtpModal";
import { Routes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector, viewModeSelector } from "../../reducers/appReducer";
import { showError } from "../../utils/NotificationUtils";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import { GetTransferListForApprovalProps } from "../../api/transfer/TransferDTO";
import { TradeFinanceTab } from "../../components/pending-approvals/TradeFinanceTab";
import { PaymentClaimTab } from "../../components/pending-approvals/PaymentClaimTab";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

export enum PendingTabs {
  Salary = "salary",
  Accounts = "accounts",
  TransfersLCY = "transfersLCY",
  TransfersFCY = "transfersFCY",
  Conversion = "conversion",
  CorporateCards = "corporate-cards",
  SentPaymentClaim = "sent-payment-claim",
  TradeFinance = "Trade Finance",
  PaymentClaim = "payment-claim",
}

interface Params {
  readonly tab?: PendingTabs;
}

export function CorporatePendingApprovalsContainer() {
  const query = useQuery();
  const locationHelpers = useLocationHelpers();
  const { AccountApi } = useAccountContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const { TransferApi } = useTransferContext();

  const { translate } = useI18n();

  const isMobile = CheckMobileUtils();

  const filter = useMemo(() => new PendingApprovalsFilter(query), [query]);

  const accountFilter = useMemo(() => new AccountsFilter(query), [query]);

  const { tab = PendingTabs.TransfersLCY } = useParams<Params>();

  const [checkOtp, setCheckOtp] = useState(false);

  const [documentsId, setDocumentsId] = useState([]);

  const [job, setJob] = useState("");

  const [appTypes, setAppTypes] = useState("");

  const [withCreatorsAndApprovals, setWithCreatorsAndApprovals] = useState(false);

  const checkWithCreatorsAndApprovals = useCallback(
    () => setWithCreatorsAndApprovals(!withCreatorsAndApprovals),
    [setWithCreatorsAndApprovals, withCreatorsAndApprovals],
  );

  const getTotalApprovalApl = useResource(() => AccountApi.getTotalPendingApplications(), []);
  const totalApp = useMemo(() => getTotalApprovalApl.data, [getTotalApprovalApl.data]);

  const setDocumentId = useCallback((value, appType) => {
    setDocumentsId(value);
    setAppTypes(appType);
  }, []);
  const onDoneJob = useCallback(
    (value) => {
      setLoading(true);
      const data = {
        approvalType: appTypes,
        state: job,
        otpKey: value.token,
        rowsCount: documentsId.length.toString(),
      };
      AccountApi.approveOrRejectDoc({
        documentIds: documentsId.map((x: any) =>
          // @ts-ignore
          x.discrepancyId
            ? x.discrepancyId
            : x.applicationId
            ? x.applicationId
            : x.id
            ? x.id
            : x.documentId
            ? x.documentId
            : x.payrollId
            ? x.payrollId
            : x.ibid
            ? x.ibid
            : x.senderId
            ? x.senderId
            : x.accountOpenId,
        ),
        ...data,
      })
        .then((r) => {
          if (r.otpCheckFlag === "02") {
            NotificationManager.error(translate("WRONG_OTP_KEY"), "OTP", 5000);
          } else if (r.status.filter((x) => x === "EXPIRED").length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_EXPIRED_ERROR"), "", 5000);
            setCheckOtp(false);
          } else if (r.status.filter((x) => x === null).length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_NULL_ERROR"), "", 5000);
            setCheckOtp(false);
          } else {
            location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
    [AccountApi, appTypes, documentsId, job, translate],
  );
  const viewMode = useShallowEqualSelector(viewModeSelector);

  // show list for total amounts
  const [list, setList] = useState<GetTransferListForApprovalProps[]>([]);

  const language = useShallowEqualSelector(appLanguageSelector);

  return (
    <>
      <CorporateAppLayout>
        <Tabs
          activeTab={tab}
          defaultTab={PendingTabs.TransfersLCY}
          className="d-flex flex-grow-1 flex-shrink-1 bg-white pt-2"
          footerHeight={isMobile ? 180 : 90}
          footerActionsComponent={
            filter.getTab() === PendingApprovalsTabs.SentPaymentClaim ? (
              <div className="d-flex justify-content-end flex-1 pr-7">
                <Paginator filter={filter} />
              </div>
            ) : (
              <div className="d-flex flex-lg-row flex-column flex-grow-1 flex-shrink-1 justify-content-between px-lg-7 px-lg-0 px-4 py-3">
                {viewMode === ViewMode.Full ? (
                  <div className="d-flex align-items-lg-center align-items-start flex-lg-row flex-column">
                    <span className="mr-lg-2 text-nowrap">
                      {translate("PENDING_APPROVALS_SELECT_REQUEST_TITLE")}
                    </span>
                    <SelectPicker
                      className="mr-2 w-100 w-lg-0"
                      size={SizeType.Small}
                      options={[
                        { label: translate("PENDING_APPROVAL_APPROVE"), value: "105" },
                        { label: translate("PENDING_APPROVAL_REJECT"), value: "106" },
                      ]}
                      onChange={(value) => setJob(value.value)}
                    />
                    <Button
                      color={ButtonColor.Orange}
                      style={{
                        width: language === AppLanguage.Uzbek ? "200px" : "auto",
                      }}
                      size={SizeType.Small}
                      className="my-lg-0 my-3"
                      onClick={() => {
                        if (documentsId.length > 0) {
                          if (job !== "") {
                            setCheckOtp(true);
                          } else {
                            NotificationManager.error(
                              translate("PENDING_APPROVAL_JOB_CHOOSE"),
                              translate("SIDE_MENU_PENDING_APPROVALS_TITLE"),
                            );
                          }
                        } else {
                          NotificationManager.error(
                            translate("PENDING_APPROVAL_DOCUMENT_CHOOSE"),
                            translate("SIDE_MENU_PENDING_APPROVALS_TITLE"),
                          );
                        }
                      }}
                    >
                      {translate("ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE")}
                    </Button>
                    {appTypes === "TL" && (
                      <div className="font-weight-bold ml-lg-2 mb-lg-0 mb-2 text-nowrap">
                        {translate("SALARY_TOTAL_AMOUNT")}:{" "}
                        {list.length > 0
                          ? floatFormat(
                              addZeros(
                                list
                                  .reduce(
                                    (partialSum, a) => +partialSum + +a.amount,
                                    0,
                                  )
                                  .toFixed(2),
                              ),
                            )
                          : 0}
                      </div>
                    )}
                  </div>
                ) : (
                  <div />
                )}
                <Paginator filter={filter} />
              </div>
            )
          }
          headerActionsComponent={
            <div className="d-flex flex-shrink-1 flex-grow-1 justify-content-between align-items-center">
              {/* {filter.getTab() === PendingApprovalsTabs.SentPaymentClaim ? (
                <SentPaymentClaimsForm />
              ) : (
                <div className="d-flex flex-grow-1  align-items-center justify-content-end mr-7">
                  <Checkbox
                    value={withCreatorsAndApprovals}
                    onClick={checkWithCreatorsAndApprovals}
                    label="PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE"
                  />
                </div>
              )} */}
              <div className="d-flex flex-grow-1  align-items-center justify-content-end mr-7">
                <Checkbox
                  value={withCreatorsAndApprovals}
                  onClick={checkWithCreatorsAndApprovals}
                  label="PENDING_APPROVALS_SHOW_CREATORS_CHECKBOX_TITLE"
                />
              </div>
            </div>
          }
          onChangeTab={(tab: PendingTabs) => {
            history.replace(`${Routes.CorporatePendingApprovalRoute}/${tab}`);
            setWithCreatorsAndApprovals(false);
          }}
        >
          <TabPane
            key={PendingTabs.TransfersLCY}
            badge={<Badge value={toFinite(totalApp?.totalLcYtransfers)} className="bg-danger" />}
            tab={translate("PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE")}
          >
            <TransfersLCYTableWrapper
              filter={filter}
              setDocumentsId={setDocumentId}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
              setList={setList}
            />
          </TabPane>
          <TabPane
            key={PendingTabs.TransfersFCY}
            badge={<Badge value={toFinite(totalApp?.totalFcYtransfers)} className="bg-danger" />}
            tab={translate("PENDING_APPROVALS_TRANSFERS_TAB_FCY_TITLE")}
          >
            <TransfersFCYTableWrapper
              filter={filter}
              setDocumentsId={setDocumentId}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
            />
          </TabPane>
          <TabPane
            key={PendingTabs.Salary}
            badge={<Badge value={toFinite(totalApp?.totalSalaryPayrolls)} className="bg-danger" />}
            tab={translate("PENDING_APPROVALS_SALARY_TAB_TITLE")}
          >
            <SalaryTableWrapper
              filter={filter}
              setDocumentsId={setDocumentId}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
            />
          </TabPane>

          <TabPane
            key={PendingTabs.CorporateCards}
            tab={translate("PENDING_APPROVALS_CORPORATE_CARDS_TAB_TITLE")}
            badge={
              <Badge value={toFinite(totalApp?.totalCorporateCardPayrolls)} className="bg-danger" />
            }
          >
            <CorporateCardsTableWrapper
              filter={filter}
              setDocumentsId={setDocumentId}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
            />
          </TabPane>

          <TabPane
            key={PendingTabs.Conversion}
            tab={translate("PENDING_APPROVALS_CONVERSION_TAB_TITLE")}
            badge={<Badge value={toFinite(totalApp?.totalConversions)} className="bg-danger" />}
          >
            <ConversionTableWrapper setDocumentsId={setDocumentId} filter={filter} />
          </TabPane>
          <TabPane
            key={PendingTabs.Accounts}
            tab={translate("PENDING_APPROVALS_ACCOUNTS_TAB_TITLE")}
            badge={
              <Badge value={toFinite(totalApp?.totalRequestsForAccount)} className="bg-danger" />
            }
          >
            <AccountTab
              onChangeTab={(type) => locationHelpers.replaceQuery({ accountType: type })}
              approvalFilter={filter}
              filter={accountFilter}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
              loading={false}
              setDocumentsId={setDocumentId}
              totalApl={totalApp}
            />
          </TabPane>
          <TabPane
            key={PendingTabs.TradeFinance}
            tab={translate("PENDING_APPROVALS_TRADE_FINANCE_TAB_TITLE")}
            badge={
              <Badge
                value={toFinite(
                  Number(totalApp?.totalRequestsForTradeFinance) +
                    Number(totalApp?.totalRequestsForDiscrepancy) +
                    Number(totalApp?.totalRequestsForAmendment),
                )}
                className="bg-danger"
              />
            }
          >
            <TradeFinanceTab
              onChangeTab={(type) => locationHelpers.replaceQuery({ tradeFinanceType: type })}
              approvalFilter={filter}
              filter={accountFilter}
              setDocumentsId={setDocumentId}
              totalApl={totalApp}
            />
          </TabPane>
          <TabPane
            key={PendingTabs.PaymentClaim}
            tab={translate("TRANSFERS_PAYMENT_CLAIM_TAB_TITLE")}
            badge={
              <Badge
                value={toFinite(
                  Number(totalApp?.totalRequestsForPaymentClaimOutgoing) +
                    Number(totalApp?.totalRequestsForPaymentClaimIncoming),
                )}
                className="bg-danger"
              />
            }
          >
            <PaymentClaimTab
              approvalFilter={filter}
              filter={accountFilter}
              withCreatorsAndApprovals={withCreatorsAndApprovals}
              onChangeTab={(type) => locationHelpers.replaceQuery({ paymentClaimType: type })}
              loading={false}
              setDocumentsId={setDocumentId}
              totalApl={totalApp}
            />
          </TabPane>
        </Tabs>
      </CorporateAppLayout>
      <Modal onClose={() => setCheckOtp(false)} width={600} show={checkOtp} title={""}>
        <TabPage>
          <CheckOtpModal onSubmit={onDoneJob} job={appTypes} loading={loading} />
        </TabPage>
      </Modal>
    </>
  );
}
