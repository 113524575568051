import "./assets/sub-tabs.scss";

import React from "react";

import { Tabs, TabsProps } from "./Tabs";

export function SubTabs({ children, ...props }: TabsProps) {
  return (
    <Tabs
      stickTabs={true}
      className="sub-tabs"
      withTabHeader={false}
      subTabs={true}
      tabClassName="bg-secondary text-white"
      containerClassName="border border-top-0 border-gray-light br-bl-1 br-br-1"
      {...props}
    >
      {children}
    </Tabs>
  );
}
