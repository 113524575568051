import React from "react";

import { AppLayout } from "../../components/app/AppLayout";

export function NotFoundContainer() {
  return (
    <AppLayout contentClassName="align-items-center justify-content-center">
      <h1>Not Found</h1>
    </AppLayout>
  );
}
