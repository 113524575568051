import "./assets/print-table-form-container.scss";

import cx from "classnames";
import React, { ReactNode } from "react";

interface Props {
  readonly id: string;
  readonly children: ReactNode;

  readonly className?: string;
  readonly formClassName?: string;
}

export function PrintTableFormContainer({ id, children, className, formClassName }: Props) {
  return (
    <div className={cx("position-absolute print-table-form-container", className)}>
      <form className={cx("print-table-form", formClassName)} id={id}>
        {children}
      </form>
    </div>
  );
}
