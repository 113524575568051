import React, { useState } from "react";
import { Form, Formik } from "formik";

import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPickerField";
import { GroupBox } from "../ui/GroupBox";
import { useResource } from "../../hooks/useResource";
import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { useAccountContext } from "../../api/account/AccountContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  userAddressSelector,
  userEmailSelector,
  userFullNameSelector,
  userPhoneSelector,
} from "../../reducers/userReducer";
import { Button, ButtonColor } from "../ui/Button";
import { SizeType } from "../../api/dto/AppDTO";

interface Props {
  readonly onSubmit: (value) => void;
  readonly loading: boolean;
}

export enum AccountNameValues {
  UzCard = "22620",
  Visa = "22620",
}

export function ApplicationAccountOpeningFormIndividual({ loading, onSubmit }: Props) {
  const { AccountApi } = useAccountContext();
  const [accounts, setAccount] = useState({});
  const [branch, setBranch] = useState({});
  const { language } = useI18n();
  const fullName = useShallowEqualSelector(userFullNameSelector);
  const address = useShallowEqualSelector(userAddressSelector);
  const email = useShallowEqualSelector(userEmailSelector);
  const phone = useShallowEqualSelector(userPhoneSelector);

  const currencies = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "UZS", value: "UZS" },
  ];

  useResource(
    () =>
      AccountApi.getAvailableAccounts().then((r) => {
        setAccount([
          r.map((x) => ({
            label: `${x.accountCode} - ${
              language === AppLanguage.Russian
                ? x.nameRU
                : language === AppLanguage.English
                ? x.nameENG
                : x.nameUZ
            }`,
            value: x.accountCode,
          })),
        ]);
      }),
    [language],
  );

  useResource(
    () =>
      AccountApi.getBranchesInformation().then((r) => {
        setBranch(
          r.map((x) => ({
            label:
              language === AppLanguage.Russian
                ? x.nameRU
                : language === AppLanguage.English
                ? x.nameENG
                : x.nameUZ,
            value: x.branchCode,
          })),
        );
      }),
    [language],
  );

  return (
    <Formik
      initialValues={{
        addressOfTheClient: address,
        nameOfTheClient: fullName,
        email: email,
        phone: phone,
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="d-flex flex-column br-1 p-4 bg-white mb-7">
          <GroupBox
            className="h-100"
            title="ACCOUNT_INQUIRY_APPLICATION_FOR_ACCOUNT_OPENING_FORM_TITLE"
          >
            <div className="d-flex row">
              <div className="col-lg-3">
                <SelectPickerField
                  name="accountType"
                  options={accounts[0]}
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ACCOUNT_NAME_FIELD_LABEL_TITLE"
                />
              </div>
              <div className="col-lg-3">
                <SelectPickerField
                  name="currency"
                  // @ts-ignore
                  options={currencies}
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_CURRENCY_FIELD_LABEL_TITLE"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  className="mb-2"
                  name="addressOfTheClient"
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_ADDRESS_OF_THE_CLIENT_FIELD_LABEL_TITLE"
                />
              </div>
            </div>
            <div className="d-flex row">
              <div className="col-lg-3">
                <SelectPickerField
                  // @ts-ignore
                  options={branch}
                  className="mb-2"
                  name="nameOfTheBank"
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_BANK_FIELD_LABEL_TITLE"
                />
              </div>
              <div className="col-lg-3">
                <InputField
                  name="nameOfTheClient"
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_NAME_OF_THE_CLIENT_FIELD_LABEL_TITLE"
                />
              </div>
              <div className="col-lg-3">
                <InputField
                  name="email"
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_EMAIL_FIELD_LABEL_TITLE"
                />
              </div>
              <div className="col-lg-3">
                <InputField
                  name="phone"
                  label="ACCOUNT_INQUIRY_APPLICATION_OF_ACCOUNT_OPENING_FORM_PHONE_FIELD_LABEL_TITLE"
                />
              </div>
            </div>
          </GroupBox>
          <div className="row p-4">
            <Button
              type="submit"
              className="px-9"
              color={ButtonColor.Orange}
              labelCode="ACCOUNT_INQUIRY_NEW_ACCOUNT_OPENING_SUBMIT_BUTTON_TITLE"
              size={SizeType.Medium}
              disabled={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
