import React, { useCallback, useMemo } from "react";
import { TradeFinanceTable } from "./TradeFinanceTable";
import { useResource } from "../../hooks/useResource";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { useTradeFinanceContext } from "../../api/trade-finance/TradeFinanceContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";

interface Props {
  readonly filter?: PendingApprovalsFilter;
  readonly setDocumentsId?: (value, appType) => void;
}

export function TradeFinanceTableWrapper({ filter, setDocumentsId }: Props) {
  const { TradeFinanceApi } = useTradeFinanceContext();
  const getAppliaction = useResource(
    () =>
      TradeFinanceApi.getApprovalApplicationAmendment({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
        type: "01",
      }),
    [filter],
  );

  const data = useMemo(() => getAppliaction.data || [], [getAppliaction.data]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.TradeFinance);
    },
    [data, setDocumentsId],
  );

  return (
    <TradeFinanceTable
      data={data}
      loading={getAppliaction.loading}
      setDocumentsId={setDocumentId}
    />
  );
}
