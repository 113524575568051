import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { SwiftCoverTableDataProps } from "../../api/account/AccountDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly data: SwiftCoverTableDataProps[];
  readonly loading: boolean;
}

export function SwiftCoverTable({ data = [], loading }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SwiftCoverTableDataProps>[] = [
      {
        width: 30,
        accessor: "date",
        Header: translate("ACCOUNT_SWIFT_COVER_DATE_TITLE"),
      },
      {
        width: 30,
        accessor: "messageDetail32A",
        Header: translate("ACCOUNT_SWIFT_COVER_SUM_TITLE"),
      },
      {
        width: 30,
        accessor: "currency",
        Header: translate("ACCOUNT_SWIFT_COVER_CURRENCY_TITLE"),
      },
      {
        width: 60,
        accessor: "messageDetail50K",
        Header: translate("ACCOUNT_SWIFT_COVER_SENDER_TITLE"),
      },
      {
        width: 60,
        accessor: "messageDetail59",
        Header: translate("ACCOUNT_SWIFT_COVER_BEBEFICIARY_TITLE"),
      },
      {
        width: 40,
        accessor: "messageDetail70",
        Header: translate("ACCOUNT_SWIFT_COVER_PAYMENT_DETAILS_TITLE"),
      },
      {
        width: 40,
        accessor: "messageDetail72",
        Header: translate("ACCOUNT_SWIFT_COVER_SUM_ADDITIONAL_PAYMENT_DETAILS_TITLE"),
      },
      {
        width: 40,
        accessor: "account",
        Header: translate("ACCOUNT_SWIFT_COVER_ACCOUNT_FOR_RECEIPT_TITLE"),
      },
    ];

    return list;
  }, [translate]);

  return <Table data={data} loading={loading} columns={columns} tableClassName="bg-white" />;
}
