import React from "react";

import LogoImage from "./assets/logo.png";

interface Props {
  readonly className?: string;
}

export function BankLogo({ className }: Props) {
  return (
    <img
      alt="logo"
      width={427}
      height={53}
      src={LogoImage}
      draggable={false}
      className={className}
    />
  );
}
