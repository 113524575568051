import { useHistory, useParams } from "react-router";

import React, { useMemo } from "react";
import { Routes } from "../../constants/Routes";
import { useQuery } from "../../hooks/useQuery";
import { Tabs } from "../../components/tabs/Tabs";
import { TabPane } from "../../components/tabs/TabPane";
import { AccountsTabs } from "../../filters/AccountsFilter";
import { SalaryTab } from "../../components/salary/SalaryTab";
import { SampleTab } from "../../components/transfers/SampleTab";
import { ExcelTab } from "../../components/transfers/ExcelTab";
import { HistoryTab } from "../../components/transfers/HistoryTab";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { FavoritesTab } from "../../components/transfers/FavoritesTab";
import { CorporateAppLayout } from "../../components/app/CorporateAppLayout";
import { CorporateCardTab } from "../../components/transfers/CorporateCardTab";
import { TransfersFCYTabWrapper } from "../../components/transfers/TransfersFCYTabWrapper";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { CorrespondentsTabWrapper } from "../../components/transfers/CorrespondentsTabWrapper";
import { TransfersLCYTabWrapper } from "../../components/transfers/TransfersLCYTabWrapper";
import {
  accessToCardReplenishment,
  accessToFcyTransfer,
  accessToLcyTransfer,
  setAuthDate,
} from "../../reducers/authReducer";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { CreateNewDocumentRoles } from "../../api/dto/AppDTO";

export enum TransfersTabs {
  Salary = "salary",
  History = "history",
  Samples = "samples",
  Excel = "excel",
  TransfersLCY = "transfersLCY",
  TransfersFCY = "transfersFCY",
  Favorites = "favorites",
  CorporateCard = "corporate-card",
  Correspondents = "correspondents",
  TransfersToAnotherClient = "transfers-to-another-client",
  TransfersBetweenMyAccounts = "transfers-between-my-accounts",
}

interface Params {
  readonly tab?: TransfersTabs;
}

export function CorporateTransfersContainer() {
  const history = useHistory();

  const query = useQuery();

  const locationHelpers = useLocationHelpers();
  const filter = useMemo(() => new TransfersFilter(query), [query]);
  const dispatch = useDispatch();
  const accessToCard = useShallowEqualSelector(accessToCardReplenishment);
  const accessToFcyTransferTab = useShallowEqualSelector(accessToFcyTransfer);
  const accessToLcyTransferTab = useShallowEqualSelector(accessToLcyTransfer);
  const {
    tab = accessToLcyTransferTab === CreateNewDocumentRoles.No
      ? accessToFcyTransferTab === CreateNewDocumentRoles.No
        ? accessToCard === CreateNewDocumentRoles.No
          ? TransfersTabs.History
          : TransfersTabs.Salary
        : TransfersTabs.TransfersFCY
      : TransfersTabs.TransfersLCY,
  } = useParams<Params>();
  return (
    <CorporateAppLayout>
      <Tabs
        activeTab={tab}
        className="bg-white pt-2"
        defaultTab={
          accessToLcyTransferTab === CreateNewDocumentRoles.No
            ? accessToFcyTransferTab === CreateNewDocumentRoles.No
              ? accessToCard === CreateNewDocumentRoles.No
                ? TransfersTabs.History
                : TransfersTabs.Salary
              : TransfersTabs.TransfersFCY
            : TransfersTabs.TransfersLCY
        }
        onChangeTab={(tab: TransfersTabs) => {
          history.replace(`${Routes.CorporateTransfersRoute}/${tab}`);
          dispatch(setAuthDate({ authDate: Date.now() }));
        }}
      >
        {accessToLcyTransferTab === CreateNewDocumentRoles.Yes ? (
          <TabPane key={TransfersTabs.TransfersLCY} tab="TRANSFERS_TRANSFERS_TAB_LCY_TITLE">
            <TransfersLCYTabWrapper filter={filter} />
          </TabPane>
        ) : (
          <div />
        )}
        {accessToFcyTransferTab === CreateNewDocumentRoles.Yes ? (
          <TabPane key={TransfersTabs.TransfersFCY} tab="TRANSFERS_TRANSFERS_TAB_FCY_TITLE">
            <TransfersFCYTabWrapper filter={filter} />
          </TabPane>
        ) : (
          <div />
        )}
        {accessToCard === CreateNewDocumentRoles.Yes ? (
          <TabPane key={TransfersTabs.Salary} tab="TRANSFERS_SALARY_TAB_TITLE">
            <SalaryTab
              filter={filter}
              onCreateClick={() => locationHelpers.replaceQuery({ create: true })}
              onUploadFile={() => locationHelpers.replaceQuery({ uploadFile: true })}
              onRegistrationClick={() => locationHelpers.replaceQuery({ registerSalary: true })}
              onGetListOfCardHoldersClick={() =>
                locationHelpers.replaceQuery({ listOfCardHolders: true })
              }
            />
          </TabPane>
        ) : (
          <div />
        )}
        {accessToCard === CreateNewDocumentRoles.Yes ? (
          <TabPane key={TransfersTabs.CorporateCard} tab="TRANSFERS_CORPORATE_CARD_TAB_TITLE">
            <CorporateCardTab
              filter={filter}
              onCreateClick={() => locationHelpers.replaceQuery({ create: true })}
              onRegistrationClick={() =>
                locationHelpers.pushLocation({
                  pathname: Routes.CorporateAccounts,
                  query: { tab: AccountsTabs.NewAccountOpening },
                })
              }
              onGetListOfCardHoldersClick={() =>
                locationHelpers.replaceQuery({ listOfCardHolders: true })
              }
            />
          </TabPane>
        ) : (
          <div />
        )}
        <TabPane key={TransfersTabs.History} tab="TRANSFERS_HISTORY_TAB_TITLE">
          <HistoryTab filter={filter} />
        </TabPane>
        <TabPane key={TransfersTabs.Favorites} tab="TRANSFERS_FAVORITES_TAB_TITLE">
          <FavoritesTab />
        </TabPane>
        <TabPane key={TransfersTabs.Correspondents} tab="TRANSFERS_CORRESPONDENTS_TAB_TITLE">
          <CorrespondentsTabWrapper />
        </TabPane>
        <TabPane key={TransfersTabs.Samples} tab="TRANSFERS_SAMPLES_TAB_TITLE">
          <SampleTab />
        </TabPane>

        {
          accessToCard === CreateNewDocumentRoles.Yes ? (
            <TabPane key={TransfersTabs.Excel} tab="TRANSFERS_SAMPLES_TAB_IMPORT_FROM_EXCEL">
              <ExcelTab />
            </TabPane>
          ) : (
            <div />
          )
        }

      </Tabs>
    </CorporateAppLayout>
  );
}
