import { useField } from "formik";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { useResource } from "../../hooks/useResource";
import { TransferType } from "../../api/transfer/TransferDTO";
import { useSalaryContext } from "../../api/salary/SalaryContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { SelectPicker, SelectPickerProps } from "../ui/SelectPicker";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  setTransitAccountNumbers,
  transitAccountNumbersSelector,
} from "../../reducers/accountsReducer";

interface Props extends Omit<SelectPickerProps, "value"> {
  readonly name: string;
  readonly transferType: TransferType;
  readonly defaultValue?: any;
}

import uzcardIcon from "../icons/uzcard-icon.jpg";
import humoIcon from "../icons/humo-icon.jpg";

export function TransitAccountNumberFieldWrapper({
  name,
  onChange,
  transferType,
  defaultValue,
  ...props
}: Props) {
  const [field, , helpers] = useField(name);

  const dispatch = useDispatch();

  const list = useShallowEqualSelector(transitAccountNumbersSelector(transferType));

  const { SalaryApi } = useSalaryContext();

  const accountNumbersResource = useResource(
    () => SalaryApi.getTransitAccountNumbers({ transferType }),
    [],
  );

  useResourceHandler(accountNumbersResource, {
    onSuccess: (data = []) =>
      dispatch(
        setTransitAccountNumbers({
          list: data,
          transferType,
        }),
      ),
  });

  const transitNumbersList = useMemo(() => {
    const { data = [] } = accountNumbersResource;

    if (list.length === 0) {
      return data;
    }

    return list;
  }, [list, accountNumbersResource]);

  const formattedList = useMemo(
    () =>
      transitNumbersList.map((x) => ({
        value: x.transitAccount,
        label: x.transitAccount,
        balance: x.accountBalance,
        image: x.transitAccount.substr(17, 1) !== "0" ? humoIcon : uzcardIcon,
      })),
    [transitNumbersList],
  );

  const loading = useMemo(() => formattedList.length === 0 && accountNumbersResource.loading, [
    formattedList,
    accountNumbersResource.loading,
  ]);

  return (
    <SelectPicker
      {...props}
      defaultValue={defaultValue}
      fluid={true}
      value={field.value}
      isLoading={loading}
      options={formattedList}
      onChange={(x) => {
        if (onChange) {
          onChange(x);
        }

        helpers.setValue(x);
      }}
    />
  );
}
