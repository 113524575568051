import React, { useEffect, useMemo } from "react";
import { SwiftTable } from "./SwiftTable";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { AccountsFilter, SwiftInnerTabType } from "../../filters/AccountsFilter";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { NotificationManager } from "react-notifications";
import { useI18n } from "../../i18n/I18nContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userRoleSelector } from "../../reducers/userReducer";
import { RoleType } from "../../api/dto/AppDTO";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly withCreatorsAndApprovals?: boolean;
  readonly filter: AccountsFilter;
}

export function SwiftTableWrapper({ withCreatorsAndApprovals, filter }: Props) {
  const { AccountApi } = useAccountContext();
  const { translate } = useI18n();
  const dispatch = useDispatch();

  const roleType = useShallowEqualSelector(userRoleSelector);

  const swiftInnerType = useMemo(
    () =>
      roleType === RoleType.Corporate
        ? filter.getSwiftInnerType()
        : SwiftInnerTabType.IncomingCover,
    [filter, roleType],
  );

  const getAccountForApprovalList = useResource(
    () =>
      filter.swiftInnerType === "outgoing"
        ? AccountApi.getAccountSwiftOutgoing(filter.getSwiftOutgoingListFilter())
        : AccountApi.getAccountSwiftIncoming(filter.getSwiftIncomingListFilter()),
    [filter],
  );

  const data = useMemo(
    () => (getAccountForApprovalList.data && getAccountForApprovalList.data.data) || [],
    [getAccountForApprovalList.data],
  );

  useResourceHandler(getAccountForApprovalList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  useEffect(() => {
    if (getAccountForApprovalList.loading && swiftInnerType === SwiftInnerTabType.IncomingCover) {
      NotificationManager.info(
        translate("TRANSFER_LOADING"),
        translate("TRANSFER_NOTIFICATION_TITLE"),
        10000,
      );
    }
  }, [getAccountForApprovalList, swiftInnerType, translate]);

  return (
    <SwiftTable
      data={data}
      withCreatorsAndApprovals={withCreatorsAndApprovals}
      filter={filter}
      loading={getAccountForApprovalList.loading}
    />
  );
}
