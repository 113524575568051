import "./assets/responsive.scss";
import React from "react";

interface Props {
  readonly isActive?: boolean;
  readonly type: string;
  readonly width?: number;
  readonly height?: number;
  readonly size?: number;
}

function MenuItemIcon({ isActive, type, width, height, size }: Props) {
  return (
    <>
      {type === "for-item" && (
        <div
          className={`custom-menu-item-icon ${isActive ? "active-item-icon" : ""}`}
          style={{ width: `${size}px`, height: `${size}px` }}
        ></div>
      )}
      {type === "for-header" && (
        <div
          className={`custom-menu-header-icon`}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <div className="custom-menu-header-icon-item"></div>
          <div className="custom-menu-header-icon-item"></div>
          <div className="custom-menu-header-icon-item"></div>
        </div>
      )}
    </>
  );
}

export default MenuItemIcon;
