import React, { useCallback, useMemo } from "react";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { SentPaymentClaimTable } from "./SentPaymentClaimTable";
import { usePaymentContext } from "../../api/payment/PaymentContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: PendingApprovalsFilter;
  readonly setDocumentsId: (value, appType) => void;
  readonly withCreatorsAndApprovals?: boolean;
}

export function SentPaymentClaimTableWrapper({
  filter,
  setDocumentsId,
  withCreatorsAndApprovals,
}: Props) {
  const { PaymentApi } = usePaymentContext();
  const dispatch = useDispatch();
  const getApprovalSentPaymentClaimList = useResource(
    () =>
      PaymentApi.getSentPaymentClaimsApprovalList({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [filter],
  );
  const data = useMemo(() => getApprovalSentPaymentClaimList.data || [], [
    getApprovalSentPaymentClaimList.data,
  ]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.SentPaymentClaim);
    },
    [data, setDocumentsId],
  );

  useResourceHandler(getApprovalSentPaymentClaimList, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  return (
    <SentPaymentClaimTable
      data={data}
      loading={getApprovalSentPaymentClaimList.loading}
      setDocumentId={setDocumentId}
      withCreatorsAndApprovals={withCreatorsAndApprovals}
    />
  );
}
