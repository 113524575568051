import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function HelpIcon({ size = 20, color = ColorPalette.BlueExtraLight, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20.021 20.021"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(-190.911 -190.911)"
        d="M210.932,204.46a6.491,6.491,0,0,0-3.56-5.78,8.827,8.827,0,0,1-8.692,8.692,6.474,6.474,0,0,0,9.073,2.66l3.15.871-.871-3.15a6.448,6.448,0,0,0,.9-3.293Zm0,0"
      />
      <path
        fill={color}
        d="M15.289,7.645A7.645,7.645,0,1,0,1.06,11.53L.028,15.261l3.731-1.032a7.646,7.646,0,0,0,11.53-6.585ZM6.471,5.865H5.3A2.346,2.346,0,1,1,9.228,7.6l-1,.912v.915H7.058V7.992L8.436,6.731a1.161,1.161,0,0,0,.382-.866,1.173,1.173,0,1,0-2.346,0ZM7.058,10.6H8.231V11.77H7.058Zm0,0"
      />
    </svg>
  );
}
