import "./assets/side-menu-item.scss";

import cx from "classnames";
import React, { ReactNode, useState } from "react";
import { Transition } from "react-transition-group";
import { LinkProps, NavLink } from "react-router-dom";

import { Tooltip } from "../tooltip/Tooltip";
import { TooltipItem } from "../tooltip/TooltipItem";
import { useTheme } from "../../theme/ThemeContext";
import { setAuthDate } from "../../reducers/authReducer";
import { useDispatch } from "react-redux";

const defaultStyles = {
  opacity: 1,
};

const transitionStyles = {
  exiting: { opacity: 0 },
  entered: { opacity: 1 },
  entering: { opacity: 1 },
  exited: { opacity: 0, height: 29 },
};

interface Props extends LinkProps {
  readonly id?: string;
  readonly icon: ReactNode;
  readonly children: string;
  readonly badge?: ReactNode;
  readonly isExpanded?: boolean;
}

export function SideMenuItem({ id, children, icon, badge, isExpanded, ...linkProps }: Props) {
  const [show, setShow] = useState(false);
  const currentTheme = useTheme();
  const dispatch = useDispatch();

  return (
    <>
      <Transition in={isExpanded} timeout={250}>
        {(state) => (
          <NavLink
            id={id}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            activeClassName={cx("font-weight-bold side-menu-tab", {
              "side-menu-tab-dark-gray": currentTheme.theme === "dark-gray",
              "side-menu-tab-light-gray": currentTheme.theme === "light-gray",
            })}
            className={cx(
              "d-flex flex-column pl-1 text-white text-decoration-none py-2 side-menu-item",
              {
                "menu-item-default": currentTheme.theme === "default",
                "menu-item-dark-gray": currentTheme.theme === "dark-gray",
                "menu-item-light-gray": currentTheme.theme === "light-gray",
              },
            )}
            onClick={() => dispatch(setAuthDate({ authDate: Date.now() }))}
            {...linkProps}
          >
            <div className="d-flex flex-1 mx-5 align-items-center">
              <div className="d-flex align-items-center justify-content-center item-icon-container">
                {icon}
              </div>

              <div
                className="d-flex flex-1 item-title-container"
                style={{
                  ...defaultStyles,
                  ...transitionStyles[state],
                }}
              >
                <div className="flex-shrink-1 flex-grow-1 mx-4">
                  <span className="mw-100 item-title">{children}</span>
                </div>

                {badge}
              </div>
            </div>
          </NavLink>
        )}
      </Transition>

      {Boolean(!isExpanded && id) && (
        <>
          <TooltipItem id={`${id}Item`} title={children} show={show} />
          <Tooltip
            buttonId={id!}
            tooltipId={`${id}Item`}
            options={{
              placement: "right",
              modifiers: [{ name: "offset", options: { offset: [0, -176] } }],
            }}
          />
        </>
      )}
    </>
  );
}
