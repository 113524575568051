import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function AccountIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.291 19.291"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M9.645,0a9.645,9.645,0,1,0,9.645,9.645A9.674,9.674,0,0,0,9.645,0Zm0,2.894a2.842,2.842,0,0,1,2.894,2.894A2.842,2.842,0,0,1,9.645,8.681,2.842,2.842,0,0,1,6.752,5.787,2.842,2.842,0,0,1,9.645,2.894Zm0,13.7A7.037,7.037,0,0,1,3.858,13.5c0-1.929,3.858-2.99,5.787-2.99s5.787,1.061,5.787,2.99A7.037,7.037,0,0,1,9.645,16.59Z"
      />
    </svg>
  );
}
