import React from "react";

import { AppHeader } from "./AppHeader";
import { userSelector } from "../../reducers/userReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";
import { AppMobileHeader } from "../../responsive/AppMobileHeader";

export function AppHeaderWrapper() {
  const user = useShallowEqualSelector(userSelector);
  const chek = CheckMobileUtils();

  return <>{!chek ? <AppHeader user={user} /> : <AppMobileHeader />}</>;
}
