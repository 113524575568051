import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
}

export function LoanIcon({ size = 20, color = ColorPalette.BlueExtraLight, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0)">
        <path
          fill={color}
          transform="translate(-97.174 -0.01)"
          d="M103.418,10.821a11.778,11.778,0,0,1,2.121-.081A7.248,7.248,0,0,1,107.13.01a6.071,6.071,0,0,0-3.712,10.811Z"
        />
        <path
          fill={color}
          transform="translate(-192.207)"
          d="M202.232,10.731h1.582a2.368,2.368,0,0,1,2.166,1.411,6.072,6.072,0,1,0-3.748-1.411Zm3.3-7.768v-.5a.588.588,0,1,1,1.176,0v.5a2.113,2.113,0,0,1,.827.149h0a2.187,2.187,0,0,1,.791.539.588.588,0,1,1-.858.805.979.979,0,0,0-.7-.316h-1.352a.671.671,0,0,0,0,1.342h1.4a1.851,1.851,0,1,1-.113,3.7v.5a.588.588,0,1,1-1.176,0v-.5a2.2,2.2,0,0,1-1.4-.393.589.589,0,0,1,.671-.968.991.991,0,0,0,.543.183h1.474a.671.671,0,0,0,0-1.342h-1.4a1.851,1.851,0,1,1,.113-3.7Z"
        />
        <path
          fill={color}
          transform="translate(0 -291.758)"
          d="M19.617,306.423a1.306,1.306,0,0,0-1.849,0l-1.919,1.921a1.122,1.122,0,0,1-.793.329h-5a1.311,1.311,0,0,1,0-2.623h1.522a1.207,1.207,0,0,0,1.215-1.164,1.191,1.191,0,0,0-1.19-1.22H7.234a4.888,4.888,0,0,0-3.762,1.769l-.709.9a.2.2,0,0,1-.154.075H.392A.392.392,0,0,0,0,306.8v4.561a.392.392,0,0,0,.392.393h15.15a1.435,1.435,0,0,0,1.015-.421l3.059-3.063a1.31,1.31,0,0,0,0-1.851Z"
        />
      </g>
    </svg>
  );
}
