import "./assets/input-control.scss";
import "react-datepicker/dist/react-datepicker.min.css";

import { enGB, ru, uz } from "date-fns/locale";

import cx from "classnames";
import React, { ReactNode, useMemo } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

import { I18nCode } from "../../i18n/I18nSchema";
import { AppLanguage, useI18n } from "../../i18n/I18nContext";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import MaskedInput from "react-maskedinput";

export interface DatePickerProps extends Omit<ReactDatePickerProps, "className"> {
  readonly fluid?: boolean;

  readonly width?: number;
  readonly minWidth?: number;

  readonly size?: SizeType;
  readonly hasError?: boolean;
  readonly className?: string;
  readonly leftIcon?: ReactNode;
  readonly rightIcon?: ReactNode;
  readonly inputClassName?: string;
  readonly label?: I18nCode | string;
  readonly labelPosition?: PositionType;
}

export function DatePicker({
  size = SizeType.Medium,
  fluid,
  label,
  width,
  minWidth = 120,
  hasError,
  leftIcon,
  className,
  rightIcon,
  labelPosition = PositionType.Top,
  inputClassName,
  ...props
}: DatePickerProps) {
  const { translate, language } = useI18n();

  const text = useMemo(() => {
    if (label) {
      return translate(label);
    }
  }, [label, translate]);
  const locale = useMemo(() => {
    if (language === AppLanguage.Russian) {
      return ru;
    }

    if (language === AppLanguage.Uzbek) {
      return uz;
    }

    return enGB;
  }, [language]);

  return (
    <div
      className={cx(
        "d-flex input-control",
        {
          "disabled-input": props.disabled,
          "flex-shrink-1 flex-grow-1": fluid,
          "flex-column": labelPosition === PositionType.Top,
          "flex-column-reverse": labelPosition === PositionType.Bottom,
          "flex-row align-items-center": labelPosition === PositionType.Left,
          "flex-row-reverse align-items-center": labelPosition === PositionType.Right,
        },
        className,
      )}
    >
      {Boolean(text) && (
        <label
          className={cx("text-nowrap", {
            "mb-1": labelPosition === PositionType.Top,
            "mr-2 mb-0": labelPosition === PositionType.Left,
            "ml-2 mb-0": labelPosition === PositionType.Right,
            "mt-2 mb-0": labelPosition === PositionType.Bottom,
          })}
        >
          {text}
        </label>
      )}

      <div
        style={{ width: `${width}px`, minWidth: `${minWidth}px` }}
        className={cx("d-flex form-control py-0 px-2 br-1", {
          "is-invalid": hasError,
          "disabled-input": props.disabled,
          "form-control-sm": size === SizeType.Small,
          "form-control-lg": size === SizeType.Large,
          "border border-gray-light text-gray": !hasError,
        })}
      >
        {Boolean(leftIcon) && (
          <div
            className={cx("d-flex align-items-center justify-content-center icon ml-1", {
              "icon-sm": size === SizeType.Small,
              "icon-lg": size === SizeType.Large,
            })}
          >
            {leftIcon}
          </div>
        )}

        <div className="d-flex flex-shrink-1 flex-grow-1">
          <ReactDatePicker
            locale={locale}
            dateFormat="dd.MM.yyyy"
            customInput={<MaskedInput mask="11.11.1111" />}
            {...props}
            wrapperClassName={cx("d-flex w-100", props.wrapperClassName)}
            className={cx(
              "w-100 h-100 outline-none border-0 bg-transparent text-gray",
              inputClassName,
            )}
          />
        </div>

        {Boolean(rightIcon) && (
          <label
            htmlFor={props.id}
            className={cx("d-flex align-items-center justify-content-center icon ml-1", {
              "icon-sm": size === SizeType.Small,
              "icon-lg": size === SizeType.Large,
            })}
          >
            {rightIcon}
          </label>
        )}
      </div>
    </div>
  );
}
