import React from "react";
import { useDispatch } from "react-redux";

import { MenuStateButton } from "./MenuStateButton";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { changeMenuState, menuStateSelector } from "../../reducers/appReducer";

export function MenuStateButtonWrapper() {
  const dispatch = useDispatch();

  const menuState = useShallowEqualSelector(menuStateSelector);

  return (
    <MenuStateButton state={menuState} onClick={(state) => dispatch(changeMenuState({ state }))} />
  );
}
