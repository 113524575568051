import React, { MutableRefObject } from "react";
import "./assets/lcy-create-forms.scss";
import { Input } from "../ui/Input";
import { Form, Formik } from "formik";
import { LcyTransferFormProps } from "../../api/transfer/TransferDTO";
import { noop } from "lodash";
import { InputField } from "../form/InputField";
import { TextAreaField } from "../form/TextAreaField";
import { useI18n } from "../../i18n/I18nContext";
import { formatDateToBody } from "../../utils/DateUtils";
// import { TextArea } from "../ui/TextArea";

interface Props {
  readonly ref?: MutableRefObject<any>;
  readonly initialValue: LcyTransferFormProps;
}

export function TransferLcyStandartPrint({ ref, initialValue }: Props) {
  const { translate } = useI18n();
  return (
    <Formik initialValues={initialValue as LcyTransferFormProps} onSubmit={noop}>
      {({ handleSubmit }) => (
        <Form className="form mt-0" id="printJS-standard-form" ref={ref} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="row" id="header-standard-form">
                <label id="header-label-standard-form">
                  {translate("TRANSFERS_LCY_PRINT_HEADER_TITTLE")}
                </label>
                <InputField
                  className="pt-1"
                  name="header"
                  width={200}
                  inputClassName="border-0 p-0"
                  height={15}
                  value={initialValue.documentNumber}
                />
              </div>
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label pt-4" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_DATA_LABEL")}
                </label>
                <div className="input-label-large-standard">
                  <InputField
                    className="data-picker-standard-form"
                    name="data"
                    width={800}
                    inputClassName="border-0"
                    // @ts-ignore
                    value={formatDateToBody(initialValue.documentDate)}
                  />
                </div>
              </div>
              <div className="form-group row mb-0 pt-2">
                <label className="col-form-label ml-3" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL")}
                </label>
                <div className="input-label-large-standard">
                  <InputField
                    width={800}
                    height={2}
                    inputClassName="border-0 "
                    name="sender-name"
                    value={initialValue.clientName}
                  />
                </div>
              </div>
              <div className="d-flex mb-0">
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label " id="span-right-standard">
                    {translate("TRANSFERS_LCY_PRINT_DEBIT_LABEL")}
                  </label>
                  <div id="input-middle-standard">
                    <InputField
                      name="currentBalancePrint"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      // value={initialValue.currentBalance}
                      value={initialValue.clientAccount.value}
                    />
                  </div>
                </div>
                <div className="form-group row mr-4 mb-0">
                  <label className="col-sm-2 col-form-label ml-5" id="span-left-standard-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL")}
                  </label>
                  <div id="input-middle-right-standard-form">
                    <InputField
                      name="senderTaxIdPrint"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.senderTaxId}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mb-0 ">
                <div className="form-group row mb-2">
                  <label className="col-sm-2 col-form-label" id="span-right-standard-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_NAME_LABEL")}
                  </label>
                  <div id="input-middle-standard">
                    <InputField
                      name="sender-name"
                      className="input-height"
                      width={550}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.senderBankName}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label" id="span-left-bank-code-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_BANK_CODE_LABEL")}
                  </label>
                  <div id="input-middle-right-bank-code-form">
                    <InputField
                      name="code"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.senderBankCode}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_AMOUNT_LABEL")}
                </label>
                <div className="input-label-large-standard">
                  <InputField
                    name="amount"
                    width={800}
                    height={2}
                    inputClassName="border-0"
                    value={initialValue.amount}
                  />
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label ml-3" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL")}
                </label>
                <div className="input-label-large-standard">
                  <InputField
                    name="cor-name"
                    width={800}
                    height={2}
                    inputClassName="border-0"
                    value={initialValue.correspondentName}
                  />
                </div>
              </div>
              <div className="d-flex mb-0">
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label " id="span-right-standard">
                    {translate("TRANSFERS_LCY_PRINT_CREDIT_LABEL")}
                  </label>
                  <div id="input-middle-standard">
                    <InputField
                      name="currentBalancePrint"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.correspondentAccount}
                    />
                  </div>
                </div>
                <div className="form-group row mr-4 mb-0">
                  <label className="col-sm-2 col-form-label ml-5" id="span-left-standard-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_SENDER_TAX_ID_LABEL")}
                  </label>
                  <div id="input-middle-right-standard-form">
                    <InputField
                      name="senderTaxIdPrint"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.correspondentTaxNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mb-0 ">
                <div className="form-group row mb-3">
                  <label className="col-sm-2 col-form-label" id="span-right-standard-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_NAME_LABEL")}
                  </label>
                  <div id="input-middle-standard">
                    <InputField
                      name="cor-name"
                      className="input-height"
                      width={550}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.correspondentBankName}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label" id="span-left-bank-code-form">
                    {translate("TRANSFERS_LCY_TABLE_PRINT_CORRESPONDENT_BANK_LABEL")}
                  </label>
                  <div id="input-middle-right-bank-code-form">
                    <InputField
                      name="cor-bank"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={initialValue.correspondentBank}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-sm-2 col-form-label" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL")}
                </label>
                <div className="input-label-large-standard pt-2">
                  <InputField
                    name="state"
                    width={800}
                    height={2}
                    inputClassName="border-0"
                    value={initialValue.amountDetails}
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-sm-2 col-form-label" id="span-standard">
                  {translate("TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE")}
                </label>
                <div className="input-label-large-standard from-group row flex-xl-nowrap">
                  <InputField
                    name="details"
                    width={100}
                    height={2}
                    inputClassName="border-0"
                    value={initialValue.paymentCode}
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-sm-2 col-form-label" id="span-standard">
                  {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
                </label>
                <div className="input-label-large-standard1 from-group row flex-xl-nowrap">
                  <TextAreaField
                    name="details of payment"
                    width={900}
                    inputClassName="border-0"
                    label={initialValue.detailsOfPayment}
                  />
                </div>
              </div>
              {/* {initialValue.transactionDate && (
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label" id="span-standard">
                    {translate("TRANSFERS_LCY_PRINT_STATUS_LABEL")}
                  </label>
                  <div id="input-middle-standard">
                    <InputField
                      name="currentBalancePrint"
                      width={300}
                      height={2}
                      inputClassName="border-0"
                      value={`${translate("TRANSFER_PRINT_TABLE_HELD")} (${
                        initialValue.transactionDate
                      })`}
                    />
                  </div>
                </div>
              )} */}
              {initialValue.state === "3"
                ? initialValue.operationDate && (
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" id="span-standard">
                        {translate("TRANSFERS_LCY_PRINT_STATUS_LABEL")}
                      </label>
                      <div id="input-middle-standard">
                        <InputField
                          name="currentBalancePrint"
                          width={300}
                          height={2}
                          inputClassName="border-0"
                          value={`${translate("TRANSFER_PRINT_TABLE_HELD")} (${
                            initialValue.operationDate
                          })`}
                        />
                      </div>
                    </div>
                  )
                : initialValue.operationDate && (
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" id="span-standard">
                        {translate("TRANSFERS_LCY_PRINT_STATUS_LABEL")}
                      </label>
                      <div id="input-middle-standard">
                        <InputField
                          name="currentBalancePrint"
                          width={300}
                          height={2}
                          inputClassName="border-0"
                          value={`${"-"}`}
                        />
                      </div>
                    </div>
                  )}

              {/* start */}
              {/* <div className="form-group row flex-nowrap mb-0">
                <label className="col-sm-2 col-form-label pt-1" id="span-standard">
                  AuthorizerOtpKey
                </label>
                <div id="input-middle-standard">
                  <Input
                    inputClassName="border-0 input-manager pt-1"
                    width={580}
                    height={30}
                    value={
                      initialValue.firstAuthorizerOtpKey
                          ? initialValue.firstAuthorizerOtpKey + " "
                          : " " + initialValue.secondAuthorizerOtpKey
                          ? initialValue.secondAuthorizerOtpKey + " "
                          : " " + initialValue.thirdAuthorizerOtpKey
                          ? initialValue.thirdAuthorizerOtpKey + ""
                          : ""
                    }
                  />
                </div>
              </div> */}
              {/* end */}

              <div className="form-group row flex-xl-nowrap ml-3 mb-0">
                <div className="form-group row flex-nowrap mb-0">
                  <label id="span-manager-standard">
                    {translate("TRANSFERS_LCY_PRINT_MANAGER_LABEL")}
                  </label>
                  <Input
                    className="input-manager pt-1"
                    inputClassName="border-right-0 border-left-0 border-top-0 "
                    height={2}
                  />
                </div>
                <div className="form-group row flex-xl-nowrap ml-7 mb-0">
                  <label id="span-general-standard">
                    {translate("TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL")}
                  </label>
                  <Input
                    className="input-manager pt-1"
                    inputClassName="border-right-0 border-left-0 border-top-0"
                    height={2}
                  />
                </div>
              </div>
              <div className="form-group row flex-xl-nowrap ml-1 mb-2">
                <div className="form-group ">
                  <Input
                    inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                    width={650}
                    height={2}
                    label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
                  />
                </div>
                <div className="form-group">
                  <Input
                    inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                    width={700}
                    height={2}
                    label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
                  />
                </div>
              </div>
              <div className="form-group row flex-xl-nowrap ml-5 mb-1">
                <Input
                  inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                  label={translate("TRANSFERS_LCY_BANK_MP_LABEL")}
                  width={150}
                  height={2}
                />
                <Input
                  className="ml-8"
                  inputClassName="border-right-0 border-left-0 border-top-0 p-0 pl-3"
                  label={translate("TRANSFERS_LCY_CHECKED_LABEL")}
                  width={600}
                  height={2}
                />
                <div className="ml-5">
                  <label>{translate("TRANSFERS_LCY_APPROVED_LABEL")}</label>
                </div>
              </div>
              <div className="form-group row flex-xl-nowrap ml-1 mb-0">
                <div className="form-group">
                  <Input
                    inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                    width={400}
                    height={2}
                    label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
                  />
                </div>
                <div className="form-group">
                  <Input
                    inputClassName="border-right-0 border-left-0 border-top-0 p-0"
                    width={800}
                    height={2}
                    label={translate("TRANSFERS_LCY_SIGNATURE_LABEL")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
