import { deepUpdate } from "immupdate";
import { PersistConfig } from "redux-persist";

import { Dict } from "../api/dto/AppDTO";
import { AppStoreState } from "../store/RootReducer";
import { stringifyFilter } from "../utils/FilterUtils";
import { formatPayrollQuery } from "../helpers/SalaryHelpers";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import {
  PayrollFilterFormProps,
  PayrollProps,
  SalaryFilterFormProps,
} from "../api/salary/SalaryDTO";

export const salaryReducerPersistConfig: Partial<PersistConfig<SalaryReducerState>> = {
  whitelist: [],
};

interface SetPayrollListMeta {
  readonly list: PayrollProps[];
  readonly filter: SalaryFilterFormProps;
}

enum ReducerActions {
  SetPayrollList = "Salary/SetPayrollList",
}

export interface SalaryReducerState {
  readonly payrollList: Dict<PayrollProps[]>;
}

function getState(): SalaryReducerState {
  return {
    payrollList: {},
  };
}

export const salaryReducer = createRootReducer<SalaryReducerState>(
  getState(),

  createReducer([ReducerActions.SetPayrollList], (state, { meta }) => {
    const filterString = stringifyFilter(formatPayrollQuery(meta.filter));

    return deepUpdate(state).at("payrollList").at(filterString).set(meta.list);
  }),
);

// ==================
// Selectors
// ==================

export const payrollListSelector = (filter: PayrollFilterFormProps) => ({
  salary,
}: AppStoreState): PayrollProps[] => {
  const filterString = stringifyFilter(formatPayrollQuery(filter));

  return salary.payrollList[filterString] || [];
};

// ==================
// Actions
// ==================

export function setPayrollList(meta: SetPayrollListMeta): PerformAction<SetPayrollListMeta> {
  return { type: ReducerActions.SetPayrollList, meta };
}
