export function BeforeMonthDate(date: string, before: number) {
  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(4, 6));
  const day = date.substring(6);
  const newYear = (month === 1 ? year - 1 : year).toString();
  const newMonth = (month === 1 ? 12 : month - before).toString();
  const newDay = day;
  const new_date = newYear + "" + `${newMonth.length == 1 ? "0" : ""}` + newMonth + "" + newDay;
  return new_date;
}
