import ExcelJS from "exceljs";
import { forEach } from "lodash";
import { update } from "immupdate";

import { printForm } from "../utils/PintUtils";
import { TranslateFunction } from "../i18n/I18nContext";
import { convertImgToBase64URL } from "../utils/ImageUtils";
import { floatFormat, removeGaps } from "../utils/FloatUtils";
import { FormatNumberToWordOptionProps, numberToWords } from "../utils/FormatUtils";
import {
  AllLCYDocProps,
  FcyTransferForPending,
  LcyTransferFormPropsExcel,
  TransferHistoryProps,
} from "../api/transfer/TransferDTO";
import {
  bottomBorders,
  fillBackground,
  fillBorders,
  horizontalBorders,
  leftBorders,
  rightBorders,
  topBorders,
  verticalBorders,
} from "../utils/ExcelUtils";

type CreateTransferLCYExcel = FormatNumberToWordOptionProps & { translate: TranslateFunction };

export function createTransferLCYExcelSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate, ...options }: CreateTransferLCYExcel,
) {
  const name = `${data.id}. ${data.documentNumber} (${data.documentDate})`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 1.5 },
    { key: "column2", width: 22.6 },
    { key: "column3", width: 19 },
    { key: "column4", width: 8.8 },
    { key: "column5", width: 5.1 },
    { key: "column6", width: 4.8 },
    { key: "column7", width: 4.6 },
    { key: "column8", width: 7.1 },
    { key: "column9", width: 6.8 },
    { key: "column10", width: 12.1 },
    { key: "column11", width: 5.5 },
  ] as any;

  // Объединение ячеек
  sheet.mergeCells("A1:E1");
  sheet.mergeCells("F1:G1");
  sheet.mergeCells("C5:J5");
  sheet.mergeCells("C8:E8");
  sheet.mergeCells("G8:I8");
  sheet.mergeCells("C10:F10");
  sheet.mergeCells("H10:I10");
  sheet.mergeCells("C12:F12");
  sheet.mergeCells("C14:J14");
  sheet.mergeCells("C17:E17");
  sheet.mergeCells("G17:I17");
  sheet.mergeCells("C19:F19");
  sheet.mergeCells("H19:I19");
  sheet.mergeCells("C21:J21");
  sheet.mergeCells("D23:J23");
  sheet.mergeCells("C24:J24");
  sheet.mergeCells("D25:F25");
  sheet.mergeCells("D26:F26");
  sheet.mergeCells("C29:C30");
  sheet.mergeCells("D29:E29");
  sheet.mergeCells("G29:H29");
  sheet.mergeCells("D30:E30");
  sheet.mergeCells("G30:H30");
  sheet.mergeCells("D27:I27");

  // Задание высоты для строк
  sheet.getRow(4).height = 8;
  sheet.getRow(6).height = 8;
  sheet.getRow(9).height = 8;
  sheet.getRow(10).height = 52;
  sheet.getRow(11).height = 8;
  sheet.getRow(13).height = 8;
  sheet.getRow(15).height = 8;
  sheet.getRow(18).height = 8;
  sheet.getRow(19).height = 50;
  sheet.getRow(20).height = 8;
  sheet.getRow(22).height = 8;
  sheet.getRow(23).height = 26;

  // Добаление границ
  // sheet.getCell("G25").border = bottomBorders();
  // sheet.getCell("H25").border = bottomBorders();
  // sheet.getCell("I25").border = bottomBorders();
  // sheet.getCell("J25").border = bottomBorders();

  sheet.getCell("C24").border = fillBorders();

  // sheet.getCell("G26").border = bottomBorders();
  // sheet.getCell("H26").border = bottomBorders();
  // sheet.getCell("I26").border = bottomBorders();
  // sheet.getCell("J26").border = bottomBorders();

  sheet.getCell("C28").border = { ...topBorders(), ...leftBorders() };
  sheet.getCell("D28").border = topBorders();
  sheet.getCell("E28").border = topBorders();
  sheet.getCell("F28").border = topBorders();
  sheet.getCell("G28").border = topBorders();
  sheet.getCell("H28").border = topBorders();
  sheet.getCell("I28").border = topBorders();
  sheet.getCell("J28").border = { ...topBorders(), ...rightBorders() };

  sheet.getCell("D29").border = fillBorders();
  sheet.getCell("G29").border = fillBorders();
  sheet.getCell("J29").border = fillBorders();
  sheet.getCell("C29").border = leftBorders();

  sheet.getCell("D30").border = fillBorders();
  sheet.getCell("G30").border = fillBorders();
  sheet.getCell("J30").border = fillBorders();
  sheet.getCell("C30").border = leftBorders();

  sheet.getCell("C31").border = { ...bottomBorders(), ...leftBorders() };
  sheet.getCell("D31").border = bottomBorders();
  sheet.getCell("E31").border = bottomBorders();
  sheet.getCell("F31").border = bottomBorders();
  sheet.getCell("G31").border = bottomBorders();
  sheet.getCell("H31").border = bottomBorders();
  sheet.getCell("I31").border = bottomBorders();
  sheet.getCell("J31").border = { ...bottomBorders(), ...rightBorders() };

  // Добаление текстов
  const a1Cell = sheet.getCell("A1");
  a1Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_TITLE");
  a1Cell.style = { font: { bold: true }, alignment: { horizontal: "right" } };

  sheet.getCell("B3").value = translate("TRANSFER_LCY_EXPORT_EXCEL_DATE");

  const b5Cell = sheet.getCell("B5");
  b5Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME");
  b5Cell.style = { font: { size: 8 } };

  const b7Cell = sheet.getCell("B7");
  b7Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_DEBIT");
  b7Cell.style = { font: { bold: true } };

  const b8Cell = sheet.getCell("B8");
  b8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT");
  b8Cell.style = { font: { size: 8 } };

  const g8Cell = sheet.getCell("G8");
  g8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER");
  g8Cell.style = { font: { size: 9 } };

  const b10Cell = sheet.getCell("B10");
  b10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME");
  b10Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const h10Cell = sheet.getCell("H10");
  h10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO");
  h10Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B12").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT");

  const b14Cell = sheet.getCell("B14");
  b14Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME");
  b14Cell.style = { font: { size: 8 } };

  const b16Cell = sheet.getCell("B16");
  b16Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CREDIT");
  b16Cell.style = { font: { bold: true } };

  const b17Cell = sheet.getCell("B17");
  b17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT");
  b17Cell.style = { font: { size: 8 } };

  const g17Cell = sheet.getCell("G17");
  g17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER");
  g17Cell.style = { font: { size: 9 } };

  const b19Cell = sheet.getCell("B19");
  b19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME");
  b19Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const h19Cell = sheet.getCell("H19");
  h19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO");
  h19Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B21").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS");

  const b23Cell = sheet.getCell("B23");
  b23Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS");
  b23Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const b24Cell = sheet.getCell("B24");
  b24Cell.value = data.transactionDate ? "Статус" : "";
  b24Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const c25Cell = sheet.getCell("C25");
  c25Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME");
  c25Cell.style = { font: { size: 9 } };

  const d25Cell = sheet.getCell("D25");
  d25Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d25Cell.style = { alignment: { horizontal: "center" } };

  const c26Cell = sheet.getCell("C26");
  c26Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT");
  c26Cell.style = { font: { size: 9 } };

  const d26Cell = sheet.getCell("D26");
  d26Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d26Cell.style = { alignment: { horizontal: "center" } };

  const c27Cell = sheet.getCell("C27");
  c27Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_OTPKEY_APPROVE");
  c27Cell.style = { font: { size: 9 } };

  const b29Cell = sheet.getCell("B29");
  b29Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE");
  b29Cell.style = { alignment: { horizontal: "center" } };

  sheet.getCell("C29").value = translate("TRANSFER_LCY_EXPORT_EXCEL_BANK");

  const d29Cell = sheet.getCell("D29");
  d29Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CHECKED");
  d29Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const g29Cell = sheet.getCell("G29");
  g29Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_APPROVED");
  g29Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const j29Cell = sheet.getCell("J29");
  j29Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED");
  j29Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  // Заполнение данных
  const f1Cell = sheet.getCell("F1");
  f1Cell.border = fillBorders();
  f1Cell.value = data.documentNumber;

  const c3Cell = sheet.getCell("C3");
  c3Cell.border = fillBorders();
  c3Cell.value = data.documentDate;

  const c5Cell = sheet.getCell("C5");
  c5Cell.border = fillBorders();
  c5Cell.value = data.clientName;

  const c8Cell = sheet.getCell("C8");
  c8Cell.border = fillBorders();
  c8Cell.value = data.clientAccount;

  const j8Cell = sheet.getCell("J8");
  j8Cell.border = fillBorders();
  j8Cell.value = data.clientTaxNumber;

  const c10Cell = sheet.getCell("C10");
  c10Cell.border = fillBorders();
  c10Cell.value = data.clientBankName;
  c10Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j10Cell = sheet.getCell("J10");
  j10Cell.border = fillBorders();
  j10Cell.value = data.senderMfo;
  j10Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c12Cell = sheet.getCell("C12");
  c12Cell.border = fillBorders();
  c12Cell.value = data.amount;

  const c14Cell = sheet.getCell("C14");
  c14Cell.border = fillBorders();
  c14Cell.value = data.correspondentName;

  const c17Cell = sheet.getCell("C17");
  c17Cell.border = fillBorders();
  c17Cell.value = data.correspondentAccount;

  const j17Cell = sheet.getCell("J17");
  j17Cell.border = fillBorders();
  j17Cell.value = data.correspondentTaxNumber;

  const c19Cell = sheet.getCell("C19");
  c19Cell.border = fillBorders();
  c19Cell.value = data.correspondentBankName;
  c19Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j19Cell = sheet.getCell("J19");
  j19Cell.border = fillBorders();
  j19Cell.value = data.receiverMfo;
  j19Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c21Cell = sheet.getCell("C21");
  c21Cell.border = fillBorders();
  c21Cell.value = numberToWords(removeGaps(data.amount), {
    ...options,
    currencyCode: data.currencyCode,
  });

  const c23Cell = sheet.getCell("C23");
  c23Cell.border = fillBorders();
  c23Cell.value = data.paymentCode;
  c23Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c24Cell = sheet.getCell("C24");
  c24Cell.value = data.transactionDate ? `Проведён (${data.transactionDate})` : "";
  c24Cell.style = { alignment: { vertical: "middle" } };
  c24Cell.border = fillBorders();

  const d23Cell = sheet.getCell("D23");
  d23Cell.border = fillBorders();
  d23Cell.value = data.paymentDetail;
  d23Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const d27Cell = sheet.getCell("D27");
  // d27Cell.border = fillBorders();
  d27Cell.value = `${data.firstAuthorizerOtpKey ? data.firstAuthorizerOtpKey : ""} ${
    data.secondAuthorizerOtpKey ? data.secondAuthorizerOtpKey : ""
  } ${data.thirdAuthorizerOtpKey ? data.thirdAuthorizerOtpKey : ""}`;
  d27Cell.style = { alignment: { vertical: "middle", horizontal: "center" } };
}

export function createTransferLCYExcel(
  data: AllLCYDocProps[],
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();

  data.forEach((x) => createTransferLCYExcelSheet(workbook, x, options));

  return workbook;
}

const fcyColumns = {
  "2.1": [1],
  "4.15": [62],
  "3.5": [37, 40],
  "3.1": [2, 4, 5],
  "2.8": [3, 35, 65],
  "2.65": [9, 27, 53, 64],
  "3": [6, 7, 14, 36, 39, 44],
  "2.5": [
    8,
    10,
    11,
    12,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    38,
    41,
    42,
    43,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    63,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
  ],
};

export function createTransferFCYExcelSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  image: string,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options: CreateTransferLCYExcel,
) {
  const name = `${data.documentId}`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 48, paperSize: 12 } });

  // Создание колонок
  const columns: any = [];

  forEach(fcyColumns, (item, key) => {
    item.forEach((column) => {
      columns.push({ key: column, width: key });
    });
  });

  sheet.columns = columns
    .sort((a, b) => a.key - b.key)
    .map((x) => update(x, { key: `column${x.key}` })) as any;

  // Задание высоты для строк
  sheet.getRow(1).height = 8;
  sheet.getRow(2).height = 8;
  sheet.getRow(3).height = 26;
  sheet.getRow(4).height = 33;
  sheet.getRow(8).height = 32;
  sheet.getRow(10).height = 20;
  sheet.getRow(14).height = 16;
  sheet.getRow(24).height = 16;
  sheet.getRow(33).height = 17;
  sheet.getRow(34).height = 16;
  sheet.getRow(35).height = 14;
  sheet.getRow(50).height = 14;
  sheet.getRow(52).height = 11;
  sheet.getRow(54).height = 12;
  sheet.getRow(56).height = 13;
  sheet.getRow(57).height = 11;
  sheet.getRow(69).height = 20;
  sheet.getRow(70).height = 17;
  sheet.getRow(71).height = 16;
  sheet.getRow(73).height = 26;
  sheet.getRow(74).height = 21;
  sheet.getRow(75).height = 23;
  sheet.getRow(76).height = 26;
  sheet.getRow(77).height = 16;
  sheet.getRow(78).height = 20;
  sheet.getRow(79).height = 19;
  sheet.getRow(80).height = 8;
  sheet.getRow(81).height = 13;
  sheet.getRow(82).height = 14;
  sheet.getRow(84).height = 13;
  sheet.getRow(85).height = 20;
  sheet.getRow(86).height = 13;
  sheet.getRow(87).height = 16;
  sheet.getRow(88).height = 20;
  sheet.getRow(89).height = 13;
  sheet.getRow(90).height = 13;
  sheet.getRow(91).height = 13;
  sheet.getRow(92).height = 8;
  sheet.getRow(93).height = 13;
  sheet.getRow(96).height = 13;
  sheet.getRow(97).height = 13;
  sheet.getRow(98).height = 13;
  sheet.getRow(99).height = 13;
  sheet.getRow(101).height = 13;
  sheet.getRow(102).height = 13;
  sheet.getRow(103).height = 13;
  sheet.getRow(104).height = 13;
  sheet.getRow(105).height = 13;

  // Объединение ячеек
  sheet.mergeCells("AI2:BJ3");
  sheet.mergeCells("BK2:BO3");

  sheet.mergeCells("BI4:BO4");
  sheet.mergeCells("AI4:BH4");

  sheet.mergeCells("AI5:BO5");
  sheet.mergeCells("B3:AG5");

  sheet.mergeCells("AI6:BO6");

  sheet.mergeCells("B6:AG8");
  sheet.mergeCells("AI8:AO8");
  sheet.mergeCells("AP8:BO8");

  sheet.mergeCells("B10:AG10");
  sheet.mergeCells("AI10:BO10");

  sheet.mergeCells("AI11:AO14");
  sheet.mergeCells("AP11:BO14");
  sheet.mergeCells("B11:H14");
  sheet.mergeCells("I11:AG14");

  sheet.mergeCells("AI15:AO16");
  sheet.mergeCells("AP15:BO16");
  sheet.mergeCells("B15:H16");
  sheet.mergeCells("I15:AG16");

  sheet.mergeCells("AI17:AO20");
  sheet.mergeCells("AP17:BO20");
  sheet.mergeCells("B17:H20");
  sheet.mergeCells("I17:AG20");

  sheet.mergeCells("AI21:AO22");
  sheet.mergeCells("AP21:BO22");
  sheet.mergeCells("B21:H22");
  sheet.mergeCells("I21:AG22");

  sheet.mergeCells("AI24:BO24");
  sheet.mergeCells("B24:AG24");

  sheet.mergeCells("AI25:AO28");
  sheet.mergeCells("AP25:BO28");
  sheet.mergeCells("B25:H28");
  sheet.mergeCells("I25:AG28");

  sheet.mergeCells("AI29:AO33");
  sheet.mergeCells("AP29:BO33");
  sheet.mergeCells("B29:H33");
  sheet.mergeCells("I29:AG33");

  sheet.mergeCells("AI34:AO35");
  sheet.mergeCells("AP34:BO35");
  sheet.mergeCells("B34:H35");
  sheet.mergeCells("I34:AG35");

  sheet.mergeCells("B37:L37");
  sheet.mergeCells("M37:AG37");

  sheet.mergeCells("AI37:BO38");

  sheet.mergeCells("AI39:BO39");

  sheet.mergeCells("B38:L40");
  sheet.mergeCells("M38:AG40");

  sheet.mergeCells("B42:AG42");

  sheet.mergeCells("AI40:BO45");

  sheet.mergeCells("AI46:AP47");
  sheet.mergeCells("AQ46:BO47");
  sheet.mergeCells("B43:AG47");

  sheet.mergeCells("AI48:BO48");

  sheet.mergeCells("B49:X49");
  sheet.mergeCells("Y49:AG49");

  sheet.mergeCells("B51:AD51");

  sheet.mergeCells("B53:AD53");

  sheet.mergeCells("AI49:BO54");

  sheet.mergeCells("B55:AD55");

  sheet.mergeCells("AI55:AP56");
  sheet.mergeCells("AQ55:BO56");
  sheet.mergeCells("B56:AD56");

  sheet.mergeCells("B58:AD58");

  sheet.mergeCells("AI58:AL59");
  sheet.mergeCells("B59:AD59");
  sheet.mergeCells("AM58:AM59");
  sheet.mergeCells("AV58:AZ59");
  sheet.mergeCells("AN58:AU59");
  sheet.mergeCells("AG50:AG59");
  sheet.mergeCells("AE59:AF59");

  sheet.mergeCells("B61:T61");
  sheet.mergeCells("U61:BO61");

  sheet.mergeCells("B62:BO67");

  sheet.mergeCells("B69:AD69");
  sheet.mergeCells("AE69:BO69");

  sheet.mergeCells("B70:BO71");

  sheet.mergeCells("B73:BO76");

  sheet.mergeCells("B78:AW78");
  sheet.mergeCells("AX78:BO78");

  sheet.mergeCells("B79:BO79");

  sheet.mergeCells("B80:AX80");

  sheet.mergeCells("B82:X82");
  sheet.mergeCells("Y82:AU82");
  sheet.mergeCells("AV82:BO82");

  sheet.mergeCells("AV83:BO83");
  sheet.mergeCells("Y83:AU83");
  sheet.mergeCells("B83:X83");

  sheet.mergeCells("B85:D85");
  sheet.mergeCells("E85:W85");
  sheet.mergeCells("Y85:AA85");
  sheet.mergeCells("AB85:AT85");

  sheet.mergeCells("B86:D86");
  sheet.mergeCells("E86:W86");
  sheet.mergeCells("Y86:AA86");
  sheet.mergeCells("AB86:AT86");

  sheet.mergeCells("B93:BO93");

  sheet.mergeCells("B94:J94");
  sheet.mergeCells("K94:T94");
  sheet.mergeCells("U94:AF94");
  sheet.mergeCells("AG94:AQ94");
  sheet.mergeCells("AR94:BB94");
  sheet.mergeCells("BC94:BO94");

  sheet.mergeCells("B95:J95");
  sheet.mergeCells("BC95:BO95");
  sheet.mergeCells("AR95:BB95");
  sheet.mergeCells("AG95:AQ95");
  sheet.mergeCells("U95:AF95");
  sheet.mergeCells("K95:T95");

  sheet.mergeCells("B96:J97");
  sheet.mergeCells("K96:T97");
  sheet.mergeCells("U96:AF97");
  sheet.mergeCells("AG96:AQ97");
  sheet.mergeCells("AR96:BB97");
  sheet.mergeCells("BC96:BO97");

  // Добавление логотипа
  const imageId1 = workbook.addImage({ base64: image, extension: "png" });

  sheet.addImage(imageId1, { tl: { row: 2.5, col: 1 }, ext: { width: 600, height: 81.69 } });

  // Добаление границ
  sheet.getCell("AI2").border = { ...topBorders(), ...leftBorders() };
  sheet.getCell("BK2").border = { ...topBorders(), ...rightBorders() };

  sheet.getCell("B10").border = fillBorders();
  sheet.getCell("B11").border = fillBorders();
  sheet.getCell("B15").border = fillBorders();
  sheet.getCell("B17").border = fillBorders();
  sheet.getCell("B21").border = fillBorders();

  sheet.getCell("I11").border = fillBorders();
  sheet.getCell("I15").border = fillBorders();
  sheet.getCell("I17").border = fillBorders();
  sheet.getCell("I21").border = fillBorders();

  sheet.getCell("B24").border = fillBorders();
  sheet.getCell("B25").border = fillBorders();
  sheet.getCell("B29").border = fillBorders();

  sheet.getCell("I25").border = fillBorders();
  sheet.getCell("I29").border = fillBorders();
  sheet.getCell("I34").border = fillBorders();

  sheet.getCell("AI8").border = fillBorders();
  sheet.getCell("AI10").border = fillBorders();
  sheet.getCell("AI11").border = fillBorders();
  sheet.getCell("AI15").border = fillBorders();
  sheet.getCell("AI17").border = fillBorders();
  sheet.getCell("AI21").border = fillBorders();

  sheet.getCell("AP8").border = fillBorders();
  sheet.getCell("AP11").border = fillBorders();
  sheet.getCell("AP15").border = fillBorders();
  sheet.getCell("AP17").border = fillBorders();
  sheet.getCell("AP21").border = fillBorders();

  sheet.getCell("AI24").border = fillBorders();
  sheet.getCell("AI25").border = fillBorders();
  sheet.getCell("AI29").border = fillBorders();

  sheet.getCell("AP25").border = fillBorders();
  sheet.getCell("AP29").border = fillBorders();
  sheet.getCell("AP34").border = fillBorders();

  sheet.getCell("B62").border = fillBorders();
  sheet.getCell("U61").border = { ...topBorders(), ...rightBorders() };
  sheet.getCell("AF51").border = fillBorders({ style: "medium", color: { argb: "00000000" } });
  sheet.getCell("AF53").border = fillBorders({ style: "medium", color: { argb: "00000000" } });
  sheet.getCell("AF55").border = fillBorders({ style: "medium", color: { argb: "00000000" } });
  sheet.getCell("AF58").border = fillBorders({ style: "medium", color: { argb: "00000000" } });

  sheet.getCell("Y49").border = { ...topBorders(), ...rightBorders() };
  sheet.getCell("AG50").border = { ...bottomBorders(), ...rightBorders() };
  sheet.getCell("AE59").border = bottomBorders();

  sheet.getCell("B50").border = leftBorders();
  sheet.getCell("B52").border = leftBorders();
  sheet.getCell("B54").border = leftBorders();
  sheet.getCell("B57").border = leftBorders();
  sheet.getCell("A55").border = rightBorders();
  sheet.getCell("A58").border = rightBorders();

  sheet.getCell("AM58").border = verticalBorders();
  sheet.getCell("AV58").border = { ...verticalBorders(), ...rightBorders() };

  sheet.getCell("B38").border = fillBorders();
  sheet.getCell("M38").border = fillBorders();

  sheet.getCell("B43").border = fillBorders();

  sheet.getCell("AQ46").border = fillBorders();
  sheet.getCell("AI39").border = horizontalBorders();
  sheet.getCell("AI40").border = horizontalBorders();
  sheet.getCell("AI48").border = horizontalBorders();
  sheet.getCell("AI49").border = horizontalBorders();
  sheet.getCell("AQ55").border = fillBorders();

  sheet.getCell("M69").border = { ...rightBorders(), ...topBorders() };
  sheet.getCell("M70").border = { ...horizontalBorders(), ...bottomBorders() };

  sheet.getCell("AD78").border = verticalBorders();
  sheet.getCell("B79").border = fillBorders();
  sheet.getCell("B78").border = fillBorders();
  sheet.getCell("AX78").border = fillBorders();
  sheet.getCell("AW78").border = { ...verticalBorders(), ...rightBorders() };

  sheet.getCell("E85").border = { ...bottomBorders() };
  sheet.getCell("E86").border = { ...bottomBorders() };

  sheet.getCell("AB85").border = { ...bottomBorders() };
  sheet.getCell("AB86").border = { ...bottomBorders() };

  sheet.getCell("BC96").border = fillBorders();
  sheet.getCell("AR96").border = fillBorders();
  sheet.getCell("AG96").border = fillBorders();
  sheet.getCell("K96").border = fillBorders();
  sheet.getCell("U96").border = fillBorders();
  sheet.getCell("B96").border = fillBorders();

  // Добаление границ и фона
  sheet.getCell("AJ4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AK4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AL4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AM4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AN4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AO4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AP4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AQ4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AR4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AS4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AT4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AU4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AV4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AW4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AX4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AY4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("AZ4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BA4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BB4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BC4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BD4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BE4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BF4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BG4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BH4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BI4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BJ4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BI4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BL4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BM4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BN4").style = { border: bottomBorders(), fill: fillBackground() };
  sheet.getCell("BO4").style = {
    fill: fillBackground(),
    border: { ...bottomBorders(), ...rightBorders() },
  };
  sheet.getCell("BK3").style = {
    border: leftBorders({ style: "thin", color: { argb: "d6e9f6" } }),
  };
  sheet.getCell("B10").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("B11").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B15").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B21").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("B17").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B24").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("B25").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B29").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AI8").style = {
    fill: fillBackground(),
    border: { ...verticalBorders(), ...leftBorders() },
  };
  sheet.getCell("AI10").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("AI11").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AI15").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AI21").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("AI17").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AI24").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground(),
  };
  sheet.getCell("AI25").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AI29").style = {
    border: { ...topBorders(), ...leftBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B82").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B83").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("Y82").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("Y83").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AV82").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AV83").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("B95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("K94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("K95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("U94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("U95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AG94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AG95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AR94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("AR95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("BC94").style = {
    border: { ...topBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };
  sheet.getCell("BC95").style = {
    border: { ...bottomBorders(), ...leftBorders(), ...rightBorders() },
    fill: fillBackground({ argb: "d6e9f6" }),
  };

  // Добаление текстов
  const ai2Cell = sheet.getCell("AI2");
  ai2Cell.value = "TRANSFER APPLICATION #";
  ai2Cell.style = {
    fill: fillBackground(),
    font: { bold: true, size: 20 },
    border: { ...topBorders(), ...leftBorders() },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };
  const bk2Cell = sheet.getCell("BK2");
  bk2Cell.style = {
    fill: fillBackground(),
    border: { ...topBorders(), ...rightBorders() },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };
  const ai4Cell = sheet.getCell("AI4");
  ai4Cell.value = "ЗАЯВЛЕНИЕ НА ПЕРЕВОД №";
  ai4Cell.style = {
    fill: fillBackground(),
    font: { bold: true, size: 20 },
    border: { ...bottomBorders(), ...leftBorders() },
    alignment: { horizontal: "center", vertical: "middle" },
  };

  const ai5Cell = sheet.getCell("AI5");
  ai5Cell.value = "* SHOULD BE TYPEWRITTEN IN ENGLISH";
  ai5Cell.style = { font: { bold: true, size: 10 } };

  const ai6Cell = sheet.getCell("AI6");
  ai6Cell.value = "* ЗАПОЛНЯЕТСЯ ПЕЧАТНЫМИ БУКВАМИ НА АНГЛИЙСКОМ ЯЗЫКЕ";
  ai6Cell.style = { font: { bold: true, size: 10 } };

  const b6Cell = sheet.getCell("B6");
  b6Cell.style = { alignment: { wrapText: true } };
  b6Cell.value =
    "Annex # 2 to the Public offer for rendering service on conversion of foreign currency of one type into foreign currency of another type \n" +
    "Приложение №2 к Публичной оферте на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида";
  b6Cell.font = { size: 13 };

  const ai8Cell = sheet.getCell("AI8");
  ai8Cell.alignment = { vertical: "middle" };
  ai8Cell.value = {
    richText: [
      { font: { bold: true, size: 10 }, text: "DATE / " },
      { text: "ДАТА", font: { bold: false, size: 10 } },
    ],
  };
  ai8Cell.font = { size: 15 };

  const b10Cell = sheet.getCell("B10");
  b10Cell.value = {
    richText: [
      { font: { bold: true, size: 10 }, text: "50: APPLICANT / " },
      { text: "ЗАЯВИТЕЛЬ", font: { bold: false, size: 10 } },
    ],
  };
  b10Cell.alignment = { vertical: "middle" };

  const ai10Cell = sheet.getCell("AI10");
  ai10Cell.value = {
    richText: [
      { font: { bold: true, size: 10 }, text: "59: BENEFICIARY / " },
      { text: "ПОЛУЧАТЕЛЬ", font: { bold: false, size: 10 } },
    ],
  };
  ai10Cell.alignment = { vertical: "middle" };
  ai10Cell.font = { size: 15 };

  const b11Cell = sheet.getCell("B11");
  b11Cell.alignment = { wrapText: true, vertical: "middle" };
  b11Cell.value = {
    richText: [
      { text: "NAME / ", font: { bold: true, size: 10 } },
      { text: "НАИМЕНОВАНИЕ", font: { bold: false, size: 10 } },
    ],
  };
  b11Cell.font = { size: 15 };

  const ai11Cell = sheet.getCell("AI11");
  ai11Cell.alignment = { wrapText: true, vertical: "middle" };
  ai11Cell.value = {
    richText: [
      { text: "NAME / ", font: { bold: true, size: 10 } },
      { text: "НАИМЕНОВАНИЕ", font: { bold: false, size: 10 } },
    ],
  };
  ai11Cell.font = { size: 15 };

  const b15Cell = sheet.getCell("B15");
  b15Cell.alignment = { vertical: "middle" };
  b15Cell.value = {
    richText: [
      { text: "TAX ID / ", font: { bold: true, size: 10 } },
      { text: "ИНН", font: { bold: false, size: 10 } },
    ],
  };
  b15Cell.font = { size: 15 };

  const ai15Cell = sheet.getCell("AI15");
  ai15Cell.alignment = { wrapText: true, vertical: "middle" };
  ai15Cell.value = {
    richText: [
      { text: "ID / ", font: { bold: true, size: 10 } },
      { text: "ИНН (IF ANY / ЕСЛИ ИМЕЕТСЯ)", font: { bold: false, size: 10 } },
    ],
  };
  ai15Cell.font = { size: 15 };

  const b17Cell = sheet.getCell("B17");
  b17Cell.alignment = { wrapText: true, vertical: "middle" };
  b17Cell.value = {
    richText: [
      { text: "ADDRESS / ", font: { bold: true, size: 10 } },
      { text: "АДРЕС", font: { bold: false, size: 10 } },
    ],
  };
  b17Cell.font = { size: 15 };

  const ai17Cell = sheet.getCell("AI17");
  ai17Cell.alignment = { wrapText: true, vertical: "middle" };
  ai17Cell.value = {
    richText: [
      { text: "ADDRESS / ", font: { bold: true, size: 10 } },
      { text: "АДРЕС", font: { bold: false, size: 10 } },
    ],
  };
  ai17Cell.font = { size: 15 };

  const b21Cell = sheet.getCell("B21");
  b21Cell.alignment = { wrapText: true, vertical: "middle" };
  b21Cell.value = {
    richText: [
      { text: "DEBIT ACCOUNT / ", font: { bold: true, size: 10 } },
      { text: "ДЕБЕТОВАТЬ СЧЕТ", font: { bold: false, size: 10 } },
    ],
  };
  b21Cell.font = { size: 15 };

  const ai21Cell = sheet.getCell("AI21");
  ai21Cell.alignment = { wrapText: true, vertical: "middle" };
  ai21Cell.value = {
    richText: [
      { text: "CREDIT ACCOUNT / ", font: { bold: true, size: 10 } },
      { text: "КРЕДИТОВАТЬ СЧЕТ", font: { bold: false, size: 10 } },
    ],
  };
  ai21Cell.font = { size: 15 };

  const b24Cell = sheet.getCell("B24");
  b24Cell.alignment = { wrapText: true, vertical: "middle" };
  b24Cell.value = {
    richText: [
      { text: "52: ORDERING BANK / ", font: { bold: true, size: 10 } },
      { text: "БАНК ОТПРАВИТЕЛЯ", font: { bold: false, size: 10 } },
    ],
  };
  b24Cell.font = { size: 15 };

  const ai24Cell = sheet.getCell("AI24");
  ai24Cell.alignment = { wrapText: true, vertical: "middle" };
  ai24Cell.value = {
    richText: [
      { text: "57: BENEFICIARY'S BANK / ", font: { bold: true, size: 10 } },
      { text: "БАНК ПОЛУЧАТЕЛЯ", font: { bold: false, size: 10 } },
    ],
  };
  ai24Cell.font = { size: 15 };

  const b25Cell = sheet.getCell("B25");
  b25Cell.alignment = { wrapText: true, vertical: "middle" };
  b25Cell.value = {
    richText: [
      { text: "NAME / ", font: { bold: true, size: 10 } },
      { text: "НАИМЕНОВАНИЕ", font: { bold: false, size: 10 } },
    ],
  };
  b25Cell.font = { size: 15 };

  const ai25Cell = sheet.getCell("AI25");
  ai25Cell.alignment = { wrapText: true, vertical: "middle" };
  ai25Cell.value = {
    richText: [
      { text: "NAME / ", font: { bold: true, size: 10 } },
      { text: "НАИМЕНОВАНИЕ", font: { bold: false, size: 10 } },
    ],
  };
  ai25Cell.font = { size: 15 };

  const b29Cell = sheet.getCell("B29");
  b29Cell.alignment = { wrapText: true, vertical: "middle" };
  b29Cell.value = {
    richText: [
      { text: "ADDRESS / ", font: { bold: true, size: 10 } },
      { text: "АДРЕС", font: { bold: false, size: 10 } },
    ],
  };
  b29Cell.font = { size: 15 };

  const ai29Cell = sheet.getCell("AI29");
  ai29Cell.alignment = { wrapText: true, vertical: "middle" };
  ai29Cell.value = {
    richText: [
      { text: "ADDRESS / ", font: { bold: true, size: 10 } },
      { text: "АДРЕС", font: { bold: false, size: 10 } },
    ],
  };
  ai29Cell.font = { size: 15 };

  const b34Cell = sheet.getCell("B34");
  b34Cell.alignment = { wrapText: true, vertical: "middle" };
  b34Cell.border = fillBorders();
  b34Cell.fill = fillBackground();
  b34Cell.value = {
    richText: [
      { text: "SWIFT CODE /\n", font: { bold: true, size: 10 } },
      { text: "SWIFT КОД", font: { bold: false, size: 10 } },
    ],
  };
  b34Cell.font = { size: 15 };

  const ai34Cell = sheet.getCell("AI34");
  ai34Cell.alignment = { wrapText: true, vertical: "middle" };
  ai34Cell.border = fillBorders();
  ai34Cell.fill = fillBackground();
  ai34Cell.value = {
    richText: [
      { text: "SWIFT CODE /\n", font: { bold: true, size: 10 } },
      { text: "SWIFT КОД", font: { bold: false, size: 10 } },
    ],
  };
  ai34Cell.font = { size: 15 };

  const b37Cell = sheet.getCell("B37");
  b37Cell.alignment = { wrapText: true, vertical: "middle" };
  b37Cell.border = fillBorders();
  b37Cell.fill = fillBackground();
  b37Cell.value = {
    richText: [
      { text: "32: CURRENCY / ", font: { bold: true, size: 10 } },
      { text: "ВАЛЮТА", font: { bold: false, size: 10 } },
    ],
  };

  const m37Cell = sheet.getCell("M37");
  m37Cell.alignment = { wrapText: true, vertical: "middle" };
  m37Cell.border = fillBorders();
  m37Cell.fill = fillBackground();
  m37Cell.value = {
    richText: [
      { text: "AMOUNT IN DIGIT / ", font: { bold: true, size: 10 } },
      { text: "СУММА ЦИФРАМИ", font: { bold: false, size: 10 } },
    ],
  };
  m37Cell.font = { size: 15 };

  const ai37Cell = sheet.getCell("AI37");
  ai37Cell.alignment = { wrapText: true, vertical: "middle" };
  ai37Cell.border = fillBorders();
  ai37Cell.fill = fillBackground();
  ai37Cell.value = {
    richText: [
      {
        text: "CORRESPONDENT BANK OF BENEFICIARY'S BANK /\n",
        font: { bold: true, size: 10, name: "Arial" },
      },
      {
        text: "БАНК-КОРРЕСПОНДЕНТ БАНКА  ПОЛУЧАТЕЛЯ",
        font: { bold: false, size: 10, name: "Arial" },
      },
    ],
  };
  ai37Cell.font = { size: 15 };

  sheet.getCell("AI39").value = "1";
  sheet.getCell("AI48").value = "2";

  const b42Cell = sheet.getCell("B42");
  b42Cell.alignment = { wrapText: true, vertical: "middle" };
  b42Cell.border = fillBorders();
  b42Cell.fill = fillBackground();
  b42Cell.value = {
    richText: [
      { text: "AMOUNT IN WORDS / ", font: { bold: true, size: 10 } },
      { text: "СУММА ПРОПИСЬЮ", font: { bold: false, size: 10 } },
    ],
  };
  b42Cell.font = { size: 15 };

  const ai46Cell = sheet.getCell("AI46");
  ai46Cell.alignment = { wrapText: true, vertical: "middle" };
  ai46Cell.border = fillBorders();
  ai46Cell.fill = fillBackground();
  ai46Cell.value = {
    richText: [
      { text: "SWIFT CODE /\n", font: { bold: true, size: 10 } },
      { text: "SWIFT КОД", font: { bold: false, size: 10 } },
    ],
  };
  ai46Cell.font = { size: 15 };

  const ai55Cell = sheet.getCell("AI55");
  ai55Cell.alignment = { wrapText: true, vertical: "middle" };
  ai55Cell.border = fillBorders();
  ai55Cell.fill = fillBackground();
  ai55Cell.value = {
    richText: [
      { text: "SWIFT CODE /\n", font: { bold: true, size: 10 } },
      { text: "SWIFT КОД", font: { bold: false, size: 10 } },
    ],
  };
  ai55Cell.font = { size: 15 };

  const b49Cell = sheet.getCell("B49");
  b49Cell.alignment = { wrapText: true, vertical: "middle" };
  b49Cell.border = fillBorders();
  b49Cell.fill = fillBackground();
  b49Cell.value = {
    richText: [
      { text: "71: CHARGES FOR TRANSFER / ", font: { bold: true, size: 10 } },
      { text: "КОМИССИИ ЗА ПЕРЕВОД", font: { bold: false, size: 10 } },
    ],
  };
  b49Cell.font = { size: 15 };

  const b51Cell = sheet.getCell("B51");
  b51Cell.border = leftBorders();
  b51Cell.value = {
    richText: [
      {
        text: "ALL CHARGES ARE FROM BENEFICIARY / BEN / ",
        font: { bold: true, size: 10, name: "Arial" },
      },
      { text: "ВСЕ КОМИССИИ ЗА СЧЕТ ПОЛУЧАТЕЛЯ", font: { bold: false, size: 10, name: "Arial" } },
    ],
  };

  const b53Cell = sheet.getCell("B53");
  b53Cell.border = leftBorders();
  b53Cell.alignment = { wrapText: true, vertical: "middle" };
  b53Cell.value = {
    richText: [
      {
        text: "ALL CHARGES ARE FROM REMITTER / OUR /",
        font: { bold: true, size: 10, name: "Arial" },
      },
      { text: "ВСЕ КОМИССИИ ЗА СЧЕТ ОТПРАВИТЕЛЯ", font: { bold: false, size: 10, name: "Arial" } },
    ],
  };

  const b55Cell = sheet.getCell("B55");
  b55Cell.value = {
    richText: [
      {
        font: { bold: true, size: 10, name: "Arial" },
        text: 'CHARGES OF JSC "KDB BANK UZBEKISTAN" ARE FROM REMITTER / SHA /',
      },
    ],
  };

  const b56Cell = sheet.getCell("B56");
  b56Cell.border = leftBorders();
  b56Cell.value = 'КОМИССИИ АО "КДБ БАНК УЗБЕКИСТАН" ЗА СЧЕТ ОТПРАВИТЕЛЯ';
  b55Cell.style = { font: { size: 10, name: "Arial" }, alignment: { wrapText: true } };

  const b58Cell = sheet.getCell("B58");
  b58Cell.border = leftBorders();
  b58Cell.value = {
    richText: [{ font: { bold: true, name: "Arial", size: 10 }, text: "FULL NO DEDUCT/FND/" }],
  };

  const b59Cell = sheet.getCell("B59");
  b59Cell.border = { ...bottomBorders(), ...leftBorders() };
  b58Cell.style = { font: { bold: false, size: 10, name: "Arial" }, alignment: { wrapText: true } };
  b59Cell.value = "ВСЕ КОМИССИИ ЗА СЧЕТ ОТПРАВИТЕЛЯ (JPMORGAN CHASE BANK)";

  const ai58Cell = sheet.getCell("AI58");
  ai58Cell.alignment = { wrapText: true, vertical: "middle" };
  ai58Cell.border = fillBorders();
  ai58Cell.fill = fillBackground();
  ai58Cell.value = {
    richText: [
      { text: "PLACE / ", font: { bold: true, size: 10 } },
      { text: "МЕСТО", font: { bold: false, size: 10 } },
    ],
  };
  ai58Cell.font = { size: 15 };

  const an58Cell = sheet.getCell("AN58");
  an58Cell.border = verticalBorders();
  an58Cell.style = {
    font: { size: 14, bold: true },
    alignment: { vertical: "middle" },
    border: topBorders(),
  };
  an58Cell.value = "T A S H K E N T";

  const b61Cell = sheet.getCell("B61");
  b61Cell.alignment = { wrapText: true, vertical: "middle" };
  b61Cell.border = fillBorders();
  b61Cell.fill = fillBackground();
  b61Cell.value = {
    richText: [
      { text: "70: DETAILS OF PAYMENT / ", font: { bold: true, size: 10 } },
      { text: "ДЕТАЛИ ПЛАТЕЖА", font: { bold: false, size: 10 } },
    ],
  };
  b61Cell.font = { size: 15 };

  const b69Cell = sheet.getCell("B69");
  b69Cell.alignment = { wrapText: true, vertical: "middle" };
  b69Cell.border = fillBorders();
  b69Cell.fill = fillBackground();
  b69Cell.value = {
    richText: [
      { text: "72: SENDER TO RECEIVER INFORMATION/ ", font: { bold: true, size: 10 } },
      { text: "ИНФОРМАЦИЯ ОТПРАВИТЕЛЯ К ПОЛУЧАТЕЛЮ", font: { bold: false, size: 10 } },
    ],
  };
  b69Cell.font = { size: 15 };

  const b73Cell = sheet.getCell("B73");
  b73Cell.alignment = { wrapText: true, vertical: "top" };
  b73Cell.border = fillBorders();
  b73Cell.value = {
    richText: [
      {
        font: { bold: true, size: 12, name: "Arial" },
        text:
          "Clause on joining to the agreement - Public Offer / Оговорка о присоединении к договору - Публичной оферте:\n",
      },
      {
        font: { bold: false, size: 12, name: "Arial" },
        text:
          "Настоящим заявлением подтверждаю, что ознакомлен(а) с условиями Публичной оферты на оказание услуг по конвертации иностранной валюты одного вида в иностранную валюту другого вида, соглашаюсь с ними и присоединяюсь к ним, претензий  в дальнейшем к Банку иметь не буду и даю согласие на безакцептное списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка / Herewith by this application I confirm that I have read the terms of Public Offer for rendering of service on conversion of fоreign currency of one type into foreign currency of another type, agree with and join them, I will not have any claims against the Bank in the future and give my consent to the direct write-off of the necessary amounts for the services rendered in accordance with the Bank’s Tariffs.",
      },
    ],
  };

  const b78Cell = sheet.getCell("B78");
  b78Cell.style = { font: { name: "Arial", size: 10 } };
  b78Cell.value = "PLEASE DEBIT OUR ACCOUNT# / ПРОСИМ СПИСАТЬ С НАШЕГО РАСЧЕТНОГО СЧЕТА №";
  b78Cell.border = fillBorders();
  b78Cell.font = { size: 15 };

  const b79Cell = sheet.getCell("B79");
  b79Cell.style = { font: { name: "Arial", size: 10 } };
  b79Cell.value =
    "FOR TRANSFER CHARGES AS PER APPLICABLE TARIFFS / КОМИССИОННОЕ ВОЗНАГРАЖДЕНИЕ ЗА ПЕРЕВОД СОГЛАСНО ДЕЙСТВУЮЩИМ ТАРИФАМ";
  b79Cell.border = fillBorders();
  b79Cell.font = { size: 15 };

  const b82Cell = sheet.getCell("B82");
  b82Cell.alignment = { wrapText: true, vertical: "middle" };
  b82Cell.value = "FIRST SIGNATURE / HEAD";
  b82Cell.font = { bold: true };

  const b83Cell = sheet.getCell("B83");
  b83Cell.alignment = { wrapText: true, vertical: "middle" };
  b83Cell.value = "ПЕРВАЯ ПОДПИСЬ / РУКОВОДИТЕЛЯ";

  const y82Cell = sheet.getCell("Y82");
  y82Cell.alignment = { wrapText: true, vertical: "middle" };
  y82Cell.value = "SECOND SIGNATURE (IF APPLICABLE) / CHIEF ACCOUNTANT";
  y82Cell.font = { bold: true };

  const y83Cell = sheet.getCell("Y83");
  y83Cell.alignment = { wrapText: true, vertical: "middle" };
  y83Cell.value = "ВТОРАЯ ПОДПИСЬ (ЕСЛИ ПРЕДУСМОТРЕНА) / ГЛАВНЫЙ БУХГАЛТЕР";
  y83Cell.font = { size: 9 };

  const av82Cell = sheet.getCell("AW82");
  av82Cell.alignment = { wrapText: true, vertical: "middle" };
  av82Cell.value = "STAMP (if required) /";
  av82Cell.font = { bold: true };

  const av83Cell = sheet.getCell("AV83");
  av83Cell.alignment = { wrapText: true, vertical: "middle" };
  av83Cell.value = "ШТАМП (по требованию) /";

  const b85Cell = sheet.getCell("B85");
  b85Cell.alignment = { wrapText: true, vertical: "middle" };
  b85Cell.value = "Ф.И.О/";

  const b86Cell = sheet.getCell("B86");
  b86Cell.alignment = { wrapText: true, vertical: "middle" };
  b86Cell.value = "NAME";

  const y85Cell = sheet.getCell("Y85");
  y85Cell.alignment = { wrapText: true, vertical: "middle" };
  y85Cell.value = "Ф.И.О/";

  const y86Cell = sheet.getCell("Y86");
  y86Cell.alignment = { wrapText: true, vertical: "middle" };
  y86Cell.value = "NAME";

  const b93Cell = sheet.getCell("B93");
  b93Cell.alignment = { wrapText: true, vertical: "middle", horizontal: "center" };
  b93Cell.value = "FILLED BY THE BANK / ЗАПОЛНЯЕТСЯ БАНКОМ";

  const b94Cell = sheet.getCell("B94");
  b94Cell.alignment = { wrapText: true, vertical: "middle" };
  b94Cell.value = {
    richText: [
      { font: { bold: true }, text: "CSD" },
      { font: { bold: false, size: 9 }, text: "(AUTHENTIFICATION AND" },
    ],
  };

  const b95Cell = sheet.getCell("B95");
  b95Cell.alignment = { wrapText: true, vertical: "middle" };
  b95Cell.value = "VERIFICATION)";

  const k94Cell = sheet.getCell("K94");
  k94Cell.alignment = { wrapText: true, vertical: "middle" };
  k94Cell.value = "AAD";
  k94Cell.font = { bold: true };

  const k95Cell = sheet.getCell("K95");
  k95Cell.alignment = { wrapText: true, vertical: "middle" };
  k95Cell.value = "(SIGNATURE/STAMP VERIFIED)";
  k95Cell.font = { size: 9 };

  const u94Cell = sheet.getCell("U94");
  u94Cell.alignment = { wrapText: true, vertical: "middle" };
  u94Cell.value = "CCD";
  u94Cell.font = { bold: true };

  const u95Cell = sheet.getCell("U95");
  u95Cell.alignment = { wrapText: true, vertical: "middle" };
  u95Cell.value = "(CHECKED)";

  const ag94Cell = sheet.getCell("AG94");
  ag94Cell.alignment = { wrapText: true, vertical: "middle" };
  ag94Cell.value = "MANEGEMENT";
  ag94Cell.font = { bold: true };

  const ag95Cell = sheet.getCell("AG95");
  ag95Cell.alignment = { wrapText: true, vertical: "middle" };
  ag95Cell.value = "(APPROVAL)";

  const ar94Cell = sheet.getCell("AR94");
  ar94Cell.alignment = { wrapText: true, vertical: "middle" };
  ar94Cell.value = "PAYMENTS";
  ar94Cell.font = { bold: true };

  const ar95Cell = sheet.getCell("AR95");
  ar95Cell.alignment = { wrapText: true, vertical: "middle" };
  ar95Cell.value = "(TRANSACTION INPUT)";

  const bc94Cell = sheet.getCell("BC94");
  bc94Cell.alignment = { wrapText: true, vertical: "middle" };
  bc94Cell.value = "AUTHORIZATION AND SWIFT";
  bc94Cell.font = { bold: true };

  const bc95Cell = sheet.getCell("BC95");
  bc95Cell.alignment = { wrapText: true, vertical: "middle" };
  bc95Cell.value = "(SWIFT SENDING)";

  // Заполнение данных

  const bk4Cell = sheet.getCell("BI4");
  bk4Cell.value = data?.transferNumber ? data.transferNumber : data.documentId;
  bk4Cell.alignment = { wrapText: true, vertical: "middle" };
  bk4Cell.font = { size: 15 };

  const ap8Cell = sheet.getCell("AP8");
  ap8Cell.value = data?.transactionDate;
  ap8Cell.alignment = { wrapText: true, vertical: "middle" };
  ap8Cell.font = { size: 15 };

  const i11Cell = sheet.getCell("I11");
  i11Cell.value = data?.applicantName;
  i11Cell.alignment = { wrapText: true, vertical: "middle" };
  i11Cell.font = { size: 15 };

  const ap11Cell = sheet.getCell("AP11");
  ap11Cell.value = data?.beneficiaryName;
  ap11Cell.alignment = { wrapText: true, vertical: "middle" };
  ap11Cell.font = { size: 15 };

  const i15Cell = sheet.getCell("I15");
  i15Cell.value = data?.taxId;
  i15Cell.alignment = { wrapText: true, vertical: "middle" };
  i15Cell.font = { size: 15 };

  const ap15Cell = sheet.getCell("AP15");
  ap15Cell.value = {
    richText: [
      {
        text: data?.beneficiaryTaxId
          ? data.beneficiaryTaxId
          : data?.bankTaxNumber
          ? data?.bankTaxNumber
          : "",
      },
      { text: data?.kpp ? ` / KPP ${data?.kpp}` : "" },
    ],
  };
  ap15Cell.alignment = { wrapText: true, vertical: "middle" };
  ap15Cell.font = { size: 15 };

  const i17Cell = sheet.getCell("I17");
  i17Cell.value = data?.applicantAddress;
  i17Cell.alignment = { wrapText: true, vertical: "middle" };
  i17Cell.font = { size: 15 };

  const ap17Cell = sheet.getCell("AP17");
  ap17Cell.value = data?.beneficiaryAddress;
  ap17Cell.alignment = { wrapText: true, vertical: "middle" };
  ap17Cell.font = { size: 15 };

  const ap21Cell = sheet.getCell("AP21");
  ap21Cell.value = data?.beneficiaryAccount;
  ap21Cell.alignment = { wrapText: true, vertical: "middle" };
  ap21Cell.font = { size: 15 };

  const i21Cell = sheet.getCell("I21");
  i21Cell.value = data?.applicantAccount;
  i21Cell.alignment = { wrapText: true, vertical: "middle" };
  i21Cell.font = { size: 15 };

  const ap25Cell = sheet.getCell("AP25");
  ap25Cell.value = data?.beneficiaryBankName;
  ap25Cell.alignment = { wrapText: true, vertical: "middle" };
  ap25Cell.font = { size: 15 };

  const i25Cell = sheet.getCell("I25");
  i25Cell.value = data?.bankName;
  i25Cell.alignment = { wrapText: true, vertical: "middle" };
  i25Cell.font = { size: 15 };

  const i29Cell = sheet.getCell("I29");
  i29Cell.value = data?.bankAddress;
  i29Cell.alignment = { wrapText: true, vertical: "middle" };
  i29Cell.font = { size: 15 };

  const ap29Cell = sheet.getCell("AP29");
  ap29Cell.value = data?.beneficiaryBankAddress;
  ap29Cell.alignment = { wrapText: true, vertical: "middle" };
  ap29Cell.font = { size: 15 };

  const i31Cell = sheet.getCell("I34");
  i31Cell.value = data?.bankSwift;
  i31Cell.alignment = { wrapText: true, vertical: "middle" };
  i31Cell.font = { size: 15 };

  const ap31Cell = sheet.getCell("AP34");
  ap31Cell.value = {
    richText: [
      {
        text: data?.beneficiarySwiftCode
          ? data.beneficiarySwiftCode
          : data?.bankSwift
          ? data?.bankSwift
          : "",
      },
      { text: data?.correspondentAccount ? ` / Corr. Account ${data?.correspondentAccount}` : "" },
    ],
  };
  ap31Cell.alignment = { wrapText: true, vertical: "middle" };
  ap31Cell.font = { size: 15 };

  const b38Cell = sheet.getCell("B38");
  b38Cell.value = data?.currency;
  b38Cell.alignment = { wrapText: true, vertical: "middle" };
  b38Cell.font = { size: 15 };

  const m38Cell = sheet.getCell("M38");
  m38Cell.value = floatFormat(data?.amount ? data.amount : data.sellAmount ? data.sellAmount : "");
  m38Cell.alignment = { wrapText: true, vertical: "middle" };
  m38Cell.font = { size: 15 };

  const ai40Cell = sheet.getCell("AI40");
  ai40Cell.value = data?.correspondentBankName1;
  ai40Cell.alignment = { wrapText: true, vertical: "middle" };
  ai40Cell.font = { size: 15 };

  const b43Cell = sheet.getCell("B43");
  b43Cell.value = numberToWords(
    data?.amount ? removeGaps(data.amount) : data?.sellAmount ? removeGaps(data?.sellAmount) : "",
    { ...options, currencyCode: data.currencyCode },
  );
  b43Cell.alignment = { wrapText: true, vertical: "middle" };
  b43Cell.font = { size: 15 };

  const aq46Cell = sheet.getCell("AQ46");
  aq46Cell.value = data?.correspondentSwiftCode1;
  aq46Cell.alignment = { wrapText: true, vertical: "middle" };
  aq46Cell.font = { size: 15 };

  const ai49Cell = sheet.getCell("AI49");
  ai49Cell.value = data?.correspondentBankName2;
  ai49Cell.alignment = { wrapText: true, vertical: "middle" };
  ai49Cell.font = { size: 15 };

  const af51Cell = sheet.getCell("AF51");
  af51Cell.value = data?.chargesForTransfer === "BEN" ? "+" : "";
  af51Cell.font = { bold: true };
  af51Cell.alignment = { wrapText: true, vertical: "middle" };
  af51Cell.font = { size: 15 };

  const af53Cell = sheet.getCell("AF53");
  af53Cell.value = data?.chargesForTransfer === "OUR" ? "+" : "";
  af51Cell.font = { bold: true };
  af53Cell.alignment = { wrapText: true, vertical: "middle" };
  af53Cell.font = { size: 15 };

  const af55Cell = sheet.getCell("AF55");
  af55Cell.value = data?.chargesForTransfer === "SHA" ? "+" : "";
  af55Cell.font = { bold: true };
  af55Cell.alignment = { wrapText: true, vertical: "middle" };
  af55Cell.font = { size: 15 };

  const aq55Cell = sheet.getCell("AQ55");
  aq55Cell.value = data?.correspondentSwiftCode2;
  aq55Cell.alignment = { wrapText: true, vertical: "middle" };
  aq55Cell.font = { size: 15 };

  const af58Cell = sheet.getCell("AF58");
  af58Cell.value = data?.chargesForTransfer === "FND" ? "+" : "";
  af58Cell.font = { bold: true };
  af58Cell.alignment = { wrapText: true, vertical: "middle" };
  af58Cell.font = { size: 15 };

  const b62Cell = sheet.getCell("B62");
  b62Cell.value = data?.detailsOfPayment;
  b62Cell.alignment = { wrapText: true, vertical: "middle" };
  b62Cell.font = { size: 15 };

  const b70Cell = sheet.getCell("B70");
  b70Cell.value = data?.remarks;
  b70Cell.alignment = { wrapText: true };
  b70Cell.font = { size: 15 };

  const aw78Cell = sheet.getCell("AX78");
  aw78Cell.value = data?.commissionAccount;
  aw78Cell.alignment = { wrapText: true, vertical: "middle" };
  aw78Cell.font = { size: 15 };
}

export function createTransferFCYExcel(
  data: FcyTransferForPending[],
  options: CreateTransferLCYExcel,
): Promise<ExcelJS.Workbook> {
  return convertImgToBase64URL(require("./assets/kdb.png")).then((image) => {
    const workbook = new ExcelJS.Workbook();

    data.forEach((x) => createTransferFCYExcelSheet(workbook, x, image, options));

    return workbook;
  });
}

export function createTransferFCYExcelFromForm(
  data: any,
  options: CreateTransferLCYExcel,
): Promise<ExcelJS.Workbook> {
  return convertImgToBase64URL(require("./assets/kdb.png")).then((image) => {
    const workbook = new ExcelJS.Workbook();

    createTransferFCYExcelSheet(workbook, data, image, options);

    return workbook;
  });
}

export function createTransferLCYExcelFromForm(
  data: LcyTransferFormPropsExcel,
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createTransferLCYExcelSheet(workbook, data, options);
  return workbook;
}

export function createTransferLCYBudgetExcelSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate, ...options }: CreateTransferLCYExcel,
) {
  const name = `${data?.id}. ${data?.documentNumber} (${data?.documentDate})`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 12 } });

  sheet.columns = [
    { key: "column1", width: 1.5 },
    { key: "column2", width: 22 },
    { key: "column3", width: 19 },
    { key: "column4", width: 8.8 },
    { key: "column5", width: 5.1 },
    { key: "column6", width: 4.8 },
    { key: "column7", width: 4.6 },
    { key: "column8", width: 7.1 },
    { key: "column9", width: 6.8 },
    { key: "column10", width: 12.1 },
    { key: "column11", width: 5.5 },
  ] as any;

  sheet.mergeCells("A1:E1");
  sheet.mergeCells("F1:G1");
  sheet.mergeCells("C5:J5");
  sheet.mergeCells("C8:E8");
  sheet.mergeCells("G8:I8");
  sheet.mergeCells("C10:F10");
  sheet.mergeCells("H10:I10");
  sheet.mergeCells("C12:F12");
  sheet.mergeCells("C14:J14");
  sheet.mergeCells("C17:E17");
  sheet.mergeCells("G17:I17");
  sheet.mergeCells("C19:F19");
  sheet.mergeCells("H19:I19");
  sheet.mergeCells("D23:F23");
  sheet.mergeCells("C21:J21");
  sheet.mergeCells("C29:J29");
  sheet.mergeCells("C25:J25");
  sheet.mergeCells("C27:C28");
  sheet.mergeCells("D27:J28");
  sheet.mergeCells("D30:F30");
  sheet.mergeCells("D31:F31");
  sheet.mergeCells("C34:C35");
  sheet.mergeCells("G23:J23");
  sheet.mergeCells("D34:E34");
  sheet.mergeCells("G34:H34");
  sheet.mergeCells("D35:E35");
  sheet.mergeCells("G35:H35");
  sheet.mergeCells("D32:I32");

  sheet.getRow(4).height = 8;
  sheet.getRow(6).height = 8;
  sheet.getRow(9).height = 8;
  sheet.getRow(10).height = 52;
  sheet.getRow(11).height = 8;
  sheet.getRow(13).height = 8;
  sheet.getRow(15).height = 8;
  sheet.getRow(18).height = 8;
  sheet.getRow(19).height = 50;
  sheet.getRow(20).height = 8;
  sheet.getRow(21).height = 28;
  sheet.getRow(23).height = 45;
  sheet.getRow(25).height = 30;

  sheet.getCell("C25").border = fillBorders();

  sheet.getCell("C27").border = fillBorders();

  sheet.getCell("D27").border = fillBorders();

  sheet.getCell("C29").border = fillBorders();

  // sheet.getCell("G30").border = bottomBorders();
  // sheet.getCell("H30").border = bottomBorders();
  // sheet.getCell("I30").border = bottomBorders();
  // sheet.getCell("J30").border = bottomBorders();

  // sheet.getCell("G31").border = bottomBorders();
  // sheet.getCell("H31").border = bottomBorders();
  // sheet.getCell("I31").border = bottomBorders();
  // sheet.getCell("J31").border = bottomBorders();

  sheet.getCell("C33").border = { ...topBorders(), ...leftBorders() };
  sheet.getCell("D33").border = topBorders();
  sheet.getCell("E33").border = topBorders();
  sheet.getCell("F33").border = topBorders();
  sheet.getCell("G33").border = topBorders();
  sheet.getCell("H33").border = topBorders();
  sheet.getCell("I33").border = topBorders();
  sheet.getCell("J33").border = { ...topBorders(), ...rightBorders() };

  sheet.getCell("D34").border = fillBorders();
  sheet.getCell("G34").border = fillBorders();
  sheet.getCell("J34").border = fillBorders();
  sheet.getCell("C34").border = leftBorders();

  sheet.getCell("D35").border = fillBorders();
  sheet.getCell("G35").border = fillBorders();
  sheet.getCell("J35").border = fillBorders();
  sheet.getCell("C35").border = leftBorders();

  sheet.getCell("C36").border = { ...bottomBorders(), ...leftBorders() };
  sheet.getCell("D36").border = bottomBorders();
  sheet.getCell("E36").border = bottomBorders();
  sheet.getCell("F36").border = bottomBorders();
  sheet.getCell("G36").border = bottomBorders();
  sheet.getCell("H36").border = bottomBorders();
  sheet.getCell("I36").border = bottomBorders();
  sheet.getCell("J36").border = { ...bottomBorders(), ...rightBorders() };

  const a1Cell = sheet.getCell("A1");
  a1Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_TITLE");
  a1Cell.style = { font: { bold: true }, alignment: { horizontal: "right" } };

  sheet.getCell("B3").value = translate("TRANSFER_LCY_EXPORT_EXCEL_DATE");

  const b5Cell = sheet.getCell("B5");
  b5Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME");
  b5Cell.style = { font: { size: 8 } };

  const b7Cell = sheet.getCell("B7");
  b7Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_DEBIT");
  b7Cell.style = { font: { bold: true } };

  const b8Cell = sheet.getCell("B8");
  b8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT");
  b8Cell.style = { font: { size: 8 } };

  const g8Cell = sheet.getCell("G8");
  g8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER");
  g8Cell.style = { font: { size: 9 } };

  const b10Cell = sheet.getCell("B10");
  b10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME");
  b10Cell.style = { alignment: { wrapText: true, vertical: "middle" } };
  b10Cell.font = { size: 13 };

  const h10Cell = sheet.getCell("H10");
  h10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO");
  h10Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B12").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT");

  const b14Cell = sheet.getCell("B14");
  b14Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME");
  b14Cell.style = { font: { size: 8 } };

  const b16Cell = sheet.getCell("B16");
  b16Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CREDIT");
  b16Cell.style = { font: { bold: true } };

  const b17Cell = sheet.getCell("B17");
  b17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT");
  b17Cell.style = { font: { size: 8 } };

  const g17Cell = sheet.getCell("G17");
  g17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER");
  g17Cell.style = { font: { size: 9 } };

  const b19Cell = sheet.getCell("B19");
  b19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME");
  b19Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const h19Cell = sheet.getCell("H19");
  h19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO");
  h19Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B21").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS");

  const b23Cell = sheet.getCell("B23");
  b23Cell.value = translate("TRANSFER_LCY_BUDGET_INN");
  b23Cell.style = { alignment: { vertical: "middle" } };

  const d23Cell = sheet.getCell("D23");
  d23Cell.value = translate("TRANSFER_BUDGET_ACCOUNT_NUMBER");
  d23Cell.style = {
    font: { size: 10 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  const c30Cell = sheet.getCell("C30");
  c30Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME");
  c30Cell.style = { font: { size: 9 } };

  const b29Cell = sheet.getCell("B29");
  b29Cell.value = data.transactionDate ? "Статус" : "";
  b29Cell.style = { font: { size: 9 } };

  const d30Cell = sheet.getCell("D30");
  d30Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d30Cell.style = { alignment: { horizontal: "center" } };

  const c31Cell = sheet.getCell("C31");
  c31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT");
  c31Cell.style = { font: { size: 9 } };

  const d31Cell = sheet.getCell("D31");
  d31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d31Cell.style = { alignment: { horizontal: "center" } };

  const c32Cell = sheet.getCell("C32");
  c32Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_OTPKEY_APPROVE");
  c32Cell.style = { font: { size: 9 } };

  const b34Cell = sheet.getCell("B34");
  b34Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE");
  b34Cell.style = { alignment: { horizontal: "center" } };

  sheet.getCell("C34").value = translate("TRANSFER_LCY_EXPORT_EXCEL_BANK");

  const d34Cell = sheet.getCell("D34");
  d34Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CHECKED");
  d34Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const g34Cell = sheet.getCell("G34");
  g34Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_APPROVED");
  g34Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const j34Cell = sheet.getCell("J34");
  j34Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED");
  j34Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const b25Cell = sheet.getCell("B25");
  b25Cell.value = translate("TRANSFER_BUDGET_NAME");
  b25Cell.style = { alignment: { vertical: "middle", wrapText: true } };

  const b27Cell = sheet.getCell("B27");
  b27Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS");

  // Заполнение данных
  const f1Cell = sheet.getCell("F1");
  f1Cell.border = fillBorders();
  f1Cell.value = data.documentNumber;

  const c3Cell = sheet.getCell("C3");
  c3Cell.border = fillBorders();
  c3Cell.value = data.documentDate;

  const c5Cell = sheet.getCell("C5");
  c5Cell.border = fillBorders();
  c5Cell.value = data.clientName;

  const c8Cell = sheet.getCell("C8");
  c8Cell.border = fillBorders();
  c8Cell.value = data.clientAccount;

  const j8Cell = sheet.getCell("J8");
  j8Cell.border = fillBorders();
  j8Cell.value = data.clientTaxNumber;

  const c10Cell = sheet.getCell("C10");
  c10Cell.border = fillBorders();
  c10Cell.value = data.clientBankName;
  c10Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j10Cell = sheet.getCell("J10");
  j10Cell.border = fillBorders();
  j10Cell.value = data.senderMfo;
  j10Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c12Cell = sheet.getCell("C12");
  c12Cell.border = fillBorders();
  c12Cell.value = data.amount;

  const c14Cell = sheet.getCell("C14");
  c14Cell.border = fillBorders();
  c14Cell.value = data.correspondentName;

  const c17Cell = sheet.getCell("C17");
  c17Cell.border = fillBorders();
  c17Cell.value = data.correspondentAccount;

  const j17Cell = sheet.getCell("J17");
  j17Cell.border = fillBorders();
  j17Cell.value = data.correspondentTaxNumber;

  const c19Cell = sheet.getCell("C19");
  c19Cell.border = fillBorders();
  c19Cell.value = data.correspondentBankName;
  c19Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j19Cell = sheet.getCell("J19");
  j19Cell.border = fillBorders();
  j19Cell.value = data.receiverMfo;
  j19Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c21Cell = sheet.getCell("C21");
  c21Cell.border = fillBorders();
  c21Cell.value = numberToWords(data.amount, options);
  c21Cell.style = { alignment: { wrapText: true }, border: fillBorders() };

  const c23Cell = sheet.getCell("C23");
  c23Cell.border = fillBorders();
  c23Cell.value = data.budgetTaxNumber;
  c23Cell.style = {
    alignment: { vertical: "middle", horizontal: "center" },
    border: fillBorders(),
  };

  const g23Cell = sheet.getCell("G23");
  g23Cell.border = fillBorders();
  g23Cell.value = data.budgetAccount;
  g23Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c25Cell = sheet.getCell("C25");
  c25Cell.value = data.budgetName;
  c25Cell.style = {
    alignment: { vertical: "middle", horizontal: "center" },
    border: fillBorders(),
  };

  const c27Cell = sheet.getCell("C27");
  c27Cell.value = data.paymentCode;
  c27Cell.style = {
    alignment: { vertical: "middle", horizontal: "center" },
    border: fillBorders(),
  };

  const c29Cell = sheet.getCell("C29");
  c29Cell.value = data.transactionDate ? `Проведён (${data.transactionDate})` : "";
  c29Cell.style = { font: { size: 9 } };
  c29Cell.border = fillBorders();

  const d27Cell = sheet.getCell("D27");
  d27Cell.value = data.detailsOfPayment ? data.detailsOfPayment : data.paymentDetail;

  const d32Cell = sheet.getCell("D32");
  // d32Cell.border = fillBorders();
  d32Cell.value = `${data.firstAuthorizerOtpKey ? data.firstAuthorizerOtpKey : ""} ${
    data.secondAuthorizerOtpKey ? data.secondAuthorizerOtpKey : ""
  } ${data.thirdAuthorizerOtpKey ? data.thirdAuthorizerOtpKey : ""}`;
  d32Cell.style = { alignment: { vertical: "middle", horizontal: "center" } };
}

export function createTransferLCYBudgetExcelFromForm(
  data: LcyTransferFormPropsExcel,
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createTransferLCYBudgetExcelSheet(workbook, data, options);
  return workbook;
}

export function createTransferLCYBudgetExcel(
  data: AllLCYDocProps[],
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();

  data.forEach((x) => createTransferLCYBudgetExcelSheet(workbook, x, options));

  return workbook;
}

export function createTransferLCYRevenueExcelSheet(
  workbook: ExcelJS.Workbook,
  data: any,
  { translate, ...options }: CreateTransferLCYExcel,
) {
  const name = `${data?.id}. ${data?.documentNumber} (${data?.documentDate})`;

  const sheet = workbook.addWorksheet(name, { pageSetup: { scale: 85, paperSize: 12 } });

  sheet.columns = [
    { key: "column1", width: 1.5 },
    { key: "column2", width: 22 },
    { key: "column3", width: 19 },
    { key: "column4", width: 8.8 },
    { key: "column5", width: 5.1 },
    { key: "column6", width: 4.8 },
    { key: "column7", width: 4.6 },
    { key: "column8", width: 7.1 },
    { key: "column9", width: 6.8 },
    { key: "column10", width: 12.1 },
    { key: "column11", width: 5.5 },
  ] as any;

  sheet.mergeCells("A1:E1");
  sheet.mergeCells("F1:G1");
  sheet.mergeCells("C5:J5");
  sheet.mergeCells("C8:E8");
  sheet.mergeCells("G8:I8");
  sheet.mergeCells("C10:F10");
  sheet.mergeCells("H10:I10");
  sheet.mergeCells("C12:F12");
  sheet.mergeCells("C14:J14");
  sheet.mergeCells("C17:E17");
  sheet.mergeCells("G17:I17");
  sheet.mergeCells("C19:F19");
  sheet.mergeCells("H19:I19");
  sheet.mergeCells("C21:J21");
  sheet.mergeCells("C23:J23");
  sheet.mergeCells("D25:J25");
  sheet.mergeCells("C26:J26");
  sheet.mergeCells("D27:F27");
  sheet.mergeCells("D28:F28");
  sheet.mergeCells("C31:C32");
  sheet.mergeCells("D31:E31");
  sheet.mergeCells("G31:H31");
  sheet.mergeCells("D32:E32");
  sheet.mergeCells("G32:H32");
  sheet.mergeCells("D29:I29");

  sheet.getRow(4).height = 8;
  sheet.getRow(6).height = 8;
  sheet.getRow(9).height = 8;
  sheet.getRow(10).height = 52;
  sheet.getRow(11).height = 8;
  sheet.getRow(13).height = 8;
  sheet.getRow(15).height = 8;
  sheet.getRow(18).height = 8;
  sheet.getRow(19).height = 50;
  sheet.getRow(20).height = 8;
  sheet.getRow(21).height = 28;
  sheet.getRow(22).height = 8;
  sheet.getRow(23).height = 30;
  sheet.getRow(25).height = 30;

  sheet.getCell("C23").border = {
    ...fillBorders(),
  };
  sheet.getCell("D22").border = { ...bottomBorders() };
  sheet.getCell("D24").border = { ...topBorders() };

  sheet.getCell("D25").border = fillBorders();

  // sheet.getCell("G27").border = bottomBorders();
  // sheet.getCell("H27").border = bottomBorders();
  // sheet.getCell("I27").border = bottomBorders();
  // sheet.getCell("J27").border = bottomBorders();

  // sheet.getCell("G28").border = bottomBorders();
  // sheet.getCell("H28").border = bottomBorders();
  // sheet.getCell("I28").border = bottomBorders();
  // sheet.getCell("J28").border = bottomBorders();

  sheet.getCell("C26").border = { ...bottomBorders(), ...leftBorders() };
  sheet.getCell("E26").border = bottomBorders();
  sheet.getCell("F26").border = bottomBorders();
  sheet.getCell("G26").border = bottomBorders();
  sheet.getCell("H26").border = bottomBorders();
  sheet.getCell("I26").border = bottomBorders();
  sheet.getCell("J26").border = bottomBorders();
  sheet.getCell("J26").border = { ...bottomBorders(), ...rightBorders() };

  sheet.getCell("C30").border = { ...topBorders(), ...leftBorders() };
  sheet.getCell("D30").border = topBorders();
  sheet.getCell("E30").border = topBorders();
  sheet.getCell("F30").border = topBorders();
  sheet.getCell("G30").border = topBorders();
  sheet.getCell("H30").border = topBorders();
  sheet.getCell("I30").border = topBorders();
  sheet.getCell("J30").border = { ...topBorders(), ...rightBorders() };

  sheet.getCell("D31").border = fillBorders();
  sheet.getCell("G31").border = fillBorders();
  sheet.getCell("J31").border = fillBorders();
  sheet.getCell("C31").border = leftBorders();

  sheet.getCell("D32").border = fillBorders();
  sheet.getCell("G32").border = fillBorders();
  sheet.getCell("J32").border = fillBorders();
  sheet.getCell("C32").border = leftBorders();

  sheet.getCell("C33").border = { ...bottomBorders(), ...leftBorders() };
  sheet.getCell("D33").border = bottomBorders();
  sheet.getCell("E33").border = bottomBorders();
  sheet.getCell("F33").border = bottomBorders();
  sheet.getCell("G33").border = bottomBorders();
  sheet.getCell("H33").border = bottomBorders();
  sheet.getCell("I33").border = bottomBorders();
  sheet.getCell("J33").border = { ...bottomBorders(), ...rightBorders() };

  const a1Cell = sheet.getCell("A1");
  a1Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_TITLE");
  a1Cell.style = { font: { bold: true }, alignment: { horizontal: "right" } };

  sheet.getCell("B3").value = translate("TRANSFER_LCY_EXPORT_EXCEL_DATE");

  const b5Cell = sheet.getCell("B5");
  b5Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_NAME");
  b5Cell.style = { font: { size: 8 } };

  const b7Cell = sheet.getCell("B7");
  b7Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_DEBIT");
  b7Cell.style = { font: { bold: true } };

  const b8Cell = sheet.getCell("B8");
  b8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_ACCOUNT");
  b8Cell.style = { font: { size: 8 } };

  const g8Cell = sheet.getCell("G8");
  g8Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_TAX_NUMBER");
  g8Cell.style = { font: { size: 9 } };

  const b10Cell = sheet.getCell("B10");
  b10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CLIENT_BANK_NAME");
  b10Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const h10Cell = sheet.getCell("H10");
  h10Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_SENDER_MFO");
  h10Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B12").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT");

  const b14Cell = sheet.getCell("B14");
  b14Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_NAME");
  b14Cell.style = { font: { size: 8 } };

  const b16Cell = sheet.getCell("B16");
  b16Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CREDIT");
  b16Cell.style = { font: { bold: true } };

  const b17Cell = sheet.getCell("B17");
  b17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_ACCOUNT");
  b17Cell.style = { font: { size: 8 } };

  const g17Cell = sheet.getCell("G17");
  g17Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_TAX_NUMBER");
  g17Cell.style = { font: { size: 9 } };

  const b19Cell = sheet.getCell("B19");
  b19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CORRESPONDENT_BANK_NAME");
  b19Cell.style = { alignment: { wrapText: true, vertical: "middle" } };

  const h19Cell = sheet.getCell("H19");
  h19Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_RECEIVER_MFO");
  h19Cell.style = {
    font: { size: 9 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  sheet.getCell("B21").value = translate("TRANSFER_LCY_EXPORT_EXCEL_AMOUNT_IN_WORDS");

  const b23Cell = sheet.getCell("B23");
  b23Cell.value = translate("TRANSFER_LCY_BUDGET_REVENUE");
  b23Cell.style = { alignment: { vertical: "middle", horizontal: "center", wrapText: true } };

  const d23Cell = sheet.getCell("D23");
  d23Cell.value = translate("TRANSFER_BUDGET_ACCOUNT_NUMBER");
  d23Cell.style = {
    font: { size: 10 },
    alignment: { horizontal: "center", vertical: "middle", wrapText: true },
  };

  const c27Cell = sheet.getCell("C27");
  c27Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_LEADER_NAME");
  c27Cell.style = { font: { size: 9 } };

  const d27Cell = sheet.getCell("D27");
  d27Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d27Cell.style = { alignment: { horizontal: "center" } };

  const b26Cell = sheet.getCell("B26");
  b26Cell.value = "Статус";
  b26Cell.style = { alignment: { horizontal: "center" } };

  const c28Cell = sheet.getCell("C28");
  c28Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_ACCOUNTANT");
  c28Cell.style = { font: { size: 9 } };

  const d28Cell = sheet.getCell("D28");
  d28Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_FIO");
  d28Cell.style = { alignment: { horizontal: "center" } };

  const c29Cell = sheet.getCell("C29");
  c29Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_OTPKEY_APPROVE");
  c29Cell.style = { font: { size: 9 } };

  const b31Cell = sheet.getCell("B31");
  b31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PRINT_PLACE");
  b31Cell.style = { alignment: { horizontal: "center" } };

  sheet.getCell("C31").value = translate("TRANSFER_LCY_EXPORT_EXCEL_BANK");

  const d31Cell = sheet.getCell("D31");
  d31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CHECKED");
  d31Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const g31Cell = sheet.getCell("G31");
  g31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_APPROVED");
  g31Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const j31Cell = sheet.getCell("J31");
  j31Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_CONDUCTED");
  j31Cell.style = { alignment: { horizontal: "center" }, border: fillBorders() };

  const b25Cell = sheet.getCell("B25");
  b25Cell.value = translate("TRANSFER_LCY_EXPORT_EXCEL_PAYMENT_DETAILS");

  // Заполнение данных
  const f1Cell = sheet.getCell("F1");
  f1Cell.border = fillBorders();
  f1Cell.value = data.documentNumber;

  const c3Cell = sheet.getCell("C3");
  c3Cell.border = fillBorders();
  c3Cell.value = data.documentDate;

  const c5Cell = sheet.getCell("C5");
  c5Cell.border = fillBorders();
  c5Cell.value = data.clientName;

  const c8Cell = sheet.getCell("C8");
  c8Cell.border = fillBorders();
  c8Cell.value = data.clientAccount;

  const j8Cell = sheet.getCell("J8");
  j8Cell.border = fillBorders();
  j8Cell.value = data.clientTaxNumber;

  const c10Cell = sheet.getCell("C10");
  c10Cell.border = fillBorders();
  c10Cell.value = data.clientBankName;
  c10Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j10Cell = sheet.getCell("J10");
  j10Cell.border = fillBorders();
  j10Cell.value = data.senderMfo;
  j10Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c12Cell = sheet.getCell("C12");
  c12Cell.border = fillBorders();
  c12Cell.value = data.amount;

  const c14Cell = sheet.getCell("C14");
  c14Cell.border = fillBorders();
  c14Cell.value = data.correspondentName;

  const c17Cell = sheet.getCell("C17");
  c17Cell.border = fillBorders();
  c17Cell.value = data.correspondentAccount;

  const j17Cell = sheet.getCell("J17");
  j17Cell.border = fillBorders();
  j17Cell.value = data.correspondentTaxNumber;

  const c19Cell = sheet.getCell("C19");
  c19Cell.border = fillBorders();
  c19Cell.value = data.correspondentBankName;
  c19Cell.style = { alignment: { wrapText: true, vertical: "middle" }, border: fillBorders() };

  const j19Cell = sheet.getCell("J19");
  j19Cell.border = fillBorders();
  j19Cell.value = data.receiverMfo;
  j19Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };

  const c21Cell = sheet.getCell("C21");
  c21Cell.border = fillBorders();
  c21Cell.value = numberToWords(data.amount, options);
  c21Cell.style = { alignment: { wrapText: true }, border: fillBorders() };

  const c23Cell = sheet.getCell("C23");
  c23Cell.border = fillBorders();
  c23Cell.value = data.budgetAccount;
  c23Cell.style = {
    alignment: { vertical: "middle", horizontal: "center" },
    border: fillBorders(),
  };

  const c25Cell = sheet.getCell("C25");
  c25Cell.value = data.paymentCode;
  c25Cell.style = {
    alignment: { vertical: "middle", horizontal: "center" },
    border: fillBorders(),
  };

  const d25Cell = sheet.getCell("D25");
  d25Cell.value = data.paymentDetail;
  d25Cell.style = {
    alignment: { vertical: "middle" },
    border: fillBorders(),
  };

  const c26Cell = sheet.getCell("C26");
  c26Cell.value = data.transactionDate ? `Проведён (${data.transactionDate})` : "";
  c26Cell.style = { alignment: { vertical: "middle" }, border: fillBorders() };
  c26Cell.border = fillBorders();

  const d29Cell = sheet.getCell("D29");
  // d29Cell.border = fillBorders();
  d29Cell.value = `${data.firstAuthorizerOtpKey ? data.firstAuthorizerOtpKey : ""} ${
    data.secondAuthorizerOtpKey ? data.secondAuthorizerOtpKey : ""
  } ${data.thirdAuthorizerOtpKey ? data.thirdAuthorizerOtpKey : ""}`;
  d29Cell.style = { alignment: { vertical: "middle", horizontal: "center" } };
}

export function createTransferLCYRevenueExcelFromForm(
  data: LcyTransferFormPropsExcel,
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createTransferLCYRevenueExcelSheet(workbook, data, options);
  return workbook;
}

export function createTransferLCYRevenueExcel(
  data: AllLCYDocProps[],
  options: CreateTransferLCYExcel,
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();

  data.forEach((x) => createTransferLCYRevenueExcelSheet(workbook, x, options));

  return workbook;
}

export enum PrintTransferLCYTableType {
  Budget = "budget",
  Revenue = "revenue",
  Standard = "standard",
}

interface PrintTransferLCYTableOptionsProps {
  readonly budgetList: AllLCYDocProps[];
  readonly revenueList: AllLCYDocProps[];
  readonly standardList: AllLCYDocProps[];
}

export function printTransferLCYTable({
  standardList,
  revenueList,
  budgetList,
}: PrintTransferLCYTableOptionsProps) {
  const formContainer = document.createElement("div");
  const portalRoot = document.querySelector("#print-root") as HTMLDivElement;

  if (formContainer) {
    formContainer.setAttribute("id", "TransferLCYPrintTable");

    const standardForm = document.querySelector(
      "#TransferLcyStandardTablePrint",
    ) as HTMLFormElement;

    // created new textarea field
    // const standardFormTextarea = document.createElement("textarea");
    // standardFormTextarea.setAttribute("maxLength", "5000");
    // standardFormTextarea.setAttribute("cols", "135");
    // standardFormTextarea.setAttribute("rows", "5");

    const revenueForm = document.querySelector("#TransferLcyRevenueTablePrint") as HTMLFormElement;
    const budgetForm = document.querySelector("#TransferLcyBudgetTablePrint") as HTMLFormElement;

    if (standardForm && standardList.length > 0) {
      standardList.forEach((x) => {
        // Заполнение формы
        standardForm.elements[0].setAttribute("value", x.documentNumber);
        standardForm.elements[1].setAttribute("value", x.documentDate);
        standardForm.elements[2].setAttribute("value", x.clientName);
        standardForm.elements[3].setAttribute("value", x.clientAccount);
        standardForm.elements[4].setAttribute("value", x.clientTaxNumber);
        standardForm.elements[5].setAttribute("value", x.clientBankName);
        standardForm.elements[6].setAttribute("value", x.senderMfo);
        standardForm.elements[7].setAttribute("value", x.amount);
        standardForm.elements[8].setAttribute("value", x.correspondentName);
        standardForm.elements[9].setAttribute("value", x.correspondentAccount);
        standardForm.elements[10].setAttribute("value", x.correspondentTaxNumber);
        standardForm.elements[11].setAttribute("value", x.correspondentBankName);
        standardForm.elements[12].setAttribute("value", x.receiverMfo);
        standardForm.elements[13].setAttribute("value", x.amountDetails);
        standardForm.elements[14].setAttribute("value", x.paymentCode);
        // standardForm.elements[15].setAttribute("value", x.paymentDetail);
        standardForm.elements[15].textContent = x.paymentDetail;
        {
          if (x.state === "3") {
            x.operationDate &&
              standardForm.elements[16].setAttribute(
                "value",
                `Success/Проведен (${x.operationDate})`,
              );
          } else {
            x.operationDate && standardForm.elements[16].setAttribute("value", "-");
          }
        }

        formContainer.appendChild(standardForm.cloneNode(true));
      });
    }

    if (revenueForm && revenueList.length > 0) {
      revenueList.forEach((x) => {
        // Заполнение формы
        revenueForm.elements[0].setAttribute("value", x.documentNumber);
        revenueForm.elements[1].setAttribute("value", x.documentDate);
        revenueForm.elements[2].setAttribute("value", x.clientName);
        revenueForm.elements[3].setAttribute("value", x.clientAccount);
        revenueForm.elements[4].setAttribute("value", x.clientTaxNumber);
        revenueForm.elements[5].setAttribute("value", x.clientBankName);
        revenueForm.elements[6].setAttribute("value", x.senderMfo);
        revenueForm.elements[7].setAttribute("value", x.amount);
        revenueForm.elements[8].setAttribute("value", x.correspondentName);
        revenueForm.elements[9].setAttribute("value", x.correspondentAccount);
        revenueForm.elements[10].setAttribute("value", x.correspondentTaxNumber);
        revenueForm.elements[11].setAttribute("value", x.correspondentBankName);
        revenueForm.elements[12].setAttribute("value", x.receiverMfo);
        revenueForm.elements[13].setAttribute("value", x.amountDetails);
        revenueForm.elements[14].setAttribute("value", String(x.budgetAccount));
        revenueForm.elements[15].setAttribute("value", x.paymentCode);
        // revenueForm.elements[16].setAttribute("value", x.paymentDetail);
        revenueForm.elements[16].textContent = x.paymentDetail;
        // {
        //   x.transactionDate &&
        //     revenueForm.elements[17].setAttribute("value", `Held/Проведен (${x.transactionDate})`);
        // }
        {
          if (x.state === "3") {
            x.operationDate &&
              revenueForm.elements[17].setAttribute(
                "value",
                `Success/Проведен (${x.operationDate})`,
              );
          } else {
            x.operationDate && revenueForm.elements[17].setAttribute("value", "-");
          }
        }

        {
          // if (x.state) {
          //   revenueForm.elements[18].setAttribute(
          //     "value",
          //     x.firstAuthorizerOtpKey
          //       ? x.firstAuthorizerOtpKey + " "
          //       : " " + x.secondAuthorizerOtpKey
          //       ? x.secondAuthorizerOtpKey + " "
          //       : " " + x.thirdAuthorizerOtpKey
          //       ? x.thirdAuthorizerOtpKey + ""
          //       : "",
          //   );
          // } else {
          //   revenueForm.elements[18].setAttribute("value", "-");
          // }
        }

        formContainer.appendChild(revenueForm.cloneNode(true));
      });
    }
    if (budgetForm && budgetList.length > 0) {
      budgetList.forEach((x) => {
        // Заполнение формы
        budgetForm.elements[0].setAttribute("value", x.documentNumber);
        budgetForm.elements[1].setAttribute("value", x.documentDate);
        budgetForm.elements[2].setAttribute("value", x.clientName);
        budgetForm.elements[3].setAttribute("value", x.clientAccount);
        budgetForm.elements[4].setAttribute("value", x.clientTaxNumber);
        budgetForm.elements[5].setAttribute("value", x.clientBankName);
        budgetForm.elements[6].setAttribute("value", x.senderMfo);
        budgetForm.elements[7].setAttribute("value", x.amount);
        budgetForm.elements[8].setAttribute("value", x.correspondentName);
        budgetForm.elements[9].setAttribute("value", x.correspondentAccount);
        budgetForm.elements[10].setAttribute("value", x.correspondentTaxNumber);
        budgetForm.elements[11].setAttribute("value", x.correspondentBankName);
        budgetForm.elements[12].setAttribute("value", x.receiverMfo);
        budgetForm.elements[13].setAttribute("value", x.amountDetails);
        budgetForm.elements[14].setAttribute("value", x.budgetTaxNumber);
        budgetForm.elements[15].setAttribute("value", String(x.budgetAccount));
        budgetForm.elements[16].setAttribute("value", x.budgetName);
        budgetForm.elements[17].setAttribute("value", x.paymentCode);
        budgetForm.elements[18].textContent = x.paymentDetail;
        // {
        //   x.transactionDate &&
        //     budgetForm.elements[19].setAttribute("value", `Held/Проведен (${x.transactionDate})`);
        // }
        {
          if (x.state === "3") {
            x.operationDate &&
              budgetForm.elements[19].setAttribute(
                "value",
                `Success/Проведен (${x.operationDate})`,
              );
          } else {
            x.operationDate && budgetForm.elements[19].setAttribute("value", "-");
          }
        }

        {
          // if (x.state) {
          //   budgetForm.elements[20].setAttribute(
          //     "value",
          //     x.firstAuthorizerOtpKey
          //       ? x.firstAuthorizerOtpKey + " "
          //       : " " + x.secondAuthorizerOtpKey
          //       ? x.secondAuthorizerOtpKey + " "
          //       : " " + x.thirdAuthorizerOtpKey
          //       ? x.thirdAuthorizerOtpKey + ""
          //       : "",
          //   );
          // } else {
          //   budgetForm.elements[20].setAttribute("value", "-");
          // }
        }

        formContainer.appendChild(budgetForm.cloneNode(true));
      });
    }

    if (portalRoot) {
      portalRoot.appendChild(formContainer);
    }

    printForm({
      type: "html",
      targetStyles: ["*"],
      printable: "TransferLCYPrintTable",
      // header: "PrintJS - Form Element Selection",
      // style: '.input-box input { height: "10px"; }',
      onError: () => formContainer.remove(),
      onPrintDialogClose: () => formContainer.remove(),
    });
  }
}

interface PrintTransferFCYTableOptionsProps {
  readonly fcyList: FcyTransferForPending[];
}

export function printTransferFcyTable({ fcyList }: PrintTransferFCYTableOptionsProps) {
  const formContainer = document.createElement("div");
  const portalRoot = document.querySelector("#print-root") as HTMLDivElement;

  if (formContainer) {
    formContainer.setAttribute("id", "TransferFcyTablePrintForm");

    const fcyForm = document.querySelector("#TransferFcyTablePrint") as HTMLFormElement;

    if (fcyForm && fcyList.length > 0) {
      fcyList.forEach((x) => {
        // Заполнение формы
        fcyForm.elements[0].setAttribute("value", x.transferNumber);
        fcyForm.elements[2].setAttribute("value", x.transactionDate);
        fcyForm.elements[4].setAttribute("value", x.applicantName);
        fcyForm.elements[6].setAttribute("value", x.taxId);
        fcyForm.elements[8].setAttribute("value", x.applicantAddress);
        fcyForm.elements[9].setAttribute("value", x.applicantAccount);
        fcyForm.elements[11].setAttribute("value", x.beneficiaryName);
        fcyForm.elements[12].setAttribute("value", x.beneficiaryTaxId);
        fcyForm.elements[14].setAttribute("value", x.beneficiaryAddress);
        fcyForm.elements[15].setAttribute("value", x.beneficiaryAccount);
        fcyForm.elements[17].setAttribute("value", x.bankName);
        fcyForm.elements[19].setAttribute("value", x.bankAddress);
        fcyForm.elements[21].setAttribute("value", x.bankSwift);
        fcyForm.elements[23].setAttribute("value", x.beneficiaryBankName);
        fcyForm.elements[25].setAttribute("value", x.beneficiaryBankAddress);
        fcyForm.elements[27].setAttribute("value", x.beneficiarySwiftCode);
        fcyForm.elements[29].setAttribute("value", x.currency);
        fcyForm.elements[31].setAttribute("value", x.amount);
        fcyForm.elements[33].setAttribute("value", x.amountInWord);
        fcyForm.elements[39].setAttribute("value", x.correspondentBankName1);
        fcyForm.elements[41].setAttribute("value", x.correspondentSwiftCode1);
        fcyForm.elements[42].setAttribute("value", x.correspondentBankName2);
        fcyForm.elements[44].setAttribute("value", x.correspondentSwiftCode2);
        fcyForm.elements[49].setAttribute("value", x.detailsOfPayment);
        fcyForm.elements[53].setAttribute("value", x.remarks);
        fcyForm.elements[56].setAttribute("value", x.commissionAccount);

        formContainer.appendChild(fcyForm.cloneNode(true));
      });
    }

    if (portalRoot) {
      portalRoot.appendChild(formContainer);
    }

    printForm({
      type: "html",
      targetStyles: ["*"],
      printable: "TransferFcyTablePrintForm",
      onError: () => formContainer.remove(),
      onPrintDialogClose: () => formContainer.remove(),
    });
  }
}
interface PrintTransferHistoryTableOptionsProps {
  readonly historyList: TransferHistoryProps[];
}

export function printTransferHistoryTable({ historyList }: PrintTransferHistoryTableOptionsProps) {
  const formContainer = document.createElement("div");
  const portalRoot = document.querySelector("#print-root") as HTMLDivElement;

  if (formContainer) {
    formContainer.setAttribute("id", "TransferHistoryTablePrintForm");

    const historyForm = document.querySelector("#TransferLcyStandardTablePrint") as HTMLFormElement;

    if (historyForm && historyList.length > 0) {
      historyList.forEach((x) => {
        // Заполнение формы
        historyForm.elements[0].setAttribute("value", x.documentNumber);
        historyForm.elements[1].setAttribute("value", (x.documentDate = x.transactionDate));
        historyForm.elements[2].setAttribute(
          "value",
          (x.clientAccount = x.creditAmount === "0" ? x.clientName : x.receiverName),
        );
        historyForm.elements[3].setAttribute(
          "value",
          (x.clientAccount = x.creditAmount === "0" ? x.senderAccount : x.receiverAccount),
        );
        // @ts-ignore
        if (x.senderTaxNumber != null) {
          historyForm.elements[4].setAttribute(
            "value",
            x.debitAmount === "0" ? x.receiverTaxNumber : x.senderTaxNumber,
          );
        }
        historyForm.elements[5].setAttribute(
          "value",
          (x.clientBankName = x.creditAmount === "0" ? x.senderBankName : x.receiverBankName),
        );
        historyForm.elements[6].setAttribute(
          "value",
          x.debitAmount === "0" ? x.receiverMfo : x.senderMfo,
        );

        historyForm.elements[7].setAttribute(
          "value",
          (x.amount = x.creditAmount === "0" ? x.debitAmount : x.creditAmount),
        );
        historyForm.elements[8].setAttribute(
          "value",
          // (x.clientName = x.creditAmount === "0" ? x.clientName : x.receiverName),
          (x.clientAccount = x.creditAmount === "0" ? x.receiverName : x.clientName),
        );
        historyForm.elements[9].setAttribute(
          "value",
          (x.correspondentAccount = x.creditAmount === "0" ? x.receiverAccount : x.senderAccount),
        );
        // if (x.correspondentTaxNumber != null) {
        //   historyForm.elements[10].setAttribute("value", x.correspondentTaxNumber);
        // }
        if (x.senderTaxNumber != null) {
          historyForm.elements[10].setAttribute(
            "value",
            x.debitAmount === "0" ? x.senderTaxNumber : x.receiverTaxNumber,
            // x.senderTaxNumber
          );
        }
        historyForm.elements[11].setAttribute(
          "value",
          (x.correspondentBankName =
            x.creditAmount === "0" ? x.receiverBankName : x.senderBankName),
        );
        historyForm.elements[12].setAttribute(
          "value",
          // x.debitAmount === "0" ? x.receiverMfo : x.rcMfo,
          x.debitAmount === "0" ? x.senderMfo : x.receiverMfo,
        );
        historyForm.elements[13].setAttribute("value", x.amountDetails);
        // historyForm.elements[13].setAttribute(
        //   "value",
        //   (x.amount = x.creditAmount === "0" ? x.debitAmount : x.creditAmount),
        // );
        historyForm.elements[14].setAttribute("value", x.paymentCode);
        // historyForm.elements[15].setAttribute("value", (x.paymentDetail = x.paymentDetails));
        historyForm.elements[15].textContent = x.paymentDetail = x.paymentDetails;

        formContainer.appendChild(historyForm.cloneNode(true));
      });
    }

    if (portalRoot) {
      portalRoot.appendChild(formContainer);
    }

    printForm({
      type: "html",
      targetStyles: ["*"],
      printable: "TransferHistoryTablePrintForm",
      onError: () => formContainer.remove(),
      onPrintDialogClose: () => formContainer.remove(),
    });
  }
}

export function createCardHoldersExcelFromTable(
  data: any,
  options: { translate: TranslateFunction },
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createCardHoldersExcelSheet(workbook, data, options);

  return workbook;
}

function createCardHoldersExcelSheet(workbook: ExcelJS.Workbook, data: any, { translate }: any) {
  const sheet = workbook.addWorksheet("hello", { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 10 },
    { key: "column2", width: 25 },
    { key: "column3", width: 50 },
    { key: "column4", width: 15 },
    { key: "column5", width: 15 },
    { key: "column6", width: 15 },
  ] as any;

  const a1Cell = sheet.getCell(`A1`);
  a1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  a1Cell.value = "№";
  a1Cell.border = fillBorders();
  a1Cell.font = { bold: true };

  const b1Cell = sheet.getCell(`B1`);
  b1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  b1Cell.value = translate("SALARY_CREATE_NEW_REGISTER_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE");
  b1Cell.border = fillBorders();
  b1Cell.font = { bold: true };

  const c1Cell = sheet.getCell(`C1`);
  c1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  c1Cell.value = translate("SALARY_CREATE_NEW_REGISTER_TABLE_FULL_NAME_COLUMN_TITLE");
  c1Cell.border = fillBorders();
  c1Cell.font = { bold: true };

  const d1Cell = sheet.getCell(`D1`);
  d1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  d1Cell.value = translate(
    "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE",
  );
  d1Cell.border = fillBorders();
  d1Cell.font = { bold: true };

  const e1Cell = sheet.getCell(`E1`);
  e1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  e1Cell.value = translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE");
  e1Cell.border = fillBorders();
  e1Cell.font = { bold: true };

  const f1Cell = sheet.getCell(`F1`);
  f1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  f1Cell.value = translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE");
  f1Cell.border = fillBorders();
  f1Cell.font = { bold: true };

  let tableCells = 1;
  for (let i = 0; i < data.length; i++) {
    tableCells = tableCells + 1;

    const aCell = sheet.getCell(`A${tableCells}`);
    aCell.alignment = { vertical: "middle", horizontal: "center" };
    aCell.value = i + 1;
    aCell.border = fillBorders();

    const bCell = sheet.getCell(`B${tableCells}`);
    bCell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    bCell.value = data[i].accountNumber;
    bCell.border = fillBorders();

    const cCell = sheet.getCell(`C${tableCells}`);
    cCell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    cCell.value = data[i].employerName;
    cCell.border = fillBorders();

    const dCell = sheet.getCell(`D${tableCells}`);
    dCell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    dCell.value = data[i].cardId;
    dCell.border = fillBorders();

    const eCell = sheet.getCell(`E${tableCells}`);
    eCell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    eCell.value = data[i].openDate;
    eCell.border = fillBorders();

    const fCell = sheet.getCell(`F${tableCells}`);
    fCell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
    fCell.value = data[i].status;
    fCell.border = fillBorders();
  }
}

export function createFavoritesExcelFromTable(
  data: any,
  options: { translate: TranslateFunction },
): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  createFavoritesExcelSheet(workbook, data, options);

  return workbook;
}

function createFavoritesExcelSheet(workbook: ExcelJS.Workbook, data: any, { translate }: any) {
  const sheet = workbook.addWorksheet("hello", { pageSetup: { scale: 85, paperSize: 9 } });

  // Создание колонок
  sheet.columns = [
    { key: "column1", width: 15 },
    { key: "column2", width: 25 },
    { key: "column3", width: 25 },
    { key: "column4", width: 50 },
    { key: "column5", width: 25 },
    { key: "column6", width: 20 },
    { key: "column7", width: 20 },
    { key: "column8", width: 50 },
    { key: "column9", width: 25 },
  ] as any;

  const a1Cell = sheet.getCell(`A1`);
  a1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  a1Cell.value = "№";
  a1Cell.border = fillBorders();
  a1Cell.font = { bold: true };

  const b1Cell = sheet.getCell(`B1`);
  b1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  b1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DOC_TYPE_COLUMN_TITLE");
  b1Cell.border = fillBorders();
  b1Cell.font = { bold: true };

  const c1Cell = sheet.getCell(`C1`);
  c1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  c1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DEBIT_ACCOUNT_COLUMN_TITLE");
  c1Cell.border = fillBorders();
  c1Cell.font = { bold: true };

  const d1Cell = sheet.getCell(`D1`);
  d1Cell.style = { font: { size: 9 }, alignment: { vertical: "justify" } };
  d1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_BENEFICIARY_NAME_COLUMN_TITLE");
  d1Cell.border = fillBorders();
  d1Cell.font = { bold: true };

  const e1Cell = sheet.getCell(`E1`);
  e1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  e1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_CREDIT_ACCOUNT_COLUMN_TITLE");
  e1Cell.border = fillBorders();
  e1Cell.font = { bold: true };

  const f1Cell = sheet.getCell(`F1`);
  f1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  f1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_AMOUNT_COLUMN_TITLE");
  f1Cell.border = fillBorders();
  f1Cell.font = { bold: true };

  const g1Cell = sheet.getCell(`G1`);
  g1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  g1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_DETAILS_COLUMN_TITLE");
  g1Cell.border = fillBorders();
  g1Cell.font = { bold: true };

  const h1Cell = sheet.getCell(`H1`);
  h1Cell.style = { font: { size: 9 }, alignment: { vertical: "middle" } };
  h1Cell.value = translate("TRANSFERS_FAVOURITES_MODAL_TABLE_INQUIRY_COLUMN_TITLE");
  h1Cell.border = fillBorders();
  h1Cell.font = { bold: true };

  let tableCells = 1;
  for (let i = 0; i < data.length; i++) {
    tableCells = tableCells + 1;

    const aCell = sheet.getCell(`A${tableCells}`);
    aCell.alignment = { vertical: "middle", horizontal: "center" };
    aCell.value = i + 1;
    aCell.border = fillBorders();

    const bCell = sheet.getCell(`B${tableCells}`);
    bCell.alignment = { vertical: "middle", horizontal: "center" };
    bCell.value = data[i].docType;
    bCell.border = fillBorders();

    const cCell = sheet.getCell(`C${tableCells}`);
    cCell.alignment = { vertical: "middle", horizontal: "center" };
    cCell.value = data[i].clientAccount;
    cCell.border = fillBorders();

    const dCell = sheet.getCell(`D${tableCells}`);
    dCell.alignment = { vertical: "middle", horizontal: "center" };
    dCell.value = data[i].correspondentName;
    dCell.border = fillBorders();

    const eCell = sheet.getCell(`E${tableCells}`);
    eCell.alignment = { vertical: "middle", horizontal: "center" };
    eCell.value = data[i].correspondentAccount;
    eCell.border = fillBorders();

    const fCell = sheet.getCell(`F${tableCells}`);
    fCell.alignment = { vertical: "middle", horizontal: "center" };
    fCell.value = data[i].amount;
    fCell.border = fillBorders();

    const gCell = sheet.getCell(`G${tableCells}`);
    gCell.alignment = { vertical: "middle", horizontal: "center" };
    gCell.value = data[i].currency;
    gCell.border = fillBorders();

    const hCell = sheet.getCell(`H${tableCells}`);
    hCell.alignment = { vertical: "middle", horizontal: "center" };
    hCell.value = data[i].details;
    hCell.border = fillBorders();

    const iCell = sheet.getCell(`I${tableCells}`);
    iCell.alignment = { vertical: "middle", horizontal: "center" };
    iCell.value = data[i].favoriteName;
    iCell.border = fillBorders();
  }
}
