import { AppFilter, AppFilterProps } from "./AppFilter";
import { GetConversionDocumentsListQuery } from "../api/conversion/ConversionApi";
import { formatDateFromUrl, formatDateToFilter } from "../utils/DateUtils";

export enum HistoryFilterTabs {
  Conversion = "conversion",
  ExchangeRate = "exchange-rate",
  History = "history",
}

interface ConversionFilterProps extends AppFilterProps<HistoryFilterTabs> {
  readonly fromDate?: string;
  readonly toDate?: string;
}

export class HistoryFilter extends AppFilter<HistoryFilterTabs> {
  private readonly fromDate?: Date;
  private readonly toDate?: Date;
  public constructor({ tab, fromDate, toDate, ...props } = {} as ConversionFilterProps) {
    super({ ...props, tab: tab || HistoryFilterTabs.Conversion });
    this.fromDate = new Date();
    this.toDate = new Date();

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }
  }
  public getConversionFilter(): GetConversionDocumentsListQuery {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      fromDate: formatDateToFilter(this.fromDate),
      toDate: formatDateToFilter(this.toDate),
    };
  }
}
