import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function AnalyticsIcon({
  size = 20,
  color = ColorPalette.BlueExtraLight,
  ...svgProps
}: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 19.175 19.175"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(9.588)">
        <path
          fill={color}
          transform="translate(-256 0)"
          d="M256.4,0a.4.4,0,0,0-.4.4V9.188a.4.4,0,0,0,.4.4h8.789a.4.4,0,0,0,.4-.4A9.2,9.2,0,0,0,256.4,0Z"
        />
      </g>
      <g transform="translate(0 0.799)">
        <path
          fill={color}
          transform="translate(0 -21.333)"
          d="M14.3,37.467,8.789,30.385V21.732a.4.4,0,0,0-.4-.4C3.763,21.333,0,25.455,0,30.521a9.2,9.2,0,0,0,9.188,9.188,6.825,6.825,0,0,0,5.047-1.689A.4.4,0,0,0,14.3,37.467Z"
        />
      </g>
      <g transform="translate(10.387 10.387)">
        <path
          fill={color}
          transform="translate(-277.338 -277.333)"
          d="M285.727,277.333h-7.99a.4.4,0,0,0-.31.65l5.193,6.429a.4.4,0,0,0,.283.148h.028a.4.4,0,0,0,.272-.107,9.226,9.226,0,0,0,2.923-6.722A.4.4,0,0,0,285.727,277.333Z"
        />
      </g>
    </svg>
  );
}
