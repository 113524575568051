import React from "react";
import { Form, Formik, FormikProps } from "formik";
import { number, object, string } from "yup";

import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { useI18n } from "../../i18n/I18nContext";
import { DatePickerField } from "../form/DatePickerField";
import { SelectPickerField } from "../form/SelectPickerField";
import InfoTitle, { InformationBody } from "../info-for-user/InfoTitle";
import { OnShowUserInfo } from "../on-show-user-info/OnShowUserInfo";
import { FcyToCreate } from "../../api/transfer/TransferDTO";
import { Button, ButtonColor } from "../ui/Button";
import { Direction, RoleType, SizeType } from "../../api/dto/AppDTO";
import { RadioGroupField } from "../form/RadioGroupField";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userRoleSelector } from "../../reducers/userReducer";
import { currencyCodeCheck } from "../../utils/TextUtils";
import { CheckboxField } from "../form/CheckboxField";
import "./assets/transfer-fcy-form.scss";
import { formatPhoneNumber } from "../../utils/FormatUtils";

interface Props {
  readonly debits: [{ label: ""; value: "" }];

  readonly commissionAccount: [{ label: ""; value: "" }];

  readonly onSubmit: (values: FcyToCreate) => void;

  readonly initialValues?: FcyToCreate;

  readonly formikRef?: (instance: FormikProps<FcyToCreate>) => void;

  readonly getBeneficiaryName: () => void;

  readonly getNacCode: () => void;

  readonly onChangeDebitsAccount: (value: any) => void;

  readonly onChangeAmount: (value: any) => void;
  readonly onChangeDocumentDate: (value: any) => void;
  readonly onChangeCorrespondentName: (value: any) => void;
  readonly onChangeCorrespondentAccount: (value: any) => void;
  readonly onChangeCorrespondentAddress: (value: any) => void;
  readonly onChangeCorrespondentTaxNumber: (value: any) => void;
  readonly onChangeTransferNumber: (value: any) => void;
  readonly onChangeSenderPhoneNumber: (value: any) => void;
  readonly onChangeContractNumber: (value: any) => void;
  readonly onChangeIsIdn: (value: any) => void;
  readonly onChangeBankSwift1: (value: any) => void;
  readonly onChangeBankSwift2: (value: any) => void;
  readonly onChangeDetails: (value: any) => void;
  readonly onChangeRemarks: (value: any) => void;
  readonly onChangeKPP: (value: any) => void;
  readonly onChangeCorrAccount: (value: any) => void;
  readonly onChangeBeneficiaryBankSwift: (value: any) => void;
  readonly onChangeCommissionAccount: (value: any) => void;
  readonly onChangeCurrency: (value: any) => void;
  readonly onChangeCharges: (value: any) => void;
  readonly onChangeServicesType: (value: any, naceCode?: any) => void;
  readonly onChangeVoCode: (value: any) => void;
  readonly onChangeBeneficiaryBankName: (value: any) => void;
  readonly onChangeBeneficiaryBankAddress: (value: any) => void;
  readonly onChangeCorrespondentName2: (value: any) => void;
  readonly onChangeCorrespondentName1: (value: any) => void;
  readonly onCheckSwiftCode: (value: any) => void;
  readonly onCheckCorrespondent2SwiftCode: (value: any) => void;
  readonly onCheckCorrespondent1SwiftCode: (value: any) => void;
  readonly onChangeHsCode: (value: any) => void;
  readonly onChangeNacCode: (value: any) => void;
  readonly onBlurCurrency: () => void;
  readonly onBlurHsCode: (value) => void;
  readonly onChangeINVnumber: (value) => void;
  readonly onChangeINVNDate: (value) => void;
  readonly onBlurINVnumber: (value) => void;
  readonly onBlurINVNDate: () => void;

  readonly swiftCodes: string[];
  readonly isSwiftCode: boolean;
  readonly onChangeIsSwiftCode: () => void;
  

  readonly swiftCodesCorrespondent: string[];
  readonly isSwiftCodeCorrespondent: boolean;
  readonly onChangeIsSwiftCodeCorrespondent: () => void;

  readonly checkCurrency: string;
}

export function FcyTransferForm({
  debits,
  onSubmit,
  initialValues,
  formikRef,
  getBeneficiaryName,
  onChangeIsIdn,
  onChangeDebitsAccount,
  onChangeDocumentDate,
  onChangeAmount,
  onChangeTransferNumber,
  onChangeCorrespondentName,
  onChangeCorrespondentAccount,
  onChangeCorrespondentTaxNumber,
  onChangeCorrespondentAddress,
  onChangeSenderPhoneNumber,
  onChangeBankSwift1,
  onChangeBankSwift2,
  onChangeDetails,
  onChangeRemarks,
  onChangeKPP,
  onChangeCorrAccount,
  onChangeBeneficiaryBankSwift,
  commissionAccount,
  onChangeCommissionAccount,
  onChangeCurrency,
  onChangeCharges,
  onChangeContractNumber,
  onChangeServicesType,
  onChangeVoCode,
  onChangeBeneficiaryBankName,
  onChangeBeneficiaryBankAddress,
  onChangeCorrespondentName2,
  onChangeCorrespondentName1,
  onChangeHsCode,
  onCheckSwiftCode,
  onBlurCurrency,
  onCheckCorrespondent2SwiftCode,
  onCheckCorrespondent1SwiftCode,
  onBlurHsCode,
  getNacCode,
  onChangeNacCode,
  onChangeINVnumber,
  onChangeINVNDate,
  onBlurINVnumber,
  onBlurINVNDate,

  swiftCodes,
  isSwiftCode,
  onChangeIsSwiftCode,

  swiftCodesCorrespondent,
  isSwiftCodeCorrespondent,
  onChangeIsSwiftCodeCorrespondent,

  checkCurrency
}: Props) {
  const isRole = useShallowEqualSelector(userRoleSelector);
  const { translate } = useI18n();

  const validationSchema = object({
    amount: number()
      .max(99999999999999999, "Amount 18 digits")
      .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    beneficiaryTaxId:
      initialValues?.currency === "RUB"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    beneficiaryName: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    beneficiaryAddress: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    applicantBalance: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    beneficiaryAccount: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    beneficiaryBankName: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    beneficiaryBankAddress: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    senderPhoneNumber: string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    contractNumber: initialValues?.isIdn
      ? string()
          .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
          .min(9, "Contract Number: minimum 9 characters.")
          .max(9, "Contract Number: maximum 9 characters.")
      : string(),
    beneficiarySwiftCode: string()
      .max(12, "SwiftCode: maximum 12 characters.")
      .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    correspondentSwiftCode1: string().max(12, "SwiftCode: maximum 12 characters.").nullable(),
    correspondentSwiftCode2: string().max(12, "SwiftCode: maximum 12 characters.").nullable(),
    currency:
      isRole === RoleType.Corporate
        ? string()
            .matches(currencyCodeCheck(), translate("WRONG_CURRENCY_TYPE"))
            .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : object().required(),
    details: string()
      .max(255, `${translate("CONVERSION_MAX_SYMBOLS")}: 255`)
      .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    commissionBalance:
      isRole === RoleType.Corporate
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string(),
    transferNumber: number()
      .max(9999999999, "Number 10 digits")
      .required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")),
    voCode:
      initialValues?.currency === "RUB"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE")).min(5).max(5)
        : string().nullable(),
    corrAccount:
      initialValues?.currency === "RUB"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    kpp:
      initialValues?.currency === "RUB"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    invNumber:
      initialValues?.currency === "EUR" && initialValues.servicesType === "goods"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    invnDate:
      initialValues?.currency === "EUR" && initialValues.servicesType === "goods"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    hsCode:
      initialValues?.currency === "EUR" && initialValues.servicesType === "goods"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
    naceCode:
      initialValues?.currency === "EUR"
        ? string().required(translate("FORMS_REQUIRED_FIELD_ERROR_TITLE"))
        : string().nullable(),
  });

  const currencies = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "RUB", value: "RUB" },
    { label: "GBP", value: "GBP" },
  ];

  const handleItemClick = (selectedCode) => {
    onChangeIsSwiftCode()
    onCheckSwiftCode(selectedCode);
    onChangeBeneficiaryBankSwift(selectedCode);
  };


  const handleItemClickCorrespondent = (selectedCode) => {
    onChangeIsSwiftCodeCorrespondent()
    onCheckCorrespondent1SwiftCode(selectedCode);
    onChangeBankSwift1(selectedCode)
  };


  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues as FcyToCreate}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <Form className="p-5" onSubmit={handleSubmit}>
          <GroupBox contentClassName="row" className="mb-7">
            <div className="col-lg-6">
              <div className="row  flex-lg-nowrap">
                <div className="col-lg-6 pr-lg-2">
                  <InputField
                    name="transferNumber"
                    label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeTransferNumber(value.target.value)}
                    value={initialValues?.transferNumber}
                    type="number"
                    inputClassName="amount"
                  />
                </div>
                <div className="col-lg-6 pl-lg-2 mt-lg-0 mt-3">
                  <DatePickerField
                    name="transactionDate"
                    label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeDocumentDate(value)}
                    minDate={new Date()}
                  />
                </div>
                <div className="col-12 mt-lg-0 mt-2">
                  <span>
                    1. {translate("TRANSFER_TEXT_REQUIRED")} A B C D E F G H I J K L M N O P Q R S T
                    U V W X Y Z 0 1 2 3 4 5 6 7 8 9 / - ? : ( ) . , ' +
                  </span>
                  <br />
                  <span className="d-flex mt-2">
                    2. {translate("TRANSFER_FILL_INFO")}
                    <div>
                      <OnShowUserInfo
                        title={<InfoTitle table={true} infoTitle={InformationBody.NoInfo} />}
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </GroupBox>
          <div className="row">
            <div className="col-lg-6 ">
              <GroupBox
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TITLE"}
                className="mb-7"
              >
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <SelectPickerField
                      name="applicantAccount"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                      options={debits}
                      onChanges={(value) => onChangeDebitsAccount(value)}
                    />
                  </div>
                  <div className="col-lg-6 mt-lg-0 mt-2">
                    <InputField
                      name="applicantBalance"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                      disabled={true}
                    />
                  </div>
                </div>
                <InputField
                  className="mb-2"
                  name="applicantName"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                  disabled={true}
                  inputClassName="text-uppercase"
                />
                <div className="row">
                  {isRole !== RoleType.Corporate ? (
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="passportNumber"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PASSWORD_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                    </div>
                  ) : (
                    <div className="col-lg-6">
                      <InputField
                        className="mb-2"
                        name="applicantTaxId"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE"
                        disabled={true}
                        inputClassName="text-uppercase"
                      />
                    </div>
                  )}
                  <div className="col-lg-6">
                    <InputField
                      className="mb-2"
                      name="senderPhoneNumber"
                      label={
                        translate(
                          "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_NUMBER_FIELD_LABEL_TITLE",
                        ) +
                        ` (${
                          initialValues?.senderPhoneNumber !== "+998"
                            ? initialValues?.phoneOperator
                            : translate(
                                "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_CODE_LABEL_TITLE",
                              )
                        })`
                      }
                      inputClassName="text-uppercase"
                      value={initialValues?.senderPhoneNumber}
                      onChange={(event) => onChangeSenderPhoneNumber(event.target.value)}
                      onBlur={(event) =>
                        onChangeSenderPhoneNumber(formatPhoneNumber(event.target.value))
                      }
                      disabled={isRole === RoleType.Individual}
                    />
                  </div>
                </div>

                <InputField
                  className="mb-2"
                  name="applicantAddress"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                  disabled={true}
                  inputClassName="text-uppercase"
                />
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE"}
                className="mb-7"
              >
                <div className="d-flex flex-column p-0 col-12">
                  <label className="text-ellipsis mb-1">
                    {translate(
                      "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE",
                    )}
                  </label>
                  <div className="input-group">
                    <InputField
                      className="flex-1"
                      name="beneficiaryName"
                      onChange={(value) => onChangeCorrespondentName(value.target.value)}
                      onBlur={(value) =>
                        onChangeCorrespondentName(value.target.value.toUpperCase())
                      }
                      value={initialValues?.beneficiaryName}
                      inputClassName="text-uppercase"
                    />
                    <div className="input-group-append">
                      <Button
                        type="button"
                        size={SizeType.Medium}
                        color={ButtonColor.Orange}
                        onClick={getBeneficiaryName}
                      >
                        {translate(
                          "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CORRESPONDENT_BUTTON_TITLE",
                        )}
                      </Button>
                    </div>
                  </div>
                  <span className="pl-1 text-danger">
                    {initialValues?.beneficiaryName &&
                    /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryName)
                      ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                      : ""}
                  </span>
                  <div className="row my-2">
                    <InputField
                      className="col-12"
                      name="beneficiaryTaxId"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_TAX_ID_FIELD_LABEL_TITLE"
                      onChange={(value) => onChangeCorrespondentTaxNumber(value.target.value)}
                      onBlur={(value) =>
                        onChangeCorrespondentTaxNumber(value.target.value.toUpperCase())
                      }
                      value={initialValues?.beneficiaryTaxId}
                      inputClassName="text-uppercase"
                    />
                  </div>
                  <span className="pl-1 text-danger">
                    {initialValues?.beneficiaryTaxId &&
                    /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryTaxId)
                      ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                      : ""}
                  </span>
                  <div className="row mb-2">
                    <InputField
                      className="col-12"
                      name="beneficiaryAddress"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                      onChange={(value) => onChangeCorrespondentAddress(value.target.value)}
                      onBlur={(value) =>
                        onChangeCorrespondentAddress(value.target.value.toUpperCase())
                      }
                      value={initialValues?.beneficiaryAddress}
                      inputClassName="text-uppercase"
                    />
                  </div>
                  <span className="pl-1 text-danger">
                    {initialValues?.beneficiaryAddress &&
                    /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryAddress)
                      ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                      : ""}
                  </span>
                  <div className="row mb-2">
                    <InputField
                      className="col-12"
                      name="beneficiaryAccount"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                      onChange={(value) => onChangeCorrespondentAccount(value.target.value)}
                      onBlur={(value) =>
                        onChangeCorrespondentAccount(value.target.value.toUpperCase())
                      }
                      value={initialValues?.beneficiaryAccount}
                      inputClassName="text-uppercase"
                    />
                  </div>
                  <span className="pl-1 text-danger">
                    {initialValues?.beneficiaryAccount &&
                    /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryAccount)
                      ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                      : ""}
                  </span>
                </div>
              </GroupBox>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <GroupBox
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_TITLE"}
                className="mb-7"
              >
                <InputField
                  name="bankName"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                  disabled={true}
                  inputClassName="text-uppercase"
                />
                <span className="pl-1 text-danger">{""}</span>
                <InputField
                  name="bankAddress"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                  disabled={true}
                  inputClassName="text-uppercase"
                />
                <span className="pl-1 text-danger">{""}</span>
                <InputField
                  name="bankSwift"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE"
                  disabled={true}
                  inputClassName="text-uppercase"
                />
                <span className="pl-1 text-danger">{""}</span>
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_TITLE_N2"}
                className="mb-7"
              >
                <InputField
                  name="beneficiaryBankName"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_NAME_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeBeneficiaryBankName(value?.target?.value)}
                  onBlur={(value) =>
                    onChangeBeneficiaryBankName(value?.target?.value.toUpperCase())
                  }
                  value={initialValues?.beneficiaryBankName}
                  inputClassName="text-uppercase"
                  disabled={false}
                />
                <span className="pl-1 text-danger">
                  {initialValues?.beneficiaryBankName &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryBankName)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
                <InputField
                  name="beneficiaryBankAddress"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_ADDRESS_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeBeneficiaryBankAddress(value?.target?.value)}
                  onBlur={(value) =>
                    onChangeBeneficiaryBankAddress(value?.target?.value.toUpperCase())
                  }
                  value={initialValues?.beneficiaryBankAddress}
                  inputClassName="text-uppercase"
                  disabled={false}
                />
                <span className="pl-1 text-danger">
                  {initialValues?.beneficiaryBankAddress &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiaryBankAddress)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
                <div>
                    <InputField
                        name="beneficiarySwiftCode"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_SWIFT_CODE_FIELD_LABEL_TITLE"
                        onChange={(value) => onChangeBeneficiaryBankSwift(value.target.value)}
                        onBlur={(value) => {
                          onCheckSwiftCode(value.target.value.toUpperCase());
                          onChangeBeneficiaryBankSwift(value.target.value.toUpperCase());
                        }}
                        // value={initialValues?.beneficiarySwiftCode}
                        inputClassName="text-uppercase"
                    />

                    <div>
                      {isSwiftCode && swiftCodes?.length > 0 && (
                        <div className="beneficiarySwiftCode-list">
                          <ul>
                            {swiftCodes?.map((code, index) => (
                              <li key={index} onClick={() => handleItemClick(code)}>
                                {code}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                </div>

                <span className="pl-1 text-danger">
                  {initialValues?.beneficiarySwiftCode &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.beneficiarySwiftCode)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </GroupBox>
            </div>
          </div>
          <div className="row mb-7">
            <div className="col-lg-6 ">
              <GroupBox
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_AMOUNT_TITLE"}
                className="h-100"
              >
                <div className="row">
                  <div className="col-lg-6">
                    {isRole === RoleType.Corporate ? (
                      <InputField
                        data-testid="fcy-currency"
                        name="currency"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE"
                        type="text"
                        onChange={(value) => onChangeCurrency(value.target.value)}
                        onBlur={(value) => {
                          onBlurCurrency();
                          onChangeCurrency(value.target.value.toUpperCase());
                        }}
                        maxLength={3}
                        inputClassName="text-uppercase"
                      />
                    ) : (
                      <SelectPickerField
                        name="currency"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_CURRENCY_FIELD_LABEL_TITLE"
                        options={currencies}
                        onChanges={(value) => onChangeCurrency(value)}
                        disabled={true}
                      />
                    )}
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      name="amount"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_DIGIT_FIELD_LABEL_TITLE"
                      value={initialValues?.amount}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      //   NotificationManager.error(translate("AMOUNT_COPY_PASTE"));
                      //   return false;
                      // }}
                      onFocus={() => {
                        initialValues?.amount &&
                          onChangeAmount(removeGaps(initialValues?.amount.replace(/[,]/g, ".")));
                        onBlurCurrency();
                      }}
                      onChange={(value) => {
                        onChangeAmount(value.target.value.replace(/[,]/g, "."));
                        onBlurCurrency();
                      }}
                      onBlur={(value) => {
                        initialValues?.amount &&
                          onChangeAmount(
                            floatFormat(addZeros(value.target.value.replace(/[,]/g, "."))),
                          );
                        onBlurCurrency();
                      }}
                      placeholder={"0.00"}
                      inputClassName="amount"
                    />
                  </div>
                </div>
                <span className="pl-1 text-danger">{""}</span>
                <InputField
                  name="amountInWord"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_ORDERING_BANK_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                  disabled={true}
                  value={initialValues?.amountInWord}
                />
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                title={
                  "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_CORRESPONDENT_BANK_FIELD_LABEL_TITLE"
                }
              >
                <InputField
                  name="correspondentBankName1"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BANK_NAME_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeCorrespondentName1(value.target.value)}
                  onBlur={(value) => onChangeCorrespondentName1(value.target.value.toUpperCase())}
                  value={initialValues?.correspondentBankName1}
                  inputClassName="text-uppercase"
                  disabled={false}
                />
                <span className="pl-1 text-danger">
                  {initialValues?.correspondentBankName1 &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.correspondentBankName1)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>

                <div>
                  <InputField
                    name="correspondentSwiftCode1"
                    label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SWIFT_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangeBankSwift1(value.target.value)}
                    onBlur={(value) => {
                      onCheckCorrespondent1SwiftCode(value.target.value.toUpperCase());
                      onChangeBankSwift1(value.target.value.toUpperCase());
                    }}
                    value={initialValues?.correspondentSwiftCode1}
                    inputClassName="text-uppercase"
                  />
                  <div>
                      {isSwiftCodeCorrespondent && swiftCodesCorrespondent?.length > 0 && (
                        <div className="beneficiarySwiftCode-list">
                          <ul>
                            {swiftCodesCorrespondent?.map((code, index) => (
                              <li key={index} onClick={() => handleItemClickCorrespondent(code)}>
                                {code}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>


                </div>
               
                <span className="pl-1 text-danger">
                  {initialValues?.correspondentSwiftCode1 &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.correspondentSwiftCode1)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </GroupBox>
            </div>
          </div>
          <div className="row mb-7">
            <div className="col-lg-6">
              <GroupBox
                className="h-100"
                title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_TITLE"}
              >
                <RadioGroupField
                  name="charges"
                  direction={Direction.Vertical}
                  items={[
                    {
                      value: "BEN",
                      title:
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_BENEFICIARY_CHECKBOX_LABEL_TITLE",
                      infoTitle: InformationBody.InformationBodyTitle_2,
                      disabled: checkCurrency === "RUB" ? true : false 
                    },
                    {
                      value: "OUR",
                      title:
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FROM_REMITTER_CHECKBOX_LABEL_TITLE",
                      infoTitle: InformationBody.InformationBodyTitle_3, 
                    },
                    {
                      value: "SHA",
                      title:
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_CHARGES_OF_JSC_CHECKBOX_LABEL_TITLE",
                      infoTitle: InformationBody.InformationBodyTitle_4, 
                    },
                    {
                      value: "FND",
                      title:
                        "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CHARGES_FOR_TRANSFER_FULL_NO_DEDUCT_CHECKBOX_LABEL_TITLE",
                      infoTitle: InformationBody.InformationBodyTitle_5,
                      disabled: checkCurrency === "EUR" ? true : checkCurrency === "RUB" ? true : false
                    },
                  ]}
                  onChanges={(value) => onChangeCharges(value)}
                />
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_OTHERS_FIELD_LABEL_TITLE"}>
                {/* {isRole === RoleType.Corporate && ( */}
                <div className="row">
                  <div className="col-lg-6 pb-2">
                    <SelectPickerField
                      name="commissionAccount"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DEBIT_COMMISSION_FIELD_LABEL_TITLE"
                      options={commissionAccount}
                      onChanges={(value) => onChangeCommissionAccount(value)}
                      disabled={isRole === RoleType.Corporate ? false : true}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      name="commissionBalance"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                      disabled={true}
                    />
                  </div>
                </div>
                {/* )} */}
                <InputField
                  name="remarks"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_BENEFICIARY_BANK_REMARKS_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeRemarks(value.target.value)}
                  onBlur={(value) => onChangeRemarks(value.target.value.toUpperCase())}
                  value={initialValues?.remarks}
                  inputClassName="text-uppercase"
                />
                <span className="pl-1 text-danger">
                  {initialValues?.remarks && /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.remarks)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </GroupBox>
            </div>
          </div>
          {initialValues?.currency === "EUR" && (
            <div className="row mb-5">
              <div className="col-lg-6">
                <GroupBox
                  title="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_OR_SERVICES_LABEL_TITLE"
                  className="pt-5 h-100"
                >
                  <RadioGroupField
                    name="servicesType"
                    direction={Direction.Horizontal}
                    items={[
                      {
                        value: "goods",
                        title: "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_GOODS_LABEL_TITLE",
                      },
                      {
                        value: "services",
                        title: "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_SERVICES_LABEL_TITLE",
                      },
                    ]}
                    onChanges={(value) => onChangeServicesType(value, initialValues?.naceCode)}
                  />
                </GroupBox>
              </div>
              <div className="col-lg-6">
                <GroupBox>
                  <div className="row">
                    <div className="col-12">
                      <label className="text-ellipsis mb-1">
                        {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_LABEL_TITLE")}
                      </label>
                      <div className="input-group">
                        <InputField
                          className="flex-1"
                          name="naceCode"
                          onChange={(value) => onChangeNacCode(value.target.value)}
                          onBlur={(value) => onChangeNacCode(value.target.value.toUpperCase())}
                          disabled={true}
                          value={initialValues?.naceCode}
                          inputClassName="text-uppercase"
                        />
                        <div className="input-group-append">
                          <Button
                            type="button"
                            size={SizeType.Medium}
                            color={ButtonColor.Orange}
                            onClick={getNacCode}
                          >
                            {translate(
                              "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_NACCODE_BUTTON_TITLE",
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </GroupBox>
              </div>
            </div>
          )}
          <div className="row mb-7">
            <div className="col-lg-6 ">
              <GroupBox
                className="h-100 pt-2"
                title="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_FIELD_LABEL_TITLE"
              >
                {initialValues?.servicesType === "goods" && initialValues.currency === "EUR" && (
                  <div className="row">
                    <div className="col-lg-4">
                      <DatePickerField
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INVN_DATE_LABEL_TITLE"
                        name="invnDate"
                        onChange={(value) => onChangeINVNDate(value)}
                        onBlur={() => onBlurINVNDate()}
                      />
                    </div>

                    <div className="col-lg-4">
                      <InputField
                        name="invNumber"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_INV_NUMBER_LABEL_TITLE"
                        className="mb-2"
                        onChange={(value) => onChangeINVnumber(value.target.value)}
                        onBlur={(value) => onBlurINVnumber(value.target.value)}
                        value={initialValues?.invNumber}
                        inputClassName="text-uppercase"
                      />
                    </div>

                    <div className="col-lg-4">
                      <InputField
                        name="hsCode"
                        label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_HS_CODE_LABEL_TITLE"
                        className="mb-2"
                        type="number"
                        onChange={(value) => onChangeHsCode(value.target.value)}
                        onBlur={(value) => onBlurHsCode(value.target.value)}
                        value={initialValues?.hsCode}
                        inputClassName="amount"
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <InputField
                      data-testid="fcy-details"
                      name="details"
                      label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DETAILS_PAYMENT_FIELD_LABEL_TITLE"
                      onChange={(value) => onChangeDetails(value.target.value)}
                      value={initialValues?.details}
                      inputClassName="text-uppercase"
                    />
                  </div>
                </div>
                {isRole === RoleType.Corporate && (
                  <>
                    <div className="row mt-3">
                      <div className="col-12">
                        <CheckboxField
                          name="isIdn"
                          label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_IS_IDN_LABEL_TITLE"
                          onChange={() => onChangeIsIdn(!initialValues?.isIdn)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-4">
                        <InputField
                          className="flex-grow-1 flex-shrink-1"
                          name="tinOfBank"
                          label="CONVERSION_IDN_NUMBER_TIN_OF_BANK_FIELD_TITLE"
                          value={initialValues?.tinOfBank}
                          disabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputField
                          className="flex-grow-1 flex-shrink-1"
                          name="tinOfCustomer"
                          label="CONVERSION_IDN_NUMBER_TIN_OF_CUSTOMER_FIELD_TITLE"
                          value={initialValues?.tinOfCustomer}
                          disabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputField
                          className="flex-grow-1 flex-shrink-1"
                          name="contractNumber"
                          label="CONVERSION_IDN_NUMBER_CONTRACT_NUMBER_TITLE"
                          onChange={(event) => onChangeContractNumber(event.target.value)}
                          value={initialValues?.isIdn ? initialValues?.contractNumber : ""}
                          disabled={!initialValues?.isIdn}
                          type="number"
                          inputClassName="amount"
                        />
                      </div>
                    </div>
                  </>
                )}
                <span className="pl-1 text-danger">
                  {initialValues?.details && /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.details)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </GroupBox>
            </div>
            
          </div>

          <div className="col-12 d-flex flex-column mb-7">
            <span className="font-weight-bold">
              {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_1_TITLE")}
            </span>
            <span className="font-weight-bold">
              {translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_CAPTION_2_TITLE")}
            </span>
            <span>{translate("TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_DESCRIPTION_TITLE")}</span>
          </div>
          {initialValues?.currency === "RUB" && isRole === RoleType.Corporate && (
            <GroupBox contentClassName="row">
              <div className="col-4">
                <InputField
                  name="kpp"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_KPP_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeKPP(value.target.value)}
                  onBlur={(value) => onChangeKPP(value.target.value.toUpperCase())}
                  value={initialValues?.kpp}
                />
                <span className="pl-1 text-danger">
                  {initialValues?.kpp && /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.kpp)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </div>
              <div className="col-4">
                <InputField
                  name="corrAccount"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_CORRESPONDENT_ACCOUNT_FIELD_LABEL_TITLE"
                  onChange={(value) => onChangeCorrAccount(value.target.value)}
                  onBlur={(value) => onChangeCorrAccount(value.target.value.toUpperCase())}
                  value={initialValues?.corrAccount}
                />
                <span className="pl-1 text-danger">
                  {initialValues?.corrAccount &&
                  /[^A-z0-9-/?:().,'+ ]/gi.test(initialValues?.corrAccount)
                    ? translate("TRANSFERS_USE_THIS_SYMBOLS")
                    : ""}
                </span>
              </div>
              <div className="col-4">
                <InputField
                  name="voCode"
                  label="TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_VO_CODE_LABEL_TITLE"
                  onChange={(value) => onChangeVoCode(value.target.value)}
                  onBlur={(value) => onChangeVoCode(value.target.value.toUpperCase())}
                  value={initialValues?.voCode}
                />
              </div>
            </GroupBox>
          )}
        </Form>
      )}
    </Formik>
  );
}
