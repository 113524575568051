import React, { useMemo, useState, useEffect } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { Loader } from "../ui/Loader";
import "./assets/excel-table.scss";
import "../table/assets/table.scss";


interface Props {
  readonly data: any;
  readonly importModalTable: boolean;
}

export function ExcelTable({ data = [], importModalTable }: Props) {
  const { translate } = useI18n();
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");

  useEffect(() => {
    const paymentCodes = data.map((item) => item?.paymentCode);
    const budgetAccount = data.map((item) => item?.budgetAccount);
    const budgetTaxNumber = data.map((item) => item?.budgetTaxNumber);
    const detailsOfPayments = data.map((item) => item?.detailsOfPayment);
    const paymentDetails = data.map((item) => item?.paymentDetails);

    setData1(paymentCodes);
    setData2(budgetAccount);
    setData3(budgetTaxNumber);
    setData4(detailsOfPayments);
    setData5(paymentDetails)

  }, [data]);
  
  const columns = useMemo(() => {
    const list: Column<any>[] = [
      {
        width: 40,
        accessor: "documentIndex",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: "№",
      },
      {
        width: 100,
        accessor: "documentDate",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_DATE_COLUMN_TITLE"),
      },
      {
        width: 90,
        accessor: "documentNumber",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_DOCUMENT_NUMBER_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "clientAccount",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_SENDER_ACCOUNT_COLUMN_TITLE"),
      },
    
      {
        width: 200,
        accessor: "correspondentName",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_NAME_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "correspondentAccount",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_ACCOUNT_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "correspondentTaxNumber",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_TIN_COLUMN_TITLE"),
      },
      
      {
        width: 300,
        accessor: "paymentCode",
        Cell: ({ row }) => {
          const data1Lower = data1[row.index]?.toLowerCase();
          const data2Lower = data2[row.index]?.toLowerCase();
          const data3Lower = data3[row.index]?.toLowerCase();
          const data4Lower = data4[row.index]?.toLowerCase();
      
        
          const hasError1 = data1Lower?.includes('cell') || data1Lower?.includes('ячейк') || data1Lower?.includes('katak');
          const hasError2 = data2Lower?.includes('cell') || data2Lower?.includes('ячейк') || data2Lower?.includes('katak');
          const hasError3 = data3Lower?.includes('cell') || data3Lower?.includes('ячейк') || data3Lower?.includes('katak');
          const hasError4 = data4Lower?.includes('cell') || data4Lower?.includes('ячейк') || data3Lower?.includes('katak');

              
          const hasAnyError = hasError1 || hasError2 || hasError3 || hasError4;
      
          if (hasAnyError) {
            return (
              <div className="excel-file-payment-details">
                {hasError1 && <span className="text-danger">{data1[row.index]}</span>}
                {hasError2 && <span className="text-danger">{data2[row.index]}</span>}
                {hasError3 && <span className="text-danger">{data3[row.index]}</span>}
                {hasError4 && <span className="text-danger">{data4[row.index]}</span>}
              </div>
            );
          } else {
            return (
              <span className="excel-file-payment-details">
                {data5[row.index]}
              </span>
            );
          }
        },
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_DETAILS_OF_PAYMENT_COLUMN_TITLE"),
      },
      
      {
        width: 150,
        accessor: "amount",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_AMOUNT_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "correspondentBank",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center ${row.value && (row.value.toLowerCase().includes('cell') || row.value.toLowerCase().includes('ячейк') || row.value.toLowerCase().includes('katak')) ? 'text-danger' : ''}`}>
            {row.value}
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_BENEFICIARY_BANK_CODE_COLUMN_TITLE"),
      },
      {
        width: 130,
        accessor: "status",
        Cell: (row) => (
          <span className={`excel-font-size d-flex justify-content-center text-center ${row.value.includes('Error') ? 'text-danger' : ''}`}>
            <span className={`${row.value.includes('Success') || row.value.includes('Успешно') || row.value.includes('Muvaffaqiyatli') ? 'excel-file-success-status' : ''}`}>
              <span className={`${row.value.includes('Imported') || row.value.includes('Импортировано') || row.value.includes('Import qilindi') ? 'excel-file-imported' : ''}`}>
                {row.value}
              </span>
            </span>
          </span>
        ),
        Header: translate("TRANSFERS_EXCEL_FILE_TABLE_STATUS_COLUMN_TITLE"),
      },
    ];

    return list;
  }, [translate, data1, data2, data3, data4, data5]);

  return (
    <>    
      {importModalTable ? (
        <>
          <Table data={data} columns={columns}/>
        </>
      ) : (
        <div className="d-flex flex-1 align-items-center justify-content-center m-4">
          <Loader />
        </div>
      )}

    </>
  );
}
