import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { CorrespondentProps } from "../../api/transfer/TransferDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";

interface Props {
  readonly loading?: boolean;
  readonly data: CorrespondentProps[];
  readonly onCorrespondentSelect?: (value) => void;
  readonly modalFor?: boolean;
}

export function CorrespondentsTable({
  data = [],
  loading,
  onCorrespondentSelect,
  modalFor,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<CorrespondentProps>[] = [
      {
        width: 90,
        accessor: "taxNumber",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_TAX_ID_COLUMN_TITLE"),
      },
      {
        width: 60,
        accessor: "mfo",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_BANK_CODE_COLUMN_TITLE"),
      },
      {
        width: 140,
        accessor: "name",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_THE_BANK_COLUMN_TITLE"),
      },
      {
        width: 200,
        accessor: "bankName",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_NAME_OF_PARTNER_COLUMN_TITLE"),
      },
      {
        width: 140,
        accessor: "account",
        Header: translate("TRANSFERS_CORRESPONDENTS_TABLE_ACCOUNT_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => {
          return (
            <>
              {modalFor ? (
                <a
                  className="font-weight-bold"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    onCorrespondentSelect &&
                    onCorrespondentSelect(
                      `${row.cell.row.values.name}${row.cell.row.values.mfo}${row.cell.row.values.taxNumber}${row.cell.row.values.account}`,
                    )
                  }
                >
                  {row.value}
                </a>
              ) : (
                <div>{row.value}</div>
              )}
            </>
          );
        },
      },
    ];

    return list;
  }, [modalFor, onCorrespondentSelect, translate]);

  return <Table data={data} columns={columns} loading={loading} />;
}
