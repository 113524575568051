import "./assets/page-layout.scss";

import cx from "classnames";
import React, { ReactNode } from "react";
import { Transition } from "react-transition-group";

import { MenuWrapper } from "./MenuWrapper";
import { Direction, MenuState } from "../../api/dto/AppDTO";
import { menuStateSelector } from "../../reducers/appReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

const defaultStyles = {
  paddingLeft: 250,
};

const transitionStyles = {
  exited: { paddingLeft: 68 },
  exiting: { paddingLeft: 68 },
  entered: { paddingLeft: 250 },
  entering: { paddingLeft: 250 },
};

export interface PageLayoutProps {
  readonly children: ReactNode;
  readonly menuComponent?: ReactNode;
  readonly contentClassName?: string;
  readonly contentDirection?: Direction;
  readonly contentBackground?: string;
}

export function PageLayout({
  children,
  menuComponent,
  contentClassName,
  contentBackground,
  contentDirection = Direction.Column,
}: PageLayoutProps) {
  const menuState = useShallowEqualSelector(menuStateSelector);
  const isMobile = CheckMobileUtils();

  return (
    <>
      {isMobile ? (
        <Transition in={menuState === MenuState.Expanded} timeout={250}>
          {(state) => (
            <div className="d-flex flex-shrink-1 flex-grow-1 page-layout">
              {Boolean(menuComponent) && <MenuWrapper state={state}>{menuComponent}</MenuWrapper>}

              <div
                className={cx(
                  "d-flex flex-1 overflow-auto",
                  {
                    "flex-column": contentDirection === Direction.Column,
                  },
                  contentClassName,
                )}
                style={{
                  backgroundColor: contentBackground,
                }}
              >
                <div className="d-flex flex-column flex-shrink-1 flex-grow-1">{children}</div>
              </div>
            </div>
          )}
        </Transition>
      ) : (
        <Transition in={menuState === MenuState.Expanded} timeout={250}>
          {(state) => (
            <div
              className="d-flex flex-shrink-1 flex-grow-1 page-layout"
              style={{
                ...defaultStyles,
                ...transitionStyles[state],
              }}
            >
              {Boolean(menuComponent) && <MenuWrapper state={state}>{menuComponent}</MenuWrapper>}

              <div
                className={cx(
                  "d-flex flex-1 overflow-auto",
                  {
                    "flex-column": contentDirection === Direction.Column,
                  },
                  contentClassName,
                )}
                style={{
                  backgroundColor: contentBackground,
                }}
              >
                <div className="d-flex flex-column flex-shrink-1 flex-grow-1">{children}</div>
              </div>
            </div>
          )}
        </Transition>
      )}
    </>
  );
}
