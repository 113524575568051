import "react-notifications/lib/notifications.css";

import { Provider } from "react-redux";
import React, { useMemo } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { PersistGate } from "redux-persist/integration/react";

import { RootContainer } from "./RootContainer";
import { ProviderContainer } from "./ProviderContainer";
import { configureStore } from "../store/configureStore";
import MobileMenuWrapper from "../responsive/MobileMenuWrapper";

export function AppContainer() {
  const store = useMemo(() => configureStore(), []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store.store}>
      <PersistGate persistor={store.persistor}>
        <ProviderContainer>
          <BrowserRouter>
            <>
              <Route component={RootContainer} />

              <NotificationContainer />
              <MobileMenuWrapper />
            </>
          </BrowserRouter>
        </ProviderContainer>
      </PersistGate>
    </Provider>
  );
}
