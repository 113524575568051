import React, { useCallback, useState, useMemo } from "react";
import { NotificationManager } from "react-notifications";

import { TransfersLCYTab } from "./TransfersLCYTab";
import { showError } from "../../utils/NotificationUtils";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { useI18n } from "../../i18n/I18nContext";
import { DateTime } from "luxon";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";

interface Props {
  readonly filter: TransfersFilter;
}

export function TransfersLCYTabWrapper({ filter }: Props) {
  const { translate } = useI18n();
  const { TransferApi } = useTransferContext();
  const locationHelpers = useLocationHelpers();
  const [loading, setLoading] = useState(false);

  const { AccountApi } = useAccountContext();
  const getTotalApprovalApl = useResource(() => AccountApi.getTotalPendingApplications(), []);
  const totalApp = useMemo(() => getTotalApprovalApl.data, [getTotalApprovalApl.data]);

  const onSubmit = useCallback(
    (value) => {
      const type =
        value.paymentType === "01" || value.paymentType === "21"
          ? totalApp?.beginDay === "1"
            ? "01"
            : totalApp?.beginDay === "0"
            ? "21"
            : ""
          : value.paymentType;
      const date = DateTime.fromFormat(value?.documentDate, "dd.MM.yyyy").toJSDate();
      const dateFor = new Date();
      dateFor.setDate(dateFor.getDate() - 1);
      setLoading(true);
      if (value.correspondentTaxNumber.length !== 9) {
        NotificationManager.error(
          translate("TRANSFER_CORRESPANDENT_TAX_ID_INCORRECT_IMPORT"),
          translate("TRANSFER_INFO_TITLE"),
          7000,
        );
        setLoading(false);
      } else if (date < dateFor) {
        NotificationManager.error(
          translate("TRANSFER_DATE_INCORRECT_IMPORT"),
          translate("TRANSFER_INFO_TITLE"),
          7000,
        );
        setLoading(false);
      } else if (value.state === "101") {
        TransferApi.updateLcyTransfer(value.id, { ...value, paymentType: type, documentType: type })
          .then(() => {
            setLoading(false);
            locationHelpers.replaceQuery({ transferType: TransferType.Unselect });
            NotificationManager.info(
              translate("TRANSFER_MODIFIED"),
              translate("TRANSFER_INFO_TITLE"),
              7000,
            );
          })
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      } else {
        TransferApi.createLcyTransfer({ ...value, paymentType: type })
          .then(() => {
            NotificationManager.info(
              translate("TRANSFER_INFO_CREATED"),
              translate("TRANSFER_INFO_TITLE"),
              7000,
            );
            locationHelpers.replaceQuery({
              transferType: TransferType.Unselect,
              reservedAmountInUZS: "",
              reserveAccountInUZS: "",
              fromUzToFcyConversationPage: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      }
    },
    [TransferApi, locationHelpers, translate, totalApp],
  );

  return <TransfersLCYTab filter={filter} onLcySubmit={onSubmit} loading={loading} />;
}
