import React, { useCallback, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";

import { TabPage } from "../tabs/TabPage";
import { FavoritesTable } from "./FavoritesTable";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { useResource } from "../../hooks/useResource";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userLoginSelector } from "../../reducers/userReducer";
import { Button } from "../ui/Button";
import { Modal } from "../ui/Modal";
import { useI18n } from "../../i18n/I18nContext";
import { createFavoritesExcelFromTable } from "../../helpers/TransfersHelpers";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import { FileControls } from "../ui/FileControls";

export function FavoritesTab() {
  const { TransferApi } = useTransferContext();
  const login = useShallowEqualSelector(userLoginSelector);
  const { translate } = useI18n();

  const [alert, setAlert] = useState(false);
  const [documentID, setDocumentId] = useState({});

  const setId = useCallback((value, docType, alert) => {
    setAlert(alert);
    setDocumentId({ value, docType });
  }, []);

  const getAllFavorites = useResource(() => TransferApi.getAllFavorites({ login }), []);
  const data = useMemo(() => getAllFavorites.data || [], [getAllFavorites.data]);

  const onFavoriteDelete = useCallback(() => {
    TransferApi.deleteFavoriteDocument({
      // @ts-ignore
      id: documentID.value,
      // @ts-ignore
      type: documentID.docType !== "FCY Transfer" ? "LCY" : "FCY",
    }).then(() => {
      setAlert(false);
      window.location.reload();
    });
    // @ts-ignore
  }, [TransferApi, documentID.docType, documentID.value]);

  return (
    <>
      <div className=" px-4 pt-7">
        <FileControls
          isMinusTransferLcyType={true}
          onSaveToExcelClick={() => {
            // @ts-ignore
            if (data.length > 0) {
              NotificationManager.info(translate("TRANSFER_LOADING"), "", 5000);
              const workbook = createFavoritesExcelFromTable(data, {
                translate,
              });
              saveExcelToFile(workbook, "Transfers Favorites").catch(showError);
            } else {
              NotificationManager.error(translate("TABLE_EMPTY_STATE_TITLE"));
            }
          }}
        />
      </div>
      <TabPage className="px-4 py-3">
        <FavoritesTable setId={setId} loading={false} data={data} />
        <Modal show={alert} onClose={() => setAlert(false)} title="FAVORITES_DELETE_BUTTON">
          <div className="d-flex justify-content-end">
            <Button className="bg-secondary py-2 px-5 mr-2" onClick={() => onFavoriteDelete()}>
              {translate(
                "TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_YES_RADIO_BUTTON_LABEL_TITLE",
              )}
            </Button>
            <Button className="bg-danger py-2 px-5" onClick={() => setAlert(false)}>
              {translate("TRANSFERS_SALARY_EMPLOYEE_REGISTRATION_FORM_NO_RADIO_BUTTON_LABEL_TITLE")}
            </Button>
          </div>
        </Modal>
      </TabPage>
    </>
  );
}
