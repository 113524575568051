import cx from "classnames";
import React, { useMemo } from "react";

import { I18nCode } from "../../i18n/I18nSchema";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly className?: string;
  readonly title: I18nCode | string;
  readonly containerClassName?: string;
}

export function PageTitle({ title, className, containerClassName }: Props) {
  const { translate } = useI18n();

  const titleText = useMemo(() => {
    if (title) {
      return translate(title);
    }
  }, [title, translate]);

  if (!titleText) {
    return null;
  }

  return (
    <div className={cx("d-flex flex-column", containerClassName)}>
      <h2 className={cx("m-0 font-weight-bold fs-5", className)}>{titleText}</h2>
    </div>
  );
}
