import "./assets/statement-transaction-item.scss";

import React from "react";
import cx from "classnames";
import { addZeros, floatFormat } from "../../utils/FloatUtils";

interface Props {
  readonly className?: string;
  readonly data?: any;
}

export function StatementTransactionItem({ className, data }: Props) {
  return (
    <div
      className={cx(
        "border border-gray-extra-light br-1 p-3 statement-transaction-item",
        className,
      )}
    >
      <table className="table table-borderless mb-0">
        <tr>
          <th className="width-for-items-1">{data?.bankTellerSequence}</th>
          <th className="width-for-items-1">{data?.bankTellerId}</th>
          <th className="width-for-items-4">{data?.receiverAccount}</th>
          <th className="width-for-items-2">{`(${data?.receiverMfo})`}</th>
          <th className="text-center width-for-items-5">
            {floatFormat(addZeros(data?.debitAmount ? data.debitAmount : "0"))}
          </th>
          <th className="text-center width-for-items-3">
            {floatFormat(addZeros(data?.creditAmount ? data.creditAmount : "0"))}
          </th>
          <th className="text-center">
            {floatFormat(addZeros(data?.balance ? data.balance : "0"))}
          </th>
        </tr>

        <tr>
          <th className="width-for-items-1">Doc. №</th>
          <td className="width-for-items-1">{data?.documentNumber}</td>
          <td
            rowSpan={3}
            colSpan={5}
          >{`${data?.receiverTaxNumber} ${data?.receiverName} ${data?.paymentDetail}`}</td>
        </tr>

        <tr>
          <th className="width-for-items-1" style={{ whiteSpace: "nowrap" }}>
            Val. date
          </th>
          <td className="width-for-items-1">{data?.transactionDate}</td>
        </tr>

        <tr>
          <th className="width-for-items-1">Tr. date</th>
          <td className="width-for-items-1">{data?.transactionDate}</td>
        </tr>
      </table>
    </div>
  );
}
