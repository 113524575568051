import React from "react";
import { AppHeaderWrapper } from "./AppHeaderWrapper";
import { PageLayout, PageLayoutProps } from "./PageLayout";

export function AppLayout(props: PageLayoutProps) {
  return (
    <div className="d-flex flex-column flex-shrink-1 flex-grow-1">
      <AppHeaderWrapper />

      <PageLayout {...props} />
    </div>
  );
}
