import { formatAccountNumber } from "../helpers/AccountHelpers";
import { AppFilter, AppFilterProps } from "./AppFilter";
import {
  formatDateFromUrl,
  formatDateToFilter,
  formatDateToBody,
  formatDateToUrl,
  getDateDay,
  getDateMonth,
  getDateYear,
} from "../utils/DateUtils";
import {
  AccountCoverFilterFormProps,
  AccountFilterFormProps,
  AccountInquiryFilterFormProps,
  AccountListQueryProps,
  AccountStatementFilterFormProps,
  AccountStatementListQueryProps,
} from "../api/account/AccountDTO";

export enum AccountsTabs {
  Inquiry = "inquiry",
  Accounts = "accounts",
  Statement = "statement",
  NewAccountOpening = "new-account-opening",
}

export enum ApprovalAccountsSubmitType {
  NoneStandInquiry = "none-stand-inquiry",
  StandInquiry = "stand-inquiry",
  NewAccountOpening = "new-account-opening",
}

export enum ApprovalTradeFinanceSubmitType {
  Application = "application",
  Amendment = "amendment",
  Discrepancy = "discrepancy",
}

export enum ApprovalPaymentClaimSubmitType {
  SentPaymentClaim = "sent-payment-claims",
  ReceivedPaymentClaim = "received-payment-claims",
  CreateNewPaymentClaim = "create-new-payment-claim",
}

export enum NewAccountOpeningTabType {
  NewAccOpening = "new-acc-opening",
  RequestForOpening = "request-for-opening",
}

export enum SwiftInnerTabType {
  IncomingCover = "incoming-cover",
  Outgoing = "outgoing",
  Incoming = "incoming",
}

export enum InquirySubmitType {
  NonStandart = "non-standart",
  Standard = "standard",
  SubmitNonStandard = "submit-non-standard",
  SubmitStandard = "submit-standard",
}

interface AccountFilterProps extends AppFilterProps<AccountsTabs> {
  readonly toDate?: string;
  readonly fromDate?: string;
  readonly from?: string;
  readonly to?: string;
  readonly accountNumber?: string;

  readonly excludeZeroBalance?: string;
  readonly includeClosedAccounts?: string;
  readonly balanceAsOfDate?: string;

  readonly amount?: string;
  readonly correspondentAccount?: string;
  readonly status?: string;
  readonly state?: string;

  readonly accountType?: ApprovalAccountsSubmitType;
  readonly tradeFinanceType?: ApprovalTradeFinanceSubmitType;
  readonly paymentClaimType?: ApprovalPaymentClaimSubmitType;

  readonly submitType?: InquirySubmitType;

  readonly newAccType?: NewAccountOpeningTabType;
  readonly swiftInnerType?: SwiftInnerTabType;

  readonly customerSequence?: string;
}

export class AccountsFilter extends AppFilter<AccountsTabs> {
  // Inquiry Tab
  private readonly submitType: InquirySubmitType;
  private readonly to: Date;
  private readonly from: Date;

  // Statement Tab
  private readonly toDate: Date;
  private readonly fromDate: Date;
  private readonly accountNumber?: string;
  private readonly amount?: string;
  private readonly correspondentAccount?: string;
  private readonly status?: string;
  private readonly state?: string;

  // Swifts Tab

  // Accounts Tab
  private readonly balanceAsOfDate: Date;
  private readonly excludeZeroBalance: boolean;
  private readonly includeClosedAccounts: boolean;
  private readonly accountType: ApprovalAccountsSubmitType;
  private readonly tradeFinanceType: ApprovalTradeFinanceSubmitType;
  private readonly paymentClaimType: ApprovalPaymentClaimSubmitType;

  // New Account Opening Tab
  private readonly newAccType: NewAccountOpeningTabType;

  readonly swiftInnerType: SwiftInnerTabType;
  readonly customerSequence: string;

  public constructor(
    {
      tab,

      // Accounts Tab
      excludeZeroBalance,
      includeClosedAccounts,
      balanceAsOfDate,
      accountType,
      tradeFinanceType,
      paymentClaimType,

      // Statement Tab
      toDate,
      fromDate,
      accountNumber,
      amount,
      correspondentAccount,
      status,
      state,
      customerSequence,
      // Inquiry Tab
      submitType,
      to,
      from,

      // New Account Opening Tab
      newAccType,

      // swift inner tabs
      swiftInnerType,

      ...props
    } = {} as AccountFilterProps,
  ) {
    super({ ...props, tab: tab || AccountsTabs.Accounts });

    // Inquiry Tab
    this.submitType = submitType || InquirySubmitType.NonStandart;
    this.to = new Date();
    this.from = new Date();

    // Accounts Tab
    this.balanceAsOfDate = new Date();
    this.excludeZeroBalance = excludeZeroBalance === "1";
    this.includeClosedAccounts = includeClosedAccounts === "1";

    this.accountType = accountType || ApprovalAccountsSubmitType.NoneStandInquiry;
    this.tradeFinanceType = tradeFinanceType || ApprovalTradeFinanceSubmitType.Application;
    this.paymentClaimType = paymentClaimType || ApprovalPaymentClaimSubmitType.ReceivedPaymentClaim;

    if (balanceAsOfDate) {
      this.balanceAsOfDate = formatDateFromUrl(balanceAsOfDate);
    }

    // Statement Tab
    this.toDate = new Date();
    this.fromDate = new Date();
    this.accountNumber = accountNumber;
    this.amount = "A";
    this.correspondentAccount = "A";
    this.status = "A";
    this.state = "A";
    // New account opening Tab
    this.newAccType = newAccType || NewAccountOpeningTabType.NewAccOpening;

    // swift inner tabs
    this.swiftInnerType = swiftInnerType || SwiftInnerTabType.Outgoing;

    if (toDate) {
      this.toDate = formatDateFromUrl(toDate);
    }

    if (fromDate) {
      this.fromDate = formatDateFromUrl(fromDate);
    }

    if (to) {
      this.to = formatDateFromUrl(to);
    }

    if (from) {
      this.from = formatDateFromUrl(from);
    }

    if (amount) {
      this.amount = amount;
    }

    if (amount === "") {
      this.amount = "A";
    }

    if (correspondentAccount) {
      this.correspondentAccount = correspondentAccount;
    }

    if (correspondentAccount === "") {
      this.correspondentAccount = "A";
    }

    if (status) {
      this.status = status;
    }

    if (state) {
      this.state = state;
    }

    if (customerSequence) {
      this.customerSequence = customerSequence;
    }
  }

  public getAccountListFilter(): AccountListQueryProps {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      balanceAsOfDate: formatDateToFilter(this.balanceAsOfDate),
      excludeZeroBalance: this.excludeZeroBalance ? "1" : undefined,
      includeClosedAccounts: this.includeClosedAccounts ? "1" : undefined,
    };
  }

  public getAccountFilterForm(): AccountFilterFormProps {
    return {
      balanceAsOfDate: this.balanceAsOfDate,
      excludeZeroBalance: this.excludeZeroBalance,
      includeClosedAccounts: this.includeClosedAccounts,
    };
  }

  public getStatementListFilter(): AccountStatementListQueryProps {
    return {
      page: this.page,
      accountNumber: this.accountNumber,
      toDate: formatDateToUrl(this.toDate),
      fromDate: formatDateToUrl(this.fromDate),
    };
  }

  public getStatementFilterForm(): AccountStatementFilterFormProps {
    return {
      to: getDateDay(this.toDate),
      year: getDateYear(this.toDate),
      from: getDateDay(this.fromDate),
      month: getDateMonth(this.toDate),
      accountNumber: this.accountNumber
        ? { label: formatAccountNumber(this.accountNumber), value: this.accountNumber }
        : undefined,
    };
  }

  public getInquiryFilterForm(): AccountInquiryFilterFormProps {
    return {
      toDate: this.toDate,
      fromDate: this.fromDate,
    };
  }

  public getCoverFilterForm(): AccountCoverFilterFormProps {
    return {
      toDate: this.toDate,
      fromDate: this.fromDate,
      customerSequence: this.customerSequence,
    };
  }

  public getInquiryListFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
    };
  }

  public getSwiftOutgoingListFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      toDate: formatDateToBody(this.toDate),
      fromDate: formatDateToBody(this.fromDate),
    };
  }

  public getSwiftIncomingListFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
    };
  }

  public getSwiftIncomingCoverFilter() {
    return {
      customerSequence: this.customerSequence,
      pageNumber: this.page,
      pageSize: this.perPage,
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
    };
  }

  public getAccountStatementsFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPageStatement,
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
      amount: this.amount,
      correspondentAccount: this.correspondentAccount,
      status: this.status,
      accountNumber: this.accountNumber,
    };
  }

  public getAccountCardBoxFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }

  public getCollectionOrderFilter() {
    return {
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }

  public getPaymentClaimFormFilter() {
    return {
      toDate: this.toDate,
      fromDate: this.fromDate,
      state: this.state,
    };
  }

  public getPaymentClaimSentFilter() {
    return {
      toDate: formatDateToFilter(this.toDate),
      fromDate: formatDateToFilter(this.fromDate),
      state: this.state,
      pageNumber: this.page,
      pageSize: this.perPage,
    };
  }

  public getSubmitType(): InquirySubmitType {
    return this.submitType;
  }

  public getAccountType(): ApprovalAccountsSubmitType {
    return this.accountType;
  }

  public getTradeFinanceType(): ApprovalTradeFinanceSubmitType {
    return this.tradeFinanceType;
  }

  public getPaymentCLaimType(): ApprovalPaymentClaimSubmitType {
    return this.paymentClaimType;
  }

  public getNewAccOpeningType(): NewAccountOpeningTabType {
    return this.newAccType;
  }

  public getSwiftInnerType(): SwiftInnerTabType {
    return this.swiftInnerType;
  }

  public getSwiftCoverCustomerSequence(): string {
    return this.customerSequence;
  }
}
