import React from "react";
import "./assets/lcy-print-table-forms.scss";
import { Input } from "../ui/Input";
import { TextArea } from "../ui/TextArea";
import { useI18n } from "../../i18n/I18nContext";
import { PrintTableFormContainer } from "../print/PrintTableFormContainer";

export function TransferLcyRevenueTablePrint() {
  const { translate } = useI18n();
  return (
    <PrintTableFormContainer id="TransferLcyRevenueTablePrint">
      <div className="page-break">
        <div className="row">
          <div className="col">
            <div className="row" id="header">
              <label id="header-label">{translate("TRANSFERS_LCY_PRINT_HEADER_TITTLE")}</label>
              <Input width={200} />
            </div>
            <div className="form-group row mb-1">
              <label className="col-sm-2 col-form-label pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_DATA_LABEL")}
              </label>
              <div className="input-label-large">
                <Input width={800} />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_SENDER_NAME_LABEL")}
              </label>
              <div className="input-label-large">
                <Input width={800} />
              </div>
            </div>
            <div className="mb-0">
              <h1>{translate("TRANSFERS_LCY_PRINT_DEBIT_LABEL")}</h1>
            </div>
            <div className="d-flex mb-0">
              <div className="form-group row">
                <label className="col-sm-2 col-form-label pl-5 " id="span-right">
                  {translate("TRANSFERS_LCY_PRINT_CURRENT_BALANCE_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label" id="input-middle">
                  <Input name="currentBalancePrint" className="h-100" width={300} />
                </div>
              </div>
              <div className="form-group row mr-4 mb-0">
                <label className="col-sm-2 col-form-label ml-5" id="span-left">
                  {translate("TRANSFERS_LCY_PRINT_SENDER_TAX_ID_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label mr-11" id="input-middle-right">
                  <Input name="senderTaxIdPrint" className="h-75" width={300} />
                </div>
              </div>
            </div>
            <div className="d-flex mb-0">
              <div className="form-group row ">
                <label className="col-sm-2 col-form-label ml-2" id="span-right">
                  {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_NAME_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label ml-11" id="input-middle">
                  <Input className="input-height" width={300} height={80} />
                </div>
              </div>
              <div className="form-group row mr-2">
                <label className="col-sm-2 col-form-label ml-5" id="span-left">
                  {translate("TRANSFERS_LCY_PRINT_SENDER_BANK_CODE_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label " id="input-middle-right">
                  <Input className="h-100" width={300} height={80} />
                </div>
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-sm-2 col-form-label ml-2 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_LABEL")}
              </label>
              <div className="input-label-large">
                <Input width={800} height={40} />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label ml-2 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_NAME_LABEL")}
              </label>
              <div className="input-label-large">
                <Input width={800} height={40} className="mt-4" />
              </div>
            </div>
            <div className="ml-3 mb-0 mt-5">
              <h1>{translate("TRANSFERS_LCY_PRINT_CREDIT_LABEL")}</h1>
            </div>
            <div className="d-flex flex-grow-1 mb-0">
              <div className="form-group row">
                <label className="col-sm-2 ml-3 col-form-label" id="span-right">
                  {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_ACCOUNT_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label ml-8" id="input-middle">
                  <Input width={300} className="mt-2" />
                </div>
              </div>
              <div className="form-group row mr-2 ">
                <label className="col-sm-2 col-form-label pl-5" id="span-left">
                  {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_TAX_NUMBER_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label ml-10" id="input-middle-right">
                  <Input width={300} className="mt-2" />
                </div>
              </div>
            </div>
            <div className="d-flex mb-0">
              <div className="form-group row ">
                <label className="col-sm-2 col-form-label ml-3" id="span-right">
                  {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_NAME_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label ml-11" id="input-middle">
                  <Input width={300} height={100} />
                </div>
              </div>
              <div className="form-group row mr-2">
                <label className="col-sm-2 col-form-label mr-10 " id="span-left">
                  {translate("TRANSFERS_LCY_PRINT_CORRESPONDENT_BANK_LABEL")}
                </label>
                <div className="col-sm-7 col-form-label ml-3" id="input-middle-right">
                  <Input width={300} height={100} />
                </div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_ACCOUNT_STATE_LABEL")}
              </label>
              <div className="input-label-large">
                <Input className="mt-5" width={800} />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label ml-3 pr-8 " id="span">
                {translate("TRANSFERS_LCY_REVENUE_PRINT_CLIENT_ACCOUNT_LABEL")}
              </label>
              <div className="input-label-large">
                <Input className="mt-6" width={800} height={60} />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                <span>
                  {translate("TRANSFERS_TRANSFERS_MINUS_TRANSFER_FORM_PAYMENT_CODE_TITLE")}
                </span>
              </label>
              <div className="input-label-large1 col">
                {/* <Input width={300} height={40} /> */}
                <Input width={150} />
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
              </label>
              <div className="input-label-large1 col">
                <TextArea width={800} inputClassName="paddingTop" />
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_AMOUNT_DETAILS_LABEL")}
              </label>
              <div className="input-label-large from-group row flex-xl-nowrap">
                <Input width={150} className="mt-5" />
                // <Input width={650} className="mt-5" /> 
                <TextArea width={900} inputClassName="border-0" />
              </div>
            </div> */}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                {translate("TRANSFERS_LCY_PRINT_STATE_LABEL")}
              </label>
              <div className="input-label-large from-group">
                <Input className="mt-4" width={800} height={60} />
              </div>
            </div>

            {/* start */}
            {/* <div className="form-group row">
              <label className="col-sm-2 col-form-label ml-3 pr-8" id="span">
                AuthorizerOtpKey
              </label>
              <div className="input-label-large from-group">
                <Input className="mt-4" width={800} height={60} />
              </div>
            </div> */}
            {/* end */}

            <div className="form-group row text-center mt-3 ml-9">
              <label id="span-manager">{translate("TRANSFERS_LCY_PRINT_MANAGER_LABEL")}</label>
              <label id="span-left-init">{translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}</label>
              <input className="border-right-0 border-left-0 border-top-0" id="input-init-top" />
            </div>
            <div className="form-group row text-center ml-11">
              <label id="span-general">
                {translate("TRANSFERS_LCY_PRINT_ACCOUNTANT_GENERAL_LABEL")}
              </label>
              <label id="span-left-init">{translate("TRANSFERS_LCY_PRINT_INITIALS_LABEL")}</label>
              <input className="border-right-0 border-left-0 border-top-0 " id="input-init" />
            </div>
            <div className="row w-75 ml-11 mt-7 ml-10">
              <div className="form-group row">
                <div className=" ml-5 pl-11 row text-center ">
                  <Input
                    name="провернео"
                    placeholder="Проверено"
                    className="w-25 mh-auto mr-8 pb-5 text-center"
                  />
                  <Input
                    name="просоа"
                    placeholder="Одобрено"
                    className="w-25 mh-100 mr-8 pb-5 text-center"
                  />
                  <Input
                    name="sdigsio"
                    placeholder="Проведено"
                    className="w-25 mh-100 pb-5 text-center"
                  />
                </div>
                <div className="row">
                  <label className="col-sm-2 col-form-label position-absolute top-3" id="span1">
                    {translate("TRANSFERS_LCY_PRINT_BANK_LABEL")}
                  </label>
                  <div className=" ml-5 pl-11 row text-center ">
                    <input
                      className="w-25 mh-auto mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                      id="lines-end"
                    />
                    <input
                      className="w-25 mh-100 mr-8 pb-5 text-center border-right-0 border-left-0 border-top-0"
                      id="lines-end"
                    />
                    <input
                      className="w-25 mh-100 pb-5 text-center border-right-0 border-left-0 border-top-0"
                      id="lines-end"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrintTableFormContainer>
  );
}
