import React, { ReactNode } from "react";
import { I18nCode } from "../../i18n/I18nSchema";

export interface TabPaneProps {
  readonly key: string;
  readonly show?: boolean;
  readonly badge?: ReactNode;
  readonly showTab?: boolean;
  readonly children: ReactNode;
  readonly tab: I18nCode | string;
  readonly scrollableContent?: boolean;
}

export function TabPane({ children, show = true }: TabPaneProps) {
  if (!show) {
    return null;
  }

  return <div>{children}</div>;
}
