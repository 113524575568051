import React, { useCallback, useState, useMemo } from "react";
import { TransfersTable } from "./TransfersTable";
import { useResource } from "../../hooks/useResource";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { PendingApprovalsFilter } from "../../filters/PendingApprovalsFilter";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { Form, Formik } from "formik";
import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { noop } from "lodash";
import { useI18n } from "../../i18n/I18nContext";
import { numberToWords } from "../../utils/FormatUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector } from "../../reducers/appReducer";
import { PaymentCodeBudgetFlag } from "../../api/reference-book/ReferenceBookDTO";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";
// import { GetTransferListForApprovalProps } from "../../api/transfer/TransferDTO";

interface Props {
  readonly filter: PendingApprovalsFilter;
  readonly setDocumentsId: (value, appType) => void;
  readonly withCreatorsAndApprovals?: boolean;
  // readonly data: GetTransferListForApprovalProps[];
  readonly setList: (value) => void;
}

export function TransfersLCYTableWrapper({
  filter,
  setDocumentsId,
  withCreatorsAndApprovals,
  setList,
}: // data,
Props) {
  const { TransferApi } = useTransferContext();
  const dispatch = useDispatch();
  const { translate } = useI18n();
  const [transferStandardView, setTransferStandardView] = useState(false);
  const [transferBudgetView, setTransferBudgetView] = useState(false);
  const [transferRevenueView, setTransferRevenueView] = useState(false);
  const [transfer, setTransfer] = useState({});
  const language = useShallowEqualSelector(appLanguageSelector);
  const getTransferListForApproval = useResource(
    () =>
      TransferApi.getTransferListForApproval({
        pageNumber: filter.getPage(),
        pageSize: filter.getPerPage(),
      }),
    [],
  );

  useResourceHandler(getTransferListForApproval, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
      setList(data);
    },
  });

  const data = useMemo(() => getTransferListForApproval.data || [], [
    getTransferListForApproval.data,
  ]);

  const setDocumentId = useCallback(
    (value) => {
      const list = data.filter((_, idx) => value[idx]);
      setDocumentsId(list, ApprovalTypes.LCYTransfer);
    },
    [data, setDocumentsId],
  );

  const chooseTransferForView = useCallback(
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      const amountDetails = numberToWords(removeGaps(value.amount), {
        language: languageApp,
        currencyCode: CurrencyCode.UzbekistanSum,
      });
      setTransfer({
        ...value,
        amountDetails:
          amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
        amount: floatFormat(addZeros(value.amount)),
      });
      if (value.budgetFlag === PaymentCodeBudgetFlag.StandartDoc) {
        setTransferStandardView(true);
      } else if (value.budgetFlag === PaymentCodeBudgetFlag.BudgetDoc) {
        setTransferBudgetView(true);
      } else if (value.budgetFlag === PaymentCodeBudgetFlag.BudgetRevenue) {
        setTransferRevenueView(true);
      }
    },
    [language],
  );

  return (
    <>
      <TransfersTable
        setDocumentId={setDocumentId}
        data={data}
        withCreatorsAndApprovals={withCreatorsAndApprovals}
        chooseTransferForView={chooseTransferForView}
        loading={getTransferListForApproval.loading}
      />
      <Modal
        onClose={() => setTransferStandardView(false)}
        width={1200}
        show={transferStandardView}
        title={"PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE"}
      >
        <TabPage>
          <Formik initialValues={transfer} onSubmit={noop}>
            {() => (
              <Form className="d-flex flex-column p-lg-4">
                <GroupBox contentClassName="row" className="mb-7">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                          name="documentNumber"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                          name="documentDate"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </GroupBox>
                <div className="row mb-7">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE"
                    >
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccountBalance"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 w-100 mb-2">
                        <InputField
                          name="clientName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 my-2">
                        <InputField
                          name="clientBankName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="senderMfo"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientTaxNumber"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE"
                        />
                      </div>
                    </GroupBox>
                  </div>

                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE"
                    >
                      <div className="d-flex flex-column col-12">
                        <label>
                          {translate(
                            "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
                          )}
                        </label>
                        <div className="input-group">
                          <InputField className="flex-1" name="correspondentName" disabled={true} />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-12"
                            name="correspondentAccount"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-lg-6"
                            name="receiverMfo"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                          <InputField
                            className="col-lg-6"
                            name="correspondentTaxNumber"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                        <div className="row mb-2">
                          <InputField
                            className="col-12"
                            name="correspondentBankName"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <InputField
                          name="amount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12">
                        <InputField
                          name="amountDetails"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <div className="d-flex flex-column">
                          <label>
                            {translate(
                              "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE",
                            )}
                          </label>
                          <div className="input-group">
                            <InputField className="flex-1" name="paymentCode" disabled={true} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <InputField
                          name="paymentDetail"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setTransferBudgetView(false)}
        width={1200}
        show={transferBudgetView}
        title={"PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE"}
      >
        <TabPage>
          <Formik initialValues={transfer} onSubmit={noop}>
            {() => (
              <Form onSubmit={noop} className="d-flex flex-column p-4">
                <GroupBox contentClassName="row" className="mb-7">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                          name="documentNumber"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                          name="documentDate"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </GroupBox>
                <div className="row mb-7">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE"
                    >
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccountBalance"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 w-100 mb-2">
                        <InputField
                          name="clientName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 my-2">
                        <InputField
                          name="clientBankName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="senderMfo"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientTaxNumber"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE"
                        />
                      </div>
                    </GroupBox>
                  </div>

                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE"
                    >
                      <div className="d-flex flex-column col-12">
                        <label>
                          {translate(
                            "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
                          )}
                        </label>
                        <div className="input-group">
                          <InputField disabled={true} className="flex-1" name="correspondentName" />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-12"
                            disabled={true}
                            name="correspondentAccount"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="row mb-2">
                          <InputField
                            className="col-12"
                            disabled={true}
                            name="correspondentBankName"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="receiverMfo"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE"
                          />
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="correspondentTaxNumber"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <label>
                            {translate(
                              "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE",
                            )}
                          </label>
                          <div className="input-group">
                            <InputField className="flex-1" name="budgetAccount" disabled={true} />
                          </div>
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="budgetName"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE"
                          />
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="budgetTaxNumber"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE"
                          />
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <InputField
                          name="amount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12">
                        <InputField
                          name="amountDetails"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <div className="d-flex flex-column">
                          <label>
                            {translate(
                              "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE",
                            )}
                          </label>
                          <div className="input-group">
                            <InputField className="flex-1" name="paymentCode" disabled={true} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <InputField
                          name="paymentDetail"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
      <Modal
        onClose={() => setTransferRevenueView(false)}
        width={1200}
        show={transferRevenueView}
        title={"PENDING_APPROVALS_TRANSFERS_TAB_LCY_TITLE"}
      >
        <TabPage>
          <Formik initialValues={transfer} onSubmit={noop}>
            {() => (
              <Form onSubmit={noop} className="d-flex flex-column p-4">
                <GroupBox contentClassName="row" className="mb-7">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                          name="documentNumber"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                          name="documentDate"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </GroupBox>
                <div className="row mb-7">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE"
                    >
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientAccountBalance"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 w-100 mb-2">
                        <InputField
                          name="clientName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-12 my-2">
                        <InputField
                          name="clientBankName"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="senderMfo"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputField
                          name="clientTaxNumber"
                          disabled={true}
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE"
                        />
                      </div>
                    </GroupBox>
                  </div>

                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE"
                    >
                      <div className="d-flex flex-column col-12">
                        <label>
                          {translate(
                            "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
                          )}
                        </label>
                        <div className="input-group">
                          <InputField disabled={true} className="flex-1" name="correspondentName" />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-12"
                            disabled={true}
                            name="correspondentAccount"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="row mb-2">
                          <InputField
                            className="col-12"
                            disabled={true}
                            name="correspondentBankName"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="row my-2">
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="receiverMfo"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE"
                          />
                          <InputField
                            className="col-lg-6"
                            disabled={true}
                            name="correspondentTaxNumber"
                            label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE"
                          />
                        </div>
                        <div className="row my-2">
                          <div className="col-lg-6 mb-2">
                            <div className="d-flex flex-column">
                              <label>
                                {translate(
                                  "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_25_DIGITS_FIELD_LABEL_TITLE",
                                )}
                              </label>
                              <div className="input-group">
                                <InputField
                                  className="flex-1"
                                  name="budgetAccount"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <InputField
                              name="budgetName"
                              label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </GroupBox>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <InputField
                          name="amount"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12">
                        <InputField
                          name="amountDetails"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                  <div className="col-lg-6">
                    <GroupBox
                      contentClassName="row"
                      className="h-100"
                      title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE"
                    >
                      <div className="col-lg-6 mb-2">
                        <div className="d-flex flex-column">
                          <label>
                            {translate(
                              "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE",
                            )}
                          </label>
                          <div className="input-group">
                            <InputField className="flex-1" name="paymentCode" disabled={true} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <InputField
                          name="paymentDetail"
                          label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE"
                          disabled={true}
                        />
                      </div>
                    </GroupBox>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPage>
      </Modal>
    </>
  );
}
