import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function OfficeIcon({ size = 18, color = ColorPalette.Blue, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      id="office"
      width={size}
      height={size}
      viewBox="0 0 16.55 16.551"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(-1.242 -4.19)"
        d="M6.241,14.534H4.517a.517.517,0,1,1,0-1.034H6.241a.517.517,0,1,1,0,1.034Z"
      />
      <path
        fill={color}
        transform="translate(-1.242 -5.121)"
        d="M6.945,17.534H4.517a.517.517,0,1,1,0-1.034H6.945a.517.517,0,1,1,0,1.034Z"
      />
      <path
        fill={color}
        d="M5.71,14.482H1.9a1.9,1.9,0,0,1-1.9-1.9V1.9A1.9,1.9,0,0,1,1.9,0H10a1.9,1.9,0,0,1,1.9,1.9V7.082a.517.517,0,1,1-1.034,0V1.9A.863.863,0,0,0,10,1.034H1.9a.863.863,0,0,0-.862.862V12.586a.863.863,0,0,0,.862.862H5.71a.517.517,0,0,1,0,1.034Z"
      />
      <path
        fill={color}
        transform="translate(-3.135 -4.108)"
        d="M16.054,13.417a.628.628,0,0,0,0,.888l.223.223a.1.1,0,1,1-.148.148l-.519-.519a.626.626,0,1,0-.885.885l.519.519a.1.1,0,1,1-.148.148l-.817-.817a.626.626,0,1,0-.885.885l.817.817a.1.1,0,1,1-.148.148l-2.894-2.894a.626.626,0,1,0-.885.885l3.483,3.483a2,2,0,0,0-1.7.37c-.443.443-.431.749-.294.887.374.374.663-.217,2.369.6s2.8.748,3.552.011l1.033-1.033h0c1.05-1.05,1.351-2.486.291-3.545l-.006-.006L16.94,13.42a.627.627,0,0,0-.887,0Z"
      />
      <circle cx="1.379" cy="1.379" r="1.379" fill={color} transform="translate(4.483 2.069)" />
      <path
        fill={color}
        transform="translate(-1.397 -2.483)"
        d="M9.5,10.758H5.017a.517.517,0,0,1-.517-.517V9.9A1.9,1.9,0,0,1,6.4,8H8.121a1.9,1.9,0,0,1,1.9,1.9v.345A.517.517,0,0,1,9.5,10.758Z"
      />
    </svg>
  );
}
