import { useEffect, useMemo } from "react";

import { Resource } from "./useResource";
import { AppError } from "../helpers/AppError";
import { showError as showNotification } from "../utils/NotificationUtils";
import { useI18n } from "../i18n/I18nContext";

interface Options<TData> {
  readonly showError?: boolean;
  readonly onSuccess?: (data: TData) => void;
  readonly onFail?: (error: AppError) => void;
}

export function useResourceHandler<TData>(
  resource: Resource<TData>,
  options: Options<TData> = {},
): void {
  const { onFail, onSuccess, showError = true } = options;
  const { translate } = useI18n();

  const error = useMemo(() => {
    if (!resource.loading && resource.error) {
      return resource.error;
    }
  }, [resource.loading, resource.error]);

  const data = useMemo(() => {
    if (!resource.loading && !resource.error && resource.data) {
      return resource.data;
    }
  }, [resource]);

  useEffect(() => {
    if (error && onFail) {
      onFail(error);
    }
  }, [error, onFail]);

  useEffect(() => {
    if (!resource.loading && data && onSuccess) {
      onSuccess(data);
    }
  }, [data, resource.loading, onSuccess]);

  useEffect(() => {
    if (!resource.loading && error && showError) {
      if (error.data[0].errorCode === 3008) {
        error.data[0].userMsg = translate("SERVER_ERROR_TITLE");
      }
      showNotification(error);
    }
  }, [error, showError, resource.loading, translate]);
}
