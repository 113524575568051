import { useEffect } from "react";
import { useEventCallback } from "./useEventCallback.js";
import { usePureDeps } from "./usePureDeps.js";

export function usePromiseEffect(factory, deps, _ref) {
  var { key, skip = false, onPerform, onFulfill, onReject } = _ref;
  var pureDeps = usePureDeps(deps);
  var pureFactory = useEventCallback(factory);
  var handlePerform = useEventCallback(onPerform);
  var handleFulfill = useEventCallback(onFulfill);
  var handleReject = useEventCallback(onReject);
  useEffect(() => {
    handlePerform();

    if (skip) {
      return;
    }

    var current = true;
    var abortController = typeof AbortController == "undefined" ? null : new AbortController();
    Promise.resolve(
      pureFactory({
        abortSignal: abortController == null ? void 0 : abortController.signal,
      }),
    ).then(
      (payload) => {
        if (current) {
          handleFulfill(payload);
        }
      },
      (error) => {
        if (current) {
          handleReject(error);
        }
      },
    );
    return () => {
      current = false;
      abortController == null ? void 0 : abortController.abort();
    };
  }, [key, skip, pureDeps, pureFactory, handlePerform, handleFulfill, handleReject]);
}
