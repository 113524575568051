import React, { useCallback, useMemo, useState } from "react";
import { PaymentCodeBudgetFlag, PaymentType } from "../../api/reference-book/ReferenceBookDTO";
import { update } from "immupdate";
import { useResource } from "../../hooks/useResource";
import { useReferenceBookContext } from "../../api/reference-book/ReferenceBookContext";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { LcyTransferMunisFormProps } from "../../api/transfer/TransferDTO";
import { FormikProps } from "formik";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { PaymentCodeTable } from "./PaymentCodeTable";
import { formatDateToBody } from "../../utils/DateUtils";
import { MinusTransferForm } from "./MinusTransferForm";
import { numberToWords } from "../../utils/FormatUtils";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appLanguageSelector } from "../../reducers/appReducer";
import { NotificationManager } from "react-notifications";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly onSubmit: (values: LcyTransferMunisFormProps) => void;

  readonly formikRef?: (instance: FormikProps<LcyTransferMunisFormProps>) => void;

  readonly initialValue?: LcyTransferMunisFormProps;

  readonly setinitialValues: (value) => void;
}

export function LCYMunisTransferFormWrapper({
  onSubmit,
  formikRef,
  initialValue,
  setinitialValues,
}: Props) {
  const { translate } = useI18n();
  const { ReferenceBookApi } = useReferenceBookContext();
  const { TransferApi } = useTransferContext();

  const [paymentCode, setPaymentCode] = useState(false);

  const [payeeGroups, setPayeeGroups] = useState({});

  const [supplierCodes, setSupplierCodes] = useState({});

  const [settlementCodes, setSettlementCodes] = useState({});

  const [debitAccount, setDebitAccount] = useState({});

  const [searchPaymentCode, setSearchPaymentCode] = useState("");

  const paymentCodeListSearch = useCallback((data, term) => {
    if (term !== "") {
      return data && data.filter((item) => `${item.code} ${item.name}`.indexOf(term) > -1);
    } else return data;
  }, []);

  const onChangeDocumentDate = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          documentDate: value,
        }),
      );
    },
    [setinitialValues],
  );

  const language = useShallowEqualSelector(appLanguageSelector);
  const onChangeAmount = useCallback(
    (value) => {
      const languageApp =
        language === "ru"
          ? Language.Russian
          : language === "uz"
          ? Language.Uzbek
          : Language.English;
      if (value.length <= 18) {
        const amountDetails = numberToWords(removeGaps(value), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        });
        setinitialValues((prev) =>
          update(prev, {
            amount: floatFormat(value),
            amountDetails:
              amountDetails !== "" ? amountDetails[0].toUpperCase() + amountDetails.slice(1) : "",
          }),
        );
      }
    },
    [language, setinitialValues],
  );

  const onChangePaymentCode = useCallback(
    (value) => {
      if (value.length <= 5) {
        setinitialValues((prev) =>
          update(prev, {
            paymentCode: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const paymentCodes = useResource(
    () =>
      ReferenceBookApi.checkPaymentCode({
        paymentCode: searchPaymentCode ? searchPaymentCode : "",
        budgetFlag: PaymentCodeBudgetFlag.Munis,
      }),
    [ReferenceBookApi, searchPaymentCode],
  );

  const onChangePaymentDetails = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          detailsOfPayment: value.replace(/\s+/g, " "),
        }),
      );
    },
    [setinitialValues],
  );

  const onChangeInvoiceNumber = useCallback(
    (value) => {
      if (value.length <= 25) {
        setinitialValues((prev) =>
          update(prev, {
            invoiceNumber: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const paymentCodesList = useMemo(() => paymentCodes.data || [], [paymentCodes.data]);

  const submitHandler = useCallback(
    (values) => {
      if (values.correspondentBankName === "Not Found") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK"),
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE"),
          7000,
        );
      }
      //  else if (typeof values.amount !== "number") {
      //   NotificationManager.error(
      //     translate("TRANSFER_AMOUNT_ERROR"),
      //     translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
      //     7000,
      //   );
      // }
      else if (values.amount === "0.00") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_AMOUNT"),
          translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
          7000,
        );
      } else if (values.clientAccount?.label === undefined) {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT"),
          translate("TRANSFER_ERRORS_DEBIT_ACCOUNT_TITLE"),
          7000,
        );
      } else {
        const data = {
          ...values,
          documentDate: formatDateToBody(values.documentDate),
          amount: removeGaps(values.amount),
          clientAccount: values.clientAccount.label,
          correspondentBankName: values.correspondentBankName.substr(0, 70),
          currentBalance: removeGaps(values.currentBalance),
          payeeGroup: values.payeeGroup.value,
          settlementCode: values.settlementCode.value,
          supplierCode: values.supplierCode.value,
          paymentType: PaymentType.Munis,
        };
        onSubmit(data);
      }
    },
    [translate, onSubmit],
  );

  const onPaymentCodeSelect = useCallback(
    (value) => {
      setPaymentCode(false);
      const selected = paymentCodesList.filter((pCs) => pCs.code === value);
      setinitialValues((prev) =>
        update(prev, {
          paymentCode: selected[0].code,
        }),
      );
    },
    [paymentCodesList, setinitialValues],
  );

  useResource(
    () =>
      TransferApi.getPayeeGroup().then((r) => {
        setPayeeGroups([
          r.map((res) => ({
            label: res.groupName,
            value: res.groupId,
          })),
        ]);
      }),
    [TransferApi],
  );

  const getSupplierCode = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          payeeGroup: value,
          supplierCode: { label: "All", value: "" },
          settlementCode: { label: "All", value: "" },
        }),
      );
      TransferApi.getSupplierCode({ groupId: value.value }).then((r) => {
        setSupplierCodes([
          r.map((res) => ({
            label: res.supplierName,
            value: res.supplierCode,
          })),
        ]);
      });
    },
    [setinitialValues, TransferApi],
  );

  const getSettlementCode = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          settlementCode: { label: "All", value: "" },
        }),
      );
      TransferApi.getSettlementCode({ supplierCode: value.value }).then((r) => {
        setSettlementCodes([
          r.map((res) => ({
            label: res.settlementName,
            value: res.settlementCode,
          })),
        ]);
        setinitialValues((prev) =>
          update(prev, {
            supplierCode: value,
          }),
        );
      });
    },
    [TransferApi, setinitialValues],
  );

  const setSettlementCode = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          settlementCode: value,
        }),
      );
    },
    [setinitialValues],
  );

  useResource(
    () =>
      ReferenceBookApi.getAllowedAccounts().then((r) => {
        setDebitAccount([
          r.map((res) => ({
            label: res.accountCode,
            value: res.accountCode,
            clientCode: res.accountCode,
            balance: res.balance,
            senderBankCode: res?.accountBranch,
            senderName: res?.accountName,
            accountState: res?.accountState,
            senderBankName: res?.bankName,
            senderTaxId: res?.taxNumber,
          })),
        ]);
      }),
    [TransferApi],
  );

  const onChangeDebitsAccount = useCallback(
    (value) => {
      setinitialValues((prev) =>
        update(prev, {
          senderName: value.senderName,
          currentBalance: floatFormat(addZeros(value.balance)),
          senderBankName: value.senderBankName,
          senderBankCode: value.senderBankCode,
          senderTaxId: value.senderTaxId,
          clientAccount: value,
          accountState: value.accountState,
        }),
      );
    },
    [setinitialValues],
  );

  const onChangeTransferNumber = useCallback(
    (value) => {
      if (value.length <= 10) {
        setinitialValues((prev) =>
          update(prev, {
            documentNumber: value,
          }),
        );
      }
    },
    [setinitialValues],
  );

  const GenerateCorrespondent = useCallback(() => {
    TransferApi.getReceiverInformation({
      // @ts-ignore
      debitAccount: initialValue?.clientAccount.label,
      // @ts-ignore
      payeeGroup: initialValue?.payeeGroup.value,
      invoiceNumber: initialValue?.invoiceNumber,
      // @ts-ignore
      settlementCode: initialValue?.settlementCode.value,
      // @ts-ignore
      supplierCode: initialValue?.supplierCode.value,
    }).then((r) => {
      setinitialValues((prev) =>
        update(prev, {
          correspondentName: r[0]?.name,
          correspondentAccount: r[0]?.account,
          correspondentBank: r[0]?.branch,
          correspondentTaxNumber: r[0]?.taxNumber,
          amountForPayment: r[0]?.amount,
        }),
      );
    });
  }, [TransferApi, initialValue, setinitialValues]);

  const paymentCodeListAfterSearch = useMemo(
    () => paymentCodeListSearch(paymentCodesList, searchPaymentCode),
    [paymentCodeListSearch, paymentCodesList, searchPaymentCode],
  );
  return (
    <>
      <MinusTransferForm
        onSubmit={submitHandler}
        formikRef={formikRef}
        initialValues={initialValue}
        getPaymentCode={() => setPaymentCode(true)}
        onChangeDocumentDate={onChangeDocumentDate}
        onChangePaymentCode={onChangePaymentCode}
        onChangeAmount={onChangeAmount}
        onChangePaymentDetails={onChangePaymentDetails}
        payeeGroupList={payeeGroups[0]}
        getSupplierCode={getSupplierCode}
        supplierCodes={supplierCodes[0]}
        getSettlementCode={getSettlementCode}
        settlementCodes={settlementCodes[0]}
        setSettlementCode={setSettlementCode}
        debitAccount={debitAccount[0]}
        onChangeDebitsAccount={onChangeDebitsAccount}
        GenerateCorrespondent={GenerateCorrespondent}
        onChangeInvoiceNumber={onChangeInvoiceNumber}
        onChangeTransferNumber={onChangeTransferNumber}
      />
      <Modal
        setSearch={(value) => setSearchPaymentCode(value)}
        searchInput={true}
        onClose={() => setPaymentCode(false)}
        width={700}
        show={paymentCode}
        title={"TRANSFERS_MODAL_PAYMENT_CODE_TITLE"}
      >
        <TabPage>
          <PaymentCodeTable
            onPaymentCodeSelect={onPaymentCodeSelect}
            data={paymentCodeListAfterSearch}
            loading={paymentCodes.loading}
          />
        </TabPage>
      </Modal>
    </>
  );
}
