import React from "react";

import { appLanguageSelector } from "../../reducers/appReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { TransferFormDirectionsTabItems } from "./TransferFormDirectionsTabItems";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { Base64ToExcel } from "../../utils/Base64ToExcelUtils";
import { useI18n } from "../../i18n/I18nContext";

export function TransferFormDirectionsTab() {
  const language = useShallowEqualSelector(appLanguageSelector);

  const { translate } = useI18n();

  const { TransferApi } = useTransferContext();

  return (
    <div
      className="bg-white border border-gray-light br-1 mx-5 mt-7 pl-lg-10 px-5 pr-lg-10 pt-lg-5 pb-5"
      data-testid="wrapper"
    >
      <div className="fs-5">
        <div className="d-flex flex-lg-wrap flex-lg-row flex-column">
          <TransferFormDirectionsTabItems
            onClick={() => {
              TransferApi.getCorporateCardPayrollExcel(language).then((response) =>
                Base64ToExcel(
                  response,
                  translate("TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_TITLE"),
                ),
              );
            }}
            href="#"
            title={translate("TRANSFERS_SAMPLES_DOWNLOAD_CORPORATE_CARD_PAYROLL_TITLE")}
            fileSize="133.6"
            className="col-lg-3 col-md-6 col-12"
          />
          <TransferFormDirectionsTabItems
            onClick={() => {
              TransferApi.getSalaryCardPayrollExcel(language).then((response) =>
                Base64ToExcel(
                  response,
                  translate("TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_TITLE"),
                ),
              );
            }}
            href="#"
            title={translate("TRANSFERS_SAMPLES_DOWNLOAD_SALARY_CARD_PAYROLL_TITLE")}
            fileSize="133.6"
            className="col-lg-3 col-md-6 col-12"
          />
          <TransferFormDirectionsTabItems
            onClick={() => {
              TransferApi.getLCYTransferExcel(language).then((response) =>
                Base64ToExcel(response, translate("TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_TITLE")),
              );
            }}
            href="#"
            title={translate("TRANSFERS_SAMPLES_DOWNLOAD_LCY_TRANSFER_TITLE")}
            fileSize="79.4"
            className="col-lg-3 col-md-6 col-12"
          />
          <TransferFormDirectionsTabItems
            onClick={() => {
              TransferApi.getFCYTransferExcel(language).then((response) =>
                Base64ToExcel(response, translate("TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_TITLE")),
              );
            }}
            href="#"
            title={translate("TRANSFERS_SAMPLES_DOWNLOAD_FCY_TRANSFER_TITLE")}
            fileSize="100.4"
            className="col-lg-3 col-md-6 col-12"
          />

          {language === "en" ? (
            <TransferFormDirectionsTabItems
              href="/templates/R_EN_signatures_cards_for_residents.docx"
              title="Signatures cards for residents"
              fileSize="49.3"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : language === "ru" ? (
            <TransferFormDirectionsTabItems
              href="/templates/R_RU_Карточка_образцов_подписей_для_резидентов.docx"
              title="Карточка образцов подписей для резидентов"
              fileSize="49.3"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : (
            <TransferFormDirectionsTabItems
              href="/templates/R_UZ_Imzolar_namunalari_(rezidentlar uchun).docx"
              title="Imzolar namunalari (rezidentlar uchun)"
              fileSize="48.3"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          )}

          {language === "en" ? (
            <TransferFormDirectionsTabItems
              href="/templates/NOR_EN_signatures_cards_for_non-residents.doc"
              title="Signatures cards for non-residents"
              fileSize="66.5"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : language === "ru" ? (
            <TransferFormDirectionsTabItems
              href="/templates/NOR_RU_Карточка_образцов_подписей_для_нерезидентов.doc"
              title="Карточка образцов подписей для нерезидентов"
              fileSize="66.5"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : (
            <TransferFormDirectionsTabItems
              href="/templates/NOR_UZ_Imzolar_namunalari_(norezidentlar_uchun).doc"
              title="Imzolar namunalari (norezidentlar uchun)"
              fileSize="63.0"
              iconName="doc"
              className="col-lg-3 col-md-6 col-12"
            />
          )}

          {language === "en" ? (
            <TransferFormDirectionsTabItems
              href="/templates/A_ENApplication_reissuing_card.xlsx"
              title="Application reissuing card"
              fileSize="113"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : language === "ru" ? (
            <TransferFormDirectionsTabItems
              href="/templates/A_RU_Заявление_на_перевыпуск_пластик_карты.xlsx"
              title="Заявление на перевыпуск пластик карты"
              fileSize="113"
              className="col-lg-3 col-md-6 col-12"
            />
          ) : (
            <TransferFormDirectionsTabItems
              href="/templates/A_UZ_Plastic_kartani_qayta_chiqarish_arizasi.xlsx"
              title="Plastic kartani qayta chiqarish arizasi"
              fileSize="113"
              className="col-lg-3 col-md-6 col-12"
            />
          )}
        </div>
      </div>
    </div>
  );
}
