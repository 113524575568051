import React from "react";
import { GroupBox } from "../ui/GroupBox";
import { InputField } from "../form/InputField";
import { SizeType } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";
import { Form, Formik, FormikProps } from "formik";
import { Button, ButtonColor } from "../ui/Button";
import { SelectPickerField } from "../form/SelectPickerField";
import { LcyTransferBudgetFormProps } from "../../api/transfer/TransferDTO";
import { DatePickerField } from "../form/DatePickerField";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { number, object, string } from "yup";
import { PaymentType } from "../../api/reference-book/ReferenceBookDTO";

interface Props {
  readonly onSubmit: (values: LcyTransferBudgetFormProps) => void;

  readonly getPaymentCode: () => void;

  readonly getCorrespondentAccount: () => void;

  readonly onChangeDebitsAccount: (value) => void;

  readonly initialValues?: LcyTransferBudgetFormProps;

  readonly formikRef?: (instance: FormikProps<LcyTransferBudgetFormProps>) => void;

  readonly debits: [{ label: ""; value: "" }];

  readonly onChangeTransferNumber: (value) => void;
  readonly onChangeAmount: (value) => void;
  readonly onChangePaymentCode: (value) => void;
  readonly onChangePaymentDetails: (value) => void;
  readonly onChangeDocumentDate: (value) => void;
  readonly onChangeAccount: (value) => void;

  readonly onFindBudgetAccount: (value) => void;
}

const validationSchema = object({
  paymentCode: number().max(999999, "Payment code 5 digits").required("Required"),
  amount: number().max(99999999999999999, "Amount 18 digits").required("Required"),
  detailsOfPayment: string().required("Required"),
  currentBalance: string().required("Required"),
  documentNumber: number().max(9999999999, "Number 10 digits").required("Required"),
  budgetAccount: string().required("Required"),
});

export function LcyTransferBudgetForm({
  onSubmit,
  formikRef,
  initialValues,
  getPaymentCode,
  onChangeDebitsAccount,
  onChangeAmount,
  onChangePaymentCode,
  onChangeTransferNumber,
  onChangePaymentDetails,
  debits,
  onChangeDocumentDate,
  getCorrespondentAccount,
  onChangeAccount,
  onFindBudgetAccount,
}: Props) {
  const { translate } = useI18n();
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues as LcyTransferBudgetFormProps}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="d-flex flex-column p-4">
          <GroupBox contentClassName="row" className="mb-7">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-3">
                  <InputField
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSFER_NUMBER_FIELD_LABEL_TITLE"
                    name="documentNumber"
                    onChange={(value) => onChangeTransferNumber(value.target.value)}
                    autoComplete="off"
                    type="number"
                    inputClassName="amount"
                    value={initialValues?.documentNumber}
                  />
                </div>
                <div className="col-lg-3">
                  <DatePickerField
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_TRANSACTION_DATE_FIELD_LABEL_TITLE"
                    name="documentDate"
                    onChange={(value) => onChangeDocumentDate(value)}
                    autoComplete="off"
                    minDate={new Date()}
                  />
                </div>
                <div className="col-lg-6 mt-5">
                  <div className="d-flex">
                    <div className="d-flex justify-content-sm-center align-items-center infIcon cursor-pointer position-relative rounded-circle border border-blue-light info-icon mr-2">
                      <svg
                        x="0px"
                        className="w-100 h-100"
                        y="0px"
                        viewBox="0 0 111.577 111.577"
                        xmlSpace="preserve"
                      >
                        <path d="M78.962 99.536l-1.559 6.373c-4.677 1.846-8.413 3.251-11.195 4.217-2.785.969-6.021 1.451-9.708 1.451-5.662 0-10.066-1.387-13.207-4.142-3.141-2.766-4.712-6.271-4.712-10.523 0-1.646.114-3.339.351-5.064.239-1.727.619-3.672 1.139-5.846l5.845-20.688c.52-1.981.962-3.858 1.316-5.633.359-1.764.532-3.387.532-4.848 0-2.642-.547-4.49-1.636-5.529-1.089-1.036-3.167-1.562-6.252-1.562-1.511 0-3.064.242-4.647.71-1.59.47-2.949.924-4.09 1.346l1.563-6.378c3.829-1.559 7.489-2.894 10.99-4.002 3.501-1.111 6.809-1.667 9.938-1.667 5.623 0 9.962 1.359 13.009 4.077 3.047 2.72 4.57 6.246 4.57 10.591 0 .899-.1 2.483-.315 4.747-.21 2.269-.601 4.348-1.171 6.239l-5.82 20.605c-.477 1.655-.906 3.547-1.279 5.676-.385 2.115-.569 3.731-.569 4.815 0 2.736.61 4.604 1.833 5.597 1.232.993 3.354 1.487 6.368 1.487 1.415 0 3.025-.251 4.814-.744 1.784-.493 3.085-.926 3.892-1.305zm1.476-86.506c0 3.59-1.353 6.656-4.072 9.177-2.712 2.53-5.98 3.796-9.803 3.796-3.835 0-7.111-1.266-9.854-3.796-2.738-2.522-4.11-5.587-4.11-9.177 0-3.583 1.372-6.654 4.11-9.207C59.447 1.274 62.729 0 66.563 0c3.822 0 7.091 1.277 9.803 3.823 2.721 2.553 4.072 5.625 4.072 9.207z" />
                      </svg>
                    </div>
                    <span style={{ whiteSpace: "pre-line", color: "#016dbc" }}>
                      {`${translate("TRANSFER_SERVICING_TIME")} ${
                        initialValues?.paymentType === PaymentType.PaymentOrder
                          ? "9:00 - 16:45"
                          : "24/7"
                      }`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </GroupBox>
          <div className="row mb-7">
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                className="h-100"
                title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_LABEL_TITLE"
              >
                <div className="col-lg-6">
                  <SelectPickerField
                    onChanges={(value) => onChangeDebitsAccount(value)}
                    options={debits}
                    name="clientAccount"
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DEBIT_ACCOUNT_FIELD_LABEL_TITLE"
                  />
                </div>
                <div className="col-lg-6">
                  <InputField
                    name="currentBalance"
                    disabled={true}
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CURRENT_BALANCE_FIELD_LABEL_TITLE"
                  />
                </div>
                <div className="col-12 w-100 mb-2">
                  <InputField
                    name="senderName"
                    disabled={true}
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_NAME_FIELD_LABEL_TITLE"
                  />
                </div>
                <div className="col-12 my-2">
                  <InputField
                    name="senderBankName"
                    disabled={true}
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_NAME_FIELD_LABEL_TITLE"
                  />
                </div>
                <div className="col-lg-6">
                  <InputField
                    name="senderBankCode"
                    disabled={true}
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_BANK_CODE_FIELD_LABEL_TITLE"
                  />
                </div>
                <div className="col-lg-6">
                  <InputField
                    name="senderTaxId"
                    disabled={true}
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_SENDER_TAX_ID_FIELD_LABEL_TITLE"
                  />
                </div>
              </GroupBox>
            </div>

            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_LABEL_TITLE"
              >
                <div className="d-flex flex-column col-12">
                  <label>
                    {translate(
                      "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_NAME_FIELD_LABEL_TITLE",
                    )}
                  </label>
                  <div className="input-group">
                    <InputField disabled={true} className="flex-1" name="correspondentName" />
                  </div>
                  <div className="row my-2">
                    <InputField
                      className="col-12"
                      disabled={true}
                      name="correspondentAccount"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_CREDIT_ACCOUNT_FIELD_LABEL_TITLE"
                    />
                  </div>
                  <div className="row mb-2">
                    <InputField
                      className="col-12"
                      disabled={true}
                      name="correspondentBankName"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_NAME_FIELD_LABEL_TITLE"
                    />
                  </div>
                  <div className="row my-2">
                    <InputField
                      className="col-lg-6"
                      disabled={true}
                      name="correspondentBank"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_BANK_CODE_FIELD_LABEL_TITLE"
                    />
                    <InputField
                      className="col-lg-6"
                      disabled={true}
                      name="correspondentTaxNumber"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_TAX_ID_FIELD_LABEL_TITLE"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label>
                      {translate(
                        "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_ACCOUNT_27_DIGITS_FIELD_LABEL_TITLE",
                      )}
                    </label>
                    <div className="input-group">
                      <InputField
                        className="flex-1"
                        name="budgetAccount"
                        type="number"
                        onChange={(value) => onChangeAccount(value.target.value)}
                        inputClassName="amount"
                        onBlur={(value) => onFindBudgetAccount(value.target.value)}
                        value={initialValues?.budgetAccount}
                      />
                      <div>
                        <Button
                          onClick={getCorrespondentAccount}
                          type="button"
                          size={SizeType.Medium}
                          color={ButtonColor.Orange}
                        >
                          {translate("TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_LOAD_BUTTON_TITLE")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <InputField
                      className="col-lg-6"
                      disabled={true}
                      name="budgetAccountName"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_TAX_ID_FIELD_LABEL_TITLE"
                      value={initialValues?.budgetAccountName}
                    />
                    <InputField
                      className="col-lg-6"
                      disabled={true}
                      name="budgetTaxNumber"
                      label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_BENEFICIARY_ORG_NAME_FIELD_LABEL_TITLE"
                      value={initialValues?.budgetTaxNumber}
                    />
                  </div>
                </div>
              </GroupBox>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                className="h-100"
                title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNTS_LABEL_TITLE"
              >
                <div className="col-lg-6 mb-2">
                  <InputField
                    name="amount"
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_DIGITS_FIELD_LABEL_TITLE"
                    value={initialValues?.amount}
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    //   NotificationManager.error(translate("AMOUNT_COPY_PASTE"));
                    //   return false;
                    // }}
                    onFocus={() =>
                      initialValues?.amount &&
                      onChangeAmount(removeGaps(initialValues?.amount.replace(/[,]/g, ".")))
                    }
                    onChange={(value) => onChangeAmount(value.target.value.replace(/[,]/g, "."))}
                    onBlur={(value) =>
                      initialValues?.amount &&
                      onChangeAmount(floatFormat(addZeros(value.target.value.replace(/[,]/g, "."))))
                    }
                    autoComplete="off"
                    inputClassName="amount"
                  />
                </div>
                <div className="col-12">
                  <InputField
                    name="amountDetails"
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_AMOUNT_IN_WORDS_FIELD_LABEL_TITLE"
                    disabled={true}
                  />
                </div>
              </GroupBox>
            </div>
            <div className="col-lg-6">
              <GroupBox
                contentClassName="row"
                className="h-100"
                title="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_DETAILS_LABEL_TITLE"
              >
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label>
                      {translate(
                        "TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_CODE_FIELD_LABEL_TITLE",
                      )}
                    </label>
                    <div className="input-group">
                      <InputField
                        className="flex-1"
                        name="paymentCode"
                        onChange={(value) => onChangePaymentCode(value.target.value)}
                        autoComplete="off"
                        type="number"
                        inputClassName="amount"
                        value={initialValues?.paymentCode}
                      />
                      <div>
                        <Button
                          onClick={getPaymentCode}
                          type="button"
                          size={SizeType.Medium}
                          color={ButtonColor.Orange}
                        >
                          {translate("TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_OPTIONS_BUTTON_TITLE")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <InputField
                    name="detailsOfPayment"
                    label="TRANSFERS_TRANSFERS_LCY_TRANSFER_FORM_PAYMENT_DETAILS_FIELD_LABEL_TITLE"
                    onChange={(value) => onChangePaymentDetails(value.target.value)}
                    maxLength={400}
                    value={initialValues?.detailsOfPayment}
                  />
                </div>
              </GroupBox>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
