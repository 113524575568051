import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { ReferenceBookApi } from "./ReferenceBookApi";

interface Props {
  readonly ReferenceBookApi: ReferenceBookApi;
}

export function useReferenceBookContext(): Props {
  const data = useApiBase();

  const api = useMemo(() => new ReferenceBookApi(data), [data]);

  return {
    ReferenceBookApi: api,
  };
}
