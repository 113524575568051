import "./assets/mobile-menu-buttons.scss";

import React from "react";

import { MobileMenu } from "../api/dto/AppDTO";
import { ColorPalette } from "../theme/ColorPalette";
import MobileMenuCloseIcon from "../components/icons/MobileMenuCloseButton";

interface Props {
  readonly type: MobileMenu;
  readonly onClick: (type: MobileMenu) => void;
}

export function MobileMenuCloseButton({ onClick }: Props) {
  return (
    <div
      role="button"
      className="mobile-menu-close-button"
      onClick={() => onClick(MobileMenu.Closed)}
    >
      <MobileMenuCloseIcon color={ColorPalette.White} />
    </div>
  );
}
