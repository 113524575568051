import React from "react";
import cx from "classnames";

export enum LoaderSize {
  Large = "large",
  Small = "small",
}

interface Props {
  readonly color?: string;
  readonly size?: LoaderSize;
  readonly className?: string;
}

export function Loader({ color = "#1c579b", size = LoaderSize.Large, className }: Props) {
  return (
    <div
      role="status"
      style={{ color }}
      className={cx(
        "spinner-border",
        { "spinner-border-sm": size === LoaderSize.Small },
        className,
      )}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}
