import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { PerformAction, createReducer, createRootReducer } from "../utils/ReducerUtils";
import { AppStoreState } from "../store/RootReducer";

export const totalReducerPersistConfig: Partial<PersistConfig<TotalReducerState>> = {
  whitelist: ["totalApiBeginDay", "totalApiOperDay"],
};

export interface TotalReducerState {
  readonly totalApiBeginDay: string;
  readonly totalApiOperDay: string;
}

interface SwitchOperDayMeta {
  readonly totalApiOperDay?: string;
}

interface SwitchBeginDayMeta {
  readonly totalApiBeginDay?: string;
}

enum ReducerActions {
  SwitchBeginDay = "Total/SwitchBeginDay",
  SwitchOperDay = "Total/SwitchOperDay",
}

function getState(): TotalReducerState {
  return {
    totalApiBeginDay: "",
    totalApiOperDay: "",
  };
}

export const totalReducer = createRootReducer<TotalReducerState>(
  getState(),
  createReducer([ReducerActions.SwitchOperDay], (state, { meta }) =>
    update(state, { totalApiOperDay: meta.totalApiOperDay }),
  ),

  createReducer([ReducerActions.SwitchBeginDay], (state, { meta }) =>
    update(state, { totalApiBeginDay: meta.totalApiBeginDay }),
  ),
);

// ==================
// Selectors
// ==================

export const operDaySelector = ({ total }: AppStoreState): string => total.totalApiOperDay;

export const beginDaySelector = ({ total }: AppStoreState): string => total.totalApiBeginDay;

// ==================
// Actions
// ==================

export function switchOperDay(meta: SwitchOperDayMeta): PerformAction<SwitchOperDayMeta> {
  return { type: ReducerActions.SwitchOperDay, meta };
}

export function switchBeginDay(meta: SwitchBeginDayMeta): PerformAction<SwitchBeginDayMeta> {
  return { type: ReducerActions.SwitchBeginDay, meta };
}
