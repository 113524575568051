import "./assets/tab-item.scss";

import cx from "classnames";
import React, { ReactNode } from "react";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

export interface TabItemProps {
  readonly color: string;
  readonly title: string;
  readonly fluid?: boolean;
  readonly active: boolean;
  readonly badge?: ReactNode;
  readonly className?: string;
  readonly onClick: () => void;
  readonly withHeader?: boolean;
  readonly background?: boolean;
  readonly textColor?: boolean;
  readonly border?: boolean;
  readonly hover?: boolean;
}

export function TabItem({
  fluid,
  badge,
  color,
  title,
  background,
  active,
  textColor,
  onClick,
  border,
  className,
  withHeader = true,
}: TabItemProps) {
  const isMobile = CheckMobileUtils();
  return (
    <div
      role="button"
      onClick={onClick}
      className={cx(
        "d-flex flex-column overflow-hidden user-select-none tab-item",
        {
          "flex-1": fluid,
          "active-tab": active,
          "bg-transparent": background,
          "login-tab-border border-grey": border,
          "auth-active-tab": active && className === "auth-tabs",
        },
        className,
      )}
    >
      {withHeader && <div className={cx("tab-item-header", { "bg-secondary": active })} />}
      <div
        style={{ backgroundColor: active ? color : "transparent" }}
        className={"d-flex flex-1 align-items-center justify-content-center px-5"}
      >
        <span
          className={cx("text-center", {
            "mr-1": Boolean(badge),
            "fl-4": !isMobile,
            "text-secondary ": active,
            "text-black": !active,
            "text-white": textColor,
            "auth-tab-title text-blue-second": className === "auth-tabs",
          })}
        >
          {title}
        </span>
        {badge}
      </div>
    </div>
  );
}
