import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function TxtIcon({ size = 17, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 14.546 16.425"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-31.361)">
        <path
          fill={color}
          transform="translate(0)"
          d="M44.986,5.875h-.394v-1.9a.277.277,0,0,0,0-.036.316.316,0,0,0-.076-.209L41.345.11l0,0a.317.317,0,0,0-.064-.054L41.258.042a.336.336,0,0,0-.064-.027L41.177.009A.32.32,0,0,0,41.1,0H33.32a.645.645,0,0,0-.644.644V5.875h-.394a.92.92,0,0,0-.921.921v4.787a.921.921,0,0,0,.921.921h.394v3.277a.645.645,0,0,0,.644.644H43.948a.645.645,0,0,0,.644-.644V12.5h.394a.921.921,0,0,0,.921-.921V6.8A.92.92,0,0,0,44.986,5.875ZM33.32.644h7.461v3.3a.322.322,0,0,0,.322.322h2.845V5.875H33.32Zm3.177,6.1h1.249l.421.878c.142.292.25.528.364.8h.014c.114-.307.207-.521.328-.8l.407-.878h1.242L39.166,9.089l1.427,2.462H39.337L38.9,10.68c-.178-.335-.293-.585-.428-.863h-.014c-.1.279-.221.528-.371.863l-.4.871H36.447l1.392-2.433Zm-4.1.913V6.741h3.7v.913H34.784v3.9H33.693v-3.9Zm11.547,7.951H33.32V12.5H43.948v3.1Zm.606-7.951H43.241v3.9H42.149v-3.9H40.857V6.741h3.7v.913Z"
        />
      </g>
    </svg>
  );
}
