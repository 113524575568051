export interface ConversionProps {
  readonly approvalUser: string;
  readonly bankAddress: string;
  readonly bankName: string;
  readonly bankSwift: string;
  readonly bankTaxNumber: string;
  readonly buyCurrency: string;
  readonly buyAmount: string;
  readonly commissionAccount: string;
  readonly commissionRate: string;
  readonly createDate: string;
  readonly createUser: string;
  readonly creditAccount: string;
  readonly debtAccount: string;
  readonly documentId: string;
  readonly exchangeRate: string;
  readonly minimumRate: string;
  readonly maximumRate: string;
  readonly purposeOfConversion: string;
  readonly sellAmount: string;
  readonly sellCurrency: string;
  readonly state: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly transactionDate: string;
  readonly transactionType: string;
  readonly transitAccount: string;
}

export interface ExchangeRateProps {
  readonly baseExchangeRate: string;
  readonly buyExchangeRate: string;
  readonly currency: string;
  readonly currencyName: string;
  readonly sellExchangeRate: string;
}

export interface AccountsUzsToFcy {
  readonly accountCurrency: string;
  readonly listOfAccounts: string;
}

export interface ConversionLcyToFcy {
  readonly accountInFCY: string;
  readonly accountInUZS: string;
  readonly approvalUser: string;
  readonly attachedDocument1: string;
  readonly attachedDocument2: string;
  readonly attachedDocument3: string;
  readonly attachedDocument4: string;
  readonly attachedDocument5: string;
  readonly attachedDocument6: string;
  readonly bankCommissionForConversion: string;
  readonly buyAmount: string;
  readonly buyCurrency: string;
  readonly conversionAccountInFcy: string;
  readonly createUser: string;
  readonly documentId: string;
  readonly maximumAmountInUzs: string;
  readonly maximumRate: string;
  readonly numberAndDateOfTheContract: string;
  readonly otherPurpose: string;
  readonly purpose: string;
  readonly reserveAccountInUzs: string;
  readonly reservedAmountInUzs: string;
  readonly state: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly transactionDate: string;
  readonly rejectionReason?: string;
}

export interface ConversionDocumentsList {
  readonly approvalUser: string;
  readonly buyAmount: string;
  readonly buyCurrency: string;
  bankAddress?: string;
  beneficiaryBankAddress?: string;
  beneficiaryBankName?: string;
  beneficiaryAccount?: string;
  detailsOfPayment?: string;
  currencyCode?: string;
  beneficiarySwiftCode?: string;
  amount?: string;
  amountInWord?: string;
  readonly bankTaxNumber: string;
  readonly bankSwift: string;
  bankName?: string;
  applicantAddress?: string;
  applicantName?: string;
  applicantTaxId?: string;
  applicantAccount?: string;
  beneficiaryAddress?: string;
  beneficiaryName?: string;
  currency?: string;
  commissionAccount: string;
  taxId?: string;
  beneficiaryTaxId?: string;
  readonly commissionRate: string;
  readonly createDate: string;
  readonly createUser: string;
  readonly creditAccount: string;
  readonly debtAccount: string;
  readonly documentId: string;
  readonly exchangeRate: string;
  readonly minimumRate: string;
  readonly purposeOfConversion: string;
  readonly sellAmount: string;
  readonly sellCurrency: string;
  readonly state: string;
  readonly totalPageCount: string;
  readonly totalRowCount: string;
  readonly transactionDate: string;
  readonly transactionType: string;
  readonly transitAccount: string;
  fullName?: string;
}

export interface ConversionLcyToFcyQueryProps {
  readonly fromDate?: string;
  readonly pageNumber?: number;
  readonly pageSize?: number;
  readonly toDate?: string;
  readonly state?: string;
}

export interface ConversionIndHistoryQuery {
  readonly fromDate?: string;
  readonly pageNumber?: number;
  readonly pageSize?: number;
  readonly toDate?: string;
}

export interface ConversionGetCreditAccount {
  readonly currency: string;
  readonly lcyAccount: string;
}

export interface ConversionGetDebitAccount {
  readonly bankAccount: string;
  readonly currency: string;
  readonly fcyAccount: string;
}

export interface ClientsInfoProps {
  readonly clientInfoId: string;
  readonly employeeName: string;
  readonly employeePosition: string;
  readonly faxNumber: string;
  readonly generalArgumentDate: string;
  readonly generalArgumentNumber: string;
  readonly organizationChief: string;
  readonly organizationDirector: string;
  readonly postalAddress: string;
  readonly telephoneNumber: string;
}

export interface ConversionAccountProps {
  readonly accountCurrency: string;
  readonly listOfAccounts: string;
}

export interface ConversionAccountQuery {
  readonly accountType: ConversionAccountType;
}

export enum TransactionType {
  FCYtoUZSConversion = "FTL",
  FCYtoFCYConversion = "FTF",
}

export enum TransactionTypeForDebit {
  FCYtoBank = "FTB",
  FCYtoUZSConversion = "LTF",
  FCYtoFCYConversion = "FTF",
}

export enum ConversionAccountType {
  DebitInUZS = "DA",
  ReserveInUZS = "RA",
  ConversionInFCY = "CA",
  CreditInFCY = "AF",
}
