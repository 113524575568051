import "./assets/app-mobile-header.scss";

import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import { LogoIcon } from "../components/icons/LogoIcon";
import { Routes } from "../constants/Routes";
import { useTheme } from "../theme/ThemeContext";
import { MobileMenuButtonWrapper } from "./MobileMenuButtonWrapper";
import { CheckMobileUtils } from "../utils/CheckMobileUtils";

export function AppMobileHeader() {
  const { ColorPalette } = useTheme();

  const isMobile = CheckMobileUtils();

  const appHeaderStyle = useMemo(
    () => ({
      background: `linear-gradient(to top, ${ColorPalette.Secondary} 0, ${ColorPalette.BlueThird} 100%)`,
    }),
    [ColorPalette.BlueThird, ColorPalette.Secondary],
  );

  return (
    <div
      className="d-flex text-white mobile-app-header d-flex justify-content-between align-items-center px-5"
      style={appHeaderStyle}
    >
      <NavLink
        to={Routes.CorporateAccountsRoute}
        className="d-flex align-items-center justify-content-center mobile-app-logo-container"
      >
        <LogoIcon
          height={20}
          width={100}
          firstColor={ColorPalette.White}
          thirdColor={ColorPalette.White}
          secondColor={ColorPalette.BlueExtraLight}
        />
      </NavLink>
      {isMobile && <MobileMenuButtonWrapper />}
    </div>
  );
}
