import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly width: number;
  readonly height: number;
  readonly firstColor?: ColorPalette;
  readonly thirdColor?: ColorPalette;
  readonly secondColor?: ColorPalette;
}

export function LogoIcon({
  thirdColor = ColorPalette.Blue,
  firstColor = ColorPalette.Blue,
  secondColor = ColorPalette.Blue,
  ...svgProps
}: Props) {
  return (
    <svg {...svgProps} viewBox="0 0 2529.3 555" xmlns="http://www.w3.org/2000/svg">
      <g id="KDB" transform="translate(-3.713 -5)">
        <text
          fontSize="421"
          fill={firstColor}
          transform="translate(711.012 446)"
          fontFamily="Roboto-Regular, Roboto"
        >
          <tspan x="0" y="0">
            KDB{" "}
          </tspan>
          <tspan y="0" fill={secondColor}>
            iDBA
          </tspan>
        </text>
        <g transform="translate(3.712 32.545)">
          <g transform="translate(0 134.785)">
            <path
              fill={thirdColor}
              transform="translate(-64.757 169.975)"
              d="M71.539-35.382a51.732,51.732,0,0,0-.145,50.956l97.74,153.341a62.167,62.167,0,0,0,49.8,25.511h130.83L170.727-86.785c-13.157-20.445-23.682-62.665-13.407-83.19Z"
            />
          </g>
          <g transform="translate(102.664 82.642)">
            <path
              fill={thirdColor}
              transform="translate(-66.448 170.788)"
              d="M131.883-170.726a57.614,57.614,0,0,0-50.469,25.379L70.56-128.44C60.35-107.916,70.8-65.7,83.966-45.251l60.35,94.979a44.576,44.576,0,0,1,1.947-39.6L260.726-170.738Z"
            />
          </g>
          <g transform="translate(269.732)">
            <path
              fill={thirdColor}
              transform="translate(-69.385 172.019)"
              d="M69.385-172.019,248.513,109.31c13.025,20.314,23.682,62.691,13.262,83.242L347.687,57.893a51.836,51.836,0,0,0,.145-51.008L249.96-146.561a61.612,61.612,0,0,0-49.64-25.458Z"
            />
          </g>
          <g transform="translate(257.645 197.964)">
            <path
              fill={thirdColor}
              transform="translate(-69.429 168.451)"
              d="M184.089-128.9,69.429,52.015H198.364A57.811,57.811,0,0,0,248.991,26.57L259.7,9.821c10.354-20.564-.237-62.928-13.262-83.242l-60.521-95.031a44.506,44.506,0,0,1-1.842,39.548"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
