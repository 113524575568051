import React, { useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { toFinite } from "lodash";

import { DocIcon } from "../icons/DocIcon";
import { AppSideMenu } from "./AppSideMenu";
import { HelpIcon } from "../icons/HelpIcon";
import { LoanIcon } from "../icons/LoanIcon";
import { SideMenuItem } from "./SideMenuItem";
import { Routes } from "../../constants/Routes";
import { Badge, BadgeColor } from "../ui/Badge";
import { useI18n } from "../../i18n/I18nContext";
import { CreateNewDocumentRoles, MenuState, ViewMode } from "../../api/dto/AppDTO";
import { AccountIcon } from "../icons/AccountIcon";
import { TransferIcon } from "../icons/TransferIcon";
import { UserInfoIcon } from "../icons/UserInfoIcon";
import { AgreementIcon } from "../icons/AgreementIcon";
import { ContactUsIcon } from "../icons/ContactUsIcon";
import { ConversionIcon } from "../icons/ConversionIcon";
import { NotificationIcon } from "../icons/NotificationIcon";
import { ReferenceIcon } from "../icons/ReferenceIcon";
import {
  changeNotificationState,
  menuStateSelector,
  notificationCountSelector,
  viewModeSelector,
  changeNotificationStateCount,
} from "../../reducers/appReducer";
import { ClientFeedbackIcon } from "../icons/ClientFeedbackIcon";
import { getMenuItemIconColor } from "../../helpers/MenuHelpers";
import { PendingApprovalsIcon } from "../icons/PendingApprovalsIcon";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { ColorPalette } from "../../theme/ColorPalette";
import { TooltipItem } from "../tooltip/TooltipItem";
import { Tooltip } from "../tooltip/Tooltip";
import { useResource } from "../../hooks/useResource";
import { useAccountContext } from "../../api/account/AccountContext";
import { formatDateToBody } from "../../utils/DateUtils";
import { useUserInfoContext } from "../../api/userInfo/UserInfoContext";
import { userLoginSelector } from "../../reducers/userReducer";
import { accessToConversion } from "../../reducers/authReducer";
import { AnalyticsIcon } from "../icons/AnalyticsIcon";
import { userEmployeeIdSelector } from "../../reducers/userReducer";
import { userSelector } from "../../reducers/userReducer";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useBrowserTabChange } from "../../hooks/useBrowserTabChange";
import { OfficeIcon } from "../icons/OfficeIcon";
import { PaymentClaimIcon } from "../icons/PaymentClaimIcon";
import { switchBeginDay, switchOperDay } from "../../reducers/totalReducer";

export function CorporateSideMenuWrapper() {
  const { translate } = useI18n();
  const notification = useShallowEqualSelector(notificationCountSelector);
  const login = useShallowEqualSelector(userLoginSelector);
  const dateForm = new Date();
  const dispatch = useDispatch();
  dateForm.setMonth(dateForm.getMonth() - 1);
  const { UserInfoApi } = useUserInfoContext();
  const employeeId = useShallowEqualSelector(userEmployeeIdSelector);
  const user = useShallowEqualSelector(userSelector);
  const { logout } = useAuthContext();
  const { visibleBrowserTab } = useBrowserTabChange();

  let onceChange = true;
  useResource(
    () =>
      employeeId &&
      UserInfoApi.getUserNotifications({
        code: "02",
        fromDate: formatDateToBody(dateForm),
        toDate: formatDateToBody(new Date()),
        login,
        statusCode: "P",
        employeeId,
      }).then((r: any) => {
        dispatch(changeNotificationStateCount({ count: r.count }));
        if (r.loginStatus === "1" && onceChange) {
          logout();
          NotificationManager.error(
            translate("ATTENTION_SUBTITLE"),
            translate("ATTENTION_TITLE"),
            7000,
          );
          onceChange = false;
        }

        const newNotifications = r.filter((x) => x.status === "P");
        dispatch(changeNotificationState({ notification: newNotifications.length.toString() }));
        return r;
      }),
    [login, employeeId, visibleBrowserTab],
  );

  const match = useRouteMatch();

  const menuState = useShallowEqualSelector(menuStateSelector);

  const isExpanded = useMemo(() => menuState === MenuState.Expanded, [menuState]);

  const [show, setShow] = useState(false);

  const { AccountApi } = useAccountContext();

  const getTotalApprovalApl = useResource(() => AccountApi.getTotalPendingApplications(), []);

  const totalApp = useMemo(() => getTotalApprovalApl.data, [getTotalApprovalApl.data]);
  const viewMode = useShallowEqualSelector(viewModeSelector);

  useEffect(() => {
    dispatch(switchBeginDay({ totalApiBeginDay: totalApp?.beginDay }));
    dispatch(switchOperDay({ totalApiOperDay: totalApp?.operDay }));
  }, [dispatch, totalApp?.beginDay, totalApp?.operDay]);

  const accessToConversionTab = useShallowEqualSelector(accessToConversion);

  const isCorporateClient = useMemo(() => (user?.clientTypeId === "2" ? true : false), [
    user?.clientTypeId,
  ]);

  return (
    <AppSideMenu>
      {viewMode === ViewMode.Full ? (
        <SideMenuItem
          isExpanded={isExpanded}
          id="TooltipPendingApprovals"
          to={Routes.CorporatePendingApprovals}
          badge={
            <Badge
              value={toFinite(totalApp?.totalAllRequests)}
              color={BadgeColor.Danger}
              size={25}
            />
          }
          icon={
            <PendingApprovalsIcon
              color={getMenuItemIconColor(match.url, Routes.CorporatePendingApprovals)}
            />
          }
        >
          {translate("SIDE_MENU_PENDING_APPROVALS_TITLE")}
        </SideMenuItem>
      ) : (
        <div />
      )}
      <SideMenuItem
        id="TooltipAccounts"
        isExpanded={isExpanded}
        to={Routes.CorporateAccountsRoute}
        icon={
          <AccountIcon color={getMenuItemIconColor(match.url, Routes.CorporateAccountsRoute)} />
        }
      >
        {translate("SIDE_MENU_ACCOUNTS_TITLE")}
      </SideMenuItem>

      <SideMenuItem
        id="TooltipTransfers"
        isExpanded={isExpanded}
        to={Routes.CorporateTransfersRoute}
        icon={
          <TransferIcon color={getMenuItemIconColor(match.url, Routes.CorporateTransfersRoute)} />
        }
      >
        {translate("SIDE_MENU_TRANSFERS_TITLE")}
      </SideMenuItem>

      {accessToConversionTab === CreateNewDocumentRoles.Yes ? (
        <SideMenuItem
          id="TooltipConversion"
          isExpanded={isExpanded}
          to={Routes.CorporateConversion}
          icon={
            <ConversionIcon color={getMenuItemIconColor(match.url, Routes.CorporateConversion)} />
          }
        >
          {translate("SIDE_MENU_CONVERSION_TITLE")}
        </SideMenuItem>
      ) : (
        <div />
      )}

      <SideMenuItem
        id="TooltipTradeFinance"
        isExpanded={isExpanded}
        to={Routes.CorporateTradeFinance}
        icon={<OfficeIcon color={getMenuItemIconColor(match.url, Routes.CorporateTradeFinance)} />}
      >
        {translate("SIDE_MENU_TRADE_FINANCE_TITLE")}
      </SideMenuItem>

      <SideMenuItem
        id="TooltipPaymentClaim"
        isExpanded={isExpanded}
        to={Routes.CorporatePaymentClaim}
        icon={
          <PaymentClaimIcon color={getMenuItemIconColor(match.url, Routes.CorporatePaymentClaim)} />
        }
        badge={
          <Badge
            value={toFinite(totalApp?.totalRequestsForPaymentClaimCollectionOrder)}
            color={BadgeColor.Danger}
            size={25}
          />
        }
      >
        <>
          {translate("SIDE_MENU_PAYMENT_CLAIM_TITLE")} <br />
          {translate("SIDE_MENU_COLLECTION_ORDER_TITLE")}
        </>
      </SideMenuItem>

      <SideMenuItem
        id="TooltipYeisvo"
        isExpanded={isExpanded}
        to={Routes.CorporateYeisvo}
        icon={<DocIcon color={getMenuItemIconColor(match.url, Routes.CorporateYeisvo)} />}
      >
        {translate("SIDE_MENU_YEISVO_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        id="TooltipLoan"
        isExpanded={isExpanded}
        to={Routes.CorporateLoan}
        icon={<LoanIcon color={getMenuItemIconColor(match.url, Routes.CorporateLoan)} />}
      >
        {translate("SIDE_MENU_LOAN_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        id="TooltipUserInfo"
        isExpanded={isExpanded}
        to={Routes.CorporateAnalytics}
        icon={<AnalyticsIcon color={getMenuItemIconColor(match.url, Routes.CorporateAnalytics)} />}
      >
        {translate("SIDE_MENU_ANALYTICS_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        isExpanded={isExpanded}
        id="TooltipNotifications"
        badge={<Badge value={toFinite(notification)} color={BadgeColor.Danger} size={25} />}
        to={Routes.CorporateNotifications}
        icon={
          <NotificationIcon
            color={getMenuItemIconColor(match.url, Routes.CorporateNotifications)}
          />
        }
      >
        {translate("SIDE_MENU_NOTIFICATIONS_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        id="TooltipAgreements"
        isExpanded={isExpanded}
        to={Routes.CorporateAgreements}
        icon={<AgreementIcon color={getMenuItemIconColor(match.url, Routes.CorporateAgreements)} />}
      >
        {translate("SIDE_MENU_AGREEMENTS_TITLE")}
      </SideMenuItem>
      {isCorporateClient && (
        <SideMenuItem
          id="TooltipReference"
          isExpanded={isExpanded}
          to={Routes.CorporateReference}
          icon={
            <ReferenceIcon color={getMenuItemIconColor(match.url, Routes.CorporateReference)} />
          }
        >
          {translate("SIDE_MENU_REFERENCE_TITLE")}
        </SideMenuItem>
      )}
      <SideMenuItem
        id="TooltipHelp"
        isExpanded={isExpanded}
        to={Routes.CorporateHelp}
        icon={<HelpIcon color={getMenuItemIconColor(match.url, Routes.CorporateHelp)} />}
      >
        {translate("SIDE_MENU_HELP_AND_FAQ_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        isExpanded={isExpanded}
        id="TooltipClientFeedback"
        to={Routes.CorporateClientFeedback}
        icon={
          <ClientFeedbackIcon
            color={getMenuItemIconColor(match.url, Routes.CorporateClientFeedback)}
          />
        }
      >
        {translate("SIDE_MENU_CLIENT_FEEDBACK_TITLE")}
      </SideMenuItem>
      <SideMenuItem
        id="TooltipUserSettings"
        isExpanded={isExpanded}
        to={Routes.CorporateUserInfo}
        icon={<UserInfoIcon color={getMenuItemIconColor(match.url, Routes.CorporateUserInfo)} />}
      >
        {translate("SIDE_MENU_USER_INFO_AND_SETTINGS_TITLE")}
      </SideMenuItem>
      <a
        className="d-flex flex-column pl-1 text-white text-decoration-none py-2 side-menu-item cursor-pointer"
        onClick={() => window.open("https://kdb.uz/ru/about-us/contact-us", "_blank")}
        onMouseEnter={() => !isExpanded && setShow(true)}
        onMouseLeave={() => !isExpanded && setShow(false)}
        id={`${"ContactUs"}`}
      >
        <div className="d-flex flex-1 mx-5 align-items-center">
          <div className="d-flex align-items-center justify-content-center item-icon-container">
            <ContactUsIcon color={ColorPalette.Teal} />
          </div>
          <div className="d-flex flex-1 item-title-container">
            <div className="flex-shrink-1 flex-grow-1 mx-4">
              {isExpanded && (
                <span className="mw-100 item-title">{translate("SIDE_MENU_CONTACT_US_TITLE")}</span>
              )}
            </div>
          </div>
        </div>
      </a>
      <TooltipItem
        id={`${"ContactUs"}Item`}
        title={translate("SIDE_MENU_CONTACT_US_TITLE")}
        show={show}
      />
      <Tooltip
        buttonId={`${"ContactUs"}`}
        tooltipId={`${"ContactUs"}Item`}
        options={{
          placement: "right",
          modifiers: [{ name: "offset", options: { offset: [0, -176] } }],
        }}
      />
    </AppSideMenu>
  );
}
