import { BaseApi } from "../BaseApi";
import { PaymentDocumentProps } from "./PaymentDTO";

export class PaymentApi extends BaseApi {
  public getCollectionOrdersList(url, query): Promise<any[]> {
    return this.get(`collection_order/${url}/:employeeId`, {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getSentPaymentClaimsList(query): Promise<PaymentDocumentProps[]> {
    return this.get("payment-claim/get-registered-list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getReceivedPaymentClaimsList(query): Promise<PaymentDocumentProps[]> {
    return this.get("payment-claim/get-incoming-list/:employeeId", {
      // return this.get("payment-claim/get-incoming-document-list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getSentPaymentClaimsApprovalList(query): Promise<PaymentDocumentProps[]> {
    return this.get("payment-claim/get-approval-list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public getReceivedPaymentClaimsApprovalList(query): Promise<PaymentDocumentProps[]> {
    return this.get("payment-claim/get-incoming-approval-list/:employeeId", {
      params: { employeeId: this.employeeId },
      query,
    });
  }

  public setPaymentsRefund(body): Promise<boolean> {
    return this.post("payment-claim/refund/:employeeId", {
      params: { employeeId: this.employeeId },
      json: body,
    });
  }

  public setPaymentsCeate(body): Promise<boolean> {
    return this.post("payment-claim/create/:employeeId", {
      params: { employeeId: this.employeeId },
      json: body,
    });
  }
}
