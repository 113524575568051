import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { update } from "immupdate";
import { DateTime } from "luxon";
import cx from "classnames";

import { useResource } from "../../hooks/useResource";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { FcyToCreate, SenderAccountForFCYProps } from "../../api/transfer/TransferDTO";
import { Modal } from "../ui/Modal";
import { TabPage } from "../tabs/TabPage";
import { formatDateToBody, formatDateToForm } from "../../utils/DateUtils";
import { formatPhoneNumber, numberToWords } from "../../utils/FormatUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { FcyTransferForm } from "./FcyTransferForm";
import { showError } from "../../utils/NotificationUtils";
import { CorrespondentsFCYTable } from "./CorrespondentsFCYTable";
import { addZeros, floatFormat, removeGaps } from "../../utils/FloatUtils";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import {
  userEmployeeIdSelector,
  userLoginSelector,
  userPhoneSelector,
  userRoleSelector,
} from "../../reducers/userReducer";
import { FcyFavouritesModalTab } from "./FcyFavouritesModalTab";
import { TransfersFcyHeader } from "./TransfersFcyHeader";
import { Button, ButtonColor } from "../ui/Button";
import { FileControls } from "../ui/FileControls";
import { createTransferFCYExcelFromForm } from "../../helpers/TransfersHelpers";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { useI18n } from "../../i18n/I18nContext";
import { TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { appLanguageSelector, viewModeSelector } from "../../reducers/appReducer";
import { TransferCurrencyPicker } from "./TransferCurrencyPicker";
import { RoleType, ViewMode, CreateNewDocumentRoles } from "../../api/dto/AppDTO";
import { TransferFcyPrint } from "./TransferFcyPrint";
import { printForm } from "../../utils/PintUtils";
import { Input } from "../ui/Input";
import "./assets/lcy-create-forms.scss";
import { FCYNacCodeTable } from "./FCYNacCodeTable";
import { getIdnInfo } from "../../utils/IdnUtils";

import { createNewDocumentRole } from "../../reducers/authReducer";

interface Props {
  readonly onSubmit: (values: FcyToCreate) => void;
  readonly filter: TransfersFilter;
  readonly documentValues: any;
  readonly backButton: () => void;
  readonly loading: boolean;
}

const currencyCodeFromDebitAccount = (value: SenderAccountForFCYProps) => {
  const { debitAccount } = value;
  const code = debitAccount.substring(5, 8);
  const chosenCur = {
    840: "USD",
    978: "EUR",
    643: "RUB",
    826: "GBP",
  }[code];
  return chosenCur;
};

export function FCYTransferFormWrapper({
  onSubmit,
  filter,
  documentValues,
  backButton,
  loading,
}: Props) {
  const { TransferApi } = useTransferContext();
  const { PdfReportApi } = usePdfReportContext();
  const language = useShallowEqualSelector(appLanguageSelector);
  const userPhone = useShallowEqualSelector(userPhoneSelector);
  const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;
  const viewMode = useShallowEqualSelector(viewModeSelector);
  const createMode = useShallowEqualSelector(createNewDocumentRole)
  const isRole = useShallowEqualSelector(userRoleSelector);
  const [naceCodeSearch, setNaceCodeSearch] = useState("");
  const [initialValues, setInitialValues] = useState<FcyToCreate>({
    amount: "",
    amountInWord: "",
    applicantAccount: "",
    applicantName: "",
    applicantAddress: "",
    applicantBalance: "",
    applicantTaxId: "",
    senderPhoneNumber: "+998",
    tinOfBank: "",
    tinOfCustomer: "",
    contractNumber: "",
    isIdn: isRole === RoleType.Corporate ? true : false,
    bankName: "",
    bankAddress: "",
    bankSwift: "",
    beneficiaryAccount: "",
    beneficiaryAddress: "",
    beneficiaryBankAddress: "",
    beneficiaryBankName: "",
    beneficiaryName: "",
    beneficiarySwiftCode: "",
    beneficiaryTaxId: "",
    passportNumber: "",
    charges: "",
    commissionAccount: "",
    commissionBalance: "",
    favoriteName: "",
    corrAccount: "",
    voCode: "",
    correspondentBankName1: "",
    correspondentBankName2: "",
    correspondentSwiftCode1: "",
    correspondentSwiftCode2: "",
    currency: "",
    details: "",
    employerId: useShallowEqualSelector(userEmployeeIdSelector),
    kpp: "",
    id: "",
    remarks: "",
    transactionDate: new Date(),
    transferNumber: "",
    servicesType: "",
    hsCode: "",
    naceCode: "",
    invNumber: "",
    invnDate: new Date(),
    phoneOperator: "",
  });
  const login = useShallowEqualSelector(userLoginSelector);
  const { translate } = useI18n();
  const lcyFormRef = useRef<any>(null);

  const [favorites, setFavorites] = useState([]);
  const [fcyFavourites, setFcyFavourites] = useState(false);

  const phoneOperatorCode = useResource(() => TransferApi.getCountyPhoneOperators(), [TransferApi]);

  const operatorCodes = useMemo(() => phoneOperatorCode.data || [], [phoneOperatorCode]);

  useEffect(() => {
    if (isRole === RoleType.Individual) {
      const operator = userPhone?.substring(3, 5);

      const hasOperator = operatorCodes.filter((op) => op.code === Number(operator));

      setInitialValues((prev) =>
        update(prev, {
          senderPhoneNumber: formatPhoneNumber(userPhone),
          phoneOperator:
            hasOperator?.length > 0
              ? hasOperator[0]?.operator
              : translate(
                  "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE",
                ),
        }),
      );
    }
  }, [isRole, operatorCodes, translate, userPhone]);

  const openFavourites = useCallback(() => {
    setFcyFavourites(true);
    TransferApi.getFcyFavorites({ userId: login }).then((r) => {
      r.map((res: any) => res);
      setFavorites(r);
    });
  }, [TransferApi, login]);

  const onChangeSenderPhoneNumber = useCallback(
    (value) => {
      const checked = /^[\d + ]*$/.test(value);
      if (checked && value.includes("998") && value.length <= 17) {
        const operator = value.replaceAll(" ", "").substring(4, 6);

        const hasOperator = operatorCodes.filter((op) => op.code === Number(operator));

        setInitialValues((prev) =>
          update(prev, {
            senderPhoneNumber: value,
            phoneOperator:
              hasOperator?.length > 0
                ? hasOperator[0]?.operator
                : translate(
                    "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE",
                  ),
          }),
        );
      }

      if (value === "+998" || value === "+998 ") {
        setInitialValues((prev) =>
          update(prev, {
            phoneOperator: "",
          }),
        );
      }
    },
    [operatorCodes, translate],
  );

  const onChangeBeneficiaryBankName = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        beneficiaryBankName: value,
      }),
    );
  }, []);

  const onChangeBeneficiaryBankAddress = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        beneficiaryBankAddress: value,
      }),
    );
  }, []);

  const onChangeCorrespondentName1 = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        correspondentBankName1: value,
      }),
    );
  }, []);

  const onChangeCorrespondentName2 = useCallback((value) => {
    setInitialValues((prev) =>
      update(prev, {
        correspondentBankName2: value,
      }),
    );
  }, []);

  const onCheckSwiftCode = useCallback(
    (value) => {
      if (value === "") {
        return;
      } else {
        TransferApi.getBankNameBySwift({ swiftCode: value }).then((r) => {
          setInitialValues((prev) =>
            update(prev, {
              beneficiaryBankAddress: r[0]
                ? r[0].swiftAddress
                : initialValues.beneficiaryBankAddress,
              beneficiaryBankName: r[0] ? r[0].swiftName : initialValues.beneficiaryBankName,
            }),
          );
        });
      }
    },
    [TransferApi, initialValues.beneficiaryBankAddress, initialValues.beneficiaryBankName],
  );

  const onCheckCorrespondent1SwiftCode = useCallback(
    (value) => {
      if (value === "") {
        return;
      } else {
        TransferApi.getBankNameBySwift({ swiftCode: value }).then((r) => {
          setInitialValues((prev) =>
            update(prev, {
              correspondentBankName1: r[0] ? r[0].swiftName : initialValues.correspondentBankName1,
            }),
          );
        });
      }
    },
    [TransferApi, initialValues.correspondentBankName1],
  );

  const onCheckCorrespondent2SwiftCode = useCallback(
    (value) => {
      if (value === "") {
        return;
      } else {
        TransferApi.getBankNameBySwift({ swiftCode: value }).then((r) => {
          setInitialValues((prev) =>
            update(prev, {
              correspondentBankName2: r[0] ? r[0].swiftName : initialValues.correspondentBankName2,
            }),
          );
        });
      }
    },
    [TransferApi, initialValues.correspondentBankName2],
  );

  const getFcyFavourites = useCallback(
    (value) => {
      setFcyFavourites(false);
      TransferApi.getFcyFavoritesDetails({ id: value }).then((r) => {
        const operator = r?.senderPhoneNumber?.replaceAll(" ", "")?.substring(4, 6);

        const hasOperator = operatorCodes.filter((op) => op.code === Number(operator));

        setInitialValues((prev) =>
          update(prev, {
            ...r,
            applicantAccount: { label: r.clientAccount, value: r.clientAccount },
            commissionAccount: { label: r.commissionAccount, value: r.commissionAccount },
            beneficiaryName: r.correspondentName,
            phoneOperator:
              hasOperator.length > 0
                ? hasOperator[0].operator
                : translate(
                    "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE",
                  ),
            beneficiaryAccount: r.correspondentAccount,
            // transactionDate: r.transactionDate
            //   ? DateTime.fromFormat(r.transactionDate, "dd.MM.yyyy").toJSDate()
            //   : new Date(),
            transactionDate: new Date(),
            employerId: initialValues.employerId,
            servicesType: r.fcyType,
            invNumber: r.details?.substring(
              r.details?.indexOf("/INVN:") + 6,
              r.details?.indexOf("//PRDN:"),
            ),
            hsCode:
              r?.fcyType === "goods"
                ? r.details.substring(
                    r.details?.indexOf("PRDN:HS") + 7,
                    r.details?.indexOf("//PRDD:"),
                  )
                : null,
            naceCode: r.naceCode,
            tinOfBank: r?.idn ? getIdnInfo(r?.idn).tinOfBank : "",
            tinOfCustomer: r?.idn ? getIdnInfo(r?.idn).tinOfCustomer : "",
            contractNumber: r?.idn ? getIdnInfo(r?.idn).contractNumber : "",
            senderPhoneNumber: r?.senderPhoneNumber?.substring(0, 4)?.includes("998")
              ? r?.senderPhoneNumber
              : "+998",
            isIdn: r?.idn ? true : false,
          }),
        );
      });
    },
    [initialValues.employerId, TransferApi, operatorCodes, translate],
  );

  const transferType = useMemo(() => filter.getTransferType(), [filter]);
  const isUnselectType = useMemo(() => transferType === TransferType.Unselect, [transferType]);

  const lcyFormRefHandler = useCallback(
    (instance) => {
      if (instance) {
        lcyFormRef.current = instance;
      }
    },
    [lcyFormRef],
  );

  const [swiftCodes, setSwiftCodes] = useState<string[]>([]);
  const [isSwiftCode, setIsSwiftCode] = useState<boolean>(true);
  const [isDisable, setIsDisable] = useState<boolean>(false);


  const onChangeIsSwiftCode = () => {
    setIsSwiftCode(false);
    setIsDisable(true);
  }


  const onChangeBeneficiaryBankSwift = useCallback(
    (value) => {

      if(value.length <= 12){
        
        if (value.trim() === "") {
          setSwiftCodes([]); 
          setIsSwiftCode(true)
          setIsDisable(false)
        } else if(value.length >= 2){
          searchSwiftCode(value.toUpperCase().trim());
        }
        
        if(isDisable){

          setInitialValues((prev) =>
            update(prev, {
              beneficiarySwiftCode: value,
              beneficiaryBankName: "",
              beneficiaryBankAddress: ""
            }),
          );
          
        }else {
          setInitialValues((prev) =>
            update(prev, {
              beneficiarySwiftCode: value
            }),
          );
        }

      }
    },
    [setInitialValues, setIsSwiftCode, setSwiftCodes, isDisable],
  );

  const searchSwiftCode = useCallback(
    (value) => {
      if (value === ""){
        setSwiftCodes([]); 
        return;
      }{
        TransferApi.getSwiftCode({ swiftCode: value }).then((r) => {
          if (r?.length > 0) {
            setSwiftCodes(r?.map(item => item.swiftCode));
          } else {
            setSwiftCodes([]);
          }
        }).catch(error => {
          setSwiftCodes([]); 
        });
      }
    },
    [setInitialValues, setSwiftCodes],
  )


  const onChangeCorrAccount = useCallback(
    (value) => {
      if (value.length < 30) {
        setInitialValues((prev) =>
          update(prev, {
            corrAccount: value,
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeDetails = useCallback(
    (value) => {
      if (value.length <= 140) {
        if (initialValues.currency === "EUR") {
          if (initialValues.servicesType === "goods") {
            const str =
              "D-LE-NACE:" +
              initialValues.naceCode +
              "/INV/" +
              formatDateToForm(initialValues.invnDate) +
              "/INVN:" +
              initialValues.invNumber +
              "//PRDN:HS" +
              initialValues.hsCode +
              "//PRDD:";

            if (value.includes(str)) {
              setInitialValues((prev) =>
                update(prev, {
                  details: value?.replace(/\s+/g, " "),
                }),
              );
            }
          } else if (initialValues.servicesType === "services") {
            const str = `D-LE-NACE:${initialValues.naceCode}/TXT/:`;
            if (value.includes(str)) {
              setInitialValues((prev) =>
                update(prev, {
                  details: value?.replace(/\s+/g, " "),
                }),
              );
            }
          }
        } else {
          setInitialValues((prev) =>
            update(prev, {
              details: value?.replace(/\s+/g, " "),
            }),
          );
        }
      }
    },
    [setInitialValues, initialValues],
  );

  const onChangeKPP = useCallback(
    (value) => {
      if (value.length < 20) {
        setInitialValues((prev) =>
          update(prev, {
            kpp: value,
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeRemarks = useCallback(
    (value) => {
      if (value.length <= 160) {
        setInitialValues((prev) =>
          update(prev, {
            remarks: value,
          }),
        );
      }
    },
    [setInitialValues],
  );


  const [swiftCodesCorrespondent, setSwiftCodesCorrespondent] = useState<string[]>([]);
  const [isSwiftCodeCorrespondent, setIsSwiftCodeCorrespondent] = useState<boolean>(true);
  const [isDisableCorrespondent, setIsDisableCorrespondent] = useState<boolean>(false);


  const onChangeIsSwiftCodeCorrespondent = () => {
    setIsSwiftCodeCorrespondent(false);
    setIsDisableCorrespondent(true);
  }


  const onChangeBankSwift1 = useCallback(
    (value) => {

      if(value.length <= 12){

        if (value.trim() === "") {
          setSwiftCodesCorrespondent([]); 
          setIsSwiftCodeCorrespondent(true)
          setIsDisableCorrespondent(false)
        } else if(value.length >= 2){
          searchSwiftCodeCorrespondent(value.toUpperCase().trim());
        }

        if(isDisableCorrespondent){

          setInitialValues((prev) =>
            update(prev, {
              correspondentSwiftCode1: value,
              correspondentBankName1: ""
            }),
          );
          
        }else {
          setInitialValues((prev) =>
            update(prev, {
              correspondentSwiftCode1: value
            }),
          );
        }
      }

    },
    [setInitialValues, setIsSwiftCodeCorrespondent, setSwiftCodesCorrespondent, isDisableCorrespondent],
  );

  const searchSwiftCodeCorrespondent = useCallback(
    (value) => {
      if (value === ""){
        setSwiftCodesCorrespondent([]); 
        return;
      }{
        TransferApi.getSwiftCode({ swiftCode: value }).then((r) => {
          if (r?.length > 0) {
            setSwiftCodesCorrespondent(r?.map(item => item.swiftCode));
          } else {
            setSwiftCodesCorrespondent([]);
          }
        }).catch(error => {
          setSwiftCodesCorrespondent([]); 
        });
      }
    },
    [setInitialValues, setSwiftCodesCorrespondent],
  )


  const onChangeBankSwift2 = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          correspondentSwiftCode2: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeTransferNumber = useCallback(
    (value) => {
      if (value.length <= 10) {
        setInitialValues((prev) =>
          update(prev, {
            transferNumber: value,
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeDocumentDate = useCallback(
    (value) => {
      NotificationManager.info(
        translate("WHEN_SELECTED_DATE_WARNING_FOR_NON_WORKING_DAY_NOTIFICATION_TITLE"),
      );
      setInitialValues((prev) =>
        update(prev, {
          transactionDate: value,
        }),
      );
    },
    [setInitialValues, translate],
  );
  const onChangeAmount = useCallback(
    (value) => {
      const size = value.includes(" ") ? 19 : value.split(".")[1] ? 16 : 13;
      if (value.length < size) {
        if (value.split(".")[1]) {
          if (value.split(".")[1]?.length <= 2) {
            const currencyApp =
              isRole === RoleType.Corporate
                ? TransferCurrencyPicker({ currency: initialValues.currency })
                : TransferCurrencyPicker({ currency: initialValues.currency?.value });
            const amountInWord = numberToWords(removeGaps(value), {
              language: Language.English,
              currencyCode: currencyApp,
            });
            if (isRole === RoleType.Corporate) {
              setInitialValues((prev) =>
                update(prev, {
                  amount: value.replace(/[^0-9 .]/gi, ""),
                  amountInWord:
                    amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1),
                }),
              );
            } else {
              setInitialValues((prev) =>
                update(prev, {
                  amount: value.replace(/[^0-9 .]/gi, ""),
                  amountInWord: initialValues?.currency?.value
                    ? amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1)
                    : "",
                }),
              );
            }
          }
        } else {
          const currencyApp = TransferCurrencyPicker({
            currency:
              isRole === RoleType.Corporate
                ? initialValues.currency?.toUpperCase()
                : initialValues.currency?.value.toUpperCase(),
          });
          const amountInWord = numberToWords(removeGaps(value), {
            language: Language.English,
            currencyCode: currencyApp,
          });
          if (isRole === RoleType.Corporate) {
            setInitialValues((prev) =>
              update(prev, {
                amount: value.replace(/[^0-9 .]/gi, ""),
                amountInWord: amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1),
              }),
            );
          } else {
            setInitialValues((prev) =>
              update(prev, {
                amount: value.replace(/[^0-9 .]/gi, ""),
                amountInWord: initialValues.currency
                  ? amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1)
                  : "",
              }),
            );
          }
        }
      }
    },
    [initialValues.currency, isRole],
  );




  const onChangeCorrespondentName = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          beneficiaryName: value,
        }),
      );
    },
    [setInitialValues],
  );
  const onChangeCharges = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          charges: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeServicesType = useCallback(
    (value, naceCode) => {
      setInitialValues((prev) =>
        update(prev, {
          hsCode: value === "services" ? null : initialValues.hsCode,
          servicesType: value,
          details: value === "services" ? `D-LE-NACE:${naceCode}/TXT/:` : "",
          invnDate: new Date(),
          invNumber: "",
        }),
      );
    },
    [setInitialValues, initialValues.hsCode, initialValues.details],
  );

  const onChangeCorrespondentAccount = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          beneficiaryAccount: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeVoCode = useCallback(
    (value) => {
      if (value.length < 6) {
        setInitialValues((prev) =>
          update(prev, {
            voCode: value,
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeCorrespondentAddress = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          beneficiaryAddress: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeCorrespondentTaxNumber = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          beneficiaryTaxId: value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangeHsCode = useCallback(
    (value) => {
      if (initialValues.details.length + value.length <= 140) {
        if (value.length <= 6) {
          setInitialValues((prev) =>
            update(prev, {
              hsCode: value,
            }),
          );
        }
      }
    },
    [setInitialValues, initialValues.details],
  );

  const onChangeNacCode = useCallback(
    (value) => {
      if (value.length <= 4) {
        setInitialValues((prev) =>
          update(prev, {
            // naceCode: value,
            details: "D-LE-NACE:" + value + initialValues.details,
          }),
        );
      }
    },
    [setInitialValues, initialValues.details],
  );

  const onChangeINVnumber = useCallback(
    (value) => {
      if (initialValues.details.length + value.length <= 140) {
        setInitialValues((prev) =>
          update(prev, {
            invNumber: value,
          }),
        );
      }
    },
    [setInitialValues, initialValues.details],
  );

  const onChangeINVNDate = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          invnDate: value,
          details:
            "D-LE-NACE:" +
            initialValues.naceCode +
            "/INV/" +
            formatDateToForm(value) +
            "/INVN:" +
            initialValues.invNumber +
            "//PRDN:HS" +
            initialValues.hsCode +
            "//PRDD:",
        }),
      );
    },
    [setInitialValues, initialValues.hsCode, initialValues.naceCode, initialValues.invNumber],
  );

  const onChangeContractNumber = useCallback(
    (value) => {
      if (value.length <= 9) {
        setInitialValues((prev) =>
          update(prev, {
            contractNumber: value,
          }),
        );
      }
    },
    [setInitialValues],
  );

  const onChangeIsIdn = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          isIdn: value,
        }),
      );

      if (!value) {
        setInitialValues((prev) =>
          update(prev, {
            tinOfBank: "",
            tinOfCustomer: "",
            contractNumber: "",
          }),
        );
      }
    },
    [setInitialValues],
  );

  const [debitAccount, setDebitAccount] = useState({});
  const [commissionAccount, setCommissionAccount] = useState({});
  const [searchTaxId, setSearchTaxId] = useState("");

  useResource(
    () =>
      TransferApi.getSenderAccountsForFCY()
        .then((r) => {
          setDebitAccount([
            r.map((res) => ({
              label: res.debitAccount,
              value: res.debitAccount,
              applicantAddress: res.applicantAddress,
              applicantName: res.applicantName,
              bankAddress: res?.bankAddress,
              bankSwift: res?.bankSwift,
              debitAccount: res?.debitAccount,
              bankName: res?.bankName,
              taxId: res?.taxId,
              currentBalance: res.currentBalance,
              passportNumber: res.passportNumber,
            })),
          ]);
          isRole !== RoleType.Corporate &&
            setCommissionAccount([
              r.map((res) => ({
                label: res?.debitAccount,
                value: res.currentBalance,
              })),
            ]);
        })
        .catch(showError),
    [TransferApi],
  );

  // const [commissionAccount, setCommissionAccount] = useState({});

  useResource(
    () =>
      isRole === RoleType.Corporate
        ? TransferApi.getCommissionsAccountsForFCY().then((r) => {
            setCommissionAccount([
              r.map((res) => ({
                label: res.account,
                value: res.balance,
              })),
            ]);
          })
        : null,
    [],
  );

  const onChangeCommissionAccount = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          commissionAccount: value,
          commissionBalance: floatFormat(addZeros(value.value)),
        }),
      );
    },
    [setInitialValues],
  );

  const [checkCurrency, setCheckCurrency] = useState<string>("");


  const onChangeCurrency = useCallback(
    (value) => {

      setCheckCurrency(value.toUpperCase())

      setInitialValues((prev) =>
        update(prev, {
          charges: "",
        }),
      );

      setInitialValues((prev) =>
        update(prev, {
          currency:
            isRole === RoleType.Corporate
              ? value.replace(/[^A-z]/gi, "")
              : {
                  label: value.label,
                  value: value.value,
                },
        }),
      );
    },
    [setInitialValues, isRole],
  );

  const onBlurCurrency = useCallback(() => {
    if (isRole === RoleType.Corporate) {
      if (initialValues?.currency?.length === 3) {
        if (initialValues.currency !== "EUR") {
          setInitialValues((prev) =>
            update(prev, {
              details: "",
              remarks: "",
            }),
          );
        }
        if (initialValues.amount !== "0.00" && initialValues.amount) {
          const amountInWord = numberToWords(removeGaps(initialValues.amount), {
            language: Language.English,
            currencyCode: TransferCurrencyPicker({
              currency: initialValues.currency.toUpperCase(),
            }),
          });
          setInitialValues((prev) =>
            update(prev, {
              amountInWord: amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1),
            }),
          );
        }
      }
    } else {
      if (initialValues.currency) {
        const currency = initialValues.currency?.value;
        if (initialValues.amount !== "0.00" && initialValues.amount) {
          const amountInWord = numberToWords(removeGaps(initialValues.amount), {
            language: Language.English,
            currencyCode: TransferCurrencyPicker({ currency: currency.toUpperCase() }),
          });
          setInitialValues((prev) =>
            update(prev, {
              amountInWord: amountInWord && amountInWord[0].toUpperCase() + amountInWord.slice(1),
            }),
          );
        }
      }
    }
  }, [initialValues.amount, initialValues.currency, isRole]);

  const onBlurINVNDate = useCallback(() => {
    setInitialValues((prev) =>
      update(prev, {
        details:
          "D-LE-NACE:" +
          initialValues.naceCode +
          "/INV/" +
          formatDateToForm(initialValues.invnDate) +
          "/INVN:" +
          initialValues.invNumber +
          "//PRDN:HS" +
          initialValues.hsCode +
          "//PRDD:",
      }),
    );
  }, [
    setInitialValues,
    initialValues.naceCode,
    initialValues.invNumber,
    initialValues.hsCode,
    initialValues.invnDate,
  ]);

  const onBlurINVnumber = useCallback(
    (value) => {
      setInitialValues((prev) =>
        update(prev, {
          details:
            "D-LE-NACE:" +
            initialValues.naceCode +
            "/INV/" +
            formatDateToForm(initialValues.invnDate) +
            "/INVN:" +
            value +
            "//PRDN:HS" +
            initialValues.hsCode +
            "//PRDD:",
        }),
      );
    },
    [setInitialValues, initialValues],
  );

  const onBlurHsCode = useCallback(
    (value) => {
      if (initialValues.currency === "EUR") {
        setInitialValues((prev) =>
          update(prev, {
            details:
              "D-LE-NACE:" +
              initialValues.naceCode +
              "/INV/" +
              formatDateToForm(initialValues.invnDate) +
              "/INVN:" +
              initialValues.invNumber +
              "//PRDN:HS" +
              value +
              "//PRDD:",
          }),
        );
      } else {
        setInitialValues((prev) =>
          update(prev, {
            details: value,
          }),
        );
      }
    },
    [setInitialValues, initialValues],
  );

  const onChangeDebitsAccount = useCallback(
    (value) => {
      const currency = currencyCodeFromDebitAccount(value) || "";
      setInitialValues((prev) =>
        update(prev, {
          applicantName: value.applicantName,
          applicantAddress: value.applicantAddress,
          applicantBalance: floatFormat(addZeros(value.currentBalance)),
          bankName: value.bankName,
          applicantTaxId: value.taxId,
          applicantAccount: value,
          bankAddress: value.bankAddress,
          bankSwift: value.bankSwift,
          applicantPhone: userPhone,
          passportNumber: value.passportNumber,
          currency:
            isRole === RoleType.Corporate
              ? initialValues.currency
              : {
                  label: currency,
                  value: currency,
                },
          // new value where commission values are applicant(balance,account)
          commissionAccount: isRole === RoleType.Corporate ? value.commissionAccount : value,
          commissionBalance:
            isRole === RoleType.Corporate
              ? value.commissionBalance
              : floatFormat(addZeros(value.currentBalance)),
        }),
      );
    },
    [setInitialValues, userPhone, isRole, initialValues],
  );

  const [beneficiary, setBeneficiary] = useState(false);
  const [nacCode, setNacCode] = useState(false);

  const beneficiaryAccounts = useResource(() => TransferApi.getCorrespondentAccountsForFCY(), [
    TransferApi,
  ]);

  const nacCodes = useResource(() => TransferApi.getNacCodesForFCY(naceCodeSearch), [
    TransferApi,
    naceCodeSearch,
  ]);

  const beneficiaryList = useMemo(() => beneficiaryAccounts?.data || [], [
    beneficiaryAccounts?.data,
  ]);

  const nacCodesList = useMemo(() => nacCodes?.data || [], [nacCodes?.data]);

  const beneficiaryListSearch = useCallback((data, term) => {
    if (term !== "") {
      // return data && data.filter((item) => item.taxNumber?.indexOf(term) > -1);
      return data && data.filter((item) => item.beneficiaryName?.indexOf(term.toUpperCase()) > -1);
    } else return data;
  }, []);

  const submitHandler = useCallback(
    (values) => {
      const checked =
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.details) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.corrAccount) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.kpp) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.remarks) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.correspondentSwiftCode2) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.correspondentBankName2) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.correspondentSwiftCode1) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.correspondentBankName1) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiarySwiftCode) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryBankAddress) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryBankName) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryAccount) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryAddress) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryTaxId) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.senderPhoneNumber) ||
        /[^A-z0-9-/?:().,'+ ]/gi.test(values.beneficiaryName);
      if (checked) {
        NotificationManager.error(
          translate("TRANSFERS_USE_THIS_SYMBOLS"),
          translate("SIDE_MENU_TRANSFERS_TITLE"),
          7000,
        );
      } else if (
        values.beneficiaryBankName === "Not Found" ||
        values.beneficiaryBankAddress === "Not Found" ||
        values.correspondentBankName1 === "Not Found" ||
        values.correspondentBankName2 === "Not Found"
      ) {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK"),
          translate("TRANSFER_ERRORS_BENEFICIARY_BANK_TITLE"),
          7000,
        );
      } else if (values.charges === "" || !values.charges) {
        NotificationManager.error(
          translate("TRANSFER_CHARGES_ERROR"),
          translate("TRANSFER_CHARGES_ERROR_TITLE"),
          7000,
        );
      }
      // else if (values.senderPhoneNumber === "") {
      //   NotificationManager.error(
      //     translate("TRANSFER_SENDER_PHONE_NUMBER_ERROR"),
      //     translate(
      //       "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_APPLICANT_PHONE_NUMBER_FIELD_LABEL_TITLE",
      //     ),
      //     7000,
      //   );
      // }
      //  else if (typeof values.amount !== "number") {
      //   NotificationManager.error(
      //     translate("TRANSFER_AMOUNT_ERROR"),
      //     translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
      //     7000,
      //   );
      // }
      else if (values.amount === "0.00") {
        NotificationManager.error(
          translate("TRANSFER_ERRORS_AMOUNT"),
          translate("TRANSFER_ERRORS_AMOUNT_TITLE"),
          7000,
        );
      }else {
        // const voCodeNeed = values.details.split("') ");
        const data = {
          ...values,
          fcyType: values.servicesType,
          documentDate: formatDateToBody(values.documentDate),
          transactionDate: formatDateToBody(values.transactionDate),
          amount: removeGaps(values.amount),
          applicantAccount: values.applicantAccount.label,
          // commissionAccount: isRole === RoleType.Corporate ? values.commissionAccount.label : "",
          commissionAccount: values.commissionAccount.label,
          kpp: values.currency !== "RUB" ? "" : values.kpp,
          corrAccount: values.currency !== "RUB" ? "" : values.corrAccount,
          details:
            values.currency !== "RUB"
              ? values?.details?.toUpperCase()
              : values?.id
              ? // ? `('${values.voCode}') ${voCodeNeed[1]}` hide with this variable - voCodeNeed. Because we don't need take previous voCode
                `('${values.voCode}') ${values.details}`
              : `('${values.voCode}') ${values.details}`,
          applicantBalance: removeGaps(values.applicantBalance),
          commissionBalance: removeGaps(values.commissionBalance),
          beneficiaryTaxId: values.beneficiaryTaxId ? values.beneficiaryTaxId : "",
          applicantPhone: values.applicantPhone,
          senderPhoneNumber: values.senderPhoneNumber?.replaceAll(" ", "").replace("+", ""),
          currency: isRole === RoleType.Corporate ? values.currency : values.currency.value,
          idn: values.tinOfBank + values.tinOfCustomer + values.contractNumber,
        };
        onSubmit(data);
      }
    },
    [isRole, onSubmit, translate],
  );

  const [agreeFirst, setAgreeFirst] = useState(false);
  const [agreeType, setAgreeType] = useState(false);

  const agreeModalOpen = useCallback((values) => {
    setInitialValues((prev) => update(prev, { ...values }));
    setAgreeFirst(true);
    if (values.applicantAccount.label.substr(0, 5) === "22614") {
      setAgreeType(false);
    } else {
      setAgreeType(true);
    }
  }, []);

  const onCorrespondentSelect = useCallback(
    (value) => {
      setBeneficiary(false);
      const selected = beneficiaryList.filter((corr) => corr.beneficiaryAccount === value);
      setInitialValues((prev) =>
        update(prev, {
          ...selected[0],
          beneficiaryName:
            selected[0]?.beneficiaryName !== null
              ? selected[0]?.beneficiaryName
              : initialValues.beneficiaryName,
          beneficiaryTaxId:
            selected[0]?.beneficiaryTaxId !== null
              ? selected[0]?.beneficiaryTaxId
              : initialValues.beneficiaryTaxId,
          beneficiaryAddress:
            selected[0]?.beneficiaryAddress !== null
              ? selected[0]?.beneficiaryAddress
              : initialValues.beneficiaryAddress,
          beneficiaryAccount:
            selected[0]?.beneficiaryAccount !== null
              ? selected[0]?.beneficiaryAccount
              : initialValues.beneficiaryAccount,
          beneficiaryBankName:
            selected[0]?.beneficiaryBankName !== null
              ? selected[0]?.beneficiaryBankName
              : initialValues.beneficiaryBankName,
          beneficiaryBankAddress:
            selected[0]?.beneficiaryBankAddress !== null
              ? selected[0]?.beneficiaryBankAddress
              : initialValues.beneficiaryBankAddress,
          beneficiarySwiftCode:
            selected[0]?.beneficiarySwiftCode !== null
              ? selected[0]?.beneficiarySwiftCode
              : initialValues.beneficiarySwiftCode,
          correspondentBankName1:
            selected[0]?.correspondentBankName1 !== null
              ? selected[0]?.correspondentBankName1
              : initialValues.correspondentBankName1,
          correspondentBankName2:
            selected[0]?.correspondentBankName2 !== null
              ? selected[0]?.correspondentBankName2
              : initialValues.correspondentBankName2,
          correspondentSwiftCode1:
            selected[0]?.correspondentSwiftCode1 !== null
              ? selected[0]?.correspondentSwiftCode1
              : initialValues.correspondentSwiftCode1,
          correspondentSwiftCode2:
            selected[0]?.correspondentSwiftCode2 !== null
              ? selected[0]?.correspondentSwiftCode2
              : initialValues.correspondentSwiftCode2,
          corrAccount:
            selected[0]?.corrAccount !== null
              ? selected[0]?.corrAccount
              : initialValues.corrAccount,
          kpp: selected[0]?.kpp !== null ? selected[0]?.kpp : initialValues.kpp,
        }),
      );
    },
    [
      beneficiaryList,
      initialValues.beneficiaryAccount,
      initialValues.beneficiaryAddress,
      initialValues.beneficiaryBankAddress,
      initialValues.beneficiaryBankName,
      initialValues.beneficiaryName,
      initialValues.beneficiarySwiftCode,
      initialValues.beneficiaryTaxId,
      initialValues.corrAccount,
      initialValues.correspondentBankName1,
      initialValues.correspondentBankName2,
      initialValues.correspondentSwiftCode1,
      initialValues.correspondentSwiftCode2,
      initialValues.kpp,
    ],
  );

  const onNacCodeSelect = useCallback(
    (value) => {
      const currentText = initialValues.details.split("D-LE-NACE:")[1].split(":")[1].trim();
      setNacCode(false);
      setInitialValues((prev) =>
        update(prev, {
          naceCode: value,
          details: `D-LE-NACE:${value}/TXT/:${currentText}`,
        }),
      );
    },
    [setInitialValues, initialValues.details],
  );

  useEffect(() => {
    if (initialValues?.applicantAccount?.value && initialValues.isIdn) {
      TransferApi.getTransferIdnInfo(initialValues?.applicantAccount?.value)
        .then((response) => {
          setInitialValues((prev) =>
            update(prev, {
              tinOfBank: response.bankIdn,
              tinOfCustomer: response.senderIdn,
            }),
          );
        })
        .catch(showError);
    }
  }, [initialValues?.applicantAccount?.value, TransferApi, initialValues?.isIdn]);

  useEffect(() => {
    if (documentValues.length > 0) {
      const operator =
        isRole === RoleType.Corporate
          ? documentValues[0]?.senderPhoneNumber?.substring(3, 5)
          : userPhone?.substring(3, 5);

      const hasOperator = operatorCodes.filter((op) => op.code === Number(operator));

      const invnDateStringFormat =
        documentValues[0]?.fcyType !== "services"
          ? documentValues[0]?.detailsOfPayment?.substring(
              documentValues[0]?.detailsOfPayment.indexOf("/INV/") + 5,
              documentValues[0]?.detailsOfPayment.indexOf("/INVN:"),
            )
          : "";
      const invnDateToDateFormat =
        "20" +
        invnDateStringFormat.substring(4) +
        "-" +
        invnDateStringFormat.substring(2, 4) +
        "-" +
        invnDateStringFormat.substring(0, 2);

      const dataForInitial = {
        ...documentValues[0],
        amount: documentValues[0]?.amount && floatFormat(addZeros(documentValues[0]?.amount)),
        applicantAccount: {
          label: documentValues[0]?.applicantAccount,
          value: documentValues[0]?.applicantAccount,
        },
        applicantCurrentBalance: "",
        applicantTaxId: documentValues[0]?.taxId,
        beneficiaryAccount: documentValues[0]?.beneficiaryAccount,
        commissionAccount: {
          label: documentValues[0]?.commissionAccount,
          value: documentValues[0]?.commissionAccount,
        },
        charges: documentValues[0]?.chargesForTransfer,
        corrAccount: documentValues[0]?.correspondentAccount,
        commissionBalance: documentValues[0]?.commissionAccountBalance,
        details: documentValues[0]?.detailsOfPayment,
        hsCode: documentValues[0]?.hsCode,
        servicesType: documentValues[0]?.fcyType,
        isIdn: documentValues[0]?.idn ? true : false,
        tinOfBank: documentValues[0]?.idn ? getIdnInfo(documentValues[0]?.idn).tinOfBank : "",
        tinOfCustomer: documentValues[0]?.idn
          ? getIdnInfo(documentValues[0]?.idn).tinOfCustomer
          : "",
        contractNumber: documentValues[0]?.idn
          ? getIdnInfo(documentValues[0]?.idn).contractNumber
          : "",
        currency:
          isRole === RoleType.Corporate
            ? documentValues[0]?.currency
            : documentValues[0]?.currency,
        id: documentValues[0]?.documentId,
        senderPhoneNumber:
          isRole === RoleType.Corporate
            ? documentValues[0]?.senderPhoneNumber &&
              documentValues[0]?.senderPhoneNumber.substring(0, 3) === "998"
              ? formatPhoneNumber(documentValues[0]?.senderPhoneNumber)
              : "+998"
            : formatPhoneNumber(userPhone),
        phoneOperator:
          hasOperator.length > 0
            ? hasOperator[0].operator
            : translate(
                "TRANSFERS_TRANSFERS_FCY_TRANSFER_FORM_PHONE_OPERATOR_INVALID_CODE_LABEL_TITLE",
              ),
        transactionDate:
          documentValues[0]?.transactionDate &&
          DateTime.fromFormat(documentValues[0]?.transactionDate, "dd.MM.yyyy").toJSDate(),
        invNumber:
          documentValues[0]?.fcyType !== "services"
            ? documentValues[0]?.detailsOfPayment?.substring(
                documentValues[0]?.detailsOfPayment.indexOf("/INVN:") + 6,
                documentValues[0]?.detailsOfPayment.indexOf("//PRDN:"),
              )
            : "",
        invnDate:
          documentValues[0]?.fcyType !== "services" ? new Date(invnDateToDateFormat) : new Date(),
      };
      setInitialValues((prev) => update(prev, { ...dataForInitial }));
    }
  }, [documentValues, isRole, operatorCodes, translate, userPhone]);

  const beneficiaryListAfterSearch = useMemo(
    () => beneficiaryListSearch(beneficiaryList, searchTaxId),

    [beneficiaryList, beneficiaryListSearch, searchTaxId],
  );

  const [favouritesNameModal, setFavouritesNameModal] = useState(false);
  const [favouritesName, setFavouritesName] = useState<string>("");

  return (
    <>
      <TabPage
        className="px-7"
        headerComponent={<TransfersFcyHeader backButton={backButton} />}
        footerComponent={
          <div className="d-flex flex-shrink-1 flex-grow-1 align-items-lg-center justify-content-between flex-lg-row flex-column">
            {viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
              <Button
                className="px-6 mt-lg-0 mt-2"
                color={ButtonColor.Orange}
                labelCode="TRANSFERS_TRANSFERS_SUBMIT_BUTTON_TITLE"
                onClick={() => {
                  if (lcyFormRef.current?.handleSubmit) {
                    lcyFormRef.current?.handleSubmit();
                  }
                }}
                disabled={loading}
                type="submit"
              />
            ) : (
              <div />
            )}

            <FileControls
              isMinusTransferLcyType={false}
              isUnselectType={isUnselectType}
              onFavoritesClick={openFavourites}
              onSaveToFavoriteClick={() => {
                setFavouritesNameModal(true);
              }}
              onPrintClick={() => {
                printForm({
                  printable: "TransferFcyPrint",
                  type: "html",
                  targetStyles: ["*"],
                });
              }}
              onSaveToPdfClick={() => {
                if (initialValues.transferNumber) {
                  const data = {
                    ...initialValues,
                    chargesForTransfer: initialValues.charges,
                    details: `${initialValues?.voCode && `('${initialValues?.voCode}')`} ${
                      initialValues.details
                    }`,
                    transactionDate: formatDateToBody(initialValues.transactionDate),
                    // @ts-ignore
                    applicantAccount: initialValues?.applicantAccount?.label,
                    // @ts-ignore
                    commissionAccount: initialValues?.commissionAccount?.label,
                    currency: initialValues.currency?.label,
                  };
                  PdfReportApi.createFcyReport("Fcy-report", [data]);
                }
              }}
              onSaveToExcelClick={() => {
                if (initialValues.transferNumber) {
                  const data = {
                    ...initialValues,
                    chargesForTransfer: initialValues.charges,
                    detailsOfPayment: `${initialValues?.voCode && `('${initialValues?.voCode}')`} ${
                      initialValues.details
                    }`,
                    transactionDate: formatDateToBody(initialValues.transactionDate),
                    // @ts-ignore
                    applicantAccount: initialValues?.applicantAccount?.label,
                    taxId: initialValues?.applicantTaxId,
                    // @ts-ignore
                    commissionAccount: initialValues?.commissionAccount?.label,
                    currencyCode: TransferCurrencyPicker({ currency: initialValues.currency }),
                  };
                  createTransferFCYExcelFromForm(data, {
                    translate,
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).then((workbook) => saveExcelToFile(workbook, "FCY Transfers"));
                }
              }}
              className={cx({ "mr-5": isUnselectType })}
            />
          </div>
        }
      >
        <FcyTransferForm
          onChangeIsSwiftCode={onChangeIsSwiftCode} 
          isSwiftCode={isSwiftCode}
          swiftCodes={swiftCodes}
        

          onChangeIsSwiftCodeCorrespondent={onChangeIsSwiftCodeCorrespondent} 
          isSwiftCodeCorrespondent={isSwiftCodeCorrespondent}
          swiftCodesCorrespondent={swiftCodesCorrespondent}

          checkCurrency={checkCurrency}

          debits={debitAccount[0]}
          onSubmit={agreeModalOpen}
          formikRef={lcyFormRefHandler}
          initialValues={initialValues}
          getBeneficiaryName={() => setBeneficiary(true)}
          getNacCode={() => setNacCode(true)}
          onChangeDebitsAccount={onChangeDebitsAccount}
          onChangeAmount={onChangeAmount}
          onChangeIsIdn={onChangeIsIdn}
          onChangeDocumentDate={onChangeDocumentDate}
          onChangeTransferNumber={onChangeTransferNumber}
          onChangeCorrespondentTaxNumber={onChangeCorrespondentTaxNumber}
          onChangeCorrespondentName={onChangeCorrespondentName}
          onChangeCorrespondentAccount={onChangeCorrespondentAccount}
          onChangeCorrespondentAddress={onChangeCorrespondentAddress}
          onChangeSenderPhoneNumber={onChangeSenderPhoneNumber}
          onChangeBankSwift1={onChangeBankSwift1}
          onChangeBankSwift2={onChangeBankSwift2}
          onChangeCorrAccount={onChangeCorrAccount}
          onChangeDetails={onChangeDetails}
          onChangeKPP={onChangeKPP}
          onChangeRemarks={onChangeRemarks}
          onChangeBeneficiaryBankSwift={onChangeBeneficiaryBankSwift}
          commissionAccount={commissionAccount[0]}
          onChangeCommissionAccount={onChangeCommissionAccount}
          onChangeCurrency={onChangeCurrency}
          onChangeCharges={onChangeCharges}
          onChangeServicesType={onChangeServicesType}
          onChangeVoCode={onChangeVoCode}
          onChangeBeneficiaryBankName={onChangeBeneficiaryBankName}
          onChangeBeneficiaryBankAddress={onChangeBeneficiaryBankAddress}
          onChangeCorrespondentName2={onChangeCorrespondentName2}
          onChangeCorrespondentName1={onChangeCorrespondentName1}
          onCheckSwiftCode={onCheckSwiftCode}
          onCheckCorrespondent2SwiftCode={onCheckCorrespondent2SwiftCode}
          onCheckCorrespondent1SwiftCode={onCheckCorrespondent1SwiftCode}
          onChangeContractNumber={onChangeContractNumber}
          onBlurCurrency={onBlurCurrency}
          onChangeHsCode={onChangeHsCode}
          onChangeNacCode={onChangeNacCode}
          onBlurHsCode={onBlurHsCode}
          onBlurINVNDate={onBlurINVNDate}
          onBlurINVnumber={onBlurINVnumber}
          onChangeINVNDate={onChangeINVNDate}
          onChangeINVnumber={onChangeINVnumber}
        />
        <Modal
          ariaHideApp={false}
          searchInput={true}
          onClose={() => setBeneficiary(false)}
          width={900}
          show={beneficiary}
          title={"TRANSFERS_CORRESPONDENTS_TITLE"}
          setSearch={(value) => setSearchTaxId(value)}
        >
          <TabPage>
            <CorrespondentsFCYTable
              data={beneficiaryListAfterSearch}
              loading={beneficiaryAccounts.loading}
              onCorrespondentSelect={onCorrespondentSelect}
            />
          </TabPage>
        </Modal>
        <Modal
          ariaHideApp={false}
          searchInput={true}
          onClose={() => setNacCode(false)}
          width={600}
          show={nacCode}
          title={"TRANSFERS_TRANSFERS_FCY_TRANSFER_MODAL_NACCODE_TITLE"}
          setSearch={(value) => setNaceCodeSearch(value)}
        >
          <FCYNacCodeTable
            data={nacCodesList}
            loading={nacCodes.loading}
            onNacCodeSelect={onNacCodeSelect}
          />
        </Modal>
        <Modal
          ariaHideApp={false}
          searchInput={true}
          onClose={() => setFcyFavourites(false)}
          width={900}
          show={fcyFavourites}
          title={"TRANSFERS_FAVOURITES_MODAL_TAB_TITLE"}
        >
          <TabPage>
            <FcyFavouritesModalTab data={favorites} getFcyDetails={getFcyFavourites} />
          </TabPage>
        </Modal>
        <Modal
          ariaHideApp={false}
          show={agreeFirst}
          onClose={() => setAgreeFirst(false)}
          title="MODAL_CONFIRMATION_TITLE"
          width={500}
        >
          <div className="d-flex justify-content-center">
            <span className="my-4 mr-8">
              {agreeType
                ? translate("MODAL_INFO_TRANSFER_SECOND")
                : translate("MODAL_INFO_TRANSFER_FIRST")}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <Button className="bg-danger py-2 px-5 w-50 mr-8" onClick={() => setAgreeFirst(false)}>
              {translate("MODAL_CONFIRMATION_NO")}
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="bg-secondary py-2 px-5 w-50 mt-3 mr-8"
              onClick={() => {
                submitHandler(initialValues);
                setAgreeFirst(false);
              }}
            >
              {translate("MODAL_CONFIRMATION_YES")}
            </Button>
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          show={favouritesNameModal}
          onClose={() => setFavouritesNameModal(false)}
          title={"TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL"}
        >
          <div className="py-2">
            <Input
              type="text"
              onChange={(e) => {
                setFavouritesName(e.target.value);
              }}
            />
            <div className="mt-3 d-flex justify-content-center ">
              <Button onClick={() => setFavouritesNameModal(false)} className="bg-danger  m-1">
                {translate("CONVERSION_FCY_TO_UZS_FCY_TO_CANCEL_BUTTON")}
              </Button>
              <Button
                onClick={() => {
                  if (favouritesName.trim()) {
                    TransferApi.createFcyFavorites({
                      json: {
                        ...initialValues,
                        docType: "FCY Transfer",
                        // @ts-ignore
                        clientAccount: initialValues.applicantAccount.label,
                        // @ts-ignore
                        commissionAccount: initialValues.commissionAccount.label,
                        corrAccount: initialValues.corrAccount ? initialValues.corrAccount : "",
                        correspondentAccount: initialValues.beneficiaryAccount,
                        correspondentName: initialValues.beneficiaryName,
                        transactionDate: formatDateToBody(initialValues.transactionDate),
                        favoriteName: favouritesName,
                        fcyType: initialValues.servicesType,
                        idn:
                          initialValues.tinOfBank +
                          initialValues.tinOfCustomer +
                          initialValues.contractNumber,
                      },
                      login,
                    })
                      .then((r) => {
                        setFavouritesNameModal(false);
                        if (r !== undefined) {
                          NotificationManager.info(
                            translate("CONVERSION_FAVORITES_INFO_SAVED"),
                            translate("CONVERSION_FAVORITES_ERROR_TITLE"),
                            7000,
                          );
                        }
                      })
                      .catch(showError);
                  } else {
                    NotificationManager.error(
                      `${translate("TRANSFERS_FAVORITES_DOCUMENT_NAME_INPUT_LABEL")} ${translate(
                        "FORMS_REQUIRED_FIELD_ERROR_TITLE",
                      )}`,
                    );
                  }
                }}
                className=" m-1"
              >
                {translate("CONVERSION_FCY_TO_UZS_FCY_TO_SAVE_BUTTON")}
              </Button>
            </div>
          </div>
        </Modal>
        <div style={{ display: "none" }}>
          <TransferFcyPrint initialValue={initialValues} />
        </div>
      </TabPage>
    </>
  );
}
