export function SaveActiveMobileMenuTab(type: string, string: string, removeString?: string) {
  const indexs: number[] = [],
    _char = "/";
  let i = -1,
    new_str = "";
  while ((i = string.indexOf(_char, i + 1)) >= 0) {
    indexs.push(i);
  }
  if (type === "active_app") {
    new_str = string.substring(indexs[0], indexs[2]);
  } else if (type === "active_app_child") {
    new_str = string.substring(indexs[2] + 1, indexs[3]);
  } else if (type === "active_child_child" && removeString) {
    new_str = string.substring(string.indexOf(removeString)).replace(removeString, "");
  }
  return new_str;
}
