import React, { useMemo } from "react";

import { TabPage } from "../tabs/TabPage";
import { Paginator } from "../ui/Paginator";
import { AccountsTable } from "./AccountsTable";
import { AccountsFilterForm } from "./AccountsFilterForm";
import { AccountsFilter } from "../../filters/AccountsFilter";
import { AccountFilterFormProps, AccountProps } from "../../api/account/AccountDTO";
import { AccountsIndividualTable } from "./AccountsIndividualTable";

interface Props {
  readonly loading: boolean;
  readonly list: AccountProps[];
  readonly filter: AccountsFilter;
  readonly onChangeFilter: (values: AccountFilterFormProps) => void;
  readonly onChangeStatementAccount: ({
    accountNumber,
    accountName,
    lastTransactionDate,
    currency,
    accountType,
  }) => void;
  readonly individual?: boolean;
}

export function AccountsTab({
  list,
  loading,
  filter,
  onChangeFilter,
  onChangeStatementAccount,
  individual = false,
}: Props) {
  const filterInitialValues = useMemo(() => filter.getAccountFilterForm(), [filter]);

  return (
    <TabPage
      className="px-4"
      footerComponent={
        <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-end">
          <Paginator filter={filter} />
        </div>
      }
      headerComponent={
        <AccountsFilterForm initialValues={filterInitialValues} onSubmit={onChangeFilter} />
      }
    >
      {individual ? (
        <AccountsIndividualTable
          data={list}
          loading={loading}
          onChangeStatementAccount={onChangeStatementAccount}
        />
      ) : (
        <AccountsTable
          data={list}
          loading={loading}
          onChangeStatementAccount={onChangeStatementAccount}
        />
      )}
    </TabPage>
  );
}
