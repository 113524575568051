import React from "react";

import { SvgProps } from "../../api/dto/AppDTO";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgProps {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function PdfIcon({ size = 16, color = ColorPalette.Primary, ...svgProps }: Props) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 13.922 16.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        transform="translate(-85.164 -263.234)"
        d="M88.467,272H88v1h.467a.5.5,0,0,0,0-1Zm0,0"
      />
      <path fill={color} transform="translate(-33.872 -421.949)" d="M35,436H46.666v2.449H35Zm0,0" />
      <path
        fill={color}
        transform="translate(-33.872)"
        d="M46.666,3.867H42.8V0H35V5.8H46.666Zm0,0"
      />
      <path fill={color} transform="translate(-297.106 -20.531)" d="M307,21.215v2.217h2.217Zm0,0" />
      <path
        fill={color}
        transform="translate(-201.297 -263.234)"
        d="M208,272v2.449a.968.968,0,0,0,.967-.967v-.516A.968.968,0,0,0,208,272Zm0,0"
      />
      <path
        fill={color}
        transform="translate(0 -203.232)"
        d="M0,210v6.316H13.922V210Zm3.3,3.964H2.836v1.45H1.869v-4.383H3.3a1.466,1.466,0,0,1,0,2.933Zm5.333-.483A1.936,1.936,0,0,1,6.7,215.414H5.736v-4.383H6.7a1.936,1.936,0,0,1,1.934,1.934ZM12.085,212h-1.45v1.112H11.6v.967h-.967v1.337H9.668v-4.383h2.417Zm0,0"
      />
    </svg>
  );
}
