import React, { MutableRefObject } from "react";
import { GroupBox } from "../ui/GroupBox";
import { Input } from "../ui/Input";
import { Form, Formik } from "formik";
import { noop } from "lodash";
import { FcyToCreate } from "../../api/transfer/TransferDTO";
import KdbLogo from "./assets/kdb.png";
import { Checkbox } from "../ui/Checkbox";
import "./assets/transfer-fcy-form.scss";
import { DatePickerField } from "../form/DatePickerField";

interface Props {
  readonly ref?: MutableRefObject<any>;
  readonly initialValue: FcyToCreate;
}
export function TransferFcyPrint({ ref, initialValue }: Props) {
  return (
    <Formik initialValues={initialValue as FcyToCreate} onSubmit={noop}>
      {({ handleSubmit }) => (
        <Form
          className="form-fcy mt-0 d-flex"
          id="TransferFcyPrint"
          ref={ref}
          onSubmit={handleSubmit}
        >
          <div className="row ml-5">
            <div className="form group row flex-xl-nowrap">
              <div className="form-group col mb-0 logo-form-fcy">
                <img src={KdbLogo} alt="" className="mb-0" />
                <div className="form-group img-text">
                  <p className="mb-0 mt-0">
                    Приложение 2 к публичной оферте на оказание услуг по ковертации иностранной
                    валюты одного вида в иностранную валюту другого вида
                  </p>
                </div>
              </div>
              <div className="row mb-0" id="box-header-fcy-form">
                <GroupBox className="row box-number">
                  <h1 className="ml-8">Transfer application Number №</h1>
                  <div className="form-group row ml-9">
                    <h1>Заявление на перевод №</h1>
                    <Input
                      width={80}
                      height={20}
                      className="mt-2 ml-1"
                      value={initialValue.transferNumber}
                    />
                  </div>
                </GroupBox>
                <span className="form-group row mb-1">* should be typewritten in english</span>
                <div className="row">
                  <Input placeholder="Date" width={100} height={20} />
                  <DatePickerField name="date" className="date-field-fcy-form" width={250} />
                </div>
              </div>
            </div>
            <div className="form-group row mt-0 mb-0">
              <div>
                <div className="form-group mb-0 ml-1  ">
                  <Input
                    width={350}
                    height={10}
                    className="input_fcy_header"
                    placeholder="50: applicant / заявитель"
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">name / наименование</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.applicantName} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">tax id / инн</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.applicantTaxId} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">address / адрес</p>
                  </GroupBox>
                  <Input width={230} height={20} value={initialValue.applicantAddress} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">debit account</p>
                  </GroupBox>
                  <Input width={230} height={20} value={initialValue.applicantAccount} />
                </div>
              </div>
              <div className="ml-2">
                <div className="form-group mb-0 ml-1">
                  <Input
                    width={350}
                    height={10}
                    className="input_fcy_header"
                    value="59: beneficary / получатель"
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">name / наименование</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.beneficiaryName} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">id / инн (if any / если имеется</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.beneficiaryTaxId} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">address / адрес</p>
                  </GroupBox>
                  <Input width={230} height={20} value={initialValue.beneficiaryAddress} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">credit account</p>
                  </GroupBox>
                  <Input width={230} height={20} value={initialValue.beneficiaryAccount} />
                </div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <div>
                <div className="form-group mb-0 ml-1">
                  <Input
                    width={350}
                    height={10}
                    className="input_fcy_header"
                    placeholder="52: ordering bank / банк отправителя"
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">name / наименование</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.bankName} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">address / адрес</p>
                  </GroupBox>
                  <Input
                    width={230}
                    height={20}
                    className="input_fcy"
                    value={initialValue.bankAddress}
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={20} placeholder="swift code" />
                  <Input
                    width={230}
                    height={20}
                    className="input_fcy"
                    value={initialValue.bankSwift}
                  />
                </div>
              </div>
              <div className="ml-2 mb-0">
                <div className="form-group mb-0 ml-1">
                  <Input
                    width={350}
                    height={10}
                    className="input_fcy_header"
                    placeholder="57: beneficiary's bank/ банк получателся"
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x40 mb-0">
                    <p className="fs-2">name / наименование</p>
                  </GroupBox>
                  <Input width={230} height={40} value={initialValue.beneficiaryBankName} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <GroupBox className="box-100x20 mb-0">
                    <p className="fs-2">address / адрес</p>
                  </GroupBox>
                  <Input width={230} height={20} value={initialValue.beneficiaryBankAddress} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={20} placeholder="swift code" />
                  <Input width={230} height={20} value={initialValue.beneficiarySwiftCode} />
                </div>
              </div>
            </div>
            <div className="form-group row mb-0 mt-0">
              <div className="form-group row mb-0 box-group-left">
                <div className="form-group row flex-xl-nowrap mb-0 currency-table">
                  <div className="form-group mb-0">
                    <Input width={175} height={25} placeholder="32: currency / валюта" />
                    <Input width={175} height={25} value={initialValue.currency} />
                  </div>
                  <div>
                    <Input width={175} height={25} placeholder="amount in digit / сумма цифрами" />
                    <Input width={175} height={25} value={initialValue.amount} />
                  </div>
                </div>
                <div className="form-group row mb-0 currency-table">
                  <Input width={350} height={25} placeholder="amount in words / сумма с прописью" />
                  <Input width={350} height={25} value={initialValue.amountInWord} />
                </div>
                <div className="form-group row mt-0 charges-table mb-0">
                  <div className="form-group row mb-0">
                    <Input
                      width={350}
                      height={25}
                      placeholder="71: charges for transfer / коммисия за перевод"
                    />
                  </div>
                  <div className="form-group row  flex-nowrap mb-0">
                    <p className="terms-fcy">
                      All charges are from beneficiary / our /все комиссии за счет получателя
                    </p>
                    <Checkbox className="ml-4" />
                  </div>
                  <div className="form-group row flex-nowrap mb-0">
                    <p className="terms-fcy">
                      All charges are from remitter / our /все комиссии за счет отправителя
                    </p>
                    <Checkbox className="ml-4" />
                  </div>
                  <div className="form-group row flex-nowrap mb-0">
                    <p className="terms-fcy">
                      Charges or JSC "kbd bank uzbekistan" are from remitter / sha / комиссии AO
                      "кдб банк узбекистан" за счет отправителя
                    </p>
                    <Checkbox className="ml-4" />
                  </div>
                  <div className="form-group row flex-nowrap mb-0">
                    <p className="terms-fcy">
                      Full no deduct/fnd/все комисии за счет отправителя(jpmorgan chase bank)
                    </p>
                    <Checkbox className="ml-4" />
                  </div>
                </div>
              </div>
              <div className="mt-0 mb-0 box-group-right">
                <div className="form-group row mb-0">
                  <GroupBox className="box-left-350x40">
                    <p>
                      <span className="paragraph"></span>53: correspondent bank of beneficiary's bank / банк-корреспондент банка получаеля
                    </p>
                  </GroupBox>
                  <Input width={350} height={70} value={initialValue.correspondentBankName1} />
                </div>
                <div className="form-group row mb-0">
                  <Input width={120} height={20} placeholder="swift code" />
                  <Input width={230} height={20} value={initialValue.correspondentSwiftCode1} />
                </div>
                {/* <div className="row">
                  <Input width={350} height={70} value={initialValue.correspondentBankName2} />
                </div> */}
                {/* <div className="form-group row mb-0">
                  <Input width={120} height={20} placeholder="swift code" />
                  <Input width={230} height={20} value={initialValue.correspondentSwiftCode2} />
                </div> */}
                <div className="form-group row mb-0">
                  <Input width={110} height={20} placeholder="place / место" />
                  <Input width={180} height={20} placeholder="Tashkent" />
                </div>
              </div>
            </div>
            <div className="mb-0 mt-0">
              <div className="form-group row mb-0 mt-0">
                <Input
                  width={320}
                  height={10}
                  placeholder="70: details of payment / детали платежа"
                />
                <Input width={400} height={10} />
              </div>
              <div className="form-group row mb-0">
                <Input width={720} height={25} value={initialValue.details} />
              </div>
            </div>
            <div>
              <div className="form-group row  mb-0">
                <Input width={320} height={10} placeholder="remarks / замечания" />
                <Input width={400} height={10} />
              </div>
              <div className="form-group row mb-1">
                <Input width={720} height={25} value={initialValue.remarks} />
              </div>
            </div>
            <div className="form-group row mr-4">
              <GroupBox className="text-offer-fcy-form-box">
                <p className="text-offer-fcy-form fs-2">
                  Clause on joining to the agreement - Public Offer / Оговорка о присоединении к
                  договору - Публичной оферте: Настоящим заявлением подтверждаю, что ознакомлен(а) с
                  условиями Публичной оферты на оказание услуг по конвертации иностранной валюты
                  одного вида в иностранную валюту другого вида, соглашаюсь с ними и присоединяюсь к
                  ним, претензий в дальнейшем к Банку иметь не буду и даю согласие на безакцептное
                  списание необходимых сумм за оказанные услуги в соответствии с Тарифами Банка /
                  Herewith by this application I confirm that I have read the terms of Public Offer
                  for rendering of service on conversion of foreign currency of one type into
                  foreign currency of another type, agree with and join them, I will not have any
                  claims claims against the Bank in the future and give my consent to the direct
                  write-off necessary amounts for the services rendered in accordance with the
                  Bank’s Tariffs.
                </p>
              </GroupBox>
            </div>
            <div className="form-group row mb-0">
              <div className="form-group row ml-0 mb-0">
                <Input
                  width={500}
                  height={10}
                  placeholder="please debit our account# / просим списать с нашего расчетного счета №"
                />
                <Input width={220} height={10} value={initialValue.commissionAccount} />
              </div>
              <div className="form-group row ml-0">
                <Input
                  width={720}
                  height={10}
                  placeholder="for transfer charges as per applicable tariffs / комиссионное вознограждение за перевод соглосно действующим тарифам "
                />
              </div>
            </div>
            <div className="form-group row">
              <Input
                width={233}
                height={20}
                placeholder="first signature / первая подпись/ руководителя"
              />
              <Input
                width={233}
                height={20}
                placeholder="second signature (if applicable) / chief accountant  вторая подпись (если предусмотрена) / главный бухгалтер"
              />
              <Input
                width={233}
                height={20}
                placeholder="stamp (if required) / штамп (по требованию)"
              />
            </div>
            <div className="form-group row mb-0">
              <div id="initials">
                <span>Ф.И.О/</span>
                <input className="border-right-0 border-left-0 border-top-0 border-light ml-5" />
              </div>
              <div className="ml-5" id="initials">
                <span>Ф.И.О/</span>
                <input className="border-right-0 border-left-0 border-top-0 border-light ml-5" />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div id="initials">
                <span>NAME</span>
                <input className="border-right-0 border-left-0 border-top-0 border-light ml-5" />
              </div>
              <div className="ml-5" id="initials">
                <span>NAME</span>
                <input className="border-right-0 border-left-0 border-top-0 border-light ml-5" />
              </div>
            </div>
            <div className="form-group mt-4 mb-0 mt-0">
              <span className="bank-line justify-content-center">
                FILLED BY THE BANK / ЗАПОЛНЯЕТСЯ БАНКОМ
              </span>
              <div className="form-group row mb-0">
                <div className="form-group row ml-1 mb-0">
                  <Input
                    width={120}
                    height={30}
                    placeholder="csd(authentification and verification)"
                  />
                  <Input width={120} height={30} placeholder="aad(signature/stamp verified)" />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={30} placeholder="ccd(checked)" />
                  <Input width={120} height={30} placeholder="manegement(approval)" />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={30} placeholder="payments(transition input)" />
                  <Input
                    width={120}
                    height={30}
                    placeholder="authorization and swift(swift sending)"
                  />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={20} />
                  <Input width={120} height={20} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={20} />
                  <Input width={120} height={20} />
                </div>
                <div className="form-group row ml-1 mb-0">
                  <Input width={120} height={20} />
                  <Input width={120} height={20} />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
