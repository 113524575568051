import React, { useMemo } from "react";
import { useI18n } from "../../i18n/I18nContext";
import { SalaryCardHolders } from "../../api/salary/SalaryDTO";
import { Column } from "react-table";
import { TabPage } from "../tabs/TabPage";
import { Dict } from "../../api/dto/AppDTO";
import { SalaryModalTable } from "../table/SalaryModalTable";

interface Props {
  readonly data: SalaryCardHolders[];
  readonly loading: boolean;
  readonly onSelect?: (ids: Dict<boolean>) => void;
}

export function SalaryListModal({ loading, data, onSelect }: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<SalaryCardHolders>[] = [
      {
        width: 95,
        accessor: "accountNumber",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE",
        ),
      },
      {
        width: 300,
        accessor: "employerName",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_FULL_NAME_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "cardId",
        Header: translate(
          "TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_ACCOUNT_SYSTEM_COLUMN_TITLE",
        ),
      },
      {
        width: 50,
        accessor: "openDate",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_OPEN_DATE_COLUMN_TITLE"),
      },
      {
        width: 50,
        accessor: "status",
        Header: translate("TRANSFERS_SALARY_LIST_OF_CARD_HOLDERS_TABLE_STATUS_COLUMN_TITLE"),
      },
    ];
    return list;
  }, [translate]);

  return (
    <TabPage>
      <SalaryModalTable
        data={data}
        columns={columns}
        loading={loading}
        showEmptyRow={true}
        withCheckbox={true}
        onSelect={onSelect}
      />
    </TabPage>
  );
}
