import cx from "classnames";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { DateTime } from "luxon";

import { TabPage } from "../tabs/TabPage";
import { Dict } from "../../api/dto/AppDTO";
import { Paginator } from "../ui/Paginator";
import { useI18n } from "../../i18n/I18nContext";
import { FileControls } from "../ui/FileControls";
import { Button, ButtonColor } from "../ui/Button";
import { TransfersTable } from "./TransfersTable";
import { addZeros, removeGaps, floatFormat } from "../../utils/FloatUtils";
import { numberToWords } from "../../utils/FormatUtils";
import { formatDateToBody, formatDateToUrl } from "../../utils/DateUtils";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { showError } from "../../utils/NotificationUtils";
import { TransfersLCYTabHeader } from "./TransfersLCYTabHeader";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { TransferLCYFormsWrapper } from "./TransferLCYFormsWrapper";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { TransferLcyType, TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { LcyTransferFormProps } from "../../api/transfer/TransferDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { FileAccept, useSelectFile } from "../../hooks/useSelectFile";
import { readCSV, readTxt, readXlsx } from "../../utils/FileUtils";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import {
  createTransferLCYBudgetExcel,
  createTransferLCYExcel,
  createTransferLCYRevenueExcel,
  printTransferLCYTable,
} from "../../helpers/TransfersHelpers";
import { TransferLcyBudgetTablePrint } from "./TransferLcyBudgetTablePrint";
import { TransferLcyRevenueTablePrint } from "./TransferLcyRevenueTablePrint";
import { TransferLcyStandardTablePrint } from "./TransferLcyStandartTablePrint";
import { useResource } from "../../hooks/useResource";
import { useReferenceBookContext } from "../../api/reference-book/ReferenceBookContext";
import { CheckOtpModal } from "../../components/salary/CheckOtpModal";
import {
  BudgetAccountSearchType,
  PaymentCodeBudgetFlag,
  PaymentType,
} from "../../api/reference-book/ReferenceBookDTO";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { Modal } from "../ui/Modal";
import { ImportLogsModalTab } from "./ImportLogsModalTab";
import { appLanguageSelector } from "../../reducers/appReducer";
import { TransferCurrencyPicker } from "./TransferCurrencyPicker";
import { ImportAnorOrCBU } from "./ImportAnorOrCBU";
import { PrintPortal } from "../ui/PrintPortal";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { SelectPicker } from "../ui/SelectPicker";
import { userLoginSelector, userSelector } from "../../reducers/userReducer";
import { DeleteIcon } from "../icons/DeleteIcon";
import { ColorPalette } from "../../theme/ColorPalette";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";
import { beginDaySelector } from "../../reducers/totalReducer";
import { betweenDays } from "../../utils/DaysBeetwenUtils";
import { FormatPaymentCode } from "../../utils/FormatImportPaymentCode";

interface Props {
  readonly filter: TransfersFilter;

  readonly loading: boolean;
  readonly onLcySubmit: (values: LcyTransferFormProps) => void;
}

export function TransfersLCYTab({ filter, onLcySubmit, loading }: Props) {
  const { translate } = useI18n();
  const { ReferenceBookApi } = useReferenceBookContext();
  const { TransferApi } = useTransferContext();
  const { AccountApi } = useAccountContext();
  const language = useShallowEqualSelector(appLanguageSelector);
  const user = useShallowEqualSelector(userSelector);

  // for clearing day open or not

  const beginDay: any = useShallowEqualSelector(beginDaySelector);

  const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;

  const locationHelpers = useLocationHelpers();
  const dispatch = useDispatch();

  const [values, setValues] = useState([]);
  const [showCreators, setShowCreators] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  const [importLogs] = useState([{ transferNumber: "", comment: "", status: "" }]);
  const [importModal, setImportModal] = useState(false);
  const [importModalTable, setImportModalTable] = useState(true);
  const [importExcelAnorOrCBU, setImportExcelAnorOrCBU] = useState(false);
  const [importCSVAnorOrCBU, setImportCSVAnorOrCBU] = useState(false);
  const [importTxt, setImportTxt] = useState(false);
  const [txtCharset, setTxtCharset] = useState("");
  // const [job, setJob] = useState("");
  const [documentsId, setDocumentsId] = useState([]);
  const [checkOtp, setCheckOtp] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [appTypes, setAppTypes] = useState("");

  const { PdfReportApi } = usePdfReportContext();

  const documentsLCY = useResource(
    () => TransferApi.getAllLCYDocuments({ ...filter.getLcyFilterForTransferDoc() }),
    [filter],
  );
  useResourceHandler(documentsLCY, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPageCount?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRowCount?.toString() }));
    },
  });

  const transferType = useMemo(() => filter.getTransferType(), [filter]);
  const list = useMemo(() => documentsLCY.data || [], [documentsLCY.data]);
  const isUnselectType = useMemo(() => transferType === TransferType.Unselect, [transferType]);

  // new one start

  const login = useShallowEqualSelector(userLoginSelector);
  const [checkIfUserCreater, setCheckIfUserCreater] = useState<boolean>(false);
  const [checkIfDocsLess100, setCheckIfDocsLess100] = useState<boolean>(false);
  const [checkPayrollRender, setCheckPayrollRender] = useState("{}");

  const setTableDatas = useCallback(() => {
    const tempDocumentsId: any = [];
    list.map((n, index) => {
      if (selectedIds[index] && n) {
        tempDocumentsId.push(n);
      }
    });

    setAppTypes(ApprovalTypes.LCYTransfer);
    setDocumentsId(tempDocumentsId);
    setCheckPayrollRender(JSON.stringify(selectedIds));
    const tempChecks = tempDocumentsId.map(
      (r) => r.createUser.includes(login) && r.state === "101",
    );
    setCheckIfUserCreater(tempDocumentsId.length > 0 && tempChecks?.includes(false));
    setCheckIfDocsLess100(tempDocumentsId.length <= 100 && true);

    // if (tempChecks?.includes(false) && tempChecks.length > 0) {
    //   NotificationManager.error(translate("NOTIFICATION_ON_STATUS_FOR_CREATER"));
    // }
  }, [list, selectedIds, login]);

  useEffect(() => {
    if (checkPayrollRender !== JSON.stringify(selectedIds)) {
      setTableDatas();
    }
  }, [selectedIds, setTableDatas, checkPayrollRender]);

  const onDoneJob = useCallback(
    (value) => {
      setLoadingDoc(true);
      const data = {
        approvalType: appTypes,
        // state: job,
        state: "106",
        otpKey: value.token,
        rowsCount: documentsId.length.toString(),
      };
      AccountApi.approveOrRejectDoc({
        documentIds: documentsId.map((x) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          x.id ? x.id : x.documentId ? x.documentId : x.payrollId ? x.payrollId : x.accountOpenId,
        ),
        ...data,
      })
        .then((r) => {
          if (r.otpCheckFlag === "02") {
            NotificationManager.error(translate("WRONG_OTP_KEY"), "OTP", 5000);
          } else if (r.status.filter((x) => x === "EXPIRED").length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_EXPIRED_ERROR"), "", 5000);
            setCheckOtp(false);
          } else if (r.status.filter((x) => x === null).length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_NULL_ERROR"), "", 5000);
            setCheckOtp(false);
          } else {
            location.reload();
          }
          setLoadingDoc(false);
        })
        .catch((err) => {
          showError(err);
          setLoadingDoc(false);
        });
    },
    [AccountApi, appTypes, documentsId, translate],
  );

  // new one end

  const onGetDocument = useCallback(
    (value, date) => {
      const selected = date.filter((x) => x.id === value);
      setValues(selected);
      locationHelpers.pushQuery({ transferType: TransferType.LCY });
    },
    [locationHelpers],
  );

  const saveToPdfHandler = useCallback(() => {
    const standardList = list.filter((x, idx) => x.budgetAccount === null && selectedIds[idx]);
    const munis = list.filter((x, idx) => x.documentType === PaymentType.Munis && selectedIds[idx]);
    const standard = [...standardList, ...munis];
    const revenue = list.filter((x, idx) => x.budgetAccount?.length === 25 && selectedIds[idx]);
    const budget = list.filter((x, idx) => x.budgetAccount?.length === 27 && selectedIds[idx]);
    const standardAfterGetValues = [{}];
    const budgetAfterGetValues = [{}];
    const revenueAfterGetValues = [{}];
    budget.map((x) => {
      const dataForBudget = {
        documentId: x.id,
        receiverMfo: x.receiverMfo,
        budgetTaxNumber: x.budgetTaxNumber,
        budgetAccount: x.budgetAccount,
        budgetName: x.budgetName,
        senderMfo: x.senderMfo,
        amountDetails:
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          })[0].toUpperCase() +
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          }).slice(1),
        carriedOut: x.transactionDate,
        documentNumber: x.documentNumber,
        documentDate: x.documentDate,
        senderName: x.clientName,
        senderAccount: x.clientAccount,
        senderTaxNumber: x.clientTaxNumber,
        senderBankName: x.clientBankName,
        senderBankCode: x.senderBankCode,
        amount: x.amount,
        correspondentName: x.correspondentName,
        correspondentAccount: x.correspondentAccount,
        correspondentTaxNumber: x.correspondentTaxNumber,
        correspondentBankName: x.correspondentBankName,
        correspondentBank: x.correspondentBank,
        paymentCode: x.paymentCode,
        paymentDetail: x.paymentDetail.includes("<")
          ? x.paymentDetail.replace("<", "&#x276E;")
          : x.paymentDetail,
        state: x.state,
        firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
        secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
        thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
      };
      budgetAfterGetValues.push(dataForBudget);
    });
    standard.map((x) => {
      const dataForStandard: any = {
        documentId: x.id,
        amountDetails:
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          })[0].toUpperCase() +
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          }).slice(1),
        senderName: x.clientName,
        senderBankCode: x.senderMfo,
        senderTaxId: x.clientTaxNumber,
        senderAccount: x.clientAccount,
        detailsOfPayment: x.paymentDetail.includes("<")
          ? x.paymentDetail.replace("<", "&#x276E;")
          : x.paymentDetail,
        senderBankName: x.clientBankName,
        correspondentBank: x.receiverMfo,
        correspondentTaxId: x.correspondentTaxNumber,
        carriedOut: x.transactionDate,
        documentNumber: x.documentNumber,
        documentDate: x.documentDate,
        amount: x.amount,
        correspondentName: x.correspondentName,
        correspondentAccount: x.correspondentAccount,
        correspondentTaxNumber: x.correspondentTaxNumber,
        correspondentBankName: x.correspondentBankName,
        paymentCode: x.paymentCode,
        state: x.state,
        firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
        secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
        thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
        qrCodeBase64: null,
      };
      standardAfterGetValues.push(dataForStandard);
    });
    revenue.map((x) => {
      const dataForRevenue = {
        documentId: x.id,
        amountDetails:
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          })[0].toUpperCase() +
          numberToWords(removeGaps(x.amount), {
            language: languageApp,
            currencyCode: CurrencyCode.UzbekistanSum,
          }).slice(1),
        carriedOut: x.transactionDate,
        documentNumber: x.documentNumber,
        documentDate: x.documentDate,
        senderName: x.clientName,
        senderAccount: x.clientAccount,
        senderTaxId: x.senderTaxId,
        senderBankName: x.clientBankName,
        senderBankCode: x.senderBankCode,
        amount: x.amount,
        senderTaxNumber: x.clientTaxNumber,
        budgetAccount: x.budgetAccount,
        tAccount: x.clientAccount,
        senderMfo: x.senderMfo,
        receiverMfo: x.receiverMfo,
        correspondentName: x.correspondentName,
        correspondentAccount: x.correspondentAccount,
        correspondentTaxNumber: x.correspondentTaxNumber,
        correspondentBankName: x.correspondentBankName,
        correspondentBank: x.correspondentBank,
        paymentCode: x.paymentCode,
        paymentDetail: x.paymentDetail.includes("<")
          ? x.paymentDetail.replace("<", "&#x276E;")
          : x.paymentDetail,
        state: x.state,
        firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
        secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
        thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
        qrCodeBase64: null,
      };
      revenueAfterGetValues.push(dataForRevenue);
    });
    standardAfterGetValues.shift();
    budgetAfterGetValues.shift();
    revenueAfterGetValues.shift();

    if (standard.length > 0) {
      PdfReportApi.createStandardPdfReport("standard-report", standardAfterGetValues, user?.login);
    }

    if (budget.length > 0) {
      PdfReportApi.createBudgetPdfReport("budget-report", budgetAfterGetValues, user?.login);
    }

    if (revenue.length > 0) {
      PdfReportApi.createRevenuePdfReport("revenue-report", revenueAfterGetValues, user?.login);
    }
  }, [PdfReportApi, languageApp, list, selectedIds, user]);

  // --- With QR begin --- //

  const saveToPdfWithQRKodeHandler = useCallback(() => {
    if (Object.keys(selectedIds).length === 1) {
      const standardList = list.filter((x, idx) => x.budgetAccount === null && selectedIds[idx]);
      const munis = list.filter(
        (x, idx) => x.documentType === PaymentType.Munis && selectedIds[idx],
      );
      const standard = [...standardList, ...munis];
      const revenue = list.filter((x, idx) => x.budgetAccount?.length === 25 && selectedIds[idx]);
      const budget = list.filter((x, idx) => x.budgetAccount?.length === 27 && selectedIds[idx]);
      const standardAfterGetValues = [{}];
      const budgetAfterGetValues = [{}];
      const revenueAfterGetValues = [{}];
      budget.map((x) => {
        const dataForBudget = {
          documentId: x.id,
          receiverMfo: x.receiverMfo,
          budgetTaxNumber: x.budgetTaxNumber,
          budgetAccount: x.budgetAccount,
          budgetName: x.budgetName,
          senderMfo: x.senderMfo,
          amountDetails:
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            })[0].toUpperCase() +
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            }).slice(1),
          carriedOut: x.transactionDate,
          documentNumber: x.documentNumber,
          documentDate: x.documentDate,
          senderName: x.clientName,
          senderAccount: x.clientAccount,
          senderTaxNumber: x.clientTaxNumber,
          senderBankName: x.clientBankName,
          senderBankCode: x.senderBankCode,
          amount: x.amount,
          correspondentName: x.correspondentName,
          correspondentAccount: x.correspondentAccount,
          correspondentTaxNumber: x.correspondentTaxNumber,
          correspondentBankName: x.correspondentBankName,
          correspondentBank: x.correspondentBank,
          paymentCode: x.paymentCode,
          paymentDetail: x.paymentDetail.includes("<")
            ? x.paymentDetail.replace("<", "&#x276E;")
            : x.paymentDetail,
          state: x.state,
          firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
          secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
          thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
        };
        budgetAfterGetValues.push(dataForBudget);
      });
      standard.map((x) => {
        const dataForStandard = {
          documentId: x.id,
          amountDetails:
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            })[0].toUpperCase() +
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            }).slice(1),
          senderName: x.clientName,
          senderBankCode: x.senderMfo,
          senderTaxId: x.clientTaxNumber,
          senderAccount: x.clientAccount,
          senderMfo: x.senderMfo,
          detailsOfPayment: x.paymentDetail.includes("<")
            ? x.paymentDetail.replace("<", "&#x276E;")
            : x.paymentDetail,
          senderBankName: x.clientBankName,
          correspondentBank: x.receiverMfo,
          correspondentTaxId: x.correspondentTaxNumber,
          budgetAccount: x.budgetAccount,
          receiverMfo: x.receiverMfo,
          carriedOut: x.transactionDate,
          documentNumber: x.documentNumber,
          documentDate: x.documentDate,
          amount: x.amount,
          correspondentName: x.correspondentName,
          correspondentAccount: x.correspondentAccount,
          correspondentTaxNumber: x.correspondentTaxNumber,
          correspondentBankName: x.correspondentBankName,
          paymentCode: x.paymentCode,
          state: x.state,
          firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
          secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
          thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
          qrCodeBase64: "null",
        };
        standardAfterGetValues.push(dataForStandard);
      });
      revenue.map((x) => {
        const dataForRevenue = {
          documentId: x.id,
          amountDetails:
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            })[0].toUpperCase() +
            numberToWords(removeGaps(x.amount), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            }).slice(1),
          carriedOut: x.transactionDate,
          documentNumber: x.documentNumber,
          documentDate: x.documentDate,
          senderName: x.clientName,
          senderAccount: x.clientAccount,
          tAccount: x.clientAccount,
          senderTaxNumber: x.clientTaxNumber,
          senderBankName: x.clientBankName,
          senderBankCode: x.senderBankCode,
          budgetAccount: x.budgetAccount,
          senderMfo: x.senderMfo,
          amount: x.amount,
          correspondentName: x.correspondentName,
          correspondentAccount: x.correspondentAccount,
          correspondentTaxNumber: x.correspondentTaxNumber,
          correspondentBankName: x.correspondentBankName,
          correspondentBank: x.correspondentBank,
          receiverMfo: x.receiverMfo,
          paymentCode: x.paymentCode,
          paymentDetail: x.paymentDetail.includes("<")
            ? x.paymentDetail.replace("<", "&#x276E;")
            : x.paymentDetail,
          state: x.state,
          firstAuthorizerOtpKey: x.firstAuthorizerOtpKey,
          secondAuthorizerOtpKey: x.secondAuthorizerOtpKey,
          thirdAuthorizerOtpKey: x.thirdAuthorizerOtpKey,
          qrCodeBase64: "null",
        };
        revenueAfterGetValues.push(dataForRevenue);
      });
      standardAfterGetValues.shift();
      budgetAfterGetValues.shift();
      revenueAfterGetValues.shift();

      if (standard.length > 0) {
        if (standard[0].state === "3") {
          PdfReportApi.createStandardPdfWithQRCode(
            "standard-report-with-qr-code",
            standardAfterGetValues,
            user?.login,
          ).catch(() => NotificationManager.error(translate("QR_CODE_ERROR3015")));
        } else {
          NotificationManager.error(translate("QR_CODE_ERROR3016"));
        }
      }

      if (budget.length > 0) {
        if (budget[0].state === "3") {
          PdfReportApi.createBudgetPdfWithQRCode(
            "budget-report-with-qr-code",
            budgetAfterGetValues,
            user?.login,
          ).catch(() => NotificationManager.error(translate("QR_CODE_ERROR3015")));
        } else {
          NotificationManager.error(translate("QR_CODE_ERROR3016"));
        }
      }

      if (revenue.length > 0) {
        if (revenue[0].state === "3") {
          PdfReportApi.createRenevuePdfWithQRCode(
            "revenue-report-with-qr-code",
            revenueAfterGetValues,
            user?.login,
          ).catch(() => NotificationManager.error(translate("QR_CODE_ERROR3015")));
        } else {
          NotificationManager.error(translate("QR_CODE_ERROR3016"));
        }
      }
    } else {
      NotificationManager.error(translate("QR_CODE_SELECT_LIMIT_ERROR"));
    }
  }, [PdfReportApi, languageApp, list, selectedIds, user, translate]);

  // --- With QR end --- //

  const saveToExcelHandler = useCallback(() => {
    const standardList = list.filter((x, idx) => x.budgetAccount === null && selectedIds[idx]);
    const munis = list.filter((x, idx) => x.documentType === PaymentType.Munis && selectedIds[idx]);
    const standard = [...standardList, ...munis];
    standard.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.currencyCode = TransferCurrencyPicker({ currency: CurrencyCode.UzbekistanSum });
    });
    const revenue = list.filter((x, idx) => x.budgetAccount?.length === 25 && selectedIds[idx]);
    revenue.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.currencyCode = TransferCurrencyPicker({ currency: CurrencyCode.UzbekistanSum });
    });
    const budget = list.filter((x, idx) => x.budgetAccount?.length === 27 && selectedIds[idx]);
    budget.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.currencyCode = TransferCurrencyPicker({ currency: CurrencyCode.UzbekistanSum });
    });

    if (standard.length > 0) {
      const workbook = createTransferLCYExcel(standard, {
        translate,
        language: languageApp,
        currencyCode: CurrencyCode.UzbekistanSum,
      });

      saveExcelToFile(workbook, "LCY Standard Outgoing Transfers").catch(showError);
    }

    if (budget.length > 0) {
      const workbook = createTransferLCYBudgetExcel(budget, {
        translate,
        language: languageApp,
        currencyCode: CurrencyCode.UzbekistanSum,
      });

      saveExcelToFile(workbook, "LCY Budget Outgoing Transfers").catch(showError);
    }

    if (revenue.length > 0) {
      const workbook = createTransferLCYRevenueExcel(revenue, {
        translate,
        language: languageApp,
        currencyCode: CurrencyCode.UzbekistanSum,
      });

      saveExcelToFile(workbook, "LCY Revenue Outgoing Transfers").catch(showError);
    }
  }, [languageApp, list, selectedIds, translate]);

  const printHandler = useCallback(() => {
    const standard = list.filter((x, idx) => x.budgetAccount === null && selectedIds[idx]);
    const munis = list.filter((x, idx) => x.documentType === PaymentType.Munis && selectedIds[idx]);
    const standardList = [...standard, ...munis];
    const revenueList = list.filter((x, idx) => x.budgetAccount?.length === 25 && selectedIds[idx]);
    const budgetList = list.filter((x, idx) => x.budgetAccount?.length === 27 && selectedIds[idx]);
    standardList.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.amountDetails =
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        })[0].toUpperCase() +
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        }).slice(1);
    });
    budgetList.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.amountDetails =
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        })[0].toUpperCase() +
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        }).slice(1);
    });
    revenueList.map((x) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.amountDetails =
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        })[0].toUpperCase() +
        numberToWords(removeGaps(x.amount), {
          language: languageApp,
          currencyCode: CurrencyCode.UzbekistanSum,
        }).slice(1);
    });
    printTransferLCYTable({ standardList, revenueList, budgetList });
  }, [languageApp, list, selectedIds]);

  const changeFilterHandler = useCallback(
    (value) => {
      locationHelpers.replaceQuery({
        status: value.status.value,
        toDate: formatDateToUrl(value.toDate),
        fromDate: formatDateToUrl(value.fromDate),
      });
    },
    [locationHelpers],
  );

  const [debitAccount, setDebitAccount] = useState({});

  useResource(
    () =>
      ReferenceBookApi.getAllowedAccounts().then((r) => {
        setDebitAccount([
          r.map((res) => ({
            label: res.accountCode,
            value: res.accountCode,
            clientCode: res.accountCode,
            balance: res.balance,
            senderBankCode: res?.accountBranch,
            senderName: res?.accountName,
            accountState: res?.accountState,
            senderBankName: res?.bankName,
            senderTaxId: res?.taxNumber,
          })),
        ]);
      }),
    [ReferenceBookApi],
  );

  const uploadFromExcel = useCallback(
    (value, type) => {
      NotificationManager.info(
        translate("TRANSFER_LOADING"),
        translate("TRANSFER_NOTIFICATION_TITLE"),
        5000,
      );
      const excel = value.filter((x) => x.B);
      excel.shift();
      excel.map((x) =>
        importLogs.push({
          transferNumber: x.D.toString(),
          status: translate("PAYROLL_STATUS_PROCESSING"),
          comment: translate("PAYROLL_STATUS_PROCESSING"),
        }),
      );
      importLogs.shift();
      const standard = excel.filter((x) => x.G !== "23402000300100001010");
      const budget = excel.filter((x) => x.I?.split("~")[4]?.length === 27);
      const revenue = excel.filter((x) => x.I?.split("~")[4]?.length === 25);
      setImportModal(true);
      if (budget.length > 0) {
        budget.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const beneficiaryAcc = x.I.toString().split("~")[4];
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: beneficiaryAcc,
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debitSelected = debitAccount[0]?.filter((d) => d.label === x.E.toString().trim());
            const paymentCodeTrue =
              FormatPaymentCode(x.I.split("~")[0], "009") ||
              FormatPaymentCode(x.I.split("~")[0], "09");
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            const date = x.C.split(".");
            const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
            const afterDate =
              jsDate.toString() === "Invalid Date"
                ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
                : jsDate;
            if (debitSelected?.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (afterDate < dateFor || betweenDays(afterDate, dateFor) > 31) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (amountTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (!paymentCodeTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_PAYMENT_CODE_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x.D,
                documentDate:
                  jsDate.toString() === "Invalid Date"
                    ? `${date[0]}.${date[1]}.20${date[2]}`
                    : formatDateToBody(jsDate),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x.K.toString().trim(),
                correspondentName: x.F.substr(0, 70),
                correspondentAccount: x.G.toString().trim(),
                correspondentBank: x.K,
                correspondentTaxNumber: x.H.toString().trim(),
                amount: addZeros(x.J.toString()), //removeGaps
                amountDetails:
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x.I.split("~")[0],
                detailsOfPayment: x.I.toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetDoc,
                budgetAccount: res[0].budgetAccount,
                budgetAccountName: res[0].budgetAccountName,
                budgetTaxNumber: res[0].taxNumber,
              };
              setTimeout(
                () =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  TransferApi.createLcyTransfer(data)
                    .then(() => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                      importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                      setTimeout(() => setImportModalTable(true), 500);
                    })
                    .catch((r) => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                      importLogs[logFromImport].comment = `${r.data[0].userMsg}`;
                      setTimeout(() => setImportModalTable(true), 500);
                    }),
                logFromImport * 500,
              );
            }
          });
        });
      }
      if (standard.length > 0) {
        standard.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          const debitSelected = debitAccount[0].filter((d) => d.label === x.E.toString().trim());
          const correspondentTaxNumberTrue = x.H.toString().trim().length === 9;
          const paymentCode = x.I.split(" ")[0];
          const dateFor = new Date();
          dateFor.setDate(dateFor.getDate() - 1);
          const date = x.C.split(".");
          const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
          const afterDate =
            jsDate.toString() === "Invalid Date"
              ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
              : jsDate;
          if (debitSelected?.length === 0) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (afterDate < dateFor || betweenDays(afterDate, dateFor) > 31) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (amountTrue) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (!correspondentTaxNumberTrue) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate(
              "TRANSFER_CORRESPANDENT_TAX_ID_INCORRECT_IMPORT",
            );
            setTimeout(() => setImportModalTable(true), 500);
          } else {
            const data = {
              documentNumber: x.D,
              documentDate:
                jsDate.toString() === "Invalid Date"
                  ? `${date[0]}.${date[1]}.20${date[2]}`
                  : formatDateToBody(jsDate),
              senderName: debitSelected[0].senderName,
              clientAccount: debitSelected[0].label,
              accountState: debitSelected[0].accountState,
              currentBalance: debitSelected[0].balance,
              senderBankName: debitSelected[0].senderBankName,
              senderBankCode: debitSelected[0].senderBankCode,
              senderTaxId: debitSelected[0].senderTaxId,
              senderMfo: debitSelected[0].senderBankCode,
              receiverMfo: x.K.substr(0, 9),
              correspondentName: x.F.substr(0, 70),
              correspondentAccount: x.G.toString().trim(),
              correspondentBank: x.K.toString().trim(),
              correspondentTaxNumber: x.H.toString().trim(),
              amount: addZeros(x.J.toString()), //removeGaps
              amountDetails:
                numberToWords(removeGaps(x.J.toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                })[0].toUpperCase() +
                numberToWords(removeGaps(x.J.toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                }).slice(1),
              paymentCode:
                paymentCode.length < 5 ? paymentCode.substr(0, 3) : paymentCode.substr(0, 5),
              detailsOfPayment: x.I.replace(/[^ ]+ /, ""),
              paymentType: type,
              budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
            };
            setTimeout(
              () =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                TransferApi.createLcyTransfer(data)
                  .then(() => {
                    setImportModalTable(false);
                    importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                    importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                    setTimeout(() => setImportModalTable(true), 500);
                  })
                  .catch((r) => {
                    setImportModalTable(false);
                    importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                    importLogs[logFromImport].comment = ` ${r.data[0].userMsg}`;
                    setTimeout(() => setImportModalTable(true), 500);
                  }),
              logFromImport * 500,
            );
          }
        });
      }
      if (revenue.length > 0) {
        revenue.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const beneficiaryAcc = x.I.split("~")[4];
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetIncomeAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: beneficiaryAcc,
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debitSelected = debitAccount[0].filter((d) => d.label === x.E.toString().trim());
            const paymentCodeTrue = FormatPaymentCode(x.I.split("~")[0], "08");
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            const date = x.C.split(".");
            const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
            const afterDate =
              jsDate.toString() === "Invalid Date"
                ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
                : jsDate;
            if (debitSelected?.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (afterDate < dateFor || betweenDays(afterDate, dateFor) > 31) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (amountTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (!paymentCodeTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_PAYMENT_CODE_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x.D,
                documentDate:
                  jsDate.toString() === "Invalid Date"
                    ? `${date[0]}.${date[1]}.20${date[2]}`
                    : formatDateToBody(jsDate),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x.K,
                correspondentName: x.F.substr(0, 70),
                correspondentAccount: x.G.toString().trim(),
                correspondentBank: x.K.toString().trim(),
                correspondentTaxNumber: x.H.toString().trim(),
                amount: addZeros(x.J.toString()), //removeGaps
                amountDetails:
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x.I.split("~")[0],
                detailsOfPayment: x.I.toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
                budgetAccount: res[0].budgetIncomeAccount,
                budgetAccountName: res[0].budgetAccountName.substr(0, 70),
                budgetTaxNumber: debitSelected[0].senderTaxId,
              };
              setTimeout(
                () =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  TransferApi.createLcyTransfer(data)
                    .then(() => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                      importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                      setTimeout(() => setImportModalTable(true), 500);
                    })
                    .catch((r) => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                      importLogs[logFromImport].comment = `${r.data[0].userMsg}`;
                      setTimeout(() => setImportModalTable(true), 500);
                    }),
                logFromImport * 500,
              );
            }
          });
        });
      }
      setTimeout(
        () => {
          importLogs.map((x) => {
            if (x.status === translate("PAYROLL_STATUS_PROCESSING")) {
              setImportModalTable(false);
              x.status = translate("TRANSFER_IMPORT_DEFECTED");
              x.comment = translate("TRANSFER_IMPORT_DEFECTED");
              setImportModalTable(true);
            }
          });
        },
        importLogs.length > 10 ? importLogs.length * 500 : 3000,
      );
    },
    [ReferenceBookApi, TransferApi, debitAccount, importLogs, languageApp, translate],
  );

  const uploadFromTxt = useCallback(
    (value, type) => {
      NotificationManager.info(
        translate("TRANSFER_LOADING"),
        translate("TRANSFER_NOTIFICATION_TITLE"),
        5000,
      );
      const filteredData: any[] = [];
      const data = value.split("{1");
      data.shift();
      data.map((x) => {
        const item = x?.split("\r\n");

        filteredData.push({
          C: `${item[15]?.substring(6, 10)}.${item[15]?.substring(10, 12)}.${item[15]?.substring(
            12,
            14,
          )}`
            .split(".")
            .reverse()
            .join("."),
          D: item[1]?.substring(4).replace(" ", ""),
          E: item[3]?.substring(7).replace(/\s/g, "").replace(" ", ""),
          F: item[12]?.substring(6),
          G: item[11]?.substring(4).replace(/\s/g, ""),
          H: item[13]?.substring(5).replace(" ", ""),
          I: item[18]?.substring(8),
          J: item[2]?.substring(14).replace(",", ".").replace(/^0+/, "").replace(" ", ""),
          K: item[10]?.substring(5).replace(" ", ""),
        });
      });

      filteredData.map((x) =>
        importLogs.push({
          transferNumber: x.D,
          status: translate("PAYROLL_STATUS_PROCESSING"),
          comment: translate("PAYROLL_STATUS_PROCESSING"),
        }),
      );

      const standard = filteredData.filter((x) => x.G !== "23402000300100001010");
      const budget = filteredData.filter((x) => x.I?.split("~")[4]?.length === 27);
      const revenue = filteredData.filter((x) => x.I?.split("~")[4]?.length === 25);

      setImportModal(true);
      if (budget.length > 0) {
        budget.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const beneficiaryAcc = x.I.toString().split("~")[4];
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: beneficiaryAcc,
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debitSelected = debitAccount[0]?.filter((d) => d.label === x.E.toString().trim());
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            const date = x.C.split(".");
            const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
            const afterDate =
              jsDate.toString() === "Invalid Date"
                ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
                : jsDate;
            if (debitSelected?.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (afterDate < dateFor) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (amountTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x.D,
                documentDate:
                  jsDate.toString() === "Invalid Date"
                    ? `${date[0]}.${date[1]}.20${date[2]}`
                    : formatDateToBody(jsDate),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x.K.toString().trim(),
                correspondentName: x.F.substr(0, 70),
                correspondentAccount: x.G.toString().trim(),
                correspondentBank: x.K,
                correspondentTaxNumber: x.H.toString().trim(),
                amount: addZeros(removeGaps(x.J.toString())),
                amountDetails:
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x.I.split("~")[0],
                detailsOfPayment: x.I.toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetDoc,
                budgetAccount: res[0].budgetAccount,
                budgetAccountName: res[0].budgetAccountName,
                budgetTaxNumber: res[0].taxNumber,
              };
              setTimeout(
                () =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  TransferApi.createLcyTransfer(data)
                    .then(() => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                      importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                      setTimeout(() => setImportModalTable(true), 500);
                    })
                    .catch((r) => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                      importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                        r.data[0].userMsg
                      }`;
                      setTimeout(() => setImportModalTable(true), 500);
                    }),
                logFromImport * 500,
              );
            }
          });
        });
      }
      if (standard.length > 0) {
        standard.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          const debitSelected = debitAccount[0].filter((d) => d.label === x.E.toString().trim());
          const paymentCode = x.I.split(" ")[0];
          const dateFor = new Date();
          dateFor.setDate(dateFor.getDate() - 1);
          const date = x.C.split(".");
          const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
          const afterDate =
            jsDate.toString() === "Invalid Date"
              ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
              : jsDate;
          if (debitSelected?.length === 0) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (afterDate < dateFor) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (amountTrue) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
            setTimeout(() => setImportModalTable(true), 500);
          } else {
            const data = {
              documentNumber: x.D,
              documentDate:
                jsDate.toString() === "Invalid Date"
                  ? `${date[0]}.${date[1]}.20${date[2]}`
                  : formatDateToBody(jsDate),
              senderName: debitSelected[0].senderName,
              clientAccount: debitSelected[0].label,
              accountState: debitSelected[0].accountState,
              currentBalance: debitSelected[0].balance,
              senderBankName: debitSelected[0].senderBankName,
              senderBankCode: debitSelected[0].senderBankCode,
              senderTaxId: debitSelected[0].senderTaxId,
              senderMfo: debitSelected[0].senderBankCode,
              receiverMfo: x.K.substr(0, 9),
              correspondentName: x.F.substr(0, 70),
              correspondentAccount: x.G.toString().trim(),
              correspondentBank: x.K.toString().trim(),
              correspondentTaxNumber: x.H.toString().trim(),
              amount: addZeros(removeGaps(x.J.toString())),
              amountDetails:
                numberToWords(removeGaps(x.J.toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                })[0].toUpperCase() +
                numberToWords(removeGaps(x.J.toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                }).slice(1),
              paymentCode:
                paymentCode.length < 5 ? paymentCode.substr(0, 3) : paymentCode.substr(0, 5),
              detailsOfPayment: x.I.replace(/[^ ]+ /, ""),
              paymentType: type,
              budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
            };
            setTimeout(
              () =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                TransferApi.createLcyTransfer(data)
                  .then(() => {
                    setImportModalTable(false);
                    importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                    importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                    setTimeout(() => setImportModalTable(true), 500);
                  })
                  .catch((r) => {
                    setImportModalTable(false);
                    importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                    importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                      r.data[0].userMsg
                    }`;
                    setTimeout(() => setImportModalTable(true), 500);
                  }),
              logFromImport * 500,
            );
          }
        });
      }
      if (revenue.length > 0) {
        revenue.map((x) => {
          const amountTrue = x.J ? x.J.toString().includes(",") : true;
          const beneficiaryAcc = x.I.split("~")[4];
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetIncomeAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: beneficiaryAcc,
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debitSelected = debitAccount[0].filter((d) => d.label === x.E.toString().trim());
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            const date = x.C.split(".");
            const jsDate = DateTime.fromFormat(x.C, "dd.MM.yyyy").toJSDate();
            const afterDate =
              jsDate.toString() === "Invalid Date"
                ? DateTime.fromFormat(`${date[0]}.${date[1]}.20${date[2]}`, "dd.MM.yyyy").toJSDate()
                : jsDate;
            if (debitSelected?.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (afterDate < dateFor) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (amountTrue) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_AMOUNT_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x.D,
                documentDate:
                  jsDate.toString() === "Invalid Date"
                    ? `${date[0]}.${date[1]}.20${date[2]}`
                    : formatDateToBody(jsDate),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x.K,
                correspondentName: x.F.substr(0, 70),
                correspondentAccount: x.G.toString().trim(),
                correspondentBank: x.K.toString().trim(),
                correspondentTaxNumber: x.H.toString().trim(),
                amount: addZeros(removeGaps(x.J.toString())),
                amountDetails:
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x.J.toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x.I.split("~")[0],
                detailsOfPayment: x.I.toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
                budgetAccount: res[0].budgetIncomeAccount,
                budgetAccountName: res[0].budgetAccountName.substr(0, 70),
                budgetTaxNumber: debitSelected[0].senderTaxId,
              };
              setTimeout(
                () =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  TransferApi.createLcyTransfer(data)
                    .then(() => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                      importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                      setTimeout(() => setImportModalTable(true), 500);
                    })
                    .catch((r) => {
                      setImportModalTable(false);
                      importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                      importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                        r.data[0].userMsg
                      }`;
                      setTimeout(() => setImportModalTable(true), 500);
                    }),
                logFromImport * 500,
              );
            }
          });
        });
      }
      setTimeout(
        () => {
          importLogs.map((x) => {
            if (x.status === translate("PAYROLL_STATUS_PROCESSING")) {
              setImportModalTable(false);
              x.status = translate("TRANSFER_IMPORT_DEFECTED");
              x.comment = translate("TRANSFER_IMPORT_DEFECTED");
              setImportModalTable(true);
            }
          });
        },
        importLogs.length > 10 ? importLogs.length * 500 : 3000,
      );
    },
    [ReferenceBookApi, TransferApi, debitAccount, importLogs, languageApp, translate],
  );

  const uploadFromCSV = useCallback(
    (value, type) => {
      NotificationManager.info(
        translate("TRANSFER_LOADING"),
        translate("TRANSFER_NOTIFICATION_TITLE"),
        10000,
      );
      value.data.shift();
      const csv = value.data.filter((x) => /[0-9]/.test(x[0]));
      csv.map((x) =>
        importLogs.push({
          transferNumber: x[2].toString(),
          status: translate("PAYROLL_STATUS_PROCESSING"),
          comment: translate("PAYROLL_STATUS_PROCESSING"),
        }),
      );
      importLogs.shift();
      const standard = csv.filter((x) => x[5] !== "23402000300100001010");
      const budget = csv.filter((x) => x[7].split("~")[4]?.length === 27);
      const revenue = csv.filter((x) => x[7].split("~")[4]?.length === 25);
      setImportModal(true);
      if (standard.length > 0) {
        standard.map((x) => {
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x[2].toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          const debit = x[3].split(" ");
          const debitAfter = debit.filter((x) => x !== "");
          const debitSelected = debitAccount[0].filter((d) => d.label === debitAfter[0]);
          const dateFor = new Date();
          dateFor.setDate(dateFor.getDate() - 1);
          if (debitSelected.length === 0) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else if (DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate() < dateFor) {
            setImportModalTable(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
            setTimeout(() => setImportModalTable(true), 500);
          } else {
            const data = {
              documentNumber: x[2],
              documentDate: formatDateToBody(DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate()),
              senderName: debitSelected[0].senderName,
              clientAccount: debitSelected[0].label,
              accountState: debitSelected[0].accountState,
              currentBalance: debitSelected[0].balance,
              senderBankName: debitSelected[0].senderBankName,
              senderBankCode: debitSelected[0].senderBankCode,
              senderTaxId: debitSelected[0].senderTaxId,
              senderMfo: debitSelected[0].senderBankCode,
              receiverMfo: x[9],
              correspondentName: x[4].substr(0, 70),
              correspondentAccount: x[5],
              correspondentBank: x[9],
              correspondentTaxNumber: x[6],
              amount: addZeros(x[8].toString()),
              amountDetails:
                numberToWords(removeGaps(x[8].toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                })[0].toUpperCase() +
                numberToWords(removeGaps(x[8].toString()), {
                  language: languageApp,
                  currencyCode: CurrencyCode.UzbekistanSum,
                }).slice(1),
              paymentCode: x[7].split(" ")[0],
              detailsOfPayment: x[7].replace(/[^ ]+ /, ""),
              paymentType: type,
              budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            TransferApi.createLcyTransfer(data)
              .then(() => {
                setImportModalTable(false);
                NotificationManager.info(
                  `${translate("TRANSFER_NOTIFICATION_FIRST")}${x[2]} ${translate(
                    "TRANSFER_CREATED",
                  )}`,
                  translate("TRANSFER_NOTIFICATION_TITLE"),
                  5000,
                );
                importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                setTimeout(() => setImportModalTable(true), 500);
              })
              .catch((r) => {
                setImportModalTable(false);
                NotificationManager.error(
                  `${translate("TRANSFER_NOTIFICATION_FIRST")}${x[2]} ${translate(
                    "TRANSFER_NOT_CREATED",
                  )} ${r.data[0].userMsg}`,
                  translate("TRANSFER_NOTIFICATION_TITLE"),
                  15000,
                );
                importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                  r.data[0].userMsg
                }`;
                setTimeout(() => setImportModalTable(true), 500);
              });
          }
        });
      }
      if (budget.length > 0) {
        budget.map((x) => {
          const numberFromExcel = csv.indexOf(x);
          csv.splice(numberFromExcel, 1);
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x[2].toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: x[7].toString().split("~")[4],
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debit = x?.[3].split(" ");
            const debitAfter = debit?.filter((x) => x !== "");
            const debitSelected = debitAccount[0]?.filter((d) => d.label === debitAfter[0]);
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            if (debitSelected.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate() < dateFor) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x[2],
                documentDate: formatDateToBody(DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate()),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x[9],
                correspondentName: x[4].substr(0, 70),
                correspondentAccount: x[5],
                correspondentBank: x[4],
                correspondentTaxNumber: x[6],
                amount: addZeros(x[8].toString()),
                amountDetails:
                  numberToWords(removeGaps(x[8].toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x[8].toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x[7].split("~")[0],
                detailsOfPayment: x[7].toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetDoc,
                budgetAccount: res[0].budgetAccount,
                budgetTaxNumber: res[0].taxNumber,
                budgetAccountName: res[0].budgetAccountName,
              };
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              TransferApi.createLcyTransfer(data)
                .then(() => {
                  setImportModalTable(false);
                  importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                  importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                  setTimeout(() => setImportModalTable(true), 500);
                })
                .catch((r) => {
                  setImportModalTable(false);
                  importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                  importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                    r.data[0].userMsg
                  }`;
                  setTimeout(() => setImportModalTable(true), 500);
                });
            }
          });
        });
      }
      if (revenue.length > 0) {
        revenue.map((x) => {
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x[2].toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          ReferenceBookApi.getBudgetAccountsInformation({
            searchType: BudgetAccountSearchType.OneRow,
            budgetIncomeAccount: x[7].toString().split("~")[4],
          }).then((res) => {
            if (!res[0]) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_BENEFICIARY_ERROR");
              setTimeout(() => setImportModalTable(true), 500);
            }
            const debit = x[3].split(" ");
            const debitAfter = debit.filter((x) => x !== "");
            const debitSelected = debitAccount[0].filter((d) => d.label === debitAfter[0]);
            const dateFor = new Date();
            dateFor.setDate(dateFor.getDate() - 1);
            if (debitSelected.length === 0) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else if (DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate() < dateFor) {
              setImportModalTable(false);
              importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
              importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
              setTimeout(() => setImportModalTable(true), 500);
            } else {
              const data = {
                documentNumber: x[2],
                documentDate: formatDateToBody(DateTime.fromFormat(x[1], "yyyy.MM.dd").toJSDate()),
                senderName: debitSelected[0].senderName,
                clientAccount: debitSelected[0].label,
                accountState: debitSelected[0].accountState,
                currentBalance: debitSelected[0].balance,
                senderBankName: debitSelected[0].senderBankName,
                senderBankCode: debitSelected[0].senderBankCode,
                senderTaxId: debitSelected[0].senderTaxId,
                senderMfo: debitSelected[0].senderBankCode,
                receiverMfo: x[9],
                correspondentName: x[4].substr(0, 70),
                correspondentAccount: x[5],
                correspondentBank: x[9],
                correspondentTaxNumber: x[6],
                amount: addZeros(x[8].toString()),
                amountDetails:
                  numberToWords(removeGaps(x[8].toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  })[0].toUpperCase() +
                  numberToWords(removeGaps(x[8].toString()), {
                    language: languageApp,
                    currencyCode: CurrencyCode.UzbekistanSum,
                  }).slice(1),
                paymentCode: x[7].split("~")[0],
                detailsOfPayment: x[7].toString().split("~")[6],
                paymentType: type,
                budgetFlag: PaymentCodeBudgetFlag.BudgetRevenue,
                budgetAccount: x[7].toString().split("~")[4],
                budgetTaxNumber: x[7].toString().split("~")[5],
              };
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              TransferApi.createLcyTransfer(data)
                .then(() => {
                  setImportModalTable(false);
                  importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                  importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                  setTimeout(() => setImportModalTable(true), 500);
                })
                .catch((r) => {
                  setImportModalTable(false);
                  importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                  importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                    r.data[0].userMsg
                  }`;
                  setTimeout(() => setImportModalTable(true), 500);
                });
            }
          });
        });
      }
      setTimeout(
        () => {
          importLogs.map((x) => {
            if (x.status === translate("PAYROLL_STATUS_PROCESSING")) {
              setImportModalTable(false);
              x.status = translate("TRANSFER_IMPORT_DEFECTED");
              x.comment = translate("TRANSFER_IMPORT_DEFECTED");
              setImportModalTable(true);
            }
          });
        },
        importLogs.length > 10 ? importLogs.length * 500 : 3000,
      );
    },
    [ReferenceBookApi, TransferApi, debitAccount, importLogs, languageApp, translate],
  );

  const fileSelectorExcelCBU = useSelectFile({
    accept: [FileAccept.Excel],
    onSelect: (x) =>
      readXlsx(x[0]).then((r) => {
        setImportExcelAnorOrCBU(false);
        uploadFromExcel && uploadFromExcel(r, PaymentType.PaymentOrder);
      }),
  });

  const fileSelectorCSVCBU = useSelectFile({
    accept: [FileAccept.CSV],
    onSelect: (x) =>
      readCSV(x[0]).then((r) => {
        setImportCSVAnorOrCBU(false);
        uploadFromCSV && uploadFromCSV(r, PaymentType.PaymentOrder);
      }),
  });

  const fileSelectorTxtCBU = useSelectFile({
    accept: [FileAccept.TXT],
    onSelect: (x) =>
      readTxt(x[0], txtCharset).then((r) => {
        setImportTxt(false);
        uploadFromTxt && uploadFromTxt(r, PaymentType.PaymentOrder);
      }),
  });

  const fileSelectorTxtANOR = useSelectFile({
    accept: [FileAccept.TXT],
    onSelect: (x) =>
      readTxt(x[0], txtCharset).then((r) => {
        setImportTxt(false);
        uploadFromTxt && uploadFromTxt(r, PaymentType.Anor);
      }),
  });

  const fileSelectorExcelANOR = useSelectFile({
    accept: [FileAccept.Excel],
    onSelect: (x) =>
      readXlsx(x[0]).then((x) => {
        setImportExcelAnorOrCBU(false);
        uploadFromExcel && uploadFromExcel(x, PaymentType.Anor);
      }),
  });

  const fileSelectorCSVANOR = useSelectFile({
    accept: [FileAccept.CSV],
    onSelect: (x) =>
      readCSV(x[0]).then((x) => {
        setImportCSVAnorOrCBU(false);
        uploadFromCSV && uploadFromCSV(x, PaymentType.Anor);
      }),
  });

  return (
    <>
      {isUnselectType && (
        <>
          <TabPage
            className="px-4"
            headerComponent={
              <TransfersLCYTabHeader
                filter={filter}
                onChangeFilter={changeFilterHandler}
                onShowCreators={() => setShowCreators(!showCreators)}
                onCreateLcyClick={() => {
                  setValues([]);
                  locationHelpers.pushQuery({
                    transferType: TransferType.LCY,
                    transferLcyType:
                      beginDay === "1"
                        ? TransferLcyType.StandardTransfer
                        : beginDay === "0" || !beginDay
                        ? TransferLcyType.AnorTransfer
                        : TransferLcyType.StandardTransfer,
                  });
                }}
              />
            }
            footerComponent={
              <div
                className={cx(
                  "d-flex flex-shrink-1 flex-grow-1 align-items-lg-center flex-lg-row flex-column",
                  {
                    "justify-content-between": isUnselectType,
                  },
                )}
              >
                <div className="d-flex align-items-center mr-5 mt-2">
                  <Button
                    style={
                      documentsId.length <= 0
                        ? { cursor: "no-drop", whiteSpace: "pre" }
                        : { whiteSpace: "pre" }
                    }
                    color={documentsId.length > 0 ? ButtonColor.Danger : ButtonColor.Default}
                    disabled={documentsId.length <= 0}
                    onClick={() => {
                      if (documentsId.length > 0) {
                        if (!checkIfUserCreater) {
                          setCheckOtp(true);
                        } else if (checkIfUserCreater) {
                          NotificationManager.error(
                            translate("NOTIFICATION_ON_STATUS_FOR_CREATER"),
                          );
                        }
                      } else {
                        NotificationManager.error(
                          translate("PENDING_APPROVAL_DOCUMENT_CHOOSE"),
                          translate("SIDE_MENU_PENDING_APPROVALS_TITLE"),
                        );
                      }
                    }}
                  >
                    <DeleteIcon
                      color={documentsId.length > 0 ? ColorPalette.White : ColorPalette.Dark}
                    />
                    {translate("PENDING_APPROVAL_REJECT")}
                  </Button>
                  <div className="font-weight-bold ml-2" style={{ whiteSpace: "pre" }}>
                    {translate("SALARY_TOTAL_AMOUNT")}:{" "}
                    {list.length > 0
                      ? floatFormat(
                          addZeros(
                            list
                              .reduce(
                                (partialSum, a) => +partialSum + +a.amount,
                                0,
                              )
                              .toFixed(2),
                          ),
                        )
                      : 0}
                  </div>
                </div>
                <div className="d-flex align-items-lg-center justify-content-between flex-lg-row flex-column my-lg-0 my-3">
                  <FileControls
                    onPrintClick={printHandler}
                    isMinusTransferLcyType={true}
                    isUnselectType={isUnselectType}
                    onSaveToPdfClick={saveToPdfHandler}
                    onSaveToPdf2Click={saveToPdfWithQRKodeHandler}
                    onSaveToExcelClick={saveToExcelHandler}
                    className={cx({ "mr-5": isUnselectType })}
                    onImportFromExcel={() => setImportExcelAnorOrCBU(true)}
                    onImportFromCSV={() => setImportCSVAnorOrCBU(true)}
                    onImportFromTxt={() => setImportTxt(true)}
                    checkIfDocsLess100={checkIfDocsLess100}
                  />
                  {isUnselectType && (
                    <div className="my-lg-0 my-3">
                      <Paginator filter={filter} />
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <TransfersTable
              data={list}
              onSelect={setSelectedIds}
              loading={documentsLCY.loading}
              showCreators={showCreators}
              onGetDocument={onGetDocument}
            />
            <PrintPortal>
              <TransferLcyBudgetTablePrint />
              <TransferLcyRevenueTablePrint />
              <TransferLcyStandardTablePrint />
            </PrintPortal>
          </TabPage>
          <Modal width={800} show={importModal} title={"TRANSFER_IMPORT_TABLE_HEADER"}>
            <TabPage>
              <ImportLogsModalTab
                data={importLogs}
                onCloseModal={() => location.reload()}
                importModalTable={importModalTable}
              />
            </TabPage>
          </Modal>
          <Modal
            onClose={() => setImportExcelAnorOrCBU(false)}
            width={550}
            show={importExcelAnorOrCBU}
            title={"TRANSFER_IMPORT_TABLE_HEADER"}
          >
            <TabPage>
              <ImportAnorOrCBU
                beginDay={beginDay}
                ImportForAnor={() => fileSelectorExcelANOR.open()}
                ImportForCBU={() => fileSelectorExcelCBU.open()}
              />
            </TabPage>
          </Modal>
          <Modal
            onClose={() => setImportCSVAnorOrCBU(false)}
            width={550}
            show={importCSVAnorOrCBU}
            title={"TRANSFER_IMPORT_TABLE_HEADER"}
          >
            <TabPage>
              <ImportAnorOrCBU
                beginDay={beginDay}
                ImportForAnor={() => fileSelectorCSVANOR.open()}
                ImportForCBU={() => fileSelectorCSVCBU.open()}
              />
            </TabPage>
          </Modal>
          <Modal
            onClose={() => setImportTxt(false)}
            width={550}
            show={importTxt}
            title={"TRANSFER_IMPORT_TABLE_HEADER"}
          >
            <div className="mb-2">
              <SelectPicker
                defaultValue={
                  txtCharset
                    ? {
                        value: txtCharset,
                        label: txtCharset,
                      }
                    : null
                }
                options={[
                  {
                    label: "UTF-8",
                    value: "UTF-8",
                  },
                  {
                    label: "ANSI",
                    value: "ANSI",
                  },
                ]}
                onChange={(e) => {
                  setTxtCharset(e.value);
                }}
                placeholder={translate("TRANSFER__CHOOSE_CHARSET")}
              />
            </div>
            <TabPage>
              <ImportAnorOrCBU
                ImportForAnor={() => fileSelectorTxtANOR.open()}
                ImportForCBU={() => fileSelectorTxtCBU.open()}
                disabled={Boolean(txtCharset)}
              />
            </TabPage>
          </Modal>
        </>
      )}

      <Modal
        onClose={() => setCheckOtp(false)}
        width={600}
        show={checkOtp}
        title={translate("REJECT_BUTTON")}
      >
        <TabPage>
          <CheckOtpModal onSubmit={onDoneJob} job={appTypes} loading={loadingDoc} />
        </TabPage>
      </Modal>

      {!isUnselectType && (
        <TransferLCYFormsWrapper
          filter={filter}
          values={values}
          loading={loading}
          onLcySubmit={onLcySubmit}
          backButton={() => locationHelpers.replaceQuery({ transferType: TransferType.Unselect })}
          beginDay={beginDay}
        />
      )}
    </>
  );
}
