import React, { useMemo } from "react";

import { useI18n } from "../../i18n/I18nContext";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { InquiryListProps } from "../../api/account/AccountDTO";
import { TransferStatusPicker } from "../transfers/TransferStatusPicker";

interface Props {
  readonly data: any;
  readonly loading: boolean;
  readonly setDocumentId?: (value) => void;
  readonly withCreatorsAndApprovals?: boolean;
}

export function InquiryTable({
  data = [],
  loading,
  setDocumentId,
  withCreatorsAndApprovals,
}: Props) {
  const { translate } = useI18n();

  const columns = useMemo(() => {
    const list: Column<InquiryListProps>[] = [
      {
        width: 50,
        accessor: "beginDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBMITTED_DATE_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "subject",
        Header: translate("ACCOUNT_INQUIRY_TABLE_SUBJECT_COLUMN_TITLE"),
      },
      {
        width: 150,
        accessor: "message",
        Header: translate("ACCOUNT_INQUIRY_NON_STANDARD_MESSAGE_TEXT_FIELD_LABEL_TITLE"),
      },
      {
        width: 90,
        accessor: "accountOpenState",
        Header: translate("ACCOUNT_INQUIRY_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <TransferStatusPicker status={row.value} />,
      },
      {
        width: 100,
        accessor: "explanation",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXPLANATION_COLUMN_TITLE"),
      },
      {
        width: 40,
        accessor: "endDate",
        Header: translate("ACCOUNT_INQUIRY_TABLE_EXCLUDED_DATE_COLUMN_TITLE"),
      },
    ];

    if (withCreatorsAndApprovals) {
      return list.concat([
        {
          width: 50,
          accessor: "createUser",
          Header: translate("ACCOUNT_INQUIRY_TABLE_CREATED_BY_COLUMN_TITLE"),
        },
        {
          width: 50,
          accessor: "approvalUser",
          Header: translate("ACCOUNT_INQUIRY_TABLE_APPROVED_BY_COLUMN_TITLE"),
        },
      ]);
    }
    return list;
  }, [translate, withCreatorsAndApprovals]);

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onSelect={setDocumentId}
      withCheckbox={true}
      tableClassName="bg-white"
    />
  );
}
