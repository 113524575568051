import cx from "classnames";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { NotificationManager } from "react-notifications";

import { TabPage } from "../tabs/TabPage";
import { Paginator } from "../ui/Paginator";
import { FileControls } from "../ui/FileControls";
import { Button, ButtonColor } from "../ui/Button";
import { TransfersFCYTabHeader } from "./TransfersFCYTabHeader";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { TransfersFilter, TransferType } from "../../filters/TransfersFilter";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { useResource } from "../../hooks/useResource";
import { TransfersFcyTable } from "./TransfersFcyTable";
import { FCYTransferFormWrapper } from "./FCYTransferFormWrapper";
import { FcyToCreate, FcyTransferForPending } from "../../api/transfer/TransferDTO";
import { useAccountContext } from "../../api/account/AccountContext";
import { ApprovalTypes } from "../../api/account/AccountDTO";
import { formatDateToUrl } from "../../utils/DateUtils";
import { useI18n } from "../../i18n/I18nContext";
import { Dict } from "../../api/dto/AppDTO";
import { createTransferFCYExcel, printTransferFcyTable } from "../../helpers/TransfersHelpers";
import { CheckOtpModal } from "../../components/salary/CheckOtpModal";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { saveExcelToFile } from "../../utils/ExcelUtils";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { usePdfReportContext } from "../../api/pdf-report/PdfReportContext";
import { FileAccept, useSelectFile } from "../../hooks/useSelectFile";
import { readXlsx } from "../../utils/FileUtils";
import { showError } from "../../utils/NotificationUtils";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userEmployeeIdSelector, userPhoneSelector } from "../../reducers/userReducer";
import { addZeros, removeGaps } from "../../utils/FloatUtils";
import { numberToWords } from "../../utils/FormatUtils";
import { Modal } from "../ui/Modal";
import { ImportLogsModalTab } from "./ImportLogsModalTab";
import { appLanguageSelector } from "../../reducers/appReducer";
import { TransferCurrencyPicker } from "./TransferCurrencyPicker";
import { TransferFcyTablePrint } from "./TransferFcyTablePrint";
import { PrintPortal } from "../ui/PrintPortal";
import { userLoginSelector } from "../../reducers/userReducer";
import { DeleteIcon } from "../icons/DeleteIcon";
import { ColorPalette } from "../../theme/ColorPalette";
import { switchPageCount, switchTotalCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: TransfersFilter;

  readonly onFcySubmit: (values: FcyToCreate) => void;
  readonly loading: boolean;
}

export function TransfersFCYTab({ filter, onFcySubmit, loading }: Props) {
  const { TransferApi } = useTransferContext();
  const { AccountApi } = useAccountContext();
  const language = useShallowEqualSelector(appLanguageSelector);
  const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;

  const { translate } = useI18n();

  const [selectedIds, setSelectedIds] = useState<Dict<boolean>>({});
  const [values, setValues] = useState([]);

  const locationHelpers = useLocationHelpers();

  const transferType = useMemo(() => filter.getTransferType(), [filter]);

  const isFcyTransferType = useMemo(() => transferType === TransferType.FCY, [transferType]);
  const isUnselectType = useMemo(() => transferType === TransferType.Unselect, [transferType]);
  const [showCreators, setShowCreators] = useState(false);
  const { PdfReportApi } = usePdfReportContext();
  const [debitAccount, setDebitAccount] = useState({});
  const [importLogs] = useState([{ transferNumber: "", comment: "", status: "" }]);
  const [importModal, setImportModal] = useState(false);
  const [importModalTable] = useState(true);
  const employeeId = useShallowEqualSelector(userEmployeeIdSelector);
  const [documentsId, setDocumentsId] = useState([]);
  const [checkOtp, setCheckOtp] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [appTypes, setAppTypes] = useState("");
  const userPhone = useShallowEqualSelector(userPhoneSelector);
  const dispatch = useDispatch();

  useResource(
    () =>
      TransferApi.getSenderAccountsForFCY()
        .then((r) => {
          setDebitAccount([
            r.map((res) => ({
              label: res.debitAccount,
              value: res.debitAccount,
              applicantAddress: res.applicantAddress,
              applicantName: res.applicantName,
              bankAddress: res?.bankAddress,
              bankSwift: res?.bankSwift,
              debitAccount: res?.debitAccount,
              bankName: res?.bankName,
              taxId: res?.taxId,
              currentBalance: res.currentBalance,
            })),
          ]);
        })
        .catch(showError),
    [TransferApi],
  );

  const getTransferFCYDocuments = useResource(
    () => TransferApi.getAllFCYDocuments(filter.getFcyFilterForTransferDoc()),
    [filter],
  );

  useResourceHandler(getTransferFCYDocuments, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPage?.toString() }));
      dispatch(switchTotalCount({ totalCount: data[0]?.totalRow?.toString() }));
    },
  });

  const data = useMemo(() => getTransferFCYDocuments.data || [], [getTransferFCYDocuments.data]);

  // new one start

  const login = useShallowEqualSelector(userLoginSelector);
  const [checkIfUserCreater, setCheckIfUserCreater] = useState<boolean>(false);
  const [checkPayrollRender, setCheckPayrollRender] = useState("{}");

  const setTableDatas = useCallback(() => {
    const tempDocumentsId: any = [];
    data.map((n, index) => {
      if (selectedIds[index] && n) {
        tempDocumentsId.push(n);
      }
    });
    setAppTypes(ApprovalTypes.FCYTransfer);
    setDocumentsId(tempDocumentsId);
    setCheckPayrollRender(JSON.stringify(selectedIds));
    const tempChecks = tempDocumentsId.map(
      (r) => r.createUser.includes(login) && r.state === "101",
    );
    setCheckIfUserCreater(tempDocumentsId.length > 0 && tempChecks?.includes(false));
    // if (tempChecks?.includes(false) && tempChecks.length > 0) {
    //   NotificationManager.error(translate("NOTIFICATION_ON_STATUS_FOR_CREATER"));
    // }
  }, [data, selectedIds, login]);
  useEffect(() => {
    if (checkPayrollRender !== JSON.stringify(selectedIds)) {
      setTableDatas();
    }
  }, [selectedIds, setTableDatas, checkPayrollRender]);

  const onDoneJob = useCallback(
    (value) => {
      setLoadingDoc(true);
      const data = {
        approvalType: appTypes,
        // state: job,
        state: "106",
        otpKey: value.token,
        rowsCount: documentsId.length.toString(),
      };
      AccountApi.approveOrRejectDoc({
        documentIds: documentsId.map((x) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          x.id ? x.id : x.documentId ? x.documentId : x.payrollId ? x.payrollId : x.accountOpenId,
        ),
        ...data,
      })
        .then((r) => {
          if (r.otpCheckFlag === "02") {
            NotificationManager.error(translate("WRONG_OTP_KEY"), "OTP", 5000);
          } else if (r.status.filter((x) => x === "EXPIRED").length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_EXPIRED_ERROR"), "", 5000);
            setCheckOtp(false);
          } else if (r.status.filter((x) => x === null).length > 0) {
            NotificationManager.error(translate("PENDING_APPROVAL_NULL_ERROR"), "", 5000);
            setCheckOtp(false);
          } else {
            location.reload();
          }
          setLoadingDoc(false);
        })
        .catch((err) => {
          showError(err);
          setLoadingDoc(false);
        });
    },
    [AccountApi, appTypes, documentsId, translate],
  );

  // new one end

  const onGetDocument = useCallback(
    (value, date) => {
      const selected = date.filter((x) => x.documentId === value);
      setValues(selected);
      locationHelpers.pushQuery({ transferType: TransferType.FCY });
    },
    [locationHelpers],
  );

  const uploadFromExcel = useCallback(
    (value) => {
      NotificationManager.info(
        translate("TRANSFER_LOADING"),
        translate("TRANSFER_NOTIFICATION_TITLE"),
        10000,
      );
      const excelFirst = value.filter((x) => x.B !== "№№");
      const excel = excelFirst.filter((x) => x.D);
      excel.map((x) =>
        importLogs.push({
          transferNumber: x.D.toString(),
          status: translate("TRANSFER_IMPORT_DEFECTED"),
          comment: translate("TRANSFER_IMPORT_DEFECTED"),
        }),
      );
      importLogs.shift();
      importLogs.shift();
      excel.shift();
      if (excel.length > 0) {
        setImportModal(true);
        excel.map((x) => {
          const selectedFromLog = importLogs.filter((y) => y.transferNumber === x.D.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog[0]);
          const debit = x?.E.split(" ");
          const debitAfter = debit?.filter((x) => x !== "");
          const debitSelected = debitAccount[0]?.filter((d) => d.label === debitAfter[0]);
          const date = DateTime.fromFormat(x?.C, "dd.MM.yyyy").toJSDate();
          const dateFor = new Date();
          dateFor.setDate(dateFor.getDate() - 1);
          if (debitSelected?.length === 0) {
            setImportModal(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_NO_DEBIT_IMPORT");
            setTimeout(() => setImportModal(true), 1000);
          } else if (date < dateFor) {
            setImportModal(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_DATE_INCORRECT_IMPORT");
            setTimeout(() => setImportModal(true), 1000);
          } else if (x.Z === "") {
            setImportModal(false);
            importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
            importLogs[logFromImport].comment = translate("TRANSFER_NO_PHONE_IMPORT");
            setTimeout(() => setImportModal(true), 1000);
          } else {
            const data = {
              amount: addZeros(removeGaps(x.N.toString()).toString()),
              amountInWord: numberToWords(removeGaps(x.N.toString()).toString(), {
                language: languageApp,
                currencyCode: TransferCurrencyPicker({ currency: x.O }),
              }),
              applicantAccount: debitSelected[0].label,
              applicantName: debitSelected[0].applicantName,
              applicantAddress: debitSelected[0].applicantAddress,
              applicantCurrentBalance: debitSelected[0].currentBalance,
              applicantTaxId: debitSelected[0].taxId,
              bankName: debitSelected[0].bankName,
              bankAddress: debitSelected[0].bankAddress,
              bankSwift: debitSelected[0].bankSwift,
              beneficiaryAccount: x.G,
              beneficiaryAddress: x.I,
              beneficiaryBankAddress: x.L,
              beneficiaryBankName: x.K,
              beneficiaryName: x.F,
              beneficiarySwiftCode: x.J,
              beneficiaryTaxId: x.H?.toString() || "",
              charges: x.P,
              commissionAccount: x.Q,
              corrAccount: x.O === "RUB" ? x.S : "",
              voCode: x.O === "RUB" ? x.T : "",
              correspondentBankName1: x.V ? x.V : "",
              correspondentBankName2: x.X ? x.X : "",
              correspondentSwiftCode1: x.U ? x.U : "",
              correspondentSwiftCode2: x.W ? x.W : "",
              currency: x.O,
              details: x.M,
              employerId: employeeId,
              kpp: x.O === "RUB" ? x.R : "",
              transactionDate: x.C,
              transferNumber: x.D,
              remarks: x.Y ? x.Y : "",
              senderPhoneNumber: x.Z ? x.Z : "",
              idn: x.AA ? x.AA : "",
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            TransferApi.createFcyTransfer(data)
              .then(() => {
                setImportModal(false);
                importLogs[logFromImport].status = translate("TRANSFER_SUCCESS");
                importLogs[logFromImport].comment = translate("TRANSFER_SUCCESS");
                setTimeout(() => setImportModal(true), 1000);
              })
              .catch((r) => {
                setImportModal(false);
                importLogs[logFromImport].status = translate("TRANSFER_IMPORT_ERROR");
                importLogs[logFromImport].comment = `${translate("TRANSFER_NOT_CREATED")} ${
                  r.data[0].userMsg
                }`;
                setTimeout(() => setImportModal(true), 1000);
              });
          }
        });
      }
    },
    [TransferApi, debitAccount, employeeId, importLogs, languageApp, translate],
  );

  const fileSelectorExcel = useSelectFile({
    accept: [FileAccept.Excel],
    onSelect: (x) =>
      readXlsx(x[0]).then((x) => {
        uploadFromExcel && uploadFromExcel(x);
      }),
  });
  const printHandler = useCallback(() => {
    const fcyList = data.filter((x, idx) => selectedIds[idx]);

    printTransferFcyTable({ fcyList });
  }, [data, selectedIds]);

  return (
    <>
      {isUnselectType && (
        <TabPage
          className="px-4"
          headerComponent={
            <TransfersFCYTabHeader
              filter={filter}
              onCreateFcyClick={() => {
                setValues([]);
                locationHelpers.pushQuery({ transferType: TransferType.FCY });
              }}
              onChangeFilter={(value) => {
                locationHelpers.replaceQuery({
                  status: value.status.value,
                  fromDateFcy: formatDateToUrl(value.fromDate),
                  toDateFcy: formatDateToUrl(value.toDate),
                });
              }}
              onSetShowCreators={() => setShowCreators(!showCreators)}
            />
          }
          footerComponent={
            <div className="d-flex flex-shrink-1 flex-grow-1 align-items-lg-center justify-content-between flex-lg-row flex-column">
              <div className="d-flex align-items-center mr-5 mt-2">
                <Button
                  color={documentsId.length > 0 ? ButtonColor.Danger : ButtonColor.Default}
                  disabled={documentsId.length <= 0}
                  onClick={() => {
                    if (documentsId.length > 0) {
                      if (!checkIfUserCreater) {
                        setCheckOtp(true);
                      } else if (checkIfUserCreater) {
                        NotificationManager.error(translate("NOTIFICATION_ON_STATUS_FOR_CREATER"));
                      }
                    } else {
                      NotificationManager.error(
                        translate("PENDING_APPROVAL_DOCUMENT_CHOOSE"),
                        translate("SIDE_MENU_PENDING_APPROVALS_TITLE"),
                      );
                    }
                  }}
                >
                  <DeleteIcon
                    color={documentsId.length > 0 ? ColorPalette.White : ColorPalette.Dark}
                  />
                  {translate("PENDING_APPROVAL_REJECT")}
                </Button>
              </div>
              <div className="d-flex align-items-lg-center justify-content-between flex-lg-row flex-column mb-lg-0 mb-3">
                <FileControls
                  isMinusTransferLcyType={true}
                  isUnselectType={isUnselectType}
                  onPrintClick={printHandler}
                  onSaveToPdfClick={() => {
                    const list = data.filter((_, idx) => selectedIds[idx]);
                    const newList: FcyTransferForPending[] = [];
                    list.map((x) => {
                      const data = {
                        ...x,
                        applicantTaxId: x.taxId,
                        details: x.detailsOfPayment,
                        phoneNumberForSms: userPhone,
                      };
                      newList.push(data);
                    });

                    if (list.length > 0) {
                      PdfReportApi.createFcyReport("FCY Transfers", newList);
                    }
                  }}
                  onSaveToExcelClick={() => {
                    const list = data.filter((_, idx) => selectedIds[idx]);
                    const newList: FcyTransferForPending[] = [];
                    list.map((x) => {
                      const data = {
                        ...x,
                        currencyCode: TransferCurrencyPicker({ currency: x.currency }),
                        applicantPhone: userPhone,
                      };
                      newList.push(data);
                    });
                    if (list.length > 0) {
                      createTransferFCYExcel(newList, {
                        translate,
                        language: languageApp,
                        currencyCode: CurrencyCode.UzbekistanSum,
                      }).then((workbook) => saveExcelToFile(workbook, "FCY Transfers"));
                    }
                  }}
                  className={cx(`my-lg-0 my-2`, { "mr-5": isUnselectType })}
                  onImportFromExcel={() => fileSelectorExcel.open()}
                />

                {isUnselectType && <Paginator filter={filter} />}
              </div>
            </div>
          }
        >
          {isUnselectType && (
            <>
              <TransfersFcyTable
                loading={getTransferFCYDocuments.loading}
                data={data}
                onSelect={setSelectedIds}
                showCreators={showCreators}
                onSelectDocument={onGetDocument}
              />
              <PrintPortal>
                <TransferFcyTablePrint />
              </PrintPortal>
              <Modal width={800} show={importModal} title={"TRANSFER_IMPORT_TABLE_HEADER"}>
                <TabPage>
                  <ImportLogsModalTab
                    data={importLogs}
                    onCloseModal={() => location.reload()}
                    importModalTable={importModalTable}
                  />
                </TabPage>
              </Modal>
            </>
          )}
        </TabPage>
      )}

      <Modal
        onClose={() => setCheckOtp(false)}
        width={600}
        show={checkOtp}
        title={translate("REJECT_BUTTON")}
      >
        <TabPage>
          <CheckOtpModal
            onSubmit={onDoneJob}
            job={appTypes}
            loading={loadingDoc}
            isHideWarning={true}
          />
        </TabPage>
      </Modal>

      {isFcyTransferType && (
        <FCYTransferFormWrapper
          onSubmit={onFcySubmit}
          filter={filter}
          documentValues={values}
          backButton={() => locationHelpers.replaceQuery({ transferType: TransferType.Unselect })}
          loading={loading}
        />
      )}
    </>
  );
}
