import React, { useCallback, useMemo, useState } from "react";
import { useCorporateCardsContext } from "../../api/corporate-card/CorporateCardsContext";
import { useResource } from "../../hooks/useResource";
import { CorporateCardListOfCardHoldersTable } from "./CorporateCardListOfCardHoldersTable";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { Form, Formik } from "formik";
import { InputField } from "../form/InputField";
import { noop } from "lodash";
import { switchPageCount } from "../../reducers/paginationReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly filter: TransfersFilter;
}

export function CorporateCardListOfCardHoldersTableWrapper({ filter }: Props) {
  const { CorporateCardsApi } = useCorporateCardsContext();
  const dispatch = useDispatch();

  const salaryListOfCardHolders = useResource(
    () =>
      CorporateCardsApi.getSalaryCardHolders({
        cardType: filter.getCardType().cardType,
        ...filter.getCardHoldersFilter(),
      }),
    [filter],
  );

  useResourceHandler(salaryListOfCardHolders, {
    onSuccess: (data) => {
      dispatch(switchPageCount({ pageCount: data[0]?.totalPages?.toString() }));
    },
  });

  const data = useMemo(() => salaryListOfCardHolders.data || [], [salaryListOfCardHolders.data]);
  const [searched, setSearched] = useState("");

  const cardHoldersListSearch = useCallback((data, term) => {
    if (term !== "") {
      return (
        data &&
        data.filter((item) => `${item.accountNumber} ${item.employerName}`.indexOf(term) > -1)
      );
    } else return data;
  }, []);

  const paymentCodeListAfterSearch = useMemo(() => cardHoldersListSearch(data, searched), [
    cardHoldersListSearch,
    data,
    searched,
  ]);

  return (
    <Formik enableReinitialize={true} initialValues={{ search: "" }} onSubmit={noop}>
      {() => (
        <Form>
          <InputField
            name="search"
            className="col-3 row pb-2"
            label="TRANSFERS_SALARY_SEARCH_BUTTON_TITLE"
            placeholder="APP_HEADER_SEARCH"
            onChange={(value) => setSearched(value.target.value)}
            value={searched}
          />
          <CorporateCardListOfCardHoldersTable
            data={paymentCodeListAfterSearch}
            loading={salaryListOfCardHolders.loading}
          />
        </Form>
      )}
    </Formik>
  );
}
