import { CurrencyCode } from "../number-to-words/NumberToWords";

export const TransferCurrencyPicker = ({ currency }) => {
  return currency === "RUB"
    ? CurrencyCode.RussianRuble
    : currency === "USD"
    ? CurrencyCode.UnitedStatesDollar
    : currency === "EUR"
    ? CurrencyCode.EURO
    : currency === "AED"
    ? CurrencyCode.AED
    : currency === "ALL"
    ? CurrencyCode.ALL
    : currency === "AMD"
    ? CurrencyCode.AMD
    : currency === "ARP"
    ? CurrencyCode.ARP
    : currency === "AUD"
    ? CurrencyCode.AUD
    : currency === "AZN"
    ? CurrencyCode.AZN
    : currency === "BGN"
    ? CurrencyCode.BGN
    : currency === "BOB"
    ? CurrencyCode.BOB
    : currency === "BRL"
    ? CurrencyCode.BRL
    : currency === "BYN"
    ? CurrencyCode.BYN
    : currency === "CAD"
    ? CurrencyCode.CAD
    : currency === "CHF"
    ? CurrencyCode.CHF
    : currency === "CLP"
    ? CurrencyCode.CLP
    : currency === "COP"
    ? CurrencyCode.COP
    : currency === "CUP"
    ? CurrencyCode.CUP
    : currency === "CNY"
    ? CurrencyCode.ChineseYuan
    : currency === "DKK"
    ? CurrencyCode.DKK
    : currency === "DOP"
    ? CurrencyCode.DOP
    : currency === "DZD"
    ? CurrencyCode.DZD
    : currency === "EGP"
    ? CurrencyCode.EGP
    : currency === "ETB"
    ? CurrencyCode.ETB
    : currency === "GEL"
    ? CurrencyCode.GEL
    : currency === "GTM"
    ? CurrencyCode.GTM
    : currency === "GBP"
    ? CurrencyCode.GreatBritainPound
    : currency === "HKD"
    ? CurrencyCode.HKD
    : currency === "HRK"
    ? CurrencyCode.HRK
    : currency === "HUF"
    ? CurrencyCode.HUF
    : currency === "ILS"
    ? CurrencyCode.ILS
    : currency === "IND"
    ? CurrencyCode.IND
    : currency === "INR"
    ? CurrencyCode.INR
    : currency === "IRR"
    ? CurrencyCode.IRR
    : currency === "ISK"
    ? CurrencyCode.ISK
    : currency === "JMD"
    ? CurrencyCode.JMD
    : currency === "JOD"
    ? CurrencyCode.JOD
    : currency === "JPY"
    ? CurrencyCode.JapaneseYen
    : currency === "KES"
    ? CurrencyCode.KES
    : currency === "KGS"
    ? CurrencyCode.KGS
    : currency === "KWD"
    ? CurrencyCode.KWD
    : currency === "KZT"
    ? CurrencyCode.KazakhTenge
    : currency === "KRW"
    ? CurrencyCode.KoreanWon
    : currency === "MAD"
    ? CurrencyCode.MAD
    : currency === "MDL"
    ? CurrencyCode.MDL
    : currency === "MGA"
    ? CurrencyCode.MGA
    : currency === "MMK"
    ? CurrencyCode.MMK
    : currency === "MNT"
    ? CurrencyCode.MNT
    : currency === "MXP"
    ? CurrencyCode.MXP
    : currency === "MYR"
    ? CurrencyCode.MYR
    : currency === "NGN"
    ? CurrencyCode.NGN
    : currency === "NOK"
    ? CurrencyCode.NOK
    : currency === "NPR"
    ? CurrencyCode.NPR
    : currency === "NZD"
    ? CurrencyCode.NZD
    : currency === "OMR"
    ? CurrencyCode.OMR
    : currency === "PAB"
    ? CurrencyCode.PAB
    : currency === "PEN"
    ? CurrencyCode.PHP
    : currency === "PKR"
    ? CurrencyCode.PKR
    : currency === "PLN"
    ? CurrencyCode.PLN
    : currency === "PYG"
    ? CurrencyCode.PYG
    : currency === "RON"
    ? CurrencyCode.RON
    : currency === "RSD"
    ? CurrencyCode.RSD
    : currency === "SAR"
    ? CurrencyCode.SAR
    : currency === "SEK"
    ? CurrencyCode.SEK
    : currency === "SGD"
    ? CurrencyCode.SGD
    : currency === "THB"
    ? CurrencyCode.THB
    : currency === "TND"
    ? CurrencyCode.TND
    : currency === "TRY"
    ? CurrencyCode.TRY
    : currency === "TWD"
    ? CurrencyCode.TWD
    : currency === "TZS"
    ? CurrencyCode.TZS
    : currency === "UAH"
    ? CurrencyCode.UAH
    : currency === "UYU"
    ? CurrencyCode.UYU
    : currency === "UZS"
    ? CurrencyCode.UzbekistanSum
    : currency === "VND"
    ? CurrencyCode.VND
    : currency === "ZAR"
    ? CurrencyCode.ZAR
    : CurrencyCode.NAN;
};
