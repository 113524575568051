import React, { useMemo } from "react";
import { toFinite } from "lodash";

import { useI18n } from "../../i18n/I18nContext";
import { AccountProps } from "../../api/account/AccountDTO";
import { Column } from "react-table";
import { Table } from "../table/Table";
import { addZeros, floatFormat } from "../../utils/FloatUtils";
import cx from "classnames";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { userLoginSelector } from "../../reducers/userReducer";
import "./assets/account-table.scss";

interface Props {
  readonly loading: boolean;
  readonly data: AccountProps[];
  readonly onChangeStatementAccount: ({
    accountNumber,
    accountName,
    lastTransactionDate,
    currency,
    accountType,
  }) => void;
}

export function AccountsTable({ data = [], loading, onChangeStatementAccount }: Props) {
  const { translate } = useI18n();
  const login = useShallowEqualSelector(userLoginSelector);

  const accountName =
    login === "uzkorgasview" || login === "uzkorgas" ? "secondAccountName" : "accountName";

  const columns = useMemo(() => {
    const list: Column<AccountProps>[] = [
      {
        width: 20,
        accessor: "forId",
        // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{toFinite(row.row.id) + 1}</span>,
        Header: "#",
      },
      {
        width: 170,
        accessor: accountName,
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NAME_COLUMN_TITLE"),
      },
      {
        width: 100,
        accessor: "accountNumber",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_NUMBER_COLUMN_TITLE"), // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{row.value}</span>,
      },
      {
        width: 50,
        accessor: "currency",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_CURRENCY_COLUMN_TITLE"), // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{row.value}</span>,
      },
      {
        width: 120,
        accessor: "accountBalance",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_BALANCE_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => <div>{row.value ? floatFormat(addZeros(row.value)) : ""}</div>,
      },
      {
        width: 90,
        accessor: "openDate",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_ACCOUNT_OPENING_DATE_COLUMN_TITLE"), // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{row.value}</span>,
      },
      {
        width: 90,
        accessor: "lastTransactionDate",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_LAST_TRANSACTION_DATE_COLUMN_TITLE"), // eslint-disable-next-line react/display-name
        Cell: (row) => <span className="text-center">{row.value}</span>,
      },
      {
        width: 80,
        accessor: "accountStatus",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_STATUS_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <div
            className={cx(
              "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center br-1",
              {
                "gray-status": row.value === "Closed",
                "green-status": row.value === "Open",
                "red-color-for-badge": row.value === "Blocked",
                "dermant-status": row.value === "Dormant",
              },
            )}
            style={{ height: "30px" }}
          >
            <div className="text-white">{row.value}</div>
          </div>
        ),
      },
      {
        width: 45,
        accessor: "inquiry",
        Header: translate("ACCOUNTS_ACCOUNTS_TABLE_INQUIRY_COLUMN_TITLE"),
        // eslint-disable-next-line react/display-name
        Cell: (row) => (
          <a
            className="text-center cursor-pointer"
            onClick={() =>
              onChangeStatementAccount({
                accountNumber: row.row.original.accountNumber,
                accountName: row.row.original.accountName,
                lastTransactionDate: row.row.original.lastTransactionDate,
                currency: row.row.original.currency,
                accountType: row.row.original.accountType,
              })
            }
          >
            {translate("ACCOUNTS_STATEMENT_TAB_TITLE")}
          </a>
        ),
      },
    ];

    return list;
  }, [onChangeStatementAccount, translate, accountName]);

  return <Table data={data} columns={columns} loading={loading} />;
}
