import React from "react";

import { Checkbox } from "../ui/Checkbox";
import { PositionType, SizeType } from "../../api/dto/AppDTO";
import { useI18n } from "../../i18n/I18nContext";
import { TransfersLCYTabControls } from "./TransfersLCYTabControls";
import { TransfersFilter } from "../../filters/TransfersFilter";
import { Form, Formik } from "formik";
import { DatePickerField } from "../form/DatePickerField";
import { SelectPickerField } from "../form/SelectPickerField";
import { TransfersStatuses } from "../../constants/TransfersConstants";
import "./assets/transfers-lcy-tab-header.scss";
import { TotalPendingApplicationProps } from "../../api/account/AccountDTO";
import { CheckMobileUtils } from "../../utils/CheckMobileUtils";

interface Props {
  readonly filter: TransfersFilter;

  readonly onCreateLcyClick: () => void;
  readonly onChangeFilter: (value) => void;
  readonly onShowCreators: () => void;
  readonly totalApp?: TotalPendingApplicationProps | undefined;
}

export function TransfersLCYTabHeader({
  onCreateLcyClick,
  filter,
  onChangeFilter,
  onShowCreators,
}: // totalApp,
Props) {
  const { translate } = useI18n();
  const isMobile = CheckMobileUtils();

  return (
    <div className="d-flex flex-column">
      <div className="headTitles">
        <TransfersLCYTabControls onCreateLcyClick={onCreateLcyClick} />
        {/* {totalApp?.beginDay && (
          <div
            className="headTitles_text"
            style={{ backgroundColor: `${totalApp?.beginDay === "0" ? "#ff4d4f" : "#52c41a"}` }}
          >
            {totalApp?.beginDay === "1"
              ? translate("CLEARING_OPEN")
              : translate("CLEARING_NOT_OPEN")}
          </div>
        )} */}
      </div>
      <h5 className="text-center mb-6 font-weight-bold">
        {translate("TRANSFERS_TRANSFERS_ALL_OUTGOING_TRANSFERS_TITLE")}
      </h5>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <Formik onSubmit={onChangeFilter} initialValues={filter.getLcyFilterForPage()}>
          {({ handleSubmit, values }) => (
            <Form>
              <div className="d-flex justify-content-between flex-lg-row flex-column">
                <div className="d-flex">
                  <DatePickerField
                    width={100}
                    name="fromDate"
                    className="pr-5"
                    size={SizeType.Small}
                    selectsStart={true}
                    labelPosition={PositionType.Left}
                    label="TRANSFERS_SALARY_CLAIM_FROM_FIELD_LABEL_TITLE"
                    onChange={() => handleSubmit()}
                    maxDate={values.toDate}
                  />
                  <DatePickerField
                    width={100}
                    name="toDate"
                    className="pr-5"
                    size={SizeType.Small}
                    selectsEnd={true}
                    labelPosition={PositionType.Left}
                    label="TRANSFERS_SALARY_CLAIM_TO_FIELD_LABEL_TITLE"
                    onChange={() => handleSubmit()}
                    minDate={values.fromDate}
                  />
                </div>
                <div
                  className={`${
                    !isMobile ? "position-absolute" : ""
                  }  d-flex status-container flex-lg-row flex-column`}
                >
                  <div className="d-flex align-items-lg-center my-lg-0 my-3">
                    <span className="mr-2">
                      {translate("TRANSFERS_TRANSFERS_FIND_DOCUMENT_WITH_STATUS_LABEL_TITLE")}
                    </span>
                    <SelectPickerField
                      minWidth={250}
                      name="status"
                      onChanges={() => handleSubmit()}
                      options={TransfersStatuses()}
                      size={SizeType.Small}
                      defaultValue={TransfersStatuses()[0]}
                    />
                  </div>
                  <div className="pl-lg-7 pt-2">
                    <Checkbox
                      onChange={() => onShowCreators()}
                      name="showCreators"
                      label="TRANSFERS_TRANSFERS_SHOW_CREATORS_AND_APPROVER_LABEL_TITLE"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
