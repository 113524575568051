
import React, { useCallback, useState} from 'react';
import { useI18n } from "../../i18n/I18nContext";
import { SizeType } from "../../api/dto/AppDTO";
import { useTransferContext } from "../../api/transfer/TransferContext";
import { useReferenceBookContext } from '../../api/reference-book/ReferenceBookContext';
import {
  BudgetAccountSearchType,
  PaymentCodeBudgetFlag,
  PaymentType,
} from "../../api/reference-book/ReferenceBookDTO";


import { Button, ButtonColor } from "../ui/Button";
import { Modal } from "../ui/Modal";
import { TabPage } from '../tabs/TabPage';
import { ExcelTable } from './ExcelTable';
import { ImportExcelFile } from './ImportExcelFile';

import { FileAccept, useSelectFile } from "../../hooks/useSelectFile";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { useResource } from "../../hooks/useResource";
import { CurrencyCode, Language } from "../number-to-words/NumberToWords";
import { beginDaySelector } from "../../reducers/totalReducer";
import { appLanguageSelector } from "../../reducers/appReducer";
import { showError } from '../../utils/NotificationUtils';
import { removeGaps } from "../../utils/FloatUtils";
import { numberToWords } from "../../utils/FormatUtils";


import NotificationExcelLoader from '../ui/NotificationExcelLoader';
import { ExcelIcon } from '../icons/ExcelIcon';

//// set roles for users
import { createNewDocumentRole } from "../../reducers/authReducer";
import { ViewMode, CreateNewDocumentRoles } from "../../api/dto/AppDTO";
import { viewModeSelector } from "../../reducers/appReducer";



export function ExcelTab() {

    const { translate } = useI18n();
    const { TransferApi } = useTransferContext();
    const { ReferenceBookApi } = useReferenceBookContext();
    const language = useShallowEqualSelector(appLanguageSelector);

    const beginDay: any = useShallowEqualSelector(beginDaySelector);
    const languageApp =
    language === "ru" ? Language.Russian : language === "uz" ? Language.Uzbek : Language.English;

    const [importModal, setImportModal] = useState<boolean>(false);
    const [importModalTable, setImportModalTable] = useState<boolean>(true);
    const [importExcelCBU, setImportExcelCBU] = useState<boolean>(false);
    const [list, setList] = useState([]);
    const [loadingModal, setLoadingModal] = useState<boolean>(false);

    const [disableConfirmBtn, setDisabelConfirmBtn] = useState<boolean>(true);
    const [disableExcelBtn, setDisableExcelBtn] = useState<boolean>(false);
    
    /// set roles for users
    const viewMode = useShallowEqualSelector(viewModeSelector);
    const createMode = useShallowEqualSelector(createNewDocumentRole)

    const [importLogs, setImportLogs] = useState([{ 
        documentIndex: "",
        documentDate: "", 
        documentNumber: "", 
        clientAccount: "",
        correspondentName: "",
        correspondentAccount: "",
        correspondentTaxNumber: "",
        paymentCode: "",
        budgetAccount: "",
        budgetTaxNumber: "",
        detailsOfPayment: "",
        amount: "",
        correspondentBank: "",
        paymentDetails: "",
        fromExcelDocument: "",
        status: ""
    }]);

    const [debitAccount, setDebitAccount] = useState({});

    useResource(
      () =>
        ReferenceBookApi.getAllowedAccounts().then((r) => {
          setDebitAccount([
            r.map((res) => ({
              label: res.accountCode,
              value: res.accountCode,
              clientCode: res.accountCode,
              balance: res.balance,
              senderBankCode: res?.accountBranch,
              senderName: res?.accountName,
              accountState: res?.accountState,
              senderBankName: res?.bankName,
              senderTaxId: res?.taxNumber,
            })),
          ]);
        }),
      [ReferenceBookApi],
    );
    

    const importExcelFileTransfers = useCallback(
      (value) => {
        setImportExcelCBU(false);
        setImportModalTable(false);
        setDisabelConfirmBtn(false);
        setImportLogs([]);

        const fileData = value[0]; 
        const fileName = fileData.name; 
        const formData = new FormData();
        formData.append('file', fileData, fileName);

      
        TransferApi.createLcyTransferExcelFile(formData).then((r) => {         
          const updatedLogs = r.map((x) => ({
            documentIndex: x.documentIndex === null ? x.documentIndexError : x.documentIndex,
            documentDate: x.documentDate === null ? x.documentDateError : x.documentDate,
            documentNumber: x.documentNumber === null ? x.documentNumberError : x.documentNumber,
            clientAccount: x.clientAccount === null ? x.clientAccountError : x.clientAccount,
            correspondentName: x.correspondentName === null ? x.correspondentNameError : x.correspondentName,
            correspondentAccount: x.correspondentAccount === null ? x.correspondentAccountError : x.correspondentAccount,
            correspondentTaxNumber: x.correspondentTaxNumber === null ? x.correspondentTaxNumberError : x.correspondentTaxNumber,
            paymentCode: x.paymentCode === null ? x.paymentCodeError : x.paymentCode,
            budgetAccount: x.budgetAccount === null ? x.budgetAccountError : x.budgetAccount,
            budgetTaxNumber: x.budgetTaxNumber === null ? x.budgetTaxNumberError : x.budgetTaxNumber,
            detailsOfPayment: x.detailsOfPayment === null ? x.detailsOfPaymentError : x.detailsOfPayment,
            amount: x.amount === null ? x.amountError : x.amount,
            correspondentBank: x.correspondentBank === null ? x.correspondentBankError : x.correspondentBank,
            paymentDetails: x.paymentDetails,
            fromExcelDocument: x.fromExcelDocument,
            status: translate("TRANSFERS_EXCEL_FILE_TABLE_IMPORTED_COLUMN_TITLE")
          }));

          setImportLogs(updatedLogs);
          importLogs.shift();
          setImportModal(true)
          setImportModalTable(true)
        }).catch(error => {
          showError(error);
          setImportModalTable(true)
        });
        
      },
      [TransferApi, importLogs, setImportModalTable, setImportModal],
    );


    
    const createLcyTransfer = useCallback(async (value, type) => {
        setLoadingModal(true);
        setDisabelConfirmBtn(true);
        setDisableExcelBtn(true);
      
        let completedCount = 0;
        const totalCount = value.length;
      
        const createTransferWithDelay = async (data, logIndex) => {
          try {
            await TransferApi.createLcyTransfer(data);
            const updatedLogs = [...importLogs];
            updatedLogs[logIndex].status = translate("TRANSFERS_EXCEL_FILE_TABLE_SUCCESS_COLUMN_TITLE");
            setImportLogs(updatedLogs);
          } catch (error) {
            const errorMsg = error.data[0]?.userMsg || translate("TRANSFER_IMPORT_ERROR");
            const updatedLogs = [...importLogs];
            updatedLogs[logIndex].status = `Error: ${errorMsg}`;
            setImportLogs(updatedLogs);
          } finally {
            completedCount++;
      
            if (completedCount === totalCount) {
              setLoadingModal(false);
              setDisableExcelBtn(false);
            }
          }
        };
      
      
        for (let x of value) {

          if(window.location.href.split('/').pop() !== "excel") break;

          const selectedFromLog = importLogs.find(y => y.documentNumber === x.documentNumber.toString());
          const logFromImport = importLogs.indexOf(selectedFromLog);
      
          const debitSelected = debitAccount[0]?.find(d => d.label === x.clientAccount.toString().trim());
      
          let data = {
            accountState: debitSelected?.accountState || "",
            amount: x.amount.toString(),
            amountDetails: numberToWords(removeGaps(x.amount.toString()), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            })[0].toUpperCase() +
            numberToWords(removeGaps(x.amount.toString()), {
              language: languageApp,
              currencyCode: CurrencyCode.UzbekistanSum,
            }).slice(1),
            budgetAccount: "",
            budgetAccountName: "",
            budgetFlag: PaymentCodeBudgetFlag.StandartDoc,
            budgetTaxNumber: "",
            clientAccount: debitSelected?.label || "",
            correspondentAccount: x.correspondentAccount.toString().trim(),
            correspondentBank: x.correspondentBank,
            correspondentBankName: "",
            correspondentName: x.correspondentName,
            correspondentTaxNumber: x.correspondentTaxNumber.toString().trim(),
            currentBalance: debitSelected?.balance || "",
            detailsOfPayment: x?.detailsOfPayment,
            documentDate: x.documentDate,
            documentNumber: x.documentNumber,
            operationDate: "",
            paymentCode: x.paymentCode,
            paymentType: type,
            senderBankCode: debitSelected?.senderBankCode || "",
            senderBankName: debitSelected?.senderBankName || "",
            senderName: debitSelected?.senderName || "",
            senderTaxId: debitSelected?.senderTaxId || "",
            fromExcelDocument: x.fromExcelDocument
          };
      
          if (x?.budgetAccount?.length === 27) {
       
            const beneficiaryAcc = x.budgetAccount.toString();
            const res = await ReferenceBookApi.getBudgetAccountsInformation({
              searchType: BudgetAccountSearchType.OneRow,
              budgetIncomeAccount: beneficiaryAcc,
            });
            data.budgetAccount = res[0]?.budgetAccount || "";
            data.budgetAccountName = res[0]?.budgetAccountName || "";
            data.budgetFlag = PaymentCodeBudgetFlag.BudgetDoc;
            data.budgetTaxNumber = res[0]?.taxNumber || "";
  
          } else if (x?.budgetAccount?.length === 25) {
            
            const beneficiaryAcc = x.budgetAccount.toString();
            const res = await ReferenceBookApi.getBudgetIncomeAccountsInformation({
              searchType: BudgetAccountSearchType.OneRow,
              budgetIncomeAccount: beneficiaryAcc,
            });
            data.budgetAccount = res[0]?.budgetIncomeAccount || "";
            data.budgetAccountName = res[0]?.budgetAccountName || "";
            data.budgetFlag = PaymentCodeBudgetFlag.BudgetRevenue;
            data.budgetTaxNumber = debitSelected?.senderTaxId || "";
          }

          await createTransferWithDelay(data, logFromImport);
        }

  
    }, [ReferenceBookApi, debitAccount, importLogs, languageApp, translate, TransferApi]);
    

    const fileSelectorExcel = useSelectFile({  
      accept: [FileAccept.Excel],
      onSelect: (x) =>
        importExcelFileTransfers(x)
    });

 
  return (
    
    <>
        {
          importModal ? (
            <TabPage className="px-4 py-5">
              <ExcelTable
                  data={importLogs}
                  importModalTable={importModalTable}
              />
            </TabPage>
          ) : (
            <TabPage className="px-4 py-5">
                <ExcelTable
                    data={list}
                    importModalTable={importModalTable}
                />
            </TabPage>
          )
        }

        { loadingModal && <NotificationExcelLoader />}
        
        <Modal
            onClose={() => setImportExcelCBU(false)}
            width={550}
            show={importExcelCBU}
            title={"TRANSFER_IMPORT_TABLE_HEADER"}
        >
          <TabPage>
            <ImportExcelFile
              beginDay={beginDay}
              ImportForCBU={() => fileSelectorExcel.open()}
            />
          </TabPage>
        </Modal>

        <div className="px-4 py-4 d-flex align-items-lg-center flex-lg-row flex-column"> 
          {
            viewMode === ViewMode.Full && createMode === CreateNewDocumentRoles.Yes ? (
              <>
                <Button
                  type="button"
                  size={SizeType.Medium}
                  color={ButtonColor.Orange}   
                  className="px-8 mt-lg-0 mr-4"
                  disabled={disableConfirmBtn}
                  onClick={() => createLcyTransfer(importLogs, PaymentType.PaymentOrder)} 
                >
                  {translate("MODAL_CONFIRMATION_YES")}
                </Button>
          
                <Button 
                  type="button"
                  size={SizeType.Medium}
                  color={ButtonColor.Default}  
                  className="px-4 mt-lg-0  bg-transparent text-dark flex border-0"
                  disabled={disableExcelBtn}
                  onClick={() => setImportExcelCBU(true)}
                > 
                  <ExcelIcon/> <span className='ml-1'>{translate("TRANSFERS_TRANSFERS_IMPORT_FROM_EXCEL_LCY_BUTTON_TITLE")}</span>
                </Button>
              </>
              
            ) : (
              <div />
            )
          }
                 
        </div>

    </>

    
  )
}

