import React from "react";
import { useI18n } from "../../i18n/I18nContext";

interface Props {
  readonly status: string;
  readonly fcy?: boolean;
}

export function TransferStatusPicker({ status, fcy }: Props) {
  const { translate } = useI18n();
  return (
    <span>
      {status === "101"
        ? `${translate("PAYROLL_STATUS_CREATED")}`
        : status === "102"
        ? `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_1")}`
        : status === "103"
        ? `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_2")}`
        : status === "104"
        ? `${translate("PAYROLL_STATUS_AUTHORIZED_BY_AUTHORIZER_3")}`
        : status === "106"
        ? `${translate("PAYROLL_STATUS_REJECTED")}`
        : status === "14"
        ? `${translate("PAYROLL_STATUS_REJECTED_BY_CB")}`
        : status === "3"
        ? fcy
          ? `${translate("TRANSFER_STATUS_SENT_BY_BANK")}`
          : `${translate("PAYROLL_STATUS_COMPLETED")}`
        : status === "1"
        ? `${translate("PAYROLL_STATUS_APPROVED")}`
        : status === "2"
        ? `${translate("PAYROLL_STATUS_PROCESSING")}`
        : status === "5"
        ? `${translate("PAYROLL_STATUS_SENT_TO_BANK")}`
        : status === "7"
        ? `${translate("PAYROLL_STATUS_REJECTED_BY_CB")}`
        : status === "8"
        ? `${translate("PAYROLL_STATUS_SENT_TO_CB")}`
        : status === "9"
        ? `${translate("PAYROLL_STATUS_SENT_TO_CARD_SYSTEM")}`
        : "NaN"}
    </span>
  );
}
