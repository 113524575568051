export function FormatPaymentCode(code: string, needChar: string) {
  const string1 = code.substring(0, 3);
  const string2 = code.substring(0, 2);

  if (needChar.length === 3) {
    return string1 === needChar;
  } else if (needChar.length === 2) {
    return string2 === needChar;
  }

  return false;
}
